import {Dialog,} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {EModuleNames} from "../../models/EModuleNames";
import {IDocumentationCall} from "../../models/IDocumentationCall";
import {IModuleDocumentation} from "../../models/IModuleDocumentation";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {PdfDocFileName} from "./panel/PdfDocFileName";
import {PdfDocScope} from "./panel/PdfDocScope";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

enum EPdfDocStep {
    PdfScope,
    PdfFileName,
}

export interface IDlgDocumentationProps {
    dataDefaults: IModuleDocumentation[];
}

interface IDlgDocumentationState {
    isDlgOpen: boolean;
    isPrepare: boolean;
    step: EPdfDocStep;
}

export class DlgDocumentation extends React.Component<IDlgDocumentationProps, IDlgDocumentationState> {

    private readonly eventDlgOpen: IEventHandler;
    private readonly eventDocSubmit: IEventHandler;
    private readonly eventDocDone: IEventHandler;
    private documentation: IDocumentationCall;
    private moduleTitle: string;
    constructor(props: any, context: IDlgDocumentationState) {
        super(props, context);

        this.eventDlgOpen = EventBus.subscribe("PdfDialog:open", (module_title) => this.openDialog(module_title as string));
        this.eventDocSubmit = EventBus.subscribe<IDocumentationCall>("Documentation.submit", (documentation) => {
            this.documentation = documentation;
        });
        this.eventDocDone = EventBus.subscribe<IDocumentationCall>("Documentation.done", () => {
            this.setState({
                isPrepare: false,
                isDlgOpen: false,
                step: EPdfDocStep.PdfScope,
            });
        });
        this.state = {
            isDlgOpen: false,
            isPrepare: false,
            step: EPdfDocStep.PdfScope,
        };
    }

    public componentWillUnmount() {
        EventBus.unsubscribe(this.eventDlgOpen);
        EventBus.unsubscribe(this.eventDocSubmit);
    }

    public render() {
        const module = Globals.getWindowKey("wp_show_module");
        const height = module === EModuleNames.TransferPricing ? undefined : 600;
        return (
            <Dialog
                title={_t(ETranslation.pdf_icon_hoover)}
                isOpen={this.state.isDlgOpen}
                icon={"floppy-disk"}
                canOutsideClickClose={false}
                usePortal={true}
                style={{height}}
                onClose={() => this.closeDialog() }
            >
                {this.renderPage()}
            </Dialog>
        );
    }

    private renderPage() {
        if (this.state.step === EPdfDocStep.PdfFileName ) {
            return <PdfDocFileName isSaving={this.state.isPrepare} onClose={(doSave, fileName, folderId) => this.submitDocument(doSave, fileName, folderId) } />;
        }
        const dataDefaults: IModuleDocumentation[] = this.props.dataDefaults;
        return <PdfDocScope module={this.moduleTitle} dataDefaults={dataDefaults} onAbort={() => this.closeDialog()} onNext={() => this.openFilePage()} />;
    }
    private openFilePage() {
        this.setState({
            step: EPdfDocStep.PdfFileName,
        });
    }
    private closeDialog() {
        this.setState({
            isPrepare: false,
            isDlgOpen: false,
            step: EPdfDocStep.PdfScope,
        });
    }
    private openDialog(module_title: string) {
        this.moduleTitle = module_title;
        this.setState({isDlgOpen: true});
    }

    private submitDocument(doSave: boolean, fileName: string, folderId: string) {
        if (!doSave) {
            return this.setState({
                isPrepare: false,
                isDlgOpen: false,
                step: EPdfDocStep.PdfScope,
            });
        }
        this.documentation.name = fileName;
        this.documentation.parent_folder_id = folderId;
        this.setState({isPrepare: true});
        EventBus.emit("Documentation.send", this.documentation);
    }
}
