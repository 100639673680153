import * as React from "react";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import {Button, Icon} from "@blueprintjs/core";
import {FrontendCalls} from "../../services/FrontendCalls";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";
import {registerNewError} from "./AppToaster";


export const Register = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [uuid, setUuid] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [bluredPassword, setBluredPassword] = useState(false);
    const [bluredCofirm, setBluredConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const pwdState = ()=>{
        return (
            <Icon
                size={20}
                icon={showPassword ? "eye-off" : "eye-open"}
                onClick={()=>setShowPassword(!showPassword)}
            />
        );
    };
    const register = (e)=> {
        e.preventDefault();
        e.stopPropagation();
        if(isLoading){
            return;
        }
        setIsLoading(true);
        (async ()=>{
            try{
                const fc = new FrontendCalls();
                const response = await fc.register(uuid, password);
                if(response.error){
                    registerNewError();
                }else{
                    navigate("/");
                }
            }catch(e){
                console.error(e);
            }
            setIsLoading(false);
        })();
    };
    const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
    const validPassword = regex.test(password);
    return (
        <div className="login-container">
            <form className="form" id={"loginform"} method={"post"}  onSubmit={(e)=> {register(e);}}>
                <div className="form-content-wrapper">
                    <div className="title-container"><h1 className="title">{_t(ETranslation.register)}</h1></div>
                    <div className="email-container">
                        <label className="email-label" htmlFor="username" >E-Mail:</label>
                        <div className="email-input-wrapper">
                            <input
                                className="email-input"
                                autoComplete="username"
                                id="username"
                                name={"username"}
                                value={uuid}
                                onChange={(e)=> setUuid(e.target.value)}
                                required={true}
                                type="email"
                            />
                            <div className="email-icon">
                                <Icon  icon="user" size={20}  />
                            </div>
                        </div>
                    </div>
                    <div className="password-container">
                        <label className="password-label" htmlFor="current-password">{_t(ETranslation.password)}</label>
                        <div className="password-input-wrapper">
                            <input
                                className="password-input"
                                autoComplete="current-password"
                                id="current-password"
                                name="current-password"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e)=> {
                                    setPassword(e.target.value);
                                    }}
                                required={true}
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$"
                                onBlur={(e)=> setBluredPassword(true)}
                                onFocus={(e)=>setBluredPassword(false)}
                            />
                            <div className="key-icon">
                                <Icon  icon="key" size={20}  />
                            </div>
                            <div className="lock-icon">
                                {pwdState()}
                            </div>
                        </div>
                        {bluredPassword && !validPassword && <div style={{color:"red"}}>
                            {_t(ETranslation.pass_valid_err)}
                        </div>}
                    </div>
                    <div className="password-container">
                        <label className="password-label" htmlFor="password2">{_t(ETranslation.confirm_pass)}</label>
                        <div className="password-input-wrapper">
                            <input
                                className="password-input"
                                autoComplete="current-password"
                                id="password2"
                                name="current-password"
                                type={showPassword2 ? "text" : "password"}
                                value={password2}
                                onChange={(e)=> setPassword2(e.target.value) }
                                required={true}
                                onBlur={(e)=> setBluredConfirm(true)}
                                onFocus={(e)=>setBluredConfirm(false)}
                            />
                            <div className="key-icon">
                                <Icon  icon="key" size={20}  />
                            </div>
                            <div className="lock-icon">
                                <Icon
                                    size={20}
                                    icon={showPassword2 ? "eye-off" : "eye-open"}
                                    onClick={()=>setShowPassword2(!showPassword2)}
                                />
                            </div>
                        </div>
                    </div>
                    {bluredCofirm && password2 !== password && <div style={{color:"red"}}>{_t(ETranslation.pass_dosent_match)}</div>}
                    <div className="login-btn-container">
                        <Button
                            loading={isLoading}
                            intent={"primary"}
                            className={"login-btn"}
                            type={"submit"}
                            disabled={!uuid || password!== password2 || !validPassword ? true  : false }
                        >
                            {_t(ETranslation.register)}
                        </Button>
                    </div>
                    <div className="divider"></div>
                    <div className="register-link-container-container">
                        <p><a href={"mailto:service@smart-zebra.de?subject=Register"} target={"_blank"}>Kontakt Support</a></p>
                    </div>
                </div>
            </form>
        </div>
    );
}
