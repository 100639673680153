import * as React from "react";
import {BusinessMultiplier} from "../widgets/BusinessMultiplier";
import {BaseModule} from "./BaseModule";
import {Menu, MenuItem} from "@blueprintjs/core";
import {TasksCalls} from "../../services/TasksCalls";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {MfBTimeSeries} from "./charts/MfBTimeSeries";

export class MultiplierForBusiness extends BaseModule<any> {

    private readonly business_multiplier: React.RefObject<BusinessMultiplier>;

    constructor(props: any, context: any) {
        super(props, context);
        this.business_multiplier =  React.createRef();
        this.state = {
            loading: true,
        };
    }

    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }

    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.business_multiplier.current.tableProps as any);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderContent() {
        return (
            <div style={{paddingTop: 16}}>
                <div className={"sz-row"}>
                    <div className={"sz-col sz-col-50"} style={{paddingRight: 20}}>
                        <BusinessMultiplier multiplier={this.data} ref={this.business_multiplier} />
                    </div>
                    <div className={"sz-col sz-col-50"} style={{paddingLeft: 20}}>
                        <MfBTimeSeries caption={this.data[0].label} time_series={this.data[0].time_series} />
                    </div>
                </div>
            </div>
        );
    }
    protected renderLoading() {
        return this.renderLoadingDefault();
    }
}
