import * as React from "react";
import {useNavigate} from "react-router-dom";
import { ServiceCalls } from "../../services/ServiceCalls";
import {
    Button,
    Classes,
    Dialog,
    DialogProps,
    FormGroup,
    InputGroup,
    Intent,
    TextArea,
} from "@blueprintjs/core";
import {IProject} from "../../models/IProject";
import { useState } from "react";
import {IconNames} from "@blueprintjs/icons";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";
interface INewProjectProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    projectType: number;
    projects: IProject[];
}

const NewProjectDialog: React.FC<INewProjectProps> = ({
                                                          isOpen,
                                                          setIsOpen,
                                                          projectType,
                                                          projects,
                                                      }) => {
    const dialog: DialogProps = {
        usePortal: true,
        isOpen,
        title: _t(ETranslation.new_project),
        onClose: () => setIsOpen(false),
    };


    const navigate = useNavigate();

    // Dialog Data
    const [dialogData, setDialogData] = useState({
        projectName: "",
        projectIdent: "",
        projectDescription: "",
    });

    // handle Dialog Data Changes
    const handleChange = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;
        setDialogData({
            ...dialogData,
            [name]: value,
        });
    };

    // create Project
    const createPrj = () => {
        if (!dialogData.projectName) {
            return;
        }
        // console.log(dialogData);
        // const data = {...dialogData, ...{projectType}}
        const data = {
            id: null,
            name: dialogData.projectName,
            ident: dialogData.projectIdent,
            description: dialogData.projectDescription,
            project_type: projectType,
        };
        (async () => {
            const cb = new ServiceCalls();
            try {
                if(!existingProject){
                const response = await cb.createProject(data);
                if(response && response?._id){
                    navigate(`/project/${response._id}`);
                }}
            } catch (exi) {
                console.error(exi);
            }
            finally {
                setIsOpen(false);
            }
        })();
    };

    // handle Create Project
    const hndCreatePrjClick = () => {
        createPrj();
    };
    const existingProject = projects.find(
        (p: IProject) =>
            p.name.toLowerCase() === dialogData.projectName.toLowerCase()
    );

    return (
        <Dialog {...dialog}>
            <div className={Classes.DIALOG_BODY}>
                <FormGroup
                    label={_t(ETranslation.project_name)}
                    labelFor="idPrjName"
                     intent={existingProject ? Intent.DANGER : undefined}
                     helperText={
                      existingProject
                        ? `Projektname ist vergeben, geben Sie bitte einen anderen ein.`
                         : "Pflichtfeld"
                     }
                >
                    <InputGroup
                        intent={existingProject ? Intent.DANGER : undefined}
                         leftIcon={existingProject ? IconNames.WARNING_SIGN : undefined}
                        id={"idPrjName"}
                        name="projectName"
                        value={dialogData.projectName}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup label={_t(ETranslation.project_ident)} labelFor="idPrjIdent">
                    <InputGroup
                        id={"idPrjIdent"}
                        name="projectIdent"
                        onChange={handleChange}
                        value={dialogData.projectIdent}

                    />
                </FormGroup>
                <TextArea
                    id={"idPrjDesc"}
                    inputMode={"text"}
                    style={{ width: "100%", minHeight: 100 }}
                    name="projectDescription"
                    onChange={handleChange}
                    value={dialogData.projectDescription}
                    placeholder={_t(ETranslation.desciption)}
                />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    {/* <Button minimal={true} onClick={() => this.dismissNewPrj()}>
            Abbrechen
          </Button> */}
                    &nbsp;&nbsp;
                    <Button
                        intent={"primary"}
                        onClick={() => hndCreatePrjClick()}
                        disabled={!dialogData.projectName || existingProject ? true : false}
                    >
                        {_t(ETranslation.create)}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default NewProjectDialog;
