import {HTMLInputProps, NumberRange, RangeSlider, RangeSliderProps} from "@blueprintjs/core";
import * as React from "react";

export interface ISzRangeProperties {
    start: number;
    stop: number;
    max: number;
    min: number;
    stepSize: number;
    stepSizeLabel?: number;
    labelValues?: number[];
    labelRenderer?(value: number);
    onValueChanged?(start: number, stop: number);
    onRelease?(start: number, stop: number);
}
export interface ISzRangeState {
    start: number;
    stop: number;
}

export class SzRange extends React.Component<HTMLInputProps & ISzRangeProperties, ISzRangeState> {
    public static getStartStop(d: any, start: number, stop: number) {
        let v = d;
        if (typeof d === "string") {
            v = d.split(",");
        }
        if (Array.isArray(v) && v.length >= 2) {
            return {start: parseInt(v[0], 10), stop: parseInt(v[1], 10)};
        }
        return {start, stop};
    }

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            start: this.props.start,
            stop: this.props.stop,
        };
    }
    public render() {
        const value = [this.state.start, this.state.stop] as NumberRange;
        const inputProps: RangeSliderProps = {
            value,
            stepSize: this.props.stepSize,
            labelStepSize: this.props.stepSizeLabel,
            labelValues: this.props.labelValues,
            min: this.props.min,
            max: this.props.max,
            onChange: (v: NumberRange) => this.onChangeValue(v),
            onRelease: (v: NumberRange) => this.onRelease(v),
            labelRenderer: this.props.labelRenderer ? this.props.labelRenderer : true,
        };
        const onKeyUp = (charCode: number, keyCode: number) => {
            // if (this.state.isOpen && keyCode === 13) {
                //
            // }
        };
        return (
            <RangeSlider {...inputProps} />
        );
    }
    private onRelease(v: NumberRange) {
        this.setState({
            start: v[0],
            stop: v[1],
        });
        this.props?.onRelease(v[0], v[1]);
    }
    private onChangeValue(v: NumberRange) {
        this.setState({
            start: v[0],
            stop: v[1],
        });
        this.props?.onValueChanged(v[0], v[1]);
    }
}
