import * as React from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {SzChartOptions} from "../../../helpers/SzChartOptions";
import {CSSProperties} from "react";
import {Bubble} from "react-chartjs-2";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {Globals} from "../../../const/Globals";
import {CompressedArrayItem} from "../../../models/chef/RawBeta";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);
const styleH: CSSProperties = {
    width: "100%",
    color: "#182026",
    fontSize: 14,
    fontWeight: "bold",
    display: "block",
    paddingBottom: 5,
    marginTop: 14,
    boxShadow: "inset 0px -1px 0px 0px #cecece",
};
export interface IRegressionResidualsChartProps {
    company: ICompanyBase;
    data: any;
}

const fmt = (v)=>Globals.formatter(v, 2, 2, true);

export function RegressionResidualsChart(props: IRegressionResidualsChartProps) {
    const company: ICompanyBase = props.company;
    const data = props.data;
    const o = new SzChartOptions();
    o.setXTickCallback(()=> "");
    o.setYTickCallback(()=> "");
    o.setYMinMax({min: -4, max: 4});
    o.setXMinMax({min: -4, max: 4});
    o.setCustomAxisLabel(_t(ETranslation.index_return), "Residuum");
    const options = {
        ...o.options,
    };
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
    };
    options.scales.x.position = {y: 0};
    // options.scales.x.grid.tickLength = 5;
    options.scales.y.position = {x: 0};
    options.plugins.tooltip = {
        enabled: true,
        mode: "point",
        intersect: true,
        backgroundColor: "rgba(255,255,255,0.75)",
        titleColor: "rgba(219,55,55,1)",
        bodyColor: "rgba(219,55,55,1)",
        borderColor: "rgba(82,82,82,0.75)",
        borderWidth: 1,
        caretSize: 0,
        cornerRadius: 2,
        callbacks: {
            label: (item)=> { return `${_t(ETranslation.index_return)}(${fmt(item?.raw?.rawData?.x)}) Residuum(${fmt(item?.raw?.rawData?.y)})`;},
            title: (item)=> { return "Regressionsresiduen";},
        },
    };
    const dots = {
        label: "Regressionsresiduen",
        backgroundColor: "rgba(19,124,189,0.75)",
        hoverBackgroundColor: "rgba(219,55,55,0.75)",
        pointHoverBackgroundColor: "rgba(219,55,55,0.75)",
        data: [],
    };
    const chart_data = {
        datasets: [
            dots,
        ],
    };
    const period = SessionStore.get(EParameters.PeriodsParameter);
    const raw_betas = data.raw_betas;
    const raw_beta = raw_betas[company.id];
    const a: CompressedArrayItem[] = raw_beta?.compressed_arrays[period];
    const regression = raw_beta?.periods[period];
    if(Array.isArray(a)){
        const fx = (x)=> regression.Intercept + regression.Slope * x;
        let max = 0;
        const dach_data = a.map((p)=>{
            const b_dach = fx(p.a);
            const bb = p.b - b_dach;
            const t_max = Math.max(Math.abs(b_dach), Math.abs(bb)) * 1.2;
            if (t_max > max) {
                max = t_max;
            }
            return {x: b_dach, y: bb};
        });
        const scale = 4 / max;
        dots.data = dach_data.map((p)=>({x: p.x*scale, y: p.y * scale, r: 3, rawData: p}));
    }
    return (
        <div>
            <div style={styleH}>{_t(ETranslation.regression_index_residual)}</div>
            <div style={{aspectRatio: "1/1"}}>
                <Bubble data={chart_data as any} options={options} />
            </div>
            <div className={"sz-hint"}>{_t(ETranslation.visualization_heteroscedasticity)}</div>
        </div>
    );
}
