import * as React from "react";
import {CSSProperties} from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {first, last} from "../../../helpers/Helpers";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {EventBus} from "../../../services/EventBus";
import {ISzRangeProperties, SzRange} from "../../widgets/SzRange";
import {ISzSelectProperties, SzSelect} from "../../widgets/SzSelect";
import {CombiParameter} from "../CombiParameter";
import {_t, _td} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const ratings = ["AA", "AA-", "A+", "A", "A-", "BBB+", "BBB", "BBB-", "BB+", "BB", "BB-", "B+", "B"];

const label_style: CSSProperties={
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
};
const label_style_2: CSSProperties={
    paddingTop: 10,
};


export class CreditSpreadParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
        EventBus.subscribe("CreditSpreadParameter::Set::SectorParameter", (sector_id)=> this.onChangeValue(EParameters.SectorParameter, sector_id));
    }
    protected getWidthClass() {
        return "fixed-width-600";
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CreditSpreadParameter];
    }

    protected async initialize() {
        const state = this.initializeShort(EParameters.CreditSpreadParameter);
        // console.error(state);
        const {start, stop} = SzRange.getStartStop(state[EParameters.RuntimeParameter], 12, 360);
        state[EParameters.RuntimeParameter] = `${start / 12},${stop / 12}`;
        return state;
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                <div style={{paddingTop: 10, paddingRight: 10}} className={"sz-row"}>
                    {this.renderRuntimeParameter()}
                    {this.renderRating()}
                    {this.renderSector()}
                </div>
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.CreditSpreadParameter))}
            </div>
        );
    }

    protected getLabel(): any {
        const {start, stop} = SzRange.getStartStop(this.state[EParameters.RuntimeParameter], 0, 30);
        let a = this.getOptionLabel(EParameters.SectorParameter);
        if (this.state[EParameters.SectorParameter] === "0000") {
            a = _t(ETranslation.sector_all_avg);
        }
        const rating = this.state[EParameters.RatingParameter];
        const start_value = first(rating, "BBB");
        const stop_value = last(rating, "BBB");

        const l = [
            `${start === stop ? `${start} ${_t(ETranslation.year_abbreviation)}` : `${start} ${_t(ETranslation.to)} ${stop} ${_t(ETranslation.year_abbreviation)}`}`,
            `${start_value === stop_value ? start_value : `${start_value} ${_t(ETranslation.to)} ${stop_value}`}`,
            a,
        ];

        return l.join(" | ");
    }
    private onRelease(_start: number, _stop: number) {
        this.onSetValue(EParameters.RuntimeParameter, `${_start},${_stop}`);
        this.fireValueChange(EParameters.RuntimeParameter, `${_start * 12},${_stop * 12}`);
    }
    private onChangeValue(field: EParameters, _valueAsNumber: any) {
        this.onSetValue(field, _valueAsNumber);
        this.fireValueChange(field, _valueAsNumber);
    }
    private onSetValue(field: EParameters, _valueAsNumber: any) {
        const state: {[k: string]: any} = {};
        state[field] = _valueAsNumber;
        this.setState(state);
    }

    private renderRuntimeParameter() {
        const {start, stop} = SzRange.getStartStop(this.state[EParameters.RuntimeParameter], 0, 30);
        const inputProps: ISzRangeProperties = {
            start,
            stop,
            min: 0,
            max: 30,
            stepSize: 1,
            stepSizeLabel: 5,
            onValueChanged: (_start: number, _stop: number) => this.onSetValue(EParameters.RuntimeParameter, `${_start},${_stop}`),
            onRelease: (_start: number, _stop: number) => this.onRelease(_start, _stop),
        };
        return this.renderColumn("40%", _t(ETranslation.duration), ()=>{
            return (
                <div style={{paddingRight: 10, paddingLeft: 10}}>
                    <div><SzRange {...inputProps} /></div>
                </div>

            );
        }, label_style);
    }
    private getInputProps(parameter: EParameters, optionParam?: EParameters): ISzSelectProperties{
        return {
            options: optionParam ? ParameterOptions.options[optionParam] : ParameterOptions.options[parameter],
            value:  "" + this.state[parameter],
            onValueChanged: (v: any) => this.onChangeValue(parameter, v),
        };
    }
    private renderRating() {
        const rating = this.state[EParameters.RatingParameter];
        // console.error(rating);
        const start_value = first(rating, "BBB");
        const stop_value = last(rating, "BBB");
        const start = ratings.indexOf(start_value);
        const stop = ratings.indexOf(stop_value);
        const getValues = (a, b)=> {
            const v = [];
            for (let i = a; i <= b; i++) {
                v.push(ratings[i]);
            }
            return v;
        }
        const onSetValue = (a, b)=> {
            this.onSetValue(EParameters.RatingParameter, getValues(a, b));
        }
        const onRelease = (a, b)=> {
            const v = getValues(a, b);
            this.onSetValue(EParameters.RatingParameter, v);
            this.fireValueChange(EParameters.RatingParameter, [v]);
        }
        const inputProps: ISzRangeProperties = {
            start,
            stop,
            min: 0,
            max: ratings.length -1,
            stepSize: 1,
            stepSizeLabel: 3,
            onValueChanged: (_start: number, _stop: number) => onSetValue(start, stop),
            onRelease: (_start: number, _stop: number) => onRelease(_start, _stop),
            labelRenderer: (value) => ratings[value] ,
        };
        return this.renderColumn("40%", _t(ETranslation.rating), ()=>{
            return (
                <div style={{paddingRight: 10, paddingLeft: 10}}>
                    <div><SzRange {...inputProps} /></div>
                </div>

            );
        }, label_style);
    }
    private renderSector() {
        const props = this.getInputProps(EParameters.SectorParameter);
        if(props.options.length<21){
            props.options.unshift({label: _td(ETranslation.sector_all_avg), value: "0000"});
        }

        return this.renderColumn("40%", _t(ETranslation.sector), ()=>{
            return (
                <SzSelect {...props} />
            );
        }, label_style);
    }
}

