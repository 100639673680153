import {EContentType} from "../../const/EContentType";
import {EDataSourceMode} from "../../const/EDataSourceMode";
import {EPackageTypes} from "../../const/EPackageTypes";
import {NopeActions} from "../../const/NopeActions";
import {EFeItem} from "../../models/EFeItem";
import {ELayoutColumnWidth} from "../../models/ELayoutColumnWidth";
import {EParameters} from "../../models/EParameters";
import {IColumnLayout} from "../../models/IColumnLayout";
import {ColumnLayouterImpl} from "../ColumnLayouterImpl";
import {ModuleGroup} from "../ModuleGroup";
import {EExcelExportType} from "../../const/EExcelExportType";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {IModuleConfig} from "../../models/IModuleConfig";

export class Multiples extends ModuleGroup<IColumnLayout> {
    public static config: IModuleConfig = {
        name: "Multiplikatoren",
    };
    constructor() {
        super("Multiplikatoren", { columns: []});

        this.documentation = [
            {module: "Multiplicators", caption: "Peer-Group Multiplikatoren"},
        ];

        this.addParameter(EParameters.CompaniesParameter);
        this.addParameter(EParameters.DateParameter);
        // this.addParameter(EParameters.SectorCountriesParameter);

        const dataDefault = [
            [],
            [],
            [EFeItem.SALES, EFeItem.EBITDA, EFeItem.EBIT, EFeItem.NETPROFIT],
            [0, 1, 2],
        ];

        this.addModule(_t(ETranslation.peer_group_multiples), "Multiplicators", "MultiplicatorsForCompaniesV2Provider", dataDefault, EDataSourceMode.multi_instance)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true);

        this.addModule(_t(ETranslation.sme_multiples), "MultiplicatorsConcess", "DateTriggerProvider")
             .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register);

        this.addModule("Peer-Group", "CompanyInfo", "DateTriggerProvider")
            .setAlignRight()
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.beta);

        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c100)
            .addModule([0, 1, 2]);
/*
        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c30)
            .addModule(3);

 */
    }
    protected getTitle(): string{
        return "Multiplikatoren";
    }
}
