import * as React from "react";
import {CSSProperties, useEffect, useState} from "react";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {Globals} from "../../../const/Globals";
import {Classes} from "@blueprintjs/core";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {CompressedArrayItem, IRegression} from "../../../models/chef/RawBeta";
import {ICompressedArrayItem} from "../../../models/ICompressedArrayItem";
import {linearRegression, no_star} from "../../../helpers/Helpers";
import {SzDocReference} from "../../widgets/SzDocReference";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {EPeriodToFrequency, EPeriodToRange} from "../../../const/PeriodConstants";
import {ChefCalls} from "../../../services/ChefCalls";
import {SzSmallLoader} from "../../hooks/SzSmallLoader";
import {PARAMETER_OPTION, ParameterOptions} from "../../../const/ParameterOptions";

interface IStdDev {
    std_dev_company: number;
    std_dev_index: number;
}

const ERenditeType = {
    "performance": _t(ETranslation.return_type_total_return),
    "price": _t(ETranslation.return_type_price_return),
}
const EResolveIndexType = {
    "local": "National",
    "regional": "Regional",
    "global": "Global",
}

export interface IInfoTableProps {
    company: ICompanyBase;
    data: any;
}
async function loadData(company: ICompanyBase, data: any): Promise<IStdDev>{
    const deadline = SessionStore.get(EParameters.DateParameter);
    const period = SessionStore.get(EParameters.PeriodsParameter);
    const return_type = SessionStore.get(EParameters.ReturnTypeParameter);

    const years = parseInt(period, 10);
    const raw_betas = data.raw_betas;
    const raw_beta = raw_betas[company.id];
    if(!raw_beta){
        return {} as IStdDev;
    }
    const stock_index_id = raw_beta.stock_index_id;
    const currency = raw_beta.currency_iso;
    const db = new ChefCalls();
    const std_dev_company = await db.getStandardDeviationCompany(company.id, deadline, years, currency, return_type);
    const std_dev_index = await db.getStandardDeviationIndex(stock_index_id, deadline, years, currency, return_type);
    return {
        std_dev_company,
        std_dev_index,
    };
}
export function InfoTable(props: IInfoTableProps){
    const [std_dev, setStdDev] = useState<IStdDev>(undefined);
    const company: ICompanyBase = props.company;
    const data = props.data;
    useEffect(() => {
        (async ()=>{
            const r = await loadData(company, data);
            setStdDev(r);
        })();
    }, []);
    if(!std_dev){
        return (
            <div>
                <div style={{aspectRatio: "1/1"}}>
                    <SzSmallLoader title={""} />
                </div>
            </div>
        );
    }
    const styleH: CSSProperties = {
        width: "100%",
        color: "#182026",
        fontSize: 14,
        fontWeight: "bold",
        display: "block",
        paddingBottom: 5,
        marginTop: 12,
        boxShadow: "inset 0px -1px 0px 0px #cecece",
    };
    const putH = (text: string) => {
        return <tr><td colSpan={2}><div style={styleH}>{text}</div></td></tr>;
    };
    const putField = (name: any, value: any, digits: number = 2, addClass?: string) => {
        const notANumber = isNaN(Number(value));
        const v = notANumber ? value : Globals.formatter(value, digits, digits, true);
        return <tr><td className={Classes.TEXT_MUTED}>{name}</td><td className={`${notANumber ? null : "sz-right"} ${addClass ? addClass : null}`}>{v}</td></tr>;
    };
    const putFieldP = (name: any, value: any, digits: number = 2, addClass?: string) => {
        const notANumber = isNaN(Number(value));
        const v = notANumber ? value : Globals.formatter_percent(value, digits, digits, true);
        return <tr><td className={Classes.TEXT_MUTED}>{name}</td><td className={`${notANumber ? null : "sz-right"} ${addClass ? addClass : null}`}>{v}</td></tr>;
    };
    const deadline = SessionStore.get(EParameters.DateParameter);
    const period = SessionStore.get(EParameters.PeriodsParameter);
    const return_type = SessionStore.get(EParameters.ReturnTypeParameter);
    const composition_type = SessionStore.get(EParameters.CompositionTypeParameter);
    const composition_type_options = PARAMETER_OPTION(EParameters.CompositionTypeParameter);
    const ct = composition_type_options.find((i)=> i.value === composition_type);
    const index_type = SessionStore.get(EParameters.IndexTypeParameter);
    const raw_betas = data.raw_betas;
    const raw_beta = raw_betas[company.id];
    const a: CompressedArrayItem[] = raw_beta?.compressed_arrays[period];
    const regression: IRegression = raw_beta?.periods[period];

    let dDubinWatsonTest = 0;
    let dBreuschPaganTest = 0;

    if(Array.isArray(a)){
        const fx = (x)=> regression.Intercept + regression.Slope * x;

        const error_term = [];
        const breusch_pagan_array:ICompressedArrayItem[] = [];
        let d = 0;
        a.forEach((p, idx)=>{
            const b_dach = fx(p.a);
            const bb = p.b - b_dach;
            const residuals2 = Math.pow( bb, 2);

            breusch_pagan_array.push({
                a: p.a,
                b: residuals2,
                stop_date: p.stop_date,
                start_date: p.start_date,
                days: p.days,
            });

            error_term.push(bb);
            if(idx>0){
                d+= Math.pow( bb -  error_term[idx - 1], 2);
            }
        });
        // console.error(d, regression.SumQDResiduals);
        dDubinWatsonTest = d / regression.SumQDResiduals;

        const breusch_pagan_regression: IRegression = linearRegression(breusch_pagan_array);
        dBreuschPaganTest = breusch_pagan_regression.R2 * breusch_pagan_array.length;
    }
    return (
        <table className={"sz-table-no-styles sz-table-minimal-no-padding-right"}>
            <tbody>
            {putH("Parameter")}
            {putField(_t(ETranslation.valuation_date), deadline, 0, "sz-right")}
            {putField(_t(ETranslation.observation_period), `${EPeriodToRange[period]}`, 0, "sz-right")}
            {putField(_t(ETranslation.return_period), `${EPeriodToFrequency[period]}`, 0, "sz-right")}
            {putField(_t(ETranslation.return_type), ERenditeType[return_type], 0, "sz-right")}
            {putField(_t(ETranslation.index_composition), no_star(ct.label), 0, "sz-right")}
            {putField(_t(ETranslation.stock_market), EResolveIndexType[index_type], 0, "sz-right")}
            {putField(_t(ETranslation.currency), raw_beta ? raw_beta.currency_iso : Globals.hyphen, 0, "sz-right")}
            {putField(_t(ETranslation.stock_index), raw_beta ? raw_beta.stock_name : Globals.hyphen, 0, "sz-right")}
            {putField(_t(ETranslation.stock), company.co_name || company.name, 0, "sz-right")}
            <tr><td colSpan={2}>&nbsp;</td></tr>
            {putH(_t(ETranslation.regression_result))}
            {putField(`${_t(ETranslation.beta_factor)} (β)`, regression.Slope, 2, "sz-right")}
            {putField("Alpha (α)", regression.Intercept, 2, "sz-right")}
            {putField("Raw Beta adjusted", regression.Slope * 2 / 3 + 1 / 3, 2, "sz-right")}
            {putField("Total Raw Beta", regression.Slope / regression.R, 2, "sz-right")}
            <tr><td colSpan={2}>&nbsp;</td></tr>
            {putH(_t(ETranslation.regression_statistics))}
            {putField(_t(ETranslation.index_return_average), regression.MeanA, 2, "sz-right")}
            {putFieldP(_t(ETranslation.index_return_standard_deviation), Math.sqrt(regression.VarianceA), 2, "sz-right")}
            {putFieldP(_t(ETranslation.index_return_standard_deviation_30d), std_dev.std_dev_index * Math.pow(30, .5), 2, "sz-right")}
            {putFieldP(_t(ETranslation.index_return_standard_deviation_250d), std_dev.std_dev_index * Math.pow(250, .5), 2, "sz-right")}
            {putField(_t(ETranslation.index_return_variance), regression.VarianceA, 4, "sz-right")}
            <tr><td colSpan={2}>&nbsp;</td></tr>
            {putField(_t(ETranslation.stock_return_average), regression.MeanB, 2, "sz-right")}
            {putFieldP(_t(ETranslation.stock_return_standard_deviation), Math.sqrt(regression.VarianceB), 2, "sz-right")}
            {putFieldP(_t(ETranslation.stock_return_standard_deviation_30d), std_dev.std_dev_company * Math.pow(30, .5), 2, "sz-right")}
            {putFieldP(_t(ETranslation.stock_return_standard_deviation_250d), std_dev.std_dev_company * Math.pow(250, .5), 2, "sz-right")}
            {putField(_t(ETranslation.stock_return_variance), regression.VarianceB, 4, "sz-right")}
            <tr><td colSpan={2}>&nbsp;</td></tr>
            {putField(_t(ETranslation.data_points), regression.DataPoints, 0, "sz-right")}
            {putField(_t(ETranslation.tradings_days_included), regression.Days, 0, "sz-right")}
            {putField(_t(ETranslation.r_square), Globals.formatter_percent(regression.R2, 2, 2, true), 0, "sz-right")}
            {putField(_t(ETranslation.correlation_coefficient), regression.R, 2, "sz-right")}
            <tr><td colSpan={2}>&nbsp;</td></tr>
            {putField(<SzDocReference text={"T-Test β"} ref_id={"57"} inline={false}/>, regression.TTestSlope, 2, "sz-right")}
            {putField(<SzDocReference text={"T-Test α"} ref_id={"57"} inline={false}/>, regression.TTestIntercept, 2, "sz-right")}
            {putField(<SzDocReference text={"F-Test"} ref_id={"58"} inline={false}/>, regression.FTest, 2, "sz-right")}
            {putField(<SzDocReference text={"Durbin-Watson-Test"} ref_id={"73"} inline={false}/>, dDubinWatsonTest, 2, "sz-right")}
            {putField(<SzDocReference text={"Breusch-Pagan-Test"} ref_id={"74"} inline={false}/>, dBreuschPaganTest, 2, "sz-right")}
            </tbody>
        </table>
    );
}
