import {Button, ButtonGroup, Icon, Intent, Menu, MenuItem, Popover} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {DateOptionsGenerator} from "../../const/DateOptionsGenerator";
import moment = require("moment");

const popper: PopperModifiers = {
    offset: { offset: "[0, 4]" },
};

interface ISzDateButtonState {
    selectedMonth: string;
    selectedYear: string;
}
export interface ISzDateButtonProps {
    date?: string;
    inputDateFormat?: string;
    outputDateFormat?: string;
    onDateChanged?(month: string, year: string);
}
export class SzDateButton extends React.Component<ISzDateButtonProps, ISzDateButtonState> {
    private readonly inputDateFormat: string;
    private readonly outputDateFormat: string;
    protected constructor(props: ISzDateButtonProps, context: any) {
        super(props, context);

        this.inputDateFormat = this.props.inputDateFormat ? this.props.inputDateFormat : "DD.MM.YYYY";
        this.outputDateFormat = this.props.outputDateFormat ? this.props.outputDateFormat : "DD.MM.YYYY";

        const date = this.props.date ? this.props.date :  moment().format(this.inputDateFormat);
        const selectedYear = moment(date, this.inputDateFormat).format("YYYY");
        const selectedMonth = moment(date, this.inputDateFormat).format("MM");
        this.state = {
            selectedMonth,
            selectedYear,
        };
    }

    public render() {
        const _years = parseInt(moment().format("YYYY"), 10);
        let currentYear = 2010;
        const years = [];
        while (currentYear <= _years) {
            years.push("" + currentYear++);
        }
        const m_values = [];
        const m_labels = [];
        DateOptionsGenerator.generateMonths().forEach( (i) => {
            m_values.push(i.value);
            m_labels.push(i.label);
        } );

        const toDay = parseInt(moment().format("YYYYMM"), 10);

        const setDate = (amount: number) => {
            const newDate: string = moment(this.state.selectedMonth + "." + this.state.selectedYear, "MM.YYYY").add(amount, "month").format("YYYYMM");
            const numDate = parseInt(newDate, 10);
            if (numDate > toDay) {
                return;
            }
            if (numDate < 201001) {
                return;
            }
            const new_year = Math.trunc(numDate / 100);
            const new_month = numDate - (new_year * 100);
            const newState = {
                selectedMonth: new_month < 10 ? "0" + new_month : "" + new_month,
                selectedYear: "" + new_year,
            };
            this.setState(newState);
            this.dateChanged(newState.selectedMonth, newState.selectedYear);
        };

        return (
            <ButtonGroup minimal={true} style={{width: "100%"}}>
                <Button icon={IconNames.CHEVRON_LEFT} intent={Intent.PRIMARY} title={"zum Vormonat"} onClick={() => setDate(-1)} />
                {this.renderMenuButton("selectedMonth", m_values, m_labels)}
                {this.renderMenuButton("selectedYear", years, years)}
                <Button icon={IconNames.CHEVRON_RIGHT} intent={Intent.PRIMARY} title={"zum Folgemonat"} onClick={() => setDate(+1)} />
            </ButtonGroup>
        );
    }

    private renderMenuButton(stateField: string, items: number[], labels: any[]) {
        const selectedValue = this.state[stateField];
        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return "";
            }
        };
        const findLabel = () => {
            const idx = items.findIndex( (v) => v === selectedValue );
            return labels[idx];
        };
        const getMenu = () => {
            const disabled = false;
            return (
                <Menu>
                    {
                        items.map((item, idx) => {
                            return(<MenuItem disabled={disabled} intent={ item === selectedValue ? "primary" : "none"} labelElement={getIcon(item === selectedValue)}  text={labels[idx]} onClick={() => { this.setOption(item, stateField); }} />);
                        })
                    }
                </Menu>
            );
        };
        return (
            <Popover
                minimal={true}
                position={"bottom-left"}
                modifiers={popper}
                popoverClassName={`no-max-width`}
                content={getMenu()}
            >
                <Button intent={Intent.PRIMARY} minimal={true} style={{minWidth: 95}}>
                    {findLabel()}
                </Button>
            </Popover>
        );
    }

    private setOption(item: number, stateField: string) {
        const state = this.state;
        state[stateField] = item;

        const m = state.selectedMonth;
        const y = state.selectedYear;

        this.setState(state);
        this.dateChanged(m, y);
    }

    private dateChanged(m: string, y: string) {
        if (this.props.onDateChanged) {
            this.props.onDateChanged(m, y);
        }
    }
}
