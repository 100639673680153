import {Button, Intent, NumericInput, Popover} from "@blueprintjs/core";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {EDataType} from "../../models/EDataType";
import {IToolBarParameter} from "../../models/IToolBarParameter";
import {ToolBarParameter} from "./ToolBarParameter";
import {NumericInputProps} from "@blueprintjs/core/lib/esm/components/forms/numericInput";

export abstract class NumericParameter extends ToolBarParameter<IToolBarParameter> {

    protected dataType: EDataType = EDataType.number;

    protected constructor(props: any, context: any) {
        super(props, context);
    }

    protected abstract getValue();
    protected abstract setValue(v: number);
    protected async initialize() {
        const value = parseFloat(this.getValue());
        if (!Globals.isRegistred) {
            this.setValue(value);
        }
        return {
            value,
            ch: 0,
            oldValue: value,
            isOpen: false,
        };
    }
    protected renderContent() {
        const value =  this.dataType === EDataType.percent ? this.state.value * 100 : this.state.value;
        const inputProps: NumericInputProps = {
            value,
            fill: true,
            stepSize: 0.01,
            minorStepSize: 0.01,
            min: 0,
            max: 100,
            onValueChange: (v: number, s: string) => this.onChangeValue("value", v, s),
            rightElement: <Button icon={"cross"} minimal={true} intent={"danger"} onClick={ () => this.onChangeValue("value", 0, "0") } title={"Zurücksetzen"} />,
        };
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        const openMenu = () => {
            this.setState({
                isOpen: !this.state.isOpen,
                oldValue: this.state.value,
            });
        };
        const testCanClose = (ev) => {
            if (!ev) {
                return;
            }
            if (this.state.isOpen && ev.type === "mousedown") {
                this.setState({isOpen: false});
                this.changeValue();
            }
        };
        const onKeyUp = (charCode: number, keyCode: number) => {
            if (this.state.isOpen && keyCode === 13) {
                this.setState({isOpen: false});
                this.changeValue();
            }
        };

        const getContent = () => {
            return (
                <div style={{padding: 24}}>
                    <NumericInput {...inputProps} onKeyUp={( e ) => onKeyUp(e.charCode, e.keyCode)} />
                </div>
            );
        };
        return (
            <Popover
                minimal={true}
                position={"bottom-left"}
                modifiers={popper}
                popoverClassName={`no-max-width`}
                content={getContent()}
                isOpen={this.state.isOpen}
                canEscapeKeyClose={true}
                captureDismiss={false}
                onInteraction={(a, b) => testCanClose(b)}
            >
                <Button intent={Intent.NONE} minimal={true} className={"sz-toolbar-button"} icon={this.icon}  onClick={() => openMenu()} >
                    <span className={"sz-property-name"}>{this.name}</span>
                    <div className={"sz-property-value"}>{this.getLabel()}</div>
                </Button>
            </Popover>
        );
    }
    protected getLabel(): string {
        if (this.dataType === EDataType.number) {
            return `${this.state.value}`;
        }

        if (isNaN(parseFloat("" + this.state.value))) {
            return "0 %";
        }
        return Globals.formatter_percent(this.state.value);
    }
    private changeValue() {
        if (this.state.value !== this.state.oldValue) {
            const value = this.dataType === EDataType.percent ? this.state.value / 100 : this.state.value;
            this.setValue(this.state.value);
            this.fireChanges([{
                component: this.props.primaryComponent,
                value: [this.state.value],
            }]);
        }
    }
    private onChangeValue(field: string, _valueAsNumber: number, valueAsString: string) {
        const state = {
            ch: this.state.ch + 1,
        };
        state[field] = this.dataType === EDataType.percent ? _valueAsNumber / 100 : _valueAsNumber;
        this.setState(state);
    }
}
