import {Button, Classes, Dialog, Intent, NonIdealState, Tab, Tabs,} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {ICompanyDetailsExt,} from "../../models/ICompanyDetails";
import {ICompanySearchResult} from "../../models/ICompanySearchResult";
import {CompanyFinancials} from "../widgets/CompanyFinancials";
import {CompanyInformation} from "../widgets/CompanyInformation";
import {CompanyRelations} from "../widgets/CompanyRelations";
import {EIndexFunction, SzTableHelper} from "../widgets/helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties, SzTable} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {CompanyEstimates} from "../widgets/CompanyEstimates";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

const BASE_DIR = Globals.BASE_DIR;
interface ISelection {
    companies: ICompanySearchResult[];
    company: ICompanySearchResult;
}
interface IMyState {
    loading?: boolean;
    selectedCompany?: ICompanySearchResult;
    load2?: boolean;
    details?: ICompanyDetailsExt;
    feItems?: any;
    feNullItems?: any;
    company_details?: any;
    est_currency_iso?: string;
}

export class CompanyInfo extends BaseModule<any> {
    private global_year: number;
    private tableProps: ISzTableProperties<ICompanySearchResult>;
    private country_xch_map = {};

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            loading: true,
            selectedCompany: 0,
            load2: false,
        };
    }

    public async getDocumentationData() {
        return this.tableProps;
    }

    public modState(s: IMyState) {
        this.setState(s);
    }
    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "";
    }

    protected renderContent() {
        if (this.state.error) {
            return (
                <NonIdealState icon={IconNames.WARNING_SIGN} title={"Serverfehler"} description={this.state.error}  />
            );
        }

        if (this.state.load2) {
            return this.renderWaiting();
        }

        const {companies, company} = this.getSelection();
        const header_2: ISzTableColumn[] = [];
        header_2.push({ text: "#" });
        header_2.push({ text: _t(ETranslation.company) });
        header_2.push({ text: _t(ETranslation.country)});
        // header_2.push({ text: "Region" });
        header_2.push({ text: _t(ETranslation.sector) });
        header_2.push({ text: "Subsektor" });
/*
        header_2.push({ text: "ID" });
        header_2.push({ text: "FID" });
*/
        const columns: ISzTableColumn[] = [];

        columns.push({ ...SzTableHelper.columnFunction(EIndexFunction.$row_num), options: {style: {width: 10} }}); // index
        columns.push({ ellipsis: true , ...SzTableHelper.columnCountry("company_name"), options: {cellRenderer: null, style: { whiteSpace: "nowrap"}}});
        columns.push({ ...SzTableHelper.columnCountry("country_iso_3"), options: {style: { whiteSpace: "nowrap", width: 40 }}});
        // columns.push({ ...SzTableHelper.columnCountry("country_region_de"), options: {style: { whiteSpace: "nowrap" }}});
        columns.push({ ellipsis: true , ...SzTableHelper.columnCountry("sector_name_de"), options: {style: { whiteSpace: "nowrap", width: 200, fontSize: "80%" }}});
        columns.push({ ...SzTableHelper.columnCountry("industry_name_de"), options: {style: { whiteSpace: "nowrap", width: 200, fontSize: "80%" }}});
/*
        columns.push({ ...SzTableHelper.columnCountry("company_id"), options: {style: { whiteSpace: "nowrap" }}});
        columns.push({ ...SzTableHelper.columnCountry("country_ticker"), options: {style: { whiteSpace: "nowrap" }}});
*/
        const tableProps: ISzTableProperties<ICompanySearchResult> = {
            rowHover: true,
            className: "sz-cell-overflow",
            colCount: columns.length,
            data: companies,
            header: [ header_2 ],
            columns,
            onSelectRow: (row: number, dataRow: any)=> this.onSlectRow(row, dataRow),
        };
        this.tableProps = tableProps;
        return (
            <div style={{overflow: "hidden", position: "relative", paddingTop: 16}} className={"company-popup"}>
                <SzTable {...tableProps} />
                {this.renderCompanyDialog()}
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    private getSelection(): ISelection {
        const source: ICompanySearchResult[] = SessionStore.getItem("SelectedCompanySearch", []);
        let companies: ICompanySearchResult[] = [];
        const selectedCompanies: string[] = SessionStore.getItem("CompaniesParameter", []);

        source.forEach( (co) => {
            if (selectedCompanies.find((s) => co.company_id === s)) {
                companies.push(co);
            }
        } );
        const deCollator = new Intl.Collator("de");
        companies = companies.sort((a, b) => deCollator.compare(a.company_name.toLowerCase(), b.company_name.toLowerCase()));

        let selectedCompany = this.state.selectedCompany;
        if (selectedCompany) {
            const co = companies.find( (c) =>  c.company_id === this.state.selectedCompany.company_id);
            if (!co) {
                selectedCompany = undefined;
            }
        }

        const company: ICompanySearchResult = selectedCompany ? selectedCompany : companies[0];
        return {companies, company};
    }
    private noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }

    private renderWaiting() {
        return (
            <div style={{overflow: "hidden", position: "relative"}}>
            </div>
        );
    }
    private onSlectRow(row: number, company: ICompanySearchResult) {
        this.setState({
            company_dialog_is_open: true,
            selectedCompany: company,
        });
    }
    private renderCompanyDialog() {
        if (!this.state.company_dialog_is_open) {
            return null;
        }
        const company: ICompanySearchResult = this.state.selectedCompany;
        return (
            <Dialog
                style={{width: 1200, backgroundColor: "transparent", padding: 0}}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={true}
                onClose={() => this.setState({company_dialog_is_open: false})}
            >
                <div className={`${Classes.DIALOG_HEADER} bp3-dark`} style={{backgroundColor: "rgb(57, 75, 89)", paddingLeft: 10}}>
                    <img src={`${Globals.BASE_DIR}images/flags/${company.country_iso_2.toLowerCase()}.jpg`} style={{height: 30, alignSelf: "center"}}/>
                    <div style={{display: "flex", width: "100%", paddingRight: 10, marginLeft: 10, justifyContent: "space-between", alignItems: "center"}}>
                        <span style={{fontSize: "100%", fontWeight: 600}}>{company.company_name}</span>
                        <div style={{fontSize: "70%"}} className={"bp3-text-muted"}>{company.country_ticker.toUpperCase()} - {company.company_id}</div>
                    </div>
                    <Button intent={Intent.DANGER} aria-label={"Schließen"} icon={"small-cross"} minimal={true} onClick={() => this.setState({company_dialog_is_open: false})} />
                </div>
                <div className={Classes.DIALOG_BODY} style={{margin: 0, padding: 10, paddingBottom: 10, backgroundColor: "#ffffff"}}>
                    <Tabs id="CI_TABS" className={"sz-tab-seized"}>
                        <Tab id="CI_TABS_BASE" title={_t(ETranslation.base_data)} panel={<CompanyInformation company_id={company.company_id} />} />
                        <Tab id="CI_TABS_COMP" title={_t(ETranslation.peer_group)} panel={<CompanyRelations company_id={company.company_id} />} />
                        <Tab id="CI_TABS_FIN" title={_t(ETranslation.financials)} panel={<CompanyFinancials company_id={company.company_id} />} />
                        <Tab id="CI_TABS_EST" title="Schätzungen" panel={<CompanyEstimates company_id={company.company_id} />} />
                    </Tabs>
                </div>
            </Dialog>
        );
    }
}
