const _f = (v)=> {
    if(isNaN(v) || v===undefined || v===null){
        return 0;
    }
    return v;
};

export class FinAnn {
    public static asCreditSpreadIndication(source: any[]){
        // source.forEach((i, idx, arr) => arr[idx] = _f(i));
        const [
            id,
            da,
            currency_iso,
            ff_debt, // ff_debt, // Finanzschulden
            ff_cash_generic, // ff_cash_generic: v("ff_debt"), // Liquide Mittel
            ff_pens_liabs_unfunded, // ff_pens_liabs_unfunded: v("ff_debt"), // Pensionsdefizit
            ff_funds_oper_gross, // ff_funds_oper_gross: v("ff_debt"), // FFO // operatives Ergebnis
            ff_eq_tot, // ff_eq_tot: v("ff_debt"), // Eigenkapital
            ff_shldrs_eq, // ff_shldrs_eq: v("ff_debt"), // Eigenkapital der Konzernaktionäre
            ff_assets, // ff_assets,  // Bilanzsumme
            ff_ppe_net, // ff_ppe_net: v("ff_debt"), // Sachanlagen
            ff_intang, // ff_intang: v("ff_debt"), // Immaterielle Vermögenstände
            ff_capex, // ff_capex: v("ff_debt"), // Investitionen
            ff_wkcap, // Net Working Kapital
            ff_sales, // ff_sales: v("ff_debt"), // Umsatzerlöse
            ff_ebitda_oper, // ff_ebitda,
            ff_ebit_oper, // ff_ebit,
            ff_int_exp_debt, // ff_int_exp_debt: v("ff_debt"), // Zinsaufwendungen
            ff_inc_tax, // Ertragssteuern
            ff_net_inc, // ff_net_inc: v("ff_debt"), // Jahresüberschuss
            ff_gross_inc, // ff_gross_inc: v("ff_debt"), // Rohertrag
            ff_invest_aff, // ff_invest_aff: v("ff_debt"), // Finanzanlagen
        ] = source;
        return {
            ff_debt, // ff_debt, // Finanzschulden
            ff_cash_generic, // ff_cash_generic: v("ff_debt"), // Liquide Mittel
            ff_pens_liabs_unfunded, // ff_pens_liabs_unfunded: v("ff_debt"), // Pensionsdefizit
            ff_funds_oper_gross, // ff_funds_oper_gross: v("ff_debt"), // FFO // operatives Ergebnis
            ff_eq_tot, // ff_eq_tot: v("ff_debt"), // Eigenkapital
            ff_shldrs_eq, // ff_shldrs_eq: v("ff_debt"), // Eigenkapital der Konzernaktionäre
            ff_assets, // ff_assets,  // Bilanzsumme
            ff_ppe_net, // ff_ppe_net: v("ff_debt"), // Sachanlagen
            ff_intang, // ff_intang: v("ff_debt"), // Immaterielle Vermögenstände
            ff_capex, // ff_capex: v("ff_debt"), // Investitionen
            ff_wkcap, // Net Working Kapital
            ff_sales, // ff_sales: v("ff_debt"), // Umsatzerlöse
            ff_ebitda_oper, // ff_ebitda,
            ff_ebit_oper, // ff_ebit,
            ff_int_exp_debt, // ff_int_exp_debt: v("ff_debt"), // Zinsaufwendungen
            ff_inc_tax, // Ertragssteuern
            ff_net_inc, // ff_net_inc: v("ff_debt"), // Jahresüberschuss
            ff_gross_inc, // ff_gross_inc: v("ff_debt"), // Rohertrag
            ff_invest_aff, // ff_invest_aff: v("ff_debt"), // Finanzanlagen
        };
    }
    public static fromCreditSpreadIndication(source: any) {
        const v = (field)=>source[field];
        const d = {
            company_id: source.id,
            date_decimal: source.da,
            currency_iso: source.currency_iso_3,
            ff_debt: v("ff_debt"), // ff_debt, // Finanzschulden
            liquide_mittel: v("ff_cash_generic"), // ff_cash_generic: v("ff_debt"), // Liquide Mittel
            pensionsdefizit: v("ff_pens_liabs_unfunded"), // ff_pens_liabs_unfunded: v("ff_debt"), // Pensionsdefizit
            ffo: v("ff_funds_oper_gross"), // ff_funds_oper_gross: v("ff_debt"), // FFO // operatives Ergebnis
            eigenkapital: v("ff_eq_tot"), // ff_eq_tot: v("ff_debt"), // Eigenkapital
            eigenkapital_konzernaktionaere: v("ff_shldrs_eq"), // ff_shldrs_eq: v("ff_debt"), // Eigenkapital der Konzernaktionäre
            bilanzsumme: v("ff_assets"), // ff_assets,  // Bilanzsumme
            sachanlagen: v("ff_ppe_net"), // ff_ppe_net: v("ff_debt"), // Sachanlagen
            immaterielle_vermoegen: v("ff_intang"), // ff_intang: v("ff_debt"), // Immaterielle Vermögenstände
            capex: v("ff_capex"), // ff_capex: v("ff_debt"), // Investitionen
            ff_wkcap: v("ff_wkcap"), // Net Working Kapital
            sales: v("ff_sales"), // ff_sales: v("ff_debt"), // Umsatzerlöse
            ebitda: v("ff_ebitda_oper"), // ff_ebitda,
            ebit: v("ff_ebit_oper"), // ff_ebit,
            interest: v("ff_int_exp_debt"), // ff_int_exp_debt: v("ff_debt"), // Zinsaufwendungen
            ff_inc_tax: v("ff_inc_tax"), // Ertragssteuern
            netincome: v("ff_net_inc"), // ff_net_inc: v("ff_debt"), // Jahresüberschuss
            gross: v("ff_gross_inc"), // ff_gross_inc: v("ff_debt"), // Rohertrag
            financial_asset: v("ff_invest_aff"), // ff_invest_aff: v("ff_debt"), // Finanzanlagen
            sales_growth: NaN,
            ebitda_growth: NaN,
        };
        return [
            d.company_id,
            d.date_decimal,
            d.currency_iso,
            d.ff_debt,
            d.liquide_mittel,
            d.pensionsdefizit,
            d.ffo,
            d.eigenkapital,
            d.eigenkapital_konzernaktionaere,
            d.bilanzsumme,
            d.sachanlagen,
            d.immaterielle_vermoegen,
            d.capex,
            d.ff_wkcap,
            d.sales,
            d.ebitda,
            d.ebit,
            d.interest,
            d.ff_inc_tax,
            d.netincome,
            d.gross,
            d.financial_asset,
            d.sales_growth,
            d.ebitda_growth
        ];
    }
    public static getCustomFin(year, benchmarkingCustom: any) {
        const v = (field)=>benchmarkingCustom ? benchmarkingCustom[`${year}_${field}`] : undefined;
        const sales_growth = ()=> {
            const sales = v("ff_sales");
            const last_sales = benchmarkingCustom ? benchmarkingCustom[`${year - 1 }_ff_sales`] : undefined;
            return (sales / last_sales - 1) * 100;
        }
        const ebitda_growth = ()=> {
            const ebitda = v("ff_ebitda_oper");
            const last_ebitda = benchmarkingCustom ? benchmarkingCustom[`${year - 1 }_ff_ebitda_oper`] : undefined;
            return (ebitda / last_ebitda - 1) * 100;
        }
        const d = {
            company_id: -1,
            date_decimal: year*10000,
            currency_iso: "fan",
            ff_debt: v("ff_debt"), // ff_debt, // Finanzschulden
            liquide_mittel: v("ff_cash_generic"), // ff_cash_generic: v("ff_debt"), // Liquide Mittel
            pensionsdefizit: v("ff_pens_liabs_unfunded"), // ff_pens_liabs_unfunded: v("ff_debt"), // Pensionsdefizit
            ffo: v("ff_funds_oper_gross"), // ff_funds_oper_gross: v("ff_debt"), // FFO // operatives Ergebnis
            eigenkapital: v("ff_eq_tot"), // ff_eq_tot: v("ff_debt"), // Eigenkapital
            eigenkapital_konzernaktionaere: v("ff_shldrs_eq"), // ff_shldrs_eq: v("ff_debt"), // Eigenkapital der Konzernaktionäre
            bilanzsumme: v("ff_assets"), // ff_assets,  // Bilanzsumme
            sachanlagen: v("ff_ppe_net"), // ff_ppe_net: v("ff_debt"), // Sachanlagen
            immaterielle_vermoegen: v("ff_intang"), // ff_intang: v("ff_debt"), // Immaterielle Vermögenstände
            capex: v("ff_capex"), // ff_capex: v("ff_debt"), // Investitionen
            ff_wkcap: v("ff_wkcap"), // Net Working Kapital
            sales: v("ff_sales"), // ff_sales: v("ff_debt"), // Umsatzerlöse
            ebitda: v("ff_ebitda_oper"), // ff_ebitda,
            ebit: v("ff_ebit_oper"), // ff_ebit,
            interest: v("ff_int_exp_debt"), // ff_int_exp_debt: v("ff_debt"), // Zinsaufwendungen
            ff_inc_tax: v("ff_inc_tax"), // Ertragssteuern
            netincome: v("ff_net_inc"), // ff_net_inc: v("ff_debt"), // Jahresüberschuss
            gross: v("ff_gross_inc"), // ff_gross_inc: v("ff_debt"), // Rohertrag
            financial_asset: v("ff_invest_aff"), // ff_invest_aff: v("ff_debt"), // Finanzanlagen
            sales_growth: sales_growth(),
            ebitda_growth: ebitda_growth(),
        };
        return [
            d.company_id,
            d.date_decimal,
            d.currency_iso,
            d.ff_debt,
            d.liquide_mittel,
            d.pensionsdefizit,
            d.ffo,
            d.eigenkapital,
            d.eigenkapital_konzernaktionaere,
            d.bilanzsumme,
            d.sachanlagen,
            d.immaterielle_vermoegen,
            d.capex,
            d.ff_wkcap,
            d.sales,
            d.ebitda,
            d.ebit,
            d.interest,
            d.ff_inc_tax,
            d.netincome,
            d.gross,
            d.financial_asset,
            d.sales_growth,
            d.ebitda_growth,
        ];
    }
    public static getFinanceData(d: any[], exchange_rate = 1) {
        if (!d) {
            return null;
        }
        d.forEach((_d, idx, ar) =>{
            if(idx < 3){
                return;
            }
            ar[idx] = _f(_d);
        });
        const [
            company_id,
            date_decimal,
            currency_iso,
            ff_debt, // ff_debt, // Finanzschulden
            liquide_mittel, // ff_cash_generic, // Liquide Mittel
            pensionsdefizit, // ff_pens_liabs_unfunded, // Pensionsdefizit
            ffo, // ff_funds_oper_gross, // FFO // operatives Ergebnis
            eigenkapital, // ff_eq_tot, // Eigenkapital
            eigenkapital_konzernaktionaere, // ff_shldrs_eq, // Eigenkapital der Konzernaktionäre
            bilanzsumme, // ff_assets,  // Bilanzsumme
            sachanlagen, // ff_ppe_net, // Sachanlagen
            immaterielle_vermoegen, // ff_intang, // Immaterielle Vermögenstände
            capex, // ff_capex, // Investitionen
            ff_wkcap, // Net Working Kapital
            sales, // ff_sales, // Umsatzerlöse
            ebitda, // ff_ebitda,
            ebit, // ff_ebit,
            interest, // ff_int_exp_debt, // Zinsaufwendungen
            ff_inc_tax, // Ertragssteuern
            netincome, // ff_net_inc, // Jahresüberschuss
            gross, // ff_gross_inc, // Rohertrag
            financial_asset, // ff_invest_aff, // Finanzanlagen // 22
        ] = d;
        if(company_id==="0"){
            console.error(ffo);
        }
        const l = d.length === 65 ? 67 : d.length;
        const ebit_oper = d[l - 22];
        const ebitda_oper = d[l - 3];
        const sales_growth = d[l - 2];
        const ebitda_growth = d[l - 1];
        // const net_fin_debt = debt - cash; // NettoFinanzschulden
        const anlagevermoegen = immaterielle_vermoegen + sachanlagen;
        const net_debt = ff_debt + pensionsdefizit - liquide_mittel; // Nettoschulden
        const net_work_capital = ff_wkcap - liquide_mittel;
        const gross_sales_ratio = 100 * gross / sales;
        const ebitda_sales_ratio = 100 * ebitda / sales;
        const ebit_sales_ratio = 100 * ebit / sales;
        const netincome_sales_ratio = 100 * netincome / sales;
        const ebitda_interest = ebitda / interest;
        const capital_employed = eigenkapital + ff_debt + pensionsdefizit - liquide_mittel - financial_asset;

        // const ebitda_oper_sales_ratio = 100 * ebitda_oper / sales;
        // const ebit_oper_sales_ratio = 100 * ebit_oper / sales;

        let roce = (ebit / capital_employed) * 100;
        let roe = 100 * netincome / eigenkapital_konzernaktionaere;

        let ffo_debt_ratio = 100 * ffo / net_debt;
        let debt_ebitda = net_debt / ebitda;
        const ff_debt_ebitda_oper = ff_debt / ebitda;
        const net_debt_ebitda = net_debt / ebitda;
        let debt_ebitda_ratio = debt_ebitda;
        const ffo_interest = (ffo + interest) / interest;
        const ebit_interest = ebit / interest;
        const ebit_interest_ratio = ebit_interest;
        let debt_equity_ratio = 100 * net_debt / eigenkapital;

        const capex_sales_ratio = 100 * capex / sales;
        const capex_capitalasset_ratio = 100 * capex / anlagevermoegen;
        const working_sales_ratio = 100 * ff_wkcap / sales;
        const working_capital_employed_ratio = 100 * ff_wkcap / capital_employed;

        const anlagendeckungsgrad = 100 * eigenkapital / anlagevermoegen;
        const anlagenintensitaet = 100 * (sachanlagen + immaterielle_vermoegen) / bilanzsumme;

        const capital_turnover = bilanzsumme / sales;
        const gearing = 100 * (net_debt / eigenkapital); //

        // Eigenkapitalquote
        const eq_ratio = 100 * eigenkapital / bilanzsumme;

        if(!sachanlagen || !sales){
            // console.error(company_id);
        }

        const xch_sales = sales/ exchange_rate;
        const xch_assets = bilanzsumme / exchange_rate;
        const xch_anlagevermoegen = anlagevermoegen/ exchange_rate;

        // check
        if (eigenkapital < 0 && netincome < 0) {
            roce = null;
            roe = null;
        }
        if (net_debt <= 0 ) {
            ffo_debt_ratio = null;
            debt_ebitda = null;
            debt_ebitda_ratio = null;
            debt_equity_ratio = null;
        }



        const source = {
            company_id,
            date_decimal,
            currency_iso,
            ff_debt, // ff_debt, // Finanzschulden
            liquide_mittel, // ff_cash_generic, // Liquide Mittel
            pensionsdefizit, // ff_pens_liabs_unfunded, // Pensionsdefizit
            ffo, // ff_funds_oper_gross, // FFO // operatives Ergebnis
            eigenkapital, // ff_eq_tot, // Eigenkapital
            eigenkapital_konzernaktionaere, // ff_shldrs_eq, // Eigenkapital der Konzernaktionäre
            bilanzsumme, // ff_assets,  // Bilanzsumme
            sachanlagen, // ff_ppe_net, // Sachanlagen
            immaterielle_vermoegen, // ff_intang, // Immaterielle Vermögenstände
            capex, // ff_capex, // Investitionen
            ff_wkcap, // Net Working Kapital
            sales, // ff_sales, // Umsatzerlöse
            ebitda, // ff_ebitda,
            ebit, // ff_ebit,
            interest, // ff_int_exp_debt, // Zinsaufwendungen
            ff_inc_tax, // Ertragssteuern
            netincome, // ff_net_inc, // Jahresüberschuss
            gross, // ff_gross_inc, // Rohertrag
            financial_asset, // ff_invest_aff, // Finanzanlagen
            sales_growth,
            net_debt,
            ebitda_growth,
            ebitda_oper,
            ebit_oper,
        };
        return {
            source,
            result: {
                ebitda,
                ebit,
                // ebit_oper_sales_ratio,
                // ebitda_oper_sales_ratio,
                sales,
                capex,
                netincome,
                gross,
                gross_sales_ratio,
                ebitda_sales_ratio,
                ebit_sales_ratio,
                netincome_sales_ratio,
                roce,
                roe,
                ffo_debt_ratio,
                ffo_interest,
                debt_ebitda,
                debt_ebitda_ratio,
                ff_debt_ebitda_oper,
                ebit_interest,
                ebitda_interest,
                ebit_interest_ratio,
                debt_equity_ratio,
                capex_sales_ratio,
                capex_capitalasset_ratio,
                working_sales_ratio,
                //
                anlagevermoegen,
                anlagendeckungsgrad,
                bilanzsumme,
                anlagenintensitaet,
                eigenkapital,
                eq_ratio,
                stichtag: date_decimal,
                sales_growth,
                capital_employed,
                capital_turnover,
                gearing,
                ebitda_growth,
                working_capital_employed_ratio,
                xch_sales,
                xch_assets,
                xch_anlagevermoegen,
                net_debt_ebitda,
            },
        };
    }
}
