import * as React from "react";
import * as ReactDOM from "react-dom";
import {SvgTextSize} from "../../helpers/SvgTextSize";
import {IHighlightValue} from "../../chart-plugin/HighlightValue";

const default_highlight: IHighlightValue = {
    line_color: "#f47a22",
    line_dash: undefined,
};


export interface ISzSvgBarHighlight{
    value: number;
    line_color?: string;
    line_dash?: number[];
}
export interface ISzSvgBarAnnotation {
    min: number;
    max: number;
    std_dev: number;
    mean: number;
    median: number;
}
export interface ISzSvgBarCellProperties {
    max: number;
    min: number;
    value: number;
    height: any;
    bar_padding?: number;
    margin?: number;
    padding_right: number;
    classNamePos?: string;
    classNameNeg?: string;
    annotation?: ISzSvgBarAnnotation;
    highlights?:ISzSvgBarHighlight[],
    hideMean?: boolean;
    override_value?: any;
    add_x_grid?: number[];
    formatter(v: any): string;
}

export class SzSvgBarCell extends React.Component<ISzSvgBarCellProperties, any> {

    private readonly svg: React.RefObject<HTMLDivElement> = null;
    private observer: IntersectionObserver;

    constructor(props: any, context: any) {
        super(props, context);
        this.svg = React.createRef();

        window.addEventListener("resize", () => this.resize());

    }
    public render() {
        return (<div ref={this.svg} style={{ width: "100%", height: this.props.height, display: "flex" }}/>);
    }
    public componentDidMount(): void {
        const width: number = this.svg.current.clientWidth;
        const height: number = this.svg.current.clientHeight;
        ReactDOM.render(this.renderSvg(width, height), this.svg.current);
        this.observer = new IntersectionObserver((items, observer) => { this.onIntersectionObserver(items, observer); });
        this.observer.observe(this.svg.current);
    }
    public componentWillUnmount(): void {
        this.observer.disconnect();
    }
    public renderSvg(width, height) {
        return (
            <svg width={width} height={height} className={"sz-chart"}>
                {this.renderData(width, height)}
            </svg>
        );
    }
    public resize() {
        if (this.svg && this.svg.current) {
            const width: number = this.svg.current.clientWidth;
            const height: number = this.svg.current.clientHeight;
            ReactDOM.render(this.renderSvg(width, height), this.svg.current);
        }
    }
    private onIntersectionObserver(items: IntersectionObserverEntry[], observer: IntersectionObserver) {
        for (const item of items) {
            if (!item.isIntersecting) {
                continue;
            }
            this.resize();
            break;
        }
    }
    private renderData(width: number, height: number) {
        const margin = this.props.margin ? this.props.margin : 0;
        let min = this.props.min;
        let max = this.props.max;
        let padding_right = this.props.padding_right + margin;
        let padding_left = this.props.padding_right + margin;
        if (min > 0) {
            min = 0;
            padding_left = 0;
        } else if (max < 0) {
            max = 0;
            padding_right = 0;
        }
        const paddings = padding_right + padding_left;
        const delta = (max - min);
        const xFactor = (width - paddings) / delta;

        const value = this.props.value;
        const label = this.props.formatter(value);
        const x_origin = (0 - min) * xFactor;
        let w = 0;
        const h = height;
        const padding_top = 0;
        let classNamePos = this.props.classNamePos ? this.props.classNamePos : "sz-chart-dot-1";
        let x_rect = padding_left;
        let x_text = 0;
        const y_0 = this.props.bar_padding ? this.props.bar_padding : 0;
        const y_h = this.props.bar_padding ? h - (2* this.props.bar_padding) : h;
        if(Array.isArray(this.props.override_value)){
            const v = this.props.override_value;
            w = (v[1] - v[0]) * xFactor;
            x_rect = padding_left + x_origin + (v[0] * xFactor);
            x_text = undefined;
        }else{
            if (value < 0) {
                classNamePos = this.props.classNameNeg ? this.props.classNameNeg : "sz-chart-dot-db";
                const label_width: number = SvgTextSize.measureText(label).width;
                w = (-1 * value) * xFactor;
                x_rect = padding_left + (x_origin - w);
                x_text = x_rect - label_width;
            } else if (value > 0) {
                w = value * xFactor;
                x_rect = padding_left + x_origin;
                x_text = x_rect + w + margin;
            } else {
                w = 2;
                x_rect = padding_left + x_origin - 1;
                x_text = x_rect + margin;
            }
            if (w < 0 || h < 0 || x_rect < 0 || x_text < 0) {
                return (
                    <g className={"sz-chart-data"}></g>
                );
            }
        }
        const renderText = ()=>{
            if(x_text === undefined){
                return null;
            }
            return (
                <text x={x_text} y={h / 2} className={"sz-legend-label"}>{label}</text>
            );
        };
        const renderHighlights = ()=>{
            const highlights = this.props.highlights;
            if(!Array.isArray(highlights)){
                return null;
            }
            return highlights.map((p)=>{
                if(p.value === undefined){
                    return null;
                }
                p = Object.assign({},default_highlight, p);
                const x = p.value * xFactor;
                const line_dash = p.line_dash ? p.line_dash.join(" ") : undefined;
                return <line x1={x} x2={x} y1={0} y2={h} stroke={p.line_color} strokeDasharray={line_dash}/>
            });
        };
        const renderXGrid = () => {
            if(!Array.isArray(this.props.add_x_grid)){
                return null;
            }
            const x_grid: number[] = this.props.add_x_grid;
            return x_grid.map((v, idx)=>{
                const x = idx === 0 ? 1 : v * xFactor;
                return <line x1={x} x2={x} y1={0} y2={h} className={"sz-chart-axis-y-grid"} />
            });
        };
        const renderRect = ()=>{
            if(isNaN(x_rect) || isNaN(y_0) || isNaN(w) || isNaN(y_h)){
                return null;
            }

            return <rect x={x_rect} y={y_0} width={w} height={y_h} className={classNamePos}/>;
        };
        return (
            <g className={"sz-chart-data"} transform={`translate(0 ${padding_top < 0 ? 0 : padding_top})`}>
                {renderXGrid()}
                {renderRect()}
                {renderText()}
                {renderHighlights()}
            </g>
        );
    }

}
