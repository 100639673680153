import {
    Alert,
    Button,
    ButtonGroup,
    Callout,
    Classes,
    Dialog,
    DialogProps,
    Divider,
    FormGroup,
    InputGroup,
    Intent,
    IToastProps,
    Navbar,
    NavbarGroup,
    TextArea,
    Toaster,
} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Tooltip2} from "@blueprintjs/popover2";
import * as React from "react";
import {EStatisticsValueType} from "../const/EStatisticsValueType";
import {Globals} from "../const/Globals";
import * as ParameterComponents from "../const/ParameterComponents";
import {SessionStore} from "../const/SessionStore";
import {_k, getResult} from "../helpers/Helpers";
import {EModuleNames} from "../models/EModuleNames";
import {EParameters} from "../models/EParameters";
import {IDocumentationCall} from "../models/IDocumentationCall";
import {IParameterChanged} from "../models/IParameterChanged";
import {EProjectType, EProjectUserRole, IProject} from "../models/IProject";
import {ModuleGroup} from "../modules";
import {IParameterOptions} from "../modules/ModuleParameter";
import {EventBus, IEventHandler} from "../services/EventBus";
import {ServiceCalls} from "../services/ServiceCalls";
import {SearchDrawer} from "./SearchDrawer";
import {ProjectParameter} from "./toolbar/inputs/ProjectParameter";
import {ToolBarParameter} from "./toolbar/ToolBarParameter";
import {_t} from "../tools/Translator";
import {ETranslation} from "../const/ETranslation";

const components = ParameterComponents.components;

export interface IParameterBar {
    module: ModuleGroup;
    handleParametersChanged?(parameters: IParameterChanged[]);
}
export interface IParameterBarState {
    isLoading: boolean;
    isOpenB: boolean;
    isSaving: boolean;
    showNewPrjDialog: boolean;
    projectDescription?: string;
    projectName?: string;
    projectIdent?: string;
    project?: IProject;
    changes?: boolean;
    freezeLongList?: boolean;
    permaFreezeLongList?: boolean;
    showPermaFreeze: boolean;
    showOverride: boolean;
    tpCountAll: number;
}

export class ParameterBar extends React.Component <IParameterBar, IParameterBarState> {
    private search: React.RefObject<SearchDrawer> = null;
    private searchField: React.RefObject<InputGroup> = null;
    private readonly msgToaster: React.RefObject<Toaster>;

    private parameters: Map<string, React.RefObject<ToolBarParameter<any>>> = null;

    private needToLoad: IParameterOptions[] = [];
    private isLoading: boolean = false;

    private readonly onDocumentation: IEventHandler;
    private readonly evtGlobalVarChanged: IEventHandler;
    private readonly evtCountTpLongList: IEventHandler;

    private documentation: IDocumentationCall;

    private allProjects: IProject[] = [];
    private selectedProject: string;
    constructor(props, context) {
        super(props, context);

        this.handleValuesChanged = this.handleValuesChanged.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleKeyUpSearch = this.handleKeyUpSearch.bind(this);

        this.state = {
            isLoading: false,
            isOpenB: false,
            isSaving: false,
            showNewPrjDialog: false,
            changes: false,
            showPermaFreeze: false,
            showOverride: false,
            tpCountAll: 0,
            freezeLongList: SessionStore.getItem(EParameters.TpFreezeLongList, false),
            permaFreezeLongList: SessionStore.getItem(EParameters.TpPermaFreezeLongList, false),
        };

        this.onDocumentation = EventBus.subscribe<IDocumentationCall>("Documentation.send", (data) => this.sendDocumentation(data));
        this.msgToaster = React.createRef();
        this.selectedProject = SessionStore.getItem("defaultProject", "0:0:0:0");
        this.evtCountTpLongList = EventBus.subscribe("TpProvider::longlist", (data) => this.onCountTpLongList(data));
    }
    public componentWillUnmount(): void {
        EventBus.unsubscribe(this.onDocumentation);
        EventBus.unsubscribe(this.evtGlobalVarChanged);
        EventBus.unsubscribe(this.evtCountTpLongList);
    }
    public componentDidMount(): void {
        (async () => {
            const cb = new ServiceCalls();
            try {
                const result: IProject[] = getResult<IProject[]>(await cb.getProjects(), []);
                if (result) {
                    this.allProjects = result;
                    this.setState({
                        isLoading: false,
                        projectDescription: "",
                        projectName: "",
                        projectIdent: "",
                        project: result.find( (p) => p._id === this.selectedProject),
                    });

                }
            } catch (exi) {
                console.error(exi);
            }
        })();
    }
    public render() {
        const params = this.props.module ? this.props.module.parameters : [];

        if (!(this.props && this.props.module)) {
            return (<Navbar fixedToTop={true} />);
        }
        // const params = this.props.module.parameters;
        this.search = React.createRef();
        this.searchField = React.createRef();
        this.parameters = new Map<string, React.RefObject<ToolBarParameter<any>>>();
        let cKey = 0;

        const existingProject = this.getPrjByName(this.state.projectName);

        const getText = () => {
            return "Erstellen";
        };
        const getCaption = () => {
            return "Neues Projekt erstellen";
        };

        const dialog: DialogProps = {
            usePortal: true,
            isOpen: this.state.showNewPrjDialog,
            title: getCaption(),
            onClose: () => this.dismissNewPrj(),
        };
        const setPermaFreeze = ()=> {
            SessionStore.setItem(EParameters.TpPermaFreezeLongList, true);
            SessionStore.setItem(EParameters.TpFreezeLongList, true);
            this.setState({
                freezeLongList: true,
                permaFreezeLongList: true,
                showPermaFreeze: false,
            });
        };
        let nopeIsSet = false;
        const hndCreatePrjClick= ()=>{
            this.createPrj();
        };

        const render_search = ()=> {
            if([EModuleNames.TransferPricing, EModuleNames.CreditSpreads].indexOf(Globals.currentModule) >=0 ){
                return null;
            }else{
                return (
                    <>
                        <SearchDrawer ref={this.search}/>
                        <Button disabled={!Globals.isRegistred} intent={"primary"} icon="search" onClick={this.handleSearchClick} className={"sz-nav-search-button"} text={_t(ETranslation.company_search)}/>
                    </>
                );
            }
        };
        return (
            <Navbar fixedToTop={false}>
                <NavbarGroup>
                    {render_search()}
                    {params.map((item, nav_item) => {
                        if(item === EParameters.DividerParameter){
                            return (<Navbar.Divider key={_k("nav_bar_parameter", nav_item)} />);
                        }
                        const DynamicComponent = components[item];
                        if (!nopeIsSet) {
                            if (item === EParameters.NopeParameter) {
                                nopeIsSet = true;
                                return null;
                            }
                        }
                        if (DynamicComponent) {
                            const refParam = React.createRef<ToolBarParameter<any>>();
                            this.parameters.set(item, refParam);
                            if (nopeIsSet) {
                                return null;
                            }
                            return <DynamicComponent ref={refParam} key={"navProp" + (cKey++)} primaryComponent={item} handleValuesChanged={(parameters: IParameterChanged[]) => {
                                this.handleValuesChanged(parameters);
                            }}/>;
                        }
                        return null;
                    })}
                    {this.renderTpCount()}
                </NavbarGroup>
                <Toaster position={"top"} autoFocus={false} canEscapeKeyClear={true} ref={this.msgToaster} className={"in-front"} />
                <Dialog {...dialog}>
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                            label="Projektname"
                            labelFor="idPrjName"
                            intent={existingProject ? Intent.DANGER : undefined}
                            helperText={existingProject ? `Projektname ist vergeben, geben Sie bitte einen anderen ein.` : "Pflichtfeld"}
                        >
                            <InputGroup
                                intent={existingProject ? Intent.DANGER : undefined}
                                leftIcon={existingProject ? IconNames.WARNING_SIGN : undefined}
                                id={"idPrjName"}
                                onChange={(event) => this.changePrjName(event)}
                                value={this.state.projectName}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Projekt-Identifikation"
                            labelFor="idPrjIdent"
                        >
                            <InputGroup
                                id={"idPrjName"}
                                onChange={(event) => this.changePrjIdent(event)}
                                value={this.state.projectIdent}
                            />
                        </FormGroup>
                        <TextArea
                            id={"idPrjDesc"}
                            inputMode={"text"}
                            style={{width: "100%", minHeight: 100}}
                            onChange={(event) => this.changePrjDesc(event)}
                            value={this.state.projectDescription}
                            placeholder={"Beschreibung"}
                        />
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button minimal={true} onClick={ () => this.dismissNewPrj() }>Abbrechen</Button>
                            &nbsp;&nbsp;
                            <Button intent={"primary"} onClick={ () => hndCreatePrjClick() } disabled={(!this.state.projectName || existingProject ? true : false)}>{getText()}</Button>
                        </div>
                    </div>
                </Dialog>
                <Alert
                    cancelButtonText={_t(ETranslation.cancel)}
                    confirmButtonText="Okay"
                    icon={IconNames.DISABLE}
                    intent={Intent.DANGER}
                    isOpen={this.state.showPermaFreeze}
                    onCancel={()=> this.setState({showPermaFreeze: false})}
                    onConfirm={()=> setPermaFreeze()}
                >
                    <>
                        <p>
                            Alle Einstellungen werden <b>permanent</b> gesperrt.
                        </p>
                        <p>
                            Diese Sperre kann nicht aufgehoben werden.
                        </p>
                    </>
                </Alert>
            </Navbar>
        );
    }
    public handleValuesChanged(parameters: IParameterChanged[]) {
        if (this.props && this.props.handleParametersChanged) {
            this.props.handleParametersChanged(parameters);
        }
    }
    private handleKeyUpSearch(evt) {
        if (this.search) {
            this.search.current.handleOpen(evt.target.value);
            evt.target.value = "";
        }
    }
    private handleSearchClick() {
        if (this.search) {
            this.search.current.handleOpen();
        }
    }

    private sendDocumentation(documentation: IDocumentationCall) {
        this.documentation = documentation;
        const parameters: any[] = [];
        let skipOthers = false;
        this.parameters.forEach((paramerterRef, key) => {
            if (!skipOthers) {
                skipOthers = !paramerterRef.current;
            }
            if (skipOthers) {
                console.error("skip parameter ... ", key);
                return;
            }
            parameters.push({
                label: paramerterRef.current.labelExt ? paramerterRef.current.labelExt : paramerterRef.current.label,
                name: paramerterRef.current.name,
                component: paramerterRef.current.component,
            });
        });
        this.documentation.parameters = parameters;
        (async () => {
            const service = new ServiceCalls();
            await service.save(this.documentation);
            EventBus.emit("Documentation.done", null);

            const v = [];
            this.documentation.saveModules.forEach( (m) => v.push(m.moduleCaption) );
            Globals.trackInfo(EStatisticsValueType.pdf_export, v);

        })();
    }
    private executeReset() {
        ( async () => {
            const cb = new ServiceCalls();
            await cb.resetValues();
            window.location.reload();
        } )();
    }
    private createPrj() {
        // console.error("savePrj");
        if (!this.state.projectName) {
            return;
        }
        const getProjectType = ()=>{
            if(Globals.currentModule === EModuleNames.TransferPricing){
                return EProjectType.transfer_pricing;
            }
            if(Globals.currentModule === EModuleNames.CreditSpreads){
                return EProjectType.credit_spread;
            }
            return EProjectType.valuation;
        };
        const data = {
            id: null,
            name: this.state.projectName,
            ident: this.state.projectIdent,
            description: this.state.projectDescription,
            project_type: getProjectType(),
        };

        const o: IProject = this.getPrjByName(data.name);
        if (o && o._id) {
            // data.id = o._id;
            return;
        }
        this.selectedProject = null;
        let f: IProject = null;
        (async () => {
            const cb = new ServiceCalls();
            try {
                const result: IProject[] = getResult<IProject[]>(await cb.createProject(data), []);
                if (result) {
                    this.allProjects = result;
                    f = this.allProjects.find( (i) => i.name.toLowerCase() === ("" + this.state.projectName).toLowerCase());
                    console.error("new prj");
                    if (f) {
                        this.selectedProject = f._id;
                        SessionStore.setItem("defaultProject", f._id);
                        SessionStore.setItem(EParameters.TpFreezeLongList, false);
                        SessionStore.setItem(EParameters.TpPermaFreezeLongList, false);
                    }
                }
            } catch (exi) {
                console.error(exi);
            }
            this.setState({
                showNewPrjDialog: false,
                showOverride: false,
                projectDescription: "",
                projectName: "",
                projectIdent: "",
                permaFreezeLongList: false,
                freezeLongList: false,
                changes: false,
                project: f,
            });
            EventBus.emit("ProjectParameter::update", {});
        })();
    }
    private dismissNewPrj() {
        this.setState({
            showNewPrjDialog: false,
            showOverride: false,
            projectDescription: "",
            projectName: "",
            projectIdent: "",
        });
    }

    private getPrjByName(projectName: string): IProject {
        if (!projectName) {
            return null;
        }
        return  this.allProjects.find( (o) => o.name.toLowerCase() === ("" + projectName).toLowerCase());
    }
    private changePrjIdent(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({projectIdent: event.target.value});
    }
    private changePrjName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({projectName: event.target.value});
    }
    private changePrjDesc(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({projectDescription: event.target.value});
    }

    private getProjectName() {
        return (
            <ProjectParameter primaryComponent={EParameters.ProjectParameter} handleValuesChanged={(evt)=> this.projectChanged(evt)} key={"idProjectParameter"} />
        );
    }

    private projectChanged(evt: IParameterChanged[]) {
        if(!Array.isArray(evt)){
            return;
        }
        const new_project = evt.find( (e) => e.component === EParameters.ProjectParameter);
        if(!new_project){
            return;
        }
        if(this.selectedProject === new_project.value[0]){
            return;
        }
        const prj_id = new_project.value[0];
        if(prj_id === "0:0:0:0"){
            (async () => {
                try {
                    const storeCalls = new ServiceCalls();
                    await storeCalls.setSessionValue("defaultProject", prj_id);
                    window.location.reload();
                } catch (e) {
                    console.error(e);
                }
            })();
            return;
        }
        (async () => {
            const cb = new ServiceCalls();
            try {
                // await cb.createProject(data);
                await cb.loadProject(prj_id);
                window.location.reload();
            } catch (exi) {
                console.error(exi);
            }
        })();
    }

    private onCountTpLongList(count) {
        this.setState({tpCountAll: count});
    }
    private renderTpCount(){
        if(Globals.currentModule !== EModuleNames.TransferPricing){
            return null;
        }
        if(!Globals.isRegistred){
            return null;
        }
        if(SessionStore.getItem(EParameters.TpFreezeLongList, false)){
            return null;
        }
        if(this.state.tpCountAll<=200){
            return (
                <Callout intent={Intent.NONE} style={{width: 300, fontSize: "95%", marginRight: 6}}>Anzahl Treffer [{Globals.formatter(this.state.tpCountAll, 0, 0)}]</Callout>
            );
        }else{
            return (
                <Callout intent={Intent.WARNING} style={{width: 300, fontSize: "95%", marginRight: 6}}>Anzahl Treffer [{Globals.formatter(this.state.tpCountAll, 0, 0)}] zu groß (max. 200)</Callout>
            );
        }
    }
}
