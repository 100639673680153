import {Button, Icon, Menu, MenuItem} from "@blueprintjs/core";
import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {Sic, Sic3, SicClasses, SicRanges} from "../../../const/Sic";
import {Labels} from "../../../helpers/Labels";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {ISic3Count, ISicEntry, ISicRange} from "../../../models/Sic";
import {CombiParameter} from "../CombiParameter";
import {PublicDbCalls} from "../../../services/PublicDbCalls";
import {IconNames} from "@blueprintjs/icons";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";

export class TpSicParameter extends CombiParameter {

    private sic3count: ISic3Count[] = [];
    private sic3countMap: {[index: string] : number} = {};

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getWidthClass() {
        // return "fixed-width-550";
        return undefined;
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.TpSicParameter];
    }

    protected async initialize() {
        const param = SessionStore.get(EParameters.TpSicParameter);
        // console.error(param);
        const selection= new Map<number, ISicEntry>();
        const selectionClass= new Map<number, ISicEntry>();
        const selection_range= new Map<string, ISicRange>();
        if(Array.isArray(param)){
            const [ranges, classes, sub_classes] = param;
            if(Array.isArray(sub_classes)){
                const to_remove = [];
                sub_classes.forEach((sc) => {
                   if(sc && sc.length===4){
                       to_remove.push(sc);
                   }
                });
                while(to_remove.length){
                    const a = to_remove.shift();
                    const i = sub_classes.indexOf(a);
                    if(i>=0){
                        sub_classes.splice(i, 1);
                    }
                }

            }
            // console.error(ranges, classes, sub_classes);
            if(Array.isArray(ranges)){
                SicRanges.forEach((sic_range) => {
                    const range_key = `${sic_range.from}_${sic_range.till}`;
                    if(ranges.indexOf(range_key) >= 0){
                        selection_range.set(range_key, sic_range);
                    }
                });
            }
            if(Array.isArray(classes)){
                SicClasses.forEach( (o_class) => {
                    if(classes.indexOf(o_class.code)>=0){
                        selectionClass.set(o_class.code, {rang: 1, text: o_class.text, code: o_class.code});
                    }
                });
            }
            if(Array.isArray(sub_classes)){
                Sic.forEach((sub_class)=>{
                    if(sub_classes.indexOf(sub_class.code)>=0){
                        selection.set(sub_class.code, {rang: 2, text: sub_class.text, code: sub_class.code});
                    }
                });
            }
        }

        this.sic3count = [];
        this.sic3countMap = {};

        const sic3countMap = this.sic3countMap;
        const db = new PublicDbCalls();
        this.sic3count = await db.countSic3();
        this.sic3count.forEach( (s) => {
            const code = parseInt(s.sic3, 10);
            const a = Math.trunc(code/10);
            const str_a = a < 10 ? `0${a}` : `${a}`;
            const str_code = code < 100 ? `0${code}` : `${code}`;
            if(!sic3countMap[str_a]){
                sic3countMap[str_a] = 0;
            }
            sic3countMap[str_a] += parseInt(s.count, 10);
            sic3countMap[str_code] = parseInt(s.count, 10);
        });


        return {
            selection,
            selectionClass,
            selection_range,
        };
    }
    private getSic3countMap(s: string) {
        const a = this.sic3countMap[s];
        if(!a){
            return " [0]";
        }else{
            return ` [${a}]`;
        }
    }
    private getSic3CountRange(r: ISicRange) {
        let sum = 0;
        this.sic3count.forEach( (i) =>{
            const code = Math.trunc(parseInt(i.sic3, 10)/10);
            if(code >= r.from && code <= r.till){
                sum += parseInt(i.count, 10);
            }
        });
        return ` [${sum}]`;
    }
    private getIcon(map: Map<number, any>, value: number) {
        if (map.has(value)) {
            return <Icon icon={"tick"}/>;
        } else {
            return <Icon icon={IconNames.BLANK}/>;
        }
    }
    private getIcon2(value: ISicRange) {
        if (this.state.selection_range.has(`${value.from}_${value.till}`)) {
            return <Icon icon={"tick"}/>;
        } else {
            return <Icon icon={IconNames.BLANK}/>;
        }
    }
    protected renderMenuContent(): any {
        const col_2: ISicEntry[] = [];
        const col_1: ISicEntry[] = [];
        const sic_header: number[] = [];
        this.state.selectionClass.forEach( (v) => {
            const c = Math.trunc(v.code / 100);
            sic_header.push(v.code);
            Object.keys(Sic3).forEach( (key) => {
                const cs = Math.trunc(parseInt(key, 10) / 10);
                if (c === cs) {
                    col_2.push({
                        str_code: key,
                        code: parseInt(key, 10),
                        text: `${Sic3[key]}${this.getSic3countMap(key)}`,
                        rang: 2,
                    });
                }
            });
        } );
        this.state.selection_range.forEach( (r) => {
            col_1.push({
                code: 0,
                text: r.text,
                rang: 0,
                data: r,
            });
            SicClasses.forEach( (sc) => {
                const code = Math.trunc( sc.code / 100 );
                if (code >= r.from && code <= r.till) {
                    col_1.push({
                        code: sc.code,
                        text: `${sc.text}${this.getSic3countMap(code < 10 ? `0${code}`: `${code}`)}`,
                        rang: 1,
                    });
                }
            } );
        } );
        const getCode = (r: ISicEntry)=> {
            const c= Math.trunc(r.code/100);
            if(c<10){
                return `0${c}`;
            }else{
                return `${c}`;
            }
        };
        const getRangeCode = (r)=> {
            const s = r.from<10 ? `0${r.from}` : `${r.from}`;
            const e = r.till<10 ? `0${r.till}` : `${r.till}`;
            return `${s}-${e}`;
        };
        const renderCode = (code, text)=>{
            return (<div style={{display: "flex", width: "100%"}}><span style={{fontSize: "75%", marginRight: 5}}>{code}</span><span>{text}</span></div>);
        };
        const renderCodeH = (code, text)=>{
            const s = [
                <span style={{fontSize: "75%", display: "contents"}}>{code}</span>,
                <span style={{marginLeft: 5}}>{text}</span>
            ];
            return (
                <div>{s.map((i) => i)}</div>
            );
        };

        const renderCol_1 = () => {
            if (!this.state.selection_range.size) {
                return null;
            }
            return (
                <Menu className={"padding-top"} style={{maxHeight: 750, overflowY: "auto"}}>
                    {col_1.map((r) => {
                        if (r.rang === 0) {
                            return (
                                <li className="bp3-menu-header" style={{marginRight: 0, marginTop: -2}}>
                                    <h6 className="bp3-heading" style={{alignItems: "center", display: "flex", justifyContent: "space-between", paddingRight: 0}}>
                                        {renderCodeH(getRangeCode(r.data), r.text)}
                                        <Button icon={"trash"} intent={"danger"} minimal={true} onClick={() => this.selectSicRange(r.data)} />
                                    </h6>
                                </li>
                            );
                        }
                        return (<MenuItem text={renderCode(getCode(r), r.text)} intent={ this.state.selectionClass.has(r.code) ? "primary" : "none"} labelElement={this.getIcon(this.state.selectionClass, r.code)} onClick={() => this.selectSic(r)} />);
                    })}
                </Menu>
            );
        };
        const renderCol_2 = () => {
            if (col_2 && col_2.length) {
                return (
                    <Menu className={"padding-top"} style={{maxHeight: 750, overflowY: "auto"}}>
                        {col_2.map((r) => {
                            const c = Math.trunc(r.code / 10);
                            const h_idx = sic_header.findIndex( (hc) => Math.trunc(hc / 100) === c );
                            if (h_idx >= 0) {
                                const h = this.state.selectionClass.get(sic_header[h_idx]);
                                sic_header.splice(h_idx, 1);
                                return (
                                    <>
                                        <li className="bp3-menu-header" style={{marginRight: 0, marginTop: -2}}>
                                            <h6 className="bp3-heading" style={{alignItems: "center", display: "flex", justifyContent: "space-between", paddingRight: 0}}>
                                                {renderCodeH(getCode(h), h.text)}
                                                <Button icon={"trash"} intent={"danger"} minimal={true} onClick={() => this.selectSic(h)} />
                                            </h6>
                                        </li>
                                        <MenuItem text={renderCode(r.str_code, r.text)} intent={ this.state.selection.has(r.code) ? "primary" : "none"} labelElement={this.getIcon(this.state.selection, r.code)} onClick={() => this.selectSubSic(r)} />
                                    </>
                                );
                            }
                            return (<MenuItem text={renderCode(r.str_code, r.text)} intent={ this.state.selection.has(r.code) ? "primary" : "none"} labelElement={this.getIcon(this.state.selection, r.code)} onClick={() => this.selectSubSic(r)} />);
                        })}
                    </Menu>
                );
            }
            return null;
        };
        return (
            <div>
                <div className={"sz-row"}>
                    <Menu className={"padding-top"} style={{maxHeight: 750, overflowY: "auto"}}>
                        {SicRanges.map((r) => {
                            return (<MenuItem text={renderCode(getRangeCode(r), `${r.text}${this.getSic3CountRange(r)}`)} intent={ this.state.selection_range.has(`${r.from}_${r.till}`) ? "primary" : "none"} labelElement={this.getIcon2(r)} onClick={() => this.selectSicRange(r)} />);
                        })}
                    </Menu>
                    {renderCol_1()}
                    {renderCol_2()}
                </div>
                <div className={"sz-row"}>
                    {this.renderHelpText(PARAMETER_HELP(EParameters.TpSicParameter), "fit-content")}
                </div>
            </div>
        );
    }
    private selectSicRange(r: ISicRange) {
        const key = `${r.from}_${r.till}`;
        const selection_range: Map<string, ISicRange> = this.state.selection_range;
        const selectionClass: Map<number, ISicEntry> = this.state.selectionClass;
        const selection: Map<number, ISicEntry> = this.state.selection;
        if (selection_range.has(key)) {
            selection_range.delete(key);
            const to_remove: number[] = [];
            const to_remove_2: number[] = [];
            selectionClass.forEach( (s) => {
                const code = Math.trunc(s.code / 100);
                if (code >= r.from && code <= r.till) {
                    to_remove.push(s.code);
                }
            });
            selection.forEach( (s) => {
                const code = Math.trunc(s.code / 10);
                if (code >= r.from && code <= r.till) {
                    to_remove_2.push(s.code);
                }
            });
            to_remove.forEach( (c) => {
                selectionClass.delete(c);
            } );
            to_remove_2.forEach( (c) => {
                selection.delete(c);
            });
        } else {
            selection_range.set(key, r);
        }
        this.setState({
            selectionClass,
            selection,
            selection_range,
        });
        // valueChanged();
        // super.valueChanged(selection);
    }
    private selectSic(sic: any) {
        // console.error(this.state.selectionClass);
        const code: number = sic.code;
        const text: string = sic.text;
        const selectionClass: Map<number, ISicEntry> = this.state.selectionClass;
        const selection: Map<number, ISicEntry> = this.state.selection;
        if (selectionClass.has(code)) {
            selectionClass.delete(code);
            const to_remove: number[] = [];
            selection.forEach( (v) => {
                if (Math.trunc(v.code / 10) === Math.trunc(code / 100)) {
                    to_remove.push(v.code);
                }
            } );
            to_remove.forEach( (c) => {
                selection.delete(c);
            } );
        } else {
            selectionClass.set(code, {
                code,
                text,
                rang: 1,
            });
        }

        this.setState({
            selectionClass,
            selection,
        });
        // super.valueChanged(selection);
    }
    private selectSubSic(sic: any) {
        const code: number = sic.code;
        const text: string = sic.text;
        const selection: Map<number, ISicEntry> = this.state.selection;
        if (selection.has(code)) {
            selection.delete(code);
        } else {
            selection.set(code, {
                code,
                text,
                rang: 2,
            });
        }

        this.setState({
            selection,
        });
        // console.error(this.state);
        // super.valueChanged(selection);
    }
    protected onClosePopup() {
        // console.error(this.state);
        const ranges = [];
        const classes = [];
        const sub_classes = [];
        this.state.selection_range.forEach( (sic_range) => {
            const range_key = `${sic_range.from}_${sic_range.till}`;
            ranges.push(range_key);
        });
        this.state.selectionClass.forEach( (sic) => {
            classes.push(sic.code);
        });
        this.state.selection.forEach( (sic) => {
            sub_classes.push(sic.code);
        });


        const unlimited_ranges: ISicRange[] = [];
        const unlimited_classes: ISicEntry[] = [];

        this.state.selection_range.forEach( (r) => {
            let selected_but_no_class: boolean = true;
            this.state.selectionClass.forEach( (sc) => {
                const code = Math.trunc(sc.code / 100);
                if (code >= r.from && code <= r.till) {
                    selected_but_no_class = false;
                }
                let class_selected_but_no_sub_class: boolean = true;
                this.state.selection.forEach( (sub_c) => {
                    const sub_code = Math.trunc( sub_c.code / 100 );
                    if (sub_code === code) {
                        class_selected_but_no_sub_class = false;
                    }
                } );
                if (class_selected_but_no_sub_class && !unlimited_classes.find( (i) => i.code === sc.code )) {
                    unlimited_classes.push(sc);
                }
            } );
            if (selected_but_no_class) {
                unlimited_ranges.push(r);
            }
        } );
        const select_classes: number[] = [];
        const select_sub_classes: number[] = [];
        unlimited_ranges.forEach( (r) => {
            SicClasses.forEach( (sc) => {
                const code = Math.trunc(sc.code / 100);
                if (code >= r.from && code <= r.till) {
                    select_classes.push(sc.code);
                }
            } );
        } );
        unlimited_classes.forEach( (sc) => {
            select_classes.push(sc.code);
        });
        this.state.selection.forEach( (s3) => {
            Sic.forEach((s)=>{
                if(s3.code === Math.trunc(s.code/10)){
                    select_sub_classes.push(s.code);
                }
            });
        } );
        this.setState({
            select_classes, select_sub_classes
        });
        this.fireChanges([
            {component: EParameters.TpSicParameter,value: [[ranges, classes, sub_classes]]},
            {component: EParameters.TpSicClassParameter,value: [[select_classes, select_sub_classes]]},
        ]);
    }

    protected getLabel(): any {
        const [sic_classes, sic_codes] = SessionStore.get(EParameters.TpSicClassParameter);
        return Labels.getSicLabel(sic_classes, sic_codes);
    }
}
