import * as React from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';

import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {Line} from "react-chartjs-2";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {DrawStateHook} from "../../../chart-plugin/DrawStateHook";
import * as ChartJsAnnotation from "chartjs-plugin-annotation";

import {CustomAxisLabel} from "../../../chart-plugin/CustomAxisLabel";
import {mkQuarterlyAnnotation} from "../../../helpers/Helpers";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
    ChartJsAnnotation,
    CustomAxisLabel,
);

export interface ITradeDataProps {
    label: string;
    company: ICompanyBase;
    market_data: any[];
    market_data_dates: number[];
    xAxisKey: string;
    yAxisKey: string;
}

export function TradeData(props: ITradeDataProps) {
    // const labels = (new Array(props.market_data.length)).fill("");
    const o = new SzChartOptions();
    o.setCustomAxisLabel("", props.label);
    o.setYMinMax({min: 0});

    const {annotation,labels} = mkQuarterlyAnnotation(props.market_data_dates);
    o.enablePlugin(EChartPlugins.ANNOTATION, annotation);
    // console.error(annotation);
    const data = props.market_data?.map((m, idx)=>{
        return {
            x: idx,
            y: m[props.yAxisKey],
        };
    });
    const options = {
        ...o.options,
    };
    options.animations = false;
    options.scales.x.gridLines= {
        drawTicks: false,
        display: false,
    };
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
    };
    const gfx = {
        label: props.label,
        borderColor: "rgba(19,124,189,0.75)",
        borderWidth: 1,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        stepped: true,
        data,
    };
    const chart_data = {
        labels,
        datasets: [
            gfx,
        ],
    };
    return (
        <Line data={chart_data as any} options={options} />
    );
}
