import {Button, Icon, Intent, Menu, MenuItem, Popover,} from "@blueprintjs/core";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {Sic, Sic3, SicClasses, SicRanges} from "../../const/Sic";
import {ISic3Count, ISicEntry, ISicRange} from "../../models/Sic";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {BaseFilter, IBaseFilterProperties, IBaseFilterState} from "./BaseFilter";

interface ISicFilterState extends IBaseFilterState {
    selectionClass: Map<number, ISicEntry>;
    selection: Map<number, ISicEntry>;
    selection_range: Map<string, ISicRange>;
    isOpen: boolean;
}
interface ISicFilterProperties extends IBaseFilterProperties {
    ranges?: string[];
    classes?: number[];
    sub_classes?: number[];
}

export class SicFilter extends BaseFilter<ISicFilterProperties, ISicFilterState> {

    private sic3count: ISic3Count[] = [];
    private sic3countMap: {[index: string] : number} = {};

    private readonly evtUpdate: IEventHandler;

    constructor(props: any, context: any) {
        super(props, context);
        const selection= new Map<number, ISicEntry>();
        const selectionClass= new Map<number, ISicEntry>();
        const selection_range= new Map<string, ISicRange>();
        this.state = {
            loading: true,
            isOpen: false,
            selection,
            selectionClass,
            selection_range,
        };
        this.evtUpdate = EventBus.subscribe("SicFilter::update", (data)=> this.updateFilter(data))
    }
    componentWillUnmount() {
        EventBus.unsubscribe(this.evtUpdate);
    }

    public componentDidMount(): void {
        (async () => {
            const sic3countMap = this.sic3countMap;
            const db = new PublicDbCalls();
            this.sic3count = await db.countSic3();
            this.sic3count.forEach( (s) => {
                const code = parseInt(s.sic3, 10);
                const a = Math.trunc(code/10);
                const str_a = a < 10 ? `0${a}` : `${a}`;
                const str_code = code < 100 ? `0${code}` : `${code}`;
                if(!sic3countMap[str_a]){
                    sic3countMap[str_a] = 0;
                }
                sic3countMap[str_a] += parseInt(s.count, 10);
                sic3countMap[str_code] = parseInt(s.count, 10);
            });
            this.setState({
                loading: false,
            });
        })();
    }

    protected resetFilter() {
        this.setState({
            selection: new Map<number, ISicEntry>(),
            selectionClass: new Map<number, ISicEntry>(),
            selection_range: new Map<string, ISicRange>(),
        });
    }

    protected renderContent() {
        return (
            <div>
                { this.state.loading ? this.renderLoading() : this.renderMyContent() }
            </div>
        );
    }
    protected renderMyContent(): any {
        const active = this.state.selection_range && this.state.selection_range.size ;

        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };

        let intent: Intent = active ? Intent.WARNING : Intent.NONE;
        if (this.state.isOpen) {
            intent = Intent.PRIMARY;
        }
        const testCanClose = (ev) => {
            if (!ev) {
                return;
            }
            if (this.state.isOpen && ev.type === "mousedown") {
                this.setState({isOpen: false});
            }
        };
        return(
            <Popover
                minimal={true}
                position={"bottom-left"}
                boundary={"viewport"}
                usePortal={true}
                hasBackdrop={true}
                popoverClassName={"sz-popover"}
                modifiers={popper}
                isOpen={this.state.isOpen}
                canEscapeKeyClose={true}
                captureDismiss={false}
                onClosed={ () => this.onValueChanged(undefined, undefined, undefined) }
                onInteraction={(a, b) => testCanClose(b)}
            >
                <Button
                    intent={intent} text={"SIC" + (active ? " aktiv" : "")}
                    icon={"oil-field"}
                    minimal={false}
                    onClick={() => this.setState({isOpen: !this.state.isOpen})}
                />
                <div className={"sz-row"}>
                    {this.renderSelection()}
                </div>
            </Popover>
        );
    }
    protected renderLoading() {
        return (
            <Button
                className={"bp3-skeleton"}
                icon={"office"}
                style={{minWidth: 180}}
                minimal={false} />
        );
    }

    private getIcon(map: Map<number, any>, value: number) {
        if (map.has(value)) {
            return <Icon icon={"tick"}/>;
        } else {
            return null;
        }
    }
    private getIcon2(value: ISicRange) {
        if (this.state.selection_range.has(`${value.from}_${value.till}`)) {
            return <Icon icon={"tick"}/>;
        } else {
            return null;
        }
    }

    private renderSelection() {
        const col_2: ISicEntry[] = [];
        const col_1: ISicEntry[] = [];
        const sic_header: number[] = [];
        this.state.selectionClass.forEach( (v) => {
            const c = Math.trunc(v.code / 100);
            sic_header.push(v.code);
            Object.keys(Sic3).forEach( (key) => {
                const cs = Math.trunc(parseInt(key, 10) / 10);
                if (c === cs) {
                    col_2.push({
                        str_code: key,
                        code: parseInt(key, 10),
                        text: `${Sic3[key]}${this.getSic3countMap(key)}`,
                        rang: 2,
                    });
                }
            });
        } );
        this.state.selection_range.forEach( (r) => {
            col_1.push({
                code: 0,
                text: r.text,
                rang: 0,
                data: r,
            });
            SicClasses.forEach( (sc) => {
                const code = Math.trunc( sc.code / 100 );
                if (code >= r.from && code <= r.till) {
                    col_1.push({
                        code: sc.code,
                        text: `${sc.text}${this.getSic3countMap(code < 10 ? `0${code}`: `${code}`)}`,
                        rang: 1,
                    });
                }
            } );
        } );
        const getCode = (r: ISicEntry)=> {
            const c= Math.trunc(r.code/100);
            if(c<10){
                return `0${c}`;
            }else{
                return `${c}`;
            }
        };
        const getRangeCode = (r)=> {
            const s = r.from<10 ? `0${r.from}` : `${r.from}`;
            const e = r.till<10 ? `0${r.till}` : `${r.till}`;
            return `${s}-${e}`;
        };
        const renderCode = (code, text)=>{
            return (<div style={{display: "flex", width: "100%"}}><span style={{fontSize: "75%", marginRight: 5}}>{code}</span><span>{text}</span></div>);
        };
        const renderCodeH = (code, text)=>{
            const s = [
                <span style={{fontSize: "75%", display: "contents"}}>{code}</span>,
                <span style={{marginLeft: 5}}>{text}</span>
            ];
            return (
                <div>{s.map((i) => i)}</div>
            );
        };
        const renderCol_1 = () => {
            if (!this.state.selection_range.size) {
                return null;
            }
            return (
                <Menu className={"padding-top"} style={{maxHeight: 750, overflowY: "auto"}}>
                    {col_1.map((r) => {
                        if (r.rang === 0) {
                            return (
                                <li className="bp3-menu-header" style={{marginRight: 0, marginTop: -2}}>
                                    <h6 className="bp3-heading" style={{alignItems: "center", display: "flex", justifyContent: "space-between", paddingRight: 0}}>
                                        {renderCodeH(getRangeCode(r.data), r.text)}
                                        <Button icon={"trash"} intent={"danger"} minimal={true} onClick={() => this.selectSicRange(r.data)} />
                                    </h6>
                                </li>
                            );
                        }
                        return (<MenuItem text={renderCode(getCode(r), r.text)} intent={ this.state.selectionClass.has(r.code) ? "primary" : "none"} labelElement={this.getIcon(this.state.selectionClass, r.code)} onClick={() => this.selectSic(r)} />);
                    })}
                </Menu>
            );
        };
        const renderCol_2 = () => {
            if (col_2 && col_2.length) {
                return (
                    <Menu className={"padding-top"} style={{maxHeight: 750, overflowY: "auto"}}>
                        {col_2.map((r) => {
                            const c = Math.trunc(r.code / 10);
                            const h_idx = sic_header.findIndex( (hc) => Math.trunc(hc / 100) === c );
                            if (h_idx >= 0) {
                                const h = this.state.selectionClass.get(sic_header[h_idx]);
                                sic_header.splice(h_idx, 1);
                                return (
                                    <>
                                        <li className="bp3-menu-header" style={{marginRight: 0, marginTop: -2}}>
                                            <h6 className="bp3-heading" style={{alignItems: "center", display: "flex", justifyContent: "space-between", paddingRight: 0}}>
                                                {renderCodeH(getCode(h), h.text)}
                                                <Button icon={"trash"} intent={"danger"} minimal={true} onClick={() => this.selectSic(h)} />
                                            </h6>
                                        </li>
                                        <MenuItem text={renderCode(r.str_code, r.text)} intent={ this.state.selection.has(r.code) ? "primary" : "none"} labelElement={this.getIcon(this.state.selection, r.code)} onClick={() => this.selectSubSic(r)} />
                                    </>
                                );
                            }
                            return (<MenuItem text={renderCode(r.str_code, r.text)} intent={ this.state.selection.has(r.code) ? "primary" : "none"} labelElement={this.getIcon(this.state.selection, r.code)} onClick={() => this.selectSubSic(r)} />);
                        })}
                    </Menu>
                );
            }
            return null;
        };
        return (
            <div className={"sz-col-100 sz-search-filter-list"} style={{margin: 8}}>
                <div className={"sz-row"}>
                    <Menu className={"padding-top"} style={{maxHeight: 750, overflowY: "auto"}}>
                        {SicRanges.map((r) => {
                            return (<MenuItem text={renderCode(getRangeCode(r), `${r.text}${this.getSic3CountRange(r)}`)} intent={ this.state.selection_range.has(`${r.from}_${r.till}`) ? "primary" : "none"} labelElement={this.getIcon2(r)} onClick={() => this.selectSicRange(r)} />);
                        })}
                    </Menu>
                    {renderCol_1()}
                    {renderCol_2()}
                </div>
            </div>
        );
    }

    private selectSicRange(r: ISicRange) {
        const key = `${r.from}_${r.till}`;
        const selection_range: Map<string, ISicRange> = this.state.selection_range;
        const selectionClass: Map<number, ISicEntry> = this.state.selectionClass;
        const selection: Map<number, ISicEntry> = this.state.selection;
        if (selection_range.has(key)) {
            selection_range.delete(key);
            const to_remove: number[] = [];
            const to_remove_2: number[] = [];
            selectionClass.forEach( (s) => {
                const code = Math.trunc(s.code / 100);
                if (code >= r.from && code <= r.till) {
                    to_remove.push(s.code);
                }
            });
            selection.forEach( (s) => {
                const code = Math.trunc(s.code / 10);
                if (code >= r.from && code <= r.till) {
                    to_remove_2.push(s.code);
                }
            });
            to_remove.forEach( (c) => {
                selectionClass.delete(c);
            } );
            to_remove_2.forEach( (c) => {
                selection.delete(c);
            });
        } else {
            selection_range.set(key, r);
        }
        this.setState({
            selectionClass,
            selection,
            selection_range,
        });
        // valueChanged();
        // super.valueChanged(selection);
    }
    private selectSic(sic: any) {
        console.error(this.state.selectionClass);
        const code: number = sic.code;
        const text: string = sic.text;
        const selectionClass: Map<number, ISicEntry> = this.state.selectionClass;
        const selection: Map<number, ISicEntry> = this.state.selection;
        if (selectionClass.has(code)) {
            selectionClass.delete(code);
            const to_remove: number[] = [];
            selection.forEach( (v) => {
                if (Math.trunc(v.code / 10) === Math.trunc(code / 100)) {
                    to_remove.push(v.code);
                }
            } );
            to_remove.forEach( (c) => {
                selection.delete(c);
            } );
        } else {
            selectionClass.set(code, {
                code,
                text,
                rang: 1,
            });
        }

        this.setState({
            selectionClass,
            selection,
        });
        // super.valueChanged(selection);
    }
    private selectSubSic(sic: any) {
        const code: number = sic.code;
        const text: string = sic.text;
        const selection: Map<number, ISicEntry> = this.state.selection;
        if (selection.has(code)) {
            selection.delete(code);
        } else {
            selection.set(code, {
                code,
                text,
                rang: 2,
            });
        }

        this.setState({
            selection,
        });
        console.error(this.state);
        // super.valueChanged(selection);
    }
    private onValueChanged(selection, selectionClass, selection_range) {
        const unlimited_ranges: ISicRange[] = [];
        const unlimited_classes: ISicEntry[] = [];

        if(!selection){
            selection = this.state.selection;
        }
        if(!selectionClass){
            selectionClass = this.state.selectionClass;
        }
        if(!selection_range){
            selection_range = this.state.selection_range;
        }

        selection_range.forEach( (r) => {
            let selected_but_no_class: boolean = true;
            selectionClass.forEach( (sc) => {
                const code = Math.trunc(sc.code / 100);
                if (code >= r.from && code <= r.till) {
                    selected_but_no_class = false;
                }
                let class_selected_but_no_sub_class: boolean = true;
                selection.forEach( (sub_c) => {
                    const sub_code = Math.trunc( sub_c.code / 100 );
                    if (sub_code === code) {
                        class_selected_but_no_sub_class = false;
                    }
                } );
                if (class_selected_but_no_sub_class && !unlimited_classes.find( (i) => i.code === sc.code )) {
                    unlimited_classes.push(sc);
                }
            } );
            if (selected_but_no_class) {
                unlimited_ranges.push(r);
            }
        } );
        const select_classes: number[] = [];
        const select_sub_classes: number[] = [];
        unlimited_ranges.forEach( (r) => {
            SicClasses.forEach( (sc) => {
                const code = Math.trunc(sc.code / 100);
                if (code >= r.from && code <= r.till) {
                    select_classes.push(sc.code);
                }
            } );
        } );
        unlimited_classes.forEach( (sc) => {
            select_classes.push(sc.code);
        });
        selection.forEach( (s3) => {
            Sic.forEach((s)=>{
                if(s3.code === Math.trunc(s.code/10)){
                    select_sub_classes.push(s.code);
                }
            });
        } );
        // console.error(this.state);
        // console.error(select_classes);
        super.valueChanged({
            select_classes,
            select_sub_classes,
        });
    }

    private getSic3countMap(s: string) {
        const a = this.sic3countMap[s];
        if(!a){
            return " [0]";
        }else{
            return ` [${a}]`;
        }
    }
    private getSic3CountRange(r: ISicRange) {
        let sum = 0;
        this.sic3count.forEach( (i) =>{
            const code = Math.trunc(parseInt(i.sic3, 10)/10);
            if(code >= r.from && code <= r.till){
                sum += parseInt(i.count, 10);
            }
        });
        return ` [${sum}]`;
    }

    private updateFilter(data: any) {
        console.error(data);
        const { selection, selectionClass, selection_range} = this.state;

        if(data.sic3){
            const sic2 = data.sic3 ? Math.trunc(data.sic3/10) : data.sic_class ? Math.trunc(data.sic_class/100) : undefined;
            const r_hit: ISicRange = SicRanges.find( (r) => sic2 >= r.from && sic2 <= r.till);
            const key = `${r_hit.from}_${r_hit.till}`;
            if(!selection_range.has( key)){
                selection_range.set(key, r_hit);
            }
            const class_hit = SicClasses.find( (s)=> s.code === sic2*100);
            if(!selectionClass.has(class_hit.code)){
                selectionClass.set(class_hit.code, Object.assign({rang: 1}, class_hit));
            }
            let sic3;
            const sic3Key = data.sic3 < 100 ? `0${data.sic3}` : `${data.sic3}`;
            Object.keys(Sic3).forEach( (skey)=>{
                if(sic3){
                    return;
                }
                if(skey===sic3Key){
                    sic3 = {code: data.sic3, text: Sic3[skey], rang: 2};
                }
            });
            if(sic3 && !selection.has(sic3.code)){
                selection.set(sic3.code, sic3);
            }
        }
        if(data.sic_class){
            const sic2 = Math.trunc(data.sic_class/100);
            const r_hit: ISicRange = SicRanges.find( (r) => sic2 >= r.from && sic2 <= r.till);
            const key = `${r_hit.from}_${r_hit.till}`;
            if(!selection_range.has( key)){
                selection_range.set(key, r_hit);
            }
            const class_hit = SicClasses.find( (s)=> s.code === sic2*100);
            if(!selectionClass.has(class_hit.code)){
                selectionClass.set(class_hit.code, Object.assign({rang: 1}, class_hit));
            }else{
                const rm = [];
                selection.forEach((i) => {
                    const c = Math.trunc(i.code/10);
                    if(c === sic2){
                        rm.push(i.code);
                    }
                });
                rm.forEach( (c) =>selection.delete(c));
            }
        }
        if(data.sic_range){
            const range = data.sic_range;
            const r_hit: ISicRange = SicRanges.find( (r) => range.from === r.from && range.till ===  r.till);
            const key = `${r_hit.from}_${r_hit.till}`;
            if(!selection_range.has( key)){
                selection_range.set(key, r_hit);
            }else{
                const rm = [];
                selection.forEach((i) => {
                    const c = Math.trunc(i.code/10);
                    if(c >= r_hit.from && c <= r_hit.till){
                        rm.push(i.code);
                    }
                });
                rm.forEach( (c) =>selection.delete(c));
                const rm2 = [];
                selectionClass.forEach((i) => {
                    const c = Math.trunc(i.code/100);
                    if(c >= r_hit.from && c <= r_hit.till){
                        rm2.push(i.code);
                    }
                });
                rm2.forEach( (c) =>selectionClass.delete(c));
            }
        }
        this.setState({ selection, selectionClass, selection_range});
        this.onValueChanged( selection, selectionClass, selection_range);
    }
}
