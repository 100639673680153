import {Classes, HTMLTable} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {Sectors} from "../../const/Sectors";
import {ISectorDesc} from "../../models/ISectorDesc";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

const BASE_DIR = Globals.BASE_DIR;
export class PageMethodology  extends React.Component<any, any> {
    public render() {
        const margin = 4;
        return (
            <div style={{margin: 10, marginTop: 0}}>
                <table className={"sz-table bp3-html-table bp3-html-table-condensed sz-small-padding"}>
                    <tbody>
                    <tr><td className={"sz-no-pd-lr"} colSpan={3} style={{boxShadow: "none"}}><h4 style={{marginBottom: 0, boxShadow: "0 1px 0 0 rgba(16, 22, 26, 0.15)"}}>smartZebra Methodik</h4></td></tr>
                    {this.renderEstimatesData()}
                    {this.renderDebtBeta()}
                    {this.renderMultiplier()}
                    {this.renderNullCoupon()}
                    {this.renderRawBetaAdj()}
                    {this.renderRefIndex()}
                    {this.renderSectors()}
                    {this.renderUnleveredBeta()}
                    </tbody>
                </table>
            </div>
        );
    }
    private renderPicture(name: string) {
        return (<div style={{width: "100%", display: "flex", justifyContent: "center"}}><img src={`${BASE_DIR}images/${name}`} style={{maxWidth: 650}}/></div>);
    }
    private renderRefIndex() {
        return (
            <tr><td className={"sz-no-pd-lr"} style={{whiteSpace: "nowrap"}}><strong>{_t(ETranslation.stock_index)}</strong></td>
                <td>&nbsp;</td>
                <td>
                    <p className={`${Classes.TEXT_MUTED}`}>Der <strong>Nationale Benchmark-Index</strong> wird mit folgender Priorisierung festgelegt:</p>
                    <ol>
                        <li className={`${Classes.TEXT_MUTED}`}>Leitindex der nationalen Hauptbörse, z.B. DAX 30 für die Deutsche Börse in Frankfurt bzw. von einem unabhängigen Index-Anbieter, z.B. FTSE 100 für die London Stock Exchange</li>
                        <li className={`${Classes.TEXT_MUTED}`}>Eng definierter Benchmark-Index von einem der beiden renommierten Index-Anbieters FTSE Russell oder MSCI</li>
                    </ol>
                    <p className={`${Classes.TEXT_MUTED}`}>Der <strong>Nationale Allshare-Index</strong> wird mit folgender Priorisierung festgelegt:</p>
                    <ol>
                        <li className={`${Classes.TEXT_MUTED}`}>Allshare-Index der nationalen Hauptbörse, z.B. CDAX für die Deutsche Börse in Frankfurt bzw. von einem unabhängigen Index-Anbieter, z.B. FTSE Allshare für die London Stock Exchange</li>
                        <li className={`${Classes.TEXT_MUTED}`}>Breit definierter Benchmark-Index von einem der beiden renommierten Index-Anbieters FTSE Russell oder MSCI</li>
                    </ol>

                    <p className={`${Classes.TEXT_MUTED}`}><strong>Regionaler und globaler Benchmark-Index:</strong> MSCI Benchmark Index mit 85% Abdeckung der Marktkapitalisierung der jeweiligen Region; Ausnahme: Westeuropa EUROSTOXX 50</p>

                    <p className={`${Classes.TEXT_MUTED}`}><strong>Regionaler und globaler Allshare-Index:</strong> FTSE All World Index mit 90 – 95% Abdeckung der Marktkapitalisierung der jeweiligen Region</p>

                </td></tr>
        );
    }

    private renderDebtBeta() {
        const rating: string[] = ["AAA", "AA", "A", "BBB", "BB", "B"];
        const rating_v: number[] = [0.9918, 0.9806, 0.9483, 0.8510, 0.6297, 0.4183];
        return (
            <tr><td className={"sz-no-pd-lr"} style={{whiteSpace: "nowrap"}}><strong>Debt Beta</strong></td>
                <td>&nbsp;</td>
                <td>
                    <p className={`${Classes.TEXT_MUTED}`}><strong>Grundsätzliche Vorgehensweise:</strong> Zur Ermittlung des Debt Beta gibt u.a. die drei folgenden Vorgehensweisen, die direkte Messung unter Nutzung börsennotierter Anleihen, die indirekte Ermittlung unter Verwendung beobachterbarer Kreditaufschläge und die indirekte Ermittlung mittels eines rating-basierten Ansatzes.</p>
                    <p className={`${Classes.TEXT_MUTED}`}>Um eine einheitliche und über alle Vergleichsunternehmen konsistente Ermittlung des Debt Beta sicherzustellen, wird hier der indirekte, rating-basierte Ansatz verfolgt.</p>

                    <p className={`${Classes.TEXT_MUTED}`}><strong>Typische Ratingkennzahlen:</strong> Zu Ermittlung eines Rating-Scores finden die folgenden rating-spezifischen Kennzahlen Anwendung: FFO / Debt, Debt / EBITDA, Debt / Equity, EBTIDA / Interest. Als Datengrundlage geht in die Ermittlung der ausgewählte Zeitraum /-punkt für den Verschuldungsgrad ein.</p>

                    <p className={`${Classes.TEXT_MUTED}`}><strong>Rating-Score und Kreditaufschlag:</strong> Aus den Ratingkennzahlen wird ein gewichtetes Rating bzw. Rating-Score für jedes Vergleichsunternehmen ermittelt. Die Kennzahlen FFO / Debt und Debt / EBITDA gehen dabei jeweils mit 50% bzw. 30% ein, die Kennzahlen Debt / Equity und EBTIDA / Interest mit jeweils 15% bzw. 5%.</p>
                    <p className={`${Classes.TEXT_MUTED}`}>Als Maßstab dienen dabei historische Ratings einer großen Grundgesamtheit an Unternehmen unter Berücksichtigung deren Sektorzugehörigkeit. Aus Rating-Score und Sektor wird ein Kreditaufschlag unter Nutzung der relevanten Marktdaten ermittelt.</p>

                    <p className={`${Classes.TEXT_MUTED}`}><strong>Systematische Risikoprämie und Debt Beta:</strong> Um den systematischen Risikoanteil des Kreditaufschlag zu ermitteln, werden verschiedene wissenschaftliche Studien der vergangenen Jahre genutzt, die eine empirisch basierte Aufteilung des Kreditaufschlag in systematisches und unsystematisches Risiko nach Ratingklassen untersucht haben. Im letzten Schritt wird unter Verwendung der Marktrisikoprämie das Debt Beta ermittelt.</p>
                    <table className={`sz-table sz-table-no-styles`} style={{width: "100%", marginLeft: "auto", marginRight: "auto"}}>
                        <tbody>
                        <tr>
                            {rating.map( (i) => (<td style={{boxShadow: "none", verticalAlign: "middle"}} className={"sz-padding-left-off"}><span className={`${Classes.TEXT_MUTED}`}>{i}</span></td>) )}
                        </tr>
                        <tr>
                            {rating_v.map( (i) => (<td style={{boxShadow: "none", verticalAlign: "middle"}} className={"sz-padding-left-off"}><span className={`${Classes.TEXT_MUTED}`}>{Globals.formatter_percent(i , 1)}</span></td>) )}
                        </tr>
                        </tbody>
                    </table>
                    <span className={`${Classes.TEXT_MUTED}`}>Quelle: smartZebra, Amato/Remolona (2003; Kreditaufschläge im Laufzeitband 7-10 Jahre)</span>
                </td>
            </tr>
        );
    }
    private renderMultiplier() {
        return (
            <>
                <tr><td className={"sz-no-pd-lr"} style={{whiteSpace: "nowrap"}}><strong>Multiplikatoren (Trailing)</strong></td>
                    <td>&nbsp;</td>
                    <td>
                        <p className={`${Classes.TEXT_MUTED}`}>{_t(ETranslation.trailing_multiples_description)}</p>
                        <p className={`${Classes.TEXT_MUTED}`}>
                            <strong>Beispiel A:</strong> Stichtag 31.01.2020<br />
                            <strong>Letztverfügbarer Jahresabschuss:</strong> 31.12.2018; 31.12.2019 ist zu diesem Zeitpunkt noch nicht verfügbar<br /><br />
                            <table className={`sz-table sz-table-no-styles`} style={{width: "auto", marginLeft: "auto", marginRight: "auto"}}>
                                <tbody>
                                <tr>
                                    <td rowSpan={2} style={{boxShadow: "none", verticalAlign: "middle", paddingLeft: 0}}>
                                        <span className={`${Classes.TEXT_MUTED}`}>KGV Trailing =</span>
                                    </td>
                                    <td style={{boxShadow: "none", borderBottom: "#5c7080 solid 2px", textAlign: "center"}}><span className={`${Classes.TEXT_MUTED}`}>Marktkapitalisierung per 31.01.2020</span></td>
                                </tr>
                                <tr><td style={{textAlign: "center"}}><span className={`${Classes.TEXT_MUTED}`}>Jahresüberschuss der Konzernaktionäre per 31.12.2018</span></td></tr>
                                </tbody>
                            </table>
                        </p>
                        <br />
                        <p className={`${Classes.TEXT_MUTED}`}>
                            <strong>Beispiel B:</strong> Stichtag 31.03.2020<br />
                            <strong>Letztverfügbarer Jahresabschuss:</strong> 31.12.2019; 31.12.2019 ist zu diesem Zeitpunkt verfügbar<br /><br />
                            <table className={`sz-table sz-table-no-styles`} style={{width: "auto", marginLeft: "auto", marginRight: "auto"}}>
                                <tbody>
                                <tr>
                                    <td rowSpan={2} style={{boxShadow: "none", verticalAlign: "middle", paddingLeft: 0}}>
                                        <span className={`${Classes.TEXT_MUTED}`}>KGV Trailing =</span>
                                    </td>
                                    <td style={{boxShadow: "none", borderBottom: "#5c7080 solid 2px", textAlign: "center"}}><span className={`${Classes.TEXT_MUTED}`}>Marktkapitalisierung per 31.03.2020</span></td>
                                </tr>
                                <tr><td style={{textAlign: "center"}}><span className={`${Classes.TEXT_MUTED}`}>Jahresüberschuss der Konzernaktionäre per 31.12.2019</span></td></tr>
                                </tbody>
                            </table>
                        </p>
                    </td>
                </tr>
                <tr><td className={"sz-no-pd-lr"} style={{whiteSpace: "nowrap"}}><strong>Multiplikatoren (Forward)</strong></td>
                    <td>&nbsp;</td>
                    <td>
                        <p className={`${Classes.TEXT_MUTED}`}>{_t(ETranslation.forward_multiples_description)}</p>
                        <p className={`${Classes.TEXT_MUTED}`}>
                        <strong>Beispiel A:</strong> Stichtag 31.01.2020<br />
                        <strong>Schätzung Jahresabschluss:</strong> 31.12.2019; der Jahresabschluss 31.12.2019 ist zu diesem Zeitpunkt nicht verfügbar<br /><br />
                            <table className={`sz-table sz-table-no-styles`} style={{width: "auto", marginLeft: "auto", marginRight: "auto"}}>
                                <tbody>
                                <tr>
                                    <td rowSpan={2} style={{boxShadow: "none", verticalAlign: "middle", paddingLeft: 0}}>
                                        <span className={`${Classes.TEXT_MUTED}`}>KGV Forward =</span>
                                    </td>
                                    <td style={{boxShadow: "none", borderBottom: "#5c7080 solid 2px", textAlign: "center"}}><span className={`${Classes.TEXT_MUTED}`}>Marktkapitalisierung per 31.01.2020</span></td>
                                </tr>
                                <tr><td style={{textAlign: "center"}}><span className={`${Classes.TEXT_MUTED}`}>Schätzung Jahresüberschuss der Konzernaktionäre für das Geschäftsjahr 31.12.2019</span></td></tr>
                                </tbody>
                            </table>
                        </p>
                        <br />
                        <p className={`${Classes.TEXT_MUTED}`}>
                            <strong>Beispiel B:</strong> Stichtag 31.03.2020<br />
                            <strong>Schätzung Jahresabschluss:</strong> 31.12.2020; der Jahresabschluss 31.12.2019 ist zu diesem Zeitpunkt verfügbar<br /><br />
                            <table className={`sz-table sz-table-no-styles`} style={{width: "auto", marginLeft: "auto", marginRight: "auto"}}>
                                <tbody>
                                <tr>
                                    <td rowSpan={2} style={{boxShadow: "none", verticalAlign: "middle", paddingLeft: 0}}>
                                        <span className={`${Classes.TEXT_MUTED}`}>KGV Forward =</span>
                                    </td>
                                    <td style={{boxShadow: "none", borderBottom: "#5c7080 solid 2px", textAlign: "center"}}><span className={`${Classes.TEXT_MUTED}`}>Marktkapitalisierung per 31.03.2020</span></td>
                                </tr>
                                <tr><td style={{textAlign: "center"}}><span className={`${Classes.TEXT_MUTED}`}>Schätzung Jahresüberschuss der Konzernaktionäre für das Geschäftsjahr 31.12.2020</span></td></tr>
                                </tbody>
                            </table>
                        </p>
                    </td>
                </tr>
            </>
        );
    }
    private renderSectors() {
        const renderSector = (s: ISectorDesc) => {
            return (
                <>
                    <td><strong className={Classes.TEXT_MUTED}>{s.sector}</strong></td>
                    <td>
                        {s.subSectors.map( (subSector) => <span className={Classes.TEXT_MUTED}>{subSector}<br/></span> )}
                    </td>
                </>
            );
        };
        const forSectors = () => {
            const arr = (new Array(Sectors.sectorsAndSubsectors.length)).fill(1);
            return arr.map( (j, i) => {
                return (
                    <tr>
                        {renderSector(Sectors.sectorsAndSubsectors[i])}
                    </tr>
                );
            });
        };
        return (
            <tr><td className={"sz-no-pd-lr"} style={{whiteSpace: "nowrap"}}><strong>Sektor-Klassifizierung</strong></td>
                <td>&nbsp;</td>
                <td style={{padding: 0}}>
                    <HTMLTable className={"sz-table"} condensed={true} striped={true} style={{width: "100%"}}>
                        <thead>
                        <tr>
                            <th><span className={Classes.TEXT_MUTED}>{_t(ETranslation.sector)}</span></th>
                            <th><span className={Classes.TEXT_MUTED}>{_t(ETranslation.subsector)}</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        {forSectors()}
                        </tbody>
                    </HTMLTable>
                </td>
            </tr>
        );
    }
    private renderUnleveredBeta() {
        return (
            <tr><td className={"sz-no-pd-lr"} style={{whiteSpace: "nowrap"}}><strong>{_t(ETranslation.beta_unlevered)} </strong></td>
                <td>&nbsp;</td>
                <td>
                    <p className={`${Classes.TEXT_MUTED}`}>Die Ermittlung des Unlevered Beta aus dem Raw Beta bzw. des Adjusted Beta erfolgt unter Verwendung des Verschuldungsgrads und ggf. eines Tax-Shields und eines Debt Betas mittel der nachfolgenden Formeln:</p>
                    <p className={`${Classes.TEXT_MUTED}`}>Bei unsicherem Tax-Shield und ohne Anwendung eines Debt Betas wird das Unlevered Beta unter Verwendung des marktwertbasierten Verschuldungsgrads folgendermaßen ermittelt.</p>
                    {this.renderPicture("formeln/formel_1.png")}

                    <p className={`${Classes.TEXT_MUTED}`}>Bei sicherem Tax-Shield und ohne Anwendung eines Debt Betas wird das Unlevered Beta unter Verwendung des marktwertbasierten Verschuldungsgrads folgendermaßen ermittelt.</p>
                    {this.renderPicture("formeln/formel_2.png")}

                    <p className={`${Classes.TEXT_MUTED}`}>Bei unsicherem Tax-Shield und unter Anwendung eines Debt Betas wird das Unlevered Beta unter Verwendung des marktwertbasierten Verschuldungsgrads folgendermaßen ermittelt.</p>
                    {this.renderPicture("formeln/formel_3.png")}

                    <p className={`${Classes.TEXT_MUTED}`}>Bei sicherem Tax-Shield und unter Anwendung eines Debt Betas wird das Unlevered Beta unter Verwendung des marktwertbasierten Verschuldungsgrads folgendermaßen ermittelt.</p>
                    {this.renderPicture("formeln/formel_4.png")}
                </td>
            </tr>
        );
    }
    private renderNullCoupon() {
        return (
            <tr><td className={"sz-no-pd-lr"} style={{whiteSpace: "nowrap"}}><strong>Nullkupon-Renditen</strong></td>
                <td>&nbsp;</td>
                <td>
                    <p className={`${Classes.TEXT_MUTED}`}>Mit Ausnahme des Emittenten Bundesrepublik Deutschland – hier stellt die Bundesbank bereits Null-Kupon-Renditen zur Verfügung – werden die ermittelten Null-Kupon-Renditen mittels des Bootstrapping-Ansatzes errechnet. Als Grundlage dienen die zum Bewertungsstichtag verfügbaren Umlaufrendite des emittierenden Staates. Soweit nicht das gesamte Laufzeitband von 1 bis 30 Jahren verfügbar ist, wird zunächst zwischen den Laufzeiten linear interpoliert und im Anschluss daran das Bootstrapping durchgeführt.</p>
                </td>
            </tr>
        );
    }
    private renderRawBetaAdj() {
        return (
            <tr><td className={"sz-no-pd-lr"} style={{whiteSpace: "nowrap"}}><strong>Raw Beta adj.</strong></td>
                <td>&nbsp;</td>
                <td>
                    <p className={`${Classes.TEXT_MUTED}`}>Die Ermittlung des Raw Beta adj. erfolgt mittels des vereinfachten Blume-Adjustment.</p>
                    {this.renderPicture("formeln/formel_5.png")}
                </td>
            </tr>
        );
    }

    private renderEstimatesData() {
        return (
            <tr><td className={"sz-no-pd-lr"} style={{whiteSpace: "nowrap"}}><strong>Analystenschätzungen / Estimates Data</strong></td>
                <td>&nbsp;</td>
                <td>
                    <p className={`${Classes.TEXT_MUTED}`}>Alle Forward-Multiples basieren auf Analystenschätzungen. Diese entsprechen den Factset Consensus-Schätzungen zum jeweiligen Bewertungsstichtag. Das Factset Panel umfasst ca. 800 Teilnehmen - u.a. Broker, Investmentbanken und Research Firmen – aus 55 Ländern und deckt 90 Länder, 19.000 Unternehmen und eine 20 jährige Historie ab.</p>
                </td>
            </tr>
        );
    }
}
