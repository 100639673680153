import {EContentType} from "../const/EContentType";
import {EDataSourceMode} from "../const/EDataSourceMode";
import {EExcelExportType} from "../const/EExcelExportType";
import {EPackageTypes} from "../const/EPackageTypes";
import {INopeAction} from "../models/INopeAction";
import {IModuleAction} from "../models/IModuleAction";

export class ModuleBase {
    public name: string;
    public component: string;
    public dataSource: string;
    public dataSourceId?: string;
    public dataSourceMode: EDataSourceMode;
    public dataDefaults: any;
    public options: any;
    public contentType: EContentType = EContentType.Premium;
    public minPackage: EPackageTypes = EPackageTypes.World;
    public excelExport: boolean;
    public proFlag: boolean;
    public isHidden: boolean;
    public alignRight: boolean;
    public nopeActions: INopeAction[];
    public exportType: EExcelExportType;
    public module_actions: IModuleAction[] = [];
    constructor(name: string) {
        this.name = name;
    }
    public setProFlag(proFlag: boolean): ModuleBase {
        this.proFlag = proFlag;
        return this;
    }
    public setAccess(contentType: EContentType, minPackage: EPackageTypes, nope?: INopeAction[]): ModuleBase {
        this.minPackage = minPackage;
        this.contentType = contentType;
        this.nopeActions = nope;
        return this;
    }
    public setExcelExport(excelExport: boolean, exportType: EExcelExportType = EExcelExportType.simple): ModuleBase {
        this.excelExport = excelExport;
        this.exportType = exportType;
        return this;
    }
    public mkHidden(): ModuleBase {
        this.isHidden = true;
        return this;
    }
    public setAlignRight(): ModuleBase {
        this.alignRight = true;
        return this;
    }
    public addModuleAction(action: IModuleAction){
        this.module_actions.push(action);
        return this;
    }
}
