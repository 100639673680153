export class ArraySearch {
    public static getRating = (val: number, arr: any[]) => {
        const N = arr.length;
        if (!N) { return NaN; }
        const firstEl = arr[0];
        const lastEl = arr[N - 1];
        if (firstEl > lastEl) {
            return ArraySearch.getRating1(val, arr);
        } else {
            return ArraySearch.getRating2(val, arr);
        }
    }
    // abwärts
    public static getRating1 = (val: number, arr: any[]) => {
        const N = arr.length;
        if (!N) { return NaN; }
        if (val > arr[0]) { return 0; }
        for (let i = 1; i < N; i++) {
            if (val > arr[i]) {
                return (i) - (val - arr[i]) / (arr[i - 1] - arr[i]);
            }
        }
        // schlechte ergebnis
        return N - 1;
    }
    // aufwärts
    public static getRating2 = (val: number, arr: any[]) => {
        const N = arr.length;
        if (!N) { return NaN; }
        if (val < arr[0]) { return 0; }
        for (let i = 1; i < N; i++) {
            if (val < arr[i]) {
                return (i - 1) + (val - arr[i - 1]) / (arr[i] - arr[i - 1]);
            }
        }
        // schlechte ergebnis
        return N - 1;
    }
}
