import * as React from "react";
import {useState} from "react";

const KEY_CODES = {
    ArrowRight: "ArrowRight",
    ArrowLeft: "ArrowLeft",
    ArrowUp: "ArrowUp",
    ArrowDown: "ArrowDown",
    Tab: "Tab",
    Enter: "Enter",
    Escape: "Escape",
    Delete: "Delete",
};


export interface ISzStringInput {
    current_value: any;
    onValueChanged?(new_value: string);
}
export function SzStringInput(props: ISzStringInput) {
    const [value, setValue] = useState(props.current_value);
    const changeValue = (new_value)=>{
        setValue(new_value);
        if(props.onValueChanged){
            props.onValueChanged(new_value);
        }
    };
    return (
        <input value={value ? value : ""}
               onChange={(e)=>changeValue(e.target.value)}
               style={{width: "100%"}}
        />
    );
}
