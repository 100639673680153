import {Classes, HTMLTable, Icon, Spinner} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {_t_region} from "../../const/Regions";
import {Sectors} from "../../const/Sectors";
import {SessionStore} from "../../const/SessionStore";
import {Sic, Sic3, SicClasses, SicRanges} from "../../const/Sic";
import {first, getResult} from "../../helpers/Helpers";
import {ECompanyRelation} from "../../models/ECompanyRelation";
import {EParameters} from "../../models/EParameters";
import {
    asICompanyDetailsXs,
    ICompany,
    ICompanyDetailsXs,
    INaics,
    IRelatedCompany,
    IRelatedFinancials
} from "../../models/ICompanyDetails";
import {ICompanySearchResult} from "../../models/ICompanySearchResult";
import {ICountry} from "../../models/ICountry";
import {INearByDetails} from "../../models/INearByDetails";
import {EventBus} from "../../services/EventBus";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {CompanyDetailPopup} from "./CompanyDetailPopup";
import {fromDateDecimal, fromStr} from "../../tools/DateTools";
import {DateTime} from "luxon";
import {getIPO, getLastDate} from "./helper/CompanyDates";
import {ETranslation} from "../../const/ETranslation";
import {_t, country_map, naics_column} from "../../tools/Translator";
import Highlighter = require("react-highlight-words");

type RelatedFinancialsMap = { [index:string] : IRelatedFinancials};
type CompanyDetailsMap = {[index: number] : ICompanyDetailsXs};
enum ESortType {
    FF_MKT_VAL= 1,
    FF_SALES,
    FF_EBITDA,
    FF_EMP_NUM,
    SIC,
    NAICS,
    REGION,
    SUPL_SALES,
    CUST_SALES,
}

const getLink = (www: string) => {
    if(!www){
        return Globals.hyphen;
    }
    // noinspection HttpUrlsUsage
    const txt = www.replace("http://","").replace("https://","");
    return (
        <a href={www} target={"_blank"}>{txt}</a>
    );
};
const sec_type = (s) => {
    const m = {
        "0":_t(ETranslation.common_share),
        "1":_t(ETranslation.preferred_share),
        "2":_t(ETranslation.preferred_convertible_share),
    };
    const r = m[`${s}`];
    if(r === undefined){
        return Globals.hyphen;
    }else{
        return r;
    }
};
const freq_code = (s) => {
    if(!s){
        return _t(ETranslation.quarterly);
    }
    const m = {
        "1":_t(ETranslation.quarterly),
        "2":_t(ETranslation.semi_annually),
        "4":_t(ETranslation.annually),
    };
    const r = m[`${s}`];
    if(r === undefined){
        return Globals.hyphen;
    }else{
        return r;
    }
};
const gaap = (s) => {
    if(!s){
        return "Local-GAAP";
    }
    const m = {
        "Local Standards with EU Guidelines":"Local-GAAP",
        "IFRS":"IFRS",
        "23":"IFRS",
        "3":"US-GAAP",
        "US Standards (GAAP)":"US-GAAP",
        "GAAP":"Local-GAAP",
        "International Standards with some EU Guidelines":"IFRS",
        "International Standards":"IFRS",
        "4095":"Local-GAAP",
        "Local Standards with EU and IASC Guidelines":"Local-GAAP",
        "Local Standards with some IASC Guidelines":"Local-GAAP",
        "1":"Local-GAAP",
        "Local Standards":"Local-GAAP",
    };
    const r = m[`${s}`];
    if(r === undefined){
        return Globals.hyphen;
    }else{
        return r;
    }
};

export interface ICompanyDetailsProps {
    company_id: string;
    company?: ICompanySearchResult;
    keyWords: string;
    onChangeFilter?(filterName: string, data: any);
}

interface ICompanyDetailsState {
    is_loading: boolean;
    current_year: number;
    company_id: string;
    keyWords: string;
    company?: ICompany;
    company_details_map?: CompanyDetailsMap;
    naics?: INaics[];
    competitor?: IRelatedCompany[];
    supplier?: IRelatedCompany[];
    customer?: IRelatedCompany[];
    related_financials?: RelatedFinancialsMap;
    order_by: any;
    order_dir: any;
    companyListed?: ICompanySearchResult;
    ident?: IRelatedCompany;
}

export class CompanyDetails extends React.Component<ICompanyDetailsProps, ICompanyDetailsState> {

    private longListMap;

    constructor(props: ICompanyDetailsProps, context: any) {
        super(props, context);
        // const now = moment().format("DD.MM.YYYY");
        const current_year = parseInt(DateTime.now().toFormat("yyyy"), 10) - 1;
        this.state = {
            is_loading: true,
            company_id: this.props.company_id,
            keyWords: this.props.keyWords,
            order_by: {},
            order_dir: {},
            current_year,
        };
    }

    public componentDidUpdate(prevProps: Readonly<ICompanyDetailsProps>) {
        if(this.props.company_id !== prevProps.company_id){
            this.setState({
                is_loading: true,
                company_id: this.props.company_id,
                companyListed: this.props.company,
            });
        }
        if(this.props.keyWords !== prevProps.keyWords){
            this.setState({
                keyWords: this.props.keyWords,
            });
        }
    }

    public render() {
        const backGroundStyle: CSSProperties = {
            width: "100%",
            height: "100%",
            overflowY: "auto",
            backgroundColor: "#ffffff",
        };
        return (<div style={backGroundStyle} className={"sz-relative"} >{this.state.is_loading ? this.renderLoading() : this.renderContent()}</div>);
    }
    private renderLoading() {
        (async ()=>{
            await this.loadData();
        })();
        return(
            <div style={{position: "relative", height: "100%", width: "100%", display: "flex", justifyContent: "center"}}>
                <Spinner size={250} />
            </div>
        );
        /*<SzSvgLoader />*/
    }
    private async loadData() {
        try{
            const min_fiscal_year = fromStr(SessionStore.get(EParameters.DateParameter))
                .startOf("month")
                .minus({month: 13})
                .year
            ;
            let current_year = this.state.current_year;
            const company_id = this.state.company_id;
            const db: PublicDbCalls = new PublicDbCalls();

            const r_company_details = await db.getCompanyDetailsXs(parseInt(company_id, 10), current_year);
            const r_company = await db.selectCompanies([company_id]);
            const company: ICompany = getResult(r_company, [])[0];
            const arr_details: any[] = getResult(r_company_details, []);

            const naics: INaics[] = await db.getNaics(company.ff_naics_code);
            const company_details_map: CompanyDetailsMap = {};
            const company_details: CompanyDetailsMap = {};

            let last_financial_year = 0;

            arr_details.forEach( (cd) =>{
                const d = asICompanyDetailsXs(cd);
                const y = Math.trunc(d.date_decimal/10000);
                if(y > last_financial_year){
                    last_financial_year = y;
                }
                company_details[y] = d;
            });

            if(last_financial_year >= min_fiscal_year){
                current_year = last_financial_year;
            }
            const years = [current_year, current_year-1, current_year-2, current_year-3, current_year-4, current_year-5, current_year-6, current_year-7, current_year-8, current_year-9, current_year-10];
            years.forEach((year)=>{
                const d = company_details[year];
                if(d){
                    company_details_map[year] = d;
                }else{
                    company_details_map[year] = {} as ICompanyDetailsXs;
                }
            });

            const ident = await db.getRelatedCompany(company_id);
            const competitor = await db.getRelatedCompanies(company_id, 15, ECompanyRelation.COMP);
            const supplier = await db.getRelatedCompanies(company_id, 15, ECompanyRelation.SUPL);
            const customer = await db.getRelatedCompanies(company_id, 15, ECompanyRelation.CUST);
            const all = competitor.concat(supplier, customer);
            const rel_fin_ids = all.map((i)=> i.id);
            rel_fin_ids.push(company.id);
            const rel_fin = await db.getRelatedFinancials(rel_fin_ids);
            const related_financials: RelatedFinancialsMap = {};
            rel_fin.forEach((i)=>related_financials[i.company_id] = i);

            if(competitor.length){
                competitor.unshift(ident);
            }

            // console.error(current_year);
            // console.error(arr_details);
            // console.error(company_details_map);

            this.setState({
                is_loading: false,
                company,
                company_details_map,
                naics,
                competitor,
                supplier,
                customer,
                ident,
                related_financials,
                current_year,
                order_by: {},
                order_dir: {},
            });

            return;
        }catch(ex){
            console.error(ex);
        }
        this.setState({
            is_loading: false,
        });
    }
    private renderContent() {
        return (
            <div>
                {this.renderDescription()}
            </div>
        );
    }

    private renderDescription() {

        this.longListMap = {};
        Globals.longList.forEach( (c) => this.longListMap[c.company_id] = true );

        const company: ICompany = this.state.company;
        if(!company){
            return undefined;
        }
        // const company_detail: ICompanyDetails2 = first(this.state.company_details, {} as ICompanyDetails2);
        const header: CSSProperties = {
            boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)",
            display: "flex",
            justifyContent: "center",
            maxHeight: 40,
            height: 40,
            paddingLeft: 10,
            backgroundColor: "#F0F0F0",
        };

        const country: ICountry = Globals.country_map[company.country_id];
        const companyDescription = company.description ? company.description : "";
        const related_financials = this.state.related_financials;
        const fin = related_financials[company.id] ? related_financials[company.id] : {} as IRelatedFinancials;
        const hqKey = "headquartered ";
        const idxHeadQuarter = companyDescription.lastIndexOf(hqKey);
        let hq = "";
        if(idxHeadQuarter > 0){
            hq = companyDescription.substring(idxHeadQuarter + hqKey.length);
            if(hq.startsWith("in ")){
                hq = hq.substring(3);
            }
            hq = (hq.split(",")[0])
        }
        const getKeyWords = (k) => {
            return ("" + (k ? k : "")).split(" ").filter( (w) => w.length > 2);
        };
        return (
            <div style={{paddingLeft: 1, paddingRight: 1}}>
                <div style={header}>
                    <img src={`${Globals.BASE_DIR}images/flags/${country?.iso_2.toLowerCase()}.jpg`} style={{height: 30, alignSelf: "center"}}/>
                    <div style={{display: "flex", width: "100%", paddingRight: 10, marginLeft: 10, justifyContent: "space-between", alignItems: "center"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span style={{fontSize: "110%", fontWeight: 600}}>{company.co_name}</span>
                            <div style={{width: 30, marginLeft: 10}}>{this.getStar(this.state.ident, 30, 24)}</div>
                        </div>
                        <div style={{fontSize: "70%"}} className={"bp3-text-muted"}>{company.security_id} - {company.id}</div>
                    </div>
                </div>
                <div style={{width: "100%", paddingTop: 5, paddingLeft: 10, paddingBottom: 20}} className={"bp3-text-muted"}>
                    <Highlighter
                        highlightClassName="sz-highlighter"
                        searchWords={getKeyWords(this.state.keyWords)}
                        autoEscape={true}
                        textToHighlight={companyDescription} />
                </div>
                <div className={"sz-row"}>
                    <div style={{width: "40%", paddingRight: 1}}>
                        <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%"}}>
                            <thead>
                            <tr style={{backgroundColor: "#F0F0F0"}}>
                                <th colSpan={2}>{_t(ETranslation.base_data)}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr><td>{_t(ETranslation.country)}</td><td style={{textAlign: "right"}}>{country_map[country.iso_3].name}</td></tr>
                            <tr><td>{_t(ETranslation.headquarter)}</td><td style={{textAlign: "right"}}>{hq}</td></tr>
                            <tr><td>{_t(ETranslation["geo-region"])}</td><td style={{textAlign: "right"}}>{_t_region(country.region_de)}</td></tr>
                            <tr style={{boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}} ><td>{_t(ETranslation.website)}</td><td style={{textAlign: "right"}}>{getLink(company.home_page)}</td></tr>
                            <tr><td>{_t(ETranslation.stock_exchange)}</td><td style={{textAlign: "right"}}>{company.p_exchange}</td></tr>
                            <tr><td>{_t(ETranslation.ipo)}</td><td style={{textAlign: "right"}}>{getIPO(company.p_first_date, "LL/dd/yyyy")}</td></tr>
                            <tr><td>{_t(ETranslation.delisting)}</td><td style={{textAlign: "right"}}>{getLastDate(company.p_last_date, "yyyyLLdd")}</td></tr>
                            <tr><td>{_t(ETranslation.sharetype)}</td><td style={{textAlign: "right"}}>{sec_type(company.ff_share_type_secs)}</td></tr>
                            <tr style={{boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}} ><td>ISIN</td><td style={{textAlign: "right"}}>{company.isin}</td></tr>
                            <tr><td>GAAP</td><td style={{textAlign: "right"}}>{gaap(company.accounting_standard)}</td></tr>
                            <tr><td>{_t(ETranslation.reporting)}</td><td style={{textAlign: "right"}}>{freq_code(company.ff_freq_code)}</td></tr>
                            <tr><td>{_t(ETranslation.reporting_currency)}</td><td style={{textAlign: "right"}}>{fin.report_currency_iso}</td></tr>
                            <tr><td>{_t(ETranslation.financial_year)}</td><td style={{textAlign: "right"}}>{fromDateDecimal(fin.dd_annual_reported).toFormat("dd.LL.")}</td></tr>
                            </tbody>
                        </HTMLTable>
                    </div>
                    <div style={{width: "60%", paddingLeft: 1}}>
                        <div style={{width: "100%", marginBottom: 16}}>
                            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%"}}>
                                <thead>
                                <tr style={{backgroundColor: "#F0F0F0"}}>
                                    <th colSpan={2}>Factset</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr><td>{_t(ETranslation.sector)}</td><td style={{textAlign: "right"}}>
                                    <a
                                       className={Classes.POPOVER_DISMISS}
                                       onClick={(evt) => {evt.stopPropagation(); this.updateFilter("SectorFilter", {sector: company.sector_id, subSector: undefined}); return false;}}>{Sectors.sectors_map[company.sector_id]}</a>
                                    </td></tr>
                                <tr><td>{_t(ETranslation.subsector)}</td><td style={{textAlign: "right"}}>
                                    <a
                                       className={Classes.POPOVER_DISMISS}
                                       onClick={(evt) => { evt.stopPropagation(); this.updateFilter("SectorFilter", {sector: company.sector_id, subSector: company.industry_id}); return false;}}>{Sectors.sectors_map[company.industry_id]}</a>
                                </td></tr>
                                </tbody>
                            </HTMLTable>
                        </div>
                        <div style={{width: "100%", marginBottom: 16}}>
                            {this.renderSic(company)}
                        </div>
                        <div style={{width: "100%", marginBottom: 16}}>
                            {this.renderNaics(company)}
                        </div>
                    </div>
                </div>
                <div className={"sz-row"} style={{marginTop: 21}}>
                    <div className={"sz-col-100"}>
                        {this.renderCompetitors(company)}
                        <div style={{fontSize: "60%", width: "100%", textAlign: "left", paddingLeft: 11}} className={"bp3-text-muted"}>{_t(ETranslation.status_reporting)}</div>
                    </div>
                </div>
                <div className={"sz-row"} style={{marginTop: 21}}>
                    <div className={"sz-col-50"} style={{paddingRight: 1}}>
                        {this.renderSupplier(company)}
                        <div style={{fontSize: "60%", width: "100%", textAlign: "left", paddingLeft: 11}} className={"bp3-text-muted"}>{_t(ETranslation.status_reporting)}</div>
                    </div>
                    <div className={"sz-col-50"} style={{paddingLeft: 1}}>
                        {this.renderCustomer(company)}
                        <div style={{fontSize: "60%", width: "100%", textAlign: "left", paddingLeft: 11}} className={"bp3-text-muted"}>{_t(ETranslation.status_reporting)}</div>
                    </div>
                </div>
                <div className={"sz-row"} style={{marginTop: 21}}>
                    <div className={"sz-col-100"}>
                        {this.renderFinanceTable(company)}
                    </div>
                </div>
            </div>
        );
    }

    private renderSic(company: ICompany) {
        const sic_code = parseInt(company.ff_sic_code, 10);
        const sic = Sic.find((i) => i.code === sic_code);
        const sic_class = SicClasses.find((i) => Math.trunc(i.code/100) === Math.trunc(sic_code/100));
        const sic_range = SicRanges.find((i) => Math.trunc(sic_code/100) >= i.from && Math.trunc(sic_code/100)<= i.till);
        const a = sic_range ? sic_range.text : Globals.hyphen;
        const b = sic_class ? sic_class.text : Globals.hyphen;
        const c = sic ? sic.text : Globals.hyphen;

        const sic3code = Math.trunc(sic_code/10);
        const sic3 = Sic3[""+sic3code] ? Sic3[""+sic3code] : Globals.hyphen;


        return (
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%"}}>
                <thead>
                    <tr style={{backgroundColor: "#F0F0F0"}}>
                        <th colSpan={2}>SIC – Standard Industrial Classification</th>
                    </tr>
                </thead>
                <tbody>
                <tr><td>{sic_range ? `${sic_range.from}-${sic_range.till}` : Globals.hyphen}</td><td style={{textAlign: "right"}}>
                    <a
                       className={Classes.POPOVER_DISMISS}
                       onClick={(evt) => { evt.stopPropagation(); this.updateFilter("SicFilter", {type: "range", sic_range}); return false;}}>{a}</a>
                </td></tr>
                <tr><td>{sic_class ? `${sic_class.code}`.slice(0, -2) : Globals.hyphen}</td><td style={{textAlign: "right"}}>
                    <a
                       className={Classes.POPOVER_DISMISS}
                       onClick={(evt) => { evt.stopPropagation(); this.updateFilter("SicFilter", {type: "class", sic_class}); return false;}}>{b}</a>
                    </td></tr>
                <tr><td>{sic ? sic3code : Globals.hyphen}</td><td style={{textAlign: "right"}}>
                    <a
                       className={Classes.POPOVER_DISMISS}
                       onClick={(evt) => { evt.stopPropagation(); this.updateFilter("SicFilter", {type: "sic3", sic3code}); return false;}}>{sic3}</a></td></tr>
                <tr><td>{sic ? sic.code : Globals.hyphen}</td><td style={{textAlign: "right"}}>{c}</td></tr>
                </tbody>
            </HTMLTable>
        );
    }

    private renderNaics(company: ICompany) {
        const naics:INaics[] = this.state.naics;
        if(!Array.isArray(naics)){
            return undefined;
        }
        let sector = "";
        let sub_sector = "";
        let branch_group = "";
        let branch = "";
        let us_branch = "";

        const f = naics_column();
        naics.forEach((i) => {
            if(i.code === `${i.sector}`){
                sector = i[f];
            }
            if(i.code === `${i.sub_sector}`){
                sub_sector = i[f];
            }
            if(i.code === `${i.branch_group}`){
                branch_group = i[f];
            }
            if(i.code === `${i.branch}`){
                branch = i[f];
            }
            if(i.code === `${company.ff_naics_code}`){
                us_branch = i[f];
            }
        });
        const a_nasic = first<INaics>(naics, {} as INaics);
        let items = [
            {code: a_nasic.sector, text: sector},
            {code: a_nasic.sub_sector, text: sub_sector},
            {code: a_nasic.branch_group, text: branch_group},
            {code: a_nasic.branch, text: branch},
            /*{code: company.ff_naics_code, text: us_branch},*/
        ];
        if(company.ff_naics_code === "0"){
            items = [
                {code: Globals.hyphen, text: Globals.hyphen},
                {code: Globals.hyphen, text: Globals.hyphen},
                {code: Globals.hyphen, text: Globals.hyphen},
            ];
        }
        return (
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%"}}>
                <thead>
                <tr style={{backgroundColor: "#F0F0F0"}}>
                    <th colSpan={2}>NAICS – North American Industry Classification System</th>
                </tr>
                </thead>
                <tbody>
                {items.map((i) => i.text ? (<tr><td>{i.code}</td><td style={{textAlign: "right"}}>{i.text}</td></tr>) : undefined)}
                </tbody>
            </HTMLTable>
        );
    }
    private renderSorty(type: ECompanyRelation, new_order: ESortType, caption: string, justifyContent = "flex-end") {
        const current_order_by = this.state.order_by[type];
        const current_order_dir = this.state.order_dir[type];
        const order_by = this.state.order_by;
        const dir = this.state.order_dir;
        const sort = () => {
            if(current_order_by && current_order_by !== new_order){
                order_by[type] = new_order;
                dir[type] = 1;
                return this.setState({
                    order_by,
                    order_dir: dir,
                });
            }

            let order_dir;
            if(current_order_dir === undefined){
                order_dir = 1;
            }
            if(current_order_dir === 1){
                order_dir = -1;
            }
            if(order_dir === undefined){
                order_by[type] = undefined;
                dir[type] = undefined;
                return this.setState({
                    order_by,
                    order_dir: dir,
                });
            }

            order_by[type] = new_order;
            dir[type] = order_dir;
            this.setState({
                order_by,
                order_dir: dir,
            });
        }
        const renderNotSorted = () => {
            if(current_order_by !== new_order){
                return (
                    <div style={{display: "flex", flexFlow: "column", alignItems: "center", height: "100%", width: 12}}>
                        <Icon icon={IconNames.CARET_UP} iconSize={10} />
                        <Icon icon={IconNames.CARET_DOWN} iconSize={10} />
                    </div>
                );
            }else{
                return undefined;
            }
        };
        const renderAsc = () => {
            if(current_order_by === new_order && current_order_dir === 1){
                return (
                    <div style={{display: "flex", flexFlow: "column", height: "100%"}}>
                        <Icon icon={IconNames.CARET_UP} iconSize={12} />
                    </div>
                );
            }else{
                return undefined;
            }
        };
        const renderDesc = () => {
            if(current_order_by === new_order && current_order_dir === -1){
                return (
                    <div style={{display: "flex", flexFlow: "column", justifyContent: "flex-end", height: "100%"}}>
                        <Icon icon={IconNames.CARET_DOWN} iconSize={12} />
                    </div>
                );
            }else{
                return undefined;
            }
        };
        return (
            <div style={{display: "flex", alignItems: "end", justifyContent, height: 20, width: "100%", cursor: "pointer", userSelect: "none"}} onClick={()=> sort()}>
                <span style={{paddingRight: 3, alignSelf: "center"}}>{caption}</span>
                {renderNotSorted()}
                {renderAsc()}
                {renderDesc()}
            </div>
        );
    }
    private renderCompetitors(company: ICompany) {
        const order_by = this.state.order_by[ECompanyRelation.COMP];
        const order_dir = this.state.order_dir[ECompanyRelation.COMP];
        const related_financials = this.state.related_financials;
        const deCollator = new Intl.Collator("de");
        const competitors = [].concat(this.state.competitor).sort((a: IRelatedCompany,b: IRelatedCompany) => {
            const fin_a = related_financials[a.id];
            const fin_b = related_financials[b.id];

            const _c = (f_a, f_b) => {
                return (f_a/a.from_euro_to_) - (f_b/b.from_euro_to_);
            };

            if(order_by === ESortType.FF_MKT_VAL){
                return order_dir * _c(fin_a.ff_mkt_val, fin_b.ff_mkt_val);
            }
            if(order_by === ESortType.FF_SALES){
                return order_dir * _c(fin_a.ff_sales, fin_b.ff_sales);
            }
            if(order_by === ESortType.FF_EBITDA){
                return order_dir * _c(fin_a.ff_ebitda, fin_b.ff_ebitda);
            }
            if(order_by === ESortType.FF_EMP_NUM){
                return order_dir * (fin_a.ff_emp_num - fin_b.ff_emp_num);
            }
            if(order_by === ESortType.SIC){
                const na = parseInt(a.ff_sic_code, 10);
                const nb = parseInt(b.ff_sic_code, 10);
                return order_dir * (na - nb);
            }
            if(order_by === ESortType.NAICS){
                const na = parseInt(a.ff_naics_code, 10);
                const nb = parseInt(b.ff_naics_code, 10);
                return order_dir * (na - nb);
            }
            if(order_by === ESortType.REGION){
                const country_a: ICountry = Globals.country_map[a.country_id];
                const country_b: ICountry = Globals.country_map[b.country_id];
                return order_dir * deCollator.compare(country_a.region_de.toLowerCase(), country_b.region_de.toLowerCase());
            }
            return 0;
        });
        const renderCompetitor = (c: IRelatedCompany)=> {
            const country: ICountry = Globals.country_map[c.country_id];
            const fin = related_financials[c.id] ? related_financials[c.id] : {} as IRelatedFinancials;
            const selected = c.rang === "0" ? true : false;
            return (
                <tr className={`sz-row-hover ${selected? "sz-row-selected-alt" : ""}`} onClick={()=> this.openPopup(c, ECompanyRelation.COMP)}>
                    <td style={{paddingTop: 3, paddingBottom: 3}}>
                        <CompanyDetailPopup company={c} relation={ECompanyRelation.COMP} flag={`${Globals.BASE_DIR}images/flags/${country?.iso_2.toLowerCase()}.jpg`}/>
                    </td>
                    <td style={{width: 20, alignItems: "center", paddingTop: 3, paddingBottom: 3, paddingLeft: 0, paddingRight: 0, color: "rgb(102, 127, 133)"}}>
                        {this.getStar(c)}
                    </td>
                    <td style={{paddingTop: 3, paddingBottom: 3, paddingRight: 0, paddingLeft: 0}}>{country?.region_de}</td>
                    <td style={{textAlign: "right", paddingTop: 3, paddingBottom: 3, width: 75, paddingLeft: 3, paddingRight: 3}}>{Globals.formatter(fin.ff_mkt_val/c.from_euro_to_, 0, 0, true)}</td>
                    <td style={{textAlign: "right", paddingTop: 3, paddingBottom: 3, width: 75, paddingLeft: 3, paddingRight: 3}}>{Globals.formatter(fin.ff_sales/c.from_euro_to_, 0, 0, true)}</td>
                    <td style={{textAlign: "right", paddingTop: 3, paddingBottom: 3, width: 75, paddingLeft: 3, paddingRight: 3}}>{Globals.formatter(fin.ff_ebitda/c.from_euro_to_, 0, 0, true)}</td>
                    <td style={{textAlign: "right", paddingTop: 3, paddingBottom: 3, width: 75, paddingLeft: 3, paddingRight: 3}}>{Globals.formatter(fin.ff_emp_num, 0,0, true)}</td>
                    <td style={{textAlign: "right", width: 75, paddingTop: 3, paddingBottom: 3, paddingLeft: 3, paddingRight: 3}}>{c.ff_sic_code}</td>
                    <td style={{textAlign: "right", width: 85, paddingTop: 3, paddingBottom: 3, paddingLeft: 3, paddingRight: 3}}>{c.ff_naics_code}</td>
                </tr>
            );
        };
        return (
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%", fontSize: "85%"}}>
                <thead>
                <tr style={{backgroundColor: "#F0F0F0",boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}>
                    <th colSpan={9} style={{fontSize: "14px"}}>{_t(ETranslation.peer_group)}</th>
                </tr>
                <tr>
                    <th style={{fontWeight: "normal", paddingTop: 3, paddingBottom: 3}}>Name</th>
                    <th style={{paddingTop: 3, paddingBottom: 3, paddingLeft: 0, paddingRight: 0}}>&nbsp;</th>
                    <th style={{fontWeight: "normal", paddingTop: 3, paddingBottom: 3, paddingRight: 0, paddingLeft: 0}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.REGION, _t(ETranslation["geo-region"]), "flex-start")}</th>
                    <th style={{fontWeight: "normal", paddingTop: 2, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.FF_MKT_VAL, _t(ETranslation.market_cap))}</th>
                    <th style={{fontWeight: "normal", paddingTop: 2, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.FF_SALES, _t(ETranslation.revenues))}</th>
                    <th style={{fontWeight: "normal", paddingTop: 2, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.FF_EBITDA, "EBITDA")}</th>
                    <th style={{fontWeight: "normal", paddingTop: 2, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.FF_EMP_NUM, _t(ETranslation.employees))}</th>
                    <th style={{fontWeight: "normal", textAlign: "right", paddingTop: 3, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.SIC, "SIC-Code")}</th>
                    <th style={{fontWeight: "normal", textAlign: "right", paddingTop: 3, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.NAICS, "NAICS-Code")}</th>
                </tr>
                </thead>
                <tbody>
                {competitors.map((i) => renderCompetitor(i))}
                </tbody>
            </HTMLTable>
        );
    }
    private renderSupplier(company: ICompany) {
        return this.renderSuplCust(_t(ETranslation.suppliers), company, this.state.supplier, ECompanyRelation.SUPL);
    }
    private renderCustomer(company: ICompany) {
        return this.renderSuplCust(_t(ETranslation.customers), company, this.state.customer, ECompanyRelation.CUST);
    }
    private renderSuplCust(title: string, company: ICompany, items: IRelatedCompany[], type: ECompanyRelation) {
        const order_by = this.state.order_by[type];
        const order_dir = this.state.order_dir[type];
        const related_financials = this.state.related_financials;

        const sorted = [].concat(items).sort((a: IRelatedCompany,b: IRelatedCompany) => {
            const fin_a = related_financials[a.id];
            const fin_b = related_financials[b.id];

            const _c = (f_a, f_b) => {
                return (f_a/a.from_euro_to_) - (f_b/b.from_euro_to_);
            };

            if(order_by === ESortType.SUPL_SALES){
                return order_dir * _c(fin_a.ff_sales, fin_b.ff_sales);
            }
            if(order_by === ESortType.CUST_SALES){
                return order_dir * _c(fin_a.ff_sales, fin_b.ff_sales);
            }
            return 0;
        });


        const renderItem = (c: IRelatedCompany) =>{
            const country: ICountry = Globals.country_map[c.country_id];
            const fin = related_financials[c.id] ? related_financials[c.id] : {} as IRelatedFinancials;
            const selected = c.rang === "0" ? true : false;
            return (
                <tr className={`sz-row-hover ${selected? "sz-row-selected-alt" : ""}`} onClick={()=> this.openPopup(c, type)}>
                    <td style={{paddingTop: 3, paddingBottom: 3}}>
                        <CompanyDetailPopup relation={type} company={c} flag={`${Globals.BASE_DIR}images/flags/${country?.iso_2.toLowerCase()}.jpg`}/>
                    </td>
                    <td style={{width: 20, alignItems: "center", paddingTop: 3, paddingBottom: 3, paddingLeft: 0, paddingRight: 0, color: "rgb(102, 127, 133)"}}>
                        {this.getStar(c)}
                    </td>
                    <td style={{textAlign: "right", paddingTop: 3, paddingBottom: 3, width: 75, paddingLeft: 3, paddingRight: 3}}>{Globals.formatter(fin.ff_sales/c.from_euro_to_, 0, 0, true)}</td>
                </tr>
            );
        };
        return (
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%", fontSize: "85%"}}>
                <thead>
                <tr style={{backgroundColor: "#F0F0F0",boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}>
                    <th colSpan={3} style={{fontSize: "14px"}}>{title}</th>
                </tr>
                <tr>
                    <th style={{fontWeight: "normal", paddingTop: 3, paddingBottom: 3}}>Name</th>
                    <th style={{paddingTop: 3, paddingBottom: 3, paddingLeft: 0, paddingRight: 0}}>&nbsp;</th>
                    <th style={{fontWeight: "normal", textAlign: "right", paddingTop: 2, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(type, type === ECompanyRelation.CUST ? ESortType.CUST_SALES : ESortType.SUPL_SALES, "Umsatz")}</th>
                </tr>
                </thead>
                <tbody>
                {sorted.map((i) => renderItem(i))}
                </tbody>
            </HTMLTable>
        );
    }

    private renderFinanceTable(company: ICompany) {
        const related_financials = this.state.related_financials;
        const fin = related_financials[company.id] ? related_financials[company.id] : {} as IRelatedFinancials;
        const current_year = this.state.current_year;
        const company_details_map = this.state.company_details_map;
        const years = [current_year, current_year-1, current_year-2, current_year-3, current_year-4, current_year-5, current_year-6, current_year-7, current_year-8, current_year-9, current_year-10].reverse();
        const formatter = (v, digits?) => {
            if (v === undefined) {
                return "—";
            }
            if (isNaN(v)) {
                return "—";
            }
            if (!isFinite(v)) {
                return "—";
            }
            const fm = (new Intl.NumberFormat("de-de", {
                maximumFractionDigits: digits ? digits : 0,
                minimumFractionDigits: digits ? digits : 0,
            })).format;
            return fm(v);
        };
        // hover-col
        const sel_year = parseInt(fromStr(SessionStore.get(EParameters.DateParameter)).toFormat("yyyy"), 10);
        const getField = (name: string) => {
            return years.map((year, idx) => {
                if (idx === 0) {
                    return null;
                }
                const r = company_details_map[year];
                const classNames: string[] = ["money"];
                if(sel_year === year){
                    classNames.push("hover-col");
                }
                return (<td className={classNames.join(" ")} style={{minWidth: 65, paddingTop: 3, paddingBottom: 3}}>{formatter(r[name])}</td>);
            });
        }
        const getGrowth = (name)=>{
            return years.map((year, idx) => {
                if (idx === 0) {
                    return null;
                }
                const r = company_details_map[year];
                const r_1 = company_details_map[year - 1];
                const classNames: string[] = ["money"];
                if(sel_year === year){
                    classNames.push("hover-col");
                }

                if (!r_1) {
                    return (<td style={{paddingTop: 3, paddingBottom: 3}} className={classNames.join(" ")}>—</td>);
                }
                return (<td className={classNames.join(" ")} style={{paddingTop: 3, paddingBottom: 3}}>{formatter((r[name] / r_1[name] - 1) * 100, 1)}</td>);
            });
        }
        const getMargin = (dividend, divisor)=>{
            return years.map((year, idx) => {
                if (idx === 0) {
                    return null;
                }
                const r = company_details_map[year];
                const classNames: string[] = ["money"];
                if(sel_year === year){
                    classNames.push("hover-col");
                }
                return (<td  className={classNames.join(" ")} style={{paddingTop: 3, paddingBottom: 3}}>{formatter((r[dividend] / r[divisor]) * 100, 1)}</td>);
            });
        }
        const emptyRow = () => {
            return (
                <tr style={{lineHeight: "1px"}}>
                    <td style={{padding: 0, boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)", fontSize: 1}}></td>
                    {years.map((r, idx, data) => {
                        if (idx === 0) {
                            return null;
                        }
                        const classNames: string[] = ["money"];
                        if(sel_year === r){
                            classNames.push("hover-col");
                        }
                        return (<td  className={classNames.join(" ")} style={{padding: 0, boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)", fontSize: 1}}></td>);
                    })}
                </tr>
            );
        };
        return (
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%", fontSize: "85%", marginBottom: 16}}>
                <thead>
                <tr style={{backgroundColor: "#F0F0F0",boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}>
                    <th colSpan={11} style={{fontSize: "14px"}}>{_t(ETranslation.financials)}</th>
                </tr>
                <tr>
                    <th style={{fontWeight: "normal", paddingTop: 3, paddingBottom: 3}}>Mio. {fin.report_currency_iso} | {fromDateDecimal(fin.dd_annual_reported).toFormat("dd.LL.")}</th>
                    {years.map((r, idx) => {
                        if (idx === 0) {
                            return null;
                        }
                        return (<th className={"money"} style={{minWidth: 65, paddingTop: 3, paddingBottom: 3}}>{r}</th>);
                    })}
                </tr>
                </thead>
                <tbody>
                    <tr><td style={{paddingTop: 3, paddingBottom: 3}}>{_t(ETranslation.revenues)}</td>{getField("ff_sales")}</tr>
                    <tr><td style={{paddingTop: 3, paddingBottom: 3}}>EBITDA</td>{getField("ff_ebitda")}</tr>
                    <tr style={{boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}><td style={{paddingTop: 3, paddingBottom: 3}}>EBIT</td>{getField("ff_ebit")}</tr>
                    <tr><td style={{paddingTop: 3, paddingBottom: 3}}>{_t(ETranslation.revenue_growth)} (%)</td>{getGrowth("ff_sales")}</tr>
                    <tr><td style={{paddingTop: 3, paddingBottom: 3}}>{_t(ETranslation.ebitda_margin)} (%)</td>{getMargin("ff_ebitda", "ff_sales")}</tr>
                    <tr style={{boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}><td style={{paddingTop: 3, paddingBottom: 3}}>{_t(ETranslation.ebit_margin)} (%)</td>{getMargin("ff_ebit", "ff_sales")}</tr>
                    <tr><td style={{paddingTop: 3, paddingBottom: 3}}>{_t(ETranslation.equity)}</td>{getField("ff_eq_tot")}</tr>
                    <tr style={{boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}><td style={{paddingTop: 3, paddingBottom: 3}}>{_t(ETranslation.net_financial_debt)}</td>{getField("netto_debt")}</tr>
                    <tr><td style={{paddingTop: 3, paddingBottom: 3}}>{_t(ETranslation.equity_ratio)} (%)</td>{getMargin("ff_eq_tot", "ff_assets")}</tr>
                    <tr><td style={{paddingTop: 3, paddingBottom: 3}}>{_t(ETranslation.gearing)} (%)</td>{getMargin("netto_debt", "ff_eq_tot")}</tr>
                    <tr style={{boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}><td style={{paddingTop: 3, paddingBottom: 3}}>Return on Equity (%)</td>{getMargin("ff_net_inc", "ff_shldrs_eq")}</tr>
                    <tr><td style={{paddingTop: 3, paddingBottom: 3}}>{_t(ETranslation.market_capitalization)}</td>{getField("ff_mkt_val")}</tr>
                </tbody>
            </HTMLTable>
        );
    }
    private updateFilter(filterName: string, data: any) {
        this.props?.onChangeFilter(filterName, data);
    }

    private getStar(c: IRelatedCompany, boxHeight= 17, iconSize= 12) {
        const is_longList = this.longListMap[c.id];
        const addLonglist = (evt: any) =>{
            evt.stopPropagation();
            this.handleLonglist("AddLongList", c);
        };
        const delLonglist = (evt: any) =>{
            evt.stopPropagation();
            this.handleLonglist("DelLongList", c);
        };
        if(is_longList){
            return (
                <div style={{width: "100%", height: boxHeight, display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Icon icon={IconNames.STAR} color={"rgb(19, 124, 189)"} iconSize={iconSize} htmlTitle={"von der Longlist entfernen"} onClick={(evt)=> delLonglist(evt)}/>
                </div>
            );
        }else{
            return (
                <div style={{width: "100%", height: boxHeight, display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Icon icon={IconNames.STAR_EMPTY} iconSize={iconSize} htmlTitle={"zur Longlist hinzufügen"} onClick={(evt)=> addLonglist(evt)}/>
                </div>
            );
        }
    }
    private handleLonglist(command, c: IRelatedCompany){
        const db = new PublicDbCalls();
        (async () => {
            const result = await db.getCompanyDetails2(parseInt(c.id, 10));
            const d: any[] = result.result ? result.result : [];
            if (d && d.length > 0) {
                const item = d[0];

                const fin: IRelatedFinancials = this.state.related_financials[c.id];
                const isLongList = Globals.longList.find( (i) => i.company_id === "" + c.id ) ? 1 : 0;
                const details: INearByDetails = {
                    isLongList,
                    company_id: "" + item[0],
                    company_name: item[1],
                    description: item[2],
                    country_iso_2: item[4],
                    sector_name_de: item[5],
                    sector_code: "" + item[6],
                    industry_name_de: item[7],
                    industry_code: "" + item[8],
                    company_home_page: item[9],
                    country_region_de: item[10],
                    country_name_de: item[11],
                    country_iso_3: "",
                    country_ticker: "",
                    full_count: 0,
                    ff_mkt_val: fin.ff_mkt_val/c.from_euro_to_,
                };
                this.updateFilter(command, details);
                Globals.nearByDetails["" + c.id] = details;
            }
        })();
    }

    private openPopup(c: IRelatedCompany, relation: ECompanyRelation) {
        EventBus.emit("CompanyDetailPopup::open", {c, relation});
    }
}
