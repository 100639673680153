import {ArraySearch} from "../../helpers/ArraySearch";

export class DebtBetaTool2 {
    public static getRatingRang(value: number, specRatingKeyRations: any) {
        // const specRatingKeyRations = ratingKeyRatios.find((r) => r.metric === "DEBT_EBITDA");
        if (!specRatingKeyRations) {
            // return {creditspread: null, debtBeta: null, rang: null, systematicRiskPremium: null};
            return {rang: null};
        }
        const specRatingKeyRationsArr = [
            specRatingKeyRations.aaa,
            specRatingKeyRations.aa1,
            specRatingKeyRations.aa2,
            specRatingKeyRations.aa3,
            specRatingKeyRations.a1,
            specRatingKeyRations.a2,
            specRatingKeyRations.a3,
            specRatingKeyRations.baa1,
            specRatingKeyRations.baa2,
            specRatingKeyRations.baa3,
            specRatingKeyRations.ba1,
            specRatingKeyRations.ba2,
            specRatingKeyRations.ba3,
            specRatingKeyRations.b1,
            specRatingKeyRations.b2,
            specRatingKeyRations.b3,
            specRatingKeyRations.caa1,
            specRatingKeyRations.caa2,
            specRatingKeyRations.caa3,
            specRatingKeyRations.ca,
            specRatingKeyRations.c,
        ];

        const rangPos: number = ArraySearch.getRating(value, specRatingKeyRationsArr);
        const rang = rangPos + 1;
        return {rang};
    }
}
