import {Classes, Dialog, IDialogProps, InputGroup, Switch} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export interface IGetNameDialogProps {
    isOpen: boolean;
    onClose?();
    onFinished?(name: string, isPublic: boolean);
}

interface IGetNameDialogState {
    name: string;
    isPublic: boolean;
}

export class GetNameDialog extends React.Component < IGetNameDialogProps, IGetNameDialogState > {

    constructor(props: IGetNameDialogProps, context: any) {
        super(props, context);
        this.state = {
            name: "",
            isPublic: false,
        };
    }
    public render() {
        const props: IDialogProps = {
            isOpen: this.props.isOpen,
            title: _t(ETranslation.longlist_save),
            isCloseButtonShown: false,
        };
        const isOkay = () => {
            const name = ("" + this.state.name).trim();
            return this.state.name && name.length >= 3;
        };
        const isKnown = () => {
            return Globals.primeSectorOptions && Array.isArray(Globals.primeSectorOptions) && Globals.primeSectorOptions.find( (s) => s.label === this.state.name && s.data.state !== 2 && !s.data.isPublic);
        };
        const isPublic = () => {
            return Globals.primeSectorOptions && Array.isArray(Globals.primeSectorOptions) && Globals.primeSectorOptions.find( (s) => s.label === this.state.name && s.data.isPublic);
        };
        const isShared = () => {
            return Globals.primeSectorOptions && Array.isArray(Globals.primeSectorOptions) && Globals.primeSectorOptions.find( (s) => s.label === this.state.name && s.data.state === 2);
        };
        const addText = () => {
            if (isKnown()) {
                return ", ist bereits vergeben.";
            }
            if (isPublic()) {
                return ", eine öffentliche Liste.";
            }
            if (isShared()) {
                return ", eine geteilte Liste.";
            }
            return null;
        };
        const getIsPublicSwitch = () => {
            if (!Globals.isAdmin) {
                return null;
            }
            return <div style={{display: "inline-block", marginLeft: 12}} ><Switch checked={this.state.isPublic} label={_t(ETranslation.public_list)} onChange={ () => this.setState({isPublic: !this.state.isPublic})}/></div>;
        };
        return (
            <Dialog {...props}>
                <div className={Classes.DIALOG_BODY}>
                    <p>{_t(ETranslation.name_longlist)}{addText()}</p>
                    <div style={{display: "inline-block", width: "calc(100% - 170px)"}} >
                        <InputGroup
                            onChange={(event) => this.changeListName(event.target.value)}
                            value={this.state.name}
                        />
                    </div>
                    {getIsPublicSwitch()}
                    <br />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <button type="button" className={`bp3-button`} onClick={() => this.close() }>{_t(ETranslation.cancel)}</button>
                        <button type="button" className="bp3-button bp3-intent-primary" onClick={() => this.done() } disabled={!isOkay()}>{isKnown() ? _t(ETranslation.overwrite) : _t(ETranslation.save)}</button>
                    </div>
                </div>
            </Dialog>
        );
    }
    private changeListName(name: string) {
        this.setState({
            name,
        });
    }
    private close() {
        if (this.props.onClose) {
            this.props.onClose();
            this.setState({
                name: "",
                isPublic: false,
            });
        }
    }
    private done() {
        if (this.props.onFinished) {
            this.props.onFinished(this.state.name, this.state.isPublic);
            this.setState({
                name: "",
                isPublic: false,
            });
        }
    }
}
