import * as React from "react";
import {useState} from "react";

import {Button, Icon} from "@blueprintjs/core";
import {useNavigate, useParams} from "react-router-dom";
import {FrontendCalls} from "../../services/FrontendCalls";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";
import {setNewPasswordError} from "./AppToaster";

export function NewPasswordSetter(){
    const navigate = useNavigate();
    const params = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [bluredPassword, setBluredPassword] = useState(false);
    const [bluredCofirm, setBluredConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const pwdState = ()=>{
        return (
            <Icon
                size={20}
                icon={showPassword ? "unlock" : "lock"}
                onClick={()=>setShowPassword(!showPassword)}
            />
        );
    };
    const send = (e)=> {
        e.preventDefault();
        e.stopPropagation();
        if(isLoading){
            return;
        }
        setIsLoading(true);
        (async ()=>{
            try{
                const fc = new FrontendCalls();
                const res = await fc.set_new_password(params.requestId, password);
                if(res.error){
                    setNewPasswordError();
                }else{
                    navigate("/");
                }
            }catch (e) {
                console.error(e);
            }
            setIsLoading(false);
        })();
    };
    const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
    const validPassword = regex.test(password);
    return (
        <div className="login-container">
            <form className="form" id={"loginform"} method={"post"}  onSubmit={(e)=> {send(e);}}>
                <div className="form-content-wrapper">
                    <div className="title-container"><h1 className="title">{_t(ETranslation.new_pass_setup)}</h1></div>
                    <div className="password-container">
                        <label className="password-label" htmlFor="current-password">{_t(ETranslation.password)}</label>
                        <div className="password-input-wrapper">
                            <input
                                className="password-input"
                                autoComplete="current-password"
                                id="current-password"
                                name="current-password"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e)=> {
                                    setPassword(e.target.value);
                                }}
                                required={true}
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$"
                                onBlur={(e)=> setBluredPassword(true)}
                                onFocus={(e)=>setBluredPassword(false)}
                            />
                            <div className="key-icon">
                                <Icon  icon="key" size={20}  />
                            </div>
                            <div className="lock-icon">
                                {pwdState()}
                            </div>
                        </div>
                        {bluredPassword && !validPassword && <div style={{color:"red"}}>
                            {_t(ETranslation.pass_valid_err)}
                        </div>}
                    </div>
                    <div className="password-container">
                        <label className="password-label" htmlFor="password2">{_t(ETranslation.confirm_pass)}</label>
                        <div className="password-input-wrapper">
                            <input
                                className="password-input"
                                id="password2"
                                name="new-password"
                                type={showPassword2 ? "text" : "password"}
                                value={password2}
                                onChange={(e)=> setPassword2(e.target.value) }
                                required={true}
                                onBlur={(e)=> setBluredConfirm(true)}
                                onFocus={(e)=>setBluredConfirm(false)}
                            />
                            <div className="key-icon">
                                <Icon  icon="key" size={20}  />
                            </div>
                            <div className="lock-icon">
                                <Icon
                                    size={20}
                                    icon={showPassword2 ? "unlock" : "lock"}
                                    onClick={()=>setShowPassword2(!showPassword2)}
                                />
                            </div>
                        </div>

                    </div>
                    {bluredCofirm && password2 !== password && <div style={{color:"red"}}>{_t(ETranslation.pass_dosent_match)}</div>}
                    <div className="login-btn-container">
                        <Button intent={"primary"} loading={isLoading} className="login-btn send-btn" type={"submit"} disabled={ password!== password2 || !validPassword ? true  : false }>{_t(ETranslation.confirm)}</Button>
                    </div>
                </div>
            </form>
        </div>
    );
}
