import {Colors, Intent} from "@blueprintjs/core";

export interface IErrorType {
    intent;
    color;
}

export const ErrorTypes = {
    warning: {intent: Intent.WARNING, color: Colors.ORANGE2},
    error: {intent: Intent.DANGER, color: Colors.RED2},
    disabled: {intent: undefined, color: "rgba(16, 22, 26, 0.15)"}
};
