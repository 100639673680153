import {Classes, HTMLTable, Icon, NonIdealState} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {deadline, fromDateDecimal} from "../../tools/DateTools";
import {ELineType, ISzSvgProperties, SzSvg} from "../widgets/SzSvg";
import {ISzSvgBarCellProperties} from "../widgets/SzSvgBarCell";
import {SzSvgCellChart} from "../widgets/SzSvgCellChart";
import {BaseModule} from "./BaseModule";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";

const BASE_DIR = Globals.BASE_DIR;
interface ISectorInfo {
    sector: string;
    minValue_0?: number;
    maxValue_0?: number;
    minValue_1?: number;
    maxValue_1?: number;
    maxDelta?: number;
    minDelta?: number;
    avg?: number;
}
interface ISectorInfoTable {
    sector: string;
    value: any[];
}
const latest_date = 20230301;
const dates = [20180301, 20180901, 20190301, 20190901, 20200301, 20200901, 20210301, 20210901, 20220301, 20220901, 20230301];
const data_series = [
    ["Beratende Dienstleistung", 4, 5.8, 4.1, 5.8, 4.1, 5.9, 4.3, 5.9, 4.1, 5.7, 4.1, 5.7, 4.2, 5.7, 4.3, 5.9, 4.4, 5.9, 4.4, 5.8, 4.3,5.8],
    ["Software", 4.7, 7.1, 4.6, 6.7, 4.6, 6.7, 4.6, 6.6, 4.7, 6.9, 4.8, 7.1, 4.9, 7.3, 5.0, 7.3, 5.2, 7.5, 5.2, 7.6, 5.1, 7.5],
    ["Telekommunikation", 4.7, 6.6, 4.7, 6.6, 4.7, 6.6, 4.7, 6.6, 4.7, 6.6, 4.6, 6.6, 4.6, 6.7, 4.8, 6.8, 4.9, 6.8, 4.7, 6.8, 4.7, 6.7],
    ["Medien", 4.4, 6, 4.4, 6.1, 4.5, 6.1, 4.6, 6.2, 4.4, 6.1, 4.4, 6.1, 4.4, 6.1, 4.3, 6.0, 4.5, 6.1, 4.5, 6.3, 4.4, 6.3],
    ["Handel und E-Commerce", 4.5, 6.4, 4.7, 6.6, 4.6, 6.5, 4.6, 6.5, 4.6, 6.6, 4.5, 6.6, 4.7, 6.8, 4.8, 6.7, 4.9, 6.8, 4.9, 6.7, 4.8, 6.7],
    ["Transport, Logistik und Touristik", 3.9, 5.6, 4, 5.7, 4, 5.7, 4, 5.8, 3.9, 5.7, 3.9, 5.6, 3.9, 5.7, 4.2, 5.7, 4.2, 5.8, 4.2, 5.9, 4.1, 5.8],
    ["Elektrotechnik", 4.6, 6.4, 4.5, 6.3, 4.5, 6.3, 4.7, 6.5, 4.6, 6.5, 4.6, 6.5, 4.7, 6.6, 4.8, 6.6, 4.9, 6.6, 5.0, 6.8, 4.9, 6.7],
    ["Fahrzeugbau und -zubehör", 4.2, 6.1, 4.3, 6.3, 4.2, 6.1, 4.4, 6.2, 4.4, 6.1, 4.3, 6.1, 4.3, 6.0, 4.3, 6.1, 4.4, 5.9, 4.4, 6.0, 4.4, 6.0],
    ["Maschinen- und Anlagenbau", 4.3, 6.6, 4.7, 6.8, 4.6, 6.6, 4.8, 6.7, 4.7, 6.7, 4.7, 6.7, 4.6, 6.6, 4.8, 6.7, 4.7, 6.6, 4.7, 6.7, 4.7, 6.7],
    ["Chemie, Kunststoffe Papier", 4.9, 7.1, 4.8, 7.1, 4.8, 7, 4.8, 7.1, 4.9, 7.2, 4.9, 7.2, 4.9, 7.3, 4.9, 7.2, 5.0, 7.3, 5.0, 7.3, 5.0, 7.3],
    ["Pharma, Biotechnik und Medizintechnik", 5.3, 7.7, 5.3, 7.7, 5.3, 7.7, 5.4, 7.8, 5.4, 7.8, 5.4, 7.9, 5.4, 7.9, 5.4, 8.0, 5.6, 8.1, 5.7, 8.3, 5.7, 8.1],
    ["Textil- und Bekleidung", 3.7, 5.3, 3.7, 5.5, 3.6, 5.4, 3.8, 5.5, 3.7, 5.5, 3.7, 5.4, 3.7, 5.3, 3.8, 5.4, 3.7, 5.4, 3.8, 5.4, 3.7, 5.3],
    ["Nahrungs- und Genussmittel", 4.3, 6.3, 4.4, 6.5, 4.4, 6.5, 4.5, 6.5, 4.5, 6.5, 4.5, 6.4, 4.6, 6.4, 4.6, 6.4, 4.7, 6.4, 4.7, 6.4, 4.6, 6.4],
    ["Versorgungswirtschaft", 4.4, 6.2, 4.5, 6.5, 4.5, 6.5, 4.5, 6.5, 4.5, 6.5, 4.5, 6.5, 4.4, 6.5, 4.5, 6.4, 4.6, 6.4, 4.6, 6.5, 4.6, 6.4],
    ["Umwelttechnik", 4.4, 6.5, 4.5, 6.7, 4.5, 6.7, 4.6, 6.7, 4.6, 6.6, 4.6, 6.7, 4.7, 6.6, 4.7, 6.7, 4.9, 6.8, 5.0, 7.1, 5.0, 7.1],
    ["Bau und Handwerk", 3.6, 5.6, 3.7, 5.7, 3.8, 5.8, 3.9, 6.1, 3.9, 5.8, 3.9, 5.9, 4.0, 6.0, 4.3, 6.2, 4.4, 6.2, 4.5, 6.3, 4.5, 6.2],
];
const avg_series = ["Durchschnitt über alle Branchen", 4.4, 6.3, 4.4, 6.4, 4.4, 6.4, 4.5, 6.4, 4.5, 6.4, 4.5, 6.4, 4.5, 6.5, 4.6, 6.5, 4.7, 6.5, 4.7, 6.6, 4.7, 6.6];

const overAll: ISectorInfo = {sector: "Mittelwert", minValue_1: 4.7, maxValue_1: 6.6};
const avgOverAll = 5.6;
export class MultiplicatorsConcess extends BaseModule<any> {

    private selected_sectors: Map<string, boolean> = new Map<string, boolean>();
    private date_idx: number = -1;
    private current_sector_info: ISectorInfo;

    constructor(props: any, context: any) {
        super(props, context);

        const selected_sectors: Map<string, boolean> = this.selected_sectors;
        const idx = Math.trunc(data_series.length / 2);
        selected_sectors.set("" + data_series[idx][0], true);
        // data.forEach( (i) =>  selected_sectors.set(i.sector, true));
        this.state = {
            selected_sectors,
        };
    }
    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected renderContent() {
       return (
            <div>
                <div className={"sz-row"} style={{marginBottom: 48}}>
                    <img src={`${BASE_DIR}images/logo_concess_4c.svg`} style={{height: 96}} />
                    <div className={"sz-col-80"} style={{marginLeft: 24}}>
                        <p>Die con|cess-Partner ermitteln regelmäßig aktuelle Multiplikatoren auf Basis der von ihnen betreuten Unternehmensverkäufe im Klein- und Mittelstand (KMU) mit Verkaufspreisen von etwa 1 bis 20 Millionen Euro. Aufgrund 20 jähriger Erfahrung und einer Vielzahl von Transaktionen, die con|cess durchgeführt hat, steht eine weit zurückreichende Datenhistorie zur Verfügung.</p>
                        <p>Seit 1999 stehen die con|cess M+A-Partner als Spezialisten mit vielfältigen Branchenkenntnissen für Unternehmensnachfolge Verkäufern und Kaufinteressenten beratend und vermittelnd zur Seite. In Deutschland, Österreich und der Schweiz umfasst das Netzwerk 17 erfahrene Berater und ihre Teams mit detaillierten Kenntnissen des jeweiligen regionalen Marktes für die Transaktionsbegleitung. Unter den Top 3 Unternehmens- und Käuferbörsen hat con|cess ständig rund 300 Verkaufsmandate und über 2.500 geprüfte Käufer im Portfolio.</p>
                    </div>
                </div>
                {this.renderData()}
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    private renderNoData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="KMU Multiplikatoren liegen erst ab März 2018 vor."
        />);
    }
    private renderData() {
        const date = deadline(1).asDateDecimal(); // parseInt(moment(this.getParameterValue(EParameters.DateParameter), "DD.MM.YYYY").format("YYYYMMDD"), 10);
        this.date_idx = -1;
        dates.forEach( (e, i) => {
            if (date > e) {
                this.date_idx = i;
            }
        } );
        const date_idx = this.date_idx;

        if (date_idx === -1) {
            return this.renderNoData();
        }

        const selected_sectors: Map<string, boolean> = this.selected_sectors;
        let all_selected = true;
        selected_sectors.forEach( (s) => all_selected = all_selected && s );
        let sum_min = 0;
        let sum_max = 0;
        const sector_time_series_map: Map<string, ISectorInfoTable> = new Map<string, ISectorInfoTable>();
        const time_series_items = dates.length;
        const mkStats = (i: ISectorInfo) => {
            i.avg = (i.maxValue_1 + i.minValue_1) / 2;
            const v = ((i.maxValue_0 + i.minValue_0) / 2) - i.avg;
            i.minDelta = v;
        };
        const column_cc = 1 + 2 * date_idx;
        const column_pre = 1 + column_cc - 2;
        const data: ISectorInfo[] = [];
        data_series.forEach( (d: any[]) => {
            const i: ISectorInfo = {
                sector: d[0],
                minValue_0: d[column_pre],
                maxValue_0: d[column_pre + 1 ],
                minValue_1: d[column_cc],
                maxValue_1: d[column_cc + 1 ],
            };
            sum_min += i.minValue_0;
            sum_max += i.maxValue_0;

            mkStats(i);
            data.push(i);

            if (!sector_time_series_map.has(i.sector)) {
                const sector_data: ISectorInfoTable = {
                    sector: i.sector,
                    value: [],
                };
                for (let j = 0; j < dates.length; j++) {
                    const col = 1 + 2 * j;
                    const a_min = d[col];
                    const a_max = d[col + 1];

                    sector_data.value.push({ x: j, y: (a_min + a_max) / 2 });
                }
                sector_time_series_map.set(i.sector, sector_data);
            }

        } );
        const avg_time_series: any[] = [];
        for (let i = 0; i < dates.length; i++) {
            const col = 1 + 2 * i;
            const a_min: any = avg_series[col];
            const a_max: any = avg_series[col + 1];

            avg_time_series.push({ x: i, y: (a_min + a_max) / 2 });
        }

        overAll.minValue_0 = parseFloat( "" + avg_series[column_pre]);
        overAll.maxValue_0 = parseFloat( "" + avg_series[column_pre + 1]);
        overAll.minValue_1 =  parseFloat( "" + avg_series[column_cc]);
        overAll.maxValue_1 =  parseFloat( "" + avg_series[column_cc + 1]);
        mkStats(overAll);

        const borderRight: CSSProperties = {borderRight: "4px solid rgb(255, 255, 255)"};

        let max_val = Number.MIN_SAFE_INTEGER;
        let min_val = Number.MAX_SAFE_INTEGER;

        data_series.forEach( (d: any[]) => {
            const minValue = d[column_cc];
            const maxValue = d[column_cc + 1];
            const c_max = maxValue - avgOverAll; // - entry[median_key];
            const c_min = -1 * (avgOverAll - minValue);
            if (c_max > max_val) {
                max_val = c_max;
            }
            if (c_min < min_val) {
                min_val = c_min;
            }

        } );

        const chart_Style: CSSProperties = {
            width: 300,
            paddingTop: 0,
            paddingBottom: 0,
        };
        const renderBarChart = (i: ISectorInfo) => {
            const annotation = {
                min: i.minValue_1,
                max: i.maxValue_1,
                median: avgOverAll,
                mean: i.avg,
                std_dev: 0,
            };
            const formatter = (new Intl.NumberFormat("de-de", {  maximumFractionDigits: 1, minimumFractionDigits: 1 })).format;
            const options: ISzSvgBarCellProperties = {
                value: i.avg,
                min: min_val,
                max: max_val,
                height: 30,
                padding_right: 0,
                annotation,
                formatter,
                hideMean: true,
            };
            return (<SzSvgCellChart {...options} />);
        };
        const legend: string[] = [];
        const xLabels: string[] = [];
        const xTicks: number[] = [];
        const series: any[] = [];
        series.push(avg_time_series);

        let current_sector: string = "nope";

        sector_time_series_map.forEach( (s, idx) => {
            if (!selected_sectors.has(s.sector)) {
                return;
            } else {
                current_sector = s.sector;
            }
            legend.push(idx);
            series.push(s.value);
            if (xLabels.length === 0) {
                for (let j = 0; j < time_series_items; j++) {
                    xTicks.push(j);
                    const d = fromDateDecimal(latest_date).minus({month: j * 6});
                    const y = d.toFormat("yyyy");
                    const m = d.toFormat("LL");
                    console.error(m, y);
                    xLabels.unshift(`${ m === "03" ? "März" : "Sep." } ${y}`);
                }
            }
        } );
        // console.error(column_cc);
        // console.error(overAll);
        // console.error(series);
        this.current_sector_info = data.find( (d) => d.sector === current_sector );

        const svg_properties: ISzSvgProperties = {
            chartId: "chConcess",
            height: "100%",
            width: "100%",
            yTickStep: 2,
            caption: "",
            xAxisLabel: "",
            xLabelsSkip: 0,
            legendWidth: 250,
            series,
            xLabels,
            xTicks,
            seriesLegend: [_t(ETranslation.sector_all_avg), `${this.current_sector_info.sector}`],
            yNiceMaxOverride: 8,
            noDots: true,
            lineType: ELineType.rectangle,
            onCustomDrawings: (x_min: number, x_max: number, y_min: number, y_max: number, xFactor: number, yFactor: number) => this.onCustomDrawings(x_min, x_max, y_min, y_max, xFactor, yFactor),
        };
        const getTickColor = (i: ISectorInfo, p: number) => {
            if (selected_sectors.get(i.sector)) {
                return `sz-color sz-color-1`;
            } else {
                return `sz-color sz-color-0`;
            }
        };
        const hndHideShowAll = () => {
            selected_sectors.forEach( (f, s) => selected_sectors.set(s, !all_selected) );
            this.setState({selected_sectors});
            const elem = document.querySelectorAll( `#chConcess .sz-chart-line:not(.sz-chart-line-avg)` );
            const dot = document.querySelectorAll( `#chConcess .sz-chart-dot:not(.sz-chart-dot-avg)` );
            if (all_selected) {
                elem.forEach( (e) => e.classList.add("sz-hide") );
                dot.forEach( (e) => e.classList.add("sz-hide") );
            } else {
                elem.forEach( (e) => e.classList.remove("sz-hide") );
                dot.forEach( (e) => e.classList.remove("sz-hide") );
            }
        };

        const hndClick = (i: ISectorInfo, p: number) => {
            const sel: boolean = selected_sectors.get(i.sector);
            if (sel) {
                selected_sectors.set(i.sector, !sel);
            } else {
                selected_sectors.clear();
                selected_sectors.set(i.sector, !sel);
            }

            this.setState({
                selected_sectors,
            });
        };
        const selectedClass = (i: ISectorInfo) => {
            const sel: boolean = selected_sectors.get(i.sector);
            if (sel) {
                return "sz-row-selected";
            } else {
                return "sz-row-hover";
            }
        };
// <th><Icon icon={"tick"} style={{cursor: "pointer"}} color={all_selected ? Colors.BLUE1 : Colors.LIGHT_GRAY1} onClick={ () => hndHideShowAll() } /></th>
        const imgStyle: CSSProperties = {
            maxHeight: 20,
            paddingRight: 4,
        };
        return (
            <>
                <div className={"sz-row"}>
                    <div className={"sz-col-50"}>
                        <HTMLTable className={"sz-table sz-small-padding"} striped={true} condensed={true} style={{borderCollapse: "separate"}}>
                            <thead>
                            <tr>
                                <th><div style={{display: "flex"}}>{["de", "at", "ch"].map( (i) => (<img style={imgStyle} src={`${BASE_DIR}images/flags/${i}.jpg`}/>))}&nbsp;EBIT-Multiplikatoren</div></th>
                                <th>&nbsp;</th>
                                <th style={{textAlign: "right"}}>min</th>
                                <th style={{textAlign: "right"}}>max</th>
                                <th style={{textAlign: "right"}}>{Globals.average}</th>
                                <th style={{textAlign: "right"}}>∆¹</th>
                                <th style={{textAlign: "center"}}>Mittelwert aller Branchen<img src={`${BASE_DIR}images/median.png`} style={{verticalAlign: "middle"}}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map( (i, p) => {
                                return  (
                                    <tr className={selectedClass(i)} onClick={ () => hndClick(i, p)}>
                                        <td>{i.sector}</td>
                                        <td className={"bordered"} style={borderRight}><Icon icon={"tick"} className={getTickColor(i, p)}/></td>
                                        <td style={{textAlign: "right"}}>{Globals.formatter(i.minValue_1, 1)}</td>
                                        <td className={"bordered"} style={Object.assign(borderRight, {textAlign: "right"})}>{Globals.formatter(i.maxValue_1, 1)}</td>
                                        <td style={{textAlign: "right"}}>{Globals.formatter(i.avg, 1)}</td>
                                        <td className={"bordered"} style={Object.assign(borderRight, {textAlign: "right"})}>{Globals.formatter(i.minDelta, 1)}</td>
                                        <td style={chart_Style} key={`${i.sector}_${date}`}>{renderBarChart(i)}</td>
                                    </tr>);
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td>{overAll.sector}</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: "right"}}>{Globals.formatter(overAll.minValue_1, 1)}</td>
                                <td style={{textAlign: "right"}}>{Globals.formatter(overAll.maxValue_1, 1)}</td>
                                <td style={{textAlign: "right"}}>{Globals.formatter(overAll.avg, 1)}</td>
                                <td style={{textAlign: "right"}}>{Globals.formatter(overAll.minDelta, 1)}</td>
                                <td style={{textAlign: "right"}}>&nbsp;</td>
                            </tr>
                            </tfoot>
                        </HTMLTable>
                    </div>
                    <div className={"sz-col-50"} style={{paddingTop: 30, paddingBottom: 5, paddingLeft: 25, paddingRight: 25}} key={`${current_sector}_${date}`}>
                        <SzSvg {...svg_properties}></SzSvg>
                    </div>
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>Unternehmensumsatz unter 20 Millionen Euro, Transaktionsmultiplikatoren</p>
                <p className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>¹) Änderung gegenüber Vorperiode</p>
            </>
        );
    }

    private onCustomDrawings(x_min: number, x_max: number, y_min: number, y_max: number, xFactor: number, yFactor: number) {
        const date = SessionStore.get(EParameters.DateParameter);
        if (this.date_idx === -1) {
            return (<></>);
        }
        if (!this.current_sector_info) {
            return (<></>);
        }
        // p_equity_risk_premium_val
        const d = fromDateDecimal(dates[this.date_idx]).setToEoM();

        const days = -1 * d.diff(deadline(1).startOf("month"), "days").days;
        const days_2 = -1 * d.diff(fromDateDecimal(dates[this.date_idx + 1]).setToEoM(), "days").days;
        const ff = dates.length - 1 === this.date_idx ? 0 : (days / days_2) * xFactor;
        const value = this.current_sector_info.avg;
        const x =  Math.trunc(xFactor * (this.date_idx) + ff) + 10;
        const y = Math.trunc(yFactor * (value));
        const ym = Math.trunc(yFactor * y_max);
        // console.error(xFactor, y_min, y_max, x, y, value);
        return (
            <g>
                <defs>
                    <filter x="0" y="0" width="1" height="1" id="p_concess_val">
                        <feFlood floodColor="rgba(255, 255, 255, .65)"/>
                        <feComposite in="SourceGraphic" operator="over" />
                    </filter>
                </defs>
                <line x1={x} x2={x} y1={ym} y2={ym - y} key={`${this.state.ch}_${x}_${y}_${value}`} className={"sz-chart-line sz-chart-line-20"}/>
                <circle cy={ym - y} cx={x} r={3} className={`sz-chart-dot sz-chart-dot-20`} />
                <text  filter="url(#p_concess_val)" className={"sz-chart-line sz-chart-line-20"} x={x + 5} y={ym - y + 5} style={{fontSize: "80%"}}>{Globals.formatter(value, 1, 1)}</text>;
            </g>
        );
    }
}
