import {EBorder} from "./PdfTable";
import {Alignment} from "pdfmake/interfaces";
export enum EPdfColumnDataType{
    label_only,
    row_num,
    text,
    number,
    percent,
    delta,
}
export interface IPdfTableStyleSetter {
    border(v: EBorder[]): IPdfTableStyleSetter;
    bold(v: boolean): IPdfTableStyleSetter;
    color(v: string): IPdfTableStyleSetter;
    alignment(v: Alignment): IPdfTableStyleSetter;
    noWrap(v: boolean): IPdfTableStyleSetter;
}
export interface IPdfTableCellStyle {
    border?: EBorder[],
    bold?: boolean,
    color?: string,
    alignment?: Alignment;
    noWrap?: boolean,
}
export interface IPdfTableColumn {
    data_type: EPdfColumnDataType,
    text?: string,
    index?: string|number,
    digits?: number,
    colSpan?: number,
    style?: IPdfTableCellStyle,
    override_content?(row_data);
}

export class PdfTableColumn {
    get definition(): IPdfTableColumn {
        return this._definition;
    }
    private _definition: IPdfTableColumn = {} as IPdfTableColumn;
    constructor(data_type: EPdfColumnDataType, digits?: number, colSpan?: number) {
        this._definition.colSpan = colSpan;
        this._definition.data_type = data_type;
        this._definition.digits = digits;
        this._definition.style = {};
    }
    public index(v: string|number){
        this._definition.index = v;
        return this;
    }
    public text(v: string){
        this._definition.text = v;
        return this;
    }
    public colSpan(v: number){
        this._definition.colSpan = v;
        return this;
    }
    public override_content(v: any){
        this._definition.override_content = v;
        return this;
    }
    public style(): IPdfTableStyleSetter{
        const s = this._definition.style;
        const setter: IPdfTableStyleSetter = {
            border: (v: EBorder[])=>{ s.border = v; return setter;},
            bold: (v: boolean)=>{ s.bold = v; return setter;},
            color: (v: string)=>{ s.color = v; return setter;},
            alignment: (v: Alignment)=>{ s.alignment = v; return setter;},
            noWrap: (v: boolean)=>{ s.noWrap = v; return setter;},
        };
        return setter;
    }
}
