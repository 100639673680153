import {Classes, Dialog, IDialogProps, InputGroup} from "@blueprintjs/core";
import * as React from "react";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export interface ISzShareDialogProps {
    listName: string;
    isOpen: boolean;
    onFinished?(listName: string, targetEmail: string);
    onClose?();
}
interface ISzShareDialogState {
    peng: number;
    currentTarget: string;
    listName: string;
}
export class SzShareDialog extends React.Component<ISzShareDialogProps, ISzShareDialogState> {

    private isOpen: boolean;
    private listName: string;

    constructor(props: ISzShareDialogProps, context: any) {
        super(props, context);
        this.state = {
            peng : 0,
            currentTarget: "",
            listName: undefined,
        };
        this.isOpen = false;
    }
    public render() {
        this.isOpen = this.props.isOpen;
        this.listName = this.props.listName;
        const props: IDialogProps = {
            isOpen: this.isOpen,
            icon: "document-share",
            title: _t(ETranslation.longlist_send),
            isCloseButtonShown: false,
        };
        return (
            <Dialog {...props}>
                <div className={Classes.DIALOG_BODY}>
                    <p>{_t(ETranslation.name_longlist)}</p>
                    <InputGroup
                        leftIcon="new-text-box"
                        onChange={(event) => this.changeListName(event.target.value)}
                        defaultValue={this.listName}
                        value={this.state.listName}
                    />
                    <br />
                    <p>{_t(ETranslation.longlist_send_help)}</p>
                    <InputGroup
                        leftIcon="envelope"
                        placeholder={"Email"}
                        onChange={(event) => this.changeTarget(event.target.value)}
                        value={this.state.currentTarget}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <button type="button" className={`bp3-button`} onClick={() => this.close() }>{_t(ETranslation.cancel)}</button>
                        <button type="button" className="bp3-button bp3-intent-primary" onClick={() => this.sendList() }>{_t(ETranslation.send)}</button>
                    </div>
                </div>
            </Dialog >
        );
    }
    private changeTarget(newTarget: string) {
        this.setState({
            currentTarget: newTarget,
        });
    }
    private changeListName(listName: string) {
        this.setState({
            listName,
        });
    }
    private close() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    private sendList() {
        if (this.props.onFinished) {
            this.props.onFinished(this.state.listName ? this.state.listName : this.listName, this.state.currentTarget);
        }
    }

}
