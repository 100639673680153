import {Button, Icon, Intent, Menu, MenuDivider, MenuItem, Popover} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {CSSProperties} from "react";
import {ETranslation} from "../../const/ETranslation";
import {Globals} from "../../const/Globals";
import {Regions} from "../../const/Regions";
import {IOptionEntry} from "../../models/IOptionEntry";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {_t, country_map} from "../../tools/Translator";
import {BaseFilter, IBaseFilterProperties, IBaseFilterState} from "./BaseFilter";

const COMPANY_LIMIT: number = 0;
const BASE_DIR = Globals.BASE_DIR;
const regions_2: IExtOptionEntry[] = [
    {label: _t(ETranslation.dach_region), value: "771,705,901", selected: false, data: { flags: ["de", "at", "ch"] }},
    {label: _t(ETranslation.eurozone), value: "712,771,759,764,765,774,794,797,809,815,816,824,843,705,865,882,883,889,748", selected: false, data: { flags: ["eu-01"] }},
    {label: _t(ETranslation.eu_countries), value: "712,725,750,771,759,764,765,774,797,794,745,809,815,816,824,843,705,864,865,869,900,883,882,889,749,788,748", selected: false, data: { flags: ["eu-02"] }},
];

interface ICountry {
    iso_3: string;
    iso_2: string;
    name: string;
    region: string;
    companies: number;
    id: number;
}
interface IExtOptionEntry extends IOptionEntry {
    region?: string;
}
interface ICountryFilterState extends IBaseFilterState {
    all: boolean;
    regions: IExtOptionEntry[];
    regions2: IExtOptionEntry[];
    countries: IExtOptionEntry[];
    isOpen: boolean;
    cc_count: number;
}
export interface ICountryFilterProperties extends IBaseFilterProperties {
    region: string;
    country: string;
}

export class CountryFilter extends BaseFilter<ICountryFilterProperties, ICountryFilterState> {

    private oldValue: string = "";
    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            loading: true,
            all: true,
            regions: this.getAllRegions(true),
            regions2: regions_2,
            countries: [],
            isOpen: false,
            cc_count: 0,
        };
    }

    public shouldComponentUpdate(nextProps: Readonly<IBaseFilterProperties>, nextState: Readonly<ICountryFilterState>, nextContext: any): boolean {
        // console.error(nextState.selectedCountries);
        const value = [];
        nextState.countries.forEach( (o) => {
            if (o.selected === true) {
                value.push(o.value);
            }
        } );
        const v = JSON.stringify(value);
        if (this.oldValue !== JSON.stringify(value)) {
            this.oldValue = v;
            super.valueChanged(value);
        }
        return true;
    }

    public componentDidMount(): void {
        this.loadCountries();
    }

    protected resetFilter() {
        if (!this.state.all) {
            this.changeAll();
        }
    }

    protected  getIcon(f: boolean) {
        if (f) {
            return <Icon icon={"tick"}/>;
        } else {
            return "";
        }
    }
    protected changeState(stateField: string, option: IExtOptionEntry) {
        const options = this.state[stateField];
        if (!options) {
            console.error("County filter -> changeState", stateField);
            return;
        }
        const item = options.find((o) => o.value === option.value);
        if (!item) {
            return;
        }
        item.selected = !item.selected;
        const all = options.reduce((acc, current) => acc && current.selected, true);
        const state = {
            all: false,
        };
        state[stateField] = options;
        state.all = all;
        this.setState(state);
    }
    protected changeOthers(stateField: string, region: string, selectFlag: boolean) {
        const elements: IExtOptionEntry[] = this.state[stateField];
        if (!elements) {
            return;
        }
        elements.forEach( (o) => {
            if (o.region === region) {
                o.selected = selectFlag;
            }
        } );
    }
    protected setAll(elements: IExtOptionEntry[], selectFlag: boolean): IExtOptionEntry[] {
        elements.forEach( (o) => o.selected = selectFlag );
        return elements;
    }
    protected changeAll() {
        const select = !this.state.all;
        const all = select;
        const regions = this.setAll(this.state.regions, select);
        // const regions2 = this.setAll(this.state.regions2, select);
        const countries = this.setAll(this.state.countries, select);

        this.setState({
            all,
            regions,
            countries,
        });
    }
    protected updateAll(stateField: string) {
        const options: IExtOptionEntry[] = this.state[stateField];
        const all = options.reduce((acc, current) => acc && current.selected, true);
        const state = {
            all: false,
        };
        state[stateField] = options;
        state.all = all;
        this.setState(state);
    }
    protected onChange(stateField: string, option: IExtOptionEntry, selectUnselectOther: string): boolean {
        const options: IExtOptionEntry[] = this.state[stateField];
        const item = options.find( (o) => o.value === option.value);
        if (!item) {
            return false;
        }
        item.selected = !item.selected;
        this.changeOthers(selectUnselectOther, item.region, item.selected);
        return true;
    }
    protected renderRegions2() {
        const onClickR2 = (option: IExtOptionEntry) => {
            const regions2: IExtOptionEntry[] = this.state.regions2;
            const regions: IExtOptionEntry[] = this.state.regions;
            const item = regions2.find( (o) => o.value === option.value);

            if (!item) {
                return false;
            }
            // item.selected = !item.selected;
            const countryIds = item.value.split(",");
            const countries = this.state.countries;
            countries.forEach( (o) => {
                o.selected = false;
                this.changeOthers("regions", o.region, false);
            });
            countries.forEach( (o) => {
                if (countryIds.indexOf(o.value) >= 0) {
                    o.selected = true;
                }
            });

            this.updateAll("countries");
            /*
            this.setState({
                countries,
                regions,
            });
            */
            return null;
        };
        const imgStyle: CSSProperties = {
            maxHeight: 20,
            paddingRight: 4,
        };
        const renderFlags = (s: string[]) => {
            return (
                <div style={{height: 20, maxHeight: 20, display: "flex"}}>
                    {s.map( (i) => (<img style={imgStyle} src={`${BASE_DIR}images/flags/${i}.jpg`}/>))}
                </div>);
        };

        let cKey = 0;
        return this.state.regions2.map((o) =>
            <MenuItem key={"regions2" + (cKey++)} intent={ o.selected ? "primary" : "none"} labelElement={renderFlags(o.data.flags)}  text={o.label} onClick={ () => onClickR2(o) } />,
        );
    }
    protected renderRegions() {
        let cKey = 0;
        return this.state.regions.map((o) =>
            <MenuItem key={"item" + (cKey++)} intent={ o.selected ? "primary" : "none"} labelElement={this.getIcon(o.selected)}  text={o.label} onClick={() => { this.onChange("regions", o, "countries"); this.updateAll("regions"); }} />,
        );
    }
    protected renderCountries(flagRenderDivider, countries: IExtOptionEntry[]) {
        let cKey = 0;
        if (!countries) {
            return null;
        }
        const renderDivider = () => {
            if (!flagRenderDivider) {
                return <MenuDivider title={"|"} className={"no-text-color"} />;
            }
            let sum_selected = 0;
            this.state.countries.forEach( (o) => {
                if (o.selected) {
                    sum_selected += o.data.count_companies;
                }
            } );// Firmen %s von %s
            return <MenuDivider title={_t(ETranslation.companies_of_max, Globals.formatter(sum_selected, 0), Globals.formatter( this.state.cc_count, 0))} />;
        };
        return (
            <Menu>
                {renderDivider()}
                {countries.map((o) =>
                    <MenuItem key={"item" + (cKey++)} intent={ o.selected ? "primary" : "none"} labelElement={this.getIcon(o.selected)}  text={o.label} onClick={() => { this.onChange("countries", o, "regions"); this.updateAll("countries"); }} />,
                )}
            </Menu>
        );
    }

    protected renderContent() {
        const len = 19 ;
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        const testCanClose = (ev) => {
            if (!ev) {
                return;
            }
            if (this.state.isOpen && ev.type === "mousedown") {
                this.setState({isOpen: false});
            }
        };
        const sp_i = this.props.no_base_filter ? Intent.PRIMARY : Intent.NONE;
        let intent: Intent = this.state.all ? sp_i : Intent.WARNING;
        if (this.state.isOpen) {
            intent = Intent.PRIMARY;
        }
        const w = this.state.countries && this.state.countries.length ? this.state.countries.slice(0) : [];
        const countries = [];
        while ( w.length ) {
            countries.push(w.splice(0, len));
        }
        return(
            <Popover
                minimal={true}
                position={"bottom-left"}
                boundary={"viewport"}
                usePortal={true}
                hasBackdrop={true}
                popoverClassName={"sz-popover"}
                modifiers={popper}
                isOpen={this.state.isOpen}
                canEscapeKeyClose={true}
                captureDismiss={false}
                onInteraction={(a, b) => testCanClose(b)}
            >
                <Button
                    intent={intent} text={_t(ETranslation.countries) + (!this.state.all ? " aktiv" : "")}
                    icon={"globe"}
                    minimal={this.props.no_base_filter}
                    rightIcon={ this.props.no_base_filter ? IconNames.CHEVRON_DOWN : null }
                    onClick={() => this.setState({isOpen: !this.state.isOpen})}
                />
                <div className={"sz-row sz-search-filter-list"} style={{margin: 8}}>
                    <div className={"sz-row min-li-width"}>
                        <Menu>
                            <MenuItem key={"itemAlle"} intent={ this.state.all ? "primary" : "none"} labelElement={this.getIcon(this.state.all)}  text={_t(ETranslation.all)} onClick={() => { this.changeAll(); }} />
                            <MenuDivider/>
                            {this.renderRegions()}
                            <MenuDivider/>
                            {this.renderRegions2()}
                        </Menu>
                        {countries.map( (c, idx) => this.renderCountries(idx === 0, c) )}
                    </div>
                </div>
            </Popover>
        );
    }

    protected renderLoading() {
        return (
            <Button
                className={"bp3-skeleton"}
                icon={"office"}
                style={{minWidth: 180}}
                minimal={false} />
        );
    }
    private loadCountries() {
        const db = new PublicDbCalls();
        const cState = {};
        (async () => {
            const response = await db.getCountries();
            try {
                const result = response.result;
                if (Array.isArray(result)) {
                    const countries = [];
                    let sum = 0;
                    result.forEach((e) => {
                        const n = parseInt(e[4], 10);
                        if (n > COMPANY_LIMIT) {
                            sum += n;
                            countries.push({
                                selected: true,
                                value:  e[5],
                                label:  `${country_map[e[0]].name} [${Globals.formatter(n, 0)}]`,
                                region: e[3],
                                data: {count_companies: n},
                            });
                        }
                    });
                    countries.sort( (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
                    // console.error(sum);
                    this.setState({
                        loading: false,
                        countries,
                        cc_count: sum,
                    });
                    return;
                }
            } catch (exi) {
                console.error(exi, response);
            }
            this.setState({
                loading: false,
            });
        })();
    }
    private getAllRegions(select: boolean): IExtOptionEntry[] {
        const regions: IExtOptionEntry[] = [];
        Regions.forEach((r) => {
            regions.push({
                label: r,
                value: r,
                region: r,
                selected: select,
            });
        });
        return regions;
    }
}
