import {SessionStore} from "../const/SessionStore";
import {ICreditSpreadIndication} from "../models/CreditSpreadIndication";
import {EParameters} from "../models/EParameters";
import {FinAnn} from "./FinAnn";
import {Regression} from "./Regression";
import {max, median, min, quantile, quantileRank} from "./Statistics";
import {CreditSpreadMetric, CreditSpreadOptions} from "../const/CreditSpreadOptions";
import {first, last} from "./Helpers";
import {Ratings} from "../const/Ratings";

const i_rating = (rating)=>{
    return rating;
};
export interface ICalcCreditSpreadResultData{
    x: number;
    y: number;
    r: number;
    quantileRank?: number;
    id: string;
    name: string;
    rating: string;
    security_id: string;
    isin: string;
    all_x?: number[];
    all_y?: number[];
    count?: number;
}
export interface ICalcCreditSpreadResult {
    key: string;
    key_spread: number;
    key_value: number;
    q_y_range: {from: number; till: number;};
    rating_range: {from: number; till: number;};
    y_range: {from: number; till: number;};
    x_range: {from: number; till: number;};
    regression: {
        Slope: number;
        Intercept: number;
        R2: number;
        DataPoints: number;
    },
    data: ICalcCreditSpreadResultData[],
    x_max: number;
    y_max: number;
    boxed_spreads?: number[];

    count_bonds?: number;
}

export class CalcCreditSpreadIndication {
    private static get_x_range(key_value, creditSpreadIndications: any[], key: string){
        if(isNaN(key_value)){
            return {from: NaN, till: NaN};
        }
        const limit = CreditSpreadOptions.limits[key] ? CreditSpreadOptions.limits[key] : {x: [0,100], y: [0, 10]};
        const range_array = ([].concat(creditSpreadIndications))
            .filter((i)=>{
                const x = i.fin_data.result[key];
                const y = i.average_spreads;

                const in_box = x >= limit.x[0] && x < limit.x[1]  && y >= limit.y[0] && y < limit.y[1];

                return in_box;
            })
            .sort((a, b)=>{
                const k_a = Math.abs(key_value - a.fin_data.result[key]);
                const k_b = Math.abs(key_value - b.fin_data.result[key]);
                return k_a - k_b;
            });
        const pp = SessionStore.get(EParameters.CreditSpreadXRangeParameter);
        const best = isNaN(parseInt(pp, 10)) ? 100 : parseInt(pp, 10);
        const best_of = ([].concat(range_array.slice(0, best)))
            .sort((a, b)=>{
                return a.fin_data.result[key] - b.fin_data.result[key];
            });

        const ra = first(best_of, {fin_data: { result: {}}});
        const rb = last(best_of, {fin_data: { result: {}}});
        const x_range = {from: ra.fin_data.result[key], till: rb.fin_data.result[key]};
        // console.error(best_of);
        return x_range;
    }
    public static forKey(creditSpreadIndications: ICreditSpreadIndication[], key: string, year: number): ICalcCreditSpreadResult{
        const limit = CreditSpreadOptions.limits[key] ? CreditSpreadOptions.limits[key] : {x: [0,100], y: [0, 10]};

        const benchmarkingCustom = SessionStore.get(EParameters.BenchmarkingCustom);

        const custom_fin = FinAnn.getCustomFin(year, benchmarkingCustom);
        const ret = FinAnn.getFinanceData(custom_fin);
        const finance_data = ret.result;

        let key_value: number = finance_data[key] === null ? NaN : finance_data[key];
        // console.error(key, key_value);
        if(key_value === Infinity || key_value === undefined){
            key_value = NaN;
        }
        const x_range:{from: number; till: number;} = CalcCreditSpreadIndication.get_x_range(key_value, creditSpreadIndications, key);

        const v_a = SessionStore.get(EParameters.CreditSpreadDecisionParameterA);
        const v_b = SessionStore.get(EParameters.CreditSpreadDecisionParameterB);
        const v_c = SessionStore.get(EParameters.CreditSpreadDecisionParameterC);
        // const v_d = SessionStore.get(EParameters.CreditSpreadDecisionParameterD);
        // console.error(v_a, v_b, v_c, v_d, v_a + v_b + v_c + v_d);
        const quality_y_range = CreditSpreadOptions.credit_spread_decisions[v_a + v_b + v_c];


        // console.error("forKey", key, key_value);
        const x_a = [];
        const y_a = [];
        const y_in_x_range = [];
        const in_x_range = [];
        const data = [];
        const ratings = {};
        const rating_in_x_range = [];

        let count_bonds = 0;

        const to_bad_rating = {"CCC+": 1,"CCC": 1, "CCC-": 1, "CC": 1, "C": 1};
        // console.error(key, creditSpreadIndications);
        creditSpreadIndications.forEach( (i, idx) =>{
            const x = i.fin_data.result[key];
            const y = i.average_spreads;

            const in_box = x >= limit.x[0] && x < limit.x[1]  && y >= limit.y[0] && y < limit.y[1];
            const in_range = x >= x_range.from && x <= x_range.till;
            // if(i.name === "Marathon Petroleum Corp."){
            //     console.error(in_range, x_range, i);
            // }
            if(!in_box){
                return;
            }
            /*
            if(i.rating==='0'){
                return;
            }
            */
            const rating = i_rating(i.rating);
            if(!ratings[rating] && rating!=="0" && !to_bad_rating[rating]){
                ratings[rating] = {
                    x: NaN,
                    y: NaN,
                    r:6,
                    all_x: [],
                    all_y: [],
                    id: 0,
                    count: 0,
                    name: rating,
                    rating,
                };
                data.push(ratings[rating]);
            }
            if(in_range){
                y_in_x_range.push(y);
                in_x_range.push(i);
                // rating_in_x_range.push(CreditSpreadOptions.get_rating_idx(i.rating));
                const r = Ratings.s_and_p_mapped_to_num[i.rating];
                if(r!==undefined){
                    rating_in_x_range.push(r);
                }
            }
            data.push({
                x,
                y,
                r: 3,
                id: i.id,
                name: i.name,
                rating: i.rating,
                security_id: i.security_id,
                isin: i.isin,
                count_bonds: i.spreads.length,
            });
            count_bonds += i.spreads.length;
            if(rating !== "0" && !to_bad_rating[rating]){
                ratings[rating].all_x.push(x);
                ratings[rating].all_y.push(y);
                ratings[rating].count++;
            }

            x_a.push([x]);
            y_a.push([y]);
        });

        const regression = new Regression();
        regression.calculate(x_a, y_a, 0);

        const fx = (x) => ((regression.Intercept * 100) + regression.Slope * x);
        const key_spread = fx(key_value);

        const y_max: number = Math.max(limit.y[1], key_spread) + .5;
        const x_max: number = key_value + .5;
        // console.error(key, x_range, in_x_range);
        Object.keys(ratings).forEach((rating)=>{
            const q1 = quantile(ratings[rating].all_x, .05);
            const q2 = quantile(ratings[rating].all_x, .95);
            const to_eliminate = [];
            ratings[rating].all_x.forEach((x, idx)=>{
                if(x<q1 || x>q2){
                    to_eliminate.push(idx);
                }
            });
            to_eliminate.forEach((idx)=>{
                ratings[rating].all_x.splice(idx, 1);
                ratings[rating].all_y.splice(idx, 1);
            });
            ratings[rating].q1 = quantile(ratings[rating].all_x, .2);
            ratings[rating].q2 = quantile(ratings[rating].all_x, .8);
            ratings[rating].q35 = quantile(ratings[rating].all_x, .35);
            ratings[rating].q65 = quantile(ratings[rating].all_x, .65);
            ratings[rating].x = median(ratings[rating].all_x);
            ratings[rating].y = median(ratings[rating].all_y);
            ratings[rating].quantileRank = quantileRank(ratings[rating].all_x, key_value);
        });
        const y_range_low_border = quantile(y_in_x_range, quality_y_range.from);
        const y_range_high_border = quantile(y_in_x_range, quality_y_range.till);
        const y_range = {from: y_range_low_border, till: y_range_high_border};
        const boxed_rated_companies = [];
        const is_boxed_company = {};
        creditSpreadIndications.forEach( (i, idx) =>{
            if(is_boxed_company[i.id]){
                return;
            }
            const x = i.fin_data.result[key];
            const y = i.average_spreads;
            const is_boxed = CreditSpreadOptions.in_box_from_till(x, y, x_range, y_range);
            if(!is_boxed || i.rating==="0"){
                return;
            }
            const r = Ratings.s_and_p_mapped_to_num[i.rating];
            if(r!==undefined && !to_bad_rating[i.rating]){
                is_boxed_company[i.id] = true;
                boxed_rated_companies.push(r);
            }
        });
        // console.error(key, min(boxed_rated_companies), max(boxed_rated_companies), Math.round(median(boxed_rated_companies)));
        // console.error(captions[key], `from(${quantile(rating_in_x_range, y_range.from)}) till(${quantile(rating_in_x_range, y_range.till)})`);
        const result: ICalcCreditSpreadResult = {
            key,
            key_spread,
            key_value,
            q_y_range: quality_y_range,
            rating_range: {
                from: min(boxed_rated_companies),
                till: max(boxed_rated_companies),
            },
            y_range,
            x_range,
            regression: {
                Slope: regression.Slope,
                Intercept: regression.Intercept,
                R2: regression.R2,
                DataPoints: regression.DataPoints,
            },
            data,
            x_max,
            y_max,
            count_bonds,
        };
        // console.error(key, data.filter((d)=> d.name===d.rating));
        return result;
    }

    private static in_box(x_value: number, y_value: number, indication: ICalcCreditSpreadResult){
        return CreditSpreadOptions.in_box(x_value, y_value, indication);
    }

    public static get_all_boxed_spreads(credit_spread_results: any){
        const metrics: CreditSpreadMetric[] = CreditSpreadOptions.getMetrics();
        const metrics_map: {[index: string]: CreditSpreadMetric} = {};
        metrics.forEach((m)=>metrics_map[m.field] = m);
        // console.error(metrics_map);
        const keys = CreditSpreadOptions.getMetricsKeys();
        const boxed_stat = [];
        const boxed_stat_map = {};
        const count_stat = (bond_stat, indication: ICalcCreditSpreadResult)=>{
            // const bond_isin = bond_stat.isin;
            const id = bond_stat.name;
            const x = bond_stat.x;
            const y = bond_stat.y;
            if(boxed_stat_map[id] === undefined){
                const s = {
                    count: 0,
                    spread: y,
                    weight: 0,
                    id,
                    cid: bond_stat.id,
                    security_id: bond_stat.security_id,
                    rating: bond_stat.rating,
                    bonds: [],
                };
                boxed_stat_map[id] = s;
                boxed_stat.push(s);
                // console.error(id, indication.data);
            }
            const boxed = this.in_box(x,y,indication);
            if(boxed){
                indication.boxed_spreads.push(y);
                boxed_stat_map[id].count++;
                boxed_stat_map[id].weight += metrics_map[indication.key].weight;
            }
        };
        keys.forEach((key) =>{
            const indication: ICalcCreditSpreadResult = credit_spread_results[key];
            if(!indication){
                return;
            }
            // console.error(indication);
            indication.boxed_spreads= [];
            indication.data.forEach((d)=>{
                if(d.name!==d.rating){
                    count_stat(d, indication);
                }
            });
        });
        const r = boxed_stat.filter((a)=> a.weight > 0).sort((a, b)=> b.weight - a.weight);
        // console.error(r);
        return r;
    }
}
