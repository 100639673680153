export type FormularDefinition= {expression: string; valued: string;};
const net_debt: FormularDefinition = {
    expression: "Finanzschulden + Pensionsverbindlichkeiten - \"Liquide Mittel\"",
    valued: "$ff_debt + $pensionsdefizit - $liquide_mittel",
};
const net_debt_ebitda: FormularDefinition = {
    expression: "\\dfrac{\\text{Finanzschulden} + \\text{Pensionsverbindlichkeiten} - \\text{Liquide Mittel}}{\\text{EBITDA}}",
    valued: "\\dfrac{\\text{$ff_debt} + \\text{$ff_pens_liabs_unfunded} - \\text{$ff_cash_generic}}{\\text{$ff_ebitda_oper}} = \\text{$result}",
};
// let ffo_debt_ratio = 100 * ffo / net_debt;
const ffo_debt_ratio: FormularDefinition = {
    expression: "\\dfrac{\\text{EBITDA} - \\text{Zinsaufwendungen} - \\text{Steueraufwendungen}}{\\text{Finanzschulden} + \\text{Pensionsverbindlichkeiten} - \\text{Liquide Mittel}}",
    valued: "\\dfrac{\\text{$ff_ebitda_oper} - \\text{$ff_int_exp_debt} - \\text{$ff_inc_tax}}{\\text{$ff_debt} + \\text{$ff_pens_liabs_unfunded} - \\text{$ff_cash_generic}} = \\text{$result}\\%",
};
// ebit_interest = ebit / interest;
const ebit_interest_ratio: FormularDefinition = {
    expression: "\\dfrac{\\text{EBIT}}{\\text{Zinsaufwendungen}}",
    valued: "\\dfrac{\\text{$ff_ebit_oper}}{\\text{$ff_int_exp_debt}} = \\text{$result}",
};
//
const ffo_interest: FormularDefinition = {
    expression: "\\dfrac{\\text{EBITDA} - \\text{Zinsaufwendungen} - \\text{Steueraufwendungen}}{\\text{Zinsaufwendungen}}",
    valued: "\\dfrac{\\text{$ff_ebitda_oper} - \\text{$ff_int_exp_debt} - \\text{$ff_inc_tax}}{\\text{$ff_int_exp_debt}} = \\text{$result}",
};
// gearing = 100 * (net_debt / eigenkapital); //
const gearing: FormularDefinition = {
    expression: "\\dfrac{\\text{Finanzschulden} + \\text{Pensionsverbindlichkeiten} - \\text{Liquide Mittel}}{\\text{Eigenkapital}}",
    valued: "\\dfrac{\\text{$ff_debt} + \\text{$ff_pens_liabs_unfunded} - \\text{$ff_cash_generic}}{\\text{$ff_eq_tot}} = \\text{$result}\\%",
};
const eq_ratio: FormularDefinition = {
    expression: "\\dfrac{\\text{Eigenkapital}}{\\text{Aktiva}}",
    valued: "\\dfrac{\\text{$ff_eq_tot}}{\\text{$ff_assets}} = \\text{$result}\\%",
};
const ebitda_sales_ratio: FormularDefinition = {
    expression: "\\dfrac{\\text{EBITDA}}{\\text{Umsatzerlöse}}",
    valued: "\\dfrac{\\text{$ff_ebitda_oper}}{\\text{$ff_sales}} = \\text{$result}\\%",
};
const ebit_sales_ratio: FormularDefinition = {
    expression: "\\dfrac{\\text{EBIT}}{\\text{Umsatzerlöse}}",
    valued: "\\dfrac{\\text{$ff_ebit_oper}}{\\text{$ff_sales}} = \\text{$result}\\%",
};
const netincome_sales_ratio: FormularDefinition = {
    expression: "\\dfrac{\\text{Jahresüberschuss}}{\\text{Umsatzerlöse}}",
    valued: "\\dfrac{\\text{$ff_net_inc}}{\\text{$ff_sales}} = \\text{$result}\\%",
};
// const capital_employed = eigenkapital + ff_debt + pensionsdefizit - liquide_mittel - financial_asset;
const roce: FormularDefinition = {
    expression: "\\dfrac{\\text{EBIT}}{\\text{Eigenkapital} + \\text{Nettoschulden} - \\text{Finanzanlagen}}",
    valued: "\\dfrac{\\text{$ff_ebit_oper}}{\\text{$ff_eq_tot} + \\text{$ff_debt} + \\text{$ff_pens_liabs_unfunded} - \\text{$ff_cash_generic} - \\text{$ff_invest_aff}} = \\text{$result}\\%",
};
// 100 * netincome / eigenkapital_konzernaktionaere;
const roe: FormularDefinition = {
    expression: "\\dfrac{\\text{Jahresüberschuss}}{\\text{Eigenkapital der Konzernaktionäre}}",
    valued: "\\dfrac{\\text{$ff_net_inc}}{\\text{$ff_shldrs_eq}} = \\text{$result}\\%",
};
const xch_sales: FormularDefinition = {
    expression: "\\text{Umsatzerlöse}",
    valued: "\\text{$ff_sales}",
};
const xch_assets: FormularDefinition = {
    expression: "\\text{Aktiva}",
    valued: "\\text{$ff_assets}",
};
const anlagevermoegen: FormularDefinition = {
    expression: "\\text{Immaterielle Vermögenswerte}+\\text{Sachanlagen}",
    valued: "\\text{$ff_intang}+\\text{$ff_ppe_net} = \\text{$result}",
};
export class CreditSpreadFormulars{
    public static forKey: {[index:string]: FormularDefinition}= {
        net_debt_ebitda,
        ffo_debt_ratio,
        ebit_interest_ratio,
        ffo_interest,
        gearing,
        eq_ratio,
        ebitda_sales_ratio,
        ebit_sales_ratio,
        netincome_sales_ratio,
        roce,
        roe,
        xch_sales,
        xch_assets,
        anlagevermoegen,
    };
}
