import {Button, Classes, Dialog, FormGroup, InputGroup, Intent, Toaster,} from "@blueprintjs/core";
import * as React from "react";
import {ServiceCalls} from "../../services/ServiceCalls";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {ButtonWait} from "../hooks/ButtonWait";

export interface IDlgDbRequestProps {
    isOpen: boolean;
    onClose();
}

interface IDlgDbRequestState {
    companies: string [];
    isPrepare: boolean;
}

export class DlgDbRequest extends React.Component<IDlgDbRequestProps, IDlgDbRequestState> {

    private readonly toaster: React.RefObject<Toaster>;

    constructor(props: any, context: IDlgDbRequestState) {
        super(props, context);

        this.state = {
            companies: ["", "", "", "", ""],
            isPrepare: false,
        };

        this.toaster = React.createRef();
    }

    public render() {
        let ck = 0;
        const renderPrepare = () => {
            if (!this.state.isPrepare) {
                return _t(ETranslation.send);
            }
            return <ButtonWait />;
        };
        return (
            <Dialog
                title={_t(ETranslation.database_extention_request)}
                isOpen={this.props.isOpen}
                icon={"database"}
                canOutsideClickClose={false}
                usePortal={true}
                style={{minHeight: 400}}
                onClose={() => this.props.onClose() }
                shouldReturnFocusOnClose={false}
            >
                <div className={Classes.DIALOG_BODY} style={{margin: 10, marginBottom: 10}}>
                    <div className={"sz-user-request-form"}>
                    <Toaster position={"top"} autoFocus={false} canEscapeKeyClear={true} ref={this.toaster} className={"in-front"} />
                    <p>{_t(ETranslation.database_extention_request_text)}</p>
                    {this.state.companies.map((s, idx) => { return (
                        <FormGroup key={"k" + (ck++)} label={`${_t(ETranslation.company)} ${idx + 1}`} labelFor={`idCompany${idx}`} inline={true}>
                            <InputGroup style={{width: 300}} id={`idCompany${idx}`} value={this.state.companies[idx]} placeholder={_t(ETranslation.company_name)} onChange={(e) => this.handleChange(e, idx)} />
                        </FormGroup>
                    ); })}
                </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => this.props.onClose() } disabled={this.state.isPrepare}>{_t(ETranslation.cancel)}</Button>
                        <Button intent={"primary"} disabled={this.state.isPrepare} onClick={() => this.submitForm()} style={{minWidth: 110}}>
                            {renderPrepare()}
                        </Button>
                    </div>
                </div>
            </Dialog>

        );
    }

    private submitForm() {
        this.setState({
            isPrepare: true,
        });
        (async () => {
            try {
                const srv = new ServiceCalls();
                const result = await srv.extendDb(this.state);
                if (result) {
                    this.toaster.current.show({
                        icon: "tick",
                        intent: Intent.PRIMARY,
                        message: `Anfrage wurde gesendet`,
                        timeout: 5000,
                    });
                } else {
                    this.toaster.current.show({
                        icon: "error",
                        intent: Intent.WARNING,
                        message: `Ihre Anfrage wurde nicht übermittelt. Bitte nuzten Sie unser Kontaktformular.`,
                    });
                }
            } catch (e) {
                console.error(e);
            }
            this.setState(
                {
                    companies: ["", "", "", "", ""],
                    isPrepare: false,
            });
            this.props.onClose();
        })();

    }
    private handleChange(event: React.ChangeEvent<HTMLInputElement>, idx: number) {
        const companies: any[] = this.state.companies;
        companies[idx] = event.target.value;
        this.setState({
            companies,
        });
    }
}
