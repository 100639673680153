import * as pdfMake from "pdfmake/build/pdfmake";
import {fromNow} from "../tools/DateTools";
import {Globals} from "../const/Globals";
import {vfsOpenSans} from "../fonts/OpenSans";
import {SvgImages} from "../const/SvgImages";
(pdfMake as any).vfs = vfsOpenSans;

/*1. Startseite Hintergrund A4 Quer
2. Logo Weiss in svg
3.Logo Schwarz in svg (falls Du das mal brauchen solltest woanders)
4. Bildmarker für die rechte Seite PNG


Farbcode Balken unter der Überschrift bei den Slides:  #293742
Schriftfarbe über Balken: Weiss
Schriftfarbe Daten, Content: #595959
Font: Open Sans
 */


const _ref = Globals._ref;

const text_color='#293742';
const comment_color='#8a8a8a';
const styles={
    title: {
        fontSize: 24,
        margin:[0,0,0,0]
    },
    disclose: {
        fontSize: 20,
        margin:[0,100,0,0]
    },
    h1: {
        bold: true,
        fontSize: 20,
        margin:[0,-27,0,20],
        color: "#ffffff",
    },
    h2: {
        fontSize: 11,
        margin:[0,0,0,15]
    },
    h3: {
        fontSize: 10,
        margin:[0,20,0,10]
    },
    h4: {
        fontSize: 9,
        margin:[0,0,0,10]
    },
    label:{
        color:'#8a8a8a',
        margin:[0,5,0,5]
    },
    text:{
        margin:[0,0,0,5]
    },
    text_2:{
        margin:[0,0,0,10]
    },
    cell:{
        margin:[0,0,0,0]
    },
    header_0:{
        color:'#182026',
        bold: true,
        margin:[0,0,0,0]
    },
    header_1:{
        color:'#182026',
        margin:[0,0,0,0]
    },

};
const defaultStyle={
    fontSize: 8,
    color: text_color,
    font: 'OpenSans'
};
const images={
    std_bg: _ref("images/00_bg_a4_quer.jpg"),
    watermark: _ref("images/watermark.jpg"),
};
const makeBackground = (page,szPage)=>{
    const bh=744;
    const bw=372;
    const w = 200;
    const h = (w/bw) * bh;
    return [
        {
            image: "watermark",
            width: w,
            height: h,
            alignment: "right",
            margin: [0,(szPage.height - h)/2,0,0]
        }
    ];
}

const makeFooter = (currentPage, pageCount) => {
    if(currentPage===1 || pageCount === currentPage){
        return null;
    }
    return [
        {
            margin: [ 50, 0, 50,0 ],
            columns:[
                {
                    width:'*',
                    alignment:'left',
                    text: `©${fromNow().toFormat("yyyy")} smartZebra GmbH | www.smart-zebra.de`,
                    color: comment_color
                },
                {
                    width:'auto',
                    alignment:'right',
                    text: `Seite ${currentPage}|${pageCount}`,
                    color: comment_color
                }
            ]
        }
    ];
}
const makeHeader= (currentPage, pageCount) =>{
    if(currentPage>0){
        return null;
    }
    return [
        {
            margin: [ 20, 0, 20,0 ],
            alignment:'right',
            text: `erstellt ${fromNow().toFormat("dd.LL.yyyy")}`,
            color: comment_color
        }
    ];
}
export class PdfCreate {
    public static create(pages){
        const document={
            pageOrientation: 'landscape',
            content:pages,
            styles,
            images,
            defaultStyle,
            background: makeBackground,
            footer: makeFooter,
            header: makeHeader,
            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: [ 50, 50, 50, 25 ],
            pageSize: "B4"
        };
        const customTableLayouts = {
            smart_table: {
                hLineWidth: ()=> 0.25,
                vLineWidth: ()=> 0.25,
                hLineColor: "#9d9d9d",
                vLineColor: "#9d9d9d",
            }
        };
        const fonts = {
            DejaVu:{
                normal: _ref("fonts/DejaVuSansMono.ttf"),
            },
            Raleway: {
                normal: _ref("fonts/Raleway-Regular.ttf"),
                bold: _ref("fonts/Raleway-Bold.ttf"),
                italics: _ref("fonts/Raleway-Italic.ttf"),
                bolditalics: _ref("fonts/Raleway-BoldItalic.ttf"),
            },
            OpenSans: {
                normal: _ref("fonts/OpenSans-Regular.ttf"),
                bold: _ref("fonts/OpenSans-Semibold.ttf"),
                italics: _ref("fonts/OpenSans-Italic.ttf"),
                bolditalics: _ref("fonts/OpenSans-SemiboldItalic.ttf"),
            },
        };
        const doc = pdfMake.createPdf(document as any, customTableLayouts, fonts);
        doc.open();
        // doc.download(`${Globals.getWindowKey("wp_show_module")}_${fromNow().toFormat("yyyyLLdd_hhmm")}.pdf`);
    }
}
