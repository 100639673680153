import {Card, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {
    BarElement,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    Tooltip,
} from 'chart.js';
import * as React from "react";
import {Bubble} from 'react-chartjs-2';
import {CustomAxisLabel} from "../../chart-plugin/CustomAxisLabel";
import {RegressionLine} from "../../chart-plugin/RegressionLine";
import {captions2} from "../../const/Benchmarking";
import {CreditSpreadMetric, CreditSpreadOptions} from "../../const/CreditSpreadOptions";
import {FinKeyLabels} from "../../const/FinKeyLabels";
import {Globals} from "../../const/Globals";
import {Ratings} from "../../const/Ratings";
import {ICalcCreditSpreadResult, ICalcCreditSpreadResultData} from "../../helpers/CalcCreditSpreadIndication";
import {median} from "../../helpers/Statistics";
import {IOptionEntry} from "../../models/IOptionEntry";
import {EChartType, PdfChartToImage} from "../../pdf-tools/PdfChartToImage";
import {PDF_IMAGE_HEIGHT, PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {ISzTableProperties} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {_t, _tn} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

ChartJS.register(LinearScale, LogarithmicScale, PointElement, LineElement, BarElement, Tooltip, Legend, RegressionLine, CustomAxisLabel);


interface IChartInfo {
    key: string,
    caption: string,
    gfx: {
        width: number,
        caption: string,
        data: any,
        options: any
    }[],
}

const def_limit = {x: [0,100], y: [0, 10]};
const limits = CreditSpreadOptions.limits;

const rating_mapping_new = {
    "AAA": ["Aaa"],
    "AA": ["Aa1", "Aa2", "Aa3"],
    "A": ["A1", "A2", "A3"],
    "BBB": ["Baa1", "Baa2", "Baa3"],
    "BB":  ["Ba1", "Ba2", "Ba3"],
    "B":  ["B1", "B2", "B3"],
};

export class CreditSpreadIndication extends BaseModule<any> {

    protected tableProps: ISzTableProperties<any[]>;
    protected selectOptions: IOptionEntry[] = [];
    private companies = {};

    private chart_infos: {[index: string]: IChartInfo} = {};

    constructor(props: any, context: any) {
        super(props, context);
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;
        // console.error(this.data);
        this.setState({
            loading: false,
            mark_companies: data.select_company_id ? [data.select_company_id] : this.state.mark_companies,
        });
    }
    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected getParameterLabel(p: string): string {
        return FinKeyLabels.getFinKeyLabel(p);
    }
    protected renderContent() {
        const r = (metric: CreditSpreadMetric)=>{
            if(metric.weight === 0){
                return null;
            }
            return (
                <div className={"sz-col-100"} style={{marginBottom: 25}}>{this.renderGfx(metric.caption, metric.field)}</div>
            );
        };

        return (
            <div style={{paddingTop: 16}}>
                <div className="sz-row">
                    {CreditSpreadOptions.getMetrics().map(r)}
                </div>
            </div>
        );
    }

    protected noData(last_caption: string, key: string) {
        return (
            <div style={{padding: 5}}>
                <Card interactive={true} style={{padding: 10}} className={"sz-card-override"}>
                    <NonIdealState
                        icon={IconNames.CALCULATOR}
                        title={`${last_caption} ${captions2[key]}`}
                        description="Diese Kreditkennzahl konnte nicht berechnet werden oder sie liegt außerhalb der Wertebereiche."
                    />
                </Card>
            </div>
        );
    }
    private in_range(x_value: number, indication: ICalcCreditSpreadResult){
        return CreditSpreadOptions.in_range(x_value, indication);
    }
    private in_box(x_value: number, y_value: number, indication: ICalcCreditSpreadResult){
        return CreditSpreadOptions.in_box(x_value, y_value, indication);
    }
    private getRatingDef(key, rating: string, x_value: number, y_value: number, indication: ICalcCreditSpreadResult, is_rating: boolean){
        // AAA/AA,A,BBB,BB,B,CCC,CC,C
        // const packet_index = Ratings.as_packet_s_and_p(rating);
        const packet_rating = rating; // Ratings.packet_s_and_p[packet_index];
        const defs = {
            "AAA/AA": {color: "#29bd6e"},
            A: {color: "#e7df3d"},
            BBB: {color: "#e3a967"},
            BB: {color: "#c06930"},
            B: {color: "#d04646"},
            CCC: {color: "#910d0d"},
            "0": {color: "#ababab"},
        };
        const boxed = this.in_box(x_value, y_value, indication);
        if(!boxed && !is_rating){
            return {color: "#00000010"};
        }
        const idx = "0";
        const d = defs[packet_rating];
        if(d){
            return d;
        }else{
            return defs[idx];
        }
    }
    private renderGfx(last_caption: string, key: string) {
        const use_limit = limits[key] ? limits[key] : def_limit;

        const indication: ICalcCreditSpreadResult = this.data.results[key];
        if(!indication){
            return this.noData(last_caption, key);
        }
        if(isNaN(indication.key_value)){
            return this.noData(last_caption, key);
        }
        const mark_companies = this.state.mark_companies ? this.state.mark_companies : [];
        const common_data = indication.data.filter((a)=>{
            return mark_companies.indexOf(a.id) === -1 && a.rating !== a.name;
        });
        const selected_data = indication.data.filter((a)=>{
            return mark_companies.indexOf(a.id) > -1;
        });
        const rating_data: ICalcCreditSpreadResultData[] = [];
        const ratings:{ [index:string] : ICalcCreditSpreadResultData } = {};
        indication.data.forEach((a)=>{
            if(a.name !== a.rating){
                return;
            }
            const r_idx = Ratings.as_packet_s_and_p(a.rating);
            const p_rating = Ratings.packet_s_and_p[r_idx];
            if(!ratings[p_rating]){
                ratings[p_rating] = Object.assign({}, a);
                ratings[p_rating].name = p_rating;
                ratings[p_rating].rating = p_rating;
                rating_data.push(ratings[p_rating]);
            }else{
                ratings[p_rating].all_x = ratings[p_rating].all_x.concat(a.all_x);
                ratings[p_rating].all_y = ratings[p_rating].all_y.concat(a.all_y);
            }
        });
        rating_data.forEach((a)=>{
            a.x = median(a.all_x);
            a.y = median(a.all_y);
            a.count = a.all_x.length;
        });
        let count_bonds_in_x_range = 0;
        const x_range_filter = (a)=>{
            const r =  this.in_range(a.x, indication) && a.name !== a.rating;
            if(r){
                count_bonds_in_x_range += a.count_bonds;
            }
            return r;
        };
        // console.error(captions[key], indication);
        // type: 'logarithmic',
        const data_x_range = [].concat(common_data, selected_data).filter(x_range_filter);
        const data_bar = [0,0,0,0,0,0,0,0];
        const boxed_data_bar = [0,0,0,0,0,0,0,0];

        const data_bar_2 = Ratings.s_and_p.map(()=> 0);
        const boxed_data_bar_2 = Ratings.s_and_p.map(()=> 0);
        let boxed_rating_count = 0;

        let min_x = Number.MAX_VALUE;
        let max_x = Number.MIN_VALUE;
        let boxed_count = 0;

        let count_boxed_bonds = 0;
        let count_rated_bonds = 0;

        data_x_range.forEach((i)=>{
            if(i.x < min_x){
                min_x = i.x;
            }
            if(i.x > max_x){
                max_x = i.x;
            }

            const slot = Math.trunc(i.y);
            const r_idx = Ratings.s_and_p_mapped_to_num[i.rating];
            const b = this.in_box(i.x, i.y, indication);
            if(r_idx >=0 && r_idx<=Ratings.s_and_p.length){
                if(b){
                    count_rated_bonds += i.count_bonds;
                    boxed_rating_count ++;
                    boxed_data_bar_2[r_idx]++;
                }else{
                    data_bar_2[r_idx]++;
                }
            }
            if(b){
                count_boxed_bonds += i.count_bonds;
                boxed_count ++;
                boxed_data_bar[slot]++;
            }else{
                data_bar[slot]++;
            }
        });
        boxed_data_bar.forEach( (a, idx, arr) =>{
            if(a === 0){
                arr[idx] = undefined;
            }
        });

        const options = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        z: 10,
                        padding: 10,
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: use_limit.y[0],
                    suggestedMax: use_limit.y[1],
                },
                x: {
                    ticks: {
                        z: 10,
                        padding: 10,
                        maxTicksLimit: Array.isArray(CreditSpreadOptions.slots[key]) ? CreditSpreadOptions.slots[key].length : undefined,
                        /*maxRotation: 0,*/
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: use_limit.x[0],
                    suggestedMax: use_limit.x[1],
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend: {
                    display: false,
                },
                datalabels: {
                    display: 0,
                },
                tooltip: {
                    enabled: true,
                    mode: "point",
                    callbacks: {
                        label: (item)=> { return item.raw.name !== item.raw.rating ? `${item.raw.name} [${item.raw.rating}] ${item.raw.security_id}` : `${item.raw.name} [${item.raw.count}]`;},
                        title: (item)=> { return undefined;},
                    },
                },
            },
            regression_line: {
                display: "vertical_x",
                regression: indication.regression,
                vertical_x: indication.key_value,
                band_width: CreditSpreadOptions.band_widths[key],
                y_range: indication.y_range,
                x_range: indication.x_range,
                vertical_x_color: "#f47a22",
                color: "rgba(255, 99, 132, 1)",
                lineDash: [5,5],
            },
            custom_axis_label: {
                x_label: `${captions2[key]}`,
                y_label: `${_t(ETranslation.credit_spread)} [% p.a.]`,
            },
            onClick: (e,b,chart) => {
                const activePoints = e.chart.getElementsAtEventForMode(e, "point", {intersect: true}, false);
                if(!Array.isArray(activePoints)){
                    return;
                }
                const _mark_companies = [];
                activePoints.forEach( (i)=>{
                    if(i.element.$context.raw.name!==i.element.$context.raw.rating){
                        _mark_companies.push(i.element.$context.raw.id);
                        console.error(i.element.$context.raw.security_id);
                    }
                });
                this.setState({mark_companies: _mark_companies});
            },
        };
        const options_x_range = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        z: 10,
                        padding: 10,
                        color: "rgba(0, 0, 0, 0.0)",
                    },
                    border:{
                        display: false,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: 0,
                        tickColor: "rgba(0, 0, 0, 0.0)",
                    },
                    min: use_limit.y[0],
                    suggestedMax: use_limit.y[1],
                },
                x: {
                    ticks: {
                        z: 10,
                        padding: 10,
                        maxTicksLimit: Array.isArray(CreditSpreadOptions.slots[key]) ? CreditSpreadOptions.slots[key].length : undefined,
                        /*maxRotation: 0,*/
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: min_x,
                    max: max_x,
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend: {
                    display: false,
                },
                datalabels: {
                    display: 0,
                },
                tooltip: {
                    enabled: true,
                    mode: "point",
                    callbacks: {
                        label: (item)=> { return item.raw.name !== item.raw.rating ? `${item.raw.name} [${item.raw.rating}] ${item.raw.security_id}` : `${item.raw.name} [${item.raw.count}]`;},
                        title: (item)=> { return undefined;},
                    },
                },
            },
            regression_line: {
                display: "vertical_x",
                regression: indication.regression,
                vertical_x: indication.key_value,
                band_width: CreditSpreadOptions.band_widths[key],
                y_range: indication.y_range,
                x_range: indication.x_range,
                vertical_x_color: "#f47a22",
                color: "rgba(255, 99, 132, 1)",
                lineDash: [5,5],
            },
            custom_axis_label: {
                x_label: `${captions2[key]}`,
                y_label: "",
            },
            onClick: (e,b,chart) => {
                const activePoints = e.chart.getElementsAtEventForMode(e, "point", {intersect: true}, false);
                if(!Array.isArray(activePoints)){
                    return;
                }
                const _mark_companies = [];
                activePoints.forEach( (i)=>{
                    if(i.element.$context.raw.name!==i.element.$context.raw.rating){
                        _mark_companies.push(i.element.$context.raw.id);
                        console.error(i.element.$context.raw.security_id);
                    }
                });
                this.setState({mark_companies: _mark_companies});
            },
        };
        const options_bar_chart = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    stacked: true,
                    ticks: {
                        z: 10,
                        padding: 10,
                        color: "rgba(0, 0, 0, 0.0)",
                    },
                    border:{
                        display: false,
                        color: "rgba(0, 0, 0, 0.1)",
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: 0,
                        tickColor: "rgba(0, 0, 0, 0.0)",
                    }
                },
                x: {
                    stacked: true,
                    ticks: {
                        z: 10,
                        padding: 10,
                        maxTicksLimit: Array.isArray(CreditSpreadOptions.slots[key]) ? CreditSpreadOptions.slots[key].length : undefined,
                        /*maxRotation: 0,*/
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend: {
                    display: false,
                },
                datalabels: {
                    display: 1,
                    formatter: (v) => {
                        if (parseInt(v, 10) === 0) {
                            return "";
                        }
                        return v;
                    },
                    align: (v, b, c) => {
                        const set_idx = v.datasetIndex;
                        if(set_idx === 1){
                            return "end";
                        }
                        return "center";
                    },
                },
                tooltip: {
                    enabled: false,
                    mode: "point",
                    callbacks: {
                        label: (item)=> { return undefined;},
                        title: (item)=> { return undefined;},
                    },
                },
            },
            regression_line: {
                display: false,
                regression: indication.regression,
                vertical_x: indication.key_value,
                band_width: CreditSpreadOptions.band_widths[key],
                y_range: indication.y_range,
                y_min: 0,
                x_range: "full",
                vertical_x_color: "#f47a22",
                color: "rgba(255, 99, 132, 1)",
                lineDash: [5,5],
            },
            custom_axis_label: {
                x_label: `# Unternehmen`,
                y_label: "",
            },
        };
        const options_bar_chart_2 = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            scales: {
                y: {
                    min: 0,
                    max: Ratings.s_and_p.length - 1,
                    type: 'linear',
                    display: true,
                    position: 'left',
                    stacked: true,
                    ticks: {
                        z: 10,
                        padding: 10,
                        color: "#485d63",
                        stepSize: 1,
                        maxTicksLimit: 100,
                        callback: (value)=>{
                            // console.error(value);
                            const sp = Ratings.s_and_p[value];
                            const show = Ratings.index_s_and_p[value];
                            if(show===undefined){
                                return "";
                            }
                            const p_sp = Ratings.as_packet_s_and_p(sp);
                            return Ratings.packet_s_and_p[p_sp];
                        },
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: 0,
                        tickColor: "rgba(0, 0, 0, 0.0)",
                    }
                },
                x: {
                    stacked: true,
                    ticks: {
                        z: 10,
                        padding: 10,
                        maxTicksLimit: Array.isArray(CreditSpreadOptions.slots[key]) ? CreditSpreadOptions.slots[key].length : undefined,
                        /*maxRotation: 0,*/
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend: {
                    display: false,
                },
                datalabels: {
                    display: 1,
                    formatter: (v) => {
                        if (parseInt(v, 10) === 0) {
                            return "";
                        }
                        return v;
                    },
                    align: (v, b, c) => {
                        const set_idx = v.datasetIndex;
                        if(set_idx === 1){
                            return "end";
                        }
                        return "center";
                    },
                },
                tooltip: {
                    enabled: false,
                    mode: "point",
                    callbacks: {
                        label: (item)=> { return undefined;},
                        title: (item)=> { return undefined;},
                    },
                },
            },
            custom_axis_label: {
                x_label: `# Unternehmen`,
                y_label: "",
            },
        };
// #DB3737
        const get_bg_color = (a)=>{
            if(a && a.raw && a.raw.id){
                if(Array.isArray(mark_companies) && mark_companies.indexOf(a.raw.id)>=0){
                    return "#78c8ff";
                }
            }
            if(a && a.raw && a.raw.rating){
                const is_rating = a.raw.rating===a.raw.name;
                const d = this.getRatingDef(key, a.raw.rating, a.raw.x, a.raw.y, indication, is_rating);
                if(d){
                    return `${d.color}`;
                }
            }
            return "rgba(255, 99, 132, 1)";
        }
        // console.error(rating_data);
        const rating_line = [].concat(rating_data).sort((a,b)=> a.x - b.x).sort((a,b)=> Ratings.packet_s_and_p_mapped_to_num[a.rating] - Ratings.packet_s_and_p_mapped_to_num[b.rating]);
        const ranges_bg_colors = [];
        const ranges_data_1 = [];
        const ranges_data_2 = [];
        const ranges_data_35 = [];
        const ranges_data_65 = [];
        rating_line.forEach((d)=>{
            ranges_bg_colors.push(this.getRatingDef(key, d.rating, d.x, d.y, undefined, true));
            // ranges_data_1.push({x: d.q1, y: d.y, r: 6, name: d.name, rating: d.name, count: Globals.hyphen});
            // ranges_data_2.push({x: d.q2, y: d.y, r: 6, name: d.name, rating: d.name, count: Globals.hyphen});
            // ranges_data_35.push({x: d.q35, y: d.y, r: 6, name: d.name, rating: d.name, count: Globals.hyphen});
            // ranges_data_65.push({x: d.q65, y: d.y, r: 6, name: d.name, rating: d.name, count: Globals.hyphen});
        });

        const chart_data = {
            datasets: [
                {
                    label: "Rating",
                    data: [].concat(rating_data),
                    backgroundColor: get_bg_color,
                    borderColor: "#13517380",
                },
                {
                    type: 'line',
                    label: 'Rating-Line',
                    data: rating_line,
                    backgroundColor: "#137cbd",
                    borderColor: "#137cbd",
                    borderDash: [7,2,2,2],
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius: 0,
                },
                {
                    label: _t(ETranslation.company),
                    data: [].concat(common_data, selected_data),
                    backgroundColor: get_bg_color,
                },
            ],
        };
        const chart_data_x_range = {
            datasets: [
                {
                    label: _t(ETranslation.company),
                    data: data_x_range,
                    backgroundColor: get_bg_color,
                },
            ],
        };
        const chart_data_bar = {
            labels: data_bar.map((i,idx)=> idx + 1),
            datasets: [
                {
                    type: "bar",
                    indexAxis: "y",
                    label: "Histogram",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    data: data_bar,
                },
                {
                    type: "bar",
                    indexAxis: "y",
                    label: "Histogram",
                    backgroundColor: Globals.shortlist_green,
                    data: boxed_data_bar,
                },
            ],
        };

        const chart_data_bar_2 = {
            labels: Ratings.s_and_p.map((s, i)=> i),
            datasets: [
                {
                    type: "bar",
                    indexAxis: "y",
                    label: "Histogram",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    data: data_bar_2,
                },
                {
                    type: "bar",
                    indexAxis: "y",
                    label: "Histogram",
                    backgroundColor: Globals.shortlist_green,
                    data: boxed_data_bar_2,
                },
            ],
        };
        const chart_info: IChartInfo = {
            key,
            caption: `${last_caption}: ${captions2[key]}`,
            gfx: [
                {
                    width: .4,
                    caption: `Relevantes Marktsegment¹ (#Unternehmen ${Globals.formatter(common_data.length + selected_data.length, 0, 0)}, ${_tn(ETranslation.bonds)} ${Globals.formatter(indication.count_bonds, 0, 0)})`,
                    data: chart_data,
                    options,
                },
                {
                    width: .2,
                    caption: `Long- & Shortlist² (#${data_x_range.length}, #${Globals.formatter(count_bonds_in_x_range, 0, 0)})`,
                    data: chart_data_x_range,
                    options: options_x_range,
                },
                {
                    width: .2,
                    caption: `Verteilung Kreditaufschlag³ (#${boxed_count}, #${Globals.formatter(count_boxed_bonds, 0, 0)})`,
                    data: chart_data_bar,
                    options: options_bar_chart,
                },
                {
                    width: .2,
                    caption: `Verteilung Rating (#${boxed_rating_count}, #${Globals.formatter(count_rated_bonds, 0, 0)})`,
                    data: chart_data_bar_2,
                    options: options_bar_chart_2,
                },
            ],
        };
        this.chart_infos[key] = chart_info;
        return (
            <div style={{padding: 5}}>
                <Card interactive={true} style={{padding: 0, paddingLeft: 10, paddingTop: 10}} className={"sz-card-override"}>
                    <div style={{fontSize: "90%"}}><strong>{last_caption}:&nbsp;{captions2[key]}</strong></div>
                    <div className={"sz-row"} style={{marginTop: 15, marginBottom: 10, fontSize: "80%"}}>
                        <div className={"sz-col sz-col-40"}>
                            <strong>Relevantes Marktsegment¹ (#Unternehmen {Globals.formatter(common_data.length + selected_data.length, 0, 0)}, {_tn(ETranslation.bonds)} {Globals.formatter(indication.count_bonds, 0, 0)})</strong>
                        </div>
                        <div className={"sz-col sz-col-20"}>
                            <strong>Long- & Shortlist² (#{data_x_range.length}, #{Globals.formatter(count_bonds_in_x_range, 0, 0)})</strong>
                        </div>
                        <div className={"sz-col sz-col-20"}>
                            <strong>Verteilung Kreditaufschlag³ (#{boxed_count}, #{Globals.formatter(count_boxed_bonds, 0, 0)})</strong>
                        </div>
                        <div className={"sz-col sz-col-20"}>
                            <strong>Verteilung Rating (#{boxed_rating_count}, #{Globals.formatter(count_rated_bonds, 0, 0)})</strong>
                        </div>
                    </div>
                    <div className={"sz-row"} style={{height: 400, marginTop: 5}}>
                        <div className={"sz-col sz-col-40"}>
                            <Bubble options={options as any} data={chart_data as any} height={"100%"} width={"100%"} />
                        </div>
                        <div className={"sz-col sz-col-20"}>
                            <Bubble options={options_x_range as any} data={chart_data_x_range as any} height={"100%"} width={"100%"} />
                        </div>
                        <div className={"sz-col sz-col-20"}>
                            <Bubble options={options_bar_chart as any} data={chart_data_bar as any} height={"100%"} width={"100%"} />
                        </div>
                        <div className={"sz-col sz-col-20"}>
                            <Bubble options={options_bar_chart_2 as any} data={chart_data_bar_2 as any} height={"100%"} width={"100%"} />
                        </div>
                    </div>
                    <div style={{fontSize: "75%", marginTop: 5, paddingBottom: 10}} className={"bp3-text-muted"}>{Array.isArray(FinKeyLabels.definitions[key]) ? FinKeyLabels.definitions[key].map((s)=><div>{s}</div>): undefined}</div>
                    <div style={{fontSize: "75%", marginTop: 5, paddingBottom: 10}} className={"bp3-text-muted"}>
                        <div style={{display: "flex", alignItems: "center"}}><div style={{backgroundColor: "#f47a22", display: "inline-block", width: 15, height: 15, marginRight: 5}}></div><span>Wert der Kreditkennzahl des betrachteten Unternehmens</span></div>
                        <div style={{display: "flex", alignItems: "center", marginTop: 2}}><div style={{backgroundColor: Globals.shortlist_green, display: "inline-block", width: 15, height: 15, marginRight: 5}}></div><span> Ausgewählter Bereich der Verteilung; in der „neutralen“ Grundeinstellung zentriert über die Verteilung Q(40%) bis Q(60%), jedoch durch die Einstellungen zu den qualitativen Faktoren verschiebbar</span></div>
                        <div style={{display: "flex", alignItems: "center", marginTop: 2}}><div style={{backgroundColor: "rgba(0, 0, 0, 0.1)", display: "inline-block", width: 15, height: 15, marginRight: 5}}></div><span>Nicht berücksichtigter Bereich der Verteilung</span></div>
                    </div>
                    <div style={{fontSize: "75%", marginTop: 5, paddingBottom: 10}} className={"bp3-text-muted"}>
                        <div>¹) Basierend auf den Einstellungen zum Land, dem Supersektor, dem Laufzeitband und den Anleihe Eigenschaften</div>
                        <div>²) Bandbreite um die Kreditkennzahl, innerhalb derer insgesamt {data_x_range.length} Unternehmen liegen; davon {boxed_count} innerhalb der relevanten Quantile (20%  der Verteilung)</div>
                        <div>³) Histogramm der Verteilung (#{data_x_range.length} insgesamt) und relevante Quantile (#{boxed_count})</div>
                    </div>
                </Card>
            </div>
        );
    }
    public getPdfPage(): any[]{
        const keys = CreditSpreadOptions.getMetricsKeys();
        const content =[];
        // PdfInsertElement.page_header(content, "Kreditkennzahlen");
        keys.forEach((k, idx)=>{
            // t.table.body = (new Array(keys.length)).map(()=>[]);
            const info = this.chart_infos[k];
            if(!info){
                return;
            }
            const c = PdfInsertElement.page_header(content, info.caption);
            // const c = PdfInsertElement.h2(content, info.caption);
            // if(idx > 0 && idx%2 === 0){
            //    c.pageBreak = "before";
            // }
            const t = PdfInsertElement.table(content);
            t.layout = "noBorders";
            t.table.widths = [PDF_IMAGE_HEIGHT * .4, PDF_IMAGE_HEIGHT * .2, PDF_IMAGE_HEIGHT * .2, PDF_IMAGE_HEIGHT * .2];
            t.table.body = [];
            const row_data = [];
            t.table.body.push(row_data);

            info.gfx.forEach((g)=>{
                const cell = [];
                row_data.push(cell);

                PdfInsertElement.p(cell, g.caption);
                PdfInsertElement.image(cell, PdfChartToImage.getDataUri(EChartType.bubble, 1600 * g.width, 400, g.options, g.data, 0), PDF_IMAGE_HEIGHT * g.width);

            });
        });

        return content;
    }
}
