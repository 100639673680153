export const DataSetHover = {
    hoverBackgroundColor: "rgba(37,42,49,0.5)",
    hoverBorderColor: "#252A31",
    hoverBorderWidth: 1,
};
export const DataSetStyles = [
    {
        backgroundColor: "rgba(19, 124, 189, 0.2)",
        borderColor: "rgba(19, 124, 189, 1)",
        borderWidth: 1,
    }, {
        backgroundColor: "rgba(32,43,51,0.2)",
        borderColor: "rgba(32,43,51, 1)",
        borderWidth: 1,
    }, {
        backgroundColor: "rgba(15,153,96,0.2)",
        borderColor: "rgba(15,153,96, 1)",
        borderWidth: 1,
    }, {
        backgroundColor: "rgba(217,130,43,0.2)",
        borderColor: "rgba(217,130,43, 1)",
        borderWidth: 1,
    }, {
        backgroundColor: "rgba(113,87,217,0.2)",
        borderColor: "#7157D9",
        borderWidth: 1,
    }, {
        backgroundColor: "rgba(138,155,168,0.2)",
        borderColor: "#8A9BA8",
        borderWidth: 1,
    }, {
        backgroundColor: "rgba(148,106,162,0.2)",
        borderColor: "#946aa2",
        borderWidth: 1,
    }, {
        backgroundColor: "rgba(122,153,143,0.2)",
        borderColor: "#79988e", // 0F9960
        borderWidth: 1,
    },
    {
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(173,13,32)",
        borderWidth: 1,
    },
    {
        backgroundColor: "rgba(150,43,140, 0.2)",
        borderColor: "rgb(150,43,140)",
        borderWidth: 1,
    },
];
