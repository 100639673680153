import {HTMLDivProps} from "@blueprintjs/core";
import MDEditor from "@uiw/react-md-editor";
import * as katex from "katex";
import "katex/dist/katex.css";
import * as React from "react";

const renderers = {
    inlineCode: ({ children }) => {
        if (/^\$\$(.*)\$\$/.test(children)) {
            const html = katex.renderToString(children.replace(/^\$\$(.*)\$\$/, "$1"), {
                throwOnError: false,
                strict: false,
            });
            return <code dangerouslySetInnerHTML={{ __html: html }} />;
        }
        return children;
    },
    code: ({ children, language, value }) => {
        if (!children) {
            children = (<></>);
        }
        if (!value) {
            return children;
        }
        if (language && language.toLocaleLowerCase() === "katex") {
            const html = katex.renderToString(value, {
                throwOnError: false,
                strict: false,
            });
            return (
                <pre>
                    <code dangerouslySetInnerHTML={{ __html: html }} />
                </pre>
            );
        }
        return children;
    },
};

export interface ISzMdViewerProps {
    value: string;
}

export class SzMdViewer extends React.Component<ISzMdViewerProps & HTMLDivProps, any> {

    public render() {
        return (
            <MDEditor.Markdown source={this.props.value} renderers={renderers} className={"sz-md"} />
        );
    }
}
