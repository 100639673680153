import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";
import {SicRanges} from "../../../const/Sic";

export class SicParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.SicParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.SicParameter);
    }
    protected async getOptions() {
        return [{label: "Alle", value: "0"}].concat(SicRanges.map((i, idx)=> ({label: i.text, value: "" + (idx + 1)})));
    }
}
