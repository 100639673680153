import {
    Alignment,
    Button,
    Classes,
    Drawer,
    DrawerProps,
    Icon,
    Intent,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    Position,
    Toaster,
} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {EStatisticsValueType} from "../const/EStatisticsValueType";
import {Globals} from "../const/Globals";
import {getResult} from "../helpers/Helpers";
import {IWpTag} from "../models/IWpTag";
import {PublicDbCalls} from "../services/PublicDbCalls";
import {FileManager} from "./FileManager";
import {PageDocumentation} from "./widgets/PageDocumentation";
import {PageMethodology} from "./widgets/PageMethodology";
import {PageProjects} from "./widgets/PageProjects";
import {EModuleNames} from "../models/EModuleNames";
import {Tooltip2} from "@blueprintjs/popover2";
import {EventBus, IEventHandler} from "../services/EventBus";
import {SessionStore} from "../const/SessionStore";
import {ServiceCalls} from "../services/ServiceCalls";
import {EProjectType, IProject} from "../models/IProject";
import {_t} from "../tools/Translator";
import {ETranslation} from "../const/ETranslation";
import {PageSysInfo} from "./widgets/PageSysInfo";
import {PageStats} from "./widgets/PageStats";

export enum EToolbarPage {
    FileManager,
    DataSources,
    FAUB,
    Glossary,
    Method,
    Projects,
    Documentation,
    SysInfo,
    Stats,
}
export interface ISideBarProps {
    name?: string;
}
interface ISideBarState {
    currentPage: EToolbarPage;
    isOpen?: boolean;
    // wpPosts: IWpPost[];
    // wpCategories: IWpCategory[];
    wpTags: IWpTag[];
}
export class SideBar extends React.Component<ISideBarProps, ISideBarState> {
    private readonly evtOpenPage: IEventHandler;

    constructor(props: ISideBarProps, context: ISideBarState) {
        super(props, context);

        this.state = {
            isOpen: false,
            currentPage: null,
            wpTags: [],
        };

        this.evtOpenPage = EventBus.subscribe<EToolbarPage>("SideBar::openPage", (page) => this.openPage(page) );
    }


    componentWillUnmount() {
        EventBus.unsubscribe(this.evtOpenPage);
    }

    public render() {
        const props: DrawerProps = {
            className: "sz-sidebar-drawer",
            size: "90%",
            isOpen: this.state.isOpen,
            canOutsideClickClose: true,
            canEscapeKeyClose: true,
            position: Position.RIGHT,
            usePortal: true,
            portalClassName: "z-index-20000001",
            onClose: (e) => this.handleClose(e),
        };
        const overrideBody: CSSProperties = {
            margin: 0,
            width: "100%",
            height: "100%",
        };
        const switchPage = (page: EToolbarPage) => {
            if (page === EToolbarPage.FileManager) {
                return this.renderFileManager();
            }
            if (page === EToolbarPage.FAUB) {
                return this.renderFaub();
            }
            if (page === EToolbarPage.Glossary) {
                return this.renderGlossary();
            }
            if (page === EToolbarPage.Method) {
                return this.renderMethod();
            }
            // if (page === EToolbarPage.Projects) {
            //     return this.renderProjects();
            // }
            if (page === EToolbarPage.Documentation) {
                return this.renderDocumentation();
            }
            if (page === EToolbarPage.SysInfo) {
                return this.renderSysInfo();
            }
            if (page === EToolbarPage.Stats) {
                return this.renderStats();
            }
            return null;
        };
        const getButtonProps =  (page: EToolbarPage, currentPage: EToolbarPage) => {
            return {
                intent: page === currentPage ? Intent.PRIMARY : Intent.NONE,
                onClick: () => this.openPage(page),
            };
        };
        const renderAdmin = ()=> {
            if(!Globals.isAdmin){
                return null;
            }
            return (
                <>
                    <NavbarDivider />
                    <Button className={Classes.MINIMAL} icon={IconNames.CHART} text="Status-Log" {...getButtonProps(EToolbarPage.Stats, this.state.currentPage)} />
                </>
            );
        };
        return (
            <Drawer {...props}>
                <div className={Classes.DRAWER_HEADER} style={{padding: 0}}>
                    <Navbar className={`${Classes.DARK}`} style={{height: 43}}>
                        <NavbarGroup align={Alignment.LEFT} className={`sz-button-extra-margin`} style={{height: 43}}>
                            <Button className={Classes.MINIMAL} icon={IconNames.FOLDER_OPEN} text="Dateien" {...getButtonProps(EToolbarPage.FileManager, this.state.currentPage)} />
                            <NavbarDivider />
                            <Button className={Classes.MINIMAL} icon={IconNames.INBOX_GEO} text="IDW News" {...getButtonProps(EToolbarPage.FAUB, this.state.currentPage)} />
                            <Button className={Classes.MINIMAL} icon={IconNames.HELP} text={_t(ETranslation.glossary)} {...getButtonProps(EToolbarPage.Documentation, this.state.currentPage)} />
                            <Button className={Classes.MINIMAL} icon={IconNames.INFO_SIGN} text="Systeminformation" {...getButtonProps(EToolbarPage.SysInfo, this.state.currentPage)} />
                            {renderAdmin()}
                        </NavbarGroup>
                        <NavbarGroup align={Alignment.RIGHT} style={{paddingRight: 10, height: 43}}>
                            <Tooltip2
                                content={<span>Schließen</span>}
                                position={Position.BOTTOM}
                                usePortal={true}
                                openOnTargetFocus={false}
                            >
                                <Button className={Classes.MINIMAL} icon={IconNames.CROSS} intent={Intent.DANGER} onClick={(e) => this.handleClose(e)} />
                            </Tooltip2>
                        </NavbarGroup>
                    </Navbar>
                </div>
                <div className={Classes.DRAWER_BODY}>
                    <div className={Classes.DIALOG_BODY} style={overrideBody}>
                        {switchPage(this.state.currentPage)}
                    </div>
                </div>
            </Drawer>
        );
    }
    private renderGlossary() {
        const tags = this.state.wpTags;
        const getContent = (p: IWpTag) => {
            const t: string = p.description ? p.description.trim() : null;
            if (t && t.length > 0) {
                return t;
            }
            return "Zu den Beiträgen";
        };
        const renderLegend = (items: string[]) => {
            return (
                <div className={"sz-tag-legend"}>
                    {items.map( (i) => <a className={`sz-tag-legend-item`} href={`#taglegend${i}`}>{i}</a> )}
                </div>
            );
        };
        const renderTag = (p: IWpTag, idx) => {
            return (
                <div className={"sz-tag"} style={idx === 0 ? {marginLeft: 0} : {}}>
                    <Tooltip2
                        content={getContent(p)}
                        usePortal={true}
                        popoverClassName={"sz-tooltip"}
                        openOnTargetFocus={false}
                    >
                        <a href={`https://www.smart-zebra.de/tag/${p.slug}/`} className={`${Classes.TEXT_MUTED} sz-wp-post-ref`} target={"_blank"}>{p.name}</a>
                    </Tooltip2>
                </div>
            );
        };

        const content = [];
        const abc = {};
        tags.forEach( (p, idx) => {
            const firstLetter = (p.name && p.name.length > 0 ? p.name[0] : "").toUpperCase();
            if (!abc[firstLetter]) {
                abc[firstLetter] = [];
            }
            abc[firstLetter].push(renderTag(p, abc[firstLetter].length));
        } );

        Object.keys(abc).forEach( (key, idx) => {
            content.push(<span id={`taglegend${key}`}> </span>);
            content.push(<h4>{key}</h4>);
            const items = abc[key];
            for (let i = 0; i < items.length; i++ ) {
                content.push(items[i]);
                if (i + 1 < items.length) {
                    content.push(<Icon icon={IconNames.DOT} color={"#bdbdbd"} />);
                }
            }
        } );

        return (
            <div style={{padding: 10, position: "relative", display: "flex", justifyItems: "flex-start", flexDirection: "column"}}>
                {renderLegend(Object.keys(abc))}
                <div className={"sz-tag-list"}>{content.map( (c) => c )}</div>
            </div>
        );
    }
    private renderFaub() {
        const style: CSSProperties = {
            marginBottom: 0,
            boxShadow: "0 1px 0 0 rgba(16, 22, 26, 0.15)",
        };
        return (
            <div style={{margin: 10, marginTop: 0}}>
                <table className={"sz-table bp3-html-table bp3-html-table-condensed sz-small-padding"}>
                    <tbody>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{paddingBottom: 0}}><h4 style={style}>[20.03.2022] Vorläufig keine Änderung der Marktrisikoprämie</h4></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}><span className={"bp3-text-muted"}>Das IDW hat am 20.03.2022 zu möglichen Auswirkungen des Russland Kriegs gegen die Ukraine auf die Kapitalkosten Stellung genommen. Der FAUB betont dabei, dass die empfohlene Bandbreite von 6,0% bis 8,0% (vor persönlichen Steuern) aus langfristig orientierten Analysen abgeleitet ist. Gründe für eine Änderung der Methodik sieht der FAUB nicht und behält die Bandbreitenempfehlung bei.</span></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}>&nbsp;</td></tr>

                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{boxShadow: "none", paddingBottom: 0}}><h4 style={style}>[25.03.2020] Keine Änderung der Methodik Kapitalisierungszinssatz</h4></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}><span className={"bp3-text-muted"}>Das IDW hat am 25.03.2020 zu den wirtschaftlichen Auswirkungen des Coronavirus auf Unternehmensbewertungen Stellung genommen. Zum Zeitpunkt der Veröffentlichung sieht das IDW keinen Anlass zur Änderungen der Methodik zur Bestimmung des Kapitalisierungszinssatzes in der Unternehmensbewertung. Die Bandbreitenempfehlung für die Marktrisikoprämie vor persönlichen Steuern in einer Größenordnung von 6,0% bis 8,0% vom 25.10.2019 gilt weiterhin.</span></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}>&nbsp;</td></tr>

                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{paddingBottom: 0}}><h4 style={style}>[25.10.2019] Anhebung der Marktrisikoprämie</h4></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}><span className={"bp3-text-muted"}>Der Fachausschuss für Unternehmensbewertung und Betriebswirtschaft des IDW (FAUB) hat in seiner Sitzung am 25.10.2019 beschlossen, seine Empfehlung für die Marktrisikoprämie vor persönlichen Steuern auf 6 - 8 % anzuheben. Damit hat sich der FAUB tendenziell am unteren Ende beobachtbarer Gesamtrenditen orientiert und möchte damit der Möglichkeit Rechnung tragen, dass diese im Zeitverlauf weiter leicht nachgeben könnten.</span></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}><span className={"bp3-text-muted"}>Für die Ermittlung objektivierter Unternehmenswerte ist grundsätzlich der Einfluss persönlicher Steuern der Anteilseigner zu berücksichtigen. Dies gilt nicht nur für den risikolosen Basiszins, sondern auch für die Marktrisikoprämie. Ausgehend von der Empfehlung vor persönlichen Steuern hat der FAUB vor dem Hintergrund des geltenden Abgeltungssteuersystems eine Überleitung in eine Welt nach persönlichen Steuern vorgenommen. Dies führt zu einer entsprechenden leichten Anpassung der Empfehlung für die Marktrisikoprämie nach persönlichen Steuern auf eine Bandbreite von nunmehr 5 - 6,5%.</span></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}>&nbsp;</td></tr>

                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{paddingBottom: 0}}><h4 style={style}>[01.10.2018] Verschuldungsgrad in der Unternehmensbewertung</h4></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}><span className={"bp3-text-muted"}>Am 12. September 2018 hat der Fachausschuss für Unternehmensbewertung und Betriebswirtschaft (FAUB) des IDW einen IDW Praxishinweis zur Berücksichtigung des Verschuldungsgrades bei der Bewertung von Unternehmen (Praxishinweis 2/2018) verabschiedet. Anknüpfend an den IDW S1, arbeitet der IDW Praxishinweis die Besonderheiten bei der objektivierten Bewertung hoch verschuldeter Unternehmen herausgearbeitet und gibt Hinweise, wie diese Besonderheiten praktisch umzusetzen sind. Dabei wird insbesondere im Zusammenhang mit der Bewertung hochverschuldeter Unternehmen und die Verwendung einer Debt Betas angesprochen, um einer Überschätzung der Eigenkapitalrisiken zu vermeiden.</span></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}>&nbsp;</td></tr>

                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{paddingBottom: 0}}><h4 style={style}>[13.07.2016] Rundungsempfehlung Basiszinssatz</h4></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}><span className={"bp3-text-muted"}>Gemäß den Vorgaben des IDW (FN 08/2016) ist bei Zinssätzen kleiner 1,0% auf 0,10% zu runden. Bei Zinssätzen größer 1,0% kommt die ursprüngliche Empfehlung des FAUB zum tragen nach der auf 0,25% gerundet wird.</span></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}>&nbsp;</td></tr>

                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{paddingBottom: 0}}><h4 style={style}>[19.09.2012] Anhebung der Marktrisikoprämie</h4></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}><span className={"bp3-text-muted"}>Vor dem Hintergrund der anhaltenden Finanzmarktkrise hat der Fachausschuss für Unternehmensbewertung und Betriebswirtschaft des IDW (FAUB) am 19. September 2012 die Bandbreitenempfehlung für die Marktrisikoprämie ab diesem Zeitpunkt auf 5,5 % bis 7,0 % vor persönlichen Steuern bzw. 5,0 % bis 6,0 % nach persönlichen Steuern angehoben.</span></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}>&nbsp;</td></tr>

                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{paddingBottom: 0}}><h4 style={style}>[18.10.2005] Rundungsempfehlung Basiszinssatz</h4></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}><span className={"bp3-text-muted"}>Gemäß den Vorgaben des IDW ist der Basiszinssatz auf 0,25% zu runden.</span></td></tr>
                    <tr><td className={"sz-no-pd-lr"} colSpan={2} style={{textAlign: "justify"}}>&nbsp;</td></tr>

                    </tbody>
                </table>
            </div>
        );
    }
    private renderMethod() {
        return <PageMethodology />;
    }
    private renderProjects() {
        return <PageProjects />;
    }

    private renderFileManager() {
        const style: CSSProperties = {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
        };
        const containerStyle: CSSProperties = {
            top: 51,
            left: 0,
            right: 0,
            bottom: 0,
            position: "absolute",
            margin: 0,
            overflowY: "scroll",
        };
        const headerStyle: CSSProperties = {
            left: 0,
            top: 0,
            width: "100%",
            position: "sticky",
            margin: 0,
            zIndex: 10,
            boxShadow: "0 1px 0 0 rgba(16, 22, 26, 0.15)",
        };
        const contentTableStyle: CSSProperties = {
            width: "100%",
            position: "relative",
            margin: 0,
            marginTop: 0,
        };
        return (
            <div style={{position: "relative", width: "100%", height: "100%"}}>
                <FileManager style={style} contentStyle={containerStyle} headerStyle={headerStyle} contentTableStyle={contentTableStyle} />
            </div>
        );
    }
    private handleClose(e) {
        document.querySelector("html").classList.remove("bp3-overlay-open");
        this.setState({isOpen: false});
    }
    private openPage(page: EToolbarPage) {
        let f;
        switch (page) {
            case EToolbarPage.Projects: f = "Projekte"; break;
            case EToolbarPage.FileManager: f = "Dateien"; break;
            case EToolbarPage.DataSources: f = "Quellenverzeichnis"; break;
            case EToolbarPage.Method: f = "Methodologie"; break;
            case EToolbarPage.Documentation: f = _t(ETranslation.glossary); break;
            case EToolbarPage.FAUB: f = "IDW News"; break;
            case EToolbarPage.SysInfo: f = "Systeminformation"; break;
            case EToolbarPage.Stats: f = "Statistiken"; break;
            default: f = "Projekte";
        }

        Globals.trackInfo(EStatisticsValueType.side_tab_changed, f);
        document.querySelector("html").classList.add("bp3-overlay-open");
        if (page !== EToolbarPage.Glossary) {
            this.setState({
                isOpen: true,
                currentPage: page,
            });
            return;
        }
        ( async () => {
            const db = new PublicDbCalls();
            const tags = await db.getTags();
            console.error(tags);

            this.setState({
                isOpen: true,
                currentPage: page,
                wpTags: getResult<IWpTag[]>(tags, []),
            });
        } )();
    }

    private renderDocumentation() {
        return <PageDocumentation />;
    }
    private renderSysInfo() {
        return <PageSysInfo />;
    }
    private renderStats() {
        if (Globals.isAdmin) {
            return <PageStats />;
        } else {
            return null;
        }
    }
}
