// https://react-chartjs-2.netlify.app/examples/vertical-bar-chart
// https://www.chartjs.org/docs/latest/charts/radar.html
import {Alignment, Classes, Icon, Menu, MenuItem, Switch,} from "@blueprintjs/core";
import * as React from "react";
import {DataSetStyles} from "../../const/DataSetStyles";
import {EStatisticsValueType} from "../../const/EStatisticsValueType";
import {Globals} from "../../const/Globals";
import {EModuleNames} from "../../models/EModuleNames";
import {IStatisticValue} from "../../models/IStatisticValue";
import {IUser} from "../../models/IUser";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {ServiceCalls} from "../../services/ServiceCalls";
import {EIndexFunction, SzTableHelper} from "./helper/SzTableHelper";
import {SzDateButton} from "./SzDateButton";
import {SzSvgLoader} from "./SzSvgLoader";
import {ISzTableColumn, ISzTableProperties, SzTable} from "./SzTable";
import {SzLineChart} from "./SzLineChart";
import moment = require("moment");
import {DateTime} from "luxon";

enum EStatisticDataSetContext {
    daily_usage= 1,
    monthly_usage,
    weekly_usage,
    excel_export,
    pdf_export,
    side_bar_usage,
    parameters_weekly_change,
}
interface IStatisticDataSet {
    context: EStatisticDataSetContext;
    labels: any[];
    dataSet: any[];
}


interface IFilterConfig {
    user_filter: boolean;
    unique_filter: boolean;
}

const activated_modules: {module: EModuleNames, title: string}[] = [
    {module: EModuleNames.BetaFactor, title: "Beta-Faktor"},
    {module: EModuleNames.CostOfCapital, title: "Kapitalkosten"},
    {module: EModuleNames.Multiples, title: "Multiplikatoren"},
    {module: EModuleNames.Benchmarking, title: "Benchmarking"},
    {module: EModuleNames.TransferPricing, title: "TransferPricing"},
    {module: EModuleNames.CreditSpreads, title: "Kreditaufschläge"},
];

const filter_users = [
    "5efb142e64e89818cc963c11",
    "59de06fc5e75ef2471fe5a97",
    "5b4454d059fcfe005cb13734",
    "5c80d303bf6bed0a675229e2",
    "5c59a7c55d42ab46d4fd4335",
    "5d77b6c6d29c716788eb4d64",
    "5d78b3bcd29c716788eb4d6b",
    "5e675d6008273805afffbb98",
    "5e6659dcb3f6d4139368259f",
    "5e81b39006c46818a797c339",
    "5c5afcbb5d42ab46d4fd4391",
    "61dc178c7a0712c0fa72a41f",
    "5a16eec95e75ef2471fe5b7e",
    "5e148c0b29717c3b140e2e7d",
    "5c69ac1a5d42ab46d4fd4583",
    "5c473ccfc67a4103748439ab",
    "5e860f3c86395504c96dbcf0",
    "5bf66fd259fcfe005cb1391d",
];

interface IPageStatsState {
    isLoading: boolean;
    page: EModuleNames;
    user_filter: boolean;
    unique_filter: boolean;
}

export class PageStats  extends React.Component<any, IPageStatsState> {

    private selectedMonth: string;
    private selectedYear: string;

    private dataSets: Map<EModuleNames, IStatisticDataSet[]> = new Map<EModuleNames, IStatisticDataSet[]>();

    private raw_module_usage_data: IStatisticValue[] = [];
    private users: IUser[] = [];

    private userLogins= {};
    private user_details= {};



    constructor(props: any, context: IPageStatsState) {
        super(props, context);
        // moment
        this.state = {
            page: EModuleNames.users,
            isLoading: true,
            user_filter: true,
            unique_filter: true,
        };

        this.selectedYear = moment().format("YYYY");
        this.selectedMonth = moment().format("MM");

    }
    public componentDidMount() {
        try {
            this.loadPage(this.state.page, {user_filter: this.state.user_filter, unique_filter: this.state.unique_filter});
        }catch (e) {
            console.error(e);
        }
    }

    public render() {
        const renderPage = () => {
            if (this.state.isLoading) {
                return this.renderLoading();
            }
            return this.renderUsers();
        };
        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return "";
            }
        };
        const onClickMenu = (p: EModuleNames) => {
            if (this.state.page === p) {
                return;
            }
            this.setState({
                page: p,
                isLoading: true,
            });
            this.loadPage(p, {user_filter: this.state.user_filter, unique_filter: this.state.unique_filter});
        };
        return (
            <div style={{width: "100%", height: "100%", display: "flex"}}>
                <div style={{width: "100%", height: 40, display: "flex", alignItems: "center", position: "fixed", borderBottom: "1px solid rgba(16, 22, 26, 0.15)", backgroundColor: "#ffffff"}}>
                    <div style={{width: 250}}>
                        <SzDateButton onDateChanged={ (m, y) => this.handleDateChanged(m, y) } />
                    </div>
                    <Switch onClick={() => this.loadPage(this.state.page, {user_filter: !this.state.user_filter, unique_filter: this.state.unique_filter})} labelElement={"smartZebra ausschliessen"} innerLabelChecked="an" innerLabel="aus" checked={this.state.user_filter} alignIndicator={Alignment.RIGHT} style={{marginBottom: 0, marginLeft: 24}} />
                    <Switch onClick={() => this.loadPage(this.state.page, {user_filter: this.state.user_filter, unique_filter: !this.state.unique_filter})} labelElement={"Unique-User"} innerLabelChecked="an" innerLabel="aus" checked={this.state.unique_filter} alignIndicator={Alignment.RIGHT} style={{marginBottom: 0, marginLeft: 24}} />
                </div>
                <div style={{width: "100%", height: "calc(100% - 41px)", display: "flex", marginTop: 41}}>
                    <div className={"bp3-dark"} style={{height: "100%", display: "flex", overflow: "auto"}}>
                        <Menu className={Classes.ELEVATION_1}>
                            <MenuItem className={"bp3-dark"} labelElement={getIcon(EModuleNames.users === this.state.page)} onClick={() => onClickMenu(EModuleNames.users)} text={"User Interaction"} />
                        </Menu>
                    </div>
                    <div style={{width: "calc(100% - 180px)", height: "100%", display: "flex", overflow: "auto"}}>
                        {renderPage()}
                    </div>
                </div>

            </div>
        );
    }
    private renderLoading() {
        return (
            <div style={{margin: 0}}>
                <div style={{paddingTop: 250}}>
                    <SzSvgLoader duration={2} />
                </div>
            </div>
        );
    }

    private handleDateChanged(m: string, y: string) {
        this.selectedMonth = m;
        this.selectedYear = y;
        this.setState({
            isLoading: true,
        });
        this.loadPage(this.state.page, {user_filter: this.state.user_filter, unique_filter: this.state.unique_filter});
    }
    private renderUsers() {
        const mm: {module: EModuleNames, title: string}[] = activated_modules.concat([{module: EModuleNames.db_request, title: "#DB-Req"}]);
        const data: IStatisticValue[] = this.raw_module_usage_data;
        const group_by = "GGGGWW";
        const group_by_name = "week";
        const txt_label_format = "WW GG";
        const user_data: Map<string, Map<number,Map<EModuleNames,number>>> = new Map<string, Map<number,Map<EModuleNames,number>>>();

        let min_date = Number.MAX_SAFE_INTEGER;
        let max_date = 0;

        const user_map = {};
        this.users.forEach( (i) => user_map[i._id] = i );
        data.forEach( (i) => {
            if(!user_data.has(i.user)){
                user_data.set(i.user, new Map<number,Map<EModuleNames,number>>());
            }
            const date_map= user_data.get(i.user);
            const date = parseInt(moment(i.date).format(group_by), 10);

            if(date < min_date){
                min_date = date;
            }
            if(date > max_date){
                max_date = date;
            }

            if(!date_map.has(date)){
                date_map.set(date, new Map<EModuleNames,number>());
            }
            const module_map = date_map.get(date);
            if(!module_map.has(i.name as EModuleNames)){
                module_map.set(i.name as EModuleNames, 0);
            }
            const v = module_map.get(i.name as EModuleNames) + 1;
            module_map.set(i.name as EModuleNames, v);
        });
        const number_labels = [];
        const txt_labels = [];
        let current_date = min_date;
        do{
            const txt = moment(current_date, group_by).format(txt_label_format);
            txt_labels.push(txt);
            number_labels.push(current_date)
            current_date = parseInt(moment(current_date, group_by).add(1, "week").format(group_by), 10);
        }while (current_date <= max_date);

        const empty_module_map: Map<EModuleNames,number> = new Map<EModuleNames,number>();
        const empty_module_object = {};
        mm.forEach( (i) => {
            empty_module_map[i.module] = 0;
            empty_module_object[i.module] = 0;
        });

        // const user_gfx_datas = [];
        const user_gfx_data_2 = (new Array<number>(number_labels.length)).fill(0);
        const user_interactions = {};
        const user_interactions_m = {};
        const user_module_interaction = {};
        const user_module_interaction_arr = [];
        for (const user_id of user_data.keys()) {
            const date_map= user_data.get(user_id);

            let user_stats = user_module_interaction[user_id];

            if(!user_stats){
                const user = user_map[user_id];
                user_stats = Object.assign(user ? user : {}, empty_module_object, user ? user : {});
                const email = user_stats.email ? user_stats.email : "";

                user_stats.last_login = this.userLogins[email.toLowerCase()] ? this.userLogins[email.toLowerCase()].last_login : 0;
                user_stats.cc_login = this.userLogins[email.toLowerCase()] ? this.userLogins[email.toLowerCase()].cc_login : 0;
                user_stats.cc_devices = this.user_details[user_id] ? this.user_details[user_id].cc : Globals.hyphen;
                user_stats.cc_prj = this.user_details[user_id] ? this.user_details[user_id].count_prj : Globals.hyphen;

                user_module_interaction[user_id] = user_stats;
                user_module_interaction_arr.push(user_stats);
            }

            number_labels.forEach( (date, number_labels_idx) => {
                const module_map: Map<EModuleNames,number> = date_map.has(date) ? date_map.get(date) : empty_module_map;
                mm.forEach( (i, idx) => {
                    if(module_map.has(i.module)){
                        const m = module_map.get(i.module);

                        if(user_stats[i.module]){
                            const old = user_stats[i.module];
                            // console.error("inc ...", user_stats[i.module], old);
                            user_stats[i.module] = old + m;
                        }else{
                            user_stats[i.module] = m;
                        }
                    }
                });
                const a = "all" as EModuleNames;
                if(module_map.size>0){
                    const m = module_map.get(a);

                    if(user_stats[a]){
                        const old = user_stats[a];
                        // console.error("inc ...", user_stats[i.module], old);
                        user_stats[a] = old + m;
                    }else{
                        user_stats[a] = m;
                    }
                    if(!user_interactions[`${user_id}_${date}`]){
                        user_interactions[`${user_id}_${date}`] = true;
                        user_gfx_data_2[number_labels_idx] ++;
                    }
                }
            });
            // console.error(user_stats.email, date_map);

        }
        // console.error(user_data);
        // console.error(user_gfx_datas);
        const uq_data_sets =[Object.assign({label: "Unique User-Interaktion", data: user_gfx_data_2}, DataSetStyles[1])];

        const header: ISzTableColumn[] = [];
        header.push({ text: "#" });
        header.push({ text: "EMail", sortable: true });
        header.push({ text: "Last Login", sortable: true });
        header.push({ text: "#Login", sortable: true , options: {style: {textAlign: "right"}}});
        header.push({ text: "#Devices", sortable: true , options: {style: {textAlign: "right"}}});
        header.push({ text: "#Prj", sortable: true , options: {style: {textAlign: "right"}}});

        mm.forEach( (i, idx) => {
            header.push({ text: i.title, sortable: true, options: {style: {width: 110, textAlign: "right"}} });
        });
        const columns: ISzTableColumn[] = [];

        const lastLoginColumn: ISzTableColumn = {
            index: "last_login",
            formatter: (v: any):string => {
                if(v === 0){
                    return undefined;
                }
                if(v){
                    // console.error(DateTime.fromMillis(Number(v)).toFormat("dd.LL.yyyy HH:mm"));
                    return DateTime.fromMillis(Number(v)).toFormat("dd.LL.yyyy HH:mm");
                }
                return undefined;
            }
        };
        const email_column: ISzTableColumn = SzTableHelper.columnIndex("email");
        email_column.onClick = (a,b,c)=>{
            // console.error(c);
        };
        columns.push({ ...SzTableHelper.columnFunction(EIndexFunction.$row_num), options: {style: {width: 10} }}); // index
        columns.push(email_column);
        columns.push(lastLoginColumn);
        columns.push({ ...SzTableHelper.columnMoney("cc_login", 0, {style: {width: 50}})});
        columns.push({ ...SzTableHelper.columnMoney("cc_devices", 0, {style: {width: 50}})});
        columns.push({ ...SzTableHelper.columnMoney("cc_prj", 0, {style: {width: 50}})});
        mm.forEach( (i, idx) => {
            columns.push({ index: i.module, options: {style: {width: 110, textAlign: "right"}} }); // { ...SzTableHelper.columnIndex(i.module, 110)});
        });
        // console.error(user_module_interaction_arr);
        const tableProps: ISzTableProperties<any[]> = {
            colCount: columns.length,
            data: user_module_interaction_arr,
            header: [ header ],
            undefinedIsEmpty: true,
            columns,
        };
        const legend_2 = {
            display: false
        };
        return (
            <div style={{width: "100%", paddingLeft: 10}}>
                <div className={"sz-row"} style={{marginBottom: 32}}>
                    <SzLineChart title={undefined} legend={legend_2} labels={txt_labels} data_sets={uq_data_sets} />
                </div>
                <SzTable {...tableProps} />
            </div>
        );
    }

    private getDates(months: number) {
        const d = `${this.selectedMonth}.${this.selectedYear}`;

        const month_end = this.selectedMonth;
        const year_end = this.selectedYear;

        const d2 = moment(d, "MM.YYYY").startOf("month").subtract(months, "month").endOf("month").format("MMYYYY");
        const month_start = d2.substring(0, 2);
        const year_start = d2.substring(2);
        return {year_start, year_end, month_start, month_end};
    }
    private async getStats(filter_config: IFilterConfig, name, value_type, year_start, year_end, month_start, month_end): Promise<IStatisticValue[]> {
        const cb = new ServiceCalls();
        try {
            let result: IStatisticValue[] = await cb.getStats(name, value_type, year_start, year_end, month_start, month_end);
            if (filter_config.user_filter) {
                result = result.filter( (i) => !filter_users.includes(i.user) );
            }
            return result;
        } catch (exi) {
            console.error(exi);
        }
        return [];
    }
    private async ldUsersData(filter_config: IFilterConfig) {
        const module: EModuleNames = EModuleNames.users;
        this.dataSets[module] = [];
        const {year_start, year_end, month_start, month_end} = this.getDates(11);
        try {
            /*
                        const filterFnk = (i: IStatisticValue) => {
                            const d = moment(i.date).format("MMYYYY");
                            return d !== `${this.selectedMonth}.${this.selectedYear}`;
                        };
            */

            const result = await this.getStats(filter_config, undefined,  EStatisticsValueType.module_usage, year_start, year_end, month_start, month_end);
            this.raw_module_usage_data = result;

            const cb = new ServiceCalls();
            const pc = new PublicDbCalls();
            this.users = await cb.getUsers();
            const user_id_map = {};
            this.users.forEach((u)=> user_id_map[u._id] = u);
            const cc_ud_r = await pc.countUserDetails();
            this.user_details = {};
            if(Array.isArray(cc_ud_r)){
                cc_ud_r.forEach((i)=> this.user_details[i.user_id] = {cc:i.cc, count_prj: 0});
            }
            // const r: any[] = await cb.getUserLogins();
            result.forEach( (i) => {
                if(i.name === "Application"){
                    // console.error(i.date, i.user);
                    const last_login = DateTime.fromISO(i.date).toMillis();
                    const user: IUser = user_id_map[i.user];
                    if(!user){
                        return;
                    }
                    // i.date
                    if(!this.userLogins[user.email.toLowerCase()]){
                        this.userLogins[user.email.toLowerCase()] = {
                            user_id: i.user,
                            last_login: "" + last_login,
                            cc_login: "0",
                        };
                    }
                    const ll = parseInt(this.userLogins[user.email.toLowerCase()].last_login, 10);
                    const cc = parseInt(this.userLogins[user.email.toLowerCase()].cc_login, 10) + 1;
                    this.userLogins[user.email.toLowerCase()].cc_login = "" + cc;
                    if(last_login>ll){
                        this.userLogins[user.email.toLowerCase()].last_login = "" + last_login;
                        // this.userLogins[user.email.toLowerCase()].date = i.date;
                        // this.userLogins[user.email.toLowerCase()].date_2 = DateTime.fromISO(i.date).toFormat("dd.LL.yyyy HH:mm");
                    }
                }
            });
            // console.error(this.userLogins);
            const cp: any[] = await cb.countUserProjects();
            cp.forEach( (i) => {
                if(!this.user_details[i._id]){
                    this.user_details[i._id] = {cc: 0, count_prj: 0};
                }
                this.user_details[i._id].count_prj= i.count;
            });
            // wöchentliche userstats ...
            // const data: IStatisticDataSet = await this.loadAndPostProcess(result, filter_config, EStatisticDataSetContext.weekly_usage, "wo YY", "user", true);
            // this.dataSets[module].push(data);
            // console.error(this.userLogins);
        } catch (exi) {
            console.error(exi);
        }
    }
    private loadPage(page: EModuleNames, filter: IFilterConfig) {
        (async () => {
            switch (page) {
                case EModuleNames.users: { await this.ldUsersData(filter); break; }
            }

            this.setState({
                page,
                user_filter: filter.user_filter,
                unique_filter: filter.unique_filter,
                isLoading: false,
            });
        })();
    }
}
