import {EContentType} from "../../const/EContentType";
import {EPackageTypes} from "../../const/EPackageTypes";
import {ELayoutColumnWidth} from "../../models/ELayoutColumnWidth";
import {EParameters} from "../../models/EParameters";
import {IColumnLayout} from "../../models/IColumnLayout";
import {ColumnLayouterImpl} from "../ColumnLayouterImpl";
import {ModuleGroup} from "../ModuleGroup";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export class TestModModule extends ModuleGroup<IColumnLayout> {
    constructor() {
        super("Test Modul", { columns: []});
        this.addParameter(EParameters.CreditSpreadMetricParameter);

        this.title = "Test Modul";

        this.documentation = [];
        const dataDefault = [{theSame: true}];
// 0
        this.addModule("Sz-Grid", "TestModule", "DateTriggerProvider", dataDefault)
            .setAccess(EContentType.Free, EPackageTypes.Dax30)
        ;

        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c100)
            .addModule([0]);
    }
    protected getTitle(): string{
        return "Test-Module";
    }
}
