import * as React from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';

import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {Line} from "react-chartjs-2";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {DrawStateHook} from "../../../chart-plugin/DrawStateHook";
import * as ChartJsAnnotation from "chartjs-plugin-annotation";

import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {mkQuarterlyAnnotation} from "../../../helpers/Helpers";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
    ChartJsAnnotation,
);
export interface ICourseHistoryProps {
    company: ICompanyBase;
    use_raw_values?: boolean;
    y_label: string;
    data: {
        index_data?: any[],
        stock_data?: any[],
        adj_index_data?: any[],
        adj_stock_data?: any[],
    };
}

export function CourseHistory(props: ICourseHistoryProps) {
    const data = props.data;
    const return_type = SessionStore.get(EParameters.ReturnTypeParameter);
    let sf_index="fg_pchg_idx";
    let sf_company="p_price_change";
    if(return_type==="performance"){
        sf_index="fg_tret_pchg_idx";
        sf_company="p_total_returnc";
    }

    let start_index = 1;
    let start_stock = 1;
    const data_index = [];
    const data_stock = [];
    const dates = [];
    data.adj_index_data.forEach((d, i)=>{
        const v_index = data.adj_index_data[i][sf_index];
        const v_stock = data.adj_stock_data[i][sf_company];

        if(!props.use_raw_values){
            start_index = start_index * (1 + (v_index / 100));
            start_stock = start_stock * (1 + (v_stock / 100));
        }else{
            start_index = v_index;
            start_stock = v_stock;
        }
        data_index.push({x: i, y: start_index});
        data_stock.push({x: i, y: start_stock});
        dates.push(data.adj_index_data[i].date_decimal);
    });

    const o = new SzChartOptions();
    o.setCustomAxisLabel("", props.y_label);
    // o.setXTickCallback(()=> "");
    const {annotation,labels} = mkQuarterlyAnnotation(dates);
    o.enablePlugin(EChartPlugins.ANNOTATION, annotation);
    const options = {
        ...o.options,
    };
    options.animations = false;
    options.scales.x.gridLines= {
        drawTicks: false,
        display: false,
    };
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
    };
    const curses_index = {
        label: "Kursdaten Index",
        borderColor: "rgba(0, 0, 0,0.15)",
        borderWidth: 1,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        data: data_index,
    };
    const curses_stock = {
        label: "Kursdaten Aktie",
        borderColor: "rgba(19,124,189,0.75)",
        borderWidth: 1,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        data: data_stock,
    };
    const chart_data = {
        labels,
        datasets: [
            curses_index,
            curses_stock,
        ],
    };
    return (
        <Line data={chart_data as any} options={options} />
    );
}
