import {Classes} from "@blueprintjs/core";
import * as React from "react";
import {PublicDbCalls} from "../services/PublicDbCalls";

interface IErrorBoundaryState {
    hasError: boolean;
    text?: string;
    stack?: string;
}

export class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {
    public static getDerivedStateFromError(error) {
        return {
            hasError: true,
            text: `${error.name} ${error.message}`,
            stack: `${error.stack}`,
        };
    }
    constructor(props, context: IErrorBoundaryState) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    public componentDidCatch(error, errorInfo) {
        const theError = {
            message: `${error.name} ${error.message}`,
            lineNo: 0,
            colNo: 0,
            error: `${error.stack}\n#########################\n${errorInfo.componentStack}`,
        };
        (async () => {
            const callout = new PublicDbCalls();
            await callout.reportError(theError);
        })();
    }

    public render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <h1>{this.state.text}</h1>
                    <pre className={`${Classes.TEXT_MUTED}`}>
{this.state.stack}
                    </pre>
                </div>
            );
        }

        return this.props.children ? this.props.children : (<div>empty...</div>);
    }
}
