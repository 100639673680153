import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {PARAMETER_OPTION, ParameterOptions} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const options= PARAMETER_OPTION(EParameters.TaxshieldParameter);

export class TaxshieldParameter extends CombiParameter {
    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.TaxshieldParameter];
    }
    protected async initialize() {
        return {
            selectedValue: SessionStore.get(EParameters.TaxshieldParameter),
        };
    }
    public static getPdfLabel(){
        return ParameterOptions.getOptionLabelHardByValue(EParameters.TaxshieldParameter, SessionStore.get(EParameters.TaxshieldParameter));
    }
    protected getLabel() {
        const selectedValue = this.state.selectedValue;
        const option = options.find((item) => item.value === selectedValue);
        this.putPacketOptionParameter(EParameters.TaxshieldParameter, [
            {o: options, s: this.state.selectedValue}
        ]);
        return option ? option.label : "—";
    }
    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                {this.renderEntries()}
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.TaxshieldParameter))}
            </div>
        );
    }

    private renderEntries() {
        return this.renderColumn("40%", _t(ETranslation.tax_shield), ()=>{
            return this.renderOptionsExt(options, this.state.selectedValue, EParameters.TaxshieldParameter, "selectedValue");
        });
    }
}
