import * as React from "react";
import { Position, Toaster } from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
    position: Position.TOP,
});
export const sysError =(msg: string)=>{
    AppToaster.show({
        icon: IconNames.ERROR,
        intent: "danger",
        message: (
            <div>
                Systemfehler
                <p>Senden Sie ihre Anfrage bitte erneut.</p>
                <code>{msg}</code>
            </div>
        )
    });
};

export const showLoginError =()=>{
    AppToaster.show({
        icon: IconNames.ERROR,
        intent: "danger",
        message: (
            <div>
                Login nicht erfolgreich.
                <p>Überprüfen Sie ihre E-Mail und Passwort Eingabe.</p>
            </div>
        )
    });
};

export const newPasswordRequestError =()=>{
    AppToaster.show({
        icon: IconNames.ERROR,
        intent: "danger",
        message: (
            <div>
                Anfrage nicht erfolgreich.
                <p>Überprüfen Sie ihre E-Mail Eingabe.</p>
            </div>
        )
    });
};
export const setNewPasswordError =()=>{
    AppToaster.show({
        icon: IconNames.ERROR,
        intent: "danger",
        message: (
            <div>
                Anfrage nicht erfolgreich.
                <div style={{display: "flex", justifyContent: "flex-end"}}><a href={"mailto:info@smart-zebra.de"}>Kontakt Support</a></div>
            </div>
        )
    });
};

export const registerNewError =()=>{
    AppToaster.show({
        icon: IconNames.ERROR,
        intent: "warning",
        timeout: 30000,
        message: (
            <div>
                Registrierung nicht erfolgreich.
                <p>Überprüfen Sie ihre E-Mail Eingabe.</p>
                <div style={{display: "flex", justifyContent: "flex-end"}}><a href={"mailto:service@smart-zebra.de?subject=Register"} target={"_blank"}>Kontakt Support</a></div>
            </div>
        )
    });
};
