import {HTMLTable} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";

import {Ratings} from "../../const/Ratings";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export enum EShowType {
    spread = 1,
    rating = 2,
};

export interface IFooterInfo<T> {
    min_value: T;
    max_value: T;
    selected_value: T;
    value: T;
    min_rating: T;
    max_rating: T;
    selected_rating: T;
    rating: T;
    q25_rating: T;
    q75_rating: T;
    q25_value: T;
    q75_value: T;
}

export interface ICreditSpreadCalcTableData {
    caption: string;
    key: string;
    key_caption: string;
    key_value: number;
    x_from: number;
    x_till: number;
    x_range_in_percent: number;
    ranged_points: number;
    boxed_points: number;
    y_from: number;
    y_till: number;
    rating_from: number;
    rating_till: number;
    longlist_range: number;
    q_y_from: number;
    q_y_till: number;
    q_y: number;
    value: number;
    rating: number;
    weight: number;
    units: string;
    spread: number;
}

export interface ICreditSpreadCalcTableProps {
    show_type: EShowType;
    data: ICreditSpreadCalcTableData[];
    footer_info: IFooterInfo<number>;
}

export class CreditSpreadCalcTable extends React.Component<ICreditSpreadCalcTableProps, any> {

    constructor(props: ICreditSpreadCalcTableProps, context: any) {
        super(props, context);
    }
    public render() {
        const f: IFooterInfo<string> = this.getFooterInfo();

        const padding = "6px 3px";
        const num_width = 50;
        const bg_spreads = undefined; // "rgba(187,187,187,0.1)";
        const td_height = 28;

        const border_left_0 = {
            boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15), inset 1px 0 0px 0px rgba(16, 22, 26, 0.15)",
        };
        const border_left = {
            boxShadow: "inset 1px 0 0px 0px rgba(16, 22, 26, 0.15)",
        };
        const border_top = {
            boxShadow: "inset 0 1px rgba(16, 22, 26, 0.15)",
        };
        const number_col: CSSProperties = {
            padding,
            textAlign: "right",
            width: num_width,
            height: td_height
        };
        const number_col2: CSSProperties = {...number_col, ...{width: 50}};
        const number_head: CSSProperties = {
            padding, textAlign: "right",
            width: num_width,
        };
        const number_head2: CSSProperties = {...number_head, ...{width: 50}};
        const f_span = 15;
        return (
            <div>
            <HTMLTable className={`sz-prj-info-table_2`} condensed={true} style={{width: "100%", fontSize: "80%"}}>
                <thead>
                <tr style={{backgroundColor: "#FFFFFF"}}>
                    <th style={{padding}} colSpan={5}>Kreditkennzahl</th>
                    <th style={{padding, textAlign: "center", ...border_left}} colSpan={3}>Longlist</th>
                    <th style={{padding, textAlign: "center", ...border_left}} colSpan={5}>Shortlist</th>
                    <th style={{padding, textAlign: "center", backgroundColor: bg_spreads, ...border_left}} colSpan={3}>{_t(ETranslation.credit_spread)}</th>
                    <th style={{padding, textAlign: "center", backgroundColor: bg_spreads, ...border_left}} colSpan={3}>{_t(ETranslation.rating)}</th>
                </tr>
                <tr style={{backgroundColor: "#FFFFFF"}}>
                    <th style={{padding}} colSpan={1}>Bereich</th>
                    <th style={{padding}} colSpan={1}>Ausgewählte Kennzahl</th>
                    <th style={{padding, textAlign: "right", width: 60}}>Einheit</th>
                    <th style={{padding, textAlign: "right", width: num_width}}>Gewichtung</th>
                    <th style={{padding, textAlign: "right", width: num_width}}>Wert</th>

                    <th style={{...number_head, ...border_left}}>von</th>
                    <th style={{...number_head}}>{_t(ETranslation.to)}</th>
                    <th style={{...number_head}}>Anzahl{Globals.superscript(1)}</th>

                    <th style={{...number_head, ...border_left}}>Breite</th>
                    <th style={{...number_head}}>Qualität{Globals.superscript(2)}</th>
                    <th style={{...number_head}}>von</th>
                    <th style={{...number_head}}>{_t(ETranslation.to)}</th>
                    <th style={{...number_head2}}>Anzahl{Globals.superscript(3)}</th>

                    <th style={{...number_head2, ...border_left}}>von</th>
                    <th style={{...number_head2}}>{_t(ETranslation.to)}</th>
                    <th style={{...number_head2}}>Wert{Globals.superscript(4)}</th>

                    <th style={{...number_head2, ...border_left, textAlign: "left", paddingLeft: 12}}>von</th>
                    <th style={{...number_head2, textAlign: "left", paddingLeft: 12}}>{_t(ETranslation.to)}</th>
                    <th style={{...number_head2, textAlign: "left", paddingLeft: 12}}>Wert{Globals.superscript(4)}</th>
                </tr>
                </thead>
                <tbody>
                {this.props.data.map((row: ICreditSpreadCalcTableData, row_num)=>{
                    const bl = row_num === 0 ? border_left_0 : border_left;
                    const _fmtp = (v, ofd?)=> Globals.formatter_percent(v, ofd, ofd, true);

                    const _fmt = (v, ofd= 2)=>{
                        let fd = ofd;
                        if(row_num === 4){
                            fd = 0;
                        }
                        return Globals.formatter(v, fd, fd, true);
                    };
                    const _fmt2 = (v)=>{
                        return Globals.formatter(v, 2, 2, true);
                    };
                    return (
                        <tr>
                            <td style={{height: td_height, width: 125}}>{row.caption}</td>
                            <td style={{height: td_height, padding}}>{row.key}</td>
                            <td style={{...number_col, width: 60}}>{row.units}</td>
                            <td style={number_col}>{_fmtp(row.weight, 0)}</td>
                            <td style={number_col}>{_fmt(row.key_value)}</td>


                            <td style={{...number_col, ...bl}}>{_fmt(row.x_from)}</td>
                            <td style={number_col}>{_fmt(row.x_till)}</td>
                            <td style={number_col}>{_fmt(row.ranged_points, 0)}</td>

                            <td style={{...number_col2, ...bl}}>{_fmtp(row.longlist_range, 0)}</td>
                            <td style={{...number_col2}}>{_fmtp(row.q_y, 0)}</td>
                            <td style={{...number_col2}}>{_fmtp(row.q_y_from, 0)}</td>
                            <td style={{...number_col2}}>{_fmtp(row.q_y_till, 0)}</td>
                            <td style={{...number_col2}}>{_fmt(row.boxed_points, 0)}</td>

                            <td style={{...number_col2, ...bl}}>{_fmt2(row.y_from)}</td>
                            <td style={number_col2}>{_fmt2(row.y_till)}</td>
                            <td style={{...number_col2, fontWeight: "bold"}}>{_fmt2(row.value)}</td>

                            <td style={{...number_col2, ...bl, textAlign: "left", paddingLeft: 12}}>{this.getRating(row.rating_from)}</td>
                            <td style={{...number_col2, textAlign: "left", paddingLeft: 12}}>{this.getRating(row.rating_till)}</td>
                            <td style={{...number_col2, fontWeight: "bold", textAlign: "left", paddingLeft: 12}}>{this.getRating(row.rating)}</td>
                        </tr>
                    );
                })}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={f_span} style={border_top}>Minimum{Globals.superscript(5)}</td>
                    <td style={{...number_col, ...border_top}}>{f.min_value}</td>
                    <td colSpan={2} style={border_top}>&nbsp;</td>
                    <td style={{...number_col, ...border_top, textAlign: "left", paddingLeft: 12}}>{f.min_rating}</td>
                </tr>
                <tr>
                    <td colSpan={f_span}>Unteres Quartil{Globals.superscript(5)}</td>
                    <td style={{...number_col}}>{f.q25_value}</td>
                    <td colSpan={2}>&nbsp;</td>
                    <td style={{...number_col, textAlign: "left", paddingLeft: 12}}>{f.q25_rating}</td>
                </tr>
                <tr>
                    <td className={"sz-bold"} colSpan={f_span}>{_t(ETranslation.median)}{Globals.superscript(5)}</td>
                    <td className={"sz-bold"} style={{...number_col}}>{f.value}</td>
                    <td colSpan={2}>&nbsp;</td>
                    <td className={"sz-bold"} style={{...number_col, textAlign: "left", paddingLeft: 12}}>{f.rating}</td>
                </tr>
                <tr>
                    <td colSpan={f_span}>Oberes Quartil{Globals.superscript(5)}</td>
                    <td style={{...number_col}}>{f.q75_value}</td>
                    <td colSpan={2}>&nbsp;</td>
                    <td style={{...number_col, textAlign: "left", paddingLeft: 12}}>{f.q75_rating}</td>
                </tr>
                <tr>
                    <td colSpan={f_span}>Maximum{Globals.superscript(5)}</td>
                    <td style={{...number_col}}>{f.max_value}</td>
                    <td colSpan={2}>&nbsp;</td>
                    <td style={{...number_col, textAlign: "left", paddingLeft: 12}}>{f.max_rating}</td>
                </tr>
                </tfoot>
            </HTMLTable>
            <div style={{marginTop: 20, width: "100%", fontSize: "80%"}} className={"bp3-text-muted"}>
                <div>{Globals.superscript(1)}) Anzahl der Unternehmen auf der Longlist</div>
                <div>{Globals.superscript(2)}) Verschiebung der Shortlist durch die qualitative Beurteilung</div>
                <div>{Globals.superscript(3)}) Anzahl der Unternehmen auf der Shortlist</div>
                <div>{Globals.superscript(4)}) Median der Shortlist für die jeweilige Kreditkennzahl</div>
                <div>{Globals.superscript(5)}) gewichtet</div>
            </div>
            </div>
        );
    }
    private getRating(index: number){
        if(isNaN(index) || index===undefined){
            return Globals.hyphen;
        }
        return Ratings.s_and_p[Math.round(index)];
    }
    private getFooterInfo(): IFooterInfo<string> {
        const f: IFooterInfo<number> = this.props.footer_info;
        const r: IFooterInfo<string> = {} as IFooterInfo<string>;
        r.selected_value= Globals.formatter(f.selected_value, 2, 2, true);
        r.value= Globals.formatter(f.value, 2, 2, true);
        r.min_value= Globals.formatter(f.min_value, 2, 2, true);
        r.max_value= Globals.formatter(f.max_value, 2, 2, true);
        r.q25_value= Globals.formatter(f.q25_value, 2, 2, true);
        r.q75_value= Globals.formatter(f.q75_value, 2, 2, true);

        r.selected_rating= f.selected_rating === undefined ? Globals.hyphen : Ratings.s_and_p[Math.round(f.selected_rating)];
        r.rating= Ratings.s_and_p[Math.round(f.rating)];
        r.min_rating= Ratings.s_and_p[Math.round(f.min_rating)];
        r.max_rating= Ratings.s_and_p[Math.round(f.max_rating)];
        r.q25_rating= Ratings.s_and_p[Math.round(f.q25_rating)];
        r.q75_rating= Ratings.s_and_p[Math.round(f.q75_rating)];
        return r;
    }
}
