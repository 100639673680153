import {Classes, HTMLTable, NonIdealState} from "@blueprintjs/core";
import * as React from "react";
import {captions, result_order} from "../../const/Benchmarking";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {FinAnn} from "../../helpers/FinAnn";
import {EParameters} from "../../models/EParameters";
import {BaseModule} from "./BaseModule";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";

export class FinancialsAnnUserDefView  extends BaseModule<any> {
    constructor(props: any, context: any) {
        super(props, context);
    }

    protected renderContent() {
        if(!this.state.data){
            return (<NonIdealState
                icon={"database"}
                title="Keine Daten"
            />);
        }
        const year = this.data.year;
        const years = [-4, -3, -2, -1, 0].map((y) => y + year);
        const data = this.state.data;
        const label_width = undefined;
        const num_width = 60;
        return (
            <div style={{overflow: "hidden", position: "relative"}}>
                <HTMLTable className={`sz-table sz-prj-info-table_2`} condensed={true} style={{width: "100%", fontSize: "80%"}}>
                    <thead>
                    <tr style={{backgroundColor: "#FFFFFF"}}>
                        <th style={{width: label_width, paddingLeft: 10}}>{_t(ETranslation.result)}</th>
                        {years.map((i)=> {
                            return (
                                <th style={{textAlign: "right", width: num_width, padding: "6px 0px"}}>{i}</th>
                            );
                        })}
                    </tr>
                    <tr style={{backgroundColor: "#FFFFFF"}}>
                        <th style={{width: label_width, paddingLeft: 10}}>%</th>
                        {years.map((i)=> {
                            return (
                                <th style={{textAlign: "right", width: num_width, padding: "6px 0px"}}>IST</th>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {result_order.map( (field, idx) => {
                        if(field.startsWith("#")){
                            return (
                                <tr>
                                    <td colSpan={6} style={{paddingLeft: 10, boxShadow: idx > 0 ? "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)" : undefined }}>{field.slice(1)}</td>
                                </tr>
                            );
                        }
                        const row_data = data[field];
                        return (
                            <tr>
                                <td style={{width: label_width, paddingLeft: 20}}>{captions[field]}</td>
                                {years.map((i)=> {
                                    return (
                                        <td style={{textAlign: "right", width: num_width, padding: "6px 0px"}}><span className={`${Classes.TEXT_MUTED}`}>{Globals.formatter(row_data[i], 1, 1, true)}</span></td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </HTMLTable>
            </div>
        );
    }

    protected onAfterUpdate(data: any) {
        this.data = data;
        this.refreshAll();
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    private refreshAll() {
        const benchmarkingCustom = SessionStore.get(EParameters.BenchmarkingCustom);
        const year = this.data.year;
        const years = [-4, -3, -2, -1, 0].map((y) => y + year);
        const years_data = {};
        years.forEach((y) =>{
            const finance_datas = FinAnn.getCustomFin(y, benchmarkingCustom);
            const ret = FinAnn.getFinanceData(finance_datas);
            const finance_data = ret.result;
            result_order.forEach( (field, idx) => {
                if(field.startsWith("#")){
                    return;
                }
                if(!years_data[field]){
                    years_data[field] = {};
                }
                const param_value = finance_data[field];
                if (param_value == null) {
                    years_data[field][y] = "n.m.";
                } else if (Number.isFinite(param_value)) {
                    years_data[field][y] = param_value;
                } else {
                    years_data[field][y] = Globals.hyphen;
                }
            })
        });

        this.setState({
            loading: false,
            data: years_data,
        });
    }
}
