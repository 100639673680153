import * as React from "react";
import {CSSProperties, useEffect, useRef, useState} from "react";

const KEY_CODES = {
    ArrowRight: "ArrowRight",
    ArrowLeft: "ArrowLeft",
    ArrowUp: "ArrowUp",
    ArrowDown: "ArrowDown",
    Tab: "Tab",
    Enter: "Enter",
    Escape: "Escape",
    Delete: "Delete",
};


export interface ISzNumericInput {
    current_value: any;
    onValueChanged?(new_value: number, key: string);
}
export function SzNumericInput(props: ISzNumericInput) {
    const [value, setValue] = useState(props.current_value);
    const [clicked, setClicked] = useState(false);
    const [no_changes, setNoChanges] = useState(true);
    useEffect(() => {
        ((inputRef.current) as HTMLInputElement).focus();
    });
    const changeValue = (new_value)=>{
        if(no_changes && false){
            const ch = new_value.replace(value, "");
            console.error(value, new_value, ch);
            if(ch.length === 1){
                setNoChanges(false);
                setValue(ch);
                return;
            }
        }
        // console.error(new_value);
        if(new_value !== value){
            setNoChanges(false);
            setValue(new_value);
        }
    };
    const inputRef = useRef(null);
    const keyDown=(e: React.KeyboardEvent<HTMLInputElement>)=>{
        e.stopPropagation();
        if(e.key === KEY_CODES.ArrowDown || e.key === KEY_CODES.ArrowUp || e.key === KEY_CODES.ArrowLeft || e.key === KEY_CODES.ArrowRight){
            // e.preventDefault();
            if(!clicked){
                return props?.onValueChanged(value, e.key);
            }
        }
        if(e.key === KEY_CODES.Enter){
            return props?.onValueChanged(value, e.key);
        }
        if(e.key === KEY_CODES.Escape){
            return props?.onValueChanged(value, e.key);
        }
        if(e.key === KEY_CODES.Tab){
            return props?.onValueChanged(value, e.key);
        }

        if(!clicked && no_changes){
            setValue("");
            // return props?.onValueChanged(undefined, e.key);
        }
    };
    const onBlur = (e)=> {
        return props?.onValueChanged(value, undefined);
    }
    // props.onValueChanged -> fertig mit bearbeiten!!
    const style: CSSProperties = {
        fontSize: "inherit",
        textAlign: "right",
        padding: 0,
        caretColor: no_changes && !clicked ? "transparent" : undefined,
    };
    return (
        <input value={value ? value : ""}
               onClick={()=> setClicked(true)}
               onChange={(e)=>changeValue(e.target.value)}
               ref={inputRef}
               style={style}
               pattern="[0-9]"
               onKeyDown={(e)=> keyDown(e)}
               onBlur={(e)=> onBlur(e)}
               className={"sz-nospin-buttons"}
               type={"number"} />
    );
}
