import {Button, HTMLTable, Icon, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {median, quantile} from "../../helpers/Statistics";
import {EParameters} from "../../models/EParameters";
import {EValueName} from "../../models/EValueName";
import {ISzSvgDataPoint} from "../../models/ISzSvgDataPoint";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {EFromXorY, ISzScatterPlotSvgProperties, SzHistogramSvg} from "../widgets/SzHistogramSvg";
import {BaseModule} from "./BaseModule";

interface ITableField {
    text?: string;
    content?: any;
    colSpan?: number;
    style?:CSSProperties;
}
interface ITransferPricingSummaryState{
    is_loading?: boolean;
}
let update_cc = 0;
export class TransferPricingResult extends BaseModule<any> {

    private readonly evtUpdateResult: IEventHandler;

    constructor(props: any, context: ITransferPricingSummaryState) {
        super(props, context);
        this.evtUpdateResult = EventBus.subscribe("TP::result", (data) => this.onUpdateResult(data));
    }
    componentWillUnmount() {
        EventBus.unsubscribe(this.evtUpdateResult);
    }
    public async getDocumentationData() {
        if(!this.state){
            return;
        }
        if(!this.state.tp_result){
            return;
        }
        const fieldHeader_3: string[] = ["Operative Marge"];
        const years = this.getYears();
        years.forEach( (y) => fieldHeader_3.push(""+y) );
        fieldHeader_3.push(Globals.average);
        const table = [];
        Object.keys(this.state.tp_result).forEach( (key) => {
            const r = [key].concat(this.state.tp_result[key].columns);
            table.push(r);
        });
        const data = {
            header: fieldHeader_3,
            table,
        };
        return data;
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    private onUpdateResult(data){
        // console.error(data);
        this.setState({
            tp_result: data.result,
            short_list_data: data.data,
            tp_data: data.results,
            update_cc: update_cc++,
        });
    }
    private getYears(): number[]{
        const year = this.getParameterValue(EParameters.TpYearsParameter);
        const result: number[] = [];
        const steps = (new Array(3)).fill(0);
        let y: number = year;
        steps.forEach(() =>result.push(y--));
        return result.reverse();
    }
    protected renderContent() {
        const fieldMap_3: any[] = [];
        const fieldHeader_3: string[] = ["Operative Marge"];
        const years = this.getYears();
        years.forEach( (y) => fieldHeader_3.push(""+y) );
        fieldHeader_3.push(Globals.average);
        // tp_result
/*result[f.label] = {
                            fontWeight: f.fontWeight,
                            columns: [],
                        };*/

        if(this.state.tp_result){
            SessionStore.setGlobalVar(EValueName.tp_result, this.state.tp_result);
            // console.error(this.state.tp_result);
            Object.keys(this.state.tp_result).forEach( (k)=>{
                if(k === "Mittelwert"){
                    //
                }
                const entry = this.state.tp_result[k];
                const row_data = {text: k, content: [], style: {paddingLeft: 2, paddingRight: 2, textAlign: "right",fontWeight: entry.fontWeight}};
                const getValue = (vv)=> {
                    if(vv < -100){
                        row_data.content.push("< -100");
                    }else{
                        row_data.content.push(Globals.formatter(vv,2,2, true));
                    }
                };
                entry.columns.forEach((vv) => getValue(vv));
                fieldMap_3.push(row_data);
            });
        }else{
            fieldMap_3.push({text: "", content: Globals.hyphen, style: {paddingLeft: 2, paddingRight: 2}});
        }
        const renderRow = (row, row_num) => {
            const style:CSSProperties = {
                verticalAlign: "middle",
            };
            if(row.style){
                Object.assign(style, row.style);
            }
            if(row.colSpan){
                return (
                    <tr>
                        <td colSpan={row.colSpan} style={style}>{row.content}</td>
                    </tr>
                );
            }
            const label_style = Object.assign({width: 200}, style, {textAlign: "left"});
            if(Array.isArray(row.content)){
                return (
                    <tr>
                        <td  style={label_style}>{row.text}</td>
                        {row.content.map((c) => (<td  style={style}>{c}</td>))}
                    </tr>
                );
            }
            return (
                <tr>
                    <td  style={label_style}>{row.text}</td>
                    <td  style={style}>{row.content}</td>
                </tr>
            );
        };

        return (
            <div className={"sz-row"} style={{paddingTop: 16}}>
                <div className={"sz-col sz-col-100"}>
                    <HTMLTable className={`sz-table`} condensed={true} style={{width: "100%", borderCollapse: "separate"}}>
                        <thead>
                        <tr>
                            {fieldHeader_3.map((h, idx) => (<th style={{paddingLeft: 2, paddingRight: 2, textAlign: idx > 0 ? "right" : undefined}}>{h}</th>))}
                        </tr>
                        </thead>
                        <tbody>
                        {fieldMap_3.map((m, idx) => renderRow(m, idx))}
                        </tbody>
                    </HTMLTable>
                    {this.renderHistogramm()}
                </div>
            </div>
        );
    }
    protected noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }
    private renderContact() {
        return (
            <div>
                <div className={"sz-loco sz-loco-big"} style={{alignItems: "center"}}>
                    <div  className={"sz-loco-icon"}><Icon icon={IconNames.ENVELOPE} color={"#5c7080"} iconSize={48} /></div>
                    <div className={"sz-loco-text"}><div style={{textAlign: "center"}}>Zum Thema <strong>Transfer-Pricing</strong> setzen Sie sich bitte mit uns in Verbindung.</div></div>
                    <div style={{margin: 32}}>
                        <Button icon={IconNames.ENVELOPE} intent={"primary"} text={"Kontakt Formular"} onClick={() => window.open("https://www.smart-zebra.de/kontakt/") } />
                    </div>
                </div>
            </div>
        );
    }

    private renderHistogramm() {
        const data = this.state.short_list_data;
        const tp_data = this.state.tp_data;
        if(!data){
            return null;
        }
        if(!tp_data){
            return null;
        }
        const _points: ISzSvgDataPoint[] = [];
        const means = [];
        data.forEach( (d, idx) =>{
            const cid = d[0];
            const tp = tp_data[cid];
            if(!tp){
                return;
            }
            const v = tp.all_marge / 100;
            if(isNaN(v) || v===null || v===undefined){
                return;
            }
            means.push(v);
            const p: ISzSvgDataPoint = {
                x: v,
                y: idx,
                dataSet: {
                    name: d[1],
                    id: cid,
                },
            };
            _points.push(p)
        });
        const q05 =  quantile(means, .05);
        const q95 =  quantile(means, .95);
        const q75 =  quantile(means, .75);
        const q25 =  quantile(means, .25);
        const points = _points.filter( (p) => p.x>q05 && p.x<q95 );
        // console.error(q05, q95, _points, means);
        const props: ISzScatterPlotSvgProperties = {
            width: "100%",
            height: "180px",
            fromXorY: EFromXorY.fromX,
            histogram_slots: 71,
            q25,
            q75,
            mean: median(means),
            points,
        };
        return (
            <div>
                <HTMLTable className={`sz-table`} condensed={true} style={{width: "100%", borderCollapse: "separate", marginTop: 32}}>
                    <thead>
                    <tr>
                        <th style={{paddingLeft: 2, paddingRight: 2}}>Verteilungsfunktion¹</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{paddingLeft: 2, paddingRight: 2, paddingTop: 16}}>
                            <SzHistogramSvg {...props} key={`dc_tp_res_his_${this.state.update_cc}`} />
                        </td>
                    </tr>
                    </tbody>
                </HTMLTable>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p className="bp3-text-small bp3-text-muted">¹) Perzentile von 5% bis 95%</p>
            </div>
        );
    }
}
