import {Button, HTMLTable, Icon, Intent, Popover,} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {SectorCountries} from "../../../const/SectorCountries";
import {Sectors} from "../../../const/Sectors";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {ToolBarParameter} from "../ToolBarParameter";

const BASE_DIR = Globals.BASE_DIR;
const popper: PopperModifiers = {
    offset: { offset: "[0, 4]" },
};

const COUNTRIES = SectorCountries.values;

export class SectorCountriesParameter extends ToolBarParameter<IToolBarParameter> {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.SectorCountriesParameter];
    }
    protected async initialize() {
        const selectedCountry = SessionStore.getItem(EParameters.SectorCountriesParameter, [["2400"], [0]]);

        if (selectedCountry[1] && Array.isArray(selectedCountry[1])) {
            selectedCountry[1] = (selectedCountry[1] as []).filter( (n: any) => n < COUNTRIES.length );
        }
        if (selectedCountry[0] && Array.isArray(selectedCountry[0]) && selectedCountry[0].length > 2) {
            selectedCountry[0] = selectedCountry[0].slice(0, 2);
        }
        const r = {
            sectors: selectedCountry[0],
            countries_idx: selectedCountry[1],
            isOpen: false,
        };
        if(Array.isArray(r.sectors)){
            const new_data: any[] = (r.sectors as any[]).filter((s)=> ""+s !=="0000");
            if(new_data.length===0){
                new_data.push("2400");
            }
            r.sectors = new_data;
        }
        return r;
    }

    protected renderContent() {
        const sectors: any[] = this.state.sectors;
        const countries_idx: any[] = this.state.countries_idx;

        let show_sel_column = sectors.length > 0;
        let show_sel_row = countries_idx.length > 0;
        if (sectors.length > 1) {
            show_sel_row = false;
        }
        if (countries_idx.length > 1) {
            show_sel_column = false;
        }

        const onClick = (sector, country_idx) => {

            const sum_selection = sectors.length + countries_idx.length;
            const idxSector = sectors.indexOf(sector);
            const idxCountry = countries_idx.indexOf(country_idx);

            let changes = false;

            if (idxSector === -1 && countries_idx.length >= 2) {
                return;
            }
            if (idxCountry === -1 && sectors.length >= 2) {
                return;
            }

            if (idxSector >= 0 && sectors.length === 1 && idxCountry >= 0 && countries_idx.length === 1) {
                sectors.splice(idxSector, 1);
                countries_idx.splice(idxCountry, 1);
                changes = true;
            }
            if (idxCountry === -1 && idxSector === -1 && sum_selection === 0) {
                sectors.push(sector);
                countries_idx.push(country_idx);
                changes = true;
            }

            if (idxSector >= 0 && sectors.length > 1) {
                sectors.splice(idxSector, 1);
                changes = true;
            }
            if (idxCountry >= 0 && countries_idx.length > 1) {
                countries_idx.splice(idxCountry, 1);
                changes = true;
            }

            if (idxCountry >= 0 && idxSector === -1 && sectors.length < 2) {
                sectors.push(sector);
                changes = true;
            }
            if (idxSector >= 0 && idxCountry === -1 && countries_idx.length < 2) {
                countries_idx.push(country_idx);
                changes = true;
            }

            if (!changes) {
                return;
            }

            this.fireValueChange(sectors, countries_idx);
            this.setState({
                sectors,
                countries_idx,
            });
        };
        const imgStyle: CSSProperties = {
            maxHeight: 20,
            paddingRight: 4,
        };
        const selectedStyle: CSSProperties = {
            backgroundColor: "rgba(191, 204, 214, 0.15)",
        };

        const disabled = this.disabled;
        if (disabled) {
            return (
                <Popover
                    minimal={true}
                    position={"bottom-left"}
                    modifiers={popper}
                    popoverClassName={"fixed-width-1000 no-max-width"}
                >
                    <Button intent={Intent.NONE} minimal={true} className={"sz-toolbar-button"} icon={this.icon}>
                        {this.renderName()}
                        <div className={"sz-property-value"}>&nbsp;</div>
                    </Button>
                    {this.renderNoOp()}
                </Popover>
            );
        }

        return (
            <Popover
                minimal={true}
                position={"bottom-left"}
                modifiers={popper}
                popoverClassName={"fixed-width-1000 no-max-width"}
            >
                <Button intent={Intent.NONE} minimal={true} className={"sz-toolbar-button"} icon={this.icon}>
                    {this.renderName()}
                    <div className={"sz-property-value"}>&nbsp;</div>
                </Button>
                <div>
                    <div className={"sz-search-filter-list"} style={{padding: 8, maxWidth: 1000}}>
                        <HTMLTable condensed={true} className={"sz-table sz-table-minimal"}>
                            <thead>
                            <tr>
                                <th className={"sz-border-right"}>&nbsp;</th>
                                {COUNTRIES.map( (c, idx) => {
                                    return (<th style={{textAlign: "center"}}>{c.label}</th>);
                                })}
                            </tr>
                            <tr>
                                <th className={"sz-border-right"}>&nbsp;</th>
                                {COUNTRIES.map( (c, idx) => {
                                    if (!c.data.flags.length) {
                                        return (<th>&nbsp;</th>);
                                    }
                                    return (
                                        <th>
                                            <div style={{height: 20, maxHeight: 20, display: "flex", justifyContent: "center"}}>
                                                {c.data.flags.map( (i) => (<img style={imgStyle} src={`${BASE_DIR}images/flags/${i}.jpg`}/>))}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {Sectors.values.map( (sector, sector_idx) => {
                                if(sector_idx === 0){
                                    return null;
                                }
                                const sector_is_selected = this.state.sectors.indexOf(sector.value) >= 0;
                                const s_useStyle =  sector_is_selected && show_sel_row ? selectedStyle : null;
                                return (
                                    <tr>
                                        <td className={"sz-border-right"}>{sector.label}</td>
                                        {COUNTRIES.map( (c, idx) => {
                                            const country_is_selected = this.state.countries_idx.indexOf(idx) >= 0;
                                            const useStyle = country_is_selected && show_sel_column ? selectedStyle : null;
                                            const icon = country_is_selected && sector_is_selected ? IconNames.TICK_CIRCLE : IconNames.CIRCLE;
                                            const icon_intent = country_is_selected && sector_is_selected ? Intent.PRIMARY : null;
                                            const icon_color = country_is_selected && sector_is_selected ? null : "rgba(120,136,143,0.3)";

                                            const _style = useStyle || s_useStyle;

                                            return (<td onClick={ () => onClick(sector.value, idx) } style={Object.assign({textAlign: "center"}, _style)}> <Icon intent={icon_intent} color={icon_color} icon={icon} /> </td>);
                                        } )}
                                    </tr>
                                );
                            } )}
                            </tbody>
                        </HTMLTable>
                    </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.SectorCountriesParameter))}
                </div>
            </Popover>
        );
    }
    protected getLabel(): string {
        const options = this.state.options;
        if (!options) {
            return "Default";
        }
        const idx = options.findIndex((o) => "771" === o.value);
        if (idx >= 0 && options.length === 1) {
            return "Default";
        } else {
            return "Longlist";
        }
        // return String.fromCharCode(parseInt("200B",16));
    }

    private fireValueChange(sectors, countries_idx) {
        this.fireChanges([{
            component: this.props.primaryComponent,
            value: [
                sectors,
                countries_idx,
            ],
        }]);
    }
}
