import {Button, Intent} from "@blueprintjs/core";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import * as ChartJsAnnotation from "chartjs-plugin-annotation";
import * as React from "react";
import {useState} from "react";
import {Line} from "react-chartjs-2";

import {CustomAxisLabel} from "../../../chart-plugin/CustomAxisLabel";
import {DrawStateHook} from "../../../chart-plugin/DrawStateHook";
import {TooltipLine} from "../../../chart-plugin/TooltipLine";
import {ColorMap} from "../../../const/ColorMap";
import {ETranslation} from "../../../const/ETranslation";
import {Globals} from "../../../const/Globals";
import {Sectors} from "../../../const/Sectors";

import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {IMultiplierForBusiness} from "../../../models/IMultiplierForBusiness";
import {EventBus, IEventHandler} from "../../../services/EventBus";
import {fromDateDecimal} from "../../../tools/DateTools";
import {_t} from "../../../tools/Translator";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
    ChartJsAnnotation,
    CustomAxisLabel,
    TooltipLine,
);
export interface IDateDecimalCountProps {
    time_series: {date_decimal: number; count: number;}[];
    label: string;
}
export function DateDecimalCount(props: IDateDecimalCountProps) {
    const time_series = props.time_series;
    const o = new SzChartOptions();
    o.enablePlugin(EChartPlugins.TOOLTIP_LINE, {});
    o.enablePlugin(EChartPlugins.LEGEND, {position: "chartArea", align: "start",labels: {boxWidth: 10}});
    const tooltip = {
        position: "nearest",
        callbacks: {
            title: (items)=> {
                const sz = time_series[items[0].dataIndex].count;
                const dd = fromDateDecimal(time_series[items[0].dataIndex].date_decimal).toFormat("LLLL yyyy", {locale: navigator.language});
                return `${dd}; #${sz}`;
            },
            label: (context)=> {
                let label = context.dataset.label || '';

                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += Globals.formatter(context.parsed.y, 0,0);
                }
                return label;
            },
        },
    };
    o.enablePlugin(EChartPlugins.TOOLTIP, tooltip);
    const labels: string[] = time_series.map( (i, idx) => idx % 12 !== 0 ? undefined : fromDateDecimal(i.date_decimal).toFormat("LL.yy"));
    // const labels: string[] = (new Array(market_risk_premium.length)).fill(1).map( (i, idx) => ""+(idx + props.min_year));
    const options = {
        ...o.options,
    };
    options.scales.y.beginAtZero= true;
    options.scales.x.ticks.stepSize= 2;

    options.animations = false;
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
        mode: 'index',
    };
    const chart_data = {
        labels,
        datasets: [
            {
                label: props.label,
                borderColor: ColorMap[`$c_${1}`],
                borderWidth: 1,
                // pointStyle: false,
                pointRadius: 1,
                pointBorderWidth: .5,
                pointHoverRadius: 1,
                data: [],
            }
        ],
    };
    time_series.forEach((d, date_idx)=>{
        chart_data.datasets[0].data.push({x: date_idx, y: d.count, sz: d});
    });

    return (
        <Line data={chart_data as any} options={options} width={"100%"} height={"100%"}/>
    );
}
