import {Button, Checkbox, Popover, Position, RangeSlider, Spinner, Tooltip} from "@blueprintjs/core";
import * as React from "react";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {Modifiers as PopperModifiers} from "popper.js";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {SzTextInput} from "../hooks/SzTextInput";
import {Globals} from "../../const/Globals";

export class SzAskKay extends React.Component<any, any> {
    private readonly onAskKayAvailable: IEventHandler;
    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            isOpen: false,
            running: Globals.askKayBlocked,
        };
        this.onAskKayAvailable = EventBus.subscribe("askKay::available", ()=> this.setState({running: false}));
    }

    public componentWillUnmount() {
        EventBus.unsubscribe(this.onAskKayAvailable);
    }

    public render() {
        const isRunning= this.state.running || Globals.askKayBlocked;
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        const toolTipContent = ()=>{
            if(isRunning){
                return (
                    <p>
                        Suche läuft ...
                    </p>
                );
            }else{
                return (
                    <>
                        {_t(ETranslation.ai_search)}
                    </>
                );
            }
        };
        return (
            <span style={{display: "inline-block"}} className={`sz-search-control sz-base-filter`}>
                <Popover
                    minimal={true}
                    position={"bottom-left"}
                    boundary={"viewport"}
                    usePortal={true}
                    popoverClassName={"sz-popover"}
                    modifiers={popper}
                    isOpen={this.state.isOpen}
                    content={this.renderPopoverContent()}
                >
                    <Tooltip
                        content={toolTipContent()}
                        position={Position.BOTTOM}
                        usePortal={true}
                    >
                        {this.renderMenuButton()}
                    </Tooltip>
            </Popover>
            </span>
        );
    }
    private renderMenuButton(){
        const isRunning= this.state.running || Globals.askKayBlocked;
        return (
            <Button
                disabled={isRunning}
                minimal={false}
                onClick={() => this.setState({isOpen: true})}
            >
                <div style={{display: "flex", alignItems: "center"}}>
                    <img height={16} width={16} src={`${Globals.BASE_DIR}images/dark-zebra.svg`} /><span style={{paddingLeft: 8}}>smartSearch</span>
                </div>
            </Button>
        );
    }
    private renderPopoverContent(){
        return (
            <div>
                <div style={{padding: 16, width: 320}}>
                    <p>{_t(ETranslation.business_description)}</p>
                    <SzTextInput maxLen={250} onValueChanged={(business_activity)=>this.setState({business_activity})}/>
                    <div style={{display: "flex", justifyContent: "flex-end", marginTop: 10}}>
                        <Button
                            text={_t(ETranslation.cancel)}
                            onClick={()=>this.handleAbort()}
                        />&nbsp;
                        <Button
                            text={_t(ETranslation.send)}
                            intent={"primary"}
                            onClick={()=>this.handleSubmit()}
                        />
                    </div>
                </div>
            </div>
        );
    }
    private handleAbort(){
        this.setState({isOpen: false, business_activity: ""});
    }
    private handleSubmit(){
        const newState = {isOpen: false, business_activity: "", running: false};
        if(this.state.business_activity && this.state.business_activity.length>5){
            EventBus.emit("askKay::business_activity", this.state.business_activity);
            newState.running=true;
            Globals.askKayBlocked= true;
        }
        this.setState(newState);
    }
}
