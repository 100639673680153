import * as React from "react";

export interface ISzSvgLoaderProperties {
    duration?: number;
}

export class SzSvgLoader extends React.Component<ISzSvgLoaderProperties, any> {

    constructor(props: any, context: any) {
        super(props, context);
    }
    public render() {
        const duration: number = this.props.duration ? this.props.duration : 2;
        const colorA = "#333333";
        const colorB = "#888888";
        return (
            <svg className={"sz-svg-loader"} xmlSpace={"http://www.w3.org/2000/svg"} xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "none", display: "block", shapeRendering: "auto"}} width="274px" height="274px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <defs>
                    <pattern id="idPattern" patternUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                        <g transform="translate(0 0)">
                            <g transform="skewX(-20)">
                                <rect x="-20" y="-10" width="10" height="120" fill={colorA}></rect>
                                <rect x="-10" y="-10" width="10" height="120" fill={colorB}></rect>
                                <rect x="0" y="-10" width="10" height="120" fill={colorA}></rect>
                                <rect x="10" y="-10" width="10" height="120" fill={colorB}></rect>
                                <rect x="20" y="-10" width="10" height="120" fill={colorA}></rect>
                                <rect x="30" y="-10" width="10" height="120" fill={colorB}></rect>
                                <rect x="40" y="-10" width="10" height="120" fill={colorA}></rect>
                                <rect x="50" y="-10" width="10" height="120" fill={colorB}></rect>
                                <rect x="60" y="-10" width="10" height="120" fill={colorA}></rect>
                                <rect x="70" y="-10" width="10" height="120" fill={colorB}></rect>
                                <rect x="80" y="-10" width="10" height="120" fill={colorA}></rect>
                                <rect x="90" y="-10" width="10" height="120" fill={colorB}></rect>
                                <rect x="100" y="-10" width="10" height="120" fill={colorA}></rect>
                                <rect x="110" y="-10" width="10" height="120" fill={colorB}></rect>
                                <rect x="120" y="-10" width="10" height="120" fill={colorA}></rect>
                            </g>
                            <animateTransform attributeName="transform" type="translate" values="0 0;20 0" keyTimes="0;1" dur={`${duration}s`} repeatCount="indefinite"></animateTransform>
                        </g>
                    </pattern>
                </defs>
                <rect x="0" y="44.5" strokeWidth="0" width="100" height="5" fill="url(#idPattern)"></rect>
            </svg>
        );
    }
}
