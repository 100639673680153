import {CreditSpreadOptions} from "../../const/CreditSpreadOptions";
import {SessionStore} from "../../const/SessionStore";
import {CalcCreditSpreadIndication} from "../../helpers/CalcCreditSpreadIndication";
import {FinAnn} from "../../helpers/FinAnn";
import {getResult, last} from "../../helpers/Helpers";
import {average, mean} from "../../helpers/Statistics";
import {EParameters} from "../../models/EParameters";
import {IBondDetails} from "../../models/IBondDetails";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {BaseDataProvider} from "./BaseDataProvider";
import {Ratings} from "../../const/Ratings";
import {deadlineEoM, fromStr} from "../../tools/DateTools";

export class CreditSpreadIndicationProvider extends BaseDataProvider {
    protected initProvider() {
        this.className = "CreditSpreadIndicationProvider";
        this.parameterMapping = [
            EParameters.DateParameter,
            EParameters.BondSectorParameter,
            EParameters.SicParameter,
            EParameters.FixedCurrencyParameter,
            EParameters.CreditSpreadRuntimeParameter,
            EParameters.SeniorityParameter,
            EParameters.PledgeStatusParameter,
            EParameters.TickerParameter,
            EParameters.CountryIso2Parameter,
            EParameters.CreditSpreadDecisionParameterA,
            EParameters.CreditSpreadDecisionParameterB,
            EParameters.CreditSpreadMetricParameter,
            EParameters.CreditSpreadXRangeParameter,
            EParameters.RegionParameter,
            EParameters.BondCallableParameter,
        ];
    }

    protected update() {
        const data = {
            creditSpreadIndications: [],
            year: fromStr(SessionStore.get(EParameters.DateParameter)).setToEoM().year,
            results: {},
            bond_details: [],
            select_company_id: undefined,
            selected_company_indication: undefined,
            all_boxed_spreads: [],
        };
        const db = new PublicDbCalls();
        (async () => {
            const q_date = fromStr(SessionStore.get(EParameters.DateParameter)).setToEoM().asDateDecimal();
            const selected_sector = SessionStore.get(EParameters.BondSectorParameter); //  === "0000" ? undefined : SessionStore.get(EParameters.SectorParameter);
            const raw_data: IBondDetails[] = await db.getCreditSpreadIndication(
                q_date,
                SessionStore.get(EParameters.FixedCurrencyParameter),
                selected_sector?.split(",").map((i)=>parseInt(i, 10)),
                SessionStore.get(EParameters.CreditSpreadRuntimeParameter),
                SessionStore.get(EParameters.SeniorityParameter),
                SessionStore.get(EParameters.PledgeStatusParameter),
                SessionStore.get(EParameters.RegionParameter),
                SessionStore.get(EParameters.BondEnhancementParameter),
                SessionStore.get(EParameters.BondCallableParameter),
            );
            const countryIso2 = (SessionStore.get(EParameters.CountryIso2Parameter) ? SessionStore.get(EParameters.CountryIso2Parameter) : "").toUpperCase();
            // data.bond_details = raw_data;
            // const companies = {};
            const all_companies = {};
            const c = [];
            const bond_data = {
                companies: {},
                results: {},
                creditSpreadIndications: [],
            };
            raw_data.forEach( (d)=> {
                if(d.fi_identifier_pticker && d.fi_identifier_pticker.startsWith("CC_")){
                    return;
                }
                if(countryIso2.indexOf(d.country_iso_2)>=0){
                    return;
                }
                // d.country_iso_2
                if(!Ratings.moodys_to_sp[d.rating]){
                    d.rating = "0";
                }else{
                    d.rating = Ratings.moodys_to_sp[d.rating];
                }

                data.bond_details.push(d);
                if(!bond_data.companies[d.id]){
                    bond_data.companies[d.id] = {
                        fin_data: null,
                        spreads: [],
                        average_spreads: NaN,
                        isin: d.p_symbol_isin,
                        name: d.co_name,
                        rating: d.rating,
                        id: d.id,
                        sector: d.sector_id,
                        security_id: d.security_id,
                    };
                    c.push(d.id);
                    all_companies[d.id] = bond_data.companies[d.id];
                }
                all_companies[d.id].spreads.push(d.ftid_spread);
            });
            const raw_fin = await db.getFinancialsAnnReported(
                [
                    c,
                    deadlineEoM(),
                    3
                ]
            );
            const fin_data_1 = getResult(raw_fin, []);
            const fin_data_companies = {};
            fin_data_1.forEach((i)=>{
                const company_id = i[0];
                if(!fin_data_companies[company_id]){
                    fin_data_companies[company_id] = [];
                }
                fin_data_companies[company_id].push(i);
            });
            const fin_data = [];
            const get_arr = (field_idx, from_a)=>{
                const r = [];
                if(Array.isArray(from_a)){
                    from_a.forEach((aa)=>r.push(aa[field_idx]));
                }
                return r;
            };
            // console.error(fin_data_companies);
            const last_company_fin = {};
            Object.keys(fin_data_companies).forEach((company_id)=>{
                // 21
                const a = fin_data_companies[company_id];

                const l = last(a, undefined);
                if(Array.isArray(l) && l[2]){
                    last_company_fin[company_id]=l;
                }

                const hit = a.find((i) => i[2]);
                const fin = hit ? hit : a[0];
                fin_data.push(fin);
                for(let i=0; i<64; i++ ){
                    if(i<3){
                        continue;
                    }
                    const values = get_arr(i, a);
                    fin[i] = mean(values);
                }
            });
            const selected_fin_data= await this.load_fin_ticker();
            data.select_company_id = last(selected_fin_data, [])[0];
            const all_c = c.indexOf(data.select_company_id)===-1 ? [data.select_company_id].concat(c) : c;
            const company_country = await db.getCountryExchangeRate(q_date);
            const company_country_map = {};
            company_country.forEach((i)=>company_country_map[i.currency_iso_3]=i);

            const currency_iso = last(selected_fin_data, [])[2];
            const select_company_xch = company_country_map[currency_iso] ? company_country_map[currency_iso].xch_rate : 1;
            // console.error("select_company_xch", data.select_company_xch);
            this.setBenchmarkingCustom(selected_fin_data, select_company_xch);
            const fin_data_map = {};
            fin_data.forEach( (i)=> fin_data_map[i[0]] = i);
            // console.error(fin_data);
            c.forEach((id)=>{
                if(!fin_data_map[id]){
                    return;
                }

                const xch = company_country_map[fin_data_map[id][2]];
                if(!xch){
                    console.error("no xch-rate for company:", id);
                    // console.error("no xch-rate for company:", id, fin_data_map[id], company_country_map);
                }
                all_companies[id].fin_data = FinAnn.getFinanceData(fin_data_map[id], xch ? xch.xch_rate : 1);
                all_companies[id].average_spreads = average(all_companies[id].spreads) / 100;
                if(!isNaN(all_companies[id].average_spreads)){
                    bond_data.creditSpreadIndications.push(all_companies[id]);
                }
                if(last_company_fin[id]){
                    last_company_fin[id] = FinAnn.getFinanceData([].concat(last_company_fin[id]), xch ? xch.xch_rate : 1);
                }
            });
            const metrics = CreditSpreadOptions.getMetrics();
            const keys: string[] = [];
            metrics.forEach((metric) =>{
                keys.push(metric.field);
                const r = CalcCreditSpreadIndication.forKey(bond_data.creditSpreadIndications, metric.field, data.year);
                bond_data.results[metric.field] = r;
            });
            data.creditSpreadIndications= bond_data.creditSpreadIndications;
            //  data.results <- indications map by key
            data.results= bond_data.results;
            // data.all = sectors;
            data.selected_company_indication = all_companies[data.select_company_id];

            data.all_boxed_spreads= CalcCreditSpreadIndication.get_all_boxed_spreads(data.results);
            // console.error(last_company_fin);
            // console.error(all_companies);
            // console.error(data.results);
            // console.error(data.creditSpreadIndications);
            // console.error(all_companies);
            // console.error(data);
            this.afterUpdate(data);
        })();
    }

    private async load_fin_ticker() {
        const db = new PublicDbCalls();
        const ticker = SessionStore.get(EParameters.TickerParameter);
        if (!ticker) {
            return;
        }
        const companies = await db.getCompaniesByTicker([ticker]);
        const company_fin = getResult(await db.getFinancialsAnnReported(
            [
                companies,
                deadlineEoM(),
                6
            ]
        ), []);
        return company_fin;
    }
    private setBenchmarkingCustom(company_fin: any[], xch = 1){
        if(!xch){
            xch = 1;
        }
        const year = fromStr(SessionStore.get(EParameters.DateParameter)).asDateDecimal();

        const last_date = last(company_fin,[])[1];
        if(!last_date){
            return;
        }
        const last_year = Math.trunc(parseInt(last_date, 10) / 10000);
        const fin_map = {};
        const benchmarking_custom = {};
        let company_id;
        if(last_year+1 === year || last_year === year){
            const to_add = last_year+1 === year ? 1 : 0;
            company_fin.forEach( (i)=> {
                company_id = i[0];
                const y = Math.trunc(parseInt(i[1], 10) / 10000) + to_add;
                fin_map[y] = FinAnn.asCreditSpreadIndication(i);
                if(fin_map[y]){
                    Object.keys(fin_map[y]).forEach((finMapElementKey)=>{
                        const v = fin_map[y][finMapElementKey];
                        benchmarking_custom[`${year}_${finMapElementKey}`] = v/xch;
                    });
                }
            });
            SessionStore.setItem(EParameters.BenchmarkingCustom, [benchmarking_custom]);
            SessionStore.setItem(EParameters.TickerParameter, [undefined]);
        }
        return company_id;
    }
}
