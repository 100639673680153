import {Alignment, Content, ContentTable, ContentText, PageOrientation} from "pdfmake/interfaces";
import {EParameters} from "../models/EParameters";
import {ParameterOptions} from "../const/ParameterOptions";
import {Globals} from "../const/Globals";
import {SessionStore} from "../const/SessionStore";

export const PDF_IMAGE_WIDTH = 550; // 708.8pt
export const PDF_IMAGE_HEIGHT = 850; // 1000.8pt

// 1000.63, height: 708.66

export class PdfInsertElement {
    public static page_header(content: Content[], s: string, pageOrientation: PageOrientation = "landscape"){
        content.push({
            style: "h1",
            absolutePosition: {x: 0, y: 0},
            canvas: [ { type: 'rect', x: 0, y: 10, w: 1000.63, h: 40, color: "#293742" } ],
            pageBreak: "before",
        });
        content.push({
            style: "h1",
            text: s,
        });
    }
    public static h2(content: Content[], s: string){
        const c: Content = {
            text: s,
            // margin: [0,150,0,0],
            fontSize: 10,
            bold: true,
        };
        content.push(c);
        return c;
    }
    public static p(content: Content[], s: string, al?: Alignment): ContentText{
        const t = {
            text: s,
            border: [false, false, false, false],
            alignment: al ? al : undefined,
        };
        content.push(t);
        return t;
    }
    public static image(content: Content[], imageDataUrl: string, width: number): Content{
        const img = {
            image: imageDataUrl,
            width,
        };
        content.push(img);
        return img;
    }
    public static table(content: Content[]): ContentTable {
        const table: ContentTable = {
            layout: "noBorders",
            table: {},
        } as ContentTable;
        content.push(table);
        return table;
    }
    public static named_value(content: Content[], caption: string, v: any){
        content.push({text:[
                {text: `${caption}: `,bold: true},
                `${v}`
            ]});
    }
    public static selected_option(content: Content[], caption: string, p: EParameters, v?: any){
        let vv = v;
        if(vv === undefined){
            vv = SessionStore.get(p);
        }
        const value_label = (`${ParameterOptions.getOptionLabelHardByValue(p, vv)}`).replace("*", "");
        content.push({text:[
                {text: `${caption}: `,bold: true},
                `${value_label}`
            ]});
    }
    public static selected_options(content: Content[], caption: string, p: EParameters, v?: any){
        let vv = v;
        if(vv === undefined){
            vv = SessionStore.get(p);
        }
        const value_label = (`${ParameterOptions.getSelectedOptionsFromObject(p, vv)}`).replaceAll("*", "");
        content.push({text:[
                {text: `${caption}: `,bold: true},
                `${value_label}`
            ]});
    }
}
