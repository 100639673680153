import {Classes, InputGroup} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {Sectors} from "../../const/Sectors";
import {EParameters} from "../../models/EParameters";
import {IRatingCompass} from "../../models/IRatingCompass";
import {DebtBetaTool2} from "../../tools/betas/DebtBetaTool2";
import {BaseModule} from "./BaseModule";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export class RatingNavigator extends BaseModule<any> {

    private estimatedRating: number = Number.NaN;
    private valueSelected: number = Number.NaN;
    private value: number = Number.NaN;
    private valueAll: number = Number.NaN;
    private sector: string = "";

    constructor(props: any, context: any) {
        super(props, context);
        const ratingCompass: IRatingCompass = {
            finanzschulden: 500,
            pension: 20,
            cash: 10,
            equity: 1000,
            ebit: 200,
            ebitda: 200,
            interest: 10,
            tax: 40,
            ffo: 0,
            debt: 0,
        };
        this.state = {
            ratingCompass,
        };
    }
    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected renderContent() {

        if (!this.data) {
            return (<div></div>);
        }

        if (!this.data.ratingKeyRatios || !this.data.ratingKeyRatios.length) {
            return (<div></div>);
        }
        const selectedSector = this.getParameterValue(EParameters.SectorParameter);
        let label_a = "sektorunspezifischer";
        let label_b = "mit dem Durschnitt aller Sektoren";
        if (selectedSector !== "0000") {
            const s = Sectors.values.find( (i) => i.value === selectedSector );
            label_a = "sektorspezifisch";
            label_b = `für den Sektor ${s ? s.label : "unbekannt"}`;
        }
        return (
            <table className={"sz-table-no-styles sz-table-minimal"}>
                <tbody>
                <tr>
                    <td style={{width: "60%", verticalAlign: "top", paddingTop: 0, paddingRight: 45}}>
                        {this.renderCompass()}
                    </td>
                    <td style={{verticalAlign: "top", paddingTop: 0}}>
                        {this.renderCreditRatingInfo()}
                    </td>
                </tr>
                <tr><td>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>¹) Indikatives Rating ermittelt auf Basis quantitativer Faktoren; qualitative Faktoren fließen in die Ratingindikation nicht ein</p>
                    <p className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>²) Die dem indikativen Rating zugrundeliegenden Kennzahlen wurden auf Basis {label_a} historischer Kennzahlen und Ratings {label_b} ermittelt</p>
                </td></tr>
                </tbody>
            </table>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    private onChange(stateField: string, value: string): boolean {
        const cleanedNum = (s: string): number => Number(s.replace(/\./g, "").replace(/,/, "."));
        // console.error("stateField", stateField, "value", value, "cleanedNum", cleanedNum(value));
        const ratingCompass: IRatingCompass = this.state.ratingCompass;
        ratingCompass[stateField] = cleanedNum(value);
        this.setState(ratingCompass);
        return true;
    }
    private renderCompass() {
        const numFormat = new Intl.NumberFormat("de-DE", { maximumFractionDigits: 0 }).format;
        const numFormat2Digits = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format;
        const numFormatPercent = new Intl.NumberFormat("de-DE", { style: "percent", minimumFractionDigits: 1, maximumFractionDigits: 1 }).format;
        const ratingCompass: IRatingCompass = this.state.ratingCompass;
        const createInputTd = (field: string) => <td className={"sz-number"} style={{width: 100}}><InputGroup className={"sz-inputgroup-right"} placeholder="text" value={numFormat(this.state.ratingCompass[field])} maxLength={20} onChange={ (e) => { ratingCompass[field] = e.target.value; this.onChange(field, e.target.value); }}/></td>;
        // console.error("renderCompass", this);

        ratingCompass.ffo = ratingCompass.ebitda - ratingCompass.interest - ratingCompass.tax;
        ratingCompass.debt = ratingCompass.finanzschulden +  ratingCompass.pension - ratingCompass.cash;
        const ffo_debt_ratio = ratingCompass.ffo / ratingCompass.debt;
        const debt_ebitda_ratio = ratingCompass.debt / ratingCompass.ebitda;
        const debt_equity_ratio = ratingCompass.debt / ratingCompass.equity;
        const ebit_interest_ratio = ratingCompass.ebit / ratingCompass.interest;

        const ffoDebtRatingKeyRatios = this.data.ratingKeyRatios.find((r) => r.metric === "FFO_Debt");
        const debtEbitdaRatingKeyRatios = this.data.ratingKeyRatios.find((r) => r.metric === "DEBT_EBITDA");
        const debtEquityRatingKeyRatios = this.data.ratingKeyRatios.find((r) => r.metric === "DEBT_Equity");
        const ebitInterestRatingKeyRatios = this.data.ratingKeyRatios.find((r) => r.metric === "EBIT_Interest");
        const ffo_debt_ratio_rank = DebtBetaTool2.getRatingRang(ffo_debt_ratio, ffoDebtRatingKeyRatios);
        const debt_ebitda_ratio_rank = DebtBetaTool2.getRatingRang(debt_ebitda_ratio, debtEbitdaRatingKeyRatios);
        const ebit_interest_ratio_rank = DebtBetaTool2.getRatingRang(ebit_interest_ratio, ebitInterestRatingKeyRatios);
        const debt_equity_ratio_rank = DebtBetaTool2.getRatingRang(debt_equity_ratio, debtEquityRatingKeyRatios);

        let rating = 0;

        const ratingWeigths = {
            ffo_debt_ratio: 0.5,
            debt_ebitda_ratio: 0.3,
            debt_equity_ratio: 0.15,
            ebit_interest_ratio: 0.05,
        };
        /*
        if (!Number.isFinite(ffo_debt_ratio_rank.rang)) {

        }
        */
        rating += ffo_debt_ratio_rank.rang * ratingWeigths.ffo_debt_ratio;
        rating += debt_ebitda_ratio_rank.rang * ratingWeigths.debt_ebitda_ratio;
        rating += debt_equity_ratio_rank.rang * ratingWeigths.debt_equity_ratio;
        rating += ebit_interest_ratio_rank.rang * ratingWeigths.ebit_interest_ratio;
        // ratingCompass.debt : nettoverschuldung
        if (ratingCompass.debt < 0 || (ratingCompass.ffo < 0 && ratingCompass.debt < 0)) {
            rating = null;
        }
        this.estimatedRating = rating;
        let estimatedRatingBandStr = "n.m.";
        let estimatedRatingBandLow = Math.round(rating);
        if (Number.isFinite(estimatedRatingBandLow)) {
            estimatedRatingBandLow -= 1;
            if (estimatedRatingBandLow < 0) {
                estimatedRatingBandLow = 0;
            }
            let estimatedRatingBandHigh = estimatedRatingBandLow + 2;
            if (estimatedRatingBandHigh > 20) {
                estimatedRatingBandHigh = 20;
            }
            estimatedRatingBandStr = `${estimatedRatingBandLow + 1} - ${estimatedRatingBandHigh + 1}`;
        }
        const resultFormatter = (num) => num == null ? "n.m." : (Number.isFinite(num) ? numFormat2Digits(num) : "-");
        const resultPercentFormatter = (num) => num == null ? "n.m." : (Number.isFinite(num) ? numFormatPercent(num) : "-");
        const ratingTableColWidth = 60;
        const labelStyle: CSSProperties = {
            verticalAlign: "middle",
        };
        return (
            <div>
                <div className={"sz-row"}>
                    <table className={"sz-table bp3-html-table bp3-html-table-condensed sz-small-padding"}>
                        <thead>
                        <tr><td className={"sz-bold"} style={{paddingBottom: 6}} colSpan={2}>Eingabe Finanzkennzahlen</td></tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={labelStyle}>EBITDA</td>
                            {createInputTd("ebitda")}
                        </tr>
                        <tr>
                            <td style={labelStyle}>EBIT</td>
                            {createInputTd("ebit")}
                        </tr>
                        <tr>
                            <td style={labelStyle}>Zinsergebnis (Interest)</td>
                            {createInputTd("interest")}
                        </tr>
                        <tr>
                            <td style={labelStyle}>Steuern</td>
                            {createInputTd("tax")}
                        </tr>
                        <tr><td style={{padding: 0, height: 1, boxShadow: "rgba(16, 22, 26, 0.15) 0px 1px 0px 0px inset"}} colSpan={4}></td></tr>
                        <tr>
                            <td style={labelStyle}>{_t(ETranslation.equity)}</td>
                            {createInputTd("equity")}
                        </tr>
                        <tr>
                            <td>Finanzschulden</td>
                            {createInputTd("finanzschulden")}
                        </tr>
                        <tr>
                            <td>Pensionsdefizit</td>
                            {createInputTd("pension")}
                        </tr>
                        <tr>
                            <td>Liquide Mittel</td>
                            {createInputTd("cash")}
                        </tr>
                        <tr><td style={{height: 25}} colSpan={4}>{" "}</td></tr>
                        </tbody>
                    </table>
                    <table className={"sz-table bp3-html-table bp3-html-table-condensed sz-small-padding"}>
                        <thead>
                        <tr>
                            <td className={"sz-nowrap sz-bold"}>Ratingkennzahlen</td>
                            <td className={"sz-right"} style={{width: 110}}></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={"sz-nowrap"}>FFO / Debt</td>
                            <td className={"sz-right sz-bold"} style={{width: 110}}>{resultPercentFormatter(ffo_debt_ratio)}</td>
                        </tr>
                        <tr>
                            <td className={"sz-nowrap"}>Debt / EBITDA</td>
                            <td className={"sz-right sz-bold"} style={{width: 110}}>{resultFormatter(debt_ebitda_ratio)}</td>

                        </tr>
                        <tr>
                            <td className={"sz-nowrap"}>Debt / Equity</td>
                            <td className={"sz-right sz-bold"} style={{width: 110}}>{resultPercentFormatter(debt_equity_ratio)}</td>
                        </tr>
                        <tr>
                            <td className={"sz-nowrap"}>EBIT / Interest</td>
                            <td className={"sz-right sz-bold"} style={{width: 110}}>{resultFormatter(ebit_interest_ratio)}</td>
                        </tr>
                        <tr>
                            <td className={"sz-bold sz-nowrap"}>Indikativer Rating Score¹</td>
                            <td className={"sz-bold sz-right"} style={{height: 31, width: ratingTableColWidth}}>{estimatedRatingBandStr}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
    private renderCreditRatingInfo() {
        let estimatedRating = this.estimatedRating;
        if (Number.isFinite(estimatedRating)) {
            estimatedRating = Math.round(estimatedRating);
            estimatedRating -= 1;
            if (estimatedRating < 0) {
                estimatedRating = 0;
            }
        } else {
            estimatedRating = 1000;
        }
        /*
        if (estimatedRating > 19) {
            estimatedRating = 19;
        }
        */
        const highlightClass = (rowNum: number, colNum: number): string => {
            let lastRow = estimatedRating + 2;
            if (lastRow > 20) { lastRow = 20; }
            const isInRange = (estimatedRating <= rowNum && rowNum <= lastRow);
            if (!isInRange) {
                return colNum === 2 ? "sz-right " : "";
            }
            let className = "sz-table-cs-highlight ";
            const isFirstRow = rowNum === estimatedRating;
            const isLastRow = rowNum === lastRow;
            if (isFirstRow) {
                className += "sz-red-border-top ";
            }
            if (isLastRow) {
                className += "sz-red-border-bottom ";
            }
            if (colNum === 1) {
                className += "sz-red-border-left ";
            }
            if (colNum === 2) {
                className += "sz-red-border-right sz-right ";
            }
            return className;
        };
        return (
            <table style={{paddingRight: 16}} className={`sz-table bp3-html-table bp3-html-table-condensed sz-small-padding`}>
                <thead>
                <tr>
                    <th></th>
                    <th colSpan={2} className={"sz-nowrap sz-bold"}>&nbsp;</th>
                    <th className={"sz-nowrap sz-bold"}>Rating</th>
                    <th className={"sz-nowrap sz-bold"} style={{textAlign: "right"}}>Score</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td rowSpan={4}  style={{width: 180}} className={"sz-nowrap"}>Sehr gute Anleihe</td>
                    <td>&nbsp;</td>
                    <td rowSpan={10} className={"sz-nowrap"}></td>
                    <td colSpan={2} rowSpan={21} style={{padding: 0}}>
                        <table className={`sz-table bp3-html-table bp3-html-table-condensed rating-compass-rating-info sz-small-padding`}>
                            <tbody>
                            <tr>
                                <td className={highlightClass(0, 1)}>AAA</td>
                                <td className={highlightClass(0, 2)}>1</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(1, 1)}>AA+</td>
                                <td className={highlightClass(1, 2)}>2</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(2, 1)}>AA</td>
                                <td className={highlightClass(2, 2)}>3</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(3, 1)}>AA-</td>
                                <td className={highlightClass(3, 2)}>4</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(4, 1)}>A+</td>
                                <td className={highlightClass(4, 2)}>5</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(5, 1)}>A</td>
                                <td className={highlightClass(5, 2)}>6</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(6, 1)}>A-</td>
                                <td className={highlightClass(6, 2)}>7</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(7, 1)}>BBB+</td>
                                <td className={highlightClass(7, 2)}>8</td>
                            </tr>
                            <tr>

                                <td className={highlightClass(8, 1)}>BBB</td>
                                <td className={highlightClass(8, 2)}>9</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(9, 1)}>BBB-</td>
                                <td className={highlightClass(9, 2)}>10</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(10, 1)}>BB+</td>
                                <td className={highlightClass(10, 2)}>11</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(11, 1)}>BB</td>
                                <td className={highlightClass(11, 2)}>12</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(12, 1)}>BB-</td>
                                <td className={highlightClass(12, 2)}>13</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(13, 1)}>B+</td>
                                <td className={highlightClass(13, 2)}>14</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(14, 1)}>B</td>
                                <td className={highlightClass(14, 2)}>15</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(15, 1)}>B-</td>
                                <td className={highlightClass(15, 2)}>16</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(16, 1)}>CCC+</td>
                                <td className={highlightClass(16, 2)}>17</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(17, 1)}>CCC</td>
                                <td className={highlightClass(17, 2)}>18</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(18, 1)}>CCC-</td>
                                <td className={highlightClass(18, 2)}>19</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(19, 1)}>CC/C</td>
                                <td className={highlightClass(19, 2)}>20</td>
                            </tr>
                            <tr>
                                <td className={highlightClass(20, 1)}>SD/D</td>
                                <td className={highlightClass(20, 2)}>21</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td rowSpan={3}>&nbsp;</td>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr>
                    <td rowSpan={6} className={"sz-nowrap"}>Gute Anleihe</td>
                    <td rowSpan={3}>&nbsp;</td>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr>
                    <td rowSpan={3}>&nbsp;</td>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr>
                    <td rowSpan={6} className={"sz-nowrap"}>Spekulative Qualität</td>
                    <td rowSpan={3}>&nbsp;</td>
                    <td rowSpan={11} className={"sz-nowrap"}></td>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr>
                    <td rowSpan={3}>&nbsp;</td>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr>
                    <td rowSpan={4} className={"sz-nowrap"}>Junk Bond</td>
                    <td rowSpan={3}>&nbsp;</td>
                </tr>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                <tr>
                    <td className={"sz-nowrap"}>Zahlungsunfähig</td>
                    <td>&nbsp;</td>
                </tr>
                </tbody>
            </table>
        );
    }
}
