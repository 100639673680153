import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {NumericParameter} from "../NumericParameter";

export class DebtBetaValueParameter extends NumericParameter {

   constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }

    protected getValue() {
        return parseFloat(SessionStore.get(EParameters.DebtBetaValueParameter));
    }

    protected setValue(v: number) {
        //
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.DebtBetaValueParameter];
    }
}
