import {country_map} from "../tools/Translator";

export class SectorCountries {
    public static values = [
        {label: country_map.DEU?.name, value: [771], selected: true, data: { flags: ["de"] }},
        {label: "DACH-Region", value: [771, 705, 901], selected: false, data: { flags: ["de", "at", "ch"] }},
        {label: "Westeuropa", value: [771, 750, 764, 814, 765, 780, 773, 794, 801, 816, 795, 697, 898, 712, 705, 774, 824, 789, 900, 762, 853, 797, 901, 833, 874, 865, 889, 843, 920, 775], selected: false, data: { flags: ["eu-02", "gb", "ch"] }},
        {label: country_map.USA?.name, value: [921], selected: false, data: { flags: ["us"] }},
        {label: country_map.CAN?.name, value: [730], selected: false, data: { flags: ["ca"] }},
        {label: country_map.KOR?.name, value: [888], selected: false, data: { flags: ["kr"] }},
        {label: country_map.JPN?.name, value: [800], selected: false, data: { flags: ["jp"] }},
        {label: country_map.CHN?.name, value: [736], selected: false, data: { flags: ["cn"] }},
        {label: country_map.AUS?.name, value: [704], selected: false, data: { flags: ["au"] }},
    ];
}
