import {EContentType} from "../../const/EContentType";
import {EPackageTypes} from "../../const/EPackageTypes";
import {ELayoutColumnWidth} from "../../models/ELayoutColumnWidth";
import {EParameters} from "../../models/EParameters";
import {IColumnLayout} from "../../models/IColumnLayout";
import {ColumnLayouterImpl} from "../ColumnLayouterImpl";
import {ModuleGroup} from "../ModuleGroup";
import {NopeActions} from "../../const/NopeActions";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {IModuleConfig} from "../../models/IModuleConfig";

export class TransferPricing extends ModuleGroup<IColumnLayout> {
    public static config: IModuleConfig = {
        name: "Transfer-Pricing",
    };
    constructor() {
        super("TransferPricing", {columns: []});

        this.documentation = undefined;
        this.addParameter(EParameters.TpYearsParameter);
        this.addParameter(EParameters.TpAnalysisSizeParameter);
        this.addParameter(EParameters.TpSicParameter);
        this.addParameter(EParameters.TpKeyWordsParameter);
        this.addParameter(EParameters.TpFreeFloatParameter);
        this.addParameter(EParameters.DividerParameter);
        this.addParameter(EParameters.TpCountriesParameter);
        this.addParameter(EParameters.TpSalesParameter);
        this.addParameter(EParameters.TpNumEmployeesParameter);

        this.documentation = [
            {module: "TransferPricingLongList", caption: "Long List"},
            {module: "TransferPricingShortList", caption: "Short List"},
            {module: "TransferPricingResult", caption: "Ergebnis", is_fixed: true},
            {module: "TransferPricingSummaryShortList", caption: "Short List", is_fixed: true},
        ];
        // this.new_pdf_export = true;
        const dataDefault = [{theSame: true}];
        this.addModule("Suchstrategie", "TransferPricingSummaryLongList", "TpProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.transfer_pricing)
            .setExcelExport(false);
        this.addModule("Long List", "TransferPricingLongList", "TpProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.transfer_pricing)
            .setExcelExport(false);
        this.addModule("Short List", "TransferPricingShortList", "TpProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.transfer_pricing)
            .setExcelExport(true);
        this.addModule("Ergebnis", "TransferPricingResult", "TpProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.transfer_pricing)
            .setExcelExport(false);
        this.addModule("Eliminierungen", "TransferPricingSummaryShortList", "TpProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.transfer_pricing)
            .setExcelExport(false);

        this.addModule(_t(ETranslation.glossary), "TransferPricingHelp", "TpProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.transfer_pricing)
            .setAlignRight()
            .setExcelExport(false);

        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c500px)
            .addModule([3, 0, 4, 5]);
        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c100Minus500px)
            .addModule([1, 2]);
    }
    protected getTitle(): string{
        return "Transferpricing";
    }
}
