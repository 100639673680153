import {
    Button,
    ButtonGroup,
    Intent,
    IToastProps,
    Menu,
    MenuDivider,
    MenuItem,
    Popover,
    Tab,
    Tabs,
    Toaster,
    Tooltip,
} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {EContentType} from "../const/EContentType";
import {EDataSourceMode} from "../const/EDataSourceMode";
import {EExcelExportType} from "../const/EExcelExportType";
import {EStatisticsValueType} from "../const/EStatisticsValueType";
import {Globals} from "../const/Globals";
import {SessionStore} from "../const/SessionStore";
import {IColumnLayout} from "../models/IColumnLayout";
import {IDocumentationCall} from "../models/IDocumentationCall";
import {IExcelExport} from "../models/IExcelExport";
import {IGridLayout, IGridLayoutColumn} from "../models/IGridLayout";
import {IParameterChanged} from "../models/IParameterChanged";
import {ModuleBase, ModuleGroup} from "../modules";
import {EventBus, IEventHandler} from "../services/EventBus";
import {TasksCalls} from "../services/TasksCalls";
import {BaseDataProvider} from "./data/BaseDataProvider";
import {BetasForBusinessProvider} from "./data/BetasForBusinessProvider";
import {ChefBetasDataProvider} from "./data/ChefBetasDataProvider";
import {CorporateSpreadProvider} from "./data/CorporateSpreadProvider";
import {CostOfCapitalProvider} from "./data/CostOfCapitalProvider";
import {CreditSpreadIndicationProvider} from "./data/CreditSpreadIndicationProvider";
import {DateTriggerProvider} from "./data/DateTriggerProvider";
import {FinancialsAnnReportedProvider} from "./data/FinancialsAnnReportedProvider";
import {InterestRatesBubaAllProvider} from "./data/InterestRatesBubaAllProvider";
import {MacroDataProvider} from "./data/MacroDataProvider";
import {MarketRiskPremiumProvider} from "./data/MarketRiskPremiumProvider";
import {MultiplicatorsForBusinessProvider} from "./data/MultiplicatorsForBusinessProvider";
import {MultiplicatorsForCompaniesV2Provider} from "./data/MultiplicatorsForCompaniesV2Provider";
import {TpProvider} from "./data/TpProvider";
import {BaseInterestRateSvg} from "./modules/BaseInterestRateSvg";
import {BaseModule} from "./modules/BaseModule";
import {BetaForBusiness} from "./modules/BetaForBusiness";
import {BetaRiskMonitor} from "./modules/BetaRiskMonitor";
import {BetasCompare} from "./modules/BetasCompare";
import {BetasRawBetaSvg} from "./modules/BetasRawBetaSvg";
import {BetasStatistics2} from "./modules/BetasStatistics2";
import {BetasUnleveredBeta} from "./modules/BetasUnleveredBeta";
import {CompanyInfo} from "./modules/CompanyInfo";
import {CostOfCapitalModule} from "./modules/CostOfCapitalModule";
import {CostOfCapitalTree} from "./modules/CostOfCapitalTree";
import {CreditSpreadBonds} from "./modules/CreditSpreadBonds";
import {CreditSpreadIndication} from "./modules/CreditSpreadIndication";
import {CreditSpreadRatingVsSpreads} from "./modules/CreditSpreadRatingVsSpreads";
import {CreditSpreadUserInput} from "./modules/CreditSpreadUserInput";
import {FinancialsAnnReported} from "./modules/FinancialsAnnReported";
import {FinancialsAnnUserDef} from "./modules/FinancialsAnnUserDef";
import {FinancialsAnnUserDefView} from "./modules/FinancialsAnnUserDefView";
import {MacroEconomy} from "./modules/MacroEconomy";
import {MarketRiskPremium} from "./modules/MarketRiskPremium";
import {MultiplicatorsConcess} from "./modules/MultiplicatorsConcess";
import {MultiplicatorsForCompanies} from "./modules/MultiplicatorsForCompanies";
import {MultiplierForBusiness} from "./modules/MultiplierForBusiness";
import {RatingNavigator} from "./modules/RatingNavigator";
import {TestModule} from "./modules/TestModule";
import {TransferPricingHelp} from "./modules/TransferPricingHelp";
import {SvgExcel} from "./widgets/SvgExcel";
import {SvgPdf} from "./widgets/SvgPdf";
import {TransferPricingSummaryLongList} from "./modules/TransferPricingSummaryLongList";
import {TransferPricingLongList} from "./modules/TransferPricingLongList";
import {TransferPricingShortList} from "./modules/TransferPricingShortList";
import {TransferPricingResult} from "./modules/TransferPricingResult";
import {TransferPricingSummaryShortList} from "./modules/TransferPricingSummaryShortList";
import {IModuleAction} from "../models/IModuleAction";
import {CreditSpreadIndicationSpreads} from "./modules/CreditSpreadIndicationSpreads";
import {CreditSpreadIndicationChart} from "./modules/CreditSpreadIndicationChart";
import {CreditSpreadRatingChart} from "./modules/CreditSpreadRatingChart";
import {CreditSpreadInterestRateChart} from "./modules/CreditSpreadInterestRateChart";
import {CreditSpreadProvider} from "./data/CreditSpreadProvider";
import {CreditSpreadIndustry} from "./modules/CreditSpreadIndustry";
import {IconNames} from "@blueprintjs/icons";
import {AdminToolV2} from "./modules/AdminToolV2";
import {CorporateSpread} from "./modules/CorporateSpread";
import {_t} from "../tools/Translator";
import {ETranslation} from "../const/ETranslation";
import {PdfCreate} from "../pdf-tools/PdfCreate";
import {BondStats} from "./modules/BondStats";

const knownModules = {
    Multiplicators: MultiplicatorsForCompanies,
    AdminToolV2,
    BaseInterestRateSvg,
    BetaForBusiness,
    BetaRiskMonitor,
    BetasCompare,
    BetasRawBetaSvg,
    BetasStatistics2,
    BetasUnleveredBeta,
    BondStats,
    CompanyInfo,
    CostOfCapitalModule,
    CostOfCapitalTree,
    CorporateSpread,
    CreditSpreadBonds,
    CreditSpreadIndication,
    CreditSpreadIndicationChart,
    CreditSpreadIndicationSpreads,
    CreditSpreadIndustry,
    CreditSpreadInterestRateChart,
    CreditSpreadRatingChart,
    CreditSpreadRatingVsSpreads,
    CreditSpreadUserInput,
    FinancialsAnnReported,
    FinancialsAnnUserDef,
    FinancialsAnnUserDefView,
    MacroEconomy,
    MarketRiskPremium,
    MultiplicatorsConcess,
    MultiplierForBusiness,
    RatingNavigator,
    TransferPricingHelp,
    TransferPricingLongList,
    TransferPricingResult,
    TransferPricingShortList,
    TransferPricingSummaryLongList,
    TransferPricingSummaryShortList,
    TestModule,
};
const knownDataSources = {
    BetasForBusinessProvider,
    ChefBetasDataProvider,
    CorporateSpreadProvider,
    CostOfCapitalProvider,
    CreditSpreadIndicationProvider,
    CreditSpreadProvider,
    DateTriggerProvider,
    FinancialsAnnReportedProvider,
    InterestRatesBubaAllProvider,
    MacroDataProvider,
    MarketRiskPremiumProvider,
    MultiplicatorsForBusinessProvider,
    MultiplicatorsForCompaniesV2Provider,
    TpProvider,
};

export interface ISectionWorkspace {
    module: ModuleGroup;
}
interface ISectionWorkspaceState {
    showPrepareDoc?: boolean;
    showPdfInProgress?: boolean;
}
export class SectionWorkspace extends React.Component<ISectionWorkspace, ISectionWorkspaceState> {

    private dataSources: Map<string, BaseDataProvider[]> = new Map<string, BaseDataProvider[]>();
    private updateDataSources: Map<string , string> = new Map<string, string>();
    private readonly msgToaster: React.RefObject<Toaster>;

    private moduleInstances: Map<string, React.RefObject<BaseModule<any>>> = new Map<string, React.RefObject<BaseModule<any>>>();
    private modulesInuse: ModuleBase[] = [];
    private readonly evtDocumentationCall: IEventHandler;
    private readonly evtExcelCall: IEventHandler;
    private readonly evtPdfCall: IEventHandler;

    private idKey: number = 0;
    private toSave: IDocumentationCall;

    private readonly evtParameterChanged: IEventHandler;
    constructor(props: any, context: ISectionWorkspaceState) {
        super(props, context);
        this.msgToaster = React.createRef();
        this.state = {
            showPrepareDoc: false,
        };
        this.evtDocumentationCall = EventBus.subscribe<IDocumentationCall>("Documentation.make", (data) => this.onDocumentationCall(data));
        this.evtExcelCall = EventBus.subscribe<any>("Excel.make", (data) => this.onExcelCall(data));
        this.evtPdfCall = EventBus.subscribe<any>("PDF.make", (data) => this.onPdfCall());

        this.updateDataSources = new Map<string, string>();
        this.props.module.modules.forEach((item: ModuleBase)=>{
            const dataSourceId = this.addDataSource(item.dataSource, item.dataSourceMode, item.dataDefaults);
            if (dataSourceId) {
                item.dataSourceId = dataSourceId;
                this.updateDataSources.set(dataSourceId, item.dataSource);
            }
        });

        this.evtParameterChanged = EventBus.subscribe<IParameterChanged[]>("ParameterChanged", (data) => this.updateParameters(data));
    }
    public shouldComponentUpdate(nextProps, nextState) {
        if(this.props?.module?.name === nextProps.module?.name){
            return false;
        }
        return true;
    }
    public componentDidMount(){
        // console.error(this.props.module);
        this.updateDataSources.forEach((s) => {
            const dataSources = this.dataSources.get(s);
            if (dataSources) {
                dataSources.forEach((i) => Globals.setUpdateLock(i.instanceName));
            }
        });
        this.updateDataSources.forEach((s) => {
            const dataSources = this.dataSources.get(s);
            if (dataSources) {
                // console.error(s, dataSources.length);
                dataSources.forEach((dataSource) => {
                    dataSource.prepare = true;
                    dataSource.parameterMapping.forEach( (component, idx) => dataSource.updateParameter({
                        component,
                        value: SessionStore.getItem(component, dataSource.parameters[idx]),
                    }));
                    dataSource.prepare = false;
                    dataSource.forceUpdate();
                });
            }
        });
    }

    public componentWillUnmount(): void {
        EventBus.unsubscribe(this.evtDocumentationCall);
        EventBus.unsubscribe(this.evtExcelCall);
        EventBus.unsubscribe(this.evtPdfCall);
        EventBus.unsubscribe(this.evtParameterChanged);
    }

    public render() {
        if (!(this.props && this.props.module)) {
            return (<div>empty</div>);
        }
        const modules = this.props.module.modules;
        const layout = this.props.module.layout;
        this.modulesInuse = [];
        this.moduleInstances = new Map<string, React.RefObject<BaseModule<any>>>();
        return (
            <div className="sz-section-workspace" style={{overflow: "auto", scrollbarGutter: "stable"}}>
                <Toaster position={"top"} autoFocus={false} canEscapeKeyClear={true} ref={this.msgToaster} className={"in-front"} />
                {layout ? this.renderLayout(layout, modules) : this.renderModules(modules)}
                {this.renderHiddenModules(modules)}
            </div>
        );
    }

    public updateParameters(parameters: IParameterChanged[]) {
        this.dataSources.forEach( (i) => i.forEach( (j) => Globals.setUpdateLock(j.instanceName) ) );
        parameters.forEach( (parameter) => SessionStore.setItem(parameter.component, parameter.value) );
        this.dataSources.forEach((dataSources) => {
            if (dataSources) {
                dataSources.forEach((dataSource) => {
                    dataSource.prepare = true;
                    parameters.forEach( (parameter) => dataSource.updateParameter(parameter) );
                    dataSource.prepare = false;
                    dataSource.forceUpdate();
                });
            }
        });
    }

    private canRender(item: ModuleBase) {
        if (item.contentType === EContentType.Admin && !Globals.isAdmin) {
            return false;
        }
        return true;
    }
    private tabClick(tab_id: string) {
        const tab: HTMLDivElement = document.querySelector(`div[data-tab-id="${tab_id}"]`);
        const moduleName = Globals.getWindowKey("wp_show_module");
        if (!tab) {
            return;
        }
        Globals.trackInfo(EStatisticsValueType.tab_changed, tab.innerText.replace("Pro", "").replace(/\n/gi, ""));
    }
    private renderModule(item: ModuleBase, tabbed: boolean = false) {
        if (!item) {
            return (<div className="sz-section-module" />);
        }
        if (!item.component) {
            return (<div className="sz-section-module">{item.name}</div>);
        }

        if (!this.canRender(item)) {
            return null;
        }
        const DynamicComponent = knownModules[item.component];
        if (DynamicComponent) {
            const aRef: React.RefObject<BaseModule<any>> = React.createRef();
            this.moduleInstances.set(`${item.component}_${item.name}`, aRef);
            this.modulesInuse.push(item);
            return(<DynamicComponent component={item.component} ref={aRef} dataSource={item.dataSource} parameters={this.props.module.parameters} dataSourceId={item.dataSourceId} name={item.name} contentType={item.contentType} minPackage={item.minPackage} tabbed={tabbed} dataDefaults={item.dataDefaults} nopeActions={item.nopeActions} options={item.options} isHidden={item.isHidden} />);
        }
        return (<div className="sz-section-module">No DynamicComponent: {item.name}</div>);
    }
    private renderTabbedGridModules(column: IGridLayoutColumn, modules: ModuleBase[]) {
        if (!Array.isArray(column.moduleIndex)) {
            return "renderTabbedModules no array column.moduleIndex";
        }
        const och = (a, b, c) => {
            this.tabClick(a);
        };
        return (
            <div className={"sz-base-module"}>
                <div className={"sz-base-module-content sz-module-table"}>
                    <Tabs animate={true} renderActiveTabPanelOnly={false} vertical={false} id={`tab${this.idKey++}`} onChange={(a, b, c) => och(a, b, c)}>
                        {column.moduleIndex.map( (idx) => this.canRender(modules[idx]) && (!modules[idx].alignRight) && (!modules[idx].isHidden) ? <Tab style={{fontWeight: "bold"}} key={`tab_page_${this.idKey++}`} id={`tab_page_${this.idKey++}`} title={this.renderTitle(modules[idx].proFlag, modules[idx].name)} panel={this.renderModule(modules[idx], true)} /> : null )}
                        <Tabs.Expander />
                        {column.moduleIndex.map( (idx) => this.canRender(modules[idx]) && (modules[idx].alignRight) && (!modules[idx].isHidden) ? <Tab style={{fontWeight: "bold"}} key={`tab_page_${this.idKey++}`} id={`tab_page_${this.idKey++}`} title={this.renderTitle(modules[idx].proFlag, modules[idx].name)} panel={this.renderModule(modules[idx], true)} /> : null )}
                        {this.renderModuleActions(column, modules)}
                        {this.renderTabMenu()}
                    </Tabs>
                </div>
            </div>
        );
    }
    private renderHiddenModules(modules: ModuleBase[]) {
        return (
            <div className={"sz-base-module-hidden sz-chart-hidden"}>
                {modules.map( (module) => {
                    if (this.canRender(module) && module.isHidden) {
                        return this.renderModule(module, true);
                    }
                    return null;
                })}
            </div>
        );
    }
    private renderTabbedColumnModules(moduleIdx: any, modules: ModuleBase[]) {
        if (!Array.isArray(moduleIdx)) {
            return "renderTabbedModules no array column.moduleIndex";
        }
        const och = (a, b, c) => {
            // this.tabClick(a);
        };
        const left_tabs: {id: string, content: any}[] = [];
        const right_tabs: {id: string, content: any}[] = [];
        moduleIdx.forEach((idx)=>{
            const i = `tab_page_${this.idKey++}`;
            if(this.canRender(modules[idx]) && (!modules[idx].alignRight) && (!modules[idx].isHidden)){
                left_tabs.push({id: i, content: <Tab style={{fontWeight: "bold"}} key={i} id={i} title={this.renderTitle(modules[idx].proFlag, modules[idx].name)} panel={this.renderModule(modules[idx], true)} />});
            }
            if(this.canRender(modules[idx]) && modules[idx].alignRight && (!modules[idx].isHidden)){
                right_tabs.push({id: i, content: <Tab style={{fontWeight: "bold"}} key={i} id={i} title={this.renderTitle(modules[idx].proFlag, modules[idx].name)} panel={this.renderModule(modules[idx], true)} />});
            }
        });
        return (
            <div className={"sz-base-module"}>
                <div className={"sz-base-module-content sz-module-table"}>
                    <Tabs animate={true} renderActiveTabPanelOnly={false} vertical={false} id={`tab${this.idKey++}`} onChange={(a, b, c) => och(a, b, c)}>
                        {left_tabs.map((t)=> t.content)}
                        <Tabs.Expander />
                        {right_tabs.map((t)=> t.content)}
                        {this.renderModuleActions(moduleIdx, modules)}
                        {this.renderTabMenu()}
                    </Tabs>
                </div>
            </div>
        );
    }
    private onExcelCall(data) {
        const items: IExcelExport[] = data;
        const export_data = {};

        const v = [];

        items.forEach( (m) => {
            this.moduleInstances.get(`${m.component}_${m.name}`).current.getExcelData(export_data);
            v.push(m.name);
        });

        const parameters = Object.assign(export_data , SessionStore.getAll());
        // console.error(parameters);
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createBetaExportTask(parameters);
                // console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
                Globals.trackInfo(EStatisticsValueType.excel_export, v);
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    private renderTabMenu() {
        const beta_items = [];
        const else_items = [];
        let exp_types_beta = 0;
        this.modulesInuse.forEach((mRef) => {
            if (mRef.isHidden) {
                return;
            }
            if (mRef.excelExport) {
                if (mRef.exportType === EExcelExportType.beta) {
                    exp_types_beta ++;
                    beta_items.push({
                        name: mRef.name,
                        component: mRef.component,
                        export_type: mRef.exportType,
                    });
                }else{
                    else_items.push({
                        name: mRef.name,
                        component: mRef.component,
                        export_type: mRef.exportType,
                    });
                }
                // console.error(mRef.name, mRef.exportType, exp_types_beta);
            }
        });
        if (!else_items.length && !beta_items.length) {
            return null;
        }
        this.modulesInuse = [];
        const css: CSSProperties = {
            cursor: "pointer",
        };

        const startExport = () => {
            EventBus.emit("ExcelDialog:open", {dialog_key: `export_dlg_${EExcelExportType.beta}`, items: beta_items});
        };
        const renderBetaExp = ()=>{
            if (!beta_items.length) {
                return null;
            }
            return (
                <>
                    <MenuItem key={"Detail Export"} text={"Detail Export"} icon={"export"} onClick={() => startExport()} />
                </>
            );
        }
        return (
            <div>
                {this.showPdfExport()}
                <Popover minimal={true} position={"bottom-right"}>
                    <Tooltip content={_t(ETranslation.excel_icon_hoover)} usePortal={true} position={"left-bottom"} openOnTargetFocus={false}>
                        <span style={css}><SvgExcel size={20} /></span>
                    </Tooltip>
                    <Menu>
                        <MenuDivider title={_t(ETranslation.excel_icon_hoover)} />
                        {renderBetaExp()}
                        {else_items.map((m) => (<MenuItem key={m.name} text={m.name} icon={"export"} onClick={() => {
                            this.moduleInstances.get(`${m.component}_${m.name}`).current.exportAsExcel();
                            Globals.trackInfo(EStatisticsValueType.excel_export, [m.name]);
                        }}/>))}
                    </Menu>
                </Popover>
            </div>
        );
    }
    private renderExcelExportBeta(items: IExcelExport[], export_type: EExcelExportType) {
        const css: CSSProperties = {
            cursor: "pointer",
        };
        const startExport = () => {
            EventBus.emit("ExcelDialog:open", {dialog_key: `export_dlg_${export_type}`, items});
        };
        return (
            <div>
                {this.showPdfExport()}
                <Tooltip content={_t(ETranslation.excel_icon_hoover)} usePortal={true} position={"left-bottom"} openOnTargetFocus={false}>
                    <span onClick={() => startExport()}><SvgExcel size={20} /></span>
                </Tooltip>
            </div>
        );
    }
    private showPdfExport() {

        if(this.props.module.new_pdf_export && !this.props.module.documentation_rendered){
            this.props.module.documentation_rendered = true;
            const btnClicked = ()=>{
                const msg: IToastProps = {
                    icon: IconNames.DOCUMENT,
                    intent: "primary",
                    message: "Das Dokument wird erzeugt.",
                    timeout: 2500,
                };
                EventBus.emit<IToastProps>("sz-show-message",msg);
                setTimeout(()=>{
                    EventBus.emit("PDF.make", null);
                },1);
            };
            return (
                <Tooltip content={_t(ETranslation.pdf_icon_hoover)} usePortal={true} position={"left-bottom"} openOnTargetFocus={false}>
                    <span style={{marginRight: 8}} onClick={() => btnClicked()}><SvgPdf size={20} /></span>
                </Tooltip>
            );
        }

        if(this.props.module.renderSingleDoc && this.props.module.documentation_rendered){
            return null;
        }

        const css: CSSProperties = {
            cursor: "pointer",
        };
        if (this.props.module && this.props.module.documentation && Array.isArray(this.props.module.documentation)) {
            const onClick = () => {
                if (Globals.isRegistred) {
                    EventBus.emit("PdfDialog:open", this.props.module.name);
                } else {
                    this.msgToaster.current.show({
                        intent: Intent.WARNING,
                        message: <><h5 style={{margin: 0, marginBottom: 10}}>{_t(ETranslation.pdf_icon_hoover)}</h5>Diese Funktionalität steht Ihnen nach Ihrer kostenlosen<br/>
                            Registrierung zur Verfügung.</>,
                    });

                }
            };
            if(this.props.module.renderSingleDoc){
                this.props.module.documentation_rendered = true;
            }
            return(
                <Tooltip content={_t(ETranslation.pdf_icon_hoover)} usePortal={true} position={"left-bottom"} openOnTargetFocus={false}>
                    <span style={{marginRight: 8}} onClick={() => onClick()}><SvgPdf size={20} /></span>
                </Tooltip>
            );
        } else {
            return null;
        }
    }
    private renderGridLayout(layout: IGridLayout, modules: ModuleBase[]) {
        let cKey = 0;

        const renderColumn = (column: IGridLayoutColumn) => {
            if (!Array.isArray(column.moduleIndex)) {
                return this.renderModule(modules[column.moduleIndex]);
            }

            if (column.tabbed) {
                return this.renderTabbedGridModules(column, modules);
            }

            return column.moduleIndex.map( (moduleId) => <div className={`sz-row`} key={"LayoutRow" + (cKey++)} >{this.renderModule(modules[moduleId])}</div> );
        };
        return (
            <div>
                {layout.rows.map((row) => <div key={"LayoutRow" + (cKey++)} className={`sz-row`}>
                    {row.columns.map((column) => <div key={"LayoutCol" + (cKey++)} className={`${column.width} sz-col`}>{ renderColumn(column) }</div>)}
                </div>)}
            </div>
        );
    }
    private renderColumnLayout(layout: IColumnLayout, modules: ModuleBase[]) {
        let cKey = 0;
        const renderItem = (moduleIdx: any) => {
            if (!Array.isArray(moduleIdx)) {
                return this.renderModule(modules[moduleIdx]);
            }

            return this.renderTabbedColumnModules(moduleIdx, modules);
        };
        return (
            <div className={`sz-row`}>
                {layout.columns.map((column) => <div className={`${column.width} sz-col`} key={"LayoutCol" + (cKey++)}>
                    {column.modules.map((moduleIdx) => <div key={"LayoutRow" + (cKey++)} className={`sz-row`}>{renderItem(moduleIdx)}</div>)}
                </div>)}
            </div>
        );
    }
    private renderLayout(layout: any, modules: ModuleBase[]) {
        if (layout.columns) {
            return this.renderColumnLayout(layout as IColumnLayout, modules);
        }
        if (layout.rows) {
            return this.renderGridLayout(layout as IGridLayout, modules);
        }
        return (<p>Unsupported layout</p>);
    }
    private renderModules(modules: ModuleBase[]) {
        return modules.map((item) => this.renderModule(item));
    }

    private addDataSource(dataSourceName: string, dataSourceMode: EDataSourceMode, dataDefaults: any): string {
        if (!dataSourceName) {
            return undefined;
        }
        let dataSourceId: string = "";
        let dataSources = this.dataSources.get(dataSourceName);

        if (!dataSources) {
            dataSources = [];
            this.dataSources.set(dataSourceName, dataSources);
        }

        if (dataSourceMode === EDataSourceMode.single_instance && dataSources.length > 0) {
            // console.error("single_instance", dataSourceName);
            return dataSources[0].instanceName;
        }

        const dsClass = knownDataSources[dataSourceName];
        if (dsClass) {
            const i = new dsClass(this.props.module.parameters);
            i.parameters = dataDefaults;
            dataSourceId = i.instanceName;
            // console.error("new instance", dataSourceName, dataSourceId);
            dataSources.push(i);
            return dataSourceId;
        }

        return undefined;
    }

    private onDocumentationCall(data: IDocumentationCall) {
        const moduleInstances: Map<string, React.RefObject<BaseModule<any>>> = this.moduleInstances;
        (async () => {
            const saveModules = [];
            for (const moduleItem of data.saveModules) {
                const insta: React.RefObject<BaseModule<any>> = moduleInstances.get(moduleItem);
                if (!insta) {
                    console.error("Not Found:", moduleItem);
                    continue;
                }
                if (insta.current.props.isHidden) {
                    console.error("is hidden ...", moduleItem);
                    continue;
                }
                const docData = await insta.current.getDocumentationData();
                if (!docData) {
                    console.error("will not save (!docData) ...", moduleItem);
                    continue;
                }
                if (Array.isArray(docData)) {
                    docData.forEach( (i) => {
                        saveModules.push({
                            moduleComponent: moduleItem,
                            moduleCaption: i.name,
                            docData: i.data,
                        });
                    } );
                } else {
                    const moduleCaption = insta.current.getName();
                    saveModules.push({
                        moduleComponent: moduleItem,
                        moduleCaption,
                        docData,
                    });
                }
            }
            const moduleName: string = this.props.module.name;
            const toSave: IDocumentationCall = {
                bewertung: data.bewertung,
                moduleName,
                saveModules,
                doc_options: this.props.module.documentation_options,
            };
            // console.error(toSave);
            EventBus.emit<IDocumentationCall>("Documentation.submit", toSave);
        })();
    }

    private renderTitle(proFlag: boolean, name: any) {
        if (!proFlag) {
            return name;
        }
        const supStyle: CSSProperties = {
            fontWeight: "normal",
            fontSize: "85%",
            color: "#DB3737",
        };
        return (
            <div>{name}<sup style={supStyle}>Pro</sup></div>
        );
    }

    private renderModuleActions(moduleIdx: any, modules: ModuleBase[]){
        let actions: IModuleAction[] = [];
        moduleIdx.map( (idx) => {
            if(Array.isArray(modules[idx].module_actions) && modules[idx].module_actions.length){
                actions = actions.concat(modules[idx].module_actions);
            }
        });
        if(!actions.length){
            return undefined;
        }
        const render_action = (a: IModuleAction)=>{
            const send_event = (event_name)=> {
                if(event_name){
                    EventBus.emit(event_name, null);
                }
            };
            return <Button intent={a.intent} icon={a.icon} text={a.caption} onClick={()=> send_event(a.event)} />;
        };
        return (
            <ButtonGroup minimal={true}>
                {actions.map((a) => render_action(a))}
            </ButtonGroup>
        );
    }

    private onPdfCall(){
        // this.setState({showPdfInProgress: true});
        const keys =[ ...this.moduleInstances.keys() ];
        const pdf_pages = [];

        const cover = this.props.module.getPdfPageCover();
        if(cover){
            pdf_pages.push(cover);
        }
        const parameters = this.props.module.getPdfParameters();
        if(parameters){
            pdf_pages.push(parameters);
        }

        keys.forEach((key)=>{
            const ref:React.RefObject<BaseModule<any>> = this.moduleInstances.get(key);
            if(!ref.current){
                return;
            }
            try{
                const content = ref.current.getPdfPage();
                if(Array.isArray(content)){
                    content.forEach((i)=>pdf_pages.push(i));
                }
            }catch (e) {
                console.error(e);
            }
        });

        const disclose = this.props.module.getPdfPageDisclose();
        if(disclose){
            pdf_pages.push(disclose);
        }
// createPdfTask
        (async () => {
            try {
                PdfCreate.create(pdf_pages);
                Globals.trackInfo(EStatisticsValueType.pdf_export, this.props.module.name);
            } catch (ex) {
                console.error(ex);
            }finally {
                // this.setState({showPdfInProgress: false});
            }
        })();
    }
}
