import {CacheToolsAllParameters} from "../../helpers/CacheTools";
import {EParameters} from "../../models/EParameters";
import {BaseDataProvider} from "./BaseDataProvider";
import {CacheEntry, ChefCalls} from "../../services/ChefCalls";
import {IRawBetaResponse, RawBetaParams} from "../../models/chef/RawBeta";
import {SessionStore} from "../../const/SessionStore";
import {Company, IUnleveredBeta, UnleveredBetaParams} from "../../models/chef/UnleveredBeta";
import {
    ConvertAdjustmentParam,
    ConvertDebtBetaParam,
    ConvertDistanceParam,
    ConvertNegativGearingParam,
    ConvertTaxShieldParam, EPeriodToCompress
} from "../../const/RawBetaParamConv";
import {median} from "../../helpers/Statistics";
import {Sectors} from "../../const/Sectors";
import {_t_region} from "../../const/Regions";

const betas_for_business_provider_cache: CacheEntry = CacheToolsAllParameters("betas_for_business_provider_cache");
const industryCache: CacheEntry = CacheToolsAllParameters("industryCache");

const putBeta= (target, code, c, beta)=>{
    const s_values = target[code];
    s_values.count++;
    s_values.values.push(beta?.result?.unlevered_beta);
    s_values.beta.push(beta?.result?.levered_beta);
    s_values.debt_beta.push(beta?.result?.debt_beta);
    s_values.tax_shield.push(beta?.result?.tax_shield);
    s_values.debt.push(beta?.result?.gearing);
    s_values.companies.push(c.company_id);
    s_values.companies_raw.push({
        id: c.company_id,
        country_id: c.country_id,
        v: beta?.result?.unlevered_beta,
        name: c.name,
        beta: beta?.result?.levered_beta,
        debt_beta: beta?.result?.debt_beta,
        tax_shield: beta?.result?.tax_shield,
        debt: beta?.result?.gearing,
    });
}
const addNew = (target, code)=>{
    if(!target[code]){
        target[code] = {
            sector_id: code,
            count: 0,
            companies: [],
            companies_raw: [],
            values: [],
            beta: [],
            debt_beta: [],
            tax_shield: [],
            debt: [],
        }
    }
}
export class BetasForBusinessProvider extends BaseDataProvider {
    protected initProvider() {
        this.className = "BetasForBusinessProvider";
        this.parameterMapping = [
            EParameters.SectorParameterV2,
            EParameters.RegionParameterV2,
            EParameters.DateParameter,
            EParameters.PeriodsParameter,
            EParameters.IndexTypeParameter,
            EParameters.ReturnTypeParameter,
            EParameters.CompositionTypeParameter,
            EParameters.AdjusmentBetaParameter,
            EParameters.DebtbetaParameter,
            EParameters.GearingPeersParameter,
            EParameters.IndexReturnParameter,
            EParameters.NegativeVerschuldungParameter,
            EParameters.TaxshieldParameter,
            EParameters.MahalanobicDistanceParameter,
            EParameters.CreditSpreadsParameter,
            EParameters.MarketRiskPremiumParameter,
            EParameters.LocalTypeParameter,
        ];
    }
    protected update() {
        const deadline: string = this.mappedParameters.get(EParameters.DateParameter)[0];
        const period_next = 5;
        const composition_type = this.mappedParameters.get(EParameters.CompositionTypeParameter)[0];
        const return_type = this.mappedParameters.get(EParameters.ReturnTypeParameter)[0];
        const index_type = this.mappedParameters.get(EParameters.IndexTypeParameter)[0];
        const fx = this.mappedParameters.get(EParameters.LocalTypeParameter)[0];

        const period = this.mappedParameters.get(EParameters.PeriodsParameter)[0];
        const years = parseInt(period, 10);

        const adjustment_param = this.mappedParameters.get(EParameters.AdjusmentBetaParameter)[0];

        // debt beta
        const debt_beta_param  = this.mappedParameters.get(EParameters.DebtbetaParameter)[0];
        const credit_spreads_param  = this.mappedParameters.get(EParameters.CreditSpreadsParameter)[0];
        const market_risk_param = parseFloat(this.mappedParameters.get(EParameters.MarketRiskPremiumParameter)[0]);

        // gearing
        const distance_param = this.mappedParameters.get(EParameters.MahalanobicDistanceParameter)[0];
        const negativ_gearing_param = this.mappedParameters.get(EParameters.NegativeVerschuldungParameter)[0];
        const gearing_peers = this.mappedParameters.get(EParameters.GearingPeersParameter)[0];

        // tax shield
        const tax_shield_param = this.mappedParameters.get(EParameters.TaxshieldParameter)[0];
        const db = new ChefCalls();
        (async () => {
            const sectors = SessionStore.get(EParameters.SectorParameterV2);
            const regions = SessionStore.get(EParameters.RegionParameterV2);

            const raw_beta_parameters: RawBetaParams = {
                companies: [],
                selected_period: null,
                years: [years],
                periods_years: {},
                deadline,
                period_next,
                composition_type,
                return_type,
                index_type,
                fx,
                region: regions,
                sectors,
            };
            raw_beta_parameters.periods_years[years]=[EPeriodToCompress[period]];

            const raw_betas = await db.loadIndustryBeta(raw_beta_parameters, industryCache);
            const base_rate = await db.getBaseRate(deadline);

            const companies: Company[] = [];
            Object.keys(raw_betas).forEach((company_id)=>{
                const r: IRawBetaResponse = raw_betas[company_id];
                const rb = {};
                if(r.periods && r.periods[period]){
                    rb[period] = {
                        beta: r.periods[period].Slope,
                        r: r.periods[period].R,
                    };
                }
                companies.push({company_id: r.company_id, raw_beta: rb});
            });
            const unlevered_beta_params_ext: UnleveredBetaParams = {
                companies: [],
                deadline,
                period: years,
                raw_beta_type: ConvertAdjustmentParam[adjustment_param],
                // gearing
                use_deadline: ConvertDistanceParam[distance_param],
                dont_use_negativ_gearing: ConvertNegativGearingParam[negativ_gearing_param],
                gearing_peers,
                // tax shield
                tax_shield_type: ConvertTaxShieldParam[tax_shield_param],
                // debt beta
                debt_beta_filter: ConvertDebtBetaParam[debt_beta_param],
                sector_id: credit_spreads_param === "all_sector" ? 0 : undefined,
                market_risk_premium: market_risk_param,
            };
            const unlevered_beta = await db.getUnleveredBeta({...unlevered_beta_params_ext, deadline, companies, company_betas_by_date: false}, betas_for_business_provider_cache);

            const region_to_idx: { [c:string] : any[] } = {};
            const all_values = {};
            Object.keys(unlevered_beta).forEach((company_id)=>{
                const unlevi = unlevered_beta[company_id];
                if(!unlevi){
                    return;
                }
                const beta: IUnleveredBeta = unlevi[period];
                if(!beta){
                    return;
                }
                const values = all_values;
                addNew(values, unlevi.sector);
                addNew(values, unlevi.sub_sector);
                putBeta(values, unlevi.sector, unlevi, beta);
                putBeta(values, unlevi.sub_sector, unlevi, beta);
            });
            // console.error(all_values);
            const result = {
                base_rate,
                label: regions.map((i)=>_t_region(i)).join(", "),
                result: {},
            };
            sectors.split(",").forEach((sector, sector_index)=>{
                const v = all_values[sector];
                if(!v){
                    return;
                }
                result.result[sector] = {
                    sector: {
                        count: v.count,
                        companies: v.companies_raw,
                        median: median(v.values),
                        median_beta: median(v.beta),
                        median_debt_beta: median(v.debt_beta),
                        median_tax_shield: median(v.tax_shield),
                        median_debt: median(v.debt),
                    },
                    sub_sectors: {},
                };
                Sectors.sectors_sub_sectors_map[sector].forEach((sub_sector)=>{
                    const vs = all_values[sub_sector];
                    if(!vs){
                        return;
                    }
                    result.result[sector].sub_sectors[sub_sector] = {
                        count: vs.count,
                        companies: vs.companies_raw,
                        median: median(vs.values),
                        median_beta: median(vs.beta),
                        median_debt_beta: median(vs.debt_beta),
                        median_tax_shield: median(vs.tax_shield),
                        median_debt: median(vs.debt),
                    };
                });
            });
            console.error(result);
            this.afterUpdate(result);
        })();
    }
}
