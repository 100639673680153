import {Globals} from "../../../const/Globals";
import {fromStr} from "../../../tools/DateTools";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {Colors, Icon} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";

export const getIPO = (a_date, format) => {
    if(!a_date){
        return Globals.hyphen;
    }
    const toDay = Globals.last_db;
    const the_date = fromStr(a_date,format);
    if(!the_date.isValid){
        return Globals.hyphen;
    }
    const cmp_date = the_date.asDateDecimal(); // parseInt(the_date.toFormat("yyyyLL"), 10);
    if(cmp_date > toDay){
        return Globals.hyphen; // ungültig IPO liegt in der Zukunft
    }
    const period = parseInt(SessionStore.get(EParameters.PeriodParameter), 10);
    const deadline = fromStr(SessionStore.get(EParameters.DateParameter)).startOf("month").minus({year: period}).asDateDecimal();
    if(deadline < cmp_date){
        return (
            <div style={{width: "100%", color: Colors.ORANGE2}} title={`IPO(${the_date.asDeDate()}) liegt im Betrachtungszeitraum`}>
                <Icon icon={IconNames.WARNING_SIGN} size={16} /><span style={{marginLeft: 5}}>{the_date.asDeDate()}</span>
            </div>
        );
    }
    return the_date.asDeDate();
};
export const getLastDate = (a_date, format) => {
    if(!a_date){
        return Globals.hyphen;
    }
    const last_db = Globals.last_db;
    const the_date = fromStr(a_date,format);
    if(!the_date.isValid){
        return Globals.hyphen;
    }
    const cmp_date = the_date.asDateDecimal(); // parseInt(the_date.toFormat("yyyyLL"), 10);
    if(cmp_date >= last_db){
        return Globals.hyphen; // ungültig liegt in der zukunft
    }
    const period = parseInt(SessionStore.get(EParameters.PeriodParameter), 10);
    const deadline = fromStr(SessionStore.get(EParameters.DateParameter)).startOf("month").minus({year: period}).asDateDecimal();
    if(deadline > cmp_date){
        return (
            <div style={{width: "100%", color: Colors.ORANGE2}} title={`Delisted`}>
                <Icon icon={IconNames.WARNING_SIGN} size={16} /><span style={{marginLeft: 5}}>{the_date.asDeDate()}</span>
            </div>
        );
    }
    return the_date.asDeDate();
};
