import * as React from "react";
import {Plugin,} from 'chart.js';

export interface ICustomAxisLabel {
    x_label?: string;
    y_label?: string;
}

class CustomAxisLabelClass{
    public id = "custom_axis_label";
    private renderLabel(label: string, ctx: CanvasRenderingContext2D, align, baseline ,x: number, y: number){
        ctx.save();
        ctx.textBaseline = baseline;
        ctx.textAlign = align;
        ctx.fillText(label, x, y);

        ctx.restore();
    }
    public beforeDatasetsDraw(chart, args, options){
        if(!chart.options.custom_axis_label){
            return true;
        }
        const min_x = chart.scales.x.min;
        const max_x = chart.scales.x.max;
        const left = chart.scales.x.left;
        const min_y = chart.scales.y.min;
        const max_y = chart.scales.y.max;
        const top = chart.scales.y.top;
        const height = chart.scales.y.height;
        const width = chart.scales.x.width;

        // console.error(chart);
        const l: ICustomAxisLabel = chart.options.custom_axis_label;
        const ctx: CanvasRenderingContext2D = chart.ctx;

        if(l.x_label){
            this.renderLabel(l.x_label, ctx, "end", "alphabetic", left + width - 5, height + top - 10);
        }
        if(l.y_label){
            this.renderLabel(l.y_label, ctx, "start", "hanging",left + 10, top + 10);
        }

        return true;
    }
}

export const CustomAxisLabel = (new CustomAxisLabelClass()) as Plugin;
