import {Button, HTMLTable, Icon, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {ParameterOptions} from "../../const/ParameterOptions";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {ICompany} from "../../models/ICompanyDetails";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {fromStr} from "../../tools/DateTools";
import {SzYearsFilter} from "../widgets/SzYearsFilter";
import {BaseModule} from "./BaseModule";
import {Sic3, SicClasses} from "../../const/Sic";
import {ParameterConfigs} from "../../const/ParameterConfigs";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

interface ITableField {
    text?: string;
    content?: any;
    colSpan?: number;
    style?:CSSProperties;
}
interface ITransferPricingSummaryState{
    sector?: number;
    sub_sector?: number;
    free_float?: number;
    country_ids?: string[];
    years?: number[];
    sales_start?: number;
    sales_stop?: number;

    is_loading?: boolean;
}
export class TransferPricingSummaryLongList extends BaseModule<any> {

    private long_list: ICompany[] = [];
    private readonly evtUpdateRejected: IEventHandler;
    private all_rejected: number = 0;

    constructor(props: any, context: ITransferPricingSummaryState) {
        super(props, context);
        this.state= {
            is_loading: false,
            years: SzYearsFilter.getDefaultYears(),
        };
        this.evtUpdateRejected = EventBus.subscribe("TP::update-rejected", (data) => this.onUpdateRejected(data));
    }
    componentWillUnmount() {
        EventBus.unsubscribe(this.evtUpdateRejected);
    }
    public modState(s: ITransferPricingSummaryState){
        const ss = Object.assign({}, this.state, s);
        this.setState(s);
        return ss;
    }
    public async getDocumentationData() {
        const data = [];
        return data;
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    protected onAfterUpdate(data: any) {
        this.data = data;
        this.setState({
            long_list: data.long_list,
            loading: false,
            rejected: {},
            all_rejected: {},
            financials: data.financials
        });
    }
    private onUpdateRejected(data: any) {
        // {id: company.id, rejected: boolean, all_rejected}
        const rejected = this.state.rejected;
        rejected[data.id] = data.rejected;

        this.all_rejected = 0;
        Object.keys(rejected).forEach((k) => {
            if(rejected[k]){
                this.all_rejected ++;
            }
        });

        this.setState({
            rejected,
            all_rejected: data.all_rejected,
        });
    }
    private getYears(): number[]{
        const year = this.getParameterValue(EParameters.TpYearsParameter);
        const result: number[] = [];
        const steps = (new Array(3)).fill(0);
        let y: number = year;
        steps.forEach(() =>result.push(y--));
        return result.reverse();
    }
    protected renderContent() {
        const cc_ll = this.data && Array.isArray(this.data.long_list) ? this.data.long_list.length : 0;
        const fieldMap: ITableField[] = [];

        const [sic_classes, sic_codes] = this.getParameterValue(EParameters.TpSicClassParameter,[]);
        if(sic_codes && Array.isArray(sic_codes) && sic_classes && Array.isArray(sic_classes) ) {
            const toRemove = [];
            sic_codes.forEach( (sic4) => {
                const a_s2=Math.trunc(sic4/100);
                sic_classes.forEach((sc)=>{
                    const b_s2=Math.trunc(sc/100);
                    if(b_s2 === a_s2){
                        toRemove.push(sc);
                    }
                });
            });
            while(toRemove.length) {
                const rm = toRemove.shift();
                const idx = sic_classes.indexOf(rm);
                if(idx>=0) {
                    sic_classes.splice(idx, 1);
                }
            }
        }
        fieldMap.push({text: "Referenzjahr", content: this.getYears()[2]});
        fieldMap.push({text: "Suchbegriffe", content: this.getKeyWords(), style: {verticalAlign: "top"}});
        fieldMap.push({text: "SIC-Code", content: this.renderSic(sic_classes, sic_codes), style: {verticalAlign: "top"}});
        fieldMap.push({text: _t(ETranslation.countries), content: this.getCountries(), style:{verticalAlign: "top"}});
        fieldMap.push({text: "Streubesitz", content: this.getFreeFloat()});
        fieldMap.push({text: "Umsatzgröße", content: this.SalesVolumeFrom()});
        fieldMap.push({text: "", content: this.SalesVolumeTill()});
        fieldMap.push({text: "Mitarbeiteranzahl", content: this.EmployeesFrom()});
        fieldMap.push({text: "", content: this.EmployeesTill()});
        fieldMap.push({text: "", content: "", colSpan: 2});
        fieldMap.push({text: "Datenbank", content: this.getDbDate()});
        fieldMap.push({text: "", content: this.getDbCount()});
        fieldMap.push({text: "", content: "", colSpan: 2});
        fieldMap.push({text: "Longlist", content: this.getCCLongList(cc_ll), style: {fontWeight: 600, textAlign: "right"}});
        fieldMap.push({text: "", content: "", colSpan: 2});

        const renderRow = (row, row_num) => {
            const style:CSSProperties = {
                verticalAlign: "middle",
                textAlign: "right",
                boxShadow: "none",
            };
            if(row.style){
                Object.assign(style, row.style);
            }
            if(row.colSpan){
                return (
                    <tr>
                        <td colSpan={row.colSpan} style={style}>{row.content}</td>
                    </tr>
                );
            }
            const label_style = Object.assign({width: 100}, style, {textAlign: "left"});
            /*
            if(Array.isArray(row.content)){
                return (
                    <tr>
                        <td  style={label_style}>{row.text}</td>
                        {row.content.map((c) => (<td  style={style}>{c}</td>))}
                    </tr>
                );
            }
            */
            return (
                <tr>
                    <td  style={label_style}>{row.text}</td>
                    <td  style={style}>{row.content}</td>
                </tr>
            );
        };

        return (
            <div className={"sz-row"} style={{paddingTop: 16}}>
                <div className={"sz-col sz-col-100"}>
                    <HTMLTable className={`sz-table`} condensed={true} style={{width: "100%", borderCollapse: "separate"}}>
                        <tbody>
                            {fieldMap.map((m, idx) => renderRow(m, idx))}
                        </tbody>
                    </HTMLTable>
                </div>
            </div>
        );
    }
    protected noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }
    private renderContact() {
        return (
            <div>
                <div className={"sz-loco sz-loco-big"} style={{alignItems: "center"}}>
                    <div  className={"sz-loco-icon"}><Icon icon={IconNames.ENVELOPE} color={"#5c7080"} iconSize={48} /></div>
                    <div className={"sz-loco-text"}><div style={{textAlign: "center"}}>Zum Thema <strong>Transfer-Pricing</strong> setzen Sie sich bitte mit uns in Verbindung.</div></div>
                    <div style={{margin: 32}}>
                        <Button icon={IconNames.ENVELOPE} intent={"primary"} text={"Kontakt Formular"} onClick={() => window.open("https://www.smart-zebra.de/kontakt/") } />
                    </div>
                </div>
            </div>
        );
    }
    private getSector() {
        return "leer";
    }

    private getFreeFloat() {
        const freeFloat = this.getParameterValue(EParameters.TpFreeFloatParameter);
        const o = ParameterOptions.options[EParameters.TpFreeFloatParameter].find( (i) => i.value === freeFloat );
        if(!o){
            return "kein Einschränkung";
        }
        return o.label;
    }
    private getKeyWords(){
        const words: string[] = SessionStore.get(EParameters.TpKeyWordsWordsParameter);
        if(!Array.isArray(words)){
            return Globals.hyphen;
        }
        if(!words.length){
            return Globals.hyphen;
        }
        return words.join(", ");
    }
    private getCountries() {
        const country_ids: string[] = SessionStore.getItem(EParameters.TpCountriesParameter);
        if(!Array.isArray(country_ids)){
            return Globals.hyphen;
        }
        const flag_all = SessionStore.get(EParameters.TpCountriesAllParameter);
        if(flag_all){
            return "Keine Einschränkung";
        }
        const c = [];
        let m = 0;
        country_ids.forEach( (i) => {
            if(c.length > 10){
                m++;
            }else{
                c.push(Globals.country_map[i].name_de);
            }
        } );
        let l = "";
        if(m > 0){
            if(m === 1){
                l = " und ein weiteres";
            }else{
                l = ` und ${m} weitere`;
            }
        }
        return `${c.join(", ")} ${l}`;
    }

    private SalesVolumeFrom() {
        const sales_range = [0, 50, 100, 250, 500, 1500, 5000, 10000, 50000, 100000, 300000000];
        const range = SessionStore.getItem(EParameters.TpSalesParameter)[0];
        let o = Globals.hyphen;
        try{
            if(range[0] === range[1]){
                if(sales_range[sales_range.length - 1] === range[1]){
                    o = Globals.infinity;
                }else{
                    o = Globals.formatter(sales_range[range[1]],0,0);
                }
            }else{
                o = Globals.formatter(sales_range[range[0]],0,0);
            }
        }catch(e){
            console.error("range", range);
            console.error(e);
        }
        return this.mkTwo("von", o);
    }
    private SalesVolumeTill() {
        const sales_range = [0, 50, 100, 250, 500, 1500, 5000, 10000, 50000, 100000, 300000000];
        const range = SessionStore.getItem(EParameters.TpSalesParameter)[0];
        let o = Globals.hyphen;
        try{
            if(range[0] === range[1]){
                if(sales_range[sales_range.length - 1] === range[1]){
                    o = Globals.infinity;
                }else{
                    o = Globals.formatter(sales_range[range[1]],0,0);
                }
            }else{
                o = range[1] === sales_range.length - 1 ? Globals.infinity : Globals.formatter(sales_range[range[1]],0,0);
            }
        }catch(e){
            console.error("range", range);
            console.error(e);
        }
        return this.mkTwo(_t(ETranslation.to), o);
    }

    private EmployeesFrom() {
        const data_range = ParameterConfigs.configs[EParameters.TpNumEmployeesParameter].range;
        const range = SessionStore.getItem(EParameters.TpNumEmployeesParameter)[0];
        let o = Globals.hyphen;
        try{
            if(range[0] === range[1]){
                if(data_range[data_range.length - 1] === range[1]){
                    o = Globals.infinity;
                }else{
                    o = Globals.formatter(data_range[range[1]],0,0);
                }
            }else{
                o = Globals.formatter(data_range[range[0]],0,0);
            }
        }catch(e){
            console.error("range", range);
            console.error(e);
        }
        return this.mkTwo("von", o);
    }
    private EmployeesTill() {
        const data_range = ParameterConfigs.configs[EParameters.TpNumEmployeesParameter].range;
        const range = SessionStore.getItem(EParameters.TpNumEmployeesParameter)[0];
        let o = Globals.hyphen;
        try{
            if(range[0] === range[1]){
                if(data_range[data_range.length - 1] === range[1]){
                    o = Globals.infinity;
                }else{
                    o = Globals.formatter(data_range[range[1]],0,0);
                }
            }else{
                o = range[1] === data_range.length - 1 ? Globals.infinity : Globals.formatter(data_range[range[1]],0,0);
            }
        }catch(e){
            console.error("range", range);
            console.error(e);
        }
        return this.mkTwo(_t(ETranslation.to), o);
    }

    private renderSic(sic_classes: number[], sic_codes: number[]) {
        // console.error(sic_classes, sic_codes);
        if(!sic_classes && !sic_codes){
            return Globals.hyphen;
        }
        if(sic_classes.length===0 && sic_codes.length===0){
            return Globals.hyphen;
        }
        const a = SicClasses.filter( (s) => sic_classes.indexOf(s.code)>=0 );
        const b= [];
        const bb= {};
        sic_codes.forEach( (s) =>{
            const sic3 = Math.trunc(s/10);
            const sic3code = sic3<100 ? `0${sic3}` : `${sic3}`;
            if(!bb[sic3code]){
                bb[sic3code] = true;
                b.push(sic3code);
            }
        });
        const r = [];
        const getEntry = (code: any, text: string) => {
            return (
                <div>
                    <span style={{fontSize: "75%", marginRight: 5}}>{code}</span>
                    <span>{text}</span>
                </div>
            );
        };
        a.forEach( (s) =>{
            const i = Math.trunc(s.code/ 100);
            const sc = i<100 ? `0${i}` : i;
            r.push(getEntry(sc, s.text));
        });
        b.forEach( (s) =>{
            r.push(getEntry(s, Sic3[s]));
        });
        return r.map((i) => i);
    }
    private mkTwo(a, b){
        return (
            <div style={{display: "flex", justifyContent: "space-between", float: "right", width: 200}}>
                <span>{a}</span>
                <span>{b}</span>
            </div>
        );
    }
    private getDbCount() {
        return this.mkTwo("# Unternehmen", "22.000");
    }
    private getDbDate() {
        return this.mkTwo("Stand", fromStr(Globals.last_db, "yyyyLLdd").asDeDate());
    }
    private getCCLongList(cc_ll: any) {
        return this.mkTwo("# Unternehmen", cc_ll);
    }
}
