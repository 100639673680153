import {MultiplierTools} from "../../helpers/MultiplierTools";
import {EParameters} from "../../models/EParameters";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {BaseDataProvider} from "./BaseDataProvider";
import {deadlineEoM, fromDateParameter} from "../../tools/DateTools";
import {ChefCalls} from "../../services/ChefCalls";
import {group_by, map_by} from "../../helpers/Helpers";
import {CompanyMultipliers} from "../../tables/CompanyMultipliers";
import {_t_region} from "../../const/Regions";

export class MultiplicatorsForBusinessProvider extends BaseDataProvider {
    protected initProvider() {
        this.className = "MultiplicatorsForBusinessProvider";
        this.parameterMapping = [
            EParameters.DateParameter,
            EParameters.SectorParameterV2,
            EParameters.RegionParameterV2,
        ];
    }
    protected update() {
        (async () => {

            const db = new PublicDbCalls();
            const chefDb = new ChefCalls();
            const s = this.mappedParameters.get(EParameters.SectorParameterV2);
            const r = this.mappedParameters.get(EParameters.RegionParameterV2);

            // const dates: string[] = [deadlineEoM()];

            const ret_companies = await db.filterCompanies(Array.isArray(s) && s[0]!==undefined ? s[0].split(",").map((i)=> parseInt(i, 10)) : [1100], r[0]);
            const companies = ret_companies && ret_companies.result ? ret_companies.result : [];
            const company_ids = companies.map( (c) => parseInt(c.id,10) );

            const company_multiplier_time_series: CompanyMultipliers[] = await chefDb.getCompanyMultipliers(deadlineEoM(), company_ids, 3);


            const deadline = fromDateParameter().setToEoM();
            const dd = deadline.asDateDecimal();
            const cm_mapped_by_dd = group_by<CompanyMultipliers>(company_multiplier_time_series, "date_decimal");

            const companies_by_country_idx = [];
            companies_by_country_idx.push({
                companies,
                time_series: {},
                label: r[0] !== null ?r[0].map((i)=>_t_region(i)).join(", ") : "Alle Regionen",
            });

            const data = [];
            companies_by_country_idx.forEach( (e) => {
                Object.keys(cm_mapped_by_dd).forEach((date_decimal)=>{
                    const m = cm_mapped_by_dd[date_decimal];
                    const multiplier_map = map_by(m, "company_id");
                    e.time_series[date_decimal] = MultiplierTools.get_each_sector(e.companies, multiplier_map);
                });
                e.multiplier = e.time_series[dd];
                data.push(e);
            } );
            this.afterUpdate(data);
        })();
    }
}
