export interface ServiceCallCacheOptions {
    changeable_fields : string[],
    ignore_fields? : string[],
    testFunctions?: any,
    response_filter(parameters, response),
}
export class ServiceCallCache {

    private called = false;

    private cacheId;
    private old_parameter_map = {};
    private readonly call_wrapper;
    private options: ServiceCallCacheOptions;
    private response: {};

    constructor(cacheId, initial_parameters, options: ServiceCallCacheOptions, call_wrapper) {
        this.cacheId = cacheId;
        // Object.keys(initial_parameters).forEach((key)=>this.old_parameter_map[key] = initial_parameters[key]);
        this.old_parameter_map = JSON.parse(JSON.stringify(initial_parameters));
        this.call_wrapper = call_wrapper;
        this.options = options;
    }
    public invalidate(){
        this.called= false;
    }
    public async exec(new_parameters){
        if(!this.call_wrapper){
            throw new Error("no cache call wrapper");
        }
        let response = {};
        if(!this.called){
            response = await this.call_wrapper(new_parameters);
            this.response = response;
            this.called = true;
            return this.response;
        }
        const refresh: boolean = this.testNeedRefresh(new_parameters);
        if(refresh){
            response = await this.call_wrapper(new_parameters);
            this.response = response;
            this.called = true;
            return this.response;
        }
        return this.filterCache();
    }

    private testNeedRefresh(new_parameters) {
        const fields = this.options?.changeable_fields;
        const ignore_fields = this.options?.ignore_fields ? this.options?.ignore_fields : [];
        if(!Array.isArray(fields)){
            this.old_parameter_map = JSON.parse(JSON.stringify(new_parameters));
            return true;
        }
        let changes = false;
        Object.keys(new_parameters).forEach((key)=>{
            if(changes === false){
                if(ignore_fields.includes(key)){
                    return;
                }
                if(fields.includes(key)){
                    const testFunctions = this.options?.testFunctions;
                    if(!testFunctions){
                        changes = true;
                        return;
                    }
                    const testCall = testFunctions[key];
                    if(testCall){
                        changes = testCall(this.old_parameter_map, new_parameters);
                    }else{
                        changes = true;
                    }
                }else{
                    changes = JSON.stringify(this.old_parameter_map[key]) !== JSON.stringify(new_parameters[key]);
                }
            }
        });
        this.old_parameter_map = JSON.parse(JSON.stringify(new_parameters));

        return changes;
    }

    private filterCache() {
        const response_filter = this.options?.response_filter;
        if(response_filter){
            return response_filter(this.old_parameter_map, this.response);
        }else{
            return this.response;
        }
    }
}

const serviceCallCacheMap = {};

export const cacheInvalidateAll = ()=>{
    Object.keys(serviceCallCacheMap).forEach((key)=>{
        const c: ServiceCallCache = serviceCallCacheMap[key];
        c.invalidate();
    });
};
export const cacheCall = async (cacheId, parameters, options: ServiceCallCacheOptions, call: (new_parameters) => Promise<any>)=>{
    if(!cacheId){
        throw new Error("no cache id");
    }
    if(!serviceCallCacheMap[cacheId]){
        serviceCallCacheMap[cacheId] = new ServiceCallCache(cacheId, parameters, options, call);
    }
    return serviceCallCacheMap[cacheId].exec(parameters);
}
