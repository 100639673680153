import {ContentTable} from "pdfmake/interfaces";
import {EContentType} from "../const/EContentType";
import {EDataSourceMode} from "../const/EDataSourceMode";
import {EPackageTypes} from "../const/EPackageTypes";
import {Globals} from "../const/Globals";
import {NopeActions} from "../const/NopeActions";
import {EFeItem} from "../models/EFeItem";
import {EModuleNames} from "../models/EModuleNames";
import {EParameters} from "../models/EParameters";
import {IModuleDocumentation, IModuleDocumentationOptions} from "../models/IModuleDocumentation";
import {ModuleBase} from "./ModuleBase";
import {PdfParameter} from "../pdf-tools/PdfParameter";
import {PdfInsertElement} from "../pdf-tools/PdfInsertElement";
import {fromNow} from "../tools/DateTools";
import {SvgImages} from "../const/SvgImages";

const _ref = Globals._ref;

export abstract class ModuleGroup<K = {}> {
    protected abstract getTitle(): string;
    public getPdfPageCover(): any[] {
        const content = [];

        const bg_image ={
            margin: [-50,-50,-50,-50],
            pageSize: { width: 1000.63, height: 708.66},
            unbreakable: true,
            height: 708.66,
            width: 1000.63,
            absolutePosition: { x: 0, y: 0 },
            image: "std_bg",
        };

        const h=79;
        const w=260;
        const image ={
            svg: SvgImages.zebra_svg,
            style: {
                alignment: "center",
            },
            margin: [0,200,0,0],
            height: h,
            width: w,
        };
        const cover={
            style:{
                fontSize: 24,
                alignment: "center",
                color:'#ffffff',
            },
            margin:[0,50,0,0],
            text: "Dokumentation",
        };
        const subTitel = {
            text: this.getTitle(),
            style:{
                fontSize: 42,
                bold: true,
                alignment: "center",
                color:'#ffffff',
            },
            // margin:[0,25,0,0],
        };
        const date = {
            text: `${fromNow().toFormat("dd. LLLL yyyy")}`,
            margin: [0,5,0,0],
            style:{
                fontSize: 22,
                alignment: "center",
                color:'#ffffff',
            },
        };
        const bommel ={
            svg: SvgImages.arrow_circle,
            style: {
                alignment: "center",
            },
            margin: [0,150,0,0],
            height: 50,
            width: 50,
        };
        content.push(bg_image, image, cover, subTitel, date,bommel);

        return content;
    }
    public getPdfParameters(): any[] {
        PdfParameter.init();
        const table_body = [];
        this.parameters.forEach((parameter)=>{
            const pdf_parameter = PdfParameter.get_parameter(parameter);
            if(!pdf_parameter){
                console.error(parameter, "nicht beschrieben");
                return;
            }
            const row = [
                {text: pdf_parameter.caption},
                pdf_parameter.content
            ];
            table_body.push(row);
        });
        if(table_body.length){
            const ret = [];
            const table: ContentTable = {
                layout: "noBorders",
                table: {
                    widths: ["auto", "auto"],
                    body: table_body,
                },
            } as ContentTable;

            PdfInsertElement.page_header(ret, "Parameter");
            ret.push(table);
            return ret;
        }
        return null;
    }
    public getPdfPageDisclose(): any[] {
        const bg_image ={
            pageBreak: "before",
            margin: [-50,-50,-50,-50],
            unbreakable: true,
            height: 708.66,
            width: 1000.63,
            absolutePosition: { x: 0, y: 0 },
            image: "std_bg",
        };
        const image ={
            svg: SvgImages.zebra_svg,
            margin: [0,525,0,0],
            width: 100,
        };
        const data={
            alignment: "left",
            color:'#ffffff',
            absolutePosition: { x: 50, y: 525 },
            fontSize: 12,
            text:[
                {text:"Kontakt\n",bold:true},
                {text:"smartZebra GmbH\n"},
                "Siemens-Halske-Ring 2\n",
                "03046 Cottbus\n",
                "Deutschland\n",
                "\n",
                "Tel.: (0355) 86688153\n",
                "E-Mail: info@smart-zebra.de",
            ]
        };
        return [bg_image, image, data];
    }
    public new_pdf_export = false;

    get documentation_options(): IModuleDocumentationOptions {
        return this._documentation_options;
    }

    set documentation_options(value: IModuleDocumentationOptions) {
        this._documentation_options = value;
    }

    get documentation(): IModuleDocumentation[] {
        return this._documentation;
    }

    set documentation(value: IModuleDocumentation[]) {
        this._documentation = value;
    }
    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }
    get parameters(): EParameters[] {
        return this._parameters;
    }

    private _name: string;
    private _modules: ModuleBase[] = [];
    private _layout: K;
    private _parameters: EParameters[] = [];
    private _title: string;

    private _documentation: IModuleDocumentation[] = null;
    private _documentation_options: IModuleDocumentationOptions = null;
    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get modules(): ModuleBase[] {
        return this._modules;
    }

    set modules(value) {
        this._modules = value;
    }

    get layout(): K {
        return this._layout;
    }

    set layout(value: K) {
        this._layout = value;
    }
    public renderSingleDoc: boolean;
    public documentation_rendered: boolean;
    constructor(name: string, layout?: K) {
        this.name = name;
        this._layout = layout;
    }

    public initGlobalData() {
        this.addParameter(EParameters.NopeParameter);
        this.setupGlobalData();
    }

    protected addModule(name: string, component?: string, dataSourceClassName?: string, dataDefaults?: any, dataSourceMode?: EDataSourceMode): ModuleBase {
        const m = new ModuleBase(name);
        m.component = component;
        m.dataSource = dataSourceClassName;
        m.dataSourceMode = dataSourceMode !== undefined ? dataSourceMode : EDataSourceMode.single_instance;
        m.dataDefaults = dataDefaults;
        this.modules.push(m);
        return m;
    }
    protected addParameter(component: EParameters) {
        if(component === EParameters.DividerParameter){
            return this.parameters.push(component);
        }

        if (!this.parameters.find( (i) => component === i )){
            this.parameters.push(component);
        }
    }

    protected setupGlobalData() {
        return;
        const current_module = Globals.getWindowKey("wp_show_module");
        if (current_module === EModuleNames.CreditSpreads) {
            return;
        }
        if (current_module === EModuleNames.TransferPricing) {
            return;
        }
        if (current_module === EModuleNames.TestModModule) {
            return;
        }
        if (current_module === EModuleNames.BetaFactor) {
            return;
        }
        if (current_module === EModuleNames.SectorsModule) {
            return;
        }
        if (current_module !== EModuleNames.Multiples) {
            const dataDefault_m = [
                [],
                [],
                [EFeItem.SALES, EFeItem.EBITDA, EFeItem.EBIT, EFeItem.NETPROFIT],
                [0, 1],
            ];
            this.addParameter(EParameters.CompaniesParameter);
            this.addParameter(EParameters.DateParameter);
            this.addParameter(EParameters.FeItemParameter);
            this.addParameter(EParameters.FePerRelParameter);
            this.addModule("Multiplikatoren", "Multiplicators", "MultiplicatorsForCompaniesV2Provider", dataDefault_m)
                .setAccess(EContentType.Free, EPackageTypes.Demo)
                .mkHidden();
        }

        if (current_module !== EModuleNames.CreditSpreads) {
            this.addParameter(EParameters.DateParameter);
            this.addParameter(EParameters.SectorParameter);
            this.addParameter(EParameters.RatingParameter);
            this.addParameter(EParameters.RuntimeParameter);

            this.addModule("Kreditaufschlag", "CorporateSpread", "CorporateSpreadProvider")
                .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
                .mkHidden();
        }

        if (current_module !== EModuleNames.ZinsSaetze) {
            this.addParameter(EParameters.DateParameter);
            this.addParameter(EParameters.FixedCountriesParameter);
            this.addParameter(EParameters.RuntimeBaseInterestParameter);
            this.addParameter(EParameters.GrowthRateCashSurpluseParameter);
            this.addModule("IDW S1 Basiszinssatz", "BaseInterestRateSvg", "InterestRatesBubaAllProvider")
                .setAccess(EContentType.Free, EPackageTypes.Demo)
                .mkHidden()
            ;
        }
        if (current_module !== EModuleNames.Benchmarking) {
            this.addParameter(EParameters.CompaniesParameter);
            this.addParameter(EParameters.DateParameter);
            this.addModule("Profitabilität", "FinancialsAnnReported", "FinancialsAnnReportedProvider")
                .setAccess(EContentType.Freemium, EPackageTypes.Demo)
                .mkHidden()
                .options = [
                "sales_growth", "ebitda_sales_ratio", "ebit_sales_ratio", "netincome_sales_ratio",
                "roce", "roe",
                "ffo_debt_ratio", "debt_ebitda_ratio", "ebit_interest_ratio", "debt_equity_ratio",
                "capex_sales_ratio", "capex_capitalasset_ratio", "working_sales_ratio", "capital_turnover",
                "eq_ratio", "anlagenintensitaet", "gearing", "anlagendeckungsgrad",
            ];
        }
    }
}
