import * as React from "react";
import {useState} from "react";
import {TextArea} from "@blueprintjs/core";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export interface ISzTextInput {
    maxLen: number;
    onValueChanged?(new_value: string);
}
export function SzTextInput(props: ISzTextInput) {
    const [value, setValue] = useState("");
    const changeValue = (new_value)=>{
        if(new_value && new_value.length+1 > props.maxLen){
            return;
        }
        setValue(new_value);
        if(props.onValueChanged){
            props.onValueChanged(new_value);
        }
    };
    return (
        <div style={{width: "100%"}}>
            <TextArea
                style={{width: "100%"}}
                maxLength={props.maxLen}
                rows={10}
                value={value}
                onChange={(e)=>changeValue(e.target.value)}
            />
            <div style={{display: "flex", justifyContent: "flex-end", fontSize: "80%", marginTop: 5}} className={"bp3-text-muted"}>
                <span>{_t(ETranslation.max_characters, value.length, props.maxLen)}</span>
            </div>
        </div>
    );
}
