import {ICompanySearchResult} from "../../../models/ICompanySearchResult";
import {IUnleveredBetaResponse, UnleveredBetaResponse} from "../../../models/chef/UnleveredBeta";
import {FinancialReportMap, FinancialReportsResponse} from "../../../models/chef/FinancialReported";
import {ITableDetailsParams} from "../table_defs/TableDetailsParams";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import * as React from "react";
import * as DebtBetaTable from "../table_defs/DebtBetaTable";
import * as TaxShieldTable from "../table_defs/TaxShieldTable";
import * as GearingTable from "../table_defs/GearingTable";
import {SzTable} from "../../widgets/SzTable";


const createTableDetailsParams = (company: ICompanySearchResult, data) => {
    const unlevered_betas: UnleveredBetaResponse = data.unlevered_betas;
    const reports: FinancialReportsResponse = data.financial_reports;

    const unlevered_beta_map: IUnleveredBetaResponse = unlevered_betas ? unlevered_betas[company.company_id] : undefined;
    const company_reports: FinancialReportMap = reports ? reports[company.company_id] : undefined;
    const period = SessionStore.get(EParameters.PeriodsParameter);
    if(!unlevered_beta_map){
        return undefined;
    }
    if(!company_reports){
        return undefined;
    }
    const deadline = SessionStore.get(EParameters.DateParameter);
    const years = parseInt(period, 10);
    const mahalanobicDistanceParameter = SessionStore.get(EParameters.MahalanobicDistanceParameter);
    const use_result = mahalanobicDistanceParameter === "average" ? 0 : 1;
    const params: ITableDetailsParams = {
        show_result: use_result,
        unlevered_beta_map,
        company_reports,
        company,
        deadline,
        years,
        period,
    };
    return params;
}
const renderNoTabData = (caption) => {
    return (
        <div>{caption} keine Daten</div>
    );
}
const renderDetailsTab = (owner, fieldName, caption, company: ICompanySearchResult, data) => {
    const params: ITableDetailsParams = TabHelper.createTableDetailsParams(company, data);
    if(!params){
        return TabHelper.renderNoTabData(caption);
    }
    // console.error(fieldName, owner);
    if(fieldName === "data_debt_beta"){
        owner[fieldName] = DebtBetaTable.getTable(params);
    }
    if(fieldName === "data_tax_shield"){
        owner[fieldName] = TaxShieldTable.getTable(params);
    }
    if(fieldName === "data_debt"){
        owner[fieldName] = GearingTable.getTable(params);
    }

    if(!owner[fieldName]){
        return TabHelper.renderNoTabData(caption);
    }
    return (
        <div style={{width: "calc(100% - 1px)"}}>
            <SzTable {...owner[fieldName]} />
        </div>
    );
}
export const TabHelper = {
    renderNoTabData,
    createTableDetailsParams,
    renderDetailsTab,
}
