import {Alignment, Content, ContentTable, TableCellProperties} from "pdfmake/interfaces";
import {PdfInsertElement} from "./PdfInsertElement";
import {
    EPdfColumnDataType,
    IPdfTableCellStyle,
    IPdfTableColumn,
    IPdfTableStyleSetter,
    PdfTableColumn
} from "./PdfTableColumn";
import {_fmt} from "../helpers/Helpers";

export enum EBorder{
    left = 0,
    top = 1,
    right = 2,
    bottom = 3,
}
export interface IPdfTableBorder {
    rows: {
        border: EBorder[],
        idx: number[],
    },
    columns: {
        border: EBorder[],
        idx: number[],
    },
}

export class PdfTable {
    private table: ContentTable;

    private current_header;
    private current_row= 0;
    private headers: IPdfTableColumn[][] = new Array<IPdfTableColumn[]>();
    private columns: IPdfTableColumn[] = [];
    private footers: IPdfTableColumn[] = [];
    private row_styles: {[row_num: number]: IPdfTableCellStyle} = {};
    constructor(content: Content[], widths: any[]) {
        this.table = PdfInsertElement.table(content);
        this.table.layout = "smart_table";
        this.table.table = {
            widths,
            body: []
        };
    }

    public addHeaderRow(){
        this.current_header = this.headers.push([]);
    }
    public addHeader(colSpan?: number): PdfTableColumn{
        if(this.current_header === undefined){
            this.addHeaderRow();
        }
        const idx = this.current_header - 1;
        const c = new PdfTableColumn(EPdfColumnDataType.label_only, undefined, colSpan);
        c.style().bold(true);
        this.headers[idx].push(c.definition);
        return c;
    }
    public addColumn(data_type: EPdfColumnDataType, digits?: number, colSpan?: number): PdfTableColumn{
        const c = new PdfTableColumn(data_type, digits, colSpan);
        this.columns.push(c.definition);
        return c;
    }
    public addFooter(data_type: EPdfColumnDataType, digits?: number, colSpan?: number): PdfTableColumn{
        const c = new PdfTableColumn(data_type, digits, colSpan);
        this.footers.push(c.definition);
        return c;
    }
    public addRowStyle(row_num: number): IPdfTableStyleSetter{
        const s = {} as IPdfTableCellStyle;
        this.row_styles[row_num] = s;
        const setter: IPdfTableStyleSetter = {
            border: (v: EBorder[])=>{ s.border = v; return setter;},
            bold: (v: boolean)=>{ s.bold = v; return setter;},
            color: (v: string)=>{ s.color = v; return setter;},
            alignment: (v: Alignment)=>{ s.alignment = v; return setter;},
            noWrap: (v: boolean)=>{ s.noWrap = v; return setter;},
        };
        return setter;
    }
    public populateTable(tableData: any[], footerData?: any[]){
        try{
            this.renderHeader();
            this.table.table.headerRows = this.table.table.body.length;
            this.renderBody(tableData);
            if(footerData){
                this.renderFooter(footerData);
            }
            // console.error(this.table.table.widths);
            // console.error(this.table.table.body);
        }catch (e) {
            console.error(e);
        }
    }
    private resolveAlignment(column: IPdfTableColumn): Alignment{
        switch (column.data_type) {
            case EPdfColumnDataType.number:
            case EPdfColumnDataType.percent:
            case EPdfColumnDataType.row_num:
            case EPdfColumnDataType.delta:
                return "right";
            case EPdfColumnDataType.text:
                return "left";
            default: return column?.style.alignment ? column.style.alignment : undefined;
        }
    }
    private applyBorder(target, b: EBorder[]){
        if(Array.isArray(b)){
            b.forEach((i)=> target[i] = true)
        }
    }
    private applyRowStyle(cell){
        const row_style = this.row_styles[this.current_row - 1];
        if(!row_style){
            return;
        }
        if(row_style.bold !== undefined){
            cell.bold = row_style.bold;
        }
        if(row_style.color !== undefined){
            cell.color = row_style.color;
        }
        if(row_style.alignment !== undefined){
            cell.alignment = row_style.alignment;
        }
        this.applyBorder(cell.border, row_style.border);
    }
    private getCellContent(col: IPdfTableColumn, row_num: number, data_row: any){
        if(col.data_type === EPdfColumnDataType.row_num){
            return _fmt.m(row_num + 1, 0);
        }
        if(col.data_type === EPdfColumnDataType.number){
            return _fmt.m(data_row[col.index], col.digits);
        }
        if(col.data_type === EPdfColumnDataType.percent){
            return _fmt.p(data_row[col.index], col.digits);
        }
        if(col.data_type === EPdfColumnDataType.delta){
            return _fmt.d(data_row[col.index], col.digits);
        }
        if(col.data_type === EPdfColumnDataType.text){
            if(col.text){
                return col.text;
            }else{
                return data_row[col.index];
            }
        }
        if(col.data_type === EPdfColumnDataType.label_only){
            if(col.text){
                return col.text;
            }else{
                return data_row[col.index];
            }
        }
        return "";
    }
    private resolveCell(col: IPdfTableColumn, row_num: number, row_data: any){
        const alignment = this.resolveAlignment(col);
        const colSpan = col.colSpan;
        const bold = col.style.bold;
        const border = [false, false, false, false];
        const noWrap = col.style.noWrap;
        this.applyBorder(border, col.style.border);
        let c: any = {
            alignment,
            colSpan,
            bold,
            border,
            noWrap,
        };
        if(col.override_content){
           const d = col.override_content(row_data);
            c = {...c, ...d};
        }else{
            c.text= this.getCellContent(col, row_num, row_data);
        }
        this.applyRowStyle(c);
        return c;
    }
    private fillColSpan(col: IPdfTableColumn, pdf_row: any[]){
        if(col.colSpan && Array.isArray(pdf_row)){
            for(let i = 0; i < col.colSpan - 1; i++){
                pdf_row.push({text: ""});
            }
        }
    }
    private renderHeader(){
        this.headers.forEach((columns: IPdfTableColumn[], row_num)=>{
            const row = [];
            this.table.table.body.push(row);
            this.current_row++;
            columns.forEach((col)=>{
                row.push(this.resolveCell(col, row_num, []));
                this.fillColSpan(col, row);
            });
        });
    }
    private renderBody(data: any[]){
        if(!Array.isArray(data)){
            return;
        }
        if(Array.isArray(this.columns) && this.columns.length){
            data.forEach((row_data, row_num)=>{
                const row = [];
                this.table.table.body.push(row);
                this.current_row++;
                this.columns.forEach((col)=>{
                    row.push(this.resolveCell(col, row_num, row_data));
                    this.fillColSpan(col, row);
                });
            });
        }
    }
    private renderFooter(data: any[]){
        if(!Array.isArray(data)){
            return;
        }
        if(Array.isArray(this.footers) && this.footers.length){
            data.forEach((row_data, row_num)=>{
                const row = [];
                this.table.table.body.push(row);
                this.current_row++;
                this.footers.forEach((col)=>{
                    row.push(this.resolveCell(col, row_num, row_data));
                    this.fillColSpan(col, row);
                });
            });
        }
    }
    public header(text: string, is_small: boolean, colspan?: number, al?: Alignment, left_border?){
        return {
            text,
            border: [false, false, left_border ? true : false, false],
            alignment: al ? al : undefined,
            bold: true,
            colSpan: colspan
        };
    }
    public cell(text: any, al?: Alignment, bold?: boolean, colSpan?: number, left_border?){
        const t = {
            text,
            bold,
            colSpan,
            border: [false, false, left_border ? true : false, false],
            alignment: al ? al : undefined,
        };
        return t;
    }
    public addRow(row: any[]){
        this.table.table.body.push(row);
    }
    public getBody(){
        return this.table.table.body;
    }
    public setBorders(b: IPdfTableBorder){
        if(!b){
            return;
        }
        // Tuple order: [left, top, right, bottom]
        const tableBody = this.getBody();
        tableBody.forEach((row, row_num)=>{
            row.forEach((c: Content & TableCellProperties, index)=>{
                if(b.columns && Array.isArray(b.columns.idx) && b.columns.idx.length){
                    if(b.columns.idx.includes(index)){
                        b.columns.border.forEach((i)=>c.border[i] = true);
                    }
                }
                if(b.rows && Array.isArray(b.rows.idx) && b.rows.idx.length){
                    if(b.rows.idx.includes(row_num)){
                        b.rows.border.forEach((i)=>c.border[i] = true);
                    }
                }
            });
        });
    }
}
