import * as React from "react";
import {CSSProperties} from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {ISzSliderProperties, SzSlider} from "../../widgets/SzSlider";

const label_style: CSSProperties={
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
};

export class CreditSpreadSoftParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getPdfLabel() {
        const a = parseInt(SessionStore.get(EParameters.CreditSpreadDecisionParameterA), 10);
        const b = parseInt(SessionStore.get(EParameters.CreditSpreadDecisionParameterB), 10);
        const c = parseInt(SessionStore.get(EParameters.CreditSpreadDecisionParameterC), 10);
        const l_a = ParameterOptions.getOptionLabelHardByValue(EParameters.CreditSpreadDecisionParameterA, a, false);
        const l_b = ParameterOptions.getOptionLabelHardByValue(EParameters.CreditSpreadDecisionParameterB, b, false);
        const l_c = ParameterOptions.getOptionLabelHardByValue(EParameters.CreditSpreadDecisionParameterC, c, false);
        const content = [];
        content.push({text:[
                {text: `Markt und Wettbewerbsposition: `,bold: true}, `${l_a}`
            ]});
        content.push({text:[
                {text: `Geschäfts- und Unternehmensstrategie: `,bold: true}, `${l_b}`
            ]});
        content.push({text:[
                {text: `Finanzierungspolitik: `,bold: true}, `${l_c}`
            ]});
        return {
            stack: content,
        };
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CreditSpreadSoftParameter];
    }

    protected getWidthClass(): string {
        return "fixed-width-500";
    }

    protected async initialize() {
        const state: {[k: string]: any} = {};
        state[EParameters.CreditSpreadDecisionParameterA] = parseInt(SessionStore.get(EParameters.CreditSpreadDecisionParameterA), 10);
        state[EParameters.CreditSpreadDecisionParameterB] = parseInt(SessionStore.get(EParameters.CreditSpreadDecisionParameterB), 10);
        state[EParameters.CreditSpreadDecisionParameterC] = parseInt(SessionStore.get(EParameters.CreditSpreadDecisionParameterC), 10);
        return state;
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                <div style={{paddingTop: 10, paddingRight: 10}} className={"sz-row"}>
                    {this.renderSlider(EParameters.CreditSpreadDecisionParameterA)}
                    {this.renderSlider(EParameters.CreditSpreadDecisionParameterB)}
                    {this.renderSlider(EParameters.CreditSpreadDecisionParameterC)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.CreditSpreadSoftParameter))}
            </div>
        );
    }
    protected onClosePopup() {
        this.fireShort(EParameters.CreditSpreadSoftParameter);
    }
    protected getLabel(): any {
        const a = this.getOptionLabelHard(EParameters.CreditSpreadDecisionParameterA);
        const b = this.getOptionLabelHard(EParameters.CreditSpreadDecisionParameterB);
        const c = this.getOptionLabelHard(EParameters.CreditSpreadDecisionParameterC);
        return [a,b,c].join(" | ");
    }
    private renderSlider(parameter: EParameters) {
        const value = this.state[parameter];
        const options: IOptionEntry[] = ParameterOptions.options[parameter];
        const label = this.getParameterLabel(parameter);
        const inputProps: ISzSliderProperties = {
            value: options.findIndex((o: IOptionEntry)=> o.value === value),
            min: 0,
            max: options.length - 1,
            stepSize: 1,
            showTrackFill: false,
            labelRenderer: (v: number) => {
                const s: IOptionEntry = options[v];
                return s?.label;
            },
            onValueChanged: (v: number) => {
                const s: IOptionEntry = options[v];
                if(s){
                    this.onChangeValue(parameter, s.value);
                }
            }
        };
        return this.renderColumn("60%", label, ()=>{
            return (
                <div style={{paddingRight: 15, paddingLeft: 15}}>
                    <SzSlider {...inputProps} />
                </div>

            );
        }, label_style);
    }
    private onChangeValue(field: EParameters, _value: any) {
        const _valueAsNumber = _value;
        const state: {[k: string]: any} = {};
        state[field] = _valueAsNumber;
        this.setState(state);
    }
}
