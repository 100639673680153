import * as React from "react";
import {PropsWithChildren} from "react";
export interface IIf {
    condition: boolean;
}
export const If: React.FC<IIf> = (props) => {
    if(props.condition){
        return props.children as any;
    }else{
        return null;
    }
}
