export enum EFinancialColumn {
    company_id= 0,
    date_decimal= 1,
    currency_iso= 2,
    ff_debt= 3, // Finanzschulden
    ff_cash_generic= 4, // Liquide Mittel
    ff_pens_liabs_unfunded= 5, // Pensionsdefizit
    ff_funds_oper_gross= 6, // FFO
    ff_eq_tot= 7, // Eigenkapital
    ff_shldrs_eq= 8, // Eigenkapital der Konzernaktionäre
    ff_assets= 9,  // Bilanzsumme
    ff_ppe_net= 10, // Sachanlagen
    ff_intang= 11, // Immaterielle Vermögenstände
    ff_capex= 12, // Investitionen
    ff_wkcap= 13, // Net Working Kapital
    ff_sales= 14, // Umsatzerlöse
    ff_ebitda= 15,
    ff_ebit= 16,
    ff_int_exp_debt= 17, // Zinsaufwendungen
    ff_inc_tax= 18, // Ertragssteuern
    ff_net_inc= 19, // Jahresüberschuss
    ff_gross_inc= 20, // Rohertrag
    ff_invest_aff= 21, // Finanzanlagen
}
