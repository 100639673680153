import {Button, ControlGroup, HTMLInputProps, InputGroup, Intent} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../const/Globals";

export interface ISzNumericProperties {
    value: number;
    defaultValue?: number;
    small?: boolean;
    digits?: number;
    noFormat?:boolean;
    onValueChanged?(value: number);
}
export interface ISzNumericState {
    value: number;
    str_value: string;
    defaultValue: number;
}

export class SzNumeric extends React.Component<HTMLInputProps & ISzNumericProperties, ISzNumericState> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            value: this.props.value,
            str_value: `${Globals.formatter(this.props.value, this.props.digits, 0)}`,
            defaultValue: this.props.defaultValue !== undefined ? this.props.defaultValue : this.props.value,
        };
    }
    public render() {
        const value =  this.state.value;
        const defaultValue = this.state.defaultValue;
        const str_value: string = this.props.noFormat ? `${value}` : this.state.str_value;
        return (
            <ControlGroup fill={true} vertical={false}>
                <InputGroup lang={"de-de"} small={this.props.small} style={{textAlign: "right"}} leftIcon={"numerical"} value={str_value} onChange={ (e) => this.changeValue(e.target.value)}/>
                <Button icon="reset" small={this.props.small} intent={Intent.NONE} onClick={ () => this.resetValue(defaultValue) } style={{maxWidth: 40}} />
            </ControlGroup>
        );
    }
    private changeValue(_valueAsNumber: string) {
        let str_value = _valueAsNumber.replace(new RegExp("[^0-9\\.,]", "g"), "");
        const v = parseFloat((str_value).replace(new RegExp("\\.", "g"), "").replace(",", "."));
        const value= isNaN(v) ? 0 : v;
        if(str_value.endsWith(",")){
            str_value = str_value = Globals.formatter(value, this.props.digits, 0) + ",";
        }else{
            const idx_k = str_value.indexOf(",");
            let fr_digit = idx_k>=0 ? str_value.length - 1 - idx_k : 0;
            if(fr_digit>2){
                fr_digit = 2;
            }
            str_value = Globals.formatter(value, this.props.digits, fr_digit);
        }
        this.setState({
            value,
            str_value,
        });
        this.props?.onValueChanged(value);
    }
    private resetValue(_valueAsNumber: number) {
        const value = _valueAsNumber;
        this.setState({
            value,
            str_value: Globals.formatter(_valueAsNumber, this.props.digits),
        });
        this.props?.onValueChanged(value);
    }
}
