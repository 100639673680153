import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SzNumericPercent} from "../../widgets/SzNumericPercent";
import {ISzRangeProperties, SzRange} from "../../widgets/SzRange";
import {ISzSelectProperties, SzSelect} from "../../widgets/SzSelect";
import {CombiParameter} from "../CombiParameter";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {ECostOfCapitalFields} from "../../modules/CostOfCapitalModule";

const label_style: CSSProperties={
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
};

export class BaseRateParameter extends CombiParameter {
    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getWidthClass() {
        return "fixed-width-600";
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.BaseRateParameter];
    }

    protected async initialize() {
        const state: {[k: string]: any} = {};
        state[EParameters.RuntimeBaseInterestParameter] = SessionStore.getItem(EParameters.RuntimeBaseInterestParameter);
        state[EParameters.BaseInterestCurveParameter] = SessionStore.getItem(EParameters.BaseInterestCurveParameter);
        state[EParameters.RoundingRuleParameter] = SessionStore.getItem(EParameters.RoundingRuleParameter);
        state[EParameters.GrowthRateCashSurpluseParameter] = parseFloat(SessionStore.get(EParameters.GrowthRateCashSurpluseParameter));
        state[EParameters.FixedCountriesParameter] = SessionStore.getItem(EParameters.FixedCountriesParameter);
        return state;
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                <div style={{paddingTop: 10, paddingRight: 10}} className={"sz-row"}>
                    {this.renderCountry()}
                    <div style={{paddingTop: 5, paddingBottom: 5, width: "100%"}}>
                    {this.renderRuntime()}
                    </div>
                    <div className={"bp3-text-muted"} style={{width: "100%",fontSize: "80%", marginTop: -10, marginBottom: 10, textAlign: "right"}}>{_t(ETranslation.years)}</div>
                    {this.renderBaseInterest()}
                    {this.renderRoundingRule()}
                    {this.renderGrowthRate()}
                </div>
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.BaseRateParameter))}
            </div>
        );
    }

    protected getLabel(): any {
        const country_iso = this.state[EParameters.FixedCountriesParameter];
        const c = Globals.country_map_iso_3[country_iso];
        const {start, stop} = SzRange.getStartStop(this.state[EParameters.RuntimeBaseInterestParameter], 0, 30);
        const selection = this.getOptionLabel(EParameters.BaseInterestCurveParameter, true);
        const roundingRule = this.getOptionLabel(EParameters.RoundingRuleParameter, true);

        const v1 = start === 31 ? Globals.infinity  : Globals.formatter(start, 0);
        const v2 = stop === 31 ? Globals.infinity  : Globals.formatter(stop, 0);
        const l = v1 === v2 ? `${v1} ${_t(ETranslation.year_abbreviation)}` : `${v1} ${_t(ETranslation.to)} ${v2} ${_t(ETranslation.year_abbreviation)}`;
        const c_label = () => {
            if(!c){
                return `${country_iso} | `;
            }
            return (`${country_iso} [${c.currency_iso_3}] | `);
        };
        return `${c_label()}${selection} | ${l} | ${Globals.formatter_percent(this.state[EParameters.GrowthRateCashSurpluseParameter])} | ${roundingRule}`;
    }
    private onChangeValue(field: EParameters, _valueAsNumber: any) {
        this.onSetValue(field, _valueAsNumber);
        this.fireValueChange(field, _valueAsNumber);
    }
    private onSetValue(field: EParameters, _valueAsNumber: any) {
        const state: {[k: string]: any} = {};
        state[field] = _valueAsNumber;
        this.setState(state);
    }
    private renderRuntime() {
        const {start, stop} = SzRange.getStartStop(this.state[EParameters.RuntimeBaseInterestParameter], 0, 30);
        const inputRuntime: ISzRangeProperties = {
            start,
            stop,
            min: 0,
            max: 31,
            stepSize: 1,
            labelValues: [0, 5, 10, 15, 20, 25, 30, 31],
            labelRenderer: (v: number) => {
                if (v === 0) {
                    return "0";
                }
                if (v === 31) {
                    return "∞";
                }
                return `${v}`;
            },
            onValueChanged: (_start: number, _stop: number) => this.onSetValue(EParameters.RuntimeBaseInterestParameter, [_start, _stop]),
            onRelease: (_start: number, _stop: number) => this.onChangeValue(EParameters.RuntimeBaseInterestParameter, [_start, _stop]),
        };
        return this.renderColumn("40%", _t(ETranslation.duration), ()=>{
            return (
                <div style={{paddingRight: 10, paddingLeft: 10}}>
                    <SzRange {...inputRuntime} />
                </div>

            );
        }, label_style);
    }
    private getInputProps(parameter: EParameters, optionParam?: EParameters): ISzSelectProperties{
        return {
            options: optionParam ? ParameterOptions.options[optionParam] : ParameterOptions.options[parameter],
            value:  "" + this.state[parameter],
            onValueChanged: (v: any) => this.onChangeValue(parameter, v),
        };
    }
    private renderBaseInterest() {
        return this.renderColumn("60%", _t(ETranslation.base_rate_term_structure), ()=>{
            return (
                <SzSelect {...this.getInputProps(EParameters.BaseInterestCurveParameter)} />
            );
        }, label_style);
    }

    private renderRoundingRule() {
        return this.renderColumn("60%", _t(ETranslation.rounding), ()=>{
            return (
                <SzSelect {...this.getInputProps(EParameters.RoundingRuleParameter)} />
            );
        }, label_style);
    }

    private renderGrowthRate() {
        return this.renderColumn("60%", _t(ETranslation.growth_rate_weighted_base_rate), ()=>{
            return (
                <SzNumericPercent {...this.getInputProps(EParameters.GrowthRateCashSurpluseParameter)} />
            );
        }, label_style);
    }

    private renderCountry() {
        // console.error(this.state[EParameters.FixedCountriesParameter]);
        const options = [].concat(ParameterOptions.options[EParameters.FixedCountriesParameter]);
        options.sort( (a, b) => a.label.localeCompare(b.label) );
        const props: ISzSelectProperties = {
            options,
            value:  "" + this.state[EParameters.FixedCountriesParameter],
            onValueChanged: (v: any) => this.onChangeValue(EParameters.FixedCountriesParameter, v),
        };
        return this.renderColumn("40%", _t(ETranslation.country_and_currency), ()=>{
            return (
                <SzSelect {...props} />
            );
        }, label_style);
    }
}
