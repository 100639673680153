import {getLocale} from "../tools/Translator";

const locale = getLocale();

export const Region_Map_DeEng= {
    "Pazifik":"Pacific",
    "Mittlerer Osten":"Mid East",
    "Nordamerika":"North America",
    "Osteuropa":"Eastern Europe",
    "Afrika":"Africa",
    "Südamerika":"South America",
    "Asien":"Asia",
    "Westeuropa":"Western Europe",
};
const regions_en = [
    "Pacific",
    "Mid East",
    "North America",
    "Eastern Europe",
    "Africa",
    "South America",
    "Asia",
    "Western Europe",
];
const regions_de = [
    "Pazifik",
    "Mittlerer Osten",
    "Nordamerika",
    "Osteuropa",
    "Afrika",
    "Südamerika",
    "Asien",
    "Westeuropa"
];
const items={
    "en": regions_en,
    "de": regions_de,
};
export const Regions = items[locale] ? items[locale] : regions_en;
export const _t_region = (region_de)=>{
    if(locale==="de"){
        return region_de;
    }
    if(locale==="en"){
        return Region_Map_DeEng[region_de];
    }
    return Region_Map_DeEng[region_de];
}
