import {EDataType} from "../models/EDataType";
import {EParameters} from "../models/EParameters";
import {IParameterConfig} from "../models/IParameterConfig";
import {EContentType} from "./EContentType";
import {EPackageTypes} from "./EPackageTypes";
import {NopeActions} from "./NopeActions";
import {_t} from "../tools/Translator";
import {ETranslation} from "./ETranslation";
import {Globals} from "./Globals";


const map: {[k: string]: IParameterConfig} = {};
// const map = {};
export class ParameterConfigs {
    public static  configs = map;
    public static label(parameter: EParameters){
        const p = ParameterConfigs.configs[parameter];
        return p ? p.name : Globals.hyphen;
    }
}
map[EParameters.ProjectParameter] = {
    name: "Aktuelles Projekt",
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};
map[EParameters.SectorCountriesParameter] = {
    name: _t(ETranslation.sector_filter),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
};

map[EParameters.BondFilterParameter] = {
    name: "Anleihefilter",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.credit_spreads,
    isPro: false,
    needLoading: false,
    parameterComposition: [
        EParameters.FixedCurrencyParameter,
        EParameters.SeniorityParameter,
        EParameters.PledgeStatusParameter,
        EParameters.BondEnhancementParameter,
        EParameters.BondCallableParameter
    ],
};
map[EParameters.CreditSpreadConfigParameter] = {
    name: "Long- & Shortlist",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.credit_spreads,
    isPro: false,
    needLoading: false,
    parameterComposition: [
        EParameters.CreditSpreadAnalysisIntervalParameter,
        EParameters.CreditSpreadXRangeParameter,
    ],
};

map[EParameters.FixedCurrencyParameter] = {
    name: "Währung",
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
};
map[EParameters.CreditSpreadXRangeParameter] = {
    name: "Longlist (# Unternehmen)",
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
};
map[EParameters.CreditSpreadMetricParameter] = {
    name: "Kreditkennzahlen",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.credit_spreads,
    isPro: false,
    needLoading: false,
};
map[EParameters.SeniorityParameter] = {
    name: "Seniorität",
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
};
map[EParameters.PledgeStatusParameter] = {
    name: "Pfandrecht",
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
};

map[EParameters.BondEnhancementParameter] = {
    name: "Garantie o.ä.",
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
};
map[EParameters.BondCallableParameter] = {
    name: "Sondertilgung",
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
};

map[EParameters.TickerParameter] = {
    name: "Ticker",
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
};
map[EParameters.CountryIso2Parameter] = {
    name: "Excl. Land",
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
};
map[EParameters.BaseRateParameter] = {
    name: _t(ETranslation.base_rate),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
    parameterComposition: [EParameters.FixedCountriesParameter, EParameters.RuntimeBaseInterestParameter, EParameters.BaseInterestCurveParameter, EParameters.RoundingRuleParameter, EParameters.GrowthRateCashSurpluseParameter],
};
map[EParameters.CreditSpreadParameter] = {
    name: _t(ETranslation.credit_spread),
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Elite,
    nopeAction: NopeActions.elite,
    isPro: false,
    needLoading: false,
    parameterComposition: [EParameters.RuntimeParameter, EParameters.RatingParameter, EParameters.SectorParameter, EParameters.UseFixedCountriesParameter],
};
map[EParameters.BondPropertiesParameter] = {
    name: "Anleihetyp",
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Elite,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
    parameterComposition: [EParameters.SeniorityParameter, EParameters.PledgeStatusParameter],
};

map[EParameters.CreditSpreadAnalysisIntervalParameter] = {
    name: "Shortlist (% von Longlist)",
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Elite,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
};

map[EParameters.CreditSpreadSoftParameter] = {
    name: "Qualitative Beurteilung",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.credit_spreads,
    isPro: false,
    needLoading: false,
    parameterComposition: [EParameters.CreditSpreadDecisionParameterA, EParameters.CreditSpreadDecisionParameterB, EParameters.CreditSpreadDecisionParameterC],
};

map[EParameters.CreditSpreadDecisionParameterA] = {
    name: "Markt und Wettbewerbsposition",
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
};
map[EParameters.CreditSpreadDecisionParameterB] = {
    name: "Geschäfts- und Unternehmensstrategie",
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
};
map[EParameters.CreditSpreadDecisionParameterC] = {
    name: "Finanzierungspolitik",
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
};
map[EParameters.CreditSpreadDecisionParameterD] = {
    name: "Finanzierungspolitik",
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
};




map[EParameters.ReleveredBetaParameter] = {
    name: _t(ETranslation.beta_factor_and_gearing),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
    parameterComposition: [EParameters.LeverageParameter, EParameters.TaxRateParameter, EParameters.TaxShieldTargetCompanyRiskEffect, EParameters.DebtBetaValueParameter],
};
map[EParameters.MarketAndCountryRiskParameter] = {
    name: _t(ETranslation.mrp_and_crp),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
    parameterComposition: [EParameters.MarketRiskPremiumParameter, EParameters.CountryRiskUsageParameter, EParameters.SizePremium],
};

map[EParameters.CountryRiskPremiumCombiParameter] = {
    name: _t(ETranslation.country_risk_premium),
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
    parameterComposition: [EParameters.CountryRiskUsageParameter],
};

map[EParameters.MarketRiskPremiumParameter] = {
    name: _t(ETranslation.market_risk_premium),
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    dataType: EDataType.percent,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};
map[EParameters.CountryRiskPremiumParameter] = {
    name: _t(ETranslation.country_risk_premium),
    dataType: EDataType.percent,
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};

map[EParameters.CountryRiskUsageParameter] = {
    name: _t(ETranslation.country_risk_premium),
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};
map[EParameters.DebtBetaValueParameter] = {
    name: _t(ETranslation.debt_beta),
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};
map[EParameters.TaxShieldTargetCompanyRiskEffect] = {
    name: _t(ETranslation.tax_shield),
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};
map[EParameters.LeverageParameter] = {
    name: _t(ETranslation.gearing),
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
    dataType: EDataType.percent,
};
map[EParameters.TaxRateParameter] = {
    name: _t(ETranslation.tax_rate),
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
    dataType: EDataType.percent,
};
map[EParameters.FixedCountriesParameter] = {
    name: "Land & Währung",
    contentType: EContentType.Elite,
    minPackage: EPackageTypes.Elite,
    nopeAction: NopeActions.elite,
    isPro: false,
    needLoading: false,
};
map[EParameters.RentabilityParameter] = {
    name: "Rentabilitätskennzahl",
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};
map[EParameters.AdjusmentBetaParameter] = {
    name: _t(ETranslation.beta_levered),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
    dataType: EDataType.select,
};
map[EParameters.BaseCurrencyParameter] = {
    name: "Inflations-Differenzen Basis-FX",
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
};
map[EParameters.CashflowParameter] = {
    name: "Cashflow-Kennzahl",
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};
map[EParameters.IntrestRateCountryParameter] = {
    name: "Währung",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Elite,
    nopeAction: NopeActions.elite,
    isPro: false,
    needLoading: false,
};
map[EParameters.CountriesParameter] = {
    name: _t(ETranslation.countries),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: true,
};
map[EParameters.CountryParameter] = {
    name: "Land im Fokus",
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: true,
};
map[EParameters.GrowthRateCashSurpluseParameter] = {
    name: "Wachstumsrate",
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
    dataType: EDataType.percent,
};
map[EParameters.ImfDataViewParameter] = {
    name: "Makro-Indikator im Fokus",
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
};
map[EParameters.ProfitabilityParameter] = {
    name: "Profitabilitätskennzahl",
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};
map[EParameters.RatingKeyParameter] = {
    name: "Ratingkennzahl",
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};
map[EParameters.RatingParameter] = {
    name: "Rating",
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
};
map[EParameters.RentabilityParameter] = {
    name: "Rentabilitätskennzahl",
    contentType: EContentType.Free,
    minPackage: EPackageTypes.Demo,
    nopeAction: [],
    isPro: false,
    needLoading: false,
};
map[EParameters.RuntimeBaseInterestParameter] = {
    name: _t(ETranslation.duration),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
    dataType: EDataType.select_range,
};
map[EParameters.RuntimeParameter] = {
    name: _t(ETranslation.duration),
    contentType: EContentType.Elite,
    minPackage: EPackageTypes.Elite,
    nopeAction: NopeActions.elite,
    isPro: false,
    needLoading: false,
};
map[EParameters.CreditSpreadRuntimeParameter] = {
    name: _t(ETranslation.duration),
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.credit_spreads,
    isPro: false,
    needLoading: false,
    dataType: EDataType.select_range,
};

map[EParameters.BondSuperSectorParameter] = {
    name: "Super-Sektor",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.credit_spreads,
    isPro: false,
    needLoading: false,
};
map[EParameters.BondSectorParameter] = {
    name: _t(ETranslation.sector),
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.credit_spreads,
    isPro: false,
    needLoading: false,
};
map[EParameters.SectorParameterV2] ={
    ...map[EParameters.BondSectorParameter],
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.elite,
};

map[EParameters.RegionParameter] = {
    name: "Region",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.credit_spreads,
    isPro: false,
    needLoading: false,
};
map[EParameters.RegionParameterV2] = {
    name: "Region",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.elite,
    isPro: false,
    needLoading: false,
};

map[EParameters.SectorParameter] = {
    name: _t(ETranslation.sector),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
};
map[EParameters.SicParameter] = {
    name: "SIC-Code",
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
};
map[EParameters.TaxshieldParameter] = {
    name: _t(ETranslation.tax_shield),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
    dataType: EDataType.select,
};
map[EParameters.CompaniesParameter] = {
    needLoading: true,
    isPro: false,
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    name: "Peer-Group",
};
map[EParameters.DateParameter] = {
    name: _t(ETranslation.valuation_date),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: true,
};
map[EParameters.BalanceSheetDateParameter] = {
    name: _t(ETranslation.financial_year),
    contentType: EContentType.Admin,
    minPackage: EPackageTypes.Elite,
    nopeAction: NopeActions.contact,
    isPro: false,
    needLoading: false,
};
map[EParameters.DebtBetaCombiParameter] = {
    name: _t(ETranslation.debt_beta),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
    parameterComposition: [EParameters.DebtbetaParameter, EParameters.CreditSpreadsParameter, EParameters.MarketRiskPremiumParameter],
};
map[EParameters.GearingCombiParameter] = {
    name: _t(ETranslation.gearing),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
    parameterComposition: [EParameters.GearingPeersParameter, EParameters.MahalanobicDistanceParameter, EParameters.NegativeVerschuldungParameter],
};
map[EParameters.IndexCombiParameter] = {
    name: _t(ETranslation.stock_index),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: false,
    parameterComposition: [EParameters.CompositionTypeParameter, EParameters.IndexTypeParameter],
};
map[EParameters.PrimeSectorParameter] = {
    name: _t(ETranslation.sector),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: true,
};

map[EParameters.TpCountriesParameter] = {
    name: _t(ETranslation.countries),
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.transfer_pricing,
    isPro: false,
    needLoading: true,
};
map[EParameters.TpFreeFloatParameter] = {
    name: "Streubesitz",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.transfer_pricing,
    isPro: false,
};
map[EParameters.TpSicParameter] = {
    name: "SIC-Code",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.transfer_pricing,
    isPro: false,
};
map[EParameters.TpYearsParameter] = {
    name: "Referenzjahr",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.transfer_pricing,
    isPro: false,
};
map[EParameters.TpSalesParameter] = {
    name: "Umsatzgröße",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.transfer_pricing,
    range: [0, 50, 100, 250, 500, 1500, 5000, 10000, 50000, 100000, 300000000],
    isPro: false,
    dataType: EDataType.select_range,
};
map[EParameters.TpNumEmployeesParameter] = {
    name: "# Mitarbeiter",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.transfer_pricing,
    range: [0, 50, 250, 1000, 10000, 100000, 500000, 1000000000],
    // 33911000
    isPro: false,
    dataType: EDataType.select_range,
};
map[EParameters.TpMarginComposition] = {
    name: "Operative Aufwendungen",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.transfer_pricing,
    isPro: false,
};
map[EParameters.TpOpResult] = {
    name: "Operatives Ergebnis",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.transfer_pricing,
    isPro: false,
};
map[EParameters.TpAnalysisSizeParameter] = {
    name: "Analysegröße",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.transfer_pricing,
    isPro: false,
    parameterComposition: [EParameters.TpMarginComposition, EParameters.TpOpResult],
};
map[EParameters.TpKeyWordsParameter] = {
    name: "Suchbegriffe",
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.Deu,
    nopeAction: NopeActions.transfer_pricing,
    isPro: false,
    parameterComposition: [EParameters.TpKeyWordsWordsParameter],
};
map[EParameters.TpKeyWordsWordsParameter] = {
    name: "Suchbegriffe",
};
map[EParameters.TpKeyWordsOperParameter] = {
    name: "Verknüpfung",
};
map[EParameters.RenditeCombiParameter] = {
    name: _t(ETranslation.return),
    contentType: EContentType.Freemium,
    minPackage: EPackageTypes.Dax30,
    nopeAction: NopeActions.register,
    isPro: false,
    needLoading: true,
    parameterComposition: [EParameters.PeriodParameter, EParameters.FrequencyParameter, EParameters.WeekDayParameter, EParameters.ReturnTypeParameter, EParameters.AdjusmentBetaParameter],
};
map[EParameters.PeriodParameter] = {
    name: _t(ETranslation.observation_period),
};
map[EParameters.FrequencyParameter] = {
    name: _t(ETranslation.return_period),
};
map[EParameters.ReturnTypeParameter] = {
    name: _t(ETranslation.return_type),
};
map[EParameters.CompositionTypeParameter] = {
    name: _t(ETranslation.index_composition),
};
map[EParameters.IndexTypeParameter] = {
    name: _t(ETranslation.stock_market_and_currency),
};
map[EParameters.MahalanobicDistanceParameter] = {
    name: _t(ETranslation.time_period_point),
};
map[EParameters.NegativeVerschuldungParameter] = {
    name: _t(ETranslation.negative_net_debt),
};
map[EParameters.GearingPeersParameter] = {
    name: _t(ETranslation.gearing_definition),
};
map[EParameters.CreditSpreadsParameter] = {
    name: _t(ETranslation.debt_beta_credit_spread),
};
map[EParameters.DebtbetaParameter] = {
    name: _t(ETranslation.debt_beta_application),
};

map[EParameters.BaseInterestCurveParameter] = {
    name: _t(ETranslation.base_rate_term_structure),
};
map[EParameters.RoundingRuleParameter] = {
    name: _t(ETranslation.rounding),
};

map[EParameters.BetaFilterParameter] = {
    name: _t(ETranslation.beta_factor_filter),
    contentType: EContentType.Premium,
    minPackage: EPackageTypes.World,
    nopeAction: NopeActions.elite,
    isPro: false,
    needLoading: false,
    parameterComposition: [
        EParameters.BetaFilterDataPoints,
        EParameters.BetaFilterBidAsk,
        EParameters.BetaFilterFreeFloat,
        EParameters.BetaFilterR2,
        EParameters.BetaFilterTTest,
        EParameters.BetaFilterBreuschPaganTest,
        EParameters.BetaFilterDurbinWatsonTest,
    ],
};
map[EParameters.BetaFilterBidAsk] = {
    name: _t(ETranslation.bid_ask_spread),
};
map[EParameters.BetaFilterFreeFloat] = {
    name: _t(ETranslation.free_float),
};
map[EParameters.BetaFilterR2] = {
    name: _t(ETranslation.r_square),
};
map[EParameters.BetaFilterDataPoints] = {
    name: _t(ETranslation.available_data_points),
};
map[EParameters.BetaFilterTTest] = {
    name: "T-Test Beta-Faktor",
};
map[EParameters.BetaFilterBreuschPaganTest] = {
    name: _t(ETranslation.breusch_pagan_test),
};
map[EParameters.BetaFilterDurbinWatsonTest] = {
    name: _t(ETranslation.durbin_watson_test),
};
