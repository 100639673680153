import {HTMLTable} from "@blueprintjs/core";
import {Popover2} from "@blueprintjs/popover2";
import * as React from "react";
import {CSSProperties} from "react";
import {SzNumericInput} from "../hooks/SzNumericInput";

const KEY_CODES = {
    Space: "Space",
    ArrowRight: "ArrowRight",
    ArrowLeft: "ArrowLeft",
    ArrowUp: "ArrowUp",
    ArrowDown: "ArrowDown",
    Tab: "Tab",
    Enter: "Enter",
    Escape: "Escape",
    Delete: "Delete",
    Backspace: "Backspace",
};
const colNumber = (letters)=>{
    letters = letters.replaceAll(/\d/g,"");
    const chrs = " ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const mode = chrs.length - 1;
    let number = 0;
    for(const p of letters){
        number = number * mode + chrs.indexOf(p);
    }
    return number;
};
const colName = (n: number) => {
    const ordA = 'A'.charCodeAt(0);
    const ordZ = 'Z'.charCodeAt(0);
    const len = ordZ - ordA + 1;

    let s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s;
};
export interface IDataChange {
    col_num: number;
    row_num: number;
    value: any;
    isError?: string;
    errorMessage?: string;
}
interface ISzTableGridState {
    selection: string;
    can_edit: boolean;
}

export interface ISzTableGridHeader{
    content: any;
    row_span?: number;
    col_span?: number;
    style?: CSSProperties;
    class_name?: string;
}
export interface ISzTableGridHeaderRow{
    style?: CSSProperties;
    class_name?: string;
    cells: ISzTableGridHeader[];
}
export interface ISzTableGridCell{
    is_empty?: boolean;
    row_span?: number;
    col_span?: number;
    style?: CSSProperties;
    class_name?: string;
    is_editable?: boolean;
    is_selectable?: boolean;
    context_id?:string;
    formatter?(v);
}
export type SzTableGridCellMap = { [index:string] : ISzTableGridCell};
export type SzTableGridContextMap = { [index:string] : any};
export interface ISzTableGridProps {
    style?: CSSProperties;
    header?: ISzTableGridHeaderRow[];
    header_1?: ISzTableGridHeaderRow[];
    cell_map: SzTableGridCellMap;
    data: any[][];
    onCellUpdated?(context_id: string, new_value: any, col_num: number, row_num: number): IDataChange[];
}



export class SzTableGrid extends React.Component<ISzTableGridProps,ISzTableGridState>{
    private readonly table: React.RefObject<HTMLTableElement>;
    private table_index = 1;
    private errors = [];
    constructor(props: ISzTableGridProps, context: ISzTableGridState) {
        super(props, context);
        this.table_index ++;
        this.table = React.createRef();
        this.state = {
            selection: undefined,
            can_edit: false,
        };
    }
    public static colName(n: number){
        const ordA = 'A'.charCodeAt(0);
        const ordZ = 'Z'.charCodeAt(0);
        const len = ordZ - ordA + 1;

        let s = "";
        while(n >= 0) {
            s = String.fromCharCode(n % len + ordA) + s;
            n = Math.floor(n / len) - 1;
        }
        return s;
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<ISzTableGridState>, snapshot?: any) {
        if(this.state.selection && !this.state.can_edit){
            const td: HTMLTableCellElement = document.querySelector(`#${this.state.selection}`);
            td?.focus();
        }
    }

    public render(){
        // console.error(this.state.selection, this.state.can_edit);
        return(
            <HTMLTable className={"sz-table-grid sz-table sz-cell-overflow"} elementRef={this.table} tabIndex={this.table_index} condensed={true} style={this.props.style} >
                {this.renderHeader()}
                <tbody>
                {this.props.data.map((row_data, row_num)=> this.renderRowData(row_data, row_num))}
                </tbody>
            </HTMLTable>
        );
    }
    private renderHeader(){
        if(!Array.isArray(this.props.header)){
            return null;
        }
        if(!this.props.header.length){
            return null;
        }
        const renderCell = (th: ISzTableGridHeader)=> {
            return (
                <th style={th.style} className={th.class_name} colSpan={th.col_span} rowSpan={th.row_span}>
                    {th.content}
                </th>
            );
        };
        const renderRow = (tr: ISzTableGridHeaderRow)=> {
            return (
                <tr style={tr.style} className={tr.class_name}>
                    {tr.cells.map((c)=> renderCell(c))}
                </tr>
            );
        };
        return (
            <thead>
            {this.props.header.map((h)=> renderRow(h))}
            {this.props.header_1?.map((h)=> renderRow(h))}
            </thead>
        );
    }
    private getSelectedCell(){
        let row = 0;
        let col = 0;
        // let can_edit = this.state.can_edit;
        if(this.state.selection){
            row = parseInt(this.state.selection.replace(/\D/g,""), 10);
            col = colNumber(this.state.selection) - 1;
        }
        return [col, row];
    }
    private getSelectNext(key_code: string, row_num: number, col_num: number){
        let editable = false;
        let old_row = row_num;
        let old_col = col_num;
        while(!editable){
            if(key_code === KEY_CODES.Delete){
                break;
            }
            if(key_code === KEY_CODES.Backspace){
                break;
            }
            if(key_code === KEY_CODES.ArrowUp && row_num>0){
                row_num --;
            }
            if((key_code === KEY_CODES.ArrowDown || key_code === KEY_CODES.Enter) && row_num + 1 < this.props.data.length){
                row_num ++;
            }
            if(key_code === KEY_CODES.ArrowLeft && col_num > 1){
                col_num --;
            }
            if((key_code === KEY_CODES.ArrowRight || key_code === KEY_CODES.Tab) && Array.isArray(this.props.data[row_num]) && col_num + 1 < this.props.data[row_num].length){
                col_num ++;
            }

            if(old_row !== row_num || old_col !== col_num){
                const cell_adr = `${colName(col_num)}${row_num}`;
                const cell_definition: ISzTableGridCell = this.props.cell_map[cell_adr];
                editable = cell_definition?.is_editable; //
                if(cell_definition?.is_editable){
                    break;
                }
            }else{
                break;
            }

            old_row = row_num;
            old_col = col_num;
        }

        return [col_num, row_num];
    }
    private renderRowData(row_data: (string | number)[], row_num: number) {
        if(row_data[0] && row_data[0].toString().startsWith("#")){
            return null;
        }
        return (
            <tr id={`row_${row_num}`}>
                {row_data.map( (cell, col_num) => this.renderCell(cell, row_num, col_num) )}
            </tr>
        );
    }
    private getCellStyle(use_editor: boolean, overrideStyle: CSSProperties): CSSProperties{
        const def: CSSProperties = {
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: use_editor ? 0 : 6,
            paddingBottom: use_editor ? 0 : 6,
        };
        return Object.assign(def, overrideStyle);
    }
    private renderCell(cell_content: string | number, row_num: number, col_num: number) {
        const cell_adr = `${colName(col_num)}${row_num}`;
        const cell_definition: ISzTableGridCell = this.props.cell_map[cell_adr];

        const editable = cell_definition?.is_editable;
        const use_editor = this.state.can_edit && cell_adr===this.state.selection;
        const cellStyle: CSSProperties = this.getCellStyle(editable && cell_adr===this.state.selection, cell_definition?.style);
        cellStyle.outline= "none";

        if(cell_definition.is_empty){
            cellStyle.height= 0;
        }

        const boxShadow = [];

        if(cell_adr === this.state.selection){
            boxShadow.push("inset 0px 0px 0px 2px #4444AF");
        }
        if(editable && cell_adr !== this.state.selection){
            boxShadow.push("inset 0 0 3px #adadad");
        }
        if(boxShadow.length){
            cellStyle.boxShadow = boxShadow.join(", ");
        }
        this.table_index ++;
        const renderContent = ()=>{
            const er = this.errors[cell_adr];
            if(!use_editor && er === "#Wert"){
                const div_css: CSSProperties = {
                    backgroundColor: "#ffffff",
                    padding: 10,
                };
                const textToDisplay = (
                    <div style={div_css}>
                        <div>Vorzeichenfehler</div>
                    </div>
                );
                return (
                    <Popover2 content={textToDisplay} fill={true} interactionKind={"hover"} usePortal={true} hoverOpenDelay={200}>
                        <div className={"sz-grid-error-value bp3-fill"}>#WERT</div>
                    </Popover2>
                );
            }

            let c: any = cell_content;
            if(editable && cell_adr===this.state.selection){
                c = this.renderCellEditor(cell_content, row_num, col_num);
                const div_style: CSSProperties = {
                    display: "flex",
                };
                return (
                    <div className={"td-wrapper"} style={{height: 24, top: 2}}>
                        <div className={"td-content"} style={div_style}>
                            {c}
                        </div>
                    </div>
                );
            }
            return cell_definition?.formatter ? cell_definition?.formatter(c) : c;
        };
        return (
            <td
                id={cell_adr}
                rowSpan={cell_definition?.row_span}
                colSpan={cell_definition?.col_span}
                className={cell_definition?.class_name}
                tabIndex={this.table_index}
                onClick={(e)=> this.onCellClick(e, cell_content, row_num, col_num)}
                onKeyDown={(e)=>this.onKeyDown(e, cell_content, row_num, col_num)}
                style={cellStyle} >{renderContent()}</td>
        );
    }

    private onCellClick(e: React.MouseEvent<HTMLTableDataCellElement>, cell: string | number, row_num: number, col_num: number) {
        let selection = `${colName(col_num)}${row_num}`;
        const cell_definition: ISzTableGridCell = this.props.cell_map[selection];
        const can_select = cell_definition?.is_selectable;

        if(selection !== this.state.selection){
            // this.setValue()
        }

        if(!can_select){
            selection = undefined; // auswahl zurücksetzen
        }
        // this.table.current.rows.namedItem()
        this.setState({
            selection,
            can_edit: can_select && cell_definition?.is_editable && selection === this.state.selection,
        });
    }

    private onKeyDown(e: React.KeyboardEvent<HTMLTableDataCellElement>, cell: string | number, row_num: number, col_num: number) {
        if(e.key === KEY_CODES.ArrowDown || e.key === KEY_CODES.ArrowUp || e.key === KEY_CODES.Space){
            e.preventDefault();
        }
        e.stopPropagation();
        // console.error(e);
        const [col, row] = this.getSelectedCell();
        const [new_col, new_row] = this.getSelectNext(e.key, row, col);
        const cell_adr = `${colName(new_col)}${new_row}`;
        const cell_definition: ISzTableGridCell = this.props.cell_map[cell_adr];

        const can_select = cell_definition?.is_selectable;
        const selection = can_select ? cell_adr : undefined;

        if(e.key === KEY_CODES.Delete && can_select && cell_definition?.is_editable){
            this.setValue(undefined, KEY_CODES.Delete, row, col);
            return;
        }
        if(e.key === KEY_CODES.Backspace && can_select && cell_definition?.is_editable){
            this.setValue(undefined, KEY_CODES.Backspace, row, col);
            return;
        }
        if(cell_adr === this.state.selection && cell_definition?.is_editable){
            // console.error(e.key);
            // this.setValue(e.key, undefined, row, col);
            return;
        }

        // console.error(e.key, this.state.selection, selection, can_select, can_edit, old_col);
        if(can_select){
            this.setState({
                selection,
                can_edit: false,
            });
        }
    }

    private setValue(new_value, key_code: string, row_num: number, col_num: number){

        if(key_code === KEY_CODES.Escape){
            return this.setState({can_edit: false});
        }

        const cell_adr = `${colName(col_num)}${row_num}`;
        const cell_definition: ISzTableGridCell = this.props.cell_map[cell_adr];
        // if(this.props.validateValue){
        //    const r = this.props.validateValue(cell_definition?.context_id, parseFloat(new_value), col_num, row_num);
        // }


        this.props.data[row_num][col_num] = parseFloat(new_value);

        if(this.props.onCellUpdated){
            const changes = this.props.onCellUpdated(cell_definition?.context_id, parseFloat(new_value), col_num, row_num);
            if(Array.isArray(changes)){
                changes.forEach( (change) =>{
                    this.props.data[change.row_num][change.col_num] = change.value;
                    this.errors[cell_adr] = change.isError;
                });
            }
        }

        const [col, row] = this.getSelectNext(key_code, row_num, col_num);
        const selection = `${colName(col)}${row}`;
        const next_cell: ISzTableGridCell = this.props.cell_map[selection];
        const can_edit = next_cell?.is_editable && key_code === KEY_CODES.Backspace;
        this.setState({
            selection,
            can_edit,
        });
    }
    private renderCellEditor(cell: any, row_num: number, col_num: number) {
        const _value = (v)=> {
            const r = parseFloat(cell);
            if(isNaN(r)){
                return undefined;
            }
            return r;
        }
        return (
            <SzNumericInput current_value={_value(cell)} onValueChanged={(new_value, key)=>this.setValue(new_value, key, row_num, col_num)}/>
        );
    }
}
