import * as React from "react";
import {CSSProperties, useState} from "react";
import {Tooltip2} from "@blueprintjs/popover2";
import {Button} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {EMIT_PROJECT_CHANGE_MODULE_TAB, useProject} from "../hooks/SzProjectHooks";
import {Link, useNavigate} from "react-router-dom";
import {ProjectModuleGroups, ProjectTypeModuleMap} from "../../models/IProject";
import {IModuleConfig} from "../../models/IModuleConfig";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";
import {_k} from "../../helpers/Helpers";

export function MainBarTitle() {
    const navigate = useNavigate();
    const currentProject = useProject();
    const modules = currentProject ? ProjectTypeModuleMap[currentProject.project_type] : [];
    const [activeModule, setActiveModule] = useState<number>(0);

    if(!currentProject){
        document.title = "smartZebra Arbeitsplatz";
        return (
            <Link to={"https://www.smart-zebra.de/"}
                  target="_blank"
                  rel="noreferrer">
                <img src={"images/zebra.svg"} height={20}/>
            </Link>
        );
    }
    const style: CSSProperties={
        backgroundColor: "#e0efff80",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minWidth: 250,
        borderRadius: 5,
    };
    document.title = `smartZebra Projekt - ${currentProject.name}`;

    const setModuleTab = (idx: number)=>{
        if(activeModule === idx){
            return;
        }
        EMIT_PROJECT_CHANGE_MODULE_TAB(idx);
        setActiveModule(idx);
    };
    const renderModuleTab = (module: any, idx: number)=>{
        if(!module){
            return null;
        }
        let caption: string;
        const config = module.config as IModuleConfig;
        if(!config){
            console.error(module);
            caption = "!ERROR";
        }else{
            caption=config.name
        }
        return (
            <div key={_k("MainBarTitle", idx)} className={idx === activeModule ? "module-tab-button active": "module-tab-button"} onClick={()=>setModuleTab(idx)}>
                {
                    caption === "Kapitalkosten" ? _t(ETranslation.cost_of_capital) :
                        caption=== "Beta-Faktoren" ? _t(ETranslation.beta_factor) :
                            caption === "Multiplikatoren" ? _t(ETranslation.multiples):
                                caption === "Branchendaten" ? _t(ETranslation.sektor_data): caption
                }
            </div>
        );
    };
    const renderModuleTabs = ()=>{
        if(Array.isArray(modules)){
            return modules.map((module, idx)=> renderModuleTab(ProjectModuleGroups[module], idx));
        }
        return null;
    };
    return (
        <>
            <div style={{height: "100%", color: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={style}>
                    <span style={{marginLeft: 5, marginRight: 5}}>{currentProject.name}</span>
                    <Tooltip2 content={_t(ETranslation.close_project)} usePortal={true} openOnTargetFocus={false}>
                        <Button icon={IconNames.CROSS} minimal={true} onClick={()=> navigate("/projects")} />
                    </Tooltip2>
                </div>
            </div>
            <div style={{display: "flex", height: "100%", paddingTop: 8}}>
                {renderModuleTabs()}
            </div>
        </>
    );
}
