import {Navbar, NavbarGroup, Tab, Tabs} from "@blueprintjs/core";
import * as React from "react";
import {RefObject} from "react";
import {ModuleGroup} from "../modules";
import {ToolBarButton} from "./toolbar/ToolBarButton";

export interface ISectionBar {
    modules: ModuleGroup[];
    handleSelectionChanged?(moduleGroup: ModuleGroup, index: number): any;
}

export class SectionBar extends React.Component<ISectionBar, any> {

    private buttons: RefObject<ToolBarButton>[] = [];

    constructor(props, context) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
    }

    public render() {
        this.buttons = [];
        return (
            <Navbar fixedToTop={true}>
                <NavbarGroup>
                    <Tabs large={true}>
                        {this.props.modules.map((item, idx) => {
                            return  <Tab className="bp3-minimal" title={item.name} onClickCapture={() => {
                                this.handleClick(item, idx);
                            }} id={`sec-tab-${idx}`} />;
                        })}
                    </Tabs>
                </NavbarGroup>
            </Navbar>
        );
    }

    public componentDidMount() {
        if (this.buttons && this.buttons.length > 0) {
            this.buttons[0].current.setSelected(true);
        }
        if (this.props.modules && this.props.modules.length > 0) {
            this.props.handleSelectionChanged(this.props.modules[0], 0);
        }
    }

    private handleClick(selectedItem: ModuleGroup, index: number) {
        if (this.props.modules[index] && this.props.handleSelectionChanged) {
            this.props.handleSelectionChanged(this.props.modules[index], index);
        }
    }
}
