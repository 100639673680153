import * as React from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, Title, Tooltip,} from 'chart.js';

import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {Line} from "react-chartjs-2";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {DrawStateHook} from "../../../chart-plugin/DrawStateHook";
import * as ChartJsAnnotation from "chartjs-plugin-annotation";

import {CustomAxisLabel} from "../../../chart-plugin/CustomAxisLabel";
import {fromDateDecimal, fromStr} from "../../../tools/DateTools";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IRegression} from "../../../models/chef/RawBeta";
import {mkQuarterlyAnnotation} from "../../../helpers/Helpers";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
    ChartJsAnnotation,
    CustomAxisLabel,
    Filler,
);

export interface IRawBetaTimeSeriesProps {
    label: string;
    company: ICompanyBase;
    data: any;
    raw_betas_ext: any[];
}

export function RawBetaTimeSeries(props: IRawBetaTimeSeriesProps) {
    const raw_betas_ext = props.raw_betas_ext;
    const period = SessionStore.get(EParameters.PeriodsParameter);
    const company: ICompanyBase = props.company;
    const dates_decimal = Object.keys(raw_betas_ext).map((d)=> fromStr(d).asDateDecimal()).sort();
    const dates = dates_decimal.map((d)=> fromDateDecimal(d).toFormat("dd.LL.yyyy"));

    // const labels =  dates.map((d, i)=> i%2 === 0 ? fromStr(d).toFormat("LL/yyyy") : null);
    const data_0 = [];
    const data_1 = [];
    const data_2 = [];
    dates.forEach((date, idx)=>{
        const company_data = raw_betas_ext[date][company.id];
        if(company_data && company_data.periods && company_data.periods[period]){
            const r: IRegression = company_data.periods[period];
            data_2[idx] = {
                x: idx,
                y: r.Slope
            };
            data_0[idx] = {
                x: idx,
                y: r.Slope - r.StandardErrorSlope,
            };
            data_1[idx] = {
                x: idx,
                y: r.Slope + r.StandardErrorSlope,
            };
        }else{
            data_2[idx] = { x: idx, y: undefined};
            data_1[idx] = { x: idx, y: undefined};
            data_0[idx] = { x: idx, y: undefined};
        }
    });
    const o = new SzChartOptions();
    const {annotation,labels} = mkQuarterlyAnnotation(dates_decimal);
    o.enablePlugin(EChartPlugins.ANNOTATION, annotation);
    // o.setXTickCallback(()=> "");
    o.setCustomAxisLabel("", props.label);
    const options = {
        ...o.options,
    };
    options.animations = false;
    options.scales.x.gridLines= {
        drawTicks: false,
        display: false,
    };
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
    };
    const gfx = {
        label: props.label,
        borderColor: "rgba(19,124,189,0.75)",
        borderWidth: 2,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        data: data_2,
    };
    const gfx_0 = {
        borderColor: "rgba(0, 0, 0,0.15)",
        borderWidth: 0,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        data: data_0,
        fill: "+1"
    };
    const gfx_1 = {
        borderColor: "rgba(0, 0, 0,0.15)",
        borderWidth: 0,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        data: data_1,
    };
    const chart_data = {
        labels,
        datasets: [
            gfx_0,
            gfx_1,
            gfx,
        ],
    };
    return (
        <Line data={chart_data as any} options={options} />
    );
}
