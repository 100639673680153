import {_t} from "../tools/Translator";
import {ETranslation} from "./ETranslation";

export class ImfDataView {
    public static values = [
        {label: "Haushaltsdefizit", value: "General government net lending/borrowing"},
        {label: "Staatsverschuldung", value: "General government gross debt"},
        {label: "Arbeitslosenquote", value: "Unemployment rate"},
        {label: _t(ETranslation.gdp_growth), value: "Real GDP growth"},
        {label: _t(ETranslation.inflation), value: "inflation"},
    ];
}
