import {NonIdealState} from "@blueprintjs/core";

import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import * as moment from "moment/moment";
import * as React from "react";
import {Bar} from 'react-chartjs-2';
import {HighlightValue} from "../../chart-plugin/HighlightValue";
import {CreditSpreadOptions} from "../../const/CreditSpreadOptions";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {ICalcCreditSpreadResult} from "../../helpers/CalcCreditSpreadIndication";
import {median, quantile, sum} from "../../helpers/Statistics";
import {EParameters} from "../../models/EParameters";
import {IBondDetails} from "../../models/IBondDetails";
import {ISzSvgDataPoint} from "../../models/ISzSvgDataPoint";
import {EChartType, PdfChartToImage} from "../../pdf-tools/PdfChartToImage";
import {PDF_IMAGE_HEIGHT, PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {SzBondChart} from "../widgets/SzBondChart";
import {BaseModule} from "./BaseModule";
import {EValueName} from "../../models/EValueName";
import {_t, _tn} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    HighlightValue,
);

export class CreditSpreadIndicationChart  extends BaseModule<any> {

    private bar_chart_options;
    private bar_chart_data;
    private spread = 0;
    private spread_min = 0;
    private spread_max = 0;
    private readonly spreadRef;
    private count_bonds =0;

    constructor(props: any, context: any) {
        super(props, context);
        this.spreadRef = React.createRef();
    }

    protected renderContent() {
        if(!this.data){
            return (<NonIdealState
                icon={"database"}
                title="Keine Daten"
            />);
        }
        const fx = (v, index)=> CreditSpreadOptions.calcWeighted(v, index);
        const spreads = [];
        const spreads_min = [];
        const spreads_max = [];
        // CreditSpreadOptions
        CreditSpreadOptions.getMetrics().forEach((metric, i)=>{
            if(metric.weight === 0){
                return;
            }
            const indication: ICalcCreditSpreadResult = this.data.results[metric.field];
            if(!indication){
                return;
            }
            if(!Array.isArray(indication.boxed_spreads)){
                return;
            }
            spreads.push(fx(median(indication.boxed_spreads), i));
            spreads_min.push(fx(quantile(indication.boxed_spreads, .25), i));
            spreads_max.push(fx(quantile(indication.boxed_spreads, .75), i));
        });

        this.spread = sum(spreads);
        SessionStore.setGlobalVar(EValueName.cp_credit_spread, this.spread);
        this.spread_min = sum(spreads_min);
        this.spread_max = sum(spreads_max);
        // <div className={"ribbon top left"}>Beta Version</div>
        return (
            <div style={{overflow: "hidden", position: "relative"}}>
                <div className={"sz-row"}>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <strong>Kreditaufschlag nach Kreditkennzahl</strong>
                            <br/>
                            <span>[% p.a.]</span>
                        </div>
                        {this.renderBarChart()}
                    </div>
                    {this.renderSpreadChart()}
                </div>
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;
        // console.error(this.data);
        this.setState({
            loading: false,
            selected_company_indication: data.selected_company_indication ? data.selected_company_indication : this.state.selected_company_indication,
        });
    }
    private noData(error_pos: string) {
        return (<NonIdealState
            icon={"database"}
            title={`Keine Daten (${error_pos})`}
            description="Die Kombination aus Stichtag, Währung, Sector und Rating liefert keine Ergebnisse."
        />);
    }
    private getSeriesNew(bond_data: IBondDetails[]) {
        const qualifying_date = moment(SessionStore.get(EParameters.DateParameter), "DD.MM.YYYY");
        const renderData: ISzSvgDataPoint[] = [];

        bond_data.forEach( (i) => {
            if(i.ftid_spread < 0){
                return;
            }
            if(i.ftid_spread > 2000){
                return;
            }
            const mat_date = moment(i.mat_date, "YYYYMMDD");
            const x = mat_date.diff(qualifying_date, "months", true);
            if((x > 30 * 12) || (x < 6)){
                return;
            }
            const y = i.ftid_spread / 100;
            renderData.push({
                x,y, dataSet: i
            });
        } );
        return renderData;
    }
    private getStartStop() {
        const dt = SessionStore.get(EParameters.CreditSpreadRuntimeParameter);
        const range = dt.split(",");
        const s = parseInt(range[0], 10);
        const start = s<12 ? 12 : s;
        const stop = parseInt(range[1], 10);
        return {start, stop};
    }
    private getBarImage(){
        const h = 500;
        const w = 1125;
        const s = PdfChartToImage.getDataUri(EChartType.bar, w, h, this.bar_chart_options, this.bar_chart_data);
        return s;
    }
    private renderBarChart(){
        if(!Array.isArray(CreditSpreadOptions.getMetrics())){
            return;
        }
        const data = [];
        const labels = [];
        const fx = (v)=>  Math.trunc(v * 100)/100;
        CreditSpreadOptions.getMetrics().forEach((metric)=>{
            if(metric.weight === 0){
                return;
            }
            const indication: ICalcCreditSpreadResult = this.data.results[metric.field];
            if(!indication){
                return;
            }
            // labels.push(short_captions[key]);
            labels.push(metric.caption);
            // console.error(captions[key], indication.y_range);
            if(!indication.y_range){
                data.push([undefined, undefined]);
                return;
            }
            if(isNaN(indication.y_range.from) || isNaN(indication.y_range.till)){
                data.push([undefined, undefined]);
                return;
            }
            data.push([fx(indication.y_range.from), fx(indication.y_range.till)]);
        });
        // https://react-chartjs-2.js.org/examples/vertical-bar-chart
        // console.error(sum_useful);
        // console.error(w_spreads);
        // console.error(labels);
        // console.error(data);

        const datalabels = {
            display: 1,
            align: "-30",
            anchor: "end",
            offset: 5,
            backgroundColor: "rgba(255,255,255,0.9)",
            formatter: (value, context, a)=>{
                // console.error(value, context, a);
                if(context.dataIndex===0){
                    return `${_t(ETranslation.credit_spread)}: ${Globals.formatter(value)}`;
                }
                return null;
            },
            labels: {
                title:{
                    color: "#f47a22",
                }
            }
        };
        const chart_data = {
            labels,
            datasets: [
                {
                    label: 'Bereich',
                    data,
                    backgroundColor: Globals.shortlist_green,
                },
                {
                    type: "line",
                    label: _t(ETranslation.credit_spread),
                    data: (new Array(labels.length)).fill(this.spread),
                    backgroundColor: "#f47a22",
                    borderColor: "#f47a22",
                    borderWidth: 1,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    order: -3,
                    datalabels,
                },
                {
                    type: "line",
                    label: `min Spread`,
                    data: (new Array(labels.length)).fill(this.spread_min),
                    borderDash: [7,2,2,2],
                    backgroundColor: "#137cbd",
                    borderColor: "#137cbd",
                    borderWidth: 1,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    order: -1,
                    datalabels: {
                        display: 1,
                        align: "135",
                        anchor: "end",
                        offset: 5,
                        backgroundColor: "rgba(255,255,255,0.9)",
                        formatter: (value, context, a)=>{
                            if(context.dataIndex === labels.length - 1){
                                return `Unteres Quartil: ${Globals.formatter(value, 2, 2, true)}`;
                            }
                            return null;
                        },
                        labels: {
                            title:{
                                color: "#137cbd",
                            }
                        }
                    }
                },
                {
                    type: "line",
                    label: `max Spread`,
                    data: (new Array(labels.length)).fill(this.spread_max),
                    borderDash: [7,2,2,2],
                    backgroundColor: "#137cbd",
                    borderColor: "#137cbd",
                    borderWidth: 1,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    order: -1,
                    datalabels: {
                        display: 1,
                        align: "-135",
                        anchor: "end",
                        offset: 5,
                        backgroundColor: "rgba(255,255,255,0.9)",
                        formatter: (value, context, a)=>{
                            if(context.dataIndex === labels.length - 1){
                                return `Oberes Quartil: ${Globals.formatter(value, 2, 2, true)}`;
                            }
                            return null;
                        },
                        labels: {
                            title:{
                                color: "#137cbd",
                            }
                        }
                    }
                },
            ],
        };
        const company_indication = this.state.selected_company_indication;
        const selected_spread = company_indication ? company_indication.average_spreads : undefined;
        // console.error(selected_spread);
        /*
        if(selected_spread){
            chart_data.datasets.push({
                type: "line",
                label: `Auswahl`,
                borderDash: [7,2,2,2],
                backgroundColor: "#137cbd",
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 0,
                order: -2,
                data: (new Array(labels.length)).fill(selected_spread),
            } as any);
        }
        */
        /*
        const title_text = [
            `Kreditaufschlag ${Globals.formatter(spreads, 2, 2, true)}`,
            `min(${Globals.formatter(spreads_min, 2, 2, true)})`,
            `max(${Globals.formatter(spreads_max, 2, 2, true)})`,
            `Auswahl(${Globals.formatter(selected_spread, 2, 2, true)})`
        ].join(" ");
        */
        const title_text = undefined;
        const options = {
            type: 'bar',
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            indexAxis: 'x',
            scales: {
                y: {
                    ticks: {
                        z: 10,
                        padding: 10,
                        font: {
                            size: 11,
                        },
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: 0,
                    max: 8,
                },
                x: {
                    ticks: {
                        z: 10,
                        padding: 10,
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                        lineWidth: 1,
                    },
                },
                min: 0,
                max: data.length - 1,
            },
            plugins: {
                title: {
                    display: false,
                    text: title_text,
                },
                legend: {
                    display: false,
                },
                datalabels: {
                    display: 0,
                },
                tooltip: {
                    enabled: true,
                    mode: "point",
                },
            },
            custom_axis_label: {
                x_label: "Kennzahl",
                y_label: "[% p.a.]",
            }
        };
        this.bar_chart_data = chart_data;
        this.bar_chart_options = options;
        return (
            <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                <Bar options={options as any} data={chart_data as any} height={"100%"} width={"100%"} />
            </div>
        );
    }
    private renderSpreadChart() {
        const boxed_stat = this.data.all_boxed_spreads;
        if(!Array.isArray(boxed_stat)){
            return this.noData("N3");
        }
        if(!boxed_stat.length){
            return this.noData("N4");
        }

        const stat_map = {};
        boxed_stat.forEach((i, num)=>{
            if(!i.count){
                return;
            }
            i.index = num;
            stat_map[i.id] = i;
        });

        const bond_details: IBondDetails[] = this.data.bond_details;
        const all_bonds: IBondDetails[] = [];
        const all_bonds_map = {};
        bond_details.forEach((i)=>{
            if(!stat_map[i.co_name]){
                return;
            }
            if(!all_bonds_map[i.p_symbol_isin]){
                stat_map[i.co_name].bonds.push(i);
                all_bonds.push(i);
                i.company_index = stat_map[i.co_name].index;
                all_bonds_map[i.p_symbol_isin] = i;
            }
        });
        const data = this.getSeriesNew(all_bonds);
        // const spread = median(data.map((i)=> i.y));
        // console.error(spread);
        // console.error(boxed_stat, all_bonds, data);
        const chart_data = {
            datasets: [
                {
                    label: _t(ETranslation.bonds),
                    data,
                    backgroundColor: Globals.shortlist_green,
                    datalabels: {
                        labels: {
                            title: null
                        }
                    }
                },
            ]
        };
        const {start, stop} = this.getStartStop();
        const spread = this.spread;
        if(spread >=0 && start && stop){
            chart_data.datasets.push({
                order: -1,
                type: start === stop ? "bubble" : "line",
                label: _t(ETranslation.credit_spread),
                data: start === stop ? [{x: start, y: spread, isSpreadValue: 1}] : [{x: start, y: spread, isSpreadValue: 1}, {x: stop, y: spread, isSpreadValue: 2}],
                backgroundColor: "#f47a22",
                borderColor: "#f47a22",
                borderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 3,
                datalabels: {
                    align:  (value, context, a)=>{
                        if(start>= 240){
                            return "left";
                        }
                        return "-30";
                    },
                    anchor: (value, context, a)=>{
                        if(start>= 240){
                            return "center";
                        }
                        return "end";
                    },
                    offset: (value, context, a)=>{
                        if(start>= 240){
                            return 5;
                        }
                        return 7;
                    },
                    backgroundColor: "rgba(255,255,255,0.9)",
                    formatter: (value, context, a)=>{
                        if(value.isSpreadValue===1){
                            return `${_t(ETranslation.credit_spread)}: ${Globals.formatter(value.y)}`;
                        }
                        return null;
                    },
                    labels: {
                        title:{
                            color: "#f47a22",
                        }
                    }
                }
            } as any);
        }
        if(this.spread_min>=0){
            chart_data.datasets.push({
                type: "line",
                label: `min Spread`,
                data: [{x: 12, y: this.spread_min, isSpreadValue: 2}, {x: 360, y: this.spread_min, isSpreadValue: 3}],
                borderDash: [7,2,2,2],
                backgroundColor: "#137cbd",
                borderColor: "#137cbd",
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 0,
                order: -1,
                datalabels: {
                    display: 1,
                    align: "135",
                    anchor: "end",
                    offset: 5,
                    backgroundColor: "rgba(255,255,255,0.9)",
                    formatter: (value, context, a)=>{
                        if(value.isSpreadValue===3){
                            return `Unteres Quartil: ${Globals.formatter(value.y)}`;
                        }
                        return null;
                    },
                    labels: {
                        title:{
                            color: "#137cbd",
                        }
                    }
                }
            } as any);
        }
        if(this.spread_max>=0){
            chart_data.datasets.push({
                type: "line",
                label: `max Spread`,
                data: [{x: 12, y: this.spread_max, isSpreadValue: 2}, {x: 360, y: this.spread_max, isSpreadValue: 3}],
                borderDash: [7,2,2,2],
                backgroundColor: "#137cbd",
                borderColor: "#137cbd",
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 0,
                order: -1,
                datalabels: {
                    display: 1,
                    align: "-135",
                    anchor: "end",
                    offset: 5,
                    backgroundColor: "rgba(255,255,255,0.9)",
                    formatter: (value, context, a)=>{
                        if(value.isSpreadValue===3){
                            return `Oberes Quartil: ${Globals.formatter(value.y)}`;
                        }
                        return null;
                    },
                    labels: {
                        title:{
                            color: "#137cbd",
                        }
                    }
                }
            } as any);
        }
        const get_tooltip_label = (item)=>{
            if(item.raw && item.raw.dataSet){
                const details:IBondDetails = item.raw.dataSet;
                return `${details.p_symbol_isin} | ${details.fi_name_iss}`;
            }
            if(item.raw && item.raw.isSpreadValue){
                return `${_t(ETranslation.credit_spread)}: ${Globals.formatter(item.raw.y)}`;
            }

            console.error(item);
            return item;
        };
        this.count_bonds = data.length;
        // console.error(spread_value);
        // https://chartjs-plugin-datalabels.netlify.app/guide/positioning.html#clamping
        return (
            <div className={"sz-col sz-col-50"}>
                <div style={{marginBottom: 20, fontSize: "80%"}}>
                    <strong>Kreditaufschlag nach Laufzeit (#Anleihen {Globals.formatter(data.length, 0, 0)})</strong>
                    <br/>
                    <span>[% p.a.]</span>
                </div>
                <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                    <SzBondChart chart_data={chart_data} get_tooltip_label={get_tooltip_label} ref={this.spreadRef} />
                </div>
            </div>
        );
    }

    public getPdfPage(): any[]{
        const bar_image = this.getBarImage();
        const spread_image = this.spreadRef?.current?.getImage();
        const content =[];
        PdfInsertElement.page_header(content, _t(ETranslation.credit_spread));

        PdfInsertElement.h2(content, "Kreditaufschlag nach Kreditkennzahl");
        PdfInsertElement.p(content, "[% p.a.]");
        PdfInsertElement.image(content, bar_image, PDF_IMAGE_HEIGHT);

        const h=PdfInsertElement.h2(content, `Kreditaufschlag nach Laufzeit (#Anleihen ${Globals.formatter(this.count_bonds, 0, 0)})`);
        h.pageBreak= "before";
        PdfInsertElement.p(content, "[% p.a.]");
        PdfInsertElement.image(content, spread_image, PDF_IMAGE_HEIGHT);

        return content;
    }
}
