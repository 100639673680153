import {Button, Icon, InputGroupProps, Intent, Menu, MenuDivider, MenuItem, Popover, Spinner,} from "@blueprintjs/core";
import {Omnibar} from "@blueprintjs/select";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {ICountry} from "../../../models/ICountry";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {PublicDbCalls} from "../../../services/PublicDbCalls";
import {ToolBarParameter} from "../ToolBarParameter";

const CountryOmnibar = Omnibar.ofType<ICountry>();

export class CountriesParameter extends ToolBarParameter<IToolBarParameter> {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CountriesParameter];
    }
    protected async initialize() {
        const db = new PublicDbCalls();
        if (!Globals.countries.length) {
            Globals.countries = (await db.getAllCountries()).result;
        }
        const options = (await this.getOptions()) as IOptionEntry[];
        const all = options.reduce( (accumulator, currentValue) => currentValue.selected && accumulator, true );
        return {
            all,
            options,
            isOpen: false,
            showSearch: false,
        };
    }

    protected renderContent() {
        const INPUT_PROPS: InputGroupProps = { placeholder: "Suche ..." };
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        return (
            <div>
                <CountryOmnibar
                    className={"sz-search-country"}
                    inputProps={INPUT_PROPS}
                    itemPredicate={this.filterCountry}
                    isOpen={this.state.showSearch}
                    items={Globals.countries}
                    itemRenderer={this.renderCountry}
                    onClose={() => this.setState({showSearch: false})}
                    onItemSelect={(item) => this.selectItem(item)} />

                <Popover
                    minimal={true}
                    position={"bottom-left"}
                    modifiers={popper}
                >
                    <Button intent={Intent.NONE} minimal={true} className={"sz-toolbar-button"}>
                        <span className={"sz-property-name"}>{this.config.name}</span>
                        <div className={"sz-property-value"}>{this.getLabel()}</div>
                    </Button>
                    <div style={{minWidth: 200}}>
                        {this.state.isLoading ? this.renderLoading() : this.renderEntries()}
                    </div>
                </Popover>
            </div>
        );
    }
    protected getLabel(): string {
        const options = this.state.options;
        const idx = options.findIndex((o) => "771" === o.value);
        if (idx >= 0 && options.length === 1) {
            return "Default";
        } else {
            return "Longlist";
        }
        // return String.fromCharCode(parseInt("200B",16));
    }

    private fireValueChange() {
        const options = this.state.options as IOptionEntry[];
        const v = options.filter( (o) => o.selected).map( (o) => o.value );
        this.fireChanges([{
            component: this.props.primaryComponent,
            value: v,
        }]);
    }

    private selectItem(country: ICountry) {
        const findCountry = (option: IOptionEntry) => option.value === country.id;
        const hit = this.state.options.find(findCountry);
        if (hit) {
            return;
        }
        this.state.options.push({
            label: country.name_de,
            value: country.id,
            selected: true,
        });

        SessionStore.setItem("CountriesParameter", this.state.options);
        this.setState({
            showSearch: false,
        });
        this.fireValueChange();
    }
    private filterCountry(query, country: ICountry, _index, exactMatch) {
        const findCountry = (option: IOptionEntry) => option.value === country.id;
        const text = [country.name_de, country.name, country.iso_2].join(", ").toLowerCase();
        return text.indexOf(query.toLowerCase()) >= 0; // || this.state.options.find(findCountry)? true : false;
    }
    private renderCountry(country: ICountry, { handleClick, modifiers, query }): any {
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={country.name_de}
                key={country.id}
                onClick={handleClick}
                text={[country.name_de, country.name, country.iso_2].join(", ")}
            />
        );
    }
    private removeItem(countryId) {
        const options = this.state.options;
        const idx = options.findIndex((o) => countryId === o.value);
        if (idx >= 0) {
            options.splice(idx, 1);
            this.setState({
                options,
            });
            this.fireValueChange();
        }
    }

    private clearList() {
        const options = [];
        this.setState({
            options,
        });

        this.fireValueChange();
    }
    private renderLoading() {
        return <Spinner size={100} />;
    }
    private renderEntries() {
        const renderDivider = () => {
            if (this.state.options.length === 0) {
                return;
            }
            return <MenuDivider />;
        };
        return (
            <div style={{paddingTop: 8}}>
                <Menu>
                    <MenuItem intent={"primary"} labelElement={<Icon icon="search" />}  text={"Suche ..."}  onClick={() => this.setState({showSearch: true})} />
                    <MenuItem intent={"danger"} labelElement={<Icon icon="cross" />}  text={"Liste leeren"} onClick={() => {this.clearList(); }} disabled={this.state.options.length === 0} />
                    {renderDivider()}
                    {
                        this.state.options.map((item, index) => {
                            return(<MenuItem labelElement={<Icon icon="cross" />} text={item.label} onClick={() => this.removeItem(item.value)} />);
                        })
                    }
                </Menu>
            </div>
        );
    }

    private async getOptions() {
        const options: IOptionEntry[] = [];
        const ddd: any[] = SessionStore.getItem("CountriesParameter", []);
        if (ddd) {
            ddd.forEach( (s) => {
                const id = s.label ? s.value : s;
                const country = Globals.countries.find( (c) => c.id === id );
                if (country) {
                    options.push({value: country.id, label: country.name_de, selected: true, countryIso2: country.iso_2});
                }
            } );
        } else {
            options.push({value: "771", label: "Deutschland", selected: true, countryIso2: "de"});
        }
        return options;
    }
}
