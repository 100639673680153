import {IToastProps, Toaster} from "@blueprintjs/core";
import * as React from "react";
import {IParameterChanged} from "../models/IParameterChanged";
import {ModuleGroup} from "../modules";
import {EventBus, IEventHandler} from "../services/EventBus";
import {ParameterBar} from "./index";
import {SectionWorkspace} from "./SectionWorkspace";
import {SideBar} from "./SideBar";
import {DlgDocumentation} from "./widgets/DlgDocumentation";
import {DlgExcelExport} from "./widgets/DlgExcelExport";

export interface IApplicationBoard {
    modules: ModuleGroup[];
}

let _key=0;
export class ApplicationBoard  extends React.Component <IApplicationBoard, any> {
    private sectionWorkspace: React.RefObject<any> = React.createRef();
    private readonly msgToaster: React.RefObject<Toaster>;
    private readonly onShowMessage: IEventHandler;
    // private readonly evtParameterChanged: IEventHandler;

    constructor(props: IApplicationBoard, context: any) {
        super(props, context);
        this.state = {
            currentModuleGroup: Array.isArray(props.modules) ? props.modules[0] : undefined,
        };
        // this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        // this.handleParametersChanged = this.handleParametersChanged.bind(this);

        this.msgToaster = React.createRef();
        this.onShowMessage = EventBus.subscribe<IToastProps>("sz-show-message", (data) => this.showMessage(data));
    }
    public componentWillUnmount(): void {
        EventBus.unsubscribe(this.onShowMessage);
    }

    public componentDidUpdate(prevProps) {
        if(this.props.modules[0] !== prevProps.modules[0]) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.setState({
                currentModuleGroup: this.props.modules[0],
            });
        }
    }

    public render() {
        const module = this.state.currentModuleGroup as ModuleGroup;
        return (
            <>
                <div className="sz-application-board" style={{width: "100%", height: "100%"}}>
                    <div className={"sz-main-content"} style={{width: "100%", height: "100%", display: "grid", gridTemplateRows: "50px 1fr"}}>
                        <ParameterBar module={module} handleParametersChanged={(d)=> this.handleParametersChanged(d)} />
                        <SectionWorkspace module={module} ref={this.sectionWorkspace} key={`${module}_${_key++}`} />
                    </div>
                </div>
                <div className={"sz-chart-hidden"} id={"hiddenSvgContainer"}></div>
                <Toaster position={"top"} autoFocus={false} canEscapeKeyClear={true} ref={this.msgToaster} className={"in-front sz-toaster-width-400px"} />
                {this.renderPdfDialog()}
                {this.renderExportDlg()}
            </>
        );
    }
    private renderExportDlg() {
        return (
            <DlgExcelExport />
        );
    }
    private renderPdfDialog() {
        const module = this.state.currentModuleGroup as ModuleGroup;
        if (module && module.documentation && Array.isArray(module.documentation)) {
            return (
                <DlgDocumentation dataDefaults={module.documentation} />
            );
        }
        return null;
    }

    private handleParametersChanged(parameters: IParameterChanged[]) {
        if (this.sectionWorkspace) {
            this.sectionWorkspace.current.updateParameters(parameters);
        }
    }

    private showMessage(data: IToastProps) {
        console.error("showMessage...");
        this.msgToaster.current.show(data);
    }
}
