import {
    Breadcrumbs,
    Button,
    Classes,
    HTMLTable,
    IBreadcrumbProps,
    Icon,
    InputGroup,
    Intent,
    Navbar,
    NavbarGroup,
    NonIdealState,
} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {
    EFileManagerEntryTypes,
    IFileManagerEntry,
    IFileManagerLoadFolder,
    IFileManagerPath,
    IFileManagerPathResult,
} from "../../../models/IFileManagerEntry";
import {EServiceType, ServiceCallBase} from "../../../services/ServiceCallBase";
import {ServiceCalls} from "../../../services/ServiceCalls";
import {SzSvgLoader} from "../SzSvgLoader";
import moment = require("moment");
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

interface IPdfDocFileNameState {
    isLoading: boolean;
    data: IFileManagerLoadFolder;
    path: IFileManagerPath[];
    fileName: string;
    scrollBarWidth: number;
}
export interface IPdfDocFileNameProps {
    isSaving: boolean;
    onClose?(doSave: boolean, fileName?: string, folderId?: string);
}

export class PdfDocFileName  extends React.Component<IPdfDocFileNameProps, IPdfDocFileNameState> {

    constructor(props: IPdfDocFileNameProps, context: any) {
        super(props, context);
        this.state = {
            scrollBarWidth: 17,
            isLoading: true,
            data: null,
            path: [],
            fileName: undefined,
        };
        this.readFolder();
    }

    public render() {
        const buttonsStyle: CSSProperties = {
            display: "flex",
            paddingLeft: 16,
            justifyContent: "flex-end",
        };
        const style: CSSProperties = {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "relative",
        };
        const renderPath = () => {
            if (!this.state.path.length) {
                return null;
            }
            const items: IBreadcrumbProps[] = this.state.path.map( (p) => ({onClick: () => {this.openFolder(p._id); }, href: "#", text: p.name, icon: p._id === "0" ? "home" : null}) );
            return (
                <Breadcrumbs items={items} />
            );
        };
        const renderSaving = () => {
            if (!this.props.isSaving) {
                return _t(ETranslation.pdf_icon_hoover);
            }
            return (
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{display: "inline-block", margin: "auto"}} width="16px" height="16px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" r="32" stroke-width="8" stroke="rgba(255, 255, 255, 0.6)" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                    </circle>
                </svg>
            );
        };
        return (
            <>
                <div className={Classes.DIALOG_BODY} style={{margin: 0, marginBottom: 10}}>
                    <div className="sz-file-manager" style={style}>
                        <Navbar className={"sz-file-manager-navbar"} style={{paddingLeft: 20}}>
                            <NavbarGroup style={{marginLeft: 6}}>
                                {renderPath()}
                            </NavbarGroup>
                        </Navbar>
                        {this.renderContent()}
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={"sz-row"}>
                        <InputGroup
                            disabled={false}
                            placeholder="Name ..."
                            small={true}
                            className={"sz-col-50"}
                            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => this.setState({fileName: e.target.value}) }
                            value={this.state.fileName}
                        />
                        <div className={"sz-col-50"} style={buttonsStyle}>
                            <Button onClick={() => this.props.onClose ? this.props.onClose(false) : null } disabled={this.props.isSaving}>{_t(ETranslation.cancel)}</Button>
                            <Button
                                disabled={(!this.isFileName()) || this.props.isSaving}
                                intent={Intent.PRIMARY}
                                style={{marginLeft: 8, minWidth: 110}}
                                onClick={ () => { this.createDoc(); } }
                            >
                                {renderSaving()}
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    private renderContent() {
        const style: CSSProperties = {
            top: 1,
            left: 0,
            right: 0,
            bottom: 0,
            position: "relative",
            margin: 0,
            overflowY: "scroll",
            maxHeight: 450,
        };
        return (
            <div style={style} className={"sz-file-manager-container"}>
                {this.renderHeader()}
                {this.contentDiv()}
                {this.renderLoader()}
            </div>
        );
    }
    private renderHeader() {
        const style: CSSProperties = {
            left: 0,
            top: 0,
            width: "100%",
            position: "sticky",
            margin: 0,
            zIndex: 10,
            boxShadow: "0 1px 0 0 rgba(16, 22, 26, 0.15)",
        };
        return (
            <table className={"sz-table bp3-html-table bp3-html-table-condensed sz-small-padding sz-file-manager-header"} style={style}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th style={{width: 170}}>Datum</th>
                </tr>
                </thead>
            </table>
        );
    }
    private renderLoader() {
        if (!this.state.isLoading) {
            return null;
        }
        return <SzSvgLoader />;
    }
    private contentDiv() {
        const style: CSSProperties = {
            width: "100%",
            height: "100%",
            position: "relative",
            margin: 0,
        };
        const tableStyle: CSSProperties = {
            width: "100%",
        };
        const emptyStyle: CSSProperties = {
            minHeight: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        };

        if (this.state.data && this.state.data.entries && this.state.data.entries.length === 0) {
            return (
                <div style={emptyStyle}>
                    <NonIdealState
                        icon={"folder-open"}
                        title={`Bitte geben Sie im Eingabefeld unten einen Namen für die Datei ein und klicken Sie dann auf "PDF Erstellen".`}
                    />
                </div>
            );
        }
        const getIcon = (e: IFileManagerEntry) => {
            if ( e.entry_type === EFileManagerEntryTypes.folder) {
                return <Icon icon={"folder-close"} />;
            }
            return <Icon icon={"document"} />;
        };
        const getEntry = (e: IFileManagerEntry) => {
            if ( e.entry_type === EFileManagerEntryTypes.folder) {
                return <a className={"sz-fm-entry"} href="#" onClick={() => {this.openFolder(e._id); }}>{getIcon(e)}&nbsp;&nbsp;{e.name}</a>;
            }
            return <a className={"sz-fm-entry"} href={`${ServiceCallBase.getHost(EServiceType.Services)}/document-store/file/${e._id}`} target={"download"}>{getIcon(e)}&nbsp;&nbsp;{e.name}</a>;
        };
        let cKey: number = 1;
        return (
            <div style={style} >
                <HTMLTable className={"sz-table sz-small-padding"} style={tableStyle} condensed={true} interactive={true} striped={true}>
                    <tbody>
                    {!this.state.data ? null : this.state.data.entries.map((entry) => (
                        <tr key={"fileManEntry" + (cKey++)}>
                            <td>{getEntry(entry)}</td>
                            <td style={{width: 170}}>{moment(entry.date).format("DD.MM.YYYY hh:mm")}</td>
                        </tr>
                    ))}
                    </tbody>
                </HTMLTable>
            </div>
        );
    }
    private openFolder(folderId: string) {
        this.setState({
            isLoading: true,
            data: null,
            path: [],
        });
        this.readFolder(folderId);
    }
    private readFolder(folderId: string = "0") {
        console.error("read folder");
        (async () => {
            try {
                const sc = new ServiceCalls();
                const ret: IFileManagerLoadFolder = await sc.readFolder(folderId);
                const path: IFileManagerPathResult = await sc.getPath(folderId);
                this.setState({
                    isLoading: false,
                    data: ret,
                    path: path.entries,
                });
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    private isFileName(): boolean {
        const fileName: string = this.state.fileName ? this.state.fileName.trim() : "";
        return fileName.length > 0;
    }
    private createDoc() {
        if (!this.isFileName()) {
            return;
        }
        if (!this.props.onClose) {
            return;
        }
        this.props.onClose(true, this.state.fileName.trim(), this.state.data.parent_folder_id);
    }
}
