import {EContentType} from "../../const/EContentType";
import {EPackageTypes} from "../../const/EPackageTypes";
import {NopeActions} from "../../const/NopeActions";
import {ELayoutColumnWidth} from "../../models/ELayoutColumnWidth";
import {EParameters} from "../../models/EParameters";
import {IColumnLayout} from "../../models/IColumnLayout";
import {ColumnLayouterImpl} from "../ColumnLayouterImpl";
import {ModuleGroup} from "../ModuleGroup";
import {IconNames} from "@blueprintjs/icons";
import {Intent} from "@blueprintjs/core";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {IModuleConfig} from "../../models/IModuleConfig";

export class Benchmarking extends ModuleGroup<IColumnLayout> {
    public static config: IModuleConfig = {
        name: "Benchmarking",
    };
    constructor() {
        super("Benchmarking", {columns: []});

        this.documentation = [
            {module: "FinancialsAnnReported", caption: "Wachstum"},
            {module: "FinancialsAnnReported", caption: "Profitabilität"},
            {module: "FinancialsAnnReported", caption: "Rentabilität"},
            {module: "FinancialsAnnReported", caption: "Kapitalstruktur"},
            {module: "FinancialsAnnReported", caption: "Bonität"},
            {module: "FinancialsAnnReported", caption: "Investitionen"},
            {module: "FinancialsAnnReported", caption: "Working Capital"},
        ];

        this.addParameter(EParameters.CompaniesParameter);
        this.addParameter(EParameters.DateParameter);
        this.addParameter(EParameters.BenchmarkingCustom);

        const dataDefault = [{theSame: true}];


        this.addModule(_t(ETranslation.company_financials), "FinancialsAnnUserDef", "FinancialsAnnReportedProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .addModuleAction({event: "FinancialsAnnUserDef::EvtRefresh", caption: _t(ETranslation.apply), icon: IconNames.REFRESH, intent: Intent.PRIMARY})
            .addModuleAction({event: "FinancialsAnnUserDef::EvtClear", icon: IconNames.TRASH, intent: Intent.DANGER})
        ;
        this.addModule(_t(ETranslation.financials), "FinancialsAnnUserDefView", "FinancialsAnnReportedProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
        ;

        this.addModule(_t(ETranslation.growth), "FinancialsAnnReported", "FinancialsAnnReportedProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true)
            .options = ["sales_growth", "ebitda_growth"];

        this.addModule(_t(ETranslation.profitability), "FinancialsAnnReported", "FinancialsAnnReportedProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true)
            .options = ["ebitda_sales_ratio", "ebit_sales_ratio"];

        this.addModule(_t(ETranslation.rentability), "FinancialsAnnReported", "FinancialsAnnReportedProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true)
            .options = ["roce", "roe"];

        this.addModule(_t(ETranslation.capital_structure), "FinancialsAnnReported", "FinancialsAnnReportedProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true)
            .options = ["eq_ratio", "gearing"];

        this.addModule(_t(ETranslation.creditworthiness), "FinancialsAnnReported", "FinancialsAnnReportedProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true)
            .options = ["ffo_debt_ratio", "ebit_interest_ratio"];

        this.addModule(_t(ETranslation.capital_expenditures), "FinancialsAnnReported", "FinancialsAnnReportedProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true)
            .options = ["capex_sales_ratio", "capex_capitalasset_ratio"];

        this.addModule(_t(ETranslation.working_capital), "FinancialsAnnReported", "FinancialsAnnReportedProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true)
            .options = ["working_sales_ratio", "working_capital_employed_ratio"];

        this.addModule("Peer-Group", "CompanyInfo", "FinancialsAnnReportedProvider", dataDefault)
            .setAccess(EContentType.Elite, EPackageTypes.Elite, NopeActions.elite)
            .setAlignRight()
        ;

        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c40)
            .addModule([0, 1]);
        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c60)
            .addModule([2, 3, 4, 5, 6, 7, 8, 9]);
        // ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c30)
//            .addModule([7, 0]);
        /*
        // test
        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c70)
            .addModule([1, 2, 3, 4, 5])
            .addModule(8);

        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c30)
            .addModule([6, 0])
            .addModule(9);
        */
    }
    protected getTitle(): string{
        return "Benchmarking";
    }
}
