import {Button, Icon} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {BaseModule} from "./BaseModule";

interface ITransferPricingHelpState{
    is_loading?: boolean;
}

export class TransferPricingHelp extends BaseModule<any> {

    constructor(props: any, context: ITransferPricingHelpState) {
        super(props, context);
    }
    public async getDocumentationData() {
        return [];
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    protected renderContent() {
        return (
            <div className={"sz-row"} style={{paddingTop: 16}}>
                <div className={"sz-col sz-col-100"}>
                    <h4 style={{fontSize: 14}}>Long List</h4>
                    <p>Unter Anwendung der Filterkriterien werden die folgenden Unternehmen in die Long List übernommen</p>
                    <ul>
                        <li>Die Long List spiegelt den Stand der Unternehmensdatenbank am der Tag der Nutzung wider.</li>
                        <li>Unternehmen, die für alle 3 Jahre einen veröffentlichen, verfügbaren Jahresabschluss haben.</li>
                        <li>Unternehmen mit unvollständigen Jahresabschlussdaten werden in die Longlist aufgenommen und sind ggf. mittels der Eliminierungskriterien auszuschließen</li>
                    </ul>
                    <h4 style={{fontSize: 14}}>Analysegröße</h4>
                    <p>Operative Erträge: Umsatzerlöse; nicht enthalten sind sonstige betriebliche Erträge, Zinserträge oder sonstige nicht operativen Erträge</p>
                    <p>Rohertrag: Umsatzerlöse abzgl. Herstellungskosten inklusive Abschreibungen; bei Unternehmen, die nach dem Gesamtkostenverfahren berichten, enthalten die Herstellungskosten sämtliche Personalkosten, soweit im Rahmen der Finanzberichterstattung nicht explizit eine Aufteilung der Personalkosten nach Funktionsbereichen erfolgt.</p>
                    <p>EBIT bereinigt & EBITDA bereinigt: Umsatzerlöse gemäß o.g. Definition abzüglich bereinigter operativer Aufwendungen inklusive der Abschreibungen (EBIT) oder exklusive der Abschreibungen (EBITDA).</p>
                    <p>
                        Mit dem Ziel einer bestmöglichen Vergleichbarkeit aller Unternehmen werden u.a. die folgenden Tatbestände bereinigt:
                    </p>
                    <ul>
                        <li>Restrukturierungsaufwendungen</li>
                        <li>Wertberichtigungen und außerplanmäßige Abschreibungen</li>
                        <li>Aufwendungen und Erträge aus Rechtsstreitigkeiten</li>
                        <li>Nicht realisierte Gewinne- und Verluste</li>
                        <li>Außerordentliches Ergebnis</li>
                        <li>Ergebnis aus nicht fortgeführten Geschäftsaktivitäten</li>
                    </ul>
                </div>
            </div>
        );
    }
    private renderContact() {
        return (
            <div>
                <div className={"sz-loco sz-loco-big"} style={{alignItems: "center"}}>
                    <div  className={"sz-loco-icon"}><Icon icon={IconNames.ENVELOPE} color={"#5c7080"} iconSize={48} /></div>
                    <div className={"sz-loco-text"}><div style={{textAlign: "center"}}>Zum Thema <strong>Transfer-Pricing</strong> setzen Sie sich bitte mit uns in Verbindung.</div></div>
                    <div style={{margin: 32}}>
                        <Button icon={IconNames.ENVELOPE} intent={"primary"} text={"Kontakt Formular"} onClick={() => window.open("https://www.smart-zebra.de/kontakt/") } />
                    </div>
                </div>
            </div>
        );
    }
}
