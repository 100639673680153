import * as React from "react";
import {CSSProperties} from "react";
import {BondSector} from "../../const/BondSector";
import {BaseModule} from "./BaseModule";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {getAllNextDates, group_by, IndexMap} from "../../helpers/Helpers";
import {ICorporateSpread} from "../../models/ICorporateSpread";
import {median} from "../../helpers/Statistics";
import {Globals} from "../../const/Globals";
import {Line} from "react-chartjs-2";
import {Ratings} from "../../const/Ratings";
import {ISzTableColumn, ISzTableProperties} from "../widgets/SzTable";
import {SzTableHelper} from "../widgets/helper/SzTableHelper";
import {HTMLTable, Menu, MenuItem} from "@blueprintjs/core";
import {TasksCalls} from "../../services/TasksCalls";
import {IBondTimeSeries} from "../../models/IBondTimeSeries";
import {ISzSvgDataPoint} from "../../models/ISzSvgDataPoint";
import {SzBondChart} from "../widgets/SzBondChart";
import {Sectors} from "../../const/Sectors";
import {deadline, deadlineEoM, fromDateDecimal, fromStr, mk_duration} from "../../tools/DateTools";
import {_t, _tn} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

type LinearFunction={n: number; m:number;};

const use_ratings = ["AAA", "AA", "AA-", "A+", "A", "A-", "BBB+", "BBB", "BBB-", "BB+", "BB", "BB-", "B+", "B", "CCC+", "CCC"];
const root_ratings = ["AAA", "AA","A", "BBB", "BB", "B", "CCC"];

const bond_quality = {
    "AAA": "Sehr gute Anleihe",
    "A+": "Gute Anleihe",
    "BB+": "Spekulative Qualität",
    "CCC+": "Junk Bond",
};
const get_bond_quality = (rating)=>{
    const q = bond_quality[rating];
    if(q){
        return q;
    }else{
        return "";
    }
};

const interpolate_years = [4, 6, 8, 9, 11, 12, 13, 14, 15, 25];
const interpolate_years_bounds = {
    4: [3,5],
    6: [5,7],
    8: [7,10],
    9: [7,10],
    11: [10, 20],
    12: [10, 20],
    13: [10, 20],
    14: [10, 20],
    15: [10, 20],
    25: [20, 30],
};
const captions = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "20",
    "25",
    "30",
];
const fields = [
    "v_1_j",
    "v_2_j",
    "v_3_j",
    "v_5_j",
    "v_7_j",
    "v_10_j",
    "v_20_j",
    "v_30_j",
];
const fields_plus = [
    "v_1_j",
    "v_2_j",
    "v_3_j",
    "v_4_j",
    "v_5_j",
    "v_6_j",
    "v_7_j",
    "v_8_j",
    "v_9_j",
    "v_10_j",
    "v_11_j",
    "v_12_j",
    "v_13_j",
    "v_14_j",
    "v_15_j",
    "v_20_j",
    "v_25_j",
    "v_30_j",
];

const xTicksNew = [12, 24, 36, 60, 84, 120, 240, 360];

const durations = [
    mk_duration(0.5, 1.5),
    mk_duration(1.5, 2.5),
    mk_duration(2.5, 3.5),
    mk_duration(3.5, 6.5),
    mk_duration(6, 8),
    mk_duration(8, 12),
    mk_duration(15, 25),
    mk_duration(25, 30),
];
const slope_durations = [
    mk_duration(1, 2),
    mk_duration(2, 3),
    mk_duration(3, 5),
    mk_duration(5, 7),
    mk_duration(7, 10),
    mk_duration(10, 20),
    mk_duration(20, 30),
];
const get_pot_idx = (x: number /*qd diff mat_date*/) => {
    const p = durations.findIndex( (d) => x >= d.start && x < d.end );
    if(p === -1){
        return undefined;
    }else{
        return p;
    }
};
const get_slope_idx = (x: number /*qd diff mat_date*/, fnk_compare) => {
    const fnk = fnk_compare ? (d) => x >= d.start && x < d.end : (d) => x > d.start && x <= d.end;
    const p = slope_durations.findIndex( (d) => fnk(d));
    if(p === -1){
        return undefined;
    }else{
        return p;
    }
};

const getSectors = (): string[] =>{
    const sector_parameter = SessionStore.get(EParameters.BondSectorParameter);
    const sector: string[] = sector_parameter===null || sector_parameter === undefined ? ["0"] : sector_parameter.split(",");
    return sector;
};

export class CreditSpreadIndustry extends BaseModule<any> {

    private tableProps: ISzTableProperties<ICorporateSpread>;

    private regressionData: ISzSvgDataPoint[] = [];
    private regressionData_all: ISzSvgDataPoint[] = [];

    private m_data: LinearFunction[] = [];
    private m_data_all: LinearFunction[] = [];

    private max_y_all = 0;
    private max_y = 0;

    private grouped_time_line: IndexMap<ICorporateSpread>;

    constructor(props: any, context: any) {
        super(props, context);
        this.grouped_time_line = {};
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }
    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }

    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.tableProps as any);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    protected renderContent() {
        return (
            <div>
                <div className={"sz-row"}>
                    <div className={"sz-col-60"} style={{paddingRight: 40}}>
                        {this.renderCreditSpreadOverview()}
                    </div>
                    <div className={"sz-col-40"} style={{paddingLeft: 40}}>
                        <div className={"sz-row"}>
                            {this.renderCorporateSpread()}
                        </div>
                        <div className={"sz-row"} style={{marginTop: 20}}>
                            {this.renderCreditSpreadTimeLine()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;
        const date_decimal = fromStr(SessionStore.get(EParameters.DateParameter)).setToEoM().asDateDecimal().toString();
        const spread_time_line: ICorporateSpread[] = this.data.spread_time_line;
        const sector: string[] = getSectors();
        const time_line: ICorporateSpread[] = spread_time_line
            .filter((i, idx)=> sector.includes(i.sector) && i.date_decimal === date_decimal);
        const grouped_time_line: IndexMap<ICorporateSpread> = group_by<ICorporateSpread>(time_line, "rating");
        this.grouped_time_line = grouped_time_line;
        const ratings = Object.keys(grouped_time_line).sort((a,b)=> Ratings.s_and_p_all_mapped_to_num[a] - Ratings.s_and_p_all_mapped_to_num[b]);
        const selected_rating = ratings[0];

        this.setState({
            ratings,
            selected_rating,
            loading: false,
        });
    }

    private renderCreditSpreadOverview(){
        const table_data: ICorporateSpread[] = [];
        const grouped_time_line: IndexMap<ICorporateSpread> = this.grouped_time_line;
        const functions: {[index:string]: LinearFunction} = {};
        use_ratings.forEach((rating)=>{
            const data_sets: ICorporateSpread[] = grouped_time_line[rating];
            const entry: ICorporateSpread = Object.assign({}, {rating}) as ICorporateSpread;
            table_data.push(entry);
            if(!data_sets){
                fields.forEach((field)=>{
                    entry[field] = NaN;
                });
            }else{
                fields.forEach((field)=>{
                    const ff = [];
                    data_sets.forEach((data_set)=>ff.push(data_set[field]));
                    entry[field] = median(ff);
                });
                interpolate_years.forEach((i)=>{
                    const bounds = interpolate_years_bounds[i];
                    if(!Array.isArray(bounds)){
                        return;
                    }
                    const bounds_key = `${rating}_${bounds[0]}_${bounds[1]}`;
                    if(!functions[bounds_key]){
                        const y = entry[`v_${bounds[1]}_j`];
                        const x = bounds[1];
                        const dx = bounds[1] - bounds[0];
                        const dy = y - entry[`v_${bounds[0]}_j`];
                        const m = dy / dx;
                        // console.error(`v_${bounds[1]}_j`, entry);
                        functions[bounds_key] =  {m, n: y - (m*x)};
                    }
                    entry[`v_${i}_j`] = functions[bounds_key].n + functions[bounds_key].m * i;
                });
            }
        });

        table_data.sort((a,b)=> Ratings.s_and_p_all_mapped_to_num[a.rating] - Ratings.s_and_p_all_mapped_to_num[b.rating]);
        const padding = "6px 3px";
        const num_width = 54;
        const td_height = 28;
        const number_col: CSSProperties = {
            padding,
            textAlign: "right",
            width: num_width,
            height: td_height
        };
        const border_right = {
            boxShadow: "inset -1px 0px 0px 0px rgba(16, 22, 26, 0.15)",
        };
        const border_right_0 = {
            boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15), inset -1px 0px 0px 0px rgba(16, 22, 26, 0.15)",
        };
        // console.error(grouped_time_line);
        // console.error(table_data);

        const header_0: ISzTableColumn[] = [];
        header_0.push({ text: "Rating" });
        header_0.push({ text: _t(ETranslation.term_in_years), options: {colSpan: fields_plus.length} });
        const header_1: ISzTableColumn[] = [];
        header_1.push({ text: "" });
        captions.forEach((c)=>header_1.push({ text: c, options: {className: "sz-right"}}));
        const columns: ISzTableColumn[] = [];
        columns.push({ ...SzTableHelper.columnIndex("rating", undefined)});
        fields_plus.forEach((c)=>{
            columns.push({...SzTableHelper.columnMoney(c, 2, {style: {width: num_width}})});
        });
        this.tableProps = {
            title: _t(ETranslation.credit_spread_ratings),
            colCount: columns.length,
            data: table_data,
            header: [ header_0, header_1 ],
            columns,
        };
        const bs_top = "#95dcfd 0px 1px 0px 0px inset";
        const bs_bottom = "#95dcfd 0px -1px 0px 0px inset";
        const bs_right = "#95dcfd -1px 0px 0px 0px inset";
        const bs_left = "#95dcfd 1px 0px 0px 0px inset";

        const selected_rating = this.state.selected_rating;
        const selectRating = (rating) => {
            this.setState({selected_rating: rating});
        };

        return (
            <div className={"sz-row"}>
                <div className={"sz-col-100"} style={{position: "relative", width: "100%"}}>
                    <div style={{marginBottom: 20, fontSize: "80%"}}>
                        <strong>Branchen-Kreditaufschlag nach Rating{Globals.superscript(1)}˒{Globals.superscript(2)}˒{Globals.superscript(3)} [% p.a.]</strong>
                        <br/>
                        {this.getSelectedSector()}
                    </div>
                    <HTMLTable className={`sz-prj-info-table_2 sz-table`} condensed={true} style={{width: "100%", fontSize: "80%"}} key={`cpi_${selected_rating}`}>
                        <thead>
                        <tr style={{backgroundColor: "#FFFFFF"}}>
                            <th style={{padding, ...border_right}} colSpan={2}>Rating</th>
                            <th style={{padding, textAlign: "center"}} colSpan={fields_plus.length}>{_t(ETranslation.term_in_years)}</th>
                        </tr>
                        <tr style={{backgroundColor: "#FFFFFF"}}>
                            <th style={{padding, ...border_right}} colSpan={2}>Anleihequalität</th>
                            {captions.map((c)=>(<th style={{padding, textAlign: "right", width: num_width}}>{c}</th>))}
                        </tr>
                        </thead>
                        <tbody>
                        {table_data.map((row: ICorporateSpread, row_num)=>{
                            const backgroundColor = {backgroundColor: "#e0efff"};
                            const bs = [];
                            if(row_num === 0 && row.rating !== selected_rating){
                                bs.push("inset 0 1px 0 0 rgba(16, 22, 26, 0.15)");
                            }
                            if(selected_rating === row.rating){
                                bs.push(bs_left, bs_right);
                            }else{
                                backgroundColor.backgroundColor = undefined;
                            }
                            if(row.rating === selected_rating){
                                bs.push(bs_top);
                            }
                            if(row.rating === selected_rating){
                                bs.push(bs_bottom);
                            }
                            const boxShadow: CSSProperties = {boxShadow: bs.join(", ")};
                            return (
                                <tr style={{...backgroundColor, ...boxShadow}} className={"sz-row-hover"}>
                                    <td style={{height: td_height, paddingLeft: 3, boxShadow: "none"}}  onClick={()=>selectRating(row.rating)}>{row.rating}</td>
                                    <td style={{...border_right, width: 150, height: td_height, paddingLeft: 12, paddingRight: 3}}  onClick={()=>selectRating(row.rating)}>{get_bond_quality(row.rating)}</td>
                                    {fields_plus.map((f)=>{
                                        const add_style:CSSProperties = {};
                                        if(!fields.includes(f) || !root_ratings.includes(row.rating)){
                                            add_style.color = "#8F99A8"; // #738091
                                        }
                                        return (
                                            <td style={{...number_col, ...add_style, boxShadow: "none"}}  onClick={()=>selectRating(row.rating)}>{Globals.formatter(row[f]/100, 2, 2, true)}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        </tbody>
                    </HTMLTable>
                    <div style={{marginTop: 20, width: "100%", fontSize: "80%"}} className={"bp3-text-muted"}>
                        <div>{Globals.superscript(1)}) {_t(ETranslation.median)}; Entspricht bei Einschränkung auf Super-Sektor dem Median der Sektoren</div>
                        <div>{Globals.superscript(2)}) Grau dargestellte Werte sind interpoliert</div>
                        <div>{Globals.superscript(3)}) Branchenkreditaufschläge ab dem 30.09.2022 auf Basis eigener Berechnungen</div>
                    </div>
                </div>
            </div>
        );
    }

    private renderCreditSpreadTimeLine(){
        const dates = getAllNextDates(deadlineEoM(), 3)
            .map((d)=>fromStr(d).asDateDecimal().toString())
            .reverse();
        const dates_map = {};
        dates.forEach((i, idx)=> dates_map[i] = idx);
        const sector: string[] = getSectors();

        const v_x_fields = ["v_1_j", "v_3_j", "v_5_j", "v_7_j", "v_10_j"];
        const v_x_labels = [
            _t(ETranslation.year_number, 1),
            _t(ETranslation.years_number, 3),
            _t(ETranslation.years_number, 5),
            _t(ETranslation.years_number, 7),
            _t(ETranslation.years_number, 10)
        ];
        const v_x_colors = [
            "#68C1EE",
            "#3FA6DA",
            "#147EB3",
            "#0F6894",
            "#0C5174",
        ];
        const spread_time_line: ICorporateSpread[] = this.data.spread_time_line;

        // für jedes rating kommt ein datensatz, deswegen werden die in values abgelegt und später der median ermittelt.
        const data_date_map: {[index: string]: {[index: string]: {values: number[]}}} = {};
        const add_data = (date_decimal: string, spread: ICorporateSpread)=>{
            if(!data_date_map[date_decimal]){
                data_date_map[date_decimal] = {};
                // initialisierung für jedes feld mit einem leeren array
                v_x_fields.forEach((field) =>{
                    data_date_map[date_decimal][field] = {values: []};
                });
            }
            v_x_fields.forEach((field) =>{
                data_date_map[date_decimal][field].values.push(spread[field]);
            });
        };
        const rating = this.state.selected_rating;
        spread_time_line.forEach((i, idx)=>{
            if(sector.includes(i.sector) && rating === i.rating){
                add_data(i.date_decimal, i);
            }
        });

        const xy = (x, values: number[]) => ({x, y: median(values) / 100 });
        const get_xy = (x, source: {[index: string]: {values: number[]}}, field_name: string) =>{
            if(!source){
                return {x, y: undefined};
            }else{
                return xy(x, source[field_name].values);
            }
        };
        const time_lines = (new Array(v_x_fields.length)).fill(0).map(()=>([]));
        const dates_formatted = [];
        dates.forEach((date, x)=>{
            dates_formatted.push(fromDateDecimal(date).toFormat("LLL yy"));
            v_x_fields.forEach((field, field_idx)=>{
                const data_set= time_lines[field_idx];
                data_set.push(get_xy(x, data_date_map[date], field));
            });
        });
        // console.error(date_map);
        // console.error(data_date_map);
        // console.error(time_lines);

        const options = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            interaction: {
                intersect: true,
                mode: 'index',
            },
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        z: 10,
                        padding: 10,
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                },
                x: {
                    type: 'linear',
                    min: 0,
                    max: dates.length - 1,
                    ticks: {
                        z: 10,
                        padding: 10,
                        stepSize: 6,
                        callback: (value, index, ticks)=>{
                            // console.error(value, index, ticks);
                            return dates_formatted[parseInt(value, 10)];
                        },
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend: {
                    display: false,
                },
                datalabels: {
                    display: 0,
                },
                tooltip: {
                    enabled: true,
                    position: "nearest",
                    callbacks: {
                        title: (items)=> {
                            return dates_formatted[parseInt(items[0].label, 10)];
                        },
                        label: (context)=> {
                            let label = context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += Globals.formatter(context.parsed.y) + "%";
                            }
                            return label;
                        },
                    },
                },
            },
            custom_axis_label: {
                x_label: _t(ETranslation.date),
                y_label: "[% p.a.]",
            },
        };
        const chart_data = {
            labels: dates.map((date)=> fromDateDecimal(date).toFormat("LLL yy")),
            datasets: []
        };
        v_x_fields.forEach((field, field_idx)=>{
            const ds = {
                label: v_x_labels[field_idx],
                data: time_lines[field_idx],
                backgroundColor: v_x_colors[field_idx],
                borderColor: v_x_colors[field_idx],
                borderWidth: 1,
                pointRadius: 2,
                pointHitRadius: 2,
            };
            chart_data.datasets.push(ds);
        });
        return (
            <div className={"sz-row"}>
                <div style={{marginBottom: 20,fontSize: "80%"}}>
                    <strong>Branchen-Kreditaufschlag im Zeitablauf{Globals.superscript(1)} [% p.a.]</strong>
                    <br/>
                    Rating {rating} | {this.getSelectedSector()}
                </div>
                <div className={"sz-col-100"} style={{position: "relative", width: "100%", height: 300}}>
                    <Line options={options as any} data={chart_data as any} height={"100%"} width={"100%"} />
                </div>
            </div>
        );
    }
    private getSelectedSector() {
        const v = SessionStore.get(EParameters.BondSectorParameter);

        if(!v){
            return BondSector.matrix_to_label["0_0"];
        }
        if(v==="—"){
            return BondSector.matrix_to_label["0_0"];
        }
        const matrix = BondSector.value_to_matrix[v] ? BondSector.value_to_matrix[v].split("_").map((i)=> parseInt(i, 10)) : [-1, -1];
        if(matrix[0] === 0 || matrix[1] === 0){
            const xy = BondSector.value_to_matrix[v];
            return BondSector.matrix_to_label[xy];
        }

        const result = [];
        v.split(",").forEach((label) => {
            result.push(Sectors.sectors_map[label]);
        });
        return result.join(", ");
    }

    private getSeriesNew(sectors: string[], all_spreads: ICorporateSpread, sector_spreads: ICorporateSpread) {
        const selected_rating = this.state.selected_rating;
        const qualifying_date = fromStr(SessionStore.get(EParameters.DateParameter)).setToEoM();
        const ret = [];
        this.regressionData = [];
        this.regressionData_all = [];
        this.m_data_all = (new Array(xTicksNew.length)).fill({n: NaN, m: NaN});
        this.m_data = (new Array(xTicksNew.length)).fill({n: NaN, m: NaN});
        if (!this.data) {
            return ret;
        }
        if (!this.data.bond_data) {
            return ret;
        }
        if(!all_spreads){
            return ret;
        }
        if(!sector_spreads){
            return ret;
        }
        const renderData: ISzSvgDataPoint[] = [];
        const renderAllData: ISzSvgDataPoint[] = [];
        const regressionData: ISzSvgDataPoint[] = (new Array(xTicksNew.length)).fill(0).map(()=>({} as ISzSvgDataPoint));
        const regressionAllData: ISzSvgDataPoint[] = (new Array(xTicksNew.length)).fill(0).map(()=>({} as ISzSvgDataPoint));
        // const xy = (item, field) => ({x: field.month, y: item[field.field] / 100 });
        const bond_data = this.data.bond_data as IBondTimeSeries[];

        bond_data.forEach( (i) => {
            if(i.ftid_spread < 0){
                return;
            }
            if(i.ftid_spread > 2000){
                return;
            }
            if(Ratings.moodys_to_sp[i.rating] !== selected_rating){
                return;
            }
            const mat_date = fromDateDecimal(i.mat_date);
            const x = mat_date.diff(qualifying_date).months;
            if((x > 30 * 12) || (x < 6)){
                return;
            }
            const y = i.ftid_spread / 100;
//
            if(sectors.includes(i.sector_id)){
                renderData.push({
                    x,y, dataSet: i
                });
                // push_to_pot(i, pots_sector, x, y);
            }
            // push_to_pot(i, pots_all, x, y);
            renderAllData.push({
                x,y, dataSet: i
            });
        } );
        // console.error(selected_rating, renderData);
        regressionData.forEach((pot, idx)=>{
            const me = sector_spreads[fields[idx]] / 100;
            const y = isNaN(me) ? undefined : me;
            const x = xTicksNew[idx];
            if(y>this.max_y){
                this.max_y = y;
            }

            const use = regressionData[idx -1];
            if(use){
                const dx = x - xTicksNew[idx -1];
                const dy = y - use.y;
                const m = dy / dx;
                // console.error(regressionData[idx -1].y, y);
                this.m_data[idx - 1] =  {m, n: y - (m*x)}   ;
            }
            regressionData[idx] = {x,y, isLineData: 2} as any;
        });

        regressionAllData.forEach((pot, idx)=>{
            const me = all_spreads[fields[idx]] / 100;
            const y = isNaN(me) ? undefined : me;
            const x = xTicksNew[idx];
            if(y>this.max_y_all){
                this.max_y_all = y;
            }
            const use = regressionAllData[idx - 1]
            if(use){
                const dx = xTicksNew[idx] - xTicksNew[idx -1];
                const dy = y - use.y;
                const m = dy / dx;
                this.m_data_all[idx - 1] =  {m, n: y - (m*x)}  ;
            }

            regressionAllData[idx] = {x,y, isLineData: 1} as any;
        });
        // console.error(this.m_data);
        // console.error(regressionData);
        ret.push(renderData);
        ret.push(renderAllData);
        // ret.push(regressionData.sort((pa,pb) => pa.x - pb.x));
        this.regressionData = regressionData; // .sort((pa,pb) => pa.x - pb.x);
        this.regressionData_all = regressionAllData; // .sort((pa,pb) => pa.x - pb.x);
        return ret;
    }
    private getSectorSpreadData() {
        const selected_sector: string[] = getSectors();
        const dt = SessionStore.get(EParameters.CreditSpreadRuntimeParameter);
        const range = dt.split(",");
        const s = parseInt(range[0], 10);
        const start = s<12 ? 12 : s;
        const stop = parseInt(range[1], 10);
        // console.error(start, stop);
        let sum = 0;
        let cc=0;

        const use_slopes = selected_sector.includes("0") ? this.m_data_all : this.m_data;
        for(let pot=0; pot<=Math.trunc( (stop - start) / 12 ); pot++){
            const pot_index =  get_slope_idx(start + (pot * 12), pot === 0);
            if(pot_index === undefined){
                sum = NaN;
                break;
            }
            const n_m = use_slopes[pot_index];
            const fx = (x)=> n_m.n + n_m.m * x;
            // console.error(start + (pot * 12), pot*12 , pot_index, fx(start + (pot * 12)), n_m);
            sum += (fx(start + (pot * 12)));
            cc++;
        }
        if(stop - start >  0 && cc === 1){
            sum = NaN;
        }
        const value = cc > 0 ? sum/cc : NaN;

        return [
            {x: start, y: value, isSpreadValue: 1},
            {x: stop, y: value, isSpreadValue: 2},
        ];

    }
    private renderCorporateSpread(){
        const selected_rating = this.state.selected_rating;
        const date_decimal = deadline(1).asDateDecimal().toString();
        const spread_time_line: ICorporateSpread[] = this.data.spread_time_line;

        const sector = this.getParameterValue(EParameters.BondSectorParameter);
        const sectors: string[] = getSectors();

        const selected_time_line_all: ICorporateSpread = spread_time_line
            .filter((i, idx)=> i.sector==="0" && i.date_decimal === date_decimal)
            .find((i)=> i.rating === selected_rating);
        const data_sets: ICorporateSpread[] = this.grouped_time_line[selected_rating];
        const selected_time_line_sector: ICorporateSpread = Object.assign({}, {rating: selected_rating}) as ICorporateSpread;
        if(!data_sets){
            fields.forEach((field)=>{
                selected_time_line_sector[field] = NaN;
            });
        }else{
            fields.forEach((field)=>{
                const ff = [];
                data_sets.forEach((data_set)=>ff.push(data_set[field]));
                selected_time_line_sector[field] = median(ff);
            });
        }

        // console.error(selected_time_line_all);
        // console.error(selected_time_line_sector);

        // const bond_data: IBondTimeSeries[] = this.data.bond_data;
        // console.error(time_line);
        // console.error(bond_data);

        const [renderData, renderData_all] = this.getSeriesNew(sectors, selected_time_line_all, selected_time_line_sector);
        // console.error(renderData, renderData_all);
        // console.error(this.regressionData, this.regressionData_all);

        const sector_label = this.getSelectedSector();

        // https://www.chartjs.org/docs/latest/axes/labelling.html
        const data = sectors.includes("0") ? renderData_all : renderData;
        // console.error(data);
        let num_companies = 0;
        let num_bonds = 0;
        const uq_companies = {};
        if(Array.isArray(data) && data.length){
            num_bonds = data.length;
            data.forEach((d)=>{
                if(d.dataSet){
                    if(!uq_companies[d.dataSet.fi_name_issr]){
                        uq_companies[d.dataSet.fi_name_issr] = 1;
                        num_companies++;
                    }
                }
            });
        }
        const chart_data = {
            datasets: [
                {
                    label: _t(ETranslation.bonds),
                    data,
                    datalabels: {
                        labels: {
                            title: null
                        }
                    }
                },
            ]
        };
        if(Array.isArray(this.regressionData_all)){
            chart_data.datasets.push({
                type: 'line',
                label: 'Rating-Line',
                data: this.regressionData_all,
                backgroundColor: "#632819",
                borderColor: "#632819",
                borderDash: [7,2,2,2],
                borderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 3,
                order: -1,
                datalabels: {
                    labels: {
                        title: null
                    }
                }
            } as any);
        }
        if(Array.isArray(this.regressionData) && sector){
            chart_data.datasets.push({
                type: 'line',
                label: 'Rating-Line',
                data: this.regressionData,
                backgroundColor: "#137cbd",
                borderColor: "#137cbd",
                borderDash: [7,2,2,2],
                borderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 3,
                order: -2,
                datalabels: {
                    labels: {
                        title: null
                    }
                }
            } as any);
        }
        const spread_value = this.getSectorSpreadData();
        // console.error(spread_value);
        // https://chartjs-plugin-datalabels.netlify.app/guide/positioning.html#clamping
        if(spread_value && Array.isArray(spread_value) && spread_value.length && !isNaN(spread_value[0].y)){
            chart_data.datasets.push({
                order: -1000,
                type: spread_value[0].x === spread_value[1].x ? "bubble" : "line",
                label: 'Branchen-Kreditaufschlag',
                data: spread_value,
                backgroundColor: "#f47a22",
                borderColor: "#f47a22",
                borderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 3,
                datalabels: {
                    align: "-30",
                    anchor: "end",
                    offset: 5,
                    backgroundColor: "rgba(255,255,255,0.8)",
                    /*borderColor: "rgba(185,185,185,0.5)",
                    borderWidth: 1,
                    padding: 2,
                    */
                    formatter: (value, context, a)=>{
                        if(value.isSpreadValue===1){
                            return `${sector_label}: ${Globals.formatter(value.y)}`;
                        }
                        return null;
                    },
                    labels: {
                        title:{
                            color: "#f47a22",
                        }
                    }
                }
            } as any);
        }
        const get_tooltip_label = (item)=> {
            if(item.raw.isLineData === 1){
                return `${_t(ETranslation.sector_all)} ${Globals.formatter(item.raw.y)}`;
            }
            if(item.raw.isLineData){
                return `${sector_label} ${Globals.formatter(item.raw.y)}`;
            }
            if(item.raw.isSpreadValue){
                return undefined;
            }

            const d = item.raw.dataSet;
            if(!d){
                return undefined;
            }
            return `${d.id} ${d.currency_iso_3} ${d.rating}`;
        };

        // console.error(chart_data);
        return (
            <div className={"sz-row"}>
                <div style={{marginBottom: 20,fontSize: "80%"}}>
                    <strong>Branchen-Kreditaufschlag{Globals.superscript(1)}˒{Globals.superscript(3)} [% p.a.]</strong>
                    <br/>
                    Rating {selected_rating} | {this.getSelectedSector()}
                </div>
                <div style={{width: "100%", height: 250}}>
                    <SzBondChart chart_data={chart_data} get_tooltip_label={get_tooltip_label} />
                </div>
            </div>
        );
    }
}
