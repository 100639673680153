import * as  sector_struct from "../resources/sector_struct.json";
import * as  sectors from "../translations/sectors.json";
import * as  sub_sectors from "../translations/sub_sectors.json";
import {IOptionEntry} from "../models/IOptionEntry";
import {ISector, ISectorDesc} from "../models/ISectorDesc";
import {getLocale} from "../tools/Translator";

const locale = getLocale(); // navigator.language.split("-")[0];
const lang = sectors[0][locale] ? locale : "en";
export class Sectors {
    public static values: IOptionEntry[] = sectors.map((s)=>({label: s[lang], value: `${s.code}`}));
    public static sectors_map = {};
    public static sectors_sub_sectors_map = {};
    public static SubSectors: IOptionEntry[] = sub_sectors.map((s)=>({label: s[lang], value: `${s.code}`}));
    public static sectorsAndSubsectors: ISectorDesc[] =[];
    public static sectors: ISector[] = [];
    public static init(){
        const _sectors = {};
        const _sub_sectors = {};
        sectors.forEach((s)=>{
            Sectors.sectors_map[`${s.code}`] = s[lang];
            _sectors[`${s.code}`] = s[lang];
        });
        sub_sectors.forEach((s)=>{
            Sectors.sectors_map[`${s.code}`] = s[lang];
            _sub_sectors[`${s.code}`] = s[lang];
        });
        let lastSect;
        let lastSectDef: ISectorDesc;
        let last_i_sector: ISector;
        sector_struct.forEach((s)=> {
            if(s.p===1){
                lastSect = [];
                lastSectDef = {
                    sector: Sectors.sectors_map[`${s.code}`],
                    subSectors: [],
                };
                last_i_sector = {
                    code: s.code,
                    sub_sectors: [],
                    name: Sectors.sectors_map[`${s.code}`],
                };
                Sectors.sectorsAndSubsectors.push(lastSectDef)
                Sectors.sectors_sub_sectors_map[s.code] = lastSect;
                Sectors.sectors.push(last_i_sector);
            }
            if(s.p===2 && last_i_sector){
                last_i_sector.sub_sectors.push({
                    name: Sectors.sectors_map[`${s.code}`],
                    code: s.code,
                });
            }
            if(s.p===2 && lastSectDef){
                lastSectDef.subSectors.push(Sectors.sectors_map[`${s.code}`]);
            }
            if(s.p===2 && Array.isArray(lastSect)){
                lastSect.push(s.code);
            }
        });
    }
}
