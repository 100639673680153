import {DateTime, Duration} from "luxon";
import {Globals} from "../../../const/Globals";
import {SessionStore} from "../../../const/SessionStore";
import {find_object} from "../../../helpers/Helpers";
import {Gearing, IUnleveredBeta} from "../../../models/chef/UnleveredBeta";
import {EParameters} from "../../../models/EParameters";
import {IFinancialAnnualReported} from "../../../models/tables/FinancialAnnualReported";
import {fromDateDecimal, fromStr} from "../../../tools/DateTools";
import {SzTableHelper} from "../../widgets/helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties, ISzTagOptions} from "../../widgets/SzTable";
import {ITableDetailsParams} from "./TableDetailsParams";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const notes = [
    "Rohdaten: Factset Inc.",
    "¹) Lineare Interpolation; für erfolgs- oder zahlungswirksame Größen",
];
const fields: string[] =
    ("weighting,country_tax_rate,ff_inc_tax,ff_ptx_inc,tax_shield_sicher_saw,ff_tax_cf," +
    "ff_ptx_inc,tax_shield_sicher_sza").split(",");
const negativ_fields = "".split(",");
const labels = {
    weighting: "Gewichtung¹",
    ff_inc_tax: "Steueraufwendungen",
    ff_ptx_inc: "Ergebnis vor Steuern",
    tax_shield_sicher_saw: "Tax-Shield [Effektivsteuersatz auf Basis Steueraufwand]",
    ff_tax_cf: "Steuerzahlung",
    tax_shield_sicher_sza: "Tax-Shield [Effektivsteuersatz auf Basis Steuerzahlung]",
    country_tax_rate: "Tax-Shield [gesetzlicher Steuersatz]",
};
const bg_rows = {
    "sicher": 1,
    "sicher-steueraufwand": 4,
    "sicher-steuerzahlung": 7,
};
export const getTable = (params: ITableDetailsParams): ISzTableProperties<any[]> => {

    const tax_shield = SessionStore.get(EParameters.TaxshieldParameter);
    const bg_row = bg_rows[tax_shield] ? bg_rows[tax_shield] : -1;

    const deadline: DateTime = fromStr(params.deadline, "dd.LL.yyyy").endOf("month");

    const unlevered_beta: IUnleveredBeta = params.unlevered_beta_map[params.period];
    const unlevered_beta_5y: IUnleveredBeta = params.unlevered_beta_map[params.period];
    if(!unlevered_beta_5y){
        return undefined;
    }
    const latest_report: IFinancialAnnualReported = find_object(params.company_reports,(date_decimal, o: IFinancialAnnualReported)=>{
        const report_date = fromDateDecimal(o.date_decimal);
        const months: Duration = deadline.diff(report_date, "months");
        return months.months < 13;
    });
    if(!latest_report){
        return undefined;
    }

    const d: DateTime = fromDateDecimal(latest_report?.date_decimal);
    const header_0: ISzTableColumn[] = [
        { text: `Mio. ${latest_report?.currency_iso}`},
    ];
    const header_1: ISzTableColumn[] = [
        { text: `${d.toFormat("dd.LL.")}`},
    ];
    [4, 3, 2, 1, 0].forEach((y)=>{
        header_0.push({ text: `${d.minus({year: y}).toFormat("yyyy")}`, options: {className: "sz-right"} });
        header_1.push({ text: `Ist`, options: {className: "sz-right"} });
    });
    // werte spalte
    header_0.push({ text: `${params.show_result === 0 ? Globals.average : _t(ETranslation.valuation_date)}`, options: {className: "sz-right"} });
    header_1.push({ text: `${params.show_result === 0 ? `${params.years} ${_t(ETranslation.years)}` : d.toFormat("dd.LL.yyyy")}`, options: {className: "sz-right"} });

    const columns: ISzTableColumn[] = [
        { ...SzTableHelper.columnIndex(0)},
        { ...SzTableHelper.columnMoney(1, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(2, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(3, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(4, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(5, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(6, 0, {style: {width: 82}})},
    ];
    const data = [];
    fields.forEach((field, idx)=>{
        const data_row = [
            labels[field]
        ];
        data.push(data_row);
        [4, 3, 2, 1, 0].forEach((year)=>{
            const dt = d.startOf("month").minus({year}).endOf("month").asDateDecimal();
            let values: Gearing = unlevered_beta.components.tax_shield[dt];
            if(!values){
                values = unlevered_beta_5y.components.tax_shield[dt]
                    ? {...unlevered_beta_5y.components.tax_shield[dt], weighting: NaN}
                    : undefined;
            }
            if(!values){
                data_row.push(NaN);
            }else{
                if(negativ_fields.includes(field) && values[field]){
                    data_row.push(values[field] * -1);
                }else{
                    data_row.push(values[field]);
                }
            }
        });
        if([0].includes(idx)){
            return data_row.push("");
        }
        const result_values = unlevered_beta.components.tax_shield[params.show_result];
        if(result_values){
            if(negativ_fields.includes(field) && result_values[field]){
                data_row.push(result_values[field] * -1);
            }else{
                data_row.push(result_values[field]);
            }

        }else{
            data_row.push("");
        }
    });
    const tableProps: ISzTableProperties<any[]> = {
        title: "Tax Shield",
        colCount: 7,
        notStriped: true,
        notes,
        header: [
            header_0,
            header_1
        ],
        columns,
        data,
        column_styles: {
            "0" : {borderRight: "#ffffff solid 4px"},
            "5" : {borderRight: "#ffffff solid 4px"},
        } as any,
        beforeRenderCell: (cellValue: any, rowNum: number, celNum: number, definition: ISzTableColumn, options: ISzTagOptions)=>{
            const class_names = options.className ? [options.className] : [];

            if(rowNum === 0 && celNum > 0 && celNum!==6){
                definition.formatter = (v)=>{
                    return Globals.formatter_percent(v, 1, 1, true);
                };
            }
            if([1, 4, 7].includes(rowNum) && celNum > 0){
                definition.formatter = (v)=>{
                    return Globals.formatter_percent(v, 1, 1, true);
                };
            }

            if([0, 1, 4].includes(rowNum) && celNum < 6){
                class_names.push("bottom-space");
            }
            if([0, 1, 4].includes(rowNum) && celNum === 6){
                class_names.push("bottom-space-gray");
            }
            if(celNum === 6){
                class_names.push("sz-bg-gray");
            }
            if(rowNum === bg_row){
                class_names.push("sz-bg-gray", "sz-bold");
            }

            options.className = class_names.join(" ");
        },
    };

    return tableProps;
}
