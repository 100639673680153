import {Icon, Popover} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import * as Barcode from "react-barcode";
import {Globals} from "../../const/Globals";
import {ISzSvgBarAnnotation, ISzSvgBarCellProperties, ISzSvgBarHighlight, SzSvgBarCell} from "./SzSvgBarCell";
import {SzSvgCellChart} from "./SzSvgCellChart";
import {IconNames} from "@blueprintjs/icons";
import {_k} from "../../helpers/Helpers";

const BASE_DIR = Globals.BASE_DIR;

export enum ESzTableColumnType {
    BarChart= "BarChart",
    CellChart= "CellChart",
}

export interface ISzTagBaseOptions {
    className?: string;
    style?: CSSProperties;
    colSpan?: number;
    rowSpan?: number;
    fractionDigits?: number;
    unitSign?: string;
    doc_auto_width?: boolean;
    onClick?();
    onMouseEnter?(e: React.MouseEvent<any, MouseEvent>);
    onMouseLeave?(e: React.MouseEvent<any, MouseEvent>);
}

export interface ISzTagOptions extends ISzTagBaseOptions {
    cellRenderer?(cellValue: any, data?: any);
}

export interface ISzTableColumn {
    options?: ISzTagOptions;
    index?: string;
    text?: string | JSX.Element;
    barchart?: ISzTableBarChartOptions;
    columnType?: ESzTableColumnType;
    columnHover?: number;
    cellHover?: number;
    numColor?: boolean;
    flag?: boolean;
    zeroAsHyphen?: boolean;
    sortable?: boolean;
    ellipsis?: boolean;
    formatter?(v: any): string;
    onClick?(rowNum, cellNum, data);
}
export interface ISzTableBarChartOptions {
    height: number;
    min: number;
    max: number;
    padding_right: number;
    classNamePos?: string;
    classNameNeg?: string;
    getAnnotation?(rowData: any): ISzSvgBarAnnotation;
    getHighlights?(rowData: any): ISzSvgBarHighlight[];
    getValue?(rowData: any): any;
}
type CssPropertiesMap = {[col_num: string]: CSSProperties};
export interface ISzTableProperties<T> {
    id?: string;
    title?: string;
    data: T[];
    footerData?: T[];
    className?: string;
    colCount: number;
    columns: ISzTableColumn[];
    column_styles?: CssPropertiesMap;
    footer?: ISzTableColumn[];
    notStriped?: boolean;
    rowHover?: boolean;
    header: ISzTableColumn[][];
    notes?: string[];
    rowIndexField?: string;
    rowIndexPrefix?: string;
    undefinedIsEmpty?: boolean;
    sortableColumns?: number[][];
    no_format?: boolean;
    bar_code?: string;
    addFooter?();
    beforeRenderRow?(rowData: T, rowNum: number, options: ISzTagOptions): void;
    beforeRenderCell?(cellValue: any, rowNum: number, celNum: number, definition: ISzTableColumn, options: ISzTagOptions): void;
    onSelectColumn?(col: number);
    onClickColumn?(col: number);
    onSelectRow?(row: number, dataRow: any);
    columnPopover?(col: number): JSX.Element;
    onLeaveCell?(e: React.MouseEvent<any, MouseEvent>, column: ISzTableColumn);
}

export class SzTable<T> extends React.Component<ISzTableProperties<T>, any> {

    private selectedColumn = 0;
    private selectedRow = 0;

    private readonly table: React.RefObject<HTMLTableElement>;

    constructor(props: ISzTableProperties<T>, context: any) {
        super(props, context);
        this.table = React.createRef();
        this.state = {};
    }
    public render() {
        const striped = this.props.notStriped ? "" : "bp3-html-table-striped";

        const on_leave = () => {
            this.selectColumn(0);
        };
// <Popover usePortal={true} content={"test"}><col /></Popover>
        return (
            <>
            <table id={this.props.id} ref={this.table} className={`sz-table bp3-html-table bp3-html-table-condensed ${striped} sz-small-padding ${this.props.className}`} onMouseLeave={ () => on_leave() } style={{width: "100%", emptyCells: "show", borderCollapse: "separate"}}>
                {this.renderHeader()}
                {this.renderBody()}
                {this.ifRenderFooter()}
            </table>
                {this.renderNotes()}
            </>
        );
    }
    public closeColumnPopover(col: number) {
        const state = {};
        state[`hoverCol_${col}`] = false;
        this.setState(state);
    }
    private ifRenderFooter() {
        if (this.props.footer && this.props.footerData) {
            return this.renderFooter();
        }
        return null;
    }
    private renderHeader() {
        const header = this.props.header;
        let cKey = 0;
        return (
            <thead>
            {header.map((headerRow, idx_row) => {
                return (
                    <tr key={"trHeader" + (cKey++)}>
                        {headerRow.map((headerCol, index) => {
                            if (!headerCol.options) {
                                headerCol.options = {};
                            }
                            if(this.props.column_styles && this.props.column_styles[index]){
                                if(headerCol.options.style){
                                    headerCol.options.style = {...headerCol.options.style, ...this.props.column_styles[index]};
                                }else{
                                    headerCol.options.style = {...this.props.column_styles[index]};
                                }
                            }
                            if(headerCol?.options?.style){
                                // headerCol.options.style.whiteSpace = "nowrap";
                            }
                            if (headerCol.columnHover) {
                                if (!headerCol.options) {
                                    headerCol.options = {};
                                }
                                headerCol.options.className = headerCol.options.className ? `${headerCol.options.className} sz-col-hover sz-col-hover-${headerCol.columnHover}` : `sz-col-hover sz-col-hover-${headerCol.columnHover}`;
                                return (
                                    <th key={"thHeader" + (cKey++)} {...headerCol.options} onMouseEnter={ () => this.selectColumn(headerCol.columnHover) }>
                                        <Popover interactionKind={"hover"} usePortal={true} fill={true} content={this.props.columnPopover(headerCol.columnHover)} isOpen={ this.state[`hoverCol_${headerCol.columnHover}`] ? true : false }>{headerCol.text}</Popover>
                                    </th>
                                );
                            }

                            if(headerCol.sortable){
                                const sortKey: string ="thHeader" + (cKey++);
                                return (<th key={"thHeader" + (cKey++)} {...headerCol.options}>{this.renderSortedHeader(sortKey, index, headerCol)}</th>)
                            }
                            return (<th key={"thHeader" + (cKey++)} {...headerCol.options}>{headerCol.text}</th>);
                        })}
                    </tr>
                );
            })}
            </thead>
        );
    }

    private renderBody() {
        if (this.props.data && !this.props.data.length) {
            return null;
        }
        let cKey = 0;
        const data = [].concat(this.props.data);

        if(this.state.sortDir){
            const sortDir = this.state.sortDir;
            const sortColumn = this.state.sortColumn;

            const columnDefinition: ISzTableColumn = this.props.columns.find( (c, idx) => idx === sortColumn);

            if(columnDefinition){
                const deCollator = new Intl.Collator("de");

                data.sort( (a, b) => {
                    const va = a[columnDefinition.index];
                    const vb = b[columnDefinition.index];
                    let v = deCollator.compare(va, vb);
                    if(!isNaN(Number(va)) && !isNaN(Number(vb))){
                        const ff_a = Number(va);
                        const ff_b = Number(vb);
                        v = (ff_a === ff_b)? 0: (ff_a > ff_b) ? 1 : -1;
                    }
                    if(vb===undefined || va===undefined){
                        v= vb===undefined && va===undefined ? 0 : vb===undefined ? 1 : -1;
                    }

                    if(sortDir === "desc"){
                        return v * -1;
                    }
                    return v;
                });
            }

        }
        /*const s = {
                sortDir: dir,
                sortColumn: col,
            };*/
        // <tr> <td colSpan={this.props.colCount} style={{height: 1}}>&nbsp;</td> </tr>
        return (
            <tbody>
            {data.map((dataRow, idx) => {
                const optionsOverride: ISzTagOptions = {};
                if (this.props.beforeRenderRow) {
                    this.props.beforeRenderRow(dataRow, idx, optionsOverride);
                }
                if ( dataRow && Array.isArray(dataRow) && dataRow.length === 0) {
                    return (
                        <tr  key={"trBody" + (cKey++)} {...optionsOverride}>
                            <td colSpan={this.props.colCount}>&nbsp;</td>
                        </tr>
                    );
                }

                if (this.props.rowHover) {
                    if (!optionsOverride.className) {
                        optionsOverride.className = "sz-row-hover";
                    } else {
                        optionsOverride.className = ["sz-row-hover", optionsOverride.className].join(" ");
                    }
                }

                if (this.props.rowIndexField && this.props.rowIndexPrefix) {
                    let v = dataRow[this.props.rowIndexField];
                    if (Number.isFinite(parseInt(this.props.rowIndexField, 10))) {
                        v = dataRow[parseInt(this.props.rowIndexField, 10)];
                    }
                    optionsOverride.className = [`${this.props.rowIndexPrefix}_${v}`, optionsOverride.className].join(" ");
                }

                return (
                    <tr  key={"trBody" + (cKey++)} {...optionsOverride}>
                        {this.renderCells(this.props.columns, dataRow, idx)}
                    </tr>
                );
            })}
            </tbody>
        );
    }
    private renderFooter() {
        let cKey = 0;
        const addFooter = () => {
            if (this.props.addFooter) {
                return this.props.addFooter();
            }
            return null;
        };
        return (
            <tfoot>
            {this.props.footerData.map((dataRow, idx) => {
                const optionsOverride: ISzTagOptions = { };
                if (this.props.beforeRenderRow) {
                    // this.props.beforeRenderRow(dataRow, idx, optionsOverride);
                }

                return (
                    <tr key={"trFooter" + (cKey++)}  {...optionsOverride}>
                        {this.renderCells(this.props.footer, dataRow, idx, true)}
                    </tr>
                );
            })}
            {addFooter()}
            </tfoot>
        );
    }
    private resolveColumnConst(constDef: string, dataRow: T, column: ISzTableColumn, rowNum: number, colIndex: number) {
        if (constDef === "$row_num") {
            return "" + (rowNum + 1);
        }
        if (constDef === "$const") {
            return column.text;
        }
        return constDef;
    }
    private renderCells(withColumns: ISzTableColumn[], data: T, rowNum: number, isFooter?: boolean) {
        const dataRow: any[] | T = data;
        let cKey = 0;
        const renderCode= (colIndex)=>{
            if(!this.props.bar_code){
                return;
            }
            if(rowNum!==1 || isFooter || colIndex!==1){
                return;
            }
            return (
                <div style={{position: "relative"}}>
                    <div style={{position: "absolute", zIndex: 10, top: -8}}>
                        <Barcode value={this.props.bar_code} height={6} margin={0} lineColor={"rgba(16,22,26,0.1)"} background={"rgba(0,0,0,0)"} displayValue={false}/>
                    </div>
                </div>
            );
        };
        const cells = withColumns.map((columnOrg, colIndex) => {
            const column: ISzTableColumn = {...columnOrg};
            if(this.props.column_styles && this.props.column_styles[colIndex]){
                if (!column.options) {
                    column.options = {};
                }
                if(column.options.style){
                    column.options.style = {...column.options.style, ...this.props.column_styles[colIndex]};
                }else{
                    column.options.style = {...this.props.column_styles[colIndex]};
                }
            }
            let v;
            if (typeof column.index === "string") {
                v = dataRow[column.index];
            }
            if (column.index && ("" + column.index).startsWith("$")) {
                v = this.resolveColumnConst("" + column.index, data, column, rowNum, colIndex);
            }
            if (Number.isFinite(parseInt(column.index, 10)) && colIndex === parseInt(column.index, 10)) {
                v = dataRow[column.index];
            }
            if (column.columnHover) {
                if (!column.options) {
                    column.options = {};
                }
                column.options.onMouseEnter = (e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
                    this.selectColumn(column.columnHover);
                };
                column.options.onClick = (): any => {
                    this.clickColumn(column.columnHover);
                };
                if (column.options.className && (column.options.className.indexOf(`sz-col-hover-${column.columnHover}`) === -1)) {
                    column.options.className = column.options.className ? `${column.options.className} sz-col-hover sz-col-hover-${column.columnHover}` : `sz-col-hover sz-col-hover-${column.columnHover}`;
                }
            }
            if (column.cellHover) {
                if (!column.options) {
                    column.options = {};
                }
                if (column.options.className && (column.options.className.indexOf(`sz-cell-hover-${column.cellHover}`) === -1)) {
                    column.options.className = column.options.className ? `${column.options.className} sz-cell-hover sz-cell-hover-${column.cellHover}` : `sz-cell-hover sz-cell-hover-${column.cellHover}`;
                }
            }
            if (this.props.rowHover && !isFooter) {
                if (!column.options) {
                    column.options = {};
                }
                column.options.onClick = () => {
                    this.clickRow(rowNum, dataRow);
                };
            }
            if (column.onClick) {
                if (!column.options) {
                    column.options = {};
                }
                column.options.onClick = () => {
                    if (column.onClick) {
                        column.onClick(rowNum, colIndex, data);
                    }
                };
            }
            const optionsOverride: ISzTagOptions = { ...column.options};
            if (this.props.beforeRenderCell && !isFooter) {
                this.props.beforeRenderCell(v, rowNum, colIndex, column, optionsOverride);
            }
            const hyphen = v === "n.m."  ? "n.m." : "—";
            const cellRenderer = optionsOverride && optionsOverride.cellRenderer ? optionsOverride.cellRenderer : (a,b) => this.defaultCellRenderer(column, a, b);
            if (cellRenderer) {
                delete optionsOverride.cellRenderer;
            }
            delete optionsOverride.fractionDigits;
            let n = Number.parseFloat(v);

            if (column.zeroAsHyphen && n === 0) {
                n = NaN;
            }

            if (column.columnType && column.columnType === ESzTableColumnType.CellChart) {

                const annotation = column.barchart && column.barchart.getAnnotation ? column.barchart.getAnnotation(dataRow) : null;
                const highlights = column.barchart && column.barchart.getHighlights ? column.barchart.getHighlights(dataRow) : null;
                const override_value =  column.barchart && column.barchart.getValue ? column.barchart.getValue(dataRow) : null;
                const options: ISzSvgBarCellProperties = {
                    classNamePos: column.barchart.classNamePos,
                    classNameNeg: column.barchart.classNameNeg,
                    value: n,
                    min: column.barchart.min,
                    max: column.barchart.max,
                    formatter: column.formatter,
                    height: column.barchart.height,
                    padding_right: column.barchart.padding_right,
                    annotation,
                    highlights,
                    override_value,
                };
                return (
                    <td key={"tdColumn" + rowNum + (cKey++)} {...optionsOverride}>
                        <SzSvgCellChart {...options} />
                    </td>
                );
            }
            if (v === undefined && this.props.undefinedIsEmpty){
                return (<td key={"tdColumn" + rowNum + (cKey++)} {...optionsOverride}>&nbsp;</td>);
            }
            if (n === undefined || (column.formatter && isNaN(n))) {
                return (<td key={"tdColumn" + rowNum + (cKey++)} {...optionsOverride}>{column.barchart ? "" : hyphen}</td>);
            }

            if (column.numColor && column.formatter) {
                const className = v < 0 ? "sz-negative" : "sz-positive";
                return (<td  key={"tdColumn" + rowNum + (cKey++)} {...optionsOverride}><span className={className}>{cellRenderer(column.formatter(v), dataRow)}</span></td>);
            }

            if (column.flag) {
                return(<td  key={"tdColumn" + rowNum + (cKey++)} className={"search-result"}><img src={`${BASE_DIR}images/flags/${v}.jpg`}/></td>);
            }

            if (column.barchart && column.formatter) {

                const annotation = column.barchart && column.barchart.getAnnotation ? column.barchart.getAnnotation(dataRow) : null;
                const options: ISzSvgBarCellProperties = {
                    classNamePos: column.barchart.classNamePos,
                    classNameNeg: column.barchart.classNameNeg,
                    value: n,
                    min: column.barchart.min,
                    max: column.barchart.max,
                    formatter: column.formatter,
                    height: column.barchart.height,
                    padding_right: column.barchart.padding_right,
                    annotation,
                };
                return (
                    <td  key={"tdColumn" + rowNum + (cKey++)} {...optionsOverride}>
                        <SzSvgBarCell {...options} />
                    </td>
                );
            }

            if (column.formatter) {
                return (<td  key={"tdColumn" + rowNum + (cKey++)} {...optionsOverride}>{cellRenderer(column.formatter(v), dataRow)}</td>);
            }

            return (
                <td  key={"tdColumn" + rowNum + (cKey++)} {...optionsOverride}>
                    {cellRenderer(v, dataRow)}
                    {renderCode(colIndex)}
                </td>
            );
        });
        // .map( (c) =>  <Popover className={"teste_"} wrapperTagName={"td"} usePortal={true} content={"test"} interactionKind={"hover-target"}>{c}</Popover>)
        return cells;
    }
    private toggle_hover_class(hover_type, colIndex, set: boolean) {
        const items = this.table.current.querySelectorAll( `.sz-${hover_type}-hover-${colIndex}` );
        const hover = `hover-${hover_type}`;
        if (set) {
            items.forEach( (c) => c.classList.add(hover) );
        } else {
            items.forEach( (c) => c.classList.remove(hover) );
        }
    }
    private toggle_col_hover_class(colIndex, set: boolean) {
        this.toggle_hover_class("col", colIndex, set);
    }
    private toggle_row_hover_class(rowIndex, set: boolean) {
        this.toggle_hover_class("row", rowIndex, set);
    }
    private selectColumn(selectedColumn: number) {
        if (this.selectedColumn !== selectedColumn) {
            this.toggle_col_hover_class(this.selectedColumn, false);
            this.toggle_col_hover_class(selectedColumn, true);
            const state = {};
            state[`hoverCol_${this.selectedColumn}`] = false;
            state[`hoverCol_${selectedColumn}`] = true;
            this.setState(state);

            if (this.props.onSelectColumn) {
                this.props.onSelectColumn(selectedColumn);
            }
        }
        this.selectedColumn = selectedColumn;
    }
    private clickColumn(column){
        if (this.props.onClickColumn) {
            this.props.onClickColumn(column);
        }
    }
    private clickRow(selectedRow: number, dataRow: any) {
        if (this.props.onSelectRow) {
            this.props.onSelectRow(selectedRow, dataRow);
        }
    }

    private renderNotes() {
        if (!this.props.notes) {
            return null;
        }

        return (
            <>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <div style={{maxWidth: 1200}}>
                    {this.props.notes.map( (n, idx) => (<p key={_k("renderNotes", idx)} className="bp3-text-small bp3-text-muted">{n}</p>) )}
            </div>
            </>
        );
    }

    private renderSortedHeader(columnKey: string, headerColIdx: number, headerCol: ISzTableColumn) {
        const sortColumn = this.state.sortColumn;
        const getIcon = () => {
            if(sortColumn !== headerColIdx){
                return IconNames.DOUBLE_CARET_VERTICAL;
            }
            if(this.state.sortDir==="asc" && sortColumn === headerColIdx){
                return IconNames.CARET_UP;
            }
            if(this.state.sortDir==="desc" && sortColumn === headerColIdx){
                return IconNames.CARET_DOWN;
            }
            return IconNames.DOUBLE_CARET_VERTICAL;
        };
        const setSort = () => {
            let dir = this.state.sortDir;
            let col = headerColIdx;

            switch (dir) {
                case "asc": {dir = "desc"; break;}
                case "desc": {dir = undefined; col= undefined; break;}
                default: dir = "asc";
            }
            const s = {
                sortDir: dir,
                sortColumn: col,
            };
            this.setState(s);
        };
        return (
            <div onClick={ () => setSort()} style={{width: "100%", display: "flex", cursor: "default", userSelect: "none"}}><span>{headerCol.text}</span><Icon icon={getIcon()} /></div>
        );
    }

    private defaultCellRenderer(column: ISzTableColumn, v, dataRow) {
        if(column.ellipsis){
            return (
                <div className="td-wrapper" style={{cursor: "inherit"}} title={v}><span
                    className="td-content" style={{cursor: "inherit"}} >{v}</span>
                </div>
            );
        }
        return (v);
    }
}
