import * as React from "react";
import {
    Button,
    Classes,
    Dialog,
    FormGroup,
     HTMLTable,
    InputGroup,
    Intent,
    TextArea,
} from "@blueprintjs/core";

import {DialogProps} from "@blueprintjs/core/lib/esm/components/dialog/dialog";
import { ServiceCalls } from "../../services/ServiceCalls";
import {
    IProject,
} from "../../models/IProject";
import { useState } from "react";

import {IconNames} from "@blueprintjs/icons";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {useNavigate} from "react-router-dom";
interface IEditProjectProps {
    isOpen: boolean;
    setIsOpen: (object: any) => void;
    project : IProject,
    isDuplicate: boolean,
    projects: IProject[];
}

const EditProjectDialog: React.FC<IEditProjectProps> = ({
                                                            isOpen,
                                                            setIsOpen,
                                                            project,
                                                            isDuplicate,
                                                            projects,
                                                      }) => {
    if(!project){
        return null;
    }
    const navigate = useNavigate();
    // Dialog Data
    const [dialogData, setDialogData] = useState({
        _id: project._id,
        ident:project.ident,
        name:project.name,
        description : project.description,
    });
    const existingProject = projects.find(
        (p: IProject) =>
            p.name.toLowerCase() === dialogData.name.toLowerCase()
    );
    // handle Dialog Data Changes
    const handleChange = (evt: any) => {
        const name = evt.target.name;
        const value = evt.target.value;
        setDialogData({
            ...dialogData,
            [name]: value,
        });
    };
    const saveEdit = ()=>{
        const cb = new ServiceCalls();
        ( async () => {
            if(!isDuplicate){
                const response = await cb.updateProject(dialogData);
            }else{
                const response = await cb.updateProject({...dialogData, project_type: project.project_type, duplicate: isDuplicate} as IProject);
                if(response.project){
                    navigate(`/project/${response.project._id}`);
                }
            }
            // handle Error
        } )();
    };
    const dialog: DialogProps = {
        usePortal: true,
        isOpen,
        title: _t(ETranslation.edit_project),
        icon: IconNames.COG,
        onClose: (e) => {
            e.stopPropagation();
            setIsOpen({isOpen: false})
        },
    };
    // console.error(dialogData);
    return (
        <div  >
            <Dialog {...dialog}>
                <div className={Classes.DIALOG_BODY} onClick={(e)=> e.stopPropagation()}>
                    <HTMLTable className={`sz-table sz-prj-info-table`} condensed={true} style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <td style={{boxShadow: "none", width: 160, verticalAlign: "middle"}}><strong>{_t(ETranslation.project_name)}</strong></td>
                            <td style={{boxShadow: "none", paddingLeft: 5}} colSpan={2}>
                                <FormGroup
                                    label={_t(ETranslation.project_name)}
                                    labelFor="idPrjName"
                                    intent={isDuplicate && existingProject ? Intent.DANGER : undefined}
                                    helperText={
                                        isDuplicate && existingProject
                                            ? `Projektname ist vergeben, geben Sie bitte einen anderen ein.`
                                            : isDuplicate ? "Pflichtfeld" : ""
                                    }

                                >
                                    <InputGroup
                                        disabled={!isDuplicate}
                                        placeholder={_t(ETranslation.project_name)}
                                        name="name" value={dialogData.name}
                                        fill={true}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </td>
                        </tr>
                        <tr>
                            <td style={{boxShadow: "none", paddingBottom: 16, width: 160, verticalAlign: "middle"}}><strong>{_t(ETranslation.project_ident)}</strong></td>
                            <td style={{boxShadow: "none", paddingBottom: 16, paddingLeft: 5}} colSpan={2}>
                                <InputGroup placeholder={_t(ETranslation.project_ident)} name="ident" value={dialogData.ident}  fill={true} onChange={handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{boxShadow: "none", paddingBottom: 16, width: 160, lineHeight: "30px"}}><strong>{_t(ETranslation.project_desc)}</strong></td>
                            <td style={{boxShadow: "none", paddingBottom: 16, paddingLeft: 5, height: 150}} colSpan={3}>
                                <TextArea  fill={true} rows={5} placeholder={_t(ETranslation.project_desc)} style={{height: 225}} name="description" value={dialogData.description} onChange={handleChange} />
                            </td>
                        </tr>
                        </tbody>
                    </HTMLTable>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button intent={Intent.NONE} minimal={true} text={_t(ETranslation.cancel)} onClick={(e: any) =>  {e.stopPropagation(); setIsOpen({isOpen: false})}} />
                        &nbsp;&nbsp;
                        <Button
                            disabled={!dialogData.name || existingProject ? true : false}
                            intent={Intent.PRIMARY}
                            text={_t(ETranslation.save)}
                            onClick={()=>{ saveEdit();  setIsOpen({isOpen: false}) } } />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default EditProjectDialog;
