import * as React from "react";
import {Plugin,} from 'chart.js';
import {Regression} from "../helpers/Regression";
import {Globals} from "../const/Globals";

class RegressionLineClass{
    public id = "regression_line";
    public render_vertical_x(chart, args, options){
        // console.error(chart, args, options);
        const regression_line = chart.options.regression_line;
        const v_x = isNaN(regression_line.vertical_x) ? undefined : regression_line.vertical_x;
        const v_label = regression_line.vertical_label ? regression_line.vertical_label : undefined;
        const vertical_x_color = regression_line.vertical_x_color ? regression_line.vertical_x_color : "#0800ff";

        const start = chart.scales.x.start;
        const min_x = chart.scales.x.min;
        const max_x = chart.scales.x.max;
        const left = chart.scales.x.left;

        if(v_x < min_x || v_x > max_x){
            return;
        }

        const min_y = chart.scales.y.min;
        const max_y = chart.scales.y.max;
        const top = chart.scales.y.top;
        const height = chart.scales.y.height;
        const width = chart.scales.x.width;

        const y_factor = height / (max_y - min_y);
        const x_factor = width / (max_x - min_x);

        const context: CanvasRenderingContext2D = chart.ctx;
        // console.error(v_x, chart);
        context.save();
        if(v_x !== undefined){
            // const p = v_x * .1;
            const x3 = left + (min_x * x_factor * -1) + x_factor*v_x;
            // const x_p =  left + (Math.abs(min_x)*x_factor) + x_factor * (v_x - p);
            // context.fillStyle = "rgba(0, 0, 0, 0.3)";
            // context.fillRect(x_p,top,(x3 - x_p) * 2,height);
            // console.error(v_x, x3, y3);
            context.beginPath();
            context.strokeStyle = vertical_x_color;
            context.fillStyle = vertical_x_color;
            context.moveTo(x3, top);
            context.lineTo(x3, top + height);
            context.textBaseline = "hanging";
            context.fillText(v_label ? v_label : Globals.formatter(v_x), x3 + 5, top + 30);
            context.stroke();
        }

        context.setLineDash([]);
        context.strokeStyle = "#485d63";
        context.restore();
    }
    public render_regression_line(chart, args, options){
        const regression_line = chart.options.regression_line;
        const regression: Regression = regression_line?.regression;
        if(!regression){
            return;
        }

        const color= regression_line.color ? regression_line.color : "#0800ff";
        const lineDash = regression_line.lineDash ? regression_line.lineDash : undefined;
        const fx = (x) => ((regression.Intercept * 100) + regression.Slope * x);

        const min_x = chart.scales.x.min;
        const max_x = chart.scales.x.max;
        const left = chart.scales.x.left;
        const right = chart.scales.x.right;
        const min_y = chart.scales.y.min;
        const max_y = chart.scales.y.max;
        const top = chart.scales.y.top;
        const height = chart.scales.y.height;

        const y_factor = height / (max_y - min_y);

        const y1 = (top + height) - fx(min_x)*y_factor;
        const y2 = (top + height) - fx(max_x)*y_factor;

        const context: CanvasRenderingContext2D = chart.ctx;
        context.save();
        context.beginPath();
        if(lineDash){
            context.setLineDash(lineDash);
        }
        context.strokeStyle = color;
        context.moveTo(left, y1);
        context.lineTo(right, y2);
        context.stroke();

        context.setLineDash([]);
        context.strokeStyle = "#485d63";
        context.restore();
    }
    public afterDatasetsDraw(chart, args, options){
        if(!chart.options.regression_line){
            return true;
        }
        if(!chart.options.regression_line.display){
            return true;
        }
        if(chart.options.regression_line.display === "vertical_x"){
            this.render_vertical_x(chart, args, options);
            return true;
        }
        if(chart.options.regression_line.display === "regression_line"){
            this.render_regression_line(chart, args, options);
            return true;
        }

        this.render_regression_line(chart, args, options);
        this.render_vertical_x(chart, args, options);

        return true;
    }
    public render_bar_part(chart, args, options){
        if(!(chart._metasets && Array.isArray(chart._metasets) && chart._metasets.length)){
            return true;
        }
        const y_range = chart.options.regression_line.y_range;
        const meta = chart._metasets[0];
        if(meta && meta.type === "bar"){
            // console.error(meta);
            const till = y_range.till;
            const from = y_range.from;
            const bar = meta.data[Math.trunc(till)];
            const bar_2 = meta.data[Math.trunc(from)];
            if(!bar){
                return true;
            }else{

                const top = chart.scales.y.top;
                const left = chart.scales.x.left;

                const old_height = bar.height;
                const new_height = old_height * (till - Math.trunc(till));
                const old_y = bar.y;
                const new_y = bar.y + old_height - bar.height;

                const context: CanvasRenderingContext2D = chart.ctx;
                context.save();
                // context.fillStyle = "rgba(119, 203, 255, 0.13)";

                const y = bar.y + top - old_height;
                const hg = old_height - new_height;

                context.fillStyle = "rgba(0, 0, 0, 0.1)";
                context.fillRect(left, y, bar.width, hg);

                context.fillStyle = "rgba(119, 203, 255, 0.13)";
                context.fillRect(left, y + hg , bar.width, new_height);
                // context.fillStyle = "rgba(0, 0, 0, 0.2)";
                // context.fillRect(bar.x, bar.y  new_height, bar.width, new_height);
                context.restore();
                // console.error(old_y, old_height);
                // console.error(bar.y, bar.height, Math.trunc(till), till);
            }
        }
        return true;
    }
    public beforeDatasetsDraw(chart, args, options){
        if(!chart.options.regression_line){
            return true;
        }
        /*
        if(!Array.isArray(chart.options.regression_line.slots)){
            return true;
        }
        if(!Array.isArray(chart.options.regression_line.slots_options)){
            return true;
        }
        */
        if(!chart.options.regression_line.x_range){
            return true;
        }
        if(!chart.options.regression_line.y_range){
            return true;
        }
        const x_range = chart.options.regression_line.x_range;
        const y_range = chart.options.regression_line.y_range;
        /*
        if(!chart.options.regression_line.band_width_range){
            return true;
        }
        const band_width_range = chart.options.regression_line.band_width_range;
        */
        // console.error(chart, args, options);
        const v_x = isNaN(chart.options.regression_line.vertical_x) ? undefined : chart.options.regression_line.vertical_x;
        const min_x = chart.scales.x.min;
        const max_x = chart.scales.x.max;
        const min_y = chart.options.regression_line.y_min !== undefined ? chart.options.regression_line.y_min : chart.scales.y.min;
        const max_y = chart.scales.y.max;
        const top = chart.scales.y.top;
        const left = chart.scales.x.left;
        const height = chart.scales.y.height;
        const width = chart.scales.x.width;
        const x_factor = width / (max_x - min_x);
        const y_factor = height / (max_y - min_y);
        const key_slots: any[] = chart.options.regression_line.slots;
        const slots_options: any[] = chart.options.regression_line.slots_options;

        let fillStyle = "rgba(0, 0, 0, 0.05)";
        let from = 0;
        let till = max_x;
        if(x_range !== "full"){
            from = x_range.from;
            till = x_range.till;
            fillStyle = Globals.shortlist_green;
        }else{
            // console.error(chart, args, options);
            // this.render_bar_part(chart, args, options);
            return true;
        }
        const context: CanvasRenderingContext2D = chart.ctx;
        context.save();
        context.fillStyle = fillStyle;
        if(v_x !== undefined){
            let x1 =  left + (min_x * x_factor * -1) + x_factor * from;
            let x2 = left + (min_x * x_factor * -1) + x_factor * till;

            if(x1 < left){
                x1 = left;
            }
            if(x2 > left + width){
                x2 = left + width;
            }

            const x = x1;
            const w = x2 - x1;
            const y = (height + top) - (y_range.till * y_factor);
            const h = (y_range.till - y_range.from) * y_factor;
            context.fillRect(x,y,w,h);

            context.beginPath();
            context.setLineDash([7,2,1]);
            context.strokeStyle = "rgba(0, 0, 0, 0.5)";
            context.moveTo(x, top);
            context.lineTo(x, top + height);
            context.stroke();
            context.beginPath();
            context.moveTo(x+w, top);
            context.lineTo(x+w, top + height);
            context.stroke();
            // console.error(v_x, x3, y3);
        }

        /*

        key_slots.forEach((pair, idx)=>{

            const x = 1 + left + (Math.abs(min_x)*x_factor) + slots_options[idx] * x_factor;
            const y = (height + top) - (pair[1] * y_factor);
            const w = (slots_options[idx +1] - slots_options[idx]) * x_factor - 2;
            const h = (pair[1] - pair[0]) * y_factor; // (height + top) - (pair[0] * y_factor);

            if(v_x>=slots_options[idx] && v_x<slots_options[idx+1]){
                context.fillStyle = "rgba(0, 0, 0, 0.3)";
                context.fillRect(x,y,w,h);
            }else{
                // context.fillStyle = "rgba(0, 0, 0, 0.1)";
            }
            // console.error(x,y,w,h);
        });
        */
        context.restore();
        return true;
    }
}

export const RegressionLine = (new RegressionLineClass()) as Plugin;
