import * as st from "simple-statistics";

const get_array = (d) => {
    const ret = [];
    if (Array.isArray(d)) {
        d.forEach( (f) => {
            const n = parseFloat(f);
            if (!isNaN(n)) {
                ret.push(n);
            }
        } );
    }
    return ret;
};
export function sum(data) {
    if (!Array.isArray(data)) {
        return NaN;
    }
    data = get_array(data);
    if (!data.length) {
        return NaN;
    }
    return st.sum(get_array(data));
}
export function min(data) {
    if (!Array.isArray(data)) {
        return NaN;
    }
    data = get_array(data);
    if (!data.length) {
        return NaN;
    }
    return st.min(get_array(data));
}
export function max(data) {
    if (!Array.isArray(data)) {
        return NaN;
    }
    data = get_array(data);
    if (!data.length) {
        return NaN;
    }
    return st.max(data);
}
export function mean(data) {
    if (!Array.isArray(data)) {
        return NaN;
    }
    data = get_array(data);
    if (!data.length) {
        return NaN;
    }
    return st.mean(data);
}
export function average(data) {
    return mean(data);
}
export function median(data) {
    if (!Array.isArray(data)) {
        return NaN;
    }
    data = get_array(data);
    if (!data.length) {
        return NaN;
    }
    return st.median(data);
}
export function standardDeviation(data) {
    if (!Array.isArray(data)) {
        return NaN;
    }
    data = get_array(data);
    if (!data.length) {
        return NaN;
    }
    return st.standardDeviation(data);
}
export function quantile(data, ff) {
    if (!Array.isArray(data)) {
        return NaN;
    }
    data = get_array(data);
    if (!data.length) {
        return NaN;
    }
    return st.quantile(data, ff);
}
export function quantileRank(data, ff) {
    if (!Array.isArray(data)) {
        return NaN;
    }
    data = get_array(data);
    if (!data.length) {
        return NaN;
    }
    return st.quantileRank(data, ff);
}
