import {DateTime} from "luxon";
import {CSSProperties} from "react";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {IDW_NET} from "../../const/IdwData";
import {Sectors} from "../../const/Sectors";
import {IBetasForBusinessResult, IBetasStat} from "../../models/IBetasForBusinessResult";
import {IOptionEntry} from "../../models/IOptionEntry";
import {fromDateParameter} from "../../tools/DateTools";
import {BusinessBetas} from "../widgets/BusinessBetas";
import {BaseModule} from "./BaseModule";
import {HTMLTable, Menu, MenuItem} from "@blueprintjs/core";
import {TasksCalls} from "../../services/TasksCalls";
import {BetaFilterState} from "../../helpers/BetaFilterState";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export class BetaForBusiness extends BaseModule<any> {

    // private tableProps: ISzTableProperties<any[]>;


    private readonly business_betas: React.RefObject<BusinessBetas> = null;

    constructor(props: any, context: any) {
        super(props, context);
        this.business_betas = React.createRef();
        this.state = {
            loading: true,
        };
    }
    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }

    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.business_betas.current.tableProps as any);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderContent() {
        console.error(this.data.base_rate);
        return (
            <div style={{position: "relative", overflow: "hidden", paddingTop: 16 }}>
                <div className={"sz-row"}>
                    <div className={"sz-col sz-col-50"} style={{paddingRight: 20}}>
                        <BusinessBetas data={this.data} ref={this.business_betas}/>
                    </div>
                    <div className={"sz-col sz-col-50"} style={{paddingLeft: 20}}>
                        {this.renderUnleveredCostOfEquity()}
                    </div>
                </div>
                <div style={{maxWidth: 1200}}>
                    <p className="bp3-text-small bp3-text-muted">¹) {BetaFilterState.getUnleveredBetaBase()}</p>
                </div>
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    private renderUnleveredCostOfEquity(){
        const idw_data = IDW_NET;
        const deadline = fromDateParameter().asDateDecimal();
        const idw = idw_data.find((d)=>{
            const dt = DateTime.fromJSDate(d.date).asDateDecimal();
            if(dt >= deadline){
                console.error(d);
            }
            return dt >= deadline;
        });
        const idw_value = (idw?.max + idw?.min)/2;
        const base_rate = this.data.base_rate?.value;
        const sectors = [];
        const entry = this.data.result;
        const padding = "6px 3px";
        const num_width = 90;
        Object.keys(entry).forEach((e)=>{
            const _sector = Sectors.sectors_map[e];
            const l = _sector ? _sector : "Sektor nicht gefunden";
            sectors.push(l);
        });
        const columns: {style: CSSProperties, caption: string}[] = [
            {caption: _t(ETranslation.sector_sub_sector), style: {padding}},
            {caption: _t(ETranslation.base_rate), style: {padding, textAlign: "right", width: num_width}},
            {caption: _t(ETranslation.beta_unlevered), style: {padding, textAlign: "right", width: num_width}},
            {caption: _t(ETranslation.market_risk_premium), style: {padding, textAlign: "right", width: num_width}},
            {caption: _t(ETranslation.unlevered_cost_equity), style: {padding, textAlign: "right", width: num_width, fontWeight: "bold", whiteSpace: "nowrap"}},
        ];
        const renderField = (value, col, strong?)=>{
            if(strong){
                return (
                    <td style={col.style}>
                        <strong style={{fontWeight: "bold"}}>{Globals.formatter(value, 2, 2, true)}</strong>
                    </td>
                );
            }
            return (
                <td style={col.style}>
                    {Globals.formatter(value, 2, 2, true)}
                </td>
            );
        }
        return (
            <div>
                <div style={{display: "flex", justifyContent: "start", marginBottom: 20}}>
                    <div style={{marginBottom: 20, fontSize: "80%"}}>
                        <div><strong>{_t(ETranslation.unlevered_cost_equity)}</strong></div>
                        <div>{this.data.label}, {sectors.join(", ")}</div>
                    </div>
                </div>
                <HTMLTable className={`bp3-html-table bp3-html-table-condensed sz-table sz-prj-info-table_2`} condensed={true} style={{width: "100%", fontSize: "80%"}}>
                    <thead>
                    <tr style={{backgroundColor: "#FFFFFF"}}>
                        {columns.map((col)=>(<th style={col.style}>{col.caption}</th>))}
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(entry).map( (key) => {
                        const sector: IBetasForBusinessResult = entry[key];
                        const _sector: IOptionEntry = Sectors.values.find( (s) => s.value === key );
                        const l = _sector ? _sector.label : "Sektor nicht gefunden";
                        return (
                            <>
                                <tr>
                                    {columns.map((col, col_num)=>{
                                        if(col_num===0){
                                            return (<td style={col.style}><strong style={{fontWeight: "bold"}}>{l}</strong></td>);
                                        }
                                        if(col_num===1){
                                            return renderField(base_rate, col, true);
                                        }
                                        if(col_num===2){
                                            return renderField(sector.sector.median, col, true);
                                        }
                                        if(col_num===3){
                                            return renderField(idw_value, col, true);
                                        }
                                        if(col_num===4){
                                            return renderField(base_rate + sector.sector.median * idw_value, col, true);
                                        }
                                        return (
                                            <td>
                                                udef.
                                            </td>
                                        );
                                    })}
                                </tr>
                                {Object.keys(sector.sub_sectors).map( (sub_key, idx, arr) => {
                                    const sub_sector: IBetasStat = sector.sub_sectors[sub_key];
                                    const _l: IOptionEntry = Sectors.sectors_map[sub_key];
                                    return (
                                        <tr>
                                            {columns.map((col, col_num)=>{
                                                if(col_num===0){
                                                    return (<td style={col.style}>{_l}</td>);
                                                }
                                                if(col_num===1){
                                                    return renderField(base_rate, col);
                                                }
                                                if(col_num===2){
                                                    return renderField(sub_sector.median, col);
                                                }
                                                if(col_num===3){
                                                    return renderField(idw_value, col);
                                                }
                                                if(col_num===4){
                                                    return renderField(base_rate + sub_sector.median * idw_value, col);
                                                }
                                                return (
                                                    <td>
                                                        udef.
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </>
                        );
                    })}
                    </tbody>
                </HTMLTable>
            </div>
        );
    }
}
