export enum EValueName {
    country_iso_3= "country_iso_3",

    // beta
    raw_beta= "raw_beta",
    unlevered_beta= "unlevered_beta",
    unlevered_total_beta= "unlevered_total_beta",
    tax_shield= "tax_shield",
    tax_shield_safe= "tax_shield_safe",
    // multiplikatoren
    sales_trailing= "sales_trailing",
    sales_forward= "sales_forward",
    ebitda_trailing= "ebitda_trailing",
    ebitda_forward= "ebitda_forward",
    ebit_trailing= "ebit_trailing",
    ebit_forward= "ebit_forward",
    //
    credit_spreads_sector= "credit_spreads_sector",
    credit_spreads_avg= "credit_spreads_avg",
    credit_spreads_sector_label= "credit_spreads_sector_label",
    credit_spreads= "credit_spreads",

    base_rate_unrounded = "base_rate_unrounded",
    base_rate_rounded  = "base_rate_rounded",

    country_risk_premium = "country_risk_premium",
    market_risk_premium = "market_risk_premium",
    market_risk_premium_label = "market_risk_premium_label",
    idw_parameter = "idw_parameter",
    wacc = "WACC",
    tp_result = "tp_result",

    cp_credit_spread = "cp_credit_spread",
    cp_rating = "cp_rating",
}
