// https://reactgrid.com/docs/4.0/7-api/0-interfaces/7-cell-style/
import {Button, Icon, Intent, IToastProps, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {Calculator} from "../../helpers/Calculator";
import {EParameters} from "../../models/EParameters";
import {IParameterChanged} from "../../models/IParameterChanged";
import {PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {
    IDataChange,
    ISzTableGridHeaderRow,
    ISzTableGridProps,
    SzTableGrid,
    SzTableGridCellMap
} from "../widgets/SzTableGrid";
import {BaseModule} from "./BaseModule";
import {ICreditSpreadCalcTableData} from "../widgets/CreditSpreadCalcTable";
import {CreditSpreadOptions} from "../../const/CreditSpreadOptions";
import {ICalcCreditSpreadResult} from "../../helpers/CalcCreditSpreadIndication";
import {median} from "../../helpers/Statistics";
import {Ratings} from "../../const/Ratings";
import {caption_units, captions} from "../../const/Benchmarking";
import {Tooltip2} from "@blueprintjs/popover2";
import {SzRenderFormular} from "../widgets/SzRenderFormular";
import {CreditSpreadFormulars, FormularDefinition} from "../../const/CreditSpreadFormulars";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

interface IRowDef {
    label: string;
    field?: string;
    isBold?: boolean;
    formula?: string;
    sign?: number;
}

const _isBold = true;
// ff_funds_oper_gross
enum ESign {
    plus= 1,
    minus= 2,
}
const _sp= {sign: ESign.plus};
const _sm= {sign: ESign.minus};
const user_input_default = {
    ff_sales: 1000,
    ff_ebitda_oper: 300,
    ff_ebit_oper: 200,
    ff_int_exp_debt: 20,
    ff_inc_tax: 30,
    ff_net_inc: 150,

    ff_ppe_net: 800,
    ff_intang: 150,
    ff_invest_aff: 0,
    ff_cash_generic: 100,
    ff_assets: 1200,

    ff_eq_tot: 500,
    ff_debt: 600,
    ff_pens_liabs_unfunded: 50,
};
const _rows: IRowDef[] = [
    {label: _t(ETranslation.revenues), field: "ff_sales", ..._sp},
    // {label: "Bestandsveränderungen", field: "b1"},
    // {label: "Aktivierte Eigenleistungen", field: "b2", ..._sp},
    // {label: "Gesamtleistung", isBold: _isBold, field: "g2", formula: "ff_sales + b1 + b2"},
    // {label: ""},
    // {label: "Sonstige betriebliche Erträge", field: "g3", ..._sp},

    // {label: "Materialaufwand", field: "oa_materialaufwand", ..._sm},
    // {label: "Personalaufwand", field: "oa_personalaufwand"},
    // {label: "Sonstiger betrieblicher Aufwand", field: "oa", formula: "ff_ebitda_oper - g2 - oa_materialaufwand - oa_personalaufwand"},
    {label: "EBITDA", field: "ff_ebitda_oper", isBold: _isBold},
    // {label: ""},
    // {label: "Abschreibungen", field: "f1", formula: "ff_ebit_oper - ff_ebitda_oper"},
    {label: "EBIT", field: "ff_ebit_oper", isBold: _isBold},
    // {label: ""},
    // {label: " Zinserträge", field: "z1"},
    {label: _t(ETranslation.interest_expenses), field: "ff_int_exp_debt", ..._sp}, // , formula: "ff_int_inc_net - z1"
    // {label: "Zinsergebnis", field: "ff_int_inc_net"},
    // {label: "Sonstiges Finanzergebnis", field: "f3"},
    // {label: "Außerordentliches Ergebnis", field: "f4"},
    // {label: "Ergebnis vor Steuern", field: "f5", isBold: _isBold, formula: "ff_ebit_oper + ff_int_inc_net + f3 + f4"},
    {label: "Steueraufwendungen", field: "ff_inc_tax", ..._sp},
    {label: _t(ETranslation.netincome), field: "ff_net_inc", isBold: _isBold}, // , formula: "f5 + ff_inc_tax" // 14
    {label: "", field: ""}, // 15
    {label: _t(ETranslation.tangible_assets), field: "ff_ppe_net", ..._sp},
    {label: _t(ETranslation.intangible_assets), field: "ff_intang", ..._sp},
    {label: _t(ETranslation.financial_assets), field: "ff_invest_aff", ..._sp},
    // {label: "Vorräte", field: "f6", ..._sp},
    // {label: "Forderungen aus L/L", field: "f7", ..._sp},
    {label: _t(ETranslation.liquid_funds), field: "ff_cash_generic", ..._sp},
    {label: _t(ETranslation.other_assets), field: "f8", formula: "ff_assets - (ff_ppe_net + ff_intang + ff_invest_aff + ff_cash_generic)"},
    {label: _t(ETranslation.total_assets), field: "ff_assets", isBold: _isBold, ..._sp},
    {label: "", field: ""},
    // {label: " Eigenkapital der Aktionäre", field: "ff_shldrs_eq", formula: "ff_eq_tot - ff_min_int_accum"},
    // {label: " Nicht beherrschende Anteile", field: "ff_min_int_accum"},
    {label: _t(ETranslation.equity), field: "ff_eq_tot"},
    {label: _t(ETranslation.financial_debt), field: "ff_debt", ..._sp},
    {label: _t(ETranslation.pension_deficit), field: "ff_pens_liabs_unfunded", ..._sp},
    // {label: "Rückstellungen", field: "f10", ..._sp},
    // {label: "Verbindlichkeiten aus L/L", field: "f11", ..._sp},
    {label: _t(ETranslation.other_liabilities), field: "f12", formula: "ff_assets - ( ff_eq_tot + ff_debt + ff_pens_liabs_unfunded)"},
    {label: _t(ETranslation.total_equity_liabilities), field: "ff_assets", isBold: _isBold, formula: "ff_assets"},
    // {label: "", field: ""},
    // {label: "Investitionen", field: "ff_capex", ..._sp},
    // berechnete kennzahlen hidden
    {label: "#FFO", field: "ff_funds_oper_gross", isBold: _isBold, formula: "ff_ebitda_oper - ff_int_exp_debt - ff_inc_tax"},
    {label: _t(ETranslation.working_capital), field: "ff_wkcap", isBold: _isBold},

];
const def_columnWidths= [undefined, 80, 70, 70, 70, 70];

export class CreditSpreadUserInput  extends BaseModule<any> {
    private row_data = {};
    private readonly evtRefresh: IEventHandler;
    private readonly evtClear: IEventHandler;

    private object_context = {};

    constructor(props: any, context: any) {
        super(props, context);

        const d = SessionStore.get(EParameters.BenchmarkingCustom);
        this.row_data = d ? d : {};
        this.state = {
            columnWidths: [undefined, 70],
            data: this.row_data,
        };

        this.evtRefresh = EventBus.subscribe("FinancialsAnnUserDef::EvtRefresh", ()=> EventBus.emit<IParameterChanged[]>("ParameterChanged",[{component: EParameters.BenchmarkingCustom, value: [this.state.data]}]));
        this.evtClear = EventBus.subscribe("FinancialsAnnUserDef::EvtClear", ()=> this.clearData());
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        EventBus.unsubscribe(this.evtRefresh);
        EventBus.unsubscribe(this.evtClear);
    }

    protected renderContent() {
        if(!this.data){
            return (<NonIdealState
                icon={"database"}
                title="Keine Daten"
            />);
        }
        return (
            <div style={{overflow: "hidden", position: "relative"}}>
                <div className={"sz-row"}>
                    <div className={"sz-col-25"} style={{paddingRight: 30}}>
                        {this.renderButtons()}
                        {this.renderGrid()}
                    </div>
                    <div className={"sz-col-45"} style={{paddingRight: 20, paddingLeft: 10}}>
                        {this.renderTable()}
                    </div>
                    <div className={"sz-col-30"}>
                        {this.renderMethod()}
                    </div>
                </div>
            </div>
        );
    }

    protected onAfterUpdate(data: any) {
        this.data = data;
        const d = SessionStore.get(EParameters.BenchmarkingCustom);
        this.row_data = d ? d : {};

        _rows.forEach((row, i)=>{
            if(!row.formula){
                this.getCellContent(this.data.year, row);
                return;
            }
        });

        const form_fields = [];
        _rows.forEach((row, i)=>{
            if(!row.formula){
                return;
            }
            const r = this.object_context[this.data.year];
            const value = Calculator.evaluate(row.formula, r);
            r[row.field] = value;
            this.row_data[`${this.data.year}_${row.field}`] = value;
            form_fields.push(`${this.data.year}_${row.field}`);
        });

        const year = this.data.year;
        // console.error(year, Object.keys(d));
        const not_yet_defined = d ? Object.keys(d).some( (k)=> !form_fields.includes(k) && k.startsWith(`${year}_`)) : false;

        this.setState({
            loading: false,
            data: this.row_data,
        });

        if(!not_yet_defined){
            setTimeout(()=>{
                const m: IToastProps = {
                    timeout: 10000,
                    icon: IconNames.WARNING_SIGN,
                    intent: Intent.DANGER,
                    message: `Für das Jahr ${year} müssen Unternehmensdaten eingegeben werden.`,
                };
                EventBus.emit<IToastProps>("sz-show-message" , m);
            },500);
        }
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    private isWarning(key: string): string{
        const r = this.object_context[this.data.year];
        if(key === "net_debt_ebitda" && r.ff_ebitda_oper <0 && Calculator.evaluate("ff_debt+ff_pens_liabs_unfunded-ff_cash_generic", r)>0){
            return "Bei negativem EBITDA ist die Verwendung der Kennzahl Nettoschulden / EBITDA nicht empfehlenswert.";
        }
        if(key === "ffo_debt_ratio" && r.ff_funds_oper_gross <0 && Calculator.evaluate("ff_debt+ff_pens_liabs_unfunded-ff_cash_generic", r)>0){
            return "Bei negativem FFO ist die Verwendung der Kennzahl FFO / Nettoschulden nicht empfehlenswert.";
        }
        if(key === "ebit_interest_ratio" && r.ff_ebit_oper <0){
            return "Bei negativem EBIT ist die Verwendung der EBIT-Zinsdeckung nicht empfehlenswert.";
        }
        if(key === "ffo_interest" && r.ff_funds_oper_gross <0){
            return "Bei negativem FFO ist die Verwendung der FFO-Zinsdeckung nicht empfehlenswert.";
        }

        if(key === "gearing" && Calculator.evaluate("ff_debt+ff_pens_liabs_unfunded-ff_cash_generic", r)<0){
            return "Bei negativen Nettoschulden ist die Verwendung der Verschuldung nicht empfehlenswert.";
        }

        if(key === "gearing" && r.ff_eq_tot<0){
            return "Bei negativem Eigenkapital ist die Verwendung der Kapitalstruktur nicht empfehlenswert.";
        }
        if(key === "eq_ratio" && r.ff_eq_tot<0){
            return "Bei negativem Eigenkapital ist die Verwendung der Kapitalstruktur nicht empfehlenswert.";
        }

        if(key === "ebitda_sales_ratio" && r.ff_ebitda_oper <0){
            return "Bei negativem EBITDA ist die Verwendung der EBITDA-Marge nicht empfehlenswert.";
        }
        if(key === "ebit_sales_ratio" && r.ff_ebit_oper <0){
            return "Bei negativem EBIT ist die Verwendung der EBIT-Marge nicht empfehlenswert.";
        }
        if(key === "netincome_sales_ratio" && r.ff_net_inc <0){
            return "Bei negativem Jahresüberschuss ist die Verwendung der Jahresüberschuss-Marge nicht empfehlenswert.";
        }
        if(key === "roce" && r.ff_ebit_oper <0){
            return "Bei negativem EBIT ist die Verwendung des ROCE nicht empfehlenswert.";
        }
        if(key === "roe" && r.ff_net_inc <0){
            return "Bei negativem Jahresüberschuss ist die Verwendung des ROE nicht empfehlenswert.";
        }
        return undefined;
    }

    private setDefaultCustom(){
        const year = this.data.year;
        Object.keys(user_input_default).forEach((key)=>{
            this.row_data[`${year}_${key}`] = user_input_default[key];
        });
        this.setState({
            loading: false,
            data: this.row_data,
        });
        EventBus.emit<IParameterChanged[]>("ParameterChanged",[{component: EParameters.BenchmarkingCustom, value: [this.row_data]}])
    }
    private clearData() {
        const year = this.data.year;
        const header = [`${year}`];
        const to_clear = [];
        header.forEach((h, colIdx)=> {
            _rows.forEach( (r) => {
                const key = `${h}_${r.field}`;
                to_clear.push(key);
            });
        });
        to_clear.forEach((k) => this.row_data[k] = 0);
        this.setState({
            loading: false,
            data: this.row_data,
        });
        EventBus.emit<IParameterChanged[]>("ParameterChanged",[{component: EParameters.BenchmarkingCustom, value: [this.row_data]}])
    }
    private renderTable(){
        const padding = "6px 3px";
        const num_width = 75;
        const td_height = 28;
        const border_left_0 = {
            boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15), inset 1px 0 0px 0px rgba(16, 22, 26, 0.15)",
        };
        const border_left = {
            boxShadow: "inset 1px 0 0px 0px rgba(16, 22, 26, 0.15)",
        };
        const number_col: CSSProperties = {
            padding,
            textAlign: "right",
            width: num_width,
            height: td_height
        };
        const table_data: ICreditSpreadCalcTableData[] = [];
        CreditSpreadOptions.getMetrics().forEach((metric)=>{
            const indication: ICalcCreditSpreadResult = this.data.results[metric.field];
            if(!indication){
                return;
            }
            const x_range_in_percent = isNaN(indication.key_value) || indication.key_value===null ? NaN : indication.x_range.till / indication.key_value - 1;
            const data_in_range = indication.data.filter((i)=> CreditSpreadOptions.in_range(i.x, indication) && i.name !== i.rating);
            // console.error(metric);
            if(metric.weight > 0 && indication.y_range && !isNaN(indication.y_range.from) && !isNaN(indication.y_range.till)){
                const data_in_box = indication.data.filter((i)=> CreditSpreadOptions.in_box(i.x, i.y, indication) && i.name !== i.rating);
                const spread = median(data_in_box.map((i)=> i.y));
                const rating_value = median(data_in_box.map((i)=> Ratings.s_and_p_mapped_to_num[i.rating]));
                table_data.push({
                    caption: metric.caption,
                    key: metric.field,
                    key_caption: captions[metric.field],
                    key_value: indication.key_value,
                    x_from: indication.x_range.from,
                    x_till: indication.x_range.till,
                    boxed_points: data_in_box.length ? data_in_box.length : NaN,
                    ranged_points: data_in_range.length ? data_in_range.length : NaN,
                    y_from: indication.y_range.from,
                    y_till: indication.y_range.till,
                    rating_from: indication.rating_range.from,
                    rating_till: indication.rating_range.till,
                    q_y_from: indication.q_y_range.from,
                    q_y_till: indication.q_y_range.till,
                    q_y: undefined,
                    longlist_range: undefined,
                    spread,
                    value: spread,
                    rating: rating_value,
                    weight: metric.weight,
                    x_range_in_percent,
                    units: caption_units[metric.field],
                });
            }else{
                table_data.push({
                    caption: metric.caption,
                    key: metric.field,
                    key_caption: captions[metric.field],
                    key_value: NaN,
                    x_from: indication.x_range.from,
                    x_till: indication.x_range.till,
                    boxed_points: NaN,
                    ranged_points: data_in_range.length ? data_in_range.length : NaN,
                    y_from: NaN,
                    y_till: NaN,
                    rating_from: NaN,
                    rating_till: NaN,
                    q_y_from: NaN,
                    q_y_till: NaN,
                    longlist_range: NaN,
                    spread: NaN,
                    value: undefined,
                    rating: undefined,
                    weight: metric.weight,
                    x_range_in_percent,
                    units: caption_units[metric.field],
                } as ICreditSpreadCalcTableData);
            }
        });
        return (
            <div style={{width: "100%", fontSize: "80%"}}>
                {table_data.map((row: ICreditSpreadCalcTableData, row_num)=>{
                    return this.renderCreditSpreadMetric(row, row_num);
                })}
            </div>
        );
    }
    private renderMethod(){
        return (
            <div style={{border: "1px solid rgba(16, 22, 26, 0.15)", padding: 10, backgroundColor: "#F5F8FA", height: "100%"}} className={"bp3-text-small bp3-text-muted"}>
                <h4 style={{fontSize: 12}}>Vorgehensweise</h4>
                <ol>
                    <li>Auswahl des Stichtags</li>
                    <li>
                        Finanzdaten des Unternehmens (Schuldner)
                        <ol type={"a"} style={{marginBottom: 10}}>
                            <li>Eingabe der Finanzdaten</li>
                            <li>Übernahme in die Analyse</li>
                        </ol>
                    </li>
                    <li style={{marginBottom: 10}}>
                        Eingrenzung der betrachteten Anleihen
                        <ol type={"a"} style={{marginBottom: 10}}>
                            <li>Laufzeit(-band)</li>
                            <li>Emissionswährung, Rang und Besicherung</li>
                        </ol>
                        <strong>Anmerkungen:</strong> Die Anwendung mehrerer Anleihe-Filterkriterien kann das analysierte Marktsegment stark begrenzen und die Aussagekraft der statistischen basierten Analyseergebnisse einschränken.
                    </li>

                    <li style={{marginBottom: 10}}>
                        Eingrenzung der herangezogenen Vergleichsunternehmen
                        <ol type={"a"} style={{marginBottom: 10}}>
                            <li>{_t(ETranslation.sector)}</li>
                            <li>Region</li>
                        </ol>
                        <strong>Anmerkungen:</strong> Die Anwendung mehrerer Filterkriterien kann den Umfang der verfügbaren Vergleichsunternehmen stark begrenzen und die Aussagekraft der statistischen basierten Analyseergebnisse einschränken.
                    </li>
                    <li>
                        Anzahl der Vergleichsunternehmen
                        <ol type={"a"} style={{marginBottom: 10}}>
                            <li>Umfang der Longlist</li>
                            <li>Einschränkung auf Shortlist durch Auswahl des Anteils der Verteilung</li>
                        </ol>
                    </li>
                    <li>
                        Kreditkennzahlen zur Beurteilung der Bonität
                        <ol type={"a"} style={{marginBottom: 10}}>
                            <li>Verwendung des Vorschlags; alternativ Auswahl der Kreditkennzahlen</li>
                            <li>Verwendung des Vorschlags; Gewichtung der Kreditkennzahlen</li>
                        </ol>
                    </li>
                    <li>
                        Qualitative Beurteilung des zu beurteilenden Unternehmens
                        <ol type={"a"} style={{marginBottom: 10}}>
                            <li>Markt und Wettbewerbsposition</li>
                            <li>Geschäfts- und Unternehmensstrategie</li>
                            <li>Finanzierungspolitik</li>
                        </ol>
                    </li>
                </ol>
            </div>
        );
    }
    private renderGrid() {
        const header_row: CSSProperties = {
            backgroundColor: undefined,
        };
        const year = this.data.year;
        const years = [year];
        const cell_map: SzTableGridCellMap = {};
        const header: ISzTableGridHeaderRow[] = [
            {style: header_row, cells: [
                    {content:_t(ETranslation.financial_data), style:{width: def_columnWidths[0], paddingLeft: 10}},
                    {content:"Mio. EUR", style:{width: def_columnWidths[0], paddingLeft: 10, paddingRight: 6, textAlign: "right"}}
                ]},
        ];
        const data = [];
        _rows.forEach((row, row_num) => {
            const row_data = [];
            data.push(row_data);
            if(!row.label){
                row_data.push(" ");
                cell_map[`A${row_num}`] = {
                    col_span: years.length + 1,
                };
                return;
            }
            row_data.push(row.label);
            cell_map[`A${row_num}`] = {
                class_name: row.isBold ? "sz-bold" : undefined,
                style:{width: def_columnWidths[0], paddingLeft: 10}
            };
            years.forEach( (y, i) =>{
                const content = this.getCellContent(y, row);
                const cell_style = this.getCellStyle(y, row);
                row_data.push(content);
                cell_map[`${SzTableGrid.colName( i + 1)}${row_num}`] = {
                    context_id: "" + y,
                    is_editable: row.field && !row.formula,
                    is_selectable: row.field && !row.formula,
                    class_name: row.isBold ? "sz-bold sz-number" : "sz-number",
                    style: Object.assign({width: def_columnWidths[ i +1 ], paddingRight: 6}, cell_style),
                    formatter: (v)=>{ if(isNaN(v)){return "";} return Globals.formatter(v, 2, 2)}
                };
            });
        });
        const grid_props: ISzTableGridProps = {
            header,
            cell_map,
            data,
            onCellUpdated: (context_id, new_value, col_num, row_num) => this.onCellUpdated(context_id, new_value, col_num, row_num),
        };
        return (
            <div>
                <SzTableGrid {...grid_props} style={{fontSize: "80%"}}/>
            </div>
        );
    }
    private getCellContent(year: number, row: IRowDef) {
        const values = this.row_data; // SessionStore.get(EParameters.BenchmarkingCustom);
        const key = `${year}_${row.field}`;
        const v = parseFloat(values && values[key]!==undefined ? values[key] : 0);
        if(!this.object_context[year]){
            this.object_context[year] = {};
        }
        this.object_context[year][row.field] = v;
        if(isNaN(v) || v === undefined){
            return "";
        }
        return v; // Math.trunc(v * 100) / 100;
    }

    private getCellStyle(year: number, row: IRowDef) {
        //
    }

    private onCellUpdated(context_id: string, new_value: any, col_num: number, row_num: number): IDataChange[] {
        // context_id: string, new_value: any, col_num: number, row_num: number
        const r = this.object_context[context_id];
        const target_row = _rows[row_num];
        const result: IDataChange[] = [];

        const v = parseFloat(new_value);
        if((target_row.sign === ESign.plus && v < 0) || (target_row.sign === ESign.minus && v > 0)){
            result.push({
                value: v,
                isError: "#Wert",
                errorMessage: "Vorzeichen",
                col_num,
                row_num,
            });
            return result;
        }

        r[target_row.field] = new_value;
        this.row_data[`${context_id}_${target_row.field}`] = new_value;


        _rows.forEach((row, i)=>{
            if(!row.formula){
                return;
            }
            const value = Calculator.evaluate(row.formula, r);
            r[row.field] = value;
            this.row_data[`${context_id}_${row.field}`] = value;
            result.push({
                value,
                col_num,
                row_num: i,
            });
        });

        if(Array.isArray(result) && result.length){
            // EventBus.emit("FinancialsAnnUserDef::EvtRefresh", undefined);
        }

        return result;
    }

    private renderButtons() {
        const button= (event, caption, icon, intent, toolTip?) => {
            if(toolTip){
                return (
                    <Tooltip2 content={toolTip}>
                        <Button icon={icon} intent={intent} onClick={()=>EventBus.emit(event, null)} text={caption} minimal={true}/>
                    </Tooltip2>
                );
            }else{
                return <Button icon={icon} intent={intent} onClick={()=>EventBus.emit(event, null)} text={caption} minimal={true}/>;
            }
        };
        return (
            <div style={{display: "flex", justifyContent: "flex-end", marginBottom: 16}}>
                <div style={{display: "flex", justifyContent: "space-between", width: 200}}>
                    {button("FinancialsAnnUserDef::EvtRefresh", "Übernehmen", IconNames.REFRESH, Intent.PRIMARY)}
                    {button("FinancialsAnnUserDef::EvtClear", undefined, IconNames.TRASH, Intent.DANGER)}
                    <Tooltip2 content={"Beispiel laden"}>
                        <Button icon={IconNames.LIGHTBULB} intent={Intent.PRIMARY} onClick={()=>this.setDefaultCustom()} minimal={true}/>
                    </Tooltip2>
                </div>
            </div>
        );
    }
    private getRowData(field){
        const f = this.object_context[this.data.year]; // `${this.data.year}_${field}`;
        if(!f){
            return NaN;
        }
        return f[field];
    }
    private renderFormular(row: ICreditSpreadCalcTableData, row_num: number){
        const exi:FormularDefinition = CreditSpreadFormulars.forKey[row.key];
        if(!exi){
            return null;
        }
        const _fmtp = (v, ofd?)=> Globals.formatter_percent(v, ofd, ofd, true);
        const _fmt = (v, ofd= 2)=>{
            let fd = ofd;
            if(row_num === 4){
                fd = 0;
            }
            return Globals.formatter(v, fd, fd, true);
        };
        const fields = exi.valued.match(/\$\w*/g);
        let resolved = exi.valued;
        if(Array.isArray(fields)){
            fields.forEach((field)=>{
                if(field === "$result"){
                    resolved = resolved.replaceAll(field, _fmt(row.key_value));
                    return;
                }
                resolved = resolved.replaceAll(field, _fmt(this.getRowData(field.slice(1))));
            });
        }

        return (
            <div style={{display: "flex", justifyContent: "flex-start"}}>
                <div style={{display: "flex", alignItems: "center", marginRight: "0.2778em"}}><SzRenderFormular expression={"="} /></div>
                <div><SzRenderFormular expression={exi.expression} /></div>
                <div style={{display: "flex", alignItems: "center", marginRight: "0.2778em", marginLeft: "0.2778em"}}><SzRenderFormular expression={"="} /></div>
                <div><SzRenderFormular expression={resolved} /></div>
            </div>
        );
    }
    public getPdfPage(): any[] {
        const year = this.data.year;
        const years = [year];
        const content = [];
        PdfInsertElement.page_header(content, "Unternehmensdaten");
        PdfInsertElement.h2(content, _t(ETranslation.financial_data));

        const table = PdfInsertElement.table(content);
        table.layout = "smart_table";
        table.table = {
            headerRows: 1,
            widths: ["auto", "auto"],
            body: [
                [
                    {
                        text: "",
                        fontSize: 7,
                        border: [false, false, false, true],
                    },
                    {
                        text: "Mio. EUR",
                        fontSize: 7,
                        alignment: "right",
                        border: [false, false, false, true],
                    },
                ],
            ],
        };

        _rows.forEach((row, row_num) => {
            if(row.label?.startsWith("#")){
                return;
            }
            const table_row = [];
            table.table.body.push(table_row);
            if(!row.label){
                PdfInsertElement.p(table_row, " ");
                years.forEach(()=>PdfInsertElement.p(table_row, " "));
                return;
            }
            const label = PdfInsertElement.p(table_row, row.label);
            label.bold = row.isBold ? true : false;
            years.forEach( (y, i) =>{
                const value = Globals.formatter(this.getCellContent(y, row), 0, 0, true);
                const l = PdfInsertElement.p(table_row, value);
                l.bold = row.isBold ? true : false;
                l.alignment = "right";
            });
        });
        // console.error(table);

        return content;
    }

    private renderCreditSpreadMetric(row: ICreditSpreadCalcTableData, row_num: number) {
        if(isNaN(row.key_value)){
            return null;
        }
        const pt = {};
        const _fmtp = (v, ofd?)=> Globals.formatter_percent(v, ofd, ofd, true);
        const _fmt = (v, ofd= 2)=>{
            let fd = ofd;
            if(row_num === 4){
                fd = 0;
            }
            return Globals.formatter(v, fd, fd, true);
        };
        const renderCaption = ()=>{
            const warning = this.isWarning(row.key);
            if(!warning){
                return (
                    <div style={{}}>
                        <span style={{fontWeight: "bold"}}>{row.caption}:&nbsp;</span>
                        <span>{row.key_caption}</span>
                    </div>
                );
            }
            return (
                <div style={{}}>
                    <div style={{color: "#c23030", display: "flex", cursor: "default"}}>
                        <Icon icon={IconNames.WARNING_SIGN} size={12} />
                        <span style={{fontWeight: "bold", paddingLeft: 10}}>{row.caption}:&nbsp;</span>
                        <span>{row.key_caption}</span>
                    </div>
                    <div style={{color: "#c23030", cursor: "default"}}>
                        <div>{warning}</div>
                        <div>Die Gewichtung der Kennzahl sollte auf Null gesetzt werden.</div>
                    </div>
                </div>
            );
        };

        return (
            <div style={{width: "100%", marginBottom: 15, border: "1px solid rgba(16, 22, 26, 0.15)", padding: 10, paddingBottom: 20, backgroundColor: "rgb(245, 248, 250)"}} className={"bp3-text-small bp3-text-muted"}>
                <div style={{marginBottom: 30, display: "flex", justifyContent: "space-between"}}>
                    {renderCaption()}
                    <div>
                        Gewichtung: {_fmtp(row.weight, 0)}
                    </div>
                </div>
                {this.renderFormular(row, row_num)}
            </div>
        );
    }
}
