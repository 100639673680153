import {EParameters} from "../../models/EParameters";
import {BaseDataProvider} from "./BaseDataProvider";

export class DateTriggerProvider extends BaseDataProvider {
    protected initProvider() {
        this.className = "DateTriggerProvider";
        this.parameterMapping = [
            EParameters.DateParameter,
        ];
    }

    protected update() {
        this.afterUpdate(this.mappedParameters.get(EParameters.DateParameter));
    }
}
