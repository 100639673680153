import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {SzStringInput} from "../../hooks/SzStringInput";

export class CountryIso2Parameter extends CombiParameter {
    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getWidthClass() {
        return "fixed-width-250";
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CountryIso2Parameter];
    }
    protected async initialize() {
        return {
            selectedValue: SessionStore.get(EParameters.CountryIso2Parameter),
        };
    }
    protected getLabel() {
        const selectedValue = this.state.selectedValue;
        return selectedValue ? selectedValue : "—";
    }
    protected onClosePopup(){
        this.fireValueChange(EParameters.CountryIso2Parameter, this.state.selectedValue);
    }
    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"} >
                <div className={"sz-col sz-col-100"} style={{padding: 10}}>
                    <SzStringInput current_value={this.state.selectedValue} onValueChanged={(v)=>this.setState({selectedValue: v})}/>
                </div>
                {this.renderHelpText(["Manuelle Eingabe, ISO2-Codes, Komma separierte Länder werden ausgeschlossen"], "100%", "100%")}
            </div>
        );
    }
}
