import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

export class ProfitabilityParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.ProfitabilityParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.ProfitabilityParameter);
    }
    protected async getOptions() {
        return [
            {label: "Rohertrags-Marge", value: "gross_sales_ratio"},
            {label: _t(ETranslation.ebitda_margin), value: "ebitda_sales_ratio"},
            {label: _t(ETranslation.ebit_margin), value: "ebit_sales_ratio"},
            {label: "Jahresüberschuss-Marge", value: "netincome_sales_ratio"},
        ];
    }
}
