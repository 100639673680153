import {Menu} from "@blueprintjs/core";
import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_OPTION} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";

export class AdjusmentBetaParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.AdjusmentBetaParameter];
    }

    protected async getOptions() {
        return PARAMETER_OPTION(EParameters.AdjusmentBetaParameter);
    }

    protected async getSelectedValue() {
        return SessionStore.get(EParameters.AdjusmentBetaParameter);
    }

    protected getWidthClass(): string {
        return "fixed-width-400";
    }

    protected renderHint() {
        return (
            <Menu style={{marginTop: 12}}>
                <li>
                    <p className={"bp3-text-small bp3-text-muted"}>Das Unadjusted Beta entspricht dem sog. Raw Beta, welches im Rahmen der Regressionsanalyse erhoben wird. Das Adjusted Beta basiert auf dem Blume-Adjustment unter Verwendung des Raw Beta (66,7% Gewichtung) und des Marktmittelpunktes von 1 (33,3 % Gewichtung).</p>
                </li>
            </Menu>
        );
    }
}
