import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";

export class RatingKeyParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.RatingKeyParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.RatingKeyParameter);
    }
    protected async getOptions() {
        return [
            {label: "FFO / Debt", value: "ffo_debt_ratio"},
            {label: "Debt / EBITDA", value: "debt_ebitda_ratio"},
            {label: "EBIT / Interest", value: "ebit_interest_ratio"},
            {label: "Debt / Equity", value: "debt_equity_ratio"},
        ];
    }
}
