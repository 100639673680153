import * as React from "react";
import * as InitWorkspace from "../const/InitWorkspace";
import * as SzProjectHooks from "./hooks/SzProjectHooks";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {IProject, ProjectModuleGroups, ProjectTypeModuleMap} from "../models/IProject";
import {IsLoading} from "./hooks/IsLoading";
import {ServiceCalls} from "../services/ServiceCalls";
import {SessionStore} from "../const/SessionStore";
import {ShowMessage} from "./hooks/ShowMessage";
import {ApplicationBoard, IApplicationBoard} from "./ApplicationBoard";
import {ModuleGroup} from "../modules";
import {Globals} from "../const/Globals";


interface IMainProjectWorkspace {
    project?: IProject;
}

export const MainProjectWorkspace = (props: IMainProjectWorkspace)=>{
    const navigate = useNavigate();
    const [project, setProject] = React.useState(undefined);
    const params = useParams();

    const activeTab = SzProjectHooks.useProjectModuleTab();

    useEffect(() => {
        (async ()=>{
            try{
                const _id = params.projectId;

                const cb = new ServiceCalls();
                const r = await cb.loadProject(_id);
                if(!(r && r.data)){
                    navigate("/projects");
                    return;
                }
                Globals.isRegistred = true;
                Globals.ownsPackage = 5;
                await SessionStore.init(r.data);
                await InitWorkspace.run();
                const pp: IProject = await cb.getProject(_id);
                setProject(pp);
                SzProjectHooks.EMIT_PROJECT_LOADED(pp);
            }catch (e) {
                console.error(e);
                navigate("/projects");
            }
        })();
    }, []);
    if(!project){
        return <IsLoading />;
    }
    // activeTab
    const module_tabs = ProjectTypeModuleMap[project.project_type];
    if(!module_tabs[activeTab]){
        return <ShowMessage message={"index out of bounds!"} message_type={"error"} />
    }
    const modules: ModuleGroup[] = [];
    const m = ProjectModuleGroups[module_tabs[activeTab]];
    Globals.currentModule = module_tabs[activeTab];
    if (m) {
        const module: ModuleGroup = new m();
        module.initGlobalData();
        modules.push(module);
    }
    // console.error("rerender", project);
    return (
        <ApplicationBoard modules={modules} />
    );
}
