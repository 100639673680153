import * as ExprEval from "expr-eval";


const excel_null = {
    get(obj, prop) {
        if(!(prop in obj)){
            // console.error(`property(${prop}) undefined`);
        }else{
            if(obj[prop] === undefined){
                return 0;
            }
            if(isNaN(obj[prop])){
                return 0;
            }
        }
        return prop in obj ? obj[prop] : 0;
    }
};

export class Calculator {
    public static evaluate(expression, context){
        const p = new Proxy(context, excel_null);
        try {
            return ExprEval.Parser.evaluate(expression, p);
        }catch (e) {
            // console.error(e);
        }
        return undefined;
    }
}
