import {Button, Card, Classes, Dialog, Intent, Menu, MenuItem, NonIdealState} from "@blueprintjs/core";
import * as React from "react";
import {FinKeyLabels} from "../../const/FinKeyLabels";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {FinAnn} from "../../helpers/FinAnn";
import {SvgTextSize} from "../../helpers/SvgTextSize";
import {TableStatistics} from "../../helpers/table/TableStatistics";
import {EParameters} from "../../models/EParameters";
import {IOptionEntry} from "../../models/IOptionEntry";
import {TasksCalls} from "../../services/TasksCalls";
import {EIndexFunction, SzTableHelper} from "../widgets/helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties, SzTable} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {ELineType, EXLabelAlign, ISzSvgProperties, SzSvg} from "../widgets/SzSvg";
import {ISzSvgDataPoint} from "../../models/ISzSvgDataPoint";
import {captions} from "../../const/Benchmarking";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export class FinancialsAnnReported extends BaseModule<any> {

    protected tableProps: ISzTableProperties<any[]>;
    protected selectOptions: IOptionEntry[] = [];
    private companies = {};

    constructor(props: any, context: any) {
        super(props, context);
        const dataDefaults: string[] = this.props.options;
        this.setKeyOptions(dataDefaults);
        /*
        this.state = {
            selectedKey: "",
            tableLabel: "",
        };
        */
    }
    // Benchmark kennzahlen festlegen
    public setKeyOptions(keys: string[]) {
        this.selectOptions = this.getKeyOptions(keys);
        const key = keys.length ? keys[0] : "";
        this.state = {
            selectedKey: key,
            tableLabel: this.hasLabelPercent(this.getParameterLabel(key)) ? "[% p.a.]" : "",
        };

    }
    public async getDocumentationData() {
        const data = [];
        this.selectOptions.forEach( (i) => {
            data.push({
                name: `${this.props.name}: ${i.label}`,
                data: this.getTableProps(i.value),
            });
        } );
        return data;
    }
    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }

    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const tables = [];
                this.props.options.forEach( (o) => tables.push(this.getTableProps(o)) );
                const result = await tc.createExcelMapTask(tables);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    protected getKeyOptions(keys: string[]): IOptionEntry[] {
        return keys.map((key) => {
            const label = this.getParameterLabel(key).replace(/\s\[% p\.a\.\]$/, "");
            return {label, value: key};
        });
    }
    protected hasLabelPercent(label: string): boolean {
        return label.includes("%");
        // return /\s\[% p\.a\.\]$/.test(label);
    }
    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderContent() {
        return (<div>{this.data && this.data.financials && this.data.financials.length ? this.renderData() : this.noData()}</div>);
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected getParameterLabel(p: string): string {
        return FinKeyLabels.getFinKeyLabel(p);
    }
    protected getCustomData(year: any){
        const key = (field)=> `${year}_${field}`;
    }
    protected renderData() {
        SessionStore.setItem("benchmarking_year", this.data.year);
        const tables: ISzTableProperties<any>[] = [];
        this.props.options.forEach( (o) => tables.push(this.getTableProps(o)) );
        // {tables.map((t, idx) => <div className={"sz-row"}><SzTable {...t} /></div>)}
        return (
            <div style={{paddingTop: 16}}>
                <div className="sz-row">
                    {tables.map((t, idx) => <div className={"sz-col-50"}>{this.renderGfx(this.props.options[idx], t)}</div>)}
                </div>
                {this.renderDlg()}
                <div className="sz-col-100 sz-col">
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </div>
            </div>
        );
    }

    protected noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }
    private renderDlg() {
        // <div className={"sz-row"}><SzTable {...t} /></div>
        const table = this.state.table;
        const show_table = this.state.show_table;
        const table_caption = this.state.table_caption;
        if(!show_table){
            return;
        }
        return (
            <Dialog
                style={{width: 1200, backgroundColor: "transparent", padding: 0}}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={true}
                onClose={() => this.setState({show_table: false})}
            >
                <div className={`${Classes.DIALOG_HEADER} bp3-dark`} style={{backgroundColor: "rgb(57, 75, 89)", paddingLeft: 10}}>
                    <div style={{display: "flex", width: "100%", paddingRight: 10, marginLeft: 10, justifyContent: "flex-start", alignItems: "center"}}>
                        <span style={{fontSize: "100%", fontWeight: 600}}>{table_caption}</span>
                    </div>
                    <Button intent={Intent.DANGER} aria-label={"Schließen"} icon={"small-cross"} minimal={true} onClick={() => this.setState({show_table: false})} />
                </div>
                <div className={Classes.DIALOG_BODY} style={{margin: 0, padding: 10, paddingBottom: 10, backgroundColor: "#ffffff"}}>
                    <div className={"sz-row"}><SzTable {...table} /></div>
                </div>
            </Dialog>
        );
    }

    private getTableProps(selectedKey: string): ISzTableProperties<any> {
        const parameter = selectedKey;
        const year = this.data.year;
        const years = [-4, -3, -2, -1, 0].map((d) => year + d);
        const companies = [].concat(this.data.companies);
        const financials = this.data.financials2;

        const header: ISzTableColumn[] = [];
        header.push({ text: "#" });
        header.push({ text: _t(ETranslation.company) });
        header.push({ text: _t(ETranslation.country) });
        years.forEach((yyyy) => {
            header.push({ text: yyyy.toString(), options: {className: "sz-right", style: {width: "7%"}} });
        });
        header.push({ text: "5-Jahre-Ø", options: {className: "sz-center", style: {width: "20%"} } });

        let cc = 0;
        this.companies = {};



        const table_datas = Object.entries(financials).map(([company_id, finance_datas]) => {
            const company = companies.find((i) => i.company_id === company_id);
            const _c = `row_${cc++}`;
            this.companies[_c] = company;
            // const company = finance_datas.company;
            const company_name = company ? company.company_name : "-";
            const country_iso_3 = company ? company.country_iso_3 : "-";

            const result = [
                _c,
                company_name,
                country_iso_3,
            ];
            let sum = 0; // für durchschnitt
            let N = 0;
            // console.error(this.data);
            years.forEach((y) => {
                if (finance_datas[y]) {
                    const ret = FinAnn.getFinanceData(finance_datas[y]);
                    const finance_data = ret.result;
                    const param_value = finance_data[parameter];
                    if (param_value == null) {
                        result.push("n.m.");
                    } else if (Number.isFinite(param_value)) {
                        result.push(param_value);
                        sum += param_value;
                        N++;
                    } else {
                        result.push(Globals.hyphen);
                    }
                } else {
                    result.push(Globals.hyphen);
                }

            });
            // 5 jahre durchschnitt
            if (N) {
                result.push(sum / N);
            } else {
                result.push("-");
            }
            result.push(company? company.company_id : undefined);
            return result;
        }).sort((a, b) => (new Intl.Collator("de")).compare(a[1].toLowerCase(), b[1].toLowerCase()));
        const formatter = (new Intl.NumberFormat("de-de", { maximumFractionDigits: 1 })).format;
        let min = 0;
        let max = 0;
        const stats = TableStatistics.getTableStatistics(table_datas, {
            pack: true,
            headColumn: 0,
            columns: [3, 4, 5, 6, 7, 8],
        });
        stats.forEach((d, idx) => {
            const v = d[d.length - 1];
            const param = d[0].toLowerCase();
            if ("maximum" === param) {
                max = v;
            }
            if ("minimum" === param) {
                min = v;
            }
        });
        const footerData = stats.map((d, idx) => {
            let sum = 0;
            let N = 0;
            d.forEach((num) => {
                if (Number.isFinite(num)) {
                    sum += num;
                    N++;
                }
            });
            /* nicht nötig! 5 jahres durchschnitt schon in table data drin ...*/
            /*
            if (N) {
                d.push(sum / N);
            } else {
                d.push("-");
            }
            */
            return d;
        });
        const padding_right_max: number = SvgTextSize.measureText(formatter(max)).width;
        const padding_right_min: number = SvgTextSize.measureText(formatter(min)).width;
        const padding_right = Math.max(padding_right_max, padding_right_min);
        const columns: ISzTableColumn[] = [];
        columns.push({ ...SzTableHelper.columnFunction(EIndexFunction.$row_num), options: {style: {width: 10} }}); // index
        columns.push(SzTableHelper.columnIndex("1")); // unternehmen
        columns.push(SzTableHelper.columnCountry("2")); // land

        columns.push({ ...SzTableHelper.columnMoney("3", 1)});
        columns.push({ ...SzTableHelper.columnMoney("4", 1)});
        columns.push({ ...SzTableHelper.columnMoney("5", 1)});
        columns.push({ ...SzTableHelper.columnMoney("6", 1)});
        columns.push({ ...SzTableHelper.columnMoney("7", 1)});
        columns.push({ ...SzTableHelper.columnMoney("8", 1), barchart: {height: 12, min, max, padding_right, classNamePos: "sz-chart-dot-1", classNameNeg: "sz-chart-dot-db"}});

        const footer: ISzTableColumn[] = [
            {index: "0", options: { className: "strong", colSpan: 3 }},
            { ...SzTableHelper.columnMoney("1", 1)},
            { ...SzTableHelper.columnMoney("2", 1)},
            { ...SzTableHelper.columnMoney("3", 1)},
            { ...SzTableHelper.columnMoney("4", 1)},
            { ...SzTableHelper.columnMoney("5", 1)},
            { ...SzTableHelper.columnMoney("6", 1), barchart: {height: 12, min, max, padding_right, classNamePos: "sz-chart-dot-11", classNameNeg: "sz-chart-dot-dy"}},
        ];
        if(Array.isArray(stats) && stats.length > 3){
            if(Array.isArray(stats[3])){
                const benchmarking = stats[3].slice(1);
                SessionStore.setItem(`benchmarking_${selectedKey}`, benchmarking);
            }
        }
        const c = (txt: string)=> {
            if(!txt){
                return "unbenannt";
            }
            const r = /\W/g;
            return txt.replace(r, "").slice(0,30);
        };
        return {
            title: c(captions[selectedKey]),
            colCount: columns.length,
            data: table_datas,
            header: [ header ],
            columns,
            footer,
            footerData,
        };
    }

    private renderGfx(key: string, table: ISzTableProperties<any>) {
        const benchmarkingCustom = SessionStore.get(EParameters.BenchmarkingCustom);
        const year = this.data.year;
        const years = [-4, -3, -2, -1, 0].map((d) => year + d);

        const user_def = [];
        years.forEach((y) =>{
            const finance_datas = FinAnn.getCustomFin(y, benchmarkingCustom);
            const ret = FinAnn.getFinanceData(finance_datas);
            const finance_data = ret.result;
            const param_value = finance_data[key];
            if (param_value == null) {
                user_def.push("n.m.");
            } else if (Number.isFinite(param_value)) {
                user_def.push(param_value);
            } else {
                user_def.push(Globals.hyphen);
            }
        });
        const l25 = Array.isArray(table.footerData) && Array.isArray(table.footerData[2]) ?
            table.footerData[2].slice(1, 6) : (new Array(5)).fill(undefined);
        const a25 = Array.isArray(table.footerData) && Array.isArray(table.footerData[4]) ?
            table.footerData[4].slice(1, 6) : (new Array(5)).fill(undefined);
        const peer = Array.isArray(table.footerData) && Array.isArray(table.footerData[3]) ?
            table.footerData[3].slice(1, 6) : (new Array(5)).fill(undefined);
        user_def.push(user_def[user_def.length - 1]);

        peer.push(peer[peer.length - 1]);
        l25.push(l25[l25.length - 1]);
        a25.push(a25[a25.length - 1]);

        const q25 = (new Array(6)).fill(1).map( (i, idx)=> ({x: idx, y: 0, dataSet: {l: l25[idx], a: a25[idx]}}));


        const xLabels: string[] = years.map((y) => `${y}`);
        const xTicks: number[] =  user_def.map((y, idx) => idx);
        const seriesLegend = [_t(ETranslation.company), _t(ETranslation.median_peer_group), `Q₂,₃ (25% ${_t(ETranslation.to)} 75%)`];
        const xy = (a: any[]) => a.map((i, idx) => ({x: idx, y: i}));
        const properties: ISzSvgProperties = {
            no10PxOffset: true,
            series: [xy(l25), xy(a25), q25, xy(user_def), xy(peer)],
            xLabels,
            xTicks,
            override_colors: [4, 2, 3, 1, 5],
            /*caption: captions[key],*/
            seriesLegend,
            hideLegend: true,
            height: "320px",
            width: "100%",
            yTickStep: 2,
            xAxisLabel: "",
            xLabelsSkip: 0,
            legendWidth: 150,
            lineType: ELineType.rectangle,
            noDots: true,
            colorIdx: 27,
            xLabelsAlign: EXLabelAlign.betweenTick,
            skipRenderSeries: 2,
            onCutomRenderSeriesPath: (index: number, path: string[], _series: ISzSvgDataPoint[], xFactor: number, yFactor: number, offsetH: number) => {
                return this.onCustomRenderSeriesPath(index, path, _series, xFactor, yFactor, offsetH);
            },
        };
        // console.error(properties);
        // console.error(xTicks);
        const openDlg = ()=> {
            this.setState({
                table,
                table_caption: captions[key],
                show_table: true,
            });
        };
        return (
            <div style={{padding: 5}}>
                <Card interactive={true} onClick={()=> openDlg()} style={{padding: 0, paddingLeft: 10, paddingTop: 10}} className={"sz-card-override"}>
                    <div style={{fontSize: "80%"}}>{captions[key]} [% p.a.]</div>
                    <SzSvg {...properties} key={`bench_svg_${key}`} />
                    <div style={{fontSize: "75%", marginTop: -20, paddingBottom: 10}} className={"bp3-text-muted"}>{Array.isArray(FinKeyLabels.definitions[key]) ? FinKeyLabels.definitions[key].map((s)=><div>{s}</div>): undefined}</div>
                </Card>
            </div>
        );
    }
    private onCustomRenderSeriesPath(index: number, path: string[], _series: ISzSvgDataPoint[], xFactor: number, yFactor: number, offsetH: number) {
        // return path;
        if (index === 0 || index === 1) {
            return null;
        }
        if (index > 2) {
            return path;
        }
        // console.error(_series);
        let x1;
        let y1;
        let x2;
        let y2;
        const rects = [];
        _series.forEach( (s, p, ar) => {
            const l25 = s.dataSet.l; // untere q25
            const a25 = s.dataSet.a; // obere q25
            if(!ar[p + 1]){
                return;
            }
            x1 = ar[p].x;
            x2 = ar[p + 1].x;
            y1 = a25;
            y2 = l25;

            const rx_1 = Math.trunc(x1 * xFactor);
            const ry_1 = offsetH - Math.trunc(y1 * yFactor);
            const rx_2 = Math.trunc(x2 * xFactor);
            const ry_2 = offsetH - Math.trunc(y2 * yFactor);

            rects.push({
                x: rx_1,
                y: ry_1,
                h: ry_2 > ry_1 ? ry_2 - ry_1 : ry_1 - ry_2,
                w: rx_2 > rx_1 ? rx_2 - rx_1 : rx_1 - rx_2,
            });
        });
        return (
            <g>
                {rects.map((rect) => <rect x={rect.x} y={rect.y} width={rect.w} height={rect.h} className={`sz-chart-rect sz-chart-rect-custom-1`} />)}
            </g>
        );
    }

}
