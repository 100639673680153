import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";

export class FixedCurrencyParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.FixedCurrencyParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.FixedCurrencyParameter);
    }
    protected async getOptions() {
        return [
            {label: "Alle", value: null},
            {label: "Euroraum (EUR)", value: "EUR"},
            {label: "USA (USD)", value: "USD"},
/*
            {label: "Hongkong (HKD)", value: "HKD"},
            {label: "China (CNY)", value: "CNY"},
            {label: "Australien (AUD)", value: "AUD"},
            {label: "Norwegen (NOK)", value: "NOK"},
            {label: "Schweiz (CHF)", value: "CHF"},
            {label: "Kanada (CAD)", value: "CAD"},
            {label: "Schweden (SEK)", value: "SEK"},
            {label: "Japan (JPY)", value: "JPY"},
            {label: "Vereinigtes Königreich (GBP)", value: "GBP"},
 */
        ];
    }
}
