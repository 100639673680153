import {EParameters} from "../models/EParameters";
import {IOptionEntry} from "../models/IOptionEntry";
import {Globals} from "./Globals";
import {Sectors} from "./Sectors";
import {_t, _ta, _td, country_map} from "../tools/Translator";
import {ETranslation} from "./ETranslation";
import {_t_region} from "./Regions";

const parameterMap = {};

const RoundingRuleOptions: IOptionEntry[] = [
    {label: _t(ETranslation.rounding_2_digits), value: "1", selected: false, short: _t(ETranslation.digits_2)},
    {label: _td(ETranslation.rounding_idw_from_2016), value: "2", selected: false, short: "IDW S1 ab 08/16"},
    {label: _t(ETranslation.rounding_idw_until_2016), value: "3", selected: false, short: "IDW S1 bis 07/16"},
];
parameterMap[EParameters.RoundingRuleParameter] = RoundingRuleOptions;

const BaseInterestCurveOptions: IOptionEntry[] = [
    {label: _t(ETranslation.valuation_date), value: "1", selected: false, short: "ST"},
    {label: _td(ETranslation.average_90_days), value: "2", selected: false, short: `${Globals.average} 90T`},
];
parameterMap[EParameters.BaseInterestCurveParameter] = BaseInterestCurveOptions;

const countryRiskUsageOptions: IOptionEntry[] = [
    {label: _td(ETranslation.not_applied), value: "0", selected: false},
    {label: _t(ETranslation.applied), value: "1", selected: false},
];
parameterMap[EParameters.CountryRiskUsageParameter] = countryRiskUsageOptions;

/*
parameterMap[EParameters.RuntimeParameter] = CreditRunTimes.values;
*/
parameterMap[EParameters.SectorParameter] = Sectors.values;

const fixedCountries = [ "USA", "JPN", "CHE", "GBR", "CHN", "CAN", "AUT", "BEL", "CYP", "EST", "FIN", "FRA", "DEU", "GRC", "IRL", "ITA", "LVA", "LTU", "LUX", "MLT", "NLD", "PRT", "SVK", "SVN", "ESP", "POL", "CZE", "HUN", "DNK", "SWE", "NOR", "TUR", "HRV", "SRB", "ROU", "BGR", "RUS", "KOR", "BRA", "IND", "MEX"];
const fixedCountriesOptions: IOptionEntry[] = fixedCountries.map((f)=>{
    const c = country_map[f];
    return ({
        label: `${c.name} (${c.currency_iso_3})`,
        value: f,
        selected: false,
    });
});
parameterMap[EParameters.FixedCountriesParameter] = fixedCountriesOptions;

const intrestRateCountryOptions: IOptionEntry[] = [
    {label: "EUR (Deutschland)", value: "DEU", selected: false},
    {label: "USD (Vereinigte Staaten von Amerika)", value: "USA", selected: false},
    {label: "JPY (Japan)", value: "JPN", selected: false},
    {label: "CHF (Schweiz)", value: "CHE", selected: false},
    {label: "GBP (Vereinigtes Königreich)", value: "GBR", selected: false},
    {label: "CNY (Volksrepublik China)", value: "CHN", selected: false},
    {label: "CAD (Kanada)", value: "CAN", selected: false},
];
parameterMap[EParameters.IntrestRateCountryParameter] = intrestRateCountryOptions;

const debtBetaUseOptions: IOptionEntry[] = [
    {label: _t(ETranslation.applied), short: _t(ETranslation.applied), value: "anwenden", selected: false},
    {label: _t(ETranslation.debt_beta_application_applied_gearing_100), short: _ta(ETranslation.applied, " > 100%"), value: "anwenden_debt_eq_ratio_over_100", selected: false},
    {label: _t(ETranslation.debt_beta_application_applied_gearing_200), short: _ta(ETranslation.applied, " > 200%"), value: "anwenden_debt_eq_ratio_over_200", selected: false},
    {label: _t(ETranslation.debt_beta_application_applied_gearing_300), short: _ta(ETranslation.applied, " > 300%"), value: "anwenden_debt_eq_ratio_over_300", selected: false},
    {label: _td(ETranslation.not_applied), short: _td(ETranslation.not_applied), value: "nicht_anwenden", selected: false},
];
parameterMap[EParameters.DebtbetaParameter] = debtBetaUseOptions;

const betaUnleverdOptions: IOptionEntry[] = [
    {label:_ta(ETranslation.median,"*"),value:"3",selected:true},
    {label:_t(ETranslation.average),value: "0",selected:false},
    {label:_t(ETranslation.lower_quartile),value:"2",selected:false},
    {label:_t(ETranslation.upper_quartile),value:"4",selected:false}
];
parameterMap[EParameters.BetaUnleverdOptions] = betaUnleverdOptions;

const creditSpreadsOptions: IOptionEntry[] = [
    {label: _td(ETranslation.sector_specific), short: _td(ETranslation.sector_specific), value: "sector", selected: false},
    {label: _t(ETranslation.sector_non_specific), short: _t(ETranslation.sector_non_specific), value: "all_sector", selected: false},
];
parameterMap[EParameters.CreditSpreadsParameter] = creditSpreadsOptions;


const marketRiskPremiumOptions: IOptionEntry[] = [];
for (let a = 0; a < 33; a++) {
    const v = (2 + (a * .25))/100;
    marketRiskPremiumOptions.push({value: "" + v, label: "", selected: false});
}
// {value: "0.04", label: "4 %", selected: false},
parameterMap[EParameters.MarketRiskPremiumParameter] = marketRiskPremiumOptions;

/*export type GearingPeers = {
    ff_debt: boolean;
    ff_pens_liabs_unfunded: boolean;
    operate_lease: boolean;
    ff_cash_only_ff_cash_restr: boolean;
    ff_cash_restr: boolean;
    ff_invest_st_tot: boolean;
    ff_invest_lt_oth_ff_notes_receiv_lt: boolean;
    ff_invest_aff: boolean;
    ff_debt_xlease: boolean;
    ff_debt_ff_debt_xlease: boolean;
};*/
const gearingOptions: IOptionEntry[] = [
    {label: _td(ETranslation.financial_debt_ex_leasing), value: "ff_debt_xlease", selected: true, fixed: true},
    {label: _td(ETranslation.leasing_on_balance), value: "ff_debt_ff_debt_xlease", selected: false},
    {label: _t(ETranslation.leasing_off_balance), value: "operate_lease", selected: false},
    {label: _td(ETranslation.pension_deficit), value: "ff_pens_liabs_unfunded", selected: false},
    {label: "---", value: "---", selected: false},
    {label: _td(ETranslation.cash_excess), value: "ff_cash_only_ff_cash_restr", selected: false},
    {label: _td(ETranslation.cash_restricted), value: "ff_cash_restr", selected: false},
    {label: _t(ETranslation.assets_current_financial), value: "ff_invest_st_tot", selected: false},
    {label: "---", value: "---", selected: false},
    {label: _t(ETranslation.assets_non_current_financial), value: "ff_invest_lt_oth_ff_notes_receiv_lt", selected: false},
    {label: _t(ETranslation.assets_affiliates), value: "ff_invest_aff", selected: false},
];
parameterMap[EParameters.GearingPeersParameter] = gearingOptions;

const negativeVerschuldungOptions: IOptionEntry[] = [
    {label: _td(ETranslation.applied), value: "verwenden", selected: false},
    {label: _t(ETranslation.not_applied), value: "auf_null_setzen", selected: false},
];
parameterMap[EParameters.NegativeVerschuldungParameter] = negativeVerschuldungOptions;

const mahalanobicDistanceOptions: IOptionEntry[] = [
    {value: "average", label: _td(ETranslation.average), selected: false},
    {value: "end", label: _t(ETranslation.valuation_date), selected: false},
];
parameterMap[EParameters.MahalanobicDistanceParameter] = mahalanobicDistanceOptions;

const indexTypeOptions: IOptionEntry[] = [
    {value: "local|local", label: _t(ETranslation.index_national_local_fx), short: "lokale FX", selected: false},
    {value: "regional|EUR", label: _td(ETranslation.index_georegional_eur), short: "Regional EUR", selected: false},
    {value: "global|EUR", label: _t(ETranslation.index_global_eur), short: "Global EUR", selected: false},
    {value: "global|USD", label: _t(ETranslation.index_global_usd), short: "Global USD", selected: false},
];
parameterMap[EParameters.IndexTypeParameter] = indexTypeOptions;

const compositionTypeOptions: IOptionEntry[] = [
    {value: "Benchmark", label: _td(ETranslation.benchmark_index), short: _t(ETranslation.benchmark_index), selected: false},
    {value: "Allshare", label: _t(ETranslation.allshare_index), short: _td(ETranslation.allshare_index), selected: false},
];
parameterMap[EParameters.CompositionTypeParameter] = compositionTypeOptions;

const frequencyOptions: IOptionEntry[] = [
    {label: _t(ETranslation.daily), value: "d", selected: false},
    {label: _t(ETranslation.weekly), value: "w", selected: false},
    {label: _t(ETranslation.biweekly), value: "bw", selected: false},
    {label: _td(ETranslation.monthly), value: "m", selected: false},
];
parameterMap[EParameters.FrequencyParameter] = frequencyOptions;

parameterMap[EParameters.WeekDayParameter] = [
    {label: _t(ETranslation.monday), value: 1, selected: false, short: "MO"},
    {label: _t(ETranslation.tuesday), value: 2, selected: false, short: "DI"},
    {label: _t(ETranslation.wednesday), value: 3, selected: false, short: "MI"},
    {label: _t(ETranslation.thursday), value: 4, selected: false, short: "DO"},
    {label: _td(ETranslation.friday), value: 5, selected: false, short: "FR"},
];


const periodOptions: IOptionEntry[] = [
    {label: _t(ETranslation.year_number, 1), value: "1", selected: false},
    {label: _t(ETranslation.years_number, 2), value: "2", selected: false},
    {label: _t(ETranslation.years_number, 3), value: "3", selected: false},
    {label: _td(ETranslation.years_number, 5), value: "5", selected: false},
];
parameterMap[EParameters.PeriodParameter] = periodOptions;

const returnTypeOptions: IOptionEntry[] = [
    {value: "performance", label: _td(ETranslation.return_type_total_return), short: "TR", selected: false},
    {value: "price", label: _t(ETranslation.return_type_price_return), short: "PR", selected: false},
];
parameterMap[EParameters.ReturnTypeParameter] = returnTypeOptions;

const adjusmentBetaOptions: IOptionEntry[] = [
    {label: _td(ETranslation.raw_beta), value: "rawbeta", short: "Raw Beta", selected: false},
    {label: _t(ETranslation.adjusted_beta), value: "blumeadjusment", short:"Adjusted", selected: false},
    {label: _t(ETranslation.total_beta), value: "totalrawbeta", short:"Total Raw Beta", selected: false},
];
parameterMap[EParameters.AdjusmentBetaParameter] = adjusmentBetaOptions;

const cashflowOptions: IOptionEntry[] = [
    {label: "CAPEX in % Umsatzerlöse", value: "capex_sales_ratio"},
    {label: "CAPEX in % Anlagevermögen", value: "capex_capitalasset_ratio"},
    {label: "Net Working Capital in % Umsatzerlöse", value: "working_sales_ratio"},
];
parameterMap[EParameters.CashflowParameter] = cashflowOptions;

const growthRateCashSurpluseOptions: IOptionEntry[] = [
    {label: "0 %", value: "0"},
    {label: "0,25 %", value: "0.0025"},
    {label: "0,5 %", value: "0.005"},
    {label: "0,75 %", value: "0.0075"},
    {label: "1,0 %", value: "0.01"},
    {label: "1,25 %", value: "0.0125"},
    {label: "1,5 %", value: "0.015"},
    {label: "2,0 %", value: "0.02"},
    {label: "3,0 %", value: "0.03"},
    {label: "4,0 %", value: "0.04"},
    {label: "5,0 %", value: "0.05"},
];
parameterMap[EParameters.GrowthRateCashSurpluseParameter] = growthRateCashSurpluseOptions;

const profitabilityOptions: IOptionEntry[] = [
    {label: "Rohertrags-Marge", value: "gross_sales_ratio"},
    {label: _t(ETranslation.ebitda_margin), value: "ebitda_sales_ratio"},
    {label: _t(ETranslation.ebit_margin), value: "ebit_sales_ratio"},
    {label: "Jahresüberschuss-Marge", value: "netincome_sales_ratio"},
];
parameterMap[EParameters.ProfitabilityParameter] = profitabilityOptions;

const ratingKeyOptions: IOptionEntry[] = [
    {label: "FFO / Debt", value: "ffo_debt_ratio"},
    {label: "Debt / EBITDA", value: "debt_ebitda_ratio"},
    {label: "EBIT / Interest", value: "ebit_interest_ratio"},
    {label: "Debt / Equity", value: "debt_equity_ratio"},
];
parameterMap[EParameters.RatingKeyParameter] = ratingKeyOptions;

const ratingOptions: IOptionEntry[] = [
    {label: "AA", value: "AA"},
    {label: "AA-", value: "AA-"},
    {label: "A+", value: "A+"},
    {label: "A", value: "A"},
    {label: "A-", value: "A-"},
    {label: "BBB+", value: "BBB+"},
    {label: "BBB*", value: "BBB"},
    {label: "BBB-", value: "BBB-"},
    {label: "BB+", value: "BB+"},
    {label: "BB", value: "BB"},
    {label: "BB-", value: "BB-"},
    {label: "B+", value: "B+"},
    {label: "B", value: "B"},
];
parameterMap[EParameters.RatingParameter] = ratingOptions;

const rentabilityOptions: IOptionEntry[] = [
    {label: "ROCE", value: "roce"},
    {label: "ROE", value: "roe"},
];
parameterMap[EParameters.RentabilityParameter] = rentabilityOptions;

const runtimeBaseInterestOptions: IOptionEntry[] = [
    {label: "unendlich", value: "0"},
    {label: _t(ETranslation.year_number, 1), value: "1"},
    {label: _t(ETranslation.years_number, 2), value: "2"},
    {label: _t(ETranslation.years_number, 3), value: "3"},
    {label: _t(ETranslation.years_number, 4), value: "4"},
    {label: _t(ETranslation.years_number, 5), value: "5"},
    {label: _t(ETranslation.years_number, 6), value: "6"},
    {label: _t(ETranslation.years_number, 7), value: "7"},
    {label: _t(ETranslation.years_number, 8), value: "8"},
    {label: _t(ETranslation.years_number, 9), value: "9"},
    {label: _t(ETranslation.years_number, 10), value: "10"},
    {label: _t(ETranslation.years_number, 12), value: "12"},
    {label: _t(ETranslation.years_number, 15), value: "15"},
    {label: _t(ETranslation.years_number, 20), value: "20"},
    {label: _t(ETranslation.years_number, 25), value: "25"},
    {label: _t(ETranslation.years_number, 30), value: "30"},
];
parameterMap[EParameters.RuntimeBaseInterestParameter] = runtimeBaseInterestOptions;

const taxshieldOptions: IOptionEntry[] = [
    {label: _td(ETranslation.tax_shield_risky), value: "unsicher", selected: false},
    {label: _t(ETranslation.tax_shield_non_risky_statutory), value: "sicher", selected: false},
    {label: _t(ETranslation.tax_shield_non_risky_effective_expense), value: "sicher-steueraufwand", selected: false},
    {label: _t(ETranslation.tax_shield_non_risky_effective_cash), value: "sicher-steuerzahlung", selected: false},
];
parameterMap[EParameters.TaxshieldParameter] = taxshieldOptions;

parameterMap[EParameters.DebtBetaCalcParameter] = [
    {label: _td(ETranslation.not_applied), value: "0", selected: false},
    {label: _t(ETranslation.applied_as_calculated), value: "1", selected: false},
    {label: _t(ETranslation.applied_as_inputed), value: "2", selected: false},
];

parameterMap[EParameters.TaxShieldTargetCompanyRiskEffect] = [
    {label: _td(ETranslation.tax_shield_risky), value: "unsicher", selected: false},
    {label: _t(ETranslation.tax_shield_non_risky), value: "sicher", selected: false},
];

parameterMap[EParameters.SectorCountriesParameter] = [
    {label: "Deutschland", value: [771], selected: true, data: { flags: ["de"] }},
    {label: "DACH-Region", value: [771, 705, 901], selected: false, data: { flags: ["de", "at", "ch"] }},
    {label: "Westeuropa", value: [771, 750, 764, 814, 765, 780, 773, 794, 801, 816, 795, 697, 898, 712, 705, 774, 824, 789, 900, 762, 853, 797, 901, 833, 874, 865, 889, 843, 920, 775], selected: false, data: { flags: ["eu-02", "gb", "ch"] }},
    {label: "USA", value: [921], selected: false, data: { flags: ["us"] }},
    {label: "Kanada", value: [730], selected: false, data: { flags: ["ca"] }},
    {label: "Südkorea", value: [888], selected: false, data: { flags: ["kr"] }},
    {label: "Japan", value: [800], selected: false, data: { flags: ["jp"] }},
    {label: "China", value: [736], selected: false, data: { flags: ["cn"] }},
    {label: "Australien", value: [704], selected: false, data: { flags: ["au"] }},
];
parameterMap[EParameters.RegionParameter] = [
    {label: "Alle*", value: null},
    {label: "Westeuropa", value: ["Westeuropa"]},
    {label: "Nordamerika", value: ["Nordamerika"]},
    {label: "Asien & Pazifik", value: ["Asien","Pazifik"]},
    {label: "RoW", value: ["Afrika","Mittlerer Osten","Osteuropa","Südamerika"]},
];
parameterMap[EParameters.RegionParameterV2] = [
    {label: _t_region("Westeuropa")+"*", value: ["Westeuropa"]},
    {label: _t_region("Nordamerika"), value: ["Nordamerika"]},
    {label: `${_t_region("Asien")} & ${_t_region("Pazifik")}`, value: ["Asien","Pazifik"]},
    {label: "RoW", value: ["Afrika","Mittlerer Osten","Osteuropa","Südamerika"]},
];

export class ParameterOptions {
    public static options = parameterMap;
    public static getOptionLabel(parameter: EParameters, state: any, useShort?: boolean): string {
        const options = ParameterOptions.options[parameter];
        const selected = options.find((e) => "" + e.value === "" + state[parameter]);
        const label = useShort && selected ? selected.short : selected ? selected.label : Globals.hyphen;
        return label;
    }
    public static getOptionLabelHardByValue(parameter: EParameters, v: any, useShort?: boolean): string {
        const options = ParameterOptions.options[parameter];
        const selected = options.find((e) => e.value === v);
        const label = useShort && selected ? selected.short : selected ? selected.label : Globals.hyphen;
        return label;
    }
    public static getSelectedOptionsFromObject(parameter: EParameters, value_map: any): string {
        const options: IOptionEntry[] = ParameterOptions.options[parameter];
        const result = []
        options.forEach((o)=>{
            if(value_map[o.value]){
                result.push(o.label);
            }
        });
        return result.length ? result.join(", ") : Globals.hyphen;
    }
    public static getOptionLabelHard(parameter: EParameters, state: any, useShort?: boolean): string {
        return ParameterOptions.getOptionLabelHardByValue(parameter, state[parameter], useShort);
    }
}
parameterMap[EParameters.FixedCurrencyParameter] = [
    {label: "Alle*", value: ""},
    {label: "Euroraum (EUR)", value: "EUR"},
    {label: "USA (USD)", value: "USD"},
    /*
                {label: "Hongkong (HKD)", value: "HKD"},
                {label: "China (CNY)", value: "CNY"},
                {label: "Australien (AUD)", value: "AUD"},
                {label: "Norwegen (NOK)", value: "NOK"},
                {label: "Schweiz (CHF)", value: "CHF"},
                {label: "Kanada (CAD)", value: "CAD"},
                {label: "Schweden (SEK)", value: "SEK"},
                {label: "Japan (JPY)", value: "JPY"},
                {label: "Vereinigtes Königreich (GBP)", value: "GBP"},
     */
];
parameterMap[EParameters.BondSuperSectorParameter] = [
    {label: "Alle*", value: null},
    {label: "Gebrauchsgüter", value: "1100,1200,1300,1400"},
    {label: "Verbrauchsgüter", value: "2100,2200,2300,2400"},
    {label: "Dienstleistungen", value: "3100,3200,3250,3300,3350,3400,3500"},
    {label: "Infrastruktur", value: "4600,4700,4800,4800"},
];
parameterMap[EParameters.SeniorityParameter] = [
    {label: "Alle*", value: ""},
    {label: "vorrangig", value: "Senior"},
    {label: "nachrangig", value: "Subordinate"},
];
parameterMap[EParameters.PledgeStatusParameter] = [
    {label: "Alle*", value: ""},
    {label: "unbesichert", value: "Unsecured"},
    {label: "besichert", value: "Secured"},
];
parameterMap[EParameters.BondEnhancementParameter] = [
    {label: "Alle*", value: ""},
    {label: "unbesichert", value: "Unsecured"},
    {label: "besichert", value: "Secured"},
];
parameterMap[EParameters.BondCallableParameter] = [
    {label: "Alle*", value: ""},
    {label: "unkündbar", value: "0"},
    {label: "kündbar", value: "1"},
];
parameterMap[EParameters.CreditSpreadXRangeParameter] = [
    {label: "100*", value: 100},
    {label: "200", value: 200},
    {label: "300", value: 300},
];
parameterMap[EParameters.CreditSpreadAnalysisIntervalParameter] = [
    {label: "10%", value: .1},
    {label: "20%*", value: .2},
    {label: "30%", value: .3},
    {label: "40%", value: .4},
    {label: "50%", value: .5},
];
parameterMap[EParameters.CreditSpreadDecisionParameterA] = [
    {label: "‑‑", value: 5},
    {label: "‑", value: 4},
    {label: Globals.average, value: 3},
    {label: "+", value: 2},
    {label: "++", value: 1},
];
parameterMap[EParameters.CreditSpreadDecisionParameterB] = [
    {label: "‑‑", value: 5},
    {label: "‑", value: 4},
    {label: Globals.average, value: 3},
    {label: "+", value: 2},
    {label: "++", value: 1},
];
parameterMap[EParameters.CreditSpreadDecisionParameterC] = [
    {label: "‑‑", value: 5},
    {label: "‑", value: 4},
    {label: Globals.average, value: 3},
    {label: "+", value: 2},
    {label: "++", value: 1},
];
parameterMap[EParameters.CreditSpreadDecisionParameterD] = [
    {label: "‑‑", value: 5},
    {label: "‑", value: 4},
    {label: Globals.average, value: 3},
    {label: "+", value: 2},
    {label: "++", value: 1},
];


parameterMap[EParameters.TpFreeFloatParameter] = [
    {label: "Keine Einschränkung", value: null},
    {label: "Größer 50%", value: .5},
    {label: "Größer 75%", value: .75},
];
parameterMap[EParameters.TpMarginComposition] = [
    {label: "Herstellungskosten exkl. Abschreibungen", value: "ff_cogs_xdep"},
    {label: "Abschreibungen", value: "ff_dep_amort_exp"},
    {label: "Vertriebs- und Verwaltungsaufwendungen", value: "ff_sga_oth"},
    {label: "Forschungs- und Entwicklungskosten", value: "ff_rd_exp"},
    {label: "Sonstige betriebliche Aufwendungen", value: "ff_oper_exp_oth"},
];
parameterMap[EParameters.TpOpResult] = [
    {label: "Rohertrag-Marge", value: "ff_gross_inc"},
    {label: _t(ETranslation.ebitda_margin), value: "ff_ebitda"},
    {label: "EBITDA-Marge bereinigt", value: "ff_ebitda_oper"},
    {label: _t(ETranslation.ebit_margin), value: "ff_ebit"},
    {label: "EBIT-Marge bereinigt", value: "ff_ebit_oper"},
    {label: "Freie Definition", value: "free_definition"},
];

parameterMap[EParameters.BetaFilterBidAsk] = [
    {label: _td(ETranslation.inactive), value: 0, short: Globals.hyphen},
    {label: "≤2,0%", value: 2.0},
    {label: "≤1,5%", value: 1.5},
    {label: "≤1,0%", value: 1.0},
    {label: "≤0,5%", value: 0.5},
];
parameterMap[EParameters.BetaFilterFreeFloat] = [
    {label: _td(ETranslation.inactive), value: 0, short: Globals.hyphen},
    {label: "≥25%", value: .25},
    {label: "≥50%", value: .50},
    {label: "≥75%", value: .75},
    {label: "≥90%", value: .90},
];
parameterMap[EParameters.BetaFilterR2] = [
    {label: _td(ETranslation.inactive), value: 0, short: Globals.hyphen},
    {label: "≥5%", value: .05},
    {label: "≥10%", value: .10},
    {label: "≥15%", value: .15},
    {label: "≥25%", value: .25},
];
parameterMap[EParameters.BetaFilterTTest] = [
    {label: _td(ETranslation.inactive), value: 0, short: Globals.hyphen},
    {label: _t(ETranslation.active), value: 1.984, short: "an"},
];
parameterMap[EParameters.BetaFilterDataPoints] = [
    {label: _td(ETranslation.inactive), value: 0, short: Globals.hyphen},
    {label: "≥50%", value: .5,},
    {label: "≥60%", value: .6,},
    {label: "≥70%", value: .7,},
    {label: "≥80%", value: .8,},
];
parameterMap[EParameters.BetaFilterBreuschPaganTest] = [
    {label: _td(ETranslation.inactive), value: 0, short: Globals.hyphen},
    {label: _t(ETranslation.active), value: 1, short: "an"},
];
parameterMap[EParameters.BetaFilterDurbinWatsonTest] = [
    {label: _td(ETranslation.inactive), value: 0, short: Globals.hyphen},
    {label: _t(ETranslation.active), value: 1, short: "an"},
];
export function PARAMETER_OPTION(p: EParameters) {
    return parameterMap[p];
}
