import {CSSProperties} from "react";
import {Icon, Intent, Spinner} from "@blueprintjs/core";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import * as React from "react";
import {IconName, IconNames} from "@blueprintjs/icons";


export interface IShowMessageProps {
    message: string;
    message_type?: "error"|"warning"|"info";
    iconSize?: number;
}
export const ShowMessage = (props: IShowMessageProps) => {
    const div_style: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        // height: 200,
    };
    let icon: IconName = IconNames.ERROR;
    let intent: Intent = Intent.DANGER;
    if(props.message_type==="warning"){
        icon = IconNames.WARNING_SIGN;
        intent = Intent.WARNING;
    }
    if(props.message_type==="info"){
        icon = IconNames.INFO_SIGN;
        intent = Intent.PRIMARY;
    }
    return (
        <div style={div_style}>
            <Icon icon={icon} size={props.iconSize ? props.iconSize : 25} intent={intent} />
            <p className={"bp3-text-muted"} style={{fontSize: "80%"}}>{props.message}</p>
        </div>
    );
}
