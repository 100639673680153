import * as React from "react";
import {CSSProperties, useEffect, useState} from "react";
import {CacheToolsAllParameters} from "../../../helpers/CacheTools";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {SzSmallLoader} from "../../hooks/SzSmallLoader";
import {Button} from "@blueprintjs/core";
import {CourseHistory} from "./CourseHistory";
import {IRawBetaResponse, RawBetaResponse} from "../../../models/chef/RawBeta";
import {ChefCalls} from "../../../services/ChefCalls";
import {_k, adjustArrays} from "../../../helpers/Helpers";
import {TradeData} from "./TradeData";
import {BidAskSpread} from "./BidAskSpread";
import {RawBetaTimeSeries} from "./RawBetaTimeSeries";
import {_t, _ta} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const index_data_cache = CacheToolsAllParameters("index_data_cache");
const stock_data_cache = CacheToolsAllParameters("stock_data_cache");
const marked_data_cache = CacheToolsAllParameters("marked_data_cache");
const xp_data_stock_cache= CacheToolsAllParameters("xp_data_stock_cache");
const raw_beta_series_cache= CacheToolsAllParameters("raw_beta_series_cache");

enum EPage{
    COURSE,
    RETURN_ON_CAPITAL,
    TRADE_VOLUME,
    TRADING_VOLUME_FX,
    TRADING_DAYS,
    BID_ASK_SPREAD,
    FREE_FLOAT,
    BETA_FACTOR,
}
const buttons_left = [
    {caption: _t(ETranslation.stock_price), page: EPage.COURSE},
    {caption: _t(ETranslation.return), page: EPage.RETURN_ON_CAPITAL},
    {caption: _ta(ETranslation.trading_volume, " #"), page: EPage.TRADE_VOLUME},
    {caption: _ta(ETranslation.trading_volume, " FX"), page: EPage.TRADING_VOLUME_FX},
    {caption: _t(ETranslation.trading_days), page: EPage.TRADING_DAYS},
    {caption: _t(ETranslation.bid_ask_spread), page: EPage.BID_ASK_SPREAD},
    {caption: _t(ETranslation.free_float), page: EPage.FREE_FLOAT},
    {caption: _t(ETranslation.beta_factor), page: EPage.BETA_FACTOR},
];
const gfx_labels = (page: EPage)=> {
    switch (page) {
        case(EPage.TRADE_VOLUME): return _t(ETranslation.trading_volume_number);
        case(EPage.TRADING_VOLUME_FX): return _t(ETranslation.trading_volume_fx);
        case(EPage.TRADING_DAYS): return _ta(ETranslation.trading_days, " [#]");
        case(EPage.BID_ASK_SPREAD): return _ta(ETranslation.bid_ask_spread, " [%]");
        case(EPage.FREE_FLOAT): return _ta(ETranslation.free_float, " [#]");
        case(EPage.BETA_FACTOR): return _t(ETranslation.beta_factor_stand_error);
        default: return "unbekannt";
    }
};
async function loadData(company: ICompanyBase, data: any){
    const fx = SessionStore.get(EParameters.LocalTypeParameter);
    const period = SessionStore.get(EParameters.PeriodsParameter);
    const deadline = SessionStore.get(EParameters.DateParameter);

    const raw_beta_parameters_ext = data.raw_beta_parameters_ext;

    const raw_betas = data.raw_betas;
    const raw_beta: IRawBetaResponse = raw_betas[company.id];

    const index_id = raw_beta.stock_index_id;
    const company_id = company.id;
    const years = parseInt(period, 10);

    const db = new ChefCalls();
    const betas_before = {count: years * 12, type: 1, step_size: 1};
    const params = { ...raw_beta_parameters_ext, companies: [company_id], betas_before};
    const raw_betas_ext: RawBetaResponse = await db.getRawBeta(params, raw_beta_series_cache);
    const index_data = await db.getTimeSeriesIndex(fx, index_id, deadline, years, index_data_cache);
    const stock_data = await db.getTimeSeriesCompany(fx, company_id, deadline, years, stock_data_cache);

    const arrays = adjustArrays(stock_data, index_data);

    const market_data = await db.getMarketData(company_id, deadline, years, marked_data_cache);
    const xp_data_stock = await db.getXpDataStock(company_id, deadline, years, xp_data_stock_cache);

    const market_data_dates = [];
    if(Array.isArray(market_data) && market_data.length){
        market_data.forEach((d)=> market_data_dates.push(d.date_decimal));
    }

    return {
        index_data,
        stock_data,
        adj_stock_data: arrays.return_a,
        adj_index_data: arrays.return_b,
        market_data,
        market_data_dates,
        xp_data_stock,
        raw_betas_ext,
    };
}
export interface ITimeSeriesProps {
    company: ICompanyBase;
    data: any;
}
const styleH: CSSProperties = {
    width: "100%",
    color: "#182026",
    fontSize: 14,
    fontWeight: "bold",
    display: "block",
    paddingBottom: 5,
    marginTop: 14,
    marginBottom: 5,
    boxShadow: "inset 0px -1px 0px 0px #cecece",
};
export function TimeSeries(props: ITimeSeriesProps) {
    const [timeSeries, setTimeSeries] = useState(undefined);
    const [page, setPage] = useState(EPage.COURSE);
    const company: ICompanyBase = props.company;
    const data = props.data;

    useEffect(() => {
        (async ()=>{
            const r = await loadData(company, data);
            setTimeSeries(r);
        })();
    }, []);

    if(!timeSeries){
        return (
            <div>
                <div style={{aspectRatio: "1/1"}}>
                    <SzSmallLoader title={""} />
                </div>
            </div>
        );
    }
    const renderButton = (button, idx)=>{
        return (
            <Button key={_k("ts-button", idx)} intent={page === button.page ? "warning" : "none"} onClick={()=>setPage(button.page)} style={{marginBottom: 5}}>{button.caption}</Button>
        );
    };
    const renderPage= ()=>{
        if(page === EPage.COURSE){
            return (<CourseHistory company={company} data={timeSeries} y_label={_t(ETranslation.stock_index_indexed)} />);
        }
        if(page === EPage.RETURN_ON_CAPITAL){
            return (<CourseHistory company={company} data={timeSeries} use_raw_values={true} y_label={_t(ETranslation.stock_price_index_price)} />);
        }

        const mkTradeData = (yKey)=> (<TradeData key={`TradeData_${page}`} company={company} market_data={timeSeries.market_data} market_data_dates={timeSeries.market_data_dates} xAxisKey={"date_decimal"} yAxisKey={yKey} label={gfx_labels(page)} />);

        if(page === EPage.TRADE_VOLUME){
            return mkTradeData("p_volume_avg");
        }
        if(page === EPage.TRADING_VOLUME_FX){
            return mkTradeData("p_price_avg");
        }
        if(page === EPage.TRADING_DAYS){
            return mkTradeData("p_trading_days");
        }
        if(page === EPage.BID_ASK_SPREAD){
            return <BidAskSpread key={`BidAskSpread_${page}`} company={company} xp_data_stock={timeSeries.xp_data_stock} label={gfx_labels(page)} />;
        }
        if(page === EPage.FREE_FLOAT){
            return mkTradeData("free_float"); // (100 - market_data.avg_ff_shs_closely_held_pct) / 100
        }
        if(page === EPage.BETA_FACTOR){
            return <RawBetaTimeSeries key={`RawBetaTimeSeries_${page}`} company={company} raw_betas_ext={timeSeries.raw_betas_ext} data={props.data} label={gfx_labels(page)} />;
        }

        return null;
    };
    return (
        <div className={"sz-row"}>
            <div className={"sz-col sz-col-100"}>
                <div style={styleH}>{_t(ETranslation.time_series)}</div>
                <div className={"sz-row"}>
                    <div className={"sz-col"} style={{width: "calc(100% - 180px)", height: 350, paddingRight: 10}}>
                        {renderPage()}
                    </div>
                    <div className={"sz-col"} style={{width: 180, display: "flex", flexDirection: "column"}}>
                        {buttons_left.map((b, idx)=> renderButton(b, idx))}
                    </div>
                </div>
            </div>
        </div>
    );
}
