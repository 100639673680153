import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {BaseDataProvider} from "./BaseDataProvider";
import {ParameterConfigs} from "../../const/ParameterConfigs";
import {EventBus} from "../../services/EventBus";

const sales_range = [0, 50, 100, 250, 500, 1500, 5000, 10000, 50000, 100000, 300000000];
const employees_range = ParameterConfigs.configs[EParameters.TpNumEmployeesParameter].range;
export class TpProvider extends BaseDataProvider {
    public getYears(): number[]{
        const year = this.mappedParameters.get(EParameters.TpYearsParameter);
        const result: number[] = [];
        const steps = (new Array(3)).fill(0);
        let y: number = year[0];
        steps.forEach(() =>result.push(y--));
        return result.reverse();
    }
    protected initProvider() {
        super.className = "TpProvider";
        this.parameterMapping = [
            EParameters.TpFreeFloatParameter,
            EParameters.TpCountriesParameter,
            EParameters.TpYearsParameter,
            EParameters.TpYearsAverageParameter,
            EParameters.TpSalesParameter,
            EParameters.TpSicClassParameter,
            EParameters.TpNumEmployeesParameter,
            EParameters.TpAnalysisSizeParameter,
            EParameters.TpMarginComposition,
            EParameters.TpOpResult,
            EParameters.TpKeyWordsWordsParameter,
            EParameters.TpKeyWordsOperParameter,
        ];
    }

    protected update() {
        (async ()=>{
            try{

                let ff_sic_classes = [];
                let ff_sic_sub_classes = [];
                const sic_param = SessionStore.get(EParameters.TpSicClassParameter);
                if(Array.isArray(sic_param)){
                    const [classes, sub_classes] = sic_param;
                    ff_sic_classes = classes;
                    ff_sic_sub_classes = sub_classes;
                }
                const [sales_start_idx,sales_stop_idx] = this.getParameter(EParameters.TpSalesParameter, [0, 10]);
                const [employees_start_idx,employees_stop_idx] = this.getParameter(EParameters.TpNumEmployeesParameter, [0, 4]);
                // this.props?.onFinished(sales_range[start], sales_range[stop]);
                const key_words = this.getParameter(EParameters.TpKeyWordsWordsParameter, []);
                const key_words_operation = this.getParameter(EParameters.TpKeyWordsOperParameter, true);

                const params = {
                    years: this.getYears(),
                    country_ids: this.mappedParameters.get(EParameters.TpCountriesParameter),
                    free_float: this.getParameter(EParameters.TpFreeFloatParameter, undefined),
                    sales_start: sales_range[sales_start_idx],
                    sales_stop: sales_range[sales_stop_idx],
                    employees_start: employees_range[employees_start_idx],
                    employees_stop: employees_range[employees_stop_idx],
                    ff_sic_classes,
                    ff_sic_sub_classes,
                    key_words,
                    key_words_operation,
                };
                // console.error(params);

                const db = new PublicDbCalls();
                const r = await db.getTpLonglist(params);
                if(r.error){
                    console.error(r.error);
                }
                if(!Array.isArray(r)){
                    this.afterUpdate({
                        long_list: [],
                        financials: {},
                    });
                    return;
                }
                const company_ids = r.map((c) => c.id);
                const years = this.getYears();
                const financials = await db.getTpFinancials({company_ids, years});
                const fin_mapped = {};
                if(financials){
                    Object.keys(financials).forEach( (k) =>{
                        fin_mapped[k] = {};
                        financials[k].forEach( (f) =>  fin_mapped[k][f.company_id]=f);
                    });
                }
                let all = 0;
                if(r.length>0){
                    all = parseInt(r[0].all, 10);
                }
                EventBus.emit("TpProvider::longlist", all);
                this.afterUpdate({
                    long_list: r,
                    financials: fin_mapped,
                });
                return;
            }catch(exi){
                console.error(exi);
            }
            this.afterUpdate({
                long_list: [],
                financials: {},
            });
        })();
    }
}
