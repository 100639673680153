// https://react-chartjs-2.netlify.app/examples/doughnut-chart
import * as React from "react";
import {NonIdealState} from "@blueprintjs/core";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {Doughnut} from "react-chartjs-2";
import {IconNames} from "@blueprintjs/icons";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface ISzSzDoughnutChartProps {
    title?: string;
    labels: string[];
    data_sets: any[];
    no_data_desc?: string;
    legend_position?: any;
    legend_align?: any;
    no_hover?: boolean;
    no_tooltip?: boolean;
    formatter?(value);
}

export class SzDoughnutChart  extends React.Component<ISzSzDoughnutChartProps,any>{

    constructor(props: ISzSzDoughnutChartProps, context: any) {
        super(props, context);
        //
    }

    public render(){
        // if (!this.props.labels.length) {
        //     return this.noData();
        // }
        const data = {
            labels: this.props.labels,
            datasets: this.props.data_sets,
        };
        const options = {
            plugins: {
                title: {
                    display: this.props.title ? true : false,
                    text: this.props.title,
                },
                legend: {
                    position: this.props.legend_position ? this.props.legend_position : "right" as "right",
                    align: this.props.legend_align ? this.props.legend_align : "end" as "end",
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            hover : this.props.no_tooltip ? {mode: null} : undefined,
            tooltip: {
                enabled: this.props.no_tooltip ? false : this.props.labels.length ? true : false,
            }
        };
        const o = {
            display: this.props.labels.length > 0,
            color: "white",
            formatter: (value, context) => {
                if(this.props.formatter){
                    return this.props.formatter(value);
                }
                return value ? value : "";
            }
        };
        Object.assign(options.plugins, {datalabels: o});
        return <Doughnut data={data} options={options} />;
    }
    private noData() {
        const desc = this.props.no_data_desc ? this.props.no_data_desc : "Datum ist zu früh oder keine Werte";
        return (
            <div style={{padding: 32, width: "100%"}}>
                <NonIdealState
                    icon={IconNames.DOUGHNUT_CHART}
                    title="Keine Daten"
                    description={desc}
                />
            </div>
        );
    }
}
