export enum EFileManagerEntryTypes {
    folder= 1,
    file,
}
export enum EFileManagerEntryState {
    active= 1,
    deleted= -1,
}

export interface IFileManagerEntry {
    _id: string;
    entry_type: EFileManagerEntryTypes;
    parent_folder_id: string;
    name: string;
    date: string;
    state: EFileManagerEntryState;
    isSelected?: boolean;
}

export interface IFileManagerLoadFolder {
    parent_folder_id: string;
    entries: IFileManagerEntry[];
}
export interface IFileManagerPath {
    name: string;
    _id: string;
}
export interface IFileManagerPathResult {
    entries: IFileManagerPath[];
}
