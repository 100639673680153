let internal_id = 1;
export interface IEventHandler {
    name: string;
    eventId: number;
    callback: (data: any) => void;
}
export interface IEvent {
    handlers: IEventHandler[];
}
export class EventBus {
    public static emit<K>(name: string, data: K): void {
        const e_name = `e_${("" + name.toLowerCase())}`;
        const known = this.events.get(e_name);
        if (known) {
            known.handlers.forEach((handler) => {
                (async () => {
                    try {
                        handler.callback(data);
                    } catch (exi) {
                        throw exi;
                    }
                })();
            });
        }
    }
    public static subscribe<K>(name: string, callback: (data: K) => void): IEventHandler {
        const e_name = `e_${("" + name.toLowerCase())}`;
        let known = this.events.get(e_name);
        if (!known) {
            known = {
                handlers: [],
            };
            this.events.set(e_name, known);
        }
        const e_handler = {
            callback,
            eventId: internal_id++,
            name: e_name,
        };
        known.handlers.push(e_handler);
        return e_handler;
    }
    public static unsubscribe(eventHandler: IEventHandler): void {
        if (!eventHandler) {
            return;
        }
        const known = this.events.get(eventHandler.name);
        if (known) {
             const idx = known.handlers.findIndex((e) => e.eventId === eventHandler.eventId);
             known.handlers.splice(idx, 1);
         }
    }

    private static events: Map<string, IEvent> = new Map<string, IEvent>();
}
