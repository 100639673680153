import {Classes, Colors, HTMLTable, Icon, Intent, Menu, MenuItem, NonIdealState,} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Tooltip2} from "@blueprintjs/popover2";
import * as React from "react";
import {CSSProperties} from "react";
import {ErrorTypes, IErrorType} from "../../const/ErrorTypes";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {mean, median, sum} from "../../helpers/Statistics";
import {
    BetaFilterResponse,
    IBetaFilterResponse,
    IRawBetaResponse,
    IRegression,
    RawBetaResponse
} from "../../models/chef/RawBeta";
import {ICompanySearchResult} from "../../models/ICompanySearchResult";
import {TasksCalls} from "../../services/TasksCalls";
import {ICompanyBase} from "../../tables/CompanyBase";
import {SzTableHelper} from "../widgets/helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties, ISzTagOptions, SzTable} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {IUnleveredBeta, IUnleveredBetaResponse, UnleveredBetaResponse} from "../../models/chef/UnleveredBeta";
import {EParameters} from "../../models/EParameters";
import {BetaFilterState, PERIOD_DATA_POINTS} from "../../helpers/BetaFilterState";
import {EStatisticsValueType} from "../../const/EStatisticsValueType";
import {fromDateDecimal, fromDateParameter, fromStr} from "../../tools/DateTools";
import {renderLongListAction} from "./company_info/TableHelper";
import {renderDebtDlg, selectRow} from "./company_info/CompanyInfoDlg";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {EBorder, PdfTable} from "../../pdf-tools/PdfTable";
import {_fmt, _k} from "../../helpers/Helpers";

enum EShowBeta {
    RAW_BETA= "Raw Beta",
    ADJ_RAW_BETA= "Adj. Raw Beta",
    UNLEVERED_BETA= "Unlevered Beta",
}

const all_periods = ["1d", "1w", "2d", "2w", "2bw", "3d", "3w", "3bw", "5w", "5bw", "5m"];
const EPeriodToIndex = {
    "1d": 0, "1w": 1,
    "2d": 2, "2w": 3, "2bw": 4,
    "3d": 5, "3w": 6, "3bw": 7,
    "5w": 8, "5bw": 9, "5m": 10,
};
const ColIdxToBetaFilter= {
    0: 1, 1: 1,
    2: 2, 3: 2, 4: 2,
    5: 3, 6: 3, 7: 3,
    8: 5, 9: 5, 10: 5,
};
const header_periods = {
    "1d": _t(ETranslation.daily), "1w": _t(ETranslation.weekly),
    "2d": _t(ETranslation.daily), "2w": _t(ETranslation.weekly), "2bw": _t(ETranslation.biweekly),
    "3d": _t(ETranslation.daily), "3w": _t(ETranslation.weekly), "3bw": _t(ETranslation.biweekly),
    "5w": _t(ETranslation.weekly), "5bw": _t(ETranslation.biweekly), "5m": _t(ETranslation.monthly),
};
const getRawBetaValue = (raw_beta: IRawBetaResponse, p: string) => {
    if(raw_beta && raw_beta.periods && raw_beta.periods[p]){
        return raw_beta.periods[p].Slope;
    }else{
        return NaN;
    }
};
const getRawBetaRegression = (raw_beta: IRawBetaResponse, p: string): IRegression => {
    if(raw_beta && raw_beta.periods && raw_beta.periods[p]){
        return raw_beta.periods[p];
    }else{
        return {} as IRegression;
    }
};
const getAdjRawBetaValue = (raw_beta: IRawBetaResponse, p: string) => {
    const rb = getRawBetaValue(raw_beta, p);
    if(rb === null){
        return NaN;
    }else{
        return rb * 2 / 3 + 1 / 3;
    }
};
const getUnleveredBetaValue = (unlevered_beta: IUnleveredBetaResponse, p: string) => {
    if(unlevered_beta && unlevered_beta[p]){
        const ub: IUnleveredBeta = unlevered_beta[p];
        return ub && ub.result ? ub.result.unlevered_beta : NaN;
    }else{
        return NaN;
    }
};

export class BetasCompare extends BaseModule<any> {
    get cleanTable(): ISzTableProperties<any[]> {
        const cleanTable = Object.assign({}, this.tableProps);
        return cleanTable;
    }

    private data_debt_beta: ISzTableProperties<any[]>;
    private data_tax_shield: ISzTableProperties<any[]>;
    private data_debt: ISzTableProperties<any[]>;
    private companies: ICompanySearchResult[];
    private tableProps: ISzTableProperties<any[]>;
    private tableData: any[] = [];

    private more_footer: any[] = [];
    private filtered;

    constructor(props: any, context: any) {
        super(props, context);
        const s = {
            loading: true,
            show_unlevered_beta: EShowBeta.UNLEVERED_BETA,
        };
        this.state = BetaFilterState.merge(s);
        BetaFilterState.listen(this);
    }
    public async getDocumentationData() {
        return this.cleanTable;
    }
    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }
    public getExcelData(target) {
        target.data_betas_cmp = this.tableProps.data;
    }
    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.cleanTable);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    public exportAsExcelMappe() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelMapTask([
                    this.data_debt_beta,
                    this.data_tax_shield,
                    this.data_debt,
                ]);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
                Globals.trackInfo(EStatisticsValueType.excel_export, "unlevered_beta_details");
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected renderContent() {
        // console.error(this.data);
        const companies = this.data.companies;
        const company_ids: any[] = this.data.company_ids;
        const raw_betas: RawBetaResponse = this.data.raw_betas;
        const unlevered_betas: UnleveredBetaResponse = this.data.unlevered_betas;

        const show_beta: EShowBeta = this.state.show_unlevered_beta;

        const beta_filter_data: BetaFilterResponse = this.data.beta_filter_data;
        this.tableData = [];
        const best_col_data = {};
        let num_row = 1;
        company_ids.forEach((company_id, cidx) => {
            const company: ICompanyBase = companies[company_id];
            const raw_beta: IRawBetaResponse = raw_betas[company_id];
            const unlevered_beta: IUnleveredBetaResponse = unlevered_betas[company_id];
            const beta_filter: IBetaFilterResponse = beta_filter_data[company_id];
            if(!company){
                return;
            }
            const row = [];
            this.tableData.push(row);
            row[0] = company_id;
            row[1] = company.name;
            if(!this.state.show_company[company_id]){
                row[15] = NaN;
            }else{
                row[15] = num_row++;
            }

            const offset = 2;
            all_periods.forEach((p, idx)=>{
                if(!best_col_data[p]){
                    best_col_data[p] = {
                        bid_ask_spread: [],
                        r2: [],
                    };
                }
                const bid_ask_spread = beta_filter ? beta_filter[`bid_ask_spread_${ColIdxToBetaFilter[idx]}`] : 0;
                const regression = getRawBetaRegression(raw_beta, p);

                best_col_data[p].bid_ask_spread.push(bid_ask_spread);
                best_col_data[p].r2.push(regression.R2);

                if(!this.state.show_company[company_id]){
                    row[idx + offset] = NaN;
                }

                if(show_beta === EShowBeta.RAW_BETA){
                    row[idx + offset] = getRawBetaValue(raw_beta, p);
                }
                if(show_beta === EShowBeta.ADJ_RAW_BETA){
                    row[idx + offset] = getAdjRawBetaValue(raw_beta, p);
                }
                if(show_beta === EShowBeta.UNLEVERED_BETA){
                    row[idx + offset] = getUnleveredBetaValue(unlevered_beta, p);
                }
            });
        });
        let best_col = -1;
        let min_val = Number.MAX_VALUE;
        let max_val = Number.MIN_VALUE;
        let return_settings_best = -1;

        all_periods.forEach((p, idx)=>{
            const b: {r2: [], bid_ask_spread: []} = best_col_data[p];
/*
            const arr_bid_ask_spread = b.bid_ask_spread
                .map((x)=> x >= 0 && x <= .02 ? x : .02)
                .map((x)=>  1 - x * 50);
*/
            // console.error(p, b, arr_bid_ask_spread);
            const qv = mean(b.bid_ask_spread);
            const r2 = mean(b.r2);
            // console.error(p, qv);
            if(qv < min_val){
                min_val = qv;
                best_col = idx + 1;
            }
            if(r2 > max_val){
                max_val = r2;
                return_settings_best = idx + 1;
            }
        });
        // console.error(min_val, best_col);
        return (<div>{this.tableData.length ? this.renderData(this.tableData, best_col, return_settings_best) : this.noData()}</div>);
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    private renderData(table: any[], best_col: number, return_settings_best: number) {
        // console.error(table);
        const period = SessionStore.get(EParameters.PeriodsParameter);
        const hl_col = EPeriodToIndex[period] + 1;
        // console.error(years, frq, label);
        const header_0: ISzTableColumn[] = [
            { text: ""},
            { text: _t(ETranslation.company), options: {colSpan: 2} },
            { text: `1 ${_t(ETranslation.year)}`, options: {className: "sz-center", colSpan: 2} },
            { text: `2 ${_t(ETranslation.years)}`, options: {className: "sz-center", colSpan: 3} },
            { text: `3 ${_t(ETranslation.years)}`, options: {className: "sz-center", colSpan: 3} },
            { text: `5 ${_t(ETranslation.years)}`, options: {className: "sz-center", colSpan: 3} },
        ];
        const getClassName = (col_num)=>{
            const classNames = ["sz-right"];
            if (col_num === hl_col) {
                classNames.push("hover-col");
            }
            return classNames.join(" ");
        };
        const getHeader = (p) => {
            const label = header_periods[p];
            const idx = EPeriodToIndex[p] + 1;
            const stars = [];
            if(idx === best_col){
                stars.push(<Icon icon={IconNames.STAR} key={"star1"} size={14} color={"rgb(91,181,241)"}/>);
            }
            if(idx === return_settings_best){
                stars.push(<Icon icon={IconNames.STAR} key={"star2"} size={14} color={"rgb(19, 124, 189)"}/>);
            }
            return (
                <div style={{width: "100%"}}>
                    <div>{label}</div>
                    <div>{stars.map((i)=>i)}</div>
                </div>
            );
        };
        const header: ISzTableColumn[] = [
            { text: "" },
            { text: "#", options: {className: "sz-right"} },
            { text: "Name" },
            { text: getHeader("1d"), options: {className: getClassName(1)} },
            { text: getHeader("1w"), options: {className: getClassName(2)} },

            { text: getHeader("2d"), options: {className: getClassName(3)} },
            { text: getHeader("2w"), options: {className: getClassName(4)} },
            { text: getHeader("2bw"), options: {className: getClassName(5)} },

            { text: getHeader("3d"), options: {className: getClassName(6)} },
            { text: getHeader("3w"), options: {className: getClassName(7)} },
            { text: getHeader("3bw"), options: {className: getClassName(8)} },

            { text: getHeader("5w"), options: {className: getClassName(9)} },
            { text: getHeader("5bw"), options: {className: getClassName(10)} },
            { text: getHeader("5m"), options: {className: getClassName(11)} },
        ];
        const op_1 = (col_num): ISzTagOptions => {
            const classNames = [];
            if (col_num === hl_col) {
                classNames.push("hover-col");
            }

            return { style: {width: 100}, className: classNames.join(" ") };
        };
        const op_2 = (col_num): ISzTagOptions => {
            const classNames = [];
            if (col_num === hl_col) {
                classNames.push("hover-col");
            }

            return { style: {borderRight: "4px solid #ffffff", width: 100}, className: classNames.join(" ") };
        };

        const footer: ISzTableColumn[] = [
            {index: "0", options: { className: "strong", colSpan: 3 }},
            { ...SzTableHelper.columnMoney("1", 2, op_1(1))},
            { ...SzTableHelper.columnMoney("2", 2, op_1(2))},
            { ...SzTableHelper.columnMoney("3", 2, op_1(3))},
            { ...SzTableHelper.columnMoney("4", 2, op_1(4))},
            { ...SzTableHelper.columnMoney("5", 2, op_1(5))},
            { ...SzTableHelper.columnMoney("6", 2, op_1(6))},
            { ...SzTableHelper.columnMoney("7", 2, op_1(7))},
            { ...SzTableHelper.columnMoney("8", 2, op_1(8))},
            { ...SzTableHelper.columnMoney("9", 2, op_1(9))},
            { ...SzTableHelper.columnMoney("10", 2, op_1(10))},
            { ...SzTableHelper.columnMoney("11", 2, op_1(11))},
        ];
        // company_details
        const years = parseInt(period, 10);
        const date_decimal_end = fromDateParameter().asDateDecimal(); // parseInt(moment(this.parameters.DateParameter[0], "DD.MM.YYYY").format("YYYYMMDD"), 10);
        const date_decimal_start = fromDateParameter().startOf("month").minus({year: years}).endOf("month").asDateDecimal();


        const chk_fin_ann_reported = (companyId) => {
            return false;
        };
        const last_fin_date = (companyId) => {
            return "";
        };
        const renderCompanyName = (company_name, last_fin_data, hide_company)=> {
            const css:CSSProperties = {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
            };
            if(hide_company){
                css.color = "rgba(16, 22, 26, 0.15)";
            }
            return (
                <div style={css}>
                    <span>{company_name}</span>
                    <span style={{fontSize: "60%"}}>{last_fin_data}</span>
                </div>
            );
        };
        const renderWarning = (company_name, last_fin_data, title, type: IErrorType = ErrorTypes.warning) => {
            return (
                <div title={title} style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <span style={{color: type.color}}>{company_name}</span>
                    <div>
                        <Icon icon={IconNames.WARNING_SIGN} size={16} intent={type.intent}/>
                        <span style={{fontSize: "60%", paddingLeft: 5}}>{last_fin_data}</span>
                    </div>
                </div>
            );
        };
        const company_details = this.data.companies;
        const aktion_col: ISzTableColumn = {
            index: "",
            options: {
                style: {borderRight: "4px solid #ffffff", width: 60, verticalAlign: "middle"},
                cellRenderer: (cellValue: any, data?: any) => {
                    return renderLongListAction(this, company_details[data[0]]);
                },
            },
        };
        const name_col: ISzTableColumn = {
            index: "1",
            options: {
                style: {borderRight: "4px solid #ffffff"},
                cellRenderer: (cellValue: any, data?: any) => {
                    // console.error(data);
                    const details: ICompanyBase = company_details[data[0]];
                    // console.error(details);
                    if (!details) {
                        return (<span>{cellValue}</span>);
                    }

                    const n_p_first_date = fromStr(details.p_first_date, "LL/dd/yyyy").asDeDate();
                    const n_p_last_date = fromDateDecimal(details.p_last_date).asDeDate();
                    const p_first_date = fromStr(details.p_first_date, "LL/dd/yyyy").asDateDecimal();
                    const p_last_date = details.p_last_date;
                    const warn = date_decimal_start < p_first_date || p_last_date < date_decimal_end;
                    const warn_2 = false;
                    const fin_date = last_fin_date(data[0]);

                    let title = p_last_date < date_decimal_end ? `Delisted seit ${n_p_last_date}` : date_decimal_start < p_first_date ? `IPO(${n_p_first_date}) liegt im Betrachtungszeitraum` : "";
                    if (warn_2) {
                        title += `Das "Unlevered Beta" ist größer als das "Levered Beta".`;
                    }

                    const hide_company = !this.state.show_company[data[0]];
                    let name_cell = renderCompanyName(cellValue, fin_date, hide_company);

                    const cn = ["sz-cell-action"];
                    let et: any = ErrorTypes.warning;
                    if(hide_company){
                        et = ErrorTypes.disabled;
                    }


                    if (warn || warn_2) {
                        name_cell = renderWarning(cellValue, fin_date, title, et);
                    }

                    if (chk_fin_ann_reported(data[0])) {
                        name_cell = renderWarning(cellValue, fin_date, "Der letzte vorliegende Finanzbericht zu diesem Unternehmen ist älter als 18 Monate.", hide_company ? ErrorTypes.disabled : ErrorTypes.error);
                    }
                    return (
                        <div className={cn.join(" ")}>
                            {name_cell}
                        </div>
                    );
                },
            },
        };
        // console.table(table);
        const raw_betas: RawBetaResponse = this.data.raw_betas;
        const beta_filter_data: BetaFilterResponse = this.data.beta_filter_data;

        const tableProps: ISzTableProperties<any[]> = {
            id: "idTabBetasCompare",
            colCount: 13,
            rowIndexField: "0",
            rowIndexPrefix: "tbc_c_id",
            bar_code: this.getBarCode(),
            data: table,
            rowHover: true,
            onSelectRow: (a, b) => {this.onSelectRow(a, b); },
            footerData: [],
            footer:[],
            addFooter: () => {
                let canceled = false;
                const more_footer = all_periods.map(()=>[]);// [[], [], [], [], [], [], [], [], [], [], []];
                table.forEach( (table_row: any[]) => {
                    table_row.forEach( (cellValue, celNum) => {
                        const cellIdx = celNum - 2;
                        if (cellIdx < 0) {
                            return;
                        }

                        if(cellIdx >= all_periods.length){
                            return;
                        }
                        const company_id = table_row[0];
                        const raw_beta: IRawBetaResponse = raw_betas[company_id];
                        const beta_filter: IBetaFilterResponse = beta_filter_data[company_id];
                        const col_period = all_periods[cellIdx];
                        const regression: IRegression = raw_beta && raw_beta.periods && raw_beta.periods[col_period] ? raw_beta.periods[col_period] : {} as IRegression;
                        let canceled_field = 0;

                        if(!this.state.show_company[company_id]){
                            canceled_field = 1;
                        }

                        const dp = PERIOD_DATA_POINTS[col_period];
                        if(this.state.filterDataPoints !== 0 && regression.DataPoints<= dp * this.state.filterDataPoints){
                            canceled_field = 2;
                        }

                        if (this.state.filterR2 && this.state.filterR2 !== 0  && regression.R2 <  this.state.filterR2) {
                            canceled_field = 3;
                        }
                        if (this.state.filterTTest && this.state.filterTTest !== 0  && regression.TTestSlope < this.state.filterTTest ) {
                            canceled_field = 4;
                        }
                        if (this.state.filterDurbinWatsonTest && this.state.filterDurbinWatsonTest !== 0  && !(regression.DurbinWatsonTest > 1.59 && regression.DurbinWatsonTest < 2.41)) {
                            canceled_field = 5;
                        }
                        if (this.state.filterBreuschPaganTest && this.state.filterBreuschPaganTest !== 0  && regression.BreuschPaganTest < 3.841) {
                            canceled_field = 6;
                        }

                        if(beta_filter){
                            const free_float = `free_float_${ColIdxToBetaFilter[cellIdx]}`;
                            const bid_ask_spread = `bid_ask_spread_${ColIdxToBetaFilter[cellIdx]}`;
                            if (this.state.filterBidAsk && this.state.filterBidAsk !== 0 && beta_filter[bid_ask_spread] >  this.state.filterBidAsk / 100) {
                                canceled_field = 7;
                            }
                            if (this.state. filterFreeFloat&& this.state.filterFreeFloat !== 0  && beta_filter[free_float] < this.state.filterFreeFloat) {
                                canceled_field = 8;
                            }
                        }
                        const v = parseFloat(cellValue);
                        if(cellIdx === 0){
                            // console.error(canceled_field, cellIdx, cellValue);
                        }

                        if (!canceled_field && !isNaN(v)) {
                            more_footer[cellIdx].push(v);
                        }else{
                            canceled = true;
                        }
                    });

                });
                // console.error(canceled);
                const txt_color = undefined; // "#106BA3";
                this.more_footer = more_footer;
                this.filtered = canceled;
                return (
                    <>
                        <tr>
                            <td>&nbsp;</td>
                            <td colSpan={2}><div style={{width: "100%", fontWeight: "bold", display: "flex", alignItems: "center", color: txt_color}}><span style={{marginLeft: 5}}>{canceled ? _t(ETranslation.average_filtered) : _t(ETranslation.average)}</span></div></td>
                            {more_footer.map( (f, cl) => (<td key={_k("bc1", cl)} className={`strong sz-number ${hl_col === cl + 1 ? "hover-col" : ""}`} style={{width: 100, fontWeight: "bold", color: txt_color}}>{Globals.formatter(mean(f), 2, 2, true)}</td>) )}
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td colSpan={2}><div style={{width: "100%", fontWeight: "bold", display: "flex", alignItems: "center", color: txt_color}}><span style={{marginLeft: 5}}>{canceled ? _t(ETranslation.median_filtered) : _t(ETranslation.median)}</span></div></td>
                            {more_footer.map( (f, cl) => (<td key={_k("bc2", cl)} className={`strong sz-number ${hl_col === cl + 1 ? "hover-col" : ""}`} style={{width: 100, fontWeight: "bold", color: txt_color}}>{Globals.formatter(median(f), 2, 2, true)}</td>) )}
                        </tr>
                    </>
                );

            },
            beforeRenderCell: (cellValue: any, rowNum: number, celNum: number, definition: ISzTableColumn, options: ISzTagOptions) => {
                if (rowNum >= table.length) {
                    return;
                }
                const cellIdx = celNum - 3;
                if (cellIdx < 0) {
                    return;
                }
                const company_id = table[rowNum][0];
                const raw_beta: IRawBetaResponse = raw_betas[company_id];
                const beta_filter: IBetaFilterResponse = beta_filter_data[company_id];
                const col_period = all_periods[cellIdx];
                const regression: IRegression = raw_beta && raw_beta.periods && raw_beta.periods[col_period] ? raw_beta.periods[col_period] : {} as IRegression;
                let canceled_field = 0;

                if(!this.state.show_company[company_id]){
                    canceled_field = 1;
                }

                const dp = PERIOD_DATA_POINTS[col_period];
                if(this.state.filterDataPoints !== 0 && regression.DataPoints<= dp * this.state.filterDataPoints){
                    canceled_field = 2;
                }

                if (this.state.filterR2 && this.state.filterR2 !== 0  && regression.R2 < this.state.filterR2) {
                    canceled_field = 3;
                }
                if (this.state.filterTTest && this.state.filterTTest !== 0  && regression.TTestSlope < this.state.filterTTest ) {
                    canceled_field = 4;
                }
                if (this.state.filterDurbinWatsonTest && this.state.filterDurbinWatsonTest !== 0  && !(regression.DurbinWatsonTest > 1.59 && regression.DurbinWatsonTest < 2.41)) {
                    canceled_field = 5;
                }
                if (this.state.filterBreuschPaganTest && this.state.filterBreuschPaganTest !== 0  && regression.BreuschPaganTest < 3.841) {
                    canceled_field = 6;
                }

                if(beta_filter){
                    const free_float = `free_float_${ColIdxToBetaFilter[cellIdx]}`;
                    const bid_ask_spread = `bid_ask_spread_${ColIdxToBetaFilter[cellIdx]}`;
                    if (this.state.filterBidAsk && this.state.filterBidAsk !== 0 && beta_filter[bid_ask_spread] >  this.state.filterBidAsk / 100) {
                        canceled_field = 7;
                    }
                    if (this.state. filterFreeFloat&& this.state.filterFreeFloat !== 0  && beta_filter[free_float] < this.state.filterFreeFloat) {
                        canceled_field = 8;
                    }
                }
                const v = parseFloat(cellValue);
                if(cellIdx === 0){
                    // console.error(canceled_field, cellIdx, cellValue);
                }
                options.className = canceled_field ? `${options.className} sz-cell-canceled` : options.className;
            },
            header: [ header_0, header ],
            columns: [
                aktion_col,
                {...SzTableHelper.columnMoney("15" , 0, {style: {width: 10}})},
                name_col,
                { ...SzTableHelper.columnMoney("2" , 2, op_1(1))},
                { ...SzTableHelper.columnMoney("3" , 2, op_2(2))},
                { ...SzTableHelper.columnMoney("4" , 2, op_1(3))},
                { ...SzTableHelper.columnMoney("5" , 2, op_1(4))},
                { ...SzTableHelper.columnMoney("6" , 2, op_2(5))},
                { ...SzTableHelper.columnMoney("7" , 2, op_1(6))},
                { ...SzTableHelper.columnMoney("8" , 2, op_1(7))},
                { ...SzTableHelper.columnMoney("9" , 2, op_2(8))},
                { ...SzTableHelper.columnMoney("10" , 2, op_1(9))},
                { ...SzTableHelper.columnMoney("11" , 2, op_1(10))},
                { ...SzTableHelper.columnMoney("12" , 2, op_1(11))},
            ],
        };
        this.tableProps = tableProps;
        const chkFilter = (filterValue, filterName, text) => {
            if (!filterValue || filterValue === 0) {
                return filterName;
            }
            return text;
        };
        const notes = [
            `*) ${_t(ETranslation.beta_unlevered_based_on, BetaFilterState.getUnleveredBetaBase())}`,
            `¹) ${chkFilter(this.state.filterBidAsk, _t(ETranslation.filter_bid_ask_spread_inactive),_t(ETranslation.filter_bid_ask_spread_active, _fmt.m(this.state.filterBidAsk, 1)))}`,
            `²) ${chkFilter(this.state.filterFreeFloat, _t(ETranslation.filter_free_float_inactive), _t(ETranslation.filter_free_float_active, _fmt.p(this.state.filterFreeFloat, 0)))}`,
            `³) ${chkFilter(this.state.filterR2, _t(ETranslation.filter_r_square_inactive), _t(ETranslation.filter_r_square_active, _fmt.p(this.state.filterR2, 0)))}`,
            `⁴) ${chkFilter(this.state.filterDataPoints, _t(ETranslation.filter_data_points_inactive), _t(ETranslation.filter_data_points_active, _fmt.p(this.state.filterDataPoints, 0)))}`,
            `${Globals.superscript(5)}) ${chkFilter(this.state.filterTTest,  _t(ETranslation.filter_beta_factor_t_test_inactive), _t(ETranslation.filter_beta_factor_t_test_active, _fmt.m(this.state.filterTTest, 0)))}`,
            `${Globals.superscript(6)}) ${chkFilter(this.state.filterDurbinWatsonTest, _t(ETranslation.filter_durbin_watson_inactive), _t(ETranslation.filter_durbin_watson_active))}`,
            `${Globals.superscript(7)}) ${chkFilter(this.state.filterBreuschPaganTest, _t(ETranslation.filter_breusch_pagan_inactive), _t(ETranslation.filter_breusch_pagan_active))}`,
        ];
        // show_unlevered_beta
        return (
            <div>
                <SzTable {...tableProps} />
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <div style={{maxWidth: 1900}}>
                    <div className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`} style={{marginBottom: 20}}>
                        <p>
                            <span style={{backgroundColor: "#95dcfd"}}>{Globals.em_space}{Globals.em_space}</span><span style={{paddingLeft: 5, paddingRight: 5}}>{_t(ETranslation.return_settings_active)}</span>
                            <span style={{backgroundColor: "#ffd1d1"}}>{Globals.em_space}{Globals.em_space}</span><span style={{paddingLeft: 5, paddingRight: 5}}>{_t(ETranslation.filter_criteria_insufficient)}</span>
                        </p>
                        <p>
                            <Icon icon={IconNames.STAR} size={14} color={"rgb(91,181,241)"}/><span style={{paddingLeft: 5, paddingRight: 5}}>{_t(ETranslation.return_settings_best)}</span>
                            <Icon icon={IconNames.STAR} size={14} color={"rgb(19, 124, 189)"}/><span style={{paddingLeft: 5, paddingRight: 5}}>{_t(ETranslation.return_settings_best_2)}</span>
                        </p>

                    </div>
                    {notes.map( (s, cl) => <p key={_k("footer_notes", cl)} className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>{s}</p> )}
                </div>
                {renderDebtDlg(this)}
            </div>
        );
    }

    private noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }
    private onSelectRow(row: number, dataRow: any) {
        const cid = dataRow[0];
        selectRow(this, cid);
    }

    public getPdfPage(): any[] {
        const content = [];
        PdfInsertElement.page_header(content, "Longlist Unlevered Beta");
        const widths= (new Array(13)).fill("auto").map((a,i)=>i === 1 ? "*" : "auto");
        const table = new PdfTable(content, widths);
        table.addRow(
            [
                table.header(_t(ETranslation.company), false, 2, "center", true),
                table.header("", false),

                table.header(`1 ${_t(ETranslation.year)}`, false, 2, "center", true),
                table.header("", false),

                table.header(`2 ${_t(ETranslation.years)}`, false, 3, "center", true),
                table.header("", false),table.header("", false),

                table.header(`3 ${_t(ETranslation.years)}`, false, 3, "center", true),
                table.header("", false),table.header("", false),

                table.header(`5 ${_t(ETranslation.years)}`, false, 3, "center"),
                table.header("", false),table.header("", false),
            ]
        );
        table.addRow(
            [
                table.header("#", true, undefined, "right"),
                table.header("Name", true, undefined, "left"),

                table.header(header_periods["1d"], true, undefined, "right"),
                table.header(header_periods["1w"], true, undefined, "right"),

                table.header(header_periods["2d"], true, undefined, "right"),
                table.header(header_periods["2w"], true, undefined, "right"),
                table.header(header_periods["2bw"], true, undefined, "right"),

                table.header(header_periods["3d"], true, undefined, "right"),
                table.header(header_periods["3w"], true, undefined, "right"),
                table.header(header_periods["3bw"], true, undefined, "right"),

                table.header(header_periods["5w"], true, undefined, "right"),
                table.header(header_periods["5bw"], true, undefined, "right"),
                table.header(header_periods["5m"], true, undefined, "right"),
            ]
        );
        // _fmt
        // this.tableData
        const col_idx = [15,1,2,3,4,5,6,7,8,9,10,11,12];
        this.tableData.forEach((r)=>{
            const row = [];

            const is_hidden = isNaN(r[15]);

            col_idx.forEach((i)=>{
                let cell;
                if(15 === i || 1 === i){
                    if(15 === i){
                        cell = table.cell(_fmt.m(r[i], 0), "right");
                    }
                    if(1 === i){
                        cell = table.cell(r[i], "left");
                    }
                }else{
                    cell = table.cell(_fmt.m(r[i]), "right");
                }
                if(is_hidden){
                    cell.fillColor = "#ffd1d1";
                    // cell.fillOpacity = "";
                }
                row.push(cell);
            });
            table.addRow(row);
        });

        const footer_avg = [
            table.cell(`Mittelwert ${this.filtered ? "gefiltert" : ""}`, "left", true, 2, true),
            table.cell(""),
        ];
        const footer_median = [
            table.cell(`Median ${this.filtered ? "gefiltert" : ""}`, "left", true, 2, true),
            table.cell(""),
        ];
        table.addRow(footer_avg);
        table.addRow(footer_median);
        this.more_footer.forEach((f)=>{
            footer_avg.push(table.cell(_fmt.m(mean(f)), "right", true));
            footer_median.push(table.cell(_fmt.m(median(f)), "right", true));
        });

        const tableBody = table.getBody();
        table.setBorders({
            columns: {
                idx: [1,3,6,9],
                border: [EBorder.right],
            },
            rows: {
                idx: [1, tableBody.length - 3],
                border: [EBorder.bottom],
            },
        });
        // console.error(tableBody);
        return content;
    }
}

