import {io} from "socket.io-client";
import {Globals} from "../const/Globals";

const servers = {
    local: "https://localhost:8001",
    remote: "https://application.smart-zebra.de",
    test: "https://test.smart-zebra.de/backend",
    test_chef: "https://test.smart-zebra.de/chef_v1",
    prod_chef: "https://sz-chef.smart-zebra.de",
};
const ws_servers = {
    local: "wss://localhost:8001",
    remote: "wss://application.smart-zebra.de",
    test: "wss://test.smart-zebra.de",
};

const services = {
    tasks: {// services
        ref: "tasks",
    },
    services: {// services
        ref: "services",
    },
    privateData: {// ajax
        ref: "ajax",
    },
    publicData: {// db-calls
        ref: "db-calls",
    },
    chef_v1: {
        ref: "chef_v1",
    },
    server_resources: {
        ref: "",
    },
};
export enum EServiceType {
    Services= "services",
    Private_Data= "privateData",
    Public_Data= "publicData",
    Chef_Service = "chef_v1",
    Tasks= "tasks",
    ServerResources= "server_resources",
}
// let cc = 0;

const cache = {};

export class ServiceCallBase {
    public static getHost(serviceType: EServiceType) {
        const serviceDef = services[serviceType];
        const host = document.location.host;
        if (host.indexOf("localhost") === 0 || host.indexOf("127.0.0.1") === 0) {
            Globals.isLocal = true;
            return `${servers.local}/${serviceDef.ref}`;
        }
        if (host.toLowerCase().indexOf("test") >= 0 ) {
            if(EServiceType.Chef_Service === serviceType){
                // override
                return `${servers.test_chef}/${serviceDef.ref}`;
            }
            Globals.isLocal = false;
            return `${servers.test}/${serviceDef.ref}`;
        } else {
            if(EServiceType.Chef_Service === serviceType){
                // override
                return `${servers.prod_chef}/${serviceDef.ref}`;
            }
            Globals.isLocal = false;
            return `${servers.remote}/${serviceDef.ref}`;
        }
    }
    public static getWsPath() {
        const host = document.location.host;
        if (host.indexOf("localhost") === 0 || host.indexOf("127.0.0.1") === 0) {
            return "/socket.io/";
        }
        if (host.toLowerCase().indexOf("test") >= 0 ) {
            return "/backend/socket.io/";
        } else {
            return "/socket.io/";
        }
    }
    public static getWsHost() {
        const host = document.location.host;
        if (host.indexOf("localhost") === 0 || host.indexOf("127.0.0.1") === 0) {
            return ws_servers.local;
        }
        if (host.toLowerCase().indexOf("test") >= 0 ) {
            return ws_servers.test;
        } else {
            return ws_servers.remote;
        }
    }

    public static socket;
    public static initWebSocket(): Promise<any>{
        const ws_host = ServiceCallBase.getWsHost();
        const ws_path = ServiceCallBase.getWsPath();
        const socket = io(ws_host,{
            transports: ["websocket", "polling"],
            path: ws_path
        });
        ServiceCallBase.socket = socket;

        return new Promise<any>((resolve, reject)=>{
            socket.on("connect", () => {
                resolve(socket.id);
            });
            setTimeout(()=>{
                resolve(-1);
                // console.error(socket);
                if(socket && !socket.connected){
                    // reject({error: "connect timeout"});
                }
            }, 2500);
        });
    }

    get host(): string {
        if (!this._host) {
            this._host = ServiceCallBase.getHost(this.serviceType);
        }
        return this._host;
    }
    private readonly serviceType: EServiceType;

    private _host: string = null;
    constructor(serviceType: EServiceType) {
        this.serviceType = serviceType;
        const host = document.location.host;
    }
    public open(serviceRef: string) {
        const slash = ("" + serviceRef).indexOf("/") === 0 ? "" : "/";
        const url: string = `${this.host}${slash}${serviceRef}`;
        window.open(url, "_blank");
    }
    public async callOut(serviceRef: string, data: any, method: string= "POST") {
        // const dt_start = Date.now();
        // const asString = `${serviceRef}_${JSON.stringify(data)}`;
        // const code = Globals.cyrb53(asString);
        // console.error(`callOut(${cc++})`, (Date.now() - dt_start));
        // if (cache[code]) {
        //    return cache[code];
        // }
        const slash = ("" + serviceRef).indexOf("/") === 0 ? "" : "/";
        const dataObject = data ? JSON.stringify(data) : null;
        const url: string = `${this.host}${slash}${serviceRef}`;
        try {
            const response = await fetch(url, {
                body: dataObject,
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "pragma": "no-cache",
                    "cache-control": "no-cache",
                },
                method,
                mode: "cors",
            });
            const resp = await response.json();
            // cache[code] = resp;
            return resp;
        } catch (error) {
            console.error("Error:", error);
        }
        return {result: false};
    }
    public async callOut2(serviceRef: string, data: any, ret_default: any, method: string= "POST") {
        const slash = ("" + serviceRef).indexOf("/") === 0 ? "" : "/";
        const dataObject = data ? JSON.stringify(data) : null;
        const url: string = `${this.host}${slash}${serviceRef}`;
        try {
            const response = await fetch(url, {
                body: dataObject,
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "pragma": "no-cache",
                    "cache-control": "no-cache",
                },
                method,
                mode: "cors",
            });
            const resp = await response.json();
            // cache[code] = resp;
            return resp;
        } catch (error) {
            console.error("Error:", error);
        }
        return ret_default;
    }

    public async emit(channel: string, data: any){
        return new Promise((resolve, reject)=>{
            try{
                // console.error(ServiceCallBase.socket);
                if(ServiceCallBase.socket && ServiceCallBase.socket.connected){
                    ServiceCallBase.socket.emit(channel, JSON.stringify(data), resolve);
                }else{
                    console.error(ServiceCallBase.socket);
                    reject({error: "not connected"});
                }
            }catch(exi){
                return reject({error: exi});
            }
        });
    }
}
