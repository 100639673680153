import {EContentType} from "../../const/EContentType";
import {EPackageTypes} from "../../const/EPackageTypes";
import {NopeActions} from "../../const/NopeActions";
import {ELayoutColumnWidth} from "../../models/ELayoutColumnWidth";
import {EParameters} from "../../models/EParameters";
import {IColumnLayout} from "../../models/IColumnLayout";
import {ColumnLayouterImpl} from "../ColumnLayouterImpl";
import {ModuleGroup} from "../ModuleGroup";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {IModuleConfig} from "../../models/IModuleConfig";

export class CreditSpreadsSector extends ModuleGroup<IColumnLayout> {
    public static config: IModuleConfig = {
        name: "Credit-Spreads-Sector",
    };
    constructor() {
        super("Kreditaufschlag Branche", { columns: []});
        this.addParameter(EParameters.DateParameter);
        this.addParameter(EParameters.DividerParameter);
        this.addParameter(EParameters.CreditSpreadRuntimeParameter);
        this.addParameter(EParameters.BondSectorParameter);
        //
        const dataDefault = [{theSame: true}];
        this.addModule("Branchen-Kreditaufschlag", "CreditSpreadIndustry", "CreditSpreadProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.credit_spreads)
            .setExcelExport(true)
        ;

        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c100)
            .addModule([0]);

    }

    protected getTitle(): string{
        return _t(ETranslation.credit_spread);
    }
}
