import {HTMLTable, Spinner} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {ETranslation} from "../../const/ETranslation";
import {Globals} from "../../const/Globals";
import {_t_region} from "../../const/Regions";
import {Sectors} from "../../const/Sectors";
import {Sic, Sic3, SicClasses, SicRanges} from "../../const/Sic";
import {first, getResult} from "../../helpers/Helpers";
import {ICompany, INaics, IRelatedCompany, IRelatedFinancials,} from "../../models/ICompanyDetails";
import {ICountry} from "../../models/ICountry";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {fromDateDecimal} from "../../tools/DateTools";
import {_t, country_map, naics_column} from "../../tools/Translator";
import {getIPO, getLastDate} from "./helper/CompanyDates";

const getLink = (www: string) => {
    if(!www){
        return Globals.hyphen;
    }
    // noinspection HttpUrlsUsage
    const txt = www.replace("http://","").replace("https://","");
    return (
        <a href={www} target={"_blank"}>{txt}</a>
    );
};
const sec_type = (s) => {
    const m = {
        "0":_t(ETranslation.common_share),
        "1":_t(ETranslation.preferred_share),
        "2":_t(ETranslation.preferred_convertible_share),
    };
    const r = m[`${s}`];
    if(r === undefined){
        return Globals.hyphen;
    }else{
        return r;
    }
};
const freq_code = (s) => {
    if(!s){
        return _t(ETranslation.quarterly);
    }
    const m = {
        "1":_t(ETranslation.quarterly),
        "2":_t(ETranslation.semi_annually),
        "4":_t(ETranslation.annually),
    };
    const r = m[`${s}`];
    if(r === undefined){
        return Globals.hyphen;
    }else{
        return r;
    }
};
const gaap = (s) => {
    if(!s){
        return "Local-GAAP";
    }
    const m = {
        "Local Standards with EU Guidelines":"Local-GAAP",
        "IFRS":"IFRS",
        "23":"IFRS",
        "3":"US-GAAP",
        "US Standards (GAAP)":"US-GAAP",
        "GAAP":"Local-GAAP",
        "International Standards with some EU Guidelines":"IFRS",
        "International Standards":"IFRS",
        "4095":"Local-GAAP",
        "Local Standards with EU and IASC Guidelines":"Local-GAAP",
        "Local Standards with some IASC Guidelines":"Local-GAAP",
        "1":"Local-GAAP",
        "Local Standards":"Local-GAAP",
    };
    const r = m[`${s}`];
    if(r === undefined){
        return Globals.hyphen;
    }else{
        return r;
    }
};

export interface ICompanyInformationProps {
    company_id: string;
}

interface ICompanyInformationState {
    is_loading: boolean;
    company_id: string;
    company?: ICompany;
    naics?: INaics[];
    ident?: IRelatedCompany;
    latest_financial?: IRelatedFinancials;
}

export class CompanyInformation extends React.Component<ICompanyInformationProps, ICompanyInformationState> {

    constructor(props: ICompanyInformationProps, context: any) {
        super(props, context);
        this.state = {
            is_loading: true,
            company_id: this.props.company_id,
        };
    }

    public render() {
        const backGroundStyle: CSSProperties = {
            width: "100%",
            height: "100%",
            overflowY: "auto",
            backgroundColor: "#ffffff",
        };
        return (<div style={backGroundStyle} className={"sz-relative"} >{this.state.is_loading ? this.renderLoading() : this.renderContent()}</div>);
    }
    private renderLoading() {
        (async ()=>{
            await this.loadData();
        })();
        return(
            <div style={{position: "relative", height: 400, width: "100%", display: "flex", justifyContent: "center"}}>
                <Spinner size={250} />
            </div>
        );
        /*<SzSvgLoader />*/
    }
    private async loadData() {
        try{
            const company_id = this.props.company_id;
            const db: PublicDbCalls = new PublicDbCalls();
            const r_company = await db.selectCompanies([company_id]);
            const company: ICompany = getResult(r_company, [])[0];
            const naics: INaics[] = await db.getNaics(company.ff_naics_code);
            const ident = await db.getRelatedCompany(company_id);
            const fins = await db.getLatestFinancials([company_id]);
            // console.error(current_year);
            // console.error(arr_details);
            // console.error(company_details_map);

            this.setState({
                is_loading: false,
                company,
                naics,
                ident,
                latest_financial: fins[0],
            });

            return;
        }catch(ex){
            console.error(ex);
        }
        this.setState({
            is_loading: false,
        });
    }
    private renderContent() {
        const company: ICompany = this.state.company;
        if(!company){
            return undefined;
        }
        const fin: IRelatedFinancials = this.state.latest_financial ? this.state.latest_financial : {} as IRelatedFinancials;
        const country: ICountry = Globals.country_map[company.country_id];
        const companyDescription = company.description ? company.description : "";
        const hqKey = "headquartered ";
        const idxHeadQuarter = companyDescription.lastIndexOf(hqKey);
        let hq = "";
        if(idxHeadQuarter > 0){
            hq = companyDescription.substring(idxHeadQuarter + hqKey.length);
            if(hq.startsWith("in ")){
                hq = hq.substring(3);
            }
            hq = (hq.split(",")[0])
        }
        // console.error(company);
        return (
            <div style={{paddingLeft: 1, paddingRight: 1}}>
                <div style={{width: "100%", paddingTop: 5, paddingLeft: 10, paddingBottom: 20}} className={"bp3-text-muted"}>
                    {companyDescription}
                </div>
                <div className={"sz-row"}>
                    <div style={{width: "40%"}}>
                        <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%"}}>
                            <thead>
                            <tr style={{backgroundColor: "#F0F0F0"}}>
                                <th colSpan={2}>{_t(ETranslation.base_data)}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr><td>{_t(ETranslation.country)}</td><td style={{textAlign: "right"}}>{country_map[country.iso_3].name}</td></tr>
                            <tr><td>{_t(ETranslation.headquarter)}</td><td style={{textAlign: "right"}}>{hq}</td></tr>
                            <tr><td>{_t(ETranslation["geo-region"])}</td><td style={{textAlign: "right"}}>{_t_region(country.region_de)}</td></tr>
                            <tr style={{boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}} ><td>{_t(ETranslation.website)}</td><td style={{textAlign: "right"}}>{getLink(company.home_page)}</td></tr>
                            <tr><td>{_t(ETranslation.stock_exchange)}</td><td style={{textAlign: "right"}}>{company.p_exchange}</td></tr>
                            <tr><td>{_t(ETranslation.ipo)}</td><td style={{textAlign: "right"}}>{getIPO(company.p_first_date, "MM/dd/yyyy")}</td></tr>
                            <tr><td>{_t(ETranslation.delisting)}</td><td style={{textAlign: "right"}}>{getLastDate(company.p_last_date, "yyyyMMdd")}</td></tr>
                            <tr><td>{_t(ETranslation.sharetype)}</td><td style={{textAlign: "right"}}>{sec_type(company.ff_share_type_secs)}</td></tr>
                            <tr style={{boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}} ><td>ISIN</td><td style={{textAlign: "right"}}>{company.isin}</td></tr>
                            <tr><td>GAAP</td><td style={{textAlign: "right"}}>{gaap(company.accounting_standard)}</td></tr>
                            <tr><td>{_t(ETranslation.reporting)}</td><td style={{textAlign: "right"}}>{freq_code(company.ff_freq_code)}</td></tr>
                            <tr><td>{_t(ETranslation.reporting_currency)}</td><td style={{textAlign: "right"}}>{fin.report_currency_iso}</td></tr>
                            <tr><td>{_t(ETranslation.financial_year)}</td><td style={{textAlign: "right"}}>{fromDateDecimal(fin.dd_annual_reported).toFormat("dd.LL.")}</td></tr>
                            </tbody>
                        </HTMLTable>
                    </div>
                    <div style={{width: "60%", paddingLeft: 1}}>
                        <div style={{width: "100%", marginBottom: 16}}>
                            <div style={{width: "100%", marginBottom: 16}}>
                                <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%"}}>
                                    <thead>
                                    <tr style={{backgroundColor: "#F0F0F0"}}>
                                        <th colSpan={2}>Factset</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{_t(ETranslation.sector)}</td>
                                        <td style={{textAlign: "right"}}>{Sectors.sectors_map[company.sector_id]}</td>
                                    </tr>
                                    <tr><td>{_t(ETranslation.subsector)}</td>
                                        <td style={{textAlign: "right"}}>{Sectors.sectors_map[company.industry_id]}</td>
                                    </tr>
                                    </tbody>
                                </HTMLTable>
                            </div>
                            <div style={{width: "100%", marginBottom: 16}}>
                                {this.renderSic(company)}
                            </div>
                            <div style={{width: "100%", marginBottom: 16}}>
                                {this.renderNaics(company)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderSic(company: ICompany) {
        const sic_code = parseInt(company.ff_sic_code, 10);
        const sic = Sic.find((i) => i.code === sic_code);
        const sic_class = SicClasses.find((i) => Math.trunc(i.code/100) === Math.trunc(sic_code/100));
        const sic_range = SicRanges.find((i) => Math.trunc(sic_code/100) >= i.from && Math.trunc(sic_code/100)<= i.till);
        const a = sic_range ? sic_range.text : Globals.hyphen;
        const b = sic_class ? sic_class.text : Globals.hyphen;
        const c = sic ? sic.text : Globals.hyphen;

        const sic3code = Math.trunc(sic_code/10);
        const sic3 = Sic3[""+sic3code] ? Sic3[""+sic3code] : Globals.hyphen;


        return (
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%"}}>
                <thead>
                <tr style={{backgroundColor: "#F0F0F0"}}>
                    <th colSpan={2}>SIC – Standard Industrial Classification</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{sic_range ? `${sic_range.from}-${sic_range.till}` : Globals.hyphen}</td>
                    <td style={{textAlign: "right"}}>{a}</td>
                </tr>
                <tr>
                    <td>{sic_class ? `${sic_class.code}`.slice(0, -2) : Globals.hyphen}</td>
                    <td style={{textAlign: "right"}}>{b}</td>
                </tr>
                <tr>
                    <td>{sic ? sic3code : Globals.hyphen}</td>
                    <td style={{textAlign: "right"}}>{sic3}</td>
                </tr>
                <tr>
                    <td>{sic ? sic.code : Globals.hyphen}</td>
                    <td style={{textAlign: "right"}}>{c}</td>
                </tr>
                </tbody>
            </HTMLTable>
        );
    }

    private renderNaics(company: ICompany) {
        const naics:INaics[] = this.state.naics;
        if(!Array.isArray(naics)){
            return undefined;
        }
        let sector = "";
        let sub_sector = "";
        let branch_group = "";
        let branch = "";
        let us_branch = "";
        const f = naics_column();
        naics.forEach((i) => {
            if(i.code === `${i.sector}`){
                sector = i[f];
            }
            if(i.code === `${i.sub_sector}`){
                sub_sector = i[f];
            }
            if(i.code === `${i.branch_group}`){
                branch_group = i[f];
            }
            if(i.code === `${i.branch}`){
                branch = i[f];
            }
            if(i.code === `${company.ff_naics_code}`){
                us_branch = i[f];
            }
        });
        const a_nasic = first<INaics>(naics, {} as INaics);
        let items = [
            {code: a_nasic.sector, text: sector},
            {code: a_nasic.sub_sector, text: sub_sector},
            {code: a_nasic.branch_group, text: branch_group},
            {code: a_nasic.branch, text: branch},
            /*{code: company.ff_naics_code, text: us_branch},*/
        ];
        if(company.ff_naics_code === "0"){
            items = [
                {code: Globals.hyphen, text: Globals.hyphen},
                {code: Globals.hyphen, text: Globals.hyphen},
                {code: Globals.hyphen, text: Globals.hyphen},
            ];
        }
        return (
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%"}}>
                <thead>
                <tr style={{backgroundColor: "#F0F0F0"}}>
                    <th colSpan={2}>NAICS – North American Industry Classification System</th>
                </tr>
                </thead>
                <tbody>
                {items.map((i) => i.text ? (<tr><td>{i.code}</td><td style={{textAlign: "right"}}>{i.text}</td></tr>) : undefined)}
                </tbody>
            </HTMLTable>
        );
    }
}
