import * as React from "react";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {EValueName} from "../../models/EValueName";
import {TasksCalls} from "../../services/TasksCalls";
import {BaseInterestTool} from "../../tools/BaseInterestTool";
import {SzTableHelper} from "../widgets/helper/SzTableHelper";
import {ISzTableProperties} from "../widgets/SzTable";
import {BaseInterestRate} from "./BaseInterestRate";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {Line} from "react-chartjs-2";
import * as moment from "moment";
import {getAllNextDates} from "../../helpers/Helpers";
import {deadlineEoM, fromDateDecimal} from "../../tools/DateTools";
import {EChartPlugins, SzChartOptions} from "../../helpers/SzChartOptions";
import {EParameters} from "../../models/EParameters";
import {ParameterOptions} from "../../const/ParameterOptions";

interface IInterestCurves {
    date_decimal: number;
    rate_type: "zero" | "coupon";
    duration: number;
    value: number;
}
interface ITimeLineDurationValues {
    zero_duration_values: number[];
    coupon_duration_values: number[];
}

export class BaseInterestRateSvg extends BaseInterestRate {

    private tableProps: ISzTableProperties<any[]>;
    private barwertaquivalentezins: number;
    private barwertaquivalentezinsgerundet: number;

    constructor(props: any, context: any) {
        super(props, context);
    }
    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.tableProps);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    private getSubTitle(use_germany: boolean = false){
        const country_iso = SessionStore.get(EParameters.FixedCountriesParameter);
        const other = Globals.country_map_iso_3[country_iso];
        const use_country = use_germany && other.currency_iso_3==="EUR" ? "DEU" : country_iso;

        const o = ParameterOptions.options[EParameters.FixedCountriesParameter].find((i) => i.value === use_country);
        if(!o){
            return `#Error ${country_iso}`;
        }
        return o.label;
    }
    protected renderView(stichtag: any, stichtagcurve: any, meancurve: any, laufzeiten: any, seriesLegend: string[], governmentYields: any) {
        stichtagcurve = stichtagcurve.concat(stichtagcurve.slice(-1));
        meancurve = meancurve.concat(meancurve.slice(-1));
        governmentYields = governmentYields.concat(governmentYields.slice(-1));

        const growthRate = this.parameters.GrowthRateCashSurpluseParameter[0];
        // const periodType = this.parameters.PeriodTypeParameter[0];
        // todo: ist nun ein array mit start stop!!! 31 === unendlich
        const laufzeit_start = Number(this.parameters.RuntimeBaseInterestParameter[0]);
        const laufzeit_stop = Number(this.parameters.RuntimeBaseInterestParameter[1]);
        const baseInterestCurve = Number(this.parameters.BaseInterestCurveParameter[0]);

        const roundingRule = Number(this.parameters.RoundingRuleParameter[0]);
        const useCurve = baseInterestCurve === 2 ? meancurve : stichtagcurve;
        const barwertaquivalentezins = BaseInterestTool.calc(growthRate, useCurve, laufzeit_start, laufzeit_stop);

        const round = () => {
            if (roundingRule === 1) {
                return Math.round(barwertaquivalentezins * 10000) / 10000;
            }

            if (roundingRule === 3) {
                return Math.round(barwertaquivalentezins * 400) / 400;
            }

            return barwertaquivalentezins < 0.01 ? Math.round(barwertaquivalentezins * 1000) / 1000 : Math.round(barwertaquivalentezins * 400) / 400;
        };

        const barwertaquivalentezinsgerundet = round();

        this.barwertaquivalentezins = barwertaquivalentezins;
        this.barwertaquivalentezinsgerundet = barwertaquivalentezinsgerundet;

        SessionStore.setGlobalVar(EValueName.base_rate_unrounded, barwertaquivalentezins);
        SessionStore.setGlobalVar(EValueName.base_rate_rounded, barwertaquivalentezinsgerundet);

        const tableHeader = [];
        tableHeader.push([].concat([ { text: `${_t(ETranslation.duration)} (${_t(ETranslation.years)})` }], laufzeiten.map( (item) => ({text: "" + item}) )));
        const tableData = [];
        tableData.push([].concat([`${_t(ETranslation.valuation_date)} ${stichtag}`], stichtagcurve));
        tableData.push([].concat([_t(ETranslation.average_90_days)], meancurve));
        const columns = laufzeiten.map( (item, idx) => ({ ...SzTableHelper.columnMoney("" + (idx + 1), 2)}) );

        this.tableProps = {
            colCount: 31,
            data: tableData,
            header: tableHeader,
            columns: [].concat([SzTableHelper.columnIndex("0")], columns),
        };
        return (
            <>
                <div className={"sz-row"}>
                    <div style={{width: "50%"}}>
                        {this.renderZeroRates(stichtagcurve, meancurve)}
                    </div>
                    <div style={{width: "50%"}}>
                        {this.renderYieldTermStructure(governmentYields)}
                    </div>
                </div>
                {this.renderTimeLine()}
            </>
        );
    }
    private renderZeroRates(stichtagcurve, meancurve){
        const chart_options = new SzChartOptions();
        chart_options.setCustomAxisLabel(_t(ETranslation.years), `[% p.a.]`);
        chart_options.enablePlugin(EChartPlugins.LEGEND, {position: "top", align: "center", labels: {boxWidth: 10}}); // chartArea
        const gfx_1 = {
            label: _t(ETranslation.valuation_date),
            borderColor: "#a0a0a0",
            backgroundColor: "#a0a0a0",
            borderWidth: 1,
            pointRadius: 3,
            pointBorderWidth: 2,
            pointHitRadius: 3,
            pointBorderColor: "#ffffff",
            spanGaps: true,
            data: [{x: 0, y: undefined}].concat(stichtagcurve.map( (y, idx) => ({x: idx + 1, y}))),
        };
        const gfx_2 = {
            label: _t(ETranslation.average_90_days),
            borderColor: "#137cbd",
            backgroundColor: "#137cbd",
            borderWidth: 1,
            pointRadius: 3,
            pointBorderWidth: 2,
            pointHitRadius: 3,
            pointBorderColor: "#ffffff",
            spanGaps: true,
            data: [{x: 0, y: undefined}].concat(meancurve.map( (y, idx) => ({x: idx + 1, y}))),
        };
        const o = {"1": "1", "2": "2", "3": "3", "5": "5", "10": "10", "30": "30", "31": "∞"};
        const labels = (new Array(32).fill("0")).map((z, i)=> o[i] ? o[i] : undefined);
        const chart_data = {
            labels,
            datasets: [
                gfx_1,
                gfx_2,
            ],
        };
        const value = this.barwertaquivalentezinsgerundet * 100;
        const label = `${_t(ETranslation.base_rate)}: ${Globals.formatter_percent(value / 100)}`;
        const lineData = labels.map((v, i)=>({x: i, y: undefined} as any));
        lineData[0] = {x: 0, y: value, a: true};
        lineData[labels.length -1] = {x: labels.length -1, y: value};
        chart_data.datasets.push({
            order: -1,
            type: "line",
            label: _t(ETranslation.base_rate),
            data: lineData,
            spanGaps: true,
            backgroundColor: "#f47a22",
            borderColor: "#f47a22",
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 0,
            datalabels: {
                display: 1,
                align: "-30",
                anchor: "end",
                offset: 5,
                backgroundColor: "rgba(255,255,255,0.9)",
                formatter: (v, context, a)=>{
                    if(!v.a){
                        return null;
                    }
                    return label;
                },
                labels: {
                    title:{
                        color: "#f47a22",
                    }
                }
            }
        } as any);

        return (
            <>
                <div style={{marginBottom: 20, fontSize: "80%"}}>
                    <strong>{_t(ETranslation.zero_rates_term_strucutre)} [% p.a.]</strong>
                    <br/>
                    {this.getSubTitle(true)}
                </div>
                <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                    <Line data={chart_data as any} options={chart_options.options as any} height={"400px"} />
                </div>
            </>
        );
    }
    private renderYieldTermStructure(governmentYields){
        const chart_options = new SzChartOptions();
        // chart_options_gov.enablePlugin(EChartPlugins.TOOLTIP, {});
        chart_options.enablePlugin(EChartPlugins.LEGEND, {position: "top", align: "center", labels: {boxWidth: 10}});
        chart_options.setCustomAxisLabel(_t(ETranslation.years), `[% p.a.]`);
        const gfx = {
            label: _t(ETranslation.valuation_date),
            borderColor: "#a0a0a0",
            backgroundColor: "#a0a0a0",
            borderWidth: 1,
            pointRadius: 3,
            pointBorderWidth: 2,
            pointHitRadius: 3,
            pointBorderColor: "#ffffff",
            spanGaps: true,
            data: [{x: 0, y: undefined}].concat(governmentYields.map( (y, idx) => ({x: idx + 1, y}))),
        };
        const o = {"1": "1", "2": "2", "3": "3", "5": "5", "10": "10", "30": "30", "31": "∞"};
        const labels = (new Array(32).fill("0")).map((z, i)=> o[i] ? o[i] : undefined);
        const chart_data = {
            labels,
            datasets: [
                gfx,
            ],
        };
        return (
            <>
                <div style={{marginBottom: 20, fontSize: "80%"}}>
                    <strong>{_t(ETranslation.yield_term_structure)} [% p.a.]</strong>
                    <br/>
                    {this.getSubTitle()}
                </div>
                <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                    <Line data={chart_data as any} options={chart_options.options as any} height={"400px"} />
                </div>
            </>
        );
    }

    private renderTimeLine(){
        const all_time_series: IInterestCurves[] = this.data.time_series;
        // console.error(this.data.time_series);
        const by_dates_map: {[date_decimal: number]: ITimeLineDurationValues} = {};
        all_time_series.forEach((a)=>{
            if(!by_dates_map[a.date_decimal]){
                by_dates_map[a.date_decimal] = {
                    zero_duration_values: [],
                    coupon_duration_values: [],
                };
            }
            if(a.rate_type === "zero"){
                by_dates_map[a.date_decimal]
                    .zero_duration_values[a.duration] = a.value;
            }
            if(a.rate_type === "coupon"){
                by_dates_map[a.date_decimal]
                    .coupon_duration_values[a.duration] = a.value;
            }
        });
        const dates = getAllNextDates(deadlineEoM(), 3)
            .map((d)=>parseInt(moment(d, "DD.MM.YYYY").format("YYYYMMDD"), 10))
            .reverse()
        ;
        const dates_formatted = [];
        const fmt_dates = [];
        const durations = [1, 3, 5, 7, 10];
        const zero_data_sets = durations.map(()=> new Array());
        const coupon_data_sets = durations.map(()=> new Array());
        dates.forEach((date_decimal, x)=>{
            const fmtDate = fromDateDecimal(date_decimal).toFormat("LLL yy", {locale: navigator.language});
            fmt_dates.push(fmtDate);
            dates_formatted[date_decimal] = fmtDate;
            const series = by_dates_map[date_decimal];
            if(!series){
                durations.forEach((d, idx)=>{
                    zero_data_sets[idx].push({x, y: undefined});
                    coupon_data_sets[idx].push({x, y: undefined});
                });
            }else{
                durations.forEach((d, idx)=>{
                    zero_data_sets[idx].push({x, y: series.zero_duration_values[d]});
                    coupon_data_sets[idx].push({x, y: series.coupon_duration_values[d]});
                });
            }
        });
        const v_x_labels = [
            _t(ETranslation.year_number, 1),
            _t(ETranslation.years_number, 3),
            _t(ETranslation.years_number, 5),
            _t(ETranslation.years_number, 7),
            _t(ETranslation.years_number, 10)
        ];
        const v_x_colors = [
            "#68C1EE",
            "#3FA6DA",
            "#147EB3",
            "#0F6894",
            "#0C5174",
        ];
        const zero_chart_data = {
            labels: fmt_dates,
            datasets: []
        };
        const coupon_chart_data = {
            labels: fmt_dates,
            datasets: []
        };
        durations.forEach((duration, idx)=>{
            const ds = (time_line) =>({
                label: v_x_labels[idx],
                data: time_line,
                backgroundColor: v_x_colors[idx],
                borderColor: v_x_colors[idx],
                borderWidth: 1,
                pointRadius: 3,
                pointBorderWidth: 2,
                pointHitRadius: 3,
                pointBorderColor: "#ffffff",
            });
            zero_chart_data.datasets.push(ds(zero_data_sets[idx]));
            coupon_chart_data.datasets.push(ds(coupon_data_sets[idx]));
        });
        // console.error(fmt_dates);
        // console.error(coupon_chart_data);
        const options = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            interaction: {
                intersect: true,
                mode: 'index',
            },
            scales: {
                y: {
                    position: 'left',
                    ticks: {
                        z: 10,
                        padding: 10,
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                },
                x: {
                    type: 'linear',
                    ticks: {
                        z: 10,
                        padding: 10,
                        stepSize: 6,
                        callback: (value, index, ticks)=>{
                            return fmt_dates[value];
                        },
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend: {
                    display: true,
                    // position: "right", // "chartArea",
                    align: "center",
                    labels: {
                        boxWidth: 10,
                    }
                    // align: "start",
                },
                datalabels: {
                    display: 0,
                },
                tooltip: {
                    enabled: true,
                    position: "nearest",
                    callbacks: {
                        title: (items)=> {
                            return fmt_dates[items[0].dataIndex];
                        },
                        label: (context)=> {
                            let label = context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += Globals.formatter(context.parsed.y) + "%";
                            }
                            return label;
                        },
                    },
                },
            },
            custom_axis_label: {
                x_label: _t(ETranslation.date),
                y_label: "[% p.a.]",
            },
        };
        return (
            <div className={"sz-row"}>
                <div style={{width: "50%"}}>
                    <div style={{marginBottom: 20, fontSize: "80%"}}>
                        <strong>{_t(ETranslation.zero_rates_over_time)} [% p.a.]</strong>
                        <br/>
                        {this.getSubTitle(true)}
                    </div>
                    <div style={{width: "100%", height: 250, marginTop: 10, marginBottom: 10}}>
                        <Line options={options as any} data={zero_chart_data as any} height={"100%"} width={"100%"} />
                    </div>
                </div>
                <div style={{width: "50%"}}>
                    <div style={{marginBottom: 20, fontSize: "80%"}}>
                        <strong>{_t(ETranslation.yield_over_time)} [% p.a.]</strong>
                        <br/>
                        {this.getSubTitle()}
                    </div>
                    <div style={{width: "100%", height: 250, marginTop: 10, marginBottom: 10}}>
                        <Line options={options as any} data={coupon_chart_data as any} height={"100%"} width={"100%"} />
                    </div>
                </div>
            </div>
        );
    }
}
