import {Globals} from "../../const/Globals";
import {IDW_GROSS, IDW_NET} from "../../const/IdwData";
import {EParameters} from "../../models/EParameters";
import {IIdwData} from "../../models/IIdwData";
import {IMarketRiskValue} from "../../models/IMarketRiskValue";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {BaseDataProvider} from "./BaseDataProvider";

// nächste Jahr zum 1.1 nach aktueller Datum
const lastDateStr = `2024-01-01`;

const idwNet: IIdwData[] = IDW_NET;
const idwGross: IIdwData[] = IDW_GROSS;
const kmpg: IMarketRiskValue[] = [
    {
        date: new Date("2010-01-01"),
        average: 5.1,
    },
    {
        date: new Date("2011-01-01"),
        average: 5.1,
    },
    {
        date: new Date("2011-07-01"),
        average: 5.2,
    },
    {
        date: new Date("2012-07-01"),
        average: 5.8,
    },
    {
        date: new Date("2013-07-01"),
        average: 5.8,
    },
    {
        date: new Date("2014-07-01"),
        average: 6.1,
    },
    {
        date: new Date("2015-07-01"),
        average: 6.3,
    },
    {
        date: new Date("2016-07-01"),
        average: 6.5,
    },
    {
        date: new Date("2017-07-01"),
        average: 6.5,
    },
    {
        date: new Date("2018-07-01"),
        average: 7,
    },
    {
        date: new Date("2019-07-01"),
        average: 7.2,
    },
    {
        date: new Date("2020-07-01"),
        average: 7.3,
    },
    {
        date: new Date("2021-07-01"),
        average: 7.3,
    },
    {
        date: new Date("2022-07-01"),
        average: 7.3,
    },
    {
        date: new Date("2023-07-01"),
        average: 7.3,
    },
    {
        date: new Date(lastDateStr),
        average: 7.3,
    },
];
const damodaran: IMarketRiskValue[] = [
    {
        average: 4.5,
        date: new Date("2010-01-01"),
    },
    {
        average: 5,
        date: new Date("2011-01-01"),
    },
    {
        average: 6,
        date: new Date("2012-01-01"),
    },
    {
        average: 5.8,
        date: new Date("2013-01-01"),
    },
    {
        average: 5,
        date: new Date("2014-01-01"),
    },
    {
        average: 5.75,
        date: new Date("2015-01-01"),
    },
    {
        average: 6.25,
        date: new Date("2016-01-01"),
    },
    {
        average: 5.69,
        date: new Date("2017-01-01"),
    },
    {
        average: 5.08,
        date: new Date("2018-01-01"),
    },
    {
        average: 5.96,
        date: new Date("2019-01-01"),
    },
    {
        average: 5.96,
        date: new Date("2020-01-01"),
    },
    {
        average: 4.72,
        date: new Date("2021-01-01"),
    },
    {
        average: 4.72,
        date: new Date("2022-01-01"),
    },
    {
        average: 4.72,
        date: new Date("2023-01-01"),
    },
    {
        average: 4.72,
        date: new Date(lastDateStr),
    },
];

export class MarketRiskPremiumProvider extends BaseDataProvider {
    protected initProvider() {
        super.className = "MarketRiskPremiumProvider";
        this.parameterMapping = [
            EParameters.FixedCountriesParameter,
            EParameters.DateParameter,
        ];
    }

    protected update() {
        const data = {
            idwNet,
            idwGross,
            kmpg,
            damodaran,
            equity_risk_premium: [],
            country_risk_premium: [],
            country_risk_premium_lrp: [],
        };
        (async () => {
            const db = new PublicDbCalls();
            if (!Globals.countries.length) {
                Globals.countries = (await db.getAllCountries()).result;
            }
            const country = this.mappedParameters.get(EParameters.FixedCountriesParameter);
            if (country && country.length) {
                const c = Globals.countries.find( (i) => i.iso_3 === country[0] );
                if (c) {
                    const country_risk_premium = await db.getCountryRiskPremium([c.id]);
                    const country_risk_premium_lrp = await db.getCountryRiskPremiumLrp([c.id]);
                    const equity_risk_premium = await db.getEquityRiskPremium([c.id]);

                    data.country_risk_premium = country_risk_premium.result;
                    data.equity_risk_premium = equity_risk_premium.result;
                    data.country_risk_premium_lrp = country_risk_premium_lrp.result;
                }

            }
            this.afterUpdate(data);
        })();
    }
}
