import * as React from "react";
import {EValueName} from "../models/EValueName";

const help: { [index:string] : any } = {};
help[EValueName.unlevered_beta] = "Median des unverschuldeten Beta-Faktors der Peer-Group";
help[EValueName.unlevered_total_beta] = "Median des unverschuldeten Total Betas der Peer-Group";
/*
Eigenkapitalkosten
Verschuldete Eigenkapitalkosten des Bewertungsobjektes vor persönlichen Steuern des Anlegers

Unlevered Cost of Equity
Unverschuldete Eigenkapitalkosten des Bewertungsobjektes vor persönlichen Steuern des Anlegers
 */
help[EValueName.wacc] = "Gewichtete Kapitalkosten (Weighted Average Cost of Capital) des Bewertungsobjektes vor persönlichen Steuern des Anlegers";

help[EValueName.sales_trailing] = "Verhältnis aus Umsatz des letztes verfügbarer Geschäftsbericht und Gesamtunternehmenswert";
help[EValueName.sales_forward] = "Verhältnis aus Umsatz des laufenden Geschäftsjahres (Schätzung) und Gesamtunternehmenswert";
help[EValueName.ebitda_trailing] = "Verhältnis aus EBITDA des letztes verfügbarer Geschäftsbericht und Gesamtunternehmenswert";
help[EValueName.ebitda_forward] = "Verhältnis aus EBITDA des laufenden Geschäftsjahres (Schätzung) und Gesamtunternehmenswert";
help[EValueName.ebit_trailing] = "Verhältnis aus EBIT des letztes verfügbarer Geschäftsbericht und Gesamtunternehmenswert";
help[EValueName.ebit_forward] = "Verhältnis aus EBIT des laufenden Geschäftsjahres (Schätzung) und Gesamtunternehmenswert";

export function Value_HELP(p: EValueName) {
    return help[p];
}
