import {_t} from "../tools/Translator";
import {ETranslation} from "./ETranslation";

const _th = (a: ETranslation, x, b: ETranslation) => `${_t(a,x)} ${_t(b)}`;
export const EPeriodToFrequencyFull = {
    "1d": _th(ETranslation.year_number, 1, ETranslation.daily),  "1w": _th(ETranslation.year_number, 1, ETranslation.weekly),
    "2d": _th(ETranslation.years_number, 2, ETranslation.daily), "2w": _th(ETranslation.years_number, 2, ETranslation.weekly), "2bw": _th(ETranslation.years_number, 2, ETranslation.biweekly),
    "3d": _th(ETranslation.years_number, 3, ETranslation.daily), "3w": _th(ETranslation.years_number, 3, ETranslation.weekly), "3bw": _th(ETranslation.years_number, 3, ETranslation.biweekly),
    "5w": _th(ETranslation.years_number, 5, ETranslation.weekly), "5bw": _th(ETranslation.years_number, 5, ETranslation.biweekly), "5m": _th(ETranslation.years_number, 5, ETranslation.monthly),
};

export const EPeriodToFrequency = {
    "1d": _t(ETranslation.daily),  "1w": _t(ETranslation.weekly),
    "2d": _t(ETranslation.daily), "2w": _t(ETranslation.weekly), "2bw": _t(ETranslation.biweekly),
    "3d": _t(ETranslation.daily), "3w": _t(ETranslation.weekly), "3bw": _t(ETranslation.biweekly),
    "5w": _t(ETranslation.weekly), "5bw": _t(ETranslation.biweekly), "5m": _t(ETranslation.monthly),
};
export const EPeriodToRange = {
    "1d": _t(ETranslation.year_number, 1),  "1w": _t(ETranslation.year_number, 1),
    "2d": _t(ETranslation.years_number, 2), "2w": _t(ETranslation.years_number, 2), "2bw": _t(ETranslation.years_number, 2),
    "3d": _t(ETranslation.years_number, 3), "3w": _t(ETranslation.years_number, 3), "3bw": _t(ETranslation.years_number, 3),
    "5w": _t(ETranslation.years_number, 5), "5bw": _t(ETranslation.years_number, 5), "5m": _t(ETranslation.years_number, 5),
};
