import {Spinner, Toaster} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {IOptionEntry} from "../../models/IOptionEntry";
import {ISavedLongList} from "../../models/ISavedLongList";
import {ServiceCalls} from "../../services/ServiceCalls";
import {ELongListAction, LoadLongListView} from "./LoadLongListView";
import {SzShareDialog} from "./SzShareDialog";

const BASE_DIR = Globals.BASE_DIR;

export interface ISaveLongListState {
    value: string;
    isLoading: boolean;
    isPublic: boolean;
    longLists: ISavedLongList[];
    _id: string;
    overrideItem: ISavedLongList;
    shareListName?: string;
    shareListIsOpen?: boolean;
    shareList?: ISavedLongList;
}

export class SaveLongList extends React.Component<any, ISaveLongListState> {
    private readonly toaster: React.RefObject<Toaster> = null;

    constructor(props: any, context: ISaveLongListState) {
        super(props, context);

        this.toaster = React.createRef();
        this.state = {
            value: null,
            isLoading: true,
            isPublic: false,
            longLists: [],
            _id: null,
            overrideItem: null,
        };
    }
    public reload() {
        this.setState({
            isLoading: true,
        });
    }
    public render() {
        if (this.state.isLoading) {
            this.load();
            return this.renderLoading();
        }
        const style: CSSProperties = {
            width: "100%",
            height: "100%",
            backgroundImage: `url(${BASE_DIR}images/zebra.svg)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "50%",
        };
        const headerStyle = {
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 10,
            height: 30,
        };
        const scrolling: any = {
            position: "absolute",
            top: "0px",
            bottom: "8px",
            width: "100%",
        };
        return(
            <div className={"sz-relative"} style={style}>
                <Toaster position={"top"} autoFocus={false} canEscapeKeyClear={true} ref={this.toaster} className={"in-front"} />
                <SzShareDialog listName={this.state.shareListName} isOpen={this.state.shareListIsOpen} onClose={() => this.closeShareDlg()} onFinished={ (name, email) => this.shareLongList(name, email) } />
                <LoadLongListView longLists={this.state.longLists} onSelectList={(e) => this.onSelectLongList(e)} onAction={(action, item, new_name) => this.onAction(action, item, new_name)} />
            </div>
        );
    }

    private load() {
        (async () => {
            const services = new ServiceCalls();
            const response = await services.loadLongList();
            let data: ISavedLongList[] = [];
            if (response && response.result && Array.isArray(response.result) && response.result.length) {
                data = response.result as ISavedLongList[];
            }
            // console.error("Longlist", data);
            if (response && response.result && Array.isArray(response.result)) {
                const options = response.result.map((i) => ({value: i._id, label: i.name, selected: false, data: i}));
                Globals.primeSectorOptions = options;
            }
            this.setState({
                isLoading: false,
                longLists: data,
                _id: null,
                overrideItem: null,
            });
        })();
    }

    private renderLoading() {
        return <Spinner size={100} />;
    }

    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            value: event.target.value,
        });
    }
    private onSelectLongList(e: ISavedLongList) {
        if (this.props.onSelectList) {
            this.props.onSelectList(e);
        }
    }

    private onAction(action: ELongListAction, item: ISavedLongList, new_name: string) {
        if (action === ELongListAction.OVERRIDE) {

            (async () => {
                const services = new ServiceCalls();
                const resp = await services.saveLongList(item.name, Globals.longList, item.is_public, item._id);
                if (resp && resp.result) {
                    this.setState({
                        longLists: resp.result,
                    });
                    const p_sector = (Globals.primeSectorOptions as IOptionEntry[]).find( (i) => i.value === item._id );
                    const selectedList = (resp.result as ISavedLongList[]).find( (i) => i._id === item._id );
                    if (selectedList && p_sector) {
                        p_sector.data.items = selectedList.items;
                    }
                }
            })();
        }
        if (action === ELongListAction.RENAME) {
            (async () => {
                const services = new ServiceCalls();
                const resp = await services.renameLongList(item._id, new_name);
                if (resp && resp.result) {
                    item.name = new_name;
                    this.setState({
                        longLists: this.state.longLists,
                    });
                }
            })();
        }
        if (action === ELongListAction.DELETE) {
            (async () => {
                const services = new ServiceCalls();
                const resp = await services.deleteLongList(item._id);
                if (resp && resp.result) {
                    const idx = this.state.longLists.findIndex((l) => l._id === item._id);
                    if (idx >= 0) {
                        this.state.longLists.splice(idx, 1);
                        this.setState({
                            longLists: this.state.longLists,
                        });
                    }
                    const searches = resp;
                    let options = [];
                    if (searches && searches.result && Array.isArray(searches.result)) {
                        options = searches.result.map((i) => ({value: i._id, label: i.name, selected: false, data: i}));
                        Globals.primeSectorOptions = options;
                    }
                }
            })();
        }
        if (action === ELongListAction.SHARE) {
            this.setState({
                shareListName: "" + item.name,
                shareListIsOpen: true,
                shareList: item,
            });
        }
        if (action === ELongListAction.SHARE_ACCEPT) {
            (async () => {
                const services = new ServiceCalls();
                const resp = await services.renameLongList(item._id, item.name, 2);
                if (resp && resp.result) {
                    item.state = 2;
                    this.setState({
                        longLists: this.state.longLists,
                    });
                    // console.error(Globals.primeSectorOptions, item._id);
                    if (Globals.primeSectorOptions && Array.isArray(Globals.primeSectorOptions)) {
                        Globals.primeSectorOptions.forEach( (o) => {
                            if (o.value === item._id) {
                                o.data.state = 2;
                            }
                        });
                    }
                }
            })();
        }
    }
    private closeShareDlg() {
        this.setState({
            shareListIsOpen: false,
            isLoading: true,
            _id: null,
            overrideItem: null,
            shareList: null,
        });
    }
    private shareLongList(name: string, email: string) {
        (async () => {
            const services = new ServiceCalls();
            const response = await services.saveLongList(name, this.state.shareList.items, false, null, email);
            this.setState({
                shareListIsOpen: false,
                isLoading: true,
                _id: null,
                overrideItem: null,
                shareList: null,
            });
        })();
    }
}
