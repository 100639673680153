import {ITableStatisticsOptions} from "../../models/ITableStatisticsOptions";
import {max, mean, median, min, quantile} from "../Statistics";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export class TableStatistics {
    public static getStatistics(table: any[], columns: number[]) {
        if (!(table && Array.isArray(table))) {
            table = [];
        }
        return columns.map((column, index) => {
            const datas = [];
            for (const row of table) {
                if (!row.length) {
                    continue;
                }
                const data = parseFloat(row[column]);
                if (!isNaN(data)) {
                    datas.push(data);
                }
            }
            try {
                return {
                    column,
                    min: datas.length ? min(datas) : "—",
                    max: datas.length ? max(datas) : "—",
                    mean: datas.length ? mean(datas) : "—",
                    median: datas.length ? median(datas) : "—",
                    q25: datas.length ? quantile(datas, 0.25) : "—",
                    q75: datas.length ? quantile(datas, 0.75) : "—",
                };
            } catch (exi) {
                console.error(exi);
            }
            return {
                column,
                min: "—",
                max: "—",
                mean: "—",
                median: "—",
                q25: "—",
                q75: "—",
            };

        });
    }
    public static getTableStatistics(table: any[][], options: ITableStatisticsOptions): any[][] {
        const headColumn = options.headColumn;
        const columns = options.columns;
        const statistics = TableStatistics.getStatistics(table, columns);

        const tableColCount = options.pack ? columns.length + 1 : table[0].length;
        const statisticsLabels = {
            min: _t(ETranslation.minimum),
            max: _t(ETranslation.maximum),
            mean: _t(ETranslation.average),
            median: _t(ETranslation.median),
            q25: _t(ETranslation.lower_quartile),
            q75: _t(ETranslation.upper_quartile),
        };
        const statisticsTable = ["mean", "min", "q25", "median", "q75", "max"].map((prop) => {
            const row = (new Array(tableColCount)).fill("");
            row[headColumn] = statisticsLabels[prop];
            for (let i = 0; i < columns.length; i++) {
                const idx = options.pack ? i + 1 : columns[i];
                const val = statistics[i][prop];
                row[idx] = val;
            }
            return row;
        });
        return statisticsTable;
    }
}
