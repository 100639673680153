import * as React from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';

import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {Line} from "react-chartjs-2";
import {DrawStateHook} from "../../../chart-plugin/DrawStateHook";
import * as ChartJsAnnotation from "chartjs-plugin-annotation";

import {CustomAxisLabel} from "../../../chart-plugin/CustomAxisLabel";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {Annotations} from "./annotations";
import {EValueName} from "../../../models/EValueName";
import {SessionStore} from "../../../const/SessionStore";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
    ChartJsAnnotation,
    CustomAxisLabel,
);
export interface ICoCMarketRisk {
    min_year: number;
    market_risk_premium: any[];
    subTitle: string;
}

export function CoCMarketRisk(props: ICoCMarketRisk) {
    if(props?.market_risk_premium?.length === 0){
        //
        return null;
    }

    const o = new SzChartOptions();
    o.enablePlugin(EChartPlugins.LEGEND, {position: "top", align: "center",labels: {boxWidth: 10}});
    // o.setCustomAxisLabel(_t(ETranslation.years), `[% p.a.]`);
    const market_risk_premium = props.market_risk_premium;
    const data = [];
    for (let i = 0; i < market_risk_premium.length - 4; i++) {
        data.push({x: i, y: 100 * market_risk_premium[i + 4]});
    }
    data.push(data[data.length - 1]);
    o.setXMinMax({min: 0, max: data.length - 1});
    const labels: string[] = (new Array(market_risk_premium.length)).fill(1).map( (i, idx) => idx % 2 !== 0 ? undefined : ""+(idx + props.min_year));
    // const labels: string[] = (new Array(market_risk_premium.length)).fill(1).map( (i, idx) => ""+(idx + props.min_year));
    o.enablePlugin(EChartPlugins.ANNOTATION, {
        annotations : {
            market_risk_premium: Annotations.coc_risk_premium(parseFloat(SessionStore.getGlobalVar(EValueName.market_risk_premium, NaN)), props.min_year, market_risk_premium.length),
        }
    });
    const options = {
        ...o.options,
    };
    options.scales.y.beginAtZero= true;
    options.scales.x.ticks.stepSize= 2;

    // options.scales.x.offset = true;
    // options.scales.x.grid.offset = true;

    options.animations = false;
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
    };
    // console.error(options);
    const gfx = {
        label: "A. Damodaran",
        borderColor: "rgba(19,124,189,0.75)",
        borderWidth: 1,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        stepped: true,
        data,
    };
    const chart_data = {
        labels,
        datasets: [
            gfx,
        ],
    };
    return (
        <div>
            <div style={{marginBottom: 20, fontSize: "80%"}}>
                <strong>{_t(ETranslation.market_risk_premium)} [% p.a.]</strong>
                <br/>
                {props.subTitle}
            </div>
            <div style={{width: "100%", height: 400}}>
                <Line data={chart_data as any} options={options} width={"100%"} height={"100%"}/>
            </div>
        </div>
    );
}
