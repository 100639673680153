import {
    Alert,
    AlertProps,
    AnchorButton,
    Button,
    Classes,
    Colors,
    ControlGroup,
    Drawer,
    Icon,
    InputGroup,
    Intent,
    NonIdealState,
    Position,
    Spinner,
    Toaster,
    Tooltip,
} from "@blueprintjs/core";
import {IconName, IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../const/Globals";
import {Sectors} from "../const/Sectors";
import {SessionStore} from "../const/SessionStore";
import {ICompanySearchResult} from "../models/ICompanySearchResult";
import {IOptionEntry} from "../models/IOptionEntry";
import {ISavedLongList} from "../models/ISavedLongList";
import {ISearchCompanyParameters} from "../models/ISearchCompanyParameters";
import {ISearchFilter} from "../models/ISearchFilter";
import {EventBus, IEventHandler} from "../services/EventBus";
import {PublicDbCalls} from "../services/PublicDbCalls";
import {ServiceCalls} from "../services/ServiceCalls";
import {CountryFilter} from "./search-components/CountryFilter";
import {RangeFilter} from "./search-components/RangeFilter";
import {ISelection, SectorFilter} from "./search-components/SectorFilter";
import {SicFilter} from "./search-components/SicFilter";
import {DlgDbRequest} from "./widgets/DlgDbRequest";
import {GetNameDialog} from "./widgets/GetNameDialog";
import {SaveLongList} from "./widgets/SaveLongList";
import {CompanyDetails} from "./widgets/CompanyDetails";
import {SzAskKay} from "./search-components/SzAskKay";
import {AskKaySearch} from "./widgets/AskKaySearch";
import {getResult} from "../helpers/Helpers";
import {_t} from "../tools/Translator";
import {ETranslation} from "../const/ETranslation";

enum EViewState {
    VsSearchResult= 1,
    VsSaveLongList= 2,
    VsLoadLongList= 3,
    VsCompanyDetails= 4,
    VsAskKay
}

interface IFieldSort {
    fieldName: string;
    direction: number;
}
export interface ISearchDrawerState {
    autoFocus: boolean;
    canEscapeKeyClose: boolean;
    canOutsideClickClose: boolean;
    enforceFocus: boolean;
    hasBackdrop: boolean;
    isOpen: boolean;
    position?: Position;
    size: string;
    usePortal: boolean;
    value?: string;
    searching?: boolean;
    sector: ISelection;
    region?: string;
    country?: string;
    longList?: ICompanySearchResult[];
    searchResult?: ICompanySearchResult[];
    tableSort: IFieldSort[];

    selectedCompanyId?: string;
    selectedCompany?: ICompanySearchResult;
    viewState?: EViewState;

    isShowingSaveDlg?: boolean;
    isShowExtendDb?: boolean;
    filterIsSet?: boolean;

    ranges: string[];
    classes: number[];
    sub_classes: number[];

    asKaySearchData?: string;
    confirmRestLonglist?: boolean;
}

const maxLongList: number = 25;
const BASE_DIR = Globals.BASE_DIR;


type SzCompany = {
    id: number;
    name: string;
    security_id: string;
    sector_id: number;
    industry_id: number;
};

export class SearchDrawer extends React.Component<any, ISearchDrawerState> {

    public state: ISearchDrawerState = {
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        hasBackdrop: true,
        isOpen: false,
        position: Position.RIGHT,
        size: "95%", /* SIZE_STANDARD */
        usePortal: true,
        value: "",
        searching: false,
        sector: undefined,
        region: "",
        country: "",
        longList: this.getLongList(),
        searchResult: [],
        tableSort: [],
        viewState: EViewState.VsSearchResult,
        selectedCompanyId: "",
        filterIsSet: true,
        ranges: [],
        classes: [],
        sub_classes: [],
    };

    private readonly saveLongList: React.RefObject<SaveLongList> = null;
    private readonly searchField: React.RefObject<InputGroup> = null;
    private readonly toaster: React.RefObject<Toaster> = null;
    private searchInput: HTMLInputElement = null;

    private currentSector: any;
    private currentCountries: any[] = [];
    private initialCountries: any[];

    private currentKeywords: string;

    private currentMarketCapMin = -1;
    private currentMarketCapMax = -1;
    private currentMarketCapDate = null;

    private currentRevenueMin = -1;
    private currentRevenueMax = -1;
    private currentRevenueDate = null;

    private currentSicClass: number[] = [];
    private currentSic: number[] = [];

    private searchDefault: string;

    private readonly evtChangeFilter: IEventHandler;
    private readonly eventHandler: IEventHandler;
    private readonly eventAskKaySearch: IEventHandler;
    private readonly eventAskKayResult: IEventHandler;
    constructor(props: any, context: any) {
        super(props, context);
        this.handleClose = this.handleClose.bind(this);
        this.saveLongList = React.createRef();
        this.searchField = React.createRef();
        this.toaster = React.createRef();

        EventBus.subscribe("remove.from.longlist", (data: ICompanySearchResult) => {
            this.removeFromLongList(data, true);
        });
        this.evtChangeFilter = EventBus.subscribe<ISearchFilter>("onNearByDetails.updateFilter", (evt) => this.changeFilter(evt.filterName, evt.data));
        this.eventHandler = EventBus.subscribe<ICompanySearchResult>("onCompanySearchResult.selected", (data) => this.loadData(data));
        this.eventAskKaySearch = EventBus.subscribe<string>("askKay::business_activity",(data)=> this.onAskKaySearch(data));
        this.eventAskKayResult = EventBus.subscribe<SzCompany[]>("askKay::business_activity_result",(data)=> this.onAskKaySearchResult(data));
        // EventBus.emit("askKay::business_activity_result", sz_companies);
    }
    public componentWillUnmount(): void {
        EventBus.unsubscribe(this.evtChangeFilter);
        EventBus.unsubscribe(this.eventHandler);
        EventBus.unsubscribe(this.eventAskKaySearch);
    }

    public render() {
        /*bp3-skeleton*/
        const targetDate: string = SessionStore.getItem("DateParameter");
        const lokalStyle = {
            maxWidth: 40,
            minWidth: 40,
        };
        const lokalStyle_db = {
            maxWidth: 40,
            minWidth: 40,
            color: "#5c7080",
        };
        const backGroundStyle: CSSProperties = {
            width: "50%",
            height: "100%",
            backgroundImage: `url(${Globals.BASE_DIR}images/zebra.svg)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "50%",
        };
        // <Button title={"Abbrechen"} icon={"cross"} intent={"danger"} minimal={true} className={`${Classes.DIALOG_CLOSE_BUTTON}`} onClick={ () => { this.setState({isOpen: false}); } }/>
        const clearSearch = () => {
          if(!this.state.value){
              return undefined;
          }
          return (
              <Button title={"Leeren"} icon={IconNames.CROSS} color={Colors.GRAY1} minimal={true} onClick={()=> this.searchInput.value=""} />
          );
        }
        const isBlocked = ()=>{
          if(Globals.ownsPackage >= 3){
              return null;
          }
          return (
              <>
                  <br/>
                  <span>für kostenlose Produkte nicht verfügbar.</span>
              </>
          );
        };
        return (
            <Drawer onClose={this.handleClose} {...this.state} portalClassName={"z-index-20000001"}>
                <div className={Classes.DRAWER_HEADER} style={{backgroundColor: "rgb(240, 240, 240)",height: 50,paddingLeft: 10, display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Tooltip
                            className={"sz-popover-content-hack"}
                            content={<span>{_t(ETranslation.apply)}</span>}
                            position={Position.BOTTOM}
                            usePortal={true}>
                            <Button icon={"arrow-left"} intent={"primary"} minimal={true} onClick={ () => { this.handleClose(); } }/>
                        </Tooltip>
                        &nbsp;&nbsp;
                        <SzAskKay />
                        &nbsp;&nbsp;
                        <ControlGroup className={"sz-search-control"} vertical={false} fill={true} style={{width: "250px"}}>
                            <InputGroup
                                placeholder={_t(ETranslation.search_text)}
                                inputRef={(input) => this.searchInput = input}
                                rightElement={clearSearch()}
                                className={"no-focus no-right-border"}
                                ref={this.searchField}
                                autoFocus={true}
                                value={this.state.value}
                                onKeyPress={(e) => {this.handleKeyPress(e); }}
                                onChange={(event) => this.handleChange(event)}
                            />
                            <Tooltip
                                className={"sz-popover-content-hack"}
                                content={<span>{_t(ETranslation.search)}</span>}
                                position={Position.BOTTOM}
                                usePortal={true}>
                                <Button intent={"primary"} icon="search" style={lokalStyle} onClick={() => {this.searchClick(); }} />
                            </Tooltip>
                        </ControlGroup>
                        &nbsp;&nbsp;
                        <Tooltip
                            content={
                            <>
                                <span>{_t(ETranslation.database_extention_request)}</span>
                                {isBlocked()}
                            </>
                            }
                            position={Position.BOTTOM}
                            usePortal={true}
                            shouldReturnFocusOnClose={false}
                        >
                            <AnchorButton intent={Intent.NONE} icon={IconNames.DATABASE} style={lokalStyle_db} onClick={ () => this.showExtendDb() } disabled={Globals.ownsPackage<3} />
                        </Tooltip>
                        &nbsp;&nbsp;
                        <CountryFilter
                            name={_t(ETranslation.countries)}
                            onValueChanged={(data) => {this.countryChanged(data); }}
                            country={this.state.country}
                            region={this.state.region}
                        />
                        &nbsp;&nbsp;
                        <SicFilter name={"SIC"} onValueChanged={(data) => {this.sicChanged(data); }} />
                        &nbsp;&nbsp;
                        <SectorFilter
                            name={_t(ETranslation.sector)}
                            onValueChanged={(data) => {this.sectorChanged(data); }}
                            sector={this.state.sector}
                        />
                        &nbsp;&nbsp;
                        <RangeFilter
                            name={_t(ETranslation.market_cap)}
                            values={[0, 50, 150, 500, 1000, 5000, 10000]}
                            last_value={3000000}
                            onValueChanged={(data) => {this.marketCapChanged(data); }}
                            targetDate={targetDate}
                        />
                        &nbsp;&nbsp;
                        <RangeFilter
                            name={_t(ETranslation.revenues)}
                            values={[0, 500, 1500, 5000, 10000, 50000, 100000]}
                            last_value={30000000}
                            onValueChanged={(data) => {this.revenueChanged(data); }}
                            targetDate={targetDate}
                        />
                        &nbsp;&nbsp;
                        <Tooltip
                            content={<span>{_t(ETranslation.reset_filter)}</span>}
                            position={Position.BOTTOM}
                            usePortal={true}>
                            <Button disabled={!this.state.filterIsSet} minimal={true} intent={Intent.DANGER} icon={IconNames.TRASH} style={lokalStyle} onClick={ () => this.resetFilters() } />
                        </Tooltip>
                        &nbsp;&nbsp;

                        <span className={"sz-search-result-count"}>{_t(ETranslation.hits)}:&nbsp;{this.state.searchResult.length}</span>
                    </div>
                    <div style={{display: "inline-block", marginRight: 5}} className={"sz-longlist-tools"}>
                        {this.renderSaveLongList()}&nbsp;&nbsp;
                        {this.renderSaveDialog()}
                        {this.renderExtendDb()}
                        {this.renderLongListManager()}&nbsp;&nbsp;
                        {this.renderResetLongList()}
                        {this.renderAlert()}
                    </div>
                </div>
                <div className={Classes.DRAWER_BODY}>
                    <div className={`${Classes.DIALOG_BODY} absolute`} style={{margin: 0, marginTop: 10}}>
                        <Toaster position={"top"} autoFocus={false} canEscapeKeyClear={true} ref={this.toaster} className={"in-front"} />
                        <div className={"sz-row hh"}>
                            <div className={"sz-relative sz-8-padding-right"} style={{width: "50%"}}>
                                { this.state.searching ? this.renderSearching() : this.renderSearchResult() }
                            </div>
                            <div  style={backGroundStyle} className={"sz-relative"} >
                                {this.renderView()}
                            </div>
                        </div>

                    </div>
                </div>
            </Drawer>
        );
    }

    public handleOpen(searchText?: string) {
        if (searchText) {
            this.setState({
                isOpen: true,
                value: searchText,
            });
        } else {
            this.setState({
                isOpen: true,
            });
        }
        document.querySelector("html").classList.add("bp3-overlay-open");
    }

    public handleClose() {
        document.querySelector("html").classList.remove("bp3-overlay-open");
        this.setState({ isOpen: false });

        this.afterClose(this.state.longList);

    }
    private afterClose(longList) {
        const options: IOptionEntry[] = longList.map( (company) => {
            const o: IOptionEntry = {
                value: company.company_id,
                label: company.company_name,
                selected: true,
            };
            return o;
        });

        options.sort( (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        longList.sort( (a, b) => a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase()) );
        const companyIds = options.map( (o) => o.value );
        SessionStore.setItem("CompanySearch", longList);
        SessionStore.setItem("CompaniesParameter", companyIds);
        SessionStore.setItem("SelectedCompanySearch", longList);
        SessionStore.setItem("SelectedCompaniesParameter", options);
        SessionStore.setItem("SelectedValue", "CurrentSearch");

        EventBus.emit("Update.Companies.LongList", longList);
        EventBus.emit("Update.CompaniesParameters", options);
    }
    private resetFilters() {
        EventBus.emit("search-reset-filters", null);
        this.currentSector = undefined;
        this.currentCountries = this.initialCountries;
        this.currentKeywords = "";

        this.currentMarketCapMin = -1;
        this.currentMarketCapMax = -1;
        this.currentMarketCapDate = "";

        this.currentRevenueMin = -1;
        this.currentRevenueMax = -1;
        this.currentRevenueDate = "";

        this.currentSicClass = [];
        this.currentSic = [];

        this.setState({
            sector: undefined,
        });

        this.execSearch();
    }
    private searchClick() {
        this.currentKeywords = this.searchInput.value;
        this.execSearch();
    }
    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            value: event.target.value,
        });
    }
    private handleKeyPress(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt && evt.key === "Enter") {
            this.currentKeywords = this.searchInput.value;
            this.execSearch();
        }
    }

    private countryChanged(countryIdArray: any[]) {
        if (countryIdArray && !countryIdArray.length) {
            return;
        }

        let isEq: any = countryIdArray && this.currentCountries;
        if (countryIdArray && this.currentCountries) {
            countryIdArray.forEach((e) => {
                if (this.currentCountries && this.currentCountries.indexOf(e) !== -1) {
                    isEq = isEq && true;
                } else {
                    isEq = false;
                }

                return isEq;
            });
        }
        isEq = isEq && countryIdArray.length === this.currentCountries.length;
        if ( isEq ) {
            return;
        }
        this.currentCountries = countryIdArray;
        this.execSearch();
    }
    private sectorChanged(sectorCode) {
        this.currentSector = sectorCode.code;
        this.execSearch();
    }
    private sicChanged(data: any) {
        this.currentSic = data.select_sub_classes;
        this.currentSicClass = data.select_classes;

        this.execSearch();
    }
    private revenueChanged(data: any) {
        this.currentRevenueMin = -1;
        this.currentRevenueMax = -1;
        this.currentRevenueDate = null;
        if (data[0] === true) {
            this.currentRevenueMin = data[1];
            this.currentRevenueMax = data[2];
            this.currentRevenueDate = data[3];
        }
        this.execSearch();
    }
    private marketCapChanged(data: any) {
        this.currentMarketCapMin = -1;
        this.currentMarketCapMax = -1;
        this.currentMarketCapDate = null;
        if (data[0] === true) {
            this.currentMarketCapMin = data[1];
            this.currentMarketCapMax = data[2];
            this.currentMarketCapDate = data[3];
        }
        this.execSearch();
    }

    private execSearch() {
        if (!this.initialCountries) {
            this.initialCountries = this.currentCountries;
        }
        const db = new PublicDbCalls();
        if ( this.currentKeywords && this.currentKeywords.length < 3 ) {
            this.currentKeywords = undefined;
            return;
        }
        if (this.state.searching) {
            return; // console.error("searching");
        }
        (async () => {
            if (this.currentCountries) {
                this.currentCountries.sort( (a: string , b: string) => a.localeCompare(b) );
            }
            if (!this.currentKeywords) {
                this.currentKeywords = "";
            }
            const searchParameters: ISearchCompanyParameters = {
                key_words: this.currentKeywords,
                countries: this.currentCountries,
                sector_id: this.currentSector,
                marketCapMin: this.currentMarketCapMin,
                marketCapMax: this.currentMarketCapMax,
                marketCapDate: this.currentMarketCapDate,
                revenueMin: this.currentRevenueMin,
                revenueMax: this.currentRevenueMax,
                revenueDate: this.currentRevenueDate,
                sic: this.currentSic,
                sicClass: this.currentSicClass,
            };
            if (!this.searchDefault) {
                this.searchDefault = JSON.stringify(searchParameters);
            }
            // console.error(this.currentKeywords, this.state.value);
            this.setState({
                searching: true,
                viewState: EViewState.VsSearchResult,
                value: this.currentKeywords,
            });
            // filterIsSet: this.searchDefault !== JSON.stringify(searchParameters),

            const result = await db.searchCompany(searchParameters);
            const use: ICompanySearchResult[] = result.result ? result.result : [];
            use.forEach((c) => c.isLongList = 0);
            this.state.longList.forEach((company) => {
                const idx = use.findIndex((c) => company.company_id === c.company_id);
                if (idx >= 0) {
                    use.splice(idx, 1);
                }
            });
            this.setState({
                searchResult: use,
                searching: false,
            });
        })();
    }
    private noData() {
        const lokalStyle = {
            maxWidth: 40,
            minWidth: 40,
        };
        return (<NonIdealState
            className="sz-search-without-result"
            icon={IconNames.WARNING_SIGN}
            title="Suchanfrage ohne Ergebnis"
            description={<div>
                <p>Bitte variieren Sie die Suchparameter, ändern Sie ggf. die Freitextsuche oder die Filterkriterien.</p>
                <br />
                <br />
                <p><a href={"#"}  onClick={ (evt) => {evt.stopPropagation(); this.showExtendDb(); }}>{_t(ETranslation.database_extention_request)}</a></p>
            </div>}
        />);
    }
    private sortBy(fieldName: string) {
        const defIdx = this.state.tableSort.findIndex((field) => field.fieldName === fieldName);
        let def = null;
        if (defIdx < 0) {
            def = {
                direction: 0,
                fieldName,
            };
            this.state.tableSort.push(def);
        } else {
            def = this.state.tableSort[defIdx];
        }
        switch (def.direction) {
            case 0: def.direction = 1; break;
            case 1: def.direction = -1; break;
            case -1: this.state.tableSort.splice(defIdx, 1); break;
        }

        this.setState({
            tableSort: this.state.tableSort,
        });
    }
    private getSortIcon(fieldName: string): IconName {
        const isSorted = this.state.tableSort.find((field) => field.fieldName === fieldName);
        let icon: IconName = "double-caret-vertical";
        if (isSorted) {
            if (isSorted.direction === -1) {
                icon = "caret-down";
            }
            if (isSorted.direction === 1) {
                icon = "caret-up";
            }
        }
        return icon;
    }
    private renderView() {
        if(this.state.viewState === EViewState.VsAskKay || Globals.askKayBlocked){
            return <AskKaySearch searchData={this.state.asKaySearchData} />
        }
        if (this.state.viewState === EViewState.VsSaveLongList) {
            return <SaveLongList onSelectList={(e) => this.onSelectLongList(e)} onResetLongList={ () => this.setState({confirmRestLonglist: true}) }  ref={this.saveLongList}/>;
        }
        if (this.state.viewState === EViewState.VsCompanyDetails) {
            return <CompanyDetails company_id={this.state.selectedCompanyId} company={this.state.selectedCompany} keyWords={this.state.value} onChangeFilter={(filterName: string, data: any)=> this.changeFilter(filterName, data)}/>;
        }
        /*
        if (this.state.viewState === EViewState.VsLoadLongList) {
            return <LoadLongList onSelectList={(e) => this.onSelectLongList(e)} />;
        }
        */
        return null;
    }
    private renderMeta() {
        return (
            <div className={"search-fields"}>
                <table className={"sz-table-no-styles"}>
                    <tbody>
                    <tr>
                        <td style={{border: "none"}}>
                        </td>
                        <td style={{textAlign: "right", border: "none"}}>
                            Suchergebnis:&nbsp;{this.state.searchResult.length}&nbsp;&nbsp;
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        );
    }
    private renderResetLongList() {
        return (<Button onClick={() => {this.setState({confirmRestLonglist: true})}}  intent={this.state.longList.length > 0 ? "danger" : "none"}>{_t(ETranslation.reset_longlist)} ({this.state.longList.length})</Button>);
    }
    private renderLongListManager() {
        if (!Globals.isRegistred) {
            return (<Button onClick={() => {this.needLoggedIn("Longlist Verwaltung"); }} intent={"none"} icon={"lock"}>{_t(ETranslation.longlist_admin)}</Button>);
        }
        return (<Button onClick={() => {this.showLongListManager(); }}  intent={this.state.viewState === EViewState.VsSaveLongList ? "warning" : "primary"}>{_t(ETranslation.longlist_admin)}</Button>);
    }

    private renderSaveLongList() {
        if (!Globals.isRegistred) {
            return (<Button onClick={() => {this.needLoggedIn(_t(ETranslation.longlist_save)); }} intent={"none"} icon={"floppy-disk"} />);
        }
        return (
            <Tooltip
                content={<span>{_t(ETranslation.save)}</span>}
                position={Position.BOTTOM}
                usePortal={true}>
                <Button onClick={() => {this.showSaveDialog(); }}  intent={"primary"} icon={"floppy-disk"} style={{minWidth: 40, maxWidth: 40}} />
            </Tooltip>
        );
    }
    private renderSaveDialog() {
        if (!Globals.isRegistred) {
            return null;
        }
        return (
            <GetNameDialog isOpen={this.state.isShowingSaveDlg} onClose={() => this.closeSaveDlg()} onFinished={(name, isPublic) => this.saveClick(name, isPublic)} />
        );
    }
    private renderExtendDb() {
        if (!Globals.isRegistred) {
            return null;
        }
        return (
            <DlgDbRequest isOpen={this.state.isShowExtendDb} onClose={() => this.closeExtendDb()} />
        );
    }
/*
    private renderLoadLongList() {
        if (!Globals.isRegistred) {
            return (<Button onClick={() => {this.needLoggedIn(); }} small={true} intent={"none"} icon={"lock"}>Load Longlist</Button>);
        }
        return (<Button onClick={() => {this.loadLongList(); }} small={true} intent={this.state.viewState === EViewState.VsLoadLongList ? "warning" : "primary"}>Load Longlist</Button>);
    }
*/
    private renderHeader() {
        return (
            <table className={"fixed-result-table header bp3-html-table bp3-html-table-condensed bp3-html-table-striped bp3-interactive bp3-small"}>
                <thead>
                    <tr style={{backgroundColor: "rgb(240, 240, 240)"}}>
                        <th onClick={() => { this.sortBy("company_name"); }}><Icon icon={this.getSortIcon("company_name")} className={"bp3-text-muted"}/>&nbsp;Name</th>
                        <th onClick={() => { this.sortBy("isLongList"); }}><Icon icon={this.getSortIcon("isLongList")} className={"bp3-text-muted"}/></th>
                        <th onClick={() => { this.sortBy("sector_name_de"); }}><Icon icon={this.getSortIcon("sector_name_de")} className={"bp3-text-muted"}/>&nbsp;{_t(ETranslation.sector)}</th>
                        <th onClick={() => { this.sortBy("industry_name_de"); }}><Icon icon={this.getSortIcon("industry_name_de")} className={"bp3-text-muted"}/>&nbsp;{_t(ETranslation.subsector)}</th>
                        <th onClick={() => { this.sortBy("ff_mkt_val"); }}><Icon icon={this.getSortIcon("ff_mkt_val")} className={"bp3-text-muted"}/>&nbsp;{_t(ETranslation.market_cap)}</th>
                        <th onClick={() => { this.sortBy("country_name_de"); }}><Icon icon={this.getSortIcon("country_name_de")} className={"bp3-text-muted"}/></th>
                    </tr>
                </thead>
            </table>
        );
    }
    private renderData() {
        const formatter = (v) => {
            if (v === null) {
                return "—";
            }
            if (v === undefined) {
                return "—";
            }
            const fm = (new Intl.NumberFormat("de-de", {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            })).format;
            return fm(v);
        };
        let mergeResult: ICompanySearchResult[] = [].concat(this.state.longList, this.state.searchResult);
        mergeResult = this.sortResult(mergeResult);
        let cKey = 0;
        return (
            <table className={"fixed-result-table bp3-html-table bp3-html-table-condensed bp3-html-table-striped bp3-small"}>
                <tbody>
                {mergeResult.map((r) => {
                    const asLonglist = (
                        <td title={"remove from long list"}>
                            <Icon
                                style={{color: "#137cbd"}}
                                icon={"star"}
                                onClick={() => { this.changeFilter("DelLongList", r); }}
                            />
                        </td>
                    );
                    const asSearchResult = (
                        <td title={"add to long list"}><Icon
                            style={{color: "#667f85"}}
                            icon={"star-empty"}
                            onClick={() => { this.changeFilter("AddLongList", r); }}
                        /></td>
                    );
                    return (
                        <tr key={"ResultRow" + (cKey++)} className={this.state.selectedCompanyId === r.company_id ? "sz-selected" : null}>
                            <td onClick={() => this.selectCompany(r)} ><div className={"td-wrapper"}><span className={"td-content"}>{r.company_name}</span></div></td>
                            {r.isLongList ? asLonglist : asSearchResult}
                            <td><a onClick={(evt) => {evt.stopPropagation(); this.changeFilter("SectorFilter", { sector: r.sector_code, subSector:  undefined}); return false;}}>{Sectors.sectors_map[r.sector_code]}</a></td>
                            <td><a onClick={(evt) => {evt.stopPropagation(); this.changeFilter("SectorFilter", { sector: r.sector_code, subSector:  r.industry_code}); return false;}}>{Sectors.sectors_map[r.industry_code]}</a></td>
                            <td>{formatter(r.ff_mkt_val)}</td>
                            <td className={"search-result"}>
                                <div style={{display: "flex", height: "100%", justifyContent: "center"}}>
                                    <img src={`${BASE_DIR}images/flags/${r.country_iso_2}.jpg`}/>
                                </div>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }
    private closeExtendDb() {
        this.setState({
            isShowExtendDb: false,
        });
    }
    private closeSaveDlg() {
        this.setState({
            isShowingSaveDlg: false,
        });
    }
    private saveClick(name: string, isPublic: boolean) {
        (async () => {
            const services = new ServiceCalls();
            const response = await services.saveLongList(name, Globals.longList, isPublic, null);
            this.setState({
                isShowingSaveDlg: false,
            });
            if (this.state.viewState === EViewState.VsSaveLongList && this.saveLongList.current) {
                this.saveLongList.current.reload();
            }
            const searches = response;
            let options = [];
            if (searches && searches.result && Array.isArray(searches.result)) {
                options = searches.result.map((i) => ({value: i._id, label: i.name, selected: false, data: i}));
                Globals.primeSectorOptions = options;
            }
        })();
    }
    private showExtendDb() {
        if (!Globals.isRegistred) {
            return this.needLoggedIn(_t(ETranslation.database_extention_request));
        }

        this.setState({
            isShowExtendDb: true,
        });
    }

    private showSaveDialog() {
        this.setState({
            isShowingSaveDlg: true,
        });
    }
    private showLongListManager() {
        if ( this.state.viewState === EViewState.VsSaveLongList) {
            return this.setState({
                viewState: EViewState.VsSearchResult,
            });
        }
        this.setState({
            viewState: EViewState.VsSaveLongList,
        });
    }
    private loadLongList() {
        if ( this.state.viewState === EViewState.VsLoadLongList) {
            return this.setState({
                viewState: EViewState.VsSearchResult,
            });
        }
        this.setState({
            viewState: EViewState.VsLoadLongList,
        });
    }
    private renderAlert() {
        const okay_del_long_list = ()=>{
            const longList = this.state.longList;
            longList.forEach((company) => {
                company.isLongList = 0;
                this.state.searchResult.unshift(company);
            });
            longList.splice(0, longList.length);
            Globals.longList = longList;
            this.setState({
                longList,
                confirmRestLonglist: false,
            });
        };
        const p: AlertProps = {
            icon: IconNames.TRASH,
            intent: "danger",
            isOpen: this.state.confirmRestLonglist,
            confirmButtonText: _t(ETranslation.yes),
            cancelButtonText: _t(ETranslation.no),
            onCancel: ()=>{this.setState({confirmRestLonglist: false})},
            onConfirm: ()=>{okay_del_long_list();},
        };
        return (
            <Alert {...p}>
                <p>{_t(ETranslation.reset_longlist)}?</p>
            </Alert>
        );
    }
    private onSelectLongList(savedLongList: ISavedLongList) {
        Globals.longList = savedLongList.items;
        this.setState({
            longList: savedLongList.items,
            viewState: EViewState.VsSearchResult,
        });
    }
    private removeFromLongList(company: ICompanySearchResult, andFireChange?: boolean) {
        company.isLongList = 0;
        const longList = this.state.longList;
        const idx = longList.findIndex((c) => c.company_id === company.company_id);
        if (idx > -1) {
            longList.splice(idx, 1);
        }
        this.state.searchResult.unshift(company);
        Globals.longList = longList;
        this.setState({
            longList,
        });
        if (andFireChange) {
            this.afterClose(longList);
        }
    }
    private needLoggedIn(caption?: string) {
        const renderCaption = () => {
            if (caption) {
                return <h5 style={{margin: 0, marginBottom: 10}}>{caption}</h5>;
            } else {
                return null;
            }
        };
        this.toaster.current.show({
            intent: Intent.WARNING,
            message: <>{renderCaption()}Diese Funktionalität steht Ihnen nach Ihrer kostenlosen<br/>
                Registrierung zur Verfügung.</>,
        });
    }
    private addToLongList(company: ICompanySearchResult) {
        if (this.state.longList.length >= maxLongList) {
            this.toaster.current.show({
                icon: "ban-circle",
                intent: Intent.DANGER,
                message: `Es sind max. ${maxLongList} Einträge in der Long-List möglich.`,
            });
            return;
        }
        const idx = this.state.longList.findIndex((c) => c.company_id === company.company_id);
        if (idx > -1) {
           return;
        }
        const idx_2 = this.state.searchResult.findIndex((c) => c.company_id === company.company_id);
        if (idx_2 >= 0) {
            this.state.searchResult.splice(idx_2, 1);
        }
        company.isLongList = 1;
        const longList = this.state.longList;
        longList.push(company);
        Globals.longList = longList;
        this.setState({
            longList,
        });
    }
    private sortResult( source: ICompanySearchResult[] ): ICompanySearchResult[] {
        const sort: IFieldSort[] = this.state.tableSort;
        const result: ICompanySearchResult[] = source;

        if (sort.length === 0) {
            return result;
        }

        const sortFunction = (fields: IFieldSort[]) => {
            return (a: ICompanySearchResult, b: ICompanySearchResult) => {
                for (const i of sort) {
                    if (a[i.fieldName] > b[i.fieldName]) {
                        return i.direction;
                    }
                    if (a[i.fieldName] < b[i.fieldName]) {
                        return -(i.direction);
                    }
                }
                return 0;
            };
        };
        result.sort(sortFunction(sort));
        return result;
    }
    private renderSearching() {
        const scrolling: any = {
            position: "absolute",
            top: "0px",
            bottom: "0px",
            width: "100%",
        };
        return(
            <div className={"scroll-container"} style={scrolling}>
                <div style={{position: "relative", height: "100%", width: "100%", display: "flex", justifyContent: "center"}}>
                    <Spinner size={250} />
                </div>
            </div>
        );
    }
    private renderSearchResult() {
        const scrolling: CSSProperties = {
            position: "absolute",
            top: "0px",
            bottom: "0px",
            width: "100%",
            paddingLeft: 1,
            paddingRight: 1,
        };
        return(
            <div className={"scroll-container"} style={scrolling}>
                <div style={{width: "100%", position: "sticky", top: 0, height: 30, zIndex: 10}}>{this.renderHeader()}</div>
                <div className={"scroll-content"} style={{paddingLeft: 1, paddingRight: 2}}>
                    {(this.state.searchResult.length > 0 || this.state.longList.length > 0) ? this.renderData() : this.noData()}
                </div>
            </div>
        );
    }

    private changeFilter(filterName: string, data: any) {
        if (filterName === "AddLongList") {
            this.addToLongList(data as ICompanySearchResult);
            return;
        }
        if (filterName === "DelLongList") {
            this.removeFromLongList(data as ICompanySearchResult);
            return;
        }
        if ( filterName === "SectorFilter" ) {
            this.currentSector = data.subSectorCode ? data.subSectorCode : data.sectorCode;
            this.setState({
                sector: data,
            });
            return;
        }
        if ( filterName === "SicFilter" ) {
            console.error(data);
            if(data.type && data.type==="sic3" && data.sic3code){
                EventBus.emit("SicFilter::update",{
                    sic3: data.sic3code,
                });
            }
            if(data.type && data.type==="class" && data.sic_class){
                EventBus.emit("SicFilter::update",{
                    sic_class: data.sic_class.code,
                });
            }
            if(data.type && data.type==="range" && data.sic_range){
                EventBus.emit("SicFilter::update",{
                    sic_range: data.sic_range,
                });
            }
            return;
        }
        this.setState(data);
    }

    private getLongList() {
        const data = SessionStore.getItem<any[]>("CompanySearch", []);
        Globals.longList = data;
        return data;
    }

    private selectCompany(r: ICompanySearchResult) {
        this.setState({
            viewState: EViewState.VsCompanyDetails,
            selectedCompanyId: r.company_id,
            selectedCompany: r,
        });
        // EventBus.emit<ICompanySearchResult>("onCompanySearchResult.selected", r);
    }

    private loadData(r: ICompanySearchResult) {
        this.setState({
            viewState: EViewState.VsCompanyDetails,
            selectedCompanyId: r.company_id,
            selectedCompany: r,
        });
    }

    private onAskKaySearch(data: string) {
        this.setState({
            viewState: EViewState.VsAskKay,
            asKaySearchData: data,
        });
    }

    private onAskKaySearchResult(companies: SzCompany[]) {
        // abgleich mit der longlist
        const longList = this.state.longList;
        const new_entries: SzCompany[] = [];
        companies.forEach((c)=>{
            const l = longList.find((i)=> ""+i.company_id === ""+c.id);
            if(!l){
                new_entries.push(c);
            }
        });
        if(!new_entries.length){
            this.setState({
                viewState: EViewState.VsSearchResult,
            });
            return;
        }
        // console.error(new_entries);
        const company_ids = new_entries.map((i)=> i.id);
        (async ()=>{
            const db = new PublicDbCalls();
            const response = await db.getCompanies(company_ids);
            const result = getResult<ICompanySearchResult[]>(response, []);
            // console.error(result);
            if(Array.isArray(result)){
                result.forEach((r)=>{
                    if(longList.length>= maxLongList){
                        return;
                    }
                    r.isLongList = 1;
                    longList.push(r);
                });
                Globals.longList = longList;
                this.setState({viewState: EViewState.VsSearchResult, longList});
            }
        })();


    }
}
