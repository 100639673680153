import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {PARAMETER_OPTION, ParameterOptions} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {ISzSliderProperties, SzSlider} from "../../widgets/SzSlider";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {PdfInsertElement} from "../../../pdf-tools/PdfInsertElement";

const frequencyOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.FrequencyParameter);
const periodOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.PeriodParameter);
const returnTypeOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.ReturnTypeParameter);
const leveredBetaOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.AdjusmentBetaParameter);
export interface IRenditeCombiState {
    selectedPeriod?: any;
    selectedFrequency?: any;
    selectedReturnType?: any;
    selectedLeveredBeta?: any;
}
const COLUMN_LABEL_WIDTH = "30%";
export class RenditeCombiParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getPdfLabel() {
        const period: string = SessionStore.get(EParameters.PeriodsParameter);
        const p = parseInt(period, 10);
        const frq = period.substring(1);
        const content = [];
        PdfInsertElement.selected_option(content, _t(ETranslation.return_period), EParameters.PeriodParameter, `${p}`);
        PdfInsertElement.selected_option(content, _t(ETranslation.observation_period), EParameters.FrequencyParameter, frq);
        // PdfInsertElement.selected_option(content, _t(ETranslation.day), EParameters.WeekDayParameter);
        PdfInsertElement.selected_option(content, _t(ETranslation.return_type), EParameters.ReturnTypeParameter);
        PdfInsertElement.selected_option(content, _t(ETranslation.beta_levered), EParameters.AdjusmentBetaParameter);
        return {
            stack: content,
        };
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.RenditeCombiParameter];
    }
    protected getWidthClass() {
        return "fixed-width-550";
    }
    protected async initialize() {
        const period = SessionStore.getItem(EParameters.PeriodsParameter, ["5m"]);
        let usePeriod = "5";
        let useFrq = "m";
        if (period && Array.isArray(period)) {
            usePeriod = parseInt(period[0], 10) + "";
            useFrq = period[0].replace(usePeriod, "");
            if (!useFrq.length) {
                usePeriod = "5";
                useFrq = "m";
            }
        }
        return {
            selectedPeriod: usePeriod,
            selectedFrequency: useFrq,
            selectedReturnType: SessionStore.get(EParameters.ReturnTypeParameter),
            selectedLeveredBeta: SessionStore.get(EParameters.AdjusmentBetaParameter),
            WeekDayParameter: SessionStore.get(EParameters.WeekDayParameter),
        };
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                {this.renderPeriod()}
                {this.renderFrequency()}
                {this.renderReturnType()}
                {this.renderLeveredBeta()}
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.RenditeCombiParameter))}
            </div>
        );
    }
    protected getLabel(): any {
        const selPeriod = this.state.selectedPeriod; // periodOptions.find((e) => e.value === this.state.selectedPeriod);
        const selFrequency = this.state.selectedFrequency; // frequencyOptions.find((e) => e.value === this.state.selectedFrequency);
        const selectedLeveredBeta = this.state.selectedLeveredBeta; // frequencyOptions.find((e) => e.value === this.state.selectedFrequency);
        const l = (selPeriod + selFrequency).toUpperCase();
        const selReturnType = returnTypeOptions.find((e) => e.value === this.state.selectedReturnType);
        const selLeveredBeta = leveredBetaOptions.find((e) => e.value === selectedLeveredBeta);

        this.putPacketOptionParameter(EParameters.PeriodsParameter, [
            {o: periodOptions, s: selPeriod},
            {o: frequencyOptions, s: selFrequency},
        ]);
        this.putPacketOptionParameter(EParameters.ReturnTypeParameter, [
            {o: returnTypeOptions, s: this.state.selectedReturnType}
        ]);
        this.putPacketOptionParameter(EParameters.AdjusmentBetaParameter, [
            {o: leveredBetaOptions, s: selectedLeveredBeta}
        ]);
        const wd ="";//  this.getOptionLabelHard(EParameters.WeekDayParameter, true);
        return `${l} | ${selReturnType.short} | ${selLeveredBeta ? selLeveredBeta.short : Globals.hyphen}`;
    }
    protected changeState(value, parameterType: EParameters, field: string) {
        if (value === this.state[field]) {
            return;
        }
        const newState: IRenditeCombiState = {
            selectedFrequency: this.state.selectedFrequency,
        };
        newState[field] = value;
        let frq = this.state.selectedFrequency;
        if (field === "selectedPeriod") {
            const year = value;
            if (year === "5" && frq === "d") {
                frq = "m";
            }
            if ((year === "3" || year === "2") && frq === "m") {
                frq = "bw";
            }
            if (year === "1" && (frq === "m" || frq === "bw")) {
                frq = "d";
            }
        }
        if (frq === "d" && value === "5") {
            return;
        }

        if (frq !== this.state.selectedFrequency) {
            newState.selectedFrequency = frq;
        }

        this.setState(newState);

        if (field === "selectedFrequency" || field === "selectedPeriod") {
            const period = newState.selectedPeriod ? newState.selectedPeriod : this.state.selectedPeriod;
            // console.error(`${period}${newState.selectedFrequency}`);
            this.fireValueChange(EParameters.PeriodsParameter, `${period}${newState.selectedFrequency}`);
            return;
        }
        this.fireValueChange(parameterType, value);
    }
    private renderPeriod() {
        return this.renderColumn(COLUMN_LABEL_WIDTH, _t(ETranslation.observation_period), ()=>{
            return this.renderOptionsExt(periodOptions, this.state.selectedPeriod, EParameters.PeriodParameter, "selectedPeriod");
        });
    }
    private renderFrequency() {
        const year = this.state.selectedPeriod;
        const frq = this.state.selectedFrequency;
        const chck = (o: IOptionEntry) => {
            if (year === "5" && o.value === "d") {
                return true;
            }
            if (year === "3" && o.value === "m") {
                return true;
            }
            if (year === "2" && o.value === "m") {
                return true;
            }
            if (year === "1" && (o.value === "m" || o.value === "bw")) {
                return true;
            }
            return false;
        };
        return this.renderColumn(COLUMN_LABEL_WIDTH, _t(ETranslation.return_period), ()=>{
            return this.renderOptionsExt(frequencyOptions, this.state.selectedFrequency, EParameters.FrequencyParameter, "selectedFrequency", chck);
        });
    }
    private renderReturnType() {
        return this.renderColumn(COLUMN_LABEL_WIDTH, _t(ETranslation.return_type), ()=>{
            return this.renderOptionsExt(returnTypeOptions, this.state.selectedReturnType, EParameters.ReturnTypeParameter, "selectedReturnType");
        });
    }
    private renderLeveredBeta() {
        return this.renderColumn(COLUMN_LABEL_WIDTH, _t(ETranslation.beta_levered), ()=>{
            return this.renderOptionsExt(leveredBetaOptions, this.state.selectedLeveredBeta, EParameters.AdjusmentBetaParameter, "selectedLeveredBeta");
        });
    }

    private renderWeekDay() {
        const currentFrq = this.state.selectedFrequency;
        const parameter: EParameters = EParameters.WeekDayParameter;
        const value = this.state[parameter];
        const options: IOptionEntry[] = ParameterOptions.options[parameter];
        const inputProps: ISzSliderProperties = {
            value: options.findIndex((o: IOptionEntry)=> o.value === value),
            disabled: currentFrq === "d" || currentFrq === "m",
            min: 0,
            max: options.length - 1,
            stepSize: 1,
            showTrackFill: false,
            labelRenderer: (v: number) => {
                const s: IOptionEntry = options[v];
                return s?.label;
            },
            onValueChanged: (v: number) => {
                const s: IOptionEntry = options[v];
                if(s){
                    if (s.value === this.state[parameter]) {
                        return;
                    }
                    const newState = {};
                    newState[parameter] = s.value
                    this.setState(newState);
                    this.fireValueChange(parameter, s.value);
                }
            }
        };
        return this.renderColumn(COLUMN_LABEL_WIDTH, "", ()=>{
            return (
                <div style={{paddingRight: 35, paddingLeft: 35, marginBottom: 10}}>
                    <SzSlider {...inputProps} />
                </div>
            );
        });
    }
}
