import {
    Breadcrumbs,
    Button,
    Classes,
    Colors,
    HTMLTable,
    IBreadcrumbProps,
    Icon,
    IToastProps,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NonIdealState,
    Toaster,
} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {EStatisticsValueType} from "../const/EStatisticsValueType";
import {Globals} from "../const/Globals";
import {
    EFileManagerEntryTypes,
    IFileManagerEntry,
    IFileManagerLoadFolder,
    IFileManagerPath,
    IFileManagerPathResult,
} from "../models/IFileManagerEntry";
import {EServiceType, ServiceCallBase} from "../services/ServiceCallBase";
import {ServiceCalls} from "../services/ServiceCalls";
import {SzSvgLoader} from "./widgets/SzSvgLoader";
import {DateTime} from "luxon";

export interface IFileManagerProps {
    style?: CSSProperties;
    contentStyle?: CSSProperties;
    headerStyle?: CSSProperties;
    contentTableStyle?: CSSProperties;
}
export interface IFileManagerState {
    selection: string[];
    scrollBarWidth: number;
    isLoading: boolean;
    data: IFileManagerLoadFolder;
    path: IFileManagerPath[];
}

const selectedColor = Colors.DARK_GRAY1;
const unselectedColor = Colors.LIGHT_GRAY3;

export class FileManager  extends React.Component <IFileManagerProps, IFileManagerState> {
    private readonly container: React.RefObject<HTMLDivElement> = null;
    private readonly toaster: React.RefObject<Toaster> = null;
    constructor(props: IFileManagerProps, context: any) {
        super(props, context);
        this.toaster = React.createRef();
        this.container = React.createRef();
        this.state = {
            selection: [],
            scrollBarWidth: 17,
            isLoading: true,
            data: null,
            path: [],
        };
    }
    public getCSSProperties(): CSSProperties {
        return this.props.style ? this.props.style : {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
        };
    }
    public render() {
        const style: CSSProperties = this.getCSSProperties();

        const renderPath = () => {
            if (!this.state.path) {
                return null;
            }
            if (!this.state.path.length) {
                return null;
            }
            const items: IBreadcrumbProps[] = this.state.path.map( (p) => ({onClick: () => {this.openFolder(p._id); }, href: "#", text: p.name, icon: p._id === "0" ? "home" : null}) );
            return (
                <Breadcrumbs items={items} />
            );
        };
        /* <Button className={Classes.MINIMAL} icon="folder-new" text="Neuer Ordner" style={{minHeight: 50}} /> */
        return (
            <div className="sz-file-manager" style={style}>
                <Navbar className={"sz-file-manager-navbar"}>
                    <NavbarGroup>
                        <Button className={Classes.MINIMAL} icon="trash" text="Auswahl löschen" disabled={this.state.selection.length === 0} style={{minHeight: 50}} onClick={() => {this.deleteFolders(); }} />
                        <NavbarDivider style={{marginRight: 20}} />
                        <div style={{marginLeft: 6}}>{renderPath()}</div>
                    </NavbarGroup>
                </Navbar>
                {this.renderContent()}
                <Toaster usePortal={true} canEscapeKeyClear={true} ref={this.toaster} />
            </div>
        );
    }

    public componentDidMount(): void {
        if (this.container.current) {
            const scrollBarWidth = this.container.current.offsetWidth - this.container.current.clientWidth;
            if (scrollBarWidth > 0 && scrollBarWidth !== this.state.scrollBarWidth) {
                this.setState({
                    scrollBarWidth,
                });
            }
        }
        this.readFolder();
    }
    private openFolder(folderId: string) {
        this.setState({
            selection: [],
            isLoading: true,
            data: null,
            path: [],
        });
        this.readFolder(folderId);
    }
    private deleteFolders() {
        if (!this.state.selection) {
            return;
        }
        const doDelete = () => {
            const items = this.state.selection.map( (_id) => ({id: _id}) );
            (async () => {
                try {
                    const sc = new ServiceCalls();
                    const ret: IFileManagerLoadFolder = await sc.deleteItems(items);
                    console.error(ret);
                    this.openFolder(this.state.data.parent_folder_id);
                } catch (ex) {
                    console.error(ex);
                }
            })();
        };
        const toastProps: IToastProps = {
            action: {
                onClick: () => { doDelete(); },
                text: "Ja",
            },
            icon: "warning-sign",
            intent: "none",
            message: "Auswahl löschen?",
        };
        this.toaster.current.show(toastProps);
    }
    private readFolder(folderId: string = "0") {
        (async () => {
            try {
                const sc = new ServiceCalls();
                const ret: IFileManagerLoadFolder = await sc.readFolder(folderId);
                const path: IFileManagerPathResult = await sc.getPath(folderId);
                this.setState({
                    selection: [],
                    isLoading: false,
                    data: ret,
                    path: path.entries,
                });
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    private selectEntryClick(entry: IFileManagerEntry) {
        const selection = this.state.selection;
        const selIdx = selection.findIndex( (_id) => entry._id === _id );
        if (selIdx < 0) {
            entry.isSelected = true;
            selection.push(entry._id);
        } else {
            entry.isSelected = false;
            selection.splice(selIdx, 1);
        }
        this.setState({
            selection,
        });
    }
    private renderContent() {
        const style: CSSProperties = this.props.contentStyle ? this.props.contentStyle : {
            top: 50,
            left: 0,
            right: 0,
            bottom: 0,
            position: "absolute",
            margin: 0,
            overflowY: "scroll",
        };
        return (
            <div style={style} ref={this.container} className={"sz-file-manager-container"}>
                {this.renderHeader()}
                {this.contentDiv()}
                {this.renderLoader()}
            </div>
        );
    }
    private renderHeader() {
        const style: CSSProperties = this.props.headerStyle ? this.props.headerStyle : {
            top: 50,
            left: 0,
            width: `calc(100% - ${this.state.scrollBarWidth}px)`,
            position: "fixed",
            margin: 0,
            zIndex: 10,
            boxShadow: "0 1px 0 0 rgba(16, 22, 26, 0.15)",
        };
        const allSelected: boolean = this.state.data && this.state.data && this.state.data.entries && this.state.data.entries.length && this.state.selection.length === this.state.data.entries.length;
        return (
            <table className={"sz-table bp3-html-table bp3-html-table-condensed sz-small-padding sz-file-manager-header"} style={style}>
                <thead>
                <tr>
                    <th style={{width: 20}}><Icon icon={"tick"} color={ allSelected ? selectedColor : unselectedColor} /></th>
                    <th>Name</th>
                    <th style={{width: 170}}>Datum</th>
                </tr>
                </thead>
            </table>
        );
    }
    private renderLoader() {
        if (!this.state.isLoading) {
            return null;
        }
        return <SzSvgLoader />;
    }
    private contentDiv() {
        const mkStat = () => {
            Globals.trackInfo(EStatisticsValueType.file_download, undefined);
        };
        const style: CSSProperties = this.props.contentTableStyle ? this.props.contentTableStyle : {
            width: "100%",
            position: "relative",
            margin: 0,
            marginTop: 30,
        };
        const emtpy = (<NonIdealState icon={"folder-open"} title={`Ihr Dokumenten-Ordner ist noch leer.`} />);
        if (!this.state.data) {
            return emtpy;
        }
        if ((this.state.data && this.state.data.entries && this.state.data.entries.length === 0)) {
            return emtpy;
        }
        const getIcon = (e: IFileManagerEntry) => {
            if ( e.entry_type === EFileManagerEntryTypes.folder) {
                return <Icon icon={"folder-close"} />;
            }
            return <Icon icon={"document"} />;
        };
        const getEntry = (e: IFileManagerEntry) => {
            if ( e.entry_type === EFileManagerEntryTypes.folder) {
                return <a className={"sz-fm-entry"} href="#" onClick={() => {this.openFolder(e._id); }}>{getIcon(e)}&nbsp;&nbsp;{e.name}</a>;
            }
            return <a className={"sz-fm-entry"} href={`${ServiceCallBase.getHost(EServiceType.Services)}/document-store/file/${e._id}`} target={"download"} onClick={() => mkStat()}>{getIcon(e)}&nbsp;&nbsp;{e.name}</a>;
        };
        const getSelectIcon = (e: IFileManagerEntry) => {
            if (e.isSelected) {
                return <Icon icon={"tick"} color={selectedColor} onClick={() => this.selectEntryClick(e)} /> ;
            }
            return <Icon icon={"tick"} color={unselectedColor} onClick={() => this.selectEntryClick(e)} />;
        };
        let cKey: number = 1;
        return (
            <HTMLTable className={"sz-table sz-small-padding sz-file-manager-content"} style={style} condensed={true} interactive={true} striped={true}>
                <tbody>
                {!this.state.data ? null : this.state.data.entries.map((entry) => (
                    <tr key={"fileManEntry" + (cKey++)}>
                        <td style={{width: 20}}>{getSelectIcon(entry)}</td>
                        <td>{getEntry(entry)}</td>
                        <td style={{width: 170}}>{DateTime.fromISO(entry.date).toFormat("dd.LL.yyyy hh:mm")}</td>
                    </tr>
                ))}
                </tbody>
            </HTMLTable>
        );
    }
}
