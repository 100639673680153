import * as React from "react";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {Button, Classes, Dialog, FormGroup, InputGroup, Intent, IToastProps} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {useState} from "react";
import {ButtonWait} from "../hooks/ButtonWait";
import {FrontendCalls} from "../../services/FrontendCalls";
import {Globals} from "../../const/Globals";
import {EventBus} from "../../services/EventBus";


enum EPasswordStep{
    enter_password= 1,
    test_password,
    test_password_error,
    enter_new_passwords,
    set_new_password,
    password_changed,
    password_change_error
}

export interface IDlgChangePassword {
    isOpen: boolean;
    onClose(): void;
}
interface IPassword {
    show: boolean;
    password: string;
}
const default_password = (): IPassword => ({show: false, password: ""});
export const DlgChangePassword = (props: IDlgChangePassword) => {
    const [passwords, setPasswords] = useState<IPassword[]>([
        default_password(),
        default_password(),
        default_password(),
    ]);

    const [passwordStep, setPasswordStep] = useState<EPasswordStep>(EPasswordStep.enter_password);
    const [page, setPage] = useState<number>(0);
    const [error_text, setErrorText] = useState<string>(undefined);

    const reset = ()=>{
        setPasswords([
            default_password(),
            default_password(),
            default_password(),
        ]);
        setPage(0);
        setPasswordStep(EPasswordStep.enter_password);
        setErrorText("");
    };

    const setPassword = (idx: number, text: string)=>{
        passwords[idx].password = text;
        setPasswords([
            passwords[0],
            passwords[1],
            passwords[2],
        ]);
    };
    const pwdState  = (idx: number)=>{
        return (
            <Button
                icon={passwords[idx].show ? "unlock" : "lock"}
                intent={Intent.NONE}
                minimal={true}
                onClick={()=>{passwords[idx].show = !passwords[idx].show; setPasswords(passwords.map((i)=>i));}}
            />
        );
    };
    const renderPage0 = ()=>{
        const renderPasswordTest = ()=>{
            if(error_text){
                return <span className={Classes.INTENT_WARNING}>{error_text}</span>;
            }
            return <span className={Classes.INTENT_WARNING}>&nbsp;</span>;
        };
        return (
            <div className={Classes.DIALOG_BODY}>
                <FormGroup
                    label="Aktuelles Passwort"
                >
                    <InputGroup
                        autoComplete="current-password"
                        id="current-password"
                        name="current-password"
                        leftIcon={IconNames.KEY}
                        type={passwords[0].show ? "text" : "password"}
                        rightElement={pwdState(0)}
                        value={passwords[0].password}
                        onChange={(e)=> setPassword(0, e.target.value)}
                        required={true}
                    />
                </FormGroup>
                <div className={Classes.FORM_HELPER_TEXT}>
                    {renderPasswordTest()}
                </div>
            </div>
        );
    };
    const renderPage1 = ()=>{
        const renderPasswordTest = ()=>{
            if(error_text){
                return <span className={Classes.INTENT_WARNING}>{error_text}</span>;
            }
            return <span className={Classes.INTENT_WARNING}>&nbsp;</span>;
        };
        return (
            <div className={Classes.DIALOG_BODY}>
                <FormGroup
                    label="Neues Passwort"
                >
                    <InputGroup
                        leftIcon={IconNames.KEY}
                        type={passwords[1].show ? "text" : "password"}
                        rightElement={pwdState(1)}
                        value={passwords[1].password}
                        onChange={(e)=> setPassword(1, e.target.value)}
                        required={true}
                    />
                </FormGroup>
                <FormGroup
                    label="Passwortwiederholung"
                >
                    <InputGroup
                        leftIcon={IconNames.KEY}
                        type={passwords[2].show ? "text" : "password"}
                        rightElement={pwdState(2)}
                        value={passwords[2].password}
                        onChange={(e)=> setPassword(2, e.target.value)}
                        required={true}
                    />
                </FormGroup>
                <div className={Classes.FORM_HELPER_TEXT}>
                    {renderPasswordTest()}
                </div>
            </div>
        );
    };
    const renderPage = ()=>{
        if(page === 0){
            return renderPage0();
        }
        if(page === 1){
            return renderPage1();
        }
        return "renderPage???";
    };
    const renderOkayButtonText = ()=>{
        if(
            passwordStep === EPasswordStep.enter_password
            || passwordStep === EPasswordStep.test_password_error
        ){
            return "Weiter";
        }
        if(passwordStep === EPasswordStep.test_password){
            return <ButtonWait />;
        }
    };
    const clickNext = ()=>{
        if(page === 0 && passwordStep === EPasswordStep.enter_password && passwords[0].password && passwords[0].password.length){
            setPasswordStep(EPasswordStep.test_password);
            (async ()=>{
                const c = new FrontendCalls();
                const r = await c.test_login(passwords[0].password);
                if(r.uuid){
                    setErrorText(undefined);
                    setPage(1);
                }else{
                    setErrorText("Passwort ist falsch.");
                }
                setPasswordStep(EPasswordStep.enter_password);
            })();
            return;
        }
        if(page === 1){
            if(passwords[1].password!==passwords[2].password){
                return setErrorText("Passwörter sind nicht gleich.");
            }
            if(passwords[1].password===passwords[2].password){
                setErrorText("");
            }
            setPasswordStep(EPasswordStep.test_password);
            (async ()=>{
                const c = new FrontendCalls();
                const r = await c.change_login(passwords[0].password, passwords[1].password);
                if(r.uuid){
                    reset();
                    props.onClose();
                    return;
                }
                if(!r.uuid){
                    setErrorText("Passwort konnte nicht geändert werden.");
                }
                setPasswordStep(EPasswordStep.enter_password);
            })();
            return;
        }
    };
    return (
        <Dialog
            title={"Passwort ändern"}
            isOpen={props.isOpen}
            icon={IconNames.KEY}
            canOutsideClickClose={true}
            usePortal={true}
            style={{minHeight: 400}}
            onClose={() => {reset(); props.onClose();} }
            shouldReturnFocusOnClose={false}
        >
            {renderPage()}
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => {reset(); props.onClose();} } minimal={true}>{_t(ETranslation.cancel)}</Button>
                    &nbsp;&nbsp;
                <Button intent={"primary"} onClick={()=> clickNext()}>{renderOkayButtonText()}</Button>
                </div>
            </div>
        </Dialog>
    );
}
