import * as React from "react";
import {SzDocReference} from "../components/widgets/SzDocReference";
import {EParameters} from "../models/EParameters";
import {SzVidReference} from "../components/widgets/SzVidReference";
import {_t} from "../tools/Translator";
import {ETranslation} from "./ETranslation";

const help = {};
const _help_map = {};
class HelpMap {
    private p: EParameters;
    public static init(p: EParameters){
        const h = new HelpMap();
        h.p = p;
        _help_map[p] = {};
        return h;
    }
    public add(pp, txt){
        _help_map[this.p][pp] = txt;
        return this;
    }
    public others(txt){
        if(!_help_map[this.p].others){
            _help_map[this.p].others = [];
        }
        _help_map[this.p].others.push(txt);
        return this;
    }
}
/*
help[EParameters.DateParameter]=[
    "Für Stichtage vor Januar 2010 kontaktieren Sie uns bitte.",
];
HelpMap.init(EParameters.DateParameter)
    .add(EParameters.DateParameter, <>Für Stichtage vor Januar 2010 kontaktieren Sie uns bitte.</>)
;
*/
help[EParameters.TpKeyWordsParameter]=[
    "Suchbegriffe: Es wird in den Unternehmensbeschreibungen (in engl.) gesucht.",
    `Verknüpfung: "Und" ein Treffer enthält alle Begriffe, "Oder" ein Treffer enthält einen Teil der Begriffe`,
];
HelpMap.init(EParameters.TpKeyWordsParameter)
    .add(EParameters.TpKeyWordsWordsParameter, "Es wird in den Unternehmensbeschreibungen (in engl.) gesucht.")
;


help[EParameters.RenditeCombiParameter] =[
    _t(ETranslation.observation_period_description),
    _t(ETranslation.return_period_description),
    _t(ETranslation.return_type_description),
    _t(ETranslation.beta_levered_description),
    (<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.adjusted_beta)} ref_id={"33"} inline={true}/></>),
    (<>Video: <SzVidReference text={_t(ETranslation.stock_index)} video_name={"Referenzindex.mp4"} video_poster={"referenzindex.jpg"} /></>),
];
HelpMap.init(EParameters.RenditeCombiParameter)
    .add(EParameters.PeriodParameter, "Der Betrachtungszeitraum umfasst die Dauer der gesamten Schätzperiode, über die der Beta-Faktor ermittelt wird.")
    .add(EParameters.FrequencyParameter, "Das Renditeintervall definiert die Zeitspanne, über die aus den Grunddaten die Aktien- und Indexrenditen ermittelt werden.")
    .add(EParameters.ReturnTypeParameter, "Die Kursrendite entspricht der relativen Preisänderung von Aktien- und Indexkurs im ausgewählten Renditeintervall. Die Gesamtrendite beinhaltet zusätzlich zur Kursrendite weitere Komponenten, u.a. Dividenden und den Wert von Bezugsrechten.")
    .add(EParameters.AdjusmentBetaParameter, "Die Einstellung \"Unadjusted Beta (Raw Beta)\"  basiert alle folgenden Berechungen auf dem sog. Raw Beta, welches im Rahmen der Regressionsanalyse erhoben wird. Das Adjusted Beta basiert auf dem Blume-Adjustment unter Verwendung des Raw Beta (66,7% Gewichtung) und des Marktmittelpunktes von 1 (33,3 % Gewichtung).")
    .others(<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.adjusted_beta)} ref_id={"33"} inline={true}/></>)
    .others(<>Video: <SzVidReference text={_t(ETranslation.stock_index)} video_name={"Referenzindex.mp4"} video_poster={"referenzindex.jpg"} /></>)
;

help[EParameters.IndexCombiParameter] =[
    _t(ETranslation.index_composition_description),
    _t(ETranslation.stock_market_and_currency_description),
    (<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.stock_index)} ref_id={"35"} inline={true}/></>),
];
HelpMap.init(EParameters.IndexCombiParameter)
    .add(EParameters.CompositionTypeParameter, "Der Benchmark-Index ist in der Regel der Leitindex des ausgewählten Aktienmarktes, z.B. der DAX40 für Deutschland. Der Allshare-Index ist ein breiter definierter Gesamtmarktindex des ausgewählten Aktienmarktes, z.B. der CDAX für Deutschland")
    .add(EParameters.IndexTypeParameter, "Aktienmarkt und Währung: Bei der Einstellung \"National lokale Währung\" werden Index- und Aktienrendite  in der Währung der Primärnotiz des Unternehmens unter Verwendung des in dieser Währung verfügbaren Referenzindex ermittelt, z.B.  Daimler AG  in EUR und DAX 40 in EUR, Apple Inc. in USD und S&P 500 in USD, Toyota Motor Corporation in JPY und NIKKEI 225 in JPY.\n" +
        "Bei der Einstellung \"Regional EUR\" werden Index- und Aktienrendite jeweils in EUR unter Verwendung des verfügbaren regionalen Referenzindex ermittelt, z.B. Daimler AG in EUR und EuroSTOXX 50 in EUR, Apple Inc. in EUR und MSCI North America in EUR , Toyota Motor Corporation in EUR und MSCI AC Asia Pacific in EUR.\n" +
        "Bei der Einstellung \"Global EUR\" bzw. \"Global USD\" werden Index- und Aktienrendite jeweils in EUR bzw. USD unter Verwendung des verfügbaren globalen Referenzindex ermittelt, d.h. alle Unternehmen werden in EUR bzw. USD gegen den MSCI World in EUR bzw. USD regressiert.")
    .others(<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.stock_index)} ref_id={"35"} inline={true}/></>)
;
help[EParameters.GearingCombiParameter]=[
    _t(ETranslation.gearing_definition_description),
    _t(ETranslation.gearing_period_description),
    _t(ETranslation.gearing_negativ_value_description),
];
HelpMap.init(EParameters.GearingCombiParameter)
    .add(EParameters.GearingPeersParameter, "Der Verschuldungsgrad geht in die Berechnung des unlevered Beta des Vergleichsunternehmens aus dem erhobenen Levered Beta ein. Die Zusammensetzung der (Netto-)Verschuldung kann mit Ausnahme der Finanzschulden (Pflichtbestanteil) frei definniert werden, indem weitere zinstragende Verpflichtung oder aktive Abzugspositionen hinzugefügt bzw. abgezogen werden. Die Verpflichtung aus Operate Leasing Verbindlichkeiten werden bis 2018 separat ermittelt und hinzugefügt, ab 2019 sind diese bereits in den Finanzschulden berücksichtigt.")
    .add(EParameters.MahalanobicDistanceParameter, "Der Durchschnittswert wird ermittelt auf Basis der im Renditezeitraum verfügbaren Jahresabschlussdaten. Zum Beispiel wird zum Bewertungsstichtag 31.12.2018 und einem fünfjährigen Betrachtungszeitraum der Durchschnitt über die Jahresabschlussdaten der Jahre 2013 bis 2018 errechnet. Der Stichtagswert greift auf den letztverfügbaren Jahresabschluss zum Bewertungsstichtag zurück. Zum Beispiel werden zum Bewertungsstichtag 31.03.2019 die Jahresabschlussdaten per 31.12.2018 verwendet, sofern diese Daten bereits vorliegen und das Berichtsjahr des Unternehmens dem Kalenderjahr entspricht.")
    .add(EParameters.NegativeVerschuldungParameter, "Die Anwendung einer negativen Verschuldung im Rahmen der Ermittlung des unlevered Beta führt dazu, dass das unlevered Beta eines Vergleichsunternehmens mit negativer Verschuldung größer ist als das levered Beta. Dieser Effekt kann mittels der Einstellung \"auf Null setzen\" vermieden werden.")
;
help[EParameters.TaxshieldParameter]=[
    _t(ETranslation.tax_shield_prop_2_description),
    (<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.tax_shield)} ref_id={"41"} inline={true}/></>),
];
HelpMap.init(EParameters.TaxshieldParameter)
    .add(EParameters.TaxshieldParameter, "Das Tax-Shield geht in die Berechnung des unlevered Beta des Vergleichsunternehmens aus dem erhobenen Levered Beta ein. Bei Auswahl der Einstellung zum Tax-Shield \"unsicher\" wird auf die Anwendung eines Tax-Shield bei der Ermittlung des unlevered Beta verzichtet (Harris / Pingle). Die Einstellung \"sicher\" setzt ein Tax-Shield bei der Ermittlung des unlevered Beta der Vergleichsunternehmen an (Hamada) an. Variiert werden kann in diesem Zusammenhang die Höhe des verwendeten Steuersatzes, z.B. gesetzlicher Steuersatz, oder effektiver Steuersatz aufwandsbasiert oder zahlungsbasiert.")
    .others(<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.tax_shield)} ref_id={"41"} inline={true}/></>)
;
help[EParameters.DebtBetaCombiParameter]=[
    _t(ETranslation.debt_beta_description),
    _t(ETranslation.debt_beta_credit_spread_description),
    _t(ETranslation.debt_beta_market_risk_premium_description),
    (<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.debt_beta)} ref_id={"21"} inline={true}/></>),
];
HelpMap.init(EParameters.DebtBetaCombiParameter)
    .add(EParameters.DebtbetaParameter, "Das Debt Beta geht in die Berechnung des unlevered Beta des Vergleichsunternehmens aus dem erhobenen Levered Beta ein. Die Anwendung des Debt Beta kann abhängig von der Höhe des Verschuldungsgrades erfolgen, da eigenkapitalspezifische Risiken in der Regel erst aber einer bestimmten Höhe durch die Fremdkaptialgeber übernommen werden.")
    .add(EParameters.CreditSpreadsParameter, "Der Kreditaufschlag börsennotierter Anleihen bildet die Basis für die Ermittlung des Debt Betas. Abhängig vom indikativen Rating des Vergleichunternehmens, kann dieser Kreditaufschlag sektorspezifisch oder sektorunspezifisch ermittelt werden. \"Sektorspezifsch\" bedeutet, dass in den Kreditaufschlag für eine bestimmte Ratingklasse nur Unternehmen mit notierten Anleihen am Kapitalmarkt mit diesem Rating und gleicher Sektorzugehörigkeit einfließen. \"Sektorunspezifisch\" bedeutet, dass in den Kreditaufschlag für eine bestimmte Ratingklasse alle Unternehmen mit notierten Anleihen am Kapitalmarkt einfließen.")
    .add(EParameters.MarketRiskPremiumParameter, <>Marktrisikoprämie: Die Marktrisikoprämie wird im Zusammenhang mit der Ermittlung der Kapitalkosten des Bewertungsobjektes festgelegt und kann <a href={"desk-kapitalkosten/"}>hier im Modul Kapitalkosten</a> geändet werden.</>)
    .others(<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.debt_beta)} ref_id={"21"} inline={true}/></>)
;
help[EParameters.SectorCountriesParameter] = [
    _t(ETranslation.sector_filter_description),
];
HelpMap.init(EParameters.SectorCountriesParameter)
    .add(EParameters.SectorCountriesParameter,_t(ETranslation.sector_filter_description))
;

help[EParameters.ReleveredBetaParameter] = [
    _t(ETranslation.beta_unlevered_description),
    _t(ETranslation.gearing_prop_description),
    _t(ETranslation.tax_rate_prop_description),
    _t(ETranslation.tax_shield_prop_description),
    _t(ETranslation.debt_beta_prop_description),
    (<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.debt_beta)} ref_id={"21"} inline={true}/>, <SzDocReference text={_t(ETranslation.beta_levered)} ref_id={"62"} inline={true}/>, <SzDocReference text={_t(ETranslation.tax_shield)} ref_id={"41"} inline={true}/></>),
    (<>Video: <SzVidReference text={_t(ETranslation.gearing)} video_name={"Verschuldungsgrad.mp4"} video_poster={"verschuldungsgrad.jpg"} /></>),
];
HelpMap.init(EParameters.ReleveredBetaParameter)
    .add(EParameters.LeverageParameter,"Der Verschuldungsgrad ist definiert als das Verhältnis aus Marktwert Nettoverschuldung und Marktwert Eigenkapital und geht sowohl in die Ermittlung des „relevered Beta“ als auch in die Kapitalgewichtung ein.")
    .add(EParameters.TaxRateParameter,"Der Steuersatz geht in die Ermittlung des Tax-Shield-Effekts ein. Abhängig von der getroffenen Auswahl zum Tax-Shield erfolgt die Berücksichtigung nur bei der Ermittlung der Fremdkapitalkosten nach Steuern (sog. Cashflow-Effekt) oder zusätzlich auch bei der Ermittlung des levered Beta (sog. Risiko-Effekt).")
    .add(EParameters.TaxShieldTargetCompanyRiskEffect,"Das Tax-Shield geht im Rahmen der Berechnung der Kapitalkosten in die Ermittlung des levered Beta und der Fremdkapitalkosten nach Steuern ein.")
    .add(EParameters.DebtBetaValueParameter,"Das Debt Beta geht in die Berechnung des levered Beta des Bewertungsobjektes ein. Die Höhe des Debt Beta wird dabei aus dem gewählten Kreditaufschlag und unter Berücksichtigung des anteiligen systematischen Risikos ermittelt oder direkt eingeben.")
    .others(<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.debt_beta)} ref_id={"21"} inline={true}/>, <SzDocReference text={_t(ETranslation.beta_levered)} ref_id={"62"} inline={true}/>, <SzDocReference text={_t(ETranslation.tax_shield)} ref_id={"41"} inline={true}/></>)
    .others(<>Video: <SzVidReference text={_t(ETranslation.gearing)} video_name={"Verschuldungsgrad.mp4"} video_poster={"verschuldungsgrad.jpg"} /></>)
;
help[EParameters.BaseRateParameter] = [
    _t(ETranslation.country_currency_prop_description),
    _t(ETranslation.duration_base_rate_prop_description),
    _t(ETranslation.base_rate_curve_prop_description),
    _t(ETranslation.rounding_prop_description),
    _t(ETranslation.growth_rate_base_rate_prop_description),
    (<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.base_rate)} ref_id={"50"} inline={true}/></>),
];
HelpMap.init(EParameters.BaseRateParameter)
    .add(EParameters.FixedCountriesParameter, "Mittels der Einstellung zum Land wird die zugrundeliegende Währung und die Länderrisikoprämie festgelegt, soweit eine Anwendung beabsichtigt ist.")
    .add(EParameters.RuntimeBaseInterestParameter, "Mittels der Laufzeiteinstellung kann ein Punkt bzw. ein Bereich der Basiszins-Kurve ausgewählt werden. Bei einer unendlichen Laufzeit wählen Sie bitte den Wert \"∞\" als obere Intervallgrenze.")
    .add(EParameters.BaseInterestCurveParameter, "Als Grundlage für der Ermittlung des Basisizinssatz kann die Stichtagskurve oder der Durchschnitt der Basiszinsskurve der letzen 90-Tage ausgewählt werden.")
    .add(EParameters.RoundingRuleParameter, "Gemäß den Vorgaben des IDW (FN 08/2016) ist bei Zinssätzen kleiner 1,0% auf 0,10% zu runden. Bei Zinssätzen größer 1,0% kommt die ursprüngliche Empfehlung des FAUB zum tragen nach der auf 0,25% gerundet wird.")
    .add(EParameters.GrowthRateCashSurpluseParameter, "Der barwertäquivalente Zinssatz ist ein wertgewichteter Durchschnitt der laufzeitspezifischen Null-Kupon-Zinssätze. Die Wachstumsrate dient der Approximation der Entwicklung der erwarteten Cashflows. Je höher die Wachstumsrate gewählt wird, desto größer ist die Gewichtung der Zinssätze größerer Laufzeiten. Einen ungewichteten Durchschnitt erhalten Sie, indem Sie die Wachstumsrate auf Null setzen und die obere Intervallgrenze kleiner \"∞\".")
    .others(<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.base_rate)} ref_id={"50"} inline={true}/></>)
;

help[EParameters.MarketAndCountryRiskParameter] = [
    _t(ETranslation.mrp_prop_description),
    _t(ETranslation.crp_prop_description),
    _t(ETranslation.company_specific_risk_premium_prop_description),
    (<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.country_risk_premium)} ref_id={"24"} inline={true}/></>),
];
HelpMap.init(EParameters.MarketAndCountryRiskParameter)
    .add(EParameters.MarketRiskPremiumParameter, "Die Marktrisikoprämie geht in die Ermittlung der Eigenkapitalrisikoprämie in Verbindung mit dem levered Beta ein und die Ermittlung des Debt Betas.")
    .add(EParameters.CountryRiskUsageParameter, "Bei Auswahl einer Länderrisikoprämie wird diese auf die Eigen- und Fremdkapitalkosten angewendet.")
    .add(EParameters.SizePremium,_t(ETranslation.company_specific_risk_premium_prop_description))
    .others(<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.country_risk_premium)} ref_id={"24"} inline={true}/></>)
;
help[EParameters.CreditSpreadParameter] = [
    _t(ETranslation.duration_credit_spread_prop_description),
    _t(ETranslation.rating_prop_description),
    _t(ETranslation.sector_prop_description),
];
HelpMap.init(EParameters.CreditSpreadParameter)
    .add(EParameters.RuntimeParameter, "Mittels der Laufzeiteinstellung kann ein Punkt bzw. ein Bereich der Kreditaufschlagskurve ausgewählt werden.")
    .add(EParameters.RatingParameter, "Das Rating dient der Festlegung der Kreditwürdigkeit des Bewertungsobjektes. Es kann zwischen den Ratingklassen AA (sichere Anlage mit geringem Ausfallrisiko) und B (hochspekulative Anlage) gewählt werden.")
    .add(EParameters.SectorParameter, "Die Höhe des Kreditaufschlags ist abhängig vom Geschäftsrisiko. Durch Auswahl des entsprechenden Sektor kann eine sektorspezifische Ermittlung des Kreditauschlags gewählt werden.")
;
help[EParameters.TpAnalysisSizeParameter] = [
    "Operative Erträge: Umsatzerlöse; nicht enthalten sind sonstige betriebliche Erträge, Zinserträge o.ä.",
    "Rohertrag: Operative Erträge abzgl. Herstellungskosten inklusive Abschreibungen; bei Unternehmen, die nach dem Gesamtkostenverfahren berichten, enthalten die Herstellungskosten sämtliche Personalkosten, soweit im Rahmen der Finanzberichterstattung nicht explizit eine Aufteilung der Personalkosten nach Funktionsbereichen erfolgt.",
    (
        <>
            <p>EBIT bereinigt & EBITDA bereinigt: Operative Erträge abzgl. bereinigter operativer Aufwendungen inkl. der Abschreibungen (EBIT) oder exkl. der Abschreibungen (EBITDA).</p>
            <p>Mit dem Ziel einer bestmöglichen Vergleichbarkeit aller Unternehmen werden u.a. die folgenden Tatbestände bereinigt:</p>
            <ul>
                <li style={{fontSize: 12}}>Restrukturierungsaufwendungen</li>
                <li style={{fontSize: 12}}>Wertberichtigungen und außerplanmäßige Abschreibungen</li>
                <li style={{fontSize: 12}}>Aufwendungen und Erträge aus Rechtsstreitigkeiten</li>
                <li style={{fontSize: 12}}>Nicht realisierte Gewinne- und Verluste</li>
                <li style={{fontSize: 12}}>Außerordentliches Ergebnis</li>
                <li style={{fontSize: 12}}>Ergebnis aus nicht fortgeführten Geschäftsaktivitäten</li>
            </ul>
        </>
    ),
];
HelpMap.init(EParameters.TpAnalysisSizeParameter)
    .add(EParameters.TpOpResult, (
        <>
            <p>Umsatzerlöse; nicht enthalten sind sonstige betriebliche Erträge, Zinserträge o.ä.</p>
            <p>Operative Erträge abzgl. Herstellungskosten inklusive Abschreibungen; bei Unternehmen, die nach dem Gesamtkostenverfahren berichten, enthalten die Herstellungskosten sämtliche Personalkosten, soweit im Rahmen der Finanzberichterstattung nicht explizit eine Aufteilung der Personalkosten nach Funktionsbereichen erfolgt.</p>
            <p>EBIT bereinigt & EBITDA bereinigt: Operative Erträge abzgl. bereinigter operativer Aufwendungen inkl. der Abschreibungen (EBIT) oder exkl. der Abschreibungen (EBITDA).</p>
            <p>Mit dem Ziel einer bestmöglichen Vergleichbarkeit aller Unternehmen werden u.a. die folgenden Tatbestände bereinigt:</p>
            <ul className={"bp3-text-small"}>
                <li style={{fontSize: 12}}>Restrukturierungsaufwendungen</li>
                <li style={{fontSize: 12}}>Wertberichtigungen und außerplanmäßige Abschreibungen</li>
                <li style={{fontSize: 12}}>Aufwendungen und Erträge aus Rechtsstreitigkeiten</li>
                <li style={{fontSize: 12}}>Nicht realisierte Gewinne- und Verluste</li>
                <li style={{fontSize: 12}}>Außerordentliches Ergebnis</li>
                <li style={{fontSize: 12}}>Ergebnis aus nicht fortgeführten Geschäftsaktivitäten</li>
            </ul>
        </>
    ))
;
help[EParameters.TpSicParameter] = [
    "SIC-Code: Die Standard Industrial Classification(SIC) ist ein 4-stelliger Zahlencode, der jedes Unternehmen nach seiner Tätigkeit einer bestimmten Branche zuordnet. Hierarchisch steht die erste Ziffer für den Hauptwirtschaftszweig [auf diese Ebene wird im Rahmen der Such-Funktionalität verzichtet], die zweite Ziffer für den Wirtschaftszweig, die dritte Ziffer für den Wirtschaftsbereich und die letzte Ziffer für die Branche. Anhand des SIC-Codes können Sie Ihre Zielgruppe genau klassifizieren, die gleichzeitige Filterung nach mehreren Wirtschaftszweigen oder Branchen ist möglich.",
];
HelpMap.init(EParameters.TpSicParameter)
    .add(EParameters.TpSicParameter, "Die Standard Industrial Classification(SIC) ist ein 4-stelliger Zahlencode, der jedes Unternehmen nach seiner Tätigkeit einer bestimmten Branche zuordnet. Hierarchisch steht die erste Ziffer für den Hauptwirtschaftszweig [auf diese Ebene wird im Rahmen der Such-Funktionalität verzichtet], die zweite Ziffer für den Wirtschaftszweig, die dritte Ziffer für den Wirtschaftsbereich und die letzte Ziffer für die Branche. Anhand des SIC-Codes können Sie Ihre Zielgruppe genau klassifizieren, die gleichzeitige Filterung nach mehreren Wirtschaftszweigen oder Branchen ist möglich.")
;
help[EParameters.TpYearsParameter] = [
    "Referenzjahr: Über die Einstellung Referenzjahr wird das letzte der insgesamt 3 betrachteten Geschäftsjahre festgelegt. Für alle Unternehmen – unabhängig vom Ende des Geschäftsjahrs - wird im Referenzjahr das Geschäftsjahr gezeigt, dass im ausgewählten Referenzjahr endet.",
];
HelpMap.init(EParameters.TpYearsParameter)
    .add(EParameters.TpYearsParameter, "Über die Einstellung Referenzjahr wird das letzte der insgesamt 3 betrachteten Geschäftsjahre festgelegt. Für alle Unternehmen – unabhängig vom Ende des Geschäftsjahrs - wird im Referenzjahr das Geschäftsjahr gezeigt, dass im ausgewählten Referenzjahr endet.")
;
help[EParameters.TpCountriesParameter] = [
    "Länder: Die Einschränkung nach Ländern bezieht sich auf den Hauptsitz des Unternehmens.",
];
HelpMap.init(EParameters.TpCountriesParameter)
    .add(EParameters.TpCountriesParameter, "Die Einschränkung nach Ländern bezieht sich auf den Hauptsitz des Unternehmens.")
;

help[EParameters.TpFreeFloatParameter] = [
    "Streubesitz: Der Streubesitz (Free Float) ist der Anteil der zum Handel an einer Börse zugelassenen Aktien, die sich in der Hand kleinerer Aktionäre befinden.",
];
HelpMap.init(EParameters.TpFreeFloatParameter)
    .add(EParameters.TpFreeFloatParameter, "Der Streubesitz (Free Float) ist der Anteil der zum Handel an einer Börse zugelassenen Aktien, die sich in der Hand kleinerer Aktionäre befinden")
;

help[EParameters.TpSalesParameter] = [
    "Umsatzgröße: Einschränkung der berücksichtigten Firmen nach ihrer Umsatzgröße. Als einheitliche Referenzwährung wird der EUR verwendet.",
];
HelpMap.init(EParameters.TpSalesParameter)
    .add(EParameters.TpSalesParameter, "Einschränkung der berücksichtigten Firmen nach ihrer Umsatzgröße. Als einheitliche Referenzwährung wird der EUR verwendet.")
;

help[EParameters.TpNumEmployeesParameter] = [
    "Mitarbeiteranzahl: Einschränkung der berücksichtigten Firmen nach ihrer Mitarbeiteranzahl zum Geschäftsjahresende.",
];
HelpMap.init(EParameters.TpNumEmployeesParameter)
    .add(EParameters.TpNumEmployeesParameter, "Einschränkung der berücksichtigten Firmen nach ihrer Mitarbeiteranzahl zum Geschäftsjahresende.")
;

help[EParameters.FixedCurrencyParameter] = [
    "Es werden ausschliesslich Anleihen mit der gewählten Währung berücksichtigt.",
];
HelpMap.init(EParameters.FixedCurrencyParameter)
    .add(EParameters.FixedCurrencyParameter, "Es werden ausschliesslich Anleihen mit der gewählten Währung berücksichtigt.")
;

help[EParameters.SeniorityParameter] = [
    "Seniorität bzw. Risikoklassifizierung unterscheidet Anleihen nach höher verzinst (nachrangig) und niedrig verzinst (vorrangig)",
];
HelpMap.init(EParameters.SeniorityParameter)
    .add(EParameters.SeniorityParameter, "Seniorität bzw. Risikoklassifizierung unterscheidet Anleihen nach höher verzinst (nachrangig) und niedrig verzinst (vorrangig)")
;

help[EParameters.PledgeStatusParameter] = [
    "Gibt an ob eine Auszahlung im Insolvenzfall besichert (versichert) ist.",
];
HelpMap.init(EParameters.PledgeStatusParameter)
    .add(EParameters.PledgeStatusParameter, "Gibt an ob eine Auszahlung im Insolvenzfall besichert (versichert) ist.")
;

help[EParameters.SicParameter] = [
    "Die Standard Industrial Classification(SIC) ist ein 4-stelliger Zahlencode, der jedes Unternehmen nach seiner Tätigkeit einer bestimmten Branche zuordnet. Hierarchisch steht die erste Ziffer für den Hauptwirtschaftszweig [auf diese Ebene wird im Rahmen der Such-Funktionalität verzichtet], die zweite Ziffer für den Wirtschaftszweig, die dritte Ziffer für den Wirtschaftsbereich und die letzte Ziffer für die Branche. Anhand des SIC-Codes können Sie Ihre Zielgruppe genau klassifizieren, die gleichzeitige Filterung nach mehreren Wirtschaftszweigen oder Branchen ist möglich.",
];
HelpMap.init(EParameters.SicParameter)
    .add(EParameters.SicParameter, "Die Standard Industrial Classification(SIC) ist ein 4-stelliger Zahlencode, der jedes Unternehmen nach seiner Tätigkeit einer bestimmten Branche zuordnet. Hierarchisch steht die erste Ziffer für den Hauptwirtschaftszweig [auf diese Ebene wird im Rahmen der Such-Funktionalität verzichtet], die zweite Ziffer für den Wirtschaftszweig, die dritte Ziffer für den Wirtschaftsbereich und die letzte Ziffer für die Branche. Anhand des SIC-Codes können Sie Ihre Zielgruppe genau klassifizieren, die gleichzeitige Filterung nach mehreren Wirtschaftszweigen oder Branchen ist möglich.")
;

help[EParameters.CreditSpreadXRangeParameter] = [
    "Die Bandbreite ist nicht symmetrisch, sondern enthält jene 100 Unternehmen, für die - in absoluten Zahlen gemessen – die kleinste Differenz zum Wert des zu beurteilenden Unternehmens ermittelt wurde",
];
HelpMap.init(EParameters.CreditSpreadXRangeParameter)
    .add(EParameters.CreditSpreadXRangeParameter, "Die Bandbreite ist nicht symmetrisch, sondern enthält jene 100 Unternehmen, für die - in absoluten Zahlen gemessen – die kleinste Differenz zum Wert des zu beurteilenden Unternehmens ermittelt wurde")
;

help[EParameters.CreditSpreadSoftParameter] = [
    "Die qualitativen Beurteilung dient dazu, Faktoren in die Einschätzung einzubeziehen, die sich nicht unmittelbar aus den aktuellen Finanzdaten ableiten lassen, aber aus Gläubigersicht dennoch wichtig sind und bei der Bonität ein wichtige Rolle spielen.",
];
HelpMap.init(EParameters.CreditSpreadSoftParameter)
    .add(EParameters.CreditSpreadSoftParameter, "Die qualitativen Beurteilung dient dazu, Faktoren in die Einschätzung einzubeziehen, die sich nicht unmittelbar aus den aktuellen Finanzdaten ableiten lassen, aber aus Gläubigersicht dennoch wichtig sind und bei der Bonität ein wichtige Rolle spielen.")
;

help[EParameters.CreditSpreadConfigParameter] = [
    "Longlist (# Unternehmen): Die Festlegung dient dazu, die Anzahl der insgesamt die Analyse einbezogenen Unternehmen festzulegen. Als Auswahlkriterium dient die Ähnlichkeit bezüglich der ausgewählten Kreditkennzahlen.",
    "Shortlist (% Longlist): Die Festlegung dient dazu festzulegen, welcher Anteil der Verteilung der herangezogenen Vergleichsunternehmen in die finale Auswahl eingehen. Der Bereich der Shortlist kann durch die quantitativen Kriterien verschoben werden.",
];
HelpMap.init(EParameters.CreditSpreadConfigParameter)
    .add(EParameters.CreditSpreadAnalysisIntervalParameter, "Die Festlegung dient dazu, die Anzahl der insgesamt die Analyse einbezogenen Unternehmen festzulegen. Als Auswahlkriterium dient die Ähnlichkeit bezüglich der ausgewählten Kreditkennzahlen.")
    .add(EParameters.CreditSpreadXRangeParameter, "Die Festlegung dient dazu festzulegen, welcher Anteil der Verteilung der herangezogenen Vergleichsunternehmen in die finale Auswahl eingehen. Der Bereich der Shortlist kann durch die quantitativen Kriterien verschoben werden.")
;

help[EParameters.CreditSpreadMetricParameter] = [
    "Die Kreditkennzahlen umfassen - thematisch geordnet - die am häufigsten verwendeten Kreditkennzahlen. Die entsprechende Auswahl sollte mit Blick auf den Sektor und den dort üblicherweise verwendeten Kennzahlen erfolgen.",
];
HelpMap.init(EParameters.CreditSpreadMetricParameter)
    .add(EParameters.CreditSpreadMetricParameter, "Die Kreditkennzahlen umfassen - thematisch geordnet - die am häufigsten verwendeten Kreditkennzahlen. Die entsprechende Auswahl sollte mit Blick auf den Sektor und den dort üblicherweise verwendeten Kennzahlen erfolgen.")
;

help[EParameters.BondFilterParameter] = [
    "Währung: Durch die Auswahl der Währung kann das Universum der zugrunde gelegten börsennotierten Anleihen eingeschränkt werden. Relevant ist in diesem Zusammenhang nur die Emissionswährung der Anleihe.",
    "Seniorität: Durch die Auswahl der Seniorität kann das Universum der zugrunde gelegten börsennotierten Anleihen nach ihrem Rang eingeschränkt werden.",
    "Pfandrecht: Durch die Auswahl zum Pfandrecht kann das Universum der zugrunde gelegten börsennotierten Anleihen nach ihrer dinglichen Besicherung eingeschränkt werden.",
    "Garantie o.ä.: Durch die Auswahl zu Garantien kann das Universum der zugrunde gelegten börsennotierten Anleihen nach Kreditsicherheiten aus Garantien, Bürgschaften o.ä. eingeschränkt werden.",
    "Sondertilgung: Durch die Auswahl zur Sondertilgung kann das Universum der zugrunde gelegten börsennotierten Anleihen nach einem Sonderkündigungsrecht durch den Emittenten eingeschränkt werden.",
];
HelpMap.init(EParameters.BondFilterParameter)
    .add(EParameters.FixedCurrencyParameter, "Durch die Auswahl der Währung kann das Universum der zugrunde gelegten börsennotierten Anleihen eingeschränkt werden. Relevant ist in diesem Zusammenhang nur die Emissionswährung der Anleihe.")
    .add(EParameters.SeniorityParameter, "Durch die Auswahl der Seniorität kann das Universum der zugrunde gelegten börsennotierten Anleihen nach ihrem Rang eingeschränkt werden.")
    .add(EParameters.PledgeStatusParameter, "Durch die Auswahl zum Pfandrecht kann das Universum der zugrunde gelegten börsennotierten Anleihen nach ihrer dinglichen Besicherung eingeschränkt werden.")
    .add(EParameters.BondEnhancementParameter, "Durch die Auswahl zu Garantien kann das Universum der zugrunde gelegten börsennotierten Anleihen nach Kreditsicherheiten aus Garantien, Bürgschaften o.ä. eingeschränkt werden.")
    .add(EParameters.BondCallableParameter, "Durch die Auswahl zur Sondertilgung kann das Universum der zugrunde gelegten börsennotierten Anleihen nach einem Sonderkündigungsrecht durch den Emittenten eingeschränkt werden.")
;
help[EParameters.BalanceSheetDateParameter] = [
    "Die Einstellung Geschäftsjahr dient der Zuordnung der Unternehmensdaten.",
];
HelpMap.init(EParameters.BalanceSheetDateParameter)
    .add(EParameters.BalanceSheetDateParameter, "Die Einstellung Geschäftsjahr dient der Zuordnung der Unternehmensdaten.")
;

help[EParameters.BondSuperSectorParameter] = [
    "Sektor: Die Höhe des Kreditaufschlags ist abhängig vom Geschäftsrisiko. Durch Auswahl des entsprechenden Sektor kann eine sektorspezifische Ermittlung des Kreditaufschlags erzielt werden.",
];
HelpMap.init(EParameters.BondSuperSectorParameter)
    .add(EParameters.BondSuperSectorParameter, "Die Höhe des Kreditaufschlags ist abhängig vom Geschäftsrisiko. Durch Auswahl des entsprechenden Sektor kann eine sektorspezifische Ermittlung des Kreditaufschlags erzielt werden.")
;
HelpMap.init(EParameters.CreditSpreadRuntimeParameter)
    .add(EParameters.CreditSpreadRuntimeParameter, "Mittels der Laufzeiteinstellung kann ein Punkt bzw. ein Bereich der Kreditaufschlagskurve ausgewählt werden.")
;
HelpMap.init(EParameters.BondSectorParameter)
    .add(EParameters.BondSectorParameter, "Die Höhe des Kreditaufschlags ist abhängig vom Geschäftsrisiko. Durch Auswahl des entsprechenden Sektor kann eine sektorspezifische Ermittlung des Kreditauschlags gewählt werden.")
;

HelpMap.init(EParameters.RegionParameter)
    .add(EParameters.RegionParameter, "")
;

help[EParameters.BetaFilterParameter] = [
    _t(ETranslation.filter_data_points_description),
    _t(ETranslation.filter_bid_ask_spread_description),
    _t(ETranslation.filter_free_float_description),
    _t(ETranslation.filter_r_square_description),
    _t(ETranslation.filter_beta_factor_t_test_description),
    _t(ETranslation.filter_durbin_watson_description),
    _t(ETranslation.filter_breusch_pagan_description),
    (<>{_t(ETranslation.glossary)}: <SzDocReference text={_t(ETranslation.r_square)} ref_id={"27"} inline={true}/>, <SzDocReference text={"T-Test"} ref_id={"57"} inline={true}/>, <SzDocReference text={"Durbin-Watson-Test"} ref_id={"73"} inline={true}/>, <SzDocReference text={"Breusch-Pagan-Test"} ref_id={"74"} inline={true}/></>),
];
HelpMap.init(EParameters.BetaFilterParameter)
    .add(EParameters.BetaFilterDataPoints, "Unternehmen können aus der Stichprobe ausgeschlossen werden, wenn die Renditepunkte nicht vollständig sind.")
    .add(EParameters.BetaFilterBidAsk, "Unternehmen können aus der Stichprobe ausgeschlossen werden, wenn die Geld-Brief-Spanne einer definiertes Niveau nicht überschreiten soll.")
    .add(EParameters.BetaFilterFreeFloat, "Unternehmen können aus der Stichprobe ausgeschlossen werden, wenn der Anteil der Anteile im Streubesitz einer definiertes Niveau überschreitet soll.")
    .add(EParameters.BetaFilterR2, "Unternehmen können aus der Stichprobe ausgeschlossen werden, wenn das Bestimmheitsmaß der linearen Regression ein definiertes Niveau nicht unterschreiten soll.")
    .add(EParameters.BetaFilterTTest, "Unternehmen können aus der Stichprobe ausgeschlossen werden, wenn der ermittelte Beta-Faktor bei einem Signifikanzniveau von 95% nicht signifikant von Null verschieden ist.")
    .add(EParameters.BetaFilterDurbinWatsonTest, "Unternehmen können aus der Stichprobe ausgeschlossen werden, wenn die Residuen der linearen Regression bei einem Signifikanzniveau von 95% Autokorrelation 1. Ordnung zeigen.")
    .add(EParameters.BetaFilterBreuschPaganTest, "Unternehmen können aus der Stichprobe ausgeschlossen werden, wenn die Residuen der linearen Regression  bei einem Signifikanzniveau von 95% Heteroskedastizität zeigen.")
;


export function PARAMETER_HELP(p: EParameters) {
    return help[p];
}
export function PARAMETER_HELP_MAP(p: EParameters) {
    return _help_map[p];
}
