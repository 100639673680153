import {BaseRateParameter} from "../components/toolbar/combi-inputs/BaseRateParameter";
import {CompaniesParameter} from "../components/toolbar/combi-inputs/CompaniesParameter";
import {CountryRiskPremiumCombiParameter} from "../components/toolbar/combi-inputs/CountryRiskPremiumCombiParameter";
import {CreditSpreadParameter} from "../components/toolbar/combi-inputs/CreditSpreadParameter";
import {DateParameter} from "../components/toolbar/combi-inputs/DateParameter";
import {DebtBetaCombiParameter} from "../components/toolbar/combi-inputs/DebtBetaCombiParameter";
import {GearingCombiParameter} from "../components/toolbar/combi-inputs/GearingCombiParameter";
import {IndexCombiParameter} from "../components/toolbar/combi-inputs/IndexCombiParameter";
import {MarketAndCountryRiskParameter} from "../components/toolbar/combi-inputs/MarketAndCountryRiskParameter";
import {PrimeSectorParameter} from "../components/toolbar/combi-inputs/PrimeSectorParameter";
import {ReleveredBetaParameter} from "../components/toolbar/combi-inputs/ReleveredBetaParameter";
import {RenditeCombiParameter} from "../components/toolbar/combi-inputs/RenditeCombiParameter";
import {TpCountriesParameter} from "../components/toolbar/combi-inputs/TpCountriesParameter";
import {TpMarginComposition} from "../components/toolbar/combi-inputs/TpMarginComposition";
import {TpSalesParameter} from "../components/toolbar/combi-inputs/TpSalesParameter";
import {TpSicParameter} from "../components/toolbar/combi-inputs/TpSicParameter";
import {DividerParameter} from "../components/toolbar/DividerParameter";
import {AdjusmentBetaParameter} from "../components/toolbar/inputs/AdjusmentBetaParameter";
import {BaseCurrencyParameter} from "../components/toolbar/inputs/BaseCurrencyParameter";
import {CashflowParameter} from "../components/toolbar/inputs/CashflowParameter";
import {CountriesParameter} from "../components/toolbar/inputs/CountriesParameter";
import {CountryParameter} from "../components/toolbar/inputs/CountryParameter";
import {CountryRiskPremiumParameter} from "../components/toolbar/inputs/CountryRiskPremiumParameter";
import {CountryRiskUsageParameter} from "../components/toolbar/inputs/CountryRiskUsageParameter";
import {CreditSpreadXRangeParameter} from "../components/toolbar/inputs/CreditSpreadXRangeParameter";
import {DebtBetaValueParameter} from "../components/toolbar/inputs/DebtBetaValueParameter";
import {FixedCountriesParameter} from "../components/toolbar/inputs/FixedCountriesParameter";
import {FixedCurrencyParameter} from "../components/toolbar/inputs/FixedCurrencyParameter";
import {GrowthRateCashSurpluseParameter} from "../components/toolbar/inputs/GrowthRateCashSurpluseParameter";
import {ImfDataViewParameter} from "../components/toolbar/inputs/ImfDataViewParameter";
import {IntrestRateCountryParameter} from "../components/toolbar/inputs/IntrestRateCountryParameter";
import {LeverageParameter} from "../components/toolbar/inputs/LeverageParameter";
import {MarketRiskPremiumParameter} from "../components/toolbar/inputs/MarketRiskPremiumParameter";
import {PledgeStatusParameter} from "../components/toolbar/inputs/PledgeStatusParameter";
import {ProfitabilityParameter} from "../components/toolbar/inputs/ProfitabilityParameter";
import {ProjectParameter} from "../components/toolbar/inputs/ProjectParameter";
import {RatingKeyParameter} from "../components/toolbar/inputs/RatingKeyParameter";
import {RatingParameter} from "../components/toolbar/inputs/RatingParameter";
import {RegionParameter} from "../components/toolbar/inputs/RegionParameter";
import {RentabilityParameter} from "../components/toolbar/inputs/RentabilityParameter";
import {RuntimeBaseInterestParameter} from "../components/toolbar/inputs/RuntimeBaseInterestParameter";
import {RuntimeParameter} from "../components/toolbar/inputs/RuntimeParameter";
import {SectorCountriesParameter} from "../components/toolbar/inputs/SectorCountriesParameter";
import {SectorParameter} from "../components/toolbar/inputs/SectorParameter";
import {SeniorityParameter} from "../components/toolbar/inputs/SeniorityParameter";
import {TaxshieldParameter} from "../components/toolbar/inputs/TaxshieldParameter";
import {TpFreeFloatParameter} from "../components/toolbar/inputs/TpFreeFloatParameter";
import {TpYearsParameter} from "../components/toolbar/inputs/TpYearsParameter";
import {TpNumEmployeesParameter} from "../components/toolbar/combi-inputs/TpNumEmployeesParameter";
import {TpAnalysisSizeParameter} from "../components/toolbar/combi-inputs/TpAnalysisSizeParameter";
import {TpKeyWordsParameter} from "../components/toolbar/combi-inputs/TpKeyWordsParameter";
import {TickerParameter} from "../components/toolbar/inputs/TickerParameter";
import {SicParameter} from "../components/toolbar/inputs/SicParameter";
import {CountryIso2Parameter} from "../components/toolbar/inputs/CountryIso2Parameter";
import {CreditSpreadDecisionParameterA} from "../components/toolbar/inputs/CreditSpreadDecisionParameterA";
import {CreditSpreadDecisionParameterB} from "../components/toolbar/inputs/CreditSpreadDecisionParameterB";
import {CreditSpreadDecisionParameterC} from "../components/toolbar/inputs/CreditSpreadDecisionParameterC";
import {CreditSpreadDecisionParameterD} from "../components/toolbar/inputs/CreditSpreadDecisionParameterD";
import {CreditSpreadSoftParameter} from "../components/toolbar/combi-inputs/CreditSpreadSoftParameter";
import {BondPropertiesParameter} from "../components/toolbar/combi-inputs/BondPropertiesParameter";
import {BondSectorParameter} from "../components/toolbar/inputs/BondSectorParameter";
import {CreditSpreadMetricParameter} from "../components/toolbar/combi-inputs/CreditSpreadMetricParameter";
import {BondFilterParameter} from "../components/toolbar/combi-inputs/BondFilterParameter";
import {CreditSpreadConfigParameter} from "../components/toolbar/combi-inputs/CreditSpreadConfigParameter";
import {CreditSpreadRuntimeParameter} from "../components/toolbar/inputs/CreditSpreadRuntimeParameter";
import {BetaFilterParameter} from "../components/toolbar/combi-inputs/BetaFilterParameter";
import {SectorParameterV2} from "../components/toolbar/inputs/SectorParameterV2";
import {RegionParameterV2} from "../components/toolbar/inputs/RegionParameterV2";

export const components = {
    CompaniesParameter,
    CountriesParameter,
    DateParameter,
    AdjusmentBetaParameter,
    TaxshieldParameter,
    GrowthRateCashSurpluseParameter,
    DividerParameter,
    IndexCombiParameter,
    GearingCombiParameter,
    SectorParameter,
    RatingParameter,
    RuntimeParameter,
    ImfDataViewParameter,
    PrimeSectorParameter,
    RuntimeBaseInterestParameter,
    RenditeCombiParameter,
    DebtBetaCombiParameter,
    CountryParameter,
    BaseCurrencyParameter,
    ProfitabilityParameter,
    RentabilityParameter,
    RatingKeyParameter,
    CashflowParameter,
    IntrestRateCountryParameter,
    FixedCountriesParameter,
    LeverageParameter,
    DebtBetaValueParameter,
    CountryRiskUsageParameter,
    CountryRiskPremiumParameter,
    MarketRiskPremiumParameter,
    CountryRiskPremiumCombiParameter,
    ReleveredBetaParameter,
    CreditSpreadParameter,
    BaseRateParameter,
    SectorCountriesParameter,
    TpSicParameter,
    TpFreeFloatParameter,
    TpCountriesParameter,
    TpYearsParameter,
    TpSalesParameter,
    TpMarginComposition,
    TpNumEmployeesParameter,
    TpAnalysisSizeParameter,
    MarketAndCountryRiskParameter,
    ProjectParameter,
    TpKeyWordsParameter,
    FixedCurrencyParameter,
    SeniorityParameter,
    PledgeStatusParameter,
    TickerParameter,
    SicParameter,
    CountryIso2Parameter,
    CreditSpreadDecisionParameterA,
    CreditSpreadDecisionParameterB,
    CreditSpreadDecisionParameterC,
    CreditSpreadDecisionParameterD,
    CreditSpreadSoftParameter,
    BondPropertiesParameter,
    BondSectorParameter,
    CreditSpreadMetricParameter,
    CreditSpreadXRangeParameter,
    BondFilterParameter,
    CreditSpreadConfigParameter,
    CreditSpreadRuntimeParameter,
    RegionParameter,
    BetaFilterParameter,
    SectorParameterV2,
    RegionParameterV2,
};
