import {Button, InputGroupProps2, Intent, MenuItem} from "@blueprintjs/core";
import {Omnibar} from "@blueprintjs/select";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {ICountry} from "../../../models/ICountry";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {PublicDbCalls} from "../../../services/PublicDbCalls";
import {ToolBarParameter} from "../ToolBarParameter";

const CountryOmnibar = Omnibar.ofType<ICountry>();

export class CountryParameter extends ToolBarParameter<IToolBarParameter> {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }

    protected async initialize() {
        const db = new PublicDbCalls();
        if (!Globals.countries.length) {
            Globals.countries = (await db.getAllCountries()).result;
        }

        const selectedCountry = SessionStore.get(EParameters.CountryParameter);
        const country = Globals.countries.find( (i) => i.id === selectedCountry );
        return {
            selected: {value: country.id, label: country.name_de},
            showSearch: false,
        };
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CountryParameter];
    }

    protected renderContent() {
        const INPUT_PROPS: InputGroupProps2 = { placeholder: "Suche ..." };
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        if (this.state.isLoading) {
            return (
                <Button intent={Intent.NONE} minimal={true} className={"sz-toolbar-button bp3-skeleton"}>
                    <span className={"sz-property-name"}>{this.name}</span>
                    <div className={"sz-property-value"}>{this.getLabel()}</div>
                </Button>
            );
        }
        return (
            <div>
                <CountryOmnibar
                    className={"sz-search-country"}
                    inputProps={INPUT_PROPS}
                    itemPredicate={this.filterCountry}
                    isOpen={this.state.showSearch}
                    items={Globals.countries}
                    itemRenderer={this.renderCountry}
                    onClose={() => this.setState({showSearch: false})}
                    onItemSelect={(item) => this.selectItem(item)} />
                <Button intent={Intent.NONE} minimal={true} className={"sz-toolbar-button"} onClick={() => this.setState({showSearch: true})}>
                    <span className={"sz-property-name"}>{this.name}</span>
                    <div className={"sz-property-value"}>{this.getLabel()}</div>
                </Button>
            </div>
        );
    }
    protected getLabel(): string {
        const selection = this.state ? this.state.selected : null;
        if (!selection) {
            return "—";
        }
        return selection.label;
    }
    private filterCountry(query, country: ICountry, _index, exactMatch) {
        const findCountry = (option: IOptionEntry) => option.value === country.id;
        const text = [country.name_de, country.name, country.iso_2].join(", ").toLowerCase();
        return text.indexOf(query.toLowerCase()) >= 0; // || this.state.options.find(findCountry)? true : false;
    }
    private renderCountry(country: ICountry, { handleClick, modifiers, query }): any {
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={country.name_de}
                key={country.id}
                onClick={handleClick}
                text={[country.name_de, country.name, country.iso_2].join(", ")}
            />
        );
    }
    private selectItem(country: ICountry) {
        this.setState({
            showSearch: false,
            selected: {
                label: country.name_de,
                value: country.id,
                selected: true,
            },
        });
        this.fireChanges([{
            component: this.props.primaryComponent,
            value: [country.id],
        }]);
    }

}
