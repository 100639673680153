import {NonIdealState} from "@blueprintjs/core";
import {
    CategoryScale,
    Chart as ChartJS, LayoutPosition,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import * as React from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);
interface ISzLineChartState {
    clicked?: boolean;
}
interface ISzLineChartProperties {
    title: string;
    legend?: any;
    labels: string[];
    data_sets: any[];
    scales?: any;
}

export class SzLineChart extends React.Component<ISzLineChartProperties, ISzLineChartState> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            clicked: false,
        };
    }
    public render() {
        // const blaaa: InteractionModeMap = "";
        if (!this.props.labels.length) {
            return this.noData();
        }
        const data = {
            labels: this.props.labels,
            datasets: this.props.data_sets,
        };
        const legend =  this.props.legend ? this.props.legend : {position: "right" as "right",align: "start" as "start"};
        const options = {
            responsive: true,
            interaction: {
                mode: "index" as "index",
                intersect: false,
            },
            maintainAspectRatio: false,
            stacked: false,
            plugins: {
                title: {
                    display: true,
                    text: this.props.title,
                },
                legend,
                datalabels: {
                    display: false,
                },
            },
            scales: {
                y: {
                    ticks: {
                        callback(val, index) {
                            return this.getLabelForValue(Math.trunc(parseFloat(val)));
                        },
                    },
                },
            },
        };
        if(this.props.scales){
            options.scales = this.props.scales;
        }
        return <Line options={options} data={data} />;
    }

    private noData() {
        return (
            <div style={{padding: 32, width: "100%"}}>
                <NonIdealState
                    icon={"polygon-filter"}
                    title="Keine Daten"
                    description="Datum ist zu früh oder keine Werte"
                />
            </div>
        );
    }
}
