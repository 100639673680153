import {
    Button,
    Classes,
    Colors,
    ControlGroup,
    Dialog,
    HTMLTable,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    NonIdealState,
    Tag,
    TextArea,
    Toaster,
} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Popover2} from "@blueprintjs/popover2";
import * as React from "react";
import {ChangeEvent, CSSProperties} from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_OPTION, ParameterOptions} from "../../../const/ParameterOptions";
import {Sectors} from "../../../const/Sectors";
import {SessionStore} from "../../../const/SessionStore";
import {Labels} from "../../../helpers/Labels";
import {EDataType} from "../../../models/EDataType";
import {EModuleNames} from "../../../models/EModuleNames";
import {EParameters} from "../../../models/EParameters";
import {EValueName} from "../../../models/EValueName";
import {ICompany} from "../../../models/ICompanyDetails";
import {ICompanySearchResult} from "../../../models/ICompanySearchResult";
import {IGlobalValue} from "../../../models/IGlobalValue";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {EProjectUserRole, IProject, ProjectUserRoleMap} from "../../../models/IProject";
import {PublicDbCalls} from "../../../services/PublicDbCalls";
import {ServiceCalls} from "../../../services/ServiceCalls";
import {SzRange} from "../SzRange";
import {SzSvgLoader} from "../SzSvgLoader";
import {PARAMETER_HELP, PARAMETER_HELP_MAP} from "../../../const/ParameterHelpText";
import {Value_HELP} from "../../../const/ValueHelpText";
import {captions, result_order} from "../../../const/Benchmarking";
import {CreditSpreadRuntimeParameter} from "../../toolbar/inputs/CreditSpreadRuntimeParameter";
import {BondSectorParameter} from "../../toolbar/inputs/BondSectorParameter";
import {RegionParameter} from "../../toolbar/inputs/RegionParameter";
import {CreditSpreadMetricParameter} from "../../toolbar/combi-inputs/CreditSpreadMetricParameter";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const addValue = (values: IGlobalValue[], dataType: EDataType, name?: EValueName, label?: string, defaultValue?: any) => {
    values.push({dataType, name, label, defaultValue});
};

const BASE_DIR = Globals.BASE_DIR;

interface IInvitation {
    email: string;
    role: EProjectUserRole;
}

export interface IProjectInfoProperties {
    project: IProject;
    page: EModuleNames;
    onChangeProject(p: IProject);
    onDeleteProject(p: IProject);
    onUpdateProject(p: IProject);
}

export class ProjectInfo  extends React.Component<IProjectInfoProperties, any> {

    private readonly msgToaster: React.RefObject<Toaster>;
    private prj: IProject;
    constructor(props: IProjectInfoProperties, context: any) {
        super(props, context);
        this.msgToaster = React.createRef();
        this.state = {
            project: undefined,
            invitations: [],
            inv_text: undefined,
            inv_zero: {
                email: undefined,
                role: EProjectUserRole.reader,
            },
        };
    }

    public render() {
        const p: IProject = this.props.project;
        const page:EModuleNames = this.props.page;
        if (!p) {
            return this.renderNotIdealState();
        }

        if ((this.state.project ? this.state.project._id !== p._id : false) || !this.state.project) {
            this.updateDetails(p);
            return (
                <div style={{paddingTop: 250}}>
                    <SzSvgLoader duration={2} />
                </div>
            );
        }

        // const user_id = Globals.user_id;
        // const can_edit = p.project_users.find( (pu) => user_id === pu.user._id && (pu.role === EProjectUserRole.owner || pu.role === EProjectUserRole.editor) );
        /*
        if (this.state.tabId === "idPrjInfoTabs-Config" && !can_edit) {
            sel_tab =  "idPrjInfoTabs-Desc";
        }
        if (!sel_tab) {
            sel_tab =  "idPrjInfoTabs-Desc";
        }
        */
        // <Tab id="idPrjInfoTabs-Desc" title="Projekt" panel={this.renderDescription(p)} />
        // {!showTp ? (<Tab id="idPrjInfoTabs-Longlist" title="Peer-Group" panel={ p && p.values && p.values.SelectedCompanySearch ? this.renderCompanies(p) : null} />) : null}
        return (
            <div style={{paddingTop: 1, height: "calc(100vh - 93px)", overflowY: "scroll"}}>
                <div style={{paddingRight: 1}}>
                    {page === EModuleNames.BetaFactor ? (this.renderBetaFactor(p)) : null}
                    {page === EModuleNames.CostOfCapital ? (this.renderCostOfCapital(p)) : null}
                    {page === EModuleNames.Multiples ? (this.renderMultiplier(p)) : null}
                    {page === EModuleNames.Benchmarking ? (this.renderBenchmarking(p)) : null}
                    {page === EModuleNames.TransferPricing ? (this.renderTransferPricing(p)) : null}
                    {page === EModuleNames.CreditSpreads ? (this.renderCreditSpreads(p)) : null}
                </div>
                <Toaster position={"top"} autoFocus={false} usePortal={true} canEscapeKeyClear={true} ref={this.msgToaster} className={"in-front"} />
                <Dialog
                    isOpen={this.state.inviteDlg}
                    canOutsideClickClose={true}
                    onClose={() => this.setState({inviteDlg: false})}
                >
                    <div className={`bp3-dark ${Classes.DIALOG_HEADER}`} style={{backgroundColor: "rgb(57, 75, 89)", borderRadius: 0}}>
                        <h4 className="bp3-heading">Nutzer Einladen</h4>
                        <Button intent={Intent.DANGER} minimal={true} icon={IconNames.SMALL_CROSS}  onClick={() => this.setState({inviteDlg: false})} />
                    </div>
                    {this.renderInviteDlgBody(p)}
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button text={_t(ETranslation.send)} onClick={() => this.sendInvite(p)} intent={Intent.PRIMARY} disabled={!this.state.invitations.length}/>
                            <Button text={_t(ETranslation.cancel)} onClick={() => this.setState({inviteDlg: false})} />
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }

    private renderNotIdealState() {
        return (
            <div className={`sz-prj-info-page`} style={{width: "100%"}}>
                <NonIdealState
                    icon={IconNames.NEW_OBJECT}
                    description="Kein Projekt gewählt."
                />
            </div>
        );
    }

    //region Render Description
    private renderDescription(p: IProject) {
        const getIdent = () => {
            if (p.ident) {
                return (
                    <span className={Classes.TEXT_MUTED} style={{fontWeight: "normal"}}>{p.ident}</span>
                );
            } else {
                return (
                    <span className={Classes.TEXT_MUTED} style={{fontWeight: "normal"}}>keine</span>
                );
            }
        };
        const user_id = Globals.user_id;
        const is_owner = p.project_users.find( (pu) => user_id === pu.user._id && pu.role === EProjectUserRole.owner );
        const edit_roles = p.project_users.filter( (pu) => user_id === pu.user._id && (pu.role === EProjectUserRole.editor || pu.role === EProjectUserRole.owner) );
        let texts = [<span style={{color: Colors.ORANGE2}}>nur lesen</span>];
        if (edit_roles.length) {
            texts = [];
            const is_editor = p.project_users.find( (pu) => user_id === pu.user._id && pu.role === EProjectUserRole.editor );
            if (is_owner) {
                texts.push(<span style={{color: Colors.ORANGE2}}>Besitzer</span>);
            }
            if (is_editor) {
                texts.push(<span style={{color: Colors.ORANGE2, paddingLeft: texts.length ? 12 : undefined}}>aktuelle Bearbeiter</span>);
            }
        }
        // {is_owner ? (<Button outlined={true} intent={Intent.PRIMARY} icon={IconNames.EDIT} onClick={() => this.setState({edit_project: true})} title={"Projekt Name, Beschreibung\nIdentifikation ändern"} />) : ("")}
        const renderPrjReadonly = () => {
            return (
                <>
                    <tr>
                        <td style={{boxShadow: "none", height: 42, width: 160}}><strong>Projekt-Name</strong></td>
                        <td style={{boxShadow: "none", height: 42}}>{p.name}</td>
                        <td style={{boxShadow: "none", height: 42, width: 90}}>
                            <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                                {is_owner ? (<Tag intent={Intent.PRIMARY} minimal={true} interactive={true} onClick={() => this.setState({edit_project: true})} htmlTitle={"Projekt Name, Beschreibung\nIdentifikation ändern"}>bearbeiten</Tag>) : ("")}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{height: 42, width: 160}}><strong>Projekt-Identifikation</strong></td>
                        <td style={{height: 42}} colSpan={2}>
                            {getIdent()}
                        </td>
                    </tr>
                    <tr>
                        <td style={{height: 42, width: 160}}><strong>Projekt-Beschreibung</strong></td>
                        <td colSpan={2}>
                            <p className={Classes.TEXT_MUTED}>{p.description ? p.description : "keine beschreibung"}</p>
                        </td>
                    </tr>
                </>
            );
        };
        // export function handleStringChange(handler: (value: string) => void) {
        //    return (event: React.FormEvent<HTMLElement>) => handler((e.target as HTMLInputElement).value);
        // }
        const cancelEdit = () => {
            this.setState({
                edit_project: false,
                project_name: p.name,
                project_description: p.description,
                project_ident: p.ident,
            });
        };
        const saveEdit = () => {
            if (!this.state.project_name) {
                return;
            }
            this.updateProject();
        };
        const getErrorIcon = () => {
            if (this.state.project_name) {
                return null;
            }
            return <Icon icon={IconNames.WARNING_SIGN} color={Colors.RED2} htmlTitle={"Projektname ist erforderlich"} />;
        };
        const renderPrjEditor = () => {
            return (
                <>
                    <tr>
                        <td style={{boxShadow: "none", width: 160, verticalAlign: "middle"}}><strong>Projekt-Name</strong></td>
                        <td style={{boxShadow: "none", paddingLeft: 5}} colSpan={2}>
                            {this.state.project_name}
                        </td>
                    </tr>
                    <tr>
                        <td style={{boxShadow: "none", paddingBottom: 16, width: 160, verticalAlign: "middle"}}><strong>Projekt-Identifikation</strong></td>
                        <td style={{boxShadow: "none", paddingBottom: 16, paddingLeft: 5}} colSpan={2}>
                            <InputGroup placeholder="Projekt-Identifikation" value={this.state.project_ident} onChange={ (e) => this.setState({project_ident: (e.target as HTMLInputElement).value}) } fill={true} />
                        </td>
                    </tr>
                    <tr>
                        <td style={{boxShadow: "none", paddingBottom: 16, width: 160}}><strong>Projekt-Beschreibung</strong></td>
                        <td style={{boxShadow: "none", paddingBottom: 16, paddingLeft: 5, height: 150}} colSpan={3}>
                            <TextArea value={this.state.project_description} fill={true} rows={5} placeholder={"Projekt-Beschreibung"} style={{height: 225}} onChange={ (e) => this.setState({project_description: (e.target as HTMLTextAreaElement).value}) } />
                        </td>
                    </tr>
                    <tr>
                        <td style={{boxShadow: "none", paddingBottom: 16, paddingLeft: 5, width: 160}} colSpan={4}>
                            <div style={{display: "flex", justifyContent: "end", width: "100%"}}>
                                <div>
                                    <Button intent={Intent.PRIMARY} text={_t(ETranslation.save)} onClick={() => saveEdit()} />
                                    &nbsp;&nbsp;
                                    <Button intent={Intent.NONE} text={_t(ETranslation.cancel)} onClick={() => cancelEdit()} />
                                </div>
                            </div>
                        </td>
                    </tr>
                </>
            );
        };
        const renderProjectProperties = () => {
            if (this.state.edit_project && is_owner) {
                return renderPrjEditor();
            } else {
                return renderPrjReadonly();
            }
        };

        const renderProjectConfig = () => {
            if (edit_roles.length && p) {
                // return this.renderConfig(p);
            } else {
               // return null;
            }
            return null;
        };
        return (
            <div style={{overflow: "hidden", position: "relative"}}>
                {renderProjectConfig()}
                <HTMLTable className={`sz-table sz-prj-info-table`} condensed={true} style={{width: "100%"}}>
                    <tbody>
                    {renderProjectProperties()}
                    </tbody>
                </HTMLTable>
            </div>
        );
    }
    //endregion

    //region Render Companies
    private renderCompanies(p: IProject) {
        const companies: ICompanySearchResult[] = p.values.SelectedCompanySearch;
        if (!Array.isArray(companies)) {
            return null;
        }
        const sel_companies: ICompany[] = this.state.companies;
        const company_map: {[k: string]: ICompany} = {};
        sel_companies.forEach( (c) => company_map[c.id] = c );
        const get_c = (c: ICompanySearchResult): ICompany => {
            const r = company_map["" + c.company_id];
            if ( r ) {
                return r;
            } else {
                return {} as ICompany;
            }
        };
        const renderRow = (c: ICompanySearchResult, row_num: number) => {
            const css = row_num === 0 ? { boxShadow: "none", paddingBottom: 0} : {paddingBottom: 0};
            const company = get_c(c);
            return (
                <>
                    <tr>
                        <td style={Object.assign({ width: 22, maxWidth: 22, paddingTop: 8}, css)} className={"no-padding"}>
                            <img style={{maxHeight: 14}} src={`${BASE_DIR}images/flags/${c.country_iso_2}.jpg`}/>
                        </td>
                        <td style={css}>
                            <div className="td-overflow">
                                <span className="td-overflow-content" style={{fontWeight: "bold"}}>{c.company_name}</span>
                            </div>
                        </td>
                        <td className={`${Classes.TEXT_MUTED}`} style={Object.assign({fontSize: "80%", width: 450, maxWidth: 450, color: Colors.GRAY3}, css)}>
                            <div style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: 448}} title={`${c.sector_name_de} | ${c.industry_name_de}`}>
                                <Icon icon={"office"} iconSize={12}/>&nbsp;{c.sector_name_de}&nbsp;|&nbsp;{c.industry_name_de}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{paddingTop: 0}} className={"no-padding"}>&nbsp;</td>
                        <td className={`${Classes.TEXT_MUTED}`} style={Object.assign({fontSize: "80%", width: 450, maxWidth: 450, color: Colors.GRAY3, paddingTop: 0}, css)}>
                            <div style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: 448}} title={`${c.sector_name_de} | ${c.industry_name_de}`}>
                                <Icon icon={"globe"} iconSize={12}/>&nbsp;{c.country_region_de}&nbsp;|&nbsp;{c.country_name_de}&nbsp;―&nbsp;SIC&nbsp;{company.ff_sic_code ? company.ff_sic_code : Globals.hyphen}&nbsp;|&nbsp;FID&nbsp;{company.security_id ? company.security_id : Globals.hyphen}
                            </div>
                        </td>
                    </tr>
                </>
            );
        };
        /*
        return (
            <>
                {companies.map( (i) => renderCompany(i) )}
            </>
        );
        */
        return (
            <table className="sz-table bp3-html-table bp3-html-table-condensed sz-small-padding" style={{width: "98%", emptyCells: "show", borderCollapse: "separate"}}>
                <tbody>
                {companies.map( (i, idx) => renderRow(i, idx) )}
                </tbody>
            </table>
        );
    }
    //endregion

    private renderBetaFactor(p: IProject) {
        const parameters = [];

        parameters.push(EParameters.DateParameter);
        parameters.push(EParameters.RenditeCombiParameter);
        parameters.push(EParameters.IndexCombiParameter);

        parameters.push(EParameters.GearingCombiParameter);
        parameters.push(EParameters.TaxshieldParameter);
        parameters.push(EParameters.DebtBetaCombiParameter);

        const values: IGlobalValue[] = [];
        addValue(values, EDataType.number, EValueName.unlevered_beta, _t(ETranslation.beta_unlevered));
        addValue(values, EDataType.number, EValueName.unlevered_total_beta, "Unlevered Total Beta");
        return (
            <>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderValues(p, values)}
                    </div>
                </div>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderOptions(p, parameters)}
                    </div>
                </div>
            </>
        );
    }
    private renderCostOfCapital(p: IProject) {
        const parameters = [];

        parameters.push(EParameters.DateParameter);
        parameters.push(EParameters.ReleveredBetaParameter);
        parameters.push(EParameters.BaseRateParameter);
        parameters.push(EParameters.MarketAndCountryRiskParameter);
        parameters.push(EParameters.CreditSpreadParameter);

        const values: IGlobalValue[] = [];
        addValue(values, EDataType.percent, EValueName.wacc, "WACC");

        return (
            <>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderValues(p, values)}
                    </div>
                </div>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderOptions(p, parameters)}
                    </div>
                </div>
            </>
        );
    }
    private renderMultiplier(p: IProject) {
        const parameters = [];

        parameters.push(EParameters.DateParameter);
        parameters.push(EParameters.SectorCountriesParameter);

        const values: IGlobalValue[] = [];
        addValue(values, EDataType.number_1, EValueName.sales_trailing, "Umsatz-Multiplikator (trailing)");
        addValue(values, EDataType.number_1, EValueName.sales_forward, "Umsatz-Multiplikator (forward)");
        addValue(values, EDataType.number_1, EValueName.ebitda_trailing, "EBITDA-Multiplikator (trailing)");
        addValue(values, EDataType.number_1, EValueName.ebitda_forward, "EBITDA-Multiplikator (forward)");
        addValue(values, EDataType.number_1, EValueName.ebit_trailing, "EBIT-Multiplikator (trailing)");
        addValue(values, EDataType.number_1, EValueName.ebit_forward, "EBIT-Multiplikator (forward)");

        return (
            <>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderValues(p, values)}
                    </div>
                </div>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderOptions(p, parameters)}
                    </div>
                </div>
            </>
        );
    }
    private renderBenchmarking(p: IProject) {
        const parameters = [];
        parameters.push(EParameters.DateParameter);
        const value = (f)=> p.values[`benchmarking_${f}`];
        const benchmarking_year = p.values.benchmarking_year !== undefined ? p.values.benchmarking_year : 0;
        if(benchmarking_year === 0){
            return (
                <>
                    <div className={"sz-row"}>
                        <div className={"sz-col-100"}>
                            <HTMLTable className={`sz-table sz-prj-info-table_2`} condensed={true} style={{width: "100%"}}>
                                <thead>
                                <tr style={{backgroundColor: "rgb(240, 240, 240)"}}>
                                    <th colSpan={8}>Ergebnis</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Benchmarking wurde in diesem Projekt noch nicht ausgeführt.</td>
                                </tr>
                                </tbody>
                            </HTMLTable>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        <HTMLTable className={`sz-table sz-prj-info-table_2`} condensed={true} style={{width: "100%"}}>
                            <thead>
                            <tr style={{backgroundColor: "rgb(240, 240, 240)"}}>
                                <th style={{width: 500, paddingLeft: 10}} colSpan={8}>Ergebnis</th>
                            </tr>
                            <tr>
                                <th style={{width: 500, paddingLeft: 10}}>&nbsp;</th>
                                {[0,1,2,3,4].reverse().map((i)=> {
                                    return (
                                        <th style={{textAlign: "right", width: 80}}>{benchmarking_year - i}</th>
                                    );
                                })}
                                <th style={{textAlign: "right", width: 80}}>5-{_t(ETranslation.years)}-{Globals.average}</th>
                                <th style={{textAlign: "right"}}>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {result_order.map( (field, idx) => {
                                if(field.startsWith("#")){
                                    return (
                                        <tr>
                                            <td colSpan={8} style={{paddingLeft: 10, boxShadow: idx > 0 ? "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)" : undefined }}>{field.slice(1)}</td>
                                        </tr>
                                    );
                                }
                                const a = value(field);
                                const row_data = Array.isArray(a)? a : [];
                                return (
                                    <tr>
                                        <td style={{width: 500, paddingLeft: 20}}>{captions[field]}</td>
                                        {[0,1,2,3,4,5].map((i)=> {
                                            return (
                                                <td style={{textAlign: "right", width: 80}}><span className={`${Classes.TEXT_MUTED}`}>{Globals.formatter(row_data[i], 1, 1, true)}</span></td>
                                            );
                                        })}
                                        <td>&nbsp;</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </HTMLTable>
                    </div>
                </div>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderOptions(p, parameters)}
                    </div>
                </div>
            </>
        );
    }
    private getYears(p: IProject): number[]{
        const year = Array.isArray(p.values[EParameters.TpYearsParameter]) ? p.values[EParameters.TpYearsParameter][0] : SessionStore.get(EParameters.TpYearsParameter);
        const result: number[] = [];
        const steps = (new Array(3)).fill(0);
        let y: number = year;
        steps.forEach(() =>result.push(y--));
        return result.reverse();
    }
    private renderTransferPricing(p: IProject) {
        const parameters = [];
        parameters.push(EParameters.TpKeyWordsParameter);
        parameters.push(EParameters.TpAnalysisSizeParameter);
        parameters.push(EParameters.TpSicParameter);
        parameters.push(EParameters.TpYearsParameter);
        parameters.push(EParameters.TpCountriesParameter);
        parameters.push(EParameters.TpFreeFloatParameter);
        parameters.push(EParameters.TpSalesParameter);
        parameters.push(EParameters.TpNumEmployeesParameter);

        const renderResult = ()=>{
            const moduleKey = `global_vars`;
            let g_vars = p.values[moduleKey];
            if (!g_vars) {
                g_vars = {};
            }
            const tp_result = g_vars[EValueName.tp_result];
            if(!tp_result){
                return (
                    <p>noch nicht bestimmt</p>
                );
            }else{
                const fieldMap_3: any[] = [];
                const fieldHeader_3: string[] = ["Operative Marge"];
                const years = this.getYears(p);
                years.forEach( (y) => fieldHeader_3.push(""+y) );
                fieldHeader_3.push(Globals.average);
                Object.keys(tp_result).forEach( (k)=>{
                    if(k === "Mittelwert"){
                        //
                    }
                    const entry = tp_result[k];
                    const row_data = {text: k, content: [], style: {paddingLeft: 2, paddingRight: 2, textAlign: "right",fontWeight: entry.fontWeight}};
                    const getValue = (vv)=> {
                        if(vv < -100){
                            row_data.content.push("< -100");
                        }else{
                            row_data.content.push(Globals.formatter(vv,2,2, true));
                        }
                    };
                    entry.columns.forEach((vv) => getValue(vv));
                    fieldMap_3.push(row_data);
                });
                const renderRow = (row, row_num) => {
                    const style:CSSProperties = {
                        verticalAlign: "middle",
                    };
                    if(row.style){
                        Object.assign(style, row.style);
                    }
                    const label_style = Object.assign({width: 200}, style, {textAlign: "left", paddingLeft: 10});
                    if(Array.isArray(row.content)){
                        return (
                            <tr>
                                <td  style={label_style}>{row.text}</td>
                                {row.content.map((c, column) =>(<td  style={style}>{c}</td>))}
                            </tr>
                        );
                    }
                    return (
                        <tr>
                            <td  style={label_style}>{row.text}</td>
                            <td  style={style}>{row.content}</td>
                        </tr>
                    );
                };
                return (
                    <HTMLTable className={`sz-table`} condensed={true} style={{width: 500, borderCollapse: "separate"}}>
                        <thead>
                        <tr>
                            {fieldHeader_3.map((h, idx) => (<th style={{paddingLeft: idx === 0 ? 10 : 2, paddingRight: 2, textAlign: idx > 0 ? "right" : undefined}}>{h}</th>))}
                        </tr>
                        </thead>
                        <tbody>
                        {fieldMap_3.map((m, idx) => renderRow(m, idx))}
                        </tbody>
                    </HTMLTable>
                );
            }
        };
        return (
            <>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        <HTMLTable className={`sz-table`} condensed={true} style={{width: "100%", borderCollapse: "separate"}}>
                            <thead>
                            <tr style={{backgroundColor: "rgb(240, 240, 240)", boxShadow: "0px 1px rgba(16, 22, 26, 0.15)"}}>
                                <th style={{width: 250, paddingLeft: 10}}>Ergebnis</th>
                            </tr>
                            </thead>
                        </HTMLTable>
                        {renderResult()}
                    </div>
                </div>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderOptions(p, parameters)}
                    </div>
                </div>
            </>
        );
    }
    private renderCreditSpreads(p: IProject){
        const parameters = [];

        parameters.push(EParameters.DateParameter);
        parameters.push(EParameters.CreditSpreadRuntimeParameter);
        parameters.push(EParameters.BondSectorParameter);
        parameters.push(EParameters.RegionParameter);
        parameters.push(EParameters.BondFilterParameter);
        parameters.push(EParameters.CreditSpreadMetricParameter);
        parameters.push(EParameters.CreditSpreadConfigParameter);
        parameters.push(EParameters.CreditSpreadSoftParameter);

        const values: IGlobalValue[] = [];
        addValue(values, EDataType.number, EValueName.cp_credit_spread, _t(ETranslation.credit_spread));
        addValue(values, EDataType.string, EValueName.cp_rating, _t(ETranslation.rating));

        return (
            <>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderValues(p, values)}
                    </div>
                </div>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderOptions(p, parameters)}
                    </div>
                </div>
            </>
        );
    }
    private renderValues(p: IProject, values: IGlobalValue[]) {
        const formatter = (v: any, digits= 2) => (new Intl.NumberFormat("de-de", {  maximumFractionDigits: digits, minimumFractionDigits: digits })).format(v);
        let countValues = 0;
        const useValues = [];
        if (values && values.length) {
            values.forEach( (i) => {
                if (i.label) {
                    useValues.push(i);
                    countValues++;
                }
            } );
        }
        const cssLineTop: CSSProperties = {
            boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)",
        };
        if (!countValues) {
            return (
                <tr>
                    <td colSpan={2} style={cssLineTop}><span className={Classes.TEXT_MUTED}>keine</span></td>
                </tr>
            );
        }
        const moduleKey = `global_vars`;
        let g_vars = p.values[moduleKey];
        if (!g_vars) {
            g_vars = {};
        }
        const getValue = (v: any, dataType: EDataType) => {
            if (dataType === EDataType.h1) {
                return null;
            }
            if (v === undefined) {
                return "—";
            }
            if (dataType === EDataType.string) {
                return v;
            }
            if (dataType === EDataType.number) {
                const f = parseFloat("" + v);
                return isNaN(f) ? "—" : formatter(f);
            }
            if (dataType === EDataType.number_1) {
                const f = parseFloat("" + v);
                return isNaN(f) ? "—" : formatter(f, 1);
            }
            if (dataType === EDataType.percent) {
                const f = parseFloat("" + v);
                return isNaN(f) ? "—" : `${formatter(f * 100)} %`;
            }
            return v;
        };
        const help_text = (v: IGlobalValue) => {
            const f = Value_HELP(v.name);
            return (
                <div style={{padding: 5, paddingBottom: 10, width: "100%"}} className={"bp3-text-small bp3-text-muted"}>
                    {f}
                </div>
            );
        };
        return (
            <HTMLTable className={`sz-table sz-prj-info-table_2`} condensed={true} style={{width: "100%"}}>
                <thead>
                <tr style={{backgroundColor: "rgb(240, 240, 240)"}}>
                    <th style={{width: 250, paddingLeft: 10}} colSpan={3}>Ergebnis</th>
                </tr>
                </thead>
                <tbody>
                {useValues.map( (gv: IGlobalValue, idx) => {
                    if (gv.dataType === EDataType.h1) {
                        return (
                            <tr>
                                <td colSpan={3} style={{borderBottom: "1px solid rgba(16, 22, 26, 0.15)", paddingTop: 24}}><strong>{gv.label}</strong></td>
                            </tr>
                        );
                    }
                    if (!gv.label) {
                        return null;
                    }
                    let useLabel = gv.label;
                    if (gv.label.startsWith("$") && gv.label.endsWith("$")) {
                        const lv = values.find( (i) => `\$${i.name}\$` === gv.label );
                        if (lv) {
                            useLabel = g_vars[lv.name];
                        } else {
                            useLabel = gv.defaultValue;
                        }
                        // console.error(lv, gv, g_vars, this.values);
                    }
                    const v =  gv.name === EValueName.wacc ? SessionStore.get(EParameters.Wacc) : g_vars[gv.name];
                    return (
                        <tr>
                            <td style={{width: 250, paddingLeft: 10}}>{useLabel}</td>
                            <td style={{width: 250}}><span className={`${Classes.TEXT_MUTED}`}>{getValue(v, gv.dataType)}</span></td>
                            <td style={{padding: 0, paddingTop: 1, backgroundColor: "#F5F8FA"}}>
                                {help_text(gv)}
                            </td>
                        </tr>
                    );
                } )}
                </tbody>
            </HTMLTable>
        );
    }
    private renderOptions(p: IProject, parameters: any[]) {
        const parameterLabels = (parent, cfg: IParameterConfig, i: EParameters[]) => {
            const ret = [];
            i.forEach( (j) => {
                const cfg_ext: IParameterConfig = ParameterConfigs.configs[j];
                const parameter_name = cfg_ext ? cfg_ext.name : undefined;
                const opt: IOptionEntry[] = ParameterOptions.options[j];
                // p.values[j]= null oder undefiniert, wenn er nie gesetzt wurde, dann nimm default!
                const vals = p.values[j] === null || p.values[j] === undefined ? SessionStore.getItem(j) : p.values[j];
                let v = Array.isArray(vals) ? vals[0] : vals;

                const push_ret = (item) => {
                    if (!parameter_name) {
                        ret.push(item);
                    } else {
                        ret.push({
                            parent,
                            parameter: j,
                            label: parameter_name,
                            value: item,
                        });
                    }
                };
                if (!cfg_ext) {
                    console.error("parameterLabels -> no label: ", j);
                }
                // console.error(j, opt, v);
                if(Array.isArray(opt)){
                    const fo = opt.find( (o) =>{
                        const cmp = ("" + o.value).split("|");
                        if(cmp.length>=2){
                            return (cmp[0] === v);
                        }
                        return (o.value === v);
                    });
                    if (fo) {
                        push_ret(fo.label);
                        return;
                    }
                }
                if(EParameters.TpKeyWordsWordsParameter === j){
                    console.error("TpKeyWordsWordsParameter", vals, v);
                    if(Array.isArray(v) && v.length){
                        push_ret(v.join(", "));
                    }else{
                        push_ret(Globals.hyphen);
                    }
                    return;
                }
                if(EParameters.TpKeyWordsOperParameter === j){
                    console.error("TpKeyWordsOperParameter", vals, v);
                    push_ret("blaa");
                    return;
                }
                if(EParameters.TpSicParameter === j){
                    const sic_class = Array.isArray(p.values[EParameters.TpSicClassParameter]) && p.values[EParameters.TpSicClassParameter][0] ? p.values[EParameters.TpSicClassParameter][0] : [[],[]];
                    const [sic_classes, sic_codes] = sic_class;
                    return push_ret(Labels.getSicLabel(sic_classes, sic_codes));
                }
                if (EParameters.TpCountriesParameter === j) {
                    let all = false;
                    if(Array.isArray(p.values[EParameters.TpCountriesAllParameter])){
                        all = p.values[EParameters.TpCountriesAllParameter][0];
                    }else{
                        all = SessionStore.get(EParameters.TpCountriesAllParameter);
                    }
                    if(all){
                        push_ret("Keine Einschränkung");
                        return;
                    }
                    const r = [];
                    let rest = "";
                    if(Array.isArray(vals)){
                        vals.forEach( (id) =>{
                            if(r.length<=5){
                                r.push(Globals.country_map[id].name_de);
                            }
                        });
                        if(vals.length>r.length){
                            rest = " und weitere";
                        }
                    }
                    push_ret(`${r.join(", ")}${rest}`);
                    return;
                }

                if (v===undefined || v === null) {
                    push_ret(Globals.hyphen);
                    return;
                }

                if (cfg.dataType === EDataType.percent && !opt) {
                    v = `${Globals.formatter_percent(parseFloat(v))}`;
                }
                if (cfg_ext && cfg.dataType !== cfg_ext.dataType && cfg_ext.dataType === EDataType.percent && !opt) {
                    v = `${Globals.formatter_percent(parseFloat(v))}`;
                }

                if (cfg_ext && Array.isArray(cfg_ext.range) && cfg.dataType === EDataType.select_range) {
                    const r = cfg_ext.range;
                    // console.error(SzRange.getStartStop(vals, r[0], r[r.length-1]));
                    const {start, stop} = SzRange.getStartStop(vals, r[0], r[r.length-1]);
                    const v1 = start === r[r.length-1] ? Globals.infinity  : Globals.formatter(start, 0);
                    const v2 = stop === r[r.length-1] ? Globals.infinity  : Globals.formatter(stop, 0);
                    const l = v1 === v2 ? `${v1}` : `${v1} - ${v2}`;
                    push_ret(l);
                    return;
                }

                if (EParameters.GearingPeersParameter === j && opt) {
                    const vv = [];
                    opt.forEach( (o) => {
                        if (v[o.value]) {
                            vv.push(o.label);
                        }
                    });
                    if (vv.length) {
                        push_ret(vv);
                    } else {
                        push_ret(Globals.hyphen);
                    }
                    return;
                }

                if (EParameters.RuntimeBaseInterestParameter === j) {
                    // console.error(SzRange.getStartStop(vals, 0, 30));
                    const {start, stop} = SzRange.getStartStop(vals, 0, 30);
                    const v1 = start === 31 ? Globals.infinity  : Globals.formatter(start, 0);
                    const v2 = stop === 31 ? Globals.infinity  : Globals.formatter(stop, 0);
                    const l = v1 === v2 ? `${v1} ${_t(ETranslation.year_abbreviation)}` : `${v1} ${_t(ETranslation.to)} ${v2} ${_t(ETranslation.year_abbreviation)}`;
                    push_ret(l);
                    return;
                }

                if (EParameters.RuntimeParameter === j) {
                    const lt12 = (ba) => ba < 12 ? ba * 12 : ba;
                    if (vals) {
                        const ar = ("" + vals).split(",");
                        const a = ar[0];
                        const b = ar[1];
                        if (a === b) {
                            v = `${lt12(parseInt(b, 10)) / 12 } ${_t(ETranslation.years)}`;
                        } else {
                            v = `${lt12(parseInt(a, 10)) / 12 } - ${lt12(parseInt(b, 10)) / 12 } ${_t(ETranslation.years)}`;
                        }
                    }
                }
                if (j === EParameters.IndexTypeParameter) {
                    const x = Array.isArray(p.values[EParameters.LocalTypeParameter]) ? p.values[EParameters.LocalTypeParameter][0] : p.values[EParameters.LocalTypeParameter];
                    if (x) {
                        v = `${v}|${x}`;
                    } else {
                        v = `${v}|EUR`;
                    }
                }
                if (j === EParameters.SectorCountriesParameter) {
                    const sectors = vals[0];
                    const country_idx = vals[1];
                    const sector_labels = [];
                    const country_labels = [];
                    if (sectors && Array.isArray(sectors)) {
                        Sectors.values.forEach( (o) => {
                            sectors.forEach( (so) => {
                                if (o.value === so) {
                                    sector_labels.push(o.label);
                                }
                            } );
                        });
                    }
                    if (country_idx && Array.isArray(country_idx)) {
                        country_idx.sort().forEach( (idx) => {
                            if (PARAMETER_OPTION(EParameters.SectorCountriesParameter)[idx]) {
                                country_labels.push(PARAMETER_OPTION(EParameters.SectorCountriesParameter)[idx].label);
                            }
                        });
                    }
                    const cl = !country_labels.length ? Globals.hyphen : country_labels.join(", ");
                    const sl = !sector_labels.length ? Globals.hyphen : sector_labels.join(", ");
                    push_ret(`${cl} | ${sl}`);
                    return;
                }

                if (j === EParameters.DateParameter) {
                    push_ret(v);
                    return;
                }
                if (!opt) {
                    push_ret(v);
                    return;
                }
            } );
            return ret;
        };
        const cssLineSpacer: CSSProperties = {
            lineHeight: "5px",
            padding: 0,
        };
        const help_text = (_parent: any, parameter: any) => {
            // console.error(_parent, parameter);
            const parent = PARAMETER_HELP_MAP(_parent);
            if(!parent){
                return null;
            }
            const f = parent[parameter];
            return (
                <div style={{padding: 5, paddingBottom: 10, width: "100%"}} className={"bp3-text-small bp3-text-muted"}>
                    {f}
                </div>
            );
        };
        const renderOptionValue = (parameter, vv) => {
            if(!vv){
                return Globals.hyphen;
            }

            if(parameter === EParameters.CreditSpreadRuntimeParameter){
                return CreditSpreadRuntimeParameter.getDocLabel(vv);
            }
            if(parameter === EParameters.BondSectorParameter){
                return BondSectorParameter.getDocLabel(vv);
            }
            if(parameter === EParameters.RegionParameter){
                return RegionParameter.getDocLabel(vv);
            }
            if(parameter === EParameters.CreditSpreadMetricParameter){
                vv = CreditSpreadMetricParameter.getDocLabel(p.values);
            }

            if (Array.isArray(vv)) {
                const le = vv.length - 1;
                return vv.map( (s, idx) => {
                    s = `${s}`;
                    if(s.indexOf("*")>0){
                        s= s.replace(new RegExp("\\*", "g"), "");
                    }
                    if (idx === le) {
                        return (<div style={{}}>{s}</div>);
                    } else {
                        return (<div style={{marginBottom: 2}}>{s}</div>);
                    }
                });
            } else {
                if(typeof vv === "string" && vv.indexOf("*")>0){
                    return vv.replace(new RegExp("\\*", "g"), "");
                }
                return vv;
            }
        };
        const boxShadow = "inset 0 1px 0 0 rgb(16, 22, 26, .15)";
        const renderOption = (i: EParameters | any, idx) => {
            const paddingTop = idx === 0 ? 1 : 0;
            if (i.text) {
                return (
                    <tr style={{margin: 8}}>
                        <td colSpan={3}><strong>{i.text}</strong></td>
                    </tr>
                );
            }

            const cfg: IParameterConfig = ParameterConfigs.configs[i];
            if (!cfg) {
                return null;
            }
            let paramFields: EParameters[] = [i];
            if (cfg.parameterComposition && Array.isArray(cfg.parameterComposition)) {
                paramFields = cfg.parameterComposition;
            }
            const labels = parameterLabels(i, cfg, paramFields);
            if (!Array.isArray(labels)) {
                return;
            }
            if (labels.length === 0) {
                return;
            }

            if (labels.length === 1) {
                const o = labels[0].label ? labels[0].value : labels[0];
                return (
                    <>
                        <tr style={{marginBottom: 0}}>
                            <td style={{width: 250, paddingLeft: 10, boxShadow}}>{cfg.name}</td>
                            <td style={{width: 250, boxShadow}}><span style={{color: "#106ba3"}}>{renderOptionValue(labels[0].parameter, o)}</span></td>
                            <td style={{padding: 0, paddingTop: 1, backgroundColor: "#F5F8FA", boxShadow}}>
                                {help_text(labels[0].parent, labels[0].parameter)}
                            </td>
                        </tr>
                    </>
                );
            } else {
                return (
                    <>
                        {labels.map( (l, l_idx) => {
                            const ub = l_idx === 0 ? boxShadow : undefined;
                            const pt = l_idx === 0 ? 1 : 0;
                            const label = l.label ? l.label : "";
                            const value = l.label ? l.value : l;
                            return (
                                <>
                                    <tr style={{marginBottom: 0}}>
                                        <td style={{width: 250, boxShadow: ub, paddingLeft: 10}}>{label}</td>
                                        <td style={{width: 250, boxShadow: ub}}><span style={{color: "#106ba3"}}>{renderOptionValue(l.parameter, value)}</span></td>
                                        <td style={{padding: 0, backgroundColor: "#F5F8FA", boxShadow: ub, paddingTop: pt}}>{help_text(l.parent, l.parameter)}</td>
                                    </tr>
                                </>
                            );
                        } )}
                    </>
                );
            }

        };

        return (
            <HTMLTable className={`sz-table sz-prj-info-table_2`} condensed={true} style={{width: "100%", marginBottom: 32}}>
                <thead>
                    <tr style={{backgroundColor: "rgb(240, 240, 240)"}}>
                        <th style={{width: 200, paddingLeft: 10}} colSpan={3}>Parameter</th>
                    </tr>
                </thead>
                <tbody>
                    {parameters.map( (i, idx) => renderOption(i, idx) )}
                </tbody>
            </HTMLTable>
        );
    }


    private changeProject(p: IProject) {
        if (this?.props?.onChangeProject) {
            this.props.onChangeProject(p);
        }
    }
    private updateProject() {
        const p = {
            _id: this.props.project._id,
            created: this.props.project.created,
            ident: this.state.project_ident,
            name: this.state.project_name,
            description: this.state.project_description,
            projectModules: this.state.projectModules,
        };
        ( async () => {
            const cb = new ServiceCalls();
            const response = await cb.updateProject(p);
            if (response?.error) {
                this.msgToaster.current.show({
                    timeout: 0,
                    intent: Intent.WARNING,
                    message:
                        <>
                            <div style={{margin: 0, marginBottom: 10}}>Das Projekt <strong>{p.name}</strong> wurde nicht aktualisiert.</div>
                            <p>{response?.error}</p>
                            <div style={{display: "flex", float: "right"}}>
                                <div>
                                    <Button minimal={true} onClick={ () => this.msgToaster.current.clear() }>Okay</Button>
                                </div>
                            </div>
                        </>,
                });
            } else {
                if (this.props.onUpdateProject) {
                    this.props.onUpdateProject(response.response);
                    this.setState({
                        edit_project: false,
                    });
                }
            }
        } )();
    }
    private deleteProject(p: IProject) {
        if (this?.props?.onDeleteProject) {
            this.props.onDeleteProject(p);
        }
    }

    private updateDetails(project: IProject) {
        (async () => {
            const cb = new ServiceCalls();
            const pub = new PublicDbCalls();
            try {

                const companies_resp: ICompanySearchResult[] = project.values.SelectedCompanySearch;
                const resp_1 = await pub.selectCompanies(companies_resp.map((c) => c.company_id));
                let sel_companies = [];
                if (resp_1 && Array.isArray(resp_1.result)) {
                    sel_companies = resp_1.result;
                }

                const resp = await cb.getProjectUsers(project);
                let project_members = [];
                if (resp && Array.isArray(resp.result)) {
                    project_members = resp.result;
                } else {
                    console.error("loadProjDetails", resp);
                }

                const user_id = Globals.user_id;
                let owner_role;
                let editor_role;
                if (project && Array.isArray(project.project_users)) {
                    owner_role = project.project_users.find( (pu) => user_id === pu.user._id && pu.role === EProjectUserRole.owner );
                    editor_role = project.project_users.find( (pu) => user_id === pu.user._id && pu.role === EProjectUserRole.editor );
                }

                const oi_resp = await cb.getOpenInvitations(project);
                let openInvitations = [];
                if (oi_resp && Array.isArray(oi_resp.response)) {
                    openInvitations = oi_resp.response;
                }

                const pm = {};
                pm[EModuleNames.BetaFactor] = true;
                pm[EModuleNames.Multiples] = true;
                pm[EModuleNames.CostOfCapital] = true;
                pm[EModuleNames.TransferPricing] = true;
                const projectModules = project.values.projectModules ? project.values.projectModules : pm;
                this.setState({
                    project,
                    projectModules,
                    owner_role,
                    editor_role,
                    project_members,
                    openInvitations,
                    companies: sel_companies,
                    edit_project: false,
                    project_name: project.name,
                    project_description: project.description,
                    project_ident: project.ident,
                    inviteDlg: false,
                    invitations: [],
                    inv_zero: {
                        email: "",
                        role: EProjectUserRole.reader,
                    },
                });
            } catch (exi) {
                console.error(exi);
            }
        })();
    }

    private sendInvite(p: IProject) {
        (async () => {
            const cb = new ServiceCalls();
            try {
                const response = cb.inviteToProject(p, this.state.invitations, this.state.inv_text);
                this.updateDetails(p);
            } catch (exi) {
                console.error(exi);
            }
        })();
    }

    private renderInviteDlgBody(p: IProject) {
        // inv_text
        const invitations: IInvitation[] = this.state.invitations;
        const inv_zero: IInvitation = this.state.inv_zero;
        const chEmail = (e: ChangeEvent<HTMLInputElement>, inv: IInvitation) => {
            inv.email = e.target.value;
            this.setState({inv_zero});
        };
        const chRole = (role: EProjectUserRole, inv: IInvitation) => {
            inv.role = role;

            this.setState({inv_zero});
        };
        const permissionsMenu = (inv: IInvitation) => {
            return (
                <Popover2
                    content={
                        <Menu>
                            <MenuItem text="Administrator" onClick={ () => chRole(EProjectUserRole.owner, inv) }/>
                            <MenuItem text="Leserecht" onClick={ () => chRole(EProjectUserRole.reader, inv) }/>
                        </Menu>
                    }
                    placement="bottom-end"
                >
                    <Button minimal={true} rightIcon="caret-down" text={ProjectUserRoleMap[inv.role]} intent={Intent.PRIMARY} style={{minWidth: 120, justifyContent: "space-between"}}/>
                </Popover2>
            );
        };
        const addInvZero = () => {
            if (!inv_zero.email) {
                return;
            }

            const idx = invitations.findIndex( (i) => i.email.toLowerCase() === inv_zero.email.toLowerCase() );
            if (idx >= 0) {
                return;
            }
            invitations.push({
                email: inv_zero.email.trim(),
                role: inv_zero.role,
            });
            inv_zero.role = EProjectUserRole.reader;
            inv_zero.email = "";
            this.setState({inv_zero});
        };
        const remInv = (inv: IInvitation) => {
            const idx = invitations.findIndex( (i) => i.email.toLowerCase() === inv.email.toLowerCase() );
            if (idx >= 0) {
                invitations.splice(idx, 1);
                this.setState({invitations});
            }
        };
        const renderInv = (inv: IInvitation) => {
            return (
                <div style={{marginTop: 8}}>
                    <ControlGroup fill={true}>
                        <InputGroup value={inv.email} readOnly={true} leftIcon={IconNames.PEOPLE} rightElement={permissionsMenu(inv)} onChange={(e: ChangeEvent<HTMLInputElement>) => chEmail(e, inv)}/>
                        <Button title={"Löschen"} icon={IconNames.TRASH} onClick={() => remInv(inv)} intent={Intent.DANGER}/>
                    </ControlGroup>
                </div>
            );
        };
        return (
            <div className={Classes.DIALOG_BODY} style={{height: 350}}>
                <p className={"bp3-text-small bp3-text-muted"}>Hier können Sie weitere Benutzer zur Zusammenarbeit im Projekt einladen und ihnen Rechte zuweisen.</p>
                <TextArea value={this.state.inv_text} fill={true} rows={5} placeholder={"Einladungstext"} maxLength={250} style={{height: 100, marginBottom: 8}} onChange={ (e) => this.setState({inv_text: (e.target as HTMLTextAreaElement).value}) } />
                <ControlGroup fill={true}>
                    <InputGroup type={"email"} value={inv_zero.email}  placeholder={"EMail- Adresse"} leftIcon={IconNames.PEOPLE} rightElement={permissionsMenu(inv_zero)} onChange={(e: ChangeEvent<HTMLInputElement>) => chEmail(e, inv_zero)}/>
                    <Button title={"Einladen"} icon={IconNames.ADD} onClick={() => addInvZero()} disabled={!Globals.validateEmail(inv_zero.email)} intent={Intent.PRIMARY}/>
                </ControlGroup>
                {invitations.map( (i) => renderInv(i) )}
            </div>
        );
    }

    private renderParameterHelp(p: IProject, parameters: EParameters[]) {
        const render_p = (parameter: EParameters) => {
            const a = PARAMETER_HELP(parameter);
            if(!Array.isArray(a)){
                return null;
            }
            return a.map( (i)=> <p className={"bp3-text-small bp3-text-muted"}>{i}</p> );
        };
        return (
            <HTMLTable className={`sz-table sz-prj-info-table_2`} condensed={true} style={{width: "98%", marginRight: 10}}>
                <thead>
                <tr>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <tr style={{backgroundColor: "#F5F8FA"}}>
                    <td style={{padding: 10}}>
                        {parameters.map((i)=> render_p(i))}
                    </td>
                </tr>
                </tbody>
            </HTMLTable>
        );
    }
}
