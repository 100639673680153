import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {getResult} from "../../../helpers/Helpers";
import {EParameters} from "../../../models/EParameters";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {EProjectType, IProject} from "../../../models/IProject";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {EventBus, IEventHandler} from "../../../services/EventBus";
import {ServiceCalls} from "../../../services/ServiceCalls";
import {SingleSelectParameter} from "../SingleSelectParameter";
import {Globals} from "../../../const/Globals";
import {EModuleNames} from "../../../models/EModuleNames";

export class ProjectParameter extends SingleSelectParameter {

    private readonly evtUpdateParameter: IEventHandler;
    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
        this.evtUpdateParameter = EventBus.subscribe("ProjectParameter::update", ()=> this.updateParameter())
    }
    public componentWillUnmount() {
        EventBus.unsubscribe(this.evtUpdateParameter);
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.ProjectParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.DefaultProject);
    }
    protected async getOptions() {
        const db: ServiceCalls = new ServiceCalls();
        let projects: IProject[] = getResult<IProject[]>(await db.getProjects(), []);
        const use_filter = (()=>{
            if(Globals.currentModule === EModuleNames.TransferPricing){
                return EProjectType.transfer_pricing;
            }
            if(Globals.currentModule === EModuleNames.CreditSpreads){
                return EProjectType.credit_spread;
            }

            return EProjectType.valuation;
        })();

        projects = projects.filter( (p)=>p.project_type === use_filter);
        const deCollator = new Intl.Collator("de");
        projects = projects.sort( (a, b) => deCollator.compare(a.name, b.name) );
        const options: IOptionEntry[] = [
            {label: "- Ohne Projekt -", value: "0:0:0:0"},
            ...projects.map((p)=> ({label: p.name, value: p._id})),
        ];
        return options;
    }
    protected onValueChanged(v) {
        // console.error(v);
    }

    private updateParameter() {
        this.setState({isLoading: true});
    }
}
