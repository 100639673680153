export enum EFinAnnRepFieldMap {
    company_id ,
    date_decimal,
    currency_iso,
    ff_debt ,
    ff_cash_generic ,
    ff_pens_liabs_unfunded ,
    ff_funds_oper_gross ,
    ff_eq_tot ,
    ff_shldrs_eq ,
    ff_assets ,
    ff_ppe_net ,
    ff_intang ,
    ff_capex ,
    ff_wkcap ,
    ff_sales ,
    ff_ebitda ,
    ff_ebit ,
    ff_int_exp_debt ,
    ff_inc_tax ,
    ff_net_inc ,
    ff_gross_inc ,
    ff_invest_aff ,
}
