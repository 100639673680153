import {EStatisticsValueType} from "../const/EStatisticsValueType";
import {SessionStore} from "../const/SessionStore";
import {ICompanySearchResult} from "../models/ICompanySearchResult";
import {IFileManagerLoadFolder, IFileManagerPathResult} from "../models/IFileManagerEntry";
import {EProjectUserRole, IProject} from "../models/IProject";
import {EServiceType, ServiceCallBase} from "./ServiceCallBase";
import {IUser} from "../models/IUser";
import {IStatisticValue} from "../models/IStatisticValue";

export class ServiceCalls extends ServiceCallBase {
    constructor() {
        super(EServiceType.Services);
    }


    public async getStats(name, value_type, year_start, year_end, month_start, month_end): Promise<IStatisticValue[]> {
        const r = await super.callOut(`qstats/`, {
            name, value_type, year_start, year_end, month_start, month_end,
        });

        if (r && Array.isArray(r.response)) {
            return r.response as IStatisticValue[];
        }

        return [];
    }

    public async getUsers(): Promise<IUser[]> {
        const r = await super.callOut(`users/`, null, "GET");
        if (r && Array.isArray(r.users)) {
            return r.users as IUser[];
        }
        return [];
    }

    public async getUserLogins(): Promise<any[]> {
        const response =  await super.callOut(`wp-logins`, null, "GET");
        if(response.result && Array.isArray(response.result)){
            return response.result;
        }
        return [];
    }
    public async countUserProjects(): Promise<any[]> {
        const response =  await super.callOut(`prj-count`, null, "GET");
        if(response.projects && Array.isArray(response.projects)){
            return response.projects;
        }
        return [];
    }
    public async logout() {
        return super.callOut(`logout/`, {});
    }
    public async login(token: string, currentModule: string) {
        return super.callOut(`login/`, {
            token,
            currentModule,
        });
    }
    public async login2(token: string) {
        return super.callOut(`login2/`, {
            token,
        });
    }
    public async loadLongList() {
        const response =  await super.callOut(`database-search-store/customer_search_v2`, null, "GET");
        return response;
    }
    public async saveLongList(name: string, items: ICompanySearchResult[], is_public: boolean, _id: string, target?: string) {
        return super.callOut(`database-search-store/customer_search_v2`, {
            name,
            items,
            is_public,
            _id,
            target,
        });
    }
    public async renameLongList(_id: string, new_name: string, new_state?: number) {
        return super.callOut(`database-search-store/rename/${_id}`, {
            new_name,
            new_state,
        });
    }
    public async deleteLongList(_id: string) {
        return super.callOut(`database-search-store/item/${_id}`, null, "DELETE");
    }
    public async readFolder(folderId: string = "0"): Promise<IFileManagerLoadFolder> {
        let folder = `document-store/read/`;
        if (folderId) {
            folder = `document-store/read/${folderId}`;
        }
        const response =  await super.callOut(folder, null, "GET");
        if (response.result === false ) {
            return {
                parent_folder_id: "0",
                entries: [],
            };
        }
        return response;
    }
    public async deleteItems(items: any[]): Promise<IFileManagerLoadFolder> {
        const response =  await super.callOut("document-store/delete", {
            items,
        }, "POST");
        return response;
    }

    public async getPath(folderId: string): Promise<IFileManagerPathResult> {
        let folder = `document-store/path/`;
        if (folderId) {
            folder = `document-store/path/${folderId}`;
        }
        const response =  await super.callOut(folder, null, "GET");
        return response;
    }

    public async save(data: any): Promise<IFileManagerPathResult> {
        const folder = `document-store/save_v2`;
        const response =  await super.callOut(folder, data, "POST");
        return response;
    }

    public async extendDb(data: any): Promise<any> {
        const response =  await super.callOut("extendDb", data, "POST");
        return response;
    }

    public async setSessionValue(name: string, value: any): Promise<any> {
        if(ServiceCallBase.socket && ServiceCallBase.socket.connected){
            return  await super.emit("sessionValues", {name, value});
        }else{
            return await super.callOut("sessionValues", {name, value}, "POST");
        }
    }

    public async pushStat(module: string, value_type: EStatisticsValueType, values: any): Promise<any> {
        const response =  await super.callOut("stats", {name: module, values, value_type}, "POST");
        return response;
    }

    public async getSessionValues(): Promise<any> {
        const response =  await super.callOut("sessionValues", null, "GET");
        if (response && response.values) {
            const gearingPeersParameter = response.values.GearingPeersParameter;
            if (gearingPeersParameter && Array.isArray(gearingPeersParameter) && gearingPeersParameter.length) {
                if (!gearingPeersParameter[0].ff_debt) {
                    gearingPeersParameter[0] = {ff_debt: true};
                }
            }
        }
        return response;
    }
    public async getProjects(): Promise<any> {
        const response =  await super.callOut("projects", null, "GET");
        return response;
    }
    public async getProject(id): Promise<any> {
        const response =  await super.callOut(`projects/${id}`, null, "GET");
        return response;
    }
    public async loadProject(_id: string): Promise<any> {
        const response =  await super.callOut("loadProject", {_id}, "POST");
        return response;
    }
    public async deleteProject(_id: string): Promise<any> {
        const response =  await super.callOut(`projects/${_id}`, null, "DELETE");
        return response;
    }
    public async getProjectUsers(p: IProject): Promise<any> {
        const response =  await super.callOut(`projectUsers/${p._id}`, null, "GET");
        return response;
    }
    public async updateProjectUser(p: IProject, user_id: string, role: EProjectUserRole, remove?: boolean): Promise<any> {
        const response =  await super.callOut(`projectUsers/${p._id}`, {user_id, role, remove}, "POST");
        return response;
    }

    public async updateProject(p: IProject): Promise<any> {
        const response =  await super.callOut(`projects/${p._id}`, p, "POST");
        return response;
    }
    public async resetValues(): Promise<any> {
        const values: any = SessionStore.getAll();
        const response =  await super.callOut("resetValues", {values}, "POST");
        return response;
    }
    public async createProject(data): Promise<any> {
        const response =  await super.callOut("createProject", data, "POST");
        return response;
    }
    public async inviteToProject(p: IProject, invitations, message): Promise<any> {
        const response =  await super.callOut("inviteToProject", {
            project_id: p._id,
            invitations,
            message,
        }, "POST");
        return response;
    }
    public async getOpenInvitations(p: IProject): Promise<any> {
        if (!p) {
            return [];
        }
        const response =  await super.callOut(`inviteToProject/${p._id}`, null, "GET");
        return response;
    }
}
