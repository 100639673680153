import {
    Button,
    Classes,
    Dialog, H4,
    Icon,
    Intent,
    Menu,
    MenuItem,
    NonIdealState,
} from "@blueprintjs/core";
import {DateTime} from "luxon";
import {CSSProperties} from "react";
import * as React from "react";
import {EFinancialColumn} from "../../const/EFinancialColumn";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {TableStatistics} from "../../helpers/table/TableStatistics";
import {EFeItem} from "../../models/EFeItem";
import {EParameters} from "../../models/EParameters";
import {EValueName} from "../../models/EValueName";
import {IMultiplierV2} from "../../models/IMultiplierV2";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {TasksCalls} from "../../services/TasksCalls";
import {deadline, deadlineEoM, fromDateDecimal, fromStr} from "../../tools/DateTools";
import {EIndexFunction, SzTableHelper} from "../widgets/helper/SzTableHelper";
import {ISzSvgProperties, SzSvg} from "../widgets/SzSvg";
import {ISzSvgBarAnnotation} from "../widgets/SzSvgBarCell";
import {
    ESzTableColumnType,
    ISzTableBarChartOptions,
    ISzTableColumn,
    ISzTableProperties,
    ISzTagOptions,
    SzTable,
} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {_t, _tf} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

const BASE_DIR = Globals.BASE_DIR;

const limits = {};

limits[EFeItem.SALES] = 10;
limits[EFeItem.EBITDA] = 30;
limits[EFeItem.EBIT] = 40;
limits[EFeItem.NETPROFIT] = 50;
const options = [
    {label: "EV / Umsatz", value: EFeItem.SALES},
    {label: "EV / EBITDA", value: EFeItem.EBITDA},
    {label: "EV / EBIT", value: EFeItem.EBIT},
    {label: "KGV", value: EFeItem.NETPROFIT},
];
enum EMultiplierTableFieldsQm {
    name= "0",
    iso_3= "1",
    dods = "2",
    value_0= "3",
    value_1= "4",
    value_2= "5",
    value_3= "6",

    num_est_0= "7",
    num_est_1= "8",
    num_est_2= "9",
    num_est_3= "10",

    fp_end_0 = "11",
    fp_end_1 = "12",
    fp_end_2 = "13",
    fp_end_3 = "14",

    data= "15",
}
interface IMultiplierTableQm {
    name: string;
    company_id: string;
    iso_3: string;
    dods: string;
    enterprise_value: number;
    marked_cap: number;
    data_set: Map<string, IMultiplierV2>;

    mean_0?: number;
    mean_1?: number;
    mean_2?: number;
    mean_3?: number;

    median_0?: number;
    median_1?: number;
    median_2?: number;
    median_3?: number;

    std_dev_0?: number;
    std_dev_1?: number;
    std_dev_2?: number;
    std_dev_3?: number;

    min_0?: number;
    min_1?: number;
    min_2?: number;
    min_3?: number;

    max_0?: number;
    max_1?: number;
    max_2?: number;
    max_3?: number;

    num_est_0?: number;
    num_est_1?: number;
    num_est_2?: number;
    num_est_3?: number;

    fp_end_0?: string;
    fp_end_1?: string;
    fp_end_2?: string;
    fp_end_3?: string;

}
enum EMultiplierTableFields {
    name= "0",
    iso_3= "1",
    sales_0= "2",
    sales_1= "3",
    ebitda_0= "4",
    ebitda_1= "5",
    ebit_0= "6",
    ebit_1= "7",
    kgv_0= "8",
    kgv_1= "9",
    price_book= "10",
    peg_ration = "11",
    fe_fp_end_0 = "12",
    fe_fp_end_1 = "13",

    sales_2= "14",
    ebitda_2= "15",
    ebit_2= "16",
    kgv_2= "17",

    fe_fp_end_2 = "18",
}
interface IMultiplierTable {
    name: string;
    company_id: string;
    iso_3: string;
    enterprise_value: number;
    marked_cap: number;
    sales_0?: number;
    sales_1?: number;
    sales_2?: number;
    ebitda_0?: number;
    ebitda_1?: number;
    ebitda_2?: number;
    ebit_0?: number;
    ebit_1?: number;
    ebit_2?: number;

    netprofit_0?: number; // NETPROFIT_0
    netprofit_1?: number;
    netprofit_2?: number;

    fe_fp_end_0?: string;
    fe_fp_end_1?: string;
    fe_fp_end_2?: string;

    price_book?: number; // market_cap/Ff_shldrs_eq
}
const isNeg = (v, defVal) => {
    const n = Number("" + v);
    if (Number.isFinite(n)) {
        if (n < 0 ) {
            return "n.m.";
        }
        return n;
    }
    return defVal;
};

const asNumber = (v, defVal) => {
    const n = Number("" + v);
    if (Number.isFinite(n)) {
        return n;
    }
    return defVal;
};
const asNumberStr = (v, defVal) => {
    const n = Number("" + v);
    if (Number.isFinite(n)) {
        if (n < 0 ) {
            return "n.m.";
        }
        return n;
    }
    return defVal;
};
const asDate = (v) => {
    const n = DateTime.fromFormat(v, "dd-LLL-yyyy", {locale: "en"});
    if (n.isValid) {
        return n.toFormat("dd.LL.yyyy");
    }
    return null;
};

export class MultiplicatorsForCompanies extends BaseModule<any> {

    private tableProps: ISzTableProperties<any[]>;
    private annual_data: any;
    private timeSeriesData: any[] = [];
    private qmData: any[] = [];

    private selectedColumn = 0;
    private currentDt = "";
    private country_xch_map = {};

    private readonly tableRef: React.RefObject<SzTable<any>>;

    constructor(props: any, context: any) {
        super(props, context);
        this.tableRef = React.createRef();
        this.state = {
            selectedYear: "1",
        };
    }
    public async getDocumentationData() {
        return this.tableProps;
    }
    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }

    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.tableProps);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderContent() {
        return (
            <div>
                {this.data ? this.renderData() : this.noData()}
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    protected onAfterUpdate(data: any) {
        this.annual_data = {};
        (async () => {
            const db = new PublicDbCalls();
            const companies = this.parameters[EParameters.CompaniesParameter];
            // const date = this.getParameterValue(EParameters.DateParameter);
            const date_decimal = deadline(1).asDateDecimal();
            const parameters = [companies, [deadlineEoM()], ["2"]];
            const response = await db.getFinancialsAnnReported(parameters);
            const response_currency_rate = await db.getCurrencyRate2([date_decimal]);
            response_currency_rate.result.forEach( (i) => this.country_xch_map[i.country_currency_iso_3] = i.from_euro_to_ );
            if (response && Array.isArray(response.result)) {
                response.result.forEach( (row) => {
                    const date_1 = parseInt(row[1], 10);

                    if (date_1 > date_decimal) {
                        return;
                    }

                    const c = this.annual_data[row[0]];
                    if (c) {
                        const date_2 = parseInt(c[1], 10);
                        if (date_1 > date_2) {
                            this.annual_data[row[0]] = row;
                        }
                    } else {
                        this.annual_data[row[0]] = row;
                    }
                });
            }
            this.data = data;
            this.setState({
                loading: false,
            });
        })();
    }

    private renderData() {
        const rawData: IMultiplierV2[] = this.data;
        const table_data_map: Map<string, IMultiplierTable> = new Map<string, IMultiplierTable>();
        const table_data_array: IMultiplierTable[] = [];
        rawData.forEach( (d) => {
            let entry: IMultiplierTable = table_data_map.get(d.company_id);
            if (!entry) {

                // console.error(d.ff_mkt_val, d.ff_pens_liabs_unfunded, d.ff_debt, d.ff_cash_generic);
                const c = this.annual_data[d.company_id];
                if (!c) {
                    // console.error("no ann rep", d.company_id);
                    return;
                }

                const ann_xch = this.country_xch_map[c[2]];
                const to_country_xch = this.country_xch_map[d.md_currency_iso];
                const xch_1 = (v) => {
                    if (c[2] === d.md_currency_iso) {
                        return v;
                    }
                    // return v;
                    // console.error(c[2], d.md_currency_iso);
                    return (v / ann_xch) * to_country_xch;
                };

                const ff_mkt_val = xch_1(d.ff_mkt_val);
                const ff_pens_liabs_unfunded = xch_1(c[EFinancialColumn.ff_pens_liabs_unfunded]);
                const ff_debt = xch_1(c[EFinancialColumn.ff_debt]);
                const ff_cash_generic = xch_1(c[EFinancialColumn.ff_cash_generic]);
                const ff_shldrs_eq = xch_1(c[EFinancialColumn.ff_shldrs_eq]);
                /*
                console.table({name: d.name,
                    ff_mkt_val,
                    ff_pens_liabs_unfunded: c[EFinancialColumn.ff_pens_liabs_unfunded],
                    ff_debt: c[EFinancialColumn.ff_debt],
                    ff_cash_generic: c[EFinancialColumn.ff_cash_generic],
                    ff_shldrs_eq: c[EFinancialColumn.ff_shldrs_eq],
                });
                */
                // console.table({name: d.name, ff_mkt_val, ff_pens_liabs_unfunded, ff_debt, ff_cash_generic, ff_shldrs_eq});
                entry = {
                    company_id: d.company_id,
                    iso_3: d.iso_3,
                    name: d.name,
                    marked_cap: asNumber(ff_mkt_val, undefined),
                    enterprise_value: asNumber((ff_mkt_val + asNumber(ff_pens_liabs_unfunded, 0) + asNumber(ff_debt, 0)) - asNumber(ff_cash_generic, 0), undefined),
                    price_book: asNumber(asNumber(ff_mkt_val, undefined) / asNumber(ff_shldrs_eq, undefined), undefined),
                };
                table_data_array.push(entry);
                table_data_map.set(d.company_id, entry);

                if (c) {
                    entry.sales_0 = entry.enterprise_value / xch_1(asNumber(c[14], NaN));
                    entry.ebitda_0 = entry.enterprise_value / xch_1(asNumber(c[15], NaN));
                    entry.ebit_0 = entry.enterprise_value / xch_1(asNumber(c[16], NaN));
                    entry.netprofit_0 = xch_1(asNumber(c[7], NaN));
                    entry.fe_fp_end_0 = fromDateDecimal(c[1]).toFormat("dd.LL.yy");
                }
            }
            const key = `${d.fe_item.toLowerCase()}_${d.fe_per_rel}`;
            if ([EFeItem.SALES, EFeItem.EBIT, EFeItem.EBITDA].indexOf(d.fe_item as EFeItem) >= 0) {
                const v = asNumber(d.fe_median, NaN);
                if (!isNaN(v)) {
                    entry[key] = entry.enterprise_value / v;
                }
            }
            if ([EFeItem.NETPROFIT].indexOf(d.fe_item as EFeItem) >= 0) {
                const v = asNumber(d.fe_median, NaN);
                if (!isNaN(v)) {
                    entry[key] = v;
                }
            }
            const fe_fp_end = `fe_fp_end_${d.fe_per_rel}`;
            // entry[fe_fp_end] = asDate(d.fe_fp_end);
            // console.error(d.fe_fp_end, asDate(d.fe_fp_end));

            if (!entry.fe_fp_end_0 && fe_fp_end === "fe_fp_end_0") {
                entry[fe_fp_end] = asDate(d.fe_fp_end);
            }
            if (fe_fp_end !== "fe_fp_end_0") {
                entry[fe_fp_end] = asDate(d.fe_fp_end);
            }
        } );
        let table_data = table_data_array.map( (xc) => {
            if (!xc.fe_fp_end_0) {
                xc.fe_fp_end_0 = Globals.hyphen;
            }
            if (!xc.fe_fp_end_1) {
                xc.fe_fp_end_1 = Globals.hyphen;
            }
            if (!xc.fe_fp_end_2) {
                xc.fe_fp_end_2 = Globals.hyphen;
            }
            const peg_ratio = ((xc.marked_cap / xc.netprofit_1) / (xc.netprofit_1 / xc.netprofit_0 - 1)) / 100;
            return [
                xc.name,
                xc.iso_3,
                isNeg(xc.sales_0, "—" ),
                isNeg(xc.sales_1, "—" ),
                isNeg(xc.ebitda_0, "—" ),
                isNeg(xc.ebitda_1, "—" ),
                isNeg(xc.ebit_0, "—" ),
                isNeg(xc.ebit_1, "—" ),
                isNeg(xc.marked_cap / xc.netprofit_0, "—" ),
                isNeg(xc.marked_cap / xc.netprofit_1, "—" ),
                isNeg(xc.price_book, "—" ),
                "n.m.",
                xc.fe_fp_end_0,
                xc.fe_fp_end_1,

                isNeg(xc.sales_2, "—" ),
                isNeg(xc.ebitda_2, "—" ),
                isNeg(xc.ebit_2, "—" ),
                isNeg(xc.marked_cap / xc.netprofit_2, "—" ),

                xc.fe_fp_end_2,
            ];
        });

        const deCollator = new Intl.Collator("de");
        table_data = table_data.sort((a, b) => deCollator.compare(a[0].toLowerCase(), b[0].toLowerCase()));

        let ch = 1;
        const spacer_header: ISzTableColumn = { text: "", options: {className: "no-padding"} };
        const header: ISzTableColumn[] = [];
        header.push({ text: "#" });
        header.push({ text: "Name" });
        header.push({ text: "ISO 3" });
        header.push({ text: "Trailing¹", options: {className: "sz-right"} });
        header.push({ text: "Forward²", options: {className: "sz-right"} });
        header.push({ text: "Forward +1", options: {className: "sz-right"} });
        header.push(spacer_header);
        header.push({ text: "Trailing", options: {className: "sz-right", style: {borderLeft: "4px solid #ffffff"}}, columnHover: ch++ });
        header.push({ text: "Forward", options: {className: "sz-right"}, columnHover: ch++ });
        header.push({ text: "Forward +1", options: {className: "sz-right"}, columnHover: ch++ });
        header.push(spacer_header);
        header.push({ text: "Trailing", options: {className: "sz-right", style: {borderLeft: "4px solid #ffffff"}}, columnHover: ch++ });
        header.push({ text: "Forward", options: {className: "sz-right"}, columnHover: ch++ });
        header.push({ text: "Forward +1", options: {className: "sz-right"}, columnHover: ch++ });
        header.push(spacer_header);
        header.push({ text: "Trailing", options: {className: "sz-right", style: {borderLeft: "4px solid #ffffff"}}, columnHover: ch++ });
        header.push({ text: "Forward", options: {className: "sz-right"}, columnHover: ch++ });
        header.push({ text: "Forward +1", options: {className: "sz-right"}, columnHover: ch++ });
        header.push(spacer_header);
        header.push({ text: "Trailing", options: {className: "sz-right", style: {borderLeft: "4px solid #ffffff"}}, columnHover: ch++ });
        header.push({ text: "Forward", options: {className: "sz-right"}, columnHover: ch++ });
        header.push({ text: "Forward +1", options: {className: "sz-right"}, columnHover: ch++ });
        header.push(spacer_header);
        header.push({ text: "", options: {className: "sz-right"}});

        const header_0: ISzTableColumn[] = [];
        header_0.push({ text: _t(ETranslation.company), options: {colSpan: 2} });
        header_0.push({ text: _t(ETranslation.country) });
        header_0.push({ text: _t(ETranslation.financial_year), options: {colSpan: 3, style: {textAlign: "center"}} });
        header_0.push(spacer_header);
        header_0.push({ text: _t(ETranslation.revenues), options: {colSpan: 3, style: {textAlign: "center"}} });
        header_0.push(spacer_header);
        header_0.push({ text: "EBITDA", options: {colSpan: 3, style: {textAlign: "center"}} });
        header_0.push(spacer_header);
        header_0.push({ text: "EBIT", options: {colSpan: 3, style: {textAlign: "center"}} });
        header_0.push(spacer_header);
        header_0.push({ text: _t(ETranslation.pe_ratio), options: {colSpan: 3, style: {textAlign: "center"}} });
        header_0.push(spacer_header);
        header_0.push({ text: _t(ETranslation.pb_ratio), options: {style: {textAlign: "right", verticalAlign: "middle", width: 92, borderLeft: "4px solid #ffffff"}}});

        const header_1: ISzTableColumn[] = [];
        header_1.push({ text: "", options: {colSpan: 6} });
        header_1.push(spacer_header);
        header_1.push({ text: _t(ETranslation.enterprise_value), options: {colSpan: 11, style: {textAlign: "center"}} });
        header_1.push(spacer_header);
        header_1.push({ text: _t(ETranslation.equity_value), options: {colSpan: 5, style: {textAlign: "center"}} });

        const extMoney: ISzTagOptions = {style: {width: 70}, doc_auto_width: true};
        const extMoney_2: ISzTagOptions = {style: {width: 75}, doc_auto_width: true};
        const extMoneyBorder: ISzTagOptions = {style: {width: 80}, doc_auto_width: true};
        const spacer = { ...SzTableHelper.columnIndex(""), options: { className: "no-padding", style: { backgroundColor: "#fff", width: 4, maxWidth: 4, boxShadow: "none", fontSize: "1px", borderTop: "none" }}};
        const columns: ISzTableColumn[] = [];

        columns.push({ ...SzTableHelper.columnFunction(EIndexFunction.$row_num), options: {style: {width: 10} }}); // index
        columns.push(SzTableHelper.columnIndex(EMultiplierTableFields.name)); // unternehmen
        columns.push(SzTableHelper.columnIndex(EMultiplierTableFields.iso_3, 50)); // subsektor
        columns.push({ ...SzTableHelper.columnIndex(EMultiplierTableFields.fe_fp_end_0), options: {style: {width: 80}, className: "sz-right"}});
        columns.push({ ...SzTableHelper.columnIndex(EMultiplierTableFields.fe_fp_end_1), options: {style: {width: 80}, className: "sz-right"}});
        columns.push({ ...SzTableHelper.columnIndex(EMultiplierTableFields.fe_fp_end_2), options: {style: {width: 80}, className: "sz-right"}});

        columns.push(spacer);

        ch = 1;
        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.sales_0, 1, extMoneyBorder), columnHover: ch++ });
        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.sales_1, 1, extMoney), columnHover: ch++ });
        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.sales_2, 1, extMoney_2), columnHover: ch++ });

        columns.push(spacer);

        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.ebitda_0, 1, extMoneyBorder), columnHover: ch++ });
        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.ebitda_1, 1, extMoney), columnHover: ch++ });
        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.ebitda_2, 1, extMoney_2), columnHover: ch++ });

        columns.push(spacer);

        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.ebit_0, 1, extMoneyBorder), columnHover: ch++ });
        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.ebit_1, 1, extMoney), columnHover: ch++ });
        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.ebit_2, 1, extMoney_2), columnHover: ch++ });

        columns.push(spacer);

        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.kgv_0, 1, extMoneyBorder), columnHover: ch++ });
        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.kgv_1, 1, extMoney), columnHover: ch++ });
        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.kgv_2, 1, extMoney_2), columnHover: ch++ });

        columns.push(spacer);

        columns.push({ ...SzTableHelper.columnMoney(EMultiplierTableFields.price_book, 1, extMoneyBorder)});

        const footerData = TableStatistics.getTableStatistics(table_data, {
            pack: true,
            headColumn: 0,
            columns: [2, 3, 14, 4, 5, 15, 6, 7, 16 , 8, 9, 17, 10],
        });
        if (Array.isArray(footerData) && footerData.length === 6) {
            SessionStore.setGlobalVar(EValueName.sales_trailing, footerData[3][1]);
            SessionStore.setGlobalVar(EValueName.sales_forward, footerData[3][2]);

            SessionStore.setGlobalVar(EValueName.ebitda_trailing, footerData[3][4]);
            SessionStore.setGlobalVar(EValueName.ebitda_forward, footerData[3][5]);

            SessionStore.setGlobalVar(EValueName.ebit_trailing, footerData[3][7]);
            SessionStore.setGlobalVar(EValueName.ebit_forward, footerData[3][8]);
        }

        const footer: ISzTableColumn[] = [
            {index: "0", options: { className: "strong", colSpan: 6 }},
            spacer,
            { ...SzTableHelper.columnMoney("1", 1, extMoneyBorder), columnHover: 1},
            { ...SzTableHelper.columnMoney("2", 1, extMoney), columnHover: 2},
            { ...SzTableHelper.columnMoney("3", 1, extMoney), columnHover: 3},
            spacer,
            { ...SzTableHelper.columnMoney("4", 1, extMoneyBorder), columnHover: 4},
            { ...SzTableHelper.columnMoney("5", 1, extMoney), columnHover: 5},
            { ...SzTableHelper.columnMoney("6", 1, extMoney), columnHover: 6},
            spacer,
            { ...SzTableHelper.columnMoney("7", 1, extMoneyBorder), columnHover: 7},
            { ...SzTableHelper.columnMoney("8", 1, extMoney), columnHover: 8},
            { ...SzTableHelper.columnMoney("9", 1, extMoney), columnHover: 9},
            spacer,
            { ...SzTableHelper.columnMoney("10", 1, extMoneyBorder), columnHover: 10},
            { ...SzTableHelper.columnMoney("11", 1, extMoney), columnHover: 11},
            { ...SzTableHelper.columnMoney("12", 1, extMoney), columnHover: 12},
            spacer,
            { ...SzTableHelper.columnMoney("13", 1, extMoneyBorder)},
        ];
        const columnPopover = (col: number) => {
            const show_time_line = () => {
                this.onCellClick(0, this.selectedColumn);
            };
            const show_qm = () => {
                this.onCellClickQm(0, this.selectedColumn);
            };
            const qm_columns = [ 2, 3, 5, 6, 8, 9, 11, 12];
            const qm_button = () => {
                if (qm_columns.indexOf(col) < 0) {
                    return null;
                }
                return (
                    <div style={{margin: 4}}>
                        <Button icon={"list-detail-view"} text={"Prognose-Qualität"} fill={true} onClick={ () => show_qm() } intent={Intent.PRIMARY} />
                    </div>
                );
            };

            return (
                <div style={{padding: 8, width: 200, backgroundColor: "white", paddingTop: 16}}>
                    <div style={{margin: 4}}>
                        <Button icon={"chart"} text={_t(ETranslation.time_series)} fill={true} onClick={ () => show_time_line() } intent={Intent.PRIMARY} />
                    </div>
                    {qm_button()}
                </div>
            );
        };
        this.tableProps = {
            colCount: columns.length,
            data: table_data,
            header: [header_1, header_0, header],
            columns,
            footer,
            footerData,
            columnPopover,
            onSelectColumn: (col: number) => {
                this.selectedColumn = col;
            },
        };

        this.tableProps.notes = [];
        this.tableProps.notes.push(_tf(1,ETranslation.trailing_multiples_description));
        this.tableProps.notes.push(_tf(2, ETranslation.forward_multiples_description));

        if (this.props.isHidden) {
            return null;
        }

        return (
            <>
                <SzTable {...this.tableProps} ref={this.tableRef} />
                {this.renderDlg()}
                {this.renderDlgQm()}
            </>
        );
    }

    private noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }
    private renderDataQm() {
        const rawData: IMultiplierV2[] = this.qmData;
        const table_data_map: Map<string, IMultiplierTableQm> = new Map<string, IMultiplierTableQm>();

        const table_data_array: IMultiplierTableQm[] = [];

        let max_val = Number.MIN_SAFE_INTEGER;
        let min_val = Number.MAX_SAFE_INTEGER;
        rawData.forEach( (d) => {
            let entry: IMultiplierTableQm = table_data_map.get(d.company_id);
            if (!entry) {

                const c = this.annual_data[d.company_id];
                if (!c) {
                    console.error("no ann rep", d.company_id);
                    return;
                }

                const ann_xch = this.country_xch_map[c[2]];
                const to_country_xch = this.country_xch_map[d.md_currency_iso];
                const xch_1 = (v) => {
                    if (c[2] === d.md_currency_iso) {
                        return v;
                    }
                    // return v;
                    // console.error(c[2], d.md_currency_iso);
                    return (v / ann_xch) * to_country_xch;
                };

                const ff_mkt_val = xch_1(d.ff_mkt_val);
                const ff_pens_liabs_unfunded = xch_1(c[EFinancialColumn.ff_pens_liabs_unfunded]);
                const ff_debt = xch_1(c[EFinancialColumn.ff_debt]);
                const ff_cash_generic = xch_1(c[EFinancialColumn.ff_cash_generic]);

                entry = {
                    company_id: d.company_id,
                    iso_3: d.iso_3,
                    name: d.name,
                    dods: fromDateDecimal(d.dods).asDeDate(),
                    marked_cap: asNumber(ff_mkt_val, undefined),
                    enterprise_value: asNumber((ff_mkt_val + asNumber(ff_pens_liabs_unfunded, 0) + asNumber(ff_debt, 0)) - asNumber(ff_cash_generic, 0), undefined),
                    data_set: new Map<string, IMultiplierV2>(),
                };
                table_data_array.push(entry);
                table_data_map.set(d.company_id, entry);
            }

            entry.data_set.set("" + d.fe_per_rel, d);
            const key = `mean_${d.fe_per_rel}`;
            const std_dev_key = `std_dev_${d.fe_per_rel}`;
            const min_key = `min_${d.fe_per_rel}`;
            const max_key = `max_${d.fe_per_rel}`;
            const median_key = `median_${d.fe_per_rel}`;
            const num_est_key = `num_est_${d.fe_per_rel}`;
            const fp_end_key = `fp_end_${d.fe_per_rel}`;

            let q = entry.enterprise_value;
            if ([EFeItem.NETPROFIT].indexOf(d.fe_item as EFeItem) >= 0) {
                q = entry.marked_cap;
            }

            const error1 = q / asNumber(d.fe_std_dev + d.fe_mean, NaN);
            const error2 = q / asNumber(d.fe_mean - d.fe_std_dev, NaN);

            entry[std_dev_key] = Math.abs(error2 - error1);
            entry[max_key] = q / asNumber(d.fe_low, NaN);
            entry[min_key] = q / asNumber(d.fe_high, NaN);
            entry[median_key] = q / asNumber(d.fe_median, NaN);
            entry[key] = q / asNumber(d.fe_mean, NaN);
            entry[num_est_key] = asNumber(d.fe_num_est, NaN);
            entry[fp_end_key] = asDate(d.fe_fp_end);

            // console.error(entry[median_key] - entry[max_key], entry[max_key], entry[median_key]);
            // console.error(entry[median_key] - entry[min_key], entry[min_key], entry[median_key]);

            const c_max = entry[max_key] - entry[median_key]; // - entry[median_key];
            const c_min = -1 * (entry[median_key] - entry[min_key]);

            if (d.fe_per_rel === "" + this.state.per_rel) {
                // console.error(d.name, `min(${entry[min_key]}) max(${entry[max_key]}) mean(${entry[key]}) median(${entry[median_key]})`);
            }

            if (c_max > max_val && isFinite(c_max) && d.fe_per_rel === "" + this.state.per_rel) {
                max_val = c_max; // + (.9 * c_max);
            }
            if (c_min < min_val && isFinite(c_min) && d.fe_per_rel === "" + this.state.per_rel) {
                min_val = c_min; // + (.9 * c_min);
                // console.error(d, entry[min_key], entry[max_key], entry[median_key]);
            }

        } );

        const fe_per_rel = EMultiplierTableFieldsQm[`value_${this.state.per_rel}`];
        const fe_num_est = EMultiplierTableFieldsQm[`num_est_${this.state.per_rel}`];
        const fe_fp_end = EMultiplierTableFieldsQm[`fp_end_${this.state.per_rel}`];

        const table_data = table_data_array.map( (xc) => {
            return [
                xc.name,
                xc.iso_3,
                xc.dods,
                asNumberStr(xc.median_0, null),
                asNumberStr(xc.median_1, null),
                asNumberStr(xc.median_2, null),
                asNumberStr(xc.median_3, null),

                asNumberStr(xc.num_est_0, null),
                asNumberStr(xc.num_est_1, null),
                asNumberStr(xc.num_est_2, null),
                asNumberStr(xc.num_est_3, null),

                xc.fp_end_0,
                xc.fp_end_1,
                xc.fp_end_2,
                xc.fp_end_3,

                xc,
            ];
        });

        const get_fe_per_rel = () => {
            const v = this.state.per_rel - 1;
            if (v === 0) {
                return "";
            }
            return `+${v}`;
        };
        const header: ISzTableColumn[] = [];
        header.push({ text: "#" });
        header.push({ text: _t(ETranslation.company) });
        header.push({ text: "ISO 3" });
        header.push({ text: ""});
        header.push({ text: _t(ETranslation.financial_year)});
        header.push({ text: ""});
        header.push({ text: `Forward ${get_fe_per_rel()}`, options: {className: "sz-right"} });
        header.push({ text: "# Schätzungen¹", options: {className: "sz-right"} });
        header.push({ text: `Prognose-Qualität: Forward ${get_fe_per_rel()}`, options: { style: {textAlign: "center"}}});

        const extMoney: ISzTagOptions = {style: {width: 100}};
        const extMoney_2: ISzTagOptions = {style: {width: 120}};
        const columns: ISzTableColumn[] = [];
        columns.push({ ...SzTableHelper.columnFunction(EIndexFunction.$row_num), options: {style: {width: 10} }}); // index
        columns.push(SzTableHelper.columnIndex(EMultiplierTableFieldsQm.name)); // unternehmen
        columns.push(SzTableHelper.columnIndex(EMultiplierTableFieldsQm.iso_3, 50)); // subsektor
        columns.push({ ...SzTableHelper.columnIndex(""), options: {style: {width: 10, borderLeft: "4px solid #ffffff"} }}); // index
        columns.push({ ...SzTableHelper.columnIndex(fe_fp_end), options: {style: {width: 50, textAlign: "right"} }}); // index
        columns.push({ ...SzTableHelper.columnIndex(""), options: {style: {width: 10, borderLeft: "4px solid #ffffff"} }}); // index
        columns.push({ ...SzTableHelper.columnMoney(fe_per_rel, 1, extMoney), zeroAsHyphen: true});
        columns.push({ ...SzTableHelper.columnMoney(fe_num_est, 0, extMoney_2), zeroAsHyphen: true});
        const _this = this;

        const bar_options: ISzTableBarChartOptions = {
            max: max_val,
            min: min_val,
            height: 30,
            padding_right: 0,
            getAnnotation(rowData: any): ISzSvgBarAnnotation {
                const x: IMultiplierTableQm = rowData[EMultiplierTableFieldsQm.data] as IMultiplierTableQm;
                const ret = {
                    min: x[`min_${_this.state.per_rel}`],
                    max: x[`max_${_this.state.per_rel}`],
                    median: x[`median_${_this.state.per_rel}`],
                    mean: x[`mean_${_this.state.per_rel}`],
                    std_dev: x[`std_dev_${_this.state.per_rel}`],
                };
                return ret;
            },
        };
        const chart_Style: CSSProperties = {
            width: 300,
            paddingTop: 0,
            paddingBottom: 0,
            borderLeft: "4px solid rgb(255, 255, 255)",
        };
        columns.push({ ...SzTableHelper.columnMoney(fe_per_rel, 2), columnType: ESzTableColumnType.CellChart , barchart: bar_options, options: {style: chart_Style}});

        const footerData = TableStatistics.getTableStatistics(table_data, {
            pack: true,
            headColumn: 0,
            columns: [fe_per_rel, fe_num_est],
        });

        const footer: ISzTableColumn[] = [
            {index: "0", options: { className: "strong", colSpan: 6 }},
            { ...SzTableHelper.columnMoney("1", 1, extMoney)},
            { ...SzTableHelper.columnMoney("2", 0, extMoney)},
            { ...SzTableHelper.columnIndex("")},
        ];
        const tableProps = {
            colCount: columns.length,
            data: table_data,
            header: [header],
            notes: [
                `¹) "${Globals.hyphen}" Unternehmen wird von Analysten nicht betreut, "0" Unternehmen wird aktuell von Analysten nicht betreut`,
            ],
            columns,
            footer,
            footerData,
        };
        return (<SzTable {...tableProps} key={`vt_${this.state.feItem}_${this.state.per_rel}`} />);
    }
    private renderGfxData() {
        const rawData: IMultiplierV2[] = this.timeSeriesData;
        const timeSeriesMap = {}; // companyId
        const series = [];
        const seriesLegend: string[] = [];

        rawData.forEach( (d) => {

            if ( parseInt(d.fe_per_rel, 10) !== this.state.per_rel) {
                return;
            }

            const c = this.annual_data[d.company_id];
            if (!c) {
                return;
            }

            let dataArray: any[] = timeSeriesMap[d.company_id];
            if (!dataArray) {
                dataArray = [];
                series.push(dataArray);
                timeSeriesMap[d.company_id] = dataArray;
                seriesLegend.push(d.name);
            }

            const ann_xch = this.country_xch_map[c[2]];
            const to_country_xch = this.country_xch_map[d.md_currency_iso];
            const xch_1 = (v) => {
                if (c[2] === d.md_currency_iso) {
                    return v;
                }
                // return v;
                // console.error(c[2], d.md_currency_iso);
                return (v / ann_xch) * to_country_xch;
            };

            const ff_mkt_val = xch_1(d.ff_mkt_val);
            const ff_pens_liabs_unfunded = xch_1(c[EFinancialColumn.ff_pens_liabs_unfunded]);
            const ff_debt = xch_1(c[EFinancialColumn.ff_debt]);
            const ff_cash_generic = xch_1(c[EFinancialColumn.ff_cash_generic]);

            const marked_cap = asNumber(ff_mkt_val, undefined);
            const enterprise_value = asNumber((ff_mkt_val + asNumber(ff_pens_liabs_unfunded, 0) + asNumber(ff_debt, 0)) - asNumber(ff_cash_generic, 0), undefined);
            let q = enterprise_value;
            if ([EFeItem.NETPROFIT].indexOf(d.fe_item as EFeItem) >= 0) {
                q = marked_cap;
            }
            const val = q / asNumber(d.fe_mean, NaN);

            dataArray.push({
                x: dataArray.length,
                y: val,
            });
        } );

        const xLabels = this.getXLegend();
        const xTicks = (new Array(xLabels.length)).fill(0).map((d, i) => i);
        const properties: ISzSvgProperties = {
            height: "480px",
            width: "100%",
            yTickStep: .25,
            caption: "",
            xAxisLabel: "",
            xLabelsSkip: 0,
            legendWidth: 170,
            showLastDotLabel: true,
            series,
            xLabels,
            xTicks,
            seriesLegend: null,
            digits: 1,
        };
        // console.error(properties);
        return (
            <div className={"sz-row"}>
                <div className={"sz-col-80"}>
                    <SzSvg {...properties}></SzSvg>
                </div>
                <div className={"sz-col-20"}>
                    {this.renderLegend(seriesLegend)}
                </div>
            </div>
        );
    }
    private renderLegend(legend: string[]) {
        if (!legend) {
            return null;
        }
        if (!legend.length) {
            return null;
        }
        return (
            <ul className={"sz-legend"}>
                {legend.map( (s, i) => <li className={`sz-legend-item sz-legend-item-${i}`}>&nbsp;{s}</li> )}
            </ul>
        );
    }
    private renderLoadingGfx() {
        return (
            <div className={"sz-row"}>
                <div className={"sz-col-80"}>
                    <div style={{width: "100%", height: 480}}>{this.renderLoadingDefault()}</div>
                </div>
                <div className={"sz-col-20"}>
                    &nbsp;
                </div>
            </div>
        );
    }
    private renderLoadingQm() {
        return (
            <div className={"sz-row"}>
                <div style={{width: "100%", height: 480}}>{this.renderLoadingDefault()}</div>
            </div>
        );
    }
    private renderDlgQm() {
        const renderRest = () => {
            if (this.state.waiting) {
                return this.renderLoadingQm();
            }
            if (!this.qmData) {
                return this.noData();
            }
            return this.renderDataQm();
        };

        const get_intend = (v) => {
            if (v === this.state.per_rel) {
                return "warning";
            } else {
                return "none";
            }
        };
        const m1: CSSProperties = {
            marginRight: 8,
        };
        const m2: CSSProperties = {
            marginRight: 14,
            display: "inline",
        };
        return (
            <Dialog
                style={{width: 1220, backgroundColor: "transparent", padding: 0}}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={this.state.showQmDlg}
                onClose={() => this.setState({showQmDlg: false})}
            >
                <div className={`${Classes.DIALOG_HEADER} bp3-dark`} style={{backgroundColor: "rgb(57, 75, 89)"}}>
                    <Icon icon={"list-detail-view"} color={"#f5f8fa"}/>
                    <H4>Prognose-Qualität</H4>
                    <Button intent={Intent.DANGER} aria-label={"Schließen"} icon={"small-cross"} minimal={true} onClick={() => this.setState({showQmDlg: false})} />
                </div>
                <div className={Classes.DIALOG_BODY} style={{margin: 0, padding: 10, paddingBottom: 30, backgroundColor: "#ffffff"}}>
                    <div style={{display: "flex", marginBottom: 8}}>
                        {options.map( (o) => {
                            return (<Button text={o.label} onClick={ () => this.setFeItem(o.value)} intent={this.state.feItem === o.value ? "warning" : "none"} style={m1} />);
                        } )}
                        <div style={m2}>&nbsp;</div>
                        <Button text={"Forward"} onClick={() => this.setFePerRel(1)} intent={get_intend(1)} style={m1} />
                        <Button text={"+1"} onClick={() => this.setFePerRel(2)} intent={get_intend(2)} style={m1} />
                    </div>
                    <div className={"sz-chart-le"}>
                        {_t(ETranslation.average)}<div className={"sz-chart-le-mean"}>&nbsp;</div>{_t(ETranslation.median)} <img src={`${BASE_DIR}images/median.png`}/>
                    </div>
                    {renderRest()}
                </div>
            </Dialog>
        );
    }
    private renderDlg() {
        const renderRest = () => {
            if (this.state.waiting) {
                return this.renderLoadingGfx();
            }
            if (!this.timeSeriesData) {
                return this.noData();
            }
            return this.renderGfxData();
        };
        const yearOptions = [
            {label: _t(ETranslation.year_number, 1), value: "1"},
            {label: _t(ETranslation.years_number, 2), value: "2"},
            {label: _t(ETranslation.years_number, 3), value: "3"},
        ];

        const get_intend = (v) => {
            if (v === this.state.per_rel) {
                return "warning";
            } else {
                return "none";
            }
        };
        const m1: CSSProperties = {
            marginRight: 8,
        };
        const m2: CSSProperties = {
            marginRight: 14,
            display: "inline",
        };
        return (
            <Dialog
                style={{width: 1220, backgroundColor: "transparent", padding: 0}}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={this.state.showDlg}
                onClose={() => this.setState({showDlg: false})}
            >
                <div className={`${Classes.DIALOG_HEADER} bp3-dark`} style={{backgroundColor: "rgb(57, 75, 89)"}}>
                    <Icon icon={"chart"} color={"#f5f8fa"}/>
                    <H4>_t(ETranslation.time_series)</H4>
                    <Button intent={Intent.DANGER} aria-label={"Schließen"} icon={"small-cross"} minimal={true} onClick={() => this.setState({showDlg: false})} />
                </div>
                <div className={Classes.DIALOG_BODY} style={{margin: 0, padding: 10, paddingBottom: 30, backgroundColor: "#ffffff"}}>
                    <div style={{width: "100%", display: "flex", paddingBottom: 10}}>
                        {yearOptions.map( (o) => {
                            return (<Button text={o.label} onClick={ () => this.setYear(o.value)} intent={this.state.selectedYear === o.value ? "warning" : "none"} style={m1} />);
                        } )}
                        <div style={m2}>&nbsp;</div>
                        {options.map( (o) => {
                            return (<Button text={o.label} onClick={ () => this.setFeItem(o.value)} intent={this.state.feItem === o.value ? "warning" : "none"} style={m1} />);
                        } )}
                        <div style={m2}>&nbsp;</div>
                        <Button text={"Trailing"} onClick={() => this.setFePerRel(0)} intent={get_intend(0)} style={m1} />
                        <Button text={"Forward"} onClick={() => this.setFePerRel(1)} intent={get_intend(1)} style={m1} />
                        <Button text={"Forward +1"} onClick={() => this.setFePerRel(2)} intent={get_intend(2)} />
                    </div>
                    {renderRest()}
                </div>
            </Dialog>
        );
    }
    private loadDate(dt, cm, feItem, per_rel) {
        (async () => {
            try {
                const db = new PublicDbCalls();
                const response = await db.getMultiplierForCompaniesV2([dt, cm, [feItem], [per_rel]]);
                if (response && response.result) {
                    this.timeSeriesData = response.result;
                    this.qmData = this.timeSeriesData.filter( (r) => this.currentDt === r.date_decimal );
                }
            } finally {
                this.setState({
                    waiting: false,
                });
            }
        })();
    }
    private setFePerRel(fe_per_rel: number) {
        const selectedYear = this.state.selectedYear;
        const dt = this.getDates(selectedYear ? selectedYear : 1 );
        const cm = this.parameters[EParameters.CompaniesParameter];
        const feItem: string = this.state.feItem;
        const per_rel = fe_per_rel;
        this.setState({
            waiting: true,
            feItem,
            per_rel,
        });
        this.loadDate(dt, cm, feItem, per_rel);
    }
    private getXLegend() {
        const nYears = parseInt(this.state.selectedYear, 10);
        if (!isFinite(nYears)) {
            return [];
        }
        const dt = this.parameters[EParameters.DateParameter];
        if (!(Array.isArray(dt) && dt.length)) {
            return [];
        }
        const dates = [];
        const divdiv = nYears <= 3 ? 3 : nYears === 10 ? 12 : 6;
        for (let i = 0; i < (nYears * 12); i++) {
            if (!(i % divdiv === 0)) {
                dates.unshift("");
                continue;
            }
            const newDate = fromStr(dt[0]).startOf("month").minus({month: i}).endOf("month").toFormat("LLL yyyy");
            dates.unshift(newDate);
        }
        return dates;
    }
    private getDates(years: string) {
        const nYears = parseInt(years, 10);
        if (!isFinite(nYears)) {
            return [];
        }
        const dt = this.parameters[EParameters.DateParameter];
        if (!(Array.isArray(dt) && dt.length)) {
            return [];
        }
        const dates = [];
        this.currentDt = fromStr(dt[0]).setToEoM().toFormat("yyyyLLdd");
        const d = fromStr(dt[0]).setToEoM();
        for (let i = 0; i < (nYears * 12); i++) {
            const newDate = d.startOf("month").minus({month: i}).endOf("month").asDeDate();
            dates.unshift(newDate);
        }
        // console.error(dates);
        return dates;
    }
    private setYear(value: string) {
        const dt = this.getDates(value);
        const cm = this.parameters[EParameters.CompaniesParameter];
        const feItem: string = this.state.feItem;
        const per_rel = this.state.per_rel;
        this.setState({
            waiting: true,
            selectedYear: value,
        });
        this.loadDate(dt, cm, feItem, per_rel);
    }
    private setFeItem(value: string) {
        const selectedYear = this.state.selectedYear;
        const dt = this.getDates(selectedYear ? selectedYear : 1 );
        const cm = this.parameters[EParameters.CompaniesParameter];
        const feItem: string = value;
        const per_rel = this.state.per_rel;
        this.setState({
            waiting: true,
            feItem,
            per_rel,
        });
        this.loadDate(dt, cm, feItem, per_rel);
    }

    private onCellClick(rowNum, cellNum) {
        const param_map = {
            1: {feItem: EFeItem.SALES, fe_per_rel: 0},
            2: {feItem: EFeItem.SALES, fe_per_rel: 1},
            3: {feItem: EFeItem.SALES, fe_per_rel: 2},

            4: {feItem: EFeItem.EBITDA, fe_per_rel: 0},
            5: {feItem: EFeItem.EBITDA, fe_per_rel: 1},
            6: {feItem: EFeItem.EBITDA, fe_per_rel: 2},

            7: {feItem: EFeItem.EBIT, fe_per_rel: 0},
            8: {feItem: EFeItem.EBIT, fe_per_rel: 1},
            9: {feItem: EFeItem.EBIT, fe_per_rel: 2},

            10: {feItem: EFeItem.NETPROFIT, fe_per_rel: 0},
            11: {feItem: EFeItem.NETPROFIT, fe_per_rel: 1},
            12: {feItem: EFeItem.NETPROFIT, fe_per_rel: 2},
        };
        // console.error(rowNum, cellNum);
        const p = param_map[cellNum];
        if (!p) {
            return;
        }
        this.tableRef.current.closeColumnPopover(cellNum);
        const selectedYear = this.state.selectedYear ? this.state.selectedYear : 1;
        const dt = this.getDates(selectedYear );
        const cm = this.parameters[EParameters.CompaniesParameter];
        const feItem: string = p.feItem;
        const per_rel = p.fe_per_rel;
        this.setState({
            showDlg: true,
            waiting: true,
            feItem,
            per_rel,
            selectedYear,
        });
        this.loadDate(dt, cm, feItem, per_rel);
    }
    private onCellClickQm(rowNum, cellNum) {
        const param_map = {
            2: {feItem: EFeItem.SALES, fe_per_rel: 1},
            3: {feItem: EFeItem.SALES, fe_per_rel: 2},

            5: {feItem: EFeItem.EBITDA, fe_per_rel: 1},
            6: {feItem: EFeItem.EBITDA, fe_per_rel: 2},

            8: {feItem: EFeItem.EBIT, fe_per_rel: 1},
            9: {feItem: EFeItem.EBIT, fe_per_rel: 2},

            11: {feItem: EFeItem.NETPROFIT, fe_per_rel: 1},
            12: {feItem: EFeItem.NETPROFIT, fe_per_rel: 2},
        };
        // console.error(rowNum, cellNum);
        const p = param_map[cellNum];
        if (!p) {
            return;
        }
        this.tableRef.current.closeColumnPopover(cellNum);
        const selectedYear = this.state.selectedYear ? this.state.selectedYear : 1;
        const dt = this.getDates(selectedYear );
        const cm = this.parameters[EParameters.CompaniesParameter];
        const feItem: string = p.feItem;
        const per_rel = p.fe_per_rel;
        this.setState({
            showQmDlg: true,
            waiting: true,
            feItem,
            per_rel,
            selectedYear,
        });
        this.loadDate(dt, cm, feItem, per_rel);
    }
}
