import {DateTime, Duration} from "luxon";
import {SessionStore} from "../const/SessionStore";
import {EParameters} from "../models/EParameters";
import {IToastProps} from "@blueprintjs/core";
import {EventBus} from "../services/EventBus";

declare module 'luxon/src/datetime' {
    export interface DateTime {
        asDateDecimal(): number;
        asDeDate(): string;
        setToEoM(): DateTime;
    }
}

DateTime.prototype.asDateDecimal = function(): number {
    const dt = this as DateTime;
    return parseInt(dt.toFormat("yyyyLLdd"), 10);
};
DateTime.prototype.asDeDate = function(): string {
    const dt = this as DateTime;
    return dt.toFormat("dd.LL.yyyy");
};
DateTime.prototype.setToEoM = function(): DateTime {
    const dt = this as DateTime;
    return dt.startOf("month").endOf("month");
};

export function fromNow(): DateTime {
    const dt: DateTime = DateTime.now();
    return dt;
}
export function fromDateParameter(): DateTime {
    return fromStr(SessionStore.get(EParameters.DateParameter));
}

export function fromStr(date: any, fmt: string = "dd.LL.yyyy"): DateTime {
    let dt: DateTime = DateTime.now().startOf("month").minus({month: 1}).endOf("month");
    try {
        if(date instanceof Date){
            dt = DateTime.fromJSDate(date);
        }else{
            dt = DateTime.fromFormat("" + date, fmt);
        }
    }catch (e) {
        const p: IToastProps = {
            intent: "danger",
            message: `Date-Error: ${date}\n${e.message}`,
        };
        EventBus.emit("sz-show-message", p);
        console.error(date, fmt);
        console.error(e);
    }
    return dt;
}
export function fromJsDate(date: any): DateTime {
    return DateTime.fromJSDate(date);
}
export function fromDate(yyyy: string|number, LL?: string|number, dd?: string|number): DateTime {
    if(LL !== undefined && typeof LL === "number" && LL<10 && LL > 0){
        LL = `0${LL}`;
    }
    if(dd !== undefined && typeof dd === "number" && dd<10 && dd > 0){
        dd = `0${dd}`;
    }
    const fmt: string = LL !== undefined && dd !== undefined ? "yyyy.LL.dd" : LL !== undefined ? "yyyy.LL" : "yyyy";
    const val: string =  LL !== undefined && dd !== undefined ? `${yyyy}.${LL}.${dd}` : LL !== undefined ? `${yyyy}.${LL}` : `${yyyy}`;
    const dt: DateTime = DateTime.fromFormat(val, fmt);
    return dt;
}

export function fromDateDecimal(yyyyLLdd: string|number): DateTime {
    const dt: DateTime = DateTime.fromFormat(`${yyyyLLdd}`, "yyyyLLdd");
    return dt;
}
export const setEOM = (d)=>{
    let a: DateTime;
    if(Array.isArray(d) && d.length){
        a = fromStr(d[0]);
    }
    if(!Array.isArray(d)){
        a = fromStr(d);
    }
    if(!a.isValid){
        return d;
    }
    return a.startOf("month").endOf("month").toFormat("dd.LL.yyyy");
}
export const deadlineEoM = (): string =>{
    const to_day = DateTime.now().startOf("month");
    let the_date = fromStr(SessionStore.get(EParameters.DateParameter)).setToEoM();
    if(the_date.asDateDecimal() >= to_day.asDateDecimal()){
        the_date = DateTime.now().startOf("month").minus({month: 1}).setToEoM();
    }
    return the_date.toFormat("dd.LL.yyyy");
}
export const deadline = (eom?): DateTime =>{
    if(eom){
        const to_day = DateTime.now().startOf("month");
        let the_date = fromStr(SessionStore.get(EParameters.DateParameter)).setToEoM();
        if(the_date.asDateDecimal() >= to_day.asDateDecimal()){
            the_date = DateTime.now().startOf("month").minus({month: 1}).setToEoM();
        }
        return the_date;
    }else{
        return fromStr(SessionStore.get(EParameters.DateParameter));
    }

}
export const mk_duration = (from: number, till: number) => {
    return {
        start: Duration.fromISO(`P${from}Y`).shiftTo("months").months,
        end: Duration.fromISO(`P${till}Y`).shiftTo("months").months,
    };
};
