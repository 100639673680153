import {NonIdealState} from "@blueprintjs/core";
import {DateTime} from "luxon";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {DeDate} from "../../helpers/convert/DeDate";
import {EParameters} from "../../models/EParameters";
import {EValueName} from "../../models/EValueName";
import {IIdwData} from "../../models/IIdwData";
import {ISzSvgDataPoint} from "../../models/ISzSvgDataPoint";
import {fromStr} from "../../tools/DateTools";
import {ELineType, EXLabelAlign, ISzSvgProperties, SzSvg} from "../widgets/SzSvg";
import {BaseModule} from "./BaseModule";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {CoCMarketRisk, ICoCMarketRisk} from "./charts/CoCMarketRisk";
import {CoCMarketRiskDeu, ICoCMarketRiskDeu} from "./charts/CoCMarketRiskDeu";
import {CoCCountryRisk, ICoCCountryRisk} from "./charts/CoCCountryRisk";
import {ParameterOptions} from "../../const/ParameterOptions";

const min_year = 2011;
const getDataRow = (ar: any[]): any[] => {
    if (Array.isArray(ar) && ar.length && Array.isArray(ar[0])) {
        return ar[0];
    }
    return [];
};

export class MarketRiskPremium extends BaseModule<any> {

    private readonly svg: React.RefObject<SzSvg> = null;
    private yearsRange: string[] = [];
    private p_equity_risk_premium_val: number;

    constructor(props: any, context: any) {
        super(props, context);
        this.svg = React.createRef();
        this.state = {
            idwParameter: "net", // SessionStore.getGlobalVar(EValueName.idw_parameter, "net"),
            ch: 0,
        };
    }

    protected onAfterUpdate(data: any): void {
        this.data = data;
        this.setState({
            loading: false,
            ch: 0,
        });
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected renderContent() {
        // console.error("parameters", this.parameters);
        return (
            <div>
                {this.data ? this.renderLayout() : this.noData()}
            </div>
        );
    }
    protected renderLayout(){
        return (
            <div className={"sz-row"}>
                <div className={"sz-col-50"}>{this.renderData()}</div>
                <div className={"sz-col-50"}>{this.renderDataCr()}</div>
            </div>
        );
    }
    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    private getSubTitle(){
        const country_iso = SessionStore.get(EParameters.FixedCountriesParameter);
        const o = ParameterOptions.options[EParameters.FixedCountriesParameter].find((i) => i.value === country_iso);
        if(!o){
            return `#Error ${country_iso}`;
        }
        return o.label;
    }
    private renderDataCr() {
        const country_risk_premium = getDataRow(this.data.country_risk_premium);
        const coc_props: ICoCCountryRisk = {
            min_year,
            country_risk_premium,
            subTitle: this.getSubTitle(),
        };
        return (
            <CoCCountryRisk {...coc_props} />
        );
    }
    private renderData() {
        const countryId = this.parameters.FixedCountriesParameter[0];
        if (countryId === "DEU") {
            return this.renderDeu();
        }
        // markt
        const market_risk_premium = getDataRow(this.data.equity_risk_premium);
        const coc_props: ICoCMarketRisk = {
            min_year,
            market_risk_premium,
            subTitle: this.getSubTitle(),
        };
        return (
            <CoCMarketRisk {...coc_props} />
        );
    }
    private renderDeu() {
        const date = SessionStore.get(EParameters.DateParameter);
        const dt_year = fromStr(date).toFormat("yyyy");
        let equity_risk_premium_val = Globals.hyphen;
        let country_risk_premium_val = Globals.hyphen;
        const xy = (x, y, ds?) => ({x, y, dataSet: ds});

        const saveArray = (ar: any[]): any[] => {
            if (Array.isArray(ar) && ar.length && Array.isArray(ar[0])) {
                return ar[0];
            }
            return [];
        };
        // markt
        const equity_risk_premium = saveArray(this.data.equity_risk_premium);
        // länder
        const country_risk_premium = saveArray(this.data.country_risk_premium);

        const series_eq = [];
        const series_co = [];

        const i_dt_year = parseInt(dt_year, 10);
        const l = equity_risk_premium[equity_risk_premium.length - 1];
        equity_risk_premium.push(l);
        for (let i = 0; i < equity_risk_premium.length - 4; i++) {
            if (i_dt_year === (2011 + i)) {
                equity_risk_premium_val = "" + (equity_risk_premium[i + 4]);
                country_risk_premium_val = "" + (country_risk_premium[i + 4]);
            }
            series_eq.push(xy(i, 100 * equity_risk_premium[i + 4]));
        }

        series_eq.forEach( (e, idx) => e.x = idx );
        series_co.push(xy(series_co.length, 100 * country_risk_premium[country_risk_premium.length - 1]));

        const idwParameter = this.state.idwParameter;
        const useIdw: IIdwData[] = idwParameter === "net" ? this.data.idwNet : this.data.idwGross;
        const yearsMap = {};
        const yearsArray = [];
        const getYear = (year: string) => {
            if (!yearsMap[year]) {
                yearsArray.push(year);
                yearsMap[year] = {
                    damodaran: undefined,
                    kmpg: undefined,
                    idw : undefined,
                };
            }
            return yearsMap[year];
        };

        useIdw.forEach( (e) => {
            const year = fromStr(e.date,"yyyy-LL-dd").toFormat("yyyy");
            const yearObject = getYear(year);
            yearObject.idw = e;
        } );
        this.data.damodaran.forEach( ( e, idx) => {
            const year = fromStr(e.date,"yyyy-LL-dd").toFormat("yyyy");
            const yearObject = getYear(year);
            yearObject.damodaran = e.average;
        } );
        this.data.kmpg.forEach( ( e, idx) => {
            const year = fromStr(e.date,"yyyy-LL-dd").toFormat("yyyy");
            const yearObject = getYear(year);
            yearObject.kmpg = e.average;
        } );

        const series_kmpg = [];
        const series_damodaran = [];
        const series_idw = [];
        const xLabels: string[] = [];
        const xTicks: number[] = [];

        let last_defined_kmpg;
        let last_defined_damodaran;
        let last_defined_idw: IIdwData;
        yearsArray.shift();
        yearsArray.sort().forEach( (year, idx) => {
            const label = idx === 0 ? "" : year;
            const yearObject = getYear(year);


            if(idx % 2 !== 0){
                xLabels.push(year);
            }else{
                xLabels.push(undefined);
            }

            xTicks.push(idx);

            // first kmpg
            if ( idx === 0 && this.data.kmpg && this.data.kmpg.length) {
                const first_kmpg = this.data.kmpg[0].average;
                series_kmpg.push(xy(idx, first_kmpg ));
            }
            // kmpg date in the year
            series_kmpg.push(xy( (idx === yearsArray.length - 1 ? idx : idx + 0.5), yearObject.kmpg === undefined ? last_defined_kmpg : yearObject.kmpg));
            series_damodaran.push(xy(idx, yearObject.damodaran === undefined ? last_defined_damodaran : yearObject.damodaran));
            series_idw.push(xy(idx, yearObject.idw === undefined ? last_defined_idw.max : yearObject.idw.max, yearObject.idw === undefined ? last_defined_idw : yearObject.idw));

            if ( yearObject.kmpg !== undefined) {
                last_defined_kmpg = yearObject.kmpg;
            }
            if ( yearObject.damodaran !== undefined) {
                last_defined_damodaran = yearObject.damodaran;
            }
            if ( yearObject.idw !== undefined) {
                last_defined_idw = yearObject.idw;
            }
        });

        /*
        series_kmpg.push({
            x: 10,
            y: NaN,
        });
        */
        const series = [
            series_idw,
            // damodaran aus DB
            series_eq,
            series_kmpg,
        ];
        const props: ICoCMarketRiskDeu = {
            min_year,
            market_risk_premium: series_eq,
            kpmg: series_kmpg,
            idw_band_width: series_idw,
            subTitle: this.getSubTitle(),
        };
        return (
            <CoCMarketRiskDeu {...props} />
        );
    }
    private onChangeValue(field: string, _valueAsNumber: number, valueAsString: string) {
        const state = {
            ch: this.state.ch + 1,
        };
        state[field] = _valueAsNumber;
        this.setState(state);
    }
    private onResetInbput(field: string, _valueAsNumber: number) {
        const state = {
            ch: this.state.ch + 1,
        };
        state[field] = _valueAsNumber;
        this.setState(state);
    }
    private onCustomDrawings(x_min: number, x_max: number, y_min: number, y_max: number, xFactor: number, yFactor: number) {
        // console.error(this);
        const date = SessionStore.get(EParameters.DateParameter);
        const dt_year = fromStr(date).toFormat("yyyy");
        const idx = this.yearsRange.findIndex( (i) => i === dt_year );
        if (idx < 0) {
            return (<></>);
        }
        // p_equity_risk_premium_val
        const value = parseFloat(SessionStore.getGlobalVar(EValueName.market_risk_premium, this.p_equity_risk_premium_val)) * 100;
        const dayToYearRatio = DeDate.getPercentYTD(date);
        const x =  Math.trunc(xFactor * (idx + dayToYearRatio));
        const y = Math.trunc(yFactor * (value));
        const ym = Math.trunc(yFactor * y_max);
        // console.error(xFactor, y_min, y_max, x, y, value);
        return (
            <g>
                <defs>
                    <filter x="0" y="0" width="1" height="1" id="p_equity_risk_premium_val">
                        <feFlood floodColor="rgba(255, 255, 255, .65)"/>
                        <feComposite in="SourceGraphic" operator="over" />
                    </filter>
                </defs>
                <line x1={x} x2={x} y1={ym} y2={ym - y} key={`${this.state.ch}_${x}_${y}_${value}`} className={"sz-chart-line sz-chart-line-20"}/>
                <circle cy={ym - y} cx={x} r={3} className={`sz-chart-dot sz-chart-dot-20`} />
                <text  filter="url(#p_equity_risk_premium_val)" className={"sz-chart-line sz-chart-line-20"} x={x + 5} y={ym - y + 5} style={{fontSize: "80%"}}>{Globals.formatter_percent(value / 100)}</text>;
            </g>
        );
    }
    private onCustomRenderSeriesPath(index: number, path: string[], _series: ISzSvgDataPoint[], xFactor: number, yFactor: number, offsetH: number) {
        if (index > 0) {
            return path;
        }

        let lastYear: string = "";

        let x1;
        let y1;
        let x2;
        let y2;
        const newPath: string[] = [];
        const rects = [];
        const thisYear = DateTime.now().toFormat("yyyy");
        _series.forEach( (s, p) => {
            const e: IIdwData = s.dataSet;
            const year = DateTime.fromJSDate(e.date).toFormat("yyyy");
            if (year !== lastYear) {
                lastYear = year;
                const day = DateTime.fromJSDate(e.date).ordinal;
                if (x1 === undefined) {
                    x1 = s.x;
                    y1 = e.max;
                    y2 = e.min;
                } else {
                    x2 = s.x;
                    if (day - 1 > 0) {
                        x2 += day / 365;
                    }

                    if (year === thisYear) {
                        x2 = s.x;
                    }

                    const rx_1 = Math.trunc(x1 * xFactor);
                    const ry_1 = offsetH - Math.trunc(y1 * yFactor);
                    const rx_2 = Math.trunc(x2 * xFactor);
                    const ry_2 = offsetH - Math.trunc(y2 * yFactor);

                    rects.push({
                        x: rx_1,
                        y: ry_1,
                        h: ry_2 > ry_1 ? ry_2 - ry_1 : ry_1 - ry_2,
                        w: rx_2 > rx_1 ? rx_2 - rx_1 : rx_1 - rx_2,
                    });
                    x1 = x2;
                    y1 = e.max;
                    y2 = e.min;
                }

            }
        });
        return (
            <g>
                {rects.map((rect) => <rect x={rect.x} y={rect.y} width={rect.w} height={rect.h} className={`sz-chart-rect sz-chart-rect-custom-${index + 1}`} />)}
            </g>
        );
    }

    private noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }
}
