export enum EDataType {
    number,
    number_1,
    string,
    percent,
    h1,
    empty,
    select,
    select_range,
}
