import * as React from "react";
import {CSSProperties} from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {ISzSelectProperties} from "../../widgets/SzSelect";
import {ISzSliderProperties, SzSlider} from "../../widgets/SzSlider";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {SessionStore} from "../../../const/SessionStore";
import {no_star} from "../../../helpers/Helpers";
import {BetaFilterState} from "../../../helpers/BetaFilterState";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {PdfInsertElement} from "../../../pdf-tools/PdfInsertElement";

const label_style: CSSProperties={
    display: "flex",
    paddingTop: 10,
};

export class BetaFilterParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getPdfLabel() {
        const content = [];
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.BetaFilterBidAsk), EParameters.BetaFilterBidAsk);
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.BetaFilterFreeFloat), EParameters.BetaFilterFreeFloat);
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.BetaFilterR2), EParameters.BetaFilterR2);
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.BetaFilterTTest), EParameters.BetaFilterTTest);
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.BetaFilterBreuschPaganTest), EParameters.BetaFilterBreuschPaganTest);
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.BetaFilterDurbinWatsonTest), EParameters.BetaFilterDurbinWatsonTest);
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.BetaFilterDataPoints), EParameters.BetaFilterDataPoints);
        return {
            stack: content
        };
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.BetaFilterParameter];
    }

    protected getWidthClass(): string {
        return "fixed-width-500";
    }

    protected async initialize() {
        return this.initializeShort(EParameters.BetaFilterParameter);
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                <div style={{paddingTop: 10, paddingRight: 10}} className={"sz-row"}>
                    {this.renderSlider(EParameters.BetaFilterDataPoints)}
                    {this.renderSlider(EParameters.BetaFilterBidAsk)}
                    {this.renderSlider(EParameters.BetaFilterFreeFloat)}
                    {this.renderSlider(EParameters.BetaFilterR2)}
                    {this.renderSelect(EParameters.BetaFilterTTest)}
                    {this.renderSelect(EParameters.BetaFilterDurbinWatsonTest)}
                    {this.renderSelect(EParameters.BetaFilterBreuschPaganTest)}
                </div>
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.BetaFilterParameter))}
            </div>
        );
    }

    private orLabel(p: EParameters){
        const l = this.getOptionLabelHard(p, true);
        const l2 = this.getOptionLabelHard(p);
        if(l){
            return l;
        }else{
            return l2;
        }
    }
    protected getLabel(): any {
        const a = this.orLabel(EParameters.BetaFilterBidAsk);
        const b = this.orLabel(EParameters.BetaFilterFreeFloat);
        const c = this.orLabel(EParameters.BetaFilterR2);
        const g = this.orLabel(EParameters.BetaFilterDataPoints);
        const d = this.orLabel(EParameters.BetaFilterTTest);
        const e = this.orLabel(EParameters.BetaFilterDurbinWatsonTest);
        const f = this.orLabel(EParameters.BetaFilterBreuschPaganTest);
        return [g,a,b,c,d,e,f].join(" | ");
    }

    protected onClosePopup() {
        const ch = this.collectChanges(EParameters.BetaFilterParameter);
        BetaFilterState.save(ch);
        this.fireShortAsEvent(EParameters.BetaFilterParameter);
    }

    private onChangeValue(field: EParameters, _value: any) {
        const _valueAsNumber = _value;
        const state: {[k: string]: any} = {};
        state[field] = _valueAsNumber;
        this.setState(state);
        // this.fireValueChange(field, _valueAsNumber);
    }
    private getInputProps(parameter: EParameters, optionParam?: EParameters): ISzSelectProperties{
        return {
            options: optionParam ? ParameterOptions.options[optionParam] : ParameterOptions.options[parameter],
            value:  this.state[parameter],
            onValueChanged: (v: any) => this.onChangeValue(parameter, parseFloat(v)),
        };
    }
    private renderSelect(parameter: EParameters) {
        const props = this.getInputProps(parameter);
        const label = this.getParameterLabel(parameter);
        const state_field = parameter;
        return this.renderColumn("40%", label, ()=>{
            return this.renderOptionsExt(props.options, this.state[state_field], parameter, state_field);
        });
    }
    private renderSlider(parameter: EParameters) {
        const value = this.state[parameter];
        const options: IOptionEntry[] = ParameterOptions.options[parameter];
        const label = this.getParameterLabel(parameter);
        const inputProps: ISzSliderProperties = {
            value: options.findIndex((o: IOptionEntry)=> o.value === value),
            min: 0,
            max: options.length - 1,
            stepSize: 1,
            showTrackFill: false,
            labelRenderer: (v: number) => {
                const s: IOptionEntry = options[v];
                return s?.label;
            },
            onValueChanged: (v: number) => {
                const s: IOptionEntry = options[v];
                if(s){
                    this.onChangeValue(parameter, s.value);
                }
            }
        };
        return this.renderColumn("40%", label, ()=>{
            return (
                <div style={{paddingRight: 15, paddingLeft: 15, marginBottom: 10}}>
                    <SzSlider {...inputProps} />
                </div>

            );
        }, label_style);
    }
}
