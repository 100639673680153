import * as React from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, TimeScale, Title, Tooltip,} from 'chart.js';
import {SzChartOptions} from "../../../helpers/SzChartOptions";
import {CSSProperties} from "react";
import {Bar} from "react-chartjs-2";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {Globals} from "../../../const/Globals";
import {CompressedArrayItem} from "../../../models/chef/RawBeta";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
ChartJS.register(CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);
const styleH: CSSProperties = {
    width: "100%",
    color: "#182026",
    fontSize: 14,
    fontWeight: "bold",
    display: "block",
    paddingBottom: 5,
    marginTop: 14,
    boxShadow: "inset 0px -1px 0px 0px #cecece",
};
export interface IRegressionResidualsHistogramProps {
    company: ICompanyBase;
    data: any;
}

const fmt = (v)=>Globals.formatter(v, 0, 0, true);

export function RegressionResidualsHistogram(props: IRegressionResidualsHistogramProps) {
    const slots = 8;
    const company: ICompanyBase = props.company;
    const data = props.data;
    const o = new SzChartOptions();
    o.setXTickCallback(()=> "");
    o.setYTickCallback(()=> "");
    const options = {
        ...o.options,
    };
    options.scales.x.ticks.padding = 0;
    options.scales.y.border = {
        display: false,
        color: "rgba(0, 0, 0, 0.1)",
        dash: [2],
    };
    options.scales.y.grid.tickLength= 0;
    options.scales.y.alignToPixels = true;
    options.scales.x.alignToPixels = true;
    // options.scales.x.ticks.maxTicksLimit = slots;
    // options.scales.x.ticks.autoSkip = false;
    // options.scales.x.ticks.beginAtZero= true;
    options.plugins.tooltip = {
        enabled: true,
        mode: "point",
        intersect: true,
        backgroundColor: "rgba(255,255,255,0.75)",
        titleColor: "rgba(219,55,55,1)",
        bodyColor: "rgba(219,55,55,1)",
        borderColor: "rgba(82,82,82,0.75)",
        borderWidth: 1,
        caretSize: 0,
        cornerRadius: 2,
        callbacks: {
            label: (item)=> {return `Anzahl(${fmt(item?.raw)})`;},
            title: (item)=> { return undefined;},
        },
    };
    const bars = {
        label: "Regressionsresiduen",
        type: "bar",
        backgroundColor: "rgba(19,124,189,0.75)",
        hoverBackgroundColor: "rgba(219,55,55,0.75)",
        pointHoverBackgroundColor: "rgba(219,55,55,0.75)",
        data: (new Array(slots)).fill(0),
    };
    const chart_data = {
        labels: (new Array(slots)).fill(""),
        datasets: [
            bars,
        ],
    };
    const period = SessionStore.get(EParameters.PeriodsParameter);
    const raw_betas = data.raw_betas;
    const raw_beta = raw_betas[company.id];
    const a: CompressedArrayItem[] = raw_beta?.compressed_arrays[period];
    const regression = raw_beta?.periods[period];
    if(Array.isArray(a)){
        const fx = (x)=> regression.Intercept + regression.Slope * x;
        const y_data = [];
        let max = Number.MIN_VALUE;
        let min = Number.MAX_VALUE;
        a.forEach((p)=>{
            const b_dach = fx(p.a);
            const y = p.b - b_dach;

            if(y>max){
                max = y;
            }
            if(y<min){
                min = y;
            }
            y_data.push(y);
        });
        const w = (max - min) / (slots - 1);
        y_data.forEach((y)=>{
            const idx = Math.floor((y - min)/w);
            bars.data[idx]++;
        });
    }
    // console.error(chart_data);
    return (
        <div>
            <div style={styleH}>{_t(ETranslation.distribution_residuals)}</div>
            <div style={{aspectRatio: "1/1"}}>
                <Bar data={chart_data as any} options={options} />
            </div>
            <div className={"sz-hint"}>{_t(ETranslation.visualization_normal_distribution)}</div>
        </div>
    );
}
