import {Icon, Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_OPTION} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {EValueName} from "../../../models/EValueName";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";
import {IconNames} from "@blueprintjs/icons";

export class FixedCountriesParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getWidthClass(): string {
        return "fixed-width-700";
    }
    protected getLabel() {
        const selectedValue = this.state.selectedValue;
        const option = this.state.options.find((item) => item.value === selectedValue);
        return option ? option.value : Globals.hyphen;
    }

    protected onValueChanged(v) {
        SessionStore.setGlobalVar(EValueName.country_iso_3, v);
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.FixedCountriesParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.FixedCountriesParameter);
    }
    protected async getOptions() {
        return PARAMETER_OPTION(EParameters.FixedCountriesParameter);
    }

    protected getMenu() {
        const otherCountriesOptions: IOptionEntry[] = [
            {label: "Polen (PLN)", value: "POL", selected: false},
            {label: "Tschechien (CZK)", value: "CZE", selected: false},

            {label: "Ungarn (HUF)", value: "HUN", selected: false}, // HU
            {label: "Dänemark (DKK)", value: "DNK", selected: false}, // DK
            {label: "Schweden (SEK)", value: "SWE", selected: false}, // SE
            {label: "Norwegen (NOK)", value: "NOR", selected: false}, // NO
            {label: "Türkei (TRY)", value: "TUR", selected: false}, // TR
            {label: "Kroatien (HRK)", value: "HRV", selected: false}, // HR
            {label: "Serbien (RSD)", value: "SRB", selected: false}, // RS
            {label: "Rumänien (RON)", value: "ROU", selected: false}, // RO
            {label: "Bulgarien (BGN)", value: "BGR", selected: false}, // BG
            {label: "Russland (RUB)", value: "RUS", selected: false}, // RU
            {label: "Südkorea (KRW)", value: "KOR", selected: false},
            {label: "Brasilien (BRL)", value: "BRA", selected: false},
            {label: "Indien (INR)", value: "IND", selected: false},
            {label: "Mexiko (MXN)", value: "MEX", selected: false},
        ];
        otherCountriesOptions.sort( (a, b) => a.label.localeCompare(b.label) );
        const euroCountriesOptions: IOptionEntry[] = [
            {label: "Deutschland (EUR)", value: "DEU", selected: false},
            {label: "Österreich (EUR)", value: "AUT", selected: false},
            {label: "Schweiz (CHF)", value: "CHE", selected: false},
            {label: "---", value: "", selected: false},
            {label: "USA (USD)", value: "USA", selected: false},
            {label: "Vereinigtes Königreich (GBP)", value: "GBR", selected: false},
            {label: "Japan (JPY)", value: "JPN", selected: false},
            {label: "Volksrepublik China (CNY)", value: "CHN", selected: false},
            {label: "Kanada (CAD)", value: "CAN", selected: false},
        ];
        const euroCountriesOptions_2: IOptionEntry[] = [
            {label: "Belgien (EUR)", value: "BEL", selected: false},
            {label: "Zypern (EUR)", value: "CYP", selected: false},
            {label: "Estland (EUR)", value: "EST", selected: false},
            {label: "Finnland (EUR)", value: "FIN", selected: false},
            {label: "Frankreich (EUR)", value: "FRA", selected: false},
            {label: "Griechenland (EUR)", value: "GRC", selected: false},
            {label: "Irland (EUR)", value: "IRL", selected: false},
            {label: "Italien (EUR)", value: "ITA", selected: false},
            {label: "Lettland (EUR)", value: "LVA", selected: false},
            {label: "Litauen (EUR)", value: "LTU", selected: false},
            {label: "Luxemburg (EUR)", value: "LUX", selected: false},
            {label: "Malta (EUR)", value: "MLT", selected: false},
            {label: "Niederlande (EUR)", value: "NLD", selected: false},
            {label: "Portugal (EUR)", value: "PRT", selected: false},
            {label: "Slowakei (EUR)", value: "SVK", selected: false},
            {label: "Slowenien (EUR)", value: "SVN", selected: false},
            {label: "Spanien (EUR)", value: "ESP", selected: false},
        ];
        euroCountriesOptions_2.sort( (a, b) => a.label.localeCompare(b.label) );

        let cKey = 0;
        const disabled = this.disabled;
        if (disabled) {
            return this.renderNoOp();
        }
        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return <Icon icon={IconNames.BLANK}/>;
            }
        };
        return (
            <div>
                <div className={"sz-row"}>
                    <div style={{width: "34%"}}>
                        <Menu>
                            {
                                euroCountriesOptions.map((item, index) => {
                                    if (item.label === "---") {
                                        return (<MenuDivider />);
                                    }
                                    return(<MenuItem disabled={disabled} key={"selection" + (cKey++)} intent={ item.value === this.state.selectedValue ? "primary" : "none"} labelElement={getIcon(item.value === this.state.selectedValue)}  text={item.label} onClick={() => { this.setOption(item.value); }} />);
                                })
                            }
                        </Menu>
                    </div>
                    <div style={{width: "33%"}}>
                        <Menu>
                            {
                                euroCountriesOptions_2.map((item, index) => {
                                    return(<MenuItem disabled={disabled} key={"selection" + (cKey++)} intent={ item.value === this.state.selectedValue ? "primary" : "none"} labelElement={getIcon(item.value === this.state.selectedValue)}  text={item.label} onClick={() => { this.setOption(item.value); }} />);
                                })
                            }
                        </Menu>
                    </div>
                    <div style={{width: "33%"}}>
                        <Menu>
                            {
                                otherCountriesOptions.map((item, index) => {
                                    return(<MenuItem disabled={disabled} key={"selection" + (cKey++)} intent={ item.value === this.state.selectedValue ? "primary" : "none"} labelElement={getIcon(item.value === this.state.selectedValue)}  text={item.label} onClick={() => { this.setOption(item.value); }} />);
                                })
                            }
                        </Menu>
                    </div>
                </div>
                {this.renderHint()}
            </div>
        );
    }
}
