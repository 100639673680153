import {IIdwData} from "../models/IIdwData";
const lastDateStr = `2024-01-01`;
export const IDW_NET: IIdwData[] = [
    {
        date: new Date("2010-01-01"),
        min: 4.5,
        max: 5.5,
    },
    {
        date: new Date("2011-01-01"),
        min: 4.5,
        max: 5.5,
    },
    {
        date: new Date("2012-09-19"),
        min: 5.5,
        max: 7,
    },
    {
        date: new Date("2019-10-25"),
        min: 6,
        max: 8,
    },
    {
        date: new Date("2020-01-01"),
        min: 6,
        max: 8,
    },
    {
        date: new Date("2021-01-01"),
        min: 6,
        max: 8,
    },
    {
        date: new Date("2022-01-01"),
        min: 6,
        max: 8,
    },
    {
        date: new Date("2023-01-01"),
        min: 6,
        max: 8,
    },
    {
        date: new Date(lastDateStr),
        min: 6,
        max: 8,
    },
];
export const IDW_GROSS: IIdwData[] = [
    {
        date: new Date("2010-01-01"),
        min: 4,
        max: 5,
    },
    {
        date: new Date("2011-01-01"),
        min: 4,
        max: 5,
    },
    {
        date: new Date("2012-09-19"),
        min: 5,
        max: 6,
    },
    {
        date: new Date("2019-10-25"),
        min: 5,
        max: 6.5,
    },
    {
        date: new Date("2020-01-01"),
        min: 6,
        max: 8,
    },
    {
        date: new Date("2021-01-01"),
        min: 5,
        max: 6.5,
    },
    {
        date: new Date("2022-01-01"),
        min: 5,
        max: 6.5,
    },
    {
        date: new Date("2023-01-01"),
        min: 5,
        max: 6.5,
    },
    {
        date: new Date(lastDateStr),
        min: 5,
        max: 6.5,
    },
];
