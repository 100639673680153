import {EDocScope} from "../const/EDocScope";
import {ELangCodes} from "../const/ELangCodes";
import {ECompanyRelation} from "../models/ECompanyRelation";
import {IBondTimeSeries} from "../models/IBondTimeSeries";
import {ICountryExchangeRate, ILatestFinancials, IRelatedCompany, IRelatedFinancials} from "../models/ICompanyDetails";
import {ICompanySearchResult} from "../models/ICompanySearchResult";
import {IBondDetails} from "../models/IBondDetails";
import {ISearchCompanyParameters} from "../models/ISearchCompanyParameters";
import {ISic3Count} from "../models/Sic";
import {EServiceType, ServiceCallBase} from "./ServiceCallBase";
export class PublicDbCalls extends ServiceCallBase {
    constructor() {
        super(EServiceType.Public_Data);
    }
    public async countUserDetails() {
        return this.callCommand("count_user_details", null);
    }
    public async getCompanies(companies) {
        const parameters = {
            companies
        };
        return this.callCommand("getCompanies", parameters);
    }
    public async filterCompanies(sectors: any[], region_de: any[]) {
        const parameters = {
            sectors,
            region_de,
        };
        return this.callCommand("filterCompanies", parameters);
    }
    public async getRawMultiplierForCompanies(parameters: any[]) {
        return this.makeCall("raw_multiplier_for_companies", parameters);
    }
    public async getMultiplierForCompaniesV2(parameters: any[]) {
        return this.makeCall("multiplier_for_companies_v2", parameters, true);
    }
    public async getFinancialsAnnReported(parameters: any[]) {
        return this.makeCall("financials_ann_reported", parameters);
    }
    public async getMarket_Data(parameters: any[]) {
        return this.makeCall("market_data", parameters);
    }
    public async getRating_Metrics(parameters: any[]) {
        return this.makeCall("rating_metrics", parameters, true);
    }
    public async getInterestRatesBubaAll(parameters: any[]) {
        return this.makeCall("interest_rates_buba_all", parameters);
    }

    public async getEquityRiskPremium(parameters: any[]) {
        return this.makeCall("equity_risk_premium", parameters);
    }
    public async getCountryRiskPremium(parameters: any[]) {
        return this.makeCall("country_risk_premium", parameters);
    }
    public async getCountryRiskPremiumLrp(parameters: any[]) {
        return this.makeCall("country_risk_premium_lrp", parameters);
    }
    public async getImfData(parameters: any[], asObject?: boolean) {
        return this.makeCall("imf_data", parameters, asObject);
    }
    public async getInterestCurves(parameters: any[]) {
        return this.makeCall("interest_curves", parameters, true);
    }
    public async getCountries() {
        return this.makeCall("countries", []);
    }
    public async getAllCountries() {
        return this.makeCall("all_countries", [], true);
    }
    public async getSectors(countries: any[] = []) {
        return this.makeCall("sector", [countries]);
    }
    public async getSubSectors(selectedSector: string, countries: any[] = []) {
        return this.makeCall("subSector", [ [selectedSector], countries]);
    }
    public async getAllSectors() {
        return this.makeCall("allSectors", [ ]);
    }
    public async getCompanyDetailsXs(company_id: number, year: number) {
        return this.makeCall("company-details-xs", [[company_id], [year]]);
    }
    public async getCompanyDetailsExt(company_id: number, year: number) {
        return this.makeCall("company-details-2", [[company_id], [year]], true);
    }
    public async getCompanyDetails2(company_id: number) {
        return this.makeCall("company_details", [[company_id]]);
    }
    public async getCorporateSpreadV2(parameters: any[]) {
        return this.makeCall("corporate_spread_v2", parameters, true);
    }
    public async getCorporateSpreadV3(parameters: any[]) {
        return this.makeCall("corporate_spread_v3", parameters, true);
    }
    public async getCountryTaxRates(countries: any[] = [], asObject?: boolean) {
        return this.makeCall("country_tax_rates", [countries], asObject);
    }
    public async getCurrencyRate(parameters: any[]) {
        return this.makeCall("country_xch_rate", parameters, true);
    }
    public async getCurrencyRate2(parameters: any[]) {
        return this.makeCall("country_xch_rate_2", parameters, true);
    }
    public async selectCompanies(company_ids: any[]) {
        return this.makeCall("select-companies", [company_ids], true);
    }
    public async getNaics(naics): Promise<any[]> {
        const ret = await this.callCommand("getNaics", {naics});
        if(Array.isArray(ret)){
            return ret;
        }
        return [];
    }
    public async getRelatedCompanies(company_id, rang: number, relation: ECompanyRelation): Promise<IRelatedCompany[]> {
        const ret = await this.callCommand("getRelatedCompanies", {company_id, rang, relation});
        if(Array.isArray(ret)){
            return ret;
        }
        return [];
    }
    public async getRelatedCompany(company_id): Promise<IRelatedCompany> {
        const ret = await this.callCommand("getRelatedCompany", {company_id});
        if(Array.isArray(ret)){
            return ret[0];
        }
        return {} as IRelatedCompany;
    }
    public async getRelatedFinancials(company_ids): Promise<IRelatedFinancials[]> {
        const ret = await this.callCommand("getRelatedFinancials", {company_ids});
        if(Array.isArray(ret)){
            return ret;
        }
        return [];
    }
    public async getLatestFinancials(company_ids): Promise<IRelatedFinancials[]> {
        const ret = await this.callCommand("getRelatedFinancials", {company_ids});
        if(Array.isArray(ret)){
            return ret;
        }
        return [];
    }
    public async countSic3(): Promise<ISic3Count[]> {
        const ret = await this.callCommand("countSic3", {});
        if(Array.isArray(ret)){
            return ret;
        }
        return [];
    }

    public async searchCompany(parameters: ISearchCompanyParameters) {
        return this.callCommand("searchCompany2", parameters);
    }
    public async getCompany(company_id: any): Promise<ICompanySearchResult> {
        const parameters = {
            company_id,
        };
        const result = await  this.callCommand("getCompany", parameters);
        const r: ICompanySearchResult[] = result.result ? result.result : [];
        return  r[0];
    }
    public async getPublicSearch() {
        return this.callCommand("public_search", {});
    }

    public async reportError(error: any) {
        return this.callCommand("reportError", error);
    }
    public async getDocumentation(code: ELangCodes, scope: EDocScope, ref_id?: number) {
        return this.callCommand("documentation", {
            code,
            scope,
            ref_id,
        });
    }
    public async getTpLonglist(params) {
        return this.callCommand("tp_longlist", params);
    }
    public async getTpFinancials(params) {
        return this.callCommand("tp_financials", params);
    }
    public async pushUserDetails(params) {
        return this.callCommand("push_user_details", params);
    }
    public async getTags() {
        return this.callWordPress("tags", null);
    }
    public async getCreditSpreadIndication( qualifying_date: number, currency_iso_3: string, sectors: number[], duration: any, seniority: string, pledge_status: string, region_de: string, enhancement: string, callable: string): Promise<IBondDetails[]> {
        const parameters = {
            qualifying_date,
            currency_iso_3,
            sectors,
            duration,
            seniority,
            pledge_status,
            region_de,
            enhancement,
            callable,
        };
        const ret = await this.callCommand("getCreditSpreadIndication", parameters);
        if(Array.isArray(ret)){
            return ret;
        }
        return [];
    }

    public async getBondTimeSeries(rating: string[], qualifying_date: number, currency_iso_3: string, sector: string): Promise<IBondTimeSeries[]> {
        const parameters = {
            rating,
            qualifying_date,
            currency_iso_3,
            sector
        };
        const ret: IBondTimeSeries[] = await this.callCommand("getBondTimeSeries", parameters);
        if(Array.isArray(ret)){
            ret.forEach( (r)=> {
                r.mat_date = parseInt(r.mat_date, 10);
                r.iss_date = parseInt(r.iss_date, 10);
            });
            return ret;
        }
        return [];
    }
    public async getCompaniesByTicker(ticker: string[]): Promise<any[]> {
        const parameters = {
            ticker,
        };
        const ret: any[] = await this.callCommand("getCompaniesByTicker", parameters);
        if(Array.isArray(ret)){
            return ret.map((i)=> i.id);
        }
        return [];
    }
    public async getCountryExchangeRate(qualifying_date): Promise<ICountryExchangeRate[]> {
        const parameters = {
            qualifying_date,
        };
        const ret: ICountryExchangeRate[] = await this.callCommand("getCountryExchangeRate", parameters);
        if(Array.isArray(ret)){
            return ret;
        }
        return [];
    }
    private async callCommand(command: string, parameters: any) {
        const data = {command, ...parameters};
        return super.callOut("call", data);
    }
    private async callWordPress(command: string, parameters: any) {
        const data = {command, ...parameters};
        return super.callOut("wordpress", data);
    }
    private async makeCall(target: string, parameters: any, asObject: boolean = false) {
        const data = {
            parameters,
            sourceTable: target,
            asObject,
        };
        return super.callOut("call", data);
    }
}
