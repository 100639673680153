import {HTMLInputProps, Slider, SliderProps} from "@blueprintjs/core";
import * as React from "react";

export interface ISzSliderProperties {
    value: number;
    defaultValue?: number;
    disabled?: boolean;
    max?: number;
    min?: number;
    stepSize?: number;
    stepSizeLabel?: number;
    labelValues?: number[];
    showTrackFill?: boolean;
    labelRenderer?(value: number);
    onValueChanged?(value: number);
}
export interface ISzSliderState {
    value: number;
}

export class SzSlider extends React.Component<HTMLInputProps & ISzSliderProperties, ISzSliderState> {
    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            value: this.props.value,
        };
    }
    public render() {
        const value = this.state.value;
        const inputProps: SliderProps = {
            value,
            disabled: this.props.disabled,
            showTrackFill: this.props.showTrackFill,
            stepSize: this.props.stepSize,
            labelStepSize: this.props.stepSizeLabel,
            labelValues: this.props.labelValues,
            min: this.props.min,
            max: this.props.max,
            onChange: (v: number) => this.onChangeValue(v),
            onRelease: (v: number) => this.onRelease(v),
            labelRenderer: this.props.labelRenderer ? this.props.labelRenderer : true,
        };
        const onKeyUp = (charCode: number, keyCode: number) => {
            // if (this.state.isOpen && keyCode === 13) {
                //
            // }
        };
        return (
            <Slider {...inputProps} />
        );
    }
    private onRelease(value: number) {
        this.setState({
            value,
        });
        this.props?.onValueChanged(value);
    }
    private onChangeValue(value: number) {
        this.setState({
            value,
        });
    }
}
