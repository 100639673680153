import {CSSProperties} from "react";
import {Spinner} from "@blueprintjs/core";
import * as React from "react";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
export function SzSmallLoader(props: {title: string; sub_title?: string}) {
    const div_style: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        height: 175,
    };
    return (
        <div style={div_style}>
            <h4>{props.title}</h4>
            <Spinner size={50} intent={"primary"} />
            <p className={"bp3-text-muted"} style={{fontSize: "80%"}}>{props.sub_title ? props.sub_title : _t(ETranslation.time_series_update)}</p>
        </div>
    );
}
