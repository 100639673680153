import {HTMLInputProps, HTMLSelect, HTMLSelectProps} from "@blueprintjs/core";
import * as React from "react";
import {IOptionEntry} from "../../models/IOptionEntry";

export interface ISzSelectProperties {
    value: any;
    options: IOptionEntry[];
    onValueChanged?(value: any);
}
export interface ISzSelectState {
    value: any;
}

export class SzSelect extends React.Component<HTMLInputProps & ISzSelectProperties, ISzSelectState> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            value: this.props.value,
        };
    }
    public render() {
        const value =  this.state.value;
        const options =  this.props.options;
        const inputProps: HTMLSelectProps = {
            value,
            options,
            fill: true,
            onChange: (event) => this.onChangeValue(event.currentTarget.value),
        };
        return (
            <HTMLSelect {...inputProps} />
        );
    }
    private onChangeValue(value: string) {
        this.setState({
            value,
        });
        this.props?.onValueChanged(value);
    }
}
