import {Icon, Menu, MenuItem,} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {PARAMETER_OPTION} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {IconNames} from "@blueprintjs/icons";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {PdfInsertElement} from "../../../pdf-tools/PdfInsertElement";
import {_k} from "../../../helpers/Helpers";

const gearingOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.GearingPeersParameter);
const negativeVerschuldungOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.NegativeVerschuldungParameter);
const mahalanobicDistanceOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.MahalanobicDistanceParameter);

const label_column: CSSProperties = {
    verticalAlign: "middle",
    boxShadow: "none",
};
const input_column: CSSProperties = {
    boxShadow: "none",
    width: 400,
};

export class GearingCombiParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getPdfLabel() {
        const content = [];
        PdfInsertElement.selected_options(content, ParameterConfigs.label(EParameters.GearingPeersParameter), EParameters.GearingPeersParameter);
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.NegativeVerschuldungParameter), EParameters.NegativeVerschuldungParameter);
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.MahalanobicDistanceParameter), EParameters.MahalanobicDistanceParameter);
        return {
            stack: content
        };
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.GearingCombiParameter];
    }

    protected async initialize() {
        let gearingPeersParameter = SessionStore.get(EParameters.GearingPeersParameter);
        if(!gearingPeersParameter.ff_debt_xlease){
            gearingPeersParameter = {...gearingPeersParameter, ff_debt_xlease: true};
        }
        return {
            selectedGearing: gearingPeersParameter,
            selectedNegativeGearing: SessionStore.get(EParameters.NegativeVerschuldungParameter),
            selectedMahalanobicDistance: SessionStore.get(EParameters.MahalanobicDistanceParameter),
        };
    }
    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                {this.renderGearing()}
                {this.renderMahalanobic()}
                {this.renderNegativeGearing()}
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.GearingCombiParameter))}
            </div>
        );
    }
    protected getWidthClass() {
        return "fixed-width-650";
    }
    protected getLabel(): any {
        const selNegativeGearing = negativeVerschuldungOptions.find((e) => e.value === this.state.selectedNegativeGearing);
        const selMahalanobicDistance = mahalanobicDistanceOptions.find((e) => e.value === this.state.selectedMahalanobicDistance);
        this.putPacketOptionParameter(EParameters.MahalanobicDistanceParameter, [
            {o: mahalanobicDistanceOptions, s: this.state.selectedMahalanobicDistance}
        ]);
        this.putPacketOptionParameter(EParameters.NegativeVerschuldungParameter, [
            {o: negativeVerschuldungOptions, s: this.state.selectedNegativeGearing}
        ]);
        return `${selMahalanobicDistance.label} | ${selNegativeGearing.label}`;
    }
    protected getLabelExt(): any {
        const selGearing = [];
        const selectedGearing = this.state.selectedGearing;
        const vaus = [1,2,4,8,16,1,2,4,8,16];
        const idx_a = 0;
        const idx_b = 0;
        const index_map = [idx_a,idx_a,idx_a,idx_a,idx_a, idx_b, idx_b, idx_b, idx_b, idx_b];
        gearingOptions.forEach((o, idx)=> {
            if(selectedGearing[o.value]){
                selGearing.push(o.label);
                const v = vaus[idx];
                if(v){
                    index_map[idx] += v;
                }
            }
        });
        this.putPacketParameter(EParameters.GearingPeersParameter, [idx_a, idx_b]);
        return `${this.getLabel()} | ${_t(ETranslation.gearing_definition)}: ${selGearing.join(" | ")}`;
    }
    private renderGearing() {
        // {this.renderOptions(gearingOptions, this.state.selectedGearing, EParameters.GearingPeersParameter, "selectedGearing")}

        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return <Icon icon={IconNames.BLANK}/>;
            }
        };
        let cKey = 0;
        const setField = (o: IOptionEntry) => {
            if (o.fixed) {
                return;
            }
            const selectedGearing = this.state.selectedGearing;
            selectedGearing[o.value] = !selectedGearing[o.value];
            this.setState(selectedGearing);
            this.fireValueChange(EParameters.GearingPeersParameter, selectedGearing);
        };
        const mapEntry = (o: IOptionEntry, idx: number) => {
            if (o.label === "---") {
                return (<li key={_k("gcp", idx)}>&nbsp;</li>);
            }
            const isSelected = this.state.selectedGearing[o.value];
            return (<MenuItem style={{paddingLeft: 10}} key={"GearingPeersParameter" + (cKey++)} intent={isSelected ? "primary" : "none"} labelElement={getIcon(isSelected)}  text={o.label} className={ isSelected ? "sz-option-selected" : undefined} onClick={() => { setField(o); }} />);
        };
        return this.renderColumn("40%", _t(ETranslation.gearing_definition), ()=>{
            return (
                <Menu>
                    {gearingOptions.map( (o, idx) => {
                        return mapEntry(o, idx);
                    } )}
                </Menu>
            );
        });
    }
    private renderNegativeGearing() {
        return this.renderColumn("40%", _t(ETranslation.negative_net_debt), ()=>{
            return this.renderOptionsExt(negativeVerschuldungOptions, this.state.selectedNegativeGearing, EParameters.NegativeVerschuldungParameter, "selectedNegativeGearing");
        });
    }
    private renderMahalanobic() {
        return this.renderColumn("40%", _t(ETranslation.time_period_point), ()=>{
            return this.renderOptionsExt(mahalanobicDistanceOptions, this.state.selectedMahalanobicDistance, EParameters.MahalanobicDistanceParameter, "selectedMahalanobicDistance");
        });
    }
}
