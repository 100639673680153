import {Button, HTMLSelect, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {ChangeEvent} from "react";
import {Globals} from "../../const/Globals";
import {BaseModule} from "./BaseModule";
import {ICompanyBase} from "../../tables/CompanyBase";
import {InfoTable} from "./analysis_tool/InfoTable";
import {RegressionChart} from "./analysis_tool/RegressionChart";
import {RegressionResidualsChart} from "./analysis_tool/RegressionResidualsChart";
import {RegressionResidualsHistogram} from "./analysis_tool/RegressionResidualsHistogram";
import {CrossSectionalAnalysis} from "./analysis_tool/CrossSectionalAnalysis";
import {TimeSeries} from "./analysis_tool/TimeSeries";
import {ResidualsChart} from "./analysis_tool/ResidualsChart";
import {TimeStability5Days} from "./analysis_tool/TimeStability5Days";
import {removeCompany} from "./company_info/TableHelper";
import {BetaFilterState} from "../../helpers/BetaFilterState";
import {PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {EBorder, PdfTable} from "../../pdf-tools/PdfTable";
import {EPdfColumnDataType} from "../../pdf-tools/PdfTableColumn";
import {Sectors} from "../../const/Sectors";
import {BondCompanies, BondCurrencyPerYear, BondRankedFieldPerYear, BondValid} from "../../models/chef/Bonds";
import {ChefCalls} from "../../services/ChefCalls";
import {DateDecimalCount} from "./charts/DateDecimalCount";
import {BsCurrenciesPerYear} from "./charts/BsCurrenciesPerYear";
import {BsRankedFieldPerYear} from "./charts/BsRankedFieldPerYear";
import {BsMedianSpreadPerYear} from "./charts/BsMedianSpreadPerYear";


const cl = {
    0: "unkündbar",
    1: "kündbar",
};
const getNullLabel = (l)=> {
    if(!l){
        return "unbekannt";
    }
    return l;
};
const getCallableLabel = (l)=> {
    const a = cl[l];
    return a;
};

const ranked_fields = {
    fi_issue_details_pledge_status: "fi_issue_details_pledge_status",
    fi_issue_details_seniority: "fi_issue_details_seniority",
    fi_redemption_flg_call: "fi_redemption_flg_call",
    fi_enhance_a_enh_type: "fi_enhance_a_enh_type",
};

export class BondStats extends BaseModule<any> {
    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
            loading: true,
        };
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;
        (async ()=>{
            let bonds_valid: BondValid[] = [];
            let bond_companies: BondCompanies[] = [];
            let bond_currencies: BondCurrencyPerYear[] = [];
            let fi_issue_details_pledge_status: BondRankedFieldPerYear[] = [];
            let fi_issue_details_seniority: BondRankedFieldPerYear[] = [];
            let fi_redemption_flg_call: BondRankedFieldPerYear[] = [];
            let fi_enhance_a_enh_type: BondRankedFieldPerYear[] = [];

            try {
                const db = new ChefCalls();
                bonds_valid = await db.getBondsMonthlyValid();
                bond_companies = await db.getBondsCompaniesCount();
                bond_currencies = await db.getBondsCurrenciesPerYear();

                fi_issue_details_pledge_status = await db.getBondsGroupedPerYear(ranked_fields.fi_issue_details_pledge_status);
                fi_issue_details_seniority = await db.getBondsGroupedPerYear(ranked_fields.fi_issue_details_seniority);
                fi_redemption_flg_call = await db.getBondsGroupedPerYear(ranked_fields.fi_redemption_flg_call);
                fi_enhance_a_enh_type = await db.getBondsGroupedPerYear(ranked_fields.fi_enhance_a_enh_type);
            }finally {
                this.setState({
                    loading: false,
                    bonds_valid,
                    bond_companies,
                    bond_currencies,
                    fi_issue_details_pledge_status,
                    fi_issue_details_seniority,
                    fi_redemption_flg_call,
                    fi_enhance_a_enh_type,
                });
            }
        })();
    }
    private renderRankedField(field: string, c?){
        return (
            <BsRankedFieldPerYear data={this.state[field]} getLabel={c} />
        );
    }
    protected renderContent() {
        return (
            <div>
                <div className={"sz-row"}>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <div><strong>#Anleihen im Zeitablauf</strong></div>
                        </div>
                        <div style={{width: "100%", height: 200}}>
                            <DateDecimalCount time_series={this.state.bonds_valid} label={"Anleihen"}/>
                        </div>
                    </div>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <div><strong>#Unternehmen mit Anleihen im Zeitablauf</strong></div>
                        </div>
                        <div style={{width: "100%", height: 200}}>
                            <DateDecimalCount time_series={this.state.bond_companies} label={"Firmen"} />
                        </div>
                    </div>
                </div>
                <div className={"sz-row"} style={{marginTop: 30}}>
                    <div className={"sz-col"} style={{width: "99%"}}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <div><strong>#Währungen der Anleihen im Zeitablauf</strong></div>
                        </div>
                        <div style={{width: "100%", height: 200}}>
                            <BsCurrenciesPerYear data={this.state.bond_currencies} />
                        </div>
                    </div>
                </div>
                <div className={"sz-row"} style={{marginTop: 30}}>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <div><strong>#Seniorität pro Jahr</strong></div>
                        </div>
                        <div style={{width: "100%", height: 200}}>
                            {this.renderRankedField(ranked_fields.fi_issue_details_seniority, getNullLabel)}
                        </div>
                    </div>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <div><strong>#Pfandrecht pro Jahr</strong></div>
                            <div>&nbsp;</div>
                        </div>
                        <div style={{width: "100%", height: 200}}>
                            {this.renderRankedField(ranked_fields.fi_issue_details_pledge_status, getNullLabel)}
                        </div>
                    </div>
                </div>
                <div className={"sz-row"} style={{marginTop: 30}}>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <div><strong>#Garantien pro Jahr</strong></div>
                            <div>&nbsp;</div>
                        </div>
                        <div style={{width: "100%", height: 200}}>
                            {this.renderRankedField(ranked_fields.fi_enhance_a_enh_type, getNullLabel )}
                        </div>
                    </div>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <div><strong>#Sondertilgung pro Jahr</strong></div>
                            <div>&nbsp;</div>
                        </div>
                        <div style={{width: "100%", height: 200}}>
                            {this.renderRankedField(ranked_fields.fi_redemption_flg_call, getCallableLabel )}
                        </div>
                    </div>
                </div>

                <div className={"sz-row"} style={{marginTop: 30}}>
                    <div className={"sz-col"} style={{width: "99%"}}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <div><strong>#Anleihen Senior vs. Other</strong></div>
                        </div>
                        <div style={{width: "100%", height: 200}}>
                            <BsMedianSpreadPerYear />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    protected renderLoading() {
        return this.renderLoadingDefault();
    }
}
