import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";
import {DateTime} from "luxon";

export class TpYearsParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.TpYearsParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.TpYearsParameter);
    }
    protected async getOptions() {
        const options: IOptionEntry[] = [];
        const start = parseInt(DateTime.now().toFormat("yyyy"), 10) - 1;
        const stop = 2010;
        for(let i = start; i>=stop; i--){
            options.push({label: `${i}`, value: `${i}`});
        }
        return options;
    }
    protected getWidthClass() {
        return "fixed-width-400";
    }
}
