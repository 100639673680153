import {Button, Classes, Dialog, Icon, Intent, NonIdealState, Switch, Tab, Tabs, TextArea} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {TdHTMLAttributes, ThHTMLAttributes} from "react";
import {Globals} from "../../const/Globals";
import {Sectors} from "../../const/Sectors";
import {Sic, Sic3, SicClasses, SicRanges} from "../../const/Sic";
import {average} from "../../helpers/Statistics";
import {EParameters} from "../../models/EParameters";
import {CompanyFinancials} from "../widgets/CompanyFinancials";
import {CompanyInformation} from "../widgets/CompanyInformation";
import {CompanyRelations} from "../widgets/CompanyRelations";
import {BaseModule} from "./BaseModule";
import {ICompany} from "../../models/ICompanyDetails";
import {EventBus} from "../../services/EventBus";
import {ICountry} from "../../models/ICountry";
import {SessionStore} from "../../const/SessionStore";
import {ISzTableColumn, ISzTableProperties} from "../widgets/SzTable";
import {EModuleNames} from "../../models/EModuleNames";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

let rejected = {};
let rejected_absolut = {};

let raw_data = [];
let row_data = [];

const _f = (v) => {
    const ff = parseFloat(v);
    if (isNaN(ff) || !v) {
        return 0;
    }
    return ff;
};
const getPlainField = (desc, company: ICompany) => {
    row_data.push(company[desc.field]);
    return company[desc.field];
};
const getField = (desc, company: ICompany, onClickHandler?) => {
    let v = company[desc.field];
    if(desc.alt_field && !v){
        v=company[desc.alt_field];
    }
    row_data.push(v);
    if(onClickHandler){
        return (
            <div className={"td-wrapper"}><span className={"td-content"} style={{cursor: "pointer", color: "#137cbd"}} onClick={()=>onClickHandler(company)} >{v}</span></div>
        );
    }
    return (
        <div className={"td-wrapper"}><span className={"td-content"}>{v}</span></div>
    );
};
const getSic = (desc, company: ICompany)=> {

    const sic_code = parseInt(company.ff_sic_code, 10);
    const sic = Sic.find((i) => i.code === sic_code);
    const sic_class = SicClasses.find((i) => Math.trunc(i.code/100) === Math.trunc(sic_code/100));
    const sic_range = SicRanges.find((i) => Math.trunc(sic_code/100) >= i.from && Math.trunc(sic_code/100)<= i.till);
    const a = sic_range ? sic_range.text : Globals.hyphen;
    const b = sic_class ? sic_class.text : Globals.hyphen;
    const c = sic ? sic.text : Globals.hyphen;

    const sic3code = Math.trunc(sic_code/10);
    const sic3 = Sic3[""+sic3code] ? `${sic3code} - ${Sic3[""+sic3code]}` : Globals.hyphen;

    row_data.push(sic3);
    return (
        <div className={"td-wrapper"} title={sic3}><span className={"td-content"} style={{fontSize: "80%"}}>{sic3}</span></div>
    );
}
const getSector = (desc, company: ICompany) => {
    const text = Sectors.sectors_map[company.sector_id];
    const text_2 = Sectors.sectors_map[company.industry_id];

    row_data.push(`${text}/${text_2}`);
    if(!text){
        return (
            <div className={"td-wrapper"}>{Globals.hyphen}</div>
        );
    }
    return (
        <div className={"td-wrapper"} title={`${text}/${text_2}`}><span className={"td-content"} style={{fontSize: "80%"}}>{text} / {text_2}</span></div>
    );
};
const getCountry = (desc, company: ICompany) => {
    const c = Globals.country_map[company.country_id];
    row_data.push(c.iso_3);
    return c.iso_3;
};

const criteria = {
    criteria_1: "Fehlende Übereinstimmung",
    criteria_2: "Unzureichende Information",
    criteria_3: "Einbindung in Konzernverbund",
    criteria_4: "Wiederkehrende Verluste",
};
const table_header = [
    {label: _t(ETranslation.company), colSpan: 2, textAlign: "left"},
    {label: "ISO", width: 50},
    {label: "SIC-Code", width: 300, borderRight: "4px #ffffff solid"},
    {label: "Eliminationskriterien", colSpan: 4, textAlign: "center"},
    {label: `${Globals.average} Marge`, textAlign: "right"},
];
const table_header_2 = [
    {label: "#", width: 35, content: getPlainField, field: "i"},
    {label: "Name", content: getField, field: "co_name", alt_field: "name", onClick: true},
    {label: "", width: 50, content: getCountry},
    {label: "", width: 300, content: getSic, borderRight: "4px #ffffff solid"},
    {label: criteria.criteria_1, width: 90, field: "criteria_1", textAlign: "center"},
    {label: criteria.criteria_2, width: 90, field: "criteria_2", textAlign: "center"},
    {label: criteria.criteria_3, width: 90, field: "criteria_3", textAlign: "center"},
    {label: criteria.criteria_4, width: 90, field: "criteria_4", borderRight: "4px #ffffff solid", textAlign: "center"},

    {label: "Marge %", fnk: "get_final_result", width: 70, textAlign: "right"},

];


export class TransferPricingLongList extends BaseModule<any> {

    private tableProps: ISzTableProperties<any>;

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            loading: true,
            company_dialog_is_open: false,
            company_dialog_company: undefined,
            showRejectedOnly: false,
        };
    }

    public async getDocumentationData() {
        return this.tableProps;
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderContent() {
        const long_list: ICompany[] = this.state.long_list;
        if(!long_list){
            return this.noData();
        }
        if(!long_list.length){
            return this.noData();
        }
        return (this.renderData());
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected renderData() {
        // table_header_2[8].label = "Marge %";
        // table_header_2[8].fnk = "get_final_result";
        const years = this.getYears();
        table_header[4].label = `${years[0]}-${(""+years[2]).slice(-2)}`;

        return (
            <div className={"sz-row"} style={{paddingTop: 16}}>
                <div style={{marginBottom: 16}}>
                    <Switch label={"nur Eliminierungen anzeigen"} innerLabel={"aus"} innerLabelChecked={"an"} value={this.state.showRejectedOnly} onClick={()=>this.setState({showRejectedOnly: !this.state.showRejectedOnly})} />
                </div>
                <div style={{width: "calc(100% - 15px)", top: 0, zIndex: 10, backgroundColor: "#ffffff"}}>{this.renderHeader()}</div>
                <div style={{boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)", width: "100%", marginTop: 5, position: "relative", height: "calc(100vh - 320px)", overflowY: "scroll", paddingRight: 1}}>{this.renderCompanies()}</div>
                {this.renderCompanyDialog()}
            </div>
        );
    }

    protected noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Einstellung ..."
        />);
    }

    protected onAfterUpdate(data: any) {
        const frozen = Globals.currentModule === EModuleNames.TransferPricing && SessionStore.getItem(EParameters.TpFreezeLongList, false);
        let long_list = data.long_list;
        let financials = data.financials;
        rejected = SessionStore.getItem(EParameters.TpRejected, {});
        rejected_absolut = SessionStore.getItem(EParameters.TpRejectedAbsolut, {});
        if(!frozen){
            // rejected = {};
            // rejected_absolut = {};
            SessionStore.setItem(EParameters.TpLongList, long_list);
            SessionStore.setItem(EParameters.TpFinancials, financials);
            // SessionStore.setItem(EParameters.TpRejected, rejected);
            // SessionStore.setItem(EParameters.TpRejectedAbsolut, rejected_absolut);
        }else{
            long_list = SessionStore.getItem(EParameters.TpLongList, long_list);
            financials = SessionStore.getItem(EParameters.TpFinancials, financials);
        }
        this.setState({
            loading: false,
            financials,
            long_list,
            rejected,
        });
        EventBus.emit("TP::update-rejected", {id: 0, rejected: {}, all_rejected: rejected});
    }

    private renderHeader() {
        const renderCell = (desc, small?) => {
            const colSpan = desc.colSpan;
            const fontSize = small ? "80%" : undefined;
            const props: ThHTMLAttributes<any> = {
                style: {width: desc.width, position: "sticky", padding: 2, fontSize, textAlign: desc.textAlign, borderRight: desc.borderRight},
                colSpan,
            };
            return <th {...props}>{desc.label}</th>;
        };
        return (
            <table className={"bp3-html-table bp3-html-table-condensed bp3-interactive bp3-small"}>
                <thead>
                <tr>
                    {table_header.map((i) => renderCell(i))}
                </tr>
                <tr>
                    {table_header_2.map((i) => renderCell(i, true))}
                </tr>
                </thead>
            </table>
        );
    }
    private getYears(): number[]{
        const year = this.getParameterValue(EParameters.TpYearsParameter);
        const year_delta = 3;
        const result: number[] = [];
        const steps = (new Array(year_delta)).fill(0);
        let y: number = year;
        steps.forEach(() =>result.push(y--));
        return result.reverse();
    }
    private renderCompanies() {
        const perm_frozen = SessionStore.getItem(EParameters.TpPermaFreezeLongList, false);
        const years = this.getYears();
        const financials = this.state.financials;
        const op_result = this.getParameterValue(EParameters.TpOpResult);
        const use_free_defined = op_result === "free_definition";

        const get_f = (c, year) => {
            const f_year = financials[year];
            if(!f_year){
                return undefined;
            }
            const f = f_year[c.id];
            return f;
        }
        const results = {};
        const pre = (c) => {
            if(!results[c.id]){
                results[c.id] ={};
            }
            return (ff, fv) => {results[c.id][ff] = fv};
        };
        const aft = (c, ff) => {
            if(results[c.id]){
                return results[c.id][ff];
            }
            return undefined;
        };
        const composition = this.getParameterValue(EParameters.TpMarginComposition);
        const sum_composition = (fin_data) => {
            let sum = 0;
            composition.forEach( (field) => {
                sum += _f(fin_data[field]);
            });
            // console.error(sum);
            return sum;
        };
        const calls= {
            get_sales:(c, desc)=> {
                const f = get_f(c, desc.year);
                if(!f){
                    return Globals.hyphen;
                }
                pre(c)(desc.field, f.ff_sales/f.xchange);
                return Globals.formatter(f.ff_sales/f.xchange, 0,0, true);
            },
            get_costs:(c, desc)=> {
                const f = get_f(c, desc.year);
                if(!f){
                    return Globals.hyphen;
                }

                const sum = sum_composition(f);
                pre(c)(desc.field, (f.ff_sales - sum)/f.xchange);
                return Globals.formatter((f.ff_sales - sum)/f.xchange, 0,0, true);
            },
            get_sales_cost_prc:(c, desc)=> {
                const f = get_f(c, desc.year);
                if(!f){
                    return Globals.hyphen;
                }
                if(f.ff_sales === 0){
                    return Globals.hyphen;
                }
                const sum = sum_composition(f);
                pre(c)(desc.field, (f.ff_sales - sum)/f.ff_sales);
                return Globals.formatter_percent(((f.ff_sales - sum)/f.ff_sales), 0,0);
            },
            get_final_result:(c, desc)=> {
                const margins = [];
                years.forEach( (y)=>{
                    const f = get_f(c, y);
                    if(!f){
                        return;
                    }
                    if(f.ff_sales === 0){
                        return;
                    }

                    let v: number;
                    if(use_free_defined){
                        v = (f.ff_sales - sum_composition(f)) / f.ff_sales;
                    }else{
                        v = f[op_result] / f.ff_sales;
                    }
                    if(isNaN(v)){
                        return;
                    }
                    margins.push(v);
                });
                const avg = average(margins);
                if(isNaN(avg)){
                    row_data.push("");
                    return Globals.hyphen;
                }
                row_data.push(avg * 100);
                return Globals.formatter(avg * 100, 2,2, true);
            }
        };
        raw_data = [];
        const getCriterion = (desc, company: ICompany) => {
            const reason = rejected[company.id];

            const setRejected = () => {
                if(perm_frozen){
                    return;
                }
                let r = rejected[company.id];
                if(!r){
                    r = {};
                    rejected[company.id] = r;
                }
                if(!r[desc.field]){
                    r[desc.field] = true;
                }else{
                    delete r[desc.field];
                }
                let f = false;
                Object.keys(r).forEach( (k) =>  f = f || r[k]);
                if(!f){
                    delete rejected[company.id];
                    delete rejected_absolut[company.id];
                }else{
                    rejected_absolut[company.id] = f;
                }
                SessionStore.setItem(EParameters.TpRejected, rejected);
                SessionStore.setItem(EParameters.TpRejectedAbsolut, rejected_absolut);
                EventBus.emit("TP::update-rejected", {id: company.id, rejected: f, all_rejected: rejected});
                this.setState({rejected});
            };
            const get_icon = ()=> {
                if(perm_frozen){
                    return (
                        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                            <Icon color={"rgba(142,142,142,0.5)"} icon={IconNames.CIRCLE} />
                        </div>
                    );
                }else{
                    return (
                        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                            <Button disabled={perm_frozen} minimal={true} small={true} intent={Intent.NONE} icon={IconNames.CIRCLE} onClick={() => setRejected()} />
                        </div>
                    );
                }
            };
            const not_rj = get_icon();
            if(!reason){
                return not_rj;
            }
            if(!reason[desc.field]){
                return not_rj;
            }

            if(perm_frozen){
                return (
                    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                        <Icon intent={Intent.DANGER} icon={IconNames.DELETE} />
                    </div>
                );
            }

            return (
                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <Button disabled={perm_frozen} minimal={true} small={true} intent={Intent.DANGER} icon={IconNames.DELETE} onClick={() => setRejected()} />
                </div>
            );
        };
        const openCompanyDialog = (company: ICompany)=> {
            this.setState({
                company_dialog_is_open: true,
                company_dialog_company: company,
            });
        };
        const long_list: ICompany[] = this.state.long_list;
        const renderCompany = (c: ICompany, idx) => {
            if(this.state.showRejectedOnly && !rejected_absolut[c.id]){
                return null;
            }
            row_data = [];
            raw_data.push(row_data);
            const renderCell = (desc, column_idx) => {
                const boxShadow = idx === 0 ? "none" : undefined;
                const color = rejected_absolut[c.id] ? "#D9822B" : undefined;

                const textAlign = desc.textAlign;
                const borderRight = desc.borderRight;
                const props: TdHTMLAttributes<any> = {
                    style: {width: desc.width, padding: 2, boxShadow, color, textAlign, borderRight, verticalAlign: "middle", overflow: "hidden", textOverflow: "ellipsis"},
                };
                if(criteria[desc.field]){
                    row_data.push( rejected[c.id] ? rejected[c.id][desc.field] ? "x" : "" : "");
                    return <td {...props}>{getCriterion(desc, c)}</td>;
                }
                if(!desc.content){
                    if(desc.fnk){
                        const vv = calls[desc.fnk](c, desc);
                        return <td {...props}>{vv}</td>;
                    }
                    row_data.push("");
                    return <td {...props}>&nbsp;</td>;
                }
                if(desc.onClick){
                    return <td {...props}>{desc.content(desc, c, openCompanyDialog)}</td>;
                }
                return <td {...props}>{desc.content(desc, c)}</td>;
            };
            return (
                <tr className={"sz-row-hover"}>
                    {table_header_2.map( (th, column_idx) => renderCell(th, column_idx) )}
                </tr>
            );
        }
        const header_1: ISzTableColumn[] = [
            {text: _t(ETranslation.company), options: {colSpan: 2}},
            {text: "ISO"},
            {text: _t(ETranslation.sector)},
            {text: "Eliminationskriterien", options: {colSpan: 4}},
            {text: table_header[4].label}
        ];
        const header_2: ISzTableColumn[] = [];
        table_header_2.forEach((h) => {
            header_2.push(
                {text: h.label, options: { style: {width: h.width}}},
            );
        });
        const table_columns: ISzTableColumn[] = [];
        table_header_2.forEach((h, idx) => {
            const className = idx === 8 ? "sz-number" : "";
            const fractionDigits = idx === 8 ? 2 : undefined;
            const width = idx === 1 ? 450 : idx === 3 ? undefined : h.width;
            table_columns.push(
                {index: `${idx}`, options: { style: {width}, className, fractionDigits}},
            );
        });
        this.tableProps = {
            colCount: table_header_2.length,
            header: [
                header_1,
                header_2,
            ],
            columns: table_columns,
            data: raw_data,
        };
        return (
            <table className={"sz-table bp3-html-table bp3-html-table-condensed bp3-html-table-striped bp3-small sz-cell-overflow"} style={{tableLayout: "fixed"}}>
                <tbody>
                {long_list.map( (c, idx) => renderCompany(c, idx) )}
                </tbody>
            </table>
        );
    }
    private renderContact() {
        return (
            <div>
                <div className={"sz-loco sz-loco-big"} style={{alignItems: "center"}}>
                    <div  className={"sz-loco-icon"}><Icon icon={IconNames.ENVELOPE} color={"#5c7080"} iconSize={48} /></div>
                    <div className={"sz-loco-text"}><div style={{textAlign: "center"}}>Zum Thema <strong>Transfer-Pricing</strong> setzen Sie sich bitte mit uns in Verbindung.</div></div>
                    <div style={{margin: 32}}>
                        <Button icon={IconNames.ENVELOPE} intent={"primary"} text={"Kontakt Formular"} onClick={() => window.open("https://www.smart-zebra.de/kontakt/") } />
                    </div>
                </div>
            </div>
        );
    }

    private renderCompanyDialog() {
        if(!this.state.company_dialog_is_open){
            return null;
        }
        const company: ICompany = this.state.company_dialog_company;
        const country: ICountry = Globals.country_map[company.country_id];
        /*company_dialog_is_open: false,
            company_dialog_company: undefined,*/
        const reason = !rejected[company.id] ? {} : rejected[company.id];
        const getCriterion = (field_name) => {
            const label = criteria[field_name];
            // const reason = rejected[company.id];

            const setRejected = () => {
                let r = rejected[company.id];
                if(!r){
                    r = {};
                    rejected[company.id] = r;
                }
                if(!r[field_name]){
                    r[field_name] = true;
                }else{
                    delete r[field_name];
                }
                let f = false;
                Object.keys(r).forEach( (k) =>  f = f || r[k]);
                if(!f){
                    delete rejected[company.id];
                    delete rejected_absolut[company.id];
                }else{
                    rejected_absolut[company.id] = f;
                }
                SessionStore.setItem(EParameters.TpRejected, rejected);
                SessionStore.setItem(EParameters.TpRejectedAbsolut, rejected_absolut);
                EventBus.emit("TP::update-rejected", {id: company.id, rejected: f, all_rejected: rejected});
                this.setState({rejected});
            };
            const not_rj = (
                <div style={{width: "100%", display: "flex"}}>
                    <Button text={label} style={{width: 250, justifyContent: "flex-start"}} minimal={true} small={true} intent={Intent.NONE} icon={IconNames.CIRCLE} onClick={() => setRejected()} />
                </div>
            );
            if(!reason){
                return not_rj;
            }
            if(!reason[field_name]){
                return not_rj;
            }
            return (
                <div style={{width: "100%", display: "flex"}}>
                    <Button text={label} style={{width: 250, justifyContent: "flex-start"}} minimal={true} small={true} intent={Intent.DANGER} icon={IconNames.DELETE} onClick={() => setRejected()} />
                </div>
            );
        };

        const keyWords = SessionStore.get(EParameters.TpKeyWordsWordsParameter);

        return (
            <Dialog
                style={{width: 1200, backgroundColor: "transparent", padding: 0}}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={true}
                onClose={() => this.setState({company_dialog_is_open: false})}
            >
                <div className={`${Classes.DIALOG_HEADER} bp3-dark`} style={{backgroundColor: "rgb(57, 75, 89)", paddingLeft: 10}}>
                    <img src={`${Globals.BASE_DIR}images/flags/${country?.iso_2.toLowerCase()}.jpg`} style={{height: 30, alignSelf: "center"}}/>
                    <div style={{display: "flex", width: "100%", paddingRight: 10, marginLeft: 10, justifyContent: "space-between", alignItems: "center"}}>
                        <span style={{fontSize: "100%", fontWeight: 600}}>{company.co_name || company.name}</span>
                        <div style={{fontSize: "70%"}} className={"bp3-text-muted"}>{company.security_id} - {company.id}</div>
                    </div>
                    <Button intent={Intent.DANGER} aria-label={"Schließen"} icon={"small-cross"} minimal={true} onClick={() => this.setState({company_dialog_is_open: false})} />
                </div>
                <div className={Classes.DIALOG_BODY} style={{margin: 0, backgroundColor: "#ffffff"}}>
                    <div className={"sz-row"}>
                        <div className={"sz-col"} style={{width: 850, minWidth: 850, overflowY: "hidden"}}>
                            <Tabs id="CI_TABS" className={"sz-tab-seized"}>
                                <Tab id="CI_TABS_BASE" title={_t(ETranslation.base_data)} panel={<CompanyInformation company_id={company.id} />} />
                                <Tab id="CI_TABS_COMP" title={_t(ETranslation.peer_group)} panel={<CompanyRelations company_id={company.id} />} />
                                <Tab id="CI_TABS_FIN" title={_t(ETranslation.financials)} panel={<CompanyFinancials company_id={company.id} />} />
                            </Tabs>
                        </div>
                        <div className={"sz-col"} style={{width: 350, paddingRight: 8}}>
                            <h3>Eliminationskriterien</h3>
                            {getCriterion("criteria_1")}
                            {getCriterion("criteria_2")}
                            {getCriterion("criteria_3")}
                            {getCriterion("criteria_4")}
                            <h3>Notizen</h3>
                            <TextArea
                                growVertically={true}
                                onChange={(e) => this.handleChange(e)}
                                rows={5}
                                maxLength={250}
                                value={reason.notes}
                            />
                            <span className={"bp3-text-muted"} style={{fontSize: "80%"}}>max. 250 Zeichen</span>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

    private handleChange(e) {
        const company: ICompany = this.state.company_dialog_company;
        let r = rejected[company.id];
        const company_dialog_note = e.target.value;
        r = !r ? {} : r;
        rejected[company.id] = r;
        r.notes = company_dialog_note;

        SessionStore.setItem(EParameters.TpRejected, rejected);
        this.setState({company_dialog_note, rejected});
    }
}
