import {Button, Icon} from "@blueprintjs/core";
import * as React from "react";
import {EContentType} from "../../const/EContentType";
import {INopeAction} from "../../models/INopeAction";

export interface ISzDemoProps {
    img: string;
    height: number;
    contentType: EContentType;
    nopeActions?: INopeAction[];
}

export class SzDemo extends React.Component<ISzDemoProps, any> {

    constructor(props: ISzDemoProps, context: any) {
        super(props, context);
    }

    public render() {
        const contentTypeText = (ct: EContentType) => {
            switch (ct) {
                case EContentType.Freemium: return (<div className={"sz-loco-text"}><div style={{textAlign: "center"}}>Diese Funktionalität steht Ihnen nach Ihrer kostenlosen<br/>Registrierung zur Verfügung.</div></div>);
                case EContentType.Elite:
                case EContentType.Premium: return (<div className={"sz-loco-text"}><div style={{textAlign: "center"}}>Diese Funktionalität ist kostenpflichtig.<br/>Bitte erwerben Sie folgendes Modul zur Aktivierung</div></div>);
                case EContentType.Free:
                default: return null;
            }
        };
        const renderButtons = (ct: EContentType) => {
            const aButton = ( caption, url ) => {
                return <Button intent={"primary"} text={caption} onClick={() => window.open(url) } />;
            };
            const model = this.props.nopeActions;
            if (!model) {
                return null;
            }
            return model.map( (m) => aButton(m.caption, m.url) );
        };
        // const justify = this.props.nopeActions && this.props.nopeActions.length > 1 ? "space-around" : "center";
        // style={{justifyContent: justify}}
        return (
            <div className={"sz-loco sz-loco-big"}>
                <div  className={"sz-loco-icon"}><Icon icon={"unlock"} color={"#5c7080"} iconSize={48} /></div>
                {contentTypeText(this.props.contentType)}
                <div  className={"sz-loco-buttons"}>
                    {renderButtons(this.props.contentType)}
                </div>
            </div>
        );
    }
}
