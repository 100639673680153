import {Icon, Menu, MenuItem, Switch} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {captions} from "../../../const/Benchmarking";
import {BondSector} from "../../../const/BondSector";
import {CreditSpreadOptions} from "../../../const/CreditSpreadOptions";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SzSlider} from "../../widgets/SzSlider";
import {CombiParameter} from "../CombiParameter";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const defaults = {xch_sales: 1, ebitda_sales_ratio: 1, net_debt_ebitda: 1, ebit_interest_ratio: 1, gearing: 1};
const label_style: CSSProperties={
    display: "flex",
    paddingTop: 10,
    flexDirection: "column",
    alignContent: "flex-start",
};

export class CreditSpreadMetricParameter extends CombiParameter {

    private groups = [];
    private cc_selected = 0;

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getDocLabel(values: any){
        const _a = (p, def_vv)=> Array.isArray(values[p]) ? values[p][0] : def_vv;
        let metrics =_a(EParameters.CreditSpreadMetricParameter, {});
        if(Object.keys(metrics).length === 0){
            metrics = {
                Schuldentragfähigkeit: "net_debt_ebitda",
                Zinsdeckung: "ebit_interest_ratio",
                Kapitalstruktur: "gearing",
                Profitabilität: "ebitda_sales_ratio",
                "Unternehmensgröße": "xch_sales",
            };
        }
        const session_weights = _a(EParameters.CreditSpreadMetricWeightsParameter, [.2, .2, .2, .2, .2]);
        const mode = _a(EParameters.CreditSpreadMetricWeightsModeParameter, false);

        const selected_sectors = _a(EParameters.BondSectorParameter, "0_0");

        const matrix_code = BondSector.value_to_matrix[selected_sectors] ? BondSector.value_to_matrix[selected_sectors] : "0_0";
        const weights =  session_weights !== undefined ? session_weights : [.2, .2, .2, .2, .2];
        const use_weights = mode === false ? BondSector.weights_sector[matrix_code] : weights;

        // captions
        const all_keys = CreditSpreadOptions.use_ratio;
        const content = [];
        let idx = 0;
        all_keys.forEach((key, )=>{
            if(!key.startsWith("#")){
                return;
            }
            const selected_key = metrics[key.substring(1)];
            const label = captions[selected_key];
            content.push(`${label} (${Globals.formatter_percent(use_weights[idx], 0, 0)})`);
            idx++;
        });
        return content;
    }
    public static getPdfLabel() {
        const metrics = SessionStore.get(EParameters.CreditSpreadMetricParameter);
        const session_weights = SessionStore.get(EParameters.CreditSpreadMetricWeightsParameter);
        const mode = SessionStore.get(EParameters.CreditSpreadMetricWeightsModeParameter);

        const selected_sectors = SessionStore.get(EParameters.BondSectorParameter);
        const matrix_code = BondSector.value_to_matrix[selected_sectors] ? BondSector.value_to_matrix[selected_sectors] : "0_0";
        const weights =  session_weights !== undefined ? session_weights : [.2, .2, .2, .2, .2];
        const use_weights = mode === false ? BondSector.weights_sector[matrix_code] : weights;

        // captions
        const all_keys = CreditSpreadOptions.use_ratio;
        const content = [];
        let idx = 0;
        all_keys.forEach((key, )=>{
            if(!key.startsWith("#")){
                return;
            }
            const selected_key = metrics[key.substring(1)];
            const label = captions[selected_key];
            content.push({text:[
                    {text: `${key.substring(1)}: `,bold: true},
                    `${label}`,
                    ` Gewichtung (${Globals.formatter_percent(use_weights[idx], 0, 0)})`
                ]});
            idx++;
        });
        return {
            stack: content,
        };
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CreditSpreadMetricParameter];
    }

    protected getWidthClass(): string {
        return "fixed-width-750";
    }

    protected async initialize() {
        const state: {[k: string]: any} = {};
        const metrics = SessionStore.get(EParameters.CreditSpreadMetricParameter);
        const session_weights = SessionStore.get(EParameters.CreditSpreadMetricWeightsParameter);
        const mode = SessionStore.get(EParameters.CreditSpreadMetricWeightsModeParameter);

        const selected_sectors = SessionStore.get(EParameters.BondSectorParameter);
        const matrix_code = BondSector.value_to_matrix[selected_sectors] ? BondSector.value_to_matrix[selected_sectors] : "0_0";
        const weights =  session_weights !== undefined ? session_weights : [.2, .2, .2, .2, .2];

        const use_weights = mode === false ? BondSector.weights_sector[matrix_code] : weights;

        state[EParameters.CreditSpreadMetricParameter] = metrics;
        state[EParameters.CreditSpreadMetricWeightsParameter] = use_weights;
        state[EParameters.CreditSpreadMetricWeightsModeParameter] = mode;
        return state;
    }

    protected renderMenuContent(): any {
        const all_keys = CreditSpreadOptions.use_ratio;
        const metrics = this.state[EParameters.CreditSpreadMetricParameter];
        this.cc_selected = 0;
        this.groups = [];
        let current = [];
        all_keys.forEach((key)=>{
            if(key.startsWith("#")){
                current = [];
                const caption = key.substring(1);
                if(metrics[caption]!=="0"){
                    this.cc_selected++;
                }
                this.groups.push({
                    caption,
                    items: current,
                });
            }else{
                current.push(key);
            }
        });

        const selected_sectors = SessionStore.get(EParameters.BondSectorParameter);
        const matrix_code = BondSector.value_to_matrix[selected_sectors] ? BondSector.value_to_matrix[selected_sectors] : "0_0";
        const weights =  this.state[EParameters.CreditSpreadMetricWeightsParameter] !== undefined ? this.state[EParameters.CreditSpreadMetricWeightsParameter] : [.2, .2, .2, .2, .2];
        const mode = this.state[EParameters.CreditSpreadMetricWeightsModeParameter] === undefined ? false : this.state[EParameters.CreditSpreadMetricWeightsModeParameter];
        const use_weights = mode === false ? BondSector.weights_sector[matrix_code] : weights;
        const usable_weights = [0 , 0, 0, 0, 0];
        let rest_weights = 0;
        let usable = 0;
        let last_usable = -1;
        this.groups.forEach((g, idx)=>{
            if(use_weights[idx] === 0){
                rest_weights += use_weights[idx];
            }else{
                last_usable = idx;
                usable_weights[idx] = use_weights[idx];
                usable++;
            }
        });
        if(rest_weights > 0 && usable > 0){
            const to_add = Math.trunc((rest_weights / usable) * 100) / 100;
            usable_weights.forEach((v, idx)=>{
                if(usable_weights[idx] > 0){
                    usable_weights[idx] += to_add;
                    if(idx === last_usable && usable > 1 && to_add*100 !== (rest_weights / usable)*100){
                        usable_weights[idx] += .01;
                    }
                }
            });
        }

        return (
            <div>
                {this.show100PAlert(mode)}
                <div className={"sz-row"}>
                    <div style={{paddingRight: 10}} className={"sz-row"}>
                        {this.groups.map((group, idx)=> this.renderGroup(group, usable_weights, idx))}
                    </div>
                    <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10, justifyContent: "space-between"}}>
                        <span>* {_t(ETranslation.default_smartzebra)}</span>
                        <span>{Globals.formatter_percent(weights.reduce((partialSum, a) => partialSum + a, 0), 0, 0, true)}</span>
                    </div>
                    {this.renderHelpText(PARAMETER_HELP(EParameters.CreditSpreadMetricParameter))}
                </div>
            </div>
        );
    }

    protected getLabel(): any {
        const metrics = this.state[EParameters.CreditSpreadMetricParameter];
        if(!metrics){
            return Globals.hyphen;
        }
        let only_default_selected = true;
        this.groups.forEach((group)=>{
            const selected = metrics[group.caption];
            if(selected && only_default_selected && !defaults[selected]){
                only_default_selected = false;
            }
        });
        const mode = this.state[EParameters.CreditSpreadMetricWeightsModeParameter];
        return `${only_default_selected ? "Voreinstellung" : "Benutzereinstellung"} | ${mode ? "benutzersp." : "sektorsp."}`;
    }

    protected onClosePopup() {
        const weights = this.state[EParameters.CreditSpreadMetricWeightsParameter];
        const mode = this.state[EParameters.CreditSpreadMetricWeightsModeParameter];
        const metrics = this.state[EParameters.CreditSpreadMetricParameter];
        this.fireChanges([
            {component: EParameters.CreditSpreadMetricParameter, value: [metrics],},
            {component: EParameters.CreditSpreadMetricWeightsModeParameter, value: [mode],},
            {component: EParameters.CreditSpreadMetricWeightsParameter, value: [weights],},
        ]);
    }

    private onChangeValue(field: EParameters, _value: any) {
        const _valueAsNumber = _value;
        const state: {[k: string]: any} = {};
        state[field] = _valueAsNumber;
        this.setState(state);
        // this.fireValueChange(field, _valueAsNumber);
    }
    private renderGroup(group: {caption: string, items: any[]}, weights: number[], group_index) {
        // EParameters.CreditSpreadMetricParameter
        const metrics = this.state[EParameters.CreditSpreadMetricParameter];
        if(!metrics){
            return null;
        }
        const selected = metrics[group.caption] === "0" ? undefined : metrics[group.caption];
        const onChange = (i)=>{
            metrics[group.caption] = i;
            this.onChangeValue(EParameters.CreditSpreadMetricParameter, metrics);
        };
        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return <Icon icon={IconNames.BLANK}/>;
            }
        };
        const getCaption= (i)=>{
            if(defaults[i]){
                return `${captions[i]}*`;
            }else{
                return captions[i];
            }
        };
        const renderItems = ()=>{
            const val = selected ? selected : group.items[0];
            return (
                <Menu>
                    {group.items.map((i)=><MenuItem className={ i === val ? "sz-option-selected" : undefined} intent={ i === val ? "primary" : undefined} labelElement={getIcon(i === val)}  text={getCaption(i)} onClick={() => { onChange(i); }} />)}
                </Menu>
            );
        };
        const renderInput = (weight, index)=>{
            // console.error(weight, index);
            return (
                <div style={{paddingLeft: 30, paddingRight: 30, paddingTop: 12}}>
                    <SzSlider key={`csmp_id_w${weight*100}`} labelRenderer={(v)=> `${v}%`} value={weight * 100} min={0} max={50} stepSize={5} stepSizeLabel={10} onValueChanged={(v)=> this.changeWeights(weights, v / 100, index)}/>
                </div>
            );
        };
        return (
            <div className={"sz-row"}>
                <div className={"sz-col sz-col-60"}>
                    {this.renderColumn("40%", group.caption, ()=>{
                        return (
                            <div style={{marginLeft: -5, marginRight: -5}}>
                                {renderItems()}
                            </div>
                        );
                    }, label_style)}
                </div>
                <div className={"sz-col sz-col-40"}>
                    {renderInput(weights[group_index], group_index)}
                </div>
            </div>
        );
    }
    private changeWeights(weights: number[], weight: number, weight_index: number) {
        weights[weight_index] = weight;
        this.setState({CreditSpreadMetricWeightsParameter: weights, CreditSpreadMetricWeightsModeParameter: true});
    }

    private show100PAlert(mode) {
        const weights = this.state[EParameters.CreditSpreadMetricWeightsParameter] ? this.state[EParameters.CreditSpreadMetricWeightsParameter] : [0];
        const w = weights.reduce((partialSum, a) => partialSum + a, 0);
        if(w===1){
            return (
                <div className={"sz-row"} style={{padding: 10, paddingBottom: 10, justifyContent: "flex-end"}}>
                    <Switch checked={mode} label="Gewichtung" alignIndicator={"right"} onChange={()=> this.setState({CreditSpreadMetricWeightsModeParameter: !mode})} innerLabelChecked={"benutzerdefiniert"} innerLabel={_t(ETranslation.sector_specific)} />
                </div>
            );
        }
        return (
            <div className={"sz-row"} style={{padding: 10, paddingBottom: 10, justifyContent: "space-between"}}>
                <div style={{color: "#AC2F33"}}>
                    <Icon icon={IconNames.WARNING_SIGN} size={16} />&nbsp; Achtung! Die Gewichtung muss 100% betragen. Aktuell {Globals.formatter_percent(w,0,0,true)}
                </div>
                <Switch checked={mode} label="Gewichtung" alignIndicator={"right"} onChange={()=> this.setState({CreditSpreadMetricWeightsModeParameter: !mode})} innerLabelChecked={"benutzerdefiniert"} innerLabel={_t(ETranslation.sector_specific)} />
            </div>
        );
    }
}
