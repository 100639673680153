import {EContentType} from "../../const/EContentType";
import {EPackageTypes} from "../../const/EPackageTypes";
import {NopeActions} from "../../const/NopeActions";
import {ELayoutColumnWidth} from "../../models/ELayoutColumnWidth";
import {EParameters} from "../../models/EParameters";
import {IColumnLayout} from "../../models/IColumnLayout";
import {ColumnLayouterImpl} from "../ColumnLayouterImpl";
import {ModuleGroup} from "../ModuleGroup";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {IModuleConfig} from "../../models/IModuleConfig";

export class CostOfCapital extends ModuleGroup<IColumnLayout> {
    public static config: IModuleConfig = {
        name: "Kapitalkosten",
    };
    constructor() {
        super(_t(ETranslation.cost_of_capital), { columns: []});

        this.addParameter(EParameters.CompaniesParameter);
        this.addParameter(EParameters.DateParameter);

        this.addParameter(EParameters.ReleveredBetaParameter);
        this.addParameter(EParameters.BaseRateParameter);
        this.addParameter(EParameters.MarketAndCountryRiskParameter);

        this.addParameter(EParameters.CreditSpreadParameter);

        this.renderSingleDoc = true;
        // this.documentation = [
            // {module: "CostOfCapitalModule", caption: _t(ETranslation.cost_of_capital)},
        // ];
        const a = {bgColor: "#e9e9e9", borderTop: true, bold: true};
        const b = {bgColor: "#e9e9e9"};
        this.documentation_options = {
            row_styles: {
                6: a,
                7: b,
                12: a,
                13: b,
                14: a,
                15: b,
            },
        };
        const same_db = {sameDb: true};
        const dataDefault = [{theSame: true}];
        this.addModule(_t(ETranslation.cost_of_capital), "CostOfCapitalModule", "CostOfCapitalProvider")
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true)
        ;
        this.addModule(_t(ETranslation.costofcapitalvisual), "CostOfCapitalTree", "CostOfCapitalProvider")
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
        ;

        const modulBeta = this.addModule(_t(ETranslation.beta_factor), "BetasUnleveredBeta", "ChefBetasDataProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
        ;
        modulBeta.options = {
            hideColumns: true,
        };
        this.addModule(_t(ETranslation.base_rate), "BaseInterestRateSvg", "InterestRatesBubaAllProvider", same_db)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true)
        ;
        this.addModule(_t(ETranslation.mrp_and_crp), "MarketRiskPremium", "MarketRiskPremiumProvider", same_db)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register);

        this.addModule(_t(ETranslation.credit_spread), "CorporateSpread", "CorporateSpreadProvider", same_db)
            .setAccess(EContentType.Elite, EPackageTypes.Elite, NopeActions.elite)
            .setExcelExport(true)
        ;
/*
        this.addModule(_t(ETranslation.rating_tool), "RatingNavigator", "CorporateSpreadProvider", same_db)
            .setAccess(EContentType.Elite, EPackageTypes.Elite, NopeActions.elite)
            .setAlignRight()
        ;

 */
        this.addModule(_t(ETranslation.macroeconomic_data), "MacroEconomy", "MacroDataProvider", same_db)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true)
            .setAlignRight()
        ;
        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c40)
            .addModule([0, 1]);
        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c60)
            .addModule([2, 3, 4, 5, 6]);
        // console.error(this.layout);
    }
    protected getTitle(): string{
        return _t(ETranslation.cost_of_capital);
    }
}
