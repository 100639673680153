import {Button, HTMLTable, Icon, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {ParameterOptions} from "../../const/ParameterOptions";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {ICompany} from "../../models/ICompanyDetails";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {fromStr} from "../../tools/DateTools";
import {SzYearsFilter} from "../widgets/SzYearsFilter";
import {BaseModule} from "./BaseModule";
import {Sic3, SicClasses} from "../../const/Sic";
import {SzDoughnutChart} from "../widgets/SzDoughnutChart";

interface ITableField {
    text?: string;
    content?: any;
    colSpan?: number;
    style?:CSSProperties;
}
interface ITransferPricingSummaryState{
    sector?: number;
    sub_sector?: number;
    free_float?: number;
    country_ids?: string[];
    years?: number[];
    sales_start?: number;
    sales_stop?: number;

    is_loading?: boolean;
}
export class TransferPricingSummaryShortList extends BaseModule<any> {

    private long_list: ICompany[] = [];
    private long_list_map= {};
    private readonly evtUpdateRejected: IEventHandler;
    private all_rejected: number = 0;
    private rejected_table;

    constructor(props: any, context: ITransferPricingSummaryState) {
        super(props, context);
        this.state= {
            is_loading: false,
            years: SzYearsFilter.getDefaultYears(),
        };
        this.evtUpdateRejected = EventBus.subscribe("TP::update-rejected", (data) => this.onUpdateRejected(data));
    }
    componentWillUnmount() {
        EventBus.unsubscribe(this.evtUpdateRejected);
    }
    public modState(s: ITransferPricingSummaryState){
        const ss = Object.assign({}, this.state, s);
        this.setState(s);
        return ss;
    }
    public async getDocumentationData() {
        return {
            table: this.rejected_table
        };
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    protected onAfterUpdate(data: any) {
        this.data = data;
        this.long_list_map = {};
        if(data && Array.isArray(data.long_list)){
            data.long_list.forEach((i)=>{
                this.long_list_map[i.id] = i;
            });
        }
        this.setState({
            long_list: data.long_list,
            loading: false,
            rejected: {},
            all_rejected: {},
            financials: data.financials
        });
    }
    private onUpdateRejected(data: any) {
        // {id: company.id, rejected: boolean, all_rejected}
        const rejected = this.state.rejected;
        rejected[data.id] = data.rejected;

        this.all_rejected = 0;
        Object.keys(data.all_rejected).forEach((k) => {
            if(!this.long_list_map[k]){
                return;
            }
            const co = data.all_rejected[k];
            if(co.criteria_1 || co.criteria_2 || co.criteria_3 || co.criteria_4){
                this.all_rejected ++;
            }
        });

        this.setState({
            rejected,
            all_rejected: data.all_rejected,
        });
    }
    private getYears(): number[]{
        const year = this.getParameterValue(EParameters.TpYearsParameter);
        const result: number[] = [];
        const steps = (new Array(3)).fill(0);
        let y: number = year;
        steps.forEach(() =>result.push(y--));
        return result.reverse();
    }
    protected renderContent() {
        // console.error(this);
        this.rejected_table = [];
        const rejected_table = this.rejected_table;
        const cc_ll = this.data && Array.isArray(this.data.long_list) ? this.data.long_list.length : 0;
        const fieldMap_2: ITableField[] = [
            {text: "", content: "", colSpan: 2},
            {text: "Long List", content: cc_ll, style:{ textAlign: "right"}},
            {text: "", content: "", colSpan: 2},
            {text: " Fehlende Übereinstimmung", content: this.getRejected(this.state.all_rejected,"criteria_1"), style:{ textAlign: "right"}},
            {text: " Unzureichende Information", content: this.getRejected(this.state.all_rejected,"criteria_2"), style:{ textAlign: "right"}},
            {text: " Einbindung in Konzernverbund", content: this.getRejected(this.state.all_rejected,"criteria_3"), style:{ textAlign: "right"}},
            {text: " Wiederkehrende Verluste", content: this.getRejected(this.state.all_rejected,"criteria_4"), style:{ textAlign: "right"}},
            {text: "Eliminierung", content: -1*this.all_rejected},
            {text: "", content: "", colSpan: 2},
            {text: "Shortlist", content: cc_ll - this.all_rejected, style: {fontWeight: 600, textAlign: "right"}},
            {text: "", content: "", colSpan: 2},
        ];
        const renderRow = (row, row_num) => {
            const data_row = [];
            rejected_table.push(data_row);
            const style:CSSProperties = {
                verticalAlign: "middle",
                textAlign: "right",
            };
            if(row.style){
                Object.assign(style, row.style);
            }
            if(row.colSpan){
                data_row.push(row.colSpan)
                return (
                    <tr>
                        <td colSpan={row.colSpan} style={style}>{row.content}</td>
                    </tr>
                );
            }
            const label_style = Object.assign({width: 300}, style, {textAlign: "left"});
            data_row.push(row.text, row.content)
            return (
                <tr>
                    <td  style={label_style}>{row.text}</td>
                    <td  style={style}>{row.content}</td>
                </tr>
            );
        };
        const gfx_data = [0, 0, 0, 0]; // data_sets[0].data;
        let hit = false;
        Object.keys(this.state.all_rejected ? this.state.all_rejected : {}).forEach((key) => {
            if(!this.long_list_map[key]){
                return;
            }
            const o = this.state.all_rejected[key];
            if(o.criteria_1){
                gfx_data[0]++;
                hit = true;
            }
            if(o.criteria_2){
                gfx_data[1]++;
                hit = true;
            }
            if(o.criteria_3){
                gfx_data[2]++;
                hit = true;
            }
            if(o.criteria_4){
                gfx_data[3]++;
                hit = true;
            }
        });

        const labels = hit ? ["Fehlende Übereinstimmung", "Unzureichende Information", "Einbindung in Konzernverbund", "Wiederkehrende Verluste"] : [];
        const data_sets = [{
            data: hit ? gfx_data : [1],
            backgroundColor: hit ? ["#cbf0fd","#8ad8e8","#29bdab","#277da7"] : ["#CED9E0"],
            borderColor: hit ? ["#cbf0fd","#8ad8e8","#29bdab","#277da7"] : ["#CED9E0"],
            borderWidth: 0,
        }];
        return (
            <div className={"sz-row"} style={{paddingTop: 16}}>
                <div className={"sz-col sz-col-100"}>
                    <HTMLTable className={`sz-table`} condensed={true} style={{width: "100%", borderCollapse: "separate"}}>
                        <thead>
                        <tr>
                            <th colSpan={2}>Eliminierung Shortlist</th>
                        </tr>
                        </thead>
                        <tbody>
                        {fieldMap_2.map((m, idx) => renderRow(m, idx))}
                        </tbody>
                    </HTMLTable>
                </div>
                <HTMLTable className={`sz-table`} condensed={true} style={{width: "100%", borderCollapse: "separate", marginTop: 32}}>
                    <thead>
                    <tr>
                        <th>Eliminierungen</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{paddingTop:16}}>
                                <div style={{height: 180}}>
                                    <SzDoughnutChart no_hover={true} no_tooltip={true} labels={labels} data_sets={data_sets} no_data_desc={"Es wurden noch keine Unternehmen eliminiert."} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </HTMLTable>
            </div>
        );
    }
    protected noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }
    private renderContact() {
        return (
            <div>
                <div className={"sz-loco sz-loco-big"} style={{alignItems: "center"}}>
                    <div  className={"sz-loco-icon"}><Icon icon={IconNames.ENVELOPE} color={"#5c7080"} iconSize={48} /></div>
                    <div className={"sz-loco-text"}><div style={{textAlign: "center"}}>Zum Thema <strong>Transfer-Pricing</strong> setzen Sie sich bitte mit uns in Verbindung.</div></div>
                    <div style={{margin: 32}}>
                        <Button icon={IconNames.ENVELOPE} intent={"primary"} text={"Kontakt Formular"} onClick={() => window.open("https://www.smart-zebra.de/kontakt/") } />
                    </div>
                </div>
            </div>
        );
    }
    private getSector() {
        return "leer";
    }

    private getFreeFloat() {
        const freeFloat = this.getParameterValue(EParameters.TpFreeFloatParameter);
        const o = ParameterOptions.options[EParameters.TpFreeFloatParameter].find( (i) => i.value === freeFloat );
        if(!o){
            return "kein Einschränkung";
        }
        return o.label;
    }

    private getCountries() {
        const country_ids: string[] = SessionStore.getItem(EParameters.TpCountriesParameter);
        if(!Array.isArray(country_ids)){
            return Globals.hyphen;
        }
        const flag_all = SessionStore.get(EParameters.TpCountriesAllParameter);
        if(flag_all){
            return "Keine Einschränkung";
        }
        const c = [];
        let m = 0;
        country_ids.forEach( (i) => {
            if(c.length > 10){
                m++;
            }else{
                c.push(Globals.country_map[i].name_de);
            }
        } );
        let l = "";
        if(m > 0){
            if(m === 1){
                l = " und ein weiteres";
            }else{
                l = ` und ${m} weitere`;
            }
        }
        return `${c.join(", ")} ${l}`;
    }

    private SalesVolume() {
        const sales_range = [0, 50, 100, 250, 500, 1500, 5000, 10000, 50000, 100000, 300000000];
        const range = SessionStore.getItem(EParameters.TpSalesParameter)[0];
        try{
            if(range[0] === range[1]){
                if(sales_range[sales_range.length - 1] === range[1]){
                    return Globals.infinity;
                }
                return Globals.formatter(sales_range[range[1]],0,0);
            }else{
                const l = range[1] === sales_range.length - 1 ? Globals.infinity : sales_range[range[1]];
                return `${Globals.formatter(sales_range[range[0]],0,0)} ... ${Globals.formatter(l,0,0)}`;
            }
        }catch(e){
            console.error("range", range);
            console.error(e);
        }
        return Globals.hyphen;
    }
    private getRejected(all_rejected: any, criteria: string) {
        if(!all_rejected){
            return 0;
        }
        let sum = 0;
        Object.keys(all_rejected).forEach( (company_id) =>{
            if(!this.long_list_map[company_id]){
                return;
            }
            if(all_rejected[company_id][criteria]){
                sum--;
            }
        });
        return sum;
    }

    private renderSic(sic_classes: number[], sic_codes: number[]) {
        console.error(sic_classes, sic_codes);
        if(!sic_classes && !sic_codes){
            return Globals.hyphen;
        }
        if(sic_classes.length===0 && sic_codes.length===0){
            return Globals.hyphen;
        }
        const a = SicClasses.filter( (s) => sic_classes.indexOf(s.code)>=0 );
        const b= [];
        const bb= {};
        sic_codes.forEach( (s) =>{
            const sic3 = Math.trunc(s/10);
            const sic3code = sic3<100 ? `0${sic3}` : `${sic3}`;
            if(!bb[sic3code]){
                bb[sic3code] = true;
                b.push(sic3code);
            }
        });
        const r = [];
        const getEntry = (code: any, text: string) => {
            return (
                <div>
                    <span style={{fontSize: "75%", marginRight: 5}}>{code}</span>
                    <span>{text}</span>
                </div>
            );
        };
        a.forEach( (s) =>{
            const i = Math.trunc(s.code/ 100);
            const sc = i<100 ? `0${i}` : i;
            r.push(getEntry(sc, s.text));
        });
        b.forEach( (s) =>{
            r.push(getEntry(s, Sic3[s]));
        });
        return r.map((i) => i);
    }
    private mkTwo(a, b){
        return (
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span>{a}</span>
                <span>{b}</span>
            </div>
        );
    }
    private getDbCount() {
        return this.mkTwo("# Unternehmen", "21110");
    }
    private getDbDate() {
        return this.mkTwo("Stand", fromStr(Globals.last_db, "yyyyLLdd").asDeDate());
    }
}
