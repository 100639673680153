import {Classes, HTMLTable} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {ETranslation} from "../../const/ETranslation";
import {Globals} from "../../const/Globals";
import {Sectors} from "../../const/Sectors";
import {IMultiplierForBusiness} from "../../models/IMultiplierForBusiness";
import {IOptionEntry} from "../../models/IOptionEntry";
import {EventBus} from "../../services/EventBus";
import {_t} from "../../tools/Translator";
import {SzTableHelper} from "./helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties} from "./SzTable";

const BASE_DIR = Globals.BASE_DIR;

export interface IBusinessMultiplierProperties {
    multiplier: any[];
}

interface IBusinessMultiplierState {
    is_loading?: boolean;
    selected_row?: number;
}

export class BusinessMultiplier extends React.Component<IBusinessMultiplierProperties, IBusinessMultiplierState> {

    public tableProps: ISzTableProperties<any[]>;
    private selected_label: string[];
    constructor(props: IBusinessMultiplierProperties, context: IBusinessMultiplierState) {
        super(props, context);
        this.state = {
            selected_row: 0,
        };
    }

    public render() {
        // console.error(JSON.parse(JSON.stringify(this.props.multiplier)));
        const e = this.props.multiplier;
        let use = e;
        this.selected_label = [];
        if(Array.isArray(use) && use.length===1){
            let last_sector;
            use = [];
            if(e[0] && Array.isArray(e[0].multiplier)){
                e[0].multiplier.forEach((m, idx)=>{
                    let sector_value = m.key;
                    if(m.key==="0"){
                        sector_value="0000";
                    }
                    const _sector: IOptionEntry = Sectors.values.find( (s) => s.value === sector_value );
                    if(_sector){
                        this.selected_label.push(Sectors.sectors_map[sector_value]);
                        last_sector = {
                            companies: e[0].companies.filter((f)=> f.sector === m.key),
                            flags: e[0].flags,
                            label: e[0].label,
                            multiplier: [],
                        };
                        last_sector.multiplier.push(m);
                        use.push(last_sector);
                    }else{
                        last_sector.multiplier.push(m);
                    }
                });
            }
        }
        // console.error(use);
        /*
        const header_0: ISzTableColumn[] = [
            {text: "",},
            {text: "Enterprise Value", options: {colSpan: 6, style: {textAlign: "center"}}},
            {text: "Equity Value", options: {colSpan: 3, style: {textAlign: "center"}}},
        ];
        */
        const header_1: ISzTableColumn[] = [
            {text: "",},
            {text: _t(ETranslation.revenues), options: {colSpan: 2, style: {textAlign: "center"}}},
            {text: "EBITDA", options: {colSpan: 2, style: {textAlign: "center"}}},
            {text: "EBIT", options: {colSpan: 2, style: {textAlign: "center"}}},

            {text: _t(ETranslation.pe_ratio), options: {colSpan: 2, style: {textAlign: "center"}}},
            {text: _t(ETranslation.pb_ratio), options: {style: {textAlign: "center"}}},
        ];
        const header_2: ISzTableColumn[] = [
            {text: _t(ETranslation.sector_sub_sector),},
            {text: "Trailing", options: {style: {textAlign: "right"}}},
            {text: "Forward", options: {style: {textAlign: "right"}}},
            {text: "Trailing", options: {style: {textAlign: "right"}}},
            {text: "Forward", options: {style: {textAlign: "right"}}},
            {text: "Trailing", options: {style: {textAlign: "right"}}},
            {text: "Forward", options: {style: {textAlign: "right"}}},
            {text: "Trailing", options: {style: {textAlign: "right"}}},
            {text: "Forward", options: {style: {textAlign: "right"}}},
            {text: "", options: {style: {textAlign: "right"}}},
        ];
        const table_columns = [
            {...SzTableHelper.columnIndex("0")}
        ];
        [1,2,3,4,5,6,7,8,9].forEach((idx)=>{
            table_columns.push({...SzTableHelper.columnMoney(""+idx, 2, {style: {width: 80}})});
        });
        this.tableProps = {
            title: "Branchen Multiplikatoren",
            colCount: header_2.length,
            header: [ header_1, header_2],
            columns: table_columns,
            data: [],
        };
        return (
            <div>
            <div className={"sz-row"}>
                {this.renderResult(use, 0, use.length - 1)}
            </div>
                <p>&nbsp;</p>
                <p className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>*) Median; n.m. – not meaningful</p>
            </div>
        );
    }

    private renderResult(e, idx, sz) {
        const imgStyle: CSSProperties = {
            maxHeight: 20,
            paddingRight: 4,
        };
        const padding = "6px 3px";
        return (
            <div className={"sz-col sz-col-100"}>
                <div style={{padding: 5, height: "100%"}}>
                    <div style={{marginBottom: 20, fontSize: "80%"}}>
                        <div><strong>{_t(ETranslation.trading_multiples)}*</strong></div>
                        <div>{e[0]?e[0].label:Globals.hyphen}, {this.selected_label.join(", ")}</div>
                    </div>
                    <HTMLTable className={`bp3-html-table bp3-html-table-condensed sz-table sz-prj-info-table_2`} condensed={true} style={{width: "100%", fontSize: "80%"}}>
                        <thead>
                        <tr>
                            <th>&nbsp;</th>

                            <th colSpan={2} style={{textAlign: "center", padding}}>{_t(ETranslation.revenues)}</th>
                            <th colSpan={2} style={{textAlign: "center", padding}}>EBITDA</th>
                            <th colSpan={2} style={{textAlign: "center", padding}}>EBIT</th>

                            <th colSpan={2} style={{textAlign: "center", padding}}>{_t(ETranslation.pe_ratio)}</th>
                            <th rowSpan={2} style={{textAlign: "right", padding}}>{_t(ETranslation.pb_ratio)}</th>
                        </tr>
                        <tr>
                            <th>{_t(ETranslation.sector_sub_sector)}</th>
                            <th style={{textAlign: "right", padding}}>Trailing</th>
                            <th style={{textAlign: "right", padding}}>Forward</th>

                            <th style={{textAlign: "right", padding}}>Trailing</th>
                            <th style={{textAlign: "right", padding}}>Forward</th>

                            <th style={{textAlign: "right", padding}}>Trailing</th>
                            <th style={{textAlign: "right", padding}}>Forward</th>

                            <th style={{textAlign: "right", padding}}>Trailing</th>
                            <th style={{textAlign: "right", padding}}>Forward</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderMultipiler(e)}
                        </tbody>
                    </HTMLTable>
                </div>
            </div>
        );
    }

    private renderMultipiler(e) {
        // console.error(e);
        let data_sets = [];
        if(Array.isArray(e)){
            e.forEach((i)=> data_sets = data_sets.concat(i.multiplier));
        }
        const selectRow = (selected_row)=>{
            this.setState({selected_row});
            EventBus.emit("SectorMultiples::selectIndex", selected_row);
        };
        // console.error(e);
        const padding = "6px 3px";
        const render_sector = (m: IMultiplierForBusiness, entry_index)=>{
            let sector_value = m.key;
            if(m.key==="0"){
                sector_value="0000";
            }
            const _sector: IOptionEntry = Sectors.values.find( (s) => s.value === sector_value );
            const _sub_sector: IOptionEntry = Sectors.SubSectors.find( (s) => s.value === m.key );
            const _ = (s, asNumber?, addStyle?: CSSProperties) => {
                if (asNumber && isNaN(s)) {
                    s = Globals.hyphen;
                }
                if (asNumber && s < 0) {
                    s = "n.m.";
                }
                const v = asNumber ? Globals.formatter(s, 1, 1) : s;
                const style: CSSProperties = asNumber ? {textAlign: "right", width: 50, padding, } : {};
                if(addStyle){
                    Object.assign(style, addStyle);
                }
                if (!_sector) {
                    return <td style={style} onClick={()=>selectRow(entry_index)}>{v}</td>;
                }
                return <td style={style} onClick={()=>selectRow(entry_index)}><strong style={{fontWeight: "bold"}}>{v}</strong></td>;
            };
            let l = _sector ? _sector.label : "Sektor nicht gefunden";
            if (_sub_sector) {
                l = _sub_sector.label;
            }
            this.tableProps.data.push(
                [_sector ? l : `${Globals.em_space} ${l}`,
                    m.sales_0_median, m.sales_1_median,
                    m.ebitda_0_median, m.ebitda_1_median,
                    m.ebit_0_median, m.ebit_1_median,
                    m.netprofit_0_median, m.netprofit_1_median,
                    m.price_book_median,
                ]
            );
            const row_classes = ["sz-row-hover"];
            if(entry_index === this.state.selected_row){
                row_classes.push("sz-row-selected-2");
            }
            return (
                <tr className={row_classes.join(" ")}>
                    {_(l)}
                    {_(m.sales_0_median, true)}
                    {_(m.sales_1_median, true)}

                    {_(m.ebitda_0_median, true, {borderLeft: "4px solid #ffffff"})}
                    {_(m.ebitda_1_median, true)}

                    {_(m.ebit_0_median, true, {borderLeft: "4px solid #ffffff"})}
                    {_(m.ebit_1_median, true)}

                    {_(m.netprofit_0_median, true, {borderLeft: "4px solid #ffffff"})}
                    {_(m.netprofit_1_median, true)}
                    {_(m.price_book_median, true, {borderLeft: "4px solid #ffffff", width: 80})}
                </tr>
            );
        };
        return (
            <>
                {data_sets.map( (i, entry_index)=> render_sector(i, entry_index))}
            </>
        );
    }
}
