import {NonIdealState, Spinner} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {SessionStore} from "../../const/SessionStore";
import {CompanyArrayDateMapCache, CompanyObjectDateMapCache} from "../../helpers/CacheTools";
import {IRawBetaResponse, RawBetaResponse} from "../../models/chef/RawBeta";
import {IUnleveredBeta, IUnleveredBetaResponse, UnleveredBetaParams,} from "../../models/chef/UnleveredBeta";
import {EParameters} from "../../models/EParameters";
import {ChefCalls} from "../../services/ChefCalls";
import {ICompanyBase} from "../../tables/CompanyBase";
import {fromDateDecimal, fromDateParameter, fromStr} from "../../tools/DateTools";
import {SzSvg} from "../widgets/SzSvg";
import {BaseModule} from "./BaseModule";
import {BetaFilterState} from "../../helpers/BetaFilterState";
import {renderLongListAction} from "./company_info/TableHelper";
import {ISzTableColumn, ISzTableProperties, ISzTagOptions, SzTable} from "../widgets/SzTable";
import {Globals} from "../../const/Globals";
import {average} from "../../helpers/Statistics";
import {EIndexFunction, SzTableHelper} from "../widgets/helper/SzTableHelper";
import {TableStatistics} from "../../helpers/table/TableStatistics";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";
import {PDF_IMAGE_HEIGHT, PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {EBorder, PdfTable} from "../../pdf-tools/PdfTable";
import {EPdfColumnDataType} from "../../pdf-tools/PdfTableColumn";
import {ColorMap} from "../../const/ColorMap";
import {EChartPlugins, SzChartOptions} from "../../helpers/SzChartOptions";
import {Line} from "react-chartjs-2";
import {EChartType, PdfChartToImage} from "../../pdf-tools/PdfChartToImage";
import {_fmt} from "../../helpers/Helpers";
import {SzSmallLoader} from "../hooks/SzSmallLoader";

const raw_beta_svg_cache = CompanyArrayDateMapCache("raw_beta_svg_cache");
const unlevered_beta_svg_cache = CompanyObjectDateMapCache("unlevered_beta_svg_cache", "company_id");
unlevered_beta_svg_cache.options.ignore_fields = ["company_betas_by_date"];

let ccc=0;
export class BetasRawBetaSvg extends BaseModule<any> {

    private readonly svg: React.RefObject<SzSvg> = null;
    private tableProps: ISzTableProperties<any[]>;
    private company_id_row_map: {};
    private chart: { chart_data: { labels: any; datasets: any[] }; options: any };

    constructor(props: any, context: any) {
        super(props, context);
        this.svg = React.createRef();

        this.state = {
            selected_index: 0,
            selectedYearNum: 5,
            loading_raw_beta: true,
            loading_unlevered: true,
        };
        const s = {
            loading: true,
            selected_index: 0,
            selectedYearNum: 5,
            loading_raw_beta: true,
            loading_unlevered: true,
        };
        this.state = BetaFilterState.merge(s);
        BetaFilterState.listen(this);
    }
    public async getDocumentationData() {
        const image = await this.svg.current.getSvgData();
        const companies: ICompanyBase[] = this.data.companies;
        const legend = companies.map((c)=> c.name );
        return {
            image,
            legend,
        };
    }
    public getExcelData(target) {
        const index_data = {};
        const company_data = {};
        if(!Array.isArray(this.data.company_ids)){
            target.data_betas_time_series = undefined;
            return;
        }
        const period = SessionStore.get(EParameters.PeriodsParameter);
        try {
            this.data.company_ids.forEach((cid)=>{
                const rb = this.data.raw_betas[cid];
                const series = rb.compressed_arrays[period];
                const stock_id = rb.stock_index_id;
                const stock_name = rb.stock_name;
                const company: ICompanyBase = this.data.companies[cid];
                if(!index_data[stock_id]){
                    index_data[stock_id] = {
                        stock_id,
                        stock_name,
                        companies: [],
                    };
                }
                index_data[stock_id].companies.push(cid);
                company_data[cid] = {
                    id: cid,
                    name: company.co_name || company.name,
                    series
                };
            });
        }catch (e) {
            console.error(e);
        }
        target.data_betas_time_series = {
            index_data,
            company_data,
        };
    }
    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected onAfterUpdate(data: any): void {
        this.data = data;
        const raw_beta_parameters_ext = data.raw_beta_parameters_ext;
        raw_beta_parameters_ext.betas_before= {count: 5 * 4, type: 1, step_size: 3};

        this.setState({
            loading: false,
            loading_raw_beta: true,
            loading_unlevered: true,
        });
        (async ()=>{
            const db = new ChefCalls();
            const period = SessionStore.get(EParameters.PeriodsParameter);
            const raw_betas_ext: RawBetaResponse = await db.getRawBeta(raw_beta_parameters_ext, raw_beta_svg_cache);
            const dates_decimal = Object.keys(raw_betas_ext).map((d)=> fromStr(d).asDateDecimal()).sort();
            const dates = dates_decimal.map((d)=> fromDateDecimal(d).toFormat("dd.LL.yyyy"));

            const xLabels =  dates.map((d, i)=> i%2 === 0 ? fromStr(d).toFormat("LL/yyyy") : undefined);
            const xTicks = dates.map((d, i) => i);

            this.setState({
                loading: false,
                loading_raw_beta: false,
                raw_betas_ext,
                dates_decimal,
                dates,
                period,
                xLabels,
                xTicks,
            });

            this.loadUnleveredBeta();
        })();
    }
    private loadUnleveredBeta(){
        (async ()=>{
            const dates = this.state.dates;
            const raw_betas_ext = this.state.raw_betas_ext;
            const period = this.state.period;
            const unlevered_beta_params_ext: UnleveredBetaParams = this.data.unlevered_beta_params_ext;
            const _companies = {};
            dates.forEach((d)=>{
                const companies_map = raw_betas_ext[d];
                _companies[d] = {};
                Object.keys(companies_map).forEach((company_id)=> {
                    const r:IRawBetaResponse = companies_map[company_id];
                    _companies[d][company_id] = {};
                    _companies[d][company_id][period] = {
                        beta: r.periods[period].Slope,
                        r: r.periods[period].R,
                    };
                });
            });
            const db = new ChefCalls();
            unlevered_beta_params_ext.deadline = dates as any;
            unlevered_beta_params_ext.company_betas_by_date = _companies as any;
            const unlevered_betas = await db.getUnleveredBeta(unlevered_beta_params_ext, unlevered_beta_svg_cache);
            this.setState({
                unlevered_betas,
                loading_unlevered: false,
            });
        })();
    }

    protected renderContent() {
        return (<div id={"idBetasRawBetaSvg"}>{this.data && this.data.company_ids && this.data.company_ids.length ? this.renderData() : this.noData()}</div>);
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    private renderLoading2(caption: string){
        const div_style: CSSProperties = {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: 175,
        };
        return (
            <div style={div_style}>
                <SzSmallLoader title={""}/>
            </div>
        );
    }
    private getSeriesUnleveredBeta() {
        const company_ids = this.data.company_ids;
        const raw_betas_ext = this.state.unlevered_betas;
        const dates = this.state.dates;
        const period = this.state.period;
        const ret = [];
        company_ids.forEach((company_id)=> {
            if(this.state?.show_company && !this.state?.show_company[company_id]){
                return;
            }
            const is_canceled = BetaFilterState.isCanceled(company_id, this.data);
            if(is_canceled){
                return;
            }

            const render_data = [];
            ret.push(render_data);
            dates.forEach( (date, idx) => {
                const data = raw_betas_ext[date];
                const beta: IUnleveredBetaResponse = data[company_id];
                if(beta && beta[period]){
                    const b: IUnleveredBeta = beta[period];
                    render_data.push({x: idx, y: b.result.unlevered_beta, data:  this.data.companies[company_id]});
                }else{
                    render_data.push({x: idx, y: undefined , data:  this.data.companies[company_id]});
                }
            });
        });
        return ret;
    }
    private renderData() {
        const companies: ICompanyBase[] = [];
        this.data.company_ids.forEach((id)=> {
            const is_canceled = BetaFilterState.isCanceled(id, this.data);
            if(is_canceled){
                return;
            }
            companies.push(this.data.companies[id]);
        });
        const selected_company = companies.find((c, idx)=> {
            return idx === this.state.selected_index;
        });
        return (
            <div style={{paddingTop: 16}}>
                <div className={"sz-row"}>
                    <div className={"sz-col-50"} style={{paddingRight: 25}}>
                        {this.renderTable(companies, selected_company)}
                    </div>
                    <div className={"sz-col-50"} style={{paddingLeft: 25, paddingRight: 20}}>
                        {this.renderUnleveredBeta()}
                    </div>

                </div>
            </div>
        );
    }
    private renderUnleveredBeta(){
        if(this.state.loading_unlevered){
            return this.renderLoading2(_t(ETranslation.beta_unlevered));
        }
        const series = this.getSeriesUnleveredBeta();
        const dates = this.state.dates_decimal;
        const xLabels = this.state.xLabels;
        const xTicks = this.state.xTicks;

        const o = new SzChartOptions();
        o.setCustomAxisLabel("", `${_t(ETranslation.beta_unlevered)}${Globals.superscript(1)}`);
        o.enablePlugin(EChartPlugins.TOOLTIP, {
            enabled: true,
            position: "nearest",
            callbacks: {
                title: (items)=> {
                    return fromDateDecimal(dates[items[0].dataIndex]).toFormat("LL/yyyy");
                    // return dates_formatted[parseInt(items[0].label, 10)];
                },
                label: (context)=> {
                    if(context && context.raw && context.raw.data){
                        return `${context.raw.data.co_name}: ${_fmt.m(context.raw.y)}`;
                    }
                    return "";
                },
            },
        });
        const options = {
            ...o.options,
        };
        const datasets = [];
        series.forEach((s, idx)=>{
            const dataset = {
                label: "",
                borderColor: ColorMap[`$c_${idx + 1}`],
                backgroundColor: ColorMap[`$c_${idx + 1}`],
                borderWidth: this.state.selected_index === idx ? 2 : 1,
                data: s,
            };
            datasets.push(dataset);
        });
        const chart_data = {
            labels: xLabels,
            datasets,
        };
        this.chart = {
            chart_data,
            options,
        };
        // console.error(this.chart);
        return (
            <div style={{width: "100%", height: 480}}>
                <Line data={chart_data as any} options={options}  key={`unb-${ccc++}-ts-${this.state.selected_index}`}/>
            </div>
        );
    }
    private noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }

    private renderTable(companies: ICompanyBase[], selected_company: ICompanyBase) {
        if(this.state.loading_unlevered){
            return this.renderLoading2(_t(ETranslation.beta_unlevered));
        }
        const dates: string[] = this.state.dates;
        const dates_decimal: number[] = this.state.dates_decimal;
        const unlevered_betas = this.state.unlevered_betas;
        const period = this.state.period;
        const row_data = [];
        const rows = {};
        const company_id_map = {};
        const deadline = fromDateParameter().asDateDecimal();
        const avg_2_years = fromDateParameter().minus({year: 2}).asDateDecimal();
        const avg_5_years = fromDateParameter().minus({year: 5}).asDateDecimal();
        dates_decimal.forEach((date_decimal, i)=>{
            const date_key = dates[i];
            const betas_all = unlevered_betas[date_key];
            if(!betas_all){
                return;
            }
            companies.forEach((c)=>{
                if(!rows[c.id]){
                    company_id_map[c.id] = c;
                    const row = [c.id, c.name, NaN,[],[]];
                    row_data.push(row);
                    rows[c.id] = row;
                }
                const ub = betas_all[c.id];
                if(!ub){
                    return;
                }
                const ub_result: IUnleveredBeta = ub[period];
                if(!ub_result){
                    return;
                }
                if(date_decimal === deadline){
                    rows[c.id][2] = ub_result?.result?.unlevered_beta;
                }
                if(date_decimal > avg_2_years){
                    rows[c.id][3].push(ub_result?.result?.unlevered_beta);
                }
                if(date_decimal > avg_5_years){
                    rows[c.id][4].push(ub_result?.result?.unlevered_beta);
                }
            });
        });

        const company_id_row_map = {};
        this.company_id_row_map = company_id_row_map;
        row_data.forEach((row, i)=>{
            company_id_row_map[row[0]] = i;
            // [c.id, c.name, NaN,[],[]];
            row[3] = average(row[3]);
            row[4] = average(row[4]);
        });
        const aktion_col: ISzTableColumn = {
            index: "",
            options: {
                style: {width: 30, verticalAlign: "middle"},
                cellRenderer: (cellValue: any, data?: any) => {
                    return renderLongListAction(this, company_id_map[data[0]], true);
                },
            },
        };
        const svg_col: ISzTableColumn = {
            index: "",
            options: {
                style: {borderRight: "4px solid #ffffff", width: 50, verticalAlign: "middle"},
                cellRenderer: (cellValue: any, data?: any) => {
                    if(!data){
                        return cellValue;
                    }
                    const i = company_id_row_map[data[0]];
                    return (
                        <div className={`sz-svg-line-item sz-svg-line-item-${i+1}`}></div>
                    );
                },
            },
        };
        const footerData =  TableStatistics.getTableStatistics(row_data, {
            pack: true,
            headColumn: 0,
            columns: [2,3,4],
        });
        const footer: ISzTableColumn[] = [];
        footer.push({index: ""});
        footer.push({index: ""});
        footer.push({index: "0", options: { className: "strong", colSpan:2}});
        footer.push({ ...SzTableHelper.columnMoney("1", 2, {style: {width: 100}})});
        footer.push({ ...SzTableHelper.columnMoney("2", 2, {style: {width: 80}})});
        footer.push({ ...SzTableHelper.columnMoney("3", 2, {style: {width: 80}})});
        const header_1: ISzTableColumn[] = [
            { text: "" },
            { text: "" },
            { text: _t(ETranslation.company), options:{colSpan: 2} },
            { text: `${_t(ETranslation.beta_unlevered)}${Globals.superscript(1)}`, options:{colSpan: 3, className: "sz-center"} },
        ];
        const header_2: ISzTableColumn[] = [
            { text: "" },
            { text: "" },
            { text: "#" },
            { text: "Name"},
            { text: _t(ETranslation.valuation_date), options: {className: "sz-right"}},
            { text: `${Globals.average} 2 ${_t(ETranslation.years)}`, options: {className: "sz-right"}},
            { text: `${Globals.average} 5 ${_t(ETranslation.years)}`, options: {className: "sz-right"}},
        ];
        const tableProps: ISzTableProperties<any[]> = {
            colCount: 6,
            rowIndexField: "0",
            rowIndexPrefix: "brbs_c_id",
            data: row_data,
            rowHover: true,
            beforeRenderRow: (rowData: any[], rowNum: number, options: ISzTagOptions)=>{
                if(rowNum!==this.state.selected_index){
                    return;
                }
                const class_names: string[] = options.className ? options.className.split(" ") : [];
                class_names.push("sz-row-selected-2");
                options.className = class_names.join(" ");
            },
            onSelectRow: (a, b) => {
                this.setState({selected_index: a});
            },
            footerData,
            footer,
            header: [ header_1, header_2 ],
            columns: [
                aktion_col,
                svg_col,
                { ...SzTableHelper.columnFunction(EIndexFunction.$row_num), options: {style: {width: 10} }}, // index
                { ...SzTableHelper.columnIndex("1")}, // name
                { ...SzTableHelper.columnMoney("2" , 2, {style: {width: 100, borderLeft: "4px solid #ffffff"}})},
                { ...SzTableHelper.columnMoney("3" , 2, {style: {width: 80}})},
                { ...SzTableHelper.columnMoney("4" , 2, {style: {width: 80}})},
            ],
            notes: [
                `${Globals.superscript(1)}) ${_t(ETranslation.beta_unlevered_based_on, BetaFilterState.getUnleveredBetaBase())}`,
            ],
        };
        this.tableProps = tableProps;
        return (
            <SzTable {...tableProps} />
        );
    }

    public getPdfPage(): any[] {
        const content = [];
        PdfInsertElement.page_header(content, _t(ETranslation.time_series));
        const widths: (number | "auto" | "*")[]= (new Array(6)).fill("auto").map((a,i)=>i === 2 ? "*" : "auto");
        // svg columns width
        const svg_width = 20;
        widths[0] = svg_width;

        const table = new PdfTable(content, widths);
        table.addHeaderRow();

        // icon
        table.addHeader().text("")
            .style().border([EBorder.right])
        ;
        table.addHeader().text(_t(ETranslation.company)).colSpan(2)
            .style()
            .alignment("left")
            .border([EBorder.right])
        ;
        table.addHeader().text(_t(ETranslation.beta_unlevered)).colSpan(3)
            .style().alignment("center");
        // zweite header zeile
        table.addHeaderRow();
        //
        table.addHeader().text("").style().border([EBorder.right]);
        table.addHeader().text("#").style().alignment("right");
        table.addHeader().text("Name").style().border([EBorder.right]);

        table.addHeader().text(_t(ETranslation.valuation_date)).style().alignment("right");
        table.addHeader().text(`${Globals.average} 2 ${_t(ETranslation.years)}`).style().alignment("right");
        table.addHeader().text(`${Globals.average} 5 ${_t(ETranslation.years)}`).style().alignment("right");
        // header ende

        const custom_cell = (data_idx: number)=>{
            const mk= (row_data)=>{
                const cid = row_data[data_idx];
                const color_key = `$c_${this.company_id_row_map[cid]+1}`;
                const c = ColorMap[color_key];
                return {
                    text: "•",
                    color: c,
                    bold: true,
                };
            };

            return mk;
        };

        table.addColumn(EPdfColumnDataType.text)
            .override_content(custom_cell(0))
            .index(0).style().border([EBorder.right]);
        table.addColumn(EPdfColumnDataType.row_num);
        table.addColumn(EPdfColumnDataType.text).index(1).style().border([EBorder.right]); // sector

        table.addColumn(EPdfColumnDataType.number, 2).index(2);
        table.addColumn(EPdfColumnDataType.number, 2).index(3);
        table.addColumn(EPdfColumnDataType.number, 2).index(4);

        table.addFooter(EPdfColumnDataType.text).index(-1);
        table.addFooter(EPdfColumnDataType.text).index(0).colSpan(2);

        table.addFooter(EPdfColumnDataType.number, 2).index(1);
        table.addFooter(EPdfColumnDataType.number, 2).index(2);
        table.addFooter(EPdfColumnDataType.number, 2).index(3);

        table.addRowStyle(1).border([EBorder.bottom]);
        table.addRowStyle(1 + this.tableProps.data.length).border([EBorder.bottom]);

        table.addRowStyle(2 + this.tableProps.data.length).bold(true);
        table.addRowStyle(2 + this.tableProps.data.length + 3).bold(true);

        table.populateTable(this.tableProps.data, this.tableProps.footerData);

        const png_uri = PdfChartToImage.getDataUri(EChartType.line, 1600, 480, this.chart.options, this.chart.chart_data, 0);
        const img: any = PdfInsertElement.image(content, png_uri, PDF_IMAGE_HEIGHT);
        img.pageBreak = "before";

        return content;
    }
}
