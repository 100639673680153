import {NonIdealState} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../../const/Globals";
import {Sectors} from "../../../const/Sectors";
import {SessionStore} from "../../../const/SessionStore";
import {median} from "../../../helpers/Statistics";
import {EParameters} from "../../../models/EParameters";
import {EValueName} from "../../../models/EValueName";
import {IBondTimeSeries} from "../../../models/IBondTimeSeries";
import {ISzSvgDataPoint} from "../../../models/ISzSvgDataPoint";
import {BaseModule} from "./../BaseModule";
import {SzBondChart} from "../../widgets/SzBondChart";
import {DateTime} from "luxon";
import {fromDateDecimal, fromStr, mk_duration} from "../../../tools/DateTools";
import {_t, _tn} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

type LinearFunction={n: number; m:number;};

interface IFieldMap {
    field: string;
    month: number;
}

const xTicksNew = [12, 24, 36, 60, 84, 120, 240, 360];
const fieldMap: IFieldMap[] = [
    {field: "v_1_m", month: 1},
    {field: "v_3_m", month: 3},
    {field: "v_6_m", month: 6},
    {field: "v_1_j", month: 12},
    {field: "v_2_j", month: 24},
    {field: "v_3_j", month: 36},
    {field: "v_5_j", month: 60},
    {field: "v_7_j", month: 84},
    {field: "v_10_j", month: 120},
    {field: "v_20_j", month: 240},
    {field: "v_30_j", month: 360},
];

const durations = [
    mk_duration(0.5, 1.5),
    mk_duration(1.5, 2.5),
    mk_duration(2.5, 3.5),
    mk_duration(3.5, 6.5),
    mk_duration(6, 8),
    mk_duration(8, 12),
    mk_duration(15, 25),
    mk_duration(25, 30),
];
const slope_durations = [
    mk_duration(1, 2),
    mk_duration(2, 3),
    mk_duration(3, 5),
    mk_duration(5, 7),
    mk_duration(7, 10),
    mk_duration(10, 20),
    mk_duration(20, 30),
];
const get_pot_idx = (x: number /*qd diff mat_date*/) => {
    const p = durations.findIndex( (d) => x >= d.start && x < d.end );
    if(p === -1){
        return undefined;
    }else{
        return p;
    }
};
const get_slope_idx = (x: number /*qd diff mat_date*/, fnk_compare) => {
    const fnk = fnk_compare ? (d) => x >= d.start && x < d.end : (d) => x > d.start && x <= d.end;
    const p = slope_durations.findIndex( (d) => fnk(d));
    if(p === -1){
        return undefined;
    }else{
        return p;
    }
};
// const old_date_decimal = 20220531;
// const dd = parseInt(DeDate.dateDE2decimalDate(SessionStore.get(EParameters.DateParameter)), 10);
export class CorporateSpreadNew extends BaseModule<any> {

    private valueSelected: number = Number.NaN;
    private value: number = Number.NaN;

    private valueAll: number = Number.NaN;
    private sector: string = "";
    private regressionData: ISzSvgDataPoint[] = [];
    private regressionData_all: ISzSvgDataPoint[] = [];

    private m_data: LinearFunction[] = [];
    private m_data_all: LinearFunction[] = [];

    private max_y_all = 0;
    private max_y = 0;

    private stats = {};

    private old_mouse_move_x = {};

    constructor(props: any, context: any) {
        super(props, context);
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected renderContent() {
        this.old_mouse_move_x = {};
        if (!this.data) {
            return this.noData("C1");
        }
        if (!this.data.corporateSpread) {
            return this.noData("C2");
        }
        return (<div id={"idCorporateSpread"}>{this.renderData()}</div>);
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    private renderData() {
        const sector = SessionStore.get(EParameters.SectorParameter);
        this.sector = sector;

        return (
            <div className={"sz-row"}>
                <div className={"sz-col-100"} style={{position: "relative"}}>
                    <div style={{marginBottom: 20, fontSize: "80%"}}>
                        <strong>{_t(ETranslation.credit_spread_term_stucture)}{Globals.superscript(1)} [% p.a.]</strong>
                        <br/>
                        {this.getSelectedSector()}
                    </div>
                    {this.renderSVGNew()}
                </div>
            </div>
        );
    }
    private getSelectedSector(only_label?) {
        const sector = SessionStore.get(EParameters.SectorParameter);
        const ff = Sectors.values.find((item) => parseInt(item.value, 10) === parseInt(sector,10));
        const s = ff ? ff.label : _t(ETranslation.sector_all_avg);
        if(only_label){
            return s;
        }
        const rating: string[] = SessionStore.get(EParameters.RatingParameter);
        const r0 = rating[0];
        const r1 = rating[rating.length - 1];
        const r = r0 === r1 ? r1 : `${r0} ${_t(ETranslation.to)} ${r1}`;

        return `${s}, ${_t(ETranslation.rating)}: ${r}`;
    }
    private getSeriesNew() {
        const qualifying_date: DateTime = fromStr(SessionStore.get(EParameters.DateParameter)); // moment(SessionStore.get(EParameters.DateParameter), "DD.MM.YYYY");
        const selected_sector = SessionStore.get(EParameters.SectorParameter) === "0000" ? undefined : SessionStore.get(EParameters.SectorParameter);
        const ret = [];
        this.regressionData = [];
        this.regressionData_all = [];
        this.m_data_all = (new Array(xTicksNew.length)).fill({n: NaN, m: NaN});
        this.m_data = (new Array(xTicksNew.length)).fill({n: NaN, m: NaN});
        if (!this.data) {
            return ret;
        }
        if (!this.data.bond_data) {
            return ret;
        }
        const renderData: ISzSvgDataPoint[] = [];
        const renderAllData: ISzSvgDataPoint[] = [];
        const regressionData: ISzSvgDataPoint[] = [];
        const regressionAllData: ISzSvgDataPoint[] = [];
        // const xy = (item, field) => ({x: field.month, y: item[field.field] / 100 });
        const bond_data = this.data.bond_data as IBondTimeSeries[];
        const stats = {};

        const pots_sector = (new Array(xTicksNew.length)).fill(0).map(()=>new Object());
        const pots_all = (new Array(xTicksNew.length)).fill(0).map(()=>new Object());
        const push_to_pot = (bond: IBondTimeSeries, target_pot, x: number, y:number)=> {
            const idx = get_pot_idx(x);
            if(idx === undefined){
                return;
            }else{
                const key = bond.fi_identifier_pticker;
                if(!target_pot[idx][key]) {
                    target_pot[idx][key] = [];
                }
                target_pot[idx][key].push(y);
            }
        };
        const median_from_company= (company_map)=>{
            const m = [];
            // console.error(Object.keys(company_map).length);
            Object.keys(company_map).forEach((key)=>{
                const ar = company_map[key];
                m.push(median(ar));
            });
            return median(m);
        };
        bond_data.forEach( (i) => {
            if(i.ftid_spread < 0){
                return;
            }
            if(i.ftid_spread > 2000){
                return;
            }
            const mat_date = fromDateDecimal(i.mat_date);
            const x = mat_date.diff(qualifying_date, "months").months;
            if((x > 30 * 12) || (x < 6)){
                return;
            }
            const y = i.ftid_spread / 100;

            if(selected_sector && selected_sector===i.sector_id){
                renderData.push({
                    x,y, dataSet: i
                });
                push_to_pot(i, pots_sector, x, y);
                // regression_data.push([x,y]);
            }
            // regression_all_data.push([x,y]);
            push_to_pot(i, pots_all, x, y);
            renderAllData.push({
                x,y, dataSet: i
            });
            if(!stats[i.sector_id]){
                stats[i.sector_id] = 0;
            }
            stats[i.sector_id] ++;
        } );
        this.stats = stats;
        // console.error(selected_sector);
        // console.error(pots_sector);
        pots_sector.forEach((pot, idx)=>{
            const me = median_from_company(pot);
            const y = isNaN(me) ? undefined : me;
            const x = xTicksNew[idx];
            if(y>this.max_y){
                this.max_y = y;
            }

            const use = regressionData[idx -1];
            if(use){
                const dx = x - xTicksNew[idx -1];
                const dy = y - use.y;
                const m = dy / dx;
                // console.error(regressionData[idx -1].y, y);
                this.m_data[idx - 1] =  {m, n: y - (m*x)}   ;
            }

            regressionData.push({x,y, isLineData: 2} as any);
        });
        this.fillData(regressionData, this.m_data);
        pots_all.forEach((pot, idx)=>{
            const me = median_from_company(pot);
            const y = isNaN(me) ? undefined : me;
            const x = xTicksNew[idx];
            if(y>this.max_y_all){
                this.max_y_all = y;
            }
            const use = regressionAllData[idx - 1]
            if(use){
                const dx = xTicksNew[idx] - xTicksNew[idx -1];
                const dy = y - use.y;
                const m = dy / dx;
                this.m_data_all[idx - 1] =  {m, n: y - (m*x)}  ;
            }

            regressionAllData.push({x,y, isLineData: 1} as any);
        });
        // console.error(this.m_data);
        // console.error(regressionData);
        ret.push(renderData);
        ret.push(renderAllData);
        // ret.push(regressionData.sort((pa,pb) => pa.x - pb.x));
        this.regressionData = regressionData; // .sort((pa,pb) => pa.x - pb.x);
        this.regressionData_all = regressionAllData; // .sort((pa,pb) => pa.x - pb.x);
        return ret;
    }
    private getSectorSpreadData() {
        const selected_sector = SessionStore.get(EParameters.SectorParameter) === "0000" ? undefined : SessionStore.get(EParameters.SectorParameter);
        const dt = SessionStore.get(EParameters.RuntimeParameter);
        const range = dt.split(",");
        const s = parseInt(range[0], 10);
        const start = s<12 ? 12 : s;
        const stop = parseInt(range[1], 10);
        // console.error(start, stop);
        let sum = 0;
        let cc=0;

        const use_slopes = selected_sector ? this.m_data : this.m_data_all;
        // console.error(slope_durations);
        // console.error(use_slopes);
        for(let pot=0; pot<=Math.trunc( (stop - start) / 12 ); pot++){
            const pot_index =  get_slope_idx(start + (pot * 12), pot === 0);
            if(pot_index === undefined){
                sum = NaN;
                break;
            }
            const n_m = use_slopes[pot_index];
            const fx = (x)=> n_m.n + n_m.m * x;
            // console.error(start + (pot * 12), pot*12 , pot_index, fx(start + (pot * 12)), n_m);
            sum += (fx(start + (pot * 12)));
            cc++;
        }
        if(stop - start >  0 && cc === 1){
            sum = NaN;
        }
        const value = cc > 0 ? sum/cc : NaN;
        SessionStore.setGlobalVar(EValueName.credit_spreads, value / 100);
        return [
            {x: start, y: value, isSpreadValue: 1},
            {x: stop, y: value, isSpreadValue: 2},
        ];

    }
    private renderSVGNew() {
        if(!this.data.bond_data){
            return this.noData("N3");
        }
        if(!Array.isArray(this.data.bond_data)){
            return this.noData("N4");
        }
        if(!this.data.bond_data.length){
            return this.noData("N5");
        }
        const selected_sector = SessionStore.get(EParameters.SectorParameter) === "0000" ? undefined : SessionStore.get(EParameters.SectorParameter);
        // console.error(selected_sector);
        // const series = this.getSeries();
        const [renderData, renderData_all] = this.getSeriesNew();
        if(selected_sector && !renderData.length){
            return this.noData("N1");
        }

        const sector_label = this.getSelectedSector();

        // https://www.chartjs.org/docs/latest/axes/labelling.html
        const data = selected_sector ? renderData : renderData_all;
        // console.error(data);
        const chart_data = {
            datasets: [
                {
                    label: _t(ETranslation.bonds),
                    data,
                    datalabels: {
                        labels: {
                            title: null
                        }
                    }
                },
            ]
        };
        if(Array.isArray(this.regressionData_all)){
            chart_data.datasets.push({
                type: 'line',
                label: _t(ETranslation.sector_all_avg),
                data: this.regressionData_all,
                backgroundColor: "#632819",
                borderColor: "#632819",
                borderDash: [7,2,2,2],
                borderWidth: 1,
                pointRadius: 3,
                pointBorderWidth: 2,
                pointHitRadius: 3,
                pointBorderColor: "#ffffff",
                order: -1,
                clip: 10,
                datalabels: {
                    labels: {
                        title: null
                    }
                }
            } as any);
        }
        if(selected_sector && Array.isArray(this.regressionData)){
            chart_data.datasets.push({
                type: 'line',
                label: this.getSelectedSector(true),
                data: this.regressionData,
                backgroundColor: "#137cbd",
                borderColor: "#137cbd",
                borderDash: [7,2,2,2],
                borderWidth: 1,
                pointRadius: 3,
                pointBorderWidth: 2,
                pointHitRadius: 3,
                pointBorderColor: "#ffffff",
                order: -2,
                clip: 10,
                datalabels: {
                    labels: {
                        title: null
                    }
                }
            } as any);
        }
        const spread_value = this.getSectorSpreadData();
        // console.error(spread_value);
        // https://chartjs-plugin-datalabels.netlify.app/guide/positioning.html#clamping
        if(spread_value && Array.isArray(spread_value) && spread_value.length && !isNaN(spread_value[0].y)){
            chart_data.datasets.push({
                order: -1,
                type: spread_value[0].x === spread_value[1].x ? "bubble" : "line",
                label: _t(ETranslation.credit_spread),
                data: spread_value,
                backgroundColor: "#f47a22",
                borderColor: "#f47a22",
                borderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 3,
                datalabels: {
                    align: "-30",
                    anchor: "end",
                    offset: 5,
                    backgroundColor: "rgba(255,255,255,0.8)",
                    /*borderColor: "rgba(185,185,185,0.5)",
                    borderWidth: 1,
                    padding: 2,
                    */
                    formatter: (value, context, a)=>{
                        if(value.isSpreadValue===1){
                            return `${sector_label}: ${Globals.formatter(value.y)}`;
                        }
                        return null;
                    },
                    labels: {
                        title:{
                            color: "#f47a22",
                        }
                    }
                }
            } as any);
        }
        const legend = {
            display: true,
            position: "top", // "chartArea",
            align: "center",
            labels: {
                boxWidth: 10,
            }
        };

        return (
            <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                <SzBondChart chart_data={chart_data} legend={legend} />
            </div>
        );
    }
    private noData(error_pos: string) {
        return (<NonIdealState
            icon={"database"}
            title={`Keine Daten (${error_pos})`}
            description="Die Kombination aus Stichtag, Währung, Sector und Rating liefert keine Ergebnisse."
        />);
    }

    private fillData(source: ISzSvgDataPoint[], linear_function: LinearFunction[]) {
        let first_defined: ISzSvgDataPoint;
        let first_index: number;
        // console.error(JSON.parse(JSON.stringify(linear_function)));
        // console.error(JSON.parse(JSON.stringify(source)));
        source.forEach((point, idx)=>{
            if(point.y !== undefined){
                first_defined = point;
                first_index = idx;
            }else{
                if(first_defined){
                    const last_index = source.findIndex((p, p_idx) => idx < p_idx && p.y !== undefined);
                    const last_defined = source[last_index];
                    if(!last_defined){
                        return;
                    }
                    const dx = last_defined.x - first_defined.x;
                    const dy = last_defined.y - first_defined.y;
                    const m = dy / dx;
                    const n = last_defined.y - (m*last_defined.x);
                    const fx = (x)=> n + m * x;
                    // console.error(first_index, last_index);
                    for(let i = first_index; i < last_index; i++){
                        linear_function[i] =  {m, n};
                        // source[i].y = fx(source[i].x);
                    }
                    // point.y = fx(point.x);
                    // console.error("set", idx, {m, n});
                }
            }
        });
        // console.error("neu lf",linear_function);
        // console.error("neu",source);
    }
}
