import * as React from "react";
import {Globals} from "../../const/Globals";
import {Sectors} from "../../const/Sectors";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";


import {Chart as ChartJS, Legend, LinearScale, LineElement, LogarithmicScale, PointElement, Tooltip,} from 'chart.js';
import {Bubble} from 'react-chartjs-2';
import {RegressionLine} from "../../chart-plugin/RegressionLine";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {EChartType, PdfChartToImage} from "../../pdf-tools/PdfChartToImage";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

ChartJS.register(LinearScale, LogarithmicScale, PointElement, LineElement, Tooltip, Legend, RegressionLine,ChartDataLabels);

const xLabels = ["0","1", "2", "3", "5", "7", "10", "20", "30"];
const xTicksNew = [12, 24, 36, 60, 84, 120, 240, 360];

export interface ISzBondChartProperties {
    chart_data: any;
    get_tooltip_label?(item);
    legend?: any;
}
export class SzBondChart extends React.Component<ISzBondChartProperties, any> {

    private chart_options;
    constructor(props: any, context: any) {
        super(props, context);
    }
    public render() {
        const selected_sector = SessionStore.get(EParameters.SectorParameter) === "0000" ? undefined : SessionStore.get(EParameters.SectorParameter);
        const ff = Sectors.values.find((item) => item.value === selected_sector);
        const get_tooltip_label = (item)=> {
            if(item.raw.isLineData === 1){
                return `${_t(ETranslation.sector_all)} ${Globals.formatter(item.raw.y)}`;
            }
            if(item.raw.isLineData){
                return `${ff ? ff.label : Globals.hyphen} ${Globals.formatter(item.raw.y)}`;
            }
            if(item.raw.isSpreadValue){
                return undefined;
            }

            const d = item.raw.dataSet;
            if(!d){
                return undefined;
            }
            return `${d.id} ${d.currency_iso_3} ${d.rating}`;
        };
        const legend = this.props.legend ? this.props.legend : {display: false,};
        const options= {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        stepSize: 1,
                        z: 10,
                        padding: 10,
                        callback: (value, index, labels)=>{
                            return Math.trunc(value);
                        },
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: 0,
                    max: 8,
                },
                x: {
                    ticks: {
                        stepSize: 12,
                        z: 10,
                        padding: 10,
                        callback: (value, index, labels)=>{
                            const idx = xTicksNew.indexOf(value);
                            if(idx >= 0){
                                const v = Math.trunc(value / 12);
                                return v;
                            }
                            return null;
                        }
                        /*maxRotation: 0,*/
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                        lineWidth: (item)=>{
                            if(item && item.tick && xLabels.indexOf(`${Math.trunc(item.tick.value/12)}`)>=0){
                                return 1;
                            }
                            return 0;
                        }
                    },
                    min: 0,
                    max: 360,
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend,
                datalabels: {
                    display: 1,
                },
                tooltip: {
                    enabled: true,
                    mode: "point",
                    filter: (tooltipItem)=>{
                        return !tooltipItem.raw.isSpreadValue;
                    },
                    callbacks: {
                        label: this.props.get_tooltip_label ? this.props.get_tooltip_label : get_tooltip_label,
                        title: ()=> null,
                    },
                },
            },
            custom_axis_label: {
                x_label: _t(ETranslation.years),
                y_label: "[% p.a.]",
            }
        };
        this.chart_options = options;
        return (
            <Bubble options={options as any} data={this.props.chart_data as any} height={"100%"} width={"100%"} />
        );
    }

    public getImage(w: number = 1125, h: number = 500): string{
        const s = PdfChartToImage.getDataUri(EChartType.bubble, w, h, this.chart_options, this.props.chart_data);
        return s;
    }
}
