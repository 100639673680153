import {Button, HTMLSelect, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {ChangeEvent} from "react";
import {Globals} from "../../const/Globals";
import {BaseModule} from "./BaseModule";
import {ICompanyBase} from "../../tables/CompanyBase";
import {InfoTable} from "./analysis_tool/InfoTable";
import {RegressionChart} from "./analysis_tool/RegressionChart";
import {RegressionResidualsChart} from "./analysis_tool/RegressionResidualsChart";
import {RegressionResidualsHistogram} from "./analysis_tool/RegressionResidualsHistogram";
import {CrossSectionalAnalysis} from "./analysis_tool/CrossSectionalAnalysis";
import {TimeSeries} from "./analysis_tool/TimeSeries";
import {ResidualsChart} from "./analysis_tool/ResidualsChart";
import {TimeStability5Days} from "./analysis_tool/TimeStability5Days";
import {removeCompany} from "./company_info/TableHelper";
import {BetaFilterState} from "../../helpers/BetaFilterState";
import {PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {EBorder, PdfTable} from "../../pdf-tools/PdfTable";
import {EPdfColumnDataType} from "../../pdf-tools/PdfTableColumn";
import {Sectors} from "../../const/Sectors";

const mkTextLink =(colCaptionIdx, colLinkIdx)=>{
    const mk = (row_data)=>{
        return (
            {
                text: row_data[colCaptionIdx],
                link: row_data[colLinkIdx],
            }
        );
    };
    return mk;
};

export class AdminToolV2 extends BaseModule<any> {
    constructor(props: any, context: any) {
        super(props, context);

        const s = {
            loading: true,
            loading_details: true,
            selectedCompany: undefined,
        };
        this.state = BetaFilterState.merge(s);
        BetaFilterState.listen(this);
    }
    public getPdfPage(): any[] {
        const company_ids: string[] = this.data.company_ids;
        const companies: {[company_id: string]: ICompanyBase} = this.data.companies;
        if(!(Array.isArray(company_ids) && company_ids.length)){
            return undefined;
        }
        const content = [];
        PdfInsertElement.page_header(content, "Firmen");
        const widths: (number | "auto" | "*")[]= ["auto","auto","auto","auto","auto"];
        // name, land, primärnotiz, sektor, desk
        const table = new PdfTable(content, widths);
        table.addHeaderRow();
        table.addHeader().text(_t(ETranslation.company))
            .style()
            .border([EBorder.right])
            .noWrap(true)
        ;
        table.addHeader().text(_t(ETranslation.country));
        table.addHeader().text(_t(ETranslation.stock_index));
        table.addHeader().text(_t(ETranslation.sector))
            .style()
            .noWrap(true)
            .border([EBorder.right]);
        table.addHeader().text("Beschreibung");

        table.addColumn(EPdfColumnDataType.text).index(0)
            .override_content(mkTextLink(0, 5))
            .style()
            .noWrap(true)
            .border([EBorder.right]);
        table.addColumn(EPdfColumnDataType.text).index(1);
        table.addColumn(EPdfColumnDataType.text).index(2);
        table.addColumn(EPdfColumnDataType.text).index(3)
            .style()
            .noWrap(true)
            .border([EBorder.right]);
        table.addColumn(EPdfColumnDataType.text).index(4);

        const data = [];
        company_ids.forEach((id)=>{
            const c: ICompanyBase = companies[id];
            const row = [
                c.co_name,
                Globals.country_map["" + c.ex_country_id].iso_3,
                c.p_exchange,
                Sectors.sectors_map[c.sector_id],
                c.description,
                c.home_page,
            ];
            data.push(row);

        });
        data.forEach((d, row_num)=>{
            table.addRowStyle(row_num)
                .border([EBorder.bottom]);
        });
        table.populateTable(data);
        return content;
    }
    protected update(selectedCompany: ICompanyBase) {
        // company selected
        this.setState({loading_details: false});
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;
        const company_ids: string[] = this.data.company_ids;
        const companies: {[company_id: string]: ICompanyBase} = this.data.companies;
        const first_company = companies[company_ids[0]];
        const selectedCompany: ICompanyBase = this.state.selectedCompany;
        const c = selectedCompany ? companies[selectedCompany.id] : undefined;
        this.setState({loading: false, selectedCompany: c ? c : first_company});
        this.update(c ? c : first_company);
    }
    protected renderContent() {
        if (this.state.error) {
            return (
                <NonIdealState icon={IconNames.WARNING_SIGN} title={"Serverfehler"} description={this.state.error}  />
            );
        }
        const company_ids: string[] = this.data.company_ids;
        if(!Array.isArray(company_ids)){
            return this.renderNoData();
        }
        if(!company_ids.length){
            return this.renderNoData();
        }
        if(!this.state.selectedCompany){
            return this.renderNoData();
        }
        const companies: {[company_id: string]: ICompanyBase} = this.data.companies;
        const company: ICompanyBase = this.state.selectedCompany ? this.state.selectedCompany : {};
        const optCompanies = company_ids.filter((id)=> companies[id]).map( (id) => ({label: companies[id].name, value: id}) );
        const optValue = this.state.selectedCompany ? this.state.selectedCompany.id : null;
        const setCompany = (company_id) => {
            const sel = companies[company_id]; // this.companies.find( (c) => c.company_id === company_id );
            this.setState({
                loading_details: true,
                selectedCompany: sel,
            });
            this.update(sel);
        };
        return (
            <div style={{overflow: "hidden", position: "relative", paddingTop: 16}} className={"company-popup"}>
                <div className={"header"}>
                    <table style={{width: "100%"}} className={"header-table"}>
                        <tbody>
                        <tr>
                            <td>
                                <div style={{alignItems: "center", display: "flex", width: "100%"}}>
                                    <img alt={"country"} src={`${Globals.BASE_DIR}images/flags/${Globals.country_map[company.country_id]?.iso_2.toLowerCase()}.jpg`} style={{height: 24}}/>
                                    &nbsp;&nbsp;<span className={"name-button"}>{company.name}</span>
                                </div>
                            </td>
                            <td>
                                <div style={{float: "right", display: "flex", alignItems: "center"}} >
                                    <HTMLSelect value={optValue} options={optCompanies} onChange={(e: ChangeEvent<HTMLSelectElement>) => { setCompany(e.target.value); }} />
                                    &nbsp;&nbsp;
                                    <Button icon={IconNames.TRASH} intent={"danger"} onClick={()=> removeCompany(this, null, "" + company.id)}/>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                {this.renderDetails()}
            </div>
        );
    }
    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    private renderNoData(){
        return (
            <NonIdealState
                icon={IconNames.OFFICE}
                title={"Kein Unternehmen"}
                description={"Ihre Peer-Group ist leer."}
            />
        );
    }
    private renderTable(){
        return (
            <InfoTable company={this.state.selectedCompany} data={this.data}  key={this.state.selectedCompany.id} />
        );
    }
    private renderRegressionDiagram(){
        return (
            <RegressionChart company={this.state.selectedCompany} data={this.data} />
        );
    }
    private renderRegressionResidualsDiagram(){
        return (
            <RegressionResidualsChart company={this.state.selectedCompany} data={this.data} />
        );
    }
    private renderResidualsChartDiagram(){
        return (
            <ResidualsChart company={this.state.selectedCompany} data={this.data} />
        );
    }

    private renderRegressionResidualsHistogram(){
        return (
            <RegressionResidualsHistogram company={this.state.selectedCompany} data={this.data} />
        );
    }
    private renderCrossSectionalAnalysis(){
        return (
            <CrossSectionalAnalysis company={this.state.selectedCompany} data={this.data} />
        );
    }
    private renderTimeStability(){
        return (
            <TimeStability5Days company={this.state.selectedCompany} data={this.data} key={this.state.selectedCompany.id} />
        );
    }
    private renderTimeSeries(){
        return (
            <TimeSeries company={this.state.selectedCompany} data={this.data} key={this.state.selectedCompany.id} />
        );
    }

    private renderDetails(){
        const company:ICompanyBase = this.state.selectedCompany;
        const raw_betas = this.data.raw_betas;
        const raw_beta = raw_betas[company?.id];
        if(!raw_beta){
            return(
                <NonIdealState
                    icon={IconNames.OFFICE}
                    title={company.name}
                    description={"Zu diesem Unternehmen wurde kein Beta-Faktor berechnet."}
                />
            );
        }
        return (
            <div className={"sz-row"}>
                <div className={"sz-col"} style={{width: 350}}>
                    {this.renderTable()}
                </div>
                <div className={"sz-col"} style={{width: "calc(100% - 350px)"}}>
                    <div className={"sz-row"}>
                        <div className={"sz-col sz-col-40"}>
                            {this.renderRegressionDiagram()}
                        </div>
                        <div className={"sz-col sz-col-60"}>
                            <div className={"sz-row"}>
                                <div className={""} style={{width: "calc(100% / 3)", paddingLeft: 4, paddingRight: 4}}>
                                    {this.renderResidualsChartDiagram()}
                                </div>
                                <div className={""} style={{width: "calc(100% / 3)", paddingLeft: 4, paddingRight: 4}}>
                                    {this.renderRegressionResidualsDiagram()}
                                </div>
                                <div className={""} style={{width: "calc(100% / 3)", paddingLeft: 4, paddingRight: 4}}>
                                    {this.renderRegressionResidualsHistogram()}
                                </div>
                            </div>
                            <div className={"sz-row"} style={{marginTop: 10}}>
                                <div className={"sz-col sz-col-50"}>
                                    {this.renderTimeStability()}
                                </div>
                                <div className={"sz-col sz-col-50"}>
                                    {this.renderCrossSectionalAnalysis()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: 10}}>
                        {this.renderTimeSeries()}
                    </div>
                </div>
            </div>
        );
    }
}
