import * as React from "react";
import {Plugin,} from 'chart.js';

export enum EHighlightValueDisplay {
    off= 1,
    on,
}
export interface IHighlightValue {
    display?: EHighlightValueDisplay;
    value?: number;
    line_color?: string;
    line_dash?: number[];
}
const defaults: IHighlightValue = {
    display: EHighlightValueDisplay.on,
    line_color: "#f47a22",
    line_dash: undefined,
};
class HighlightValueClass{
    public id = "highlight_value";
    public render_value(chart, val, line_color, line_dash){
        const v_x = parseFloat(val);
        if(isNaN(v_x) || val===null || val===undefined){
            return;
        }

        const min_x = chart.scales.x.min;
        const max_x = chart.scales.x.max;
        const left = chart.scales.x.left;
        const min_y = chart.scales.y.min;
        const max_y = chart.scales.y.max;
        const top = chart.scales.y.top;
        const height = chart.scales.y.height;
        const width = chart.scales.x.width;

        const x_factor = width / (max_x - min_x);

        const context: CanvasRenderingContext2D = chart.ctx;
        context.save();
        if(line_dash){
            context.setLineDash(line_dash);
        }
        if(v_x !== undefined){
            const x3 = left + (Math.abs(min_x)*x_factor) + x_factor*v_x;
            context.beginPath();
            context.strokeStyle = line_color;
            context.moveTo(x3, top);
            context.lineTo(x3, top + height);
            context.stroke();
        }
        context.restore()
    }

    public afterDatasetsDraw(chart, args, options){
        if(!Array.isArray(chart.options.highlight_value)){
            return true;
        }
        const h: IHighlightValue[] = chart.options.highlight_value;
        h.forEach((i)=>{
            const o: IHighlightValue = Object.assign({},defaults, i);
            if(o.display === EHighlightValueDisplay.on){
                this.render_value(chart, o.value, o.line_color, o.line_dash);
            }
        });
        return true;
    }
    public beforeDatasetsDraw(chart, args, options){
        return true;
    }
}

export const HighlightValue = (new HighlightValueClass()) as Plugin;
