import {Button, Icon, Intent, Menu, MenuItem, Popover,} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {ETranslation} from "../../const/ETranslation";
import {Globals} from "../../const/Globals";
import {Sectors} from "../../const/Sectors";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {_t} from "../../tools/Translator";
import {BaseFilter, IBaseFilterProperties, IBaseFilterState} from "./BaseFilter";

interface ISectorEntry {
    code: string;
    sector: string;
    subSector: string;
}
export interface ISelection {
    sector: string;
    subSector: string;
}
interface ISector {
    id: string;
    name: string;
    companyCount: number;
}
interface ISubSector {
    id: number;
    name: string;
    companyCount: number;
}
interface ISectorFilterProperties extends IBaseFilterProperties {
    selectedCountries?: any;
    sector: ISelection;
}
interface ISectorFilterState extends IBaseFilterState {
    loadingSelection: boolean;
    selectedSubSector?: string;
    isOpen: boolean;
}

export class SectorFilter extends BaseFilter<ISectorFilterProperties, ISectorFilterState> {
    private items: ISectorEntry[] = [];
    private sectors: ISector[] = [];
    private sectorMap: Map<string, string> = new Map<string, string>();
    private subSectors: ISector[] = [];

    private selected: ISelection = {
        subSector: null,
        sector: null,
    };

    constructor(props: any, context: any) {
        super(props, context);
        if (this.props.sector) {
            this.selected.subSector = this.props.sector.subSector;
            this.selected.sector = this.props.sector.sector;
        }
        this.state = {
            loading: true,
            loadingSelection: true,
            selectedSubSector: this.selected.subSector,
            isOpen: false,
        };
    }
    public componentDidMount(): void {
        const db = new PublicDbCalls();
        (async () => {
            await this.getAllSectors(db);
            await this.loadSectors();
            this.setState({
                loading: false,
            });
        })();
    }

    protected resetFilter() {
        this.selected.subSector = null;
        this.selected.sector = null;
        this.setState({
            selectedSubSector: null,
        });
    }

    protected renderContent() {
        let reload = false;
        if (this.props.sector) {
            if (this.selected.sector !== this.props.sector.sector) {
                this.selected.sector = this.props.sector.sector;
                reload = true;
            }
            if (this.selected.subSector !== this.props.sector.subSector) {
                this.selected.subSector = this.props.sector.subSector;
                reload = true;
            }
        }
        if (reload) {
            this.setState({
                selectedSubSector: this.selected.subSector,
                loadingSelection: true,
            });
            const data = {
                isSubSector: this.selected.subSector ? true : false,
                code: this.selected.subSector ? this.selected.subSector : this.selected.sector,
            };
            super.valueChanged(data);
            return this.renderLoading();
        }

        return (
            <div>
                { this.state.loading ? this.renderLoading() : this.renderMyContent() }
            </div>
        );
    }
    protected renderMyContent(): any {
        const active = this.selected.sector || this.selected.subSector;
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        let intent: Intent = active ? Intent.WARNING : Intent.NONE;
        if (this.state.isOpen) {
            intent = Intent.PRIMARY;
        }
        const testCanClose = (ev) => {
            if (!ev) {
                return;
            }
            if (this.state.isOpen && ev.type === "mousedown") {
                this.setState({isOpen: false});
            }
        };
        return(
            <Popover
                minimal={true}
                position={"bottom-left"}
                boundary={"viewport"}
                usePortal={true}
                hasBackdrop={true}
                popoverClassName={"sz-popover"}
                onOpening={() => this.loadData()}
                onClosed={ ()=> this.onClosed()}
                modifiers={popper}
                isOpen={this.state.isOpen}
                canEscapeKeyClose={true}
                captureDismiss={false}
                onInteraction={(a, b) => testCanClose(b)}
            >
                <Button
                    intent={intent} text={_t(ETranslation.sector)}
                    icon={"tractor"}
                    minimal={false}
                    onClick={() => this.setState({isOpen: !this.state.isOpen})}
                />
                <div className={"sz-search-filter-list"} style={{margin: 8}}>
                    <div className={"sz-row"}>
                        {this.renderSectors()}
                        {this.subSectors.length ? this.renderSelection() : null}
                    </div>
                </div>
            </Popover>
        );
    }
    protected renderLoading() {
        return (
            <Button
                className={"bp3-skeleton"}
                icon={"office"}
                style={{minWidth: 180}}
                minimal={false} />
        );
    }

    private getIcon(value: string, selectedValue: string) {
        if (value === selectedValue ) {
            return <Icon icon={"tick"}/>;
        } else {
            return null;
        }
    }
    private renderLoadingDefault2() {
        return (
            <Menu className={""}>
                {[1, 2, 3, 4, 5].map((r) => {
                    return (<MenuItem text={"läd ........."} className={"bp3-skeleton"} />);
                })}
            </Menu>
        );
    }
    private renderSectors() {
        if (this.state.loadingSelection) {
            // return super.renderLoadingDefault();
        }
        return (
            <Menu className={""}>
                {this.sectors.map((r) => {
                    return (<MenuItem text={`${Sectors.sectors_map[r.id]} [${Globals.formatter(r.companyCount, 0)}]`} onClick={() => this.selectSector(r.id)} labelElement={this.getSectorIcon(r.id)} intent={this.getSectorIntent(r.id)} />);
                })}
            </Menu>
        );
    }

    private renderSelection() {
        if (this.state.loadingSelection) {
            return this.renderLoadingDefault2();
        }
        return (
            <Menu className={""}>
                {this.subSectors.map((r) => {
                    return (<MenuItem text={`${Sectors.sectors_map[r.id]} [${Globals.formatter(r.companyCount, 0)}]`} intent={this.getSubSectorIntent(r.id)} labelElement={this.getSubSectorIcon(r.id)} onClick={() => this.selectSubSector(r.id)} />);
                })}
            </Menu>
        );
    }
    private loadData() {
        (async () => {
            this.setState({
                loadingSelection: true,
            });
            if (this.selected.subSector) {
                await this.loadSubSectors();
            } else {
                await this.loadSectors();
            }
            this.setState({
                loadingSelection: false,
            });
        })();
    }
    private getSector() {
        if (this.selected.sector) {
            return this.sectorMap.get(this.selected.sector);
        } else {
            return "Sektorauswahl";
        }
    }
    private async loader(loadFunction: any): Promise<ISector[]> {
        const response = await loadFunction();
        try {
            const result = response.result;
            if (Array.isArray(result)) {
                return result.map((e) => {
                    const item = {
                        id: "" + e[0],
                        name: e[1],
                        companyCount: e[2],
                    };
                    return item;
                }) as ISector[];
            }
        } catch (exi) {
            console.error(exi, response);
        }
        return [];
    }
    private async loadSubSectors() {
        const db = new PublicDbCalls();
        this.subSectors = await this.loader(async () => {
            return await db.getSubSectors(this.selected.sector);
        });
    }
    private async loadSectors() {
        const db = new PublicDbCalls();
        this.sectors = await this.loader(async () => {
            return await db.getSectors();
        });
    }

    private async getAllSectors(db: PublicDbCalls) {
        const response = await db.getAllSectors();
        try {
            const result = response.result;
            if (Array.isArray(result)) {
                this.items = result.map((e) => {
                    const item = {
                        code: "" + e[0],
                        sector: e[1],
                        subSector: e[2],
                    };
                    if (item.sector) {
                        this.sectorMap.set(item.code, item.sector);
                    }
                    return item;
                }) as ISectorEntry[];
            }
        } catch (exi) {
            console.error(exi, response);
        }
    }

    private setSector(sector: string, subSector: string) {
        this.selected.sector = sector;
        this.selected.subSector = subSector;
        if (this.props.sector) {
            this.props.sector.sector = sector;
            this.props.sector.subSector = subSector;
        }
    }

    private selectSector(sectorId: string) {
        if (this.selected.sector === sectorId) {
            this.subSectors = [];
            this.setSector(undefined, undefined);
        } else {
            this.subSectors = [];
            this.setSector(sectorId, undefined);
        }
        this.setState({
            loadingSelection: true,
        });
        (async () => {
            await this.loadSubSectors();
            this.setState({
                loadingSelection: false,
            });
        })();
        // super.valueChanged({code: this.selected.sector, isSubSector: false});
    }
    private selectSubSector(subSectorId: string) {
        if (this.selected.subSector === subSectorId) {
            this.subSectors = [];
            this.setSector(this.selected.sector, undefined);
        } else {
            this.setSector(this.selected.sector, subSectorId);
        }

        this.setState({
            selectedSubSector: this.selected.subSector,
        });
        // const sub = this.selected.subSector;
        // super.valueChanged({code: this.selected.subSector ? this.selected.subSector : this.selected.sector, isSubSector: sub});
    }

    private resetSektor() {
        this.subSectors = [];
        this.setSector(undefined, undefined);
        this.setState({
            loadingSelection: true,
        });
        (async () => {
            await this.loadSectors();
            this.setState({
                loadingSelection: false,
            });
        })();
        super.valueChanged({code: this.selected.sector, isSubSector: false});
    }

    private getSectorIntent(r: string) {
        if (this.selected && this.selected.sector === r) {
            return Intent.PRIMARY;
        }
        return Intent.NONE;
    }

    private getSectorIcon(r: string) {
        if (this.selected && this.selected.sector === r) {
            return (<Icon icon={IconNames.TICK} />);
        }
        return null;
    }
    private getSubSectorIntent(r: string) {
        if (this.selected && this.selected.subSector === r) {
            return Intent.PRIMARY;
        }
        return Intent.NONE;
    }

    private getSubSectorIcon(r: string) {
        if (this.selected && this.selected.subSector === r) {
            return (<Icon icon={IconNames.TICK} />);
        }
        return null;
    }

    private onClosed() {
        const sub = this.selected.subSector;
        super.valueChanged({code: this.selected.subSector ? this.selected.subSector : this.selected.sector, isSubSector: sub});
    }
}
