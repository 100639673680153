import {TooltipLine} from "../chart-plugin/TooltipLine";

export enum EChartPlugins{
    TITLE= "title",
    LEGEND= "legend",
    DATALABELS= "datalabels",
    TOOLTIP= "tooltip",
    ANNOTATION = "annotation",
    DRAW_STATE_HOOK = "draw_state_hook",
    TOOLTIP_LINE = "tooltip_line",
}
export type ChartMinMax = {min?: number; max?: number;};
export class SzChartOptions {
    private _options= {
        stacked: false,
        responsive: true,
        hover: {mode: null},
        maintainAspectRatio: false,
        scales: {
            y: {
                position: 'left',
                ticks: {
                    z: 10,
                    padding: 10,
                },
                border:{
                    display: true,
                    color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                    dash: [2],
                },
                grid: {
                    drawTicks: true,
                    tickLength: -5,
                    tickColor: "#485d63",
                },
            },
            x: {
                ticks: {
                    z: 10,
                    padding: 10,
                },
                border:{
                    display: true,
                    color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                    dash: [2],
                },
                grid: {
                    drawTicks: true,
                    tickLength: -5,
                    tickColor: "#485d63",
                },
            }
        },
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
            annotation: {
                enabled: false,
            },
            draw_state_hook: {
                enabled: false,
            },
            tooltip_line: {
                enabled: false,
            },
        },
    } as any;

    get options(): any {
        return this._options;
    }

    public setXMinMax(p: ChartMinMax): SzChartOptions {
        this._options.scales.x.min = p?.min;
        this._options.scales.x.max = p?.max;
        return this;
    }
    public setYMinMax(p: ChartMinMax): SzChartOptions {
        this._options.scales.y.min = p?.min;
        this._options.scales.y.max = p?.max;
        return this;
    }
    public setXSuggestedMinMax(p: ChartMinMax): SzChartOptions {
        this._options.scales.x.suggestedMin = p?.min;
        this._options.scales.x.suggestedMax = p?.max;
        return this;
    }
    public setYSuggestedMinMax(p: ChartMinMax): SzChartOptions {
        this._options.scales.y.suggestedMin = p?.min;
        this._options.scales.y.suggestedMax = p?.max;
        return this;
    }

    public setXTickCallback(callback): SzChartOptions {
        this._options.scales.x.ticks.callback = callback;
        return this;
    }
    public setYTickCallback(callback): SzChartOptions {
        this._options.scales.y.ticks.callback = callback;
        return this;
    }
    public enablePlugin(p: EChartPlugins, options: any): SzChartOptions {
        this._options.plugins[p] = {
            ...options,
            display: true,
            enabled: true,
        };
        return this;
    }
    public setRegressionLine(line): SzChartOptions{
        this._options.regression_line = line;
        return this;
    }
    public setCustomAxisLabel(x_label: string, y_label: string): SzChartOptions{
        this._options.custom_axis_label = {
            x_label,
            y_label,
        };
        return this;
    }
}
