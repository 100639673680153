import {HTMLTable, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {SectorCountries} from "../../const/SectorCountries";
import {Sectors} from "../../const/Sectors";
import {SessionStore} from "../../const/SessionStore";
import {IBetasForBusinessResult, IBetasStat} from "../../models/IBetasForBusinessResult";
import {IOptionEntry} from "../../models/IOptionEntry";
import {EParameters} from "../../models/EParameters";
import {ISzTableColumn, ISzTableProperties} from "./SzTable";
import {SzTableHelper} from "./helper/SzTableHelper";
import {_t, _t_super} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

const BASE_DIR = Globals.BASE_DIR;

const COUNTRIES = SectorCountries.values;

const padding = "6px 3px";
const num_width = 90;
const td_height = 28;

const border_left_0 = {
    boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15), inset 1px 0 0px 0px rgba(16, 22, 26, 0.15)",
};
const border_left = {
    boxShadow: "inset 1px 0 0px 0px rgba(16, 22, 26, 0.15)",
};
const border_top = {
    boxShadow: "inset 0 1px rgba(16, 22, 26, 0.15)",
};
const number_col: CSSProperties = {
    padding,
    textAlign: "right",
    width: num_width,
    height: td_height
};
const number_col2: CSSProperties = {...number_col, ...{width: 50}};
const number_head: CSSProperties = {
    padding, textAlign: "right",
    width: num_width,
};
const number_head2: CSSProperties = {...number_head, ...{width: 50}};
export interface IBusinessBetasProperties {
    data: any;
}

interface IBusinessBetasState {
    is_loading?: boolean;
    qq_on?: boolean;
    rerender?: boolean;
}
interface IColumn {
    style: CSSProperties;
    caption: string;
    field: string;
    c_field?: string;
    fractionDigits?: number;
    formatter?(v: any, fractionDigits?: number, fractionDigitsMin?: number, nan_is_hypen?);
}
export class BusinessBetas extends React.Component<IBusinessBetasProperties, IBusinessBetasState> {

    public tableProps: ISzTableProperties<any[]>;

    constructor(props: IBusinessBetasProperties, context: IBusinessBetasProperties) {
        super(props, context);
        this.state = {
            qq_on: true,
        };
    }
    public render() {
        const textAlign = "right";
        const columns: IColumn[] = [
            {caption: _t(ETranslation.sector_sub_sector), field: "label", style: {padding}},
            {caption: "#", field: "count", style: {padding, textAlign, width: 30}},
            {caption: _t_super(1,ETranslation.beta_levered), field: "median_beta", c_field: "beta", style: {padding, textAlign, width: num_width}, formatter: Globals.formatter, fractionDigits: 2,},
        ];
        const taxshieldParameter: string = SessionStore.get(EParameters.TaxshieldParameter);
        const debtbetaParameter: string = SessionStore.get(EParameters.DebtbetaParameter);
        if (debtbetaParameter !== "nicht_anwenden") {
            columns.push({caption: _t(ETranslation.debt_beta), field: "median_debt_beta", c_field: "debt_beta", style: {padding, textAlign, width: 70}, formatter: Globals.formatter, fractionDigits: 2});
        }
        if (taxshieldParameter !== "unsicher") {
            columns.push({caption: _t(ETranslation.tax_shield), field: "median_tax_shield", c_field: "tax_shield", style: {padding, textAlign, width: 70}, formatter: Globals.formatter_percent, fractionDigits: 1});
        }
        columns.push({caption: _t(ETranslation.gearing), field: "median_debt", c_field: "debt", style: {padding, textAlign, width: num_width}, formatter: Globals.formatter_percent, fractionDigits: 1});
        columns.push({caption: _t(ETranslation.beta_unlevered), field: "median", c_field: "v", style: {padding, textAlign, width: num_width, fontWeight: "bold"}, formatter: Globals.formatter, fractionDigits: 2});


        const table_columns: ISzTableColumn[] = [{ index: "0" }];
        const header_0: ISzTableColumn[] = [];
        columns.forEach((c, idx)=> {
            header_0.push({text: c.caption, options: {className: idx > 0 ? "sz-right" : undefined}});
            if(idx>0){
                table_columns.push({...SzTableHelper.columnMoney(""+idx, 2, {style: {width: num_width}})});
            }
        });
        const table_data = [];
        const entry: { [index: string]: IBetasForBusinessResult } = this.props.data.result;
        const sectors: string[] = [];
        Object.keys(entry).map( (key) => {
            const sector: IBetasForBusinessResult = entry[key];
            const _sector: IOptionEntry = Sectors.values.find((s) => s.value === key);
            const l = _sector ? _sector.label : "Sektor nicht gefunden";
            const sector_row = [];
            table_data.push(sector_row);
            sectors.push(l);
            columns.forEach((col, col_num)=>{
                if(col_num===0){
                    return sector_row.push(l); // <td style={col.style}><strong style={{fontWeight: "bold"}}>{l}</strong></td>);
                }
                if(col_num===1){
                    return sector_row.push(sector.sector.count); // (<td style={col.style}><strong style={{fontWeight: "bold"}}>{sector.sector.count}</strong></td>);
                }
                sector_row.push(col.formatter(sector.sector[col.field], col.fractionDigits, col.fractionDigits, true));
            });
            Object.keys(sector.sub_sectors).map( (sub_key, idx, arr) => {
                const sub_sector: IBetasStat = sector.sub_sectors[sub_key];
                const sub = Sectors.SubSectors.find((s)=> s.value === sub_key);
                const l2 = sub ? sub.label : "Subsektor nicht gefunden";
                const sub_sector_row = [];
                table_data.push(sub_sector_row);
                columns.forEach((col, col_num)=>{
                    if(col_num===0){
                        return sub_sector_row.push(`${Globals.em_space}${l2}`); // <td style={col.style}><strong style={{fontWeight: "bold"}}>{l}</strong></td>);
                    }
                    if(col_num===1){
                        return sub_sector_row.push(sub_sector.count); // (<td style={col.style}><strong style={{fontWeight: "bold"}}>{sector.sector.count}</strong></td>);
                    }
                    sub_sector_row.push(col.formatter(sub_sector[col.field], col.fractionDigits, col.fractionDigits, true));
                });
            });
        });
        this.tableProps = {
            title: _t(ETranslation.sector_beta),
            colCount: columns.length,
            data: table_data,
            header: [ header_0],
            columns: table_columns,
        };

        if (this.props && this.props.data && this.props.data.result) {
            return this.renderEntry(columns, this.props.data, sectors.join(", "));
        }
        return (
            <div>
                <NonIdealState
                    icon={IconNames.WARNING_SIGN}
                    title="Keine Daten"
                    description=""
                />
            </div>
        );
    }
    private renderEntry(columns: IColumn[], data_object, sectors) {
        const entry: { [index: string]: IBetasForBusinessResult } = data_object.result;
        return (
            <div className={"sz-col sz-col-100"}>
                <div style={{display: "flex", justifyContent: "start", marginBottom: 20}}>
                    <div style={{marginBottom: 20, fontSize: "80%"}}>
                        <div><strong>{_t(ETranslation.beta_unlevered)}</strong></div>
                        <div>{data_object.label}, {sectors}</div>
                    </div>
                </div>
                <HTMLTable className={`bp3-html-table bp3-html-table-condensed sz-table sz-prj-info-table_2`} condensed={true} style={{width: "100%", fontSize: "80%"}}>
                    <thead>
                    <tr style={{backgroundColor: "#FFFFFF"}}>
                        {columns.map((col)=>(<th style={col.style}>{col.caption}</th>))}
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(entry).map( (key) => {
                        const sector: IBetasForBusinessResult = entry[key];
                        const _sector: IOptionEntry = Sectors.values.find( (s) => s.value === key );
                        const l = _sector ? _sector.label : "Sektor nicht gefunden";
                        return (
                            <>
                            <tr>
                                {columns.map((col, col_num)=>{
                                    if(col_num===0){
                                        return (<td style={col.style}><strong style={{fontWeight: "bold"}}>{l}</strong></td>);
                                    }
                                    if(col_num===1){
                                        return (<td style={col.style}><strong style={{fontWeight: "bold"}}>{sector.sector.count}</strong></td>);
                                    }
                                    return (<td style={col.style}><strong style={{fontWeight: "bold"}}>{col.formatter(sector.sector[col.field], col.fractionDigits, col.fractionDigits, true)}</strong></td>);
                                })}
                            </tr>
                                {Object.keys(sector.sub_sectors).map( (sub_key, idx, arr) => {
                                    const sub_sector: IBetasStat = sector.sub_sectors[sub_key];
                                    const _sub_sector: IOptionEntry = Sectors.SubSectors.find( (s) => s.value === sub_key );
                                    return (
                                        <>
                                            <tr className={"sz-row-hover"}>
                                                {columns.map(
                                                    (col, col_num)=>{
                                                        if(col_num===0){
                                                            return (<td style={col.style}>{_sub_sector?.label}</td>);
                                                        }
                                                        if(col_num===1){
                                                            return (<td style={col.style}><strong>{sub_sector.count}</strong></td>);
                                                        }
                                                        return (<td style={col.style}>{col.formatter(sub_sector[col.field], col.fractionDigits, col.fractionDigits, true)}</td>);
                                                    })}
                                            </tr>
                                        </>
                                    );
                                })}
                            </>
                        );
                    })}
                    </tbody>
                </HTMLTable>
            </div>
        );
    }
}
