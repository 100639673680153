import {EParameters} from "../../models/EParameters";
import {BaseDataProvider} from "./BaseDataProvider";

export class CostOfCapitalProvider extends BaseDataProvider {
    protected initProvider() {
        super.className = "CostOfCapitalProvider";
        this.parameterMapping = [
            EParameters.DateParameter,
            EParameters.FixedCountriesParameter,
            EParameters.CountryRiskUsageParameter,
            EParameters.LeverageParameter,
            EParameters.TaxRateParameter,
            EParameters.DebtBetaValueParameter,
            EParameters.DebtBetaCalcParameter,
            EParameters.TaxShieldTargetCompanyRiskEffect,
        ];
    }

    protected update() {
        (async () => {
            this.afterUpdate({});
        })();
    }
}
