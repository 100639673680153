import {Navigate, Route, Routes} from "react-router-dom";
import * as React from "react";
import * as UseAuth from "./hooks/UseAuth";
import {Login} from "./widgets/Login";
import {RequestNewPassword} from "./widgets/RequestNewPassword";
import {NewPasswordSetter} from "./widgets/NewPasswordSetter";
import {Register} from "./widgets/Register";
import {MainApplication} from "./MainApplication";

const PrivateRoute = ({children}) => {
    const auth = UseAuth.useAuth();
    if(auth.user){
        return children;
    }else{
        return (
            <Navigate to={"/login"} />
        );
    }
}

export const ApplicationRouter = ()=>{
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/password" element={<RequestNewPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/new-password/:requestId" element={<NewPasswordSetter />} />
            <Route path="*" element={<PrivateRoute><MainApplication /></PrivateRoute>} />
        </Routes>
    );
}
