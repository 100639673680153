import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {PARAMETER_OPTION} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {PdfInsertElement} from "../../../pdf-tools/PdfInsertElement";

const debtBetaUseOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.DebtbetaParameter);
const creditSpreadsOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.CreditSpreadsParameter);
const marketRiskOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.MarketRiskPremiumParameter);


export class DebtBetaCombiParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getPdfLabel() {
        const content = [];
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.DebtbetaParameter), EParameters.DebtbetaParameter);
        PdfInsertElement.selected_option(content, ParameterConfigs.label(EParameters.CreditSpreadsParameter), EParameters.CreditSpreadsParameter);
        const marketRiskPremium = Globals.formatter_percent(parseFloat(SessionStore.get(EParameters.MarketRiskPremiumParameter)), 2, 2, true);
        PdfInsertElement.named_value(content, ParameterConfigs.label(EParameters.MarketRiskPremiumParameter), marketRiskPremium);
        return {
            stack: content
        };
    }
    protected getWidthClass() {
        return "fixed-width-550";
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.DebtBetaCombiParameter];
    }

    protected async initialize() {
        return {
            selectedDebtBetaUse: SessionStore.get(EParameters.DebtbetaParameter),
            selectedCreditSpreads: SessionStore.get(EParameters.CreditSpreadsParameter),
            selectedMarketRiskPremium: SessionStore.get(EParameters.MarketRiskPremiumParameter),
        };
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                {this.renderDebtBetaUse()}
                {this.renderCreditSpreads()}
                {this.renderMarketRiskPremium()}
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.DebtBetaCombiParameter))}
            </div>
        );
    }

    protected getLabel(): any {
        const selDebtBetaUse = debtBetaUseOptions.find((e) => e.value === this.state.selectedDebtBetaUse);
        const selCreditSpreads = creditSpreadsOptions.find((e) => e.value === this.state.selectedCreditSpreads);
        const marketRiskPremium = Globals.formatter_percent(parseFloat(this.state.selectedMarketRiskPremium), 2);

        this.putPacketOptionParameter(EParameters.DebtbetaParameter, [
            {o: debtBetaUseOptions, s: this.state.selectedDebtBetaUse}
        ]);
        this.putPacketOptionParameter(EParameters.CreditSpreadsParameter, [
            {o: creditSpreadsOptions, s: this.state.selectedCreditSpreads}
        ]);
        this.putPacketOptionParameter(EParameters.MarketRiskPremiumParameter, [
            {o: marketRiskOptions, s: "" + this.state.selectedMarketRiskPremium}
        ]);
        if (this.notActive()) {
            return `${selDebtBetaUse.short}`;
        }
        return `${selDebtBetaUse.short} | ${marketRiskPremium} MRP | ${selCreditSpreads.short}`;
        // return `${selDebtBetaUse.short}`;
    }
    private notActive() {
        return this.state.selectedDebtBetaUse === "nicht_anwenden";
    }
    private renderDebtBetaUse() {
        return this.renderColumn("40%", _t(ETranslation.debt_beta_application), ()=>{
            return this.renderOptionsExt(debtBetaUseOptions, this.state.selectedDebtBetaUse, EParameters.DebtbetaParameter, "selectedDebtBetaUse");
        });
    }
    private renderCreditSpreads() {
        return this.renderColumn("40%", _t(ETranslation.debt_beta_credit_spread), ()=>{
            return this.renderOptionsExt(creditSpreadsOptions, this.state.selectedCreditSpreads, EParameters.CreditSpreadsParameter, "selectedCreditSpreads");
        });
    }
    private renderMarketRiskPremium() {
        return this.renderColumn("40%", _t(ETranslation.market_risk_premium_debt_beta), ()=>{
            return (
                <div style={{padding: 10}}>[{Globals.formatter_percent(parseFloat(this.state.selectedMarketRiskPremium), 2)}]</div>
            );
        });
    }
}
