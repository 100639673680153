import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Callout, Classes, ControlGroup, Dialog, InputGroup, Intent, Menu, MenuItem} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

import {DialogProps} from "@blueprintjs/core/lib/esm/components/dialog/dialog";
import {EProjectUserRole, IProject, ProjectUserRoleMap} from "../../models/IProject";
import {Popover2} from "@blueprintjs/popover2";
import {ServiceCalls} from "../../services/ServiceCalls";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {min} from "../../helpers/Statistics";

interface IShareProjectDialog {
    isOpen: boolean;
    setIsOpen: (object: any) => void;
    project : IProject,
}

interface IInvitation {
    email?: string;
    user_id?: string;
    role: EProjectUserRole;

}

const ShareProjectDialog :React.FC<IShareProjectDialog> = ({
                                                               isOpen,
                                                               setIsOpen,
                                                               project,
                                                           }) => {
    if(!project){
        return null;
    }
    const [project_members, setProject_members]= useState(project.project_users);
    useEffect(() => {
        (async ()=>{
            const cb = new ServiceCalls();
            const r = await cb.getProjectUsers(project);
            setProject_members(r.result);
        })();
    }, [project]);

    console.error("ShareProjectDialog", isOpen);

    const props: DialogProps = {
        style: {width: 650},
        isOpen,
        title: _t(ETranslation.project_members),
        icon: IconNames.NEW_PERSON,
        usePortal: true,
        onClose: ()=> setIsOpen({isOpen: false}),
    }
    // const project_members  = project.project_users;
    const [invitation, setInvitation] = useState<IInvitation>({
        email: "",
        user_id: "65007d7ebeed3a3d316255ff",
        role:EProjectUserRole.unset,
        }
    );
    const [isSent, setIsSent] = useState(0);
    const [isAdd, setIsAdd] = useState (false);

    const handleChange = (evt) => {
        const name = evt.target.name;
        setIsSent(0);
        const value = evt.target.value;
        setInvitation({
            ...invitation,
            [name]: value,
        });
    };
    const chRole = (role: EProjectUserRole, inv: IInvitation) => {
        setInvitation( {...invitation, role});
    };
    const permissionsMenu = (inv: IInvitation) => {
        return (
            <Popover2
                content={
                    <Menu>
                        <MenuItem text="Administrator"  onClick={ () => chRole(EProjectUserRole.owner, inv) }/>
                        <MenuItem text={_t(ETranslation.read_only)} onClick={ () => chRole(EProjectUserRole.reader, inv) }/>
                    </Menu>
                }
                placement="bottom-end"
                minimal={true}
            >
                <Button minimal={true} rightIcon="caret-down" text={ProjectUserRoleMap[inv.role]} intent={Intent.PRIMARY} style={{minWidth: 120, justifyContent: "space-between"}}/>
            </Popover2>
        );
    };
    const sendInvite = (p: IProject) => {
        (async () => {
            const cb = new ServiceCalls();
            try {
                const response : {response : string[]} = await cb.inviteToProject(p, [invitation], "inviteToProject");

                if(response.response && response.response[0].startsWith("saved")){
                   setIsSent(1);
                   setInvitation({
                       email: "",
                       user_id: "65007d7ebeed3a3d316255ff",
                       role:EProjectUserRole.unset,
                   });
                }else{
                    setIsSent(2);
                }
            } catch (exi) {
                console.error(exi);
            }
        })();
    }
    const renderMember = (i)=> {
        const inv: IInvitation = {
            role: min(i.roles),
            user_id: i.user_id,
        };
        return (
            <div style={{marginTop: 8}}>
                <ControlGroup fill={true}>
                    <InputGroup value={[i.display_name, i.company_name].join(" | ")} readOnly={true} leftIcon={IconNames.PEOPLE} rightElement={permissionsMenu(inv)} />
                    <Button title={_t(ETranslation.delete)} icon={IconNames.TRASH} intent={Intent.DANGER} onClick={()=> alert("Delete ")}/>
                </ControlGroup>
            </div>
        );
    };
    return(
        <div onClick={(e) => e.stopPropagation()}>
            <Dialog {...props}>
                <div className={Classes.DIALOG_BODY} style={{backgroundColor: "#ffffff"}}>
                    <Callout title={_t(ETranslation.add_member)} style={{backgroundColor: "#ffffff"}}>
                        <p className={"bp3-text-small bp3-text-muted"}>{_t(ETranslation.add_member_description)}</p>
                        <ControlGroup fill={true}>
                            <InputGroup type={"email"} value={invitation.email} name="email" placeholder={_t(ETranslation.email)} leftIcon={IconNames.PEOPLE} rightElement={permissionsMenu(invitation)} onChange={handleChange} />
                        </ControlGroup>
                        <br/>

                        {  isSent>0 && <div>{  isSent === 1 ? <div>{_t(ETranslation.invite_success)} </div> : <div>User Dosen't Existed</div> }</div>}
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <Button text={_t(ETranslation.add)}  intent={Intent.PRIMARY} onClick={() => {sendInvite(project); setIsAdd(true)}}/>
                        </div>
                    </Callout>
                    <Callout title={_t(ETranslation.members)} style={{backgroundColor: "#ffffff"}}>
                         { project_members.map((i)=> renderMember(i)) }
                    </Callout>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button intent={Intent.NONE} minimal={false} text={_t(ETranslation.finish)} onClick={() => alert("show members Dialog")} />
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default  ShareProjectDialog;
