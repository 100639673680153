export enum EStatisticsValueType {
    parameter= 0,
    module_usage= 1,
    tab_changed= 2,
    button_click= 3,
    excel_export= 4,
    pdf_export= 5,
    side_tab_changed= 6,
    file_download= 7,
    global_var= 8,
}
