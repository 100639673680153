export enum EModuleNames {
    overview= "__overview__",
    parameters= "__parameters__",
    users= "__users__",
    hub_spot= "__hub_spot__",
    hub_spot_funnel= "__hub_spot_funnel__",
    hub_spot_sales= "__hub_spot_sales__",
    datev= "__datev__",
    datev_import= "__datev_import__",
    datev_monthly= "__datev_monthly__",
    datev_budget= "__datev_budget__",
    datev_forecast= "__datev_forecast__",
    sz_financials= "__sz_financials__",
    sz_valuation= "__sz_valuation__",
    sz_benchmarking= "__sz_benchmarking__",
    db_request = "DB-Request",
    Multiples = "Multiples",
    BetaFactor = "BetaFactor",
    ZinsSaetze = "InterestRates",
    CreditSpreads = "CreditSpreads",
    RiskPremium = "RiskPremium",
    Benchmarking = "Benchmarking",
    FileManager = "FileManager",
    CostOfCapital = "CostOfCapital",
    TransferPricing = "TransferPricing",
    TestModModule = "TestModModule",
    SectorsModule = "SectorsModule",
}
