import * as React from "react";
import * as ReactDOM from "react-dom";
import {ISzSvgBarCellProperties} from "./SzSvgBarCell";

export class SzSvgCellChart extends React.Component<ISzSvgBarCellProperties, any> {

    private readonly svg: React.RefObject<HTMLDivElement> = null;
    private observer: IntersectionObserver;

    constructor(props: any, context: any) {
        super(props, context);
        this.svg = React.createRef();

        window.addEventListener("resize", () => this.resize());

    }
    public render() {
        return (<div ref={this.svg} style={{ width: "100%", height: this.props.height }}/>);
    }
    public componentDidMount(): void {
        const width: number = this.svg.current.clientWidth;
        const height: number = this.svg.current.clientHeight;
        ReactDOM.render(this.renderSvg(width, height), this.svg.current);
        this.observer = new IntersectionObserver((items, observer) => { this.onIntersectionObserver(items, observer); });
        this.observer.observe(this.svg.current);
    }
    public componentWillUnmount(): void {
        this.observer.disconnect();
    }
    public renderSvg(width, height) {
        return (
            <svg width={width} height={height} className={"sz-chart"}>
                {this.renderData(width, height)}
            </svg>
        );
    }
    public resize() {
        if (this.svg && this.svg.current) {
            const width: number = this.svg.current.clientWidth;
            const height: number = this.svg.current.clientHeight;
            ReactDOM.render(this.renderSvg(width, height), this.svg.current);
        }
    }
    private onIntersectionObserver(items: IntersectionObserverEntry[], observer: IntersectionObserver) {
        for (const item of items) {
            if (!item.isIntersecting) {
                continue;
            }
            this.resize();
            break;
        }
    }
    private renderData(width: number, height: number) {
        const use_height = height - 8;
        const y_off = 4;
        const min = this.props.min;
        const max = this.props.max;
        const padding_right = 0; // this.props.padding_right;
        const padding_left = 0; // this.props.padding_right;
/*
        if (min > 0) {
            min = 0;
            padding_left = 0;
        } else if (max < 0) {
            max = 0;
            padding_right = 0;
        }
*/
        const paddings = padding_right + padding_left;
        // const delta = min < 0 ? max - min : max;
        const delta = (max - min);

        const xFactor =  (width - paddings) / delta;
        const value = this.props.value;
        // const middle = Math.trunc(((width - padding_left) / 2));
        const getX = (v) => Math.trunc(((width - padding_left) / 2) + (this.props.annotation.median - v) * xFactor);

        // padding left right
        const x_rect = 60; // min < 0 ? getX(min) : 0;

        const w = getX(value) - x_rect;

        const h = use_height;
        const className = this.props.classNamePos ? this.props.classNamePos : "sz-chart-dot-1";

        if (!this.props.annotation) {
            return (
                <g className={"sz-chart-data"} transform={`translate(0 0)`}>
                    <rect x={x_rect} y={0} width={w} height={h} className={className}/>
                </g>
            );
        }

        const asPx = (v) => Math.trunc(v / delta * (width - x_rect)) + x_rect / 2;
        const a_min =  asPx( (this.props.annotation.min - this.props.annotation.median) - min);
        const a_median =  asPx( Math.abs(min));
        const a_max =  asPx( Math.abs(min) + (this.props.annotation.max - this.props.annotation.median));
        const a_mean =  asPx( Math.abs(min) +  (this.props.annotation.mean - this.props.annotation.median));

        const cw = 2;
        const path = [];
        path.push(`M${a_mean - cw} ${(height / 2)}`);
        path.push(`L${a_mean} ${(height / 2) + cw}`);
        path.push(`L${a_mean + cw} ${(height / 2)}`);
        path.push(`L${a_mean} ${(height / 2) - cw}`);
        path.push(`L${a_mean - cw} ${(height / 2)}`);

        const nan_test = [a_min, a_max, a_median, a_mean - cw];
        if (value === undefined || isNaN(value) || height === 0 || nan_test.some( (v) => isNaN(v) )) {
            return (
                <g className={"sz-chart-cell"} transform={`translate(0 0)`}>
                    <line x1={a_median} x2={a_median} y1={0} y2={height} className={"sz-chart-cell-median"} />
                </g>
            );
        }

        const formatter = (new Intl.NumberFormat("de-de", {  maximumFractionDigits: 1, minimumFractionDigits: 1 })).format;

        return (
            <g className={"sz-chart-cell"} transform={`translate(0 0)`} onClick={ () => console.error(a_min, a_max, a_mean, a_median, a_median)}>
                <rect x={a_min} width={a_max - a_min} y={y_off} height={use_height} className={"sz-chart-cell-min-max"} />
                <line x1={a_min} x2={a_min} y1={y_off} y2={height - y_off} className={"sz-chart-cell-min"} />
                <line x1={a_max} x2={a_max} y1={y_off} y2={height - y_off} className={"sz-chart-cell-max"} />
                <line x1={a_median} x2={a_median} y1={0} y2={height} className={"sz-chart-cell-median"} />

                {this.props.hideMean ? null : (<rect x={a_mean - cw} y={y_off + (use_height / 2) - cw} width={2 * cw} height={2 * cw} className={"sz-chart-cell-mean"} />)}

                <text className={"sz-chart-cell-label-min"} x={a_min - 5} y={y_off + (use_height / 2) - cw}>{formatter(this.props.annotation.min)}</text>
                <text className={"sz-chart-cell-label-max"} x={a_max + 5} y={y_off + (use_height / 2) - cw}>{formatter(this.props.annotation.max)}</text>

            </g>
        );
// <path d={path.join(" ")} className={"sz-chart-cell-mean"}/>
    }

}
