import {EParameters} from "../models/EParameters";
import {EValueName} from "../models/EValueName";
import {EventBus} from "../services/EventBus";
import {ServiceCalls} from "../services/ServiceCalls";
import {Globals} from "./Globals";

export class SessionStore {
    public static putItem(name: EParameters, val: any, force?:boolean) {
        if (this.__values) {
            if(force){
                return this.__values[name] = val;
            }
            if (!this.__values[name]) {
                this.__values[name] = val;
            }
        }
    }
    public static resetItem(name: EParameters, val: any) {
        if (this.__values) {
            this.__values[name] = val;
        }
    }
    public static setItem(name: string, val: any, updateLock?: boolean) {
        if (this.__values) {
            this.__oldValues[name] = this.__values[name];
            this.__values[name] = val;
        }

        if (updateLock !== undefined && updateLock === false) {
            return;
        }

        if (!Globals.isRegistred) {
            return;
        }
        if (!this.storeCalls) {
            this.storeCalls = new ServiceCalls();
        }
        (async () => {
            try {
                const storeCalls = new ServiceCalls();
                await storeCalls.setSessionValue(name, val);
            } catch (e) {
                console.error(e);
            }
        })();
    }
    public static get(name: EParameters) {
        if (!this.__values) {
            return undefined;
        }
        const p = this.__values[name];
        if (p && Array.isArray(p)) {
            return p[0];
        }
        if(p){
            return p;
        }
        return undefined;
    }
    public static getAll() {
        if (!this.__values) {
            return undefined;
        } else {
            return this.__values;
        }
    }
    public static getOld(name: EParameters) {
        if (!this.__oldValues) {
            return undefined;
        }
        const p = this.__oldValues[name];
        if (p && Array.isArray(p)) {
            return p[0];
        }
        return undefined;
    }
    public static getGlobalVar(name: EValueName, defVal: any) {
        const showModule = "global_vars"; // `global_vars_${Globals.getWindowKey("wp_show_module")}`;
        const g_values = this.getItem(showModule, {});
        if (g_values && g_values.hasOwnProperty(name)) {
            return g_values[name];
        }
        return defVal;
    }
    public static setGlobalVar(name: EValueName, val: any) {
        const showModule = "global_vars"; // `global_vars_${Globals.getWindowKey("wp_show_module")}`;
        const g_values = this.getItem(showModule, {});
        if (g_values) {
            g_values[name] = val;
            this.setItem(showModule, g_values, Globals.isUpdateLock());
            EventBus.emit("SessionStore::SetGlobalVar", null);
        }
    }
    public static getGlobalVars() {
        const showModule = "global_vars"; // `global_vars_${Globals.getWindowKey("wp_show_module")}`;
        const g_values = this.getItem(showModule, {});
        if (g_values) {
            return g_values;
        } else {
            return null;
        }
    }
    public static getItem<K>(name: string, defaultVal?: K): K {
        if (this.__values !== undefined) {
            return this.getValueIntern(name, defaultVal);
        } else {
            return defaultVal;
        }
    }
    public static async init(values) {
        if (!Globals.isRegistred) {
            this.__values = {};
            this.__oldValues = {};
            return;
        }
        if (values) {
            this.__values = values;
        } else {
            this.__values = {};
        }
        this.__oldValues = JSON.parse(JSON.stringify(this.__values));
    }
    private static storeCalls: ServiceCalls;
    private static __values: any;
    private static __oldValues: any;

    private static getValueIntern(name: string, defaultVal: any) {
        const v = this.__values[name];
        if (v === undefined || v === null) {
            return defaultVal;
        }
        return v;
    }
}
