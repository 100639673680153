import {HTMLTable, Icon, Spinner} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {getResult} from "../../helpers/Helpers";
import {ECompanyRelation} from "../../models/ECompanyRelation";
import {
    ICompany,
    IRelatedCompany,
    IRelatedFinancials
} from "../../models/ICompanyDetails";
import {ICountry} from "../../models/ICountry";
import {EventBus} from "../../services/EventBus";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {CompanyDetailPopup} from "./CompanyDetailPopup";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

type RelatedFinancialsMap = { [index:string] : IRelatedFinancials};

enum ESortType {
    FF_MKT_VAL= 1,
    FF_SALES,
    FF_EBITDA,
    FF_EMP_NUM,
    SIC,
    NAICS,
    REGION,
    SUPL_SALES,
    CUST_SALES,
};

export interface ICompanyRelationsProps {
    company_id: string;
}

interface ICompanyRelationsState {
    is_loading: boolean;
    company_id: string;
    company?: ICompany;
    competitor?: IRelatedCompany[];
    supplier?: IRelatedCompany[];
    customer?: IRelatedCompany[];
    related_financials?: RelatedFinancialsMap;
    order_by: any;
    order_dir: any;
}

export class CompanyRelations extends React.Component<ICompanyRelationsProps, ICompanyRelationsState> {

    constructor(props: ICompanyRelationsProps, context: any) {
        super(props, context);
        this.state = {
            is_loading: true,
            company_id: this.props.company_id,
            order_by: {},
            order_dir: {},
        };
    }

    public render() {
        const backGroundStyle: CSSProperties = {
            width: "100%",
            height: "100%",
            overflowY: "auto",
            backgroundColor: "#ffffff",
        };
        return (<div style={backGroundStyle} className={"sz-relative"} >{this.state.is_loading ? this.renderLoading() : this.renderContent()}</div>);
    }
    private renderLoading() {
        (async ()=>{
            await this.loadData();
        })();
        return(
            <div style={{position: "relative", height: "100%", width: "100%", display: "flex", justifyContent: "center"}}>
                <Spinner size={250} />
            </div>
        );
        /*<SzSvgLoader />*/
    }
    private async loadData() {
        try{
            const company_id = this.state.company_id;
            const db: PublicDbCalls = new PublicDbCalls();
            const r_company = await db.selectCompanies([company_id]);
            const company: ICompany = getResult(r_company, [])[0];

            const ident = await db.getRelatedCompany(company_id);
            const competitor = await db.getRelatedCompanies(company_id, 15, ECompanyRelation.COMP);
            const supplier = await db.getRelatedCompanies(company_id, 15, ECompanyRelation.SUPL);
            const customer = await db.getRelatedCompanies(company_id, 15, ECompanyRelation.CUST);
            const all = competitor.concat(supplier, customer);
            const rel_fin_ids = all.map((i)=> i.id);
            rel_fin_ids.push(company.id);
            const rel_fin = await db.getRelatedFinancials(rel_fin_ids);
            const related_financials: RelatedFinancialsMap = {};
            rel_fin.forEach((i)=>related_financials[i.company_id] = i);

            if(competitor.length){
                competitor.unshift(ident);
            }

            // console.error(current_year);
            // console.error(arr_details);
            // console.error(company_details_map);

            this.setState({
                is_loading: false,
                company,
                competitor,
                supplier,
                customer,
                related_financials,
                order_by: {},
                order_dir: {},
            });

            return;
        }catch(ex){
            console.error(ex);
        }
        this.setState({
            is_loading: false,
        });
    }
    private renderContent() {
        const company: ICompany = this.state.company;
        if(!company){
            return undefined;
        }
        const header: CSSProperties = {
            boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)",
            display: "flex",
            justifyContent: "center",
            maxHeight: 40,
            height: 40,
            paddingLeft: 10,
            backgroundColor: "#F0F0F0",
        };

        return (
            <div style={{paddingLeft: 1, paddingRight: 1}}>
                <div className={"sz-row"}>
                    <div className={"sz-col-100"}>
                        {this.renderCompetitors(company)}
                        <div style={{fontSize: "60%", width: "100%", textAlign: "left", paddingLeft: 11}} className={"bp3-text-muted"}>{_t(ETranslation.status_reporting)}</div>
                    </div>
                </div>
                <div className={"sz-row"} style={{marginTop: 21}}>
                    <div className={"sz-col-50"}>
                        {this.renderSupplier(company)}
                        <div style={{fontSize: "60%", width: "100%", textAlign: "left", paddingLeft: 11}} className={"bp3-text-muted"}>{_t(ETranslation.status_reporting)}</div>
                    </div>
                    <div className={"sz-col-50"} style={{paddingLeft: 1}}>
                        {this.renderCustomer(company)}
                        <div style={{fontSize: "60%", width: "100%", textAlign: "left", paddingLeft: 11}} className={"bp3-text-muted"}>{_t(ETranslation.status_reporting)}</div>
                    </div>
                </div>
            </div>
        );
    }

    private renderSorty(type: ECompanyRelation, new_order: ESortType, caption: string, justifyContent = "flex-end") {
        const current_order_by = this.state.order_by[type];
        const current_order_dir = this.state.order_dir[type];
        const order_by = this.state.order_by;
        const dir = this.state.order_dir;
        const sort = () => {
            if(current_order_by && current_order_by !== new_order){
                order_by[type] = new_order;
                dir[type] = 1;
                return this.setState({
                    order_by,
                    order_dir: dir,
                });
            }

            let order_dir;
            if(current_order_dir === undefined){
                order_dir = 1;
            }
            if(current_order_dir === 1){
                order_dir = -1;
            }
            if(order_dir === undefined){
                order_by[type] = undefined;
                dir[type] = undefined;
                return this.setState({
                    order_by,
                    order_dir: dir,
                });
            }

            order_by[type] = new_order;
            dir[type] = order_dir;
            this.setState({
                order_by,
                order_dir: dir,
            });
        }
        const renderNotSorted = () => {
            if(current_order_by !== new_order){
                return (
                    <div style={{display: "flex", flexFlow: "column", alignItems: "center", height: "100%", width: 12}}>
                        <Icon icon={IconNames.CARET_UP} iconSize={10} />
                        <Icon icon={IconNames.CARET_DOWN} iconSize={10} />
                    </div>
                );
            }else{
                return undefined;
            }
        };
        const renderAsc = () => {
            if(current_order_by === new_order && current_order_dir === 1){
                return (
                    <div style={{display: "flex", flexFlow: "column", height: "100%"}}>
                        <Icon icon={IconNames.CARET_UP} iconSize={12} />
                    </div>
                );
            }else{
                return undefined;
            }
        };
        const renderDesc = () => {
            if(current_order_by === new_order && current_order_dir === -1){
                return (
                    <div style={{display: "flex", flexFlow: "column", justifyContent: "flex-end", height: "100%"}}>
                        <Icon icon={IconNames.CARET_DOWN} iconSize={12} />
                    </div>
                );
            }else{
                return undefined;
            }
        };
        return (
            <div style={{display: "flex", alignItems: "end", justifyContent, height: 20, width: "100%", cursor: "pointer", userSelect: "none"}} onClick={()=> sort()}>
                <span style={{paddingRight: 3, alignSelf: "center"}}>{caption}</span>
                {renderNotSorted()}
                {renderAsc()}
                {renderDesc()}
            </div>
        );
    }
    private renderCompetitors(company: ICompany) {
        const order_by = this.state.order_by[ECompanyRelation.COMP];
        const order_dir = this.state.order_dir[ECompanyRelation.COMP];
        const related_financials = this.state.related_financials;
        const deCollator = new Intl.Collator("de");
        const competitors = [].concat(this.state.competitor).sort((a: IRelatedCompany,b: IRelatedCompany) => {
            const fin_a = related_financials[a.id];
            const fin_b = related_financials[b.id];

            const _c = (f_a, f_b) => {
                return (f_a/a.from_euro_to_) - (f_b/b.from_euro_to_);
            };

            if(order_by === ESortType.FF_MKT_VAL){
                return order_dir * _c(fin_a.ff_mkt_val, fin_b.ff_mkt_val);
            }
            if(order_by === ESortType.FF_SALES){
                return order_dir * _c(fin_a.ff_sales, fin_b.ff_sales);
            }
            if(order_by === ESortType.FF_EBITDA){
                return order_dir * _c(fin_a.ff_ebitda, fin_b.ff_ebitda);
            }
            if(order_by === ESortType.FF_EMP_NUM){
                return order_dir * (fin_a.ff_emp_num - fin_b.ff_emp_num);
            }
            if(order_by === ESortType.SIC){
                const na = parseInt(a.ff_sic_code, 10);
                const nb = parseInt(b.ff_sic_code, 10);
                return order_dir * (na - nb);
            }
            if(order_by === ESortType.NAICS){
                const na = parseInt(a.ff_naics_code, 10);
                const nb = parseInt(b.ff_naics_code, 10);
                return order_dir * (na - nb);
            }
            if(order_by === ESortType.REGION){
                const country_a: ICountry = Globals.country_map[a.country_id];
                const country_b: ICountry = Globals.country_map[b.country_id];
                return order_dir * deCollator.compare(country_a.region_de.toLowerCase(), country_b.region_de.toLowerCase());
            }
            return 0;
        });
        const renderCompetitor = (c: IRelatedCompany)=> {
            const country: ICountry = Globals.country_map[c.country_id];
            const fin = related_financials[c.id] ? related_financials[c.id] : {} as IRelatedFinancials;
            const selected = c.rang === "0" ? true : false;
            return (
                <tr className={`${selected? "sz-row-selected-alt" : ""}`}>
                    <td style={{paddingTop: 3, paddingBottom: 3}}>
                        <CompanyDetailPopup company={c} relation={ECompanyRelation.COMP} flag={`${Globals.BASE_DIR}images/flags/${country?.iso_2.toLowerCase()}.jpg`}/>
                    </td>
                    <td style={{paddingTop: 3, paddingBottom: 3, paddingRight: 0, paddingLeft: 0}}>{country?.region_de}</td>
                    <td style={{textAlign: "right", paddingTop: 3, paddingBottom: 3, width: 75, paddingLeft: 3, paddingRight: 3}}>{Globals.formatter(fin.ff_mkt_val/c.from_euro_to_, 0, 0, true)}</td>
                    <td style={{textAlign: "right", paddingTop: 3, paddingBottom: 3, width: 75, paddingLeft: 3, paddingRight: 3}}>{Globals.formatter(fin.ff_sales/c.from_euro_to_, 0, 0, true)}</td>
                    <td style={{textAlign: "right", paddingTop: 3, paddingBottom: 3, width: 75, paddingLeft: 3, paddingRight: 3}}>{Globals.formatter(fin.ff_ebitda/c.from_euro_to_, 0, 0, true)}</td>
                    <td style={{textAlign: "right", paddingTop: 3, paddingBottom: 3, width: 75, paddingLeft: 3, paddingRight: 3}}>{Globals.formatter(fin.ff_emp_num, 0,0, true)}</td>
                    <td style={{textAlign: "right", width: 75, paddingTop: 3, paddingBottom: 3, paddingLeft: 3, paddingRight: 3}}>{c.ff_sic_code}</td>
                    <td style={{textAlign: "right", width: 85, paddingTop: 3, paddingBottom: 3, paddingLeft: 3, paddingRight: 3}}>{c.ff_naics_code}</td>
                </tr>
            );
        };
        return (
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%", fontSize: "85%"}}>
                <thead>
                <tr style={{backgroundColor: "#F0F0F0",boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}>
                    <th colSpan={8} style={{fontSize: "14px"}}>{_t(ETranslation.peer_group)}</th>
                </tr>
                <tr>
                    <th style={{fontWeight: "normal", paddingTop: 3, paddingBottom: 3}}>Name</th>
                    <th style={{fontWeight: "normal", paddingTop: 3, paddingBottom: 3, paddingRight: 0, paddingLeft: 0}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.REGION, _t(ETranslation["geo-region"]), "flex-start")}</th>
                    <th style={{fontWeight: "normal", paddingTop: 2, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.FF_MKT_VAL, _t(ETranslation.market_cap))}</th>
                    <th style={{fontWeight: "normal", paddingTop: 2, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.FF_SALES, _t(ETranslation.revenues))}</th>
                    <th style={{fontWeight: "normal", paddingTop: 2, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.FF_EBITDA, "EBITDA")}</th>
                    <th style={{fontWeight: "normal", paddingTop: 2, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.FF_EMP_NUM, _t(ETranslation.employees))}</th>
                    <th style={{fontWeight: "normal", textAlign: "right", paddingTop: 3, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.SIC, "SIC-Code")}</th>
                    <th style={{fontWeight: "normal", textAlign: "right", paddingTop: 3, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(ECompanyRelation.COMP, ESortType.NAICS, "NAICS-Code")}</th>
                </tr>
                </thead>
                <tbody>
                {competitors.map((i) => renderCompetitor(i))}
                </tbody>
            </HTMLTable>
        );
    }
    private renderSupplier(company: ICompany) {
        return this.renderSuplCust(_t(ETranslation.suppliers), company, this.state.supplier, ECompanyRelation.SUPL);
    }
    private renderCustomer(company: ICompany) {
        return this.renderSuplCust(_t(ETranslation.customers), company, this.state.customer, ECompanyRelation.CUST);
    }
    private renderSuplCust(title: string, company: ICompany, items: IRelatedCompany[], type: ECompanyRelation) {
        const order_by = this.state.order_by[type];
        const order_dir = this.state.order_dir[type];
        const related_financials = this.state.related_financials;

        const sorted = [].concat(items).sort((a: IRelatedCompany,b: IRelatedCompany) => {
            const fin_a = related_financials[a.id];
            const fin_b = related_financials[b.id];

            const _c = (f_a, f_b) => {
                return (f_a/a.from_euro_to_) - (f_b/b.from_euro_to_);
            };

            if(order_by === ESortType.SUPL_SALES){
                return order_dir * _c(fin_a.ff_sales, fin_b.ff_sales);
            }
            if(order_by === ESortType.CUST_SALES){
                return order_dir * _c(fin_a.ff_sales, fin_b.ff_sales);
            }
            return 0;
        });


        const renderItem = (c: IRelatedCompany) =>{
            const country: ICountry = Globals.country_map[c.country_id];
            const fin = related_financials[c.id] ? related_financials[c.id] : {} as IRelatedFinancials;
            const selected = c.rang === "0" ? true : false;
            return (
                <tr className={`${selected? "sz-row-selected-alt" : ""}`}>
                    <td style={{paddingTop: 3, paddingBottom: 3}}>
                        <CompanyDetailPopup relation={type} company={c} flag={`${Globals.BASE_DIR}images/flags/${country?.iso_2.toLowerCase()}.jpg`}/>
                    </td>
                    <td style={{textAlign: "right", paddingTop: 3, paddingBottom: 3, width: 75, paddingLeft: 3, paddingRight: 3}}>{Globals.formatter(fin.ff_sales/c.from_euro_to_, 0, 0, true)}</td>
                </tr>
            );
        };
        return (
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%", fontSize: "85%"}}>
                <thead>
                <tr style={{backgroundColor: "#F0F0F0",boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}>
                    <th colSpan={2} style={{fontSize: "14px"}}>{title}</th>
                </tr>
                <tr>
                    <th style={{fontWeight: "normal", paddingTop: 3, paddingBottom: 3}}>Name</th>
                    <th style={{fontWeight: "normal", textAlign: "right", paddingTop: 2, paddingBottom: 0, paddingLeft: 3, paddingRight: 3}}>{this.renderSorty(type, type === ECompanyRelation.CUST ? ESortType.CUST_SALES : ESortType.SUPL_SALES, "Umsatz")}</th>
                </tr>
                </thead>
                <tbody>
                {sorted.map((i) => renderItem(i))}
                </tbody>
            </HTMLTable>
        );
    }
    private openPopup(c: IRelatedCompany, relation: ECompanyRelation) {
        EventBus.emit("CompanyDetailPopup::open", {c, relation});
    }
}
