import * as React from "react";
import {getLocale} from "../../tools/Translator";
import {Classes, Dialog} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
export interface IDlgYouTrack {
    isOpen: boolean;
    onClose(): void;
}
export const DlgYouTrack = (props: IDlgYouTrack) => {
    const loadScript = () => {
        const d = document.querySelector("#szYoutrack");
        if(!d){
            console.error("no szYoutrack");
            return;
        }
        const script = document.createElement("script");
        script.id = "b4541253-15a2-4698-8676-2e209ea1428e";
        script.src = "https://smart-zebra.youtrack.cloud/static/simplified/form/form-entry.js";
        script.setAttribute("data-yt-url", "https://smart-zebra.youtrack.cloud");
        script.setAttribute("data-theme", "light");
        script.setAttribute("data-lang", getLocale());
        d.append(script);
    };
    return (
        <Dialog
            title={"Support Anfrage"}
            isOpen={props.isOpen}
            icon={IconNames.ENVELOPE}
            canOutsideClickClose={true}
            usePortal={true}
            style={{minHeight: 400}}
            onClose={() => {
                props.onClose();
            }}
            onOpened={()=> loadScript()}
            shouldReturnFocusOnClose={false}
        >
            <div className={Classes.DIALOG_BODY}>
                <div id={"szYoutrack"}>
                </div>
            </div>
        </Dialog>
    );
}
