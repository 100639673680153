import {useEffect, useState} from "react";
import {EventBus} from "../../services/EventBus";
import {IProject, ProjectTypeModuleMap} from "../../models/IProject";

export enum EProjectEvents {
    LOADED= "SzProject::LOADED",
    CHANGED= "SzProject::CHANGED",
    CHANGE_TAB= "SzProject::CHANGE_TAB",
}

export const EMIT_PROJECT_LOADED = (p: IProject)=>{
    // console.error("EMIT_PROJECT_LOADED");
    EventBus.emit<IProject>(EProjectEvents.LOADED, p);
};


export const useProject = ()=>{
    const [project, setProject] = useState<IProject>(null);
    useEffect(() => {
        // console.error("EMIT_PROJECT_LOADED - useEffect");
        const event = EventBus.subscribe<IProject>(EProjectEvents.LOADED, (p)=>{
            setProject(p);
        });
        return ()=>{
            EventBus.unsubscribe(event);
        };
    }, []);
    return project;
};
export const useProjectModules = ()=>{
    const [modules, setModules] = useState<string[]>(null);
    useEffect(() => {
        const event = EventBus.subscribe<IProject>(EProjectEvents.LOADED, (p)=>{
            if(!p){
                setModules([]);
            }
            if(p && ProjectTypeModuleMap[p.project_type]){
                setModules(ProjectTypeModuleMap[p.project_type]);
            }
        });
        return ()=>{
            EventBus.unsubscribe(event);
        };
    }, []);
    return modules;
};

export const EMIT_PROJECT_CHANGE_MODULE_TAB = (p: number)=> EventBus.emit<number>(EProjectEvents.CHANGE_TAB, p);
export const useProjectModuleTab = ()=>{
    const [tab, setTab] = useState<number>(0);
    useEffect(() => {
        const event = EventBus.subscribe<number>(EProjectEvents.CHANGE_TAB, (p)=>{
            setTab(p);
        });
        return ()=>{
            EventBus.unsubscribe(event);
        };
    }, []);
    return tab;
};
