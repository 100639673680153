import {Button, Callout, Classes, H4, Icon, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {EDocScope} from "../../const/EDocScope";
import {Globals} from "../../const/Globals";
import {IDocDetails, IDocList} from "../../models/IDocList";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {SzMdViewer} from "./SzMdViewer";
import {SzSvgLoader} from "./SzSvgLoader";
import {EventBus, IEventHandler} from "../../services/EventBus";

interface IPageDocumentationState {
    current_view: EDocScope;
    is_loading: boolean;
    list_items: IDocList[];
    selected_item?: IDocDetails;
}

export class PageDocumentation extends React.Component<any, IPageDocumentationState> {

    private readonly evtOpenDocRef: IEventHandler;

    constructor(props: any, context: IPageDocumentationState) {
        super(props, context);
        this.state = {
            current_view: EDocScope.list,
            is_loading: true,
            list_items: [],
        };
        this.onHashChange = this.onHashChange.bind(this);

        this.evtOpenDocRef = EventBus.subscribe<string>("PageDocumentation::openDocRef", (ref_id) => this.openDocRef(ref_id));
    }

    public componentDidMount() {
        this.loadList();
        // window.addEventListener("hashchange", this.onHashChange);
    }

    public componentWillUnmount() {
        EventBus.unsubscribe(this.evtOpenDocRef);
        // window.removeEventListener("hashchange", this.onHashChange);
    }

    public render() {
        if (this.state.is_loading) {
            return this.isLoading();
        }
        if (this.state.current_view === EDocScope.list) {
            return this.renderList();
        }
        if (this.state.current_view === EDocScope.details) {
            return this.renderDetails();
        }
        return (
            <div style={{margin: 10, marginTop: 0}}>
                <div>
                    <SzMdViewer value={"Das ist ein Text `$$\\pm \\sqrt{a^2 + b^2}$$`  in dem Mathsachen `$$\\beta$$`  stehen.\n" +
                    "\n" +
                    "```katex\n" +
                    "c = \\pm \\sqrt{a^2+b^2}\n" +
                    "```\n" +
                    "\n" +
                    "# Überschrift 1\n" +
                    "## Ü2\n" +
                    "### U3\n"} />
                </div>
            </div>
        );
    }
    private onHashChange(evt: HashChangeEvent) {
        console.error(location.hash);
        const hash = location.hash;
        if (!hash) {
            return;
        }

        if (hash === "#doc_list") {
            this.openDocList();
            return;
        }
        if (hash.startsWith("#doc_id=")) {
            const ref_id = hash.replace("#doc_id=", "");
            if (!ref_id) {
                return;
            }
            this.openDocRef(ref_id);
        }
    }
    private openDocRef(ref_id: string) {
        this.setState({
            current_view: EDocScope.details,
            is_loading: true,
        });
        this.loadRef(parseInt(ref_id, 10));
    }
    private openDocList() {
        this.setState({
            current_view: EDocScope.list,
            is_loading: true,
            selected_item: null,
        });
        this.loadList();
    }
    private isLoading() {
        return (
            <div>
                <SzSvgLoader />
            </div>
        );
    }
    private get_md(s: string) {
        if (!s) {
            return null;
        } else {
            return <SzMdViewer value={s} />;
        }
    }

    private renderList() {
        const list_items: IDocList[] = this.state.list_items;
        const getContent = (p: IDocList) => {
            return (
                <div style={{paddingBottom: 5}}>
                    {this.get_md(p.text_long)}
                </div>
            );
        };
        const renderLegend = (items: string[]) => {
            return (
                <div className={"sz-tag-legend"}>
                    {items.map( (i) => <a className={`sz-tag-legend-item`} href={`#taglegend${i}`}>{i}</a> )}
                </div>
            );
        };
        const hndTagClick = (ref_id: string, more: boolean) => {
            this.openDocRef(ref_id);
            // location.hash = `doc_id=${ref_id}`;
        };
        const renderTag = (p: IDocList, idx) => {
            return (
                <div className={"sz-tag"} style={idx === 0 ? {marginLeft: 0} : {}}>
                    <a className={`${Classes.TEXT_MUTED} sz-wp-post-ref`} onClick={ () => hndTagClick(p.ref_id, p.more) } >{p.text_short}</a>
                </div>
            );
        };

        const content = [];
        const abc = {};
        list_items.forEach( (p, idx) => {
            const firstLetter = (p.text_short && p.text_short.length > 0 ? p.text_short[0] : "").toUpperCase();
            if (!abc[firstLetter]) {
                abc[firstLetter] = [];
            }
            abc[firstLetter].push(renderTag(p, abc[firstLetter].length));
        } );

        Object.keys(abc).forEach( (key, idx) => {
            content.push(<span id={`taglegend${key}`}> </span>);
            content.push(<h4>{key}</h4>);
            const items = abc[key];
            for (let i = 0; i < items.length; i++ ) {
                content.push(items[i]);
                if (i + 1 < items.length) {
                    content.push(<Icon icon={IconNames.DOT} color={"#bdbdbd"} />);
                }
            }
        } );

        return (
            <div style={{padding: 10, position: "relative", display: "flex", justifyItems: "flex-start", flexDirection: "column"}}>
                {renderLegend(Object.keys(abc))}
                <div className={"sz-tag-list"}>{content.map( (c) => c )}</div>
            </div>
        );
    }

    private renderDetails() {
        const item: IDocDetails = this.state.selected_item;
        const hndBackClick = () => {
            this.openDocList();
            // location.hash = "#doc_list";
        };
        const renderItem = () => {
            if (!item) {
                return (
                    <Callout intent={Intent.WARNING} title={"Datenfehler"}>
                        <p>Zu dem gewählten Text gibt es keine weiteren Information.</p>
                        <a><Icon icon={IconNames.ARROW_LEFT} onClick={ () => hndBackClick()} />&nbsp;Zurück</a>
                    </Callout>
                );
            }
            const renderBorder = () => {
                if (!item) {
                    return null;
                }
                if (!item.methodology) {
                    return null;
                }
                return (<div style={{height: 1, borderBottom: "1px solid #b5b5b5", marginBottom: 16, marginRight: -10, marginLeft: -10}}></div>);
            };
            let rendered = 0;
            const renderFx = () => {
                if (!item.formula) {
                    return null;
                }
                rendered++;
                const fx = this.get_md(item.formula);
                return (
                    <Callout intent={"primary"} icon={IconNames.FUNCTION}>
                        <H4>Definition</H4>
                        {fx}
                    </Callout>
                );
            };
            const renderDataSource = () => {
                if (!item) {
                    return null;
                }
                if ((!item.is_our_data_source) && (!item.data_source) ) {
                    return null;
                }
                rendered++;
                let a = null;
                let b = null;
                let p = 0;
                if (item.data_source) {
                    p = 16;
                    a = (
                        <>
                            <H4>Rohdaten</H4>
                            {item.data_source.split("\n").map( (s) => {
                                if (!s) {
                                    return null;
                                }
                                return (
                                    <>
                                        {s}
                                        <br/>
                                    </>
                                );
                            } )}
                        </>
                    );
                }
                if (item.is_our_data_source) {
                    b = (
                        <>
                            <H4 style={{marginTop: p}}>Erweiterte Daten</H4>
                            smartZebra GmbH
                        </>
                    );
                }
                return (
                    <div>
                        <Callout intent={"primary"} icon={IconNames.DATABASE}>
                            {a}
                            {b}
                        </Callout>
                    </div>
                );
            };
            const renderBlogRef = () => {
                if (!item.blog_ref) {
                    return null;
                }
                const paddingTop = rendered > 0 ? 16 : 0;
                return (
                    <div>
                        <Callout intent={"primary"} icon={IconNames.TAG}>
                            <H4>Passende Beiträge</H4>
                            {item.blog_ref.split("\n").map( (s) => {
                                const l = s.split("|");
                                return (
                                    <>
                                        <a href={l[1]} target={"_blank"}>{l[0]}</a><br/>
                                    </>
                                );
                            })}
                        </Callout>
                    </div>
                );
            };
            return (
                <div style={{marginTop: 16}}>
                    <h3>{item.text_short}</h3>
                    <div className={"sz-row"} style={{paddingBottom: 16}}>
                        <div style={{width: "calc( 50% - 8px )", marginRight: 16}}>
                            {this.get_md(item.text_long)}
                        </div>
                        <div style={{width: "calc( 50% - 8px )"}}>
                            {renderFx()}
                            {renderDataSource()}
                            {renderBlogRef()}
                        </div>
                    </div>
                    {renderBorder()}
                    {this.get_md(item.methodology)}
                </div>
            );
        };
        return (
            <div style={{padding: 10, position: "relative", display: "flex", justifyItems: "flex-start", flexDirection: "column"}}>
                <div className={"sz-tag-legend"} style={{justifyContent: "left", paddingTop: 5, paddingBottom: 5}}>
                    <Button intent={Intent.PRIMARY} text={"Zurück"} icon={IconNames.ARROW_LEFT} minimal={true} onClick={ () => hndBackClick()} />
                </div>
                <div className={"sz-tag-list"}>
                    {renderItem()}
                </div>
            </div>
        );
    }

    private loadList() {
        // location.hash = "doc_list";
        (async () => {
            try {
                const db = new PublicDbCalls();
                const ret = await db.getDocumentation(Globals.current_language, EDocScope.list);
                const list_items: IDocList[] = ret.result && Array.isArray(ret.result) ? ret.result : [];
                this.setState({
                    is_loading: false,
                    list_items,
                });
            } catch (exi) {
                console.error(exi);
            }
        })();
    }

    private loadRef(ref_id: number) {
        // location.hash = `doc_id=${ref_id}`;
        (async () => {
            try {
                const db = new PublicDbCalls();
                const ret = await db.getDocumentation(Globals.current_language, EDocScope.details, ref_id);
                const list_items: IDocDetails[] = ret.result && Array.isArray(ret.result) ? ret.result : [];
                this.setState({
                    is_loading: false,
                    selected_item: list_items[0],
                });
            } catch (exi) {
                console.error(exi);
            }
        })();
    }
}
