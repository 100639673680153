import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {Globals} from "../../../const/Globals";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";


const toStr = (a)=>{
    if(Array.isArray(a)){
        return a.join(",");
    }else{
        return a;
    }
}

export class RegionParameterV2 extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getDocLabel(value) {
        const options = ParameterOptions.options[EParameters.RegionParameterV2];
        const selected = options.find((o)=>toStr(o.value)===toStr(value));
        if(!selected){
            return Globals.hyphen;
        }
        const v = Array.isArray(selected.value)? selected.value.join(", ") : "Alle";
        return v;
    }
    public static getPdfLabel() {
        const value = SessionStore.get(EParameters.RegionParameterV2);
        const options = ParameterOptions.options[EParameters.RegionParameterV2];
        const selected = options.find((o)=>toStr(o.value)===toStr(value));
        if(!selected){
            return Globals.hyphen;
        }
        const v = Array.isArray(selected.value)? selected.value.join(", ") : _t(ETranslation.all);
        return {
            text: v
        };
    }
    protected getWidthClass(): string {
        return "fixed-width-250";
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.RegionParameterV2];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.RegionParameterV2);
    }
    protected async getOptions() {
        return ParameterOptions.options[EParameters.RegionParameterV2];
    }

    protected renderHint(){
        return (
            <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                * {_t(ETranslation.default_smartzebra)}
            </div>
        );
    }
}
