import {HTMLTable, Spinner} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {getResult} from "../../helpers/Helpers";
import {EParameters} from "../../models/EParameters";
import {
    ICompany, ICompanyDetailsDataExt, ICompanyDetailsExt,
} from "../../models/ICompanyDetails";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {EFeItem} from "../../models/EFeItem";
import {IMultiplierForCompaniesV2} from "../../models/IMultiplierForCompaniesV2";
import {asFinAnnReportedV2, IFinAnnReportedV2} from "../../models/IFinAnnReported";
import {fromDateDecimal, fromStr} from "../../tools/DateTools";
import {DateTime} from "luxon";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export interface ICompanyEstimatesProps {
    company_id: string;
}

interface ICompanyEstimatesState {
    is_loading: boolean;
    current_year: number;
    company_id: string;
    est_currency_iso?: string;
    company?: ICompany;
    feItems?: any;
    feNullItems?: any;
    details?: ICompanyDetailsExt;
}

export class CompanyEstimates extends React.Component<ICompanyEstimatesProps, ICompanyEstimatesState> {
    private readonly global_year: number;
    private country_xch_map = {};

    private finance_years: number[] = [];
    private finance_map: {[index: number]: IFinAnnReportedV2} = {};

    constructor(props: ICompanyEstimatesProps, context: any) {
        super(props, context);
        const year = fromStr(SessionStore.get(EParameters.DateParameter)).year - 1;
        this.global_year = year;
        const current_year = DateTime.now().year - 1;
        this.state = {
            is_loading: true,
            company_id: this.props.company_id,
            current_year,
        };
    }
    public render() {
        const backGroundStyle: CSSProperties = {
            width: "100%",
            height: "100%",
            overflowY: "auto",
            backgroundColor: "#ffffff",
        };
        return (<div style={backGroundStyle} className={"sz-relative"} >{this.state.is_loading ? this.renderLoading() : this.renderContent()}</div>);
    }
    private renderLoading() {
        (async ()=>{
            await this.loadData();
        })();
        return(
            <div style={{position: "relative", height: "100%", width: "100%", display: "flex", justifyContent: "center"}}>
                <Spinner size={250} />
            </div>
        );
        /*<SzSvgLoader />*/
    }
    private async loadDetails(db: PublicDbCalls, target_year: number): Promise<ICompanyDetailsExt> {
        const result = await db.getCompanyDetailsExt(parseInt(this.state.company_id, 10), target_year);
        const use: ICompanyDetailsDataExt[] = result.result ? result.result : [];
        const getAnn = ( d ) => fromDateDecimal(d).year;
        const getDate = ( d ) => fromDateDecimal(d).toFormat("dd.LL.");
        const companyDetails: ICompanyDetailsExt = {
            detailsData : Array(6),
        };
        const found_years = {};
        const min_year: number = target_year - 5;
        use.forEach((item: ICompanyDetailsDataExt, idx) => {
            Object.keys(item).forEach( (k) => {

                if (item[k] === null) {
                    item[k] = undefined;
                }

            });
            const year = getAnn(item.date_decimal);
            found_years[year] = true;

            item.date = getDate(item.date_decimal);
            item.year = year;
            item.netto_debt = item.ff_debt - item.ff_cash_generic;

            companyDetails.currency_iso = item.currency_iso;
            companyDetails.p_exchange = item.p_exchange;
            companyDetails.p_first_date = item.p_first_date;
            companyDetails.n_last_date = parseInt(item.p_last_date, 10);
            companyDetails.ff_sic_code = item.ff_sic_code;
            if (companyDetails.p_first_date) {
                const date = companyDetails.p_first_date;
                companyDetails.p_first_date = fromStr(date, "LL/dd/yyyy").asDeDate();
                companyDetails.n_first_date = fromStr(date, "LL/dd/yyyy").asDateDecimal();
            }

            companyDetails.detailsData[year - min_year] = item;
        });

        const fixed_years = [min_year, min_year + 1, min_year + 2, min_year + 3, min_year + 4, min_year + 5];
        fixed_years.forEach( (y) => {
            if (found_years[y]) {
                return;
            }
            const item = {
                date: `${y}1231`,
                year: y,
            };
            companyDetails.detailsData[y - min_year] = item;
        } );

        // console.error(companyDetails);
        return companyDetails;
    }
    private async loadData() {
        try{
            const company_id = this.state.company_id;
            const db: PublicDbCalls = new PublicDbCalls();
            const r_company = await db.selectCompanies([company_id]);
            const company: ICompany = getResult(r_company, [])[0];

            const date = SessionStore.get(EParameters.DateParameter);
            const date_decimal = fromStr(date).asDateDecimal();
            const response_currency_rate = await db.getCurrencyRate2([date_decimal]);
            response_currency_rate.result.forEach( (i) => this.country_xch_map[i.country_currency_iso_3] = i.from_euro_to_ );


            const sel_fe_items = [EFeItem.SALES, EFeItem.EBITDA, EFeItem.EBIT, EFeItem.NETPROFIT, EFeItem.GROSSINCOME];
            const response = await db.getMultiplierForCompaniesV2([
                [SessionStore.get(EParameters.DateParameter)],
                [company_id],
                sel_fe_items,
                [0, 1, 2, 3],
            ]);
            // const company_details = await db.getCompanyDetails2(parseInt(company_id, 10));
            const feItems = {};
            const feNullItems = {};
            let mm;
            let null_date;
            let null_year;
            if (response && Array.isArray(response.result)) {
                const multi_base: IMultiplierForCompaniesV2[] = [].concat(response.result);
                multi_base.forEach( (m) => {
                    if (!mm) {
                        mm = m.currency_iso;
                    }
                    if(m.fe_per_rel==="0" && null_date===undefined){
                        const d = fromStr(m.fe_fp_end, "dd-LLL-yyyy");
                        if(d.isValid){
                            null_date = d.toFormat("dd.L.yyyy");
                            null_year = parseInt(d.toFormat("yyyy"), 10);
                        }
                    }
                } );
                const getData = (item: EFeItem, array: IMultiplierForCompaniesV2[]) => (array.filter( (a) => a.fe_item === item && a.fe_per_rel !== "0"))
                    .sort((a, b) => a.fe_per_rel.localeCompare(b.fe_per_rel))
                    .map( (a) => a.fe_median );
                const getNullData = (item: EFeItem, array: IMultiplierForCompaniesV2[]) => (array.filter( (a) => a.fe_item === item && a.fe_per_rel === "0"))
                    .map( (a) => a.fe_median );
                sel_fe_items.forEach( (fe) => feItems[fe] = getData(fe, multi_base) );
                sel_fe_items.forEach( (fe) => feNullItems[fe] = getNullData(fe, multi_base) );
            }
            if(null_date){
                const parameters_financials = [
                    [company_id],
                    [null_date],
                    [7],
                ];
                const fin_rep = await db.getFinancialsAnnReported(parameters_financials);
                const financials: IFinAnnReportedV2[] = getResult(fin_rep, []).map((a) => asFinAnnReportedV2(a));
                const fin_map: {[index: number]: IFinAnnReportedV2} = {};
                const years = (new Array(8)).fill(1).map((i, idx) => null_year - idx).reverse();
                years.forEach( (y) => {
                    const f = financials.find((_f) => Math.trunc(parseInt(_f.date_decimal, 10)/ 10000) === y);
                    if(f){
                        fin_map[y] = f;
                    }else{
                        fin_map[y] = {} as IFinAnnReportedV2;
                    }
                });
                this.finance_years = years;
                this.finance_map = fin_map;
            }
            sel_fe_items.forEach( (fe) => {
                while (Array.isArray(feItems[fe]) && feItems[fe].length < 3) {
                    feItems[fe].push(undefined);
                }
                while (Array.isArray(feItems[fe]) && feNullItems[fe].length < 3) {
                    feNullItems[fe].push(undefined);
                }
            } );
            const details: ICompanyDetailsExt = await this.loadDetails(db, this.global_year);
            this.setState({
                is_loading: false,
                est_currency_iso: mm,
                company,
                feItems,
                feNullItems,
                details,
            });

            return;
        }catch(ex){
            console.error(ex);
        }
        this.setState({
            is_loading: false,
        });
    }
    private renderContent() {
        const company: ICompany = this.state.company;
        if(!company){
            return undefined;
        }
        return (
            <div style={{paddingLeft: 1, paddingRight: 1}}>
                {this.renderFinanceTable()}
            </div>
        );
    }
    private renderFinanceTable() {
        const fin_years: number[] = this.finance_years;
        const fin_map = this.finance_map;
        const d: ICompanyDetailsExt = this.state.details;
        const target_currency = d.currency_iso;
        const source_currency = this.state.est_currency_iso;

        const from_xch = this.country_xch_map[source_currency];
        const to_country_xch = this.country_xch_map[target_currency];
        const xch_1 = (v) => {
            if (from_xch === to_country_xch) {
                return v;
            }
            return (v / from_xch) * to_country_xch;
        };

        const formatter = (v, digits?, pot?) => {
            if (v === undefined || v === null) {
                return "—";
            }
            if (!isFinite(v)) {
                return "—";
            }
            if (isNaN(v)) {
                return "—";
            }
            const fm = (new Intl.NumberFormat("de-de", {
                maximumFractionDigits: digits ? digits : 0,
                minimumFractionDigits: digits ? digits : 0,
            })).format;
            if (pot) {
                const v_pot = Math.pow(10, pot);
                v = Math.round( v / v_pot ) * v_pot;
            }

            return fm(v);
        };

        const feItems = this.state.feItems;
        const feNullItems = this.state.feNullItems;
        const fe_item = (item: EFeItem) => feItems[item].map((e, idx) => <td className={`money${idx === 0 ? " hover-col" : ""}`} style={{...p_3, }}>{formatter(xch_1(e), 0, 1)}</td>);
        const fe_item_marge = (item: EFeItem) => feItems[item].map((e, idx) => {
            const sales_val = feItems[EFeItem.SALES][idx];
            if (sales_val === undefined || sales_val === null) {
                return (<td className={`money${idx === 0 ? " hover-col" : ""}`} style={{...p_3, }}>{Globals.hyphen}</td>);
            }
            return (<td className={`money${idx === 0 ? " hover-col" : ""}`} style={{...p_3, }}>{formatter((xch_1(e) / xch_1(feItems[EFeItem.SALES][idx])) * 100, 1)}</td>);
        });
        const fmtDate = (a_date) => {
            if (a_date && a_date.length < 8) {
                return a_date;
            }

            try {
                return fromDateDecimal(a_date).toFormat("dd.LL");
            } catch (e) {
                return Globals.hyphen;
            }
        };
        const yearsColumn = (nField, yField?) => {
            return fin_years.map((y, idx, ar)=>{
                if(idx === 0){
                    return null;
                }
                const f: IFinAnnReportedV2 = fin_map[y] ? fin_map[y] : {} as IFinAnnReportedV2;
                if (!yField) {
                    return (<td className={"money"} style={{...p_3, backgroundColor: "#ffffff"}}>{formatter(xch_1(f[nField]))}</td>);
                }
                const previous_fin: IFinAnnReportedV2 = fin_map[ar[idx - 1]] ? fin_map[ar[idx - 1]] : {} as IFinAnnReportedV2;
                const nValue = f[nField];
                const yValue = previous_fin[yField];
                // console.error(yValue);
                return (<td className={"money"} style={{...p_3, }}>{formatter(xch_1((nValue / yValue - 1) * 100))}</td>);
            });
        };
        const yearsMarge = (nField) => {
            return fin_years.map((y, idx, ar)=>{
                if(idx === 0){
                    return null;
                }
                // const val = xch_1(feNullItems[EFeItem.SALES][0]);
                const f: IFinAnnReportedV2 = fin_map[y] ? fin_map[y] : {} as IFinAnnReportedV2;
                const val = f.ff_sales;
                const e = f[nField];
                if (val === undefined || val === null) {
                    return (<td className={"money"} style={{...p_3, }}>{Globals.hyphen}</td>);
                }
                return (<td className={"money"} style={{...p_3, }}>{formatter(xch_1((e / val) * 100))}</td>);
            });
        };
        // console.error(this.state, d);
        const p_3 = {
            paddingTop: 3, paddingBottom: 3
        };
        return(
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%", fontSize: "85%", marginBottom: 16}}>
                <thead>
                <tr style={{backgroundColor: "#F0F0F0",boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}>
                    <th colSpan={6 + fin_years.length - 1} style={{fontSize: "14px"}}>Schätzungen {SessionStore.get(EParameters.DateParameter)}</th>
                </tr>
                <tr>
                    <th style={{...p_3, fontWeight: "normal", textAlign: "left"}}>Mio. {d.currency_iso} | {fmtDate(d.detailsData[d.detailsData.length - 1].date)}</th>
                    <th style={{...p_3, width: 80}} >&nbsp;</th>
                    {fin_years.map((e, idx) => idx === 0 ? null : <th className={"money"} style={{...p_3, minWidth: 80}}>{e}</th>)}
                    {feItems[EFeItem.SALES].map((e, idx) => <th className={"money"} style={{...p_3, minWidth: 80}}>{this.global_year + 1 + idx}</th>)}
                </tr>
                <tr>
                    <th style={{textAlign: "left"}}>&nbsp;</th>
                    <th style={{...p_3, width: 80}} >&nbsp;</th>
                    {fin_years.map((e, idx) => idx === 0 ? null : <th className={"money"} style={{...p_3, minWidth: 80}}>Ist</th>)}
                    {feItems[EFeItem.SALES].map((e, idx) => <th className={"money"} style={{...p_3, minWidth: 80}}>Est.</th>)}
                </tr>

                </thead>
                <tbody>
                <tr>
                    <td style={{...p_3, }} colSpan={2}>{_t(ETranslation.revenues)}</td>
                    {yearsColumn("ff_sales")}
                    {fe_item(EFeItem.SALES)}
                </tr>
                <tr>
                    <td style={{...p_3, }} colSpan={2}>{_t(ETranslation.revenue_growth)} (%)</td>
                    {yearsColumn("ff_sales", "ff_sales")}
                    {feItems[EFeItem.SALES].map((e, idx) => {
                        const n = xch_1(idx === 0 ? feNullItems[EFeItem.SALES][0] : feItems[EFeItem.SALES][idx - 1]);
                        return (<td className={`money${idx === 0 ? " hover-col" : ""}`} style={{...p_3, }}>{formatter((xch_1(e) / n - 1) * 100, 1)}</td>);
                    })}
                </tr>
                <tr>
                    <td style={{...p_3, }} colSpan={2}>Rohertrag</td>
                    {yearsColumn("ff_gross_inc")}
                    {fe_item(EFeItem.GROSSINCOME)}
                </tr>
                <tr>
                    <td style={{...p_3, }} colSpan={2}>Rohertrag-Marge (%)</td>
                    {yearsMarge("ff_gross_inc")}
                    {fe_item_marge(EFeItem.GROSSINCOME)}
                </tr>
                <tr>
                    <td style={{...p_3, }} colSpan={2}>EBITDA</td>
                    {yearsColumn("ff_ebitda")}
                    {fe_item(EFeItem.EBITDA)}
                </tr>
                <tr>
                    <td style={{...p_3, }} colSpan={2}>{_t(ETranslation.ebitda_margin)} (%)</td>
                    {yearsMarge("ff_ebitda")}
                    {fe_item_marge(EFeItem.EBITDA)}
                </tr>
                <tr>
                    <td style={{...p_3, }} colSpan={2}>EBIT</td>
                    {yearsColumn("ff_ebit")}
                    {fe_item(EFeItem.EBIT)}
                </tr>
                <tr>
                    <td style={{...p_3, }} colSpan={2}>{_t(ETranslation.ebit_margin)} (%)</td>
                    {yearsMarge("ff_ebit")}
                    {fe_item_marge(EFeItem.EBIT)}
                </tr>
                <tr>
                    <td style={{...p_3, }} colSpan={2}>Jahresüberschuß</td>
                    {yearsColumn("ff_net_inc")}
                    {fe_item(EFeItem.NETPROFIT)}
                </tr>
                <tr>
                    <td style={{...p_3, }} colSpan={2}>Jahresüberschuß-Marge (%)</td>
                    {yearsMarge("ff_net_inc")}
                    {fe_item_marge(EFeItem.NETPROFIT)}
                </tr>
                </tbody>
            </HTMLTable>
        );
    }
}
