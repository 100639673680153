import {EContentType} from "../../const/EContentType";
import {EDataSourceMode} from "../../const/EDataSourceMode";
import {EPackageTypes} from "../../const/EPackageTypes";
import {NopeActions} from "../../const/NopeActions";
import {EFeItem} from "../../models/EFeItem";
import {ELayoutColumnWidth} from "../../models/ELayoutColumnWidth";
import {EParameters} from "../../models/EParameters";
import {IColumnLayout} from "../../models/IColumnLayout";
import {ColumnLayouterImpl} from "../ColumnLayouterImpl";
import {ModuleGroup} from "../ModuleGroup";
import {EExcelExportType} from "../../const/EExcelExportType";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {RegionParameterV2} from "../../components/toolbar/inputs/RegionParameterV2";
import {IModuleConfig} from "../../models/IModuleConfig";

export class SectorsModule extends ModuleGroup<IColumnLayout> {
    public static config: IModuleConfig = {
        name: "Branchendaten",
    };
    constructor() {
        super("Branchen", { columns: []});

        this.addParameter(EParameters.DateParameter);
        this.addParameter(EParameters.SectorParameterV2);
        this.addParameter(EParameters.RegionParameterV2);

        this.addModule(_t(ETranslation.sector_multiples), "MultiplierForBusiness", "MultiplicatorsForBusinessProvider")
            .setAccess(EContentType.Elite, EPackageTypes.World, NopeActions.elite)
            .setExcelExport(true)
        ;
        this.addModule(_t(ETranslation.sector_beta), "BetaForBusiness", "BetasForBusinessProvider")
            .setAccess(EContentType.Elite, EPackageTypes.World, NopeActions.elite)
            .setExcelExport(true)
        ;
        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c100)
            .addModule([0, 1]);
/*
        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c30)
            .addModule(3);

 */
    }
    protected getTitle(): string{
        return "Multiplikatoren";
    }
}
