import moment = require("moment");
import {IRegression} from "../models/chef/RawBeta";
import {ICompressedArrayItem} from "../models/ICompressedArrayItem";
import {fromDateDecimal} from "../tools/DateTools";
import {Globals} from "../const/Globals";
import {ChefCalls} from "../services/ChefCalls";
import {FrontendCalls} from "../services/FrontendCalls";
import {ServiceCalls} from "../services/ServiceCalls";

export function find_object(object, predicate: (key: string, object)=> unknown, reverse?) {
    if(!object){
        return undefined;
    }
    const keys= reverse ? Object.keys(object).reverse() : Object.keys(object);
    const s = keys.find((object_key)=>predicate(object_key, object[object_key]));
    return object[s];
}

export function no_star(text) {
    if(!text){
        return text;
    }
    return `${text}`.replace("*","");
}
export function first<K>( ar: any[] , def: K): K {
    if(!Array.isArray(ar)){
        return def;
    }
    if(!ar.length){
        return def;
    }
    return ar[0] as K;
}
export function last<K>( ar: any[] , def: K): K {
    if(!Array.isArray(ar)){
        return def;
    }
    if(!ar.length){
        return def;
    }
    return ar[ar.length - 1] as K;
}

export function getResult<T>( a: any, d: T): T {
    if (a && a.result && Array.isArray(a.result)) {
        return a.result as T;
    }
    if (a && a.response && Array.isArray(a.response)) {
        return a.response as T;
    }
    return d;
}
export function getAllNextDates(date_end, past_years) {
    const startOfPeriod = parseInt(moment(date_end, "DD.MM.YYYY").startOf("month").subtract(past_years, "years").endOf("month").format("YYYYMMDD"), 10);
    let current: number = parseInt(moment("" + date_end, "DD.MM.YYYY").endOf("month").format("YYYYMMDD"), 10);
    const ret = [];
    while (startOfPeriod <= current) {
        ret.push(moment("" + current, "YYYYMMDD").format("DD.MM.YYYY"));
        current = parseInt(moment("" + current, "YYYYMMDD").startOf("month").subtract(1, "month").endOf("month").format("YYYYMMDD"), 10);
    }
    return ret;
}
export function sleep(ms): Promise<void>{
    return new Promise<void>( (resolve, reject) => {
        setTimeout( ()=> resolve(), ms );
    });
}

export type IndexMap<T> = { [index:string] : T[]};
export function group_by<T>(input: any[], field_name): IndexMap<T> {
    const result: IndexMap<T> = {};
    input.forEach((i)=>{
        if(!Array.isArray(result[i[field_name]])){
            result[i[field_name]] = [];
        }
        result[i[field_name]].push(i);
    });
    return result;
}

export function linearRegression(data: ICompressedArrayItem[]): IRegression{
    const result: IRegression = {} as IRegression;
    result.DataPoints = 0;
    const n = data.length;

    let sumA=0; // a
    let sumB=0; // b
    let days = 0;
    data.forEach((d)=>{
        sumA += d.a;
        sumB += d.b;
        days += d.days;
    });
    const averageA=sumA/n;
    const averageB=sumB/n;
    result.MeanA=averageA;
    result.MeanB=averageB;

    let sumDividend=0;
    let sumDivisor=0;
    let tss=0;
    let sumx2=0;

    data.forEach((d)=>{
        const a=d.a;
        const b=d.b;
        const deltaA=a-averageA;
        const deltaB=b-averageB;
        sumDividend += deltaA * ( b - averageB );
        sumDivisor += Math.pow(deltaA,2);
        tss += Math.pow(deltaB,2);
        sumx2 += a * b;
    });
    result.Days = days;
    result.DataPoints=n;
    result.SumQDA = sumDivisor;
    result.SumQDB = tss;
    result.Slope = sumDividend / sumDivisor;
    result.Intercept = averageB - ( result.Slope * averageA );
    result.VarianceA = result.SumQDA / ( n - 1 );
    result.VarianceB = result.SumQDB / ( n - 1 );

    let rss = 0;
    data.forEach((d)=>{
        const a=d.a;
        const b=d.b;

        const b_dach = result.Intercept + ( result.Slope * a );
        rss+=Math.pow(b - b_dach,2);
    });
    result.K = 2; // freiheitsgrade
    result.SumQDResiduals=rss;
    result.R2=1-(rss/tss);
    result.R=Math.sqrt(result.R2);
    result.AdjR2=1-((rss/tss)*((result.DataPoints-1)/(result.DataPoints-result.K)));

    result.StandardErrorBeta=Math.sqrt(result.SumQDResiduals/(result.DataPoints-result.K));
    result.StandardErrorIntercept=result.StandardErrorBeta*Math.sqrt(sumx2/(result.DataPoints*result.SumQDA));
    result.StandardErrorSlope=result.StandardErrorBeta/Math.sqrt(result.SumQDA);

    result.TTestIntercept=result.Intercept/result.StandardErrorIntercept;
    result.TTestSlope=result.Slope/result.StandardErrorSlope;

    result.FTest=(result.R2/(1-result.R2))*((result.DataPoints-result.K)/(result.K-1));

    return result;
}
export interface IDateDecimal {
    date_decimal: number;
}
export function adjustArrays(a: IDateDecimal[], b: IDateDecimal[]): {return_a: IDateDecimal[], return_b: IDateDecimal[]}{
    const dates = {};
    const return_a = [];
    const return_b = [];
    a?.forEach((i)=>{
        dates[i.date_decimal] = i;
    });
    b?.forEach((i)=>{
        if(dates[i.date_decimal]){
            return_a.push(dates[i.date_decimal]);
            return_b.push(i);
        }
    });
    return {
        return_a,
        return_b
    };
}

const quarter_colors = [
    "rgba(255,255,255,0)",
    "rgba(182,182,182,0.11)",
    "rgba(255,255,255,0)",
    "rgba(182,182,182,0.11)",
    "rgba(255,255,255,0)",
    "rgba(182,182,182,0.11)",
];
export function mkQuarterlyAnnotation(dates: number[]) {
    const annotation = {
        clip: false,
        annotations : {} as any
    };
    let last_annotation;
    let last_hit;
    let is_shifted = false;
    const labels = [];
    dates.forEach((d, idx, array)=>{
        const dt = fromDateDecimal(d);
        const start_q = dt.startOf("quarter").startOf("day").asDateDecimal();

        if(idx === 0){
            is_shifted = start_q !== d;
        }
        labels.push(undefined);

        const quarter = dt.get("quarter");
        const year = dt.get("year");
        const key = `Q${quarter} ${dt.toFormat("yy")}`;

        if(last_hit!==key){
            last_hit = key;
            labels[idx] = ""; // dt.toFormat("dd.LL.yy", {locale: "de"});

            const getContent = ()=>{
              if(is_shifted && idx === 0){
                  return "";
              }
              return key;
            };
            if(last_annotation){
                last_annotation.xMax = idx;
            }
            last_annotation = {
                type: "box",
                backgroundColor: quarter_colors[quarter],
                borderWidth: 0,
                xMax: dates.length,
                xMin: idx,
                label: {
                    drawTime: "afterDraw",
                    display: true,
                    content: getContent(),
                    color: "#485d63",
                    font: {
                        size: 10,
                        weight: "lighter",
                    },
                    yAdjust: 24, // 24
                    position: {
                        x: 'center',
                        y: 'end'
                    }
                }
            };
            annotation.annotations[key] = last_annotation;
        }
    });

    if(is_shifted && last_annotation){
        last_annotation.label.content = "";
        labels[0] = undefined;
    }

    return {
        annotation,
        labels,
    };
}

export const anyNot = (...args)=>{
    return args.some((a)=>!a);
}

export interface IShortFormatter {
    m(v, d);
    p(v, d);
}
export const _fmt = {
    /*
    * format value 1,000.00
    * v: float, d: digits default 2
    * */
    m: (v, d= 2) => Globals.formatter(v, d, d, true),
    /*
    * format value 1,000.00 with leading + or - sign
    * v: float, d: digits default 2
    * */
    d: (v, d= 2) => Globals.formatter_delta(v, d, d, true),
    /*
    * format percent value 1,000.00 %
    * v: float, d: digits default 2
    * */
    p: (v, d= 2) => Globals.formatter_percent(v, d, d, true)
};

export const map_by = (array: any[], field_name: string)=>{
    const ret = {};
    array.forEach((i)=> ret[i[field_name]] = i);
    return ret;
};

export const checkSession = async ()=>{

    Globals.isChargeBee = true;
    Globals.subscriptions = undefined;

    Globals.user = undefined;
    Globals.isRegistred = false;
    Globals.isAdmin = false;

    Globals.user_id = undefined;
    Globals.ownsPackage = 0;

    const getToken = async ()=>{
        const response = await fetch("get_token", {method: "GET"});
        const result = await response.json();
        // console.error(result);
        return result.token;
    };
    const services = new ServiceCalls();
    const db = new ChefCalls();
    const calls = new FrontendCalls();
    const r = await calls.active_session();
    if(r.uuid){
        const token: string = await getToken();
        const chef_token: string = await getToken();

        if(!r.token){
            r.token = token;
        }

        const loginResponse = await services.login2(token);
        await db.login(chef_token);
        const e = await db.isChargebeeUser();

        Globals.user = r;
        Globals.isRegistred = true;
        Globals.isAdmin = r.is_admin;

        Globals.user_id = loginResponse.id;
        Globals.ownsPackage = 4;

        Globals.isChargeBee = !e.error;
        Globals.subscriptions = e.subscriptions;
    }

};

export const _k = (...p: any)=>{
    return p.join("_");
};

export const getRatingIndication = (rating_score: number): string => {
    if (rating_score < 1) {
        return "AAA";
    } else if (rating_score < 4) {
        return  "AA";
    }  else if (rating_score < 5) {
        return  "AA-";
    } else if (rating_score < 6) {
        return  "A+";
    } else if (rating_score < 7) {
        return  "A";
    } else if (rating_score < 8) {
        return  "A-";
    } else if (rating_score < 9) {
        return  "BBB+";
    } else if (rating_score < 10) {
        return  "BBB";
    } else if (rating_score < 11) {
        return  "BBB-";
    } else if (rating_score < 12) {
        return  "BB+";
    } else if (rating_score < 13) {
        return  "BB";
    } else if (rating_score < 14) {
        return  "BB-";
    } else if (rating_score < 15) {
        return  "B-";
    } else if (rating_score < 16) {
        return  "B";
    } else if (rating_score < 17) {
        return  "B+";
    } else {
        return  "CCC";
    }
};
