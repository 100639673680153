import {RawBetaParams, RawBetaResponse} from "../models/chef/RawBeta";
import {UnleveredBetaParams} from "../models/chef/UnleveredBeta";
import {EServiceType, ServiceCallBase} from "./ServiceCallBase";
import {cacheCall, ServiceCallCacheOptions} from "../helpers/ServiceCallCache";
import {last} from "../helpers/Helpers";
import {
    BondCompanies,
    BondCurrencyPerYear,
    BondMedianSpreadPerYear,
    BondRankedFieldPerYear,
    BondValid
} from "../models/chef/Bonds";

export interface CacheEntry {
    cacheId: string;
    options: ServiceCallCacheOptions;
}

export class ChefCalls extends ServiceCallBase {
    constructor() {
        super(EServiceType.Chef_Service);
    }
    private async cachedCall(serviceRef: string, parameters: any, defaultRet, cache?: CacheEntry){
        if(cache && cache.cacheId){
            return cacheCall(cache.cacheId, parameters, cache.options, (new_parameters) => {
                return this.callOut2(serviceRef, new_parameters, defaultRet);
            });
        }else{
            return this.callOut2(serviceRef, parameters, defaultRet);
        }
    }
    public async logout(){
        return this.callOut2("/logout", {}, {error: true});
    }
    public async login(token: string){
        return this.callOut2("/login", {token}, {error: true});
    }
    public async getRawBeta(parameters: RawBetaParams, cache?: CacheEntry): Promise<RawBetaResponse> {
        return this.cachedCall("beta/raw_beta", parameters, {}, cache);
    }
    public async loadRiskMonitor(parameters: RawBetaParams, cache?: CacheEntry): Promise<any> {
        return this.cachedCall("beta/risk_monitor", parameters, {}, cache);
    }
    public async loadIndustryBeta(parameters: RawBetaParams, cache?: CacheEntry): Promise<any> {
        return this.cachedCall("beta/industry_beta", parameters, {}, cache);
    }
    public async raw_beta_7days(parameters: RawBetaParams, cache?: CacheEntry): Promise<RawBetaResponse> {
        return this.cachedCall("beta/raw_beta_7days", parameters, {}, cache);
    }
    public async getUnleveredBeta(parameters: UnleveredBetaParams, cache?: CacheEntry): Promise<any> {
        return this.cachedCall("beta/unlevered_beta", parameters, {}, cache);
    }

    public async getCompanies(companies: any[], cache?: CacheEntry): Promise<any> {
        return this.cachedCall("db/get_companies", {companies}, {}, cache);
    }
    public async getBetaCompareFilterData(companies: any[], deadline: string, cache?: CacheEntry): Promise<any> {
        return this.cachedCall("db/get_beta_compare_filter_data", {companies, deadline}, {}, cache);
    }
    public async getFinancialReports(companies: any[], deadline: string, cache?: CacheEntry): Promise<any> {
        return this.cachedCall("db/get_financial_reports", {companies, deadline}, {}, cache);
    }
    public async getMarketDataAvg(companies: any[], deadline: string, years: number, cache?: CacheEntry): Promise<any> {
        return this.cachedCall("db/get_market_data_avg", {companies, deadline, years}, {}, cache);
    }
    public async getTimeSeriesCompany(fx, company_id, deadline: string, years: number, cache?: CacheEntry): Promise<any> {
        return this.cachedCall("db/get_time_series", {fx, company_id, deadline, years}, [], cache);
    }
    public async getTimeSeriesIndex(fx, index_id, deadline: string, years: number, cache?: CacheEntry): Promise<any> {
        return this.cachedCall("db/get_time_series", {fx, index_id, deadline, years}, [], cache);
    }
    public async getMarketData(company_id, deadline: string, years: number, cache?: CacheEntry): Promise<any> {
        return this.cachedCall("db/get_market_data", {company_id, deadline, years}, [], cache);
    }
    public async getXpDataStock(company_id, deadline: string, years: number, cache?: CacheEntry): Promise<any> {
        return this.cachedCall("db/get_xp_data_stock", {company_id, deadline, years}, [], cache);
    }
    public async getStandardDeviationIndex(stock_index_id, deadline, years: number, currency: string, rendite_type: string){
        const p = {
            stock_index_id,
            deadline,
            years,
            currency,
            rendite_type
        };
        const r = await this.callOut2("db/get_standard_deviation_index", p, []);
        return (last(r, {}) as any).x
    }
    public async getStandardDeviationCompany(company_id, deadline, years: number, currency: string, rendite_type: string){
        const p = {
            company_id,
            deadline,
            years,
            currency,
            rendite_type
        };
        const r = await this.callOut2("db/get_standard_deviation_company", p, []);
        return (last(r, {}) as any).x
    }
    public async getInterestCurvesTimeSeries(deadline, country_iso3: string, currency_iso3: string){
        const p = {
            deadline,
            country_iso3,
            currency_iso3
        };
        return await this.callOut2("db/get_interest_curves_time_series", p, []);
    }
    public async getCompanyMultipliers(deadline, companies, years?: number){
        const p = {
            deadline,
            companies,
            years
        };
        return await this.callOut2("db/get_company_multipliers", p, []);
    }
    public async getBaseRate(deadline: string): Promise<any> {
        const p = {
            deadline
        };
        const r =  await this.callOut2("db/get_base_rate", p, []);
        if(Array.isArray(r) && r.length){
            return r[0];
        }
        return {};
    }
    public async getBondsMonthlyValid(): Promise<BondValid[]> {
        const r =  await this.callOut2("db/bonds_monthly_valid", {}, []);
        return r;
    }
    public async getBondsCompaniesCount(): Promise<BondCompanies[]> {
        const r =  await this.callOut2("db/bonds_companies_count", {}, []);
        return r;
    }
    public async getBondsCurrenciesPerYear(): Promise<BondCurrencyPerYear[]> {
        const r =  await this.callOut2("db/bonds_currencies_per_year", {}, []);
        return r;
    }
    public async getBondsGroupedPerYear(field: string): Promise<BondRankedFieldPerYear[]> {
        const r =  await this.callOut2("db/bonds_grouped_per_year", {field}, []);
        return r;
    }
    public async getBondsMedianSpreadPerYear(): Promise<BondMedianSpreadPerYear[]> {
        const r =  await this.callOut2("db/bonds_median_spread_per_year", {}, []);
        return r;
    }
    public async askKaySearchCompanies(business_activity: string){
        const p = {
            business_activity
        };
        return await this.callOut2("ask-kay/search-companies", p, []);
    }

    public async isChargebeeUser(){
        return await this.callOut2("/chargebee/is_customer", undefined, {error:true}, "GET");
    }
    public async createChargebeeSession(): Promise<any> {
        const r =  await this.callOut2("/chargebee/create_session", undefined, {error:true}, "GET");
        return r;
    }
}
