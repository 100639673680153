import * as React from "react";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import * as UseAuth from "../hooks/UseAuth";
import {Button, Icon} from "@blueprintjs/core";
import {checkSession} from "../../helpers/Helpers";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";
import {showLoginError} from "./AppToaster";


export function Login(){
    const auth = UseAuth.useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [uuid, setUuid] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const pwdState = ()=>{
        return (
            <Icon
                size={20}
                icon={showPassword ? "eye-off" : "eye-open"}
                onClick={()=>setShowPassword(!showPassword)}
            />
        );
    };
    const login = (e)=> {
        e.preventDefault();
        e.stopPropagation();
        if(isLoading){
            return;
        }
        setIsLoading(true);
        (async ()=>{
            try {
                const r = await auth.signin(uuid, password);
                if(r.error){
                    showLoginError();
                }else{
                    await checkSession();
                    navigate("/");
                }
            }catch (e) {
                console.error(e);
            }
            setIsLoading(false);
        })();
    };
    return (
        <div className="login-container">
                <form className="form" id={"loginform"} method={"post"}  onSubmit={(e)=> {login(e);}}>
                    <div className="form-content-wrapper">
                        <div className="title-container"><h1 className="title">{_t(ETranslation.login)}</h1></div>
                        <div className="email-container">
                            <label className="email-label" htmlFor="username" >E-Mail</label>
                            <div className="email-input-wrapper">
                                <input
                                    className="email-input"
                                    autoComplete="username"
                                    id="username"
                                    name={"username"}
                                    value={uuid}
                                    onChange={(e)=> setUuid(e.target.value)}
                                    required={true}
                                />
                                <div className="email-icon">
                                    <Icon  icon="user" size={20}  />
                                </div>
                            </div>
                        </div>
                        <div className="password-container">
                            <label className="password-label" htmlFor="current-password">{_t(ETranslation.password)}</label>
                            <div className="password-input-wrapper">
                                <input
                                    className="password-input"
                                    autoComplete="current-password"
                                    id="current-password"
                                    name="current-password"
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e)=> setPassword(e.target.value)}
                                    required={true}
                                />
                                <div className="key-icon">
                                    <Icon  icon="key" size={20}  />
                                </div>
                                <div className="lock-icon">
                                    {pwdState()}
                                </div>
                                <div className="forget-password-container">
                                    <Link className="link" to="/password">{_t(ETranslation.pass_forget)}</Link>
                                </div>
                            </div>
                        </div>
                        <div className="login-btn-container">
                            <Button intent={"primary"} className={"login-btn"} type={"submit"}  loading={isLoading}>{_t(ETranslation.login)}</Button>
                        </div>
                        <div className="divider"></div>
                        <div className="register-link-container-container">
                            <p>{_t(ETranslation.no_accaount)} <a href={"mailto:service@smart-zebra.de?subject=Login"} target={"_blank"}>{_t(ETranslation.account_request)}</a></p>
                        </div>
                    </div>
                </form>
        </div>
    );
}
