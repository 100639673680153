import {
    Alert,
    Alignment,
    Button,
    Callout,
    Classes,
    Colors,
    ControlGroup,
    Dialog,
    HTMLTable,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
    Popover,
    TextArea,
    Toaster,
} from "@blueprintjs/core";
import {DialogProps} from "@blueprintjs/core/lib/esm/components/dialog/dialog";

import {IconName, IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {ChangeEvent, CSSProperties} from "react";
import {sum} from "simple-statistics";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {getResult} from "../../helpers/Helpers";
import {EProjectType, EProjectUserRole, IProject, ProjectTypeToModule, ProjectUserRoleMap} from "../../models/IProject";
import {IProjectGroupMember} from "../../models/IProjectGroupMember";
import {ServiceCalls} from "../../services/ServiceCalls";
import {ProjectInfo} from "./panel/ProjectInfo";
import {SzSvgLoader} from "./SzSvgLoader";
import {EModuleNames} from "../../models/EModuleNames";
import {Popover2, Tooltip2} from "@blueprintjs/popover2";
import {min} from "../../helpers/Statistics";
import {DateTime} from "luxon";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

const BASE_DIR = Globals.BASE_DIR;
enum ESortDir {
    asc = 1,
    desc= -1,
}

enum ESortField {
    name= "name",
    created = "created",
    modified = "modified",
}
const SORT_LABEL = {
    name: "Name",
    created: "Erstellt",
    modified: "Geändert",
};

const ICON_TITLE = {
    owner: "Projekt Administrator",
    editor: "Darf im Projekt\nÄnderungen vornehmen (Schreibrecht)",
    reader: "Kann auf dieses Projekt\n nur lesend zugreifen (Leserecht)",
};
interface IInvitation {
    email?: string;
    user_id?: string;
    role: EProjectUserRole;
}
interface IPageProjectsState {
    selectedValue: string;
    isLoading: boolean;
    showPrjDialog: boolean;
    showPrjMemberDialog: boolean;
    sortDesc?: boolean;
    prjTypeFilterOpen: boolean;
    prjTypeFilter: {
        editor: boolean;
        owner: boolean;
        reader: boolean;
    };
    prjSortField: any;
    is_pinned?: boolean;
    projectName?: string;
    chkDeleteProject: boolean;
    project_description?: string;
    project_ident?: string;
    inv_zero: IInvitation;
    inv_text: string;
    nav_page?: EModuleNames;
}

export class PageProjects  extends React.Component<any, IPageProjectsState> {
    private readonly popover: React.RefObject<Popover>;
    private readonly msgToaster: React.RefObject<Toaster>;

    private allProjects: IProject[] = [];
    private _currentProject: IProject = null;

    private project_members: IProjectGroupMember[];
    private open_invitations: any;

    get currentProject(): IProject {
        if (!this._currentProject) {
            return {};
        }
        return this._currentProject;
    }

    set currentProject(value: IProject) {
        this._currentProject = value;
    }

    private prjTypeFilter = {
        editor: true,
        owner: true,
        reader: true,
    };
    private prjSortField = ESortField.name;

    constructor(props: any, context: IPageProjectsState) {
        super(props, context);

        this.popover = React.createRef();
        this.msgToaster = React.createRef();

        const defPrjId = SessionStore.getItem("defaultProject", "0:0:0:0");
        const selection = this.allProjects.find( (o) => o._id === defPrjId );

        const prjSortField = {};
        prjSortField[ESortField.name] = ESortDir.asc;
        this.state = {
            isLoading: true,
            showPrjDialog: false,
            showPrjMemberDialog: false,
            prjTypeFilterOpen: false,
            prjTypeFilter: {
                editor: true,
                owner: true,
                reader: true,
            },
            prjSortField,
            is_pinned: true,
            selectedValue: defPrjId,
            chkDeleteProject: false,
            projectName: selection ? selection.name : "",
            inv_zero: {
                email: undefined,
                role: EProjectUserRole.reader,
            },
            inv_text: undefined,
        };
    }
    public componentDidMount(): void {
        (async () => {
            const cb = new ServiceCalls();
            try {
                const result = await cb.getProjects();
                if (result && result.response && Array.isArray(result.response) && result.response.length) {
                    this.allProjects = result.response;
                    let selection = this.allProjects.find( (o) => o._id === this.state.selectedValue );

                    if (!selection) {
                        selection = this.allProjects[0];
                    }

                    this._currentProject = selection;
                    Globals.changed_project = this._currentProject;
                    await this.loadPrjMembers(this._currentProject);
                    let nav_page: EModuleNames = EModuleNames.BetaFactor;
                    if(this._currentProject){
                        nav_page = ProjectTypeToModule(this._currentProject.project_type);
                    }
                    this.setState({
                        isLoading: false,
                        selectedValue: selection._id,
                        nav_page,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        nav_page: undefined,
                    });
                }
            } catch (exi) {
                console.error(exi);
            }
        })();
    }
    public render() {
        if (this.state.isLoading) {
            return this.renderLoading();
        }
        const defPrjId = SessionStore.getItem("defaultProject", "0:0:0:0");
        const getLock = (project: IProject) => {
            if(project && project.values){
                if(project.values.TpPermaFreezeLongList){
                    return (
                        <Tooltip2 content={"Projekt eingefroren"} usePortal={true} openOnTargetFocus={false} className={"sz-td-tooltip"}>
                            <Icon icon={IconNames.SNOWFLAKE} size={12} />
                        </Tooltip2>
                    );
                }
                if(project.values.TpFreezeLongList){
                    return (
                        <Tooltip2 content={"Long List gesperrt"} usePortal={true} openOnTargetFocus={false} className={"sz-td-tooltip"}>
                            <Icon icon={IconNames.LOCK} size={12} />
                        </Tooltip2>
                    );
                }
            }

            if(project && Array.isArray(project.project_users)){
                let r: EProjectUserRole = EProjectUserRole.unset;
                project.project_users.forEach( (i) => {
                    if(i.user._id === Globals.user_id && i.role>r){
                        r = i.role;
                    }
                });
                if(r >= EProjectUserRole.reader){
                    return (
                        <Tooltip2 content={"Nur Leserecht"} usePortal={true} openOnTargetFocus={false} className={"sz-td-tooltip"}>
                            <Icon icon={IconNames.BLOCKED_PERSON} size={12} />
                        </Tooltip2>
                    );
                }
            }

            return null;
        };
        const getDot = (project: IProject) => {
            const txt = project.project_type === EProjectType.transfer_pricing ? "TP" : project.project_type === EProjectType.credit_spread ? "CP" : "Val";
            const htxt = project.project_type === EProjectType.transfer_pricing ? "Transfer Pricing" : project.project_type === EProjectType.credit_spread ? "Kreditaufschlag" : "Valuation";
            return (
                <Tooltip2 content={htxt} usePortal={true} openOnTargetFocus={false}>
                    {txt}
                </Tooltip2>
            );
        };
        const header = [
            "",
            "Projektname",
            "Zuletzt geändert",
            "Typ",
            "Status",
        ];
        const widths = [32, 210, 155, 40, 60];
        const sum_widths = sum(widths);
        const getHeaderStyle = (column, row_num= undefined, p?: IProject): CSSProperties => {
            // borderBottom: "1px rgba(16, 22, 26, 0.1) solid",
            return {
                borderBottom: "1px solid rgba(16, 22, 26, 0.15)",
                backgroundColor: "rgba(16, 22, 26, 0.07)",
                width: widths[column],
                textAlign: column > 2 ? "center" : undefined,
                verticalAlign: "middle",
            };
        };

        const getStyle = (column, row_num= undefined, p?: IProject): CSSProperties => {
            const textAlign = column === 0 || column === 3 || column === 4 ? "center" : undefined;
            const color = column > 1 ? "#5c7080" : undefined;
            const fontSize = column > 1 ? "80%" : undefined;
            return {
                width: widths[column],
                textAlign,
                color,
                fontSize,
            };
        };
        let allProjects = this.allProjects.slice();
        allProjects.sort((pa, pb) => {
            let sort_by_name = 0;
            let sort_by_date = 0;
            if (this.state.prjSortField[ESortField.name]) {
                sort_by_name = this.state.prjSortField[ESortField.name] * pa.name.localeCompare(pb.name);
            }
            if (this.state.prjSortField[ESortField.created]) {
                sort_by_date = this.state.prjSortField[ESortField.created] * DateTime.fromISO(pa.created).diff(DateTime.fromISO(pb.created)).days;
            }
            return sort_by_name || sort_by_date;
        });
        allProjects = allProjects.filter( (p) => {
            let okay = false;
            if (!p.project_users) {
                return false;
            }
            p.project_users.forEach((pu) => {
                okay = okay || (
                    (this.state.prjTypeFilter.owner && pu.role === EProjectUserRole.owner) ||
                    (this.state.prjTypeFilter.editor && pu.role === EProjectUserRole.editor) ||
                    (this.state.prjTypeFilter.reader && pu.role === EProjectUserRole.reader)
                );
            });
            return okay;
        });
        const is_pinned = this.state.is_pinned;
        const pin_bar = 26;
        const pin_icon = is_pinned ? IconNames.EYE_ON : IconNames.EYE_OFF;
        const cssPinBar: CSSProperties = {
            width: pin_bar,
            padding: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: Colors.WHITE,
            position: "absolute",
            transform: "translate(-33px, 90px)",
        };
        const cssPinButton: CSSProperties = {
            transform: "rotate(-90deg)",
            height: pin_bar,
            width: 140,
            color: "#182026",
            backgroundColor: Colors.GRAY5,
            padding: 5,
            paddingLeft: 20,
            paddingRight: 20,
            cursor: "default",
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        };
        return (
            <>
                <div style={cssPinBar}><div style={cssPinButton} onClick={() => this.setState({is_pinned: !is_pinned})}><span>Projektliste</span><Icon icon={pin_icon}/></div></div>
                <div className={`sz-prj-body`}>
                    <div className={`sz-prj-list-page`} style={{width: sum_widths + pin_bar, display: is_pinned ? "block" : "none"}}>
                        <table className="sz-table bp3-html-table bp3-html-table-condensed sz-small-padding" style={{width: sum_widths, emptyCells: "show", borderCollapse: "separate"}}>
                            <thead style={{position: "fixed"}}>
                            <tr>
                                {header.map( (h, i) => (<th style={getHeaderStyle(i)} >{this.renderHeader(h, i)}</th>) )}
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                {header.map( (h, i) => (<td style={getStyle(i, -1)}>&nbsp;</td>) )}
                            </tr>
                            {allProjects.map( (p, row_num) => {
                                return (
                                    <tr className={`${this._currentProject._id === p._id ? "sz-row-orange" : ""} sz-row-hover`} onClick={() => this.selectProject(p._id)}>
                                        <td style={getStyle(0, row_num, p)}>
                                            {this.getIcon(p._id === defPrjId)}
                                        </td>
                                        <td style={getStyle(1, row_num, p)}>
                                            <div style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: widths[1] - 12}}>
                                                {p.name}
                                            </div>
                                        </td>
                                        <td style={getStyle(2, row_num, p)}>
                                            {DateTime.fromISO(p.created).toFormat("hh:mm dd.LL.yyyy")}
                                        </td>
                                        <td style={getStyle(3, row_num, p)}>
                                            {getDot(p)}
                                        </td>
                                        <td style={getStyle(4, row_num, p)}>
                                            {getLock(p)}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className={`sz-prj-info-page`} style={{height: "100%" ,width: `calc( 100% - ${is_pinned ? sum_widths : 0}px )`, paddingLeft: 1}}>
                        {this.renderSelectedPrjNav()}
                        <ProjectInfo
                            project={this._currentProject}
                            page={this.state.nav_page}
                            onChangeProject={(p) => this.changeProject(p)}
                            onUpdateProject={(p) => this.updateProject(p)}
                            onDeleteProject={(p) => this.deleteProject(p)}
                        />
                    </div>
                </div>
                <Toaster position={"top"} autoFocus={false} usePortal={true} canEscapeKeyClear={true} ref={this.msgToaster} className={"in-front"} />
                <Alert
                    cancelButtonText={_t(ETranslation.cancel)}
                    confirmButtonText={_t(ETranslation.delete)}
                    icon={IconNames.HIGH_PRIORITY}
                    intent={Intent.DANGER}
                    isOpen={this.state.chkDeleteProject}
                    onCancel={()=> this.setState({chkDeleteProject: false})}
                    onConfirm={()=> this.deleteProject(this._currentProject)}
                >
                    <>
                        <p>
                            Projekt löschen?
                        </p>
                        <p>
                            Diese Aktion kann nicht rückgängig gemacht werden.
                        </p>
                    </>
                </Alert>
                {this.renderPrjConfigDlg()}
                {this.renderPrjMemberDlg()}
            </>
        );
    }
    private renderHeader(content, idx: number) {
        const chPrjFilter = (fieldName) => {
            this.prjTypeFilter[fieldName] = !this.prjTypeFilter[fieldName];
            if (!this.prjTypeFilter.owner && !this.prjTypeFilter.reader && !this.prjTypeFilter.editor) {
                this.prjTypeFilter[fieldName] = true;
            }
            this.setState({
                prjTypeFilter: this.prjTypeFilter,
            });
        };
        const onClickSort = (sortField: ESortField) => {
            const state = this.state.prjSortField;
            const direction = state[sortField];
            if (direction === ESortDir.asc) {
                state[sortField] = ESortDir.desc;
            }
            if (!direction) {
                state[sortField] = ESortDir.asc;
            }
            if (direction === ESortDir.desc) {
                state[sortField] = undefined;
            }
            this.setState({
                prjSortField: state,
            });
        };
        const sortHeader = (field: ESortField) => {
            let icon_name: IconName = IconNames.DOUBLE_CARET_VERTICAL;
            let icon_assign = "middle";
            let icon_color = Colors.GRAY5;
            const direction = this.state.prjSortField[field];

            if (direction === ESortDir.asc) {
                icon_name = IconNames.CARET_UP;
                icon_assign = "top";
                icon_color = Colors.BLUE2;
            }
            if (direction === ESortDir.desc) {
                icon_name = IconNames.CARET_DOWN;
                icon_assign = "bottom";
                icon_color = Colors.BLUE2;
            }
            return (
                <div style={{display: "flex", width: "100%", cursor: "default"}} onClick={() => onClickSort(field)}>
                    <span>{content}</span>
                    <Icon icon={icon_name} color={icon_color}/>
                </div>
            );
        };
        const displayHeader = (fieldName) => {
            const title = ICON_TITLE[fieldName];
            const is_active = this.prjTypeFilter[fieldName];
            return <Icon onClick={() => chPrjFilter(fieldName)} htmlTitle={title} color={is_active ? Colors.BLUE2 : Colors.GRAY5} icon={content} iconSize={12} style={{display: "inline", cursor: "default"}} />;
        };
        if (idx === 0) {
            return "";
        }
        if (idx === 1) {
            return sortHeader(ESortField.name);
        }
        if (idx === 2) {
            return sortHeader(ESortField.created);
        }
        return (content);
    }
    private getIcon(f: boolean) {
        if (f) {
            return (
                <Tooltip2 content={"Gegenwärtig verwendetes Projekt"} usePortal={true} openOnTargetFocus={false} className={"sz-td-tooltip"}>
                    <Icon icon={IconNames.TICK_CIRCLE} size={12} className={"sz-selected-blue"}/>
                </Tooltip2>
            );
        } else {
            return "";
        }
    }
    private renderLoading() {
        return (
            <div style={{margin: 0}}>
                <Navbar>
                    <Navbar.Group align={Alignment.LEFT}>
                        <Button className={Classes.SKELETON} minimal={true} icon={IconNames.NEW_OBJECT} text="Läd... ... ... ..." />
                    </Navbar.Group>
                </Navbar>
                <div style={{paddingTop: 250}}>
                    <SzSvgLoader duration={2} />
                </div>
            </div>
        );
    }
    private updateProject(p: IProject) {
        const upd = this.allProjects.find( (o) => o._id === p._id );
        if (upd) {
            upd.name = p.name;
            upd.description = p.description;
            upd.ident = p.ident;
            upd.created = p.created;
            upd.project_users = p.project_users;
            this.selectProject(p._id);
        }
    }
    private deleteProject(p: IProject) {
        if(p){
            this.executeDeletePrj(p._id);
        }
    }
    private changeProject(p: IProject) {
        const renderButtons = () => {
            return (
                <div>
                    <Button minimal={true} onClick={ () => this.msgToaster.current.clear() }>{_t(ETranslation.cancel)}</Button>
                    &nbsp;&nbsp;
                    <Button intent={"primary"} onClick={ () => this.executeChangePrj(p._id) }>Okay</Button>
                </div>
            );
        };

        this.msgToaster.current.show({
            timeout: 0,
            intent: Intent.NONE,
            message:
                <>
                    <div style={{margin: 0, marginBottom: 10}}>Zum Projekt <strong>{p.name}</strong> wechseln?</div>
                    <ul>
                        <li>Alle Parametereinstellung werden aus dem Projekt übernommen.</li>
                        <li>Die Long List wird aus dem Projekt übernommen.</li>
                        <li>Die Seite wird neu geladen.</li>
                    </ul>
                    <div style={{display: "flex", float: "right"}}>
                        {renderButtons()}
                    </div>
                </>,
        });
    }
    private executeDeletePrj(_id: string) {
        ( async () => {
            const cb = new ServiceCalls();
            const response = await cb.deleteProject(_id);
            if (response?.response?.deletedCount) {
                const idx = this.allProjects.findIndex( (i) => i._id === _id );
                if ( idx >= 0) {
                    this.allProjects.splice(idx, 1);
                    const new_prjId = this.allProjects.length > 0 ? this.allProjects[0]._id : "0:0:0:0";
                    SessionStore.setItem("defaultProject", new_prjId);
                    const selection = this.allProjects.find( (o) => o._id === new_prjId );
                    this._currentProject = selection;
                    this.setState({
                        selectedValue: new_prjId,
                        projectName: selection ? selection.name : "",
                        chkDeleteProject: false,
                    });
                }
            }
            // this.msgToaster.current.clear();
        } )();
    }
    private executeChangePrj(_id: string) {
        const selectedProject = this.allProjects.find( (o) => o._id === _id );
        if(!selectedProject){
            return;
        }
        ( async () => {
            const cb = new ServiceCalls();
            await cb.loadProject(_id);
            const isTp: boolean = Globals.currentModule === EModuleNames.TransferPricing;

            if(selectedProject.project_type === EProjectType.transfer_pricing && Globals.currentModule !== EModuleNames.TransferPricing){
                window.open("/desk-transfer-pricing","_self")
                return;
            }
            if(selectedProject.project_type === EProjectType.credit_spread && Globals.currentModule !== EModuleNames.CreditSpreads){
                window.open("/desk-kreditaufschlaege","_self")
                return;
            }
            if(Globals.currentModule === EModuleNames.TransferPricing || Globals.currentModule === EModuleNames.CreditSpreads){
                window.open("/desk-beta-faktor","_self")
                return;
            }
            window.location.reload();
        } )();
    }

    private selectProject(_id: string) {
        (async ()=>{
            const selectedProject = this.allProjects.find( (o) => o._id === _id );
            this._currentProject = selectedProject;
            Globals.changed_project = selectedProject;
            await this.loadPrjMembers(this._currentProject);
            this.setState({
                selectedValue: _id,
                projectName: selectedProject ? selectedProject.name : "",
                nav_page: ProjectTypeToModule(this._currentProject.project_type),
            });
        })();
    }

    private renderSelectedPrjNav() {
        const defPrjId = SessionStore.getItem("defaultProject", "0:0:0:0");
        const selection = this.allProjects.find( (o) => o._id === this.state.selectedValue );
        if (!selection) {
            return (
                <div style={{height: 50, width: "100%", display: "flex", justifyContent: "space-between", backgroundColor: "rgba(16, 22, 26, 0.07)", borderBottom: "1px solid rgba(16, 22, 26, 0.15)", paddingLeft: 6}}>
                    <span style={{fontWeight: "bold", fontSize: "1.5em", alignSelf: "center"}}>Kein Projekt gewählt</span>
                </div>
            );
        }
        const p = this._currentProject;
        if (!p) {
            return null;
        }
        const is_owner = p.project_users.find( (pu) => Globals.user_id === pu.user._id && pu.role === EProjectUserRole.owner );
        const is_editor = p.project_users.find( (pu) => Globals.user_id === pu.user._id && pu.role < EProjectUserRole.reader );
        const nav_button = (title, page: EModuleNames)=>{
            if(!this.state.nav_page){
                return null;
            }
            const borderBottom = this.state.nav_page === page ? "3px solid #106ba3" : "3px solid transparent";
            const color = this.state.nav_page === page ? "#106ba3" : undefined;
            return (
                <div className={"sz-prj-nav-button"} onClick={()=> this.setState({nav_page: page})}>
                    <div style={{borderBottom, color, display: "flex", alignItems: "center"}}><span style={{paddingTop: 3}}>{title}</span></div>
                </div>
            );
        };
        const navButtons = ()=> {
            let items = [];
            const showTp: boolean = p.project_type === EProjectType.transfer_pricing;
            if(p.project_type === EProjectType.transfer_pricing){
                items = [
                    {title: "Transfer Pricing", page: EModuleNames.TransferPricing},
                ];
            }
            if(p.project_type === EProjectType.credit_spread){
                items = [
                    {title: _t(ETranslation.credit_spread), page: EModuleNames.CreditSpreads},
                ];
            }
            if(p.project_type === EProjectType.valuation){
                items = [
                    {title: "Beta-Faktoren", page: EModuleNames.BetaFactor},
                    {title: _t(ETranslation.cost_of_capital), page: EModuleNames.CostOfCapital},
                    {title: "Multiplikatoren", page: EModuleNames.Multiples},
                    {title: "Benchmarking", page: EModuleNames.Benchmarking},
                ];
            }
            return items.map((i)=> nav_button(i.title, i.page));
        };
        return (
            <Navbar style={{boxShadow: "none", borderBottom: "1px solid rgba(16, 22, 26, 0.15)", backgroundColor: "rgba(16, 22, 26, 0.07)"}}>
                <NavbarGroup>
                    <NavbarHeading style={{paddingLeft: 10}}>{p.name}</NavbarHeading>
                    <NavbarDivider />
                    {navButtons()}
                </NavbarGroup>
                <NavbarGroup align={"right"} style={{paddingRight: 10}}>
                    <Tooltip2 content={"Projekt bearbeiten"} position={"bottom"} disabled={!is_editor} usePortal={true} openOnTargetFocus={false} className={"sz-td-tooltip"}>
                        <Button intent={Intent.NONE} minimal={true} disabled={!is_editor} icon={IconNames.COG}  style={{marginLeft: 11}} onClick={()=> this.openPrjDlg()}/>
                    </Tooltip2>

                    <Tooltip2 content={"Person hinzufügen"} position={"bottom"} disabled={!is_editor} usePortal={true} openOnTargetFocus={false} className={"sz-td-tooltip"}>
                        <Button intent={Intent.NONE} minimal={true} disabled={!is_editor} icon={IconNames.NEW_PERSON}  onClick={()=> this.openPrjMemberDlg()}/>
                    </Tooltip2>

                    <Tooltip2 content={"Projekt löschen"} position={"bottom"} disabled={!is_owner} usePortal={true} openOnTargetFocus={false} className={"sz-td-tooltip"}>
                        <Button minimal={true} disabled={!is_owner} intent={Intent.DANGER} icon={IconNames.TRASH} onClick={() => this.setState({chkDeleteProject: true})} style={{marginLeft: 11}} />
                    </Tooltip2>

                </NavbarGroup>
            </Navbar>
        );
    }
    private openPrjDlg(){
        this.setState({
            project_ident: this._currentProject.ident,
            project_description: this._currentProject.description,
            showPrjDialog: true,
        });
    }
    private openPrjMemberDlg(){
        // console.error(this._currentProject);
        this.setState({
            showPrjMemberDialog: true,
        });
    }
    private renderPrjConfigDlg(){
        if(!this._currentProject){
            return null;
        }
        const saveEdit = ()=>{
            const p = {
                _id: this._currentProject._id,
                ident: this.state.project_ident,
                name: this._currentProject.name,
                description: this.state.project_description,
            };
            ( async () => {
                const cb = new ServiceCalls();
                const response = await cb.updateProject(p);
                if (response?.error) {
                    this.msgToaster.current.show({
                        timeout: 0,
                        intent: Intent.WARNING,
                        message:
                            <>
                                <div style={{margin: 0, marginBottom: 10}}>Das Projekt <strong>{p.name}</strong> wurde nicht aktualisiert.</div>
                                <p>{response?.error}</p>
                                <div style={{display: "flex", float: "right"}}>
                                    <div>
                                        <Button minimal={true} onClick={ () => this.msgToaster.current.clear() }>Okay</Button>
                                    </div>
                                </div>
                            </>,
                    });
                }else{
                    this._currentProject.description = this.state.project_description;
                    this._currentProject.ident = this.state.project_ident;
                }
                this.setState({showPrjDialog: false});
            } )();
        };
        const props: DialogProps = {
            isOpen: this.state.showPrjDialog,
            title: `Projekt Eigenschaften`,
            icon: IconNames.COG,
            usePortal: true,
            onClose: ()=> this.setState({showPrjDialog: false}),
        };
        return (
            <Dialog {...props}>
                <div className={Classes.DIALOG_BODY}>
                    <HTMLTable className={`sz-table sz-prj-info-table`} condensed={true} style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <td style={{boxShadow: "none", width: 160, verticalAlign: "middle"}}><strong>Projekt-Name</strong></td>
                            <td style={{boxShadow: "none", paddingLeft: 5}} colSpan={2}>
                                {this._currentProject.name}
                            </td>
                        </tr>
                        <tr>
                            <td style={{boxShadow: "none", paddingBottom: 16, width: 160, verticalAlign: "middle"}}><strong>Projekt-Identifikation</strong></td>
                            <td style={{boxShadow: "none", paddingBottom: 16, paddingLeft: 5}} colSpan={2}>
                                <InputGroup placeholder="Projekt-Identifikation" value={this.state.project_ident} onChange={ (e) => this.setState({project_ident: (e.target as HTMLInputElement).value}) } fill={true} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{boxShadow: "none", paddingBottom: 16, width: 160, lineHeight: "30px"}}><strong>Projekt-Beschreibung</strong></td>
                            <td style={{boxShadow: "none", paddingBottom: 16, paddingLeft: 5, height: 150}} colSpan={3}>
                                <TextArea value={this.state.project_description} fill={true} rows={5} placeholder={"Projekt-Beschreibung"} style={{height: 225}} onChange={ (e) => this.setState({project_description: (e.target as HTMLTextAreaElement).value}) } />
                            </td>
                        </tr>
                        </tbody>
                    </HTMLTable>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button intent={Intent.NONE} minimal={true} text={_t(ETranslation.cancel)} onClick={() => this.setState({showPrjDialog: false})} />
                        &nbsp;&nbsp;
                        <Button intent={Intent.PRIMARY} text={_t(ETranslation.save)} onClick={() => saveEdit()} />
                    </div>
                </div>
            </Dialog>
        );
    }
    private sendInvite(p: IProject) {
        (async () => {
            const cb = new ServiceCalls();
            try {
                const response = await cb.inviteToProject(p, [this.state.inv_zero], this.state.inv_text);
                console.error(response);
            } catch (exi) {
                console.error(exi);
            }
        })();
    }
    private renderPrjMemberDlg(){
        if(!this._currentProject){
            return null;
        }
        const inv_zero: IInvitation = this.state.inv_zero;
        const props: DialogProps = {
            style: {width: 650},
            isOpen: this.state.showPrjMemberDialog,
            title: `Projekt Mitglieder`,
            icon: IconNames.NEW_PERSON,
            usePortal: true,
            onClose: ()=> this.setState({showPrjMemberDialog: false}),
        };
        const chEmail = (e: ChangeEvent<HTMLInputElement>, inv: IInvitation) => {
            inv.email = e.target.value;
            this.setState({inv_zero});
        };
        const chRole = (role: EProjectUserRole, inv: IInvitation) => {
            (async () => {
                inv.role = role;
                if(inv.user_id){
                    const cb = new ServiceCalls();
                    const response = await cb.updateProjectUser(this._currentProject, inv.user_id, role);
                    if (response?.error) {
                        console.error(response.error);
                    }else{
                        const user = this.project_members.find((i)=> i.user_id === inv.user_id);
                        if(user){
                            user.roles = [role];
                        }
                    }
                }
                this.setState({inv_zero});
            })();
        };
        const removeUser = (user_id)=>{
            (async () => {
                const cb = new ServiceCalls();
                const response = await cb.updateProjectUser(this._currentProject, user_id, EProjectUserRole.deleted, true);
                if (response?.error) {
                    console.error(response.error);
                }else{
                    const idx = this.project_members.findIndex((i)=> i.user_id === user_id);
                    if(idx>=0){
                        this.project_members.splice(idx, 1);
                    }
                    this.setState({inv_zero});
                }
            })();
        };
        const permissionsMenu = (inv: IInvitation) => {
            return (
                <Popover2
                    content={
                        <Menu>
                            <MenuItem text="Administrator" onClick={ () => chRole(EProjectUserRole.owner, inv) }/>
                            <MenuItem text="Leserecht" onClick={ () => chRole(EProjectUserRole.reader, inv) }/>
                        </Menu>
                    }
                    placement="bottom-end"
                    minimal={true}
                >
                    <Button minimal={true} rightIcon="caret-down" text={ProjectUserRoleMap[inv.role]} intent={Intent.PRIMARY} style={{minWidth: 120, justifyContent: "space-between"}}/>
                </Popover2>
            );
        };

        const display_members = this.project_members.filter((i)=> i.user_id !== Globals.user_id);
        const renderMember = (i)=> {
            const inv: IInvitation = {
                role: min(i.roles),
                user_id: i.user_id,
            };
            return (
                <div style={{marginTop: 8}}>
                    <ControlGroup fill={true}>
                        <InputGroup value={[i.display_name, i.company_name].join(" | ")} readOnly={true} leftIcon={IconNames.PEOPLE} rightElement={permissionsMenu(inv)} />
                        <Button title={"Löschen"} icon={IconNames.TRASH} intent={Intent.DANGER} onClick={()=> removeUser(i.user_id)}/>
                    </ControlGroup>
                </div>
            );
        };
        return (
            <Dialog {...props}>
                <div className={Classes.DIALOG_BODY} style={{backgroundColor: "#ffffff"}}>
                    <Callout title={"Mitglied hinzufügen"} style={{backgroundColor: "#ffffff"}}>
                        <p className={"bp3-text-small bp3-text-muted"}>Hier können Sie Benutzer zur Zusammenarbeit dem Projekt hinzufügen.</p>
                        <ControlGroup fill={true}>
                            <InputGroup type={"email"} value={inv_zero.email}  placeholder={"EMail- Adresse"} leftIcon={IconNames.PEOPLE} rightElement={permissionsMenu(inv_zero)} onChange={(e: ChangeEvent<HTMLInputElement>) => chEmail(e, inv_zero)}/>
                        </ControlGroup>
                        <br/>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <Button text={"Hinzufügen"} onClick={() => this.sendInvite(this._currentProject)} disabled={!Globals.validateEmail(inv_zero.email)} intent={Intent.PRIMARY}/>
                        </div>
                    </Callout>
                    <Callout title={"Mitglieder"} style={{backgroundColor: "#ffffff"}}>
                        {display_members.map((i)=> renderMember(i))}
                    </Callout>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button intent={Intent.NONE} minimal={false} text={"Fertig"} onClick={() => this.setState({showPrjMemberDialog: false})} />
                    </div>
                </div>
            </Dialog>
        );
    }

    private async loadPrjMembers(project: IProject) {
        if(!project){
            this.project_members = [];
            this.open_invitations = [];
        }
        const cb = new ServiceCalls();
        const resp = await cb.getProjectUsers(project);
        const oi_resp = await cb.getOpenInvitations(project);
        this.project_members = getResult(resp, []);
        this.open_invitations = getResult(oi_resp, []);
        // console.error(this.project_members);
        // console.error(this.open_invitations);
    }
}
