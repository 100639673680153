export class SvgTextSize {
    public static measureText(txt: string, className?: string): DOMRect {
        try {
            const tokens: string[] = className ? className.split(" ") : [];
            const element = document.getElementById("svgtextsize").firstChild as SVGTextElement;
            try {
                if (className) {
                    element.classList.add(...tokens);
                }
                element.textContent = txt;
                const box = element.getBoundingClientRect();
                return box;
            } finally {
                element.classList.remove(...tokens);
            }
        } catch (e) {
            console.error(e);
        }
        return undefined;
    }
}
const dummy_svg: HTMLElement = document.createElement("svg");
const dummy_svg_text: HTMLElement = document.createElement("text");
dummy_svg.className = "sz-legend-label sz-chart sz-chart-hidden";
dummy_svg.id = "svgtextsize";
dummy_svg.append(dummy_svg_text);
document.body.append(dummy_svg);
