import {PublicDbCalls} from "../services/PublicDbCalls";
import {Globals} from "./Globals";
import * as ReactDetect from "react-device-detect";
import {Sectors} from "./Sectors";
import {setParameterDefaults} from "./ParameterDefaults";
import {ServiceCalls} from "../services/ServiceCalls";
import {SessionStore} from "./SessionStore";
import {getResult} from "../helpers/Helpers";
import {IProjectGroupMember} from "../models/IProjectGroupMember";
import {EProjectUserRole} from "../models/IProject";
import {min} from "../helpers/Statistics";
import {EventBus} from "../services/EventBus";
import {PdfParameter} from "../pdf-tools/PdfParameter";

export const run = async ()=>{
    const db = new PublicDbCalls();
    const key_name = Globals.user_id;
    if(Globals.user_id){
        let first_seen;
        try{
            first_seen = JSON.parse(localStorage.getItem(key_name));
        }catch (e) {
            // console.error(e);
        }
        first_seen = await db.pushUserDetails({
            browser_name: ReactDetect.browserName,
            os_name: ReactDetect.osName,
            os_version: ReactDetect.osVersion,
            user_id: Globals.user_id,
            first_seen: first_seen?.st,
        });
        try{
            localStorage.setItem(key_name, JSON.stringify(first_seen));
        }catch (e) {
            console.error(e);
        }
    }
    Globals.countries = (await db.getAllCountries()).result;
    if(Array.isArray(Globals.countries)){
        Globals.countries.forEach( (c) => Globals.country_map[c.id] = c );
        Globals.countries.forEach( (c) => Globals.country_map_iso_3[c.iso_3] = c );
    }
    Sectors.init();
    Sectors.sectors_map["0"] = Globals.hyphen;


    setParameterDefaults();
    const serviceCalls = new ServiceCalls();
    const p_id = SessionStore.getItem("defaultProject", "0:0:0:0");
    const r_users = await serviceCalls.getProjectUsers({_id: p_id});
    const members = getResult<IProjectGroupMember[]>(r_users, []);
    const mee: IProjectGroupMember = members.find((i) => i.user_id === Globals.user_id);
    if(mee){
        Globals.projectMemberShip = mee;
    }else{
        Globals.projectMemberShip = {} as IProjectGroupMember;
    }
    if(!Array.isArray(Globals.projectMemberShip.roles)){
        Globals.projectMemberShip.roles = [];
    }
    if(p_id === "0:0:0:0"){
        Globals.projectAccess = EProjectUserRole.owner;
    }else{
        const m = min(Globals.projectMemberShip.roles);
        Globals.projectAccess = m;
    }
    EventBus.subscribe("askKay::available", ()=>{
        Globals.askKayBlocked = false;
    });
    PdfParameter.init();
};
