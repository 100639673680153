import {Button, Intent, Popover} from "@blueprintjs/core";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {EDataType} from "../../models/EDataType";
import {IToolBarParameter} from "../../models/IToolBarParameter";
import {ISzSliderProperties, SzSlider} from "../widgets/SzSlider";
import {ToolBarParameter} from "./ToolBarParameter";

export abstract class SliderParameter extends ToolBarParameter<IToolBarParameter> {

    protected dataType: EDataType = EDataType.number;

    protected constructor(props: any, context: any) {
        super(props, context);
    }

    protected abstract getValue();
    protected abstract setValue(currentIndex: number);
    protected abstract getParameters(currentIndex: number): ISzSliderProperties;
    protected abstract resolveValue(currentIndex: number): number;

    protected async initialize() {
        const value = this.getValue();
        if (!Globals.isRegistred) {
            this.setValue(value);
        }
        return {
            value,
            ch: 0,
            oldValue: value,
            isOpen: false,
        };
    }
    protected renderHint() {
        return (<></>);
    }
    protected renderContent() {
        const value =  this.state.value;
        const inputProps: ISzSliderProperties = this.getParameters(value);
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        const openMenu = () => {
            this.setState({
                isOpen: !this.state.isOpen,
                oldValue: this.state.value,
            });
        };
        const testCanClose = (ev) => {
            if (!ev) {
                return;
            }
            if (this.state.isOpen && ev.type === "mousedown") {
                this.setState({isOpen: false});
                this.changeValue(this.state.value);
            }
        };

        const getContent = () => {
            return (
                <>
                    <div style={{padding: 24, minWidth: 450, paddingLeft: 35, paddingRight: 35}}>
                        <SzSlider {...inputProps}/>
                    </div>
                    {this.renderHint()}
                </>
            );
        };
        return (
            <Popover
                minimal={true}
                position={"bottom-left"}
                modifiers={popper}
                popoverClassName={`no-max-width`}
                content={getContent()}
                isOpen={this.state.isOpen}
                canEscapeKeyClose={true}
                captureDismiss={false}
                onInteraction={(a, b) => testCanClose(b)}
            >
                <Button intent={Intent.NONE} minimal={true} className={"sz-toolbar-button"} icon={this.icon}  onClick={() => openMenu()} >
                    <span className={"sz-property-name"}>{this.name}</span>
                    <div className={"sz-property-value"}>{this.getLabel()}</div>
                </Button>
            </Popover>
        );
    }
    protected getLabel(): string {
        const v: number = this.resolveValue(this.state.value);
        if (this.dataType !== EDataType.percent) {
            return Globals.formatter(v);
        } else {
            return Globals.formatter_percent(v);
        }
    }
    protected changeValue(index: number) {
        if (index !== this.state.oldValue) {
            const value = this.resolveValue(index);
            this.setState({value: index});
            this.setValue(index);
            this.fireChanges([{
                component: this.props.primaryComponent,
                value: [value],
            }]);
        }
    }
}
