import {NonIdealState} from "@blueprintjs/core";
import * as React from "react";
import {caption_units, captions} from "../../const/Benchmarking";
import {ICalcCreditSpreadResult, ICalcCreditSpreadResultData} from "../../helpers/CalcCreditSpreadIndication";
import {PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {BaseModule} from "./BaseModule";
import {max, median, min, quantile, sum} from "../../helpers/Statistics";
import {Ratings} from "../../const/Ratings";
import {CreditSpreadOptions} from "../../const/CreditSpreadOptions";
import {
    CreditSpreadCalcTable,
    EShowType,
    ICreditSpreadCalcTableData,
    IFooterInfo
} from "../widgets/CreditSpreadCalcTable";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {Globals} from "../../const/Globals";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {EBorder, PdfTable} from "../../pdf-tools/PdfTable";
import {_fmt} from "../../helpers/Helpers";

export class CreditSpreadIndicationSpreads  extends BaseModule<any> {
    private ratings = 0;
    private ratings_min = 0;
    private ratings_max = 0;
    private ratings_q25 = 0;
    private ratings_q75 = 0;

    private spread = 0;
    private spread_min = 0;
    private spread_max = 0;
    private spread_q25 = 0;
    private spread_q75 = 0;

    private table_data;
    private footer_info;

    constructor(props: any, context: any) {
        super(props, context);
    }

    protected renderContent() {
        if(!this.data){
            return (<NonIdealState
                icon={"database"}
                title="Keine Daten"
            />);
        }
        const fx = (v, weight)=> v * weight;
        const ratings = [];
        const ratings_min = [];
        const ratings_max = [];
        const ratings_q25 = [];
        const ratings_q75 = [];
        const spreads = [];
        const spreads_min = [];
        const spreads_max = [];
        const spreads_q25 = [];
        const spreads_q75 = [];

        CreditSpreadOptions.getMetrics().forEach((metric)=>{
            const indication: ICalcCreditSpreadResult = this.data.results[metric.field];
            if(!indication){
                return;
            }


            if(Array.isArray(indication.boxed_spreads)){
                spreads.push(fx(median(indication.boxed_spreads), metric.weight));
                spreads_min.push(fx(min(indication.boxed_spreads), metric.weight));
                spreads_max.push(fx(max(indication.boxed_spreads), metric.weight));
                spreads_q25.push(fx(quantile(indication.boxed_spreads, .25), metric.weight));
                spreads_q75.push(fx(quantile(indication.boxed_spreads, .75), metric.weight));
            }

            const filterFunction = (i: ICalcCreditSpreadResultData)=>{
                if(i.rating==="0"){
                    return false;
                }
                const r = Ratings.s_and_p_mapped_to_num[i.rating];
                const in_box = CreditSpreadOptions.in_box(i.x, i.y, indication);
                return in_box;
            };
            const boxed_ratings = indication.data
                .filter(filterFunction)
                .map((i)=> CreditSpreadOptions.get_rating_idx(i.rating))
            ;

            ratings.push(fx(median(boxed_ratings), metric.weight));
            ratings_min.push(fx(min(boxed_ratings), metric.weight));
            ratings_max.push(fx(max(boxed_ratings), metric.weight));

            ratings_q25.push(fx(quantile(boxed_ratings, .25), metric.weight));
            ratings_q75.push(fx(quantile(boxed_ratings, .75), metric.weight));
        });

        this.ratings = Math.round(sum(ratings));
        this.ratings_min = Math.round(sum(ratings_min));
        this.ratings_max = Math.round(sum(ratings_max));
        this.ratings_q25 = sum(ratings_q25);
        this.ratings_q75 = sum(ratings_q75);

        this.spread = sum(spreads);
        this.spread_min = sum(spreads_min);
        this.spread_max = sum(spreads_max);
        this.spread_q25 = sum(spreads_q25);
        this.spread_q75 = sum(spreads_q75);
        // <div className={"ribbon top left"}>Beta Version</div>
        return (
            <div style={{overflow: "hidden", position: "relative"}}>
                <div className={"sz-row"}>
                    <div className={"sz-col sz-col-70"} style={{paddingRight: 5}}>
                        {this.renderTable()}
                    </div>
                    <div className={"sz-col sz-col-30"} style={{paddingLeft: 5}}>
                        {this.renderDocu()}
                    </div>
                </div>
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;
        // console.error(this.data);
        this.setState({
            loading: false,
            selected_company_indication: data.selected_company_indication ? data.selected_company_indication : this.state.selected_company_indication,
        });
    }
    private renderDocu(){
        const cc = SessionStore.get(EParameters.CreditSpreadXRangeParameter);
        const pcc = `${parseFloat(SessionStore.get(EParameters.CreditSpreadAnalysisIntervalParameter))*100}%`;
        return (
            <div style={{border: "1px solid rgba(16, 22, 26, 0.15)", padding: 10, backgroundColor: "#F5F8FA"}} className={"bp3-text-small bp3-text-muted"}>
                <h4 style={{fontSize: 12}}>Methodik</h4>
                <ol>
                    <li style={{marginBottom: 10}}>
                        Kreditkennzahlen: Für das zu beurteilende Unternehmen – i.d.R. der Darlehensnehmer – werden auf Basis der eingegebenen Unternehmensdaten die ausgewählten Kreditkennzahlen errechnet
                    </li>
                    <li>
                        Longlist: Für jede Kreditkennzahl werden entsprechend des festgelegten Umfangs der Longlist von {cc} Unternehmen jene Vergleichsunternehmen ausgewählt, die der Kreditkennzahl des zu beurteilenden Unternehmen am nächsten kommen
                        <ol type={"a"} style={{marginBottom: 10}}>
                            <li>Enthält {cc} Unternehmen</li>
                            <li>Die Bandbreite ist nicht symmetrisch, sondern enthält jene {cc} Unternehmen, für die - in absoluten Zahlen gemessen – die kleinste Differenz zum Wert des zu beurteilenden Unternehmens ermittelt wurde</li>
                            <li>{cc} Unternehmen der Kennzahl X müssen nicht identisch mit {cc} Unternehmen der Kennzahl Y sein</li>
                        </ol>
                    </li>
                    <li>
                        Shortlist: Die ausgewählt Longlist kann in zweierlei Hinsicht beschränkt werden
                        <ol type={"a"} style={{marginBottom: 10}}>
                            <li>Breite: Es werden nur {pcc} der insgesamt ausgewählten {cc} berücksichtigt. In der neutralen Grundeinstellung der qualitativen Kriterien handelt es sich um die mittleren 20% der Verteilungsfunktion (Quantil 40% bis Quantil 60%)</li>
                            <li>Qualität: Durch eine qualitative Beurteilung der Markt- und Wettbewerbssituation, der Unternehmensstrategie und der Finanzierungspolitik kann die Position der Shortlist innerhalb der Verteilung  der Longlist geändert werden. Ein positiver Beurteilungsschritt verschiebt des Analyseintervall um 2,5% nach unten und senkt damit das Spektrum untersuchter Kreditaufschläge. Ein negativer Beurteilungsschritt verschiebt des Analyseintervall um 2,5% nach oben und erhöht damit das Spektrum untersuchter Kreditaufschläge</li>
                        </ol>
                    </li>
                    <li style={{marginBottom: 10}}>
                        Kreditaufschlag: Für jede Kreditkennzahl werden der kleinste und der größte Kreditaufschlag der Shortlist ermittelt. Außerdem werden der Mittelpunkt und das obere und untere Quartil bestimmt. Für alle 5 Werte (kleinster Wert, unteres Quartil, Median, oberes Quartil, größter Wert) wird über alle Kreditkennzahlen der gewichtete Kreditaufschläge ermittelt und unter der Tabelle ausgewiesen
                    </li>
                    <li>
                        Rating: Für jede Kreditkennzahl werden des schlechteste und das beste Rating der Shortlist ermittelt. Außerdem werden der Mittelpunkt und das obere und untere Quartil bestimmt. Für alle 5 Werte (kleinster Wert, unteres Quartil, Median, oberes Quartil, größter Wert) wird über alle Kreditkennzahlen das gewichtete Rating ermittelt und unter der Tabelle ausgewiesen
                    </li>
                </ol>
            </div>
        );
    }
    private renderTable() {
        if(!Array.isArray(CreditSpreadOptions.getMetrics())){
            return;
        }
        const table_data: ICreditSpreadCalcTableData[] = [];
        const pcc = parseFloat(SessionStore.get(EParameters.CreditSpreadAnalysisIntervalParameter));

        const p_map = {3: 6, 4: 5, 5: 4, 6: 3, 7: 2, 8: 1, 9: 0, 10: -1, 11: -2, 12: -3, 13: -4, 14: -5, 15: -6};
        const sum_q =
            parseInt(SessionStore.get(EParameters.CreditSpreadDecisionParameterA), 10)
            + parseInt(SessionStore.get(EParameters.CreditSpreadDecisionParameterB), 10)
            + parseInt(SessionStore.get(EParameters.CreditSpreadDecisionParameterC), 10);
        const y_quality = p_map[sum_q] * CreditSpreadOptions.credit_spread_decisions_step;

        CreditSpreadOptions.getMetrics().forEach((metric)=>{
            const indication: ICalcCreditSpreadResult = this.data.results[metric.field];
            if(!indication){
                return;
            }
            const x_range_in_percent = isNaN(indication.key_value) || indication.key_value===null ? NaN : indication.x_range.till / indication.key_value - 1;
            const data_in_range = indication.data.filter((i)=> CreditSpreadOptions.in_range(i.x, indication) && i.name !== i.rating);
            // console.error(data_in_range);
            if(metric.weight > 0 && indication.y_range && !isNaN(indication.y_range.from) && !isNaN(indication.y_range.till)){
                const data_in_box = indication.data.filter((i)=> CreditSpreadOptions.in_box(i.x, i.y, indication) && i.name !== i.rating);
                const spread = median(data_in_box.map((i)=> i.y));
                const rating_value = median(data_in_box.map((i)=> Ratings.s_and_p_mapped_to_num[i.rating]));
                table_data.push({
                    caption: metric.caption,
                    key: captions[metric.field],
                    key_caption: metric.field,
                    key_value: indication.key_value,
                    x_from: indication.x_range.from,
                    x_till: indication.x_range.till,
                    boxed_points: data_in_box.length ? data_in_box.length : NaN,
                    ranged_points: data_in_range.length ? data_in_range.length : NaN,
                    y_from: indication.y_range.from,
                    y_till: indication.y_range.till,
                    rating_from: indication.rating_range.from,
                    rating_till: indication.rating_range.till,
                    q_y_from: indication.q_y_range.from,
                    q_y_till: indication.q_y_range.till,
                    q_y: y_quality,
                    longlist_range: pcc,
                    spread,
                    value: spread,
                    rating: rating_value,
                    weight: metric.weight,
                    x_range_in_percent,
                    units: caption_units[metric.field],
                });
            }else{
                table_data.push( {
                    caption: metric.caption,
                    key: captions[metric.field],
                    key_caption: metric.field,
                    key_value: NaN,
                    x_from: NaN,
                    x_till: NaN,
                    boxed_points: NaN,
                    ranged_points: NaN,
                    y_from: NaN,
                    y_till: NaN,
                    rating_from: NaN,
                    rating_till: NaN,
                    q_y_from: NaN,
                    q_y_till: NaN,
                    longlist_range: NaN,
                    spread: NaN,
                    value: undefined,
                    rating: undefined,
                    weight: metric.weight,
                    x_range_in_percent,
                    units: caption_units[metric.field],
                } as ICreditSpreadCalcTableData);
            }
        });

        const company_indication = this.state.selected_company_indication;
        const selected_value = company_indication ? company_indication.average_spreads : undefined;

        const rating = this.ratings;
        const min_rating = this.ratings_min;
        const max_rating = this.ratings_max;
        const q25_rating = this.ratings_q25;
        const q75_rating = this.ratings_q75;

        const selected_rating = company_indication ? company_indication.rating : undefined;
// q25 // q75 fehlt!!
        const footer_info: IFooterInfo<number> = {
            selected_value,
            selected_rating,
            rating,
            min_rating,
            max_rating,
            q25_rating,
            q75_rating,
            value: this.spread,
            min_value: this.spread_min,
            max_value: this.spread_max,
            q25_value: this.spread_q25,
            q75_value: this.spread_q75,
        };
        // console.error(footer_info);
        this.table_data = table_data;
        this.footer_info = footer_info;

        return (
            <CreditSpreadCalcTable show_type={EShowType.spread} data={table_data} footer_info={footer_info} />
        );
    }
    private getFooterInfo(footer_info): IFooterInfo<string> {
        const f: IFooterInfo<number> = footer_info;
        const r: IFooterInfo<string> = {} as IFooterInfo<string>;
        r.selected_value= Globals.formatter(f.selected_value, 2, 2, true);
        r.value= Globals.formatter(f.value, 2, 2, true);
        r.min_value= Globals.formatter(f.min_value, 2, 2, true);
        r.max_value= Globals.formatter(f.max_value, 2, 2, true);
        r.q25_value= Globals.formatter(f.q25_value, 2, 2, true);
        r.q75_value= Globals.formatter(f.q75_value, 2, 2, true);

        r.selected_rating= f.selected_rating === undefined ? Globals.hyphen : Ratings.s_and_p[Math.round(f.selected_rating)];
        r.rating= Ratings.s_and_p[Math.round(f.rating)];
        r.min_rating= Ratings.s_and_p[Math.round(f.min_rating)];
        r.max_rating= Ratings.s_and_p[Math.round(f.max_rating)];
        r.q25_rating= Ratings.s_and_p[Math.round(f.q25_rating)];
        r.q75_rating= Ratings.s_and_p[Math.round(f.q75_rating)];
        return r;
    }
    public getPdfPage(): any[] {
        const footer_info: IFooterInfo<number> = this.footer_info;
        const f = this.getFooterInfo(footer_info);
        const table_data: ICreditSpreadCalcTableData[] = this.table_data;
        const content = [];
        PdfInsertElement.page_header(content, "Methodik");
        const widths= (new Array(19)).fill("auto").map((a,i)=>i === 1 ? "*" : "auto");
        const table = new PdfTable(content, widths);
        table.addRow(
            [
                table.header("Kreditkennzahl", false, 5, "left", true),
                table.header("", false),table.header("", false),table.header("", false),table.header("", false),
                table.header("Longlist", false, 3, "center", true),
                table.header("", false, undefined, "center"),table.header("", false, undefined, "center"),
                table.header("Shortlist", false, 5, "center", true),
                table.header("", false),table.header("", false),table.header("", false),table.header("", false),
                table.header(_t(ETranslation.credit_spread), false, 3, "center", true),
                table.header("", false),table.header("", false),
                table.header(_t(ETranslation.rating), false, 3, "center"),
                table.header("", false),table.header("", false),
            ]
        );
        table.addRow([
            table.header(_t(ETranslation.rating), true),
            table.header("Ausgewählte Kennzahl", true),
            table.header("Einheit", true, undefined, "right"),
            table.header("Gewichtung", true, undefined, "right"),
            table.header("Wert", true, undefined, "right"),

            table.header("von", true, undefined, "right"),
            table.header(_t(ETranslation.to), true, undefined, "right"),
            table.header("Anzahl", true, undefined, "right"),

            table.header("Breite", true, undefined, "right"),
            table.header("Qualität", true, undefined, "right"),
            table.header("von", true, undefined, "right"),
            table.header(_t(ETranslation.to), true, undefined, "right"),
            table.header("Anzahl", true, undefined, "right"),

            table.header("von", true, undefined, "right"),
            table.header(_t(ETranslation.to), true, undefined, "right"),
            table.header("Wert", true, undefined, "right"),

            table.header("von", true),
            table.header(_t(ETranslation.to), true),
            table.header("Wert", true),
        ]);
        const getRating = (index: number) => {
            if(isNaN(index) || index===undefined){
                return Globals.hyphen;
            }
            return Ratings.s_and_p[Math.round(index)];
        };
        const fmt = (v, ofd= 2, row_num?)=>{
            let fd = ofd;
            if(row_num === 4){
                fd = 0;
            }
            return _fmt.m(v, fd);
        };

        table_data.forEach((row, row_num)=>{
            const row_data = [
                table.cell(row.caption),
                table.cell(row.key),
                table.cell(row.units, "right"),
                table.cell(_fmt.p(row.weight, 0), "right"),
                table.cell(fmt(row.key_value, 2, row_num), "right"),

                table.cell(fmt(row.x_from), "right"),
                table.cell(fmt(row.x_till), "right"),
                table.cell(fmt(row.ranged_points, 0), "right"),

                table.cell(_fmt.p(row.longlist_range, 0), "right"),
                table.cell(_fmt.p(row.q_y, 0), "right"),
                table.cell(_fmt.p(row.q_y_from, 0), "right"),
                table.cell(_fmt.p(row.q_y_till, 0), "right"),
                table.cell(fmt(row.boxed_points, 0), "right"),

                table.cell(_fmt.m(row.y_from), "right"),
                table.cell(_fmt.m(row.y_till), "right"),
                table.cell(_fmt.m(row.value), "right"),

                table.cell(getRating(row.rating_from)),
                table.cell(getRating(row.rating_till)),
                table.cell(getRating(row.rating)),
            ];
            table.addRow(row_data);
        });
        const tableBody = table.getBody();
        table.setBorders({
            columns: {
                idx: [4,7,12,15],
                border: [EBorder.left],
            },
            rows: {
                idx: [1, tableBody.length - 1],
                border: [EBorder.bottom],
            },
        });
        const mk_footer = (label: string, value_a, value_b, bold = false)=>{
            const row_data = (new Array(19)).fill("").map((a,i)=>{
                if(i===0){
                    return table.cell(label,undefined, bold);
                }
                if([15,18].includes(i)){
                    if(i===15){
                        return table.cell(value_a,"right", bold);
                    }
                    if(i===18){
                        return table.cell(value_b,undefined, bold);
                    }
                }else{
                    return table.cell("");
                }
            });
            table.addRow(row_data);
        };
        mk_footer(_t(ETranslation.minimum), f.min_value, f.min_rating);
        mk_footer(_t(ETranslation.lower_quartile), f.q25_value, f.q25_rating);
        mk_footer(_t(ETranslation.median), f.value, f.rating, true);
        mk_footer(_t(ETranslation.upper_quartile), f.q75_value, f.q75_rating);
        mk_footer(_t(ETranslation.maximum), f.max_value, f.max_rating);
        // console.error(table);
        // footer info ...
        // 19 spalten
        return content;
    }
}
