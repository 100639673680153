import {NonIdealState} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {ICalcCreditSpreadResult} from "../../helpers/CalcCreditSpreadIndication";
import {PDF_IMAGE_WIDTH, PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {BaseModule} from "./BaseModule";
import {median, quantile, sum} from "../../helpers/Statistics";
import {EParameters} from "../../models/EParameters";
import {SzBondChart} from "../widgets/SzBondChart";
import * as moment from "moment/moment";
import {ISzSvgDataPoint} from "../../models/ISzSvgDataPoint";
import {SessionStore} from "../../const/SessionStore";
import {IBondDetails} from "../../models/IBondDetails";

import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {HighlightValue} from "../../chart-plugin/HighlightValue";
import {CreditSpreadOptions} from "../../const/CreditSpreadOptions";
import {_t, _tn} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    HighlightValue,
);
const currencies: string[] = "USD,EUR,GBP,JPY,AUD,CAD,CHF,CNY,SEK".split(",");
export class CreditSpreadInterestRateChart  extends BaseModule<any> {

    private spread = 0;
    private spread_min = 0;
    private spread_max = 0;

    private charts: {[index: string]: React.RefObject<SzBondChart>} = {};
    private charts_count = {};

    constructor(props: any, context: any) {
        super(props, context);
        currencies.forEach((i)=>this.charts[i] = React.createRef());
    }

    protected renderContent() {
        if(!this.data){
            return (<NonIdealState
                icon={"database"}
                title="Keine Daten"
            />);
        }
        const fx = (v, weight)=> v * weight;
        const spreads = [];
        const spreads_min = [];
        const spreads_max = [];
        CreditSpreadOptions.getMetrics().forEach((metric)=>{
            const indication: ICalcCreditSpreadResult = this.data.results[metric.field];
            if(!indication){
                return;
            }
            if(!Array.isArray(indication.boxed_spreads)){
                return;
            }
            spreads.push(fx(median(indication.boxed_spreads), metric.weight));
            spreads_min.push(fx(quantile(indication.boxed_spreads, .25), metric.weight));
            spreads_max.push(fx(quantile(indication.boxed_spreads, .75), metric.weight));
        });

        this.spread = sum(spreads);
        this.spread_min = sum(spreads_min);
        this.spread_max = sum(spreads_max);
        // <div className={"ribbon top left"}>Beta Version</div>
        return (
            <div style={{overflow: "hidden", position: "relative"}}>
                <div className={"sz-row"}>
                    {this.renderSpreadChart("USD")}
                    {this.renderSpreadChart("EUR")}
                    {this.renderSpreadChart("GBP")}
                </div>
                <div className={"sz-row"}>
                    {this.renderSpreadChart("JPY")}
                    {this.renderSpreadChart("AUD")}
                    {this.renderSpreadChart("CAD")}
                </div>
                <div className={"sz-row"}>
                    {this.renderSpreadChart("CHF")}
                    {this.renderSpreadChart("CNY")}
                    {this.renderSpreadChart("SEK")}
                </div>
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;
        // console.error(this.data);
        this.setState({
            loading: false,
            selected_company_indication: data.selected_company_indication ? data.selected_company_indication : this.state.selected_company_indication,
        });
    }
    private noData(error_pos: string) {
        return (<NonIdealState
            icon={"database"}
            title={`Keine Daten (${error_pos})`}
            description="Die Kombination aus Stichtag, Währung, Sector und Rating liefert keine Ergebnisse."
        />);
    }
    private getSeriesNew(currency: string, bond_data: IBondDetails[]) {
        const qualifying_date = moment(SessionStore.get(EParameters.DateParameter), "DD.MM.YYYY");
        const renderData: ISzSvgDataPoint[] = [];

        bond_data.forEach( (i) => {
            if(i.ftid_yield < 0){
                return;
            }
            if(i.currency_iso_3!==currency){
                return;
            }

            const mat_date = moment(i.mat_date, "YYYYMMDD");
            const x = mat_date.diff(qualifying_date, "months", true);
            if((x > 30 * 12) || (x < 6)){
                return;
            }
            const y = i.ftid_yield;
            renderData.push({
                x,y, dataSet: i
            });
        } );
        return renderData;
    }
    private getStartStop() {
        const dt = SessionStore.get(EParameters.CreditSpreadRuntimeParameter);
        const range = dt.split(",");
        const s = parseInt(range[0], 10);
        const start = s<12 ? 12 : s;
        const stop = parseInt(range[1], 10);
        return {start, stop};
    }
    private renderSpreadChart(currency: string) {
        const boxed_stat = this.data.all_boxed_spreads;
        if(!Array.isArray(boxed_stat)){
            return this.noData("N3");
        }
        if(!boxed_stat.length){
            return this.noData("N4");
        }

        const stat_map = {};
        boxed_stat.forEach((i, num)=>{
            if(!i.count){
                return;
            }
            i.index = num;
            stat_map[i.id] = i;
        });

        const bond_details: IBondDetails[] = this.data.bond_details;
        const all_bonds: IBondDetails[] = [];
        const all_bonds_map = {};
        bond_details.forEach((i)=>{
            if(!stat_map[i.co_name]){
                return;
            }
            if(!all_bonds_map[i.p_symbol_isin]){
                stat_map[i.co_name].bonds.push(i);
                all_bonds.push(i);
                i.company_index = stat_map[i.co_name].index;
                all_bonds_map[i.p_symbol_isin] = i;
            }
        });
        const data = this.getSeriesNew(currency, all_bonds);
        const interest_rate = median(data.map((i)=> i.y));
        const interest_rate_q25 = quantile(data.map((i)=> i.y), .25);
        const interest_rate_q75 = quantile(data.map((i)=> i.y), .75);
        // console.error(spread);
        // console.error(boxed_stat, all_bonds, data);
        const chart_data = {
            datasets: [
                {
                    label: _t(ETranslation.bonds),
                    data,
                    backgroundColor: Globals.shortlist_green,
                    datalabels: {
                        labels: {
                            title: null
                        }
                    }
                },
            ]
        };
        const {start, stop} = this.getStartStop();
        // const spread = this.spread;
        if(interest_rate >=0 && start && stop){
            chart_data.datasets.push({
                order: -1,
                type: start === stop ? "bubble" : "line",
                label: 'Zinssatz',
                data: start === stop ? [{x: start, y: interest_rate, isSpreadValue: 1}] : [{x: start, y: interest_rate, isSpreadValue: 1}, {x: stop, y: interest_rate, isSpreadValue: 2}],
                backgroundColor: "#f47a22",
                borderColor: "#f47a22",
                borderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 3,
                datalabels: {
                    align:  (value, context, a)=>{
                        if(start>= 240){
                            return "left";
                        }
                        return "-30";
                    },
                    anchor: (value, context, a)=>{
                        if(start>= 240){
                            return "center";
                        }
                        return "end";
                    },
                    offset: (value, context, a)=>{
                        if(start>= 240){
                            return 5;
                        }
                        return 7;
                    },
                    backgroundColor: "rgba(255,255,255,0.9)",
                    formatter: (value, context, a)=>{
                        if(value.isSpreadValue===1){
                            return `${_t(ETranslation.median)}: ${Globals.formatter(value.y)}`;
                        }
                        return null;
                    },
                    labels: {
                        title:{
                            color: "#f47a22",
                        }
                    }
                }
            } as any);
        }
        if(interest_rate_q25 >=0){
            chart_data.datasets.push({
                order: -1,
                type: "line",
                label: 'Unteres Quartil',
                data: [{x: 12, y: interest_rate_q25, isSpreadValue: 2}, {x: 30 * 12, y: interest_rate_q25, isSpreadValue: 3}],
                backgroundColor: "#137cbd",
                borderColor: "#137cbd",
                borderDash: [7,2,2,2],
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 0,
                datalabels: {
                    align: "135",
                    anchor: "end",
                    offset: 5,
                    backgroundColor: "rgba(255,255,255,0.9)",
                    formatter: (value, context, a)=>{
                        if(value.isSpreadValue===3){
                            return `Unteres Quartil: ${Globals.formatter(value.y)}`;
                        }
                        return null;
                    },
                    labels: {
                        title:{
                            color: "#137cbd",
                        }
                    }
                }
            } as any);
        }
        if(interest_rate_q75 >=0){
            chart_data.datasets.push({
                order: -1,
                type: "line",
                label: 'Oberes Quartil',
                data: [{x: 12, y: interest_rate_q75, isSpreadValue: 2}, {x: 30 * 12, y: interest_rate_q75, isSpreadValue: 3}],
                backgroundColor: "#137cbd",
                borderColor: "#137cbd",
                borderDash: [7,2,2,2],
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 0,
                datalabels: {
                    align: "-135",
                    anchor: "end",
                    offset: 5,
                    backgroundColor: "rgba(255,255,255,0.9)",
                    formatter: (value, context, a)=>{
                        if(value.isSpreadValue===3){
                            return `Oberes Quartil: ${Globals.formatter(value.y)}`;
                        }
                        return null;
                    },
                    labels: {
                        title:{
                            color: "#137cbd",
                        }
                    }
                }
            } as any);
        }
        const get_tooltip_label = (item)=>{
            if(item.raw && item.raw.dataSet){
                const details:IBondDetails = item.raw.dataSet;
                return `${details.p_symbol_isin} | ${details.fi_name_iss}`;
            }
            if(item.raw && item.raw.isSpreadValue){
                return `${_t(ETranslation.credit_spread)}: ${Globals.formatter(item.raw.y)}`;
            }

            console.error(item);
            return item;
        };
        // console.error(spread_value);
        // https://chartjs-plugin-datalabels.netlify.app/guide/positioning.html#clamping
        this.charts_count[currency] = data.length;
        return (
            <div className={"sz-col"} style={{width: "calc(100% / 3)"}}>
                <div style={{marginBottom: 20, fontSize: "80%"}}>
                    <strong>Zinssatz {currency} (#Anleihen {Globals.formatter(data.length, 0, 0)})</strong>
                    <br/>
                    <span>[% p.a.]</span>
                </div>
                <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                    <SzBondChart chart_data={chart_data} get_tooltip_label={get_tooltip_label} ref={this.charts[currency]}/>
                </div>
            </div>
        );
    }

    public getPdfPage(): any[] {
        const content =[];
        PdfInsertElement.page_header(content, "Zinssätze");

        const table = PdfInsertElement.table(content);
        table.layout = "noBorders";
        table.table.heights = 250;
        table.table.widths = ["*","*","*"];
        table.table.body = [[0,0,0],[0,0,0],[0,0,0]];

        const images = [];
        currencies.forEach((c)=>{
            const r = this.charts[c];
            const items = [];

            PdfInsertElement.h2(items, `Zinssatz ${c} (${_tn(ETranslation.bonds)} ${Globals.formatter(this.charts_count[c], 0, 0)})`);
            PdfInsertElement.p(items, "[% p.a.]");
            if(r.current){
                PdfInsertElement.image(items, r.current.getImage(560, 400), PDF_IMAGE_WIDTH / 2);
            }else{
                PdfInsertElement.p(items, "");
            }
            const stack = {
                unbreakable: true,
                stack: items,
            };
            images.push(stack);
        });

        table.table.body[0][0] = images[0];
        table.table.body[0][1] = images[1];
        table.table.body[0][2] = images[2];
        table.table.body[1][0] = images[3];
        table.table.body[1][1] = images[4];
        table.table.body[1][2] = images[5];
        table.table.body[2][0] = images[6];
        table.table.body[2][1] = images[7];
        table.table.body[2][2] = images[8];
        // console.error(content);
        // this.charts[currency]
        return content;
    }
}
