import * as katex from "katex";
import "katex/dist/katex.css";
import * as React from "react";

// evtl https://www.mathjax.org/#demo nutzen!
export interface ISzRenderFormularProps {
    expression: string;
}
export class SzRenderFormular extends React.Component<ISzRenderFormularProps,any> {

    constructor(props: ISzRenderFormularProps, context: any) {
        super(props, context);
    }

    public render(){
        const html = katex.renderToString(this.props.expression, {
            throwOnError: false,
            strict: false,
        });
        return <div style={{width: "100%"}} dangerouslySetInnerHTML={{ __html: html }} />;
    }
}
