import {Button, Classes, Icon, Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {ICompanySearchResult} from "../../../models/ICompanySearchResult";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {EventBus, IEventHandler} from "../../../services/EventBus";
import {ServiceCalls} from "../../../services/ServiceCalls";
import {StoredSearch} from "../../../services/StoredSearch";
import {GetNameDialog} from "../../widgets/GetNameDialog";
import {CombiParameter} from "../CombiParameter";
import {IconNames} from "@blueprintjs/icons";

export class CompaniesParameter  extends CombiParameter {
    private readonly eventHandler: IEventHandler;

    get companies(): any[] {
        const companyOptions = this.state.companyOptions;
        const ret = [];
        companyOptions.forEach( (o) => {
            if (o.selected) {
                ret.push(o.value);
            }
        });
        return companyOptions.filter((o) => o.selected);
    }

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
        this.eventHandler = EventBus.subscribe<IOptionEntry[]>("Update.CompaniesParameters", (data) => {this.onUpdateCompanies(data); });
    }

    public onUpdateCompanies( companyOptions: IOptionEntry[]) {
        // console.error(companyOptions);
        super.setState({
            companiesAll: true,
            selectedValue: "CurrentSearch",
            companyOptions,
        });
        this.fireCompaniesChange(companyOptions);
    }
    public componentWillUnmount(): void {
        EventBus.unsubscribe(this.eventHandler);
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CompaniesParameter];
    }

    protected async initialize() {
        const selectCompanies = SessionStore.getItem("CompaniesParameter", []);
        const selectedValue = SessionStore.getItem("SelectedValue", "CurrentSearch");
        const companyOptions = SessionStore.getItem<IOptionEntry[]>("SelectedCompaniesParameter", []);

        if(!selectCompanies.length){
            companyOptions.forEach((o) => {
                o.selected = true;
                selectCompanies.push(o.value);
            });
        }

        companyOptions.sort( (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        companyOptions.forEach( (o) => o.selected = selectCompanies.indexOf(o.value) >= 0 ? true : false);
        const companiesAll = companyOptions.reduce( (a, o) => a && o.selected , true );
        const searches = await StoredSearch.get();
        let options = [];
        if (searches && searches.result && Array.isArray(searches.result)) {
            options = searches.result.map((i) => ({value: i._id, label: i.name, selected: false, data: i}));
            Globals.primeSectorOptions = options;
        }
        return {
            selectedValue,
            companyOptions,
            companiesAll,
            primeSectorOptions: options,
            isLoading: true,
        };
    }

    protected getLabel(): any {
        // console.error("CompaniesParameter", this.state);
        if (this.state.selectedValue === "CurrentSearch") {
            return "Longlist";
        }
        const option = Globals.primeSectorOptions.find((item) => item.value === this.state.selectedValue);
        return option ? option.label : "—";
    }
    protected getWidthClass() {
        if (Globals.isRegistred) {
            return "fixed-width-1000";
        }
        return "fixed-width-400";
    }
    protected  getIcon(f: boolean) {
        if (f) {
            return <Icon icon={"tick"}/>;
        } else {
            return <Icon icon={IconNames.BLANK}/>;
        }
    }

    protected fireCompaniesChange(companyOptions: IOptionEntry[]) {
        this.fireValueChange(EParameters.CompaniesParameter, companyOptions.filter((o) => o.selected).map( (o) => o.value ));
    }
    protected changeCompany(value) {
        const companyOptions = this.state.companyOptions;
        const item = companyOptions.find((o) => o.value === value);
        if (!item) {
            return;
        }
        item.selected = !item.selected;
        const companiesAll = companyOptions.reduce((acc, current) => acc && current.selected, true);
        this.setState({
            companiesAll,
            companyOptions,
        });
        this.fireCompaniesChange(companyOptions);
    }
    protected setSelected(companyOptions: IOptionEntry[], longList: ICompanySearchResult[], selectedValue: string) {
        companyOptions.sort( (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        longList.sort( (a, b) => a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase()) );
        SessionStore.setItem("SelectedCompanySearch", longList);
        SessionStore.setItem("SelectedCompaniesParameter", companyOptions);
        SessionStore.setItem("SelectedValue", selectedValue);
    }
    protected setCurrentSearch() {
        const longList = SessionStore.getItem("CompanySearch", []);
        const companyOptions = longList.map( (i: ICompanySearchResult) => ({label: i.company_name, value: i.company_id, selected: true}) ); // SessionStore.getItem("CompaniesParameter", []);
        companyOptions.forEach( (o) => o.selected = true );
        super.setState({
            companiesAll: true,
            selectedValue: "CurrentSearch",
            companyOptions,
        });
        this.setSelected(companyOptions, longList, "CurrentSearch");
        this.fireCompaniesChange(companyOptions);
    }
    protected changeState(value, parameterType: EParameters, field: string) {
        if (parameterType === EParameters.PrimeSectorV2Parameter) {
            return this.changeCompany(value);
        } else {
            if (!Globals.primeSectorOptions) {
                return;
            }
            const selectedValue = value;
            const listEntry = Globals.primeSectorOptions.find( (o) =>  o.value === value);
            if (listEntry && listEntry.data && Array.isArray(listEntry.data.items)) {

                const companiesAll: boolean = true;
                const companyOptions: IOptionEntry[] = listEntry.data.items.map( (i) => ({
                    label: i.company_name,
                    value: i.company_id,
                    selected: true,
                }));
                this.setState({
                    companiesAll,
                    companyOptions,
                    selectedValue,
                });
                companyOptions.sort( (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
                this.setSelected(companyOptions, listEntry.data.items, selectedValue);
                this.fireCompaniesChange(companyOptions);
            }

        }
    }
    protected renderOptions(options: IOptionEntry[], selectedValue, parameter: EParameters, fieldName, isDisabled?: (o: IOptionEntry) => any) {
        if (!options) {
            return (<span>Liste ist Leer.</span>);
        }
/*
        const getShareIcon = (o: IOptionEntry): IconName => {
            if (o.data && o.data.state === 2) {
                return "document-open";
            }
            if (o.data ) {
                return "blank";
            }
            return null;
        };
 */
        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return <Icon icon={IconNames.BLANK}/>;
            }
        };
        // let cKey = 0;
        const mapEntry = (o: IOptionEntry) => {
            const d = isDisabled && isDisabled(o);
            if (o.data && o.data.state === 1) {
                return null;
            }
            const text = o.data && o.data.state === 2 ? o.label + "*" : o.label;
            return (<MenuItem disabled={this.disabled || d} key={"" + parameter + (o.value)} intent={ o.selected ? "primary" : "none"} labelElement={getIcon(o.selected)} text={text} onClick={() => { this.changeState(o.value, parameter, fieldName); }} />);
        };
        return options.map(mapEntry);
    }
    protected renderMenuContent() {
        // console.error("render");
        const publicList: IOptionEntry[] = [];
        const privateList: IOptionEntry[] = [];
        Globals.primeSectorOptions.forEach( (o) => {
            const item: IOptionEntry = {
                label: o.label,
                value: o.value,
                selected: o.value === this.state.selectedValue,
                data: o.data,
            };
            if (o.data.is_public) {
                publicList.push(item);
            } else {
                privateList.push(item);
            }

        } );
        const renderMenu = (optionList: IOptionEntry[], caption: string, empty_text: string) => {
            const renderCaption = () => {
                if (caption !== "smartZebra Listen") {
                    return (<MenuDivider title={caption} />);
                }
                return (
                    <li className="bp3-menu-header">
                        <h6 className="bp3-heading"><Icon iconSize={16} icon={"star"} color={"#FFC940"} />&nbsp;{caption}</h6>
                    </li>
                );
            };

            if (!optionList.length) {
                return (
                    <Menu>
                        {renderCaption()}
                        <div className={Classes.TEXT_MUTED} style={{marginLeft: 7}}>{empty_text}</div>
                    </Menu>
                );
            }
            return (
                <Menu>
                    {renderCaption()}
                    {this.renderOptions(optionList, this.state.selectedValue, EParameters.PrimeSectorParameter, "selectedValue")}
                </Menu>
            );
        };
        const renderPublic = () => {
            let style = {width: "50%"};
            if (Globals.isRegistred) {
                style = {width: "33%"};
            }
            return (
                <div style={style}>
                    {renderMenu(publicList, "smartZebra Listen", "Keine smartZebra Listen definiert.")}
                </div>
            );
        };
        const renderCurrentSearch = () => {
            const icon = () => this.state.selectedValue === "CurrentSearch" ? <Icon icon={"tick"}/> : null;
            return (
                <MenuItem key={"idCurrentSearchPr"} intent={ "warning"} labelElement={icon()}  text={"Longlist"} onClick={() => {this.setCurrentSearch(); }} />
            );
        };
        const renderPrivate = () => {
            if (Globals.isRegistred) {
                return (
                    <div style={{width: "33%"}}>
                        <Menu>
                            <MenuDivider title={"Eigene Listen"} />
                            {renderCurrentSearch()}
                            {this.renderOptions(privateList, this.state.selectedValue, EParameters.PrimeSectorParameter, "selectedValue")}
                        </Menu>
                    </div>
                );
            } else {
                return null;
            }
        };
        const renderCompanies = () => {
            let style: CSSProperties = {width: "100%", position: "relative"};
            if (Globals.isRegistred) {
                style = {width: "33%", position: "relative"};
            }
            const flag = this.state.companiesAll;
            const renderCaption = () => {
                if (!Globals.isRegistred) {
                    return <MenuDivider title={"Aktive Peer-Group"} />;
                }
                const companyOptions: IOptionEntry[] = this.state.companyOptions;
                const isOneNotSelected = companyOptions.find( (i) => !i.selected );
                return (
                    <li className="bp3-menu-header" style={{marginRight: 0, marginTop: -2}}>
                        <h6 className="bp3-heading" style={{alignItems: "center", display: "flex", justifyContent: "space-between", paddingRight: 0}}>
                            <span style={{display: "flex"}}>Aktive Peer-Group</span>
                            <Button icon={"floppy-disk"} intent={"primary"} minimal={true} disabled={!isOneNotSelected} onClick={() => this.setState({shGetNameDialog: true})} />
                        </h6>
                    </li>
                );
            };
            return (
                <div style={style}>
                    <Menu>
                        {renderCaption()}
                        <MenuItem intent={ flag ? "primary" : "none"} labelElement={this.getIcon(flag)}  text={"Alle"} onClick={() => { this.changeAll(); }}/>
                        <MenuDivider />
                        {this.renderOptions(this.state.companyOptions, this.state.selectedValue, EParameters.PrimeSectorV2Parameter, "companyOptions")}
                    </Menu>
                </div>
            );
        };

        const renderSaveDlg = () => {
            if (!Globals.isRegistred) {
                return null;
            }
            return (<GetNameDialog isOpen={this.state.shGetNameDialog} onClose={() => this.setState({shGetNameDialog: false})} onFinished={ (name: string, isPublic: boolean) => this.saveList(name, isPublic) } />);
        };

        if (!Globals.isRegistred) {
            return (
                <div className={"sz-row"}>
                    {renderCompanies()}
                </div>
            );
        }
        return (
            <div className={"sz-row"}>
                {renderSaveDlg()}
                {renderCompanies()}
                {renderPrivate()}
                {renderPublic()}
                <Menu style={{marginTop: 12, width: "100%", textAlign: "right"}}>
                    <li>
                        <p className={"bp3-text-small bp3-text-muted"}>* geteilte Listen</p>
                    </li>
                </Menu>
            </div>
        );
    }
    private changeAll() {
        const companiesAll = !this.state.companiesAll;
        const companyOptions = this.state.companyOptions;
        companyOptions.forEach((item) => item.selected = companiesAll ? true : false);
        this.setState({
            companiesAll,
            companyOptions,
        });
        this.fireCompaniesChange(companyOptions);
    }
/*
this.state.primeSectorOptions.forEach( (o) => {
            const item: IOptionEntry = {
                label: o.label,
                value: o.value,
                selected: o.value === this.state.selectedValue,
                data: o.data,
            };
            if (o.data.is_public) {
                publicList.push(item);
            } else {
                privateList.push(item);
            }

        } );
*/
    private saveList(name: string, isPublic: boolean) {
        (async () => {
            const selectedList = (this.state.primeSectorOptions as IOptionEntry[]).find( (i) => i.value === this.state.selectedValue);
            const is_selected = (this.state.primeSectorOptions as IOptionEntry[]).find( (i) => i.label === name && i.value === this.state.selectedValue && !i.data.is_public );

            const useList = selectedList ? selectedList.data.items : Globals.longList;

            const companyOptions: IOptionEntry[] = this.state.companyOptions;
            const c_ids = {};
            companyOptions.forEach( (i) => c_ids[i.value] = i.selected);
            const newList = useList.filter( (i) => c_ids[i.company_id] );

            const services = new ServiceCalls();
            const response = await services.saveLongList(name, newList, false, null);

            let options = [];
            if (response && response.result && Array.isArray(response.result)) {
                options = response.result.map((i) => ({value: i._id, label: i.name, selected: false, data: i}));
                Globals.primeSectorOptions = options;
            }

            if (is_selected) {
                const sel_comp = companyOptions.filter( (i) => i.selected );
                this.setSelected(sel_comp, newList, is_selected.value);
                this.setState({
                    shGetNameDialog: false,
                    primeSectorOptions: options,
                    companyOptions: sel_comp,
                    companiesAll: true,
                });
            } else {
                this.setState({shGetNameDialog: false, primeSectorOptions: options});
            }
        })();
    }
}
