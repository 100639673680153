import {
    Button,
    Colors,
    ControlGroup,
    HTMLTable,
    Icon,
    InputGroup, Intent,
    NonIdealState,
    Position, Tag,
    Tooltip,
} from "@blueprintjs/core";
import {CSSProperties} from "react";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {ISavedLongList} from "../../models/ISavedLongList";
import {SzTinyCollapse} from "./SzTinyCollapse";
import {IconNames} from "@blueprintjs/icons";
import {DateTime} from "luxon";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";

const BASE_DIR = Globals.BASE_DIR;

enum EOrderBy {
    author= 1,
    name,
    date,
    star
}
enum EOrderType {
    long_list= 1,
}

export enum ELongListAction {
    RENAME,
    DELETE,
    SELECT,
    OVERRIDE,
    SHARE,
    SHARE_ACCEPT,
}
export interface ILoadLongListProps {
    longLists: ISavedLongList[];
    onSelectList?( item: ISavedLongList);
    onAction?(action: ELongListAction, item: ISavedLongList, new_name?: string);
}
interface ILoadLongListViewState {
    currentEditing: ISavedLongList;
    new_name: string;
    order_by: any;
    order_dir: any;
}
export class LoadLongListView extends React.Component<ILoadLongListProps, ILoadLongListViewState> {

    constructor(props: ILoadLongListProps, context: ILoadLongListViewState) {
        super(props, context);
        this.state = {
            currentEditing: null,
            new_name: null,
            order_by: {},
            order_dir: {},
        };
    }

    public render() {
        if (this.props.longLists && this.props.longLists.length > 0) {
            return this.renderData();
        }
        return this.renderNoData();
    }
    private renderNoData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Es sind keine Listen gespeichert."
        />);
    }
    private renderData() {
        const deCollator = new Intl.Collator("de");
        const order_by = this.state.order_by[EOrderType.long_list];
        const order_dir = this.state.order_dir[EOrderType.long_list];
        const longLists = ([].concat(this.props.longLists)).sort((a,b) => {
            if(order_by === EOrderBy.star){
                const i_a = a.is_public ? 1 : a.state ? 2 : 0;
                const i_b = b.is_public ? 1 : b.state ? 2 : 0;
                return order_dir * (i_a - i_b);
            }
            if(order_by === EOrderBy.date){
                const ma = DateTime.fromJSDate(a.date);
                const mb = DateTime.fromJSDate(b.date);
                return order_dir * (ma.toMillis() - mb.toMillis());
            }
            if(order_by === EOrderBy.name){
                return order_dir * deCollator.compare(a.name.toLowerCase(), b.name.toLowerCase());
            }
            if(order_by === EOrderBy.author){
                const ua = (a.is_public || a.state) ? a.user.toLowerCase() : "";
                const ub = (b.is_public || b.state) ? b.user.toLowerCase() : "";
                return order_dir * deCollator.compare(ua, ub);
            }
        });
        // console.error(longLists);
        const headerStyle: CSSProperties = {
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 10,
            height: 30,
        };
        const scrolling: CSSProperties = {
            position: "absolute",
            top: "0px",
            bottom: "8px",
            width: "100%",
            paddingRight: 1,
            paddingLeft: 1,
        };
        return (
            <div className={"scroll-container"} style={scrolling}>
                <div style={headerStyle}>
                    <HTMLTable className={"fixed-result-table-2 header bp3-html-table bp3-html-table-condensed bp3-html-table-striped bp3-interactive bp3-small"}>
                        <thead>
                        <tr style={{backgroundColor: "rgb(240, 240, 240)"}}>
                            <th style={{width: 45}}>{this.renderSorty(EOrderType.long_list, EOrderBy.star, <Icon icon={"star-empty"} />)}</th>
                            <th>{this.renderSorty(EOrderType.long_list, EOrderBy.name, "Name")}</th>
                            <th style={{width: 250}}>{this.renderSorty(EOrderType.long_list, EOrderBy.author, "Autor")}</th>
                            <th style={{width: 150}}>{this.renderSorty(EOrderType.long_list, EOrderBy.date, _t(ETranslation.date))}</th>
                        </tr>
                        </thead>
                    </HTMLTable>
                </div>
                <div className={"scroll-content"}>
                    <HTMLTable className={"sz-table fixed-result-table-2 bp3-html-table-condensed bp3-interactive bp3-small"} style={{width: "100%", marginTop: 0, marginBottom: 0}} condensed={true} interactive={false} bordered={false}>
                        <tbody>
                        {longLists.map((item) => this.renderItem(item))}
                        </tbody>
                    </HTMLTable>
                </div>
            </div>
        );
    }
    private renderItem(item: ISavedLongList) {
        const getPublicIcon = () => {
            if (item.state === 2) {
                return <Icon icon={"document-open"} color={"#137cbd"} title={"Geteilt"}/>;
            }
            if (item.state === 1) {
                return <Icon icon={"document-open"} color={Colors.LIGHT_GRAY3} title={"Geteilt"}/>;
            }
            if (item.is_public) {
                return <Icon icon={"star"} color={Colors.GOLD5} title={"Redaktionell erstellt"}/>;
            } else {
                return <Icon icon={"star-empty"} color={Colors.LIGHT_GRAY3}/>;
            }
        };
        const isEditing: boolean = this.state.currentEditing && this.state.currentEditing._id === item._id;
        const inlineEditing = () => {
            if (isEditing) {
                return (
                    <ControlGroup className={"sz-search-control"} vertical={false} fill={true} style={{width: "100%"}}>
                        <InputGroup
                            className={"no-focus no-right-border"}
                            autoFocus={true}
                            value={this.state.new_name}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({new_name: event.target.value})}
                        />
                        <Button onClick={() => this.renameListItem()} intent={"primary"} icon="floppy-disk" title={_t(ETranslation.rename)} style={{maxWidth: 40}} />
                        <Button onClick={() => this.setState({currentEditing: null})} intent={"danger"} icon="cross" title={_t(ETranslation.cancel)} style={{maxWidth: 40}} />
                    </ControlGroup>
                );
            }
            return (
                <SzTinyCollapse caption={item.name}>
                    {item.items.map((c) => (<div style={{paddingBottom: 4}}>
                        <span><img style={{maxHeight: 16, verticalAlign: "middle"}} src={`${BASE_DIR}images/flags/${c.country_iso_2}.jpg`}/></span>&nbsp;
                        {c.company_name}
                    </div>))}
                </SzTinyCollapse>
            );
        };
        const actionWidth = 150;
        const tooltip = (title, content) => {
            return (
                <Tooltip
                    content={<span>{title}</span>}
                    position={Position.BOTTOM}
                    usePortal={false}
                    hoverOpenDelay={500}
                >
                    {content}
                </Tooltip>
            );
        };
        const renderAction = () => {
            // console.error(`${item.date}`);
            const dt = DateTime.fromISO(`${item.date}`);
            if (item.state === 1) {
                return (
                    <td className={"sz-load-longlist-conte"} style={{width: actionWidth}}>
                        <div className={"sz-load-longlist-date"}>{dt.toFormat("dd.LL.yyyy hh.mm")}</div>
                        <div className={"sz-load-longlist-action"}>
                            {tooltip("Liste annehmen", <Button onClick={() => this.onAccept(item)} icon={"tick"} minimal={true} intent={"primary"}/>)}
                            {tooltip("Löschen", <Button onClick={() => this.deleteListItem(item)} icon={"trash"} minimal={true} intent={"danger"} />)}
                        </div>
                    </td>
                );
            }
            if (item.is_public && !Globals.isAdmin) {
                return (
                    <td className={"sz-load-longlist-conte"} style={{width: actionWidth}}>
                        <div className={"sz-load-longlist-date"}>{dt.toFormat("dd.LL.yyyy hh.mm")}</div>
                        <div className={"sz-load-longlist-action"}>
                            {tooltip(_t(ETranslation.apply), <Button onClick={() => this.props.onSelectList(item)} icon={"arrow-left"} minimal={true} intent={"primary"} />)}
                        </div>
                    </td>
                );
            }
            if (item.state === 2) {
                return (
                    <td className={"sz-load-longlist-conte"} style={{width: actionWidth}}>
                        <div className={"sz-load-longlist-date"}>{dt.toFormat("dd.LL.yyyy hh.mm")}</div>
                        <div className={"sz-load-longlist-action"}>
                            {tooltip(_t(ETranslation.apply), <Button onClick={() => this.props.onSelectList(item)} icon={"arrow-left"} minimal={true} intent={"primary"} />)}
                            {tooltip("Löschen", <Button onClick={() => this.deleteListItem(item)} icon={"trash"} minimal={true} intent={"danger"}/>)}
                        </div>
                    </td>
                );
            }
            return (
                <td className={"sz-load-longlist-conte"} style={{width: actionWidth}}>
                    <div className={"sz-load-longlist-date"}>{dt.toFormat("dd.LL.yyyy hh.mm")}</div>
                    <div className={"sz-load-longlist-action"}>
                        {tooltip(_t(ETranslation.apply), <Button onClick={() => this.props.onSelectList(item)} icon={"arrow-left"} minimal={true} intent={"primary"} />)}
                        {tooltip(_t(ETranslation.overwrite), <Button onClick={() => this.overrideListItem(item)} icon={"arrow-up"} minimal={true}/>)}
                        {tooltip(_t(ETranslation.rename), <Button onClick={() => this.setState({currentEditing: item, new_name: item.name})} icon={"edit"} minimal={true}/>)}
                        {tooltip(_t(ETranslation.send), <Button onClick={() => this.shareListItem(item)} icon={"document-share"} minimal={true}/>)}
                        {tooltip(_t(ETranslation.delete), <Button onClick={() => this.deleteListItem(item)} icon={"trash"} minimal={true} intent={"danger"}/>)}
                    </div>
                </td>
            );
        };
        const getAuthor = ()=> {
            if(item.is_public || item.state){
                const u = item.user;
                const c = item.company;
                if(u && c){
                    return `${u} | ${c}`;
                }else{
                    return `${u}`;
                }
            }else{
                return <Tag intent={Intent.WARNING}>Sie</Tag>;
            }
        };
        return (
            <tr className={"sz-load-longlist-tr"}>
                <td style={{width: 45}}>{getPublicIcon()}</td>
                <td className={"sz-load-longlist-name"} style={isEditing ? {padding: 0} : {}}>
                    {inlineEditing()}
                </td>
                <td style={{width: 250}}>{getAuthor()}</td>
                {renderAction()}
            </tr>
        );
    }

    private renameListItem() {
        if (!this.props.onAction) {
            return;
        }
        const new_name = this.state.new_name;
        if (!new_name) {
            return;
        }
        if (new_name.trim().length === 0) {
            return;
        }
        this.props.onAction(ELongListAction.RENAME, this.state.currentEditing, new_name.trim());
        this.setState({
            currentEditing: null,
            new_name: null,
        });
    }

    private onAccept(item: ISavedLongList) {
        if (!this.props.onAction) {
            return;
        }
        this.props.onAction(ELongListAction.SHARE_ACCEPT, item);
    }
    private deleteListItem(item: ISavedLongList) {
        if (!this.props.onAction) {
            return;
        }
        this.props.onAction(ELongListAction.DELETE, item);
    }
    private shareListItem(item: ISavedLongList) {
        if (!this.props.onAction) {
            return;
        }
        this.props.onAction(ELongListAction.SHARE, item);
    }

    private overrideListItem(item: ISavedLongList) {
        if (!this.props.onAction) {
            return;
        }
        this.props.onAction(ELongListAction.OVERRIDE, item);
    }

    private renderSorty(type: EOrderType, new_order: EOrderBy, caption: any, justifyContent = "flex-start") {
        const current_order_by = this.state.order_by[type];
        const current_order_dir = this.state.order_dir[type];
        const order_by = this.state.order_by;
        const dir = this.state.order_dir;
        const sort = () => {
            if(current_order_by && current_order_by !== new_order){
                order_by[type] = new_order;
                dir[type] = 1;
                return this.setState({
                    order_by,
                    order_dir: dir,
                });
            }

            let order_dir;
            if(current_order_dir === undefined){
                order_dir = 1;
            }
            if(current_order_dir === 1){
                order_dir = -1;
            }
            if(order_dir === undefined){
                order_by[type] = undefined;
                dir[type] = undefined;
                return this.setState({
                    order_by,
                    order_dir: dir,
                });
            }

            order_by[type] = new_order;
            dir[type] = order_dir;
            this.setState({
                order_by,
                order_dir: dir,
            });
        }
        const renderNotSorted = () => {
            if(current_order_by !== new_order){
                return (
                    <div style={{display: "flex", flexFlow: "column", alignItems: "center", height: "100%", width: 12}}>
                        <Icon icon={IconNames.CARET_UP} iconSize={10} />
                        <Icon icon={IconNames.CARET_DOWN} iconSize={10} />
                    </div>
                );
            }else{
                return undefined;
            }
        };
        const renderAsc = () => {
            if(current_order_by === new_order && current_order_dir === 1){
                return (
                    <div style={{display: "flex", flexFlow: "column", height: "100%"}}>
                        <Icon icon={IconNames.CARET_UP} iconSize={12} />
                    </div>
                );
            }else{
                return undefined;
            }
        };
        const renderDesc = () => {
            if(current_order_by === new_order && current_order_dir === -1){
                return (
                    <div style={{display: "flex", flexFlow: "column", justifyContent: "flex-end", height: "100%"}}>
                        <Icon icon={IconNames.CARET_DOWN} iconSize={12} />
                    </div>
                );
            }else{
                return undefined;
            }
        };
        return (
            <div style={{display: "flex", alignItems: "end", justifyContent, height: 20, width: "100%", cursor: "pointer", userSelect: "none"}} onClick={()=> sort()}>
                <span style={{paddingRight: 3, alignSelf: "center"}}>{caption}</span>
                {renderNotSorted()}
                {renderAsc()}
                {renderDesc()}
            </div>
        );
    }
}
