import * as React from "react";
import {Icon} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {EventBus} from "../../services/EventBus";
import {sleep} from "../../helpers/Helpers";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export interface ISzDocReferenceProps {
    page?: number;
    inline?: boolean;
    ref_id: string;
    text: string;
}

export class SzDocReference extends React.Component<ISzDocReferenceProps>{


    constructor(props: Readonly<ISzDocReferenceProps> | ISzDocReferenceProps) {
        super(props);
    }

    public render() {
        const onClick = ()=> {
            (async () => {
                try {
                    EventBus.emit("SideBar::openPage", 6);
                    await sleep(250);
                    EventBus.emit("PageDocumentation::openDocRef", this.props.ref_id);
                } catch (exi) {
                    console.error(exi);
                }
            })();
        };
        if(this.props.inline){
            return (
                <a title={_t(ETranslation.glossary_icon_hoover)} style={{display: "inline-flex", color: "#106ba3"}} className={"sz-doc-ref-con bp3-popover-dismiss"} onClick={()=> onClick()}>
                    {this.props.text}
                </a>
            );
        }
        return (
            <div title={_t(ETranslation.glossary_icon_hoover)} style={{width: "fit-content", display: "flex", justifyContent: "flex-start", alignItems: "center"}} className={"sz-doc-ref-con"} onClick={()=> onClick()}>
                <span>{this.props.text}&nbsp;</span>
                <Icon icon={IconNames.HELP} size={12} className={"sz-doc-ref"} />
            </div>
        );
    }
}
