export enum ETranslation {
	"t_test_beta_description"= "t_test_beta_description",
	"regression_residual_t"= "regression_residual_t",
	"regression_residual_t_-1"= "regression_residual_t_-1",
	"return_settings_best_2"= "return_settings_best_2",
	"off"= "off",
	"longlist_save"= "longlist_save",
	"longlist_send_help"= "longlist_send_help",
	"market_capitalization"= "market_capitalization",
	"accept"= "accept",
	"active"= "active",
	"add"= "add",
	"add_member"= "add_member",
	"add_member_description"= "add_member_description",
	"adjusted_beta"= "adjusted_beta",
	"ai_search"= "ai_search",
	"all"= "all",
	"allshare_index"= "allshare_index",
	"analysis"= "analysis",
	"annually"= "annually",
	"applied"= "applied",
	"applied_as_calculated"= "applied_as_calculated",
	"applied_as_inputed"= "applied_as_inputed",
	"apply"= "apply",
	"apply_filter"= "apply_filter",
	"assets_affiliates"= "assets_affiliates",
	"assets_current_financial"= "assets_current_financial",
	"assets_non_current_financial"= "assets_non_current_financial",
	"at_valuation_date"= "at_valuation_date",
	"available_data_points"= "available_data_points",
	"average"= "average",
	"average_90_days"= "average_90_days",
	"average_filtered"= "average_filtered",
	"average_observation_period_beta"= "average_observation_period_beta",
	"base_data"= "base_data",
	"base_rate"= "base_rate",
	"base_rate_curve_prop_description"= "base_rate_curve_prop_description",
	"base_rate_term_structure"= "base_rate_term_structure",
	"benchmark_index"= "benchmark_index",
	"benchmarking"= "benchmarking",
	"beta_factor"= "beta_factor",
	"beta_factor_and_gearing"= "beta_factor_and_gearing",
	"beta_factor_filter"= "beta_factor_filter",
	"beta_factor_stand_error"= "beta_factor_stand_error",
	"beta_levered"= "beta_levered",
	"beta_levered_description"= "beta_levered_description",
	"beta_relevered"= "beta_relevered",
	"beta_unlevered"= "beta_unlevered",
	"beta_unlevered_based_on"= "beta_unlevered_based_on",
	"bid_ask_spread"= "bid_ask_spread",
	"biweekly"= "biweekly",
	"bond"= "bond",
	"bonds"= "bonds",
	"breusch_pagan_test"= "breusch_pagan_test",
	"breusch_pagan_test_short"= "breusch_pagan_test_short",
	"budget_deficit"= "budget_deficit",
	"business_description"= "business_description",
	"cancel"= "cancel",
	"capital_expenditures"= "capital_expenditures",
	"capital_structure"= "capital_structure",
	"cash_excess"= "cash_excess",
	"cash_restricted"= "cash_restricted",
	"close_project"= "close_project",
	"common_share"= "common_share",
	"companies_of_max"= "companies_of_max",
	"company"= "company",
	"company_financials"= "company_financials",
	"company_name"= "company_name",
	"company_search"= "company_search",
	"company_valuation"= "company_valuation",
	"confirm"= "confirm",
	"confirm_pass"= "confirm_pass",
	"constituents"= "constituents",
	"corporate_tax_rate"= "corporate_tax_rate",
	"correlation_coefficient"= "correlation_coefficient",
	"cost_of_capital"= "cost_of_capital",
	"cost_of_debt"= "cost_of_debt",
	"cost_of_debt_after_tax"= "cost_of_debt_after_tax",
	"cost_of_debt_weight"= "cost_of_debt_weight",
	"cost_of_equity"= "cost_of_equity",
	"cost_of_equity_unlevered"= "cost_of_equity_unlevered",
	"cost_of_equity_weight"= "cost_of_equity_weight",
	"countries"= "countries",
	"country"= "country",
	"country_and_currency"= "country_and_currency",
	"country_and_currency_labels"= "country_and_currency_labels",
	"country_currency_prop_description"= "country_currency_prop_description",
	"country_risk_premium"= "country_risk_premium",
	"create"= "create",
	"created_at"= "created_at",
	"credit_spread"= "credit_spread",
	"credit_spread_over_time"= "credit_spread_over_time",
	"credit_spread_ratings"= "credit_spread_ratings",
	"credit_spread_term_stucture"= "credit_spread_term_stucture",
	"credit_spread_term_stucture_rating"= "credit_spread_term_stucture_rating",
	"creditworthiness"= "creditworthiness",
	"crp_prop_description"= "crp_prop_description",
	"currency"= "currency",
	"customers"= "customers",
	"dach_region"= "dach_region",
	"daily"= "daily",
	"data_points"= "data_points",
	"database_extention_request"= "database_extention_request",
	"database_extention_request_text"= "database_extention_request_text",
	"date"= "date",
	"day"= "day",
	"debt_beta"= "debt_beta",
	"debt_beta_application"= "debt_beta_application",
	"debt_beta_application_applied_gearing_100"= "debt_beta_application_applied_gearing_100",
	"debt_beta_application_applied_gearing_200"= "debt_beta_application_applied_gearing_200",
	"debt_beta_application_applied_gearing_300"= "debt_beta_application_applied_gearing_300",
	"debt_beta_credit_spread"= "debt_beta_credit_spread",
	"debt_beta_credit_spread_description"= "debt_beta_credit_spread_description",
	"debt_beta_description"= "debt_beta_description",
	"debt_beta_market_risk_premium_description"= "debt_beta_market_risk_premium_description",
	"debt_beta_prop_description"= "debt_beta_prop_description",
	"default_smartzebra"= "default_smartzebra",
	"delete"= "delete",
	"delete_msg"= "delete_msg",
	"delisting"= "delisting",
	"desciption"= "desciption",
	"digits_2"= "digits_2",
	"distribution_residuals"= "distribution_residuals",
	"duration"= "duration",
	"duration_base_rate_prop_description"= "duration_base_rate_prop_description",
	"duration_credit_spread_prop_description"= "duration_credit_spread_prop_description",
	"durbin_watson_test"= "durbin_watson_test",
	"durbin_watson_test_short"= "durbin_watson_test_short",
	"ebit_adjusted"= "ebit_adjusted",
	"ebit_margin"= "ebit_margin",
	"ebitda_adjusted"= "ebitda_adjusted",
	"ebitda_margin"= "ebitda_margin",
	"ebt"= "ebt",
	"edit"= "edit",
	"edit_project"= "edit_project",
	"elimination_data_points"= "elimination_data_points",
	"email"= "email",
	"employees"= "employees",
	"empty_msg"= "empty_msg",
	"enterprise_value"= "enterprise_value",
	"equity_ratio"= "equity_ratio",
	"equity_risk_premium"= "equity_risk_premium",
	"equity_value"= "equity_value",
	"eu_countries"= "eu_countries",
	"eurozone"= "eurozone",
	"example"= "example",
	"excel_icon_hoover"= "excel_icon_hoover",
	"exchange_rate_avg"= "exchange_rate_avg",
	"exchange_rate_eoy"= "exchange_rate_eoy",
	"f_test_description"= "f_test_description",
	"files"= "files",
	"filter_beta_factor_t_test_active"= "filter_beta_factor_t_test_active",
	"filter_beta_factor_t_test_description"= "filter_beta_factor_t_test_description",
	"filter_beta_factor_t_test_inactive"= "filter_beta_factor_t_test_inactive",
	"filter_bid_ask_spread_active"= "filter_bid_ask_spread_active",
	"filter_bid_ask_spread_description"= "filter_bid_ask_spread_description",
	"filter_bid_ask_spread_inactive"= "filter_bid_ask_spread_inactive",
	"filter_breusch_pagan_active"= "filter_breusch_pagan_active",
	"filter_breusch_pagan_description"= "filter_breusch_pagan_description",
	"filter_breusch_pagan_inactive"= "filter_breusch_pagan_inactive",
	"filter_criteria_insufficient"= "filter_criteria_insufficient",
	"filter_data_points_active"= "filter_data_points_active",
	"filter_data_points_description"= "filter_data_points_description",
	"filter_data_points_inactive"= "filter_data_points_inactive",
	"filter_durbin_watson_active"= "filter_durbin_watson_active",
	"filter_durbin_watson_description"= "filter_durbin_watson_description",
	"filter_durbin_watson_inactive"= "filter_durbin_watson_inactive",
	"filter_free_float_active"= "filter_free_float_active",
	"filter_free_float_description"= "filter_free_float_description",
	"filter_free_float_inactive"= "filter_free_float_inactive",
	"filter_r_square_active"= "filter_r_square_active",
	"filter_r_square_description"= "filter_r_square_description",
	"filter_r_square_inactive"= "filter_r_square_inactive",
	"financial_debt_ex_leasing"= "financial_debt_ex_leasing",
	"financial_year"= "financial_year",
	"financials"= "financials",
	"finish"= "finish",
	"forward_multiples_description"= "forward_multiples_description",
	"free_float"= "free_float",
	"friday"= "friday",
	"gdp"= "gdp",
	"gdp_growth"= "gdp_growth",
	"gearing"= "gearing",
	"gearing_definition"= "gearing_definition",
	"gearing_definition_description"= "gearing_definition_description",
	"gearing_negativ_value_description"= "gearing_negativ_value_description",
	"gearing_period_description"= "gearing_period_description",
	"gearing_prop_description"= "gearing_prop_description",
	"geo-region"= "geo-region",
	"get_new_pass"= "get_new_pass",
	"glossary"= "glossary",
	"glossary_icon_hoover"= "glossary_icon_hoover",
	"growth"= "growth",
	"growth_rate_base_rate_prop_description"= "growth_rate_base_rate_prop_description",
	"growth_rate_weighted_base_rate"= "growth_rate_weighted_base_rate",
	"hashtag"= "hashtag",
	"have_account"= "have_account",
	"headquarter"= "headquarter",
	"hits"= "hits",
	"inactive"= "inactive",
	"income_tax"= "income_tax",
	"index_composition"= "index_composition",
	"index_composition_description"= "index_composition_description",
	"index_georegional_eur"= "index_georegional_eur",
	"index_global_eur"= "index_global_eur",
	"index_global_usd"= "index_global_usd",
	"index_national_local_fx"= "index_national_local_fx",
	"index_return"= "index_return",
	"index_return_average"= "index_return_average",
	"index_return_standard_deviation"= "index_return_standard_deviation",
	"index_return_standard_deviation_250d"= "index_return_standard_deviation_250d",
	"index_return_standard_deviation_30d"= "index_return_standard_deviation_30d",
	"index_return_variance"= "index_return_variance",
	"index_standard_deviation"= "index_standard_deviation",
	"inflation"= "inflation",
	"inflation_differential"= "inflation_differential",
	"interest_expenses"= "interest_expenses",
	"invite_success"= "invite_success",
	"ipo"= "ipo",
	"key_financial_ratios"= "key_financial_ratios",
	"konto"= "konto",
	"leasing_off_balance"= "leasing_off_balance",
	"leasing_on_balance"= "leasing_on_balance",
	"licence_group_company_valuation"= "licence_group_company_valuation",
	"licence_group_fiaxed_income"= "licence_group_fiaxed_income",
	"licence_group_transfer_pricing"= "licence_group_transfer_pricing",
	"login"= "login",
	"logout"= "logout",
	"longlist_admin"= "longlist_admin",
	"longlist_send"= "longlist_send",
	"lower_quartile"= "lower_quartile",
	"m_eur"= "m_eur",
	"macroeconomic_data"= "macroeconomic_data",
	"mandatory_field"= "mandatory_field",
	"market_cap"= "market_cap",
	"market_liquidity"= "market_liquidity",
	"market_risk_premium"= "market_risk_premium",
	"market_risk_premium_debt_beta"= "market_risk_premium_debt_beta",
	"max_characters"= "max_characters",
	"maximum"= "maximum",
	"median"= "median",
	"median_filtered"= "median_filtered",
	"median_largest_25"= "median_largest_25",
	"median_peer_group"= "median_peer_group",
	"median_peergroup"= "median_peergroup",
	"members"= "members",
	"minimum"= "minimum",
	"mio_#"= "mio_#",
	"mio_#_relative"= "mio_#_relative",
	"mio_fx"= "mio_fx",
	"mio_fx_relative"= "mio_fx_relative",
	"monday"= "monday",
	"month"= "month",
	"monthly"= "monthly",
	"mrp_and_crp"= "mrp_and_crp",
	"mrp_prop_description"= "mrp_prop_description",
	"multiples"= "multiples",
	"name"= "name",
	"name_longlist"= "name_longlist",
	"negative_net_debt"= "negative_net_debt",
	"net_debt_defined"= "net_debt_defined",
	"net_financial_debt"= "net_financial_debt",
	"netincome"= "netincome",
	"new_pass_setup"= "new_pass_setup",
	"new_project"= "new_project",
	"no"= "no",
	"no_accaount"= "no_accaount",
	"not_applied"= "not_applied",
	"observation_period"= "observation_period",
	"observation_period_description"= "observation_period_description",
	"open_project"= "open_project",
	"overwrite"= "overwrite",
	"pass_change"= "pass_change",
	"pass_dosent_match"= "pass_dosent_match",
	"pass_forget"= "pass_forget",
	"pass_valid_err"= "pass_valid_err",
	"password"= "password",
	"pb_ratio"= "pb_ratio",
	"pdf_icon_hoover"= "pdf_icon_hoover",
	"pe_ratio"= "pe_ratio",
	"peer_group"= "peer_group",
	"peer_group_multiples"= "peer_group_multiples",
	"plan"= "plan",
	"preferred_convertible_share"= "preferred_convertible_share",
	"preferred_share"= "preferred_share",
	"primary_listing_currency"= "primary_listing_currency",
	"profitability"= "profitability",
	"project_desc"= "project_desc",
	"project_ident"= "project_ident",
	"project_members"= "project_members",
	"project_name"= "project_name",
	"public_debt"= "public_debt",
	"public_list"= "public_list",
	"quarterly"= "quarterly",
	"r_square"= "r_square",
	"r_square_description"= "r_square_description",
	"rating"= "rating",
	"rating_indication"= "rating_indication",
	"rating_prop_description"= "rating_prop_description",
	"rating_score"= "rating_score",
	"rating_tool"= "rating_tool",
	"raw_beta"= "raw_beta",
	"read_only"= "read_only",
	"register"= "register",
	"regression_beta_factor"= "regression_beta_factor",
	"regression_index_residual"= "regression_index_residual",
	"regression_residual_residual"= "regression_residual_residual",
	"regression_result"= "regression_result",
	"regression_statistics"= "regression_statistics",
	"reject"= "reject",
	"rename"= "rename",
	"rentability"= "rentability",
	"reporting"= "reporting",
	"reporting_currency"= "reporting_currency",
	"reset"= "reset",
	"reset_filter"= "reset_filter",
	"reset_longlist"= "reset_longlist",
	"return"= "return",
	"return_period"= "return_period",
	"return_period_description"= "return_period_description",
	"return_settings_active"= "return_settings_active",
	"return_settings_best"= "return_settings_best",
	"return_type"= "return_type",
	"return_type_description"= "return_type_description",
	"return_type_price_return"= "return_type_price_return",
	"return_type_total_return"= "return_type_total_return",
	"revenue_growth"= "revenue_growth",
	"revenues"= "revenues",
	"revenues_2"= "revenues_2",
	"risk_premium"= "risk_premium",
	"rounding"= "rounding",
	"rounding_2_digits"= "rounding_2_digits",
	"rounding_footnote"= "rounding_footnote",
	"rounding_idw_from_2016"= "rounding_idw_from_2016",
	"rounding_idw_until_2016"= "rounding_idw_until_2016",
	"rounding_prop_description"= "rounding_prop_description",
	"save"= "save",
	"search"= "search",
	"search_text"= "search_text",
	"sector"= "sector",
	"sector_all"= "sector_all",
	"sector_all_avg"= "sector_all_avg",
	"sector_beta"= "sector_beta",
	"sector_filter"= "sector_filter",
	"sector_filter_description"= "sector_filter_description",
	"sector_multiples"= "sector_multiples",
	"sector_non_specific"= "sector_non_specific",
	"sector_prop_description"= "sector_prop_description",
	"sector_specific"= "sector_specific",
	"sector_sub_sector"= "sector_sub_sector",
	"sector_tracker"= "sector_tracker",
	"sektor_data"= "sektor_data",
	"semi_annually"= "semi_annually",
	"send"= "send",
	"share"= "share",
	"sharetype"= "sharetype",
	"smartsearch_no_result"= "smartsearch_no_result",
	"smartsearch_spinner"= "smartsearch_spinner",
	"sme_multiples"= "sme_multiples",
	"source_idw_bandwidth"= "source_idw_bandwidth",
	"source_kpmg_coc_study"= "source_kpmg_coc_study",
	"spinner_standard"= "spinner_standard",
	"stability_cross_section"= "stability_cross_section",
	"stability_weekday"= "stability_weekday",
	"status_reporting"= "status_reporting",
	"stock"= "stock",
	"stock_exchange"= "stock_exchange",
	"stock_index"= "stock_index",
	"stock_index_indexed"= "stock_index_indexed",
	"stock_market"= "stock_market",
	"stock_market_and_currency"= "stock_market_and_currency",
	"stock_market_and_currency_description"= "stock_market_and_currency_description",
	"stock_market_global"= "stock_market_global",
	"stock_market_local"= "stock_market_local",
	"stock_market_regional"= "stock_market_regional",
	"stock_price"= "stock_price",
	"stock_price_index_price"= "stock_price_index_price",
	"stock_return_average"= "stock_return_average",
	"stock_return_standard_deviation"= "stock_return_standard_deviation",
	"stock_return_standard_deviation_250d"= "stock_return_standard_deviation_250d",
	"stock_return_standard_deviation_30d"= "stock_return_standard_deviation_30d",
	"stock_return_variance"= "stock_return_variance",
	"stock_standard_deviation"= "stock_standard_deviation",
	"subsector"= "subsector",
	"suppliers"= "suppliers",
	"system_info"= "system_info",
	"system_information"= "system_information",
	"systematic_risk_share"= "systematic_risk_share",
	"tax_paid"= "tax_paid",
	"tax_rate"= "tax_rate",
	"tax_rate_prop_description"= "tax_rate_prop_description",
	"tax_shield"= "tax_shield",
	"tax_shield_non_risky"= "tax_shield_non_risky",
	"tax_shield_non_risky_effective_cash"= "tax_shield_non_risky_effective_cash",
	"tax_shield_non_risky_effective_expense"= "tax_shield_non_risky_effective_expense",
	"tax_shield_non_risky_statutory"= "tax_shield_non_risky_statutory",
	"tax_shield_risky"= "tax_shield_risky",
	"tax_shield_prop_2_description"= "tax_shield_prop_2_description",
	"tax_shield_prop_description"= "tax_shield_prop_description",
	"template_name_benchmarking"= "template_name_benchmarking",
	"template_name_beta_factors"= "template_name_beta_factors",
	"template_name_credit_spreads"= "template_name_credit_spreads",
	"template_name_credit_spreads_sectors"= "template_name_credit_spreads_sectors",
	"template_name_multiples"= "template_name_multiples",
	"template_name_transfer_pricing"= "template_name_transfer_pricing",
	"template_name_valuation_pro"= "template_name_valuation_pro",
	"term_in_years"= "term_in_years",
	"thursday"= "thursday",
	"time_period_point"= "time_period_point",
	"time_series"= "time_series",
	"time_series_update"= "time_series_update",
	"to"= "to",
	"total_beta"= "total_beta",
	"trading_days"= "trading_days",
	"trading_days_description"= "trading_days_description",
	"trading_multiples"= "trading_multiples",
	"trading_statistics"= "trading_statistics",
	"trading_volume"= "trading_volume",
	"trading_volume_amount_description"= "trading_volume_amount_description",
	"trading_volume_amount_ratio_market_cap_description"= "trading_volume_amount_ratio_market_cap_description",
	"trading_volume_description"= "trading_volume_description",
	"trading_volume_fx"= "trading_volume_fx",
	"trading_volume_number"= "trading_volume_number",
	"trading_volume_ratio_free_float_description"= "trading_volume_ratio_free_float_description",
	"trading_volumes"= "trading_volumes",
	"trading_volumes_liquidity"= "trading_volumes_liquidity",
	"tradings_days_included"= "tradings_days_included",
	"trailing_multiples_description"= "trailing_multiples_description",
	"transfer_pricing"= "transfer_pricing",
	"tuesday"= "tuesday",
	"type"= "type",
	"unemployment_rate"= "unemployment_rate",
	"unit"= "unit",
	"unlevered_cost_equity"= "unlevered_cost_equity",
	"upper_quartile"= "upper_quartile",
	"valuation_date"= "valuation_date",
	"visualization_autocorrelation"= "visualization_autocorrelation",
	"visualization_cross_section"= "visualization_cross_section",
	"visualization_heteroscedasticity"= "visualization_heteroscedasticity",
	"visualization_normal_distribution"= "visualization_normal_distribution",
	"wacc"= "wacc",
	"website"= "website",
	"wednesday"= "wednesday",
	"weekday_adjustment"= "weekday_adjustment",
	"weekly"= "weekly",
	"weighting"= "weighting",
	"working_capital"= "working_capital",
	"year"= "year",
	"year_abbreviation"= "year_abbreviation",
	"year_number"= "year_number",
	"years"= "years",
	"years_number"= "years_number",
	"yes"= "yes",
	"yield"= "yield",
	"yield_over_time"= "yield_over_time",
	"yield_term_structure"= "yield_term_structure",
	"zero_rates"= "zero_rates",
	"zero_rates_over_time"= "zero_rates_over_time",
	"zero_rates_term_strucutre"= "zero_rates_term_strucutre",
	"actual"= "actual",
	"tax_expenses"= "tax_expenses",
	"tangible_assets"= "tangible_assets",
	"intangible_assets"= "intangible_assets",
	"financial_assets"= "financial_assets",
	"liquid_funds"= "liquid_funds",
	"other_assets"= "other_assets",
	"total_assets"= "total_assets",
	"equity"= "equity",
	"financial_debt"= "financial_debt",
	"pension_deficit"= "pension_deficit",
	"other_liabilities"= "other_liabilities",
	"total_equity_liabilities"= "total_equity_liabilities",
	"continue"= "continue",
	"license_dialog"= "license_dialog",
	"debt_coverage"= "debt_coverage",
	"net_debt_to_ebitda"= "net_debt_to_ebitda",
	"ffo_to_net_debt"= "ffo_to_net_debt",
	"debt_service_cover_ratio"= "debt_service_cover_ratio",
	"interest_coverage"= "interest_coverage",
	"interest_cover_ebitda"= "interest_cover_ebitda",
	"interest_cover_ebit"= "interest_cover_ebit",
	"interest_cover_ffo"= "interest_cover_ffo",
	"gearing_book"= "gearing_book",
	"company_size"= "company_size",
	"account_request"= "account_request",
	"financial_profil"= "financial_profil",
	"business_profil"= "business_profil",
	"credit_profil"= "credit_profil",
	"billion"= "billion",
	"million"= "million",
	"thousand"= "thousand",
	"loan_profil"= "loan_profil",
	"interest_rate"= "interest_rate",
	"seniority_colateral"= "seniority_colateral",
	"changed_at"= "changed_at",
	"volatility"= "volatility",
	"estimates"= "estimates",
	"value"= "value",
	"costofcapitalvisual"= "costofcapitalvisual",
	"recovery_rate"= "recovery_rate",
	"probability_of_default"= "probability_of_default",
	"size_premium"= "size_premium",
	"size_premium_prop_description"= "size_premium_prop_description",
	"companies"= "companies",
	"company_specific_risk_premium"= "company_specific_risk_premium",
	"company_specific_risk_premium_prop_description"= "company_specific_risk_premium_prop_description",
	"rated_companies"= "rated_companies",
	"bond_rated_companies"= "bond_rated_companies",
	"term_loan"= "term_loan",
	"range_credit_spread"= "range_credit_spread",
	"term_loan_prop_description"= "term_loan_prop_description",
	"range_credit_spread_description"= "range_credit_spread_description",
	"australian_curr"= "australian_curr",
	"hongkong_curr"= "hongkong_curr",
	"chinese_curr"= "chinese_curr",
	"norwegian_curr"= "norwegian_curr",
	"swiss_curr"= "swiss_curr",
	"swedish_curr"= "swedish_curr",
	"japanese_curr"= "japanese_curr",
	"british_curr"= "british_curr",
	"canadian_dollar"= "canadian_dollar",
	"other"= "other",
	"range_of_cs_ratio_desc"= "range_of_cs_ratio_desc",
	"bonds_of_companies_desc"= "bonds_of_companies_desc",
	"rest_of_the_world"= "rest_of_the_world",
	"range_warning"= "range_warning",
	"financial_data"= "financial_data",
	"result"= "result",
	"net_debt"= "net_debt",
	"capital_assets"= "capital_assets",
	"return_on_equity"= "return_on_equity",
	"shareholders_equity"= "shareholders_equity",
	"financiaprofile_warning"= "financiaprofile_warning",
	"methodology"= "methodology",
	"long_lived_goods"= "long_lived_goods",
	"short_lived_goods"= "short_lived_goods",
	"services"= "services",
	"infrastructure"= "infrastructure",
	"materials"= "materials",
	"production"= "production",
	"technology"= "technology",
	"consumer"= "consumer",
	"co_name_as_ex"= "co_name_as_ex",
	"longlist_companies"= "longlist_companies",
	"shortlist_from_longlist"= "shortlist_from_longlist",
	"longlist_co_desc"= "longlist_co_desc",
	"shortlist_long_desc"= "shortlist_long_desc",
	"total_score"= "total_score",
	"business_attractiveness"= "business_attractiveness",
	"market_attractiveness"= "market_attractiveness",
	"high"= "high",
	"middle"= "middle",
	"low"= "low",
	"weak"= "weak",
	"strong"= "strong",
	"competitive_position"= "competitive_position",
	"business_risk"= "business_risk",
	"industry_risk"= "industry_risk",
	"business_risk_2"= "business_risk_2",
	"growth_to_gdp"= "growth_to_gdp",
	"average_profitability"= "average_profitability",
	"market_entry_barriers"= "market_entry_barriers",
	"marketshare_development"= "marketshare_development",
	"competitive_advantages"= "competitive_advantages",
	"image_and_awareness_lvl"= "image_and_awareness_lvl",
	"sales_earnings_cyclicality"= "sales_earnings_cyclicality",
	"substitution_risks"= "substitution_risks",
	"supply_chain_risks"= "supply_chain_risks",
	"business_strategy_risk"= "business_strategy_risk",
	"operational_risks"= "operational_risks",
	"governance_risks"= "governance_risks",
	"refund"= "refund",
	"special_termination_rights"= "special_termination_rights",
	"seniority"= "seniority",
	"colateral"= "colateral",
	"refund_rate"= "refund_rate",
	"seniority_and_security"= "seniority_and_security",
	"refund_rate_default"= "refund_rate_default",
	"final"= "final",
	"annuity_table"= "annuity_table",
	"for_creditors"= "for_creditors",
	"for_lenders"= "for_lenders",
	"secured"= "secured",
	"primary"= "primary",
	"subordinate"= "subordinate",
	"second_class_sub"= "second_class_sub",
	"mezzanine"= "mezzanine",
	"above_average"= "above_average",
	"under_average"= "under_average",
	"surcharges_and_fees"= "surcharges_and_fees",
	"premium_loan_vs_bond"= "premium_loan_vs_bond",
	"fees"= "fees",
	"seniority_describtion"= "seniority_describtion",
	"refund_type_desc"= "refund_type_desc",
	"special_termination_right_desc"= "special_termination_right_desc",
	"special_termination_right_desc_2"= "special_termination_right_desc_2",
	"rank_security_desc"= "rank_security_desc",
	"debt_capital_desc"= "debt_capital_desc",
	"notches_seniority_colateral"= "notches_seniority_colateral",
	"primarily_unsecured"= "primarily_unsecured",
	"refund_rate_default_desc"= "refund_rate_default_desc",
	"notches_to_refund"= "notches_to_refund",
	"premium_loan_vs_bond_desc"= "premium_loan_vs_bond_desc",
	"weight"= "weight",
	"outstanding"= "outstanding",
	"subsceptible"= "subsceptible",
	"issue"= "issue",
	"due"= "due",
	"remaining_term"= "remaining_term",
	"beta_unlevered_description"= "beta_unlevered_description",
	"peer_group_2"= "peer_group_2",
}