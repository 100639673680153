import {AxisLabelRange} from "./AxisLabelRange";

export class AxisLabelsAuto {
    get isXAxis(): boolean {
        return this._isXAxis;
    }

    set isXAxis(value: boolean) {
        this._isXAxis = value;
    }
    private readonly availableSpace: number;
    private readonly minValue: number;
    private readonly maxValue: number;
    private yTickStep: number;

    private _isXAxis: boolean = false;

    constructor(minValue: number, maxValue: number, availableSpace: number, yTickStep: number) {
        this.availableSpace = availableSpace;
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.yTickStep = yTickStep;
    }

    public get(fixedDecimal?: number, steps?: number) {
        const calcy = new AxisLabelRange(this.minValue, this.maxValue);
        const d = calcy.getMinMax();
        const distance = d[1] - d[0];
        const inc = this.nice(distance);
        const max_lines = d[0] < 0 ? 8 : 4;
        let useSteps = this.nice(distance);

        while (isFinite(distance / useSteps) &&  distance / useSteps > max_lines) {
            useSteps += inc;
        }

        this.yTickStep = useSteps;
        const factor = this.availableSpace / distance;
        const step = this.yTickStep > 0 ? this.yTickStep : distance / 5;
        const offset = Math.abs(d[0]) * factor;

        const aboveZero: number[] = [];
        const aboveZeroLabel: string[] = [];
        let start: number = 0;
        while (start <= (d[1] )) {
            const currentLabel = this.format(fixedDecimal, start);
            aboveZeroLabel.push(currentLabel);
            if (this._isXAxis) {
                aboveZero.push(Math.trunc((start * factor) + offset));
            } else {
                aboveZero.push(this.availableSpace - Math.trunc((start * factor) + offset));
            }
            start += step;
        }
        const belowZero: number[] = [];
        const belowZeroLabel: string[] = [];
        start = 0 - step ;
        if (d[0] < 0) {
            do {
                const currentLabel = this.format(fixedDecimal, start);
                belowZeroLabel.push(currentLabel);
                if (this._isXAxis) {
                    belowZero.push(Math.trunc((start * factor) + offset));
                } else {
                    belowZero.push(this.availableSpace - Math.trunc((start * factor) + offset));
                }

                start -= step;
            }while (start >= d[0] );
        }

        const labels: string[] = belowZeroLabel.reverse().concat(aboveZeroLabel);
        const values: number[] = belowZero.reverse().concat(aboveZero);

        return {labels, values};
    }
    private nice(a) {
        if (a > 1000) {
            return 100;
        }
        if (a > 100) {
            return 50;
        }
        if (a > 10) {
            return 5;
        }
        if (a > 1) {
            return .5;
        }
        return .25;
    }
    private format(fixedDecimal: number, value: number): string {
        if (!fixedDecimal) {
            return "" + value;
        }
        return "" + Math.trunc(Math.pow(10, fixedDecimal) * value) / Math.pow(10, fixedDecimal);
    }
}
