import {Menu} from "@blueprintjs/core";
import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";

export class GrowthRateCashSurpluseParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        // props.multiColumnsClass = "three-columns";
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.GrowthRateCashSurpluseParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.GrowthRateCashSurpluseParameter);
    }
    protected async getOptions() {
        return [
            {label: "0 %", value: "0"},
            {label: "0,25 %", value: "0.0025"},
            {label: "0,5 %", value: "0.005"},
            {label: "0,75 %", value: "0.0075"},
            {label: "1,0 %", value: "0.01"},
            {label: "1,5 %", value: "0.015"},
            {label: "2,0 %", value: "0.02"},
            {label: "3,0 %", value: "0.03"},
            {label: "4,0 %", value: "0.04"},
            {label: "5,0 %", value: "0.05"},

        ];
    }

    protected renderHint() {
        return (
            <Menu style={{marginTop: 12}}>
                <li>
                    <p className={"bp3-text-small bp3-text-muted"} style={{maxWidth: 300}}>Die Wachstumsrate dient der Ermittlung des barwertäquivalenten Zinssatzes.</p>
                    <p className={"bp3-text-small bp3-text-muted"} style={{maxWidth: 300}}>Je höher die Wachstumsrate gewählt wird, desto größer ist die Gewichtung der Zinssätze größerer Laufzeiten.</p>
                </li>
            </Menu>
        );
    }
}
