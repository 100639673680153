import {Globals} from "../const/Globals";
import {SicClasses} from "../const/Sic";

export class Labels {

    public static getSicLabel(sic_classes, sic_codes){
        if(sic_codes && Array.isArray(sic_codes) && sic_classes && Array.isArray(sic_classes) ) {
            const toRemove = [];
            sic_codes.forEach( (sic4) => {
                const a_s2=Math.trunc(sic4/100);
                sic_classes.forEach((sc)=>{
                    const b_s2=Math.trunc(sc/100);
                    if(b_s2 === a_s2){
                        toRemove.push(sc);
                    }
                });
            });
            while(toRemove.length) {
                const rm = toRemove.shift();
                const idx = sic_classes.indexOf(rm);
                if(idx>=0) {
                    sic_classes.splice(idx, 1);
                }
            }
        }

        if(!sic_classes && !sic_codes){
            return Globals.hyphen;
        }
        if(sic_classes.length===0 && sic_codes.length===0){
            return Globals.hyphen;
        }
        const a = SicClasses.filter( (s) => sic_classes.indexOf(s.code)>=0 );
        const b= [];
        const bb= {};
        sic_codes.forEach( (s) =>{
            const sic3 = Math.trunc(s/10);
            const sic3code = sic3<100 ? `0${sic3}` : `${sic3}`;
            if(!bb[sic3code]){
                bb[sic3code] = true;
                b.push(sic3code);
            }
        });
        const r = [];
        a.forEach( (s) =>{
            const i = Math.trunc(s.code/ 100);
            const sc = i<100 ? `0${i}` : i;
            r.push(sc);
        });
        b.forEach( (s) =>{
            r.push(s);
        });
        return r.filter((s,i)=> i<5).join(", ");
    }

}
