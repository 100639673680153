import {Globals} from "../const/Globals";
import {ChefCalls} from "../services/ChefCalls";

export class Chargebee {
    public static cbInstance;
    public static async setSession(){
        if(!Chargebee.cbInstance){
            console.error("no Chargebee.cbInstance")
            return false;
        }
        try {
            const cb = new ChefCalls();
            const session = await cb.createChargebeeSession();
            if(!session){
                return false;
            }
            Chargebee.cbInstance.setPortalSession(session);
            return true;
        } catch (error) {
            console.error("Error:", error);
        }
        return false;
    }
    public static async openPortal(){
        const okay = await Chargebee.setSession();
        if(!okay){
            return;
        }
        Chargebee.cbInstance.createChargebeePortal().open();
    }
    public static async checkout(project_group: string){
        if(!Chargebee.cbInstance){
            return;
        }
        // <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="CS30-EUR-Monthly" data-cb-item-0-quantity="1" >subscribe</a>
        // <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="TP30-EUR-Monthly" >subscribe</a>
        // <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="VP30-EUR-Monthly" data-cb-item-0-quantity="1" >subscribe</a>
        const product_id = {
            VP: "VP30-EUR-Monthly",
            TP: "TP30-EUR-Monthly",
            CS: "CS30-EUR-Monthly",
        }[project_group];
        if(!project_group){
            return;
        }
        if(!product_id){
            window.alert(`Fehler! Keine Produkt-ID.`);
        }
        const cart =  Chargebee.cbInstance.getCart();
        const product = Chargebee.cbInstance.initializeProduct(product_id, 1);

        cart.replaceProduct(product);
        cart.proceedToCheckout();
    }
}
document.addEventListener("DOMContentLoaded", (event) => {
    const c = Globals.getWindowKey("Chargebee");
    if(c && !Chargebee.cbInstance){
        Chargebee.cbInstance= c.init({
            site: "smart-zebra",
            isItemsModel: true,
        });
    }else{
        console.error("Missing Chargebee");
    }

});
