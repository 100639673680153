import * as React from "react";
import {Button, ButtonGroup} from "@blueprintjs/core";
import {Popover2} from "@blueprintjs/popover2";
import {SzNumeric} from "./SzNumeric";
import {fromNow} from "../../tools/DateTools";

export interface ISzYearsFilterProperties {
    onFinished?(years);
}
export interface ISzYearsFilterState {
    year?: number;
    year_delta?: number;
}

export class SzYearsFilter extends React.Component<ISzYearsFilterProperties, ISzYearsFilterState> {
    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            year: fromNow().year - 1,
            year_delta: 3,
        };
    }
    public static getDefaultYears(): number[]{
        const result: number[] = [];
        const steps = (new Array(3)).fill(0);
        let y = fromNow().year - 1;
        steps.forEach(() =>result.push(y--));
        return result.reverse();
    }

    public render() {
        return (
            <Popover2
                minimal={true}
                position={"bottom-left"}
                usePortal={true}
                popoverClassName={"sz-popover"}
                canEscapeKeyClose={true}
                captureDismiss={false}
                content={this.renderPopup()}
                onClosed={()=> this.onFinished()}
            >
                <Button
                    intent={"primary"}
                    text={this.renderLabel()}
                    minimal={true}
                    small={true}
                    fill={true}
                />
            </Popover2>
        );
    }
    public getYears(): number[]{
        const result: number[] = [];
        const steps = (new Array(this.state.year_delta)).fill(0);
        let y = this.state.year;
        steps.forEach(() =>result.push(y--));
        return result.reverse();
    }
    private renderPopup() {
        const getIntent = (delta)=> {
            if(delta === this.state.year_delta){
                return "primary";
            }else{
                return "none";
            }
        };
        const setDelta = (year_delta)=> {
            this.setState({year_delta});
        };
        const label_width = 100;
        return (
            <div style={{margin: 12, backgroundColor: "#ffffff"}}>
                <div className={"sz-row"}>
                    <div className={"sz-col"} style={{width: label_width, verticalAlign: "middle"}}>
                        Referenzjahr
                    </div>
                    <div className={"sz-col"} style={{width: `calc(100% - ${label_width}px)`}}>
                        <SzNumeric noFormat={true} value={this.state.year} onValueChanged={(v) => this.onYearChanged(v)}  />
                    </div>
                </div>
                <div className={"sz-row"} style={{marginTop: 12}}>
                    <div className={"sz-col"} style={{width: label_width, verticalAlign: "middle"}}>
                        &nbsp;
                    </div>
                    <div className={"sz-col"} style={{display: "flex", justifyContent: "center", width: `calc(100% - ${label_width}px)`}}>
                        <ButtonGroup minimal={false}>
                            <Button intent={getIntent(1)} onClick={ () => setDelta(1) }>ein Jahr</Button>
                            <Button intent={getIntent(5)} onClick={ () => setDelta(5) }>-5</Button>
                            <Button intent={getIntent(3)} onClick={ () => setDelta(3) }>-3</Button>
                        </ButtonGroup>
                    </div>

                </div>
            </div>
        );
    }
    private renderLabel() {
        const y = this.getYears();
        return y.join(", ");
    }
    private onFinished() {
        this.props?.onFinished(this.getYears());
    }

    private onYearChanged(year: number) {
        this.setState({year})
    }
}
