import {Button, Checkbox, Classes, Dialog,} from "@blueprintjs/core";
import * as React from "react";
import {EExcelExportType} from "../../const/EExcelExportType";
import {IExcelExport} from "../../models/IExcelExport";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export interface IDlgExcelExportProps {
    title?: string;
}

interface IDlgExcelExportState {
    showExportDlg?: string;
    exportItems?: IExcelExport[];
    export_items_checked: { };
}

export class DlgExcelExport extends React.Component<IDlgExcelExportProps, IDlgExcelExportState> {

    private readonly eventDlgOpen: IEventHandler;
    constructor(props: any, context: IDlgExcelExportState) {
        super(props, context);

        this.eventDlgOpen = EventBus.subscribe<{dialog_key: string, items: IExcelExport[] }>("ExcelDialog:open", (data) => this.showExcelDialog(data.dialog_key, data.items));
        this.state = {
            showExportDlg: "null",
            export_items_checked: {},
            exportItems: [],
        };
    }

    public componentWillUnmount() {
        EventBus.unsubscribe(this.eventDlgOpen);
    }

    public render() {
        return (
            <div>
                {this.renderExportBeta()}
            </div>
        );
    }

    private renderExportBeta() {
        const setState = (name) => {
            const s = this.state.export_items_checked;
            s[name] = !s[name];
            this.setState( s );
        };
        return (
            <Dialog canOutsideClickClose={false} usePortal={true} title={"Beta-Faktoren Export"} isOpen={this.state.showExportDlg === `export_dlg_${EExcelExportType.beta}`} onClose={ () => this.setState({showExportDlg: "null"}) }>
                <div className={Classes.DIALOG_BODY} style={{margin: 0, marginLeft: 20, marginBottom: 10}}>
                    <h3>Datenblätter</h3>
                    {this.state.exportItems.map( (i) => (<Checkbox checked={this.state.export_items_checked[i.name]} label={i.name} onChange={() => setState(i.name) } />) )}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => this.setState({showExportDlg: "null"}) }>{_t(ETranslation.cancel)}</Button>
                        <Button intent={"primary"} onClick={() => this.excelExport()} style={{minWidth: 110}}>
                            Start
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
    private showExcelDialog(dialog_key: string, items: IExcelExport[]) {
        const s = this.state.export_items_checked;
        items.forEach( (i) => s[i.name] = true );
        this.setState({
            showExportDlg: dialog_key,
            exportItems: items,
            export_items_checked: s,
        });
    }

    private excelExport() {
        const data = [];
        this.state.exportItems.forEach( (i) => {
            if (this.state.export_items_checked[i.name]) {
                data.push(i);
            }
        } );
        this.setState({
            showExportDlg: "null",
            exportItems: [],
            export_items_checked: {},
        });
        EventBus.emit("Excel.make", data);
    }
}
