import {Button, Icon, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {TdHTMLAttributes, ThHTMLAttributes} from "react";
import {Globals} from "../../const/Globals";
import * as Statistics from "../../helpers/Statistics";
import {average} from "../../helpers/Statistics";
import {EParameters} from "../../models/EParameters";
import {ICompany} from "../../models/ICompanyDetails";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {BaseModule} from "./BaseModule";
import {ISzTableColumn, ISzTableProperties} from "../widgets/SzTable";
import {TasksCalls} from "../../services/TasksCalls";
import {EModuleNames} from "../../models/EModuleNames";
import {SessionStore} from "../../const/SessionStore";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

const _f = (v) => {
    const ff = parseFloat(v);
    if (isNaN(ff) || !v) {
        return 0;
    }
    return ff;
};
const getFlag = (desc, company: ICompany) => {
    const r = Globals.country_map[company.country_id];
    return (
        <div style={{display: "flex", height: "100%", justifyContent: "center"}}>
            <img src={`${Globals.BASE_DIR}images/flags/${r.iso_2.toLocaleLowerCase()}.jpg`} style={{maxHeight: 20}}/>
        </div>
    );
};
const getField = (desc, company: ICompany) => {
    let v = company[desc.field];
    if(desc.alt_field && !v){
        v=company[desc.alt_field];
    }
    return (
        <div className={"td-wrapper"}><span className={"td-content"}>{v}</span></div>
    );
};
const getCountry = (desc, company: ICompany) => {
    const c = Globals.country_map[company.country_id];
    return c.iso_3;
};


export class TransferPricingShortList extends BaseModule<any> {

    private readonly evtUpdateRejected: IEventHandler;


    private table_header = [
        {label: _t(ETranslation.company), width: 35, colSpan: 2, textAlign: "left", borderRight: "4px #ffffff solid"},
    ];
    private table_header_2 = [
        {label: "#", width: 35, content: getField, field: "i"},
        {label: "Name", content: getField, field: "co_name", alt_field: "name", borderRight: "4px #ffffff solid"},
    ];

    private tableProps: ISzTableProperties<any>;

    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
        };
        this.evtUpdateRejected = EventBus.subscribe("TP::update-rejected", (data) => this.onUpdateRejected(data));
    }
    componentWillUnmount() {
        EventBus.unsubscribe(this.evtUpdateRejected);
    }
    public async getDocumentationData() {
        return this.tableProps;
    }
    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.tableProps);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected renderContent() {
        return (<div>{this.data && Array.isArray(this.data.long_list) && this.data.long_list.length ? this.renderData() : this.noData()}</div>);
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected renderData() {
        return (
            <div className={"sz-row"} style={{paddingTop: 16}}>
                <div style={{width: "calc(100% - 15px)", top: 0, zIndex: 10, backgroundColor: "#ffffff"}}>{this.renderHeader()}</div>
                <div style={{boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)", width: "100%", marginTop: 5, position: "relative", height: "calc(100vh - 320px)", overflowY: "scroll", paddingRight: 1}}>{this.renderCompanies()}</div>
            </div>
        );
    }
    protected noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }
    protected onAfterUpdate(data: any) {
        const frozen = Globals.currentModule === EModuleNames.TransferPricing && SessionStore.getItem(EParameters.TpFreezeLongList, false);
        let long_list = data.long_list;
        let financials = data.financials;
        const rejected = SessionStore.getItem(EParameters.TpRejectedAbsolut, {});
        if(frozen){
            long_list = SessionStore.getItem(EParameters.TpLongList, long_list);
            financials = SessionStore.getItem(EParameters.TpFinancials, financials);
        }
        this.data = data;
        this.setState({
            long_list,
            financials,
            loading: false,
            rejected,
        });
    }
    private onUpdateRejected(data: any) {
        if(!this.state.rejected){
            return;
        }
        // {id: company.id, rejected: boolean}
        const rejected = this.state.rejected;
        rejected[data.id] = data.rejected;
        this.setState({
            rejected,
        });
    }
    private getYears(): number[]{
        const year = this.getParameterValue(EParameters.TpYearsParameter);
        const result: number[] = [];
        const steps = (new Array(3)).fill(0);
        let y: number = year;
        steps.forEach(() =>result.push(y--));
        return result.reverse();
    }
    private updateHeader(){
        const w = 60;
        const table_header = [].concat(this.table_header);
        const table_header_2 = [].concat(this.table_header_2);

        const years = this.getYears();
        // console.error(years);
        years.forEach( (year, idx) => {
            table_header.push({label: `${_t(ETranslation.financial_year)} ${year}`, colSpan: 4, textAlign: "center"});
            table_header_2.push({label: `Umsatz`, year, fnk: "get_sales", field: `ff_sales_${idx +1}`, width: w, textAlign: "right"});
            table_header_2.push({label: `Op. Aufw.`, year, fnk: "get_costs", field: `costs_${idx +1}`, width: w, textAlign: "right"});
            table_header_2.push({label: `Op. Ergebnis`, year, fnk: "get_op_income", field: `income_${idx +1}`, width: w, textAlign: "right"});
            table_header_2.push({label: `Marge %`, year, fnk: "get_sales_cost_prc", field: `marge_${idx +1}`, width: w, borderRight: "4px #ffffff solid", textAlign: "right"});
        });

        table_header.push({label: `${years[0]}-${(""+years[2]).slice(-2)}`, textAlign: "right"});
        table_header_2.push({label: `Marge %`, year: -1,field: "all_marge", fnk: "get_avg_sales_cost_prc", width: w, textAlign: "right"});

        return {table_header, table_header_2};
    }
    private renderHeader() {
        const renderCell = (desc, small?) => {
            const colSpan = desc.colSpan;
            const fontSize = small ? "80%" : undefined;
            const props: ThHTMLAttributes<any> = {
                style: {width: desc.width, position: "sticky", padding: 2, fontSize, textAlign: desc.textAlign, borderRight: desc.borderRight},
                colSpan,
            };
            return <th {...props}>{desc.label}</th>;
        };
        const {table_header, table_header_2} = this.updateHeader();
        return (
            <table className={"bp3-html-table bp3-html-table-condensed bp3-interactive bp3-small"} >
                <thead>
                <tr>
                    {table_header.map((i) => renderCell(i))}
                </tr>
                <tr>
                    {table_header_2.map((i) => renderCell(i, true))}
                </tr>
                </thead>
            </table>
        );
    }
    private renderCompanies() {
        const {table_header, table_header_2} = this.updateHeader();
        const years = this.getYears();
        const financials = this.state.financials;
        const rejected = this.state.rejected;
        const long_list = this.state.long_list;
        const composition = this.getParameterValue(EParameters.TpMarginComposition);
        const op_result = this.getParameterValue(EParameters.TpOpResult);
        const use_free_defined = op_result === "free_definition";
        const results = {};

        const hg_footer = 11;
        // console.error(financials);
        // console.error(composition);

        const get_f = (c, year) => {
            const f_year = financials[year];
            if(!f_year){
                return undefined;
            }
            const f = f_year[c.id];
            return f;
        }
        const pre = (c) => {
            if(!results[c.id]){
                results[c.id] ={};
            }
            return (ff, fv) => {results[c.id][ff] = fv};
        };
        const aft = (c, ff) => {
            if(results[c.id]){
                return results[c.id][ff];
            }
            return undefined;
        };
        const sum_composition = (fin_data) => {
            let sum = 0;
            composition.forEach( (field) => {
                sum += _f(fin_data[field]);
            });
            // console.error(sum);
            return sum;
        };
        const calls= {
            get_sales:(c, desc)=> {
                const f = get_f(c, desc.year);
                if(!f){
                    return Globals.hyphen;
                }
                pre(c)(desc.field, f.ff_sales);
                return Globals.formatter(f.ff_sales, 0,0, true);
            },
            get_costs:(c, desc)=> {
                const f = get_f(c, desc.year);
                if(!f){
                    return Globals.hyphen;
                }
                let v: number;
                if(use_free_defined){
                    v = sum_composition(f);
                }else{
                    v = (f.ff_sales - f[op_result]);
                }
                pre(c)(desc.field, (-1) * v);
                return Globals.formatter((-1) * v, 0,0, true);
            },
            get_op_income: (c, desc) => {
                const f = get_f(c, desc.year);
                if(!f){
                    return Globals.hyphen;
                }

                let v: number;
                if(use_free_defined){
                    v = (f.ff_sales - sum_composition(f));
                }else{
                    v = f[op_result];
                }

                pre(c)(desc.field, v);
                return Globals.formatter(v, 0,0, true);
            },
            get_sales_cost_prc:(c, desc)=> {
                const f = get_f(c, desc.year);
                if(!f){
                    return Globals.hyphen;
                }
                if(f.ff_sales === 0){
                    return Globals.hyphen;
                }

                let v: number;
                if(use_free_defined){
                    v = (f.ff_sales - sum_composition(f)) / f.ff_sales;
                }else{
                    v = f[op_result] / f.ff_sales;
                }
                pre(c)(desc.field, v * 100);
                return Globals.formatter(v * 100, 2,2, true);
            },
            get_avg_sales: (c, desc) => {
                const r = results[c.id];
                if(!r){
                    return Globals.hyphen;
                }
                const ar = [r.ff_sales_1, r.ff_sales_2, r.ff_sales_3];
                pre(c)(desc.field, average(ar));
                return Globals.formatter(average(ar), 0,0);
            },
            get_avg_costs: (c, desc) => {
                const r = results[c.id];
                if(!r){
                    return Globals.hyphen;
                }
                const ar = [r.costs_1, r.costs_2, r.costs_3];
                pre(c)(desc.field, average(ar));
                return Globals.formatter((-1) * average(ar), 0,0);
            },
            get_avg_sales_cost_prc: (c, desc) => {
                const r = results[c.id];
                if(!r){
                    return Globals.hyphen;
                }
                const ar = [r.marge_1, r.marge_2, r.marge_3];
                pre(c)(desc.field, average(ar));
                return Globals.formatter(average(ar), 2,2);
            },
            get_avg_op_income: (c, desc)=>{
                const r = results[c.id];
                if(!r){
                    return Globals.hyphen;
                }
                const ar = [r.income_1, r.income_2, r.income_3];
                pre(c)(desc.field, average(ar));
                return Globals.formatter(average(ar), 0,0);
            }
        };

        const result = {};
        const raw_data = [];
        const transfer_data = [];
        const renderCompany = (c: ICompany, idx) => {
            if(rejected[c.id]){
                return null;
            }
            const data_row = [];
            transfer_data.push([c.id, c.co_name]);
            raw_data.push(data_row);
            const renderCell = (desc) => {
                const boxShadow = idx === 0 ? "none" : undefined;
                const textAlign = desc.textAlign;
                const borderRight = desc.borderRight;
                const props: TdHTMLAttributes<any> = {
                    style: {width: desc.width, padding: 2, overflow: "hidden", textOverflow: "ellipsis", boxShadow, textAlign, borderRight},
                };

                if(!desc.content){
                    if(desc.fnk){
                        const r = calls[desc.fnk](c, desc);
                        data_row.push(aft(c, desc.field));
                        return <td {...props}>{r}</td>;
                    }
                    data_row.push("");
                    return <td {...props}>&nbsp;</td>;
                }
                data_row.push(c[desc.field]);
                return <td {...props}>{desc.content(desc, c)}</td>;
            };
            return (
                <tr>
                    {table_header_2.map( (th) => renderCell(th) )}
                </tr>
            );
        }

        // {label: "Mittelwert", fnk: Statistics.mean, fontWeight: "bold", colSpan: 2},
        const footer = [
            {label: _t(ETranslation.minimum), fnk: Statistics.min, colSpan: 2},
            {label: _t(ETranslation.lower_quartile), fnk: Statistics.quantile, param: .25, colSpan: 2},
            {label: _t(ETranslation.median), fnk: Statistics.median, fontWeight: "bold", colSpan: 2},
            {label: _t(ETranslation.upper_quartile), fnk: Statistics.quantile, param: .75, colSpan: 2},
            {label: _t(ETranslation.maximum), fnk: Statistics.max, colSpan: 2},
        ];
        const def_footer = ("ff_sales_1,costs_1,income_1,marge_1,ff_sales_2,costs_2,income_2,marge_2,ff_sales_3,costs_3,income_3,marge_3").split(",");
        const add_columns = ("all_marge").split(",");
        const footer_columns = def_footer.concat(add_columns);
        const table_footer: ISzTableColumn[] = [
            {index: "0", options: {colSpan: 2}},
        ];
        footer_columns.forEach((key, i) => {
            let fractionDigits =  0;
            if(key.indexOf("marge")>=0){
                fractionDigits = 2;
            }
            const c: ISzTableColumn = {
                index: `${i + 1}`,
                options: {style: {textAlign: "right"}, className: "sz-number", fractionDigits},
            };
            table_footer.push(c);
        });
        const footer_data = [];
        const renderFooterRow = (columns, f, row_num) => {
            const foot = [f.label];
            footer_data.push(foot);
            const renderCell = (field, column_idx) => {
                const textAlign = "right";
                const borderRight = table_header_2[column_idx + 2].borderRight;
                const props: TdHTMLAttributes<any> = {
                    style: {width: f.width, padding: 2, fontWeight: f.fontWeight,overflow: "hidden", textOverflow: "ellipsis", textAlign, borderRight},
                };
                const v = f.fnk(columns[field], f.param);
                if(field.indexOf("marge")>=0){
                    if(!result[f.label]){
                        result[f.label] = {
                            fontWeight: f.fontWeight,
                            columns: [],
                        };
                    }
                    result[f.label].columns.push(v);
                    const ff = Globals.formatter(v,2,2);
                    foot.push(v);
                    return <td {...props}>{ff}</td>;
                }
                foot.push("");
                return <td {...props}>&nbsp;</td>;
            };
            return (
                <tr className={"sz-ignore"}>
                    <td colSpan={f.colSpan} style={{fontWeight: f.fontWeight, padding: 2,borderRight: "4px solid rgb(255, 255, 255)"}}>{f.label}</td>
                    {footer_columns.map((field, column_idx) => renderCell(field, column_idx))}
                </tr>
            );
        };
        const renderFooter = () => {
            // console.error(results);
            const columns = {};
            Object.keys(results).forEach( (company_id) => {
                const items = results[company_id];
                Object.keys(items).forEach( (field)=>{
                    if(!columns[field]){
                        columns[field] = [];
                    }
                    columns[field].push(items[field]);
                });
            });

            return (
                <tfoot style={{boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)"}}>
                    {footer.map( (f, row_num) => renderFooterRow(columns, f, row_num))}
                </tfoot>
            );
        };
        const company_table = long_list.map( (c, idx) => renderCompany(c, idx) );
        const footer_table = renderFooter();
        EventBus.emit("TP::result", {result, data: transfer_data, results});

        // const {table_header, table_header_2} = this.updateHeader();
        // {label: "Unternehmen", width: 35, colSpan: 2, textAlign: "left", borderRight: "4px #ffffff solid"},
        const header_0: ISzTableColumn[] = [];
        const header_1: ISzTableColumn[] = [];
        table_header.forEach( (h, i) => {
            const c: ISzTableColumn = {
                text: h.label,
                options: {colSpan: h.colSpan, style: {width: h.width, textAlign: h.textAlign}},
            };
            header_0.push(c);
        });
        table_header_2.forEach( (h, i) => {
            const c: ISzTableColumn = {
                text: h.label,
                options: {colSpan: h.colSpan, style: {width: h.width, textAlign: h.textAlign}},
            };
            header_1.push(c);
        });
        const table_columns: ISzTableColumn[] = [];
        table_header_2.forEach( (h, i) => {
            const className = i > 1 ? "sz-number" : "";
            let fractionDigits =  i > 1 ? 0 : undefined;
            if(h.label === "Marge %"){
                fractionDigits = 2;
            }
            const c: ISzTableColumn = {
                index: `${i}`,
                options: {colSpan: h.colSpan, style: {width: h.width, textAlign: h.textAlign}, className, fractionDigits},
            };
            table_columns.push(c);
        });
        this.tableProps = {
            colCount: header_1.length,
            header: [
                header_0,
                header_1,
            ],
            columns: table_columns,
            footer: table_footer,
            footerData: footer_data,
            data: raw_data,
        };
        return (
            <table style={{fontSize: "80%", tableLayout: "fixed"}} className={"sz-table bp3-html-table bp3-html-table-condensed bp3-html-table-striped bp3-small sz-cell-overflow"}>
                <tbody>
                {company_table}
                </tbody>
                {footer_table}
            </table>
        );
    }
    private renderContact() {
        return (
            <div>
                <div className={"sz-loco sz-loco-big"} style={{alignItems: "center"}}>
                    <div  className={"sz-loco-icon"}><Icon icon={IconNames.ENVELOPE} color={"#5c7080"} iconSize={48} /></div>
                    <div className={"sz-loco-text"}><div style={{textAlign: "center"}}>Zum Thema <strong>Transfer-Pricing</strong> setzen Sie sich bitte mit uns in Verbindung.</div></div>
                    <div style={{margin: 32}}>
                        <Button icon={IconNames.ENVELOPE} intent={"primary"} text={"Kontakt Formular"} onClick={() => window.open("https://www.smart-zebra.de/kontakt/") } />
                    </div>
                </div>
            </div>
        );
    }
}
