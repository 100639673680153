import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";

export class CashflowParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CashflowParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.CashflowParameter);
    }
    protected async getOptions() {
        return [
            {label: "CAPEX in % Umsatzerlöse", value: "capex_sales_ratio"},
            {label: "CAPEX in % Anlagevermögen", value: "capex_capitalasset_ratio"},
            {label: "Net Working Capital in % Umsatzerlöse", value: "working_sales_ratio"},
        ];
    }
}
