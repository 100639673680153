import {Icon, Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {ETranslation} from "../../../const/ETranslation";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {Regions} from "../../../const/Regions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {PublicDbCalls} from "../../../services/PublicDbCalls";
import {_t} from "../../../tools/Translator";
import {CombiParameter} from "../CombiParameter";
import {IconNames} from "@blueprintjs/icons";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";

const COMPANY_LIMIT: number = 0;
const BASE_DIR = Globals.BASE_DIR;
const regions_2: IExtOptionEntry[] = [
    {label: "DACH-Region", value: "771,705,901", selected: false, data: { flags: ["de", "at", "ch"] }},
    {label: "Eurozone", value: "712,771,759,764,765,774,794,797,809,815,816,824,843,705,865,882,883,889,748", selected: false, data: { flags: ["eu-01"] }},
    {label: "EU-Staaten", value: "712,725,750,771,759,764,765,774,797,794,745,809,815,816,824,843,705,864,865,869,900,883,882,889,749,788,748", selected: false, data: { flags: ["eu-02"] }},
    {label: "OECD", value: "771,750,764,730,744,765,794,800,704,830,816,788,739,845,712,705,774,809,815,796,789,900,853,797,901,735,759,913,864,865,889,883,843,920,921,888,749,882", selected: false, data: { flags: ["oecd-4"] }},
];
interface ICountry {
    iso_3: string;
    iso_2: string;
    name: string;
    region: string;
    companies: number;
    id: number;
}
interface IExtOptionEntry extends IOptionEntry {
    region?: string;
}

export class TpCountriesParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getWidthClass() {
        // return "fixed-width-550";
        return undefined;
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.TpCountriesParameter];
    }
    protected onClosePopup(){
        const country_ids = this.state.countries.filter((c) => c.selected).map((c) => c.value);
        // console.error("onCountriesChanged", country_ids);
        this.fireValueChange(EParameters.TpCountriesParameter, country_ids);
        this.fireValueChange(EParameters.TpCountriesAllParameter, this.state.all);
    }
    protected async initialize() {
        const {selected_regions, countries} =  await this.loadCountries(SessionStore.getItem(EParameters.TpCountriesParameter));
        const regions = this.getAllRegions(selected_regions);

        const all = SessionStore.get(EParameters.TpCountriesAllParameter);
        let country_ids = SessionStore.get(EParameters.TpCountriesParameter);
        if(all){
            country_ids = [];
            countries.forEach( (c) =>{
                c.selected = true;
                country_ids.push(c.value);
            });
            regions.forEach( (r)=> r.selected=true );
        }
        return {
            country_ids,
            all,
            loading: true,
            regions,
            regions2: regions_2,
            countries,
            isOpen: false,
            cc_count: 0,
        };
    }
    protected resetFilter() {
        if (!this.state.all) {
            this.changeAll();
        }
    }

    protected  getIcon(f: boolean) {
        if (f) {
            return <Icon icon={"tick"}/>;
        } else {
            return <Icon icon={IconNames.BLANK}/>;
        }
    }
    protected changeOthers(stateField: string, region: string, selectFlag: boolean) {
        const elements: IExtOptionEntry[] = this.state[stateField];
        if (!elements) {
            return;
        }
        elements.forEach( (o) => {
            if (o.region === region) {
                o.selected = selectFlag;
            }
        } );
    }
    protected setAll(elements: IExtOptionEntry[], selectFlag: boolean): IExtOptionEntry[] {
        elements.forEach( (o) => o.selected = selectFlag );
        return elements;
    }
    protected changeAll() {
        const select = !this.state.all;
        const all = select;
        const regions = this.setAll(this.state.regions, select);
        // const regions2 = this.setAll(this.state.regions2, select);
        const countries = this.setAll(this.state.countries, select);

        this.setState({
            all,
            regions,
            countries,
        });
        // this.onClosed(all);
    }
    protected updateAll(stateField: string) {
        const options: IExtOptionEntry[] = this.state[stateField];
        const all = options.reduce((acc, current) => acc && current.selected, true);
        const state = {
            all: false,
        };
        state[stateField] = options;
        state.all = all;
        this.setState(state);
        // this.onClosed(all);
    }
    protected onChange(stateField: string, option: IExtOptionEntry, selectUnselectOther: string): boolean {
        const options: IExtOptionEntry[] = this.state[stateField];
        const item = options.find( (o) => o.value === option.value);
        if (!item) {
            return false;
        }
        item.selected = !item.selected;
        this.changeOthers(selectUnselectOther, item.region, item.selected);
        return true;
    }
    protected renderRegions2() {
        const onClickR2 = (option: IExtOptionEntry) => {
            const regions2: IExtOptionEntry[] = this.state.regions2;
            const regions: IExtOptionEntry[] = this.state.regions;
            const item = regions2.find( (o) => o.value === option.value);

            if (!item) {
                return false;
            }
            // item.selected = !item.selected;
            const countryIds = item.value.split(",");
            const countries = this.state.countries;
            countries.forEach( (o) => {
                o.selected = false;
                this.changeOthers("regions", o.region, false);
            });
            countries.forEach( (o) => {
                if (countryIds.indexOf(o.value) >= 0) {
                    o.selected = true;
                }
            });

            this.updateAll("countries");
            /*
            this.setState({
                countries,
                regions,
            });
            */
            return null;
        };
        const imgStyle: CSSProperties = {
            maxHeight: 20,
            paddingRight: 4,
        };
        const renderFlags = (s: string[]) => {
            return (
                <div style={{height: 20, maxHeight: 20, display: "flex"}}>
                    {s.map( (i) => (<img style={imgStyle} src={`${BASE_DIR}images/flags/${i}.jpg`}/>))}
                </div>);
        };

        let cKey = 0;
        return this.state.regions2.map((o) =>
            <MenuItem shouldDismissPopover={false} key={"regions2" + (cKey++)} intent={ o.selected ? "primary" : "none"} labelElement={renderFlags(o.data.flags)}  text={o.label} onClick={ () => onClickR2(o) } />,
        );
    }
    protected renderRegions() {
        let cKey = 0;
        return this.state.regions.map((o) =>
            <MenuItem shouldDismissPopover={false} key={"item" + (cKey++)} intent={ o.selected ? "primary" : "none"} labelElement={this.getIcon(o.selected)}  text={o.label} onClick={() => { this.onChange("regions", o, "countries"); this.updateAll("regions"); }} />,
        );
    }
    protected renderCountries(flagRenderDivider, countries: IExtOptionEntry[]) {
        let cKey = 0;
        if (!countries) {
            return null;
        }
        const renderDivider = () => {
            if (!flagRenderDivider) {
                return <MenuDivider title={"|"} className={"no-text-color"} />;
            }
            let sum_selected = 0;
            let all_cou = 0;
            this.state.countries.forEach( (o) => {
                if (o.selected) {
                    sum_selected += o.data.count_companies;
                }
                all_cou += o.data.count_companies;
            } );
            return <MenuDivider title={_t(ETranslation.companies_of_max, Globals.formatter(sum_selected, 0), Globals.formatter( all_cou, 0))} />;
        };
        return (
            <Menu>
                {renderDivider()}
                {countries.map((o) =>
                    <MenuItem shouldDismissPopover={false} key={"item" + (cKey++)} intent={ o.selected ? "primary" : "none"} labelElement={this.getIcon(o.selected)}  text={o.label} onClick={() => { this.onChange("countries", o, "regions"); this.updateAll("countries"); }} />,
                )}
            </Menu>
        );
    }
    protected renderMenuContent(): any {
        const len = 19 ;
        const w = this.state.countries && this.state.countries.length ? this.state.countries.slice(0) : [];
        const countries = [];
        while ( w.length ) {
            countries.push(w.splice(0, len));
        }
        return this.renderEntries(countries);
    }
    private async loadCountries(country_ids) {
        const selected_regions = {};
        const countries = [];
        const db = new PublicDbCalls();
        const isSelected = (id) => {
            if(country_ids){
                return country_ids.indexOf(id) >= 0;
            }else{
                return true;
            }
        };
        const response = await db.getCountries();
        try {
            const result = response.result;
            if (Array.isArray(result)) {
                let sum = 0;
                result.forEach((e) => {
                    const n = parseInt(e[4], 10);
                    if (n > COMPANY_LIMIT) {
                        sum += n;
                        const sel = isSelected(e[5]);
                        if(sel){
                            selected_regions[e[3]] = true;
                        }
                        countries.push({
                            selected: sel,
                            value:  e[5],
                            label:  `${e[2]} [${Globals.formatter(n, 0)}]`,
                            region: e[3],
                            data: {count_companies: n, name: e[2], iso_3: e[0]},
                        });
                    }
                });
                countries.sort( (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
            }
        } catch (exi) {
            console.error(exi, response);
        }

        return {selected_regions, countries};
    }
    private getAllRegions(sel_erg): IExtOptionEntry[] {
        const regions: IExtOptionEntry[] = [];
        Regions.forEach((r) => {

            const s = sel_erg[r];

            regions.push({
                label: r,
                value: r,
                region: r,
                selected: s,
            });
        });
        return regions;
    }

    private renderEntries(countries) {
        return (
            <div>
                <div className={"sz-row"}>
                    <div className={"sz-row min-li-width"}>
                        <Menu>
                            <MenuItem shouldDismissPopover={false} key={"itemAlle"} intent={ this.state.all ? "primary" : "none"} labelElement={this.getIcon(this.state.all)}  text={"Alle"} onClick={() => { this.changeAll(); }} />
                            <MenuDivider/>
                            {this.renderRegions()}
                            <MenuDivider/>
                            {this.renderRegions2()}
                        </Menu>
                        {countries.map( (c, idx) => this.renderCountries(idx === 0, c) )}
                    </div>
                </div>
                <div className={"sz-row"}>
                    {this.renderHelpText(PARAMETER_HELP(EParameters.TpCountriesParameter), undefined, "100%")}
                </div>
            </div>
        );
    }

    public getLabel() {
        if(this.state.all){
            return "Keine Einschränkung";
        }
        const selected = [];
        let mx = 0;
        this.state.countries.forEach((c) => {
            if(!c.selected){
                return;
            }
            if(selected.length>4){
                mx++;
                return;
            }
            selected.push(c.data.iso_3);
        });
        if(selected.length === 0){
            return `Kein Land gewählt`;
        }
        if(mx===0){
            return `${selected.join(", ")}`;
        }else{
            if(mx===1){
                return `${selected.join(", ")} und ein weiteres`;
            }else{
                return `${selected.join(", ")} und ${mx} weitere`;
            }
        }
    }
}
