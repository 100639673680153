import {ISzTableProperties} from "../components/widgets/SzTable";
import {EServiceType, ServiceCallBase} from "./ServiceCallBase";

enum ETaskCalls {
    "excel" = "excel",
    "excel_map" = "excel_map",
    "excel_beta" = "excel_beta",
    "pdf" = "pdf",
}
export class TasksCalls extends ServiceCallBase {
    constructor() {
        super(EServiceType.Tasks);
    }

    public execTask(id: string) {
        super.open(id);
    }

    public async createExcelTask(data: ISzTableProperties<any[]>) {
        const snd = {
            type: ETaskCalls.excel,
            data,
        };
        return super.callOut(`/create`, snd);
    }
    public async createBetaExportTask(data: any) {
        const snd = {
            type: ETaskCalls.excel_beta,
            data,
        };
        return super.callOut(`/create`, snd);
    }
    public async createExcelMapTask(data: ISzTableProperties<any[]>[]) {
        const snd = {
            type: ETaskCalls.excel_map,
            data,
        };
        return super.callOut(`/create`, snd);
    }
    public async createPdfTask(data: any[]) {
        const snd = {
            type: ETaskCalls.pdf,
            data,
        };
        return super.callOut(`/create`, snd);
    }
}
