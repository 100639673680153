import {INopeAction} from "../models/INopeAction";

export class NopeActions {

    public static register: INopeAction[] = [
        {caption: "Registrieren", url: "/kundenkonto"},
    ];

    public static beta: INopeAction[] = [
        {caption: "Beta-Faktoren", url: "/beta-faktor"},
        {caption: "Valuation Pro", url: "/valuation-pro"},
    ];
    public static elite: INopeAction[] = [
        {caption: "Valuation Pro", url: "/valuation-pro"},
    ];
    public static multiplier: INopeAction[] = [
        {caption: "Multiplikatoren", url: "/multiplikatoren"},
        {caption: "Valuation Pro", url: "/valuation-pro"},
    ];
    public static multiplier_world: INopeAction[] = [
        {caption: "Multiplikatoren WORLD", url: "/multiplikatoren"},
        {caption: "Valuation Pro", url: "/valuation-pro"},
    ];
    public static transfer_pricing: INopeAction[] = [
        {caption: "Transfer Pricing", url: "/transfer-pricing"},
    ];
    public static credit_spreads: INopeAction[] = [
        {caption: "Kreditaufschläge", url: "/desk-kreditaufschlaege"},
    ];
    public static contact: INopeAction[] = [
        {caption: "Anfrage senden", url: "/kontakt"},
    ];
}
