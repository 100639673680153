import * as countries from "../translations/countries.json";
import * as de from "../translations/de.json";
import * as en from "../translations/en.json";
import {ETranslation} from "../const/ETranslation";
import {Globals} from "../const/Globals";
import * as sectors from "../translations/sectors.json";
import * as sic_text from "../translations/sic_translation.json";
import {Sic, Sic3, SicClasses, SicDefinitions, SicRanges} from "../const/Sic";

const locales = {
    de,
    en,
};
let locale = navigator.language.split("-")[0];
const lang_key = localStorage.getItem("language");
if(lang_key){
    locale = lang_key;
}
const setLocale = (l: string)=> locale = l;
const getLocale = ()=> locale;
const available_locales = {
    de: "Deutsch",
    en: "English",
};

const lang = sectors[0][locale] ? locale : "en";
const CURRENT_LANG = lang;
const country_map: {[iso3: string]: {name: string, currency_iso_3: string}} = {};
countries.forEach((c)=>{
    country_map[c.code] = {
        name: c[lang],
        currency_iso_3: c.currency_iso_3,
    };
});

// sic texte
SicDefinitions.sic.forEach((c)=>Sic.push({code: c, text: sic_text[c][lang]}));
SicDefinitions.sic3.forEach((c)=>Sic3[c] = sic_text[`sic3${c}`][lang]);
SicDefinitions.sic_classes.forEach((c)=>SicClasses.push({code: c, text: sic_text[`c${c}`][lang]}));
SicRanges.forEach((item: any)=>{
    const k = `r${item.from}_${item.till}`;
    item.text = sic_text[k][lang];
});

const formatter = (v, fractionDigits)=> (new Intl.NumberFormat(navigator.language, {  maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits })).format(v);
const reg_exp = new RegExp("\%(s|(..\d*)?f)", "g");
const _t = (key: ETranslation, ...parameters): string => {
    if(!key){
        return "_t(#ERROR)";
    }
    const use_locale = locales[locale];
    let tr: string = en[key];
    if(use_locale){
        tr = use_locale[key];
    }
    if(!tr){
        tr = `_t(${key})`;
    }
    let result: string = tr;
    if(Array.isArray(parameters) && parameters.length){
        const placeholder = Array.from(tr.matchAll(reg_exp));
        placeholder.forEach((r, idx)=>{
            const to_replace = r[0];
            const to_replace_def = r[1];
            const to_replace_op = r[2];
            if(to_replace_def === "s"){
                result = result.replace(to_replace, parameters[idx]);
            }
            // float
            if(to_replace_def.endsWith("f")){
                let digits;
                // resolve fraction digits
                if(to_replace_op && to_replace_op.startsWith(".")){
                    digits = parseInt(to_replace_op.substring(1), 10);
                }
                result = result.replace(to_replace, formatter(parseFloat(parameters[idx]), digits));
            }
        });
    }
    return result;
}
/*
* An Übersetzung wir "parameter" angehangen
* */
const _ta = (key: ETranslation, parameter)=>{
    const x = _t(key);
    return `${x}${parameter}`;
}
/*
* Übersetzung mit * am Ende
* */
const _td = (key: ETranslation, ...parameters)=>{
    const x = _t(key, ...parameters);
    return `${x}*`;
}
/*
* Übersetzung mit # am Anfang
* */
const _tn = (key: ETranslation, ...parameters)=>{
    const x = _t(key, ...parameters);
    return `#${x}`;
}
/*
* übersetzung mit fussnote) vorweg
* */
const _tf = (feed_note: number,key: ETranslation, ...parameters)=>{
    const ff = Globals.superscript(feed_note);
    const x = _t(key, ...parameters);
    return `${ff.join("")}) ${x}`;
}
/*
* übersetzung mit super script ziffern
* */
const _t_super = (feed_note: number,key: ETranslation, ...parameters)=>{
    const ff = Globals.superscript(feed_note);
    const x = _t(key, ...parameters);
    return `${x}${ff.join("")}`;
}
const naics_column = ()=>{
    switch(lang){
        case "de": return "title_de";
        default: return "title_en";
    }
}
export {
    _t,
    _ta,
    _td,
    _tn,
    _tf,
    _t_super,
    naics_column,
    country_map,
    setLocale,
    getLocale,
    available_locales
}
