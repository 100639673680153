import * as React from "react";
import {CSSProperties, useEffect, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {SzChartOptions} from "../../../helpers/SzChartOptions";
import {Bubble, Line} from "react-chartjs-2";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {Globals} from "../../../const/Globals";
import {CompressedArrayItem} from "../../../models/chef/RawBeta";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {ChefCalls} from "../../../services/ChefCalls";
import {SzSmallLoader} from "../../hooks/SzSmallLoader";
import {BondMedianSpreadPerYear} from "../../../models/chef/Bonds";
import {median} from "../../../helpers/Statistics";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);
const styleH: CSSProperties = {
    width: "100%",
    color: "#182026",
    fontSize: 14,
    fontWeight: "bold",
    display: "block",
    paddingBottom: 5,
    marginTop: 14,
    boxShadow: "inset 0px -1px 0px 0px #cecece",
};
export interface IBsMediumSpreadPerYearProps {
    company: ICompanyBase;
    data: any;
}
const loadData= async ()=>{
    const db = new ChefCalls();
    const bonds = await db.getBondsMedianSpreadPerYear();
    console.error(bonds.length);
    return bonds;
};
export function BsMedianSpreadPerYear() {
    const [timeSeries, setTimeSeries] = useState(undefined);
    useEffect(() => {
        (async ()=>{
            const r = await loadData();
            setTimeSeries(r);
        })();
    }, []);
    if(!timeSeries){
        return (
            <div>
                <div style={{aspectRatio: "1/1"}}>
                    <SzSmallLoader title={""} />
                </div>
            </div>
        );
    }

    const o = new SzChartOptions();
    // o.setXTickCallback(()=> "");
    // o.setYTickCallback(()=> "");
    o.setYMinMax({min: 0, max: 250});
    // o.setXMinMax({min: -4, max: 4});
    // o.setCustomAxisLabel(_t(ETranslation.index_return), "Residuum");
    const options = {
        ...o.options,
    };
    options.animations = false;
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
    };
    // options.scales.x.position = {y: 0};
    // options.scales.x.grid.tickLength = 5;
    // options.scales.y.position = {x: 0};
    options.plugins.tooltip = {
        enabled: true,
        mode: "point",
        intersect: true,
        backgroundColor: "rgba(255,255,255,0.75)",
        titleColor: "rgba(219,55,55,1)",
        bodyColor: "rgba(219,55,55,1)",
        borderColor: "rgba(82,82,82,0.75)",
        borderWidth: 1,
        caretSize: 0,
        cornerRadius: 2,
        callbacks: {
            label: (item)=> { return "a";},
            title: (item)=> { return _t(ETranslation.bonds);},
        },
    };

    const a_map = {};
    const b_map = {};
    timeSeries.forEach((i: BondMedianSpreadPerYear)=>{
        const k = `${i.security_id}_${i.year}`;
        if(!a_map[k]){
            a_map[k]= {security_id: i.security_id, year: i.year, senior_spreads: [], other_spreads: []};
        }
        a_map[k].senior_spreads.push(i.senior_spread);
        a_map[k].other_spreads.push(i.other_spread);
    });
    const years = {};
    const d = [];
    Object.keys(a_map).forEach((k)=>{
        const _a = a_map[k];
        if(_a){
            if(!years[_a.year]){
                years[_a.year] = {year: _a.year, senior_spreads: [], other_spreads: []};
            }
            years[_a.year].senior_spreads.push(median(_a.senior_spreads));
            years[_a.year].other_spreads.push(median(_a.other_spreads));
        }
    });
    Object.keys(years).map((i)=>parseInt(i,10)).sort().forEach((year, idx)=>{
        const i = years[year];
        d.push({
            x: idx,
            y: median(i.other_spreads) - median(i.senior_spreads), rawData: i
        });
    });
    console.error(d);
    const senior_dots = {
        label: "Senior",
        backgroundColor: "rgba(19,124,189,0.75)",
        data: d,
    };

    const chart_data = {
        labels: Object.keys(years),
        datasets: [
            senior_dots,
        ],
    };
    return (
        <Line data={chart_data as any} options={options} width={"100%"} height={"100%"}/>
    );
}
