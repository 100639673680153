import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {EventBus, IEventHandler} from "../../../services/EventBus";
import {CombiParameter} from "../CombiParameter";
import {ESzDateMode, SzDate} from "../../widgets/SzDate";
import {fromDateDecimal, fromStr} from "../../../tools/DateTools";

export class DateParameter extends CombiParameter {
    private readonly evtDateOverride: IEventHandler;
    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
        Globals.setWindowKey("change_date_parameter", (date_decimal) => {
            const d = fromDateDecimal(date_decimal).toFormat("dd.LL.yyyy");
            this.setState({
                selectedDate: d,
            });
            this.fireValueChange(EParameters.DateParameter, d);
        });

        this.evtDateOverride = EventBus.subscribe("evt-date-override", (date_decimal) => {
            this.setState({
                selectedDate: fromDateDecimal(date_decimal as any).asDeDate(),
            });
            this.fireValueChange(EParameters.DateParameter, fromDateDecimal(date_decimal as any).asDeDate());
        });
    }
    public static getPdfLabel(){
        return SessionStore.get(EParameters.DateParameter);
    }
    public componentWillUnmount() {
        EventBus.unsubscribe(this.evtDateOverride);
    }

    protected getWidthClass() {
        return "fixed-width-560";
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.DateParameter];
    }

    protected async initialize() {
        // dd.LL.yyyy
        let defaultValue = SessionStore.get(EParameters.DateParameter);
        // console.error(defaultValue);
        const dt = fromStr(defaultValue);
        if(!dt.isValid){
            defaultValue = "31.12.2021";
        }
        return {
            selectedDate: defaultValue,
        };
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                <div className={"sz-row"} style={{minHeight: 310}}>
                    <SzDate mode={ESzDateMode.END_OF_MONTH} onValueChanged={(v)=>{
                        const d = fromStr(v);
                        const new_state = {
                            selectedDate: d.toFormat("dd.LL.yyyy"),
                        };
                        this.setState(new_state);
                    }} />
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.DateParameter))}
            </div>
        );
    }
    protected getLabel(): any {
        const date = this.state.selectedDate;
        return date;
    }
    protected onClosePopup() {
        const date = this.state.selectedDate;
        if(date === SessionStore.get(EParameters.DateParameter)){
            return;
        }
        this.fireValueChange(EParameters.DateParameter, date);
    }
}
