import * as React from "react";
import {CSSProperties} from "react";
import * as ReactDOM from "react-dom";
import {SvgTextSize} from "../../helpers/SvgTextSize";
import {ISzSvgDataPoint} from "../../models/ISzSvgDataPoint";
import {ChartHelper} from "./helper/ChartHelper";
import {Globals} from "../../const/Globals";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

const PaddingAll: number = 0;
// const HISTOGRAM_SLOTS: number = 15;

interface IPadding {
    left: number;
    right: number;
    top: number;
    bottom: number;
}
export enum EFromXorY {
    fromX,
    fromY,
}
export interface ISzScatterPlotSvgProperties {
    width: string;
    height: string;
    addClassName?: string;
    histogram_slots: number;
    points: ISzSvgDataPoint[];
    fromXorY: EFromXorY;
    mean: number;
    q25: number;
    q75: number;
}

export class SzHistogramSvg extends React.Component<ISzScatterPlotSvgProperties, any> {

    private readonly svg: React.RefObject<HTMLDivElement> = null;
    private observer: IntersectionObserver;
    private padding: IPadding;

    constructor(props: any, context: any) {
        super(props, context);
        this.svg = React.createRef();
        window.addEventListener("resize", () => this.resize());
        this.padding = {
            top: PaddingAll,
            left: PaddingAll,
            right: PaddingAll,
            bottom: 30,
        };
    }
    public render() {
        return (<div ref={this.svg} style={{ width: this.props.width, height: this.props.height }}/>);
    }
    public componentDidMount(): void {
        const width: number = this.svg.current.clientWidth;
        const height: number = this.svg.current.clientHeight;
        ReactDOM.render(this.renderSvg(width, height), this.svg.current);
        this.observer = new IntersectionObserver((items, observer) => { this.onIntersectionObserver(items, observer); });
        this.observer.observe(this.svg.current);
    }
    public componentWillUnmount(): void {
        this.observer.disconnect();
    }

    public renderSvg(width, height) {
        const w = width - (this.padding.left + this.padding.right);
        const h = height - (this.padding.top + this.padding.bottom);
        return (
            <svg width={width} height={height} className={"sz-chart"}>
                {this.renderHistogram(w, h)}
            </svg>
        );
    }
    public resize() {
        if (!this.svg.current) {
            return;
        }
        const width: number = this.svg.current.clientWidth;
        const height: number = this.svg.current.clientHeight;
        ReactDOM.render(this.renderSvg(width, height), this.svg.current);
    }
    private onIntersectionObserver(items: IntersectionObserverEntry[], observer: IntersectionObserver) {
        for (const item of items) {
            if (!item.isIntersecting) {
                continue;
            }
            this.resize();
            break;
        }
    }
    private assignPot(points: ISzSvgDataPoint[], startValue, slotWidth: number, pointsGetter) {
        const targetPot = new Array(this.props.histogram_slots);
        let maxCount = 0;
        for (let i = 0; i < this.props.histogram_slots; i++) {
            const pot = { count: 0, low: startValue + (i * slotWidth), high:  startValue + ((i + 1) * slotWidth), nullPot: false, dates: []};
            pot.nullPot = 0 >= pot.low && 0 < pot.high;

            targetPot[i] = pot;
            points.forEach( (p) => {
                const pv = pointsGetter(p);
                if ( pv >= pot.low && pv < pot.high) {
                    if (p.dataSet && p.dataSet.date) {
                        pot.dates.push(p.dataSet.date);
                    }
                    pot.count ++;
                    if (maxCount < pot.count) {
                        maxCount = pot.count;
                    }
                }
            } );
        }
        // console.error(maxCount, targetPot);
        return {maxCount, pots: targetPot};
    }
    private renderHistogram(width: number, height: number) {
        const {x_min, x_max, y_min, y_max} = ChartHelper.getMinMax(this.props.points);
        const min = this.props.fromXorY === EFromXorY.fromX ? x_min : y_min;
        const max = this.props.fromXorY === EFromXorY.fromX ? x_max : y_max;
        const slotWidth = (max - min) / this.props.histogram_slots;
        const factor = width / this.props.histogram_slots;
        const getter = this.props.fromXorY === EFromXorY.fromX ? (p) => p.x : (p) => p.y;

        // const means = [];
        // this.props.points.forEach( (p) => means.push(getter(p)) );
        const mean = this.props.mean;
        const q25 = this.props.q25;
        const q75 = this.props.q75;

        const x_pos_factor = width / (max - min);
        const pos_mean = (mean - min) * x_pos_factor;
        const pos_q25 = (q25 - min) * x_pos_factor;
        const pos_q75 = (q75 - min) * x_pos_factor;
        const pot = this.assignPot(this.props.points, min, slotWidth, getter);
        // console.error(x_min, x_max, y_min, y_max);
        // console.error(pot);

        const maxHeight = height - 16;
        const boundingBox = SvgTextSize.measureText("µ");
        const getLineStyle = (css:CSSProperties) => {
            const r:CSSProperties = {
                fill: "none",
                strokeWidth: 1,
                strokeDasharray: 4,
            };
            return Object.assign(r, css);
        };
        const getTextStyle = (css:CSSProperties) => {
            const r:CSSProperties = {
                textAnchor: "start",
                fontSize: "75%",
                fontFamily: "Arial",
            };
            return Object.assign(r, css);
        };
        const renderAnnotation = (x_position, y_offset, text, css_text, css_line) => {
            if (x_position < 0 || x_position > width) {
                return null;
            }
            return (
                <g>
                    <line style={css_line} x1={x_position} y1={height - y_offset} x2={x_position} y2={0} />
                    <text x={x_position + 5 } y={height - y_offset} style={css_text}>{text}</text>
                </g>
            );
        };
        const renderPot = (source) => {
            let x = 0;
            const maxCount = source.maxCount;
            const pots = source.pots;
            const gtHeight = (count) => Math.abs((count / maxCount) * maxHeight );

            return pots.map( (p) => {
                const x1 = x;
                const h = gtHeight(p.count);
                const hh = h - 5 < 0 ? 0 : h - 5;
                x += factor;
                return <rect data-hisx-dates={p.dates.join(",")} x={x1} y={(maxHeight - h) + 5 } width={5} height={hh}  className={`sz-chart-histogram-rect`} name={`t(${p.low}), ${p.high}`} />;
            } );
        };
        const css_q25_txt = getTextStyle({fill: "#EC9A3C", textAnchor: "end", transform: "translateX(-10px)"});
        const css_q75_txt = getTextStyle({fill: "#EC9A3C"});
        const css_q_line = getLineStyle({stroke: "#EC9A3C"});
        const css_mean_txt = getTextStyle({fill: "#137cbd"});
        const css_mean_line = getLineStyle({stroke: "#137cbd"});

        return (
            <g className={"sz-chart-histogram"} transform={`translate(0 0)`}>
                {renderPot(pot)}
                {renderAnnotation(pos_q25, 0, `Q25 ${Globals.formatter_percent(q25)}`, css_q25_txt, css_q_line)}
                {renderAnnotation(pos_q75, 0, `Q75 ${Globals.formatter_percent(q75)}`, css_q75_txt, css_q_line)}
                {renderAnnotation(pos_mean, -15, `${_t(ETranslation.median)} ${Globals.formatter_percent(mean)}`, css_mean_txt, css_mean_line)}
                <line className={"sz-chart-histogram-bounds"} x1={0} x2={width} y1={Math.trunc(maxHeight + 1)} y2={Math.trunc(maxHeight + 1)} />
            </g>
        );
    }

}
