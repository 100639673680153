import * as UseAuth from "./UseAuth";
import * as React from "react";
import {useState} from "react";
import {FrontendCalls} from "../../services/FrontendCalls";
import {Globals} from "../../const/Globals";

function useProvideAuth() {
    const [user, setUser] = useState(Globals.user);
    const c = new FrontendCalls();
    const signin = async (email: string, passwd: string)=>{
        const r = await c.login(email, passwd);
        // console.error(r);
        if(r && !r.error){
            setUser(r);
        }
        return r;
    };

    const signout = async ()=>{
        const r = await c.logout();
        setUser(undefined);
        return true;
    };

    return {
        user,
        signin,
        signout
    };
}

export const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth();
    return (
        <UseAuth.authContext.Provider value={auth}>
            {children}
        </UseAuth.authContext.Provider>
    );
};
