import * as React from "react";
import {CSSProperties} from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";

const label_style: CSSProperties={
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
};

export class BondPropertiesParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.BondPropertiesParameter];
    }

    protected getWidthClass(): string {
        return "fixed-width-500";
    }

    protected async initialize() {
        const state: {[k: string]: any} = {};
        state[EParameters.SeniorityParameter] = SessionStore.get(EParameters.SeniorityParameter);
        state[EParameters.PledgeStatusParameter] = SessionStore.get(EParameters.PledgeStatusParameter);
        return state;
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                <div style={{paddingTop: 10, paddingRight: 10}} className={"sz-row"}>
                    {this.renderParameter(EParameters.SeniorityParameter)}
                    {this.renderParameter(EParameters.PledgeStatusParameter)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.BondPropertiesParameter))}
            </div>
        );
    }

    protected getLabel(): any {
        const a = this.getOptionLabelHard(EParameters.SeniorityParameter);
        const b = this.getOptionLabelHard(EParameters.PledgeStatusParameter);
        return [a,b].join(" | ");
    }
    private onChangeValue(field: EParameters, _value: any) {
        const _valueAsNumber = _value;
        const state: {[k: string]: any} = {};
        state[field] = _valueAsNumber;
        this.setState(state);
        this.fireValueChange(field, _valueAsNumber);
    }
    private renderParameter(name: EParameters) {
        const config = ParameterConfigs.configs[name];
        const options = ParameterOptions.options[name];
        return this.renderColumn("50%", config.name, ()=>{
            return (
                <div style={{marginLeft: -5, marginRight: -5}}>
                    {this.renderOptionsExt(options, this.state[name], name, name)}
                </div>
            );
        });
    }
}
