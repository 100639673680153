import * as React from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, Title, Tooltip,} from 'chart.js';

import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {Line} from "react-chartjs-2";
import {DrawStateHook} from "../../../chart-plugin/DrawStateHook";
import * as ChartJsAnnotation from "chartjs-plugin-annotation";

import {CustomAxisLabel} from "../../../chart-plugin/CustomAxisLabel";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {Annotations} from "./annotations";
import {SessionStore} from "../../../const/SessionStore";
import {EValueName} from "../../../models/EValueName";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
    ChartJsAnnotation,
    CustomAxisLabel,
    Filler,
);
export interface ICoCMarketRiskDeu {
    min_year: number;
    market_risk_premium: any[];
    idw_band_width: any[];
    kpmg: any[];
    subTitle: string;
}

export function CoCMarketRiskDeu(props: ICoCMarketRiskDeu) {
    if(props?.market_risk_premium?.length === 0){
        //
        return null;
    }

    const o = new SzChartOptions();
    const legend_options = {
        position: "top",
        align: "center",
        labels: {
            filter: (item, chart)=>{
                return item.text !== undefined;
            },
            boxWidth: 10
        }
    };
    o.enablePlugin(EChartPlugins.LEGEND, legend_options);
    // o.setCustomAxisLabel(_t(ETranslation.years), `[% p.a.]`);
    const market_risk_premium = props.market_risk_premium;
    const idw_band_width = props.idw_band_width;
    const kpmg = props.kpmg;
    const labels: string[] = (new Array(market_risk_premium.length)).fill(1).map( (i, idx) => idx % 2 !== 0 ? undefined : ""+(idx + props.min_year));
    // const labels: string[] = (new Array(market_risk_premium.length)).fill(1).map( (i, idx) => ""+(idx + props.min_year));
    o.enablePlugin(EChartPlugins.ANNOTATION, {
        annotations : {
            market_risk_premium: Annotations.coc_risk_premium(parseFloat(SessionStore.getGlobalVar(EValueName.market_risk_premium, NaN)), props.min_year, market_risk_premium.length),
        }
    });
    const options = {
        ...o.options,
    };
    options.scales.y.beginAtZero= true;

    options.scales.x.ticks.stepSize= 2;
    // options.scales.x.offset = true;
    // options.scales.x.grid.offset = true;

    options.animations = false;
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
    };
    const gfx = {
        label: "A. Damodaran",
        borderColor: "rgba(19,124,189,0.75)",
        borderWidth: 1,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        stepped: true,
        data: market_risk_premium,
    };
    const gfx_kpmg = {
        label: _t(ETranslation.source_kpmg_coc_study),
        borderColor: "#202B33",
        borderWidth: 1,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        stepped: true,
        data: kpmg,
    };
    const idw_min = [];
    const idw_max = [];
    idw_band_width.forEach((i)=>{
        const d = i.dataSet;
        idw_min.push({x: i.x, y: d.min});
        idw_max.push({x: i.x, y: d.max});
    });
    const gfx_idw_min = {
        label: _t(ETranslation.source_idw_bandwidth),
        borderColor: "rgba(15, 153, 96, 0.15)",
        backgroundColor: "rgba(15, 153, 96, 0.15)",
        borderWidth: 1,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        stepped: true,
        fill: "+1",
        data: idw_min,
    };
    const gfx_idw_max = {
        label: undefined,
        borderColor: "rgba(15, 153, 96, 0.15)",
        backgroundColor: "rgba(15, 153, 96, 0.15)",
        borderWidth: 1,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        stepped: true,
        data: idw_max,
    };
    const chart_data = {
        labels,
        datasets: [
            gfx,
            gfx_kpmg,
            gfx_idw_min,
            gfx_idw_max,
        ],
    };
    return (
        <div>
            <div style={{marginBottom: 20, fontSize: "80%"}}>
                <strong>{_t(ETranslation.market_risk_premium)} [% p.a.]</strong>
                <br/>
                {props.subTitle}
            </div>
            <div style={{width: "100%", height: 400}}>
                <Line data={chart_data as any} options={options} width={"100%"} height={"100%"}/>
            </div>
        </div>
    );
}
