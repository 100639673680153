export enum EMarketDataFieldMap {
    company_id,
    date_decimal,
    currency_iso,
    ff_mkt_val,
    p_volume_avg,
    p_price_avg,
    xp_daily_avg_spread,
    ff_shs_closely_held_pct,
    fa_shs_float,
    p_trading_days,
}
