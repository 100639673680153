import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {PARAMETER_OPTION} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {PdfInsertElement} from "../../../pdf-tools/PdfInsertElement";

const indexTypeOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.IndexTypeParameter);
const compositionTypeOptions: IOptionEntry[] = PARAMETER_OPTION(EParameters.CompositionTypeParameter);

export class IndexCombiParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getPdfLabel() {
        const content = [];
        const localTypeParameter = SessionStore.get(EParameters.LocalTypeParameter) === "" ? "EUR" : SessionStore.get(EParameters.LocalTypeParameter);
        const selectedIndexType = `${SessionStore.get(EParameters.IndexTypeParameter)}|${localTypeParameter}`;
        PdfInsertElement.selected_option(content, _t(ETranslation.index_composition), EParameters.CompositionTypeParameter);
        PdfInsertElement.selected_option(content, _t(ETranslation.stock_market_and_currency), EParameters.IndexTypeParameter, selectedIndexType);
        return {
            stack: content,
        };
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.IndexCombiParameter];
    }

    protected async initialize() {
        const localTypeParameter = SessionStore.get(EParameters.LocalTypeParameter) === "" ? "EUR" : SessionStore.get(EParameters.LocalTypeParameter);
        const selectedIndexType = `${SessionStore.get(EParameters.IndexTypeParameter)}|${localTypeParameter}`;
        return {
            selectedCompositionType: SessionStore.get(EParameters.CompositionTypeParameter),
            selectedIndexType,
        };
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                {this.renderCompositionType()}
                {this.renderIndexType()}
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.IndexCombiParameter))}
            </div>
        );
    }

    protected getWidthClass(): string {
        return "fixed-width-500";
    }

    protected getLabel(): any {
        const selIndexType = indexTypeOptions.find((e) => e.value === this.state.selectedIndexType);
        const selCompositionType = compositionTypeOptions.find((e) => e.value === this.state.selectedCompositionType);

        this.putPacketOptionParameter(EParameters.CompositionTypeParameter, [
            {o: compositionTypeOptions, s: this.state.selectedCompositionType}
        ]);
        this.putPacketOptionParameter(EParameters.IndexTypeParameter, [
            {o: indexTypeOptions, s: this.state.selectedIndexType}
        ]);

        return `${selCompositionType.short} | ${selIndexType.short}`;
    }
    protected changeState(value, parameterType: EParameters, field: string) {
        console.error(value, parameterType);
        if (value === this.state[field]) {
            return;
        }
        const newState = {};
        newState[field] = value;
        this.setState(newState);
        if (parameterType === EParameters.IndexTypeParameter) {
            const values = ("" + value).split("|");
            this.fireChanges([
                {component: EParameters.IndexTypeParameter, value: [values[0]]},
                {component: EParameters.LocalTypeParameter, value: [values[1]]},
            ]);
            return;
        }
        this.fireValueChange(parameterType, value);
    }
    private renderIndexType() {
        return this.renderColumn("50%", _t(ETranslation.stock_market_and_currency), ()=>{
            return this.renderOptionsExt(indexTypeOptions, this.state.selectedIndexType, EParameters.IndexTypeParameter, "selectedIndexType");
        });
    }
    private renderCompositionType() {
        return this.renderColumn("50%", _t(ETranslation.index_composition), ()=>{
            return this.renderOptionsExt(compositionTypeOptions, this.state.selectedCompositionType, EParameters.CompositionTypeParameter, "selectedCompositionType");
        });
    }
}
