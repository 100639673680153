import {Button, Intent} from "@blueprintjs/core";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import * as ChartJsAnnotation from "chartjs-plugin-annotation";
import * as React from "react";
import {useState} from "react";
import {Line} from "react-chartjs-2";

import {CustomAxisLabel} from "../../../chart-plugin/CustomAxisLabel";
import {DrawStateHook} from "../../../chart-plugin/DrawStateHook";
import {TooltipLine} from "../../../chart-plugin/TooltipLine";
import {ColorMap} from "../../../const/ColorMap";
import {ETranslation} from "../../../const/ETranslation";
import {Globals} from "../../../const/Globals";
import {Sectors} from "../../../const/Sectors";

import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {IMultiplierForBusiness} from "../../../models/IMultiplierForBusiness";
import {EventBus, IEventHandler} from "../../../services/EventBus";
import {fromDateDecimal} from "../../../tools/DateTools";
import {_t} from "../../../tools/Translator";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
    ChartJsAnnotation,
    CustomAxisLabel,
    TooltipLine,
);
export interface IMfBTimeSeries {
    caption: string;
    time_series: {[date_decimal: number]: any};
}
const value_names = [
    {field: "sales", label: _t(ETranslation.revenues)},
    {field: "ebitda", label: "EBITDA"},
    {field: "ebit", label: "EBIT"},
    {field: "netprofit", label: _t(ETranslation.pe_ratio)},
    {field: "price_book", label: _t(ETranslation.pb_ratio)},
];

const evtSelectIndexChanged: IEventHandler = undefined;
export function MfBTimeSeries(props: IMfBTimeSeries) {
    const [fe_item, setFeItem] = useState("sales");
    const [fe_per_rel, setFePerRel] = useState("0");
    const [selected_index, setSelectedIndex] = useState(0);

    if(!evtSelectIndexChanged){
        EventBus.subscribe<number>("SectorMultiples::selectIndex", (i)=> setSelectedIndex(i));
    }

    const time_series = props.time_series;
    const dates = Object.keys(time_series).sort();
    const o = new SzChartOptions();
    o.enablePlugin(EChartPlugins.TOOLTIP_LINE, {});
    o.enablePlugin(EChartPlugins.LEGEND, {position: "chartArea", align: "start",labels: {boxWidth: 10}});
    const tooltip = {
        position: "nearest",
        callbacks: {
            title: (items)=> {
                const sz = items[0].raw.sz;
                const dd = fromDateDecimal(dates[items[0].dataIndex]).toFormat("LLLL yyyy", {locale: navigator.language});
                return `${dd}; #${sz}`;
            },
            label: (context)=> {
                let label = context.dataset.label || '';

                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += Globals.formatter(context.parsed.y);
                }
                return label;
            },
        },
    };
    o.enablePlugin(EChartPlugins.TOOLTIP, tooltip);
    const labels: string[] = dates.map( (i, idx) => idx % 2 !== 0 ? undefined : fromDateDecimal(i).toFormat("LL.yy"));
    // const labels: string[] = (new Array(market_risk_premium.length)).fill(1).map( (i, idx) => ""+(idx + props.min_year));
    const options = {
        ...o.options,
    };
    options.scales.y.beginAtZero= true;
    options.scales.x.ticks.stepSize= 2;

    // options.scales.x.offset = true;
    // options.scales.x.grid.offset = true;

    options.animations = false;
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
        mode: 'index',
    };
    const chart_data = {
        labels,
        datasets: [],
    };
    const sector_gfx = (label, color_idx: number)=>{
        return {
            label,
            borderColor: ColorMap[`$c_${color_idx + 1}`],
            borderWidth: 2,
            // pointStyle: false,
            pointRadius: 3,
            pointBorderWidth: .5,
            pointHoverRadius: 10,
            data: [],
        };
    };
    const sets = {};
    const field_sz =  fe_item === "price_book" ? `${fe_item}_sz` : `${fe_item}_${fe_per_rel}_sz`;
    const field = fe_item === "price_book" ? `${fe_item}_median` : `${fe_item}_${fe_per_rel}_median`;
    const field_p25 = fe_item === "price_book" ? `${fe_item}_p25` : `${fe_item}_${fe_per_rel}_p25`;
    const field_p75 = fe_item === "price_book" ? `${fe_item}_p75` : `${fe_item}_${fe_per_rel}_p75`;
    const data_p25 = [];
    const data_p75 = [];
    const sector_label: string[] = [];
    dates.forEach((d, date_idx)=>{
        const entries: IMultiplierForBusiness[] = time_series[d];
        entries.forEach((sector_entry, idx)=>{
            if(selected_index !== idx){
                return;
            }
            if(sets[sector_entry.key] === undefined){
                sector_label.push(Sectors.sectors_map[sector_entry.key]);
                const e = sector_gfx(Sectors.sectors_map[sector_entry.key], 0);
                chart_data.datasets.push(e);
                sets[sector_entry.key] = e;
            }
            data_p25.push({x: date_idx, y: sector_entry[field_p25], sz: sector_entry[field_sz]});
            data_p75.push({x: date_idx, y: sector_entry[field_p75], sz: sector_entry[field_sz]});
            sets[sector_entry.key].data.push({x: date_idx, y: sector_entry[field], sz: sector_entry[field_sz]});
        });
    });

    const gfx_0 = {
        label: _t(ETranslation.lower_quartile),
        borderColor: "rgba(0, 0, 0,0.15)",
        borderWidth: 0,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        data: data_p25,
        fill: "+1"
    };
    const gfx_1 = {
        label: _t(ETranslation.upper_quartile),
        borderColor: "rgba(0, 0, 0,0.15)",
        borderWidth: 0,
        pointStyle: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        normalized: true,
        data: data_p75,
    };
    chart_data.datasets.push(gfx_0);
    chart_data.datasets.push(gfx_1);
    const ok = (a,b)=>{ return a === b };
    const _intent = (a,b)=> ok(a,b) ? Intent.PRIMARY : Intent.NONE;
    const _intent2 = (a,b)=> ok(a,b) && fe_item!=="price_book" ? Intent.WARNING : Intent.NONE;
    const ism = false;
    return (
        <div style={{padding: 5, height: "100%"}}>
            <div style={{marginBottom: 20, fontSize: "80%"}}>
                <div><strong>{_t(ETranslation.trading_multiples)} 3 {_t(ETranslation.years)}</strong></div>
                <div>{props.caption}, {sector_label.join(", ")}</div>
            </div>
            <div style={{display: "flex"}}>
                <div style={{marginRight: 50}}>
                    {value_names.map((i)=> <Button minimal={ism} text={i.label} intent={_intent(fe_item, i.field)} onClick={()=> setFeItem(i.field)} style={{marginRight: 5}}/>)}
                </div>
                <div>
                    <Button disabled={fe_item==="price_book"} minimal={ism} text={"Trailing"} intent={_intent2(fe_per_rel, "0")} onClick={()=>setFePerRel("0")} style={{marginRight: 5}}/>
                    <Button disabled={fe_item==="price_book"} minimal={ism} text={"Forward"} intent={_intent2(fe_per_rel, "1")} onClick={()=>setFePerRel("1")}/>
                </div>
            </div>
            <div style={{width: "100%", height: 400, marginTop: 10}}>
                <Line data={chart_data as any} options={options} width={"100%"} height={"100%"}/>
            </div>
        </div>
    );
}
