import * as sys_info from "../sys_info.json";

import * as React from "react";
import {CSSProperties, useState} from "react";
import {EToolbarPage, SideBar} from "./SideBar";
import {ErrorBoundary} from "./ErrorBoundary";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {MainProjectWorkspace} from "./MainProjectWorkspace";
import {MainProjectsView} from "./MainProjectsView";
import {IProject} from "../models/IProject";
import {cacheInvalidateAll} from "../helpers/ServiceCallCache";
import {Popover2, Tooltip2} from "@blueprintjs/popover2";
import {Button, Icon, Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {ChefCalls} from "../services/ChefCalls";
import {_t, available_locales, getLocale} from "../tools/Translator";
import {ETranslation} from "../const/ETranslation";
import {Globals} from "../const/Globals";
import {EventBus} from "../services/EventBus";
import {Chargebee} from "../helpers/Chargebee";
import {ServiceCalls} from "../services/ServiceCalls";
import * as UseAuth from "./hooks/UseAuth";
import {DlgChangePassword} from "./widgets/DlgChangePassword";
import {MainBarTitle} from "./widgets/MainBarTitle";
import {_k} from "../helpers/Helpers";
import {DlgYouTrack} from "./widgets/DlgYouTrack";

const main_bar_style: CSSProperties = {
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#394b59",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};
const sys_bar_style: CSSProperties = {
    paddingLeft: 15,
    paddingRight: 10,
    backgroundColor: "#394b59",
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "1fr 250px",
};

export const MainApplication = ()=>{
    const auth = UseAuth.useAuth();
    const navigate = useNavigate();
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [showYouTrack, setShowYouTrack] = useState<boolean>(false);
    const location = useLocation();
    React.useEffect(() => {
        (async ()=>{
            try{
                const storeCalls = new ServiceCalls();
                await storeCalls.setSessionValue("user_language", getLocale());
            }catch (e) {
                console.error(e);
            }
        })();
    }, []);
    React.useEffect(() => {
        (async ()=>{
            try{
                if(location && !location.pathname.startsWith("/project/")){
                    cacheInvalidateAll();
                }
            }catch (e) {
                console.error(e);
            }
        })();
    }, [location]);
    const logout = ()=>{
        (async ()=>{
            const services = new ServiceCalls();
            await services.logout();
            const chef = new ChefCalls();
            await chef.logout();
            await auth.signout();
        })();
    };
    const sysMenu = ()=>{
        return (
            <Menu>
                <MenuItem icon={IconNames.PERSON} text={_t(ETranslation.konto)} onClick={()=> Chargebee.openPortal()} />
                <MenuDivider />
                <MenuItem icon={IconNames.USER} text={_t(ETranslation.pass_change)} onClick={()=> setShowChangePassword(true)}/>
                <MenuItem icon={IconNames.LOG_OUT} text={_t(ETranslation.logout)} onClick={()=> logout()}/>
            </Menu>
        );
    };
    const langMenu = ()=>{
        const chgLang = (language: string)=> {
            // setLocale(l);
            (async ()=>{
                localStorage.setItem("language", language);
                window.location.reload();
            })();
        };
        return (
            <Menu>
                {Object.keys(available_locales).map((key)=> <MenuItem key={_k("LangMenu", key)} text={available_locales[key]} onClick={()=> chgLang(key)} />)}
            </Menu>
        );
    };
    const getFlag=  ()=>{
        const le = getLocale();
        if(le === "en"){
            return "gb";
        }
        return le;
    };
    const m = [
        {caption: _t(ETranslation.files), icon: IconNames.FOLDER_OPEN, page: EToolbarPage.FileManager},
        {caption: "IDW News", icon: IconNames.INBOX_GEO, page: EToolbarPage.FAUB},
        {caption: _t(ETranslation.glossary), icon: IconNames.HELP, page: EToolbarPage.Documentation},
        {caption: _t(ETranslation.system_information), icon: IconNames.INFO_SIGN, page: EToolbarPage.SysInfo},
    ];
    if(Globals.isAdmin){
        m.push({
            caption: "Status-Log", icon: IconNames.CHART, page: EToolbarPage.Stats
        });
    }
    return (
        <div style={{
            height: "100vh",
            width: "100vw",
            display: "grid",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "43px 1fr 34px"
        }}>
            <div style={main_bar_style}>
                <MainBarTitle key={`MainBarTitle${Date.now()}`}/>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    {m.map((i, idx) => (
                        <Tooltip2 content={i.caption} usePortal={true} openOnTargetFocus={false}
                                  key={_k(i.caption, idx)}>
                            <Button minimal={true} onClick={() => EventBus.emit("SideBar::openPage", i.page)}>
                                <Icon icon={i.icon as any} color={"#ffffff"}/>
                            </Button>
                        </Tooltip2>
                    ))}
                    <Tooltip2 content={"Service Anfrage"} usePortal={true} openOnTargetFocus={false}>
                        <Button minimal={true} onClick={()=> setShowYouTrack(true)} >
                            <Icon icon={IconNames.ENVELOPE} color={"#ffffff"}/>
                        </Button>
                    </Tooltip2>
                    <div style={{width: 10}}>&nbsp;</div>
                    <Popover2 content={sysMenu()} position={"bottom-left"} minimal={true}>
                        <Tooltip2 content={<span style={{wordBreak: "keep-all"}}>{Globals.user.uuid}</span>}
                                  usePortal={true} openOnTargetFocus={false}>
                            <Button minimal={true}>
                                <Icon icon={IconNames.COG} color={"#ffffff"}/>
                                &nbsp;
                                <Icon icon={IconNames.CARET_DOWN} color={"#ffffff"}/>
                            </Button>
                        </Tooltip2>
                    </Popover2>
                    <div style={{width: 10}}>&nbsp;</div>
                    <Popover2 content={langMenu()} position={"bottom-left"} minimal={true}>
                        <Button minimal={true}>
                            <img src={`images/flags/${getFlag()}.jpg`} height={16}
                                 alt={`${getLocale().toUpperCase()}`}/>
                        </Button>
                    </Popover2>
                    <DlgChangePassword isOpen={showChangePassword} onClose={() => setShowChangePassword(false)}/>
                    <DlgYouTrack isOpen={showYouTrack} onClose={() => setShowYouTrack(false)}/>
                </div>
            </div>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                paddingTop: 1,
                paddingBottom: 1,
                overflow: "hidden"
            }}>
                <div style={{overflow: "auto"}}>
                    <ErrorBoundary>
                        <Routes>
                            <Route path="/projects" element={<MainProjectsView onProjectSelected={(p: IProject) => {
                                navigate(`/project/${p._id}`);
                            }}/>}/>
                            <Route path="/project/:projectId" element={<MainProjectWorkspace/>}/>
                            <Route path="*" element={<Navigate to="/projects"/>}/>
                        </Routes>
                    </ErrorBoundary>
                </div>
                <SideBar/>
            </div>
            <div style={sys_bar_style}>
                <div style={{display: "flex", height: "100%"}}>&nbsp;</div>
                <div style={{color: "#f5f5f5", display: "flex", justifyContent: "flex-end"}}>
                    <span>v{sys_info.version}</span></div>
            </div>
        </div>
    );
}
