import {Globals} from "../../const/Globals";
import {EParameters} from "../../models/EParameters";
import {ICountry} from "../../models/ICountry";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {BaseDataProvider} from "./BaseDataProvider";
import {deadline, deadlineEoM} from "../../tools/DateTools";
import {ChefCalls} from "../../services/ChefCalls";

export class InterestRatesBubaAllProvider extends BaseDataProvider {
    protected initProvider() {
        this.className = "InterestRatesBubaAllProvider";
        this.parameterMapping = [
            EParameters.DateParameter,
            EParameters.GrowthRateCashSurpluseParameter,
            EParameters.PeriodTypeParameter,
            EParameters.RuntimeBaseInterestParameter,
            EParameters.FixedCountriesParameter,
            EParameters.BaseInterestCurveParameter,
            EParameters.RoundingRuleParameter,
        ];
    }

    protected update() {
        //
        let data = [];
        const db = new PublicDbCalls();
        const chef = new ChefCalls();
        (async () => {

            try {
                if (!Globals.countries.length) {
                    Globals.countries = (await db.getAllCountries()).result;
                }
            } catch (e) {
                console.error(e);
            }

            const country_iso_3 = this.mappedParameters.get(EParameters.FixedCountriesParameter)[0];

            const country: ICountry = Globals.countries.find( (i) => i.iso_3 === country_iso_3 );
            const isBuba = country ? country.currency_iso_3 === "EUR" : false;
            let parameters = [];
            if (isBuba) {
                parameters = [
                    [deadlineEoM()],
                    this.mappedParameters.get(EParameters.GrowthRateCashSurpluseParameter),
                    this.mappedParameters.get(EParameters.PeriodTypeParameter),
                    this.mappedParameters.get(EParameters.RuntimeBaseInterestParameter),
                ];
            } else {
                parameters = [
                    [deadlineEoM()],
                    this.mappedParameters.get(EParameters.FixedCountriesParameter),
                    ["GovernmentYields", "ZeroCoupon"],
                ];
            }
            const parameters_b = [
                [deadlineEoM()],
                "DEU",
                ["GovernmentYields", "ZeroCoupon"],
            ];

            const response = isBuba ? await db.getInterestRatesBubaAll(parameters) : await db.getInterestCurves(parameters);
            const response_b = isBuba ? await db.getInterestCurves(parameters_b) : null;
            // console.error(response, response_b);
            const all_times = await chef.getInterestCurvesTimeSeries(deadlineEoM(), country_iso_3, country.currency_iso_3);
            try {
                data = response.result;
                if (Array.isArray(data) && data.length === 0) {
                    data = null;
                }
                let zero = null;
                let gov = null;
                if (!isBuba && data) {
                    zero = this.remap(data, "ZeroCoupon");
                    gov = this.remap(data, "GovernmentYields");
                }

                if (isBuba && response_b && response_b.result && Array.isArray(response_b.result)) {
                    zero = data;
                    gov = this.remap(response_b.result, "GovernmentYields");
                }
                const dt = deadline(true).asDateDecimal();
                if(Array.isArray(all_times) && country_iso_3!=="DEU"){
                    const d = {};
                    const gy = all_times.filter((i)=> i.date_decimal === dt && i.rate_type ==="coupon" ).map((i)=>({...i, ...{usage: "GovernmentYields"}}));
                    gov = this.remap(gy, "GovernmentYields");
                    // console.error(gy);
                    // console.error(ogov);
                    // console.error(gov);
                }
                this.afterUpdate({
                    zeroCoupon: zero,
                    governmentYields: gov,
                    time_series: all_times,
                });
            } catch (exi) {
                console.error(exi, response);
            }
        })();
    }

    private remap(data: any[], usage: string) {
        if (!data) {
            return null;
        }
        const ret = [];
        const dates = {};
        data.forEach( (i) => {
            if (i.duration === .25) {
                return;
            }
            if (i.usage !== usage) {
                return;
            }

            let idx = i.duration + 1;
            if (i.duration === .5) {
                idx = 1;
            }

            let o = dates[i.date_decimal];
            if (!o) {
                o = (new Array(32)).fill(NaN);
                o[0] = i.date_decimal;
                ret.push(o);
                dates[i.date_decimal] = o;
            }

            o[idx] = i.value;

        } );
        return ret;
    }
}
