import {Menu, MenuItem, NonIdealState,} from "@blueprintjs/core";
import {isNumber} from "chart.js/helpers";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {ArrayCacheTools, CacheTools, CacheToolsAllParameters} from "../../helpers/CacheTools";
import {find_object} from "../../helpers/Helpers";
import {FinancialReportMap} from "../../models/chef/FinancialReported";
import {IFinancialAnnualReported} from "../../models/tables/FinancialAnnualReported";
import {TasksCalls} from "../../services/TasksCalls";
import {fromDateDecimal, fromDateParameter} from "../../tools/DateTools";
import {ISzTableColumn, ISzTableProperties, SzTable,} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {SzSmallLoader} from "../hooks/SzSmallLoader";
import {CacheEntry, ChefCalls} from "../../services/ChefCalls";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {Company} from "../../models/chef/UnleveredBeta";
import {IRawBetaResponse} from "../../models/chef/RawBeta";
import {ICompanySearchResult} from "../../models/ICompanySearchResult";
import {Region_Map_DeEng} from "../../const/Regions";
import {median} from "../../helpers/Statistics";
import {EIndexFunction, SzTableHelper} from "../widgets/helper/SzTableHelper";
import {TableStatistics} from "../../helpers/table/TableStatistics";
import {SvgTextSize} from "../../helpers/SvgTextSize";
import {BetaFilterState} from "../../helpers/BetaFilterState";
import {renderLongListAction} from "./company_info/TableHelper";
import {renderDebtDlg, selectRow} from "./company_info/CompanyInfoDlg";
import {_t, _ta} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {EBorder, PdfTable} from "../../pdf-tools/PdfTable";
import {EPdfColumnDataType} from "../../pdf-tools/PdfTableColumn";
import {Sectors} from "../../const/Sectors";

const risk_monitor_cache: CacheEntry = ArrayCacheTools("risk_monitor_cache", "companies", CacheTools.Company_Array());
risk_monitor_cache.options.response_filter = (parameters, response) => response;

const rm_unlevered_beta_cache: CacheEntry = CacheToolsAllParameters("rm_unlevered_beta_cache");

const formatter = (new Intl.NumberFormat("de-de", { maximumFractionDigits: 4 })).format;

const bar_padding = (min_value, max_value)=>{
    const m = Math.max(Math.abs(max_value), Math.abs(min_value));
    const _max = m;
    const _min = min_value < 0 ? -m : 0;
    const padding_right_max: number = SvgTextSize.measureText(formatter(_max)).width;
    const padding_right_min: number = SvgTextSize.measureText(formatter(_min)).width;
    const _padding_right = Math.max(padding_right_max, padding_right_min);
    return [_min, _max, _padding_right];
};
const mk_bar_col = (index, v_min, v_max, swap_colors = true) => {
    const base_col = SzTableHelper.columnMoney(index, 2, {style: {borderRight: "4px solid #ffffff", width: 150}});
    base_col.formatter = ()=> "";
    const col_negativ = swap_colors ? "sz-chart-dot-5" : "sz-chart-dot-1";
    const col_positiv = swap_colors ? "sz-chart-dot-1" : "sz-chart-dot-5";
    base_col.barchart =  {height: 14, min: v_min, max: v_max, padding_right: 0, classNamePos: col_positiv, classNameNeg: col_negativ};
    base_col.options.style.verticalAlign = "middle";
    return base_col;
}

const make_bar_svg = (data_idx: number, min: number, max: number, width: number, colors: {minus: string, plus: string})=>{
    const mk = (row_data)=> {
        const value = row_data[data_idx];

        if (min > 0) {
            min = 0;
        } else if (max < 0) {
            max = 0;
        }
        const delta = (max - min);
        const xFactor = width / delta;
        const x_origin = (0 - min) * xFactor;
        let w = 0;
        const h = 10;

        let x_rect = 0;

        const y_0 = 0;
        const y_h = h;
        if (value < 0) {
            w = (-1 * value) * xFactor;
            x_rect = (x_origin - w);
        } else if (value > 0) {
            w = value * xFactor;
            x_rect = x_origin;
        } else {
            w = 2;
            x_rect = x_origin - 1;
        }
        if (w < 0 || h < 0 || x_rect < 0) {
            return {text: ""};
        }
        const fill_color = value < 0 ? colors.minus : colors.plus;
        return {
            svg: `<svg width="${width}" height="${h}" viewBox="0 0 ${width} ${h}"><rect x="${x_rect}" y="${y_0}" width="${w}" height="${y_h}" fill="${fill_color}"/></svg>`,
            fit: [width, h],
        };
    };

    return mk;
}

export class BetaRiskMonitor extends BaseModule<any> {

    private tableProps;
    private risk_monitor;
    private unlevered_beta;
    private table_data = [];

    private min_delta = 0;
    private max_delta = 0;

    private min_ebitda_margin = 0;
    private max_ebitda_margin = 0;
    private min_sales_growth = 0;
    private max_sales_growth = 0;

    constructor(props: any, context: any) {
        super(props, context);
        const s = {
            load_risk_monitor: true,
            loading: false,
        };
        this.state = BetaFilterState.merge(s);
        BetaFilterState.listen(this);
    }
    public async getDocumentationData() {
        return undefined;
    }
    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }

    public exportAsExcelMappe() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelMapTask([
                    // this.data_debt_beta,
                    // this.data_tax_shield,
                    // this.data_debt,
                ]);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    public exportAsExcel(props?: ISzTableProperties<any>) {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(props ? props : this.tableProps);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected renderContent() {
        if(this.state.load_risk_monitor){
            return (<SzSmallLoader title={""} />);
        }
        return (
            <div>
                {this.data ? this.renderData() : this.noData()}
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;

        // const selected_period = SessionStore.get(EParameters.PeriodsParameter);
        const years = parseInt(this.parameters.PeriodsParameter[0], 10);
        // const date_decimal_end = fromDateParameter().asDateDecimal();
        // const date_decimal_start = fromDateParameter().startOf("month").minus({year: years}).endOf("month").asDateDecimal();

        (async ()=>{
            try {
                const db = new ChefCalls();
                const deadline = SessionStore.get(EParameters.DateParameter);
                const period = SessionStore.get(EParameters.PeriodsParameter);

                const result = await db.loadRiskMonitor(data.risk_monitor_parameters, risk_monitor_cache);
                const raw_betas = result.raw_beta_result;
                const companies: Company[] = [];
                Object.keys(raw_betas).forEach((company_id)=>{
                    const r: IRawBetaResponse = raw_betas[company_id];
                    const rb = {};
                    if(r.periods && r.periods[period]){
                        rb[period] = {
                            beta: r.periods[period].Slope,
                            r: r.periods[period].R,
                        };
                    }
                    companies.push({company_id: r.company_id, raw_beta: rb});
                });
                const ub = await db.getUnleveredBeta({...data.unlevered_beta_params_ext, deadline, companies, company_betas_by_date: false}, rm_unlevered_beta_cache);
                this.risk_monitor = result;
                this.unlevered_beta = ub;

                const table_data = [];
                const company_search: ICompanySearchResult[] = SessionStore.getItem("CompanySearch", []);
                company_search.forEach((c: ICompanySearchResult)=>{
                    const data_row = [
                        c.company_id,
                        c.company_name,
                        Sectors.sectors_map[c.sector_code],
                        c.country_region_de,
                        NaN,
                        NaN,
                        NaN,
                        NaN,
                        NaN,
                        NaN,
                    ] as any;
                    const peer = this.data.unlevered_betas[c.company_id];
                    if(peer && peer[period]){
                        data_row[5] = peer[period]?.result?.unlevered_beta;
                    }
                    const rm_key=`${Region_Map_DeEng[c.country_region_de]}_${c.sector_code}`;
                    const rm = this.risk_monitor.sector_region_companies[rm_key];
                    if(Array.isArray(rm)){
                        const unlevered_betas = [];
                        const sales = [];
                        const ebitda = [];
                        rm.forEach((r)=>{
                            const company_id = r.id;
                            if(this.unlevered_beta[company_id] && this.unlevered_beta[company_id][period]){
                                const v = this.unlevered_beta[company_id][period];
                                unlevered_betas.push(v?.result?.unlevered_beta);
                            }
                            const fin_sec: {ebitda_margin: number,sales_growth: number}= this.risk_monitor.fin_data_map[company_id];
                            if(isNumber(fin_sec.ebitda_margin)){
                                ebitda.push(fin_sec.ebitda_margin);
                            }
                            if(isNumber(fin_sec.sales_growth)){
                                sales.push(fin_sec.sales_growth);
                            }
                        });
                        const fin_peer: {ebitda_margin: number,sales_growth: number}=
                            this.risk_monitor.fin_data_map[c.company_id] ?
                                this.risk_monitor.fin_data_map[c.company_id] :
                                this.calcFinData(c.company_id)
                        ;

                        data_row[4] = median(unlevered_betas);

                        data_row[8] = median(sales) * 100; // sector median
                        data_row[9] = fin_peer?.sales_growth * 100; // sector median
                        data_row[10] = data_row[9] - data_row[8]; // delta

                        data_row[11] = median(ebitda) * 100; // sector median
                        data_row[12] = fin_peer?.ebitda_margin * 100; // sector median
                        data_row[13] = data_row[12] - data_row[11]; // delta

                        if(isNumber(data_row[10])){
                            this.min_sales_growth = Math.min(this.min_sales_growth, data_row[10]);
                            this.max_sales_growth = Math.max(this.max_sales_growth, data_row[10]);
                        }
                        if(isNumber(data_row[13])){
                            this.min_ebitda_margin = Math.min(this.min_ebitda_margin, data_row[13]);
                            this.max_ebitda_margin = Math.max(this.max_ebitda_margin, data_row[13]);
                        }
                        // console.error(rm_key, sales.length, data_row[8], ebitda.length, data_row[9]);
                    }
                    table_data.push(data_row);

                    data_row[6] = data_row[5] - data_row[4]; // idx5 === peer

                    if(data_row[6]< this.min_delta){
                        this.min_delta = data_row[6];
                    }
                    if(data_row[6]> this.max_delta){
                        this.max_delta = data_row[6];
                    }

                });
                this.table_data = table_data;

            }catch (e) {
                console.error(e);
            }finally {
                this.setState({
                    load_risk_monitor: false,
                    loading: false,
                });
            }
        })();
    }

    private calcFinData(companyId){
        const ann_reported: FinancialReportMap = this.data.financial_reports[companyId];
        const sales: number[] = [];
        let sum_ebitda = 0;
        let sum_sales= 0;
        [0,1,2,3,4].forEach((i)=>{
            const date_decimal_end = fromDateParameter().minus({year: i}).asDateDecimal();
            const last: IFinancialAnnualReported = find_object(ann_reported, (key, o: IFinancialAnnualReported)=>{
                const dd = fromDateDecimal(date_decimal_end).diff(fromDateDecimal(key), "months").months;
                return dd < 18 && dd > 0;
            }, true);
            if(last){
                if(isNumber(last.ff_sales)){
                    sales.push(last.ff_sales);
                }
                if(isNumber(last.ff_sales) && isNumber(last.ff_ebitda_oper) && !isNaN(last.ff_sales) && !isNaN(last.ff_ebitda_oper)){
                    sum_ebitda += last.ff_ebitda_oper;
                    sum_sales += last.ff_sales;
                }
            }
        });
        const sales_growth = sales.length < 2 ? NaN : Math.pow(sales[0]/sales[sales.length - 1], 1/sales.length) - 1;
        const ebitda_margin =  sum_ebitda / sum_sales;
        return {
            sales_growth,
            ebitda_margin: isFinite(ebitda_margin) ? ebitda_margin : NaN,
        };
    }
    private noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }
    private renderData() {
        const aktion_col: ISzTableColumn = {
            index: "",
            options: {
                style: {borderRight: "4px solid #ffffff", width: 30, verticalAlign: "middle"},
                cellRenderer: (cellValue: any, data?: any) => {
                    return renderLongListAction(this, this.data.companies[data[0]], true);
                },
            },
        };

        const [min, max, padding_right] = bar_padding(this.min_delta, this.max_delta);
        const [e_min, e_max, e_padding_right] = bar_padding(this.min_ebitda_margin, this.max_ebitda_margin);
        const [s_min, s_max, s_padding_right] = bar_padding(this.min_sales_growth, this.max_sales_growth);
        // console.error(e_min, e_max, e_padding_right);
        // console.error(s_min, s_max, s_padding_right);
        // console.error(this.min_sales_growth, this.max_sales_growth);

        // <SzSvgRiskBeta {...gfxProps} />
        const header_2: ISzTableColumn[] = [];
        header_2.push({ text: ""});
        header_2.push({ text: _t(ETranslation.company), options: {colSpan: 2} });
        header_2.push({ text: _t(ETranslation.sector)});
        header_2.push({ text: `${_t(ETranslation.beta_unlevered)}${Globals.superscript(1)}`, options: {colSpan: 4, className: "sz-center"} });
        header_2.push({ text: _ta(ETranslation.revenue_growth, " [% p.a.]"), options: {colSpan: 4, className: "sz-center"} });
        header_2.push({ text: _t(ETranslation.ebitda_margin) + " [%]", options: {colSpan: 4, className: "sz-center"} });

        const header_1: ISzTableColumn[] = [];
        header_1.push({ text: ""});
        header_1.push({ text: "#"});
        header_1.push({ text: "Name"});
        header_1.push({ text: "", options: {style: {borderRight: "4px solid #ffffff"}}});

        header_1.push({ text: _t(ETranslation.sector), options: {className: "sz-right"} });
        header_1.push({ text: "Peer", options: {className: "sz-right"} });
        header_1.push({ text: "Δ", options: {className: "sz-right"} });
        header_1.push({ text: `Delta${Globals.superscript(2)}`, options: {className: "sz-right"} });

        header_1.push({ text: _t(ETranslation.sector), options: {className: "sz-right"} });
        header_1.push({ text: "Peer", options: {className: "sz-right"} });
        header_1.push({ text: "Δ", options: {className: "sz-right"} });
        header_1.push({ text: `Delta${Globals.superscript(2)}`, options: {className: "sz-right"} });

        header_1.push({ text: _t(ETranslation.sector), options: {className: "sz-right"} });
        header_1.push({ text: "Peer", options: {className: "sz-right"} });
        header_1.push({ text: "Δ", options: {className: "sz-right"} });
        header_1.push({ text: `Delta${Globals.superscript(2)}`, options: {className: "sz-right"} });

        const columns: ISzTableColumn[] = [aktion_col];
        columns.push({ ...SzTableHelper.columnFunction(EIndexFunction.$row_num), options: {style: {width: 10} }}); // index
        columns.push(SzTableHelper.columnIndex(1)); // unternehmen
        columns.push({...SzTableHelper.columnIndex(2), options: {style: {borderRight: "4px solid #ffffff", width: 220}}}); // sector

        columns.push({ ...SzTableHelper.columnMoney(4, 2, {style: {width: 60}})}); // sector beta unlevered
        columns.push({ ...SzTableHelper.columnMoney(5, 2, {style: {width: 60}})}); // peer beta unlevered
        columns.push({ ...SzTableHelper.columnDelta(6, 2, {style: {width: 60}})}); // peer - sector
        columns.push(mk_bar_col(6, min, max, false));

        columns.push({ ...SzTableHelper.columnMoney("8", 2, {style: {width: 60}})}); // sector beta unlevered
        columns.push({ ...SzTableHelper.columnMoney("9", 2, {style: {width: 60}})}); // peer beta unlevered
        columns.push({ ...SzTableHelper.columnDelta("10", 2, {style: {width: 60}})}); // peer - sector
        columns.push(mk_bar_col(10, s_min, s_max));

        columns.push({ ...SzTableHelper.columnMoney("11", 2, {style: {width: 60}})}); // sector beta unlevered
        columns.push({ ...SzTableHelper.columnMoney("12", 2, {style: {width: 60}})}); // peer beta unlevered
        columns.push({ ...SzTableHelper.columnDelta("13", 2, {style: {width: 60}})}); // peer - sector
        columns.push(mk_bar_col(13, e_min, e_max));

        const footerData =  TableStatistics.getTableStatistics(this.table_data, {
            pack: true,
            headColumn: 0,
            columns: [4,5,6,  8,9,10,  11,12,13],
        });
        const footer: ISzTableColumn[] = [];
        footer.push({index: "", options: { className: "strong", style: {borderRight: "4px solid #ffffff"}}});
        footer.push({index: "0", options: { className: "strong", colSpan: 3, style: {borderRight: "4px solid #ffffff"}}});

        footer.push({ ...SzTableHelper.columnMoney("1", 2, {style: {width: 60}})});
        footer.push({ ...SzTableHelper.columnMoney("2", 2, {style: {width: 60}})});
        footer.push({ ...SzTableHelper.columnDelta("3", 2, {style: {width: 60}})});
        footer.push(mk_bar_col(3, min, max, false));


        footer.push({ ...SzTableHelper.columnMoney("4", 2, {style: {width: 60}})});
        footer.push({ ...SzTableHelper.columnMoney("5", 2, {style: {width: 60}})});
        footer.push({ ...SzTableHelper.columnDelta("6", 2, {style: {width: 60}})});
        footer.push(mk_bar_col(6, s_min, s_max));

        footer.push({ ...SzTableHelper.columnMoney("7", 2, {style: {width: 60}})});
        footer.push({ ...SzTableHelper.columnMoney("8", 2, {style: {width: 60}})});
        footer.push({ ...SzTableHelper.columnDelta("9", 2, {style: {width: 60}})});
        footer.push(mk_bar_col(9, e_min, e_max));

        // console.error(footerData);
        // console.error(footer);
        const tableProps: ISzTableProperties<any[]> = {
            colCount: columns.length,
            data: this.table_data.filter((row)=>!BetaFilterState.isCanceled(row[0], this.data)),
            header: [ header_2, header_1 ],
            rowHover: true,
            onSelectRow: (a, b) => {this.onSelectRow(a, b); },
            columns,
            footer,
            footerData,
            notes: [
                `${Globals.superscript(1)}) ${_t(ETranslation.beta_unlevered_based_on, BetaFilterState.getUnleveredBetaBase())}`,
                `${Globals.superscript(2)}) ${_t(ETranslation.median_largest_25)}`,
            ],
        };
        this.tableProps = tableProps;
        return (
            <div style={{paddingTop: 16}}>
                <div className={"sz-row"}>
                    <div style={{width: "100%", position: "relative", overflow: "hidden" }}>
                        <SzTable {...tableProps} />
                    </div>
                </div>
                {renderDebtDlg(this)}
            </div>
        );
    }

    private onSelectRow(row: number, dataRow: any) {
        const cid = dataRow[0];
        selectRow(this, cid);
    }

    public getPdfPage(): any[] {
        const content = [];
        PdfInsertElement.page_header(content, _t(ETranslation.sector_tracker));
        const widths: (number | "auto" | "*")[]= (new Array(15)).fill("auto").map((a,i)=>i === 1 ? "*" : "auto");
        // svg columns width
        const svg_width = 75;
        widths[6] = svg_width;
        widths[10] = svg_width;
        widths[14] = svg_width;

        const table = new PdfTable(content, widths);
        table.addHeaderRow();
        table.addHeader().text(_t(ETranslation.company)).colSpan(2)
            .style().alignment("left");
        table.addHeader().text(_t(ETranslation.sector)).style().border([EBorder.right]);

        table.addHeader().text(_t(ETranslation.beta_unlevered)).colSpan(4)
            .style().alignment("center").border([EBorder.right]);

        table.addHeader().text(_ta(ETranslation.revenue_growth, " [% p.a.]")).colSpan(4)
            .style().alignment("center").border([EBorder.right]);

        table.addHeader().text(_t(ETranslation.ebitda_margin)+" [%]").colSpan(4)
            .style().alignment("center");

        // zweite header zeile
        table.addHeaderRow();
        //
        table.addHeader().text("#").style().alignment("right");
        table.addHeader().text("Name").style();
        table.addHeader().text("").style().border([EBorder.right]);

        table.addHeader().text(_t(ETranslation.sector)).style().alignment("right");
        table.addHeader().text("Peer").style().alignment("right");
        table.addHeader().text("Δ").style().alignment("right");
        table.addHeader().text("Delta").style().alignment("right").border([EBorder.right]);

        table.addHeader().text(_t(ETranslation.sector)).style().alignment("right");
        table.addHeader().text("Peer").style().alignment("right");
        table.addHeader().text("Δ").style().alignment("right");
        table.addHeader().text("Delta").style().alignment("right").border([EBorder.right]);

        table.addHeader().text(_t(ETranslation.sector)).style().alignment("right");
        table.addHeader().text("Peer").style().alignment("right");
        table.addHeader().text("Δ").style().alignment("right");
        table.addHeader().text("Delta").style().alignment("right");

        // header ende
        table.addColumn(EPdfColumnDataType.row_num);
        table.addColumn(EPdfColumnDataType.text).index(1);
        table.addColumn(EPdfColumnDataType.text).index(2).style().border([EBorder.right]); // sector


        const [min, max, padding_right] = bar_padding(this.min_delta, this.max_delta);
        const [e_min, e_max, e_padding_right] = bar_padding(this.min_ebitda_margin, this.max_ebitda_margin);
        const [s_min, s_max, s_padding_right] = bar_padding(this.min_sales_growth, this.max_sales_growth);

        table.addColumn(EPdfColumnDataType.number, 2).index(4);
        table.addColumn(EPdfColumnDataType.number, 2).index(5);
        table.addColumn(EPdfColumnDataType.delta, 2).index(6);
        table.addColumn(EPdfColumnDataType.number, 2).index(6)
            .override_content(make_bar_svg(6, min, max, svg_width, {minus: "#137cbd", plus: "#DB3737"}))
            .style().border([EBorder.right]);

        table.addColumn(EPdfColumnDataType.number, 2).index(8);
        table.addColumn(EPdfColumnDataType.number, 2).index(9);
        table.addColumn(EPdfColumnDataType.delta, 2).index(10);
        table.addColumn(EPdfColumnDataType.number, 2).index(10)
            .override_content(make_bar_svg(10, s_min, s_max, svg_width, {minus: "#DB3737", plus: "#137cbd"}))
            .style().border([EBorder.right]);

        table.addColumn(EPdfColumnDataType.number, 2).index(11);
        table.addColumn(EPdfColumnDataType.number, 2).index(12);
        table.addColumn(EPdfColumnDataType.delta, 2).index(13);
        table.addColumn(EPdfColumnDataType.number, 2).index(13)
            .override_content(make_bar_svg(13, e_min, e_max, svg_width, {minus: "#DB3737", plus: "#137cbd"}));



        table.addFooter(EPdfColumnDataType.text).index(0).colSpan(3);

        table.addFooter(EPdfColumnDataType.number, 2).index(1);
        table.addFooter(EPdfColumnDataType.number, 2).index(2);
        table.addFooter(EPdfColumnDataType.delta, 2).index(3);
        table.addFooter(EPdfColumnDataType.number, 2).index(3)
            .override_content(make_bar_svg(3, min, max, svg_width, {minus: "#137cbd", plus: "#DB3737"}))
            .style().border([EBorder.right]);

        table.addFooter(EPdfColumnDataType.number, 2).index(4);
        table.addFooter(EPdfColumnDataType.number, 2).index(5);
        table.addFooter(EPdfColumnDataType.delta, 2).index(6);
        table.addFooter(EPdfColumnDataType.number, 2).index(6)
            .override_content(make_bar_svg(6, s_min, s_max, svg_width, {minus: "#DB3737", plus: "#137cbd"}))
            .style().border([EBorder.right]);

        table.addFooter(EPdfColumnDataType.number, 2).index(7);
        table.addFooter(EPdfColumnDataType.number, 2).index(8);
        table.addFooter(EPdfColumnDataType.delta, 2).index(9);
        table.addFooter(EPdfColumnDataType.number, 2).index(9)
            .override_content(make_bar_svg(9, e_min, e_max, svg_width, {minus: "#DB3737", plus: "#137cbd"}));

        table.addRowStyle(1).border([EBorder.bottom]);
        table.addRowStyle(1 + this.tableProps.data.length).border([EBorder.bottom]);

        table.addRowStyle(2 + this.tableProps.data.length).bold(true);
        table.addRowStyle(2 + this.tableProps.data.length + 3).bold(true);

        table.populateTable(this.tableProps.data, this.tableProps.footerData);

        return content;
    }
}
