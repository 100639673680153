import {Button, Icon, Intent, Menu, MenuItem, Popover} from "@blueprintjs/core";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {IToolBarParameter} from "../../models/IToolBarParameter";
import {ToolBarParameter} from "./ToolBarParameter";
import {IconNames} from "@blueprintjs/icons";
import {PARAMETER_HELP} from "../../const/ParameterHelpText";

const toStr = (a)=>{
    if(Array.isArray(a)){
        return a.join(",");
    }else{
        return a;
    }
}

export abstract class SingleSelectParameter extends ToolBarParameter<IToolBarParameter> {
    private readonly popover: React.RefObject<Popover>;
    protected constructor(props: IToolBarParameter, context: any) {
        super(props, context);
        this.popover = React.createRef();
    }

    protected getLabel() {
        const selectedValue = this.state.selectedValue;
        const option = this.state.options.find((item) =>{
            if(Array.isArray(item.value) && Array.isArray(selectedValue)){
                return item.value.join(",") === selectedValue.join(",");
            }
            return item.value === selectedValue;
        });
        return option ? option.label : "—";
    }

    protected abstract getOptions(): Promise<any>;
    protected abstract getSelectedValue(): Promise<any>;
    protected async initialize() {
        const options = await this.getOptions();
        const selectedValue = await this.getSelectedValue();
        return {
            options,
            selectedValue,
        };
    }
    protected onValueChanged(v) {
        //
    }
    protected renderHint() {
        return null;
    }
    protected getWidthClass(): string {
        return "";
    }
    protected renderContent() {
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        const widthClass = this.getWidthClass();
        return (
            <Popover
                minimal={true}
                position={"bottom-left"}
                modifiers={popper}
                popoverClassName={`no-max-width ${widthClass}`}
                ref={this.popover}
                content={this.getMenu()}
                disabled={this.tp_disabled}
            >
                <Button intent={Intent.NONE} minimal={true} className={"sz-toolbar-button"} icon={this.icon} disabled={this.tp_disabled}>
                    {this.renderName()}
                    <div className={"sz-property-value"}>{this.safeGetLabel()}</div>
                </Button>
            </Popover>
        );
    }

    protected getMenu() {
        let cKey = 0;
        const disabled = this.disabled;
        if (disabled) {
            return this.renderNoOp();
        }
        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return <Icon icon={IconNames.BLANK}/>;
            }
        };
        return (
            <div>
                <Menu className={this.config.multiColumnsClass ? this.config.multiColumnsClass : ""}>
                    {
                        this.state.options.map((item, index) => {
                            const selected =  toStr(item.value) === toStr(this.state.selectedValue);
                            return(<MenuItem disabled={disabled} key={"selection" + (cKey++)} className={ selected ? "sz-option-selected" : undefined} intent={selected ? "primary" : "none"} labelElement={getIcon(selected)}  text={item.label} onClick={() => { this.setOption(item.value); }} />);
                        })
                    }
                </Menu>
                {this.renderHint()}
                {this.renderHelpText(PARAMETER_HELP(this.props.primaryComponent), "fit-content")}
            </div>
        );
    }

    protected setOption(value) {
        if (value === this.state.selectedValue) {
            return;
        }
        this.onValueChanged(value);
        this.popover.current.setState({
            isOpen: false,
        });
        this.setState({
            selectedValue: value,
        });
        this.fireChanges([{
            value: [value],
            component: this.props.primaryComponent,
        }]);
    }
}
