import {EParameters} from "../models/EParameters";
import {SessionStore} from "./SessionStore";
import {ICalcCreditSpreadResult} from "../helpers/CalcCreditSpreadIndication";
import {Ratings} from "./Ratings";


export type CreditSpreadMetric = {
    caption: string,
    field: string,
    weight: number,
};

const mk_slots = (start_stop: number[], step_size)=>{
    const r = [];
    for(let i = start_stop[0]; i<=start_stop[1]; i+=step_size){
        r.push(i);
    }
    return r;
};
const max_y = 8;
export class CreditSpreadOptions {
    public static get_rating_idx(rating: string){
        return Ratings.s_and_p_mapped_to_num[rating];

        let idx = 1000;
        switch(rating){
            case "Aaa": idx = 0; break;
            case "Aa1":
            case "Aa2":
            case "Aa3": idx = 3; break;
            case "A1":
            case "A2":
            case "A3": idx = 6; break;
            case "Baa1":
            case "Baa2":
            case "Baa3": idx = 9; break;
            case "Ba1":
            case "Ba2":
            case "Ba3": idx = 12; break;
            case "B1":
            case "B2":
            case "B3": idx = 15; break;
            case "0": idx = -1; break;
        }
        return idx;
    }
    public static in_range(x_value: number, indication: ICalcCreditSpreadResult){
        return indication
            && indication.x_range.from <= x_value && indication.x_range.till >= x_value;
    }
    public static in_box_from_till(x_value: number, y_value: number, x_range: {from: number; till: number}, y_range: {from: number; till: number}){
        return x_range.from <= x_value && x_range.till >= x_value
            && y_range.from <= y_value && y_range.till > y_value;
    }
    public static in_box(x_value: number, y_value: number, indication: ICalcCreditSpreadResult){
        return indication
            && CreditSpreadOptions.in_box_from_till(x_value, y_value, indication.x_range, indication.y_range);
    }
    public static getWeights(){
        let weights = SessionStore.get(EParameters.CreditSpreadMetricWeightsParameter);
        if(!Array.isArray(weights)){
            weights = [.2,.2,.2,.2,.2];
        }
        if(weights.length!==5){
            weights = [.2,.2,.2,.2,.2];
        }
        return weights;
    }
    public static calcWeighted(v: number, index: number){
        const weights = CreditSpreadOptions.getWeights();
        if(!weights[index]){
            return NaN;
        }
        return v * weights[index];
    }
    public static getMetricsKeys(): string[]{
        const metrics = CreditSpreadOptions.getMetrics();
        return metrics.filter((m)=> m.weight>0).map((m)=> m.field);
    }
    public static getMetrics(): CreditSpreadMetric[]{
        const session_metrics = SessionStore.get(EParameters.CreditSpreadMetricParameter);
        const metrics = session_metrics ? session_metrics : {};
        const r: CreditSpreadMetric[] = [];
        const groups = [];
        let current = [];
        CreditSpreadOptions.use_ratio.forEach((key)=>{
            if(key.startsWith("#")){
                current = [];
                groups.push({
                    caption: key.substring(1),
                    items: current,
                });
            }else{
                current.push(key);
            }
        });
        const weights = CreditSpreadOptions.getWeights();
        groups.forEach((group, group_index)=>{
            if(metrics[group.caption] === "0"){
                metrics[group.caption] = undefined;
            }
            const selected = metrics[group.caption] ? metrics[group.caption] : group.items[0];
            const weight = weights[group_index];

            r.push({
                caption: group.caption,
                field: selected,
                weight,
            });

        });
        return r;
    }
    public static limits = {
        ffo_debt_ratio: {x: [-20,100], y: [0, max_y]},
        debt_ebitda_ratio: {x: [0,10], y: [0, max_y]},
        net_debt_ebitda: {x: [0,10], y: [0, max_y]},
        ebit_interest_ratio: {x: [-10,60], y: [0, max_y]},
        ebitda_interest: {x: [-10,60], y: [0, max_y]},
        ffo_interest: {x: [-10,60], y: [0, max_y]},
        roce: {x: [-20,50], y: [0, max_y]},
        roe: {x: [-40,100], y: [0, max_y]},
        eq_ratio: {x: [-20,100], y: [0, max_y]},
        anlagenintensitaet: {x: [0,100], y: [0, max_y]},
        gross_sales_ratio: {x: [-25,100], y: [0, max_y]},
        ebitda_sales_ratio: {x: [-10,70], y: [0, max_y]},
        ebit_sales_ratio: {x: [-20,60], y: [0, max_y]},
        netincome_sales_ratio: {x: [-30,50], y: [0, max_y]},
        gearing: {x: [-200,500], y: [0, max_y]},
        ebit_oper_sales_ratio: {x: [-50,100], y: [0, max_y]},
        ebitda_oper_sales_ratio: {x: [-35,100], y: [0, max_y]},
        anlagendeckungsgrad: {x: [0,150], y: [0, max_y]},

        xch_sales: {x: [0,100000], y: [0, max_y]},
        anlagevermoegen: {x: [0,100000], y: [0, max_y]},
        xch_ppe_net: {x: [0,30000], y: [0, max_y]},
        xch_assets: {x: [0,100000], y: [0, max_y]},
    };
    public static slots = {
        xch_ppe_net: [0, 5000, 10000, 15000, 20000, 25000, 30000],

        xch_sales: mk_slots(CreditSpreadOptions.limits.xch_sales.x, 20000),
        anlagevermoegen: mk_slots(CreditSpreadOptions.limits.anlagevermoegen.x, 20000),
        xch_assets: mk_slots(CreditSpreadOptions.limits.xch_assets.x, 20000),

        ebitda_sales_ratio: mk_slots(CreditSpreadOptions.limits.ebitda_sales_ratio.x, 10),
        ebit_sales_ratio: mk_slots(CreditSpreadOptions.limits.ebit_sales_ratio.x, 10),
        netincome_sales_ratio: mk_slots(CreditSpreadOptions.limits.netincome_sales_ratio.x, 10),

        roce: mk_slots(CreditSpreadOptions.limits.roce.x, 10),
        roe: mk_slots(CreditSpreadOptions.limits.roe.x, 20),
        net_debt_ebitda: mk_slots(CreditSpreadOptions.limits.net_debt_ebitda.x, 2),
        debt_ebitda_ratio: mk_slots(CreditSpreadOptions.limits.debt_ebitda_ratio.x, 2),
        ffo_debt_ratio: mk_slots(CreditSpreadOptions.limits.ffo_debt_ratio.x, 20),
        // Zinsdeckung
        ebitda_interest: mk_slots(CreditSpreadOptions.limits.ebitda_interest.x, 10),
        ebit_interest_ratio: mk_slots(CreditSpreadOptions.limits.ebit_interest_ratio.x, 10),
        ffo_interest: mk_slots(CreditSpreadOptions.limits.ffo_interest.x, 10),

        anlagendeckungsgrad: mk_slots(CreditSpreadOptions.limits.anlagendeckungsgrad.x, 20),
        anlagenintensitaet: mk_slots(CreditSpreadOptions.limits.anlagenintensitaet.x, 20),
        eq_ratio: mk_slots(CreditSpreadOptions.limits.eq_ratio.x, 20),

        gearing: mk_slots(CreditSpreadOptions.limits.gearing.x, 100),
    }
    public static credit_spread_decisions_step = 0;
    public static get credit_spread_decisions(){
        const middle = parseFloat(SessionStore.get(EParameters.CreditSpreadAnalysisIntervalParameter) ? SessionStore.get(EParameters.CreditSpreadAnalysisIntervalParameter) : .2);
        const r = {
            3:   {from: 0    , till: .20},
            4:   {from: 0.05    , till: .25},
            5:   {from: 0.10 , till: .30},
            6:   {from: 0.15 , till: .35},
            7:   {from: 0.20 , till: .4},
            8:   {from: 0.25 , till: .45},
            9:  {from: 0.4 , till: .6},
            10:  {from: 0.47 , till: .65},
            11:  {from: 0.54  , till: .7},
            12:  {from: 0.61  , till: .75},
            13:  {from: 0.68  , till: .8},
            14:  {from: 0.75  , till: .85},
            15:  {from: 0.82 , till: .90},
        };
        r["9"] = {
            from: .5 - (middle/2),
            till: .5 + (middle/2),
        };
        const step = (1 - r["9"].till) / 6;
        CreditSpreadOptions.credit_spread_decisions_step = step;
        [9,10,11,12,13,14].forEach((i)=>{
            r[i + 1].from = r[i].from + step;
            if(r[i + 1].from < 0 ){
                r[i + 1].from = 0;
            }
            r[i + 1].till = r[i + 1].from + middle;
            if(r[i + 1].till > 1 ){
                r[i + 1].till = 1;
            }
        });
        [9,8,7,6,5,4].forEach((i)=>{
            r[i - 1].from = r[i].from - step;
            if(r[i - 1].from < 0 ){
                r[i - 1].from = 0;
            }
            r[i - 1].till = r[i - 1].from + middle;
            if(r[i - 1].till > 1 ){
                r[i - 1].till = 1;
            }
        });
        // console.error(r);
        return r;
    }
    public static band_widths = {
        xch_sales: 5000,
        xch_assets: 5000,
        anlagevermoegen: 5000,
        ebit_sales_ratio: 5,
        netincome_sales_ratio: 5,
        roce: 3,
        roe: 3,
        net_debt_ebitda: 0.5,
        ffo_debt_ratio: 10,
        ebit_interest_ratio: 3,
        ffo_interest: 3,
        anlagendeckungsgrad: 10,
        anlagenintensitaet: 10,
        eq_ratio: 10,
        gearing: 25,
    };
    public static peter_neu = "#Schuldentragfähigkeit,net_debt_ebitda,ffo_debt_ratio,#Zinsdeckung,ebit_interest_ratio,ffo_interest,#Kapitalstruktur,gearing,eq_ratio,#Profitabilität,ebitda_sales_ratio,ebit_sales_ratio,netincome_sales_ratio,roce,roe,#Unternehmensgröße,xch_sales,xch_assets,anlagevermoegen".split(",");
    public static use_ratio = CreditSpreadOptions.peter_neu;
}
