import {SVGProps} from "react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as saveSvgAsPng from "save-svg-as-png";
import {v4} from "uuid";
import {SvgTextSize} from "../../helpers/SvgTextSize";
import {ISzSvgDataPoint} from "../../models/ISzSvgDataPoint";
import {AxisLabelsAuto} from "./helper/AxisLabelsAuto";
import {fromDateDecimal} from "../../tools/DateTools";
export enum ELineType {
    line = 1,
    rectangle,
}
export enum EXLabelAlign {
    atTick,
    betweenTick,
}
export interface ISelectedIndex {
    index: number;
    className: string;
}
export interface ISzSvgProperties {
    width: string;
    height: string;
    addClassName?: string;
    chartId?: string;
    xLabels: string[];
    xLabelsSkip: number;
    xAxisLabel: string;
    yTickStep: number;
    caption?: string;
    xTicks: number[];
    series?: any[];
    override_colors?: any[];
    legend_colors?: any[];
    skipRenderSeries?: number;
    noPath?: number[];
    concatHoles?: boolean;
    noDots?: boolean;
    seriesLegend: string[];
    legendWidth?: number;
    lineType?: ELineType;
    showLastDotLabel?: boolean;
    noGrid?: boolean;
    noTicks?: boolean;
    noTicksY?: boolean;
    noYAxis?: boolean;
    styleXLabels?: boolean;
    hoverLegend?: boolean;
    hideLegend?: boolean;
    noXHighLines?: boolean;
    ranges?: any[];
    digits?: number;
    xMaxOverride?: number;
    xMinOverride?: number;
    yMaxOverride?: number;
    yMinOverride?: number;
    yNiceMaxOverride?: number;
    avgSeriesIdx?: number;
    colorIdx?: number;
    paddingX?: number;
    paddingY?: number;
    selectedIndex?: ISelectedIndex;
    no10PxOffset?: boolean;
    isBeta?: boolean;
    mouseMoveXTrigger?: number;
    xLabelsAlign?: EXLabelAlign;
    onCutomRenderSeriesPath?(index: number, path: string[], series: ISzSvgDataPoint[], xFactor: number, yFactor: number, offsetH: number);
    onKeyDown?(charCode: number, keyCode: number);
    onClick?(index: number);
    onCustomDrawings?(x_min: number, x_max: number, y_min: number, y_max: number, xFactor: number, yFactor: number);
    onAfterRendered?();
    onMouseMoveX?(idx: number, container: HTMLElement, values: number[], x_value: number, y_values: number[]);
    onMouseMoveX2?(container: HTMLElement, data: any[], width: number, height: number);
    onMouseClick?();
}

export class SzSvg extends React.Component<ISzSvgProperties, any> {

    private readonly svg: React.RefObject<HTMLDivElement> = null;
    private observer: IntersectionObserver;

    private readonly px10: number;

    constructor(props: any, context: any) {
        super(props, context);
        this.svg = React.createRef();
        this.px10 = this.props.no10PxOffset ? 0 : 10;
        window.addEventListener("resize", () => this.resize());
    }
    public render() {
        return (
            <div ref={this.svg} id={this.props.chartId} style={{ width: this.props.width, height: this.props.height }}/>
        );
    }
    public componentDidMount(): void {
        const width: number = this.svg.current.clientWidth;
        const height: number = this.svg.current.clientHeight;
        ReactDOM.render(this.renderSvg(width, height), this.svg.current);
        if (this.props.onAfterRendered) {
            this.props.onAfterRendered();
        }
        this.observer = new IntersectionObserver((items, observer) => { this.onIntersectionObserver(items, observer); });
        this.observer.observe(this.svg.current);
    }
    public componentWillUnmount(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    public renderSvg(width, height) {
        const padding = 70;
        const x_padd = this.props.noTicksY ? 40 : 0;
        const x = this.props.paddingX !== undefined ? this.props.paddingX : padding - x_padd;
        const y = this.props.paddingY !== undefined ? this.props.paddingY : padding;

        const {x_min, x_max, y_min, y_max, deltaX, deltaY, xFactor, yFactor} = this.getLimits(width - x, height - y);
        const addSpace = y_min === 0 ? 0 : 0;
        const offH = (height - y) + (y_min * yFactor) + addSpace; // - 20;
        const x_offset = this.props.noTicksY ? this.px10 : 30;

        let last_idx_mouse_x = -10;
        const ym = Math.trunc(yFactor * y_max);

        const renderItems = (items: any[]) => {
            if (!Array.isArray(items)) {
                return null;
            }
            return (
                <g>{items.map( (i) => i )}</g>
            );
        };

        const onMouseMoveX = this.props.onMouseMoveX || this.props.onMouseMoveX2;

        const props: SVGProps<any> = {
            width: "100%",
            height,
            viewBox: `0 0 ${width} ${height}`,
            className: "sz-chart",
            focusable: true,
            tabIndex: 10,
            onClick: () => { if (this.props.onMouseClick) {this.props.onMouseClick(); } },
            onKeyUp: ( e ) => this.onKeyDown(e.charCode, e.keyCode),
            onMouseLeave: (e) => {
                // const svg: HTMLElement = e.currentTarget;
                // const layers = svg.querySelector("g.mouse_move_x");
                // ReactDOM.unmountComponentAtNode(layers);
                if (!onMouseMoveX) {
                    return;
                }
                const svg: HTMLElement = e.currentTarget;
                const layers = svg.querySelector("g.mouse_move_x");
                if (layers.childNodes.length) {
                    ReactDOM.unmountComponentAtNode(layers);
                }
            },
            onMouseMove: (e) => {
                if (!onMouseMoveX) {
                    return;
                }
                if (!Math.abs(e.movementX)) {
                    return;
                }
                const x_pos = e.nativeEvent.offsetX - (x_offset);
                const y_pos = ym - e.nativeEvent.offsetY;
                if(this.props.onMouseMoveX){
                    if (this.props.series && Array.isArray(this.props.series) && Array.isArray(this.props.series[0])) {
                        // index to x
                        const x_values = this.props.series[0];
                        x_values.forEach( (vx, idx) => {
                            const v_x = Math.trunc(vx.x * xFactor) + this.px10;
                            // console.error(v_x, x_pos, x_pos - 1, x_pos + 1);
                            if ( v_x >= x_pos - 1 && v_x <= x_pos + 1 ) {
                                if (last_idx_mouse_x === idx) {
                                    return;
                                }

                                last_idx_mouse_x = idx;
                                const svg: HTMLElement = e.currentTarget;
                                const layers = svg.querySelector("g.mouse_move_x");
                                const values: number[] = [];
                                const y_values: number[] = [];
                                this.props.series.forEach( (s) => {
                                    values.push(s[idx].y);
                                    const _y_pos = offH - Math.trunc(s[idx].y * yFactor);
                                    y_values.push( _y_pos );
                                } );
                                const items = this.props.onMouseMoveX(idx, svg , values, v_x, y_values);
                                if (layers.childNodes.length) {
                                    ReactDOM.unmountComponentAtNode(layers);
                                }
                                ReactDOM.render(renderItems(items), layers);
                            }

                        } );
                    }
                }
                if(this.props.onMouseMoveX2){
                    if (Array.isArray(this.props.series)) {
                        const ret_data = [];
                        this.props.series.forEach( (serie: ISzSvgDataPoint[], idx_serie)=>{
                            if(!Array.isArray(serie)){
                                return;
                            }
                            serie.forEach( (vx, idx_point) => {
                                const v_x = Math.trunc(vx.x * xFactor) + this.px10;
                                const v_y = Math.trunc(vx.y * yFactor) + this.px10;
                                if (
                                    v_x >= x_pos - 10 && v_x <= x_pos + 10 &&
                                    v_y >= y_pos - 10 && v_y <= y_pos + 10
                                ) {
                                    ret_data.push({
                                        serie: idx_serie,
                                        numDataPoint: idx_point,
                                        dataPoint: vx,
                                        x: Math.trunc(v_x),
                                        y: Math.trunc(offH - v_y),
                                    });
                                    // ret_data[idx_serie].push(vx);
                                    // console.error(vx);
                                }
                            });
                        });
                        // console.error(ret_data);
                        const svg: HTMLElement = e.currentTarget;
                        const layers = svg.querySelector("g.mouse_move_x");
                        const items = this.props.onMouseMoveX2(svg, ret_data, width- x, height - y);
                        if(!items){
                            return;
                        }
                        if (layers.childNodes.length) {
                            ReactDOM.unmountComponentAtNode(layers);
                        }
                        ReactDOM.render(renderItems(items), layers);
                    }
                }
            },
        };
        if (this.props.isBeta) {
            return (
                <div style={{ width: "100%", height, position: "relative", overflow: "hidden" }}>
                    <div className={"ribbon top right"} style={{right: -75, fontSize: "80%"}}>Beta Version</div>
                    <svg {...props}>
                        {this.renderAxis(width - x, height - y)}
                        {this.renderRanges(width - x, height - y)}
                        {this.renderData(width - x, height - y)}
                        {this.renderDots(width - x, height - y)}
                        {this.renderCustom(width - x, height - y)}
                        {this.renderLegends(width - x, height - y)}
                        {this.renderXMouse(width - x, height - y)}
                    </svg>
                </div>
            );
        }

        return (
            <svg {...props}>
                {this.renderAxis(width - x, height - y)}
                {this.renderRanges(width - x, height - y)}
                {this.renderData(width - x, height - y)}
                {this.renderDots(width - x, height - y)}
                {this.renderCustom(width - x, height - y)}
                {this.renderLegends(width - x, height - y)}
                {this.renderXMouse(width - x, height - y)}
            </svg>
        );
    }
    public resize() {
        if (!this.svg.current) {
            return;
        }
        const width: number = this.svg.current.clientWidth;
        const height: number = this.svg.current.clientHeight;
        ReactDOM.render(this.renderSvg(width, height), this.svg.current);
        if (this.props.onAfterRendered) {
            this.props.onAfterRendered();
        }
    }
    public async getSvgData() {
        const e: HTMLElement = document.getElementById("hiddenSvgContainer");
        // console.error(e);
        ReactDOM.render(this.renderSvg(1575, 720), e);
        const svg = e.querySelector("svg");
        if (!svg) {
            return null;
        }
        const result = await saveSvgAsPng.svgAsPngUri(svg, {
            scale: 2,
            encoderOptions: 1,
        });
        ReactDOM.unmountComponentAtNode(e);
        return result;
    }
    private getPath(series, xFactor, yFactor, offH) {
        const lineType = this.props.lineType ? this.props.lineType : ELineType.line;
        let op = "M";
        const path: string[] = [];

        let _x = NaN;
        let _y = NaN;
        series.forEach((e) => {
            const v_x = e.x === null ? NaN : e.x;
            const v_y = e.y === null ? NaN : e.y;
            const x = Math.trunc(v_x * xFactor) + this.px10;
            const y = offH - Math.trunc(v_y * yFactor);

            if (this.props.concatHoles && (isNaN(y) || isNaN(x))) {
                return;
            }

            if ( !isNaN(_y) && !isNaN(_x) && lineType === ELineType.rectangle) {
                path.push(`L${x} ${_y}`);
            }

            if ( isNaN(y) || isNaN(x)) {
                op = "M";
                _x = NaN;
                _y = NaN;
                return;
            }

            path.push(`${op}${x} ${y}`);
            op = "L";

            _x = x;
            _y = y;
        });
        return path;
    }
    private getDots(series, xFactor, yFactor, offH) {
        const dots = [];
        series.forEach((e) => {
            if ( e.x === null) {
                e.x = NaN;
            }
            if ( e.y === null) {
                e.y = NaN;
            }
            const x = Math.trunc(e.x * xFactor) + this.px10;
            const y = offH - Math.trunc(e.y * yFactor);

            if (isNaN(y) || isNaN(x) ) {
                // return;
            }

            dots.push({x, y, value: e.y});
        });
        return dots;
    }
    private getLimits(width: number, height: number) {
        let x_min = 0;
        let x_max = 0;
        let y_min = 0;
        let y_max = 0;

        this.props.series.forEach((serie) => {
            serie.forEach( (e) => {
                if (e.x < x_min && isFinite(e.x)) {
                    x_min = e.x;
                }
                if (e.x > x_max && isFinite(e.x)) {
                    x_max = e.x;
                }

                if (e.y < y_min && isFinite(e.y)) {
                    y_min = e.y;
                }
                if (e.y > y_max && isFinite(e.y)) {
                    y_max = e.y;
                }

            } );
        });

        x_max = this.props.xMaxOverride !== undefined ? Math.max(x_max, this.props.xMaxOverride) : x_max;
        y_max = this.props.yMaxOverride !== undefined ? Math.max(y_max, this.props.yMaxOverride) : y_max;
        y_min = this.nice(y_min); // this.props.yMinOverride === undefined  ? this.nice(y_min) : this.props.yMinOverride;
        y_max = this.props.yNiceMaxOverride !== undefined ?  this.props.yNiceMaxOverride : this.nice(y_max);
        if (y_min === 0 && y_max === 0) {
            y_min = -1;
            y_max = 1;
        }

        const deltaY = y_min < 0 ? y_max - y_min : y_max;
        const deltaX = x_min < 0 ? x_max - x_min : x_max;

        const yFactor =  (height) / deltaY;
        const xFactor =  (width - 20) / deltaX;

        return {x_min, x_max, y_min, y_max, deltaX, deltaY, xFactor, yFactor};
    }

    private nice(v: number) {
        const z = v < 0 ? -1 : 1;
        const a = Math.abs(v);
        const r = Math.ceil(a);
        const c = r - (Math.trunc(r / 10) * 10);
        let p = 0;
        if (c > 5) {
           p = 10 - c;
        }
        if (c > 0 && c < 5 && r > 10) {
            p = 5 - c;
        }
        // console.error(a, z, r, c, p, "=", (Math.ceil(a) * z) + (p * z));
        return (Math.ceil(a) * z) + (p * z);
    }

    private renderLegends(width: number, height: number) {
        if (!this.props.seriesLegend) {
            return null;
        }
        const colorIdx = this.props.colorIdx ? this.props.colorIdx : 0;
        const lineHeight: number = 20;
        const useLegendWidth: number = this.props.legendWidth ? this.props.legendWidth : 150;
        const useLegendHeight: number = lineHeight * this.props.seriesLegend.length;
        const x: number = width - useLegendWidth;
        const y: number = height - (lineHeight * this.props.seriesLegend.length);
        const lineLength = 20;
        const renderLengend = (label, idx) => {
            const user_colors = this.props.legend_colors || this.props.override_colors;
            const idx_color = user_colors && user_colors[idx]!==undefined ? user_colors[idx] : idx + colorIdx;

            const showDotA = () => {
                return <circle cx={lineLength / 2} cy={lineHeight / 2} r={3} className={`sz-chart-dot sz-chart-dot-${idx_color}`} />;
            };
            const useLabel: string = label;
/*
            let labelRect: DOMRect = SvgTextSize.measureText(label);
            const needElip = labelRect && labelRect.width > useLegendWidth;
            while (labelRect && labelRect.width > useLegendWidth) {
                useLabel = useLabel.slice(0, -1);
                labelRect = SvgTextSize.measureText(useLabel + " ...");
            }
            if (needElip) {
                useLabel += " ...";
            }
*/
            return (
                <g transform={`translate(5 ${idx * lineHeight})`}>
                    <line x1={0} x2={lineLength} y1={lineHeight / 2} y2={lineHeight / 2} className={`sz-chart-line sz-chart-line-${idx_color}`}/>
                    {this.props.noDots ? null : showDotA()}
                    <text x={lineLength + 5} y={lineHeight / 2} className={"sz-legend-label"}>{useLabel}</text>
                </g>
            );
        };
        const uuid = v4();
        const cl = [];
        if(this.props.hoverLegend){
            cl.push("sz-legend-hide");
        }
        if(this.props.hideLegend){
            cl.push("sz-legend-initial-hide");
        }
        return (
            <g transform={`translate(${x} ${y})`} className={cl.join()}>
                <defs>
                    <clipPath id={`legend-clip_${uuid}`}>
                        <rect x={-3} y={-3} width={useLegendWidth} height={useLegendHeight} />
                    </clipPath>
                </defs>
                <rect x={0} y={0} width={useLegendWidth} height={useLegendHeight} className={"sz-legend-rect"}/>
                <g clipPath={`url(#legend-clip_${uuid})`}>
                    {this.props.seriesLegend.map( (label, idx) => renderLengend(label, idx) )}
                </g>
            </g>
        );
    }
    private renderAxis(width: number, height: number) {
        const offH = height; // - 20;
        const {x_min, x_max, y_min, y_max, deltaX, deltaY, xFactor, yFactor} = this.getLimits(width, height);

        const dates = [];
        let ccc = 1;
        this.props.series.forEach((serie) => {
            if (ccc === 1) {
                serie.forEach( (e) => dates.push(e.dataSet ? e.dataSet.date : ccc++));
            }
            return;
        });

        const calcy = new AxisLabelsAuto(y_min, y_max, height, this.props.yTickStep );
        const digits = this.props.digits ? this.props.digits : 2;
        const yAxsis = calcy.get(digits);
        const nullIdx = yAxsis.labels.findIndex((d) => "0" === d);
        const nullY = yAxsis.values[nullIdx];

        const y_values: number[] = yAxsis.values;
        const y_labels: string[] =  yAxsis.labels;
        const x_marks: string[] = [];
        const y_marks: string[] = [];
        const xGrid_lines: any[] = [];
        const xHigh_lines: any[] = [];

        y_values.forEach((y) => {
            y_marks.push(`M${0} ${y}`);
            y_marks.push(`L${5} ${y}`);
        });
        let mX1 = 0;
        let mX2 = 5;
        if (nullIdx !== 0) {
            mX1 = 5;
            mX2 = 5;
        }
        this.props.xTicks.forEach((tick, idx) => {
            xHigh_lines.push( (idx * xFactor) + this.px10);

            const x = (tick * xFactor) + this.px10;

            xGrid_lines.push(x);

            if (tick === undefined) {
                return;
            }

            x_marks.push(`M${x} ${nullY + mX1}`);
            x_marks.push(`L${x} ${nullY - mX2}`);
        });

        let path: string[] = [
            "M0 0",
            `L0 ${height}`,
            `M0 ${nullY}`,
            `L${width} ${nullY}`,
        ];
        if (this.props.noYAxis) {
            path = [
                `M0 ${nullY}`,
                `L${width} ${nullY}`,
            ];
        }

        let txtStartAnchor = "sz-x-label";
        let txtEndAnchor = "sz-x-label";
        if (this.props.styleXLabels) {
            txtStartAnchor = "sz-x-label-start";
            txtEndAnchor = "sz-x-label-end";
        }
        const x_pox = this.props.noTicksY ? this.px10 : 30;
        return (
            <g className={"sz-chart-axis"} transform={`translate(${x_pox}.5 10.5)`}>
                <text y={5} x={8} className={"sz-chart-caption"}>{this.props.caption}</text>
                {y_values.map((y, idx) => {
                    if (this.props.noGrid) {
                        return null;
                    }
                    return(<line x1={10} x2={width} y1={y} y2={y} className={"sz-chart-axis-y-grid"} />);
                } )}
                {xGrid_lines.map((x, idx) => {
                    if (this.props.noGrid) {
                        return null;
                    }
                    if (x === undefined || isNaN(x)) {
                        return null;
                    }
                    return(<line x1={x} x2={x} y1={offH} y2={0} className={"sz-chart-axis-y-grid v"} />);
                } )}
                <path d={path.join(" ")} />

                {this.props.noTicksY ? null : <path d={y_marks.join(" ")}/>}
                {y_values.map((y, idx) => {
                    if (this.props.noTicksY) {
                        return null;
                    }
                    if (this.props.no10PxOffset && nullIdx === 0 && idx === 0) {
                        return null;
                    }
                    return(<text x={-5} y={y} className={"sz-y-label"} style={{alignmentBaseline: idx === (y_values.length - 1) ? "hanging" : "central"}}>{y_labels[idx]}</text>);
                } )}
                {this.props.noTicks ? null : <path d={x_marks.join(" ")} />}
                {xGrid_lines.map((x, idx, a) => {
                    if (x === undefined || isNaN(x)) {
                        return null;
                    }
                    const isLast = idx + 1 === a.length ? txtEndAnchor : null;
                    const isStart = idx === 0 ? txtStartAnchor : null;
                    let useAnchor = isStart ? isStart : isLast;
                    const use_label = this.props.xLabels[idx];
                    if (this.props.xLabelsAlign === EXLabelAlign.betweenTick) {

                        if (idx === a.length - 1) {
                            // return null;
                        }

                        useAnchor = null;

                        const next_valid_idx = a.findIndex( (n_x, n_idx) => n_idx > idx && !isNaN(n_x));
                        if (next_valid_idx > -1) {
                            if (this.props.showLastDotLabel && next_valid_idx === a.length - 1) {
                                // use_label = this.props.xLabels[next_valid_idx] ? `${use_label} - ${this.props.xLabels[next_valid_idx]}` : use_label;
                                // useAnchor = this.props.xLabels[next_valid_idx] ? txtStartAnchor : null;
                            }
                            x = x + (a[next_valid_idx] - x) / 2;
                        } else {
                            x = x + (xFactor / 2);
                        }

                    }
                    return(
                    <text x={x} y={offH + 8} className={`sz-x-label ${useAnchor}`}>{idx >= this.props.xLabelsSkip ? use_label : ""}</text>
                ); } )}
                {xHigh_lines.map((x, idx, a) => {
                    if (this.props.noXHighLines) {
                        return null;
                    }
                    const label = fromDateDecimal(dates[idx]).asDeDate();
                    const rect: DOMRect = SvgTextSize.measureText(label, "sz-chart-label");
                    let textAnchor = "start";
                    let textX = x + 5;
                    if ( (x + rect.width + 10) > width) {
                        textAnchor = "end";
                        textX = x - 5;
                    }
                    return (
                        <g data-name={`sp${dates[idx]}`} className={"sz-high-line"}>
                            <line x1={x} x2={x} y1={offH} y2={0} />
                            <text y={0} x={textX} textAnchor={textAnchor} alignmentBaseline={"hanging"}>{label}</text>
                        </g>
                    );
                } )}
                <text y={ offH - 10 } x={width} className={"sz-x-axis-label"}>{this.props.xAxisLabel}</text>
            </g>
        );
    }
    private renderRanges(width: number, height: number) {
        if (!this.props.ranges) {
            return null;
        }

        const ranges = {};
        for (const r of this.props.ranges) {
            ranges[r.start] = parseInt(r.end, 10);
        }

        const offH = height; // - 20;
        const {x_min, x_max, y_min, y_max, deltaX, deltaY, xFactor, yFactor} = this.getLimits(width, height);

        const dates = [];
        let ccc = 1;
        this.props.series.forEach((serie) => {
            if (ccc === 1) {
                serie.forEach( (e) => dates.push(e.dataSet ? parseInt(e.dataSet.date, 10) : ccc++));
            }
            return;
        });

        const xGrid_lines: any[] = [];
        const rx = [];
        let cr = null;
        let last_start = null;
        this.props.xTicks.forEach((tick, idx) => {
            const x = (idx * xFactor) + this.px10;
            xGrid_lines.push(x);
            const current_date = dates[idx];
            const range_end = ranges[ "" + current_date ];
            if (cr) {
                cr.x2 = x;
            }
            if (range_end && last_start !== range_end) {
                last_start = range_end;
                cr = {
                    x1: x,
                    x2: undefined,
                    start: current_date,
                    end: range_end,
                };
                rx.push(cr);
            }
        });
        // console.error(rx);
        const x_pox = this.props.noTicksY ? this.px10 : 30;
        return (
            <g transform={`translate(${x_pox} 10)`}>
               {rx.map((r, idx, a) => {
                    return (
                        <rect data-name={`sp${r.start}`} className={"sz-high-rect"} x={r.x1} y={0} width={r.x2 - r.x1} height={offH}/>
                    );
                } )}
            </g>
        );

    }
    private renderXMouse(width: number, height: number) {
        const x_pox = this.props.noTicksY ? this.px10 : 30;
        return (
            <g className={"mouse_move_x no-pointer-events"} transform={`translate(${x_pox} 10)`} />
        );
    }
    private renderData(width: number, height: number) {
        const {x_min, x_max, y_min, y_max, deltaX, deltaY, xFactor, yFactor} = this.getLimits(width, height);
        const addSpace = y_min === 0 ? 0 : 0;
        const offH = height + (y_min * yFactor) + addSpace; // - 20;
        const x_pox = this.props.noTicksY ? this.px10 : 30;

        const paths = [];
        const customs = [];

        this.props.series.forEach((series, idx) => {
            const path = this.getPath(series, xFactor, yFactor, offH);
            if (this.props.onCutomRenderSeriesPath) {
                const cp = this.props.onCutomRenderSeriesPath(idx, path, series, xFactor, yFactor, offH);
                if(cp){
                    customs.push(cp);
                }
            }
            if ( isNaN(parseInt("" + this.props.skipRenderSeries, 10)) || idx > this.props.skipRenderSeries) {
                paths.push(path);
            }
        });

        const colorIdx = this.props.colorIdx ? this.props.colorIdx : 0;
        return (
            <g className={"sz-chart-data"} transform={`translate(${x_pox} 10)`}>
                {customs.map( (node, idx) => {
                    return node;
                })}
                {paths.map( (path, idx) => {
                    if(Array.isArray(this.props.noPath) && this.props.noPath.includes(idx)){
                        return;
                    }
                    const col_idx = this.props.override_colors && this.props.override_colors[idx]!==undefined ? this.props.override_colors[idx] : idx + colorIdx;
                    const _classes = ["sz-chart-line"];
                    if(this.props.avgSeriesIdx === idx){
                        _classes.push(`sz-chart-line-avg`);
                    }else{
                        _classes.push(`sz-chart-line-${col_idx}`);
                    }
                    // sz-chart-line-${ this.props.avgSeriesIdx === idx + colorIdx ? "avg" : (idx + colorIdx) }`;
                    if (this.props && this.props.selectedIndex && this.props.selectedIndex.index === idx + 1) {
                        _classes.push(this.props.selectedIndex.className);
                    }
                    return (
                        <path d={path.join(" ")} className={_classes.join(" ")} onClick={() => this.props.onClick ? this.props.onClick(this.props.avgSeriesIdx === idx ? -1 : (idx) ) : null } />
                    );
                })}
            </g>
        );
    }
    private renderDots(width: number, height: number) {
        if (this.props.noDots === true) {
            return null;
        }
        const {x_min, x_max, y_min, y_max, deltaX, deltaY, xFactor, yFactor} = this.getLimits(width, height);

        const addSpace = y_min === 0 ? 0 : 0;
        const offH = height + (y_min * yFactor) + addSpace; // - 20;

        const dotsSeries = [];
        this.props.series.forEach((series, idx) => {
            if ( isNaN(parseInt("" + this.props.skipRenderSeries, 10)) || idx > this.props.skipRenderSeries) {
                dotsSeries.push(this.getDots(series, xFactor, yFactor, offH));
            }
        });
        const renderDot = (dots, line_number, d, dot_number) => {
            const d_x = d.x === null ? NaN : d.x;
            const d_y = d.y === null ? NaN : d.y;
            if ( isNaN(d_x) || isNaN(d_y) ) {
                return null;
            }

            const digits = this.props.digits ? this.props.digits : 2;
            const formatter = (new Intl.NumberFormat("de-de", {  maximumFractionDigits: digits, minimumFractionDigits: digits })).format;
            const isLast = dots.length === dot_number + 1;
            const renderLastDotLabel = () => {
                if (!this.props.showLastDotLabel || !isLast) {
                    return null;
                }
                return <text className={"sz-last-dot-label"} x={d.x + 5} y={d.y}>{formatter(d.value)}</text>;
            };
            const colorIdx = this.props.colorIdx ? this.props.colorIdx : 0;
            const col_idx = this.props.override_colors && this.props.override_colors[line_number]!==undefined ? this.props.override_colors[line_number] : line_number + colorIdx;
            return (
                <g>
                    <circle cy={d.y} cx={d.x} r={3} className={`sz-chart-dot sz-dot-number-${dot_number} sz-chart-dot-${this.props.avgSeriesIdx === col_idx ? "avg" : (col_idx) }`} />
                    {renderLastDotLabel()}
                </g>
            );
        };
        const x_pox = this.props.noTicksY ? this.px10 : 30;
        return (
            <g className={"sz-chart-dots"} transform={`translate(${x_pox} 10)`}>
                {dotsSeries.map( (dots, line_number) => dots.map( (d, dot_number) => renderDot(dots, line_number, d, dot_number) ))}
            </g>
        );
    }
    private renderCustom(width: number, height: number) {
        if (!this.props.onCustomDrawings) {
            return null;
        }
        const {x_min, x_max, y_min, y_max, deltaX, deltaY, xFactor, yFactor} = this.getLimits(width, height);
        const x_pox = this.props.noTicksY ? this.px10 : 30;
        return (
            <g className={"sz-chart-data"} transform={`translate(${x_pox} 10)`}>
                {this.props.onCustomDrawings(x_min, x_max, y_min, y_max, xFactor, yFactor)}
            </g>
        );
    }
    private onIntersectionObserver(items: IntersectionObserverEntry[], observer: IntersectionObserver) {
        for (const item of items) {
            if (!item.isIntersecting) {
                continue;
            }
            this.resize();
            break;
        }
    }

    private onKeyDown(charCode: number, keyCode: number) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(charCode, keyCode);
        }
    }
}
