import * as React from "react";
import {CSSProperties, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {Bubble} from "react-chartjs-2";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {Globals} from "../../../const/Globals";
import {linearRegression} from "../../../helpers/Helpers";
import {DrawStateHook, IDrawStateHookProps} from "../../../chart-plugin/DrawStateHook";
import * as Statistics from "../../../helpers/Statistics";
import {SzRenderFormular} from "../../widgets/SzRenderFormular";
import {IRegression} from "../../../models/chef/RawBeta";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
);
type CompressedArrayItem = {days: number; start_date: number; stop_date: number; a: number; b: number};

const styleH: CSSProperties = {
    width: "100%",
    color: "#182026",
    fontSize: 14,
    fontWeight: "bold",
    display: "block",
    paddingBottom: 5,
    marginTop: 14,
    boxShadow: "inset 0px -1px 0px 0px #cecece",
};
export interface IRegressionChartProps {
    company: ICompanyBase;
    data: any;
}

const fmt = (v)=>Globals.formatter(v, 2, 2, true);

export function RegressionChart(props: IRegressionChartProps) {
    const [selectedPoints, setSelectedPoints] = useState([]);
    const company: ICompanyBase = props.company;
    const data = props.data;
    const o = new SzChartOptions();
    o.setCustomAxisLabel(_t(ETranslation.index_return), _t(ETranslation.stock_price));
    o.setXTickCallback(()=> "");
    o.setYTickCallback(()=> "");
    o.setYMinMax({min: -4, max: 4});
    o.setXMinMax({min: -4, max: 4});
    const options = {
        ...o.options,
    };
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
    };
    options.scales.x.position = {y: 0};
    options.scales.y.position = {x: 0};
    options.plugins.tooltip = {
        enabled: true,
        mode: "point",
        intersect: true,
        backgroundColor: "rgba(255,255,255,0.75)",
        titleColor: "rgba(219,55,55,1)",
        bodyColor: "rgba(219,55,55,1)",
        borderColor: "rgba(82,82,82,0.75)",
        borderWidth: 1,
        caretSize: 0,
        cornerRadius: 2,
        filter: (item)=> item.datasetIndex === 0,
        callbacks: {
            label: (item)=> { return `${_t(ETranslation.index_return)}(${fmt(item?.raw?.rawData?.a)}) ${_t(ETranslation.stock_price)}(${fmt(item?.raw?.rawData?.b)})`;},
            title: (item)=> { return _t(ETranslation.regression_statistics);},
        },
    };
    const dots = {
        label: _t(ETranslation.regression_statistics),
        backgroundColor: (item)=>{
            if(selectedPoints.find((i)=> i.index === item.dataIndex)){
                return "rgba(219,55,55,0.75)";
            }
            return "rgba(19,124,189,0.75)";
        },
        hoverBackgroundColor: "rgba(219,55,55,0.75)",
        pointHoverBackgroundColor: "rgba(219,55,55,0.75)",
        data: [],
    };
    const line = {
        label: "Regression",
        type: "line",
        borderWidth: 1,
        pointStyle: false,
        pointRadius: 0,
        borderColor: "rgba(19,124,189,0.75)",
        data: [],
    };
    options.onClick = (e)=>{
        // console.error(e);
        const elements = e.chart.getElementsAtEventForMode(e, 'point', {intersect: true}, false);
        const items = e.native.ctrlKey && Array.isArray(selectedPoints) ? [].concat(selectedPoints) : [];
        elements.forEach((element)=>{
            if(element.datasetIndex!==0){
                return;
            }
            const index = element.index;
            if(!selectedPoints.find((i)=> i.index === index)){
                items.push({
                    index,
                    data: {...dots.data[index]},
                });
            }
        });
        setSelectedPoints(items);
    };
    // stroke: #DB3737;
    const chart_data = {
        datasets: [
            dots,
            line,
        ],
    };
    const period = SessionStore.get(EParameters.PeriodsParameter);
    const raw_betas = data.raw_betas;
    const raw_beta = raw_betas[company.id];
    const a: CompressedArrayItem[] = raw_beta?.compressed_arrays[period];
    const regression = raw_beta?.periods[period];
    let regression_2;

    const hist_slots = 16;
    const x_bars = (new Array(hist_slots)).fill(0);
    const y_bars = (new Array(hist_slots)).fill(0);
    if(Array.isArray(a)){
        let max = 0;
        a.forEach( (p) => {
            const t_max = Math.max(Math.abs(p.a), Math.abs(p.b)) * 1.2;
            if (t_max > max) {
                max = t_max;
            }
        });
        const scale = 4 / max;
        const slot_width = 0.5;
        const fx = (x)=> regression.Intercept + regression.Slope * x;
        line.data.push({x: -max * scale, y: fx(-max) * scale});
        line.data.push({x: max * scale, y: fx(max) * scale});
        dots.data = a.map((p)=>{
            const x =  p.a*scale;
            const y =  p.b*scale;
            const slot_idx_x = Math.floor((x + 4)/slot_width);
            const slot_idx_y = Math.floor((y + 4)/slot_width);
            x_bars[slot_idx_x]++;
            y_bars[slot_idx_y]++;

            return ({x, y, r: 6, rawData: p});
        });
        if(selectedPoints.length){
            const new_a = a.filter((p, idx) => !selectedPoints.find((i)=> i.index === idx));
            regression_2 = linearRegression(new_a);
            const line_2 = {
                label: "Regression 2",
                type: "line",
                borderWidth: 1,
                pointStyle: false,
                pointRadius: 0,
                borderColor: "rgba(219,55,55,0.75)",
                borderDash: [5,5],
                data: [],
            };
            const fx_2 = (x)=> regression_2.Intercept + regression_2.Slope * x;
            line_2.data.push({x: -max * scale, y: fx_2(-max) * scale});
            line_2.data.push({x: max * scale, y: fx_2(max) * scale});
            chart_data.datasets.push(line_2);
        }
    }
    y_bars.reverse();

    const max_x = Statistics.max(x_bars);
    const max_y = Statistics.max(y_bars);
    const dsh: IDrawStateHookProps = {
        enabled: true,
        onBeforeDatasetsDraw: (chart, args) => {
            const ctx: CanvasRenderingContext2D = chart.ctx;
            const xAxes: LinearScale = chart.scales.x;
            const yAxes: LinearScale = chart.scales.y;

            const top = yAxes.top;
            const left = xAxes.left;
            const height = yAxes.height;
            const width = xAxes.width;

            const x_factor = width / (hist_slots/4);
            const y_factor = height / (hist_slots/4);
            const x_bar_width = width / hist_slots;
            const y_bar_width = height / hist_slots;

            ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
            ctx.strokeStyle = "#ffffff";
            x_bars.forEach((v, idx)=>{
                if(v){
                    const h = (v/max_x) * x_factor;
                    const x = left + (idx * x_bar_width);
                    const y = top + height - h;
                    const w = x_bar_width - 2;
                    ctx.fillRect(x + 1,y,w,h);
                }
            });
            y_bars.forEach((v, idx)=>{
                if(v){
                    const x = left; // + (idx * bar_width);
                    const y = top + (idx * y_bar_width);
                    const w = (v/max_y) * y_factor;
                    const h = y_bar_width - 2;
                    ctx.fillRect(x,y + 1,w,h);
                }
            });
        },
    };
    o.enablePlugin(EChartPlugins.DRAW_STATE_HOOK, dsh);

    const renderForm= (r: IRegression, color)=> {
        if(!r){
            return null;
        }
        return (
            <div style={{color, marginBottom: 5}}>
                <SzRenderFormular expression={`\\operatorname{f}(x) = ${Globals.formatter(r.Intercept)} + ${Globals.formatter(r.Slope)}x`} />
            </div>
        );
    };

    return (
        <div>
            <div style={styleH}>{_t(ETranslation.regression_beta_factor)}</div>
            <div style={{position: "relative"}}>
                <div style={{aspectRatio: "1/1"}}>
                    <Bubble data={chart_data as any} options={options} />
                </div>
                <div style={{position: "absolute", top: 24, right: 20, fontSize: 11}}>
                    {renderForm(regression, "rgba(19,124,189,0.75)")}
                    {renderForm(regression_2, "rgba(219,55,55,0.75)")}
                </div>
            </div>
            <div className={"sz-hint"} style={{paddingLeft: 10}}>
                {_t(ETranslation.elimination_data_points)}
            </div>
        </div>
    );
}
