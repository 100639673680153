export enum EParameters {
    NopeParameter= "NopeParameter",
    DividerParameter= "DividerParameter",
    CompaniesParameter= "CompaniesParameter",
    DateParameter = "DateParameter",
    BalanceSheetDateParameter= "BalanceSheetDateParameter",
    ProjectParameter = "ProjectParameter",
    DefaultProject = "defaultProject",
    PeriodParameter= "PeriodParameter",
    WeekDayParameter= "WeekDayParameter",
    FrequencyParameter= "FrequencyParameter",
    IndexTypeParameter= "IndexTypeParameter",
    LocalTypeParameter= "LocalTypeParameter",
    AdjusmentBetaParameter= "AdjusmentBetaParameter",
    DebtbetaParameter= "DebtbetaParameter",
    GearingPeersParameter= "GearingPeersParameter",
    IndexReturnParameter= "IndexReturnParameter",
    NegativeVerschuldungParameter= "NegativeVerschuldungParameter",
    TaxshieldParameter= "TaxshieldParameter",
    TaxShieldTargetCompanyRiskEffect= "TaxShieldTargetCompanyRiskEffect",
    CountriesParameter= "CountriesParameter",
    PeriodsParameter= "PeriodsParameter",
    RegionParameter= "RegionParameter",
    RegionParameterV2= "RegionParameterV2",
    ImfDataViewParameter= "ImfDataViewParameter",
    YearsParameter= "YearsParameter",
    IndexCombiParameter= "IndexCombiParameter",
    CompositionTypeParameter= "CompositionTypeParameter",
    ReturnTypeParameter= "ReturnTypeParameter",
    GearingCombiParameter= "GearingCombiParameter",
    MahalanobicDistanceParameter= "MahalanobicDistanceParameter",
    RatingParameter= "RatingParameter",
    SectorParameter= "SectorParameter",
    SicParameter= "SicParameter",
    RiskPremiumParameter= "RiskPremiumParameter",
    RuntimeParameter= "RuntimeParameter",
    IdwTaxesParameter= "IdwTaxesParameter",
    PrimeSectorParameter= "PrimeSectorParameter",
    PrimeSectorV2Parameter= "PrimeSectorV2Parameter",
    GrowthRateCashSurpluseParameter= "GrowthRateCashSurpluseParameter",
    PeriodTypeParameter= "PeriodTypeParameter",
    RuntimeBaseInterestParameter= "RuntimeBaseInterestParameter",
    RenditeCombiParameter= "RenditeCombiParameter",
    CreditSpreadsParameter= "CreditSpreadsParameter",
    MarketRiskPremiumParameter= "MarketRiskPremiumParameter",
    DebtBetaCombiParameter= "DebtBetaCombiParameter",
    CountryParameter= "CountryParameter",
    IntrestRateCountryParameter= "IntrestRateCountryParameter",
    BaseCurrencyParameter= "BaseCurrencyParameter",
    ProfitabilityParameter= "ProfitabilityParameter",
    RentabilityParameter= "RentabilityParameter",
    RatingKeyParameter= "RatingKeyParameter",
    CashflowParameter= "CashflowParameter",
    FeItemParameter= "FeItemParameter",
    FePerRelParameter= "FePerRelParameter",
    FixedCountriesParameter= "FixedCountriesParameter",
    UseFixedCountriesParameter= "UseFixedCountriesParameter",
    LeverageParameter = "LeverageParameter",
    DebtBetaCalcParameter = "DebtBetaCalcParameter",
    DebtBetaValueParameter = "DebtBetaValueParameter",
    CountryRiskUsageParameter = "CountryRiskUsageParameter",
    CountryRiskPremiumParameter = "CountryRiskPremiumParameter",
    CountryRiskPremiumCombiParameter = "CountryRiskPremiumCombiParameter",
    ReleveredBetaParameter = "ReleveredBetaParameter",
    MarketAndCountryRiskParameter = "MarketAndCountryRiskParameter",
    TaxRateParameter = "TaxRateParameter",
    SizePremium = "SizePremium",
    CreditSpreadParameter = "CreditSpreadParameter",
    BaseRateParameter = "BaseRateParameter",
    BaseInterestCurveParameter = "BaseInterestCurveParameter",
    RoundingRuleParameter = "RoundingRuleParameter",
    Wacc = "WACC",
    CostOfCapital= "CostOfCapital",
    SectorCountriesParameter = "SectorCountriesParameter",
    FilterState = "FilterState",
    BenchmarkingCustom = "BenchmarkingCustom",
    TpFreezeLongList= "TpFreezeLongList",
    TpPermaFreezeLongList= "TpPermaFreezeLongList",
    TpSubSectorParameter= "TpSubSectorParameter",
    TpYearsParameter= "TpYearsParameter",
    TpCountriesParameter="TpCountriesParameter" ,
    TpCountriesAllParameter="TpCountriesAllParameter" ,
    TpFreeFloatParameter="TpFreeFloatParameter",
    TpSalesParameter="TpSalesParameter",
    TpYearsAverageParameter= "TpYearsAverageParameter",
    TpMarginComposition= "TpMarginComposition",
    TpAnalysisSizeParameter = "TpAnalysisSizeParameter",
    TpOpResult = "TpOpResult",
    TpSicParameter = "TpSicParameter",
    TpSicClassParameter = "TpSicClassParameter",
    TpSicSubClassParameter = "TpSicSubClassParameter",
    TpRejected = "TpRejected",
    TpRejectedAbsolut = "TpRejectedAbsolut",
    TpLongList = "TpLongList",
    TpFinancials = "TpFinancials",
    TpNumEmployeesParameter="TpNumEmployeesParameter",
    TpKeyWordsParameter = "TpKeyWordsParameter",
    TpKeyWordsWordsParameter = "TpKeyWordsWordsParameter",
    TpKeyWordsOperParameter = "TpKeyWordsOperParameter",
    FixedCurrencyParameter = "FixedCurrencyParameter",
    SeniorityParameter = "SeniorityParameter",
    PledgeStatusParameter = "PledgeStatusParameter",
    BondEnhancementParameter = "BondEnhancementParameter",
    BondCallableParameter = "BondCallableParameter",
    TickerParameter = "TickerParameter",
    CountryIso2Parameter = "CountryIso2Parameter",
    CreditSpreadDecisionParameterA = "CreditSpreadDecisionParameterA",
    CreditSpreadDecisionParameterB = "CreditSpreadDecisionParameterB",
    CreditSpreadDecisionParameterC = "CreditSpreadDecisionParameterC",
    CreditSpreadDecisionParameterD = "CreditSpreadDecisionParameterD",
    CreditSpreadSoftParameter = "CreditSpreadSoftParameter",
    CreditSpreadXRangeParameter = "CreditSpreadXRangeParameter",
    BondPropertiesParameter = "BondPropertiesParameter",
    BondSuperSectorParameter = "BondSuperSectorParameter",
    BondSectorParameter = "BondSectorParameter",
    SectorParameterV2 = "SectorParameterV2",
    CreditSpreadMetricParameter= "CreditSpreadMetricParameter",
    CreditSpreadMetricWeightsParameter= "CreditSpreadMetricWeightsParameter",
    CreditSpreadMetricWeightsModeParameter= "CreditSpreadMetricWeightsModeParameter",
    CreditSpreadAnalysisIntervalParameter= "CreditSpreadAnalysisIntervalParameter",
    BondFilterParameter = "BondFilterParameter",
    CreditSpreadConfigParameter = "CreditSpreadConfigParameter",
    CreditSpreadRuntimeParameter = "CreditSpreadRuntimeParameter",
    BetaFilterParameter= "BetaFilterParameter",
    BetaFilterBidAsk= "BetaFilterBidAsk",
    BetaFilterFreeFloat= "BetaFilterFreeFloat",
    BetaFilterR2= "BetaFilterR2",
    BetaFilterTTest= "BetaFilterTTest",
    BetaFilterDataPoints= "BetaFilterDataPoints",
    BetaFilterDurbinWatsonTest= "BetaFilterDurbinWatsonTest",
    BetaFilterBreuschPaganTest= "BetaFilterBreuschPaganTest",
    BetaUnleverdOptions ="BetaUnleverdOptions",
}
