import {CacheEntry} from "../services/ChefCalls";

export interface ICacheFunctions {
    test_function(old_params, new_params);
    response_filter(parameters, response);
}
const Company_Objects = (field_name: string): ICacheFunctions =>{
    return {
        test_function(old_params, new_params) {
            const old_companies = old_params?.companies;
            const new_companies = new_params?.companies;
            if(!Array.isArray(old_companies) || !Array.isArray(new_companies)){
                return true;
            }
            const m = {};
            old_companies.forEach((i)=> m[i[field_name]] = JSON.stringify(i));
            const c = new_companies.find((i)=>{
                // company not found
                const test_data =  m[i[field_name]];
                if(!test_data){
                    return true;
                }
                return test_data !== JSON.stringify(i) ? true : false;
            });
            if(c){
                return true;
            }
            return new_companies.length > old_companies.length;
        },
        response_filter(parameters, response) {
            const keep_companies = parameters.companies;
            if(!Array.isArray(keep_companies)){
                return response;
            }
            Object.keys(response).forEach((key)=>{
                const keep = keep_companies.find((e)=> ""+e[field_name] === key);
                if(keep){
                    return;
                }
                delete response[key];
            });
            // console.error(response);
            return response;
        }
    };
};
const Company_Array = (): ICacheFunctions =>{
    return {
        test_function(old_params, new_params) {
            const old_companies: any[] = old_params?.companies;
            const new_companies: any[] = new_params?.companies;
            if(!Array.isArray(old_companies) || !Array.isArray(new_companies)){
                return true;
            }
            const m = {};
            old_companies.forEach((i)=> m[i] = true);
            // sind "neue" firmen enthalten die es vorher nicht gab?
            const c = new_companies.find((i)=> !m[i]);
            if(c){
                return true;
            }
            return new_companies.length > old_companies.length;
        },
        response_filter(parameters, response) {
            const keep_companies = parameters.companies;
            if(!Array.isArray(keep_companies)){
                return response;
            }
            Object.keys(response).forEach((key)=>{
                if(keep_companies.includes(key)){
                    return;
                }
                delete response[key];
            });
            // console.error(response);
            return response;
        }
    };
};
export const CacheTools = {
    Company_Objects,
    Company_Array
};

export const ArrayCacheTools = (cache_id: string, changeable_field: string, calls: ICacheFunctions): CacheEntry => {
    const cc = {};
    cc[changeable_field] = calls.test_function;
    return {
        cacheId: cache_id,
        options: {
            changeable_fields : [changeable_field],
            testFunctions: cc,
            response_filter: calls.response_filter,
        }
    };
}

export const CompanyArrayDateMapCache = (cache_id: string): CacheEntry => {
    const calls: ICacheFunctions = Company_Array();
    return {
        cacheId: cache_id,
        options: {
            changeable_fields : ["companies"],
            testFunctions: {
                companies: calls.test_function,
            },
            response_filter(parameters, response) {
                const keep_companies = parameters.companies;
                if(!Array.isArray(keep_companies)){
                    return response;
                }
                Object.keys(response).forEach((dates)=>{
                    const dates_object = response[dates];
                    Object.keys(dates_object).forEach((key)=>{
                        if(keep_companies.includes(key)){
                            return;
                        }
                        delete dates_object[key];
                    });
                });
                return response;
            },
        }
    };
}
export const CompanyObjectDateMapCache = (cache_id: string, field_name: string): CacheEntry => {
    const calls: ICacheFunctions = Company_Objects(field_name);
    return {
        cacheId: cache_id,
        options: {
            changeable_fields : ["companies"],
            testFunctions: {
                companies: calls.test_function,
            },
            response_filter(parameters, response) {
                const keep_companies = parameters.companies;
                if(!Array.isArray(keep_companies)){
                    return response;
                }
                Object.keys(response).forEach((dates)=>{
                    const dates_object = response[dates];
                    Object.keys(dates_object).forEach((key)=>{
                        const keep = keep_companies.find((e)=> ""+e[field_name] === key);
                        if(keep){
                            return;
                        }
                        delete dates_object[key];
                    });
                });
                return response;
            },
        }
    };
}

export const CacheToolsAllParameters = (cache_id: string): CacheEntry => {
    return {
        cacheId: cache_id,
        options: {
            changeable_fields : [],
            testFunctions: undefined,
            response_filter: undefined,
        }
    };
}
