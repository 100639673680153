import {NonIdealState} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {ICalcCreditSpreadResult} from "../../helpers/CalcCreditSpreadIndication";
import {EChartType, PdfChartToImage} from "../../pdf-tools/PdfChartToImage";
import {PDF_IMAGE_HEIGHT, PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {BaseModule} from "./BaseModule";
import {median, quantile, sum} from "../../helpers/Statistics";
import {Ratings} from "../../const/Ratings";
import {SessionStore} from "../../const/SessionStore";
import {EValueName} from "../../models/EValueName";

import {
    Chart as ChartJS,
    LinearScale,
    Tooltip,
    Legend, BarElement, Title, CategoryScale,
} from 'chart.js';
import {Bar, Bubble} from 'react-chartjs-2';
import {HighlightValue} from "../../chart-plugin/HighlightValue";
import {CreditSpreadOptions} from "../../const/CreditSpreadOptions";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    HighlightValue,
);

export class CreditSpreadRatingChart  extends BaseModule<any> {

    private ratings = 0;
    private ratings_min = 0;
    private ratings_max = 0;

    private chart_1_options;
    private chart_1_data;
    private chart_2_options;
    private chart_2_data;

    constructor(props: any, context: any) {
        super(props, context);
    }

    protected renderContent() {
        if(!this.data){
            return (<NonIdealState
                icon={"database"}
                title="Keine Daten"
            />);
        }
        const fx = (v, weight)=> v * weight;
        const ratings = [];
        const ratings_min = [];
        const ratings_max = [];
        CreditSpreadOptions.getMetrics().forEach((metric)=>{
            if(metric.weight === 0){
                return;
            }
            const indication: ICalcCreditSpreadResult = this.data.results[metric.field];
            if(!indication){
                return;
            }

            const boxed_ratings = indication.data
                .filter((i)=> CreditSpreadOptions.in_box(i.x, i.y, indication) && i.rating!=="0")
                .map((i)=> CreditSpreadOptions.get_rating_idx(i.rating))
            ;

            ratings.push(fx(median(boxed_ratings), metric.weight));
            ratings_min.push(fx(quantile(boxed_ratings, .25), metric.weight));
            ratings_max.push(fx(quantile(boxed_ratings, .75), metric.weight));
        });

        this.ratings = Math.round(sum(ratings));
        SessionStore.setGlobalVar(EValueName.cp_rating, Ratings.s_and_p[this.ratings]);

        this.ratings_min = Math.round(sum(ratings_min));
        this.ratings_max = Math.round(sum(ratings_max));
        // <div className={"ribbon top left"}>Beta Version</div>
        return (
            <div style={{overflow: "hidden", position: "relative"}}>
                <div className={"sz-row"}>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <strong>Rating nach Kreditkennzahl</strong>
                            <br/>
                            <span>&nbsp;</span>
                        </div>
                        {this.renderBarChart()}
                    </div>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <strong>Rating nach Laufzeit</strong>
                            <br/>
                            <span>&nbsp;</span>
                        </div>
                        {this.renderCompaniesBarChart()}
                    </div>
                </div>
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;
        // console.error(this.data);
        this.setState({
            loading: false,
            selected_company_indication: data.selected_company_indication ? data.selected_company_indication : this.state.selected_company_indication,
        });
    }
    private noData(error_pos: string) {
        return (<NonIdealState
            icon={"database"}
            title={`Keine Daten (${error_pos})`}
            description="Die Kombination aus Stichtag, Währung, Sector und Rating liefert keine Ergebnisse."
        />);
    }
    private renderBarChart(){
        if(!Array.isArray(CreditSpreadOptions.getMetrics())){
            return;
        }
        const data = [];
        // const fx = (v)=>  Math.trunc(v * 100)/100;
        const labels = [];
        CreditSpreadOptions.getMetrics().forEach((metric)=>{
            if(metric.weight === 0){
                return;
            }

            const indication: ICalcCreditSpreadResult = this.data.results[metric.field];
            if(!indication){
                return;
            }
            labels.push(metric.caption);
            // labels.push(short_captions[key]);
            // console.error(captions[key], indication.y_range);
            if(!indication.rating_range){
                data.push(null);
                return;
            }
            if(isNaN(indication.rating_range.from) || isNaN(indication.rating_range.till)){
                data.push(null);
                return;
            }
            data.push([indication.rating_range.from, indication.rating_range.till]);
        });
        // https://react-chartjs-2.js.org/examples/vertical-bar-chart
        // console.error(sum_useful);
        // console.error(w_spreads);
        // console.error(labels);
        // console.error(data);

        const datalabels = {
            display: 1,
            align: "-30",
            anchor: "end",
            offset: 5,
            backgroundColor: "rgba(255,255,255,0.9)",
            formatter: (value, context, a)=>{
                // console.error(value, context, a);
                if(context.dataIndex===0){
                    return `Rating: ${Ratings.s_and_p[value]}`;
                }
                return null;
            },
            labels: {
                title:{
                    color: "#f47a22",
                }
            }
        };
        const chart_data = {
            labels,
            datasets: [
                {
                    label: 'Bereich',
                    data,
                    minBarLength: 5,
                    backgroundColor: Globals.shortlist_green,
                    borderWidth: 2,
                    borderColor: Globals.shortlist_green,
                },
                {
                    type: "line",
                    label: `Rating`,
                    data: (new Array(labels.length)).fill(this.ratings),
                    backgroundColor: "#f47a22",
                    borderColor: "#f47a22",
                    borderWidth: 1,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    order: -3,
                    datalabels,
                },
                {
                    type: "line",
                    label: `min Spread`,
                    data: (new Array(labels.length)).fill(this.ratings_min),
                    borderDash: [7,2,2,2],
                    backgroundColor: "#137cbd",
                    borderColor: "#137cbd",
                    borderWidth: 1,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    order: -1,
                    datalabels: {
                        display: 1,
                        align: "135",
                        anchor: "end",
                        offset: 5,
                        backgroundColor: "rgba(255,255,255,0.9)",
                        formatter: (value, context, a)=>{
                            if(context.dataIndex === labels.length - 1){
                                return `Unteres Quartil: ${Ratings.s_and_p[value]}`;
                            }
                            return null;
                        },
                        labels: {
                            title:{
                                color: "#137cbd",
                            }
                        }
                    }
                },
                {
                    type: "line",
                    label: `max Spread`,
                    data: (new Array(labels.length)).fill(this.ratings_max),
                    borderDash: [7,2,2,2],
                    backgroundColor: "#137cbd",
                    borderColor: "#137cbd",
                    borderWidth: 1,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    order: -1,
                    datalabels: {
                        display: 1,
                        align: "-135",
                        anchor: "end",
                        offset: 5,
                        backgroundColor: "rgba(255,255,255,0.9)",
                        formatter: (value, context, a)=>{
                            if(context.dataIndex === labels.length - 1){
                                return `Oberes Quartil: ${Ratings.s_and_p[value]}`;
                            }
                            return null;
                        },
                        labels: {
                            title:{
                                color: "#137cbd",
                            }
                        }
                    }
                },
            ],
        };
        const company_indication = this.state.selected_company_indication;
        const selected_rating = company_indication ? Ratings.s_and_p[company_indication.rating] : undefined;
        // console.error(selected_spread);
        if(selected_rating){
            chart_data.datasets.push({
                type: "line",
                label: `Auswahl`,
                borderDash: [7,2,2,2],
                backgroundColor: "#137cbd",
                borderWidth: 1,
                pointRadius: 0,
                pointHitRadius: 0,
                order: -2,
                data: (new Array(labels.length)).fill(selected_rating),
            } as any);
        }
        /*
        const title_text = [
            `Kreditaufschlag ${Globals.formatter(spreads, 2, 2, true)}`,
            `min(${Globals.formatter(spreads_min, 2, 2, true)})`,
            `max(${Globals.formatter(spreads_max, 2, 2, true)})`,
            `Auswahl(${Globals.formatter(selected_spread, 2, 2, true)})`
        ].join(" ");
        */
        const title_text = undefined;
        const options = {
            type: 'bar',
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            indexAxis: 'x',
            scales: {
                y: {
                    max: Ratings.s_and_p.length -1,
                    min: 0,
                    ticks: {
                        z: 10,
                        padding: 10,
                        stepSize: 1,
                        font: {
                            size: 11,
                        },
                        callback: (value)=>{
                            const show = Ratings.index_s_and_p[value];
                            if(show===undefined){
                                return undefined;
                            }
                            const sp = Ratings.s_and_p[value];
                            const p_sp = Ratings.as_packet_s_and_p(sp);
                            return Ratings.packet_s_and_p[p_sp];
                        },
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                },
                x: {
                    ticks: {
                        z: 10,
                        padding: 10,
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: title_text,
                },
                legend: {
                    display: false,
                },
                datalabels: {
                    display: 0,
                },
                tooltip: {
                    enabled: true,
                    mode: "point",
                    callbacks: {
                        label: (context)=>{
                            if(!Array.isArray(context.raw)){
                                return undefined;
                            }
                            if(context.raw.length === 1){
                                return `Rating ${Ratings.s_and_p[context.raw[0]]}`;
                            }
                            if(context.raw.length === 2){
                                return `Rating von ${Ratings.s_and_p[context.raw[0]]} ${_t(ETranslation.to)} ${Ratings.s_and_p[context.raw[1]]}`;
                            }
                            return undefined;
                        }
                    }
                },
            },
            custom_axis_label: {
                x_label: "Kennzahl",
                y_label: "Rating",
            }
        };
        this.chart_1_options = options;
        this.chart_1_data = chart_data;
        return (
            <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                <Bar options={options as any} data={chart_data as any} height={"100%"} width={"100%"} />
            </div>
        );
    }
    private renderCompaniesBarChart() {
        const boxed_stat = this.data.all_boxed_spreads;
        if(!Array.isArray(boxed_stat)){
            return this.noData("N3");
        }
        if(!boxed_stat.length){
            return this.noData("N4");
        }
        const companies_by_rating = Ratings.s_and_p.map((s, i)=> 0);
        boxed_stat.forEach((i, num)=>{
            const slot = Ratings.s_and_p_mapped_to_num[i.rating];
            if(companies_by_rating[slot]>=0){
                companies_by_rating[slot]++;
            }
        });
        // const spread = median(data.map((i)=> i.y));
        // console.error(spread);
        // console.error(boxed_stat, all_bonds, data);
        const chart_data_bar = {
            labels: Ratings.s_and_p.map((s, i)=> i),
            datasets: [
                {
                    type: "bar",
                    indexAxis: "y",
                    label: "Histogram",
                    backgroundColor: Globals.shortlist_green,
                    data: companies_by_rating,
                },
            ],
        };
        const options_bar_chart = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            scales: {
                y: {
                    min: 0,
                    max: Ratings.s_and_p.length - 1,
                    type: 'linear',
                    display: true,
                    position: 'left',
                    stacked: true,
                    ticks: {
                        z: 10,
                        padding: 10,
                        color: "#485d63",
                        stepSize: 1,
                        maxTicksLimit: 100,
                        callback: (value)=>{
                            // console.error(value);
                            const sp = Ratings.s_and_p[value];
                            const show = Ratings.index_s_and_p[value];
                            if(show===undefined){
                                return "";
                            }
                            const p_sp = Ratings.as_packet_s_and_p(sp);
                            return Ratings.packet_s_and_p[p_sp];
                        },
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: 0,
                        tickColor: "rgba(0, 0, 0, 0.0)",
                    }
                },
                x: {
                    stacked: true,
                    ticks: {
                        z: 10,
                        padding: 10,
                        /*maxRotation: 0,*/
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend: {
                    display: false,
                },
                datalabels: {
                    display: 1,
                    formatter: (v) => {
                        if (parseInt(v, 10) === 0) {
                            return "";
                        }
                        return v;
                    },
                    align: (v, b, c) => {
                        const set_idx = v.datasetIndex;
                        if(set_idx === 1){
                            return "end";
                        }
                        return "center";
                    },
                },
                tooltip: {
                    enabled: false,
                    mode: "point",
                    callbacks: {
                        label: (item)=> { return undefined;},
                        title: (item)=> { return undefined;},
                    },
                },
            },
            custom_axis_label: {
                x_label: `# Unternehmen`,
                y_label: "",
            },
        };
        // console.error(spread_value);
        // https://chartjs-plugin-datalabels.netlify.app/guide/positioning.html#clamping
        this.chart_2_options = options_bar_chart;
        this.chart_2_data = chart_data_bar;
        return (
            <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                <Bubble options={options_bar_chart as any} data={chart_data_bar as any} height={"100%"} width={"100%"} />
            </div>
        );
    }

    private getImage1(){
        const h = 500;
        const w = 1125;
        const s = PdfChartToImage.getDataUri(EChartType.bar, w, h, this.chart_1_options, this.chart_1_data);
        return s;
    }
    private getImage2(){
        const h = 500;
        const w = 1125;
        const s = PdfChartToImage.getDataUri(EChartType.bar, w, h, this.chart_2_options, this.chart_2_data);
        return s;
    }
    public getPdfPage(): any[]{
        const image_1 = this.getImage1();
        const image_2 = this.getImage2();
        const content =[];
        PdfInsertElement.page_header(content, "Rating");

        PdfInsertElement.h2(content, "Rating nach Kreditkennzahl");
        PdfInsertElement.image(content, image_1, PDF_IMAGE_HEIGHT);

        const h =PdfInsertElement.h2(content, "Rating nach Laufzeit");
        h.pageBreak= "before";
        PdfInsertElement.image(content, image_2, PDF_IMAGE_HEIGHT);

        return content;
    }
}
