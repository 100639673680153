export const ColorMap = {
    $c_0: "#CED9E0",
    $c_1: "#137cbd",
    $c_2: "#202B33",
    $c_3: "#0f9960",
    $c_4: "#D9822B",
    $c_5: "#DB3737",
    $c_6: "#8F398F",
    $c_7: "#7157D9",
    $c_8: "#9BBF30",
    $c_9: "#96622D",
    $c_10:"#8A9BA8",
    $c_11: "#F7BA0B",
    $c_12: "#ff5d83",
    $c_13: "#7dfc00",
    $c_14: "#8ad8e8",
    $c_15: "#29bdab",
    $c_16: "#277da7",
    $c_17: "#d30b94",
    $c_18: "#632819",
    $c_19: "#5d4c86",
    $c_20: "#f47a22",
    $c_21: "#2f2aa0",
    $c_22: "#b732cc",
    $c_23: "#772b9d",
    $c_24: "#f07cab",
    $c_25: "#c3a5b4",
    $c_26: "#946aa2",
    $c_27: "#ebedef",
}
