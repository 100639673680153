import {
    Button,
    Callout,
    Classes,
    HTMLSelect,
    HTMLTable,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Popover,
    TextArea,
} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../../const/Globals";
import {EModuleNames} from "../../../models/EModuleNames";
import {IBewertung} from "../../../models/IBewertung";
import {IDocumentationCall} from "../../../models/IDocumentationCall";
import {IModuleDocumentation} from "../../../models/IModuleDocumentation";
import {EventBus} from "../../../services/EventBus";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const optBewertungsAnlass: string[] = [
    "Unternehmerische Initiative",
    "Externe Rechnungslegung",
    "Gesetzliche Vorschrift",
    "Vertragliche Vereinbarung",
    "keine Angabe",
];
const optVerwendung: string[] = [
    "IDW S1 Gutachten",
    "IDW S13 Gutachten",
    "IDW S5 Gutachten",
    "KFS/BW1 Gutachten",
    "FM 2018 Gutachten",
    "keine Angabe",
];
const optBewerterRolle: string[] = [
    "Neutraler Gutachter",
    "Berater",
    "Schiedsgutachter / Vermittler",
    "keine Angabe",
];

export interface IPdfDocScopeProps {
    dataDefaults: IModuleDocumentation[];
    module: string;
    onNext();
    onAbort();
}

export class PdfDocScope extends React.Component<IPdfDocScopeProps, any> {
    private timer: any;

    private showNotAll: boolean;

    constructor(props: IPdfDocScopeProps, context: any) {
        super(props, context);

        this.showNotAll = this.props.module === "Benchmarking" ? true : false;

        const dataDefaults: IModuleDocumentation[] = this.props.dataDefaults;
        const optionMap: Map<string, boolean> = new Map<string, boolean>();
        dataDefaults.forEach((e) => {
            if(!e.is_fixed){
                optionMap.set(`${e.module}_${e.caption}`, true);
            }
        });

        this.state = {
            optionMap,
            isPrepare: false,
            isOpen: false,
            all: true,
            bewertung: {
                object: "",
                anlass: optBewertungsAnlass[0],
                verwendung: optVerwendung[0],
                bewerter: optBewerterRolle[0],
                description: "",
            },
        };

        EventBus.subscribe("Documentation.submit", () => {
            Globals.setWindowKey("pdf-prepare", false);
            this.setState({
                isPrepare: false,
            });
            this.props.onNext();
        });
    }
    public render() {
        const renderPrepare = () => {
            if (!this.state.isPrepare) {
                return "Weiter";
            }
            return (
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{display: "inline-block", margin: "auto"}} width="16px" height="16px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" r="32" stroke-width="8" stroke="rgba(255, 255, 255, 0.6)" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                    </circle>
                </svg>
            );
        };

        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        let intent: Intent = this.state.all ? Intent.NONE : Intent.WARNING;
        if (this.state.isOpen) {
            intent = Intent.PRIMARY;
        }
        let ck = 0;
        const dataDefaults: IModuleDocumentation[] = this.props.dataDefaults;
        const optionMap: Map<string, boolean> = this.state.optionMap;
        const doSwitch = (o: IModuleDocumentation) => {
            if(o.is_fixed){
                return null;
            }
            const checked = optionMap.get(`${o.module}_${o.caption}`);
            return (
                <MenuItem key={"key_" + (ck++)} intent={ checked ? "primary" : "none"} labelElement={this.getIcon(checked)} text={o.caption} onClick={() => this.export(`${o.module}_${o.caption}`)} />
            );
        };
        const testCanClose = (ev) => {
            if (!ev) {
                return;
            }
            if (this.state.isOpen && ev.type === "mousedown") {
                this.setState({isOpen: false});
            }
        };
        const bewertung: IBewertung = this.state.bewertung;
        const inputStyle: CSSProperties = null;
        const renderTable = () => {
            const module = Globals.getWindowKey("wp_show_module");
            if(module === EModuleNames.TransferPricing){
                return (
                    <HTMLTable condensed={true} className={"sz-doc-form sz-table"} style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <td >Module</td>
                            <td >
                                <Popover
                                    minimal={true}
                                    position={"bottom-right"}
                                    boundary={"viewport"}
                                    usePortal={true}
                                    hasBackdrop={false}
                                    popoverClassName={"sz-popover sz-min-w250"}
                                    modifiers={popper}
                                    isOpen={this.state.isOpen}
                                    canEscapeKeyClose={true}
                                    captureDismiss={false}
                                    onInteraction={(a, b) => testCanClose(b)}
                                >
                                    <Button
                                        intent={intent} text={(!this.state.all ? "Nicht alle Module" : "Alle Module")}
                                        minimal={false}
                                        style={{width: "100%"}}
                                        onClick={() => this.setState({isOpen: !this.state.isOpen})}
                                    />
                                    <Menu>
                                        <MenuItem intent={ this.state.all ? "primary" : "none"} labelElement={this.getIcon(this.state.all)}  text={"Alle"} onClick={() => { this.changeAll(); }} />
                                        <MenuDivider />
                                        {dataDefaults.map((item) => doSwitch(item))}
                                    </Menu>
                                </Popover>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Callout intent={Intent.WARNING} icon={IconNames.TIME}>
                                    <div>
                                        <p>Das Erzeugen der Dokumentation kann einige Sekunden dauern. Die Dauer ist abhängig von der Größe der Long bzw. Short List.</p>
                                    </div>
                                </Callout>
                            </td>
                        </tr>
                        </tbody>
                    </HTMLTable>
                );
            }
            if (this.showNotAll) {
                return (
                    <HTMLTable condensed={true} className={"sz-doc-form sz-table"} style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <td >Module</td>
                            <td >
                                <Popover
                                    minimal={true}
                                    position={"bottom-right"}
                                    boundary={"viewport"}
                                    usePortal={true}
                                    hasBackdrop={false}
                                    popoverClassName={"sz-popover sz-min-w250"}
                                    modifiers={popper}
                                    isOpen={this.state.isOpen}
                                    canEscapeKeyClose={true}
                                    captureDismiss={false}
                                    onInteraction={(a, b) => testCanClose(b)}
                                >
                                    <Button
                                        intent={intent} text={(!this.state.all ? "Nicht alle Module" : "Alle Module")}
                                        minimal={false}
                                        style={{width: "100%"}}
                                        onClick={() => this.setState({isOpen: !this.state.isOpen})}
                                    />
                                    <Menu>
                                        <MenuItem intent={ this.state.all ? "primary" : "none"} labelElement={this.getIcon(this.state.all)}  text={"Alle"} onClick={() => { this.changeAll(); }} />
                                        <MenuDivider />
                                        {dataDefaults.map((item) => doSwitch(item))}
                                    </Menu>
                                </Popover>
                            </td>
                        </tr>
                        <tr><td colSpan={2}>Beschreibung</td></tr>
                        <tr><td colSpan={2}>
                            <TextArea inputMode={"text"} rows={3} maxLength={1024} value={bewertung.description} placeholder={"optional"} onChange={ (e) => { bewertung.description = e.target.value; this.setState({bewertung}); }}  style={{
                                minWidth: "100%",
                                minHeight: 400,
                            }} />
                        </td></tr>
                        </tbody>
                    </HTMLTable>
                );
            } else {
                return (
                    <HTMLTable condensed={true} className={"sz-doc-form sz-table"} style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <td >Module</td>
                            <td >
                                <Popover
                                    minimal={true}
                                    position={"bottom-right"}
                                    boundary={"viewport"}
                                    usePortal={true}
                                    hasBackdrop={false}
                                    popoverClassName={"sz-popover sz-min-w250"}
                                    modifiers={popper}
                                    isOpen={this.state.isOpen}
                                    canEscapeKeyClose={true}
                                    captureDismiss={false}
                                    onInteraction={(a, b) => testCanClose(b)}
                                >
                                    <Button
                                        intent={intent} text={(!this.state.all ? "Nicht alle Module" : "Alle Module")}
                                        minimal={false}
                                        style={{width: "100%"}}
                                        onClick={() => this.setState({isOpen: !this.state.isOpen})}
                                    />
                                    <Menu>
                                        <MenuItem intent={ this.state.all ? "primary" : "none"} labelElement={this.getIcon(this.state.all)}  text={"Alle"} onClick={() => { this.changeAll(); }} />
                                        <MenuDivider />
                                        {dataDefaults.map((item) => doSwitch(item))}
                                    </Menu>
                                </Popover>
                            </td>
                        </tr>
                        <tr>
                            <td>Bewertungsanlass</td>
                            <td><HTMLSelect value={bewertung.anlass} options={optBewertungsAnlass} onChange={(e) => { bewertung.anlass = e.target.value; this.setState({bewertung}); }}  style={inputStyle} /></td>
                        </tr>
                        <tr>
                            <td>Verwendung</td>
                            <td><HTMLSelect value={bewertung.verwendung} options={optVerwendung} onChange={(e) => { bewertung.verwendung = e.target.value; this.setState({bewertung}); }}  style={inputStyle} /></td>
                        </tr>
                        <tr>
                            <td>Rolle des Bewerters</td>
                            <td><HTMLSelect value={bewertung.bewerter} options={optBewerterRolle} onChange={(e) => { bewertung.bewerter = e.target.value; this.setState({bewertung}); }}  style={inputStyle} /></td>
                        </tr>
                        <tr>
                            <td>Name Bewertungsobjekt</td>
                            <td><InputGroup placeholder="optional" value={bewertung.object} maxLength={100} onChange={ (e) => { bewertung.object = e.target.value; this.setState({bewertung}); }}/></td>
                        </tr>
                        <tr>
                            <td>Name Auftraggeber</td>
                            <td><InputGroup placeholder="optional" value={bewertung.customer} maxLength={100} onChange={ (e) => { bewertung.customer = e.target.value; this.setState({bewertung}); }}/></td>
                        </tr>
                        <tr><td colSpan={2}>Beschreibung der Vorgehensweise</td></tr>
                        <tr><td colSpan={2}>
                            <TextArea inputMode={"text"} rows={3} maxLength={300} value={bewertung.description} placeholder={"optional"} onChange={ (e) => { bewertung.description = e.target.value; this.setState({bewertung}); }}  style={{
                                minWidth: "100%",
                                minHeight: 75,
                            }} />
                        </td></tr>
                        </tbody>
                    </HTMLTable>
                );
            }
        };
        return (
            <>
                <div className={Classes.DIALOG_BODY} style={{margin: 0, marginBottom: 10}}>
                    {renderTable()}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => this.props.onAbort() } disabled={this.state.isPrepare}>{_t(ETranslation.cancel)}</Button>
                        <Button intent={"primary"} disabled={this.state.isPrepare} onClick={() => this.onNext()} style={{minWidth: 110}}>
                            {renderPrepare()}
                        </Button>
                    </div>
                </div>
            </>
        );
    }
    protected  getIcon(f: boolean) {
        if (f) {
            return <Icon icon={"tick"}/>;
        } else {
            return "";
        }
    }
    protected changeAll() {
        const select = !this.state.all;
        const optionMap: Map<string, boolean> = this.state.optionMap;
        const all = select;
        optionMap.forEach((value, key) => optionMap.set(key, all));
        this.setState({
            all,
            optionMap,
        });
    }

    private export(moduleKey: string) {
        const optionMap: Map<string, boolean> = this.state.optionMap;
        const value: boolean = !optionMap.get(moduleKey);
        optionMap.set(moduleKey, value);
        let all = value;
        optionMap.forEach((v) => all = all && v );
        this.setState({
            optionMap,
            all,
        });
    }
    private onNext() {
        const optionMap: Map<string, boolean> = this.state.optionMap;
        let anyChecked: boolean = false;
        optionMap.forEach( (isSelected) => anyChecked = anyChecked || isSelected );
        if (!anyChecked) {
            return;
        }

        const saveModules: string[] = [];
        optionMap.forEach( (isSelected, key) => {
            if (isSelected) {
                saveModules.push(key);
            }
        });
        const dataDefaults: IModuleDocumentation[] = this.props.dataDefaults;
        dataDefaults.forEach( (e) =>{
            if(e.is_fixed){
                saveModules.push(`${e.module}_${e.caption}`);
            }
        });

        const saveData: IDocumentationCall = {
            saveModules,
            bewertung: this.state.bewertung,
        };
        Globals.setWindowKey("pdf-prepare", true);
        this.setState({
            isPrepare: true,
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.state.isPrepare) {
                Globals.setWindowKey("pdf-prepare", false);
                this.setState({
                    isPrepare: false,
                });
            }
        }, 10000);
        setTimeout(() => {
            EventBus.emit<IDocumentationCall>("Documentation.make", saveData);
        }, 1000);
    }
}
