import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EDataType} from "../../../models/EDataType";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {NumericParameter} from "../NumericParameter";

export class LeverageParameter extends NumericParameter {

    constructor(props: any, context: any) {
        super(props, context);
        this.dataType = EDataType.percent;
    }

    protected getValue() {
       return parseFloat(SessionStore.get(EParameters.LeverageParameter));
    }

    protected setValue(v: number) {
       //
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.LeverageParameter];
    }
}
