import {ISzTableColumn, ISzTagOptions} from "../SzTable";

export enum EIndexFunction {
    $row_num= "$row_num",
    $const= "$const",
}

export class SzTableHelper {
    public static columnFunction(index: EIndexFunction | string , text: string = null): ISzTableColumn {
        return {index: "" + index, text};
    }
    public static columnIndex(index: string | number, width?: number): ISzTableColumn {
        if (width) {
            return {index: `${index}`, options: { style: {width} }};
        }
        return {index: `${index}`};
    }
    public static columnMoney(index: string | number, fractionDigits: number = 2, addOptions: ISzTagOptions = {}): ISzTableColumn {
        const formatter = (new Intl.NumberFormat(navigator.language, {  maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits })).format;
        const cn = addOptions.className ? `sz-number ${addOptions.className}` : "sz-number";
        return {index: `${index}`, formatter, options: { ...addOptions, className: cn, fractionDigits}};
    }
    public static columnDelta(index: string | number, fractionDigits: number = 2, addOptions: ISzTagOptions = {}): ISzTableColumn {
        const formatter = (new Intl.NumberFormat(navigator.language, {  maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits,  signDisplay: "always" })).format;
        const cn = addOptions.className ? `sz-number ${addOptions.className}` : "sz-number";
        return {index: `${index}`, formatter, options: { ...addOptions, className: cn, fractionDigits}};
    }
    public static columnPercent(index: string, fractionDigits: number = 2, addOptions: ISzTagOptions = {}): ISzTableColumn {
        const formatter = (new Intl.NumberFormat(navigator.language, { style: "percent", maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits })).format;
        const cn = addOptions.className ? `sz-number ${addOptions.className}` : "sz-number";
        return {index: "" + index, formatter, options: {...addOptions, className: cn, fractionDigits}};
    }
    public static columnCountry(index: string): ISzTableColumn {
        return {index: "" + index, options: {className: "sz-country"}};
    }
}
