import {Button, Checkbox, Intent, NumberRange, Popover, RangeSlider,} from "@blueprintjs/core";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {ETranslation} from "../../const/ETranslation";
import {Globals} from "../../const/Globals";
import {IMarketDataRange} from "../../models/IMarketDataRange";
import {_t} from "../../tools/Translator";
import {BaseFilter, IBaseFilterProperties, IBaseFilterState} from "./BaseFilter";

interface IRangeFilterProperties extends IBaseFilterProperties {
    targetDate: string;
    values: number[];
    last_value: number;
}
interface IRangeFilterState extends IBaseFilterState {
    isOpen: boolean;
    currentRange: NumberRange;
    useFilter: boolean;
}

export class RangeFilter extends BaseFilter<IRangeFilterProperties, IRangeFilterState> {

    private marketDataRange: IMarketDataRange[] = [];
    private sliderChange: boolean = true;
    private numTiles: number;

    private old_useFilter: boolean = false;
    private old_md_0: number;
    private old_md_1: number;

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            loading: true,
            isOpen: false,
            currentRange: [0, 0],
            useFilter: false,
        };
        const l = this.props.values;
        l.forEach( (i, idx) => {
            const min = i;
            const max = l[idx + 1];
            this.marketDataRange.push({
                min,
                max: max === undefined ? this.props.last_value : max,
                tile: idx,
                count: 0,
            });
        });
        this.numTiles = this.marketDataRange.length;
    }
    public componentDidMount(): void {
        (async () => {
            // console.error(this.marketDataRange);
            let currentRange: NumberRange = [0, 0];
            if (this.marketDataRange && this.marketDataRange.length) {
                currentRange = [0, this.numTiles - 1];
            }
            this.setState({
                loading: false,
                currentRange,
            });
        })();
    }

    protected resetFilter() {
        this.setState({
            currentRange: [0, this.numTiles - 1],
            useFilter: false,
        });
    }

    protected renderContent() {
        return (
            <div>
                { this.state.loading ? this.renderLoading() : this.renderMyContent() }
            </div>
        );
    }
    protected renderMyContent(): any {
        const formatter = (new Intl.NumberFormat("de-de", {  maximumFractionDigits: 0, minimumFractionDigits: 0 })).format;
        const active = this.state.useFilter;
        let intent: Intent = active ? Intent.WARNING : Intent.NONE;
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        if (this.state.isOpen) {
            intent = Intent.PRIMARY;
        }
        const testCanClose = (ev) => {
            if (!ev) {
                return;
            }
            if (this.state.isOpen && ev.type === "mousedown") {
                this.setState({isOpen: false});
            }
        };
        const minMax = this.getMinMax(this.state.currentRange);
        const delta = this.state.currentRange[1] - this.state.currentRange[0];
        const f50 = (t) => t === this.props.last_value ? Globals.infinity : Globals.formatter(t, 0);
        const getLabel = (v) => {
            if (!this.marketDataRange) {
                return "";
            }
            if (!this.marketDataRange.length) {
                return "";
            }
            if (this.sliderChange) {
                this.sliderChange = false;
                return "0";
            }
            if (delta === 0 && v === this.state.currentRange[0]) {
                return f50(minMax[0]) + "-" + f50(minMax[1]);
            }
            const r = f50(v >= this.marketDataRange.length - 1 ? this.marketDataRange[v].max : this.marketDataRange[v].min);
            return r;
        };
        // labelRenderer={ (value: number) => getLabel(value) }
        const valid = this.marketDataRange && this.marketDataRange.length;
        return(
            <Popover
                minimal={true}
                position={"bottom-left"}
                boundary={"viewport"}
                usePortal={true}
                hasBackdrop={true}
                popoverClassName={"sz-popover"}
                modifiers={popper}
                isOpen={this.state.isOpen}
                canEscapeKeyClose={true}
                captureDismiss={false}
                onInteraction={(a, b) => testCanClose(b)}
            >
                <Button
                    disabled={!valid}
                    intent={intent} text={this.props.name + (active ? " aktiv" : "")}
                    icon={"euro"}
                    minimal={false}
                    onClick={() => this.setState({isOpen: !this.state.isOpen})}
                />
                <div style={{padding: 16}}>
                    <p>{_t(ETranslation.valuation_date)} {this.props.targetDate}</p>
                    <Checkbox checked={this.state.useFilter} label={_t(ETranslation.apply_filter)} onChange={() => this.onUseFilterChange()} />
                    <div style={{color: "#c1c1c1", textAlign: "left", fontSize: "80%", paddingLeft: 32, paddingRight: 32, paddingBottom: 3, display: "flex", justifyContent: "space-between"}}><span /><span>Mio. €</span></div>
                    <div style={{paddingLeft: 32, paddingRight: 32, minWidth: 400}}>
                        <RangeSlider
                            min={0}
                            max={this.marketDataRange.length - 1}
                            stepSize={1}
                            value={this.state.currentRange}
                            onChange={ (range) => this.onRangeChange(range)}
                            onRelease={ (range) => this.onRangeReleased(this.state.useFilter)}
                            labelRenderer={ (value: number) => getLabel(value) }
                            labelStepSize={1}
                        />
                    </div>
                </div>
            </Popover>
        );
    }
    protected renderLoading() {
        return (
            <Button
                className={"bp3-skeleton"}
                icon={"office"}
                style={{minWidth: 180}}
                minimal={false} />
        );
    }
    private getMinMax(range: NumberRange) {
        if (!range) {
            return [0, 0, 0];
        }
        if (!this.marketDataRange) {
            return [0, 0, 0];
        }
        if (!this.marketDataRange.length) {
            return [0, 0, 0];
        }

        const d_max = range[1];
        const d_min = range[0];
        const delta = d_max - d_min;

        if (!this.marketDataRange[d_min]) {
            return [0, 0, 0];
        }
        if (!this.marketDataRange[d_max]) {
            return [0, 0, 0];
        }

        if (delta === 0) {
            return [this.marketDataRange[d_min].min, this.marketDataRange[d_max].max, 0];
        }

        if (d_max + 1 === this.marketDataRange.length) {
            return [this.marketDataRange[d_min].min, this.marketDataRange[d_max].max, 0];
        } else {
            return [this.marketDataRange[d_min].min, this.marketDataRange[d_max].min, 0];
        }

    }
    private changeValue(useFilter: boolean, range: NumberRange) {
        this.sliderChange = true;
        this.setState({
            useFilter,
            currentRange: range,
        });
    }
    private onRangeReleased(useFilter: boolean) {
        const md = this.getMinMax(this.state.currentRange);
        if (((this.old_md_0 !== md[0] || this.old_md_1 !== md[1]) && this.old_useFilter) || useFilter !== this.old_useFilter) {
            this.old_md_0 = md[0];
            this.old_md_1 = md[1];
            this.old_useFilter = useFilter;
            super.valueChanged([useFilter, md[0], md[1], this.props.targetDate]);
        }
    }
    private onRangeChange(range: NumberRange) {
        this.changeValue(this.state.useFilter, range);
    }

    private onUseFilterChange() {
        const useFilter = !this.state.useFilter;
        this.onRangeReleased(useFilter);
        this.setState({
            useFilter,
        });
    }
}
