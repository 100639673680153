import {CSSProperties} from "react";
import {Spinner} from "@blueprintjs/core";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import * as React from "react";

export const IsLoading = () => {
    const div_style: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        height: 200,
    };
    return (
        <div style={div_style}>
            <Spinner size={75} intent={"primary"} />
            <p className={"bp3-text-muted"} style={{fontSize: "80%"}}>{_t(ETranslation.spinner_standard)}</p>
        </div>
    );
}
