import {_t, _ta} from "../tools/Translator";
import {ETranslation} from "./ETranslation";

const defLabes = {
    capital_employed: `Capital Employed (eingesetztes Kapital) = ${_t(ETranslation.equity)} + ${_t(ETranslation.pension_deficit)} + ${_t(ETranslation.financial_debt)} - ${_t(ETranslation.liquid_funds)} - ${_t(ETranslation.financial_assets)}`,
    debt: `${_t(ETranslation.net_debt)} = ${_t(ETranslation.financial_debt)} + ${_t(ETranslation.pension_deficit)} – ${_t(ETranslation.liquid_funds)}`,
    ffo: `Funds from Operations = EBITDA – Zinsergebnis – Steuern`,
    anlagevermoegen: "Anlagevermögen",
    sales: _t(ETranslation.revenues),
    bilanzsumme: "Bilanzsumme",
    eigenkapital: _t(ETranslation.equity),
    ebit: "Earnings Before Interest And Taxes (EBIT) = Ergebnis vor Zinsen und Steuern",
    ebitda: "Earnings Before Interest, Taxes, Depreciation & Amortization (EBITDA) = Ergebnis vor Abschreibung, Zinsen und Steuern",
    capex: "Capex = Investitionen in immaterielle Vermögenswerte und Sachanlagen",
    netincome: _t(ETranslation.netincome),
    ebit_interest: "EBIT-Zinsdeckung = EBIT / Interest",
    ebitda_interest: "EBITDA-Zinsdeckung = EBITDA / Interest",
    equity: "Equity = Eigenkapital",
    total_assets: "Total Assets = Bilanzsumme",
    capital_assets: "Anlagevermögen = Immaterielle Vermögenswerte + Sachanlagen",
    net_working_capital: "Net Working Capital = Nettoumlaufvermögen",
};
export class FinKeyLabels {
    public static labels = {
        sales_growth: _ta(ETranslation.revenue_growth, " [% p.a.]"),
        ebitda_growth: "EBITDA-Wachstum [% p.a.]",
        gross_sales_ratio: "Rohertrags-Marge [% p.a.]",
        ebitda_sales_ratio: _ta(ETranslation.ebitda_margin, " [% p.a.]"),
        ebit_sales_ratio: _t(ETranslation.ebit_margin)+" [% p.a.]",
        netincome_sales_ratio: "Jahresüberschuss-Marge [% p.a.]",
        roce: "Return on Capital Employed (ROCE) [% p.a.]",
        roe: `${_t(ETranslation.return_on_equity)} (ROE) [% p.a.]`,
        ffo_debt_ratio: "FFO / Debt [% p.a.]",
        debt_ebitda: "Debt / EBITDA",
        debt_ebitda_ratio: "Debt / EBITDA",
        ebit_interest: "EBIT-Zinsdeckung",
        ebit_interest_ratio: "EBIT-Zinsdeckung",
        ebitda_interest: "EBITDA-Zinsdeckung",
        debt_equity_ratio: "Debt / Equity [% p.a.]",
        capex: "Investitionen",
        capex_sales_ratio: "Investitionen / Umsatzerlöse",
        capex_capitalasset_ratio: "Investitionen / Anlagevermögen",
        working_sales_ratio: "Net Working Capital in % Umsatzerlöse",
        //
        anlagevermoegen: "Anlagevermögen",
        sales: "Umsatz",
        bilanzsumme: "Bilanzsumme",
        eigenkapital: _t(ETranslation.equity),
        ebit: "EBIT",
        netincome: _t(ETranslation.netincome),
        eq_ratio: _t(ETranslation.equity_ratio) + " [% p.a.]",
        anlagenintensitaet: "Anlagenintensität [% p.a.]",
        anlagendeckungsgrad: "Anlagendeckungsgrad [% p.a.]",
        capital_turnover: "Kapitalumschlag",
        gearing: _t(ETranslation.gearing),
        capital_employed: "Capital Employed = Eigenkapital + Finanzschulden + Pensionsdefizit – Liquide Mittel – Finanzanlagen",
        xch_sales: "Umsatz (€)",
        xch_ppe_net: "Sachanlagen (€)",
        ff_debt_ebitda_oper: "Debt / EBITDA (bereinigt)",
        ff_debt_ebitda_oper_2: "(Debt + Pensionsdefizit) / EBITDA (bereinigt)",
        ffo_interest: "FFO-Zinsdeckung",
    };
    public static definitions = {

        gross_sales_ratio: ["Rohertrags-Marge = Rohertrag / Umsatzerlöse"],
        ebitda_sales_ratio: [`EBITDA-Marge = EBITDA / ${_t(ETranslation.revenues_2)}`],
        ebit_sales_ratio: [`EBIT-Marge = EBIT / ${_t(ETranslation.revenues_2)}`],
        netincome_sales_ratio: [`${_t(ETranslation.netincome)}-Marge = ${_t(ETranslation.netincome)} / ${_t(ETranslation.revenues_2)}`],
        roce: [
            "Gesamtkapitalrendite (ROCE) = EBIT / Capital Employed",
            defLabes.capital_employed
        ],
        roe: [`${_t(ETranslation.return_on_equity)}(ROE) = ${_t(ETranslation.netincome)} / ${_t(ETranslation.shareholders_equity)}`],
        ffo_debt_ratio: [defLabes.ffo, defLabes.debt],
        debt_ebitda: [defLabes.debt, "EBITDA = Ergebnis vor Abschreibung, Zinsen und Steuern"],
        debt_ebitda_ratio: [defLabes.debt, "EBITDA = Ergebnis vor Abschreibung, Zinsen und Steuern"],
        ebit_interest: [defLabes.ebit_interest],
        ebit_interest_ratio: [defLabes.ebit_interest],
        ebitda_interest: [defLabes.ebitda_interest],
        ebitda_interest_ratio: [defLabes.ebitda_interest],
        debt_equity_ratio: [defLabes.debt, defLabes.equity],

        capex_sales_ratio: [defLabes.capex],
        capex_capitalasset_ratio: [defLabes.capex, "Anlagevermögen = Sachanlagen + Immaterielle Vermögenswerte"],
        working_sales_ratio: [defLabes.net_working_capital],
        working_capital_employed_ratio: [
            defLabes.net_working_capital,
            defLabes.capital_employed
        ],

        eq_ratio: [`${_t(ETranslation.equity_ratio)} = ${_t(ETranslation.equity)} / ${_t(ETranslation.total_assets)}`],
        anlagenintensitaet: ["Anlagenintensität = Anlagevermögen / Bilanzsumme", "Anlagevermögen = Sachanlagen + Immaterielle Vermögenswerte"],
        anlagendeckungsgrad: ["Anlagendeckungsgrad = Eigenkapital / Anlagevermögen", "Anlagevermögen = Sachanlagen + Immaterielle Vermögenswerte"],
        gearing: [`${_t(ETranslation.gearing)} = ${_t(ETranslation.net_debt)} / ${_t(ETranslation.equity)}`],
        capital_turnover: ["Kapitalumschlag = Bilanzsumme / Umsatzerlöse"],
        sales_growth: [`${_t(ETranslation.revenue_growth)} = rel. Veränderung ggü. Vorjahr`],
        ebitda_growth: [`EBITDA-${_t(ETranslation.growth)} = rel. Veränderung ggü. Vorjahr`],
    };
    public static getFinKeyLabel(p: string): string {
        if(p==="#"){
            return "";
        }
        const labels = this.labels;
        if(!labels[p]){
            console.error("no label! ->", p);
        }
        return labels[p] ? labels[p] : "";
    }
}
