import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {EValueName} from "../../../models/EValueName";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {ISzSliderProperties, SzSlider} from "../../widgets/SzSlider";
import {CombiParameter} from "../CombiParameter";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {ISzNumericPercentProperties, SzNumericPercent} from "../../widgets/SzNumericPercent";

const label_style: CSSProperties={
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
};

export class MarketAndCountryRiskParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.MarketAndCountryRiskParameter];
    }

    protected getWidthClass(): string {
        return "fixed-width-600";
    }

    protected async initialize() {
        const m_risk = parseFloat(SessionStore.get(EParameters.MarketRiskPremiumParameter));
        const state: {[k: string]: any} = {};
        state[EParameters.MarketRiskPremiumParameter] = m_risk;
        state[EParameters.CountryRiskUsageParameter] = SessionStore.get(EParameters.CountryRiskUsageParameter);
        state[EParameters.SizePremium] = SessionStore.get(EParameters.SizePremium);

        const values: number[] = [];
        let idx = 0;
        for (let a = 0; a < 33; a++) {
            values[a] = 2 + (a * .25);
            if(m_risk ===  values[a]/100){
                idx=a;
            }
        }
        SessionStore.setGlobalVar(EValueName.market_risk_premium, values[idx] / 100);
        return state;
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                <div style={{paddingTop: 10, paddingRight: 10}} className={"sz-row"}>
                    {this.renderMarketRisk()}
                    {this.renderCountryRisk()}
                    {this.renderSizePremium()}
                </div>
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.MarketAndCountryRiskParameter))}
            </div>
        );
    }

    protected getLabel(): any {
        const m_risk = Globals.formatter_percent(this.state[EParameters.MarketRiskPremiumParameter], 2, 2);
        const options = ParameterOptions.options[EParameters.CountryRiskUsageParameter];
        const c_risk = options.find((i)=>i.value === this.state.CountryRiskUsageParameter);
        const country_iso = SessionStore.get(EParameters.FixedCountriesParameter);
        const fx_lb = c_risk && c_risk.value!=="0" ? ` ${country_iso} | ` : "";
        return `${m_risk} |${fx_lb} ${c_risk? c_risk.label : Globals.hyphen} | ${Globals.formatter_percent(this.state[EParameters.SizePremium])}`;
    }
    private onChangeValue(field: EParameters, _value: any) {
        const _valueAsNumber = _value;
        const state: {[k: string]: any} = {};
        state[field] = _valueAsNumber;
        this.setState(state);
        this.fireValueChange(field, _valueAsNumber);
    }
    private renderCountryRisk() {
        const options = ParameterOptions.options[EParameters.CountryRiskUsageParameter];
        return this.renderColumn("40%", _t(ETranslation.country_risk_premium), ()=>{
            return (
                <div style={{marginLeft: -5, marginRight: -5}}>
                    {this.renderOptionsExt(options, this.state.CountryRiskUsageParameter, EParameters.CountryRiskUsageParameter, "CountryRiskUsageParameter")}
                </div>
            );
        });
    }
    private renderMarketRisk() {
        const values: number[] = [];
        let idx = 0;
        for (let a = 0; a < 33; a++) {
            values[a] = 2 + (a * .25);
            if(this.state[EParameters.MarketRiskPremiumParameter] ===  values[a]/100){
                idx=a;
            }
        }
        const inputProps: ISzSliderProperties = {
            value: idx,
            min: 0,
            max: values.length - 1,
            stepSize: 1,
            stepSizeLabel: 8,
            labelRenderer: (index: number) => {
                return Globals.formatter_percent(values[index] / 100, 2, 0);
            },
            onValueChanged: (index: number) => {
                SessionStore.setGlobalVar(EValueName.market_risk_premium, values[index] / 100);
                this.onChangeValue(EParameters.MarketRiskPremiumParameter, values[index]/100);
            },
        };
        return this.renderColumn("40%", _t(ETranslation.market_risk_premium), ()=>{
            return (
                <div style={{paddingRight: 15, paddingLeft: 15}}>
                    <SzSlider {...inputProps} />
                </div>

            );
        }, label_style);
    }

    private renderSizePremium() {
        const values: number[] = [];
        let idx = 0;
        for (let a = 0; a < 41; a++) {
            values[a] =  (a * .25);
            if(this.state[EParameters.SizePremium] ===  values[a]/100){
                idx=a;
            }
        }
        const inputProps: ISzSliderProperties = {
            value: idx,
            min: 0,
            max: values.length - 1,
            stepSize: 1,
            stepSizeLabel: 8,
            labelRenderer: (index: number) => {
                return Globals.formatter_percent(values[index] / 100, 2, 0);
            },
            onValueChanged: (index: number) => {
                this.onChangeValue(EParameters.SizePremium, values[index]/100);
            },
        };
        return this.renderColumn("40%", _t(ETranslation.company_specific_risk_premium), ()=>{
            return (
                <div style={{paddingRight: 15, paddingLeft: 15}}>
                    <SzSlider {...inputProps} />
                </div>

            );
        }, label_style);
        // let value: number =  this.state[EParameters.SizePremium];
        // if(value > 10 )
        //     value =10;
        // const inputProps: ISzNumericPercentProperties = {
        //     value,
        //     onValueChanged: (v: number) => this.onChangeValue(EParameters.SizePremium, v),
        // };
        // return this.renderColumn("40%", _t(ETranslation.size_premium), ()=>{
        //     return (
        //         <SzNumericPercent {...inputProps} />
        //     );
        // }, label_style);
    }
}
