import {Button, Icon, Intent, Menu, MenuItem, Popover} from "@blueprintjs/core";
import {Modifiers as PopperModifiers} from "popper.js";
import * as React from "react";
import {ParameterConfigs} from "../../const/ParameterConfigs";
import {ParameterOptions} from "../../const/ParameterOptions";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {IOptionEntry} from "../../models/IOptionEntry";
import {IToolBarParameter} from "../../models/IToolBarParameter";
import {ToolBarParameter} from "./ToolBarParameter";
import {IconNames} from "@blueprintjs/icons";
import {IParameterChanged} from "../../models/IParameterChanged";
import {Globals} from "../../const/Globals";
import {EventBus} from "../../services/EventBus";

export abstract class CombiParameter extends ToolBarParameter<IToolBarParameter> {

    protected constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected renderContent(): any {
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        const disabled = false;
        const widthClass = disabled ? "fixed-width-400" : this.getWidthClass();

        const renderMenu = () => {
            if (disabled) {
                return this.renderNoOp();
            }
            return this.renderMenuContent();
        };

        const testCanClose = (ev) => {
            if (!ev) {
                return;
            }
            if (this.state.isOpen && ev.type === "mousedown") {
                this.setState({isOpen: false});
            }
        };
        const onClosed = ()=>{
            this.onClosePopup();
            if(this.props.onClosed){
                this.props.onClosed();
            }
        };
        return (
            <Popover
                minimal={true}
                position={"bottom-left"}
                modifiers={popper}
                popoverClassName={`no-max-width sz-bg-white ${widthClass}`}
                isOpen={this.state.isOpen}
                canEscapeKeyClose={true}
                captureDismiss={false}
                onClosed={ () =>  onClosed()}
                onInteraction={(a, b) => testCanClose(b)}
                disabled={this.tp_disabled}
            >
                <Button disabled={this.tp_disabled} intent={Intent.NONE} minimal={true} className={"sz-toolbar-button"} icon={this.icon} onClick={() => this.setState({isOpen: !this.state.isOpen})}>
                    {this.renderName()}
                    <div className={"sz-property-value"}>{this.safeGetLabel()}</div>
                </Button>
                {renderMenu()}
            </Popover>
        );
    }

    protected abstract getLabel(): any;
    protected abstract renderMenuContent(): any;
    protected onClosePopup(){
        //
    }
    protected getWidthClass() {
        return "fixed-width-600";
    }
    protected renderOptionsShort(parameter: EParameters, isDisabled?: (o: IOptionEntry) => any) {
        const options = ParameterOptions.options[parameter];
        const selectedValue = this.state[parameter];
        const fieldName = parameter;
        return this.renderOptions(options, selectedValue, parameter, fieldName, isDisabled);
    }
    protected renderOptions(options: IOptionEntry[], selectedValue, parameter: EParameters, fieldName, isDisabled?: (o: IOptionEntry) => any) {
        if (!options) {
            return (<span>Liste ist Leer.</span>);
        }
        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return <Icon icon={IconNames.BLANK}/>;
            }
        };
        let cKey = 0;
        const mapEntry = (o) => {
            const d = isDisabled && isDisabled(o);
            return (<MenuItem className={ o.value === selectedValue ? "sz-option-selected" : undefined} disabled={this.disabled || d} key={"" + parameter + (cKey++)} intent={ o.value === selectedValue ? "primary" : "none"} labelElement={getIcon(o.value === selectedValue)}  text={o.label} onClick={() => { this.changeState(o.value, parameter, fieldName); }} />);
        };
        return options.map(mapEntry);
    }
    protected renderOptionsExt(options: IOptionEntry[], selectedValue, parameter: EParameters, fieldName, isDisabled?: (o: IOptionEntry) => any) {
        if (!options) {
            return (<span>Liste ist Leer.</span>);
        }
        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return <Icon icon={IconNames.BLANK}/>;
            }
        };
        let cKey = 0;
        const mapEntry = (o) => {
            const d = isDisabled && isDisabled(o);
            return (<MenuItem disabled={this.disabled || d} className={ o.value === selectedValue ? "sz-option-selected" : undefined} key={"" + parameter + (cKey++)} intent={ o.value === selectedValue ? "primary" : undefined} labelElement={getIcon(o.value === selectedValue)}  text={o.label} onClick={() => { this.changeState(o.value, parameter, fieldName); }} />);
        };
        return (
            <Menu>
                {options.map(mapEntry)}
            </Menu>
        );
    }
    protected fireValueChange(parameterName: EParameters, value) {
        this.fireChanges([{
            component: parameterName,
            value: Array.isArray(value) ? value : [value],
        }]);
    }
    protected changeState(value, parameterType: EParameters, field: string) {
        if (value === this.state[field]) {
            return;
        }
        const newState = {};
        newState[field] = value;
        this.setState(newState);
        this.fireValueChange(parameterType, value);
    }

    protected getOptionLabel(parameter: EParameters, useShort?: boolean): string {
        return ParameterOptions.getOptionLabel(parameter, this.state, useShort);
    }
    protected getOptionLabelHard(parameter: EParameters, useShort?: boolean): string {
        return ParameterOptions.getOptionLabelHard(parameter, this.state, useShort);
    }

    protected initializeShort(parameter: EParameters) {
        const state: {[k: string]: any} = {};
        const parameterComposition = ParameterConfigs.configs[parameter];
        parameterComposition?.parameterComposition.forEach( (s) => state[s] =  SessionStore.get(s));
        return state;
    }
    protected collectChanges(parameter: EParameters){
        const parameterComposition = ParameterConfigs.configs[parameter];
        const to_fire: IParameterChanged[] = [];
        parameterComposition?.parameterComposition.forEach( (s) =>{
            const value = this.state[s];
            to_fire.push({
                component: s,
                value: Array.isArray(value) ? value : [value],
            });
        });
        return to_fire;
    }
    protected fireShort(parameter: EParameters): IParameterChanged[] {
        const to_fire: IParameterChanged[] = this.collectChanges(parameter);
        this.fireChanges(to_fire);
        return to_fire;
    }
    protected fireShortAsEvent(parameter: EParameters): IParameterChanged[] {
        const to_fire: IParameterChanged[] = this.collectChanges(parameter);
        EventBus.emit(`ParameterChanged::${parameter}`, to_fire);
        return to_fire;
    }
    protected getParameterLabel(parameter: EParameters){
        const p = this.getParameterConfig(parameter);
        return p ? p.name : Globals.hyphen;
    }
}
