import {BondSector} from "../../../const/BondSector";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {Sectors} from "../../../const/Sectors";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {ToolBarParameter} from "../ToolBarParameter";
import {Modifiers as PopperModifiers} from "popper.js";
import {Button, Intent, Popover} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {SessionStore} from "../../../const/SessionStore";
import {Globals} from "../../../const/Globals";
import {EventBus} from "../../../services/EventBus";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

let table_ids = 1;
const state_selections = {
    "1_0": ["1_1","1_2","1_3","1_4","1_0"],
    "2_0": ["2_1","2_2","2_3","2_4","2_0"],
    "3_0": ["3_1","3_2","3_3","3_4","3_0"],
    "4_0": ["4_2","4_3","4_4","4_5","4_0"],

    "0_1": ["1_1","2_1","3_1","0_1",],
    "0_2": ["1_2","2_2","3_2","0_2",],
    "0_3": ["1_3","2_3","3_3","0_3",],
    "0_4": ["1_4","2_4","3_4","0_4",],
}
const sector_examples = {
    1100: [{ iso_2: "us", name: "Alcoa"}, { iso_2: "de", name: "ThyssenKrupp"}],
    1200: [{ iso_2: "us", name: "Caterpillar"}, { iso_2: "de", name: "Continental"}],
    1300: [{ iso_2: "us", name: "Apple"}, { iso_2: "de", name: "Jenoptik"}],
    1400: [{ iso_2: "us", name: "Harley-Davidson"}, { iso_2: "de", name: "MAN"}],
    2100: [{ iso_2: "us", name: "Exxon Mobile"}, { iso_2: "de", name: Globals.hyphen}],
    2200: [{ iso_2: "us", name: "Symrise"}, { iso_2: "de", name: "BASF"}],
    2300: [{ iso_2: "us", name: "Sanofi"}, { iso_2: "de", name: "Bayer"}],
    2400: [{ iso_2: "us", name: "Coca Cola"}, { iso_2: "de", name: "Adidas"}],
    3100: [{ iso_2: "us", name: "Halliburton"}, { iso_2: "de", name: "Hochtief"}],
    3200: [{ iso_2: "us", name: "Adecco"}, { iso_2: "de", name: "GfK"}],
    3250: [{ iso_2: "us", name: "Dole"}, { iso_2: "de", name: "Metro"}],
    3300: [{ iso_2: "us", name: "VeriSign"}, { iso_2: "de", name: "SAP"}],
    3350: [{ iso_2: "us", name: "United Health"}, { iso_2: "de", name: "RHON-Klinikum"}],
    3400: [{ iso_2: "us", name: "NH Hotels"}, { iso_2: "de", name: "Axel Springer"}],
    3500: [{ iso_2: "us", name: "Walmart"}, { iso_2: "de", name: "Fielmann"}],
    4600: [{ iso_2: "us", name: "FedEx"}, { iso_2: "de", name: "Deutsche Lufthansa"}],
    4700: [{ iso_2: "us", name: "Veolia Environment"}, { iso_2: "de", name: "RWE"}],
    4800: [{ iso_2: "us", name: "Axa"}, { iso_2: "de", name: "Deutsche Bank"}],
    4900: [{ iso_2: "us", name: "Vodafone"}, { iso_2: "de", name: "Deutsche Telekom"}],
};

const render_example = (code)=>{
    const item = sector_examples[code];
    if(!Array.isArray(item)){
        return null;
    }
    const imgStyle: CSSProperties = {
        height: 12,
        marginRight: 5,
    };
    return item.map((i, index)=>{
        return (
            <div className={"bp3-text-muted"} style={{display: "flex", fontSize: "90%", marginTop: index > 0 ? 3 : 0}}>
                <img style={imgStyle} src={`${Globals.BASE_DIR}images/flags/${i.iso_2}.jpg`}/>
                <span>{i.name}</span>
            </div>
        );
    });
};

const get_sector_name = (code, margin_bottom = false)=>{
    const f = Sectors.sectors_map["" + code];
    if(!f){
        return code;
    }
    return (
        <div style={{ marginBottom: margin_bottom ? 20 : 0,  marginTop: margin_bottom ? 5 : 0}}>
            <div style={{marginBottom: 5}}>{f} [{code}]</div>
            {render_example(code)}
        </div>
    );
};

export class BondSectorParameter extends ToolBarParameter<IToolBarParameter> {

    private table_id = `BondSectorParameter_${table_ids++}`;

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getDocLabel(v){
        if(!v){
            return BondSector.matrix_to_label["0_0"];
        }
        if(v==="—"){
            return BondSector.matrix_to_label["0_0"];
        }
        const result = [];
        v.split(",").forEach((label) => {
            result.push(Sectors.sectors_map[label]);
        });
        return result.join(", ");
    }
    public static getPdfLabel(){
        const sector = SessionStore.get(EParameters.BondSectorParameter);
        // console.error(sector);
        if(!sector){
            return {
                text: BondSector.matrix_to_label["0_0"],
            };
        }
        const result = [];
        sector.split(",").forEach((label) => {
            result.push(Sectors.sectors_map[label]);
        });
        return {
            text: result,
        };
    }

    protected getWidthClass(): string {
        return "fixed-width-1050";
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.BondSectorParameter];
    }

    protected getLabel(): string {
        const sector = this.state[EParameters.BondSectorParameter];
        if(!sector){
            return BondSector.matrix_to_label["0_0"];
        }
        const xy = BondSector.value_to_matrix[sector];
        return BondSector.matrix_to_label[xy] ? BondSector.matrix_to_label[xy] : Globals.hyphen;
    }

    protected async initialize(){
        const sectors = SessionStore.get(EParameters.BondSectorParameter);
        const state = {};
        state[EParameters.BondSectorParameter] = sectors;
        return state;
    }

    protected renderContent() {
        const popper: PopperModifiers = {
            offset: { offset: "[0, 4]" },
        };
        const widthClass = this.getWidthClass();
        const onClosed = ()=>{
            const sectors = SessionStore.get(EParameters.BondSectorParameter);
            const state_sectors = this.state[EParameters.BondSectorParameter];
            if(sectors !== state_sectors){
                // EParameters.CreditSpreadMetricWeightsParameter, [.2, .2, .2, .2, .2]);
                // const mode = _a(EParameters.CreditSpreadMetricWeightsModeParameter
                EventBus.emit("CreditSpreadMetricWeightsParameter::changed", BondSector.weights_sector[this.state.mKey]);
                this.fireChanges([
                    {component: EParameters.BondSectorParameter, value: [state_sectors]},
                    {component: EParameters.CreditSpreadMetricWeightsParameter, value: [BondSector.weights_sector[this.state.mKey]]},
                    {component: EParameters.CreditSpreadMetricWeightsModeParameter, value: [false]},
                ]);
            }
        };
        return (
            <Popover
                minimal={true}
                position={"bottom-left"}
                modifiers={popper}
                popoverClassName={`no-max-width ${widthClass}`}
                content={this.getMenu()}
                disabled={this.tp_disabled}
                onClosed={()=>onClosed()}
            >
                <Button intent={Intent.NONE} minimal={true} className={"sz-toolbar-button"} icon={this.icon} disabled={this.tp_disabled}>
                    {this.renderName()}
                    <div className={"sz-property-value"}>{this.safeGetLabel()}</div>
                </Button>
            </Popover>
        );
    }
    protected changeState(parameterType: EParameters, mKey) {
        if (mKey === this.state.mKey) {
            return;
        }
        const newState = {} as any;
        newState[parameterType] = BondSector.matrix_to_value[mKey];
        newState.mKey = mKey;
        this.setState(newState);
    }
    private getMenu(){
        const sector = this.state[EParameters.BondSectorParameter];
        const xy = BondSector.value_to_matrix[sector];
        const cssHeaderRow: CSSProperties = {
            textAlign: "left",
            fontWeight: "bold",
            borderBottom: "1px solid rgba(16, 22, 26, 0.15)",
        };
        const cssBorderRight: CSSProperties = {
            borderRight: "1px solid rgba(16, 22, 26, 0.15)",
        };
        const cssHeaderCol: CSSProperties = {
            fontWeight: "bold",
            verticalAlign: "top",
            width: 140,
        };
        const cssCell: CSSProperties = {
            textAlign: "left",
            verticalAlign: "top",
            width: 220,
        };
        const cl= (col, row, addi)=>{
            const key = `${col}_${row}`;
            // console.error(sector, xy, key);

            if(!sector && key === "0_0"){
                return  `cell row_num_${row} col_num_${col} ${addi} select`;
            }
            if(sector === key){
                return  `cell row_num_${row} col_num_${col} ${addi} select`;
            }
            if(xy === key){
                return  `cell row_num_${row} col_num_${col} ${addi} select`;
            }
            const ar = state_selections[xy];
            if(Array.isArray(ar) && ar.includes(key)){
                return  `cell row_num_${row} col_num_${col} ${addi} select`;
            }

            return  `cell row_num_${row} col_num_${col} ${addi}`;
        };
        const clear_all= (selected_too?)=>{
          const tds = document.querySelectorAll(`#${this.table_id}.sz-table-matrix td`);
          tds.forEach((td)=>{
              if(selected_too === true){
                  td.classList.remove("select", "_col_f","_col_com","_col_l","_row_f","_row_com","_row_l", "_box_bl","_box_b","_box_br");
              }else{
                  td.classList.remove("_col_f","_col_com","_col_l","_row_f","_row_com","_row_l", "_box_bl","_box_b","_box_br");
              }
          });
        };
        const hl_any = (key, class_names: string[], and_select = false)=>{
            clear_all(and_select);
            window.requestAnimationFrame(()=>{
                const tds = document.querySelectorAll(`#${this.table_id}.sz-table-matrix td.${key}`);
                tds.forEach((td)=>{
                    class_names.forEach((col_class)=>{
                        if(td.classList.contains(col_class)){
                            td.classList.add(`_${col_class}`);
                            if(and_select){
                                td.classList.add("select");
                            }
                        }
                    });
                });
            });
        };
        const hl_column= (col_num, and_select = false)=>{
            const key = `col_num_${col_num}`;
            hl_any(key, ["col_f","col_com","col_l"], and_select);
            if(and_select){
                this.changeState(EParameters.BondSectorParameter , `${col_num}_0`);
            }
        };
        const hl_row= (row_num, and_select = false)=>{
            const key = `row_num_${row_num}`;
            hl_any(key, ["row_f","row_com","row_l"], and_select);
            if(and_select){
                this.changeState(EParameters.BondSectorParameter , `0_${row_num}`);
            }
        };
        const hl_box= (col_num, and_select = false)=>{
            const key = `col_num_${col_num}`;
            hl_any(key, ["col_f","box_bl","box_b","box_br"], and_select);
            if(and_select){
                this.changeState(EParameters.BondSectorParameter , `${col_num}_0`);
            }
        };
        const hl_cell= (col_num, row_num)=>{
            clear_all(true);
            const key = `cell.row_num_${row_num}.col_num_${col_num}`;
            const mkey = `${col_num}_${row_num}`;
            const td = document.querySelector(`#${this.table_id}.sz-table-matrix td.${key}`);
            if(td){
                if(!td.classList.contains("select")){
                    td.classList.add("select");
                    this.changeState(EParameters.BondSectorParameter , mkey);
                }
            }
        };
        const sl= (col_num, row_num, class_names)=>{
            const key = `${col_num}_${row_num}`;
            const ar = state_selections[xy];
            if(Array.isArray(ar) && ar.includes(key)){
                return  `${class_names} select`;
            }
            return class_names;
        };
        return (
            <div style={{paddingTop: 10, paddingRight: 10, paddingBottom: 10, cursor: "pointer"}}>
                <table style={{fontSize: "80%"}} className={"sz-table-matrix"} id={this.table_id}>
                    <tbody>
                    <tr>
                        <td style={{...cssHeaderCol, ...cssBorderRight, ...{borderBottom: "1px solid rgba(16, 22, 26, 0.15)"}}} onClick={()=> hl_cell(0,0)} className={cl(0, 0, "")}>Alle*</td>
                        <td style={{...cssHeaderRow}} onClick={()=> hl_column(1, true)} onMouseEnter={()=> hl_column(1)} onMouseLeave={()=>clear_all()} className={sl(1, 0, "col_num_1 col_f")}>langlebige Güter</td>
                        <td style={{...cssHeaderRow}} onClick={()=> hl_column(2, true)} onMouseEnter={()=> hl_column(2)} onMouseLeave={()=>clear_all()} className={sl(2, 0, "col_num_2 col_f")}>kurzlebige Güter</td>
                        <td style={{...cssHeaderRow}} onClick={()=> hl_column(3, true)} onMouseEnter={()=> hl_column(3)} onMouseLeave={()=>clear_all()} className={sl(3, 0, "col_num_3 col_f")}>Dienstleistungen</td>
                        <td style={{width: 20}}>&nbsp;</td>
                        <td style={{...cssHeaderRow}}onClick={()=> hl_column(4, true)} onMouseEnter={()=> hl_column(4)} onMouseLeave={()=>clear_all()} className={sl(4, 0, "col_num_4 col_f")}>Infrastruktur</td>
                    </tr>
                    <tr>
                        <td style={{...cssHeaderCol, ...cssBorderRight}} className={sl(0, 1, "row_num_1 row_f")} onClick={()=> hl_row(1, true)} onMouseEnter={()=> hl_row(1)} onMouseLeave={()=>clear_all()}>Materialien</td>
                        <td style={cssCell} onClick={()=> hl_cell(1,1)} className={cl(1, 1, "col_com row_com")}>{get_sector_name(1100)}</td>
                        <td style={cssCell} onClick={()=> hl_cell(2,1)} className={cl(2, 1, "col_com row_com")}>{get_sector_name(2100)}</td>
                        <td style={cssCell} onClick={()=> hl_cell(3,1)} className={cl(3, 1, "col_com row_l")}>{get_sector_name(3100, true)}</td>
                        <td rowSpan={4} style={{cursor: "not-allowed", width: 20}}>&nbsp;</td>
                        <td style={cssCell} onClick={()=> hl_cell(4,2)} className={cl(4,2, "col_com")}>{get_sector_name(4600)}</td>
                    </tr>
                    <tr>
                        <td style={{...cssHeaderCol, ...cssBorderRight}} className={sl(0, 2, "row_num_2 row_f")} onClick={()=> hl_row(2, true)} onMouseEnter={()=> hl_row(2)} onMouseLeave={()=>clear_all()}>Produktion</td>
                        <td style={cssCell} onClick={()=> hl_cell(1,2)} className={cl(1, 2, "col_com row_com")}>{get_sector_name(1200)}</td>
                        <td style={cssCell} onClick={()=> hl_cell(2,2)} className={cl(2, 2, "col_com row_com")}>{get_sector_name(2200)}</td>
                        <td style={cssCell} onClick={()=> hl_cell(3,2)} className={cl(3, 2, "col_com row_l")}>{get_sector_name(3200)}{get_sector_name(3250, true)}</td>
                        <td style={cssCell} onClick={()=> hl_cell(4,3)} className={cl(4,3, "col_com")}>{get_sector_name(4700)}</td>
                    </tr>
                    <tr>
                        <td style={{...cssHeaderCol, ...cssBorderRight}} className={sl(0, 3, "row_num_3 row_f")} onClick={()=> hl_row(3, true)} onMouseEnter={()=> hl_row(3)} onMouseLeave={()=>clear_all()}>Technologie</td>
                        <td style={cssCell} onClick={()=> hl_cell(1,3)} className={cl(1, 3, "col_com row_com")}>{get_sector_name(1300)}</td>
                        <td style={cssCell} onClick={()=> hl_cell(2,3)} className={cl(2, 3, "col_com row_com")}>{get_sector_name(2300)}</td>
                        <td style={cssCell} onClick={()=> hl_cell(3,3)} className={cl(3, 3, "col_com row_l")}>{get_sector_name(3300)}{get_sector_name(3350, true)}</td>
                        <td style={cssCell} onClick={()=> hl_cell(4,5)} className={cl(4,5, "col_l")}>{get_sector_name(4900)}</td>
                    </tr>
                    <tr>
                        <td style={{...cssHeaderCol, ...cssBorderRight}} className={sl(0, 4, "row_num_4 row_f")} onClick={()=> hl_row(4, true)} onMouseEnter={()=> hl_row(4)} onMouseLeave={()=>clear_all()}>Endverbraucher</td>
                        <td style={cssCell} onClick={()=> hl_cell(1,4)} className={cl(1, 4, "col_l row_com")}>{get_sector_name(1400)}</td>
                        <td style={cssCell} onClick={()=> hl_cell(2,4)} className={cl(2, 4, "col_l row_com")}>{get_sector_name(2400)}</td>
                        <td style={cssCell} onClick={()=> hl_cell(3,4)} className={cl(3, 4, "col_l row_l")}>{get_sector_name(3400)}{get_sector_name(3500, true)}</td>
                        <td>&nbsp;</td>
                    </tr>
                    </tbody>
                </table>
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", paddingTop: 10, paddingLeft: 10}}>
                    * {_t(ETranslation.default_smartzebra)}; Unternehmensnamen sind Beispiele
                </div>
            </div>
        );
    }
}
