import * as React from "react";
import {useEffect, useState} from "react";
import {IsLoading} from "./hooks/IsLoading";
import {ServiceCalls} from "../services/ServiceCalls";
import {ShowMessage} from "./hooks/ShowMessage";

import {
    EProjectType,
    EProjectUserRole,
    IProject,
    ProjectGroup,
    ProjectTypeModuleMap,
    ProjectTypeTranslation,
} from "../models/IProject";
import {Globals} from "../const/Globals";
import {Tooltip2} from "@blueprintjs/popover2";
import {Alert, Button, Icon, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

import NewProjectDialog from "./widgets/NewProjectDialog";
import EditProjectDialog from "./widgets/EditProjectDialog";
import ShareProjectDialog from "./widgets/shareProjectDialog";
import {ETranslation} from "../const/ETranslation";
import {_t} from "../tools/Translator";
import {ChefCalls} from "../services/ChefCalls";
import {Chargebee} from "../helpers/Chargebee";
import {DateTime} from "luxon";


const loadProjects = (setter: any) => {
    const [projects, setProjects] = useState(null);
    const [error, setError] = useState(null);
    const [is_loading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const sc = new ServiceCalls();
                const a = await sc.getProjects();
                let p: IProject[];
                if (a && Array.isArray(a.response)) {
                    p = a.response;
                } else {
                    p = [];
                }
                setProjects(p);
                setter(p);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);
    return [projects, error, is_loading];
};

interface IProjectButton {
    text: string;
    projectType: number;
    backgroundColor?: string;
    hover?:string;
    projects: IProject[];
    onClick?(): void;
    onMouseEnter?():void;
    onMouseLeave?():void;
}
const allProjects: IProject[] = [];
const ProjectButton = (props: IProjectButton) => {
    const [isHovered,setIsHovered] = useState(false);
    return (
        <>
            <div
                className={"project-button"}
                style={{ backgroundColor: !isHovered ? props.backgroundColor: props.hover, cursor: "pointer",   }}
                onClick={() =>{
                    if(props.onClick){
                        props.onClick();
                    }
                }}
                onMouseEnter={()=> setIsHovered(true)}
                onMouseLeave={()=> setIsHovered(false)}
            >
                <div className="btn-icon"></div>
                <div className={"project-button-title"}>{props.text}</div>
            </div>
        </>
    );
};

interface IDialogState {
    isOpen: boolean;
    project?: IProject;
    isDuplicate?: boolean;
}
const openDlg = (project: IProject, name?: string): IDialogState =>{
    if(!name){
        return ({isOpen: true, project});
    }else{
        const p: IProject = {...{}, ...project};
        p.name = name;
        return ({isOpen: true, project: p, isDuplicate: true});
    }

};
export const MainProjectsView = ({ onProjectSelected }) => {
    const [editDlg, setEditDlg] = useState<IDialogState>({isOpen: false});
    const [shareDlg, setShareDlg] = useState<IDialogState>({isOpen: false});
    const [confirmDeleteDlg, setConfirmDeleteDlg] = useState<IDialogState>({isOpen: false});

    const [createDlgIsOpen, setCreateDlgIsOpen] = useState(false);
    const [newProjectType, setNewProjectType] = useState(undefined);
    const [orderDlg, setOrderDlg] = useState(false);

    const [projects, setProjects] = useState<IProject[]>();
    const [_projects, error, is_loading] = loadProjects(setProjects);
    const [sortBy, setSortBy] = useState("none");

    if (error) {
        return <ShowMessage message={error} />;
    }
    if (is_loading) {
        return <IsLoading />;
    }

    const deleteProject = (_id: string) => {
        (async ()=>{
            const cb = new ServiceCalls();
            const response = await cb.deleteProject(_id);
            if(response.response && response.response.deletedCount){
                // remove deleted project from project list
                const recent = projects.filter((p)=> p._id!==_id);
                setProjects(recent);
            }
        })();

    }
    const renderProject = (p: IProject, idx: number) => {
        const getProjectCode = (pt: EProjectType) => {
            const to_str = ProjectGroup[pt];
            if(!to_str){
                return "??";
            }
            return to_str;
        };
        const getProjectType = (pt: EProjectType) =>{
            const et = ProjectTypeTranslation[pt];
            return _t(et);
        }
        const renderText = (t: string) => {
            return t;
        };
        const renderState = () => {
            if (p && Array.isArray(p.project_users)) {
                let r: EProjectUserRole = EProjectUserRole.unset;
                p.project_users.forEach((i) => {
                    if (i.user._id === Globals.user_id && i.role > r) {
                        r = i.role;
                    }
                });
                if (r >= EProjectUserRole.reader) {
                    return (
                        <Tooltip2
                            content={"Zum Bearbeiten gesperrt."}
                            openOnTargetFocus={false}
                        >
                            <Icon icon={IconNames.LOCK} size={12} />
                        </Tooltip2>
                    );
                }
            }
            return null;
        };

        return (
            <div className="product-card" onClick={() => onProjectSelected(p)}>
                <div className={"product-badge"}>
                    <div
                        className={"product-icon"}
                        style={{backgroundColor:getProjectCode(p.project_type) === "VP" ? "#41799b" : "#41949b",}}
                    ></div>
                    <div style={{ position: "absolute", color:"#fff", left:"17px" }}>
                        {getProjectCode(p.project_type)}
                    </div>
                    <div style={{ position:"absolute", left:"48px"}}>{getProjectType(p.project_type)}</div>
                </div>
                <div
                    className={"product-desc"}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width:"80px",
                    }}
                >
                    <div >{renderText(p.name)}</div>
                </div>
                <div style={{width:"150px"}}>{p.description.length >50 ? `${p.description.substring(0,50)}...` : p.description }</div>
                <div style={{width:"100px"}}>{DateTime.fromISO(p.created).toFormat("dd.LL.yyyy HH:mm")}</div>
                <div className={"product-action"}>
                    {renderState()}
                    <div className={"product-action-buttons"} >
                        <Tooltip2 content={"Bearbeiten"}>
                            <Button style={{width:"40px", }} minimal={true} icon={IconNames.EDIT} intent={"primary"} onClick={(e: any)=>{e.stopPropagation();  setEditDlg(openDlg(p))}}/>
                        </Tooltip2>
                        <Tooltip2 content={"Teilen"}>
                            <Button style={{width:"40px"}} minimal={true} icon={IconNames.SHARE} intent={"primary"} onClick={(e: any)=>{e.stopPropagation();  setShareDlg(openDlg(p))}}/>

                        </Tooltip2>
                        <Tooltip2 content={"Duplizieren"}>
                            <Button style={{width:"40px"}} minimal={true} icon={IconNames.DUPLICATE} intent={"primary"} onClick={(e: any)=>{e.stopPropagation(); setEditDlg(openDlg(p,`${p.name} (1)`))}} />
                        </Tooltip2>
                        <Tooltip2 content={"Löschen"}>
                            <Button style={{width:"40px"}} minimal={true} icon={IconNames.TRASH} intent={"danger"} onClick={(e: any)=>{e.stopPropagation(); setConfirmDeleteDlg(openDlg(p));}} />
                        </Tooltip2>
                    </div>
                </div>
            </div>
        );
    };

    const renderProjects = () => {
        if (!Array.isArray(projects) || !projects.length ) {
            window.console.log("Empty Array");
            return (
                <div className="bp3-non-ideal-state">
                    <div className="bp3-non-ideal-state-visual">
                        <span   className="bp3-icon bp3-icon-folder-open">
                            <svg data-icon="folder-open" width="60" height="60" viewBox="0 0 20 20">
                                <path d="M20 9c0-.55-.45-1-1-1H5c-.43 0-.79.27-.93.65h-.01l-3 8h.01c-.04.11-.07.23-.07.35 0 .55.45 1 1 1h14c.43 0 .79-.27.93-.65h.01l3-8h-.01c.04-.11.07-.23.07-.35zM3.07 7.63C3.22 7.26 3.58 7 4 7h14V5c0-.55-.45-1-1-1H8.41l-1.7-1.71A.997.997 0 006 2H1c-.55 0-1 .45-1 1v12.31l3.07-7.68z" fill-rule="evenodd"></path>
                            </svg>
                        </span>
                    </div>
                    <h4 className="bp3-heading">{_t(ETranslation.empty_msg)}</h4>
                </div>
            );
        }
        const sort_dir = sortBy && sortBy.endsWith("Asc") ? 1 : -1;
        const sortedProject: IProject[] = [].concat(projects);
        if (sortBy && sortBy.startsWith("Name")){
            sortedProject.sort((a, b)=>{
                return sort_dir * a.name.toUpperCase().localeCompare(b.name.toUpperCase());
            });
        }
        if (sortBy && sortBy.startsWith("Date")){
            sortedProject.sort( (a,b) => {
                return sort_dir * (DateTime.fromISO(a.created).toMillis() - DateTime.fromISO(b.created).toMillis());
            })
        }

        return (sortedProject.map((p, idx) => renderProject(p, idx)));
    };
    const clVp: string = "#41799b";
    const clOther: string = "#41949b";
    const vpHover: string = "#137cbd";
    const clHover:string = "#29bdab";

    const newProject = (_pt: EProjectType)=>{
        const pt = ProjectGroup[_pt];
        (async ()=>{
            const cc = new ChefCalls();
            const response = await cc.isChargebeeUser();
            const lic = Array.isArray(response.subscriptions) && response.subscriptions.find((s)=> s.item === pt);
            setNewProjectType(_pt);
            setCreateDlgIsOpen(lic);
            setOrderDlg(!lic);
        })();
    };
    const renderSortBy = (column: string)=>{
        const col_name = sortBy.startsWith(column);
        const col_dir = sortBy.endsWith("Asc");
        if(sortBy === "none"){
            return <Icon icon={IconNames.DOUBLE_CARET_VERTICAL} size={14} onClick={()=>setSortBy(`${column}Asc`)}/>;
        }
        if(col_name){
            if(col_dir){
                return <Icon icon={IconNames.CARET_DOWN} size={14} onClick={()=>setSortBy(`${column}Des`)}/>;
            }else{
                return <Icon icon={IconNames.CARET_UP} size={14} onClick={()=>setSortBy(`none`)}/>;
            }
        }
        return null;
    };
    return (
        <div className="main-project-view-container">
            <div className={"left-container"}>
                <div className="buttons-container">
                    <div className="buttons-wrapper">
                        <div className="title-container">
                            <div className={"title"} style={{ fontWeight: "bold" }}>{_t(ETranslation.new_project)}</div>
                        </div>
                        <div className="VP-container">
                            <div className={"title"}>{_t(ETranslation.licence_group_company_valuation)}</div>
                            <div className="VP-buttuns-container">
                                <ProjectButton
                                    text={_t(ETranslation.template_name_valuation_pro)}
                                    projectType={EProjectType.valuation}
                                    backgroundColor={clVp}
                                    hover={vpHover}
                                    projects={projects}
                                    onClick={()=> newProject(EProjectType.valuation)}
                                />
                                <ProjectButton
                                    text={_t(ETranslation.template_name_beta_factors)}
                                    projectType={EProjectType.valuation}
                                    backgroundColor={clVp}
                                    hover={vpHover}
                                    projects={projects}
                                    onClick={()=> newProject(EProjectType.vp_beta)}
                                />
                                <ProjectButton
                                    text={_t(ETranslation.template_name_multiples)}
                                    projectType={EProjectType.valuation}
                                    backgroundColor={clVp}
                                    hover={vpHover}
                                    projects={projects}
                                    onClick={()=> newProject(EProjectType.vp_multiples)}
                                />
                                <ProjectButton
                                    text={_t(ETranslation.template_name_benchmarking)}
                                    projectType={EProjectType.valuation}
                                    backgroundColor={clVp}
                                    hover={vpHover}
                                    projects={projects}
                                    onClick={()=> newProject(EProjectType.vp_benchmarking)}
                                />
                            </div>
                        </div>
                        <br/>
                        <div className="WA-container">
                            <div className={"title"}>{_t(ETranslation.licence_group_fiaxed_income)}</div>
                            <div className="WA-buttons-container">
                                <ProjectButton
                                    text={_t(ETranslation.template_name_credit_spreads)}
                                    projectType={EProjectType.credit_spread}
                                    backgroundColor={clOther}
                                    hover={clHover}
                                    projects={projects}
                                    onClick={()=> newProject(EProjectType.credit_spread)}
                                />
                                <ProjectButton
                                    text={_t(ETranslation.template_name_credit_spreads_sectors)}
                                    projectType={EProjectType.credit_spread}
                                    backgroundColor={clOther}
                                    hover={clHover}
                                    projects={projects}
                                    onClick={()=> newProject(EProjectType.cs_credit_spreads_sector)}
                                />
                            </div>
                        </div>
                        <br/>
                        <div className="WA-container">
                            <div className={"title"}>{_t(ETranslation.transfer_pricing)}</div>
                            <div className="WA-buttons-container">
                                <ProjectButton
                                    text={_t(ETranslation.template_name_transfer_pricing)}
                                    projectType={EProjectType.transfer_pricing}
                                    backgroundColor={clOther}
                                    hover={clHover}
                                    projects={projects}
                                    onClick={()=> newProject(EProjectType.transfer_pricing)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="projects-list-container">
                <div className={"projects-list-wrapper"}>
                    <div className="title-container">
                        <div className="title" >{_t(ETranslation.open_project)}</div>
                    </div>
                    <div className={"list-name-container"} style={{}}>
                        <h4 style={{width:"150px",height:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", paddingLeft:"8px"}}>{_t(ETranslation.type)}</h4>
                        <h4 style={{width:"80px",height:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>{_t(ETranslation.name)}
                            {renderSortBy("Name")}
                        </h4>
                        <h4 style={{width:"150px",height:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>{_t(ETranslation.desciption)}</h4>
                        <h4 style={{width:"100px",height:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start"}}>{_t(ETranslation.created_at)}
                            {renderSortBy("Date")}
                        </h4>
                        <div style={{width:"150px",height:"100%", display:"flex", alignItems:"center", justifyContent:"space-between",marginRight:22}}>
                            {/*
                            <h4 style={{width:"40px",height:"100%",display:"flex", alignItems:"center",justifyContent:"center"}}>{_t(ETranslation.edit)}</h4>
                            <h4 style={{width:"40px",height:"100%",display:"flex", alignItems:"center",justifyContent:"center"}}>{_t(ETranslation.share)}</h4>
                            <h4 style={{width:"40px",height:"100%",display:"flex", alignItems:"center",justifyContent:"center"}}>Duplizieren</h4>
                            <h4 style={{width:"40px",height:"100%",display:"flex", alignItems:"center",justifyContent:"center"}}>{_t(ETranslation.delete)}</h4>
                            */}
                        </div>

                    </div>
                    <div className="projects-container">
                        {renderProjects()}
                    </div>
                </div>
            </div>
            <EditProjectDialog
                isOpen={editDlg.isOpen}
                setIsOpen={setEditDlg}
                project = {editDlg.project}
                isDuplicate = {editDlg.isDuplicate}
                projects={projects}
            />
            <ShareProjectDialog isOpen={shareDlg.isOpen} setIsOpen={setShareDlg} project={shareDlg.project} />
            <Alert
                isOpen={confirmDeleteDlg.isOpen}
                cancelButtonText={_t(ETranslation.cancel)}
                confirmButtonText={_t(ETranslation.yes)}
                icon={IconNames.TRASH}
                intent={"danger"}
                onConfirm={()=>{deleteProject(confirmDeleteDlg?.project?._id); setConfirmDeleteDlg({isOpen: false});}}
                onCancel={()=>{setConfirmDeleteDlg({isOpen: false});}}
            >
                <p>
                    {_t(ETranslation.delete_msg,confirmDeleteDlg?.project?.name)}
                </p>
            </Alert>
            <NewProjectDialog
                isOpen={createDlgIsOpen}
                setIsOpen={setCreateDlgIsOpen}
                projectType={newProjectType}
                projects={projects}
            />
            <Alert
                cancelButtonText={_t(ETranslation.cancel)}
                confirmButtonText="Weiter"
                icon={IconNames.APPLICATION}
                intent={Intent.PRIMARY}
                isOpen={orderDlg}
                onCancel={()=> setOrderDlg(false)}
                onConfirm={()=> {setOrderDlg(false); Chargebee.checkout(ProjectGroup[newProjectType]);}}
            >
                <p>
                    Für diesen Projekttyp fehlt Ihnen eine Lizenz,
                    im Folgendem können Sie eine 30Tage Lizenz erwerben.
                    <br />
                </p>
            </Alert>
        </div>
    );
};
