// <span class="bp3-navbar-divider"></span>
import * as React from "react";
import {EContentType} from "../../const/EContentType";
import {EPackageTypes} from "../../const/EPackageTypes";
import {IParameterConfig} from "../../models/IParameterConfig";
import {IToolBarParameter} from "../../models/IToolBarParameter";
import {ToolBarParameter} from "./ToolBarParameter";

export class DividerParameter extends ToolBarParameter<IToolBarParameter> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    public render() {
        return (<span className="bp3-navbar-divider" />);
    }

    protected getConfig(): IParameterConfig {
        return {
            name: "divider",
            contentType: EContentType.Free,
            minPackage: EPackageTypes.Deu,
            nopeAction: [],
            isPro: false,
            needLoading: false,
        };
    }

    protected getLabel(): string {
        return "";
    }

    protected async initialize(): Promise<any> {
        return Promise.resolve(undefined);
    }

    protected renderContent() {
        return <div></div>;
    }

}
