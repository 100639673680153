import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {BaseDataProvider} from "./BaseDataProvider";
import {getAllNextDates, getResult} from "../../helpers/Helpers";
import {deadlineEoM, fromStr} from "../../tools/DateTools";

const ratings = {
    A: ["AAA", "AA+", "AA", "AA-", "A+", "A", "A-"],
    B: ["BBB", "BBB+", "BBB-", "BB+", "BB", "BB-", "B+", "B", "B-"],
    C: ["CCC", "CCC+", "CCC-", "CC", "C"],
};
const rating_mapping_new = {
    "AAA": ["Aaa"],
    "AA": ["Aa1", "Aa2", "Aa3"],
    "A": ["A1", "A2", "A3"],
    "BBB": ["Baa1", "Baa2", "Baa3"],
    "BB":  ["Ba1", "Ba2", "Ba3"],
    "B":  ["B1", "B2", "B3"],
};
/*
const rating_mapping = {
    "AA": ["Aa2"],
    "AA-": ["Aa3"],
    "A+": ["A1"],
    "A": ["A2"],
    "A-": ["A3"],
    "BBB+": ["Baa1"],
    "BBB": ["Baa2"],
    "BBB-": ["Baa3"],
    "BB+":  ["Ba1"],
    "BB":  ["Ba2"],
    "BB-":  ["Ba3"],
    "B+":  ["B1"],
    "B":  ["B2"],
};
*/
const rating_mapping = {
    "AA": "Aa2",
    "AA-": "Aa3",
    "A+": "A1",
    "A": "A2",
    "A-": "A3",
    "BBB+": "Baa1",
    "BBB": "Baa2",
    "BBB-": "Baa3",
    "BB+":  "Ba1",
    "BB":  "Ba2",
    "BB-":  "Ba3",
    "B+":  "B1",
    "B":  "B2",
};


export class CreditSpreadProvider extends BaseDataProvider {
    protected initProvider() {
        this.className = "CreditSpreadProvider";
        this.parameterMapping = [
            EParameters.DateParameter,
            EParameters.BondSectorParameter,
        ];
    }

    protected update() {
        const data = {
            corporateSpread: null,
            spread_time_line: [],
            bond_data: [],
        };
        const db = new PublicDbCalls();
        (async () => {

            const selected_sectors = SessionStore.get(EParameters.BondSectorParameter);
            let sectors = ["0"];
            if(selected_sectors){
                sectors = sectors.concat(selected_sectors.split(","));
            }
            // console.error(rating, p_rating);
            data.bond_data = await db.getBondTimeSeries(
                "Aa2,Aa3,A1,A2,A3,Baa1,Baa2,Baa3,Ba1,Ba2,Ba3,B1,B2".split(","),
                fromStr(SessionStore.get(EParameters.DateParameter)).setToEoM().asDateDecimal(),
                undefined,
                selected_sectors ? sectors.join(",") : undefined,
            );

            const response = await db.getCorporateSpreadV2([
                [deadlineEoM()],
                ["AA", "AA-", "A+", "A", "A-", "BBB+", "BBB", "BBB-", "BB+", "BB", "BB-", "B+", "B"],
                sectors,
            ]);

            const time_line_response = await db.getCorporateSpreadV3([
                getAllNextDates(deadlineEoM(), 3),
                ["AAA", "AA", "AA-", "A+", "A", "A-", "BBB+", "BBB", "BBB-", "BB+", "BB", "BB-", "B+", "B", "CCC+", "CCC"],
                sectors,
            ]);
            data.spread_time_line = getResult(time_line_response, []);
            try {
                data.corporateSpread = response.result;
                if (Array.isArray(data.corporateSpread) && data.corporateSpread.length === 0) {
                    data.corporateSpread = null;
                }
            } catch (exi) {
                console.error(exi, response);
            }
            this.afterUpdate(data);
        })();
    }
}
