import * as React from "react";
import {useState} from "react";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";

// import MomentLocaleUtils from "react-day-picker";
import { de, enUS, es } from 'date-fns/locale';
import {fromDate, fromStr} from "../../tools/DateTools";
import {DateTime} from "luxon";
import {CaptionProps, DayPicker, DayPickerProps, useNavigation} from "react-day-picker";
import {SzDateButton} from "./SzDateButton";

const locales = {
    de,
    en: enUS,
    es
};

export enum ESzDateMode{
    END_OF_MONTH = 1,
    ANY_DATE
}
export interface ISzDateProps {
    mode: ESzDateMode;
    onValueChanged?(new_value: string);
}

function CustomCaption(props: CaptionProps) {
    const start_date = DateTime.fromJSDate(props.displayMonth).toFormat("dd.LL.yyyy"); // SessionStore.get(EParameters.DateParameter);
    const { goToMonth, nextMonth, previousMonth } = useNavigation();
    return (
        <SzDateButton key={start_date} date={start_date} onDateChanged={ (m, y) =>{
            goToMonth(fromDate(y,m,1).toJSDate());
        }} />
    );
}
export function SzDate(props: ISzDateProps) {
    const [value, setValue] = useState(SessionStore.get(EParameters.DateParameter));
    // console.error(value);
    const changeValue = (new_date)=>{
        const date = DateTime.fromJSDate(new_date).toFormat("dd.LL.yyyy");
        setValue(date);
        if(props.onValueChanged){
            props.onValueChanged(date);
        }
    };
    const maxDate = DateTime.now().minus({day: 1}).toJSDate();
    const minDate = fromDate(2010,1, 31).toJSDate();

    const bestLang = navigator.languages.find((l)=> locales[l]);
    const useLocale = bestLang ? locales[bestLang] : enUS;
    const dayPickerProps: DayPickerProps ={
        locale: useLocale,
        mode: "single",
        selected: fromStr(value).toJSDate(),
        defaultMonth: fromStr(value).toJSDate(),
        fromDate: minDate,
        toDate: maxDate,
        showOutsideDays: true,
        numberOfMonths: 2,
        captionLayout: "dropdown-buttons",
        components: {
            Caption: CustomCaption
        },
        modifiersClassNames: {
            selected: "sz-date-selected",
            today: "sz-date-today"
        },
        styles: {
            day: {
                borderRadius: 0,
            }
        },
        onSelect: (date, selected)=>{
            if(!selected){
                return;
            }
            if(selected>maxDate){
                return;
            }
            if(selected<minDate){
                return;
            }
            changeValue(selected);
        }
    };
    return (
        <DayPicker  {...dayPickerProps} />
    );
}
