import {EParameters} from "../../models/EParameters";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {BaseDataProvider} from "./BaseDataProvider";

export class MultiplicatorsForCompaniesV2Provider extends BaseDataProvider {
    protected initProvider() {
        this.className = "MultiplicatorsForCompaniesV2Provider";
        this.parameterMapping = [
            EParameters.DateParameter,
            EParameters.CompaniesParameter,
            EParameters.FeItemParameter,
            EParameters.FePerRelParameter,
        ];
    }
    protected update() {
        let data = [];
        const db = new PublicDbCalls();
        (async () => {
            const response = await db.getMultiplierForCompaniesV2(this.parameters);
            try {
                data = response.result;
                if (Array.isArray(data) && data.length === 0) {
                    data = null;
                }
            } catch (exi) {
                console.error(exi, response);
            }
            this.afterUpdate(data);
        })();
    }
}
