import {Menu, MenuDivider} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {EValueName} from "../../../models/EValueName";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {ISzNumericPercentProperties, SzNumericPercent} from "../../widgets/SzNumericPercent";
import {CombiParameter} from "../CombiParameter";

export class CountryRiskPremiumCombiParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getWidthClass() {
        return "fixed-width-200";
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CountryRiskPremiumCombiParameter];
    }

    protected async initialize() {
        return {
            selectedCountryRiskUsage: SessionStore.get(EParameters.CountryRiskUsageParameter),
            selectedCountryRiskPremium: parseFloat(SessionStore.get(EParameters.CountryRiskPremiumParameter)),
        };
    }

    protected renderMenuContent(): any {
        const options = ParameterOptions.options[EParameters.CountryRiskUsageParameter];
        const value: number =  this.state.selectedCountryRiskPremium;
        const inputProps: ISzNumericPercentProperties = {
            value,
            onValueChanged: (v: number) => this.onChangeValue(v),
        };

        return (
            <div>
                <Menu>
                    {this.renderOptions(options, this.state.selectedCountryRiskUsage, EParameters.CountryRiskUsageParameter, "selectedCountryRiskUsage")}
                </Menu>
            </div>
        );
    }

    protected getLabel(): any {
        const options = ParameterOptions.options[EParameters.CountryRiskUsageParameter];
        const selectedCountryRiskUsage = options.find((e) => e.value === this.state.selectedCountryRiskUsage);
        const countryRiskUsageLabel = selectedCountryRiskUsage ? selectedCountryRiskUsage.label : Globals.hyphen;
        return `${countryRiskUsageLabel}`;
    }
    private renderCountryRiskUsage() {
        const options = ParameterOptions.options[EParameters.CountryRiskUsageParameter];
        return (
            <div style={{width: "50%"}}>
                <Menu>
                    <MenuDivider title="Anwendung" />
                    {this.renderOptions(options, this.state.selectedCountryRiskUsage, EParameters.CountryRiskUsageParameter, "selectedCountryRiskUsage")}
                </Menu>
            </div>
        );
    }

    private renderRiskPremium() {
        const value: number =  this.state.selectedCountryRiskPremium;
        const inputProps: ISzNumericPercentProperties = {
            value,
            onValueChanged: (v: number) => this.onChangeValue(v),
        };
        const onKeyUp = (charCode: number, keyCode: number) => {
            if (this.state.isOpen && keyCode === 13) {
                //
            }
        };
        return (
            <div style={{width: "50%"}}>
                <Menu>
                    <MenuDivider title="Wert" />
                    <div style={{padding: 10}}><SzNumericPercent {...inputProps} /></div>
                </Menu>
            </div>
        );
    }
    private onChangeValue(_valueAsNumber: number) {
        this.setState({
            selectedCountryRiskPremium: _valueAsNumber,
        });
        SessionStore.setGlobalVar(EValueName.country_risk_premium, _valueAsNumber);
        this.fireValueChange(EParameters.CountryRiskPremiumParameter, _valueAsNumber);
    }
}
