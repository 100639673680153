import {Icon, Intent, Menu, MenuItem} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {IconNames} from "@blueprintjs/icons";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";

export class TpAnalysisSizeParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.TpAnalysisSizeParameter];
    }

    protected async initialize() {
        return {
            composition: SessionStore.get(EParameters.TpMarginComposition),
            op_result: SessionStore.get(EParameters.TpOpResult),
            analysis_size: SessionStore.get(EParameters.TpAnalysisSizeParameter),
        };
    }
    protected renderMenuContent(): any {
        const analysis_size = this.state.analysis_size;
        const css_buttons: CSSProperties = {
            width: "100%",
            justifyContent: "flex-start",
        };
        const get_intent = (flag: boolean) => {
            if(flag) {
                return Intent.PRIMARY;
            }else{
                return Intent.NONE;
            }
        };
        const upd = (p: EParameters) => {
            this.setState({
                analysis_size: p,
            });
        };
        return (
            <div className={"sz-row"}>
                {this.renderOpResultMenu()}
                {this.renderHelpText(PARAMETER_HELP(EParameters.TpAnalysisSizeParameter))}
            </div>
        );
    }
    protected renderOpResultMenu(): any {
        const op_result: string = this.state.op_result;
        const options: IOptionEntry[] = ParameterOptions.options[EParameters.TpOpResult];

        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return <Icon icon={IconNames.BLANK}/>;
            }
        };
        const select = (v: string) => {
            this.setState({op_result: v});
        };
        const renderOption = (o:IOptionEntry) => {
            const selected = o.value === op_result;
            if(o.value!=="free_definition"){
                return (<MenuItem text={o.label} labelElement={getIcon(selected)} className={ selected ? "sz-option-selected" : undefined} intent={selected ? "primary" : "none"} onClick={()=> select(o.value)}/>);
            }else{
                return (
                    <MenuItem text={o.label} shouldDismissPopover={true} labelElement={getIcon(selected)} intent={selected ? "primary" : "none"} className={ selected ? "sz-option-selected" : undefined} onClick={()=> select(o.value)}>
                        {this.renderCompositionMenu()}
                    </MenuItem>);
            }
        };
        return (
            <div className={"sz-row"}>
                <Menu style={{width: "100%"}}>
                    {options.map((o)=> renderOption(o))}
                </Menu>
            </div>
        );
    }
    protected renderCompositionMenu(): any {
        const composition: string[] = this.state.composition;
        const options: IOptionEntry[] = ParameterOptions.options[EParameters.TpMarginComposition];

        const getIcon = (f: boolean) => {
            if (f) {
                return <Icon icon={"tick"}/>;
            } else {
                return <Icon icon={IconNames.BLANK}/>;
            }
        };
        const select = (v: string) => {
            const index_comp = composition.indexOf(v);
            if(index_comp>=0){
                composition.splice(index_comp, 1);
            }else{
                composition.push(v);
            }
            const op_result = "free_definition";
            this.setState({composition, op_result});
        };
        const renderOption = (o:IOptionEntry) => {
            const index_comp = composition.indexOf(o.value);
            const selected = index_comp >= 0;
            return (<MenuItem shouldDismissPopover={false} text={o.label} labelElement={getIcon(selected)} className={"bp3"} intent={selected ? "primary" : "none"} onClick={()=> select(o.value)}/>);
        };
        return options.map((o)=> renderOption(o));
    }
    protected onClosePopup(){
        this.fireChanges([
            {component: EParameters.TpAnalysisSizeParameter, value: [this.state.analysis_size]},
            {component: EParameters.TpMarginComposition, value: [this.state.composition]},
            {component: EParameters.TpOpResult, value: [this.state.op_result]},
        ]);
    }
    protected getLabel(): any {
        const op_result: string[] = this.state.op_result;
        const option: IOptionEntry = ParameterOptions.options[EParameters.TpOpResult].find((o) => o.value===op_result);
        if(!option){
            return Globals.hyphen;
        }
        return `${option.label}`;
    }
    protected getWidthClass() {
        return "fixed-width-500";
    }
}
