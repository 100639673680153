import {NonIdealState} from "@blueprintjs/core";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    Tooltip,
} from 'chart.js';
import * as React from "react";
import {Bubble, Line} from 'react-chartjs-2';
import {CustomAxisLabel} from "../../chart-plugin/CustomAxisLabel";
import {RegressionLine} from "../../chart-plugin/RegressionLine";
import {CreditSpreadOptions} from "../../const/CreditSpreadOptions";
import {Globals} from "../../const/Globals";
import {Ratings} from "../../const/Ratings";
import {ICalcCreditSpreadResult} from "../../helpers/CalcCreditSpreadIndication";
import {median, sum} from "../../helpers/Statistics";
import {EChartType, PdfChartToImage} from "../../pdf-tools/PdfChartToImage";
import {PDF_IMAGE_HEIGHT, PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {BaseModule} from "./BaseModule";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

ChartJS.register(CategoryScale,LinearScale, LogarithmicScale, PointElement, LineElement, BarElement, Tooltip, Legend, RegressionLine, CustomAxisLabel);

interface ICompanySpread {
    cid: string;
    id: string; // name
    rating: string;
    security_id: string;
    spread: number;
    count: number;
    index: number;
}
interface IDot{
    x: number;
    y: number;
    dataSet: ICompanySpread;
}

export class CreditSpreadRatingVsSpreads  extends BaseModule<any> {

    private spread = 0;
    private spread_min = 0;
    private spread_max = 0;

    private chart_1_options;
    private chart_1_data;
    private chart_2_options;
    private chart_2_data;

    constructor(props: any, context: any) {
        super(props, context);
    }

    protected renderContent() {
        if(!this.data){
            return (<NonIdealState
                icon={"database"}
                title="Keine Daten"
            />);
        }
        return (
            <div style={{overflow: "hidden", position: "relative"}}>
                <div className={"sz-row"}>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <strong>Ratings & Kreditaufschlag</strong>
                            <br/>
                            <span>[% p.a.]</span>
                        </div>
                        {this.renderSpreadChart()}
                    </div>
                    <div className={"sz-col sz-col-50"}>
                        <div style={{marginBottom: 20, fontSize: "80%"}}>
                            <strong>Ausfallwahrscheinlichkeit</strong>
                            <br/>
                            <span>&nbsp;</span>
                        </div>
                        {this.renderDefaultProbabilities()}
                    </div>
                </div>
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;
        // console.error(this.data);
        this.setState({
            loading: false,
            selected_company_indication: data.selected_company_indication ? data.selected_company_indication : this.state.selected_company_indication,
        });
    }
    private noData(error_pos: string) {
        return (<NonIdealState
            icon={"database"}
            title={`Keine Daten (${error_pos})`}
            description="Die Kombination aus Stichtag, Währung, Sector und Rating liefert keine Ergebnisse."
        />);
    }
    private renderSpreadChart() {
        const boxed_stat: ICompanySpread[] = this.data.all_boxed_spreads;
        if(!Array.isArray(boxed_stat)){
            return this.noData("N3");
        }
        if(!boxed_stat.length){
            return this.noData("N4");
        }

        const fx = (v, weight)=> v * weight;
        const ratings = [];
        const spreads = [];

        CreditSpreadOptions.getMetrics().forEach((metric)=>{
            if(metric.weight === 0){
                return;
            }
            const indication: ICalcCreditSpreadResult = this.data.results[metric.field];
            if(!indication){
                return;
            }
            if(Array.isArray(indication.boxed_spreads)){
                spreads.push(fx(median(indication.boxed_spreads), metric.weight));
            }
            const boxed_ratings = indication.data
                .filter((i)=> CreditSpreadOptions.in_box(i.x, i.y, indication) && i.rating!=="0")
                .map((i)=> CreditSpreadOptions.get_rating_idx(i.rating))
            ;
            ratings.push(fx(median(boxed_ratings), metric.weight));
        });

        const median_rating = Math.round(sum(ratings));
        const median_spread = sum(spreads);

        const dots: IDot[]  = [];
        const other_dots: IDot[]  = [];

        const median_ratings = (new Array(Ratings.s_and_p.length)).fill(0).map(()=> new Array());
        const median_other_ratings = (new Array(Ratings.s_and_p.length)).fill(0).map(()=> new Array());

        const other_dots_median: IDot[]  = [];
        const boxed_companies = {};
        boxed_stat.forEach((i, num)=>{
            if(!i.count){
                return;
            }
            boxed_companies[i.security_id] = true;
            const rating= Ratings.s_and_p_mapped_to_num[i.rating];
            if(rating === undefined){
                return;
            }
            median_ratings[rating].push(i.spread);
            dots.push({
                x: rating,
                y: i.spread,
                dataSet: i,
            });
        });
        // alle firmen einsammeln, die noch nicht drin sind
        CreditSpreadOptions.getMetricsKeys().forEach((field)=>{
            const indication: ICalcCreditSpreadResult = this.data.results[field];
            if(!indication){
                return;
            }
            indication.data.forEach((r)=>{
                if(r.name === r.rating){
                    return;
                }
                if(boxed_companies[r.security_id]){
                    return;
                }
                boxed_companies[r.security_id] = true;
                const rating= Ratings.s_and_p_mapped_to_num[r.rating];
                if(rating === undefined){
                    return;
                }
                median_other_ratings[rating].push(r.y);
                other_dots.push({
                    x: rating,
                    y: r.y,
                    dataSet: {
                        spread: r.y,
                        cid: r.id,
                        rating: r.rating,
                        count: 0,
                        security_id: r.security_id,
                        index: 0,
                        id: r.name
                    },
                });
            });
        });

        median_other_ratings.forEach((m, rating)=>{
            if(m.length === 0){
                return;
            }
            other_dots_median.push({
                x: rating,
                y: median(m),
                dataSet: undefined,
            });
        });
        // const bond_details: IBondDetails[] = this.data.bond_details;
        // bond_details.forEach((b)=>{
        //    b.
        // });
        // console.error(dots, other_dots);
        const chart_data = {
            datasets: [
                {
                    label: "Im Pot",
                    type: "bubble",
                    data: dots,
                    backgroundColor: Globals.shortlist_green,
                    datalabels: {
                        labels: {
                            title: null
                        }
                    }
                },
                {
                    label: "Nicht im Pot",
                    type: "bubble",
                    data: other_dots,
                    backgroundColor: "#00000010",
                    datalabels: {
                        labels: {
                            title: null
                        }
                    }
                },
                {
                    label: "Other Median Pot",
                    type: "bubble",
                    data: other_dots_median,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    pointRadius: 10,
                    datalabels: {
                        labels: {
                            title: null
                        }
                    }
                },
            ]
        };
        chart_data.datasets.push({
            order: -1,
            type: "line",
            label: _t(ETranslation.credit_spread),
            data: [{x: 0, y: median_spread, a: true}, {x: 22, y: median_spread}],
            backgroundColor: "#f47a22",
            borderColor: "#f47a22",
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 0,
            datalabels: {
                display: 1,
                align: "-30",
                anchor: "end",
                offset: 5,
                backgroundColor: "rgba(255,255,255,0.9)",
                formatter: (value, context, a)=>{
                    if(!value.a){
                        return null;
                    }
                    return `${_t(ETranslation.credit_spread)}: ${Globals.formatter(value.y)}`;
                },
                labels: {
                    title:{
                        color: "#f47a22",
                    }
                }
            }
        } as any);
        const options = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        z: 10,
                        padding: 10,
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: 0,
                    suggestedMax: 8,
                },
                x: {
                    ticks: {
                        z: 10,
                        padding: 10,
                        callback: (value)=>{
                            return Ratings.s_and_p[value];
                        },
                        /*maxRotation: 0,*/
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: 0,
                    suggestedMax: Ratings.s_and_p.length - 1,
                    max: Ratings.s_and_p.length - 1,
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend: {
                    display: false,
                },
                datalabels: {
                    display: 0,
                },
                tooltip: {
                    enabled: true,
                    mode: "point",
                    callbacks: {
                        label: (item)=> {
                            if(!item.raw.dataSet){
                                return null;
                            }
                            const data: ICompanySpread = item.raw.dataSet;
                            return data.id;
                        },
                        title: (item)=> { return undefined;},
                    },
                },
            },
            regression_line: {
                display: "vertical_x",
                regression: undefined,
                vertical_x: median_rating,
                vertical_label: Ratings.s_and_p[median_rating],
                vertical_x_color: "#f47a22",
                color: "rgba(255, 99, 132, 1)",
                lineDash: [5,5],
            },
            custom_axis_label: {
                x_label: `Rating`,
                y_label: `${_t(ETranslation.credit_spread)} [% p.a.]`,
            }
        };
        // console.error(spread_value);
        // https://chartjs-plugin-datalabels.netlify.app/guide/positioning.html#clamping
        this.chart_1_options = options;
        this.chart_1_data = chart_data;
        return (
            <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                <Bubble options={options as any} data={chart_data as any} height={"100%"} width={"100%"} />
            </div>
        );
    }
    private renderDefaultProbabilities() {
        const pds = [
            [0.01, 0.02, 0.04, 0.08, 0.12, 0.22, 0.40, 0.65, 1.10, 1.85, 2.95, 4.30, 5.85, 9.05, 11.40],
            [0.02, 0.05, 0.09, 0.16, 0.25, 0.45, 0.80, 1.30, 2.19, 3.67, 5.81, 8.42, 11.36, 17.28, 21.50],
            [0.03, 0.07, 0.13, 0.24, 0.37, 0.67, 1.20, 1.94, 3.26, 5.45, 8.59, 12.35, 16.54, 24.77, 30.45],
            [0.04, 0.10, 0.18, 0.32, 0.50, 0.90, 1.59, 2.57, 4.33, 7.20, 11.29, 16.12, 21.43, 31.58, 38.38],
            [0.05, 0.12, 0.22, 0.40, 0.62, 1.12, 1.98, 3.21, 5.38, 8.91, 13.91, 19.73, 26.02, 37.77, 45.40],
        ];
        const colors = [
            "#137cbd",
            "#202B33",
            "#0f9960",
            "#D9822B",
            "#DB3737"
        ];
        const labels = [
            "PD (1)",
            "PD (2)",
            "PD (3)",
            "PD (4)",
            "PD (5)",
        ];
        const x_ticks = ["AAA/AA", "AA-", "A+", "A", "A-", "BBB+", "BBB", "BBB-", "BB+", "BB", "BB-", "B+", "B", "B-", "CCC"];
        const chart_data = {
            labels: x_ticks,
            datasets: []
        };
        pds.forEach((pd, index)=>{
            chart_data.datasets.push({
                label: labels[index],
                data: pd,
                borderColor: colors[index],
                tension: 0.1,
                pointRadius: 0,
                pointHitRadius: 0,
            } as any);
        });
        const options = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        z: 10,
                        padding: 10,
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: 0,
                    max: 50,
                },
                x: {
                    ticks: {
                        z: 10,
                        padding: 10,
                        /*maxRotation: 0,*/
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: 0,
                    suggestedMax: x_ticks.length - 1,
                    max: x_ticks.length - 1,
                }
            },
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: true,
                    position: "right" as "right",
                    align: "start" as "start",
                },
                datalabels: {
                    display: 0,
                },
                tooltip: {
                    enabled: false,
                },
            },
            custom_axis_label: {
                x_label: "Rating",
                y_label: "Ausfallwahrscheinlichkeit [%]",
            }
        };
        this.chart_2_options = options;
        this.chart_2_data = chart_data;
        return (
            <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                <Line options={options as any} data={chart_data as any} height={"100%"} width={"100%"} />
            </div>
        );
    }

    public getPdfPage(): any[] {
        const content = [];
        const image_1 = PdfChartToImage.getDataUri(EChartType.bubble, 1125, 500, this.chart_1_options, this.chart_1_data);
        // const image_2 = PdfChartToImage.getDataUri(EChartType.line, 1125, 500, this.chart_2_options, this.chart_2_data);
        PdfInsertElement.page_header(content, "Rating & Kreditaufschlag");

        PdfInsertElement.h2(content, "Ratings & Kreditaufschlag");
        PdfInsertElement.p(content, "[% p.a.]");
        PdfInsertElement.image(content, image_1, PDF_IMAGE_HEIGHT);
        // PdfInsertElement.h2(content, "Ausfallwahrscheinlichkeit");
        // PdfInsertElement.p(content, " ");
        // PdfInsertElement.image(content, image_2, PDF_IMAGE_WIDTH);

        return content;
    }
}
