import * as React from "react";
import {CSSProperties} from "react";

export interface ISvgPdfProps {
    size?: number;
}
export class SvgPdf extends React.Component<ISvgPdfProps, any> {
    public render() {
        const size = this.props.size ? this.props.size : 30;
        const style: CSSProperties = {
            width: size,
            height: size,
        };

        return (
            <svg style={style} enableBackground="new 0 0 30 30" height="30px" id="Layer_1" version="1.1" viewBox="0 0 30 30" width="30px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><g><path d="M28.595,7.562l-5.438-6.309l-1.078-1.25H9.228c-1.727,0-3.124,1.397-3.124,3.124v3.971H8.04l-0.001-3.21   c0.004-0.971,0.784-1.756,1.752-1.756l10.994-0.01v5.208c0.001,1.939,1.567,3.51,3.507,3.51h3.807L27.91,25.86   c-0.004,0.967-0.784,1.747-1.752,1.754L9.652,27.606c-0.883,0-1.594-0.866-1.6-1.935V24.4H6.114v1.896   c0,1.907,1.277,3.455,2.845,3.455l17.763-0.005c1.726,0,3.124-1.404,3.124-3.126V9.016L28.595,7.562" fill="#434440"/><path d="M20.145,25.368H0V6.129h20.145V25.368 M1.934,23.432h16.274V8.065H1.934" fill="#EA4C3A"/><path d="M10.314,9.069   c0.305,0.141,0.242,0.328,0.148,1.201c-0.097,0.905-0.414,2.554-1.032,4.173c-0.616,1.622-1.529,3.21-2.325,4.39   c-0.797,1.178-1.478,1.943-1.998,2.386c-0.519,0.441-0.882,0.559-1.115,0.599c-0.233,0.04-0.339,0-0.405-0.117   c-0.063-0.118-0.084-0.315-0.031-0.551c0.053-0.234,0.181-0.51,0.542-0.863c0.36-0.354,0.956-0.785,1.785-1.188   c0.829-0.402,1.891-0.775,2.762-1.031s1.551-0.393,2.146-0.5c0.595-0.108,1.104-0.187,1.604-0.226c0.5-0.04,0.988-0.04,1.467,0   c0.478,0.039,0.945,0.117,1.348,0.216c0.406,0.097,0.745,0.217,1.042,0.402c0.299,0.187,0.552,0.441,0.681,0.726   c0.127,0.286,0.127,0.6,0.021,0.825c-0.105,0.227-0.318,0.364-0.563,0.441c-0.246,0.08-0.522,0.099-0.851,0   c-0.33-0.098-0.712-0.314-1.115-0.599c-0.404-0.284-0.829-0.638-1.381-1.187c-0.553-0.551-1.232-1.298-1.807-2.023   c-0.573-0.727-1.041-1.434-1.358-2.033c-0.319-0.599-0.489-1.09-0.627-1.582c-0.138-0.491-0.244-0.98-0.287-1.422   c-0.043-0.443-0.021-0.837,0.021-1.149c0.042-0.315,0.106-0.55,0.213-0.708c0.106-0.157,0.256-0.235,0.362-0.275   s0.169-0.04,0.234-0.049c0.063-0.009,0.126-0.029,0.222,0c0.094,0.03,0.216,0.104,0.34,0.18" fill="none" stroke="#EA4C3A" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75"/></g></svg>
        );
    }
}
