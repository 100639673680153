import {EContentType} from "../../const/EContentType";
import {EPackageTypes} from "../../const/EPackageTypes";
import {NopeActions} from "../../const/NopeActions";
import {ELayoutColumnWidth} from "../../models/ELayoutColumnWidth";
import {EParameters} from "../../models/EParameters";
import {IColumnLayout} from "../../models/IColumnLayout";
import {ColumnLayouterImpl} from "../ColumnLayouterImpl";
import {ModuleGroup} from "../ModuleGroup";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {IModuleConfig} from "../../models/IModuleConfig";

export class CreditSpreads extends ModuleGroup<IColumnLayout> {
    public static config: IModuleConfig = {
        name: "Credit-Spreads",
    };
    constructor() {
        super("Kreditaufschlag", { columns: []});
        this.new_pdf_export = true;
        this.addParameter(EParameters.DateParameter);
        this.addParameter(EParameters.DividerParameter);
        this.addParameter(EParameters.CreditSpreadRuntimeParameter);
        this.addParameter(EParameters.BondSectorParameter);
        this.addParameter(EParameters.RegionParameter);
        this.addParameter(EParameters.BondFilterParameter);
        this.addParameter(EParameters.CreditSpreadMetricParameter);
        this.addParameter(EParameters.CreditSpreadConfigParameter);

        this.addParameter(EParameters.CreditSpreadSoftParameter);


        this.addParameter(EParameters.DividerParameter);

        this.addParameter(EParameters.TickerParameter);
        this.addParameter(EParameters.CountryIso2Parameter);
        //

        const dataDefault = [{theSame: true}];
// zweite reihe

        this.addModule("Unternehmensdaten", "CreditSpreadUserInput", "CreditSpreadIndicationProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.credit_spreads)
        ;

        this.addModule(_t(ETranslation.credit_spread), "CreditSpreadIndicationChart", "CreditSpreadIndicationProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.credit_spreads)
        ;
        this.addModule("Zinssätze", "CreditSpreadInterestRateChart", "CreditSpreadIndicationProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.credit_spreads)
        ;
        this.addModule("Rating", "CreditSpreadRatingChart", "CreditSpreadIndicationProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.credit_spreads)
        ;
        this.addModule("Kreditkennzahlen", "CreditSpreadIndication", "CreditSpreadIndicationProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.credit_spreads)
        ;
        this.addModule("Unternehmen & Anleihen", "CreditSpreadBonds", "CreditSpreadIndicationProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.credit_spreads)
            .setExcelExport(true)
        ;
        this.addModule("Rating & Kreditaufschlag", "CreditSpreadRatingVsSpreads", "CreditSpreadIndicationProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.credit_spreads)
        ;

        this.addModule("Methodik", "CreditSpreadIndicationSpreads", "CreditSpreadIndicationProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.credit_spreads)
        ;

        // rechte seite
        this.addModule("Branchen-Kreditaufschlag", "CreditSpreadIndustry", "CreditSpreadProvider", dataDefault)
            .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.credit_spreads)
            .setExcelExport(true)
            .setAlignRight()
        ;
        this.addModule("Datenbank-Info", "BondStats", "DateTriggerProvider", dataDefault)
            .setAccess(EContentType.Admin, EPackageTypes.Deu, NopeActions.credit_spreads)
            .setAlignRight()
        ;

        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c100)
            .addModule([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

    }

    protected getTitle(): string{
        return _t(ETranslation.credit_spread);
    }
}
