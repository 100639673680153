import * as React from "react";
import {
    Plugin,
} from 'chart.js';

export interface IDrawStateHookProps {
    onAfterDatasetsDraw?(chart, args);
    onBeforeDatasetsDraw?(chart, args);
    enabled?: boolean,
}

class DrawStateHookClass{
    public id = "draw_state_hook";

    public defaults: IDrawStateHookProps = {
        onAfterDatasetsDraw: undefined,
        onBeforeDatasetsDraw: undefined,
        enabled: false,
    }
    private saveCall(chart, the_call){
        const {ctx} = chart;
        ctx.save();
        try{
            the_call();
        }catch (e) {
            console.error(e);
        }finally {
            ctx.restore();
        }
    }
    public afterDatasetsDraw(chart, args, options: IDrawStateHookProps){
        this.saveCall(chart, ()=> {
            if(options?.enabled && options?.onAfterDatasetsDraw){
                options?.onAfterDatasetsDraw(chart, args);
            }
        });
        return true;
    }
    public beforeDatasetsDraw(chart, args, options){
        this.saveCall(chart, ()=> {
            if(options?.enabled && options?.onBeforeDatasetsDraw) {
                options?.onBeforeDatasetsDraw(chart, args);
            }
        });
        return true;
    }
}

export const DrawStateHook = (new DrawStateHookClass()) as Plugin;
