import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {fromStr} from "../../../tools/DateTools";
import {DeDate} from "../../../helpers/convert/DeDate";
import {Globals} from "../../../const/Globals";
import {Ratings} from "../../../const/Ratings";
import { LineAnnotationOptions } from "chartjs-plugin-annotation";
import { BoxAnnotationOptions } from "chartjs-plugin-annotation";

const coc_risk_premium = (value, min_year: number, count: number)=>{
    if(isNaN(value)){
        console.error("Annotations::coc_risk_premium-> value is NaN");
        return undefined;
    }
    const labels: string[] = (new Array(count)).fill(1).map( (i, n) => ""+(n + min_year));
    const date = SessionStore.get(EParameters.DateParameter);
    const dt_year = fromStr(date).toFormat("yyyy");
    const idx = labels.findIndex( (i) => i === dt_year );
    // const value = parseFloat(SessionStore.getGlobalVar(EValueName.market_risk_premium, NaN)) * 100;
    // console.error(value);
    if(idx < 0){
        console.error("Annotations::coc_risk_premium-> year not found");
    }
    const dayToYearRatio = DeDate.getPercentYTD(date);
    const v = idx + dayToYearRatio;
    const a: LineAnnotationOptions = {
        borderColor: "#f47a22",
        borderDash: [6, 6],
        borderWidth: 1,
        xMax: v,
        xMin: v,
        xScaleID: "x",
        yMax: 0,
        yMin: value === 0 ? 0.02 : value * 100,
        yScaleID: "y",
        label: {
            display: true,
            backgroundColor: "rgba(255,255,255,0.9)",
            color: "#f47a22",
            drawTime: 'afterDatasetsDraw',
            position: "start",
            content: Globals.formatter_percent(value),
        },
    };

    return a;
};
const coc_corporate_spread_rating = ()=>{
    const rating = SessionStore.get(EParameters.RatingParameter);
    if(!(Array.isArray(rating) && rating.length)){
        return undefined;
    }
    const le = rating.length;
    const o = (le === 1? .5 : 0);
    const xMax= Ratings.s_and_p_mapped_to_num[rating[le - 1]] + o;
    const xMin= Ratings.s_and_p_mapped_to_num[rating[0]] - o;
    const a: BoxAnnotationOptions = {
        type: "box",
        drawTime: "beforeDraw",
        backgroundColor: 'rgb(224, 239, 255)',
        borderColor: 'rgb(149, 220,253)',
        borderRadius: 0,
        borderWidth: 1,
        xMax,
        xMin,
    } as BoxAnnotationOptions;
    return a;
};
export const Annotations = {
    coc_risk_premium,
    coc_corporate_spread_rating,
};
