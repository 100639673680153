import {IOptionEntry} from "../models/IOptionEntry";
import {fromNow} from "../tools/DateTools";

export class DateOptionsGenerator {
    public static generateMonths(selectedMonth?: string) {
        let current: number = 0;
        const months: IOptionEntry[] = [];
        while (current < 12) {
            const s = fromNow().startOf("year").plus({month: current}).toFormat("LLLL", {locale: navigator.language});
            const m = fromNow().startOf("year").plus({month: current}).toFormat("LL");
            months.push({
                selected: selectedMonth === m,
                value: m,
                label: s,
            });
            current++;
        }
        return months;
    }
}
