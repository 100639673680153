export interface ICompanyDetailsData {
    ann: string;
    date: string;
    sales: number;
    ebitda: number;
    ebit: number;
    netto_debt: number;
    ff_mkt_val: number;
    ff_assets: number;
    ff_eq_tot: number;
    ff_net_inc: number;
    ff_shldrs_eq: number;
}
export interface ICompanyDetails {
    description?: string;
    currency_iso?: string;
    p_exchange?: string;
    p_first_date?: string;
    n_last_date?: number;
    n_first_date?: number;
    ff_sic_code?: number;
    detailsData: ICompanyDetailsData[];
}
export interface ICompanyDetailsDataExt {
    ann?: string;
    date?: string;
    company_id?: string;
    currency_iso?: string;
    currency_iso_3?: string;
    date_decimal?: string;
    year?: number;
    ff_assets?: number;
    ff_capex?: number;
    ff_cash_generic?: number;
    ff_debt?: number;
    ff_ebit?: number;
    ff_ebitda?: number;
    ff_eq_tot?: number;
    ff_funds_oper_gross?: number;
    ff_gross_inc?: number;
    ff_inc_tax?: number;
    ff_int_exp_debt?: number;
    ff_intang?: number;
    ff_invest_aff?: number;
    ff_mkt_val?: number;
    ff_net_inc?: number;
    ff_pens_liabs_unfunded?: number;
    ff_ppe_net?: number;
    ff_sales?: number;
    ff_shldrs_eq?: number;
    ff_wkcap?: number;
    p_exchange?: string;
    p_first_date?: string;
    p_last_date?: string;

    ff_sic_code?: number;
    netto_debt?: number;
}
export interface ICompanyDetailsExt {
    currency_iso?: string;
    p_exchange?: string;
    p_first_date?: string;
    n_last_date?: number;
    n_first_date?: number;
    ff_sic_code?: number;
    detailsData: ICompanyDetailsDataExt[];
}
export interface ICompanyDetailsXs {
    country_id: string;
    company_id: string;
    currency_iso_3: string;
    date_decimal: number;
    ff_sales: number;
    ff_ebitda: number;
    ff_ebit: number;
    netto_debt: number;
    ff_mkt_val: number;
    ff_assets: number;
    ff_eq_tot: number;
    ff_net_inc: number;
    ff_shldrs_eq: number;
    ff_ebitda_oper: number;
    ff_ebit_oper: number;
    ff_net_income: number;
    ff_ppe_net: number;
    ff_cash_generic: number;
    ff_pens_liabs_unfunded: number;
    ff_invest_aff: number;
    ff_intang: number;
    ff_debt: number;
    ff_funds_oper_gross: number;
    ff_int_exp_debt: number;
    ff_capex: number;
    ff_wkcap: number;
}
export function asICompanyDetailsXs(data: any[]): ICompanyDetailsXs {
    if(Array.isArray(data)){
        try{
            const [
                country_id,
                company_id,
                date_decimal,
                currency_iso_3,
                ff_sales,
                ff_ebitda,
                ff_ebit,
                netto_debt,
                ff_mkt_val,
                ff_assets,
                ff_eq_tot,
                ff_net_inc,
                ff_shldrs_eq,
                ff_ebitda_oper,
                ff_ebit_oper,
                ff_net_income,
                ff_ppe_net,
                ff_cash_generic,
                ff_pens_liabs_unfunded,
                ff_invest_aff,
                ff_intang,
                ff_debt,
                ff_funds_oper_gross,
                ff_int_exp_debt,
                ff_capex,
                ff_wkcap,
            ] = data;
            const ret: ICompanyDetailsXs = {
                date_decimal: parseInt(date_decimal, 10),
                country_id,
                company_id,
                currency_iso_3,
                ff_sales,
                ff_ebitda,
                ff_ebit,
                netto_debt,
                ff_mkt_val,
                ff_assets,
                ff_eq_tot,
                ff_net_inc,
                ff_shldrs_eq,
                ff_ebitda_oper,
                ff_ebit_oper,
                ff_net_income,
                ff_ppe_net,
                ff_cash_generic,
                ff_pens_liabs_unfunded,
                ff_invest_aff,
                ff_intang,
                ff_debt,
                ff_funds_oper_gross,
                ff_int_exp_debt,
                ff_capex,
                ff_wkcap,
            };
            return ret;
        }catch(e){
            console.error(e);
        }
    }
    return {} as ICompanyDetailsXs;
}
export interface ICompany {
    accounting_standard: string;
    country_id: string;
    ex_country_id: string;
    ff_sic_code: string;
    home_page: string;
    id: string;
    import_id: string;
    industry_id: string;
    name: string;
    co_name: string;
    p_exchange: string;
    p_first_date: string;
    p_last_date: string;
    sector_id: string;
    security_id: string;

    ff_share_type_secs: string,
    ff_naics_code: string;
    ff_freq_code: string;
    isin: string;
    fa_employees_num: string;
    description?: string;
}

export interface INaics{
    code: string;
    title_eng: string;
    title_de: string;
    branch: string;
    branch_group: string;
    sub_sector: string;
    sector: string;
}

export interface IRelatedCompany {
    rang: string;
    id: string;
    country_id: string;
    name: string;
    co_name: string;
    ff_naics_code: string;
    ff_sic_code: string;
    sector_id: string;
    industry_id: string;
    from_euro_to_: number;
    country_currency_iso_3: string;
}
export interface IRelatedFinancials{
    rang: string;
    company_id: string;
    currency_iso: string;
    report_currency_iso: string;
    dd_mkt_val: string;
    ff_mkt_val: number;
    dd_annual_reported: string;
    ff_sales: number;
    ff_ebitda: number;
    ff_emp_num: number;
}
export interface ILatestFinancials{
    company_id: string;
    date_decimal: string;
    currency_iso: string;
    report_currency_iso: string;
    ff_sales: number;
    ff_ebitda: number;
    ff_emp_num: number;
}

export interface ICountryExchangeRate {
    iso_3: string;
    currency_iso_3: string;
    xch_rate: number;
}
