import {BaseCurrencies} from "../../../const/BaseCurrencies";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";

export class BaseCurrencyParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.BaseCurrencyParameter];
    }

    protected async getOptions() {
        return BaseCurrencies.values;
    }

    protected async getSelectedValue() {
        return SessionStore.get(EParameters.BaseCurrencyParameter);
    }
}
