import {createContext, useContext} from "react";

export const authContext = createContext({
    user: null,
    signin: null,
    signout: null,
});
export const useAuth = ()=> {
    return useContext(authContext);
}
