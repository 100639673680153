import {EParameters} from "../models/EParameters";
import {Content} from "pdfmake/interfaces";
import {ParameterConfigs} from "../const/ParameterConfigs";
import {IParameterConfig} from "../models/IParameterConfig";
import {components} from "../const/ParameterComponents";

export interface IPdfParameter {
    caption: string;
    content: Content;
}
const parameters: {[index: string]: IPdfParameter} = {};

const getParameterCaption = (p: EParameters)=>{
    const c: IParameterConfig = ParameterConfigs.configs[p];
    if(c){
        return c.name;
    }else{
        return "";
    }
}
const p_base = (p: EParameters): IPdfParameter =>{
    const component = components[p];
    if(!component){
        return null;
    }
    const pdf = component.getPdfLabel() as Content;
    if(!pdf){
        return null;
    }
    return {
        caption: getParameterCaption(p),
        content: pdf,
    };
};
export class PdfParameter {
    public static init(){
        parameters[EParameters.DateParameter] = p_base(EParameters.DateParameter);
        parameters[EParameters.CreditSpreadRuntimeParameter] = p_base(EParameters.CreditSpreadRuntimeParameter);
        parameters[EParameters.BondSectorParameter] = p_base(EParameters.BondSectorParameter);
        parameters[EParameters.RegionParameter] = p_base(EParameters.RegionParameter);
        parameters[EParameters.BondFilterParameter] = p_base(EParameters.BondFilterParameter);
        parameters[EParameters.CreditSpreadMetricParameter] = p_base(EParameters.CreditSpreadMetricParameter);
        parameters[EParameters.CreditSpreadConfigParameter] = p_base(EParameters.CreditSpreadConfigParameter);
        parameters[EParameters.CreditSpreadSoftParameter] = p_base(EParameters.CreditSpreadSoftParameter);
        parameters[EParameters.CountryIso2Parameter] = p_base(EParameters.CountryIso2Parameter);
        // beta factor
        parameters[EParameters.RenditeCombiParameter] = p_base(EParameters.RenditeCombiParameter);
        parameters[EParameters.IndexCombiParameter] = p_base(EParameters.IndexCombiParameter);
        parameters[EParameters.BetaFilterParameter] = p_base(EParameters.BetaFilterParameter);
        parameters[EParameters.GearingCombiParameter] = p_base(EParameters.GearingCombiParameter);
        parameters[EParameters.TaxshieldParameter] = p_base(EParameters.TaxshieldParameter);
        parameters[EParameters.DebtBetaCombiParameter] = p_base(EParameters.DebtBetaCombiParameter);
    }
    public static get_parameter(parameter: EParameters): IPdfParameter{
        return parameters[parameter];
    }
}
