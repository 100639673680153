import {HTMLTable, Spinner} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {ETranslation} from "../../const/ETranslation";
import {Calculator} from "../../helpers/Calculator";
import {getResult} from "../../helpers/Helpers";
import {
    asICompanyDetailsXs,
    ICompany,
    ICompanyDetailsXs,
    IRelatedCompany,
    IRelatedFinancials
} from "../../models/ICompanyDetails";
import {ICompanySearchResult} from "../../models/ICompanySearchResult";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {fromDateDecimal, fromDateParameter, fromNow, fromStr} from "../../tools/DateTools";
import {_t, _ta} from "../../tools/Translator";
import {SzDocReference} from "./SzDocReference";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";

type RelatedFinancialsMap = { [index:string] : IRelatedFinancials};
type CompanyDetailsMap = {[index: number] : ICompanyDetailsXs};
export interface ICompanyFinancialsProps {
    company_id: string;
}

interface ICompanyFinancialsState {
    is_loading: boolean;
    current_year: number;
    company_id: string;
    company?: ICompany;
    company_details_map?: CompanyDetailsMap;
    related_financials?: RelatedFinancialsMap;
    order_by: any;
    order_dir: any;
    companyListed?: ICompanySearchResult;
    ident?: IRelatedCompany;
}

export class CompanyFinancials extends React.Component<ICompanyFinancialsProps, ICompanyFinancialsState> {
    constructor(props: ICompanyFinancialsProps, context: any) {
        super(props, context);
        const current_year = fromNow().year - 1;
        this.state = {
            is_loading: true,
            company_id: this.props.company_id,
            order_by: {},
            order_dir: {},
            current_year,
        };
    }
    public render() {
        const backGroundStyle: CSSProperties = {
            width: "100%",
            height: "100%",
            overflowY: "auto",
            backgroundColor: "#ffffff",
        };
        return (<div style={backGroundStyle} className={"sz-relative"} >{this.state.is_loading ? this.renderLoading() : this.renderContent()}</div>);
    }
    private renderLoading() {
        (async ()=>{
            await this.loadData();
        })();
        return(
            <div style={{position: "relative", height: "100%", width: "100%", display: "flex", justifyContent: "center"}}>
                <Spinner size={250} />
            </div>
        );
        /*<SzSvgLoader />*/
    }
    private async loadData() {
        try{
            let current_year = this.state.current_year;
            const company_id = this.state.company_id;
            const db: PublicDbCalls = new PublicDbCalls();
            const r_company_details = await db.getCompanyDetailsXs(parseInt(company_id, 10), current_year);
            const r_company = await db.selectCompanies([company_id]);
            const company: ICompany = getResult(r_company, [])[0];
            const arr_details: any[] = getResult(r_company_details, []);

            const company_details_map: CompanyDetailsMap = {};
            const company_details: CompanyDetailsMap = {};

            let last_financial_year = 0;
            arr_details.forEach( (cd) =>{
                const d = asICompanyDetailsXs(cd);
                const y = Math.trunc(d.date_decimal/10000);
                company_details[y] = d;
                if(y > last_financial_year){
                    last_financial_year = y;
                }
            });
            const min_fiscal_year = fromStr(SessionStore.get(EParameters.DateParameter))
                .startOf("month")
                .minus({month: 13})
                .year
            ;
            if(last_financial_year >= min_fiscal_year){
                current_year = last_financial_year;
            }
            const years = [current_year, current_year-1, current_year-2, current_year-3, current_year-4, current_year-5, current_year-6, current_year-7, current_year-8, current_year-9, current_year-10];
            years.forEach((year)=>{
                const d = company_details[year];
                if(d){
                    company_details_map[year] = d;
                }else{
                    company_details_map[year] = {} as ICompanyDetailsXs;
                }
            });

            const ident = await db.getRelatedCompany(company_id);
            const rel_fin = await db.getRelatedFinancials([company_id]);
            const related_financials: RelatedFinancialsMap = {};
            rel_fin.forEach((i)=>related_financials[i.company_id] = i);

            // console.error(current_year);
            // console.error(arr_details);
            // console.error(company_details_map);

            this.setState({
                is_loading: false,
                company,
                company_details_map,
                ident,
                related_financials,
                current_year,
                order_by: {},
                order_dir: {},
            });

            return;
        }catch(ex){
            console.error(ex);
        }
        this.setState({
            is_loading: false,
        });
    }
    private renderContent() {
        const company: ICompany = this.state.company;
        if(!company){
            return undefined;
        }
        return (
            <div style={{paddingLeft: 1, paddingRight: 1}}>
                {this.renderFinanceTable(company)}
            </div>
        );
    }
    private renderFinanceTable(company: ICompany) {
        const related_financials = this.state.related_financials;
        const fin = related_financials[company.id] ? related_financials[company.id] : {} as IRelatedFinancials;
        const current_year = this.state.current_year;
        const company_details_map = this.state.company_details_map;
        const years = [current_year, current_year-1, current_year-2, current_year-3, current_year-4, current_year-5, current_year-6, current_year-7, current_year-8, current_year-9, current_year-10].reverse();
        const formatter = (v, digits?) => {
            if (v === undefined) {
                return "—";
            }
            if (isNaN(v)) {
                return "—";
            }
            if (!isFinite(v)) {
                return "—";
            }
            const fm = (new Intl.NumberFormat("de-de", {
                maximumFractionDigits: digits ? digits : 0,
                minimumFractionDigits: digits ? digits : 0,
            })).format;
            return fm(v);
        };
        // console.error(company_details_map);
        // hover-col
        const sel_year = fromDateParameter().year;
        const getField = (name: string) => {
            return years.map((year, idx) => {
                if (idx === 0) {
                    return null;
                }
                const r = company_details_map[year];
                const classNames: string[] = ["money"];
                if(sel_year === year){
                    classNames.push("hover-col");
                }
                return (<td className={classNames.join(" ")} style={{minWidth: 65, paddingTop: 3, paddingBottom: 3}}>{formatter(r[name])}</td>);
            });
        }
        const getCalc = (expression) => {
            return years.map((year, idx) => {
                if (idx === 0) {
                    return null;
                }
                const r = company_details_map[year];
                const v = Calculator.evaluate(expression, r);
                const classNames: string[] = ["money"];
                if(sel_year === year){
                    classNames.push("hover-col");
                }
                return (<td className={classNames.join(" ")} style={{minWidth: 65, paddingTop: 3, paddingBottom: 3}}>{formatter(v)}</td>);
            });
        };
        const getGrowth = (name)=>{
            return years.map((year, idx) => {
                if (idx === 0) {
                    return null;
                }
                const r = company_details_map[year];
                const r_1 = company_details_map[year - 1];
                const classNames: string[] = ["money"];
                if(sel_year === year){
                    classNames.push("hover-col");
                }

                if (!r_1) {
                    return (<td style={{paddingTop: 3, paddingBottom: 3}} className={classNames.join(" ")}>—</td>);
                }
                return (<td className={classNames.join(" ")} style={{paddingTop: 3, paddingBottom: 3}}>{formatter((r[name] / r_1[name] - 1) * 100, 1)}</td>);
            });
        }
        const getMargin = (dividend, divisor)=>{
            return years.map((year, idx) => {
                if (idx === 0) {
                    return null;
                }
                const r = company_details_map[year];
                const classNames: string[] = ["money"];
                if(sel_year === year){
                    classNames.push("hover-col");
                }
                return (<td className={classNames.join(" ")} style={{paddingTop: 3, paddingBottom: 3}}>{formatter((r[dividend] / r[divisor]) * 100, 1)}</td>);
            });
        }
        const h2 = (caption, line: boolean = true)=> {
            return (
                <tr>
                    <td style={{paddingTop: 3, paddingBottom: 3, boxShadow: line ? "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)" : undefined}}>{caption}</td>
                    {years.map((year, idx) => {
                        if (idx === 0) {
                            return null;
                        }

                        const classNames: string[] = [];
                        if(sel_year === year){
                            classNames.push("hover-col");
                        }
                        return (<td className={classNames.join(" ")} style={{paddingTop: 3, paddingBottom: 3, boxShadow: line ? "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)" : undefined}}>&nbsp;</td>);
                    })}
                </tr>
            );
            /*
            return (
                <tr>
                    <td style={{paddingTop: 3, paddingBottom: 3, boxShadow: line ? "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)" : undefined}} colSpan={years.length + 1}>{caption}</td>
                </tr>
            );
            */
        };
        const commonField = (caption, content)=> {
            return (
                <tr>
                    <td style={{paddingTop: 3, paddingBottom: 3, paddingLeft: 20}}>{caption}</td>
                    {content}
                </tr>
            );
        };
        return (
            <HTMLTable condensed={true} className={"sz-table"} style={{width: "100%", fontSize: "85%", marginBottom: 16}}>
                <thead>
                <tr style={{backgroundColor: "#F0F0F0",boxShadow: "0 1px 0 rgba(16, 22, 26, 0.15)"}}>
                    <th colSpan={11} style={{fontSize: "14px"}}>{_t(ETranslation.financials)}</th>
                </tr>
                <tr>
                    <th style={{fontWeight: "normal", paddingTop: 3, paddingBottom: 3}}>Mio. {fin.report_currency_iso} | {fromDateDecimal(fin.dd_annual_reported).toFormat("dd.LL.")}</th>
                    {years.map((r, idx) => {
                        if (idx === 0) {
                            return null;
                        }
                        return (<th className={"money"} style={{minWidth: 65, paddingTop: 3, paddingBottom: 3}}>{r}</th>);
                    })}
                </tr>
                </thead>
                <tbody>
                {h2("Gewinn- und Verlustrechnung")}
                {commonField(_t(ETranslation.revenues), getField("ff_sales"))}
                {commonField("EBITDA", getField("ff_ebitda"))}
                {commonField(_t(ETranslation.ebitda_adjusted), getField("ff_ebitda_oper"))}
                {commonField("EBIT", getField("ff_ebit"))}
                {commonField(_t(ETranslation.ebit_adjusted), getField("ff_ebit_oper"))}
                {commonField(_t(ETranslation.netincome), getField("ff_net_income"))}
                {commonField("Jahresüberschuß bereinigt", getField("ff_net_inc"))}

                {h2("Bilanz")}
                {commonField("Sachanlagen", getField("ff_ppe_net"))}
                {commonField("Immaterielle Vermögensgegenstände", getField("ff_intang"))}
                {commonField("Finanzanlagen", getField("ff_invest_aff"))}
                {commonField("Liquide Mittel", getField("ff_cash_generic"))}
                {commonField("Sonstige Aktiva", getCalc("ff_assets - ff_cash_generic - ff_invest_aff - ff_ppe_net - ff_intang"))}
                {commonField("Summe Aktiva", getField("ff_assets"))}
                {commonField(_t(ETranslation.equity), getField("ff_eq_tot"))}
                {commonField("Pensionsverpflichtungen", getField("ff_pens_liabs_unfunded"))}
                {commonField("Finanzschulden", getField("ff_debt"))}
                {commonField("Sonstige Passiva", getCalc("ff_assets - ff_eq_tot - ff_debt - ff_pens_liabs_unfunded"))}
                {commonField("Summe Passiva", getField("ff_assets"))}

                {h2("Profitabilität")}
                {commonField(_ta(ETranslation.revenue_growth, " (%)"), getGrowth("ff_sales"))}
                {commonField(_t(ETranslation.ebitda_margin) + " (%)", getMargin("ff_ebitda", "ff_sales"))}
                {commonField(_t(ETranslation.ebit_margin)+" (%)", getMargin("ff_ebit", "ff_sales"))}
                {commonField("Jahresüberschuß-Marge (%)", getMargin("ff_net_income", "ff_sales"))}

                {h2("Rentabilität")}
                {commonField(<SzDocReference text={"Eigenkapitalrendite (%)"} ref_id={"46"}/>, getMargin("ff_net_income", "ff_eq_tot"))}
                {commonField(<SzDocReference text={"Gesamtkapitalrendite (%)"} ref_id={"25"}/>, getCalc("(ff_ebit / (ff_eq_tot + ff_debt + ff_pens_liabs_unfunded - ff_cash_generic - ff_invest_aff)) * 100"))}

                {h2("Bilanzstruktur")}
                {commonField(<SzDocReference text={_t(ETranslation.equity_ratio) + " (%)"} ref_id={"56"}/>, getCalc("(ff_eq_tot / ff_assets) * 100"))}
                {commonField(<SzDocReference text={"Verschuldungsgrad, bilanziell  (%)"} ref_id={"37"}/>, getCalc("((ff_debt - ff_cash_generic) / ff_eq_tot) * 100"))}
                {commonField("Anlagenintensität (%)", getCalc("100 * ((ff_ppe_net + ff_intang)/ff_assets)"))}
                {commonField("Anlagendeckungsgrad (%)", getCalc("100 * (ff_eq_tot / (ff_intang + ff_ppe_net))"))}

                {h2("Rating")}
                {commonField("FFO / Debt", getCalc("ff_funds_oper_gross / (ff_debt + ff_pens_liabs_unfunded - ff_cash_generic)"))}
                {commonField("Net debt / EBITDA", getCalc("(ff_debt + ff_pens_liabs_unfunded - ff_cash_generic) / ff_ebitda"))}
                {commonField("EBIT / Interest", getCalc("ff_ebit / ff_int_exp_debt"))}
                {commonField("Gearing", getCalc("100 * (ff_debt + ff_pens_liabs_unfunded - ff_cash_generic) / ff_eq_tot"))}

                {h2("Kapitalbindung")}
                {commonField("CAPEX in % Umsatzerlöse", getCalc("100 * ff_capex / ff_sales"))}
                {commonField("CAPEX in % Anlagevermögen", getCalc("100 * ff_capex / (ff_intang + ff_ppe_net)"))}
                {commonField("Net Working Capital in % Umsatzerlöse", getCalc("100 * (ff_wkcap - ff_cash_generic) / ff_sales"))}
                {commonField("Kapitalumschlag", getCalc("100 * ff_assets / ff_sales"))}

                <tr style={{boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)"}}><td style={{paddingTop: 3, paddingBottom: 3, paddingLeft: 20}}>{_t(ETranslation.market_capitalization)}</td>{getField("ff_mkt_val")}</tr>

                </tbody>
            </HTMLTable>
        );
    }
}
