import {NonIdealState} from "@blueprintjs/core";
import * as React from "react";
import {BaseModule} from "./BaseModule";
import {deadlineEoM} from "../../tools/DateTools";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export abstract class BaseInterestRate extends BaseModule<any> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    protected  abstract renderView(stichtag: any, stichtagcurve: any, meancurve: any, laufzeiten: any, seriesLegend: any, governmentYields?: any);

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected renderContent() {
        // console.error("parameters", this.parameters);
        return (<div style={{overflowX: "auto"}}>{this.data ? this.renderData() : this.noData()}</div>);
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    private renderData() {
        if (!this.data.governmentYields || !this.data.governmentYields) {
            return this.noData();
        }
        const stichtag = deadlineEoM(); // moment(this.parameters.DateParameter[0],"DD.MM.YYYY").format("DD.M.YYYY");
        const governmentYields = this.getGovYieldsArray(this.data.governmentYields);
        // console.error(this.data, governmentYields);
        // governmentYields
        const resultTable: any[][] = this.data.zeroCoupon;
        // const resultTable: any[][] = this.data.governmentYields;
        if (!resultTable) {
            console.error("BaseInterestRate: no resultTable");
            return this.noData();
        }
        if (!resultTable.length) {
            console.error("BaseInterestRate: no resultTable");
            return this.noData();
        }
        const laufzeiten = (new Array(30)).fill(0).map((laufzeit, index) => index + 1);

        const stichtagcurve_2: any[] = [].concat(resultTable[resultTable.length - 1]);
        if (stichtagcurve_2) {
            stichtagcurve_2.splice(0, 2);
        } else {
            return this.noData();
        }
        const meanDummy: any[][] = (new Array(30)).fill([]);
        for (let i = 0; i < resultTable.length; i++) {
            for (let j = 0; j < resultTable[i].length; j++) {
                const d = j - 2;
                if (d < 0) {
                    continue;
                }
                if (!meanDummy[d].length) {
                    meanDummy[d] = (new Array(resultTable.length)).fill(0);
                }
                meanDummy[d][i] = resultTable[i][j];
            }
        }
        const meancurve_2 = meanDummy.map( (col) => col.reduce((acc, current) => acc + current) / col.length);

        const seriesLegend = [
            `${_t(ETranslation.valuation_date)} [${_t(ETranslation.zero_rates)}]`,
            `${_t(ETranslation.average_90_days)} [${_t(ETranslation.zero_rates)}]`,
            `${_t(ETranslation.valuation_date)} [${_t(ETranslation.yield)}]`,
        ];
        return this.renderView(stichtag, [].concat(stichtagcurve_2), [].concat(meancurve_2), laufzeiten, seriesLegend, governmentYields);
    }

    private noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Das Datum darf nicht in der Zukunft liegen."
        />);
    }

    private getGovYieldsArray(governmentYields: any) {
        const ar = (Array.isArray(governmentYields) ? governmentYields : []).pop();
        if (!Array.isArray(ar)) {
            return [];
        }
        return ar.slice(2);
    }
}
