import {Globals} from "../../const/Globals";
import {EParameters} from "../../models/EParameters";
import {IParameterChanged} from "../../models/IParameterChanged";
import {EventBus} from "../../services/EventBus";
import {PublicDbCalls} from "../../services/PublicDbCalls";

let cc: number = 1;

export enum EDataProviderEvents {
    OnBeforeUpdate= "OnBeforeUpdate",
    OnAfterUpdate= "OnAfterUpdate",
}
export abstract class BaseDataProvider {
    get mappedParameters(): Map<EParameters, any[]> {
        return this._mappedParameters;
    }
    get instanceName(): string {
        return this._instanceName;
    }
    get parameters(): any[] {
        return this._parameters;
    }

    set parameters(value: any[]) {
        if (value && Array.isArray(value)) {
            this._parameters = value;
        }
    }

    set prepare(value: boolean) {
        this._prepare = value;
    }
    public parameterMapping: EParameters[] = [];
    protected className: string = "BaseDataProvider";

    private readonly _instanceName: string;
    private _parameters: any[];
    private _prepare: boolean = false;
    private _mappedParameters: Map<EParameters, any[]> = new Map();

    protected constructor() {
        this.initProvider();
        this._parameters = new Array(this.parameterMapping.length);
        this._instanceName = "ds_" + (cc++);
    }

    public getParameter(parameter: EParameters, defVal: any, item: number= 0) {
        const d = this.mappedParameters.get(parameter);
        if (Array.isArray(d) && item >= 0 && d.length > item) {
            return d[item];
        }
        return defVal;
    }

    public forceUpdate(): void {
        this.beforeUpdate();
    }
    public setParameters(parameters: any[]) {
        if (Array.isArray(parameters)) {
            this._parameters = parameters;
        }
    }
    public updateParameter(parameter: IParameterChanged) {
        if (this.parameterMapping && Array.isArray(this.parameterMapping)) {
            this.setParameter(parameter.component, parameter.value);
        }
    }

    public async getAllCountries() {
        if (!Globals.countries.length) {
            try {
                const db = new PublicDbCalls();
                if (!Globals.countries.length) {
                    Globals.countries = (await db.getAllCountries()).result;
                }
            } catch (exi) {
                console.error(exi);
            }
        }
        return Globals.countries;
    }
    protected abstract initProvider();
    protected abstract update();
    protected emit(ev: EDataProviderEvents, data: any): void {
        const e_name = `${this.className.toLocaleLowerCase()}_${ev}_${this._instanceName}`;
        EventBus.emit(e_name, data);
    }
    protected afterUpdate(newData: any) {
        Globals.unsetUpdateLock(this.instanceName);
        this.emit(EDataProviderEvents.OnAfterUpdate, newData);
    }
    private setParameter(parameter: EParameters, newValue: any) {
        let needUpdate = false;
        this.parameterMapping.some((item, index) => {
            const r = parameter === item;
            if (r) {
                this._mappedParameters.set(item, newValue);
                this._parameters[index] = newValue;
                needUpdate = true;
            }
            return r;
        }, this);
        if (needUpdate && !this._prepare) {
            this.beforeUpdate();
        }
    }
    private beforeUpdate() {
        const parameters = {};
        this.mappedParameters.forEach( (value, key) => {
            parameters[key] = value;
        } );
        this.emit(EDataProviderEvents.OnBeforeUpdate, parameters);
        this.update();
    }
}
