import {DateTime, Duration} from "luxon";
import {Globals} from "../../../const/Globals";
import {Ratings} from "../../../const/Ratings";
import {SessionStore} from "../../../const/SessionStore";
import {find_object, getRatingIndication} from "../../../helpers/Helpers";
import {DebtBeta, IUnleveredBeta} from "../../../models/chef/UnleveredBeta";
import {EParameters} from "../../../models/EParameters";
import {IFinancialAnnualReported} from "../../../models/tables/FinancialAnnualReported";
import {fromDateDecimal, fromStr} from "../../../tools/DateTools";
import {SzTableHelper} from "../../widgets/helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties, ISzTagOptions} from "../../widgets/SzTable";
import {ITableDetailsParams} from "./TableDetailsParams";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const notes = [
    "Rohdaten: Factset Inc.",
    "¹) Lineare Interpolation; für Bestandsgrößen",
    "²) Lineare Interpolation; für erfolgs- oder zahlungswirksame Größen",
    "³) Finanzschulden zzgl. Pensionsdefizit und Barwert Opeate Lease abzgl. Liquide Mittel",
    "⁴) Buchwert Eigenkapital inklusive Minderheitsanteile",
    "⁵) EBITDA – Zinsergebnis – Steuern",
    "⁶) Operatives Zinsergebnis",
    "⁷) Der Rating Score wird ermittelt auf Basis der Finanzkennzahlen FFO / Debt, Debt / EBITDA, Debt / Equity und EBIT / Interest. Rating Scores größer als 14 (\"B\"), werden auf 14 gesetzt.",
    "⁸) Die Rating Indikation wird unter Berücksichtigung beobachteter historischer Ratings zugeordnet.",
    "⁹) Der Anteil des systematischen Risikos wird auf Basis emprischer Erfahrungswerte unter Berücksichtung des indikativen Ratings ermittelt",
    "¹⁰) Das Risk Premium ist der systematische Anteil des Kreditaufschlags im Sinne des CAPM.",
];
const fields: string[] =(
    "weighting_a,weighting,debt," +
    "ff_eq_tot,ff_funds_oper_gross,ff_ebit,ff_ebitda,ff_int_exp_debt,ff_funds_oper_gross_debt," +
    "debt_ff_ebitda,debt_ff_eq_tot,ff_ebit_ff_int_exp_debt," +
    "rating_score,rating_indikation,creditspread,systematic_risk_premium,systematic_risk_premium_abs,market_risk,debt_beta"
).split(",");
const negativ_fields = "".split(",");
const labels = {
    weighting_a: "Gewichtung¹",
    weighting: "Gewichtung²",
    debt: " Debt³",
    ff_eq_tot: " Equity⁴",
    ff_funds_oper_gross: " Funds from Operations⁵",
    ff_ebit: " EBIT",
    ff_ebitda: " EBITDA",
    ff_int_exp_debt: " Interest⁶",
    ff_funds_oper_gross_debt: " FFO / Debt",
    debt_ff_ebitda: " Debt / EBITDA",
    debt_ff_eq_tot: " Debt / Equity",
    ff_ebit_ff_int_exp_debt: " EBIT / Interest",
    rating_score: " Rating Score⁷",
    rating_indikation: " Rating Indikation⁸",
    creditspread: ` ${_t(ETranslation.credit_spread)}`,
    systematic_risk_premium: " Anteil systematisches Risiko⁹",
    systematic_risk_premium_abs: " Risikoprämie¹⁰",
    market_risk: ` ${_t(ETranslation.market_risk_premium)}`,
    debt_beta: _t(ETranslation.debt_beta),
};

export const getTable = (params: ITableDetailsParams): ISzTableProperties<any[]> => {

    const market_risk = SessionStore.get(EParameters.MarketRiskPremiumParameter);

    const deadline: DateTime = fromStr(params.deadline, "dd.LL.yyyy").endOf("month");

    const unlevered_beta: IUnleveredBeta = params.unlevered_beta_map[params.period];
    const unlevered_beta_5y: IUnleveredBeta = params.unlevered_beta_map[params.period];
    if(!unlevered_beta_5y){
        return undefined;
    }
    const latest_report: IFinancialAnnualReported = find_object(params.company_reports,(date_decimal, o: IFinancialAnnualReported)=>{
        const report_date = fromDateDecimal(o.date_decimal);
        const months: Duration = deadline.diff(report_date, "months");
        return months.months < 13;
    });
    if(!latest_report){
        return undefined;
    }

    const d: DateTime = fromDateDecimal(latest_report?.date_decimal);
    const header_0: ISzTableColumn[] = [
        { text: `Mio. ${latest_report?.currency_iso}`},
    ];
    const header_1: ISzTableColumn[] = [
        { text: `${d.toFormat("dd.LL.")}`},
    ];
    [5, 4, 3, 2, 1, 0].forEach((y)=>{
        header_0.push({ text: `${d.minus({year: y}).toFormat("yyyy")}`, options: {className: "sz-right"} });
        header_1.push({ text: `Ist`, options: {className: "sz-right"} });
    });
    // werte spalte
    header_0.push({ text: `${params.show_result === 0 ? Globals.average : _t(ETranslation.valuation_date)}`, options: {className: "sz-right"} });
    header_1.push({ text: `${params.show_result === 0 ? `${params.years} ${_t(ETranslation.years)}` : d.toFormat("dd.LL.yyyy")}`, options: {className: "sz-right"} });

    const columns: ISzTableColumn[] = [
        { ...SzTableHelper.columnIndex(0)},
        { ...SzTableHelper.columnMoney(1, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(2, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(3, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(4, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(5, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(6, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(7, 0, {style: {width: 82}})},
    ];
    const data = [];
    fields.forEach((field, idx)=>{
        const data_row = [
            labels[field]
        ];
        data.push(data_row);
        [5, 4, 3, 2, 1, 0].forEach((year)=>{

            if(!unlevered_beta.components.debt_beta){
                return data_row.push(NaN);
            }

            const dt = d.startOf("month").minus({year}).endOf("month").asDateDecimal();
            let values: DebtBeta = unlevered_beta.components.debt_beta[dt];
            if(!values){
                values = unlevered_beta_5y.components.debt_beta[dt]
                    ? {...unlevered_beta_5y.components.debt_beta[dt], weighting: NaN}
                    : undefined;
            }
            if(!values){
                data_row.push(NaN);
            }else{
                if(field === "rating_indikation" && values.rating_score){
                    values.rating_indikation = getRatingIndication(Math.round(values.rating_score));
                }
                if(field === "market_risk" && year !== 5){
                    values.market_risk = market_risk;
                }
                if(negativ_fields.includes(field) && values[field]){
                    data_row.push(values[field] * -1);
                }else{
                    data_row.push(values[field]);
                }
            }
        });
        if([0, 1].includes(idx)){
            return data_row.push("");
        }
        if(!unlevered_beta.components.debt_beta){
            return data_row.push("");
        }
        const result_values = unlevered_beta.components.debt_beta[params.show_result];
        if(result_values){

            if(field === "rating_indikation" && result_values.rating_score){
                result_values.rating_indikation = getRatingIndication(Math.round(result_values.rating_score));
            }
            if(field === "market_risk"){
                result_values.market_risk = market_risk;
            }

            if(negativ_fields.includes(field) && result_values[field]){
                data_row.push(result_values[field] * -1);
            }else{
                data_row.push(result_values[field]);
            }

        }else{
            data_row.push("");
        }
    });
    // console.error(data);
    const tableProps: ISzTableProperties<any[]> = {
        title: _t(ETranslation.debt_beta),
        colCount: 7,
        notStriped: true,
        notes,
        header: [
            header_0,
            header_1
        ],
        columns,
        data,
        column_styles: {
            "0" : {borderRight: "#ffffff solid 4px"},
            "6" : {borderRight: "#ffffff solid 4px"},
        } as any,
        beforeRenderCell: (cellValue: any, rowNum: number, celNum: number, definition: ISzTableColumn, options: ISzTagOptions)=>{
            const class_names = options.className ? [options.className] : [];

            if([0, 1].includes(rowNum) && celNum > 0 && celNum!==7){
                definition.formatter = (v)=>{
                    return Globals.formatter_percent(v, 1, 1, true);
                };
            }
            if([8, 10, 14, 15, 16, 17].includes(rowNum) && celNum > 0){
                definition.formatter = (v)=>{
                    return Globals.formatter_percent(v, 1, 1, true);
                };
            }
            if([9, 11, 12].includes(rowNum) && celNum > 0){
                definition.formatter = (v)=>{
                    return Globals.formatter(v, 1, 1, true);
                };
            }
            if([18].includes(rowNum) && celNum > 0){
                definition.formatter = (v)=>{
                    return Globals.formatter(v, 2, 2, true);
                };
            }
            if(rowNum === 13 && celNum > 0){
                definition.formatter = undefined;
                options.cellRenderer = (v)=> v ? v : "";
            }
            if([0, 1].includes(rowNum) && celNum ===7){
                definition.formatter = undefined;
                options.cellRenderer = (v)=> "";
            }
            if((rowNum>3 || rowNum===1) && celNum ===1){
                definition.formatter = undefined;
                options.cellRenderer = (v)=> "";
            }

            if([1, 4, 7, 11].includes(rowNum) && celNum < 7){
                class_names.push("bottom-space");
            }
            if([1, 4, 7, 11].includes(rowNum) && celNum === 7){
                class_names.push("bottom-space-gray");
            }
            if([7, 11].includes(rowNum)){
                class_names.push("sz-border-bottom-td");
            }
            if(celNum === 7){
                class_names.push("sz-bg-gray");
            }
            if(rowNum === 18){
                class_names.push("sz-bg-gray", "sz-bold");
            }

            options.className = class_names.join(" ");
        },
    };

    return tableProps;
}
