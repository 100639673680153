export class BondSector {
    public static matrix_to_value = {
        "1_0": "1100,1200,1300,1400",
        "2_0": "2100,2200,2300,2400",
        "3_0": "3100,3200,3250,3300,3350,3400,3500",
        "4_0": "4600,4700,4800,4900",

        "0_1": "1100,2100,3100",
        "0_2": "1200,2200,3200,3250",
        "0_3": "1300,2300,3300,3350",
        "0_4": "1400,2400,3400,3500",

        "1_1": "1100",
        "2_1": "2100",
        "3_1": "3100",

        "1_2": "1200",
        "2_2": "2200",
        "3_2": "3200,3250",

        "1_3": "1300",
        "2_3": "2300",
        "3_3": "3300,3350",

        "1_4": "1400",
        "2_4": "2400",
        "3_4": "3400,3500",

        "4_2": "4600",
        "4_3": "4700",
        "4_4": "4800",
        "4_5": "4900",
    };
    public static matrix_to_label = {
        "0_0" : "Alle",
        "1_0" : "Langlebige Güter",
        "2_0" : "Kurzlebige Güter",
        "3_0" : "Dienstleistungen",
        "4_0" : "Infrastruktur",

        "0_1" : "Materialien",
        "0_2" : "Produktion",
        "0_3" : "Technologie",
        "0_4" : "Endverbraucher",

        "1_1" : "1100",
        "2_1" : "2100",
        "3_1" : "3100",

        "1_2" : "1200",
        "2_2" : "2200",
        "3_2" : "3200, 3250",

        "1_3" : "1300",
        "2_3" : "2300",
        "3_3" : "3300, 3350",

        "1_4" : "1400",
        "2_4" : "2400",
        "3_4" : "3400, 3500",

        "4_2" : "4600",
        "4_3" : "4700",
        "4_4" : "4800",
        "4_5" : "4900",
    };

    public static value_to_matrix = {
        "1100,1200,1300,1400": "1_0",
        "2100,2200,2300,2400": "2_0",
        "3100,3200,3250,3300,3350,3400,3500": "3_0",
        "4600,4700,4800,4900": "4_0",
        "1100,2100,3100": "0_1",
        "1200,2200,3200,3250": "0_2",
        "1300,2300,3300,3350": "0_3",
        "1400,2400,3400,3500": "0_4",

        "1100" : "1_1",
        "2100" : "2_1",
        "3100" : "3_1",

        "1200" : "1_2",
        "2200" : "2_2",
        "3200,3250" : "3_2",

        "1300" : "1_3",
        "2300" : "2_3",
        "3300,3350" : "3_3",

        "1400" : "1_4",
        "2400" : "2_4",
        "3400,3500" : "3_4",

        "4600" : "4_2",
        "4700" : "4_3",
        "4800" : "4_4",
        "4900" : "4_5",
    };

    public static weights_sector = {
        "1_1": [.30,	.10,	.05,	.20,	.35,],
        "1_2": [.40,	.25,	0,	.10,	.25,],
        "1_3": [.35,	.15,	0,	.20,	.30,],
        "1_4": [.35,	.20,	0,	.20,	.25,],
        "2_1": [.20,	.15,	.05,	.25,	.35,],
        "2_2": [.35,	.20,	.05,	.15,	.25,],
        "2_3": [.45,	.05,	0,	.05,	.45,],
        "2_4": [.35,	.20,	0,	.15,	.30,],
        "3_1": [.40,	.15,	0,	.15,	.30,],
        "3_2": [.30,   .20,   .05, .25,	.20,],
        "3_3": [.40,	.15,	0,	.10,	.35,],
        "3_4": [.50,	.20,	0,	.10,	.20,],
        "4_2": [.45,	.20,	0,	.15,	.20,],
        "4_3": [.35,	.25,	0,	.05,	.35,],
        "4_4": [.20,	.20,	.50,	0,	.10,],
        "4_5": [.35,	.20,	0,	.25,	.20,],
        "5_1": [.35,	.20,	0,	.15,	.30,],
        "0_0": [.35,	.20,	0,	.15,	.30],
        "0_1": [.30,	.15,	.05,	25,	.25,],
        "0_2": [.40,	.20,	0,	.10,	.30,],
        "0_3": [.45,	.15,	0,	.20, .30,],
        "0_4": [.40, .20, 0,  .15, .25,],
        "1_0": [.35,	.20,	0,	.20,	.25,],
        "2_0": [.35,	.15,	.05,	.15,	.30,],
        "3_0": [.40,	.20,	0,	.15,	.25,],
        "4_0": [.35,	.20,	0,	.10,	.35,],
    };
}
