import {BaseModule} from "./BaseModule";
import * as React from "react";
import {Tree} from "./components/cost_of_capital/Tree";

import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {Globals} from "../../const/Globals";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {BetaGlobals} from "../../const/BetaGlobals";
import {RatingDebtBetaSystematicRisk} from "../../const/RatingDebtBetaSystematicRisk";
import {ParameterOptions} from "../../const/ParameterOptions";

 type TCostOfCapitalFields = {
    showShieldRiskEffect: boolean,
    showCountryRiskPremium: boolean,
    baseInterest: string,
    leverage: string,
    countryRiskUsage: string,
    betaUnlevered: string,
    betaUnleveredDefault: string,
    taxShieldCashEffect: string,
    taxShieldRiskEffect: string,
    debtBeta: string,
    marketRiskPremium: string,
    countryRiskPremium:string,
     sizePremium:string,
    creditSpread: string,
    creditSpreadDefault: string,
    betaLevered: string,
    equityRiskPremium: string,
    equityRiskCountry: string,
    costOfEquity: string,
    weightingCostOfEquity: string,
    costOfDebt: string,
    costOfDebtAfterTaxes: string,
    weightingCostOfDebt: string,
    weightedAvgCostOfCapital: string,
    unleveredCostOfEquity: string,
}
export type TTreeNode = {
    id: string,
    text: string,
    value?: number | string,
    children?: TTreeNode[],
}

export class CostOfCapitalTree extends BaseModule<any> {
    private cocEvent: IEventHandler;
    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            coc: SessionStore.getItem(EParameters.CostOfCapital)
        }
        EventBus.subscribe("CostOfCapital::changed",(coc)=>{
            this.setState({coc});
        });
    }
    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected renderContent() {
        const roundingRuleParameterV = {};
        roundingRuleParameterV[EParameters.RoundingRuleParameter] = SessionStore.get(EParameters.RoundingRuleParameter);
        const roundingRuleParameter = ParameterOptions.getOptionLabel(EParameters.RoundingRuleParameter, roundingRuleParameterV, false);
        const coc : TCostOfCapitalFields = this.state.coc;
        const usage_debtBeta = this.getParameterValue(EParameters.DebtBetaCalcParameter, "0");
        let debtBeta = 0;
        if(usage_debtBeta === "2"){
            debtBeta = parseFloat(this.getParameterValue(EParameters.DebtBetaValueParameter, "0"));
        }
        // brechnen
        if(usage_debtBeta === "1"){
            const rating = this.getParameterValue(EParameters.RatingParameter, "BBB");
            const rating_idx = BetaGlobals.ratingScaleSP.indexOf(rating);
            const field = BetaGlobals.ratingScaleMoodys[rating_idx] ? BetaGlobals.ratingScaleMoodys[rating_idx] : "";
            const sys_risk = RatingDebtBetaSystematicRisk.data[field.toLowerCase()];
            debtBeta = Number(coc?.creditSpread) * sys_risk / Number(coc?.marketRiskPremium);
        }
        const treeData: TTreeNode[]  = [
            {
                id: '1',
                text: _t(ETranslation.wacc),
                value:`${Globals.formatter(parseFloat(coc?.weightedAvgCostOfCapital)*100,2,2,true)} %`,

                children: [
                    {
                        id: 'l2_l_1',
                        text: _t(ETranslation.cost_of_equity),
                        value: `${Globals.formatter(parseFloat(coc?.costOfEquity)*100,2,2,true)} %`,
                        children: [
                            {
                                id: 'l3_l_1',
                                text: `${_t(ETranslation.base_rate)}¹`,
                                value: `${Globals.formatter(parseFloat(coc?.baseInterest)*100, 2,2,true)} %` ,
                            },
                            {
                                id: 'l3_l_2',
                                text: _t(ETranslation.country_risk_premium),
                                value: `${Globals.formatter(parseFloat(coc?.countryRiskPremium)*100, 2,2,true)} %` ,

                            },
                            {
                                id: 'l3_l_3',
                                text: _t(ETranslation.company_specific_risk_premium),
                                value: `${Globals.formatter(parseFloat(coc?.sizePremium)*100, 2,2,true)} %` ,

                            },
                            {
                                id: 'l3_l_4',
                                text: _t(ETranslation.equity_risk_premium),
                                value: `${Globals.formatter(parseFloat(coc?.equityRiskPremium)*100 ,2,2,true)} %`,
                                children: [
                                    {
                                        id: 'l4_l_1',
                                        text: _t(ETranslation.beta_levered),
                                        value: Globals.formatter(parseFloat(coc?.betaLevered), 2,2,true),
                                        children: [
                                            {
                                                id: 'l5_l_1',
                                                text: 'Beta unlevered²',
                                                value: Globals.formatter(parseFloat(coc?.betaUnlevered),2,2,true),
                                            },
                                            {
                                                id: 'l5_l_2',
                                                text: _t(ETranslation.tax_shield),
                                                value:`${Globals.formatter(parseFloat(coc?.taxShieldCashEffect)*100, 2,2,true)} %` ,

                                            },
                                            {
                                                id: 'l5_l_3',
                                                text: _t(ETranslation.debt_beta),
                                                value: Globals.formatter(debtBeta, 2,2,true) ,

                                            },
                                            {
                                                id: 'l5_l_4',
                                                text: _t(ETranslation.gearing),
                                                value: `${Globals.formatter(parseFloat(coc?.leverage)*100,2,2,true)} %`,
                                            },
                                        ]
                                    },
                                    {
                                        id: 'l4_l_2',
                                        text: _t(ETranslation.market_risk_premium),
                                        value: `${Globals.formatter(parseFloat(coc?.marketRiskPremium)*100, 2,2,true)} %`,
                                    }
                                ]
                            },
                        ]
                    },
                    {id:'l2_m_1',text:_t(ETranslation.cost_of_equity_weight).split(" ")[0], value:`${Globals.formatter(parseFloat(coc.weightingCostOfEquity)*100,2,2,true)} %`},
                    {id:'l2_m_2',text:_t(ETranslation.cost_of_debt_weight).split(" ")[0], value:`${Globals.formatter(parseFloat(coc.weightingCostOfDebt)*100,2,2,true)} %`},
                    {
                        id: 'l2_r_1',
                        text: _t(ETranslation.cost_of_debt_after_tax),
                        value: `${Globals.formatter(parseFloat(coc?.costOfDebtAfterTaxes)*100, 2,2,true)} %` ,
                        children: [
                            {
                                id: 'l3_r_1',
                                text: `${_t(ETranslation.base_rate)}¹`,
                                value: `${Globals.formatter(parseFloat(coc?.baseInterest)*100, 2,2,true)} %`,

                            },
                            {
                                id: 'l3_r_2',
                                text: _t(ETranslation.credit_spread),
                                value: `${Globals.formatter(parseFloat(coc?.creditSpread)*100, 2,2,true)} %` ,

                            },
                            {
                                id: 'l3_r_3',
                                text: _t(ETranslation.country_risk_premium),
                                value: `${Globals.formatter(parseFloat(coc?.countryRiskPremium)*100, 2,2,true)} %` ,

                            },
                            {
                                id: 'l3_r_4',
                                text: _t(ETranslation.tax_shield),
                                value: `${Globals.formatter(parseFloat(coc?.taxShieldCashEffect)*100, 2,2,true)} %` ,
                            },
                        ]
                    }
                ]
            }
        ];
        const getBetaUnleveredLabel = (betaUnlevered: number): string => {
            if (betaUnlevered === 3) {
                return `${_t(ETranslation.median)} ${_t(ETranslation.peer_group_2)}`
            }
            if(betaUnlevered === 0){
                return`${_t(ETranslation.average)} ${_t(ETranslation.peer_group_2)}`
            }
            if(betaUnlevered === 2){
                return`${_t(ETranslation.lower_quartile)} ${_t(ETranslation.peer_group_2)}`
            }
            if(betaUnlevered === 4){
                return`${_t(ETranslation.upper_quartile)} ${_t(ETranslation.peer_group_2)}`
            }
        };
        return(
            <div className={"tree_container"}>
                <Tree treeData={treeData}/>
                <div>
                    <p> ¹) {_t(ETranslation.rounding_footnote, roundingRuleParameter)}</p>
                    <p >²) {getBetaUnleveredLabel(Number(SessionStore.getItem(EParameters.BetaUnleverdOptions,3)))} </p>

                </div>
            </div>
        )
    }
}