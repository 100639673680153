import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";
import {ParameterOptions} from "../../../const/ParameterOptions";

export class CreditSpreadDecisionParameterA extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CreditSpreadDecisionParameterA];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.CreditSpreadDecisionParameterA);
    }
    protected async getOptions() {
        return ParameterOptions.options[EParameters.CreditSpreadDecisionParameterA];
    }
}
