import {EContentType} from "../../const/EContentType";
import {EExcelExportType} from "../../const/EExcelExportType";
import {EPackageTypes} from "../../const/EPackageTypes";
import {NopeActions} from "../../const/NopeActions";
import {ELayoutColumnWidth} from "../../models/ELayoutColumnWidth";
import {EParameters} from "../../models/EParameters";
import {IColumnLayout} from "../../models/IColumnLayout";
import {ColumnLayouterImpl} from "../ColumnLayouterImpl";
import {ModuleGroup} from "../ModuleGroup";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {IModuleConfig} from "../../models/IModuleConfig";

export class BetaFactor extends ModuleGroup<IColumnLayout> {
    public static config: IModuleConfig = {
        name: "Beta-Faktoren",
    };
    constructor() {
        super("Betafaktoren", { columns: []});
        this.new_pdf_export = true;
        this.title = "Bestimmung Beta-Faktor";

        this.documentation = [
            {module: "BetasUnleveredBeta", caption: "Beta-Faktor"},
            {module: "BetasStatistics2", caption: _t(ETranslation.trading_statistics)},
            {module: "BetasRawBetaSvg", caption: "Zeitablauf"},
            {module: "BetasCompare", caption: "Vergleich"},
            {module: "CompanyInfo", caption: "Unternehmensprofile"},
        ];
        this.addParameter(EParameters.CompaniesParameter);
        this.addParameter(EParameters.DateParameter);

        this.addParameter(EParameters.DividerParameter);

        this.addParameter(EParameters.RenditeCombiParameter);
        this.addParameter(EParameters.IndexCombiParameter);
        this.addParameter(EParameters.BetaFilterParameter);

        this.addParameter(EParameters.DividerParameter);

        this.addParameter(EParameters.GearingCombiParameter);
        this.addParameter(EParameters.TaxshieldParameter);
        this.addParameter(EParameters.DebtBetaCombiParameter);

        // this.addParameter(EParameters.SectorCountriesParameter);

        const dataDefault = [{theSame: true}];
// 0
        this.addModule("Longlist Unlevered Beta", "BetasCompare", "ChefBetasDataProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true, EExcelExportType.beta);
// 1
        this.addModule("Shortlist Unlevered Beta", "BetasUnleveredBeta", "ChefBetasDataProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true, EExcelExportType.beta);
// 2
        this.addModule(_t(ETranslation.trading_statistics), "BetasStatistics2", "ChefBetasDataProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true, EExcelExportType.beta);
// 3
        this.addModule(_t(ETranslation.time_series), "BetasRawBetaSvg", "ChefBetasDataProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
            .setExcelExport(true, EExcelExportType.beta);
// 4
        this.addModule(_t(ETranslation.sector_tracker), "BetaRiskMonitor", "ChefBetasDataProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
// 5
        // ChefBetasDataProvider // AdminTool1Provider
        this.addModule(_t(ETranslation.analysis), "AdminToolV2", "ChefBetasDataProvider", dataDefault)
            .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register);
// 7
        // this.addModule("Peer-Group", "CompanyInfo", "BetasDataProvider", dataDefault)
       //     .setExcelExport(true, EExcelExportType.beta)
       //     .setAlignRight()
       //     .setAccess(EContentType.Premium, EPackageTypes.Deu, NopeActions.beta);
// 8
        // this.addModule("Total Beta", "BetasTotalBeta", "BetasDataProvider", dataDefault)
        //     .setAccess(EContentType.Freemium, EPackageTypes.Dax30, NopeActions.register)
        //     .setAlignRight();

        ColumnLayouterImpl.addColumn(this.layout, ELayoutColumnWidth.c100)
            .addModule([0, 1, 2, 3, 4, 5]);
    }

    protected getTitle(): string{
        return _t(ETranslation.beta_factor);
    }
}
