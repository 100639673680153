import {Classes, Menu, MenuDivider} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {EParameters} from "../../../models/EParameters";
import {IOptionEntry} from "../../../models/IOptionEntry";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {StoredSearch} from "../../../services/StoredSearch";
import {CombiParameter} from "../CombiParameter";

export class PrimeSectorParameter  extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
        this.state = {
            selectedValue: null,
        };
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.PrimeSectorParameter];
    }

    protected async initialize() {
        const searches = await StoredSearch.get();
        let options = [];
        if (searches && searches.result && Array.isArray(searches.result)) {
            options = searches.result.map((i) => ({value: i._id, label: i.name, data: i}));
            Globals.primeSectorOptions = options;
        }
        return {
            selectedValue: null,
            options,
        };
    }

    protected renderMenuContent(): any {
        const publicList: IOptionEntry[] = [];
        const privateList: IOptionEntry[] = [];
        this.state.options.forEach( (o) => {
            const item: IOptionEntry = {
                label: o.label,
                value: o.value,
                selected: o.value === this.state.selectedValue,
                data: o.data,
            };
            if (o.data.is_public) {
                publicList.push(item);
            } else {
                privateList.push(item);
            }

        } );
        const renderMenu = (optionList: IOptionEntry[], caption: string, empty_text: string) => {
            if (!optionList.length) {
                return (
                    <Menu>
                        <MenuDivider title={caption} />
                        <div className={Classes.TEXT_MUTED} style={{marginLeft: 7}}>{empty_text}</div>
                    </Menu>
                );
            }
            return (
                <Menu>
                    <MenuDivider title={caption} />
                    {this.renderOptions(optionList, this.state.selectedValue, EParameters.PrimeSectorParameter, "selectedValue")}
                </Menu>
            );
        };
        const renderPublic = () => {
            if (Globals.isRegistred) {
                return (
                    <div style={{width: "50%"}}>
                        {renderMenu(publicList, "Öffentliche Sektoren", "Keine öffentlichen Sektoren definiert.")}
                    </div>
                );
            } else {
                return renderMenu(publicList, "Öffentliche Sektoren", "Keine öffentlichen Sektoren definiert.");
            }
        };
        const renderPrivate = () => {
            if (Globals.isRegistred) {
                return (
                    <div style={{width: "50%"}}>
                        {renderMenu(privateList, "Eigene Sektoren", "Sie haben noch keine eigenen Sektoren definiert.")}
                    </div>
                );
            } else {
                return null;
            }
        };
        return (
            <div className={"sz-row"}>
                {renderPublic()}
                {renderPrivate()}
            </div>
        );
    }

    protected getLabel(): any {
        const option = this.state.options.find((item) => item.value === this.state.selectedValue);
        return option ? option.label : "—";
    }
    protected getWidthClass() {
        if (Globals.isRegistred) {
            return "fixed-width-600";
        }
        return null;
    }
}
