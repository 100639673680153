export enum ETaxShieldType{
    UNSAFE,
    SAFE_TAX_RATE, // gesetzlicher steuersatz
    SAVE_TAX_EXPENSE, // steueraufwand
    SAVE_TAX_PAYMENT, // steuerzahlung
}
export enum EDebtBetaFilter {
    DONT_USE,
    USE,
    USE_ONLY_IF_GEARING_OVER_100,
    USE_ONLY_IF_GEARING_OVER_200,
    USE_ONLY_IF_GEARING_OVER_300,
}
export enum ERawBetaType {
    RAW_BETA= 0,
    RAW_BETA_BLUME_ADJUSTED= 1,
    TOTAL_RAW_BETA= 2,
}
export interface IRawBeta {
    company_id: number;
    periods: {[period: string]: {beta: number; r: number;}};
}
type RawBeta = {[company_id: number] : IRawBeta};
export type Company = {company_id: number; raw_beta: RawBeta;};
export type GearingPeers = {
    ff_debt: boolean;
    ff_pens_liabs_unfunded: boolean;
    operate_lease: boolean;
    ff_cash_only_ff_cash_restr: boolean;
    ff_cash_restr: boolean;
    ff_invest_st_tot: boolean;
    ff_invest_lt_oth_ff_notes_receiv_lt: boolean;
    ff_invest_aff: boolean;
    ff_debt_xlease: boolean;
    ff_debt_ff_debt_xlease: boolean;
};
export interface Gearing{
    date: string;
    ff_cash_only_ff_cash_restr: number;
    ff_cash_restr: number;
    ff_debt: number;
    ff_debt_ff_debt_xlease: number;
    ff_debt_xlease: number;
    ff_invest_aff: number;
    ff_invest_lt_oth_ff_notes_receiv_lt: number;
    ff_invest_st_tot: number;
    ff_pens_liabs_unfunded: number;
    gearing: number;
    market_cap: number;
    operate_lease: number;
    ration: number;
    weighting: number;
}
export interface DebtBeta {
    date: string;
    weighting_a: number;
    duration_a: number;
    weighting: number;
    duration: number;
    debt: number;
    ff_eq_tot: number;
    ff_funds_oper_gross: number;
    ff_ebit: number;
    ff_ebitda: number;
    ff_int_exp_debt: number;
    ff_funds_oper_gross_debt: number;
    debt_ff_ebitda: number;
    debt_ff_eq_tot: number;
    ff_ebit_ff_int_exp_debt: number;
    rating_score: number;
    rating_indikation: string;
    creditspread: number;
    systematic_risk_premium: number;
    systematic_risk_premium_abs: number;
    market_risk: number;
    debt_beta: number;
}
export interface UnleveredBetaParams {
    companies: Company[];
    company_betas_by_date?: any;
    sector_id: number;
    market_risk_premium: number;
    tax_shield_type: ETaxShieldType;
    period: number;
    deadline: string;
    use_deadline: boolean;
    gearing_peers: GearingPeers;
    dont_use_negativ_gearing: boolean;
    debt_beta_filter: EDebtBetaFilter;
    // levered_beta_adjustment: ELeveredBetaAdjustment;
    raw_beta_type: ERawBetaType;
}

export interface IUnleveredBeta {
    components: {
        debt_beta: {[index: number]: any};
        gearing: {[index: number]: any};
        tax_shield: {[index: number]: any};
    };
    result: {
        debt_beta: number;
        gearing: number;
        levered_beta: number;
        tax_shield: number;
        unlevered_beta: number;
    };
}
export interface IUnleveredBetaResponse {
    company_id: number;
    raw_beta: number;
}
export type UnleveredBetaResponse = {[company_id: number]: IUnleveredBetaResponse};
