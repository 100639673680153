import * as  release_notes from "../../resources/release_notes.json";
import {Classes} from "@blueprintjs/core";
import * as React from "react";
import {SzMdViewer} from "./SzMdViewer";
import * as sys_info from "../../sys_info.json";
import {fromDateDecimal} from "../../tools/DateTools";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
interface IPagePageSysInfoState {
    is_loading: boolean;
}
enum EReleaseNoteTopicType {
    news = "Neues",
    obsolete = "Obsolet",
    bug_fix = "Fehlerkorrektur",
}
type TopicSection = {section: string; paragraphs: string[]};
type ReleaseNoteTopic = {type: EReleaseNoteTopicType, sections: TopicSection[]};
type ReleaseNote = {date: number; version: string; topics: ReleaseNoteTopic[]};
type ReleaseNotes = {notes: ReleaseNote[]};

export class PageSysInfo extends React.Component<any, IPagePageSysInfoState> {
    constructor(props: any, context: IPagePageSysInfoState) {
        super(props, context);
        this.state = {
            is_loading: false,
        };
    }
    private renderTableRow(label: string, content: any) {
        return (
            <tr>
                <td className={"sz-no-pd-lr"} style={{whiteSpace: "nowrap", width: 210}}><strong>{label}</strong></td>
                <td><div className={`${Classes.TEXT_MUTED}`}>{content}</div></td>
            </tr>
        );
    }
    private renderNote(note: ReleaseNote){
        const renderTopic= (topic: ReleaseNoteTopic)=>{
            return this.renderTableRow(EReleaseNoteTopicType[topic.type], topic.sections.map((section)=>{
                return (
                    <div>
                        <div style={{marginBottom: 10}}><strong>{section.section}</strong></div>
                        {section.paragraphs.map((p)=><p>{p}</p>)}
                    </div>
                );
            }));
        };
        return (
            <>
                <tr>
                    <td className={"sz-no-pd-lr"} colSpan={2} style={{boxShadow: "none"}}>
                        <h4 style={{marginBottom: 0, boxShadow: "0 1px 0 0 rgba(16, 22, 26, 0.15)", display: "flex", justifyContent: "space-between"}}>
                            <div>Release&nbsp;<span className={"bp3-text-muted"}>v{note.version}</span></div>
                            <span className={"bp3-text-muted"}>{fromDateDecimal(note.date).asDeDate()}</span>
                        </h4>
                    </td>
                </tr>
                {note.topics.map((topic)=>renderTopic(topic))}
            </>
        );
    }
    private renderSysVersion(){
        const notes: ReleaseNote[] = (release_notes as ReleaseNotes).notes;
        return (
            <table className={"sz-table bp3-html-table bp3-html-table-condensed sz-small-padding"}>
                <tbody>
                <tr>
                    <td className={"sz-no-pd-lr"} colSpan={2} style={{boxShadow: "none"}}>
                        <h4 style={{marginBottom: 0, boxShadow: "0 1px 0 0 rgba(16, 22, 26, 0.15)"}}>Systeminformation</h4>
                    </td>
                </tr>
                {this.renderTableRow("Version", sys_info.version)}
                {this.renderTableRow("Datum", fromDateDecimal(sys_info.build_date).toFormat("dd.LL.yyyy"))}
                {notes.map((note)=>this.renderNote(note))}
                </tbody>
            </table>
        );
    }
    private renderDataInfo(){
        return (
            <table className={"sz-table bp3-html-table bp3-html-table-condensed sz-small-padding"}>
                <tbody>
                <tr>
                    <td className={"sz-no-pd-lr"} colSpan={2} style={{boxShadow: "none"}}>
                        <h4 style={{marginBottom: 0, boxShadow: "0 1px 0 0 rgba(16, 22, 26, 0.15)"}}>Basisdaten</h4>
                    </td>
                </tr>
                {this.renderTableRow("Aktienkursrenditen", "Factset Inc., Tagesschlusskurse der Börsen mit dem „Primary Listing“ der jeweiligen Aktie")}
                {this.renderTableRow("Aktienkursrenditen bereinigt", "Factset Inc., Bereinigung um Dividenden, Splitts und Bezugsrechtswerte")}
                {this.renderTableRow("Basiszinssätze Bund", "Bundesbank")}
                {this.renderTableRow("Finanzdaten IST", "Factset Inc., Jahresabschluss-, Halbjahres- und Quartalsdaten soweit verfügbar")}
                {this.renderTableRow("Finanzdaten Schätzung", "Factset Inc., Consensus Estimate Data")}
                {this.renderTableRow("Handelsvolumina & Free Float", "Factset Inc.")}
                {this.renderTableRow("Indexkursrenditen", "Factset Inc., Tagesschlusskurse der Index-Provider")}
                {this.renderTableRow("Indexkursrenditen bereinigt", "Factset Inc., bereinigte Tagesschlusskurse der Index-Provider")}
                {this.renderTableRow("Kreditaufschläge", "Factset Inc.")}
                {this.renderTableRow("Länderrisikoprämien", "Aswath Damodaran, Stern School of Business at New York University")}

                {this.renderTableRow("Makroökonomische Daten", "International Monetary Fund")}
                {this.renderTableRow("Marktrisikoprämien", "Aswath Damodaran, Stern School of Business at New York University")}

                {this.renderTableRow("Staatsanleihenrenditen", "Factset Inc.")}
                {this.renderTableRow(_t(ETranslation.base_data), "Factset Inc., u.a. Aktienanzahl, Sektor- und Subsektorklassifikation, Unternehmensbeschreibung")}
                {this.renderTableRow("Steuersätze", "Öffentlich verfügbare Datenquellen, u.a. KPMG Tax Survey")}
                {this.renderTableRow("Währungskurse", "Bundesbank")}
                </tbody>
            </table>
        );
    }
    public render() {
        return (
            <div style={{margin: 10, marginTop: 0}}>
                <div className={"sz-row"}>
                    <div className={"sz-col sz-col-50"} style={{paddingRight: 10}}>
                        {this.renderSysVersion()}
                    </div>
                    <div className={"sz-col sz-col-50"} style={{paddingLeft: 10}}>
                        {this.renderDataInfo()}
                    </div>
                </div>
            </div>
        );
    }
}
