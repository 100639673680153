import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "react-day-picker/dist/style.css";
import "../blueprint/modified_blueprint.css";

import * as React from "react";
import * as ReactDOM from "react-dom";
import {BrowserRouter, HashRouter} from "react-router-dom";

import {
    Chart as ChartJS
} from "chart.js";
import {ApplicationRouter} from "./components/ApplicationRouter";
import {ProvideAuth} from "./components/hooks/ProvideAuth";
import {ServiceCalls} from "./services/ServiceCalls";
import {EServiceType, ServiceCallBase} from "./services/ServiceCallBase";
import {checkSession} from "./helpers/Helpers";

ChartJS.defaults.font.size = 12*.9;
ChartJS.defaults.font.family = "Segoe UI";

const container = document.getElementById("app");

const App = () => {
    // return (<br />);
    return (
        <ProvideAuth>
            <HashRouter>
                <ApplicationRouter />
            </HashRouter>
        </ProvideAuth>
    );
}

(async ()=>{
    ServiceCalls.getHost(EServiceType.Services);
    try{
        await checkSession();
    }catch (e) {
        console.error("checkSession");
        console.error(e);
    }
    try{
        await ServiceCallBase.initWebSocket();
    }catch (e) {
        console.error("ServiceCallBase.initWebSocket");
        console.error(e);
    }

    ReactDOM.render(<App/>, container);
})();
