import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {BaseDataProvider} from "./BaseDataProvider";
import {getAllNextDates, getResult} from "../../helpers/Helpers";
import {deadlineEoM, fromStr} from "../../tools/DateTools";
import {Ratings} from "../../const/Ratings";
import {DateTime} from "luxon";

const ratings = {
    A: ["AAA", "AA+", "AA", "AA-", "A+", "A", "A-"],
    B: ["BBB", "BBB+", "BBB-", "BB+", "BB", "BB-", "B+", "B", "B-"],
    C: ["CCC", "CCC+", "CCC-", "CC", "C"],
};
const rating_mapping_new = {
    "AAA": ["Aaa"],
    "AA": ["Aa1", "Aa2", "Aa3"],
    "A": ["A1", "A2", "A3"],
    "BBB": ["Baa1", "Baa2", "Baa3"],
    "BB":  ["Ba1", "Ba2", "Ba3"],
    "B":  ["B1", "B2", "B3"],
};
/*
const rating_mapping = {
    "AA": ["Aa2"],
    "AA-": ["Aa3"],
    "A+": ["A1"],
    "A": ["A2"],
    "A-": ["A3"],
    "BBB+": ["Baa1"],
    "BBB": ["Baa2"],
    "BBB-": ["Baa3"],
    "BB+":  ["Ba1"],
    "BB":  ["Ba2"],
    "BB-":  ["Ba3"],
    "B+":  ["B1"],
    "B":  ["B2"],
};
*/
const rating_mapping = {
    "AA": "Aa2",
    "AA-": "Aa3",
    "A+": "A1",
    "A": "A2",
    "A-": "A3",
    "BBB+": "Baa1",
    "BBB": "Baa2",
    "BBB-": "Baa3",
    "BB+":  "Ba1",
    "BB":  "Ba2",
    "BB-":  "Ba3",
    "B+":  "B1",
    "B":  "B2",
};


export class CorporateSpreadProvider extends BaseDataProvider {
    protected initProvider() {
        this.className = "CorporateSpreadProvider";
        this.parameterMapping = [
            EParameters.DateParameter,
            EParameters.RatingParameter,
            EParameters.SectorParameter,
            EParameters.RuntimeParameter,
            EParameters.UseFixedCountriesParameter,
        ];
    }

    protected update() {
        const data = {
            corporateSpread: null,
            ratingKeyRatios: null,
            spread_time_line: [],
            bond_data: [],
            all_bond_data: [],
            selected_rating_map: {},
        };
        const db = new PublicDbCalls();
        (async () => {
            let rating; // rating_mapping[SessionStore.get(EParameters.RatingParameter)];
            const r_map = {};
            data.selected_rating_map = r_map;
            const p_rating = SessionStore.get(EParameters.RatingParameter);
            if(Array.isArray(p_rating) && p_rating.length){
                rating = [];
                p_rating.forEach((p) =>{
                    const r = rating_mapping[p];
                    r_map[r] = true;
                    rating.push(r);
                });
            }
            const sector = "0000"; // SessionStore.get(EParameters.SectorParameter)
            const to_day = DateTime.now().startOf("month").asDateDecimal();
            let the_date = fromStr(SessionStore.get(EParameters.DateParameter)).setToEoM().asDateDecimal();
            if(the_date >= to_day){
                the_date = DateTime.now().startOf("month").minus({month: 1}).setToEoM().asDateDecimal();
            }
            data.all_bond_data = await db.getBondTimeSeries(
                Ratings.moodys,
                the_date,
                undefined,
                sector
            );
            data.bond_data = data.all_bond_data.filter((b)=> r_map[b.rating]);

            // console.error(r_map, data.bond_data.length, data.all_bond_data.length);

            const response = await db.getCorporateSpreadV2([
                [deadlineEoM()],
                this.mappedParameters.get(EParameters.RatingParameter)? this.mappedParameters.get(EParameters.RatingParameter)[0] : this.mappedParameters.get(EParameters.RatingParameter),
                [].concat(this.mappedParameters.get(EParameters.SectorParameter), ["0"]),
            ]);

            const time_line_response = await db.getCorporateSpreadV3([
                getAllNextDates(deadlineEoM(), 3),
                ["AA", "AA-", "A+", "A", "A-", "BBB+", "BBB", "BBB-", "BB+", "BB", "BB-", "B+", "B"],
                [].concat(this.mappedParameters.get(EParameters.SectorParameter), ["0"]),
            ]);
            data.spread_time_line = getResult(time_line_response, []);
            const ratingKeyRatios = await db.getRating_Metrics([this.mappedParameters.get(EParameters.SectorParameter)]);
            try {
                data.corporateSpread = response.result;
                if (Array.isArray(data.corporateSpread) && data.corporateSpread.length === 0) {
                    data.corporateSpread = null;
                }
                data.ratingKeyRatios = ratingKeyRatios.result;
                if (Array.isArray(data.ratingKeyRatios) && data.ratingKeyRatios.length === 0) {
                    data.ratingKeyRatios = null;
                }
            } catch (exi) {
                console.error(exi, response);
            }
            this.afterUpdate(data);
        })();
    }
}
