import * as React from "react";
import {CSSProperties} from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {ISzSelectProperties, SzSelect} from "../../widgets/SzSelect";
import {SessionStore} from "../../../const/SessionStore";
import {no_star} from "../../../helpers/Helpers";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const label_style: CSSProperties={
    display: "flex",
    paddingTop: 10,
};

export class BondFilterParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getPdfLabel() {
        const v_a = SessionStore.get(EParameters.FixedCurrencyParameter);
        const v_b = SessionStore.get(EParameters.SeniorityParameter);
        const v_c = SessionStore.get(EParameters.PledgeStatusParameter);
        const v_d = SessionStore.get(EParameters.BondEnhancementParameter);
        const v_e = SessionStore.get(EParameters.BondCallableParameter);
        const l_a = ParameterOptions.getOptionLabelHardByValue(EParameters.FixedCurrencyParameter, v_a, false);
        const l_b = ParameterOptions.getOptionLabelHardByValue(EParameters.SeniorityParameter, v_b, false);
        const l_c = ParameterOptions.getOptionLabelHardByValue(EParameters.PledgeStatusParameter, v_c, false);
        const l_d = ParameterOptions.getOptionLabelHardByValue(EParameters.BondEnhancementParameter, v_d, false);
        const l_e = ParameterOptions.getOptionLabelHardByValue(EParameters.BondCallableParameter, v_e, false);

        const content = [];
        content.push({text:[
                {text: `Währung: `,bold: true}, `${no_star(l_a)}`
            ]});
        content.push({text:[
                {text: `Seniorität: `,bold: true}, `${no_star(l_b)}`
            ]});
        content.push({text:[
                {text: `Pfandrecht: `,bold: true}, `${no_star(l_c)}`
            ]});
        content.push({text:[
                {text: `Garantie o.ä.: `,bold: true}, `${no_star(l_d)}`
            ]});

        return {
            stack: content
        };
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.BondFilterParameter];
    }

    protected getWidthClass(): string {
        return "fixed-width-500";
    }

    protected async initialize() {
        return this.initializeShort(EParameters.BondFilterParameter);
    }

    protected renderMenuContent(): any {
        return (
            <div className={"sz-row"}>
                <div style={{paddingTop: 10, paddingRight: 10}} className={"sz-row"}>
                    {this.renderSelect(EParameters.FixedCurrencyParameter)}
                    {this.renderSelect(EParameters.SeniorityParameter)}
                    {this.renderSelect(EParameters.PledgeStatusParameter)}
                    {this.renderSelect(EParameters.BondEnhancementParameter)}
                    {this.renderSelect(EParameters.BondCallableParameter)}
                </div>
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.BondFilterParameter))}
            </div>
        );
    }

    protected getLabel(): any {
        const a = this.getOptionLabelHard(EParameters.FixedCurrencyParameter);
        const b = this.getOptionLabelHard(EParameters.SeniorityParameter);
        const c = this.getOptionLabelHard(EParameters.PledgeStatusParameter);
        const d = this.getOptionLabelHard(EParameters.BondEnhancementParameter);
        const e = this.getOptionLabelHard(EParameters.BondCallableParameter);
        return [a,b,c,d,e].join(" | ");
    }

    protected onClosePopup() {
        this.fireShort(EParameters.BondFilterParameter);
    }

    private onChangeValue(field: EParameters, _value: any) {
        const _valueAsNumber = _value;
        const state: {[k: string]: any} = {};
        state[field] = _valueAsNumber;
        this.setState(state);
        // this.fireValueChange(field, _valueAsNumber);
    }
    private getInputProps(parameter: EParameters, optionParam?: EParameters): ISzSelectProperties{
        return {
            options: optionParam ? ParameterOptions.options[optionParam] : ParameterOptions.options[parameter],
            value:  this.state[parameter],
            onValueChanged: (v: any) => this.onChangeValue(parameter, v),
        };
    }
    private renderSelect(parameter: EParameters) {
        const props = this.getInputProps(parameter);
        const label = this.getParameterLabel(parameter);
        return this.renderColumn("40%", label, ()=>{
            return (
                <SzSelect {...props} />
            );
        }, label_style);
    }
}
