import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {SingleSelectParameter} from "../SingleSelectParameter";

export class TpFreeFloatParameter extends SingleSelectParameter {

    constructor(props: any, context: any) {
        super(props, context);
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.TpFreeFloatParameter];
    }

    protected getOptions(): Promise<any> {
        return ParameterOptions.options[EParameters.TpFreeFloatParameter];
    }

    protected getSelectedValue(): Promise<any> {
        return SessionStore.get(EParameters.TpFreeFloatParameter);
    }
    protected getWidthClass() {
        return "fixed-width-400";
    }
}
