import * as FastStats from "fast-stats";
import {EFinAnnRepFieldMap} from "../const/EFinAnnRepFieldMap";
import {EMarketDataFieldMap} from "../const/EMarketDataFieldMap";
import {CompanyMultipliers} from "../tables/CompanyMultipliers";

const Stats = FastStats.Stats;

interface IMCompany {
    id: string;
    ex_country_id: string;
    sector: string;
    sub_sector: string;
}
let allready_calc = {};

const asNumber = (v, defVal) => {
    const vv = "" + v;
    if (vv === "") {
        return defVal;
    }
    const n = Number(vv);
    if (Number.isFinite(n)) {
        return n;
    }
    return defVal;
};
const for_company = (company_id, date_decimal, multiplier_map, marked_data_map, fin_ann_map) => {
    const ac = allready_calc[company_id];
    if (ac) {
        return ac;
    }
    const raw_multiplier = multiplier_map[company_id];
    const marked_data = marked_data_map[company_id];
    const fin_ann = fin_ann_map[company_id];
    const use_fin_ann = Array.isArray(fin_ann) ? fin_ann.reverse().find( (f) => f[EFinAnnRepFieldMap.ff_shldrs_eq] !== null ) : {};
    if (!use_fin_ann) {
        return null;
    }
    if (!marked_data || !raw_multiplier) {
        return null;
    }
    if (!Array.isArray(raw_multiplier)) {
        return null;
    }
    if (!raw_multiplier.length) {
        return null;
    }

    const market_cap = marked_data[EMarketDataFieldMap.ff_mkt_val];
    const enterprise_value = asNumber(
        (market_cap +
            asNumber(use_fin_ann[EFinAnnRepFieldMap.ff_pens_liabs_unfunded], 0) +
            asNumber(use_fin_ann[EFinAnnRepFieldMap.ff_debt], 0)) -
        asNumber(use_fin_ann[EFinAnnRepFieldMap.ff_cash_generic], 0), undefined);
    const price_book = asNumber(asNumber(market_cap, undefined) / asNumber(use_fin_ann[EFinAnnRepFieldMap.ff_eq_tot], undefined), undefined);
    if (enterprise_value === undefined) {
        return null;
    }
    const ret = {
        enterprise_value,
        market_cap,
        price_book,
        sales_0: undefined,
        ebitda_0: undefined,
        ebit_0: undefined,
        netprofit_0: undefined,
    };
    ret.sales_0 = ret.enterprise_value / asNumber(use_fin_ann[EFinAnnRepFieldMap.ff_sales], NaN);
    ret.ebitda_0 = ret.enterprise_value / asNumber(use_fin_ann[EFinAnnRepFieldMap.ff_ebitda], NaN);
    ret.ebit_0 = ret.enterprise_value / asNumber(use_fin_ann[EFinAnnRepFieldMap.ff_ebit], NaN);
    ret.netprofit_0 = ret.market_cap / asNumber(use_fin_ann[EFinAnnRepFieldMap.ff_net_inc], NaN);
    raw_multiplier.forEach( (d: any[]) => {
        const key = `${d[3].toLowerCase()}_${d[2]}`;
        if (["SALES", "EBIT", "EBITDA"].indexOf(d[3]) >= 0) {
            const v = asNumber(d[4], NaN);
            if (!isNaN(v)) {
                ret[key] = ret.enterprise_value / v;
            }
        }
        if (["NETPROFIT"].indexOf(d[3]) >= 0) {
            const v = asNumber(d[4], NaN);
            if (!isNaN(v)) {
                ret[key] = ret.market_cap / v;
            }
        }
    } );
    allready_calc[company_id] = ret;
    return ret;
};

export class MultiplierTools {

    public static map_company_id(source_array, as_array?) {
        const m = {};
        source_array.forEach((c) => {
            const company_id = c[0];
            let e = m[company_id];
            if (!e) {
                e = as_array ? [] : c;
                m[company_id] = e;
            }
            if (as_array && Array.isArray(e)) {
                e.push(c);
            }
        });
        return m;
    }

    public static _get_each_sector(companies, date_decimal, multiplier_map, marked_data_map, fin_ann_map) {
        const results = [];
        allready_calc = {};

        const pots = {};
        companies.forEach( (c: IMCompany) => {
            let p: string[] = pots[c.sector];
            if (!p) {
                p = [];
                pots[c.sector] = p;
            }
            p.push(c.id);
            p = pots[c.sub_sector];
            if (!p) {
                p = [];
                pots[c.sub_sector] = p;
            }
            p.push(c.id);
        } );

        const keys = Object.keys(pots);
        keys.forEach( (s: string) => {
            const key = s;
            const company_id_array = pots[s];

            const pot_data = {
                price_book_companies: [],

                sales_0_companies: [],
                sales_1_companies: [],

                ebitda_0_companies: [],
                ebitda_1_companies: [],

                ebit_0_companies: [],
                ebit_1_companies: [],

                netprofit_0_companies: [],
                netprofit_1_companies: [],

                price_book: new Stats({bucket_precision: 10}),

                sales_0: new Stats({bucket_precision: 10}),
                sales_1: new Stats({bucket_precision: 10}),

                ebitda_0: new Stats({bucket_precision: 10}),
                ebitda_1: new Stats({bucket_precision: 10}),

                ebit_0: new Stats({bucket_precision: 10}),
                ebit_1: new Stats({bucket_precision: 10}),

                netprofit_0: new Stats({bucket_precision: 10}),
                netprofit_1: new Stats({bucket_precision: 10}),

            };
            const push_if_valid = (target, value, companies_array, company_id) => {
                if (Number.isFinite(value)) {
                    target.push(value);
                    companies_array.push(company_id);
                }
            };
            company_id_array.forEach( (company_id) => {
                const ret = for_company(company_id, date_decimal, multiplier_map, marked_data_map, fin_ann_map);
                if (ret) {
                    // pot_data.companies.push(company_id);
                    push_if_valid(pot_data.sales_0, ret.sales_0, pot_data.sales_0_companies, company_id);
                    push_if_valid(pot_data.sales_1, ret.sales_1, pot_data.sales_1_companies, company_id);

                    push_if_valid(pot_data.ebitda_0, ret.ebitda_0, pot_data.ebitda_0_companies, company_id);
                    push_if_valid(pot_data.ebitda_1, ret.ebitda_1, pot_data.ebitda_1_companies, company_id);

                    push_if_valid(pot_data.ebit_0, ret.ebit_0, pot_data.ebit_0_companies, company_id);
                    push_if_valid(pot_data.ebit_1, ret.ebit_1, pot_data.ebit_1_companies, company_id);

                    push_if_valid(pot_data.netprofit_0, ret.netprofit_0, pot_data.netprofit_0_companies, company_id);
                    push_if_valid(pot_data.netprofit_1, ret.netprofit_1, pot_data.netprofit_1_companies, company_id);

                    push_if_valid(pot_data.price_book, ret.price_book, pot_data.price_book_companies, company_id);

                }
            } );

            const pot_data_fields = "price_book|sales_0|sales_1|ebitda_0|ebitda_1|ebit_0|ebit_1|netprofit_0|netprofit_1";
            const pot_result = {
                key,
                date_decimal,
            };
            pot_data_fields.split("|").forEach( (f) => {
                pot_result[`${f}_sz`] = pot_data[f].length;
                pot_result[`${f}_min`] = pot_data[f].min;
                pot_result[`${f}_max`] = pot_data[f].max;

                pot_result[`${f}_median`] = Number(pot_data[f].median().toFixed(2));

                pot_result[`${f}_p05`] = Number(pot_data[f].percentile(5).toFixed(2));
                pot_result[`${f}_p25`] = Number(pot_data[f].percentile(25).toFixed(2));
                pot_result[`${f}_p75`] = Number(pot_data[f].percentile(75).toFixed(2));
                pot_result[`${f}_p95`] = Number(pot_data[f].percentile(95).toFixed(2));
            } );

            results.push(pot_result);

        } );

        return results;
    }

    public static get_each_sector(companies, company_multipliers: {[company_id: number]: CompanyMultipliers}) {
        const results = [];
        allready_calc = {};

        const pots = {};
        companies.forEach( (c: IMCompany) => {
            let p: string[] = pots[c.sector];
            if (!p) {
                p = [];
                pots[c.sector] = p;
            }
            p.push(c.id);
            p = pots[c.sub_sector];
            if (!p) {
                p = [];
                pots[c.sub_sector] = p;
            }
            p.push(c.id);
        } );

        const keys = Object.keys(pots);
        keys.forEach( (s: string) => {
            const key = s;
            const company_id_array = pots[s];

            const pot_data = {
                price_book_companies: [],

                sales_0_companies: [],
                sales_1_companies: [],

                ebitda_0_companies: [],
                ebitda_1_companies: [],

                ebit_0_companies: [],
                ebit_1_companies: [],

                netprofit_0_companies: [],
                netprofit_1_companies: [],

                price_book: new Stats({bucket_precision: 10}),

                sales_0: new Stats({bucket_precision: 10}),
                sales_1: new Stats({bucket_precision: 10}),

                ebitda_0: new Stats({bucket_precision: 10}),
                ebitda_1: new Stats({bucket_precision: 10}),

                ebit_0: new Stats({bucket_precision: 10}),
                ebit_1: new Stats({bucket_precision: 10}),

                netprofit_0: new Stats({bucket_precision: 10}),
                netprofit_1: new Stats({bucket_precision: 10}),

            };
            const push_if_valid = (target, value, companies_array, company_id) => {
                if (Number.isFinite(value)) {
                    target.push(value);
                    companies_array.push(company_id);
                }
            };
            company_id_array.forEach( (company_id) => {
                const ret = company_multipliers[company_id];
                if (ret) {
                    // pot_data.companies.push(company_id);
                    push_if_valid(pot_data.sales_0, ret.sales_0, pot_data.sales_0_companies, company_id);
                    push_if_valid(pot_data.sales_1, ret.sales_1, pot_data.sales_1_companies, company_id);

                    push_if_valid(pot_data.ebitda_0, ret.ebitda_0, pot_data.ebitda_0_companies, company_id);
                    push_if_valid(pot_data.ebitda_1, ret.ebitda_1, pot_data.ebitda_1_companies, company_id);

                    push_if_valid(pot_data.ebit_0, ret.ebit_0, pot_data.ebit_0_companies, company_id);
                    push_if_valid(pot_data.ebit_1, ret.ebit_1, pot_data.ebit_1_companies, company_id);

                    push_if_valid(pot_data.netprofit_0, ret.netprofit_0, pot_data.netprofit_0_companies, company_id);
                    push_if_valid(pot_data.netprofit_1, ret.netprofit_1, pot_data.netprofit_1_companies, company_id);

                    push_if_valid(pot_data.price_book, ret.price_book, pot_data.price_book_companies, company_id);

                }
            } );

            const pot_data_fields = "price_book|sales_0|sales_1|ebitda_0|ebitda_1|ebit_0|ebit_1|netprofit_0|netprofit_1";
            const pot_result = {
                key,
            };
            pot_data_fields.split("|").forEach( (f) => {
                pot_result[`${f}_sz`] = pot_data[f].length;
                pot_result[`${f}_min`] = pot_data[f].min;
                pot_result[`${f}_max`] = pot_data[f].max;

                pot_result[`${f}_median`] = Number(pot_data[f].median().toFixed(2));

                pot_result[`${f}_p05`] = Number(pot_data[f].percentile(5).toFixed(2));
                pot_result[`${f}_p25`] = Number(pot_data[f].percentile(25).toFixed(2));
                pot_result[`${f}_p75`] = Number(pot_data[f].percentile(75).toFixed(2));
                pot_result[`${f}_p95`] = Number(pot_data[f].percentile(95).toFixed(2));
            } );

            results.push(pot_result);

        } );

        return results;
    }
}
