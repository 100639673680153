import {IOptionEntry} from "../models/IOptionEntry";
import {_t} from "../tools/Translator";
import {ETranslation} from "./ETranslation";

export class CreditRunTimes {
    public static values: IOptionEntry[] = [
        {label: _t(ETranslation.years_number, "0 - 10"), value: "1,120"},
        {label: _t(ETranslation.years_number, "0 - 20"), value: "1,240"},
        {label: _t(ETranslation.years_number, "0 - 30"), value: "1,360"},
        {label: _t(ETranslation.years_number, "5 - 10"), value: "60,120"},
    ];
}
