import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {Sectors} from "../../../const/Sectors";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";

export class SectorParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.SectorParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.SectorParameter);
    }
    protected async getOptions() {
        return Sectors.values;
    }
}
