import {NonIdealState} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../../const/Globals";
import {Sectors} from "../../../const/Sectors";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {EValueName} from "../../../models/EValueName";
import {ICorporateSpread} from "../../../models/ICorporateSpread";
import {ISzSvgDataPoint} from "../../../models/ISzSvgDataPoint";
import {ISzSvgProperties, SzSvg} from "../../widgets/SzSvg";
import {BaseModule} from "../BaseModule";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {mk_duration} from "../../../tools/DateTools";


type LinearFunction={n: number; m:number;};

interface IFieldMap {
    field: string;
    month: number;
}
interface IDataPoints{
    dataPoint: ISzSvgDataPoint;
    numDataPoint: number;
    serie: number;
    x:number;
    y:number;
}

const xLabels = ["0","1", "2", "3", "5", "7", "10", "20", "30"];
const xTicks = [0,12, 24, 36, 60, 84, 120, 240, 360];
const xTicksNew = [12, 24, 36, 60, 84, 120, 240, 360];
const fieldMap: IFieldMap[] = [
    {field: "v_1_m", month: 1},
    {field: "v_3_m", month: 3},
    {field: "v_6_m", month: 6},
    {field: "v_1_j", month: 12},
    {field: "v_2_j", month: 24},
    {field: "v_3_j", month: 36},
    {field: "v_5_j", month: 60},
    {field: "v_7_j", month: 84},
    {field: "v_10_j", month: 120},
    {field: "v_20_j", month: 240},
    {field: "v_30_j", month: 360},
];

const durations = [
    mk_duration(0.5, 1.5),
    mk_duration(1.5, 2.5),
    mk_duration(2.5, 3.5),
    mk_duration(3.5, 6.5),
    mk_duration(6, 8),
    mk_duration(8, 12),
    mk_duration(15, 25),
    mk_duration(25, 30),
];
const slope_durations = [
    mk_duration(1, 2),
    mk_duration(2, 3),
    mk_duration(3, 5),
    mk_duration(5, 7),
    mk_duration(7, 10),
    mk_duration(10, 20),
    mk_duration(20, 30),
];
const get_pot_idx = (x: number /*qd diff mat_date*/) => {
    const p = durations.findIndex( (d) => x >= d.start && x < d.end );
    if(p === -1){
        return undefined;
    }else{
        return p;
    }
};
const get_slope_idx = (x: number /*qd diff mat_date*/, fnk_compare) => {
    const fnk = fnk_compare ? (d) => x >= d.start && x < d.end : (d) => x > d.start && x <= d.end;
    const p = slope_durations.findIndex( (d) => fnk(d));
    if(p === -1){
        return undefined;
    }else{
        return p;
    }
};

export class CorporateSpreadOld extends BaseModule<any> {

    private valueSelected: number = Number.NaN;
    private value: number = Number.NaN;

    private valueAll: number = Number.NaN;
    private sector: string = "";
    private regressionData: ISzSvgDataPoint[] = [];
    private regressionData_all: ISzSvgDataPoint[] = [];

    private m_data: LinearFunction[] = [];
    private m_data_all: LinearFunction[] = [];

    private max_y_all = 0;
    private max_y = 0;

    private stats = {};

    private old_mouse_move_x = {};

    constructor(props: any, context: any) {
        super(props, context);
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }


    protected renderContent() {
        this.old_mouse_move_x = {};
        if (!this.data) {
            return this.noData("C1");
        }
        if (!this.data.corporateSpread) {
            return this.noData("C2");
        }
        return (<div id={"idCorporateSpread"}>{this.renderData()}</div>);
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    private getSeries() {
        const ret = [];

        if (!this.data) {
            return ret;
        }
        if (!this.data.corporateSpread) {
            return ret;
        }

        const xy = (item, field) => ({x: field.month, y: item[field.field] / 100 });
        this.data.corporateSpread.forEach( (a) => {
            const d: ICorporateSpread = a;
            const renderData = [];
            fieldMap.forEach((field) => renderData.push(xy(d, field)));
            ret.push(renderData);
        } );
        return ret;
    }
    private renderData() {
        const dt = SessionStore.get(EParameters.RuntimeParameter);
        const range = dt.split(",");
        const start = parseInt(range[0], 10);
        const stop = parseInt(range[1], 10);
        const sector = SessionStore.get(EParameters.SectorParameter);
        this.sector = sector;

        const fieldMap_2: IFieldMap[] = (new Array(30)).fill(1);
        fieldMap_2.forEach( (i: IFieldMap, c) => {
            fieldMap_2[c] = {
                field: `v_${c + 1}_j`,
                month: (c + 1) * 12,
            };
        } );

        let sum = 0;
        let sum_2 = 0;
        let cc = 0;

        if (this.data && Array.isArray(this.data.corporateSpread)) {
            const corporateSpread = [];
            this.data.corporateSpread.forEach( (csObj, idx) => {
                let lastValue = 0;
                let ccMissingValues = [];
                corporateSpread.push(Object.assign({}, csObj));
                fieldMap_2.forEach( (i: IFieldMap) => {
                    if (csObj.hasOwnProperty(i.field) && ccMissingValues.length) {
                        const new_v = csObj[i.field];
                        const v_delta = (new_v - lastValue) / (ccMissingValues.length + 1);
                        ccMissingValues.forEach( (mv_key, count) => {
                            corporateSpread[idx][mv_key] = lastValue + (v_delta * (count + 1)) ;
                        });
                        ccMissingValues = [];
                    }
                    if (csObj.hasOwnProperty(i.field)) {
                        lastValue = csObj[i.field];
                        corporateSpread[idx][i.field] = lastValue;
                    } else {
                        ccMissingValues.push(i.field);
                    }
                });
            } );

            // console.error(corporateSpread);

            const idx_2 = corporateSpread.length >= 2 ? 1 : 0;
            fieldMap_2.forEach((field) => {
                const m = field.month;
                try {
                    if (m >= start && m <= stop) {
                        cc++;
                        sum += corporateSpread[0][field.field] === null ? NaN : corporateSpread[0][field.field];
                        sum_2 += corporateSpread[idx_2][field.field] === null ? NaN : corporateSpread[idx_2][field.field];
                    }
                } catch (exi) {
                    console.error(exi, this);
                }
            });
        }
        // console.error(`sum: ${sum}, cc: ${cc}, avg: ${sum / cc}, old: ${Math.trunc((sum / cc) * .1) / 10}`);
        this.valueSelected = sum / cc / 100; // Math.trunc((sum / cc) * .1) / 10;
        this.valueAll =  sum_2 / cc / 100; // Math.trunc((sum_2 / cc) * .1) / 10;

        // SessionStore.setGlobalVar(EValueName.credit_spreads_sector_label, this.getSelectedSector().label);
        // SessionStore.setGlobalVar(EValueName.credit_spreads_sector, this.valueSelected / 100);
        // SessionStore.setGlobalVar(EValueName.credit_spreads_avg, this.valueAll / 100);

        if (sector === "0000") {
            this.value = this.valueAll;
        } else {
            this.value = this.valueSelected;
        }
        SessionStore.setGlobalVar(EValueName.credit_spreads, this.value / 100);
        return (
            <div className={"sz-row"}>
                <div style={{marginBottom: 20, fontSize: "80%"}}>
                    <strong>{_t(ETranslation.credit_spread_term_stucture)}{Globals.superscript(1)} [% p.a.]</strong>
                    <br/>
                    {this.getSelectedSector()}
                </div>
                <div className={"sz-col-100"} style={{position: "relative"}}>
                    {this.renderSVG()}
                </div>
            </div>
        );
    }
    private getSelectedSector() {
        const sector = this.getParameterValue(EParameters.SectorParameter, "0");
        const ff = Sectors.values.find((item) => parseInt(item.value, 10) === parseInt(sector,10));
        const s = ff ? ff.label : _t(ETranslation.sector_all_avg);

        const rating: string[] = SessionStore.get(EParameters.RatingParameter);
        const r0 = rating[0];
        const r1 = rating[rating.length - 1];
        const r = r0 === r1 ? r1 : `${r0} ${_t(ETranslation.to)} ${r1}`;

        return `${s}, ${_t(ETranslation.rating)}: ${r}`;
    }
    private renderSVG() {
        if (!this.data) {
            return this.noData("A1");
        }
        if (!this.data.corporateSpread) {
            return this.noData("A2");
        }
        const series = this.getSeries();
        const seriesLegend = [this.getSelectedSector(), _t(ETranslation.sector_all_avg)];

        // console.error(this.sector);
        if (this.sector === "0000") {
            series.splice(1, 1);
            seriesLegend.splice(0, 1);
        }
        const selected_sector = this.sector === "0000" ? undefined : this.sector;
        const svgProps: ISzSvgProperties = {
            series,
            xLabels,
            xTicks,
            seriesLegend,
            override_colors: selected_sector ? [1, 0] : [0],
            height: "400px",
            width: "100%",
            yTickStep: .5,
            xAxisLabel: _t(ETranslation.years),
            caption: "[% p.a.]",
            xLabelsSkip: 3,
            legendWidth: 220,
            onCustomDrawings: (x_min: number, x_max: number, y_min: number, y_max: number, xFactor: number, yFactor: number) => this.onCustomDrawings(x_min, x_max, y_min, y_max, xFactor, yFactor),
        };
        return (<SzSvg {...svgProps} />);
    }


    private noData(error_pos: string) {
        return (<NonIdealState
            icon={"database"}
            title={`Keine Daten (${error_pos})`}
            description="Die Kombination aus Stichtag, Währung, Sector und Rating liefert keine Ergebnisse."
        />);
    }

    private onCustomDrawings(x_min: number, x_max: number, y_min: number, y_max: number, xFactor: number, yFactor: number) {
        const dt = SessionStore.get(EParameters.RuntimeParameter);
        const range = dt.split(",");
        const start = parseInt(range[0], 10);
        const stop = parseInt(range[1], 10);

        const value = isNaN(this.value) ? 0 : this.value;
        const x1 =  Math.trunc(xFactor * start) + 10;
        const x2 =  Math.trunc(xFactor * stop) + 10;
        const y = Math.trunc(yFactor * (value));
        const ym = Math.trunc(yFactor * y_max);

        let label = this.getSelectedSector();
        if (this.sector === "0000") {
            label = _t(ETranslation.sector_all_avg);
        }

        const vv = isNaN(this.value) ? ": Keine Marktdaten verfügbar" : Globals.formatter_percent(value / 100);

        return (
            <g>
                <defs>
                    <filter x="0" y="0" width="1" height="1" id="p_corp_spreat_sector_val">
                        <feFlood floodColor="rgba(255, 255, 255, .65)"/>
                        <feComposite in="SourceGraphic" operator="over" />
                    </filter>
                </defs>
                <line x1={x1} x2={x2} y1={ym - y} y2={ym - y} key={`${this.state.ch}_${x1}_${x2}_${y}_${value}`} className={"sz-chart-line sz-chart-line-20"}/>
                <circle cy={ym - y} cx={x1} r={3} className={`sz-chart-dot sz-chart-dot-20`} />
                <circle cy={ym - y} cx={x2} r={3} className={`sz-chart-dot sz-chart-dot-20`} />
                <text  filter="url(#p_corp_spreat_sector_val)" className={"sz-chart-line sz-chart-line-20"} x={x1 + 5} y={ym - y - 5} style={{fontSize: "80%"}}>{label} {vv}</text>;
            </g>
        );
    }
}
