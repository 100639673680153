import {NumberRange, RangeSlider} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";

const data_range = ParameterConfigs.configs[EParameters.TpNumEmployeesParameter].range;
export class TpNumEmployeesParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getWidthClass() {
        return "fixed-width-550";
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.TpNumEmployeesParameter];
    }

    protected async initialize() {
        const currentRange = SessionStore.get(EParameters.TpNumEmployeesParameter);
        return {
            currentRange: Array.isArray(currentRange) ? currentRange : [0, data_range.length - 1],
        };
    }

    protected onClosePopup() {
        this.fireChanges([{
            component: EParameters.TpNumEmployeesParameter,
            value: [this.state.currentRange],
        }]);
    }

    protected renderMenuContent(): any {
        return this.renderPopup();
    }
    private renderPopup() {
        const getLabel = (value: number) => {
            if(value === data_range.length - 1){
                return Globals.infinity;
            }
            return Globals.formatter(data_range[value],0,0);
        };
        return (
            <div>
                <div style={{margin: 20, backgroundColor: "#ffffff"}}>
                    <div style={{color: "#c1c1c1", textAlign: "left", fontSize: "80%", paddingBottom: 3, display: "flex", justifyContent: "space-between"}}>&nbsp;</div>
                    <div>
                        <RangeSlider
                            min={0}
                            max={data_range.length - 1}
                            stepSize={1}
                            value={this.state.currentRange}
                            onChange={ (range) => this.onRangeChange(range)}
                            onRelease={ (range) => this.onRangeReleased(range)}
                            labelRenderer={ (value: number) => getLabel(value) }
                            labelStepSize={1}
                        />
                    </div>
                </div>
                <div className={"sz-row"}>
                    {this.renderHelpText(PARAMETER_HELP(EParameters.TpNumEmployeesParameter), undefined, "100%")}
                </div>
            </div>
        );
    }
    private onRangeChange(range: NumberRange) {
        this.setState({currentRange: range});
    }

    private onRangeReleased(range: NumberRange) {
        //
    }
    public getLabel() {
        const range = this.state.currentRange;
        try{
            if(range[0] === range[1]){
                if(data_range[data_range.length - 1] === range[1]){
                    return Globals.infinity;
                }
                return Globals.formatter(data_range[range[1]],0,0);
            }else{
                const l = range[1] === data_range.length - 1 ? Globals.infinity : data_range[range[1]];
                return `${Globals.formatter(data_range[range[0]],0,0)} ... ${Globals.formatter(l,0,0)}`;
            }
        }catch(e){
            console.error(e);
        }
        return Globals.hyphen;
    }
}
