import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";

export class RatingParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.RatingParameter];
    }

    protected async getOptions() {
        return [
            {label: "AA", value: "AA"},
            {label: "AA-", value: "AA-"},
            {label: "A+", value: "A+"},
            {label: "A", value: "A"},
            {label: "A-", value: "A-"},
            {label: "BBB+", value: "BBB+"},
            {label: "BBB", value: "BBB"},
            {label: "BBB-", value: "BBB-"},
            {label: "BB+", value: "BB+"},
            {label: "BB", value: "BB"},
            {label: "BB-", value: "BB-"},
            {label: "B+", value: "B+"},
            {label: "B", value: "B"},
        ];
    }

    protected async getSelectedValue() {
        return SessionStore.get(EParameters.RatingParameter);
    }
}
