import * as React from "react";
import {CSSProperties, useEffect, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {CacheToolsAllParameters} from "../../../helpers/CacheTools";

import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {Bar} from "react-chartjs-2";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {Globals} from "../../../const/Globals";
import {DrawStateHook} from "../../../chart-plugin/DrawStateHook";
import {IRawBetaResponse} from "../../../models/chef/RawBeta";
import {SzSmallLoader} from "../../hooks/SzSmallLoader";
import {ChefCalls} from "../../../services/ChefCalls";
import {fromDateDecimal, fromStr} from "../../../tools/DateTools";

import {LineAnnotationOptions,} from "chartjs-plugin-annotation/types/options";
import * as ChartJsAnnotation from "chartjs-plugin-annotation";

import * as Statistics from "../../../helpers/Statistics";
import {LabelOptions} from "chartjs-plugin-annotation/types/label";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {EPeriodToFrequencyFull} from "../../../const/PeriodConstants";

const raw_beta_7d_cache= CacheToolsAllParameters("raw_beta_7d_cache");

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
    ChartJsAnnotation,
);


async function loadData(company: ICompanyBase, data: any){
    const period = SessionStore.get(EParameters.PeriodsParameter);
    const raw_beta_parameters_ext = {...data.raw_beta_parameters_ext};
    raw_beta_parameters_ext.companies = [company.id];
    raw_beta_parameters_ext.selected_period = period;
    const db = new ChefCalls();
    const raw_betas_ext = await db.raw_beta_7days(raw_beta_parameters_ext, raw_beta_7d_cache);
    const dates = Object.keys(raw_betas_ext);
    const a = [];
    dates.forEach((date)=>{
        const companies = raw_betas_ext[date];
        const raw_beta: IRawBetaResponse = companies[company.id];
        const regression = raw_beta.periods[period];
        const the_date = fromStr(date);
        if(the_date.weekday > 5){
            return;
        }
        const date_decimal = the_date.asDateDecimal();
        a.push(
            {
                date,
                date_decimal,
                regression
            }
        );
    });
    a.sort((v1,v2)=> v2.date_decimal - v1.date_decimal);
    // console.error(raw_betas_ext);
    // console.error(a);
    return a.slice(0, 5).reverse();
}

const styleH: CSSProperties = {
    width: "100%",
    color: "#182026",
    fontSize: 14,
    fontWeight: "bold",
    display: "block",
    paddingBottom: 5,
    marginTop: 14,
    boxShadow: "inset 0px -1px 0px 0px #cecece",
};
export interface ITimeStability5DaysProps {
    company: ICompanyBase;
    data: any;
}

const fmt = (v)=>Globals.formatter(v, 2, 2, true);

export function TimeStability5Days(props: ITimeStability5DaysProps) {
    const [timeSeries, setTimeSeries] = useState(undefined);
    const company: ICompanyBase = props.company;
    const data = props.data;

    useEffect(() => {
        (async ()=>{
            const r = await loadData(company, data);
            setTimeSeries(r);
        })();
    }, []);

    if(!Array.isArray(timeSeries)){
        return (
            <div>
                <div style={styleH}>Qualität: Zeitstabilität Beta-Faktor</div>
                <div style={{aspectRatio: "1/1"}}>
                    <SzSmallLoader title={""} />
                </div>
            </div>
        );
    }
    const period = SessionStore.get(EParameters.PeriodsParameter);
    let maxSlope = Number.MIN_VALUE;
    const chartData = timeSeries.map((i)=>{
        maxSlope = Math.max(i?.regression?.Slope, maxSlope);
        return i?.regression?.Slope;
    });
    const avg7 = Statistics.average(chartData.slice(0, 7));
    // const avg30 = Statistics.average(chartData.slice(0, 30));
    const o = new SzChartOptions();
    // o.setXTickCallback(()=> "");
    // o.setXMinMax({max: maxSlope + (maxSlope * .2) })
    const options = {
        ...o.options,
    };
    options.scales.x.ticks.font = {
        size: 10,
        lineHeight: 1,
    };
    options.indexAxis= "x";
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
    };
    options.plugins.tooltip = {
        enabled: true,
        mode: "point",
        intersect: true,
        backgroundColor: "rgba(255,255,255,0.75)",
        titleColor: "rgba(219,55,55,1)",
        bodyColor: "rgba(219,55,55,1)",
        borderColor: "rgba(82,82,82,0.75)",
        borderWidth: 1,
        caretSize: 0,
        cornerRadius: 2,
        callbacks: {
            label: (item)=> {return `${_t(ETranslation.beta_factor)} ${fmt(item?.raw)}`;},
            title: (items)=> {
                if(Array.isArray(items) && items[0]){
                    const e = timeSeries[items[0].dataIndex];
                    return e.date;
                }
                return undefined;
            },
        },
    };
    const bars = {
        label: "Beta-Faktoren",
        axis: 'x',
        backgroundColor: (item)=>{
            if(item.dataIndex === timeSeries.length - 1){
                return "rgba(19,124,189,0.75)";
            }
            return "rgba(0, 0, 0,0.15)";
        },
        hoverBackgroundColor : '#D9822B',
        data: chartData,
    };

    const labels = [];
    timeSeries.slice(0).forEach((i, idx, array)=>{
        labels.push([
            fromDateDecimal(i.date_decimal).toFormat("cccc", {locale: navigator.language}),
            fromDateDecimal(i.date_decimal).toFormat("dd.LL.yy", {locale: navigator.language})
        ]);
        // fromDateDecimal(i.date_decimal).toFormat("dd.LL.yy");
    });
    // labels.reverse();
    const chart_data = {
        labels,
        datasets: [
            bars,
        ],
    };
    const mkLabel = (content) : LabelOptions =>({
        display: true,
        color: "rgb(24, 32, 38)",
        backgroundColor: "#ffffff00",
        borderColor: "#ffffff00",
        borderRadius: 0,
        borderWidth: 0,
        font: {
            size: 10,
        } as any,
        position: "start",
        padding: 0,
        // xAdjust: 35,
        yAdjust: -10,
        content,
    });
    const tick_7 = {
        type: "point",
        pointStyle: "line",
        borderColor: "#485d63",
        rotation: 0,
        radius: 2,
        borderWidth: 1,
        yValue: avg7,
        xValue: labels.length - 1,
        // xAdjust: (ctx)=> Math.trunc(ctx.chart.scales.y.right - ctx.chart.scales.x.getPixelForValue(0)) - 3,
    };
    const avg_7: LineAnnotationOptions = {
        borderColor: "rgba(19,124,189,0.75)",
        borderDash: [6, 6],
        borderWidth: 1,
        xMax: 0,
        xMin: labels.length - 1,
        xScaleID: "x",
        yMax: avg7,
        yMin: avg7,
        yScaleID: "y",
        label: mkLabel(`${Globals.average} 5 ${_t(ETranslation.day)} ${Globals.formatter(avg7, 2, 2, true)}`),
    };
    const annotation = {
        clip: false,
        annotations : {
            tick_7,
            avg_7,
        }
    };
    o.enablePlugin(EChartPlugins.ANNOTATION, annotation);
    return (
        <div>
            <div style={styleH}>{_t(ETranslation.stability_weekday)}</div>
            <div style={{aspectRatio: "5/3"}}>
                <Bar data={chart_data as any} options={options} />
            </div>
            <div className={"sz-hint"} style={{paddingLeft: 10}}>
                {EPeriodToFrequencyFull[period]}; {_t(ETranslation.weekday_adjustment)}
            </div>
        </div>
    );
}
