import {ISzSvgDataPoint} from "../../../models/ISzSvgDataPoint";

export class ChartHelper {
    public static getDots(points: ISzSvgDataPoint[], xFactor: number, yFactor: number, offW: number, offH: number) {
        const dots = [];
        points.forEach((e) => {
            const x = offW - (Math.trunc(e.x * xFactor));
            const y = offH - Math.trunc(e.y * yFactor);

            if (isNaN(y) || isNaN(x) ) {
                return;
            }

            dots.push({x, y, value: `[${e.x},${e.y}]`});
        });
        return dots;
    }
    public static getLimits(points: ISzSvgDataPoint[], width: number, height: number) {
        let x_min = 0;
        let x_max = 0;
        let y_min = 0;
        let y_max = 0;

        points.forEach( (p) => {
            if (p.x > x_max) {
                x_max = p.x;
            }
            if (p.x < x_min) {
                x_min = p.x;
            }
            if (p.y > y_max) {
                y_max = p.y;
            }
            if (p.y < y_min) {
                y_min = p.y;
            }
        });

        const deltaY = y_min < 0 ? y_max - y_min : y_max;
        const deltaX = x_min < 0 ? x_max - x_min : x_max;

        const yFactor =  (height - 20) / deltaY;
        const xFactor =  (width - 20) / deltaX;
        return {x_min, x_max, y_min, y_max, deltaX, deltaY, xFactor, yFactor};
    }
    public static getMinMax(points: ISzSvgDataPoint[]) {
        let x_min = Number.MAX_VALUE;
        let x_max = Number.MIN_VALUE;
        let y_min = Number.MAX_VALUE;
        let y_max = Number.MIN_VALUE;

        points.forEach( (p) => {
            if (p.x > x_max) {
                x_max = p.x;
            }
            if (p.x < x_min) {
                x_min = p.x;
            }
            if (p.y > y_max) {
                y_max = p.y;
            }
            if (p.y < y_min) {
                y_min = p.y;
            }
        });
        return {x_min, x_max, y_min, y_max};
    }
}
