import {Menu} from "@blueprintjs/core";
import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

export class RuntimeBaseInterestParameter extends SingleSelectParameter {
    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.RuntimeBaseInterestParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.RuntimeBaseInterestParameter);
    }
    protected async getOptions() {
        return [
            {label: "unendlich", value: "0"},
            {label: _t(ETranslation.year_number, 1), value: "1"},
            {label: _t(ETranslation.years_number, 2), value: "2"},
            {label: _t(ETranslation.years_number, 3), value: "3"},
            {label: _t(ETranslation.years_number, 4), value: "4"},
            {label: _t(ETranslation.years_number, 5), value: "5"},
            {label: _t(ETranslation.years_number, 6), value: "6"},
            {label: _t(ETranslation.years_number, 7), value: "7"},
            {label: _t(ETranslation.years_number, 8), value: "8"},
            {label: _t(ETranslation.years_number, 9), value: "9"},
            {label: _t(ETranslation.years_number, 10), value: "10"},
            {label: _t(ETranslation.years_number, 12), value: "12"},
            {label: _t(ETranslation.years_number, 15), value: "15"},
            {label: _t(ETranslation.years_number, 20), value: "20"},
            {label: _t(ETranslation.years_number, 25), value: "25"},
            {label: _t(ETranslation.years_number, 30), value: "30"},
        ];
    }

    protected renderHint() {
        return (
            <Menu style={{marginTop: 12}}>
                <li>
                    <p className={"bp3-text-small bp3-text-muted"} style={{maxWidth: 320}}>Die Laufzeit dient der Anpassung des barwertäquivalenten Zinssatzes an den Bewertungskontext.</p>
                    <p className={"bp3-text-small bp3-text-muted"} style={{maxWidth: 320}}>„Going concern“-Bewertungen erfordern die Annahme einer unendlichen Laufzeit.</p>
                    <p className={"bp3-text-small bp3-text-muted"} style={{maxWidth: 320}}>Die Bewertung von Einzelwirtschaftsgütern, z.B. Markenbewertungen nach IDW S5, erfordern eine Anpassung der Zinssatzermittlung an die Nutzungsdauer.</p>
                </li>
            </Menu>
        );
    }
}
