import {Irr} from "./Irr";

interface IFiniteCalc {
    barwert: number;
    barwertaquivalentezins: number;
    cashFlows: number[];
}
export class BaseInterestTool {
    public static calc(growthRate: string, meancurve: number[], start: number, stop: number): number {
        // Parameterfehler
        if (!Number.isFinite(start) || !Number.isFinite(stop)) {
            return NaN;
        }
        // console.error("meancurve", meancurve);
        const meancurve_fixed = meancurve.map((d) => d / 100);
        this.linearInterpaltion( meancurve_fixed );
        const isPeriodTypeInfinity = stop === 31 ? true : false;
        if (stop === 31) {
            stop = 30;
        }
        if (isPeriodTypeInfinity) {
            const resultFinite: IFiniteCalc = this.calcFinity(growthRate, meancurve_fixed, start, 30);
            return this.calcInfinity(growthRate, meancurve_fixed, resultFinite.barwert, resultFinite.cashFlows);
        } else {
            const resultFinite: IFiniteCalc = this.calcFinity(growthRate, meancurve_fixed, start, stop);
            return resultFinite.barwertaquivalentezins;
        }
    }
    private static calcFinity(growthRate: string, meancurve: number[], start: number, stop: number): IFiniteCalc {
        const wachstumsrate = Number(growthRate);
        const colLen = Math.floor( stop );
        const lastRate = meancurve[meancurve.length - 1];

        // jahren
        const perioden = (new Array(colLen)).fill(0).map((l, index) => index + 1);
        // Zinssätze
        const rates = perioden.map((l, index) => index < meancurve.length ? meancurve[index] : lastRate);
        // casflow-wachstum
        // const cashFlows = perioden.map( (_laufzeit) => Math.pow(1 + wachstumsrate, _laufzeit) );
        const cashFlows: number[] = (new Array(colLen)).fill(0);
        // barwert zum vergleich
        const barWerts = (new Array(colLen)).fill(0);

        let barWert = 0;
        if (start === 0) {
            // TODO
            barWert += 0;
        }
        for (let i = Math.max(0, start - 1); i < stop; i++) {
            const rate = rates[i];
            if (!Number.isFinite(rate)) {
                continue;
            }
            const periode = i + 1;
            cashFlows[i] = Math.pow(1 + wachstumsrate, periode);
            const cashFlow = cashFlows[i];
            barWerts[i] = cashFlow * Math.pow( 1 + rate, -periode );
            barWert += cashFlow * Math.pow( 1 + rate, -periode );
        }

        // console.warn("calcNeu", "barWert", barWert, "cashFlows", cashFlows);

        let irr = 0;
        let irrDown = 0;
        try {
            const guess = 0; // 0.8*(colLen<this.meancurve.length ? this.meancurve[colLen] : lastRate)
            irrDown = Irr.calc([-barWert, ...cashFlows], guess);
        } catch (e) {
            console.error(e);
        }

        irr = irrDown;

        // console.error("lastRate", lastRate, "wachstumsrate + 1/barWert", wachstumsrate + 1 / barWert, "irr", irr);

        // keine Laufzeit-Brüche mehr
        /*
        const rest = Math.floor((end - Math.floor( end )) * 10) / 10;
        if (rest > 0.09) {
            const guess = irr;
            // console.error("rest", rest);
            const periode = Math.ceil( end );
            const rate = periode - 1 < meancurve.length ? meancurve[periode - 1] : lastRate;
            const cashFlow = cashFlows[cashFlows.length - 1] * (1 + wachstumsrate);
            cashFlows[cashFlows.length] = cashFlow;
            barWert += cashFlow * Math.pow( 1 + rate, -periode );
            const irrUp = Irr.calc([-barWert, ...cashFlows], guess);
            irr = irrDown + rest * (irrUp - irrDown);
        }
        */
        // Ergebnis check
        let barWertCheck = 0;
        for (let i = 0; i < cashFlows.length; i++) {
            const periode = i + 1;
            const cashFlow = cashFlows[i];
            barWertCheck += cashFlow * Math.pow( 1 + irr, -periode );
        }
        // console.error("calcFinity", "irr", irr, "barWert", barWert, "barWertCheck", barWertCheck, "diff", Number(Math.abs(barWert - barWertCheck)).toFixed(10), "barWerts", barWerts, "meancurve", meancurve.map((d) => Number(100 * d).toFixed(3)));
        // Barwertäquivalenter Zinssatz
        const barwertaquivalentezins: number = Number(Number(irr).toFixed(14));
        return {
            barwertaquivalentezins,
            barwert: barWert,
            cashFlows,
        } as IFiniteCalc;
    }
    private static calcInfinity(growthRate: string, meancurve: number[], barWert30: number, cashFlows: number[]): number {
        const colLen = 31;
        const perioden = (new Array(colLen)).fill(0).map((laufzeit, index) => index + 1);
        const rates = meancurve;
        // const wachstumsfaktor = 1;
        const wachstumsrate = Number(growthRate);
        const lastRate = rates[rates.length - 1];

        let barWert = +barWert30;
        const guess = 0; // 0.8 * lastRate; // 0
        let barwertaquivalentezins: number = NaN;
        // console.error("zins", rates.map((ra) => ra * 100));
        const bedingung = wachstumsrate < lastRate;
        if (false) { // bedingung
            const ewigeRente = 1 / (lastRate - wachstumsrate);
            // cashFlows[30] += ewigeRente;
            // barWert += cashFlows[30] * Math.pow( 1 + lastRate, -31 );
            const nr = /*(1 / wachstumsfaktor) * */ ewigeRente * Math.pow( 1 + lastRate, -31 ) * Math.pow( 1 + wachstumsrate, 31 );
            barWert += nr;
            const irr = Irr.calc([-barWert, ...cashFlows], 0);
            console.error("ewige rente lastRate", lastRate, "wachstumsrate + 1/barWert", wachstumsrate + 1 / barWert, "irr", irr, "barwert", barWert);
            barwertaquivalentezins = Number.isFinite(irr) && irr > 0 ? irr : wachstumsrate + 1 / barWert;
        } else {
            const count = 1000;
            cashFlows.length = count;
            // barwert_temp.length = count;
            for (let i = 30; i < count; i++) {
                const rate = lastRate;
                const periode = i + 1;
                cashFlows[i] = (1 + wachstumsrate) * cashFlows[i - 1];
                const cashFlow = cashFlows[i];
                // barwert_temp[i] = (1 / wachstumsfaktor) * cashFlow * Math.pow( 1 + rate, -periode );
                barWert += /*(1 / wachstumsfaktor) * */ cashFlow * Math.pow( 1 + rate, -periode );
            }
            const irr = Irr.calc([-barWert, ...cashFlows], 0);
            // Ergebnis check
            let barWertCheck = 0;
            for (let i = 0; i < cashFlows.length; i++) {
                const periode = i + 1;
                const cashFlow = cashFlows[i];
                barWertCheck += cashFlow * Math.pow( 1 + irr, -periode );
            }
            // console.error("1000 jahre lastRate", lastRate, "wachstumsrate + 1/barWert", wachstumsrate + 1 / barWert, "irr", irr, "barwert", barWert, "barWertCheck", barWertCheck);
            barwertaquivalentezins = irr;
        }
        return barwertaquivalentezins;
    }
    // veraltet
    // TODO: remove
    private static calcInfinityOLD(growthRate: string, meancurve: number[], start: number): number {
        const colLen = 31;
        const perioden = (new Array(colLen)).fill(0).map((laufzeit, index) => index + 1);
        const rates = meancurve;
        // const wachstumsfaktor = 1;
        const wachstumsrate = Number(growthRate);
        const lastRate = rates[rates.length - 1];
        const cashFlows = perioden.map( (laufzeit) => /*wachstumsfaktor * */ Math.pow(1 + wachstumsrate, laufzeit) );

        // const barwert_temp = new Array(colLen); // zum testen
        let barWert30 = 0; // Barwert der ersten 30 Jahre
        for (let i = start; i < 30; i++) {
            const rate = rates[i];
            if (!Number.isFinite(rate)) {
                continue;
            }
            const periode = i + 1;
            const cashFlow = cashFlows[i];
            barWert30 += cashFlow * Math.pow( 1 + rate, -periode );
            // barwert_temp[i] = cashFlow * Math.pow( 1 + rate, -periode );
        }

        let barWert = +barWert30;
        const guess = 0.8 * lastRate; // 0
        let barwertaquivalentezins: number = NaN;
        // console.error("zins", rates.map((ra) => ra * 100));
        const bedingung = wachstumsrate < lastRate;
        if (false) { // bedingung
            const ewigeRente = 1 / (lastRate - wachstumsrate);
            // cashFlows[30] += ewigeRente;
            // barWert += cashFlows[30] * Math.pow( 1 + lastRate, -31 );
            const nr = /*(1 / wachstumsfaktor) * */ ewigeRente * Math.pow( 1 + lastRate, -31 ) * Math.pow( 1 + wachstumsrate, 31 );
            barWert += nr;
            const irr = Irr.calc([-barWert, ...cashFlows], 0);
            // console.error("ewige rente lastRate", lastRate, "wachstumsrate + 1/barWert", wachstumsrate + 1 / barWert, "irr", irr, "barwert", barWert);
            barwertaquivalentezins = Number.isFinite(irr) && irr > 0 ? irr : wachstumsrate + 1 / barWert;
        } else {
            const count = 1000;
            cashFlows.length = count;
            // barwert_temp.length = count;
            for (let i = 30; i < count; i++) {
                const rate = lastRate;
                const periode = i + 1;
                cashFlows[i] = (1 + wachstumsrate) * cashFlows[i - 1];
                const cashFlow = cashFlows[i];
                // barwert_temp[i] = (1 / wachstumsfaktor) * cashFlow * Math.pow( 1 + rate, -periode );
                barWert += /*(1 / wachstumsfaktor) * */ cashFlow * Math.pow( 1 + rate, -periode );
            }
            const irr = Irr.calc([-barWert, ...cashFlows], 0);
            // console.error("1000 jahre lastRate", lastRate, "wachstumsrate + 1/barWert", wachstumsrate + 1 / barWert, "irr", irr, "barwert", barWert);
            barwertaquivalentezins = irr;
        }
        return barwertaquivalentezins;
    }
    // lineare Interpolation für Zinssätze
    // speziell für Zinssätze
    private static linearInterpaltion(curve: number[]) {
        if (curve && curve.length && curve.length > 2) {
            let first = -1;
            let last = -1;
            for (let i = 0; i < curve.length; i++) {
                if (first === -1 && Number.isFinite(curve[i])) {
                    first = i;
                }
                if (Number.isFinite(curve[i])) {
                    last = i;
                }
            }
            // fehlende Werte am Anfang der Array
            if (first > 0) {
                for (let i = 0; i < first + 1; i++) {
                    curve[i] = curve[first];
                }
            }
            // fehlende Werte am Ende der Array
            if (last < curve.length - 1 && last !== -1) {
                for (let i = last + 1; i < curve.length; i++) {
                    curve[i] = curve[last];
                }
            }
            // fehlende Werte innerhalb Range
            // lineare Interpolation
            if (first !== -1 && last !== -1) {
                for (let i = 1; i < curve.length - 1; i++) {
                    if (!Number.isFinite(curve[i])) {
                        let right = 0;
                        for (let j = i + 1; j < curve.length; j++) {
                            if (Number.isFinite(curve[j])) {
                                right = j;
                                break;
                            }
                        }
                        // interpolation
                        const x0 = i - 1;
                        const x1 = right;
                        const y0 = curve[x0];
                        const y1 = curve[x1];
                        const m = (y1 - y0) / (x1 - x0);
                        for (let x = i; x < right; x++) {
                            curve[x] = y0 + m * (x - x0);
                        }
                    }
                }
            }
        }
    }
}
