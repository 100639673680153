import * as React from 'react';
import {TTreeNode} from "../../CostOfCapitalTree";
import {SessionStore} from "../../../../const/SessionStore";
import {EParameters} from "../../../../models/EParameters";

interface ITreeNode {
    treeData: TTreeNode[];
}
export const Tree = (props : ITreeNode) => {
    const {treeData} = props;
    const Risk = SessionStore.get(EParameters.CountryRiskUsageParameter);
    const sizePremium = SessionStore.get(EParameters.SizePremium);
    const TaxShield = SessionStore.get(EParameters.TaxShieldTargetCompanyRiskEffect); // unsicher | sicher
    const DebtBeta = SessionStore.get(EParameters.DebtBetaCalcParameter); // 0|1|2
    const treeRendering = (data : TTreeNode[]) => {
        // 0 notApplied || 1 Applied
        return (
            <>
                <div className={`node_container`}>
                    {
                        data.map((item) =>
                            <div className={item.id} style={{}}>
                                <div style={{marginRight:16, visibility: ((item.id === "l3_l_2"  && Risk === "0") || (item.id === "l3_l_3"  && sizePremium === 0) || (item.id ==="l3_r_3" && Risk === "0") || (item.id ==="l5_l_2" && TaxShield==="unsicher") || (item.id ==="l5_l_3" && DebtBeta==="0")) ? "hidden" : "visible"}}>
                                    <p className={`text${item.id}`}
                                       style={{fontSize:10,border:"1px solid black",borderRadius:5, width: "6.7vw", height: 50, display: "flex", alignItems: "center", justifyContent: "center",textAlign:"center"}}>
                                        {item.text} <br/> {item.value}
                                    </p>
                                </div>
                                {
                                    item.children && item.children.length ? treeRendering(item.children) : ''
                                }
                            </div>
                        )
                    }
                </div>
            </>
        )
    }
    return (
        <div className={"tree"}>
            <div className="node">
                {
                    treeData.map((item: TTreeNode) =>
                        <div className={"WACC1"}>
                            <div className="root">
                                <p style={{fontSize:10,border:"1px solid black",borderRadius:5, minWidth: "5.8vw", height: 50, display: "flex", alignItems: "center", justifyContent: "center",textAlign:"center"}}>
                                {item.text} <br/> {item.value}
                                </p>
                            </div>
                            {
                                item.children && item.children.length ? treeRendering(item.children) : ''
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
};
