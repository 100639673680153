import {Button, NonIdealState} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {SzSmallLoader} from "../hooks/SzSmallLoader";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {ChefCalls} from "../../services/ChefCalls";
import {IconNames} from "@blueprintjs/icons";
import {Sectors} from "../../const/Sectors";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

const BASE_DIR = Globals.BASE_DIR;

type SzKaySearchCompany = {
    name: string;
    ticker: string;
    country: string;
};
type SzCompany = {
    id: number;
    name: string;
    security_id: string;
    sector_id: number;
    industry_id: number;
};
export interface IAskKaySearch {
    searchData: string;
    isLoading: boolean;

    sz_companies: SzCompany[];
    kay_companies: SzKaySearchCompany[];
}

export class AskKaySearch extends React.Component<any, IAskKaySearch> {
    private readonly eventAskKaySearch: IEventHandler;
    constructor(props: any, context: IAskKaySearch) {
        super(props, context);
        this.state = {
            searchData: props.searchData,
            isLoading: true,
            kay_companies: [],
            sz_companies: [],
        };
        this.eventAskKaySearch = EventBus.subscribe<string>("askKay::business_activity",(searchData)=> this.setState({searchData,isLoading: true}));
    }

    public componentWillUnmount() {
        EventBus.unsubscribe(this.eventAskKaySearch);
    }

    public render() {
        if (this.state.isLoading) {
            this.askKay();
            return this.renderLoading();
        }
        const style: CSSProperties = {
            width: "100%",
            height: "100%",
        };
        return(
            <div className={"sz-relative"} style={style}>
                {this.state.isLoading ? this.renderLoading() : this.renderContent()}
            </div>
        );
    }
    private renderLoading() {
        return (
            <div style={{aspectRatio: "1/1"}}>
                <SzSmallLoader title={""} sub_title={_t(ETranslation.smartsearch_spinner)} />
            </div>
        );
    }
    private renderEmpty(){
        return (
            <div style={{width: "100%", marginTop: 50}}>
                <NonIdealState title={"Smart-Search"} icon={IconNames.ISSUE} description={_t(ETranslation.smartsearch_no_result)} />
            </div>
        );
    }
    private renderContent() {
        const {sz_companies, kay_companies} = this.state;
        if(sz_companies.length === 0 || kay_companies.length === 0){
            return this.renderEmpty();
        }
        const styleH: CSSProperties = {
            width: "100%",
            color: "#182026",
            fontSize: 14,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            paddingLeft: 6,
            height: 31,
            boxShadow: "inset 0px -1px 0px 0px #cecece",
        };
        const renderHit =(c: SzCompany)=>{
            return (
                <tr>
                    <td>{c.name}</td>
                    <td>{Sectors.sectors_map[c.sector_id]}</td>
                    <td>{Sectors.sectors_map[c.industry_id]}</td>
                </tr>
            );
        };
        return (
            <div style={{borderLeft: "1px white solid"}}>
                <table className={"sz-table bp3-html-table bp3-html-table-condensed bp3-html-table-striped sz-small-padding"} style={{width: "100%"}}>
                    <thead>
                        <tr style={{backgroundColor: "rgb(240, 240, 240)"}}>
                            <th style={{textAlign: "left"}}>Smart Search - Peers</th>
                            <th style={{textAlign: "left"}}>{_t(ETranslation.sector)}</th>
                            <th style={{textAlign: "left"}}>{_t(ETranslation.subsector)}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {sz_companies.map((h)=>renderHit(h))}
                    </tbody>
                </table>
                <div style={{display: "flex", justifyContent: "flex-end", marginTop: 16, paddingRight: 10}}>
                    <Button text={_t(ETranslation.reject)} onClick={()=> this.submitSelection([])}/>&nbsp;
                    <Button text={_t(ETranslation.apply)} intent={"primary"} onClick={()=> this.submitSelection(sz_companies)}/>
                </div>
            </div>
        );
    }

    private askKay() {
        (async ()=>{
            const chef = new ChefCalls();
            const newState = {
                sz_companies: [],
                kay_companies: [],
                isLoading: false,
            };
            try {
                const  ret = await chef.askKaySearchCompanies(this.state.searchData);
                if(ret && ret.kay_companies && ret.sz_companies){
                    newState.kay_companies = ret.kay_companies;
                    newState.sz_companies = ret.sz_companies;
                }else{
                    console.error(ret);
                }
            }catch (ex) {
                console.error(ex);
            }
            EventBus.emit("askKay::available", "");
            this.setState(newState);
        })();
    }

    private submitSelection(sz_companies: SzCompany[]) {
        EventBus.emit("askKay::business_activity_result", sz_companies);
    }
}
