import * as React from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EDataType} from "../../../models/EDataType";
import {EParameters} from "../../../models/EParameters";
import {EValueName} from "../../../models/EValueName";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {NumericParameter} from "../NumericParameter";

export class CountryRiskPremiumParameter extends NumericParameter {

    constructor(props: any, context: any) {
        super(props, context);
        this.dataType = EDataType.percent;
    }

    protected getValue() {
       return parseFloat(SessionStore.get(EParameters.CountryRiskPremiumParameter));
    }

    protected setValue(v: number) {
        SessionStore.setGlobalVar(EValueName.country_risk_premium, v);
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CountryRiskPremiumParameter];
    }
}
