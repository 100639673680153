import {Globals} from "../../const/Globals";
import {ImfDataView} from "../../const/ImfDataView";
import {EParameters} from "../../models/EParameters";
import {ICountry} from "../../models/ICountry";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {BaseDataProvider} from "./BaseDataProvider";
import {fromStr} from "../../tools/DateTools";
import {SessionStore} from "../../const/SessionStore";

export class MacroDataProvider extends BaseDataProvider {
    private country_usa: ICountry;
    private country_deu: ICountry;
    protected initProvider() {
        super.className = "MacroDataProvider";
        this.country_deu = Globals.countries.find( (i) => "DEU" === i.iso_3);
        this.country_usa = Globals.countries.find( (i) => "USA" === i.iso_3);
        this.parameterMapping = [
            EParameters.DateParameter,
            EParameters.FixedCountriesParameter,
        ];
    }

    protected update() {
        (async () => {
            try {
                const keys = [];
                ImfDataView.values.forEach( (i) => keys.push(i.value) );
                const year = fromStr(SessionStore.get(EParameters.DateParameter)).year;
                const dates = [];
                const years = [];
                for (let i = year - 5; i < year; i++) {
                    years.push(i);
                    for (let m = 0; m < 12; m++) {
                        const d = fromStr(`1.${m + 1}.${i}`, "d.L.yyyy").endOf("month").toFormat("yyyyLLdd");
                        const dd = parseInt(d, 10);
                        dates.push(dd);
                    }
                }
                const country: ICountry = Globals.countries.find( (i) => this.getParameter(EParameters.FixedCountriesParameter, 0) === i.iso_3);
                const countries = country.currency_iso_3 !== "EUR" ? ["USA"].concat([country.iso_3]) : ["USA"];
                const db = new PublicDbCalls();
                const rTaxRates = await db.getCountryTaxRates([country.id], true);
                const rXch = await db.getCurrencyRate([
                    dates,
                    countries,
                ]);
                const rImf = await db.getImfData([
                    keys,
                    [country.id, this.country_deu.id, this.country_usa.id],
                    years,
                ], true);
                const data = {
                    years,
                    id_deu: this.country_deu.id,
                    id_usa: this.country_usa.id,
                    taxRates: Globals.save_result(rTaxRates),
                    xch: Globals.save_result(rXch),
                    imf: Globals.save_result(rImf),
                };
                // IImfData
                // ITaxRate
                // IXchRate
                // console.error(data);
                this.afterUpdate(data);
            } catch (exi) {
                console.error(exi);
            }

        })();
    }
}
