import { IUser } from "./IUser";
import { EModuleNames } from "./EModuleNames";
import { Multiples } from "../modules";
import { BetaFactor } from "../modules/groups/BetaFactor";
import { CreditSpreads } from "../modules/groups/CreditSpreads";
import { Benchmarking } from "../modules/groups/Benchmarking";
import { CostOfCapital } from "../modules/groups/CostOfCapital";
import { TransferPricing } from "../modules/groups/TransferPricing";
import { TestModModule } from "../modules/groups/TestModModul";
import { SectorsModule } from "../modules/groups/SectorsModule";
import { CreditSpreadsSector } from "../modules/groups/CreditSpreadsSector";
import {ETranslation} from "../const/ETranslation";

export enum EProjectUserRole {
    unset = 0,
    owner = 1,
    editor = 2,
    reader = 3,

    deleted = 100,
}
export enum EProjectType {
    valuation = 1,
    transfer_pricing = 2,
    credit_spread = 3,

    vp_beta = 11,
    vp_multiples = 12,
    vp_benchmarking = 13,

    cs_credit_spreads_sector = 31,
}
export const ProjectTypeTranslation = {
    1: ETranslation.template_name_valuation_pro,
    2: ETranslation.template_name_transfer_pricing,
    3: ETranslation.template_name_credit_spreads,

    11: ETranslation.template_name_beta_factors,
    12: ETranslation.template_name_multiples,
    13: ETranslation.template_name_benchmarking,

    31: ETranslation.template_name_credit_spreads_sectors,
}
export const ProjectGroup = {
    1: "VP",
    2: "TP",
    3: "CS",

    11: "VP",
    12: "VP",
    13: "VP",

    31: "CS",
};

export function ProjectTypeToModule(project_type: EProjectType): EModuleNames {
    if (project_type === EProjectType.transfer_pricing) {
        return EModuleNames.TransferPricing;
    }
    if (project_type === EProjectType.credit_spread) {
        return EModuleNames.CreditSpreads;
    }
    return EModuleNames.BetaFactor;
}
export const ProjectUserRoleMap = {
    1: "Administrator",
    2: "Schreibrecht",
    3: "Leserecht",
};
export const ProjectTypeModuleMap = {
    1: [
        "CostOfCapital",
        "BetaFactor",
        "Multiples",
        "Benchmarking",
        "SectorsModule",
    ],
    2: [
        "TransferPricing",
        "Benchmarking",
    ],
    3: ["CreditSpreads"],

    11: [
        "BetaFactor",
    ],
    12: [
        "Multiples",
    ],
    13: [
        "Benchmarking",
    ],

    31: ["CreditSpreadsSector"],
};

export const ProjectModuleGroups = {
    Multiples,
    BetaFactor,
    CreditSpreads,
    Benchmarking,
    CostOfCapital,
    TransferPricing,
    TestModModule,
    SectorsModule,
    CreditSpreadsSector,
};

export interface IProject {
    _id?: string;
    name?: string;
    ident?: string;
    description?: string;
    created?: string;
    values?: any;
    project_type?: EProjectType;
    project_users?: { role: EProjectUserRole; user: IUser }[];
}
