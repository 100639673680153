import {BaseModule} from "../components/modules/BaseModule";
import {ParameterConfigs} from "../const/ParameterConfigs";
import {SessionStore} from "../const/SessionStore";
import {
    BetaFilterResponse,
    IBetaFilterResponse,
    IRawBetaResponse,
    IRegression,
    RawBetaResponse
} from "../models/chef/RawBeta";
import {EParameters} from "../models/EParameters";
import {IParameterChanged} from "../models/IParameterChanged";
import {EventBus} from "../services/EventBus";
import {anyNot} from "./Helpers";
import {IOptionEntry} from "../models/IOptionEntry";
import {_t} from "../tools/Translator";
import {ETranslation} from "../const/ETranslation";

const all_parameters = ParameterConfigs.configs[EParameters.BetaFilterParameter].parameterComposition;
const ParameterToState={
    BetaFilterBidAsk: "filterBidAsk",
    BetaFilterFreeFloat: "filterFreeFloat",
    BetaFilterR2: "filterR2",
    BetaFilterTTest: "filterTTest",
    BetaFilterDurbinWatsonTest: "filterDurbinWatsonTest",
    BetaFilterBreuschPaganTest: "filterBreuschPaganTest",
    BetaFilterDataPoints: "filterDataPoints",
};

export const PERIOD_DATA_POINTS = {
    "1d": 250, "1w": 52,
    "2d": 500, "2w": 104, "2bw": 52,
    "3d": 750, "3w": 156, "3bw": 78,
    "5w": 260, "5bw": 130, "5m": 60,
};

export class BetaFilterState {
    public static getParamToState(p: EParameters){
        return ParameterToState[p];
    }
    public static merge(otherState){
        const newState: {[index:string]: any} = {};
        all_parameters.forEach( (s) =>{
            newState[ParameterToState[s]] = SessionStore.get(s);
        });
        newState.show_company = {};
        const options: IOptionEntry[] = SessionStore.getItem("SelectedCompaniesParameter", []);
        options.forEach((o)=> newState.show_company[o.value] = true);
        return {...otherState, ...newState};
    }
    public static save(data: IParameterChanged[]){
        data.forEach((p)=> SessionStore.setItem(p.component, p.value));
    }
    public static listen(component: BaseModule<any>){
        const changeEvent = EventBus.subscribe<IParameterChanged[]>("ParameterChanged::BetaFilterParameter", (data)=>{
            const newState: {[index:string]: any} = {};
            data.forEach((p)=> newState[ParameterToState[p.component]] = p.value[0]);
            component.setState(newState);
        });
        component.eventHandlers.push(changeEvent);
        const showCompanyEvent = EventBus.subscribe("ParameterChanged::ShowCompanyEvent", (data)=>{
            const newState: {[index:string]: any} = {};
            newState.show_company = data;
            component.setState(newState);
        });
        component.eventHandlers.push(showCompanyEvent);

        const updateCompaniesEvent = EventBus.subscribe<IOptionEntry[]>("Update.CompaniesParameters", (data)=>{
            const show_company = {};
            const old_companies = component.state.show_company;
            data.forEach((o)=> show_company[o.value] = true);
            Object.keys(old_companies).forEach((key)=> show_company[key] = old_companies[key]);
            component.setState({show_company});
        });
        component.eventHandlers.push(updateCompaniesEvent);
    }
    public static getUnleveredBetaBase(){
        const adj = SessionStore.get(EParameters.AdjusmentBetaParameter);
        if("blumeadjusment" === adj){
            return _t(ETranslation.adjusted_beta);
        }
        if("totalrawbeta" === adj){
            return _t(ETranslation.total_beta);
        }
        return _t(ETranslation.raw_beta);
    }
    public static calcBeta(regression: IRegression){
        const adj = SessionStore.get(EParameters.AdjusmentBetaParameter);
        if("blumeadjusment" === adj){
            return regression.Slope * 2 / 3 + 1 / 3;
        }
        if("totalrawbeta" === adj){
            return regression.Slope / regression.R;
        }
        return regression.Slope;
    }
    public static isCanceled(company_id, data){
        const raw_betas: RawBetaResponse = data.raw_betas;
        const beta_filter_data: BetaFilterResponse = data.beta_filter_data;
        if(anyNot(raw_betas, beta_filter_data)){
            return true;
        }
        const raw_beta: IRawBetaResponse = raw_betas[company_id];
        const beta_filter: IBetaFilterResponse = beta_filter_data[company_id] ? beta_filter_data[company_id] : {} as IBetaFilterResponse;
        const period = SessionStore.get(EParameters.PeriodsParameter);
        const year = parseInt(period, 10);
        const regression: IRegression = raw_beta && raw_beta.periods && raw_beta.periods[period] ? raw_beta.periods[period] : {} as IRegression;

        const filterBidAsk = SessionStore.get(EParameters.BetaFilterBidAsk);
        const filterFreeFloat = SessionStore.get(EParameters.BetaFilterFreeFloat);
        const filterR2 = SessionStore.get(EParameters.BetaFilterR2);
        const filterTTest = SessionStore.get(EParameters.BetaFilterTTest);
        const filterBreuschPaganTest = SessionStore.get(EParameters.BetaFilterBreuschPaganTest);
        const filterDurbinWatsonTest = SessionStore.get(EParameters.BetaFilterDurbinWatsonTest);
        const filterDataPoints = SessionStore.get(EParameters.BetaFilterDataPoints);
        const dp = PERIOD_DATA_POINTS[period];
        if(filterDataPoints !== 0 && regression.DataPoints <= dp*filterDataPoints){
            return true;
        }

        if(filterBidAsk !== 0 && isNaN(beta_filter[`bid_ask_spread_${year}`])){
            return true;
        }
        if(filterFreeFloat !== 0 && isNaN(beta_filter[`free_float_${year}`])){
            return true;
        }

        if(filterBidAsk !== 0 && beta_filter[`bid_ask_spread_${year}`] > filterBidAsk/100){
            return true;
        }
        if(filterFreeFloat !== 0 && beta_filter[`free_float_${year}`] < filterFreeFloat){
            return true;
        }
        if(filterR2 !== 0 && regression.R2 < filterR2){
            return true;
        }
        if(filterTTest !== 0 && regression.TTestSlope < filterTTest){
            return true;
        }
        if(filterDurbinWatsonTest !== 0 && !(regression.DurbinWatsonTest>1.59 && regression.DurbinWatsonTest<2.41)){
            return true;
        }
        if(filterBreuschPaganTest !== 0 && regression.BreuschPaganTest <= 3.841){
            return true;
        }
        return false;
    }
}
