import {
    Button,
    Classes,
    Dialog,
    HTMLTable,
    Icon,
    Intent,
    Menu,
    MenuItem,
    NonIdealState, Tab,
    Tabs
} from "@blueprintjs/core";
import {Alignment, ContentText} from "pdfmake/interfaces";
import * as React from "react";
import {RegressionLine} from "../../chart-plugin/RegressionLine";
import {Globals} from "../../const/Globals";
import {CalcCreditSpreadIndication} from "../../helpers/CalcCreditSpreadIndication";
import {median} from "../../helpers/Statistics";
import {EParameters} from "../../models/EParameters";
import {IBondDetails} from "../../models/IBondDetails";
import {IOptionEntry} from "../../models/IOptionEntry";
import {PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {TasksCalls} from "../../services/TasksCalls";
import {SzTableHelper} from "../widgets/helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import {Sectors} from "../../const/Sectors";
import {TdHTMLAttributes} from "react";
import {IconNames} from "@blueprintjs/icons";
import {ICompanySearchResult} from "../../models/ICompanySearchResult";
import {CompanyInformation} from "../widgets/CompanyInformation";
import {CompanyRelations} from "../widgets/CompanyRelations";
import {CompanyFinancials} from "../widgets/CompanyFinancials";
import {CompanyEstimates} from "../widgets/CompanyEstimates";
import {PublicDbCalls} from "../../services/PublicDbCalls";
import {Bubble} from "react-chartjs-2";
import {ICountry} from "../../models/ICountry";
import {Ratings} from "../../const/Ratings";
import {fromDateDecimal, fromStr} from "../../tools/DateTools";
import {_t, _tn} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, RegressionLine);


export class CreditSpreadBonds extends BaseModule<any> {

    protected tableProps: ISzTableProperties<any[]>;
    protected selectOptions: IOptionEntry[] = [];

    constructor(props: any, context: any) {
        super(props, context);
    }

    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }

    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.tableProps);
                // console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }


    protected getClassNames(): string {
        return "sz-module-table";
    }
    protected renderContent() {
        return (
            <div>
                {this.data && Array.isArray(this.data.bond_details) ? this.renderData() : this.noData()}
                {this.renderCompanyDialog()}
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    protected renderData() {
        const boxed_stat = CalcCreditSpreadIndication.get_all_boxed_spreads(this.data.results);
        const stat_map = {};
        // const stat = boxed_stat; // .slice(-20);
        boxed_stat.forEach((i)=>{
            stat_map[i.id] = i;
        });

        const qualifying_date = fromStr(this.getParameterValue(EParameters.DateParameter)).setToEoM();
        const remaining = (dd_mat_date) => {
            const mat_date = fromDateDecimal(dd_mat_date);
            const x = mat_date.diff(qualifying_date, "years").years;
            // const d = moment.duration(x, "years");
            // return Globals.formatter(x, 1, 1, true);
            return x;
        };

        const deCollator = new Intl.Collator("de");
        const bond_details: IBondDetails[] = this.data.bond_details;
        const company_info_map = {};
        bond_details.forEach((i)=>{
            if(!stat_map[i.co_name]){
                return;
            }
            if(!company_info_map[i.co_name]){
                const d = {
                    co_name: i.co_name,
                    spread: stat_map[i.co_name].spread,
                    rating: i.rating,
                    sector_id: i.sector_id,
                    sub_sector_id: i.sub_sector_id,
                    country_iso3: i.country_iso_3,
                };
                company_info_map[i.co_name] = d;
            }
            i.remaining = remaining(i.mat_date);
            stat_map[i.co_name].bonds.push(i);
        });
        Object.keys(stat_map).forEach((key)=>{
            const s = stat_map[key];
            if(!Array.isArray(s.bonds)){
                return;
            }
            const r = s.bonds.map((b)=> b.remaining);
            company_info_map[key].remaining = median(r);
        });
        // console.error(stat);
        // stat.sort((a, b)=>deCollator.compare(a.id, b.id));
        boxed_stat.forEach((i)=> i.bonds.sort((a: IBondDetails, b: IBondDetails)=>{
            return a.remaining - b.remaining;
        }));

        const seniority = (f)=>{
            if(["Senior","","Senior Non Preferred","Senior Contingent","Not Disclosed"].includes(f)){
                return "vorrangig";
            }
            return "nachrangig";
        };
        const enhancement = (f)=> {
            if(["","None"].includes(f)){
                return "unbesichert";
            }
            if(["Statutory","Insured","Guarantee"].includes(f)){
                return "besichert";
            }
            return f;
        };
        const pledge = (f)=>{
            if(["","Unsecured"].includes(f)){
                return "unbesichert";
            }
            return "besichert";
        };
        const open = this.state.open_company;
        const renderOpen = (id)=>{
            if(open!==id){
                return null;
            }
            const row_data = stat_map[id];
            if(!row_data){
                return;
            }
            if(!Array.isArray(row_data.bonds)){
                return;
            }
            return row_data.bonds.map((item: IBondDetails, idx)=>{
                const name_cell: TdHTMLAttributes<any> = {
                    style: {overflow: "hidden", textOverflow: "ellipsis"},
                };
                return (
                    <tr style={{backgroundColor: "rgba(16,22,26,0.05)"}}>
                        <td style={{width: 30, paddingLeft: 10, paddingRight: 3, textAlign: "right"}}>{idx+1}</td>
                        <td style={{padding: def_padding}} colSpan={7}>{Globals.em_space}{item.fi_name_iss}</td>
                        <td style={{padding:  def_padding}}><span className={`${Classes.TEXT_MUTED}`}>{item.p_symbol_isin}</span></td>
                        <td style={{width: 30, padding:  def_padding}}><span className={`${Classes.TEXT_MUTED}`}>{item.currency_iso_3}</span></td>
                        <td style={{width: 80, padding:  def_padding}}><span className={`${Classes.TEXT_MUTED}`}>{seniority(item.fi_issue_details_seniority)}</span></td>
                        <td style={{width: 80, padding:  def_padding}}><span className={`${Classes.TEXT_MUTED}`}>{pledge(item.fi_issue_details_pledge_status)}</span></td>
                        <td style={{width: 80, padding:  def_padding}}><span className={`${Classes.TEXT_MUTED}`}>{enhancement(item.fi_enhance_a_enh_type)}</span></td>
                        <td style={{width: 65, padding:  def_padding}}><span className={`${Classes.TEXT_MUTED}`}>{fromDateDecimal(item.iss_date).asDeDate()}</span></td>
                        <td style={{width: 65, padding:  def_padding}}><span className={`${Classes.TEXT_MUTED}`}>{fromDateDecimal(item.mat_date).asDeDate()}</span></td>
                        <td style={{width: 60, padding:  def_padding, textAlign: "right"}}><span className={`${Classes.TEXT_MUTED}`}>{Globals.formatter(item.remaining, 1, 1, true)}</span></td>
                        <td style={{width: 60, padding:  def_padding, textAlign: "right"}}><span className={`${Classes.TEXT_MUTED}`}>{Globals.formatter(item.ftid_spread/100)} %</span></td>
                        <td style={{width: 60, padding:  def_padding, textAlign: "right"}}><span className={`${Classes.TEXT_MUTED}`}>{Globals.formatter(item.ftid_yield)} %</span></td>
                    </tr>
                );
            });
        };
        const label_width = undefined;
        const num_width = 80;
        const def_padding = "6px 3px 6px 3px";
        // console.error(stat);

        // <th style={{width: num_width, padding: "6px 3px"}}>FX</th>
        const onIconClick= (event, row)=>{
            event.stopPropagation();
            this.loadCompany(row.cid);
        };
        const notes = [
            "Restlaufzeit in Jahren",
            "% p.a.; gegenüber ausfallsicheren Staatsanleihen",
        ];
        const table_data = [];
        const columns: ISzTableColumn[] = [];

        columns.push({ ...SzTableHelper.columnIndex("0"), options: {style: {width: 30} }});
        columns.push({ ...SzTableHelper.columnIndex("1")});
        columns.push({ ...SzTableHelper.columnIndex("2"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("3"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("4"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("5"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("6"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("7"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("8"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("9"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("10"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("11"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("12"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("13"), options: {style: {width: 90} }});
        columns.push({ ...SzTableHelper.columnIndex("14"), options: {style: {width: 90} }});
        columns.push({...SzTableHelper.columnMoney("15", 0, {style: {width: 60}})});
        columns.push({...SzTableHelper.columnPercent("16", 0, {style: {width: 60}})});
        columns.push({...SzTableHelper.columnPercent("17", 0, {style: {width: 60}})});
        this.tableProps = {
            title: "Details Anleihen",
            colCount: 13,
            columns,
            header: [[
                { text: "#" },
                { text: _t(ETranslation.company) },
                { text: _t(ETranslation.country) },
                { text: "Region" },
                { text: "Rating" },
                { text: _t(ETranslation.sector) },
                { text: "Subsektor" },
                { text: _tn(ETranslation.bonds) },
                { text: "ISIN" },
                { text: "FX" },
                { text: "Rang" },
                { text: "Pfandrecht" },
                { text: "Garantie o.ä." },
                { text: "Ausgabe" },
                { text: "Fälligkeit" },
                { text: "Restlaufzeit" },
                { text: "Spread" },
                { text: _t(ETranslation.return) },
            ]],
            data: table_data,
        };
        const getColor = (c: number)=>{
            const ranges = [
                {a: 0, b: .2, color: "#E5E8EB"},
                {a: .2, b: .4, color: "#C5CBD3"},
                {a: .4, b: .6, color: "#72CA9B"},
                {a: .6, b: .8, color: "#238551"},
                {a: .8, b: 1, color: "#165A36"},
            ];
            const r = ranges.find((i)=> c>=i.a && c<i.b);
            if(r){
                return r.color;
            }
                return "#7e1e1e";
        };

        const sorted_boxed_stat = [].concat(boxed_stat);
        const sortDir = this.state.sortDir;
        const sortColumn = this.state.sortColumn;
        if(sortColumn !== undefined && sortDir!==undefined){
            sorted_boxed_stat.sort((a, b)=>{
                const info_a = company_info_map[a.id];
                const country_a: ICountry = Globals.country_map_iso_3[info_a.country_iso3];
                const info_b = company_info_map[b.id];
                const country_b: ICountry = Globals.country_map_iso_3[info_b.country_iso3];
                /*
                    1 info.co_name
                    2 info.country_iso3
                    3 country?.region_de
                    4 info.rating === "0" ? Globals.hyphen : info.rating
                    5 Sectors.sectors_map[info.sector_id]
                    6 Sectors.sectors_map[info.sub_sector_id]
                */
                let str_a; // info_a.co_name;
                let str_b; // info_b.co_name;
                if(sortColumn === 1){
                    str_a = info_a.co_name;
                    str_b = info_b.co_name;
                }
                if(sortColumn === 2){
                    str_a = info_a.country_iso3;
                    str_b = info_b.country_iso3;
                }
                if(sortColumn === 3){
                    str_a = country_a?.region_de;
                    str_b = country_b?.region_de;
                }
                if(sortColumn === 4){
                    const ra = Ratings.s_and_p_all_mapped_to_num[info_a.rating] !== undefined ? Ratings.s_and_p_all_mapped_to_num[info_a.rating] : 100;
                    const rb = Ratings.s_and_p_all_mapped_to_num[info_b.rating] !== undefined ? Ratings.s_and_p_all_mapped_to_num[info_b.rating] : 100;
                    if(sortDir === "asc"){
                        return ra - rb;
                    }
                    if(sortDir === "desc"){
                        return rb - ra;
                    }
                }
                if(sortColumn === 5){
                    str_a = Sectors.sectors_map[info_a.sector_id];
                    str_b = Sectors.sectors_map[info_b.sector_id];
                }
                if(sortColumn === 6){
                    str_a = Sectors.sectors_map[info_a.sub_sector_id];
                    str_b = Sectors.sectors_map[info_b.sub_sector_id];
                }
                if(sortDir === "asc"){
                    return deCollator.compare(str_a, str_b);
                }
                if(sortDir === "desc"){
                    return deCollator.compare(str_b, str_a);
                }
                return 0;
            });
        }
        let count_bonds = 0;
        const ret_content = (
            <div style={{overflow: "hidden", position: "relative"}}>
                <HTMLTable className={`sz-table sz-prj-info-table_2`} condensed={true} style={{width: "100%", fontSize: "78%"}}>
                    <thead>
                    <tr>
                        <th style={{padding: def_padding, textAlign: "right"}}>#</th>
                        <th style={{padding: def_padding}}>{this.renderSortedHeader(1, _t(ETranslation.company))}</th>
                        <th style={{padding: def_padding}}>{this.renderSortedHeader(2, _t(ETranslation.country))}</th>
                        <th style={{padding: def_padding}}>{this.renderSortedHeader(3, "Region")}</th>
                        <th style={{padding: def_padding}}>{this.renderSortedHeader(4, "Rating")}</th>
                        <th style={{padding: def_padding}}>{this.renderSortedHeader(5, _t(ETranslation.sector))}</th>
                        <th style={{padding: def_padding}}>{this.renderSortedHeader(6, "Subsektor")}</th>
                        <th style={{padding: def_padding}}>{_tn(ETranslation.bonds)}</th>
                        <th style={{padding: def_padding}}>ISIN</th>
                        <th style={{padding: def_padding}}>FX</th>
                        <th style={{padding: def_padding}}>Rang</th>
                        <th style={{padding: def_padding}}>Pfandrecht</th>
                        <th style={{padding: def_padding}}>Garantie o.ä.</th>
                        <th style={{padding: def_padding}}>Ausgabe</th>
                        <th style={{padding: def_padding}}>Fälligkeit</th>
                        <th style={{width: 60, padding: def_padding, textAlign: "right"}}>Restlaufzeit{Globals.superscript(1)}</th>
                        <th style={{width: 60, padding: def_padding, textAlign: "right"}}>Spread{Globals.superscript(2)}</th>
                        <th style={{width: 60, padding: def_padding, textAlign: "right"}}>{_t(ETranslation.return)}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sorted_boxed_stat.map( (row, row_num) => {
                        const info = company_info_map[row.id];
                        const country: ICountry = Globals.country_map_iso_3[info.country_iso3];
                        if(stat_map[row.id]?.bonds?.length){
                            count_bonds += stat_map[row.id]?.bonds?.length;
                        }
                        const row_data = [
                            row_num + 1,
                            info.co_name,
                            info.country_iso3,
                            country?.region_de,
                            info.rating === "0" ? Globals.hyphen : info.rating,
                            Sectors.sectors_map[info.sector_id],
                            Sectors.sectors_map[info.sub_sector_id],
                            stat_map[row.id]?.bonds?.length,
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            info.remaining,
                            info.spread,
                            undefined,
                        ];
                        table_data.push(row_data);
                        stat_map[row.id]?.bonds?.forEach((item, b)=>{
                            const bond_data = [
                                b + 1,
                                `${Globals.em_space}${item.fi_name_iss}`,
                                info.country_iso3,
                                country?.region_de,
                                info.rating === "0" ? Globals.hyphen : info.rating,
                                Sectors.sectors_map[info.sector_id],
                                Sectors.sectors_map[info.sub_sector_id],
                                stat_map[row.id]?.bonds?.length,
                                item.p_symbol_isin,
                                item.currency_iso_3,
                                seniority(item.fi_issue_details_seniority),
                                pledge(item.fi_issue_details_pledge_status),
                                enhancement(item.fi_enhance_a_enh_type),
                                fromDateDecimal(item.iss_date).asDeDate(),
                                fromDateDecimal(item.mat_date).asDeDate(),
                                item.remaining,
                                item.ftid_spread / 100,
                                item.ftid_yield
                            ];
                            table_data.push(bond_data);
                        });
                        return (
                            <>
                                <tr className={"sz-row-hover"} onClick={()=>{ if(this.state.open_company === row.id){ return this.setState({open_company: undefined});} this.setState({open_company: row.id});}}>
                                    <td style={{width: 30, paddingLeft: 10, paddingRight: 6, textAlign: "right"}}>{row_num + 1}</td>
                                    <td style={{padding: def_padding}}>
                                        <div style={{width: "100%", display: "grid", gridTemplateColumns: "12px 1fr 12px"}}>
                                            <span style={{color: getColor(row.weight)}} title={`Übereinstimmung ${Globals.formatter_percent(row.weight, 0, 0)}`}>⬤</span>
                                            <span title={info.co_name} style={{paddingLeft: 3, paddingRight: 3, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}>{info.co_name}</span>
                                            <span><Icon icon={IconNames.ARROW_TOP_RIGHT} color={"#137cbd"} size={12} onClick={(e)=>onIconClick(e, row)}/></span>
                                        </div>
                                    </td>
                                    <td style={{width: 40, padding: def_padding}}>{info.country_iso3}</td>
                                    <td style={{width: 90, padding: def_padding}}>{country?.region_de}</td>
                                    <td style={{width: 40, padding: def_padding}}>{info.rating === "0" ? Globals.hyphen : info.rating}</td>
                                    <td style={{width: 180, padding: def_padding}}>{Sectors.sectors_map[info.sector_id]}</td>
                                    <td style={{width: 210, padding: def_padding}}>{Sectors.sectors_map[info.sub_sector_id]}</td>
                                    <td style={{width: 30, padding: def_padding, textAlign: "right"}}>{stat_map[row.id]?.bonds?.length}</td>
                                    <td style={{width: 90, padding: def_padding}}>&nbsp;</td>
                                    <td style={{width: 30, padding: def_padding}}>&nbsp;</td>
                                    <td style={{width: 80, padding: def_padding}}>&nbsp;</td>
                                    <td style={{width: 80, padding: def_padding}}>&nbsp;</td>
                                    <td style={{width: 80, padding: def_padding}}>&nbsp;</td>
                                    <td style={{width: 65, padding: def_padding}}>&nbsp;</td>
                                    <td style={{width: 65, padding: def_padding}}>&nbsp;</td>
                                    <td style={{width: 60, padding: def_padding, textAlign: "right"}}>{Globals.formatter(info.remaining, 1, 1, true)}</td>
                                    <td style={{width: 60, padding: def_padding, textAlign: "right"}}>{Globals.formatter(info.spread)} %</td>
                                    <td style={{width: 60, padding: def_padding, textAlign: "right"}}>&nbsp;</td>
                                </tr>
                                {renderOpen(row.id)}
                            </>
                        );
                    })}
                    </tbody>
                </HTMLTable>
                {this.appendNotes(notes)}
            </div>
        );
        console.error("count_bonds", count_bonds);
        return ret_content;
    }

    protected noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Stichtag ..."
        />);
    }

    private renderSortedHeader(headerColIdx: number, label: string) {
        const sortColumn = this.state.sortColumn;
        const getIcon = () => {
            if(sortColumn !== headerColIdx){
                return IconNames.DOUBLE_CARET_VERTICAL;
            }
            if(this.state.sortDir==="asc" && sortColumn === headerColIdx){
                return IconNames.CARET_UP;
            }
            if(this.state.sortDir==="desc" && sortColumn === headerColIdx){
                return IconNames.CARET_DOWN;
            }
            return IconNames.DOUBLE_CARET_VERTICAL;
        };
        const setSort = () => {
            let dir = this.state.sortDir;
            let col = headerColIdx;
            const currentSortColumn = this.state.sortColumn;
            if(currentSortColumn === col){
                switch (dir) {
                    case "asc": {dir = "desc"; break;}
                    case "desc": {dir = undefined; col= undefined; break;}
                    default: dir = "asc";
                }
            }else{
                if(dir === undefined){
                    dir = "asc";
                }
            }
            const s = {
                sortDir: dir,
                sortColumn: col,
            };
            this.setState(s);
        };
        return (
            <div onClick={ () => setSort()} style={{width: "100%", display: "flex", cursor: "default", userSelect: "none"}}><span>{label}</span><Icon icon={getIcon()} /></div>
        );
    }

    private loadCompany(cid){
        (async ()=>{
            const db = new PublicDbCalls();
            const company = await db.getCompany(cid);
            this.setState({
                company_dialog_is_open: true,
                company,
            });
        })();
    }
    private appendNotes(notes: string[]) {
        const renderNote = (s: string, i: number) => {
            return (<div className={"table-note bp3-text-small bp3-text-muted"} style={{display: "flex", justifyContent: "flex-start", alignItems: "stretch"}}><div style={{display: "inline-block", width: 15}}>{Globals.superscript(i + 1)})</div><div style={{display: "inline-block", width: "calc(100% - 15px)"}}>{s}</div></div>);
        };
        return (
            <div className={"table-notes"}>
                {notes.map(renderNote)}
            </div>
        );
    }
    private renderCompanyDialog() {
        if (!this.state.company_dialog_is_open) {
            return null;
        }
        const company: ICompanySearchResult = this.state.company;
        return (
            <Dialog
                style={{width: 1200, backgroundColor: "transparent", padding: 0}}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={true}
                onClose={() => this.setState({company_dialog_is_open: false})}
            >
                <div className={`${Classes.DIALOG_HEADER} bp3-dark`} style={{backgroundColor: "rgb(57, 75, 89)", paddingLeft: 10}}>
                    <img src={`${Globals.BASE_DIR}images/flags/${company.country_iso_2.toLowerCase()}.jpg`} style={{height: 30, alignSelf: "center"}}/>
                    <div style={{display: "flex", width: "100%", paddingRight: 10, marginLeft: 10, justifyContent: "space-between", alignItems: "center"}}>
                        <span style={{fontSize: "100%", fontWeight: 600}}>{company.company_name}</span>
                        <div style={{fontSize: "70%"}} className={"bp3-text-muted"}>{company.country_ticker.toUpperCase()} - {company.company_id}</div>
                    </div>
                    <Button intent={Intent.DANGER} aria-label={"Schließen"} icon={"small-cross"} minimal={true} onClick={() => this.setState({company_dialog_is_open: false})} />
                </div>
                <div className={Classes.DIALOG_BODY} style={{margin: 0, padding: 10, paddingBottom: 10, backgroundColor: "#ffffff"}}>
                    <Tabs id="CI_TABS" className={"sz-tab-seized"}>
                        <Tab id="CI_TABS_BASE" title={_t(ETranslation.base_data)} panel={<CompanyInformation company_id={company.company_id} />} />
                        <Tab id="CI_TABS_COMP" title={_t(ETranslation.peer_group)} panel={<CompanyRelations company_id={company.company_id} />} />
                        <Tab id="CI_TABS_FIN" title={_t(ETranslation.financials)} panel={<CompanyFinancials company_id={company.company_id} />} />
                        <Tab id="CI_TABS_EST" title="Schätzungen" panel={<CompanyEstimates company_id={company.company_id} />} />
                        <Tabs.Expander />
                        <Tab id="CI_TABS_BONDS" title={_t(ETranslation.bonds)} panel={this.renderBondsTab(company.company_id)} />
                    </Tabs>
                </div>
            </Dialog>
        );
    }
    private renderBondsTab(company_id){
        const o = 0.3;
        const colors = {
            "USD": `rgba(64,72,84,${o})`,
            "EUR": `rgba(24,74,144,${o})`,
            "GBP": `rgba(119,69,13,${o})`,
            "JPY": `rgba(92,37,92,${o})`,
            "AUD": `rgba(150, 41, 13, ${o})`,
            "CAD": `rgba(12,81,116,${o})`,
            "KRW": `rgba(0,77,70,${o})`,
            "RoW": `rgba(0,0,0,.01)`,
        };
        const colors_idx = {
            "USD": 0,
            "EUR": 1,
            "GBP": 2,
            "JPY": 3,
            "AUD": 4,
            "CAD": 5,
            "KRW": 6,
        };
        const colors_idx_default = 7;
        const chart_data_spreads = { datasets: [] };
        const chart_data_yield = { datasets: [] };
        Object.keys(colors).forEach((key)=>{
            chart_data_yield.datasets.push({
                label: key,
                data: [],
                datalabels: {
                    labels: {
                        title: null
                    }
                },
                backgroundColor: colors[key],
                borderColor: colors[key],
            });
            chart_data_spreads.datasets.push({
                label: key,
                data: [],
                datalabels: {
                    labels: {
                        title: null
                    }
                },
                backgroundColor: colors[key],
                borderColor: colors[key],
            });
        });
        const xTicksNew = [12, 24, 36, 60, 84, 120, 240, 360];
        const xLabels = ["0","1", "2", "3", "5", "7", "10", "20", "30"];
        const company_bonds_details = this.data.all_boxed_spreads.find((b   )=> b.cid === company_id);
        const bonds: IBondDetails[] = company_bonds_details.bonds;
        // console.error(bonds);
        const spread_data = [];
        const yield_data = [];
        const used_bonds = {};
        const push_bond = (target, b: IBondDetails, data)=>{
            const cr = b.currency_iso_3;
            const c_idx = colors_idx[cr] !== undefined ? colors_idx[cr] : colors_idx_default;
            target.datasets[c_idx].data.push(data);
        };
        bonds.forEach((b)=>{
            if(used_bonds[b.p_symbol_isin]){
                return;
            }else{
                used_bonds[b.p_symbol_isin] = true;
            }
            push_bond(chart_data_yield, b, {
                x: b.remaining * 12,
                y: b.ftid_yield,
                data: b,
            });
            push_bond(chart_data_spreads, b, {
                x: b.remaining * 12,
                y: b.ftid_spread / 100,
                data: b,
            });
        });
        const get_tooltip_label = (item)=> {
            const d: IBondDetails = item.raw.data;
            if(!d){
                return undefined;
            }
            return `${d.fi_name_iss} ${d.currency_iso_3}`;
        };
        const renderChart = (label, chart_data, min_y, max_y)=> {
            const options = {
                stacked: false,
                responsive: true,
                hover: {mode: null},
                maintainAspectRatio: false,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: {
                            stepSize: 1,
                            z: 10,
                            padding: 10,
                            callback: (value, index, labels)=>{
                                return Math.trunc(value);
                            },
                        },
                        border:{
                            display: true,
                            color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                            dash: [2],
                        },
                        grid: {
                            drawTicks: true,
                            tickLength: -5,
                            tickColor: "#485d63",
                        },
                        min: min_y,
                        max: max_y,
                    },
                    x: {
                        position: {
                            y: 0,
                        },
                        ticks: {
                            stepSize: 12,
                            z: 10,
                            padding: 10,
                            callback: (value, index, labels)=>{
                                const idx = xTicksNew.indexOf(value);
                                if(idx >= 0){
                                    const v = Math.trunc(value / 12);
                                    return v;
                                }
                                return null;
                            }
                            /*maxRotation: 0,*/
                        },
                        border:{
                            display: true,
                            color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                            dash: [2],
                        },
                        grid: {
                            drawTicks: true,
                            tickLength: -5,
                            tickColor: "#485d63",
                            lineWidth: (item)=>{
                                if(item && item.tick && xLabels.indexOf(`${Math.trunc(item.tick.value/12)}`)>=0){
                                    return 1;
                                }
                                return 0;
                            }
                        },
                        min: 0,
                        max: 360,
                    }
                },
                plugins: {
                    title: {
                        display: false,
                        text: undefined,
                    },
                    legend: {
                        display: true,
                        position: "right" as "right",
                        align: "start" as "start",
                    },
                    datalabels: {
                        display: 1,
                    },
                    tooltip: {
                        enabled: true,
                        mode: "point",
                        filter: (tooltipItem)=>{
                            return !tooltipItem.raw.isSpreadValue;
                        },
                        callbacks: {
                            label: get_tooltip_label,
                            title: (item)=> { return undefined;},
                        },
                    },
                },
                custom_axis_label: {
                    x_label: _t(ETranslation.years),
                    y_label: "[% p.a.]",
                }
            };
            return <Bubble options={options as any} data={chart_data as any} height={"100%"} width={"100%"} />;
        };
        return (
            <div className={"sz-row"}>
                <div className={"sz-col sz-col-50"}>
                    <div style={{marginBottom: 20,fontSize: "80%"}}>
                        <strong>{_t(ETranslation.credit_spread)} [% p.a.]</strong>
                    </div>
                    <div style={{height: 300}}>
                        {renderChart("Spread", chart_data_spreads, 0, 8)}
                    </div>
                </div>
                <div className={"sz-col sz-col-50"}>
                    <div style={{marginBottom: 20,fontSize: "80%"}}>
                        <strong>{_t(ETranslation.return)} [% p.a.]</strong>
                    </div>
                    <div style={{height: 300}}>
                        {renderChart(_t(ETranslation.return), chart_data_yield, -2, 10)}
                    </div>
                </div>
            </div>
        );
    }

    public getPdfPage(): any[] {
        const header = (text: string, al?: Alignment)=>{
            return {
                text,
                /*fontSize: 7,*/
                border: [false, false, false, true],
                alignment: al ? al : undefined,
                bold: true,
            };
        };
        const cell = (text: any, al?: Alignment): ContentText=>{
            return PdfInsertElement.p([], text, al);
        };
        const content = [];
        PdfInsertElement.page_header(content, _t(ETranslation.company));
        const table = PdfInsertElement.table(content);
        table.layout = "smart_table";
        table.table = {
            headerRows: 1,
            widths: ["auto", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto", 30],
            body: [
                [
                    header("#", "right"),
                    header(_t(ETranslation.company)),
                    header(_t(ETranslation.country)),
                    header("Region"),
                    header("Rating"),
                    header(_t(ETranslation.sector)),
                    header("Subsektor"),
                    header(_tn(ETranslation.bonds), "right"),
                    header("Restlaufzeit", "right"),
                    header("Spread", "right"),
                ],
            ],
        };
        let cc = 1;
        this.tableProps.data.forEach((row_data, row_num)=>{
            if(!row_data[2]){
                return;
            }
            const row = [];
            table.table.body.push(row);
            row.push(cell(cc++, "right"));
            row.push(cell(row_data[1]));
            row.push(cell(row_data[2]));
            row.push(cell(row_data[3]));
            row.push(cell(row_data[4]));
            row.push(cell(row_data[5]));
            row.push(cell(row_data[6]));
            row.push(cell(row_data[7], "right"));
            row.push(cell(Globals.formatter(row_data[15], 1, 1, true), "right"));
            row.push(cell(`${Globals.formatter(row_data[16])} %`, "right"));
        });

        return content;
    }
}
