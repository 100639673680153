import {Menu} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EDataType} from "../../../models/EDataType";
import {EParameters} from "../../../models/EParameters";
import {EValueName} from "../../../models/EValueName";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {ISzSliderProperties} from "../../widgets/SzSlider";
import {SliderParameter} from "../SliderParameter";

const values: number[] = [];
/*
for (let a = 0; a < 37; a++) {
    values[a] = 1 + (a * .25);
}
*/
for (let a = 0; a < 33; a++) {
    values[a] = 2 + (a * .25);
}
// [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
// [2, 2.5, 3, 3.5, 4, 4.25, 4.5, 4.75, 5, 5.25, 5.5, 5.75, 6, 6.25, 6.5, 7, 7.25, 7.5, 7.75, 8, 8.5, 9, 9.5, 10]

export class MarketRiskPremiumParameter extends SliderParameter {

    private initialIndex: number;

    constructor(props: any, context: any) {
        super(props, context);

        console.error(values.length);

        // values.forEach( (i, j) => values[j] = i / 100 );
        this.dataType = EDataType.percent;

        const v = Math.trunc(parseFloat(SessionStore.get(EParameters.MarketRiskPremiumParameter)) * 10000) / 100;
        const idx: number = values.findIndex( (i) => i === v );
        if (idx === undefined) {
            this.initialIndex = 0;
        } else {
            this.initialIndex = idx;
        }
    }

    protected renderHint() {
        return (
            <Menu style={{marginTop: 12}}>
                <li>
                    <p className={"bp3-text-small bp3-text-muted"} style={{maxWidth: 450}}>Die Marktrisikoprämie bezieht sich auf die CAPM. Es handelt sich hierbei um eine Marktrisikoprämie vor persönlichen Steuern.</p>
                </li>
            </Menu>
        );
    }

    protected getValue() {
       return this.initialIndex;
    }

    protected setValue(currentIndex: number) {
        SessionStore.setGlobalVar(EValueName.market_risk_premium, values[currentIndex] / 100);
    }

    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.MarketRiskPremiumParameter];
    }

    protected resolveValue(currentIndex: number): number {
        console.error(currentIndex, values[currentIndex], values[currentIndex] / 100);
        return values[currentIndex] / 100;
    }

    protected getParameters(currentIndex: number): ISzSliderProperties {

        const parameters: ISzSliderProperties = {
            value: currentIndex,
            min: 0,
            max: values.length - 1,
            stepSize: 1,
            stepSizeLabel: 8,
            labelRenderer: (index: number) => {
                return Globals.formatter_percent(values[index] / 100, 2, 0);
            },
            onValueChanged: (index: number) => {
                this.changeValue(index);
            },
        };
        return parameters;
    }

}
