import {sysError} from "../components/widgets/AppToaster";

export class FrontendCalls {

    private async callOut(serviceRef: string, data: any, method: string= "POST") {
        const dataObject = data ? JSON.stringify(data) : null;
        const url: string = serviceRef;
        try {
            const response = await fetch(url, {
                body: dataObject,
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "pragma": "no-cache",
                    "cache-control": "no-cache",
                },
                method,
                mode: "cors",
            });
            if(response.status !== 200){
                const msg = response.statusText;
                sysError(msg);
                return {error: true};
            }
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error("Error:", error);
        }
        return {error: true};
    }

    public async login(uuid: string, password: string){
        const p = {uuid, password};
        return await this.callOut("login", p);
    }
    public async change_login(password: string, new_password: string){
        const p = {password, new_password};
        return await this.callOut("change_login", p);
    }
    public async test_login(password: string){
        const p = {password};
        return await this.callOut("test_login", p);
    }
    public async logout(){
        const p = {};
        return await this.callOut("logout", p);
    }
    public async active_session(){
        const p = {};
        return await this.callOut("active_session", p);
    }

    public async register(uuid: string, password: string){
        const p = {uuid, password};
        return await this.callOut("register", p);
    }

    public async new_password_request(uuid: string){
        const p = {uuid};
        return await this.callOut("new_password_request", p);
    }
    public async set_new_password(rq_id: string, password: string){
        const p = {rq_id, password};
        return await this.callOut("set_new_password", p);
    }
}
