import {Button, Intent} from "@blueprintjs/core";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import * as ChartJsAnnotation from "chartjs-plugin-annotation";
import * as React from "react";
import {useState} from "react";
import {Bar, Line} from "react-chartjs-2";

import {CustomAxisLabel} from "../../../chart-plugin/CustomAxisLabel";
import {DrawStateHook} from "../../../chart-plugin/DrawStateHook";
import {TooltipLine} from "../../../chart-plugin/TooltipLine";
import {ColorMap} from "../../../const/ColorMap";
import {ETranslation} from "../../../const/ETranslation";
import {Globals} from "../../../const/Globals";
import {Sectors} from "../../../const/Sectors";

import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {IMultiplierForBusiness} from "../../../models/IMultiplierForBusiness";
import {EventBus, IEventHandler} from "../../../services/EventBus";
import {fromDateDecimal} from "../../../tools/DateTools";
import {_t} from "../../../tools/Translator";
import {BondCurrencyPerYear, BondRankedFieldPerYear} from "../../../models/chef/Bonds";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
    ChartJsAnnotation,
    CustomAxisLabel,
);
export interface IBsRankedFieldPerYear {
    data: BondRankedFieldPerYear[];
    getLabel?(l): string;
}
export function BsRankedFieldPerYear(props: IBsRankedFieldPerYear) {
    const data = props.data;
    const o = new SzChartOptions();
    o.enablePlugin(EChartPlugins.LEGEND, {position: "top", labels: {boxWidth: 10}});
    o.enablePlugin(EChartPlugins.TOOLTIP, {});
    const options = {
        ...o.options,
    };
    options.scales.y.stacked= true;
    options.scales.x.stacked= true;
    const datasets= [];
    const years_map = {};
    data.forEach((d)=>{
        if(!years_map[d.year]){
            years_map[d.year] = {
            };
        }
        years_map[d.year][d.field] = d.count;
    });
    const years = Object.keys(years_map).sort();
    const datasets_map_by_field = {};
    years.forEach((year, y_idx)=>{
        const field_map = years_map[year];
        Object.keys(field_map).forEach((c)=>{
            if(!datasets_map_by_field[c]){
                datasets_map_by_field[c] = {
                    label: props.getLabel ? props.getLabel(c) : c,
                    data: years.map(()=>undefined),
                    backgroundColor: ColorMap[`$c_${1 + datasets.length}`],
                };
                datasets.push(datasets_map_by_field[c]);
            }
            datasets_map_by_field[c].data[y_idx] = field_map[c];
        });
    });
    const labels: any[] = years.sort();
    const chart_data = {
        labels,
        datasets,
    };
    return (
        <Bar data={chart_data as any} options={options} width={"100%"} height={"100%"}/>
    );
}
