import {Globals} from "./Globals";
import {_t} from "../tools/Translator";
import {ETranslation} from "./ETranslation";

export const captions = {
    roce : "ROCE",
    roe : "ROE",
    sales_growth: _t(ETranslation.revenue_growth),
    ebitda_growth: `EBITDA-${_t(ETranslation.growth)}`,
    gross_sales_ratio: "Rohertrags-Marge",
    ebitda_sales_ratio: _t(ETranslation.ebitda_margin),
    ebit_sales_ratio: _t(ETranslation.ebit_margin),
    netincome_sales_ratio: `${_t(ETranslation.netincome)}-Marge`,
    ffo_debt_ratio: _t(ETranslation.ffo_to_net_debt),
    debt_ebitda_ratio: _t(ETranslation.net_debt_to_ebitda),
    ebit_interest_ratio: _t(ETranslation.interest_cover_ebit),
    debt_equity_ratio: `${_t(ETranslation.net_debt)} / ${_t(ETranslation.equity)}`,
    capex_sales_ratio: `CAPEX / ${_t(ETranslation.revenues_2)}`,
    capex_capitalasset_ratio: `CAPEX / ${_t(ETranslation.capital_assets)}`,
    anlagevermoegen: `${_t(ETranslation.capital_assets)}`,
    xch_anlagevermoegen: `${_t(ETranslation.capital_assets)}`,
    working_sales_ratio: `${_t(ETranslation.working_capital)}/ ${_t(ETranslation.revenues_2)}`,
    working_capital_employed_ratio: `${_t(ETranslation.working_capital)} / Capital Employed`,
    eq_ratio: _t(ETranslation.equity_ratio),
    anlagenintensitaet: "Anlagenintensität",
    anlagendeckungsgrad: "Anlagendeckungsgrad",
    capital_turnover: "Kapitalumschlag",
    gearing: _t(ETranslation.gearing),
    ebitda_interest: "EBITDA-Zinsdeckung",
    ebitda_oper_sales_ratio: "EBITDA-Marge, bereinigt",
    ebit_oper_sales_ratio: "EBIT-Marge, bereinigt",
    xch_ppe_net: "Sachanlagen",
    ff_debt_ebitda_oper: "Nettoschulden / EBITDA",
    net_debt_ebitda: "Nettoschulden / EBITDA",
    ffo_interest: "FFO-Zinsdeckung",
    xch_assets: "Bilanzsumme",
    xch_sales: "Umsatz",
};
export const captions2 = {
    "0": "nicht berücksichtigt",
    roce : "ROCE",
    roe : "ROE",
    sales_growth: _t(ETranslation.revenue_growth),
    ebitda_growth: `EBITDA-${_t(ETranslation.growth)}`,
    gross_sales_ratio: "Rohertrags-Marge",
    ebitda_sales_ratio: _t(ETranslation.ebitda_margin),
    ebit_sales_ratio: _t(ETranslation.ebit_margin),
    netincome_sales_ratio: "Jahresüberschuss-Marge",
    ffo_debt_ratio: "FFO / Nettoschulden",
    debt_ebitda_ratio: "Nettoschulden / EBITDA",
    ebit_interest_ratio: "EBIT-Zinsdeckung",
    debt_equity_ratio: "Nettoschulden / Eigenkapital",
    capex_sales_ratio: "CAPEX / Umsatzerlöse",
    capex_capitalasset_ratio: "CAPEX / Anlagevermögen",
    anlagevermoegen: "Anlagevermögen [Mio. EUR]",
    xch_anlagevermoegen: "Anlagevermögen [Mio. EUR]",
    working_sales_ratio: "Net Working Capital / Umsatzerlöse",
    working_capital_employed_ratio: "Net Working Capital / Capital Employed",
    eq_ratio: _t(ETranslation.equity_ratio),
    anlagenintensitaet: "Anlagenintensität",
    anlagendeckungsgrad: "Anlagendeckungsgrad",
    capital_turnover: "Kapitalumschlag",
    gearing: _t(ETranslation.gearing),
    ebitda_interest: "EBITDA-Zinsdeckung",
    ebitda_oper_sales_ratio: "EBITDA-Marge, bereinigt",
    ebit_oper_sales_ratio: "EBIT-Marge, bereinigt",
    xch_ppe_net: "Sachanlagen",
    ff_debt_ebitda_oper: "Nettoschulden / EBITDA",
    net_debt_ebitda: "Nettoschulden / EBITDA",
    ffo_interest: "FFO-Zinsdeckung",
    xch_assets: "Bilanzsumme [Mio. EUR]",
    xch_sales: "Umsatz [Mio. EUR]",
};
export const short_captions = {
    "0": Globals.hyphen,
    roce : "ROCE",
    roe : "ROE",
    sales_growth: _t(ETranslation.revenue_growth),
    ebitda_growth: "EBITDA...",
    gross_sales_ratio: "Rohertrag...",
    ebitda_sales_ratio: "EBITDA-M.",
    ebit_sales_ratio: "EBIT-M.",
    netincome_sales_ratio: "JÜ-Marge",
    ffo_debt_ratio: "FFO...",
    debt_ebitda_ratio: "Nettoschulden...",
    ebit_interest_ratio: "EBIT-Zinsd.",
    debt_equity_ratio: "Nettoschulden / Eigenkapital",
    capex_sales_ratio: "CAPEX / Umsatzerlöse",
    capex_capitalasset_ratio: "CAPEX / Anlagevermögen",
    anlagevermoegen: "Anl.-Verm.",
    xch_anlagevermoegen: "Anl.-Verm.",
    working_sales_ratio: "Net Working Capital / Umsatzerlöse",
    working_capital_employed_ratio: "Net Working Capital / Capital Employed",
    eq_ratio: "EK-Quote",
    anlagenintensitaet: "Anlagenintensität",
    anlagendeckungsgrad: "Anlagendeckungsgrad",
    capital_turnover: "Kapitalumschlag",
    gearing: "Versch.-G.",
    ebitda_interest: "EBITDA-Zinsd.",
    ebitda_oper_sales_ratio: "EBITDA-Marge, bereinigt",
    ebit_oper_sales_ratio: "EBIT-Marge, bereinigt",
    xch_ppe_net: "Sachanlagen",
    ff_debt_ebitda_oper: "Nettoschulden / EBITDA",
    net_debt_ebitda: "Nettosch...",
    ffo_interest: "FFO-Zinsd.",
    xch_assets: "Bilanz",
    xch_sales: "Umsatz",
};
export const caption_units = {
    "0": Globals.hyphen,
    roce : "% p.a.",
    roe : "% p.a.",
    sales_growth: "% p.a.",
    ebitda_growth: "% p.a.",
    gross_sales_ratio: "%",
    ebitda_sales_ratio: "%",
    ebit_sales_ratio: "%",
    netincome_sales_ratio: "%",
    ffo_debt_ratio: "%",
    debt_ebitda_ratio: Globals.hyphen,
    ebit_interest_ratio: Globals.hyphen,
    debt_equity_ratio: "%",
    capex_sales_ratio: "%",
    capex_capitalasset_ratio: "%",
    anlagevermoegen: "Mio. EUR",
    xch_anlagevermoegen: "Mio. EUR",
    working_sales_ratio: "%",
    working_capital_employed_ratio: "%",
    eq_ratio: "%",
    anlagenintensitaet: "%",
    anlagendeckungsgrad: "%",
    capital_turnover: Globals.hyphen,
    gearing: "%",
    ebitda_interest: Globals.hyphen,
    ebitda_oper_sales_ratio: "%",
    ebit_oper_sales_ratio: "%",
    xch_ppe_net: "Mio. EUR",
    ff_debt_ebitda_oper: Globals.hyphen,
    net_debt_ebitda: Globals.hyphen,
    ffo_interest: "%",
    xch_assets: "Mio. EUR",
    xch_sales: "Mio. EUR",
};
export const result_order = [
    `#${_t(ETranslation.growth)}`,
    "sales_growth", "ebitda_growth",
    `#${_t(ETranslation.profitability)}`,
    "ebitda_sales_ratio", "ebit_sales_ratio",
    `#${_t(ETranslation.rentability)}`,
    "roce", "roe",
    `#${_t(ETranslation.capital_structure)}`,
    "eq_ratio", "gearing",
    `#${_t(ETranslation.creditworthiness)}`,
    "ffo_debt_ratio", "ebit_interest_ratio",
    `#${_t(ETranslation.capital_expenditures)}`,
    "capex_sales_ratio", "capex_capitalasset_ratio",
    `#${_t(ETranslation.working_capital)}`,
    "working_sales_ratio", "working_capital_employed_ratio",
];
