import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {average} from "../../helpers/Statistics";
import {EParameters} from "../../models/EParameters";
import {ICountry} from "../../models/ICountry";
import {IImfData} from "../../models/IImfData";
import {ITaxRate} from "../../models/ITaxRate";
import {IXchRate} from "../../models/IXchRate";
import {TasksCalls} from "../../services/TasksCalls";
import {SzTableHelper} from "../widgets/helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {_t, country_map} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {_k} from "../../helpers/Helpers";

const BASE_DIR = Globals.BASE_DIR;

export class MacroEconomy extends BaseModule<any> {

    private readonly html_table: React.RefObject<HTMLTableElement>;

    constructor(props: any, context: any) {
        super(props, context);
        this.html_table = React.createRef();
    }

    public exportAsExcel() {
        if (!this.html_table.current) {
            return;
        }
        const heading_rows = this.html_table.current.querySelectorAll("thead tr");
        const data_rows = this.html_table.current.querySelectorAll("tbody tr");
        const header = [];
        const data = [];
        const columns: ISzTableColumn[] = [];
        heading_rows.forEach( (r, idx) => {
            const hr = [];
            r.querySelectorAll( "td" ).forEach( (d) => {
                const td: ISzTableColumn = {
                    text: d.textContent,
                    options: {
                    },
                };
                if (d.colSpan > 1) {
                    td.options.colSpan = d.colSpan;
                }
                hr.push(td);
            } );
            header.push(hr);
        } );
        data_rows.forEach( (r, idx) => {
            const data_row = [];
            const td = r.querySelectorAll("td");
            td.forEach( (cell, cc) => {
                if (idx === 0) {
                    if (cc > 1) {
                        columns.push({...SzTableHelper.columnMoney(`${cc}`, 2)});
                    } else {
                        columns.push({...SzTableHelper.columnIndex(`${cc}`)});
                    }
                }
                data_row.push(cell.textContent);
            } );
            data.push(data_row);
        });
        const tableProps: ISzTableProperties<any[]> = {
            colCount: 7,
            header,
            data,
            columns,
        };
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(tableProps);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected renderContent() {
        const country_iso_3 = this.getParameterValue(EParameters.FixedCountriesParameter, "DEU");
        const date = this.getParameterValue(EParameters.DateParameter, "31.12.2019");
        const country: ICountry = Globals.countries.find( (i) => country_iso_3 === i.iso_3);

        const labelStyle: CSSProperties = {
            verticalAlign: "middle",
        };
        const fieldStyle: CSSProperties = {
            verticalAlign: "middle",
            textAlign: "right",
        };
        const getVal = (source, field) => {
            const getField = (o) => {
                const f = o[`${field}`];
                if (f === undefined) {
                    return undefined;
                }
                return f;
            };
            let v;
            const isArray = Array.isArray(source);
            if (source && isArray && source.length) {
                v = getField(source[0]);
            }
            if (source && !isArray) {
                v = getField(source);
            }
            return v;
        };
        const asString = (v, isPercent?: boolean) => {
            if (v === undefined) {
                return Globals.hyphen;
            }
            if (v === null) {
                return Globals.hyphen;
            }
            if (isPercent) {
                return Globals.formatter_percent(v);
            } else {
                return Globals.formatter(v);
            }
        };
        const collectXch = (country_id, xch_data: IXchRate[], y: number[]) => {
            const years_object = {};
            y.forEach( (i) => {
                let yo = years_object[i];
                if (!yo) {
                    yo = {
                        value_1231: undefined,
                        values: [],
                        days: {},
                    };
                    years_object[i] = yo;
                }
                const work: IXchRate[] = xch_data.slice();
                while (work.length) {
                    const xch_rate: IXchRate[] = work.splice(0, 1);
                    if (!(xch_rate && xch_rate.length)) {
                        break;
                    }
                    if (xch_rate[0].country_id !== country_id) {
                        continue;
                    }
                    const dd = parseInt(xch_rate[0].date_decimal, 10);
                    if (dd >= (i * 10000) && dd <= (i * 10000) + 1231 )  {
                        yo.values.push(xch_rate[0].from_euro_to_);
                        yo.days[dd] = xch_rate[0].from_euro_to_;
                    }
                    if (dd === (i * 10000) + 1231) {
                        yo.value_1231 = xch_rate[0].from_euro_to_;
                    }
                }
            } );
            return years_object;
        };
        const taxRates: ITaxRate[] = this.data.taxRates;
        const xch: IXchRate[] = this.data.xch;
        let imf_other: IImfData[] = [];
        const imf_deu: IImfData[] = [];
        const imf_usa: IImfData[] = [];
        this.data.imf.forEach( (i) => {
            if (i.country_iso_3 === "DEU") {
                imf_deu.push(i);
                return;
            }
            if (i.country_iso_3 === "USA") {
                imf_usa.push(i);
                return;
            }
            imf_other.push(i);
        } );
        if (country.iso_3 === "USA") {
            imf_other = imf_usa;
        }
        if (country.iso_3 === "DEU") {
            imf_other = imf_deu;
        }
        const years: number[] = this.data.years;
        const id_usa = this.data.id_usa;

        const obj_usa = collectXch(id_usa, xch, years);
        const obj_other = collectXch(country.id, xch, years);

        // console.error(obj_usa);
        // console.error(obj_other);

        const renderTaxRates = () => {
            return years.map( (i) => (<td key={_k("renderTaxRates", i)}  style={fieldStyle}>{asString(getVal(taxRates, `j${i}`))}</td>) );
        };

        const renderFixedDateXch = () => {
            return years.map( (i) => {
                if (country.currency_iso_3 === "EUR") {
                    return (<td key={_k("renderFixedDateXch", i)} style={fieldStyle}>{Globals.formatter(1)}</td>);
                }
                if (obj_other && obj_other[i]) {
                    return (<td key={_k("renderFixedDateXch", i)} style={fieldStyle}>{asString(obj_other[i].value_1231)}</td>);
                }
                return (<td key={_k("renderFixedDateXch", i)} style={fieldStyle}>{Globals.hyphen}</td>);
            });
        };
        const renderFixedDateUsdXch = () => {
            return years.map( (i) => {
                if (obj_other && obj_other[i] && obj_usa && obj_usa[i]) {
                    // console.error(obj_usa[i].value_1231);
                    const vo = isEuro ? 1 : obj_other[i].value_1231;
                    return (<td key={_k("renderFixedDateUsdXch", i)} style={fieldStyle}>{asString(vo / obj_usa[i].value_1231)}</td>);
                }
                return (<td key={_k("renderFixedDateUsdXch", i)} style={fieldStyle}>{Globals.hyphen}</td>);
            });
        };
        const isEuro = country.currency_iso_3 === "EUR";
        const renderAvgXch = () => {
            return years.map( (i) => {
                if (country.currency_iso_3 === "EUR") {
                    return (<td key={_k("renderAvgXch", i)} style={fieldStyle}>{Globals.formatter(1)}</td>);
                }
                if (obj_other && obj_other[i]) {
                    return (<td key={_k("renderAvgXch", i)} style={fieldStyle}>{asString(average(obj_other[i].values))}</td>);
                }
                return (<td key={_k("renderAvgXch", i)} style={fieldStyle}>{Globals.hyphen}</td>);
            });
        };
        const renderAvgUsdXch = () => {
            return years.map( (i) => {
                if (obj_other && obj_other[i] && obj_usa && obj_usa[i]) {
                    const values = [];
                    Object.keys( obj_usa[i].days ).forEach( (d) => {
                        const v_usd = obj_usa[i].days[d];
                        const v_other = isEuro ? 1 : obj_other[i].days[d];
                        if (v_usd !== undefined && v_other !== undefined) {
                            values.push( v_other / v_usd );
                        }
                    } );
                    return (<td key={_k("renderAvgUsdXch", i)} style={fieldStyle}>{asString(average(values))}</td>);
                }
                return (<td key={_k("renderAvgUsdXch", i)} style={fieldStyle}>{Globals.hyphen}</td>);
            });
        };
        // inflation
        const imfKeyForYears = (imf: IImfData[], key: string) => {
            const ret = {};
            const imf_by_key = imf.filter( (i) => i.key === key );
            years.forEach( (y) => {
                const e = imf_by_key.find( (i) => i.year === ("" + y));
                if (e) {
                    ret [y] = e.value;
                }
            });
            return ret;
        };
        const inflation = imfKeyForYears(imf_other, "inflation");
        const inflation_deu = imfKeyForYears(imf_deu, "inflation");
        const inflation_usa = imfKeyForYears(imf_usa, "inflation");
        const renderInflation = () => {
            return years.map( (i) => {
                if (inflation && inflation[i]) {
                    return (<td key={_k("renderInflation", i)} style={fieldStyle}>{asString(inflation[i])}</td>);
                }
                return (<td key={_k("renderInflation", i)} style={fieldStyle}>{Globals.hyphen}</td>);
            });
        };
        const renderDiffInflation = (diff_source) => {
            return years.map( (i) => {
                if (inflation && inflation[i] && diff_source && diff_source[i]) {
                    return (<td key={_k("renderDiffInflation", i)} style={fieldStyle}>{asString((inflation[i] - diff_source[i]))}</td>);
                }
                return (<td key={_k("renderDiffInflation", i)} style={fieldStyle}>{Globals.hyphen}</td>);
            });
        };
        const renderImfForYears = (imf: IImfData[], key: string, isPercent: boolean) => {
            const source = imfKeyForYears(imf, key);
            return years.map( (i) => {
                if (source && source[i]) {
                    return (<td key={_k("renderImfForYears", i)} style={fieldStyle}>{asString(source[i])}</td>);
                }
                return (<td key={_k("renderImfForYears", i)} style={fieldStyle}>{Globals.hyphen}</td>);
            });
        };
        const country_name = country_map[country.iso_3].name;
        return (
            <div>
                <table className={"sz-table bp3-html-table bp3-html-table-condensed sz-small-padding bp3-html-table-striped bp3-interactive"} ref={this.html_table}>
                    <thead>
                    <tr><td className={"sz-bold"} style={{verticalAlign: "middle", display: "flex"}} colSpan={7}><img src={`${BASE_DIR}images/flags/${country.iso_2.toLowerCase()}.jpg`} style={{height: 19}}/>&nbsp;{country_name}&nbsp;({country.currency_iso_3})</td></tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        {years.map( (i) => (<td key={_k("renderTableHeader", i)} style={fieldStyle}>{i}</td>) )}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.corporate_tax_rate)}</td>
                        <td>% p.a.</td>
                        {renderTaxRates()}
                    </tr>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.exchange_rate_eoy)}</td>
                        <td>{country.currency_iso_3}/EUR</td>
                        {renderFixedDateXch()}
                    </tr>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.exchange_rate_avg)}</td>
                        <td>{country.currency_iso_3}/EUR</td>
                        {renderAvgXch()}
                    </tr>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.exchange_rate_eoy)}</td>
                        <td>{country.currency_iso_3}/USD</td>
                        {renderFixedDateUsdXch()}
                    </tr>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.exchange_rate_avg)}</td>
                        <td>{country.currency_iso_3}/USD</td>
                        {renderAvgUsdXch()}
                    </tr>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.inflation)}</td>
                        <td>% p.a.</td>
                        {renderInflation()}
                    </tr>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.inflation_differential, country.iso_3)}</td>
                        <td>% p.a.</td>
                        {renderDiffInflation(inflation_deu)}
                    </tr>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.gdp_growth)}</td>
                        <td>% p.a.</td>
                        {renderImfForYears(imf_other, "Real GDP growth", true)}
                    </tr>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.unemployment_rate)}</td>
                        <td>% p.a.</td>
                        {renderImfForYears(imf_other, "Unemployment rate", true)}
                    </tr>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.budget_deficit)}</td>
                        <td>% {_t(ETranslation.gdp)} p.a.</td>
                        {renderImfForYears(imf_other, "General government net lending/borrowing", true)}
                    </tr>
                    <tr>
                        <td style={labelStyle}>{_t(ETranslation.public_debt)}</td>
                        <td>% {_t(ETranslation.gdp)}</td>
                        {renderImfForYears(imf_other, "General government gross debt", true)}
                    </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
            </div>

        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
}
