import {fromStr} from "../../tools/DateTools";

export class DeDate {
    public static getPercentYTD(dateDE: string): number {
        const date = fromStr(dateDE).toJSDate();// moment(dateDE, "DD.MM.YYYY").toDate();
        const start = new Date(date.getFullYear(), 0, 0);
        const diff = date.getTime() - start.getTime();
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        return day === 366 ? 1.0 : day / 365;
    }
}
