import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {HighlightValue} from "../chart-plugin/HighlightValue";
import {RegressionLine} from "../chart-plugin/RegressionLine";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    HighlightValue,
    LogarithmicScale, PointElement, LineElement, Legend, RegressionLine,ChartDataLabels
);

export enum EChartType {
    bar= "bar",
    bubble= "bubble",
    line= "line",
}
export class PdfChartToImage {
    public static getDataUri(chart_type: EChartType, width: number, height: number, chart_options: any, chart_data: any, padding: number = 15): string{
        /*
        *
        * options: {
        layout: {
            padding: 20
        }
    }
        * */
        const canvas_div = document.createElement('div');
        const canvas = document.createElement('canvas');
        canvas_div.classList.add("sz-hide");
        canvas_div.appendChild(canvas);
        document.body.appendChild(canvas_div);
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        const chart = new ChartJS(ctx, {
            type: chart_type,
            data: chart_data,
            options: {
                animation: false,
                layout: {
                    padding
                },
                ...chart_options
            } as any
        });
        chart.resize(width, height);
        chart.update();
        const s = chart.toBase64Image();

        chart.destroy();
        canvas_div.remove();

        return s;
    }
}
