import {ELayoutColumnWidth} from "../models/ELayoutColumnWidth";
import {IColumnLayout, IColumnLayoutColumn, IColumnLayouter} from "../models/IColumnLayout";

export class ColumnLayouterImpl implements IColumnLayouter {

    public static addColumn(target: IColumnLayout, width: ELayoutColumnWidth): IColumnLayouter {
        const currentColumn: IColumnLayoutColumn = {
            modules: [],
            width,
        };
        target.columns.push(currentColumn);
        return new ColumnLayouterImpl(currentColumn);
    }

    public currentColumn: IColumnLayoutColumn;

    constructor(currentColumn: IColumnLayoutColumn) {
        this.currentColumn = currentColumn;
    }

    public addModule(moduleIndex: any): IColumnLayouter {
        this.currentColumn.modules.push(moduleIndex);
        return this;
    }
}
