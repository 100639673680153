import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {ISzNumericProperties, SzNumeric} from "../../widgets/SzNumeric";
import {ISzNumericPercentProperties, SzNumericPercent} from "../../widgets/SzNumericPercent";
import {CombiParameter} from "../CombiParameter";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {ISzSelectProperties, SzSelect} from "../../widgets/SzSelect";
import {EValueName} from "../../../models/EValueName";

const label_style: CSSProperties={
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
};

export class ReleveredBetaParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.ReleveredBetaParameter];
    }

    protected getWidthClass(): string {
        return "fixed-width-500";
    }

    protected async initialize() {
        const state: {[k: string]: any} = {};
        state[EParameters.LeverageParameter] = parseFloat(SessionStore.get(EParameters.LeverageParameter));
        state[EParameters.DebtBetaValueParameter] = parseFloat(SessionStore.get(EParameters.DebtBetaValueParameter));
        state[EParameters.TaxRateParameter] = parseFloat(SessionStore.get(EParameters.TaxRateParameter));
        state[EParameters.TaxShieldTargetCompanyRiskEffect] = SessionStore.get(EParameters.TaxShieldTargetCompanyRiskEffect);
        state[EParameters.DebtBetaCalcParameter] = SessionStore.get(EParameters.DebtBetaCalcParameter);
        state[EParameters.BetaUnleverdOptions] = SessionStore.get(EParameters.BetaUnleverdOptions);
        return state;
    }

    protected renderMenuContent(): any {
        // window.console.log("Desired result",SessionStore.getGlobalVar(EValueName.unlevered_beta,""));

        return (
            <div className={"sz-row"}>
                <div style={{paddingTop: 10, paddingRight: 10}} className={"sz-row"}>
                    {this.renderBetaUnlevered()}
                    {this.renderLeverage()}
                    {this.renderTaxRate()}
                    {this.renderTaxShield()}
                    {this.renderDebtBeta()}
                    {this.renderDebtBetaMan()}
                </div>
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.ReleveredBetaParameter))}
            </div>
        );
    }

    protected getLabel(): any {
        const options = ParameterOptions.options[EParameters.TaxShieldTargetCompanyRiskEffect];
        const selected = options.find((e) => e.value === this.state[EParameters.TaxShieldTargetCompanyRiskEffect]);
        const label = selected ? selected.label : Globals.hyphen;
        return `${label} | ${Globals.formatter_percent(this.state[EParameters.LeverageParameter], 0, 0)}  | ${Globals.formatter_percent(this.state[EParameters.TaxRateParameter], 0, 0)} | ${Globals.formatter(this.state[EParameters.DebtBetaValueParameter])}`;
    }
    private renderBetaUnlevered() {
        // console.error(this.state[EParameters.FixedCountriesParameter]);
        const options = (ParameterOptions.options[EParameters.BetaUnleverdOptions]);
        const props: ISzSelectProperties = {
            options,
            value:  this.state[EParameters.BetaUnleverdOptions],
            onValueChanged: (v: any) => this.onChangeValue(EParameters.BetaUnleverdOptions, v),
        };
        return this.renderColumn("50%", "Beta unlevered", ()=>{
            // window.console.log("betaaaaa:",props);
            return (
                <SzSelect {...props} />
            );
        }, label_style);
    }
    private onChangeValue(field: EParameters, _value: any) {
        const _valueAsNumber = _value;
        const state: {[k: string]: any} = {};
        state[field] = _valueAsNumber;
        this.setState(state);
        this.fireValueChange(field, _valueAsNumber);
    }
    private renderDebtBeta() {
        const options = ParameterOptions.options[EParameters.DebtBetaCalcParameter];
        return this.renderColumn("50%", _t(ETranslation.debt_beta), ()=>{
            return (
                <div style={{marginLeft: -5, marginRight: -5}}>
                    {this.renderOptionsExt(options, this.state.DebtBetaCalcParameter, EParameters.DebtBetaCalcParameter, "DebtBetaCalcParameter")}
                </div>
            );
        });
    }
    private renderDebtBetaMan() {
        // DebtBetaValueParameter!!!
        const value: number =  this.state[EParameters.DebtBetaValueParameter];
        const inputProps: ISzNumericProperties = {
            value,
            onValueChanged: (v: number) => this.onChangeValue(EParameters.DebtBetaValueParameter, v),
        };
        return this.renderColumn("50%", "", ()=>{
            return (
                <SzNumeric {...inputProps} />
            );
        }, label_style);
    }
    private renderLeverage() {
        const value: number =  this.state[EParameters.LeverageParameter];
        const inputProps: ISzNumericPercentProperties = {
            value,
            defaultValue: value,
            onValueChanged: (v: number) => this.onChangeValue(EParameters.LeverageParameter, v),
        };
        return this.renderColumn("50%", _t(ETranslation.gearing), ()=>{
            return (
                <SzNumericPercent {...inputProps} />
            );
        }, label_style);
    }
    private renderTaxRate() {
        const value: number =  this.state[EParameters.TaxRateParameter];
        const inputProps: ISzNumericPercentProperties = {
            value,
            onValueChanged: (v: number) => this.onChangeValue(EParameters.TaxRateParameter, v),
        };
        return this.renderColumn("50%", _t(ETranslation.tax_rate), ()=>{
            return (
                <SzNumericPercent {...inputProps} />
            );
        }, label_style);
    }

    private renderTaxShield() {
        const options = ParameterOptions.options[EParameters.TaxShieldTargetCompanyRiskEffect];
        return this.renderColumn("50%", _t(ETranslation.tax_shield), ()=>{
            return (
                <div style={{marginLeft: -5, marginRight: -5}}>
                    {this.renderOptionsExt(options, this.state.TaxShieldTargetCompanyRiskEffect, EParameters.TaxShieldTargetCompanyRiskEffect, "TaxShieldTargetCompanyRiskEffect")}
                </div>
            );
        });
    }
}
