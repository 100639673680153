import {EDebtBetaFilter, ERawBetaType, ETaxShieldType} from "../models/chef/UnleveredBeta";

export const EPeriodToCompress = {
    "1d": 4, "1w": 1,
    "2d": 4, "2w": 1, "2bw": 2,
    "3d": 4, "3w": 1, "3bw": 2,
    "5w": 1, "5bw": 2, "5m": 3,
};
export const ConvertDebtBetaParam = {
    nicht_anwenden: EDebtBetaFilter.DONT_USE,
    anwenden: EDebtBetaFilter.USE,
    anwenden_debt_eq_ratio_over_100: EDebtBetaFilter.USE_ONLY_IF_GEARING_OVER_100,
    anwenden_debt_eq_ratio_over_200: EDebtBetaFilter.USE_ONLY_IF_GEARING_OVER_200,
    anwenden_debt_eq_ratio_over_300: EDebtBetaFilter.USE_ONLY_IF_GEARING_OVER_300,
};
export const ConvertTaxShieldParam = {
    unsicher: ETaxShieldType.UNSAFE,
    sicher: ETaxShieldType.SAFE_TAX_RATE,
    "sicher-steueraufwand": ETaxShieldType.SAVE_TAX_EXPENSE,
    "sicher-steuerzahlung": ETaxShieldType.SAVE_TAX_PAYMENT,
};
export const ConvertAdjustmentParam = {
    rawbeta: ERawBetaType.RAW_BETA,
    blumeadjusment: ERawBetaType.RAW_BETA_BLUME_ADJUSTED,
    totalrawbeta: ERawBetaType.TOTAL_RAW_BETA,
};
export const ConvertNegativGearingParam = {
    verwenden: false,
    auf_null_setzen: true,
};
export const ConvertDistanceParam = {
    average: false,
    end: true,
};
