import * as React from "react";
import {Plugin, Chart} from 'chart.js';

class TooltipLineClass{
    public id = "tooltip_line";
    public beforeDraw(c, args, options){
        if(options?.enabled && c?.tooltip?._active && c?.tooltip?._active?.length){
            const active_point = c.tooltip._active[0];
            const x = active_point.element.x;
            const y = active_point.element.y;
            const chart: Chart = c;
            const ctx: CanvasRenderingContext2D = (chart as Chart).ctx;

            ctx.save();
            ctx.beginPath();
            ctx.lineWidth= .5;
            ctx.strokeStyle = "#f47a22";
            ctx.setLineDash([5,7]);
            ctx.moveTo(x, chart.chartArea.top);
            ctx.lineTo(x, y);
            ctx.stroke();
            ctx.restore();

            ctx.save();
            ctx.beginPath();
            ctx.lineWidth= .5;
            ctx.strokeStyle = "#f47a22";
            ctx.moveTo(x, chart.chartArea.bottom);
            ctx.lineTo(x, y);
            ctx.stroke();
            ctx.restore();

        }
    }
}

export const TooltipLine = (new TooltipLineClass()) as Plugin;
