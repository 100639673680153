import {Globals} from "../const/Globals";
import {PublicDbCalls} from "./PublicDbCalls";
import {ServiceCalls} from "./ServiceCalls";

export class StoredSearch {
    public static getSave() {
        const searches = this.searches;
        const sectors = searches && searches.result && Array.isArray(searches.result) ? searches.result : [];
        return sectors;
    }
    public static async get() {
        if (this.searches) {
           return this.searches;
        }
        let searches = null;
        if (Globals.isRegistred) {
            const services = new ServiceCalls();
            searches = await services.loadLongList();
        } else {
            const db = new PublicDbCalls();
            searches = await db.getPublicSearch();
        }
        this.searches = searches;
        return searches;
    }
    private static searches: any;
}
