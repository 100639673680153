import {Button, Classes, Icon, Intent} from "@blueprintjs/core";
import {IconName} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {EContentType} from "../../const/EContentType";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {EModuleNames} from "../../models/EModuleNames";
import {EParameters} from "../../models/EParameters";
import {IParameterChanged} from "../../models/IParameterChanged";
import {IParameterConfig} from "../../models/IParameterConfig";
import {EProjectUserRole} from "../../models/IProject";
import {IToolBarParameter} from "../../models/IToolBarParameter";
import {IOptionEntry} from "../../models/IOptionEntry";
import {ParameterConfigs} from "../../const/ParameterConfigs";
import {_k} from "../../helpers/Helpers";

export abstract class ToolBarParameter<P extends IToolBarParameter> extends React.Component<P, any> {
    public static getLabel(parameter: EParameters){
        const p = ParameterConfigs.configs[parameter];
        return p ? p.name : Globals.hyphen;
    }
    protected config: IParameterConfig;
    get tp_disabled(): boolean {
        return false;
    }
    get disabled(): boolean {
        return false;
    }
    get icon(): IconName {
        if(this.config.contentType === EContentType.Admin && !Globals.isAdmin){
            return "lock";
        }
        if (Globals.isRegistred) {
            if ( Globals.ownsPackage < this.config.minPackage ) {
                return "lock";
            }
        }
        return null; // Globals.isRegistred ? null : this.config.contentType === EContentType.Premium || this.config.contentType === EContentType.Freemium ? "lock" : null;
    }

    get label(): string {
        return this.getLabel();
    }
    get labelExt(): string {
        return this.getLabelExt();
    }
    get name(): string {
        return this.config.name;
    }
    get component(): string {
        return "" + this.props.primaryComponent;
    }

    public static getPdfLabel(): any{
        return "";
    }
    protected constructor(props: P, context: any) {
        super(props, context);
        this.config = this.getConfig();
        const isLoading = true;
        this.state = {
            isLoading,
            isOpen: false,
        };
    }
    protected putPacketOptionParameter(parameter: EParameters,values: {o: IOptionEntry[], s: string}[]){
        const indexes: number[] = [];
        values.forEach( (v) => {
            const idx = v.o.findIndex((o)=>o.value===v.s);
            indexes.push(idx);
        });
        Globals.addPackedParameter(parameter, indexes);
    }
    protected putPacketParameter(parameter: EParameters,values: number[]){
        Globals.addPackedParameter(parameter, values);
    }
    protected getParameterConfig(parameter: EParameters): IParameterConfig{
        return ParameterConfigs.configs[parameter];
    }
    public render() {
        if(this.props.primaryComponent===EParameters.CompaniesParameter){
            switch (Globals.currentModule) {
                case EModuleNames.BetaFactor:
                case EModuleNames.CostOfCapital:
                case EModuleNames.Multiples:
                case EModuleNames.Benchmarking:
                    return null;
            }
        }
        if (this.state.isLoading) {
            this.loadComponent();
            return (
                <Button intent={Intent.NONE} minimal={true} className={`${Classes.SKELETON} sz-toolbar-button`} icon={this.icon}>
                    {this.renderName()}
                    <div className={"sz-property-value"}>&nbsp;</div>
                </Button>
            );
        }
        return this.renderContent();
    }

    public fireChanges(p: IParameterChanged[]) {
        if (this.props && this.props.handleValuesChanged) {
            this.props.handleValuesChanged(p);
        }
    }

    public safeGetLabel() {
        try {
            const l = this.getLabel();
            return l.replace(new RegExp("\\*", "g"), "");
        } catch (er) {
            console.error(er);
        }
        return "—";
    }

    protected abstract initialize(): Promise<any>;
    protected abstract getConfig(): IParameterConfig;
    protected abstract getLabel(): string;
    protected abstract renderContent();

    protected getLabelExt(): string{
        return undefined;
    };
    protected renderNoOp() {
        const nopeActions = this.config.nopeAction;
        const contentType = this.config.contentType;
        const contentTypeText = (ct: EContentType) => {
            switch (ct) {
                case EContentType.Freemium: return (<div className={"sz-loco-text"}>Diese Funktion steht Ihnen nach Ihrer kostenlosen<br/>Registrierung zur Verfügung.</div>);
                case EContentType.Elite:
                case EContentType.Premium: return (<div className={"sz-loco-text"}>Diese Funktion ist kostenpflichtig.<br/>Bitte erwerben Sie folgendes Modul zur Aktivierung.</div>);
                case EContentType.Admin: return (<div className={"sz-loco-text"}>Diese Funktion ist nicht verfügbar.</div>);
                case EContentType.Free:
                default: return null;
            }
        };
        const renderButtons = (ct: EContentType) => {
            const aButton = ( caption, url ) => {
                return <Button intent={"primary"} text={caption} onClick={() => window.open(url, "_self") } />;
            };
            const model = nopeActions ? nopeActions : [];
            return model.map( (m) => aButton(m.caption, m.url) );
        };
        const justify = nopeActions && nopeActions.length > 1 ? "space-between" : "center";
        return (
            <div className={"sz-loco"}>
                <div  className={"sz-loco-icon"}><Icon icon={"unlock"} color={"#5c7080"} size={48} /></div>
                {contentTypeText(contentType)}
                <div  className={"sz-loco-buttons"} style={{justifyContent: justify}}>
                    {renderButtons(contentType)}
                </div>
            </div>
        );
    }
    protected renderName() {
        const name = this.config.name;
        const isPro = this.config.isPro;
        const supStyle: CSSProperties = {
            fontWeight: "normal",
            fontSize: "70%",
            color: "#DB3737",
        };
        const lb = isPro ? "Pro" : " ";
        return (<span className={"sz-property-name"} >{name}<sup style={supStyle}>{lb}</sup></span>);
    }
    protected renderColumn(width: any, label:string, content, label_style?:CSSProperties, sub_element?){
        const _width = parseInt(width, 10);
        let w1 = width;
        let w2 = width;
        if(width!=="50%"){
            w1 = `${_width}%`;
            w2 = `${100 - _width}%`;
        }
        return (
            <div className={"sz-row"} style={{marginBottom: 10, width: "100%"}}>
                <div style={Object.assign({width: w1, fontWeight: 600, paddingLeft: 10, paddingTop: 10}, label_style)}>{label}{sub_element}</div>
                <div style={{width: w2}}>{content()}</div>
            </div>
        );
    }
    protected renderHelpText(items, maxWidth?, width?){
        if(!Array.isArray(items)){
            return null;
        }
        if(width === undefined){
            width = "100%";
        }
        return (
            <div style={{boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15)", padding: 10, backgroundColor: "#F5F8FA", maxWidth, width}}>
                {items.map((i, idx)=> <p key={_k("HelpText", idx)} className={"bp3-text-small bp3-text-muted"}>{i}</p>)}
            </div>
        );
    }
    private loadComponent() {
        ( async () => {
            let newState = {};
            try {
                newState = await this.initialize();
            } catch (exp) {
                console.error(exp);
            }
            this.setState({...newState, isLoading: false});
        } )();
    }
}
