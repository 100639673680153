export class RatingDebtBetaSystematicRisk {
    public static data = {
        aaa: 0.9918,
        aa1: 0.9806,
        aa2: 0.9695,
        aa3: 0.9589,
        a1: 0.9483,
        a2: 0.9377,
        a3: 0.8943,
        baa1: 0.8510,
        baa2: 0.8076,
        baa3: 0.7186,
        ba1: 0.6297,
        ba2: 0.5407,
        ba3: 0.4795,
        b1: 0.4183,
        b2: 0.3572,
        b3: 0.3572,
        caa1: 0.3572,
        caa2: 0.3572,
        caa3: 0.3572,
        ca: 0.3572,
        c: 0.3572,
    };
}
