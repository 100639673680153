export class Regression {
    public Slope: number; // faktor

    public K: number = 2;
    public DataPoints: number = 0;
    public MeanX: number; // mean
    public MeanY: number; // +

    public SumQDX: number;
    public SumQDY: number;
    public SumQDResiduals: number;
    public VarianceX: number; // +
    public VarianceY: number; // variance

    public Intercept: number; // Verschiebung- x achse
    public R2: number; // R2
    public AdjR2: number; //
    public R: number; // R

    public StandardErrorBeta: number;
    public StandardErrorSlope: number;
    public StandardErrorIntercept: number;

    public TTestSlope: number; // TTest Betafaktor
    public TTestIntercept: number;

    public FTest: number; // F-Test

    public dt_start: number;
    public dt_end: number;

    public calculate(x, y, value_idx: number) {
        const lr = {
            n: 0,
            averageX: 0,
            averageY: 0,
            sumQDX: 0,
            tss: 0,
            slope: 0,
            intercept: 0,
            varX: 0,
        };
        const n = x.length;

        let sumY = 0;
        let sumX = 0;

        const loop = (hndSkiped, call) => {
            for (let i = 0; i < x.length; i++) {
                call(i);
            }
        };

        loop(true, (i) => {
            sumY += y[i][value_idx] / 100;
            sumX += x[i][value_idx] / 100;
        });

        const averageX = sumX / n;
        const averageY = sumY / n;

        this.MeanX = averageX;
        this.MeanY = averageY;

        let sumDividend = 0;
        let sumDivisor = 0;
        let tss = 0;
        let sumx2 = 0;
        loop(false, (i) => {
            const yi = y[i][value_idx] / 100;
            const xi = x[i][value_idx] / 100;
            const deltaX = xi - averageX;
            const deltaY = yi - averageY;
            sumDividend += deltaX * (yi - averageY);
            sumDivisor += Math.pow(deltaX, 2);
            tss += Math.pow(deltaY, 2);
            sumx2 += xi * xi;
        });

        this.DataPoints = n;
        this.SumQDX = sumDivisor;
        this.SumQDY = tss;
        this.Slope = sumDividend / sumDivisor;
        this.Intercept = averageY - (this.Slope * averageX);
        this.VarianceX = this.SumQDX / (n - 1);
        this.VarianceY = this.SumQDY / (n - 1);

        lr.n = n;
        lr.averageX = averageX;
        lr.averageY = averageY;
        lr.sumQDX = sumDivisor;
        lr.tss = tss;
        lr.slope = sumDividend / sumDivisor;
        lr.intercept = averageY - (lr.slope * averageX);
        lr.varX = sumDivisor / (n - 1);
        let rss = 0;
        loop(false, (i) => {
            const yi = y[i][value_idx] / 100;
            const xi = x[i][value_idx] / 100;
            const ydach = lr.intercept + (lr.slope * xi);
            rss += Math.pow(yi - ydach, 2);
        });
        this.SumQDResiduals = rss;
        this.R2 = 1 - (rss / tss);
        this.R = Math.sqrt(this.R2);
        this.AdjR2 = 1 - ((rss / tss) * ((this.DataPoints - 1) / (this.DataPoints - this.K)));

        this.StandardErrorBeta = Math.sqrt(this.SumQDResiduals / (this.DataPoints - this.K));
        this.StandardErrorIntercept = this.StandardErrorBeta * Math.sqrt(sumx2 / (this.DataPoints * this.SumQDX));
        this.StandardErrorSlope = this.StandardErrorBeta / Math.sqrt(this.SumQDX);

        this.TTestIntercept = this.Intercept / this.StandardErrorIntercept;
        this.TTestSlope = this.Slope / this.StandardErrorSlope;

        this.FTest = (this.R2 / (1 - this.R2)) * ((this.DataPoints - this.K) / (this.K - 1));

        if (x.length !== n) {
            // console.error("skiped-> " + (x.length - n));
        }

    }
}
