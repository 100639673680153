import {ArrayCacheTools, CacheTools} from "../../helpers/CacheTools";
import {IRawBetaResponse, RawBetaParams, RawBetaResponse} from "../../models/chef/RawBeta";
import {
    UnleveredBetaParams,
    Company,
} from "../../models/chef/UnleveredBeta";
import {EParameters} from "../../models/EParameters";
import {CacheEntry, ChefCalls} from "../../services/ChefCalls";
import {BaseDataProvider} from "./BaseDataProvider";
import {
    ConvertAdjustmentParam, ConvertDebtBetaParam,
    ConvertDistanceParam,
    ConvertNegativGearingParam,
    ConvertTaxShieldParam,
    EPeriodToCompress
} from "../../const/RawBetaParamConv";
import {SessionStore} from "../../const/SessionStore";
import {fromStr} from "../../tools/DateTools";
import {getRatingIndication} from "../../helpers/Helpers";

const market_data_avg_cache: CacheEntry = ArrayCacheTools("market_data_avg_cache", "companies", CacheTools.Company_Array());
const financial_reports_cache: CacheEntry = ArrayCacheTools("financial_reports_cache", "companies", CacheTools.Company_Array());
const beta_compare_filter_cache: CacheEntry = ArrayCacheTools("beta_compare_filter_cache", "companies", CacheTools.Company_Array());
const company_cache: CacheEntry = ArrayCacheTools("company_cache", "companies", CacheTools.Company_Array());
const rawBetaAllCache: CacheEntry = ArrayCacheTools("raw_beta_all_cache", "companies", CacheTools.Company_Array());

const unlevered_betas_cache: CacheEntry = ArrayCacheTools("unlevered_betas_cache", "companies", CacheTools.Company_Objects("company_id"));


const all_periods = ["1d", "1w", "2d", "2w", "2bw", "3d", "3w", "3bw", "5w", "5bw", "5m"];
export class ChefBetasDataProvider extends BaseDataProvider {
    protected initProvider() {
        this.className = "ChefBetasDataProvider";
        this.parameterMapping = [
            EParameters.CompaniesParameter,
            EParameters.DateParameter,
            EParameters.PeriodsParameter,
            EParameters.IndexTypeParameter,
            EParameters.ReturnTypeParameter,
            EParameters.CompositionTypeParameter,
            EParameters.AdjusmentBetaParameter,
            EParameters.DebtbetaParameter,
            EParameters.GearingPeersParameter,
            EParameters.IndexReturnParameter,
            EParameters.NegativeVerschuldungParameter,
            EParameters.TaxshieldParameter,
            EParameters.MahalanobicDistanceParameter,
            EParameters.CreditSpreadsParameter,
            EParameters.MarketRiskPremiumParameter,
            EParameters.LocalTypeParameter,
            EParameters.WeekDayParameter,
        ];
    }

    protected update() {
        const company_ids: string[] = this.mappedParameters.get(EParameters.CompaniesParameter);
        const deadline: string = SessionStore.get(EParameters.DateParameter);
        const period_next = this.mappedParameters.get(EParameters.WeekDayParameter)[0];
        const composition_type = this.mappedParameters.get(EParameters.CompositionTypeParameter)[0];
        const return_type = this.mappedParameters.get(EParameters.ReturnTypeParameter)[0];
        const index_type = this.mappedParameters.get(EParameters.IndexTypeParameter)[0];
        const fx = this.mappedParameters.get(EParameters.LocalTypeParameter)[0];

        const period = this.mappedParameters.get(EParameters.PeriodsParameter)[0];
        const years = parseInt(period, 10);

        const adjustment_param = this.mappedParameters.get(EParameters.AdjusmentBetaParameter)[0];

        // debt beta
        const debt_beta_param  = this.mappedParameters.get(EParameters.DebtbetaParameter)[0];
        const credit_spreads_param  = this.mappedParameters.get(EParameters.CreditSpreadsParameter)[0];
        const market_risk_param = parseFloat(this.mappedParameters.get(EParameters.MarketRiskPremiumParameter)[0]);

        // gearing
        const distance_param = this.mappedParameters.get(EParameters.MahalanobicDistanceParameter)[0];
        const negativ_gearing_param = this.mappedParameters.get(EParameters.NegativeVerschuldungParameter)[0];
        const gearing_peers = this.mappedParameters.get(EParameters.GearingPeersParameter)[0];

        // tax shield
        const tax_shield_param = this.mappedParameters.get(EParameters.TaxshieldParameter)[0];

        const db = new ChefCalls();

        (async () => {
            const raw_beta_parameters: RawBetaParams = {
                companies: company_ids,
                selected_period: period,
                deadline,
                period_next,
                composition_type,
                return_type,
                index_type,
                fx
            };
            const raw_beta_parameters_ext: RawBetaParams = {
                betas_before: {count: years * 4, type: 1, step_size: 3},
                companies: company_ids,
                selected_period: null,
                years: [years],
                periods_years: {},
                deadline,
                period_next,
                composition_type,
                return_type,
                index_type,
                fx
            };
            raw_beta_parameters_ext.periods_years[years]=[EPeriodToCompress[period]];
            const raw_betas: RawBetaResponse = await db.getRawBeta(raw_beta_parameters, rawBetaAllCache);

            const companies: Company[] = [];
            const companies_2: Company[] = [];
            Object.keys(raw_betas).forEach((company_id)=>{
                const r: IRawBetaResponse = raw_betas[company_id];
                const rb = {};
                const rb_2 = {};
                all_periods.forEach((p)=>{
                    if(r.periods && r.periods[p]){
                        rb[p] = {
                            beta: r.periods[p].Slope,
                            r: r.periods[p].R,
                        };
                        if(p===period){
                            rb_2[p] = {
                                beta: r.periods[p].Slope,
                                r: r.periods[p].R,
                            };
                        }
                    }
                });
                companies_2.push({company_id: r.company_id, raw_beta: rb_2});
                companies.push({company_id: r.company_id, raw_beta: rb});
            });
            const unlevered_beta_params: UnleveredBetaParams = {
                companies,
                deadline,
                period: years, // todo: rename period to years
                raw_beta_type: ConvertAdjustmentParam[adjustment_param],
                // gearing
                use_deadline: ConvertDistanceParam[distance_param],
                dont_use_negativ_gearing: ConvertNegativGearingParam[negativ_gearing_param],
                gearing_peers,
                // tax shield
                tax_shield_type: ConvertTaxShieldParam[tax_shield_param],
                // debt beta
                debt_beta_filter: ConvertDebtBetaParam[debt_beta_param],
                sector_id: credit_spreads_param === "all_sector" ? 0 : undefined,
                market_risk_premium: market_risk_param,
            };
            const unlevered_beta_params_ext: UnleveredBetaParams = {
                companies: companies_2,
                deadline,
                period: years, // todo: rename period to years
                raw_beta_type: ConvertAdjustmentParam[adjustment_param],
                // gearing
                use_deadline: ConvertDistanceParam[distance_param],
                dont_use_negativ_gearing: ConvertNegativGearingParam[negativ_gearing_param],
                gearing_peers,
                // tax shield
                tax_shield_type: ConvertTaxShieldParam[tax_shield_param],
                // debt beta
                debt_beta_filter: ConvertDebtBetaParam[debt_beta_param],
                sector_id: credit_spreads_param === "all_sector" ? 0 : undefined,
                market_risk_premium: market_risk_param,
            };

            const unlevered_betas = await db.getUnleveredBeta(unlevered_beta_params, unlevered_betas_cache);
            const comp = await db.getCompanies(company_ids, company_cache);
            const beta_filter_data = await db.getBetaCompareFilterData(company_ids, deadline, beta_compare_filter_cache);
            const financial_reports = await db.getFinancialReports(company_ids, fromStr(deadline).endOf("month").asDeDate(), financial_reports_cache);
            const market_data_avg = await db.getMarketDataAvg(company_ids, deadline, years, market_data_avg_cache);

            const data = {
                companies: comp,
                company_ids,
                risk_monitor_parameters: {...raw_beta_parameters_ext},
                raw_beta_parameters_ext,
                unlevered_beta_params_ext,
                unlevered_betas,
                raw_betas,
                beta_filter_data,
                financial_reports,
                market_data_avg,
            };
            data.risk_monitor_parameters.betas_before = undefined;

            const _unlevered_betas = {};
            company_ids.forEach((cid)=>{
                const ubm = unlevered_betas[cid];
                if(!ubm){
                    return;
                }
                const ub = ubm[period];
                if(ub){
                    if(ub.components?.debt_beta){
                        for(const obj_key in ub.components.debt_beta){
                            if(ub.components.debt_beta.hasOwnProperty(obj_key)){
                                const r = ub.components.debt_beta[obj_key];
                                r.rating_indikation= isFinite(r.rating_score) && r.rating_score!==null ? getRatingIndication(r.rating_score) : "";
                            }
                        }
                    }
                    _unlevered_betas[cid] = ub;
                }
            });
            // console.error(financial_reports);
            SessionStore.putItem("financial_reports" as EParameters, financial_reports, true);
            SessionStore.putItem("unlevered_betas" as EParameters, _unlevered_betas, true);
            this.afterUpdate(data);
        })();
    }
}
