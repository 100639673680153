import {ICompanyBase} from "../../../tables/CompanyBase";
import {Icon} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {EventBus} from "../../../services/EventBus";
import {ICompanySearchResult} from "../../../models/ICompanySearchResult";
import {SessionStore} from "../../../const/SessionStore";

export const  removeCompany = (e, the_other, cid) => {
    if(e){
        e.stopPropagation();
    }
    const companySearch: ICompanySearchResult[] = SessionStore.getItem("CompanySearch", []);
    const item = companySearch.find((i) => i.company_id === cid);
    if (item) {
        // console.error("remove -> ",item);
        EventBus.emit("remove.from.longlist", item);
    }
};
const  disableCompany = (the_other, e, cid) => {
    e.stopPropagation();
    const show_company = the_other.state.show_company;
    show_company[cid] = !show_company[cid];
    the_other.setState({show_company});
    EventBus.emit("ParameterChanged::ShowCompanyEvent", show_company);
};
const renderDisplayIcon = (remove_only, the_other, cid) => {
    if(remove_only){
        return null;
    }
    if(the_other.state?.show_company[cid]){
        return (
            <Icon htmlTitle={"Ausblenden"} className={"sz-cell-action-primary"} icon={IconNames.EYE_OFF} size={12} onClick={ (e) => disableCompany(the_other, e, cid) }/>
        );
    }else{
        return (
            <Icon htmlTitle={"Einblenden"} className={"sz-cell-action-primary"} icon={IconNames.EYE_ON} size={12} onClick={ (e) => disableCompany(the_other, e, cid) }/>
        );
    }
}

export const renderLongListAction = (the_other, details: ICompanyBase, remove_only?)=>{
    if (!details) {
        return (<span>#error</span>);
    }
    return (
        <div className={"sz-cell-action"}>
            <div className={"sz-cell-action-bar"}>
                {renderDisplayIcon(remove_only, the_other, details.id)}
                <Icon htmlTitle={"Aus Longlist entfernen"} className={"sz-cell-action-danger"} icon={IconNames.TRASH} intent={"danger"} size={12} onClick={ (e) => removeCompany(e, the_other,  "" + details.id) }/>
            </div>
        </div>
    );
};

