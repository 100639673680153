import * as React from "react";
import {Button, Icon, Intent, Overlay} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";


const video_base =`https://www.smart-zebra.de/wp-content/uploads/Videos/`;

export interface ISzVidReferenceProps {
    text: string;
    video_name: string;
    video_poster: string;
}

export class SzVidReference extends React.Component<ISzVidReferenceProps, any>{


    constructor(props: ISzVidReferenceProps, context: any) {
        super(props, context);
        this.state={
            isOpen: false,
        };
    }

    public render() {
        const toggleOverlay = ()=> {
            const isOpen = !this.state.isOpen;
            this.setState({
                isOpen,
            });
        };
        return (
            <div style={{display: "inline"}}>
                <a title={"Video anzeigen"} style={{display: "inline-flex", color: "#106ba3"}} className={"sz-doc-ref-con bp3-popover-dismiss"} onClick={()=> toggleOverlay()}>
                    {this.props.text}
                </a>
                <Overlay isOpen={this.state.isOpen} onClose={()=> toggleOverlay()}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
                        <div style={{display: "flex",flexDirection: "column", alignItems: "flex-end"}}>
                            <Button intent={Intent.DANGER} minimal={true} title={"Video schliessen"} onClick={()=> this.setState({isOpen: false})}>
                                <Icon icon={IconNames.CROSS} iconSize={24}/>
                            </Button>
                            <video playsInline={true} width={840} style={{objectFit: "cover", paddingRight: 35}}
                                   poster={`${video_base}${this.props.video_poster}`}
                                   preload={"auto"} controls={true}>
                                <source src={`${video_base}${this.props.video_name}`} type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </div>
                    </div>
                </Overlay>
            </div>
        );
    }
}
