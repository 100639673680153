export enum ELayoutColumnWidth {
    c100Minus500px= "sz-col-100-minus-500px",
    c500px= "sz-col-500px",
    c10= "sz-col-10",
    c20= "sz-col-20",
    c30= "sz-col-30",
    c40= "sz-col-40",
    c50= "sz-col-50",
    c60= "sz-col-60",
    c70= "sz-col-70",
    c80= "sz-col-80",
    c90= "sz-col-90",
    c100= "sz-col-100",
}
