import * as React from "react";
import {CSSProperties} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {EChartPlugins, SzChartOptions} from "../../../helpers/SzChartOptions";
import {Bar} from "react-chartjs-2";
import {ICompanyBase} from "../../../tables/CompanyBase";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {Globals} from "../../../const/Globals";
import {DrawStateHook} from "../../../chart-plugin/DrawStateHook";
import {IRawBetaResponse, IRegression} from "../../../models/chef/RawBeta";
import * as ChartJsAnnotation from "chartjs-plugin-annotation";
import {LineAnnotationOptions} from "chartjs-plugin-annotation";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {EPeriodToFrequency} from "../../../const/PeriodConstants";

const EPeriodToColor = {
    "1d": "#d9822b", "1w": "#202b33",
    "2d": "#d9822b", "2w": "#202b33", "2bw": "#0f9960",
    "3d": "#d9822b", "3w": "#202b33", "3bw": "#0f9960",
    "5w": "#202b33", "5bw":"#0f9960", "5m":  "#8ad8e8",
};

const EPeriodToIndex = {
    "1d": 0,  "1w": 0,
    "2d": 1, "2w": 1, "2bw": 1,
    "3d": 2, "3w": 2, "3bw": 2,
    "5w": 3, "5bw": 3, "5m": 3,
};
const EPeriodToArray = {
    "1d": 0,  "1w": 1,
    "2d": 0, "2w": 1, "2bw": 2,
    "3d": 0, "3w": 1, "3bw": 2,
    "5w": 0, "5bw": 1, "5m": 2,
};
const all_periods = ["1d", "1w", "2d", "2w", "2bw", "3d", "3w", "3bw", "5w", "5bw", "5m"];

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    DrawStateHook,
    ChartJsAnnotation,
);

const styleH: CSSProperties = {
    width: "100%",
    color: "#182026",
    fontSize: 14,
    fontWeight: "bold",
    display: "block",
    paddingBottom: 5,
    marginTop: 14,
    boxShadow: "inset 0px -1px 0px 0px #cecece",
};
export interface ICrossSectionalAnalysisProps {
    company: ICompanyBase;
    data: any;
}

export function CrossSectionalAnalysis(props: ICrossSectionalAnalysisProps) {
    const company: ICompanyBase = props.company;
    const data = props.data;
    const o = new SzChartOptions();
    const period = SessionStore.get(EParameters.PeriodsParameter);
    // o.setXTickCallback(()=> "");
    // o.setYTickCallback(()=> "");
    // o.setYMinMax({min: -4, max: 4});
    // o.setXMinMax({min: -4, max: 4});
    const options = {
        ...o.options,
    };
    options.stacked = true;
    options.hover.mode = "point";
    options.interaction = {
        intersect: true,
    };
    options.plugins.tooltip = {
        enabled: true,
        mode: "point",
        intersect: true,
        backgroundColor: "rgba(255,255,255,0.75)",
        titleColor: "rgba(219,55,55,1)",
        bodyColor: "rgba(219,55,55,1)",
        borderColor: "rgba(82,82,82,0.75)",
        borderWidth: 1,
        caretSize: 0,
        cornerRadius: 2,
        callbacks: {
            label: (item)=> {
                const info: {slope: number; period: string} = item?.raw?.rawData;
                if(!info){
                    return Globals.hyphen;
                }
                return `${EPeriodToFrequency[info.period]} ${Globals.formatter(info.slope, 2, 2, true)}`;
            },
            title: (item)=> { return _t(ETranslation.beta_factor);},
        },
    };
    const chart_data = {
        labels: [
            _t(ETranslation.year_number, 1),
            _t(ETranslation.years_number, 2),
            _t(ETranslation.years_number, 3),
            _t(ETranslation.years_number, 5),
        ],
        datasets: [],
    };
    const datalabels = {
        display: (context)=>{
            const value = context.dataset.data[context.dataIndex];
            return value?.rawData?.period === period;
        },
        align: "-30",
        anchor: "end",
        offset: 5,
        backgroundColor: "rgba(255,255,255,0.9)",
        formatter: (value, context, a)=>{
            return Globals.formatter(value?.rawData?.slope);
        },
        labels: {
            title:{
                color: "rgb(19,124,189)",
            }
        }
    };
    const dots_templ = {
        type: "bubble",
        label: "Beta-Faktoren",
        hoverBackgroundColor: "rgba(219,55,55,0.75)",
        pointHoverBackgroundColor: "rgba(219,55,55,0.75)",
        datalabels,
        backgroundColor: (item)=>{
            const info: {slope: number; period: string} = item?.raw?.rawData;
            if(!info){
                return "rgba(255,255,255,0)";
            }
            if(info.period === period){
                return "rgba(19,124,189,0.75)";
            }else{
                return "rgba(255,255,255,0)";
            }
        },
        borderColor: (item)=>{
            const info: {slope: number; period: string} = item?.raw?.rawData;
            if(!info){
                return "rgba(0, 0, 0,0.1)";
            }
            if(info.period === period){
                return "rgba(19,124,189,0.75)";
            }
            return EPeriodToColor[info.period];
        },
    } as any;
    chart_data.datasets = [
        {...dots_templ},
        {...dots_templ},
        {...dots_templ},
    ];
    const raw_betas = data.raw_betas;
    const raw_beta: IRawBetaResponse = raw_betas[company.id];

    const q = [0.1, 0.25, 0.5]; // quantile(this.props.series.map( (s) => s.r2 ), [1 / 3, 2 / 3]);
    const q_test = (r2) => {
        if (r2 <= q[0]) {
            return 2;
        }
        if (r2 > q[0] && r2 <= q[1] ) {
            return 5;
        }
        if (r2 > q[1] && r2 <= q[2] ) {
            return 7;
        }
        if (r2 > q[2]) {
            return 10;
        }

        return 3;
    };
    let period_slope;
    let period_x;
    all_periods.forEach((p)=>{
        const r: IRegression = raw_beta.periods[p];
        if(!r){
            return;
        }
        const x = EPeriodToIndex[p];
        const a = EPeriodToArray[p];

        if(!chart_data.datasets[a].data){
            chart_data.datasets[a].data = [
                {x: undefined, y: undefined, r: 0},undefined,undefined,undefined
            ];
        }
        if(period === p){
            period_slope = r.Slope;
            period_x = x;
        }
        chart_data.datasets[a].data[x] = {x, y: r.Slope, r: q_test(r.R2), rawData: {slope: r.Slope, period: p}};
    });
    const period_annotation: LineAnnotationOptions = {
        borderColor: "rgba(19,124,189,0.75)",
        borderDash: [6, 6],
        borderWidth: 1,
        xMax: period_x,
        xMin: period_x,
        xScaleID: "x",
        yMax: 0,
        yMin: period_slope,
        yScaleID: "y"
    };
    const annotation = {
        annotations : {
            period_annotation
        }
    };
    o.enablePlugin(EChartPlugins.ANNOTATION, annotation);
    return (
        <div>
            <div style={styleH}>{_t(ETranslation.stability_cross_section)}</div>
            <div className={"sz-row"} style={{aspectRatio: "5/3"}}>
                <div style={{width: "calc(100% - 100px)"}} className={"sz-col"}>
                    <Bar data={chart_data as any} options={options} />
                </div>
                <div className={"sz-col"} style={{width: 100, fontSize: "80%", paddingTop: 20}}>
                    <div><span style={{color: "#d9822b"}}>◯</span> {_t(ETranslation.daily)}</div>
                    <div><span style={{color: "#202b33"}}>◯</span> {_t(ETranslation.weekly)}</div>
                    <div><span style={{color: "#0f9960"}}>◯</span> {_t(ETranslation.biweekly)}</div>
                    <div><span style={{color: "#8ad8e8"}}>◯</span> {_t(ETranslation.monthly)}</div>
                </div>
            </div>
            <div className={"sz-hint"} style={{paddingLeft: 10}}>
                {_t(ETranslation.visualization_cross_section)}
            </div>
        </div>
    );
}
