import * as React from "react";
import {CSSProperties} from "react";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {Calculator} from "../../helpers/Calculator";
import {EParameters} from "../../models/EParameters";
import {
    IDataChange,
    ISzTableGridHeaderRow,
    ISzTableGridProps,
    SzTableGrid,
    SzTableGridCellMap
} from "../widgets/SzTableGrid";
import {BaseModule} from "./BaseModule";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

interface IRowDef {
    label: string;
    field: string;
    isBold?: boolean;
    formula?: string;
    sign?: number;
}

const header_row: CSSProperties = {
    backgroundColor: "rgb(240, 240, 240)"
};

const _isBold = true;
// ff_funds_oper_gross
enum ESign {
    plus= 1,
    minus= 2,
};
const _sp= {sign: ESign.plus};
const _sm= {sign: ESign.minus};
const _rows: IRowDef[] = [
    {label: _t(ETranslation.revenues), field: "ff_sales", ..._sp},
    {label: "Bestandsveränderungen", field: "b1"},
    {label: "Aktivierte Eigenleistungen", field: "b2", ..._sp},
    {label: "Gesamtleistung", isBold: _isBold, field: "g2", formula: "ff_sales + b1 + b2"},
    {label: "Sonstige betriebliche Erträge", field: "g3", ..._sp},

    {label: "Materialaufwand", field: "oa_materialaufwand"},
    {label: "Personalaufwand", field: "oa_personalaufwand"},
    {label: "Sonstiger betrieblicher Aufwand", field: "oa", formula: "ff_ebitda_oper - g2 - oa_materialaufwand - oa_personalaufwand"},
    {label: "EBITDA", field: "ff_ebitda_oper", isBold: _isBold},

    {label: "Abschreibungen", field: "f1", formula: "ff_ebit_oper - ff_ebitda_oper"},
    {label: "EBIT", field: "ff_ebit_oper", isBold: _isBold},

    {label: ` Zinserträge`, field: "z1", ..._sp},
    {label: ` ${_t(ETranslation.interest_expenses)}`, field: "ff_int_exp_debt", ..._sm},
    {label: "Zinsergebnis", field: "ff_int_inc_net", formula: "z1 + ff_int_exp_debt"},
    {label: "Sonstiges Finanzergebnis", field: "f3"},
    {label: "Außerordentliches Ergebnis", field: "f4"},
    {label: "Ergebnis vor Steuern", field: "f5", isBold: _isBold, formula: "ff_ebit_oper + ff_int_inc_net + f3 + f4"},
    {label: _t(ETranslation.income_tax), field: "ff_inc_tax"},
    {label: _t(ETranslation.netincome), field: "ff_net_inc", isBold: _isBold, formula: "f5 + ff_inc_tax"}, // 14
    {label: "", field: ""}, // 15
    {label: "Sachanlagen", field: "ff_ppe_net", ..._sp},
    {label: "Immaterielle Vermögenswerte", field: "ff_intang", ..._sp},
    {label: "Finanzanlagen", field: "ff_invest_aff", ..._sp},
    {label: "Vorräte", field: "f6", ..._sp},
    {label: "Forderungen aus L/L", field: "f7", ..._sp},
    {label: "Liquide Mittel", field: "ff_cash_generic", ..._sp},
    {label: "Sonstige Vermögensgegenstände", field: "f8", formula: "bilanzsumme - (ff_ppe_net + ff_intang + ff_invest_aff + f6 + f7 + ff_cash_generic)"},
    {label: "Aktiva", field: "bilanzsumme", isBold: _isBold},
    {label: "", field: ""},
    {label: " Eigenkapital der Aktionäre", field: "ff_shldrs_eq"},
    {label: " Nicht beherrschende Anteile", field: "ff_min_int_accum"},
    {label: _t(ETranslation.equity), field: "ff_eq_tot", formula: "ff_shldrs_eq + ff_min_int_accum"},
    {label: "Finanzschulden", field: "ff_debt", ..._sp},
    {label: "Pensionsverbindlichkeiten", field: "ff_pens_liabs_unfunded", ..._sp},
    {label: "Rückstellungen", field: "f10", ..._sp},
    {label: "Verbindlichkeiten aus L/L", field: "f11", ..._sp},
    {label: "Sonstige Verbindlichkeiten", field: "f12", formula: "bilanzsumme - ( ff_eq_tot + ff_debt + ff_pens_liabs_unfunded + f10 + f11)"},
    {label: "Passiva", field: "ff_assets", isBold: _isBold, formula: "bilanzsumme"},
    {label: "", field: ""},
    {label: "Investitionen", field: "ff_capex", ..._sp},
    // berechnete kennzahlen hidden
    {label: "#FFO", field: "ff_funds_oper_gross", isBold: _isBold, formula: "ff_ebitda_oper - ff_int_inc_net - ff_inc_tax"},
    {label: "#Net Working Kapital", field: "ff_wkcap", isBold: _isBold, formula: "f6 + f7 + f8 - f10 - f11 - f12"},

];
const def_columnWidths= [undefined, 70, 70, 70, 70, 70];

export class TestModule extends BaseModule<any> {
    private grid_data: any[][] = [];
    private object_context = {};
    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            isOpenA: false,
            isOpenB: false,
        };
    }

    protected renderContent() {
        const year = 2021;
        const years = [-4, -3, -2, -1, 0].map((y) => y + year);
        const cell_map: SzTableGridCellMap = {};
        const header: ISzTableGridHeaderRow[] = [
            {style: header_row, cells: [{content:"Peers", style:{width: def_columnWidths[0], paddingLeft: 10}}]},
            {style: header_row, cells: [{content:"Eur", style:{width: def_columnWidths[0], paddingLeft: 10}}]},
        ];
        years.forEach( (y, i) =>  header[0].cells.push({content: y, class_name: "sz-number", style:{width: def_columnWidths[i +1], paddingRight: 6}}));
        years.forEach( (y, i) =>  header[1].cells.push({content: "IST", class_name: "sz-number", style:{width: def_columnWidths[i +1], paddingRight: 6}}));
        const data = [];
        _rows.forEach((row, row_num) => {
            const row_data = [];
            data.push(row_data);
            if(!row.label){
                row_data.push("");
                cell_map[`A${row_num}`] = {
                    col_span: years.length + 1,
                };
                return;
            }
            row_data.push(row.label);
            cell_map[`A${row_num}`] = {
                class_name: row.isBold ? "sz-bold" : undefined,
                style:{width: def_columnWidths[0], paddingLeft: 10}
            };
            years.forEach( (y, i) =>{
                const content = this.getCellContent(y, row);
                const cell_style = this.getCellStyle(y, row);
                row_data.push(content);
                cell_map[`${SzTableGrid.colName( i + 1)}${row_num}`] = {
                    context_id: "" + y,
                    is_editable: row.field && !row.formula,
                    is_selectable: row.field && !row.formula,
                    class_name: row.isBold ? "sz-bold sz-number" : "sz-number",
                    style: Object.assign({width: def_columnWidths[ i +1 ], paddingRight: 6}, cell_style),
                    formatter: (v)=>{ if(isNaN(v)){return "";} return Globals.formatter(v)}
                };
            });
        });
        const grid_props: ISzTableGridProps = {
            header,
            cell_map,
            data,
            onCellUpdated: (context_id, new_value, col_num, row_num) => this.onCellUpdated(context_id, new_value, col_num, row_num),
        };
        return (
            <div>
                <SzTableGrid {...grid_props} style={{fontSize: "80%"}}/>
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    private getCellContent(year: number, row: IRowDef) {
        const values = SessionStore.get(EParameters.BenchmarkingCustom);
        const key = `${year}_${row.field}`;
        const v = parseFloat(values[key]);
        if(!this.object_context[year]){
            this.object_context[year] = {};
        }
        this.object_context[year][row.field] = v;
        if(isNaN(v) || v === undefined){
            return "";
        }
        return v;
    }

    private getCellStyle(year: number, row: IRowDef) {
        //
    }

    private onCellUpdated(context_id: string, new_value: any, col_num: number, row_num: number): IDataChange[] {
        // context_id: string, new_value: any, col_num: number, row_num: number
        const r = this.object_context[context_id];
        const target_row = _rows[row_num];
        const result: IDataChange[] = [];

        const v = parseFloat(new_value);
        if((target_row.sign === ESign.plus && v < 0) || (target_row.sign === ESign.minus && v > 0)){
            result.push({
                value: v,
                isError: "#Wert",
                col_num,
                row_num,
            });
            return result;
        }

        r[target_row.field] = new_value;



        _rows.forEach((row, i)=>{
            if(!row.formula){
                return;
            }
            const value = Calculator.evaluate(row.formula, r);
            r[row.field] = value;
            result.push({
                value,
                col_num,
                row_num: i,
            });
        });
        return result;
    }
}
