import {DateTime, Duration} from "luxon";
import {Globals} from "../../../const/Globals";
import {find_object} from "../../../helpers/Helpers";
import {Gearing, IUnleveredBeta} from "../../../models/chef/UnleveredBeta";
import {IFinancialAnnualReported} from "../../../models/tables/FinancialAnnualReported";
import {fromDateDecimal, fromStr} from "../../../tools/DateTools";
import {SzTableHelper} from "../../widgets/helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties, ISzTagOptions} from "../../widgets/SzTable";
import {ITableDetailsParams} from "./TableDetailsParams";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const dept_notes = [
    "Rohdaten: Factset Inc.",
    "¹) Lineare Interpolation auf Basis der Bilanzstichtagswerte",
    "²) Barwert der zum Bilanzstichtag ausstehenden Verpflichtungen aus Operate Lease Verträgen; Ermittlung des Barwertes unter Verwendung des Mindestleasingzahlungen nach 1 bis 5 Jahren und darüber in Übereinstimmung mit dem Vorgehen",
    "³) Nettopensionsverpflichtung; Barwert der Pensionsverpflichtungen abzgl. Zeitwert des Planvermögens",
    "⁴) Liquide Mittel, die aufgrund einer Verfügungsbeschränkung für eine Erfüllung kurzfristiger Zahlungsverpflichtungen nicht zur Verfügung stehen; Angabe nicht für alle Unternehmen verfügbar",
    "⁵) Finanzanlagen ohne Beteiligungen; enthält u.a. auch Forderungen aus Finanzdienstleistungen",
    "⁶) Summe aller nicht vollkonsolidierten Anteile an anderen Gesellschaften einschließlich at equity bilanzierter Gesellschaften",
    "⁷) Jahresdurchschnitt auf Basis der Monatsendwerte",
];
const fields: string[] =
    ("weighting,ff_debt_xlease,ff_debt_ff_debt_xlease,operate_lease,ff_pens_liabs_unfunded," +
    "ff_cash_only_ff_cash_restr,ff_cash_restr,ff_invest_st_tot," +
    "ff_invest_lt_oth_ff_notes_receiv_lt,ff_invest_aff," +
    "gearing,market_cap,ration").split(",");
const negativ_fields = "ff_cash_only_ff_cash_restr,ff_cash_restr,ff_invest_st_tot,ff_invest_lt_oth_ff_notes_receiv_lt,ff_invest_aff".split(",");
const labels = {
    weighting: `${_t(ETranslation.weighting)}¹`,
    ff_debt_xlease: ` ${_t(ETranslation.financial_debt_ex_leasing)}`,
    ff_debt_ff_debt_xlease: ` ${_t(ETranslation.leasing_on_balance)}`,
    operate_lease: ` ${_t(ETranslation.leasing_off_balance)}²`,
    ff_pens_liabs_unfunded: ` ${_t(ETranslation.pension_deficit)}³`,
    ff_cash_only_ff_cash_restr: ` ${_t(ETranslation.cash_excess)}`,
    ff_cash_restr: ` ${_t(ETranslation.cash_restricted)}⁴`,
    ff_invest_st_tot: ` ${_t(ETranslation.assets_current_financial)}`,
    ff_invest_lt_oth_ff_notes_receiv_lt: ` ${_t(ETranslation.assets_non_current_financial)}⁵`,
    ff_invest_aff: ` ${_t(ETranslation.assets_affiliates)}⁶`,
    gearing: "Nettoschulden",
    market_cap: _t(ETranslation.market_cap) + "⁷",
    ration: _t(ETranslation.gearing),
};
const formulars = [
    "ff_debt_ff_debt_xlease = " +
        "ff_debt - ff_debt_xlease",
    "ff_cash_only_ff_cash_restr = " +
        "ff_cash_only - ff_cash_restr",
    "ff_invest_lt_oth_ff_notes_receiv_lt = " +
        "ff_invest_lt_oth + ff_notes_receiv_lt",
    "gearing = " +
        "ff_debt_xlease " +
        "+ ff_debt_ff_debt_xlease " +
        "+ ff_pens_liabs_unfunded + operate_lease " +
        "- ff_cash_only_ff_cash_restr " +
        "- ff_cash_restr " +
        "- ff_invest_st_tot " +
        "- ff_invest_lt_oth_ff_notes_receiv_lt " +
        "- ff_invest_aff",
    "ration = gearing / market_cap",
];
export const getTable = (params: ITableDetailsParams): ISzTableProperties<any[]> => {
    // console.error(params);
    const deadline: DateTime = fromStr(params.deadline, "dd.LL.yyyy").endOf("month");

    const unlevered_beta: IUnleveredBeta = params.unlevered_beta_map[params.period];
    const unlevered_beta_5y: IUnleveredBeta = params.unlevered_beta_map[params.period];
    if(!unlevered_beta_5y){
        return undefined;
    }
    const latest_report: IFinancialAnnualReported = find_object(params.company_reports,(date_decimal, o: IFinancialAnnualReported)=>{
        const report_date = fromDateDecimal(o.date_decimal);
        const months: Duration = deadline.diff(report_date, "months");
        return months.months < 13;
    });
    if(!latest_report){
        return undefined;
    }

    const d: DateTime = fromDateDecimal(latest_report?.date_decimal);
    const header_0: ISzTableColumn[] = [
        { text: `Mio. ${latest_report?.currency_iso}`},
    ];
    const header_1: ISzTableColumn[] = [
        { text: `${d.toFormat("dd.LL.")}`},
    ];
    [5, 4, 3, 2, 1, 0].forEach((y)=>{
        header_0.push({ text: `${d.minus({year: y}).toFormat("yyyy")}`, options: {className: "sz-right"} });
        header_1.push({ text: `Ist`, options: {className: "sz-right"} });
    });
    // werte spalte
    header_0.push({ text: `${params.show_result === 0 ? Globals.average : _t(ETranslation.valuation_date)}`, options: {className: "sz-right"} });
    header_1.push({ text: `${params.show_result === 0 ? `${params.years} ${_t(ETranslation.years)}` : d.toFormat("dd.LL.yyyy")}`, options: {className: "sz-right"} });

    const columns: ISzTableColumn[] = [
        { ...SzTableHelper.columnIndex(0)},
        { ...SzTableHelper.columnMoney(1, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(2, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(3, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(4, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(5, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(6, 0, {style: {width: 82}})},
        { ...SzTableHelper.columnMoney(7, 0, {style: {width: 82}})},
    ];
    const data = [];
    fields.forEach((field, idx)=>{
        const data_row = [
            labels[field]
        ];
        data.push(data_row);
        [5, 4, 3, 2, 1, 0].forEach((year)=>{
            if(!unlevered_beta.components.gearing){
                return data_row.push(NaN);
            }
            const dt = d.startOf("month").minus({year}).endOf("month").asDateDecimal();
            let values: Gearing = unlevered_beta.components.gearing[dt];
            if(!values){
                values = unlevered_beta_5y.components.gearing[dt]
                    ? {...unlevered_beta_5y.components.gearing[dt], weighting: NaN}
                    : undefined;
            }
            if(!values){
                data_row.push(NaN);
            }else{
                if(negativ_fields.includes(field) && values[field]){
                    data_row.push(values[field] * -1);
                }else{
                    data_row.push(values[field]);
                }
            }
        });
        if([0].includes(idx)){
            return data_row.push("");
        }
        if(unlevered_beta.components.gearing && unlevered_beta.components.gearing[params.show_result]){
            const result_values = unlevered_beta.components.gearing[params.show_result];
            if(negativ_fields.includes(field) && result_values[field]){
                data_row.push(result_values[field] * -1);
            }else{
                data_row.push(result_values[field]);
            }
        }else{
            data_row.push("");
        }
    });
    const tableProps: ISzTableProperties<any[]> = {
        title: "Verschuldung",
        colCount: 8,
        notStriped: true,
        notes: dept_notes,
        header: [
            header_0,
            header_1
        ],
        columns,
        data,
        column_styles: {
            "0" : {borderRight: "#ffffff solid 4px"},
            "6" : {borderRight: "#ffffff solid 4px"},
        } as any,
        beforeRenderCell: (cellValue: any, rowNum: number, celNum: number, definition: ISzTableColumn, options: ISzTagOptions)=>{
            const class_names = options.className ? [options.className] : [];

            if(rowNum === 0 && celNum > 0 && celNum!==7){
                definition.formatter = (v)=>{
                    return Globals.formatter_percent(v, 1, 1, true);
                };
            }
            if(rowNum === 12 && celNum > 0){
                definition.formatter = (v)=>{
                    return Globals.formatter_percent(v, 0, 0, true);
                };
            }

            if([0, 4, 7].includes(rowNum) && celNum < 7){
                class_names.push("bottom-space");
            }
            if([0, 4, 7].includes(rowNum) && celNum === 7){
                class_names.push("bottom-space-gray");
            }
            if(celNum === 7){
                class_names.push("sz-bg-gray");
            }
            if(rowNum === 10){
                class_names.push("sz-border-top-td");
            }
            if(rowNum === 12){
                class_names.push("sz-bg-gray", "sz-bold");
            }

            options.className = class_names.join(" ");
        },
    };

    return tableProps;
}
