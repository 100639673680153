import {PublicDbCalls} from "../../../services/PublicDbCalls";
import {ICompanySearchResult} from "../../../models/ICompanySearchResult";
import {Globals} from "../../../const/Globals";
import {EStatisticsValueType} from "../../../const/EStatisticsValueType";
import {Button, Classes, Dialog, Intent, Tab, Tabs, Tooltip} from "@blueprintjs/core";
import {CompanyInformation} from "../../widgets/CompanyInformation";
import {CompanyRelations} from "../../widgets/CompanyRelations";
import {CompanyFinancials} from "../../widgets/CompanyFinancials";
import {SvgExcel} from "../../widgets/SvgExcel";
import * as React from "react";
import {TabHelper} from "./TabHelper";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";
import {If} from "../../widgets/If";
import {CompanyEstimates} from "../../widgets/CompanyEstimates";


export const selectRow = (owner, cid) => {
    ( async () => {
        const db = new PublicDbCalls();
        const selectedCompany: ICompanySearchResult = await db.getCompany(cid);
        owner.setState({
            showDebtDlg: true,
            cid,
            selectedCompany,
        });
    } )();
}

const renderDetailsTab = (owner, fieldName: string, caption: string) => {
    const company: ICompanySearchResult = owner.state.selectedCompany;
    return TabHelper.renderDetailsTab(owner, fieldName, caption, company, owner.data);
}
const renderDebtBetaTab = (owner) => {
    return renderDetailsTab(owner, "data_debt_beta", _t(ETranslation.debt_beta));
}
const renderTaxTab = (owner) => {
    return renderDetailsTab(owner, "data_tax_shield", _t(ETranslation.tax_shield));
}
const renderDebtTab = (owner) => {
    return renderDetailsTab(owner, "data_debt", _t(ETranslation.gearing));
}
export const renderDebtDlg = (owner, options = {showExcelExport: true})=> {
    if (!owner.state.showDebtDlg) {
        return null;
    }
    Globals.trackInfo(EStatisticsValueType.button_click, "show_unlevered_beta_details");
    const current_company: ICompanySearchResult = owner.state.selectedCompany;
    if (!current_company) {
        return null;
    }
    const debtbetaParameter = owner.parameters.DebtbetaParameter[0];
    const hideDebtBeta: boolean = debtbetaParameter === "nicht_anwenden";

    const taxshieldParameter: string = owner.parameters.TaxshieldParameter[0];
    const hideTaxShield: boolean = taxshieldParameter === "unsicher";
    return (

        <Dialog
            style={{width: 1124, backgroundColor: "transparent", padding: 0}}
            canOutsideClickClose={true}
            usePortal={true}
            isOpen={true}
            onClose={() => owner.setState({showDebtDlg: false})}
        >
            <div className={`${Classes.DIALOG_HEADER} bp3-dark`} style={{backgroundColor: "rgb(57, 75, 89)", paddingLeft: 10}}>
                <img src={`${Globals.BASE_DIR}images/flags/${current_company.country_iso_2.toLowerCase()}.jpg`} style={{height: 30, alignSelf: "center"}}/>
                <div style={{display: "flex", width: "100%", paddingRight: 10, marginLeft: 10, justifyContent: "space-between", alignItems: "center"}}>
                    <span style={{fontSize: "100%", fontWeight: 600}}>{current_company.company_name}</span>
                    <div style={{fontSize: "70%"}} className={"bp3-text-muted"}>{current_company.country_ticker} - {current_company.company_id}</div>
                </div>
                <Button intent={Intent.DANGER} aria-label={"Schließen"} icon={"small-cross"} minimal={true} onClick={() => owner.setState({showDebtDlg: false})} />
            </div>
            <div className={Classes.DIALOG_BODY} style={{margin: 0, padding: 10, paddingBottom: 10, backgroundColor: "#ffffff"}}>
                <Tabs id="BUB_TABS" className={"sz-tab-seized"}>
                    <Tab id="CI_TABS_BASE" title={_t(ETranslation.base_data)} panel={<CompanyInformation company_id={current_company.company_id} />} />
                    <Tab id="CI_TABS_COMP" title={_t(ETranslation.peer_group)} panel={<CompanyRelations company_id={current_company.company_id} />} />
                    <Tab id="CI_TABS_FIN" title={_t(ETranslation.financials)} panel={<CompanyFinancials company_id={current_company.company_id} />} />
                    <Tab id="CI_TABS_ESTIMATES" title={_t(ETranslation.estimates)} panel={<CompanyEstimates company_id={current_company.company_id} />} />
                    <Tabs.Expander />
                    <Tab id="mb" title={_t(ETranslation.gearing)} panel={renderDebtTab(owner)} />
                    {hideTaxShield ? null : (<Tab id="rx" title={_t(ETranslation.tax_shield)} panel={renderTaxTab(owner)} />)}
                    {hideDebtBeta ? null : (<Tab id="ng" title={_t(ETranslation.debt_beta)} panel={renderDebtBetaTab(owner)} />)}

                    <If condition={options?.showExcelExport}>
                        <div>
                            <Tooltip content={_t(ETranslation.excel_icon_hoover)} usePortal={true} position={"left-bottom"} openOnTargetFocus={false}>
                                <span onClick={() => owner.exportAsExcelMappe()}><SvgExcel size={20} /></span>
                            </Tooltip>
                        </div>
                    </If>
                </Tabs>
            </div>
        </Dialog>
    );
}
