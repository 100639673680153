import * as React from "react";
import {CSSProperties} from "react";
import {CreditRunTimes} from "../../../const/CreditRuntimes";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {ISzRangeProperties, SzRange} from "../../widgets/SzRange";
import {SingleSelectParameter} from "../SingleSelectParameter";
import {ETranslation} from "../../../const/ETranslation";
import {_t} from "../../../tools/Translator";

const label_style: CSSProperties={
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
};

export class CreditSpreadRuntimeParameter extends SingleSelectParameter {
    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getDocLabel(v){
        const {start, stop} = SzRange.getStartStop(v, 1, 30);
        const s = Math.trunc(start/12);
        const c = `${s} - ${Math.trunc(stop/12)} ${_t(ETranslation.years)}`;
        return c;
    }
    public static getPdfLabel(){
        const v=SessionStore.get(EParameters.CreditSpreadRuntimeParameter);
        return {
            text: CreditSpreadRuntimeParameter.getDocLabel(v),
        };
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CreditSpreadRuntimeParameter];
    }

    protected async getOptions() {
        return CreditRunTimes.values;
    }

    protected async getSelectedValue() {
        return SessionStore.get(EParameters.CreditSpreadRuntimeParameter);
    }

    protected getMenu(): any {
        return (
            <div className={"sz-row"}>
                <div style={{paddingTop: 10, paddingRight: 10, width: 300}} className={"sz-row"}>
                    {this.renderRuntime()}
                    <div className={"bp3-text-muted"} style={{width: "100%",fontSize: "80%", marginTop: -10, marginBottom: 10, textAlign: "right"}}>{_t(ETranslation.years)}</div>
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.CreditSpreadRuntimeParameter))}
            </div>
        );
    }
    protected fireValueChange(parameterName: EParameters, value) {
        this.fireChanges([{
            component: parameterName,
            value: Array.isArray(value) ? value : [value],
        }]);
    }
    protected getLabel(): any {
        const v=this.state.selectedValue; // SessionStore.get(EParameters.CreditSpreadRuntimeParameter);
        const {start, stop} = SzRange.getStartStop(v, 1, 30);
        const s = Math.trunc(start/12);
        const c = `${s} - ${Math.trunc(stop/12)} ${_t(ETranslation.years)}`;
        return c;
    }
    private onRelease(_start: number, _stop: number) {
        this.onSetValue(EParameters.CreditSpreadRuntimeParameter, `${_start*12},${_stop*12}`);
        this.fireValueChange(EParameters.CreditSpreadRuntimeParameter, `${_start * 12},${_stop * 12}`);
    }
    private onSetValue(field: EParameters, _valueAsNumber: any) {
        const state: {[k: string]: any} = {};
        state.selectedValue = _valueAsNumber;
        this.setState(state);
    }
    private renderRuntime() {
        const {start, stop} = SzRange.getStartStop(this.state.selectedValue, 0, 30);
        const inputProps: ISzRangeProperties = {
            start: Math.trunc(start/12),
            stop: Math.trunc(stop/12),
            min: 0,
            max: 30,
            stepSize: 1,
            stepSizeLabel: 5,
            onValueChanged: (_start: number, _stop: number) => this.onSetValue(EParameters.CreditSpreadRuntimeParameter, `${_start*12},${_stop*12}`),
            onRelease: (_start: number, _stop: number) => this.onRelease(_start, _stop),
        };
        return (
            <div style={{padding: 10, width: "100%"}}>
                <SzRange {...inputProps} />
            </div>
        );
    }
}
