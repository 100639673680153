import {Spinner} from "@blueprintjs/core";
import * as React from "react";
import {EventBus, IEventHandler} from "../../services/EventBus";

export interface IBaseFilterProperties {
    name: string;
    no_base_filter?: boolean;
    onValueChanged?(data: any): void;
}
export interface IBaseFilterState {
    loading: boolean;
}
export abstract class BaseFilter<P extends IBaseFilterProperties, S extends IBaseFilterState> extends React.Component<P, S> {

    private evtResetFilter: IEventHandler;

    protected constructor(props: P, context: S) {
        super(props, context);
        // EventBus.emit("search-reset-filters", null);
        this.evtResetFilter = EventBus.subscribe("search-reset-filters", () => this.onResetFilter());
    }
/* <h3>{this.props.name}</h3> */
    public render() {
        let f = "sz-base-filter";
        if (this.props.no_base_filter) {
            f = "";
        }
        return (
            <span style={{display: "inline-block"}} className={`sz-search-control ${f}`}>
                { this.state.loading ? this.renderLoading() : this.renderContent() }
            </span>
        );
    }
    public componentWillUnmount() {
        EventBus.unsubscribe(this.evtResetFilter);
    }

    public renderLoadingDefault() {
        return <Spinner size={100} />;
    }

    protected abstract renderContent();
    protected abstract renderLoading();
    protected abstract resetFilter();

    protected beginUpdate(): void {
        this.setState({
            loading: true,
        });
    }
    protected endUpdate(): void {
        this.setState({
            loading: false,
        });
    }

    protected valueChanged(data: any): void {
        if (this.props.onValueChanged) {
            this.props.onValueChanged(data);
        }
    }

    private onResetFilter() {
        try {
            this.resetFilter();
        } catch (exi) {
            console.error(exi);
        }
    }
}
