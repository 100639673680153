import * as React from "react";
import {CSSProperties} from "react";
import {BaseModule} from "./BaseModule";
import {SessionStore} from "../../const/SessionStore";
import {EParameters} from "../../models/EParameters";
import {CorporateSpreadOld} from "./old/CorporateSpreadOld";
import {CorporateSpreadNew} from "./new/CorporateSpreadNew";
import {_k, getAllNextDates} from "../../helpers/Helpers";
import * as moment from "moment/moment";
import {ICorporateSpread} from "../../models/ICorporateSpread";
import {median} from "../../helpers/Statistics";
import {Globals} from "../../const/Globals";
import {Bubble, Line} from "react-chartjs-2";
import {Sectors} from "../../const/Sectors";
import {Ratings} from "../../const/Ratings";
import {ISzTableColumn, ISzTableProperties} from "../widgets/SzTable";
import {SzTableHelper} from "../widgets/helper/SzTableHelper";
import {HTMLTable, Menu, MenuItem} from "@blueprintjs/core";
import {TasksCalls} from "../../services/TasksCalls";
import {deadline, deadlineEoM} from "../../tools/DateTools";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {EValueName} from "../../models/EValueName";
import {CreditSpreadOptions} from "../../const/CreditSpreadOptions";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    LogarithmicScale,
    PointElement,
    Tooltip
} from "chart.js";
import {RegressionLine} from "../../chart-plugin/RegressionLine";
import {CustomAxisLabel} from "../../chart-plugin/CustomAxisLabel";
import {Annotations} from "./charts/annotations";

const old_date_decimal = 20220930;


ChartJS.register(CategoryScale,LinearScale, LogarithmicScale, PointElement, LineElement, BarElement, Tooltip, Legend, RegressionLine, CustomAxisLabel);

const captions = [
    "1",
    "2",
    "3",
    "5",
    "7",
    "10",
    "20",
    "30",
];
const fields = [
    "v_1_j",
    "v_2_j",
    "v_3_j",
    "v_5_j",
    "v_7_j",
    "v_10_j",
    "v_20_j",
    "v_30_j",
];
interface ICompanySpread {
    cid: string;
    id: string; // name
    rating: string;
    security_id: string;
    spread: number;
    count: number;
    index: number;
}
interface IDot{
    x: number;
    y: number;
    dataSet: ICompanySpread;
}

export class CorporateSpread extends BaseModule<any> {

    private aRef: any = React.createRef();
    private sector: string = "";
    private tableProps: ISzTableProperties<ICorporateSpread>;

    constructor(props: any, context: any) {
        super(props, context);
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }
    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }

    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.tableProps as any);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    protected renderContent() {
        return (
            <div>
                <div className={"sz-row"}>
                    <div className={"sz-col-50"} style={{paddingRight: 10}}>
                        {this.renderCorporateSpread()}
                    </div>
                    <div className={"sz-col-50"} style={{paddingLeft: 10}}>
                        {this.renderCreditSpreadOverview()}
                    </div>
                </div>
                <div className={"sz-row"} style={{paddingTop: 20}}>
                    <div className={"sz-col-50"}>
                        {this.renderCreditSpreadTimeLine()}
                    </div>
                    <div className={"sz-col-50"}>
                        {this.renderRatingAnd()}
                    </div>
                </div>
            </div>
        );
    }
    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    protected onAfterUpdate(data: any): void {
        this.data = data;
        this.setState({
            loading: false,
        });
        this.aRef.current.onAfterUpdate(data);
    }

    private renderCreditSpreadOverview(){
        const date_decimal = deadline(true).asDateDecimal().toString(10);
        const spread_time_line: ICorporateSpread[] = this.data.spread_time_line;
        const sector = this.getParameterValue(EParameters.SectorParameter) === "0000" ? "0" : this.getParameterValue(EParameters.SectorParameter);
        const table_data: ICorporateSpread[] = spread_time_line
            .filter((i, idx)=>i.sector === sector && i.date_decimal === date_decimal);
        table_data.sort((a,b)=> Ratings.s_and_p_all_mapped_to_num[a.rating] - Ratings.s_and_p_all_mapped_to_num[b.rating]);
        const padding = "6px 3px";
        const num_width = "11%";
        const td_height = 28;
        const number_col: CSSProperties = {
            padding,
            textAlign: "right",
            width: num_width,
            height: td_height
        };
        const border_right = {
            boxShadow: "inset -1px 0px 0px 0px rgba(16, 22, 26, 0.15)",
        };
        const border_right_0 = {
            boxShadow: "inset 0 1px 0 0 rgba(16, 22, 26, 0.15), inset -1px 0px 0px 0px rgba(16, 22, 26, 0.15)",
        };

        const header_0: ISzTableColumn[] = [];
        header_0.push({ text: "Rating" });
        header_0.push({ text: _t(ETranslation.term_in_years), options: {colSpan: 8} });
        const header_1: ISzTableColumn[] = [];
        header_1.push({ text: "" });
        captions.forEach((c)=>header_1.push({ text: c, options: {className: "sz-right"}}));
        const columns: ISzTableColumn[] = [];
        columns.push({ ...SzTableHelper.columnIndex("rating", undefined)});
        fields.forEach((c)=>{
            columns.push({...SzTableHelper.columnMoney(c, 2, {style: {width: num_width}})});
        });
        this.tableProps = {
            title: _t(ETranslation.credit_spread_ratings),
            colCount: columns.length,
            data: table_data,
            header: [ header_0, header_1 ],
            columns,
        };
        const rating: string[] = SessionStore.get(EParameters.RatingParameter);
        // window.console.log("rating:", rating);
        const runTime = SessionStore.get(EParameters.RuntimeParameter);
        const [_start,_stop] = runTime.split(",");
        const start = _start/12;
        let stop = _stop/12;
        const r0 = rating[0];
        const r1 = rating[rating.length - 1];
        // const r00 = range[0];
        // const r11 = range[rating.length - 1];
        const bs_top = "#95dcfd 0px 1px 0px 0px inset";
        const bs_bottom = "#95dcfd 0px -1px 0px 0px inset";
        return (
            <div className={"sz-row"}>
                <div className={"sz-col-100"} style={{position: "relative", width: "100%"}}>
                    <div style={{marginBottom: 20, fontSize: "80%"}}>
                        <strong>{_t(ETranslation.credit_spread_term_stucture_rating)}{Globals.superscript(1)} [% p.a.]</strong>
                        <br/>
                        {this.getSelectedSector(false)}
                    </div>
                    <HTMLTable className={`sz-prj-info-table_2`} condensed={true} style={{width: "100%", fontSize: "80%"}}>
                        <thead>
                        <tr style={{backgroundColor: "#FFFFFF"}}>
                            <th style={{padding, ...border_right}}>Rating</th>
                            <th style={{padding, textAlign: "center"}} colSpan={8}>{_t(ETranslation.term_in_years)}</th>
                        </tr>
                        <tr style={{backgroundColor: "#FFFFFF"}}>
                            <th style={{padding, ...border_right, borderBottom:"1.5px solid rgba(16, 22, 26, 0.15)"}}>&nbsp;</th>
                            {captions.map((c, idx)=>(<th key={_k(c, idx)} style={{padding, textAlign: "right", width: num_width,borderBottom:"1.5px solid rgba(16, 22, 26, 0.15)"}}>{c}</th>))}
                        </tr>
                        </thead>
                        <tbody>
                        {table_data.map((row: ICorporateSpread, row_num)=>{
                            const backgroundColor = {backgroundColor: "#e0efff"};
                            const bs = [];
                            const bxs = {boxShadow: ""};
                            if(row_num === 0 && row.rating !== r0){
                                bs.push("inset 0 1px 0 0 rgba(16, 22, 26, 0.15)");
                            }
                            if(row.rating === r0){
                                bs.push(bs_top);
                            }
                            if(row.rating === r1){
                                bs.push(bs_bottom);
                            }
                            const boxShadow: CSSProperties = {boxShadow: bs.join(", ")};
                            // window.console.log("fields",rating.indexOf(row.rating))
                            return (
                                <tr style={{}}>
                                    <td style={{height: td_height, ...border_right, paddingLeft: 3}}>{row.rating}</td>
                                    {fields.map ((f) => {
                                        const _col = f.split("_");
                                        const col = Number(_col[1]);
                                        if(stop >7 && stop <10){
                                           stop = 10;
                                        }
                                        if(stop >10 && stop <20){
                                            stop = 20;
                                        }
                                        if(stop >20 && stop <30){
                                            stop = 30;
                                        }
                                        if(start > col || stop < col || rating.indexOf(row.rating) < 0 ){
                                            backgroundColor.backgroundColor = "#FFFFFF";
                                            bxs.boxShadow = "#fff 0px 0px 0px 0px inset";
                                        }
                                        else{
                                            backgroundColor.backgroundColor = "#e0efff";
                                            if(row.rating === r0){
                                                bxs.boxShadow = "#95dcfd 0px 1px 0px 0px inset";
                                            }
                                            if(row.rating === r1){
                                                bxs.boxShadow = "#95dcfd 0px -1px 0px 0px inset";
                                            }

                                        }
                                        return (
                                            <td style={{padding, textAlign: "right", width: num_width, height: td_height,  ...backgroundColor,...bxs}}>
                                            {Globals.formatter(row[f]/100, 2, 2, true)}</td>
                                        )}
                                    )}
                                </tr>
                            );
                        })}
                        </tbody>
                    </HTMLTable>
                </div>
            </div>
        );
    }

    private renderCreditSpreadTimeLine(){
        const dates = getAllNextDates(deadlineEoM(), 3)
            .map((d)=>moment(d, "DD.MM.YYYY").format("YYYYMMDD"))
            .reverse();
        const dates_map = {};
        dates.forEach((i, idx)=> dates_map[i] = idx);
        const sector = this.getParameterValue(EParameters.SectorParameter) === "0000" ? "0" : this.getParameterValue(EParameters.SectorParameter);
        this.sector = sector;
        const v_x_fields = ["v_1_j", "v_3_j", "v_5_j", "v_7_j", "v_10_j"];
        const v_x_labels = [
            _t(ETranslation.year_number, 1),
            _t(ETranslation.years_number, 3),
            _t(ETranslation.years_number, 5),
            _t(ETranslation.years_number, 7),
            _t(ETranslation.years_number, 10)
        ];
        const v_x_colors = [
            "#68C1EE",
            "#3FA6DA",
            "#147EB3",
            "#0F6894",
            "#0C5174",
        ];
        const spread_time_line: ICorporateSpread[] = this.data.spread_time_line;

        // für jedes rating kommt ein datensatz, deswegen werden die in values abgelegt und später der median ermittelt.
        const data_date_map: {[index: string]: {[index: string]: {values: number[]}}} = {};
        const add_data = (date_decimal: string, spread: ICorporateSpread)=>{
            if(!data_date_map[date_decimal]){
                data_date_map[date_decimal] = {};
                // initialisierung für jedes feld mit einem leeren array
                v_x_fields.forEach((field) =>{
                    data_date_map[date_decimal][field] = {values: []};
                });
            }
            v_x_fields.forEach((field) =>{
                data_date_map[date_decimal][field].values.push(spread[field]);
            });
        };
        const rating: string[] = SessionStore.get(EParameters.RatingParameter);
        spread_time_line.forEach((i, idx)=>{
            if(i.sector === sector && rating.indexOf(i.rating)>=0){
                add_data(i.date_decimal, i);
            }
        });
        const xy = (x, values: number[]) => ({x, y: median(values) / 100 });
        const get_xy = (x, source: {[index: string]: {values: number[]}}, field_name: string) =>{
            if(!source){
                return {x, y: undefined};
            }else{
                return xy(x, source[field_name].values);
            }
        };
        const time_lines = (new Array(v_x_fields.length)).fill(0).map(()=>([]));
        const dates_formatted = [];
        dates.forEach((date, x)=>{
            dates_formatted.push(moment(date, "YYYYMMDD").format("MMM YY"));
            v_x_fields.forEach((field, field_idx)=>{
                const data_set= time_lines[field_idx];
                data_set.push(get_xy(x, data_date_map[date], field));
            });
        });
        // console.error(date_map);
        // console.error(data_date_map);
        // console.error(time_lines);

        const options = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            interaction: {
                intersect: true,
                mode: 'index',
            },
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        z: 10,
                        padding: 10,
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                },
                x: {
                    type: 'linear',
                    min: 0,
                    max: dates.length - 1,
                    ticks: {
                        z: 10,
                        padding: 10,
                        stepSize: 6,
                        callback: (value, index, ticks)=>{
                            // console.error(value, index, ticks);
                            return dates_formatted[parseInt(value, 10)];
                        },
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend: {
                    display: true,
                    position: "top", // "chartArea",
                    align: "center",
                    labels: {
                        boxWidth: 10,
                    }
                },
                datalabels: {
                    display: 0,
                },
                tooltip: {
                    enabled: true,
                    position: "nearest",
                    callbacks: {
                        title: (items)=> {
                            return dates_formatted[parseInt(items[0].label, 10)];
                        },
                        label: (context)=> {
                            let label = context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += Globals.formatter(context.parsed.y) + "%";
                            }
                            return label;
                        },
                    },
                },
            },
            custom_axis_label: {
                x_label: _t(ETranslation.date),
                y_label: "[% p.a.]",
            },
        };
        const chart_data = {
            labels: dates.map((date)=> moment(date, "YYYYMMDD").format("MMM YY")),
            datasets: []
        };
        v_x_fields.forEach((field, field_idx)=>{
            const ds = {
                label: v_x_labels[field_idx],
                data: time_lines[field_idx],
                backgroundColor: v_x_colors[field_idx],
                borderColor: v_x_colors[field_idx],
                borderWidth: 1,
                pointRadius: 3,
                pointBorderWidth: 2,
                pointHitRadius: 3,
                pointBorderColor: "#ffffff",
                clip: 10,
            };
            chart_data.datasets.push(ds);
        });
        // console.error(chart_data.datasets);
        return (
            <div className={"sz-row"}>
                <div style={{marginBottom: 20, fontSize: "80%"}}>
                    <strong>{_t(ETranslation.credit_spread_over_time)}{Globals.superscript(1)} [% p.a.]</strong>
                    <br/>
                    {this.getSelectedSector(false)}
                </div>
                <div className={"sz-col-100"} style={{position: "relative", width: "100%", height: 400}}>
                    <Line options={options as any} data={chart_data as any} height={"100%"} width={"100%"} />
                </div>
                <div style={{marginTop: 20, width: "100%", fontSize: "80%"}} className={"bp3-text-muted"}>
                    <div>{Globals.superscript(1)}) {_t(ETranslation.median)}</div>
                </div>
            </div>
        );
    }
    private getSelectedSector(no_rating) {
        const sector = this.getParameterValue(EParameters.SectorParameter, "0");
        const ff = Sectors.values.find((item) => parseInt(item.value, 10) === parseInt(sector,10));
        const s = ff ? ff.label : _t(ETranslation.sector_all_avg);
        if(no_rating){
            return s;
        }

        const rating: string[] = SessionStore.get(EParameters.RatingParameter);
        const r0 = rating[0];
        const r1 = rating[rating.length - 1];
        const r = r0 === r1 ? r1 : `${r0} ${_t(ETranslation.to)} ${r1}`;

        return `${s}, ${_t(ETranslation.rating)}: ${r}`;
    }

    private renderCorporateSpread(){
        const dd = deadline(true).asDateDecimal();
        if(dd < old_date_decimal){
            return(<CorporateSpreadOld ref={this.aRef} component={this.props.component} dataSource={this.props.dataSource} parameters={this.props.parameters} dataSourceId={this.props.dataSourceId} name={this.props.name} contentType={this.props.contentType} minPackage={this.props.minPackage} tabbed={this.props.tabbed} dataDefaults={this.props.dataDefaults} nopeActions={this.props.nopeActions} options={this.props.options} isHidden={this.props.isHidden} />);
        }else{
            return(<CorporateSpreadNew ref={this.aRef} component={this.props.component} dataSource={this.props.dataSource} parameters={this.props.parameters} dataSourceId={this.props.dataSourceId} name={this.props.name} contentType={this.props.contentType} minPackage={this.props.minPackage} tabbed={this.props.tabbed} dataDefaults={this.props.dataDefaults} nopeActions={this.props.nopeActions} options={this.props.options} isHidden={this.props.isHidden} />);
        }
    }

    private renderRatingAnd(){
        const bond_data = this.data.all_bond_data;
        const selected_rating_map = this.data.selected_rating_map;
        if(!Array.isArray(bond_data)){
            return null;
        }
        // spreads group by companies
        const ids = [];
        const tmp: {[id: string]: number[]} = {};
        const co: {[id: string]: ICompanySpread} = {};
        const ratings: number[] = [];
        bond_data.forEach((b)=>{
            if(!co[b.fi_identifier_pticker]){
                const rating = Ratings.moodys_to_sp[b.rating];
                if(selected_rating_map[b.rating]){
                    ratings.push(CreditSpreadOptions.get_rating_idx(rating));
                }
                ids.push(b.fi_identifier_pticker);
                co[b.fi_identifier_pticker] = {
                    cid: b.fi_identifier_pticker,
                    rating,
                    count: 0,
                    id: b.fi_name_issr,
                    spread: 0,
                    index: 0,
                    security_id:  b.fi_identifier_pticker,
                };
                tmp[b.fi_identifier_pticker] = [];
            }
            if(b.ftid_spread < 0){
                return;
            }
            if(b.ftid_spread > 800){
                return;
            }
            co[b.fi_identifier_pticker].count++;
            tmp[b.fi_identifier_pticker].push(b.ftid_spread/100);
        });
        const median_rating = Math.round(median(ratings));
        const median_spread = SessionStore.getGlobalVar(EValueName.credit_spreads, undefined) * 100;
        const median_ratings = (new Array(Ratings.s_and_p.length)).fill(0).map(()=> new Array());
        const dots: IDot[]  = [];
        const dots_median: IDot[]  = [];
        const boxed_companies = {};
        ids.forEach((id)=>{
            const i = co[id];
            if(!i.count){
                return;
            }
            co[id].spread = median(tmp[id]);
            boxed_companies[i.security_id] = true;
            const rating= Ratings.s_and_p_mapped_to_num[i.rating];
            if(rating === undefined){
                return;
            }
            median_ratings[rating].push(i.spread);
            dots.push({
                x: rating,
                y: i.spread,
                dataSet: i,
            });
        });
        median_ratings.forEach((m, rating)=>{
            if(m.length === 0){
                return;
            }
            dots_median.push({
                x: rating,
                y: median(m),
                dataSet: undefined,
            });
        });
        // EventBus.emit("SessionStore::SetGlobalVar", null);
        const chart_data = {
            datasets: [
                {
                    label: _t(ETranslation.company),
                    type: "bubble",
                    data: dots,
                    backgroundColor: "#00000010",
                    datalabels: {
                        labels: {
                            title: null
                        }
                    }
                },
                {
                    label: _t(ETranslation.median),
                    type: "bubble",
                    data: dots_median,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    pointRadius: 10,
                    datalabels: {
                        labels: {
                            title: null
                        }
                    }
                },
            ]
        };
        chart_data.datasets.push({
            order: -1,
            type: "line",
            label: _t(ETranslation.credit_spread),
            data: [{x: 0, y: median_spread, a: true}, {x: Ratings.s_and_p.length - 1, y: median_spread}],
            backgroundColor: "#f47a22",
            borderColor: "#f47a22",
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 0,
            datalabels: {
                display: 1,
                align: "-30",
                anchor: "end",
                offset: 5,
                backgroundColor: "rgba(255,255,255,0.9)",
                formatter: (value, context, a)=>{
                    if(!value.a){
                        return null;
                    }
                    return `${_t(ETranslation.credit_spread)}: ${Globals.formatter(value.y)}`;
                },
                labels: {
                    title:{
                        color: "#f47a22",
                    }
                }
            }
        } as any);

        const options = {
            stacked: false,
            responsive: true,
            hover: {mode: null},
            maintainAspectRatio: false,
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        z: 10,
                        padding: 10,
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: 0,
                    suggestedMax: 8,
                },
                x: {
                    ticks: {
                        z: 10,
                        padding: 10,
                        callback: (value)=>{
                            return Ratings.s_and_p[value];
                        },
                        /*maxRotation: 0,*/
                    },
                    border:{
                        display: true,
                        color: ["#485d63","rgba(0, 0, 0, 0.1)"],
                        dash: [2],
                    },
                    grid: {
                        drawTicks: true,
                        tickLength: -5,
                        tickColor: "#485d63",
                    },
                    min: 0,
                    suggestedMax: Ratings.s_and_p.length - 1,
                    max: Ratings.s_and_p.length - 1,
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: undefined,
                },
                legend: {
                    display: true,
                    position: "top", // "chartArea",
                    align: "center",
                    labels: {
                        boxWidth: 10,
                    }
                },
                datalabels: {
                    display: 0,
                },
                tooltip: {
                    enabled: true,
                    mode: "point",
                    callbacks: {
                        label: (item)=> {
                            if(!item.raw.dataSet){
                                return null;
                            }
                            const data: ICompanySpread = item.raw.dataSet;
                            return data.id;
                        },
                        title: (item)=> { return undefined;},
                    },
                },
                annotation: {
                    display: true,
                    enabled: true,
                    annotations: {
                        coc_corporate_spread_rating: Annotations.coc_corporate_spread_rating(),
                    },
                },
            },
            regression_line: {
                display: "vertical_x",
                regression: undefined,
                vertical_x: median_rating,
                vertical_label: Ratings.s_and_p[median_rating],
                vertical_x_color: "#f47a22",
                color: "rgba(255, 99, 132, 1)",
                lineDash: [5,5],
            },
            custom_axis_label: {
                x_label: `Rating`,
                y_label: `[% p.a.]`,
            }
        };

        return (
            <>
                <div style={{marginBottom: 20, fontSize: "80%"}}>
                    <strong>{_t(ETranslation.credit_spread_ratings)}{Globals.superscript(1)} [% p.a.]</strong>
                    <br/>
                    {this.getSelectedSector(true)}
                </div>
                <div style={{width: "100%", height: 400, marginTop: 10, marginBottom: 10}}>
                    <Bubble options={options as any} data={chart_data as any} height={"100%"} width={"100%"} />
                </div>
            </>
        );
    }
}
