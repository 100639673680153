import * as React from "react";
import {CSSProperties} from "react";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {ParameterOptions} from "../../../const/ParameterOptions";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {CreditSpreadOptions} from "../../../const/CreditSpreadOptions";
import {Menu} from "@blueprintjs/core";
import {ISzSelectProperties} from "../../widgets/SzSelect";
import {SessionStore} from "../../../const/SessionStore";
import {_t} from "../../../tools/Translator";
import {ETranslation} from "../../../const/ETranslation";

const label_style: CSSProperties={
    display: "flex",
    paddingTop: 10,
};

export class CreditSpreadConfigParameter extends CombiParameter {

    private groups = [];

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    public static getPdfLabel() {
        const cc = SessionStore.get(EParameters.CreditSpreadXRangeParameter);
        const pcc = parseFloat(SessionStore.get(EParameters.CreditSpreadAnalysisIntervalParameter))*100;
        const content = [];
        content.push({text:[
                {text: `Longlist (# Unternehmen): `,bold: true}, `${cc}`
                ]});
        content.push({text:[
                {text: `Shortlist (% von Longlist): `,bold: true}, `${pcc}%`,
            ]});
        return {
            stack: content,
        };
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.CreditSpreadConfigParameter];
    }

    protected getWidthClass(): string {
        return "fixed-width-400";
    }

    protected async initialize() {
        return this.initializeShort(EParameters.CreditSpreadConfigParameter);
    }

    protected renderMenuContent(): any {
        const all_keys = CreditSpreadOptions.use_ratio;
        this.groups = [];
        let current = [];
        all_keys.forEach((key)=>{
            if(key.startsWith("#")){
                current = [];
                this.groups.push({
                    caption: key.substring(1),
                    items: current,
                });
            }else{
                current.push(key);
            }
        });
        return (
            <div className={"sz-row"}>
                <div style={{paddingTop: 10, paddingRight: 10}} className={"sz-row"}>
                    {this.renderSelect(EParameters.CreditSpreadXRangeParameter)}
                    {this.renderSelect(EParameters.CreditSpreadAnalysisIntervalParameter)}
                </div>
                <div className={"sz-row bp3-text-muted"} style={{fontSize: "80%", padding: 10}}>
                    * {_t(ETranslation.default_smartzebra)}
                </div>
                {this.renderHelpText(PARAMETER_HELP(EParameters.CreditSpreadConfigParameter))}
            </div>
        );
    }

    protected getLabel(): any {
        const cc = `#${SessionStore.get(EParameters.CreditSpreadXRangeParameter)}`;
        const pcc = `${parseFloat(SessionStore.get(EParameters.CreditSpreadAnalysisIntervalParameter))*100}%`;

        const l = [cc, pcc];
        return l.join(" | ");
    }

    protected onClosePopup() {
        this.fireShort(EParameters.CreditSpreadConfigParameter);
    }

    private onChangeValue(field: EParameters, _value: any) {
        const _valueAsNumber = _value;
        const state: {[k: string]: any} = {};
        state[field] = _valueAsNumber;
        this.setState(state);
        // this.fireValueChange(field, _valueAsNumber);
    }
    private getInputProps(parameter: EParameters, optionParam?: EParameters): ISzSelectProperties{
        return {
            options: optionParam ? ParameterOptions.options[optionParam] : ParameterOptions.options[parameter],
            value:  this.state[parameter],
            onValueChanged: (v: any) => this.onChangeValue(parameter, v),
        };
    }
    private renderSelect(parameter: EParameters) {
        const props = this.getInputProps(parameter);
        const label = this.getParameterLabel(parameter);
        return this.renderColumn("50%", label, ()=>{
            return (
                <Menu style={{minWidth: "100%"}}>
                    {this.renderOptionsShort(parameter)}
                </Menu>
            );
        }, label_style);
    }
}
