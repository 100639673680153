import {Collapse} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";

export interface ISzCollapseState {
    isOpen: boolean;
}
export interface ISzCollapseProps {
    caption: string;
}

export class SzTinyCollapse extends React.Component<ISzCollapseProps, ISzCollapseState> {

    constructor(props: ISzCollapseProps, context: any) {
        super(props, context);
        this.state = {
            isOpen: false,
        };
    }

    public render() {
        const getIcon = (o) => {
            if (o) {
                return "minus";
            } else {
                return "plus";
            }
        };
        const style: CSSProperties = {
            width: "100%",
        };
        // <span style={{display: "block"}}><Icon icon={getIcon(this.state.isOpen)} /> {this.props.caption}</span>
        return (
            <div className={"sz-tiny-collapse"} style={style} onClick={() => { this.setState({isOpen: !this.state.isOpen}); }}>
                <div style={{display: "block", width: "100%"}}>{this.props.caption}</div>
                <Collapse isOpen={this.state.isOpen}>
                    {this.props.children}
                </Collapse>
            </div>
        );
    }
}
