import {Button, Intent, Switch, TagInput, TagProps} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../../const/Globals";
import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {CombiParameter} from "../CombiParameter";
import {PARAMETER_HELP} from "../../../const/ParameterHelpText";
import {IconNames} from "@blueprintjs/icons";

export class TpKeyWordsParameter extends CombiParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.TpKeyWordsParameter];
    }

    protected async initialize() {
        const key_words = SessionStore.get(EParameters.TpKeyWordsWordsParameter);
        const key_words_operation = SessionStore.get(EParameters.TpKeyWordsOperParameter);
        return {
            key_words: Array.isArray(key_words) ? key_words : [],
            key_words_operation,
        };
    }

    protected onClosePopup() {
        this.fireChanges([
            {component: EParameters.TpKeyWordsWordsParameter,value: [this.state.key_words]},
            {component: EParameters.TpKeyWordsOperParameter,value: [this.state.key_words_operation]},
        ]);
    }
    protected getWidthClass() {
        return "fixed-width-400";
    }
    public getLabel() {
        const words: string[] = this.state.key_words;
        if(!Array.isArray(words)){
            return Globals.hyphen;
        }
        if(!words.length){
            return Globals.hyphen;
        }
        try{
            return `${(words.slice(0,3)).join(", ")}${words.length>3 ? " ..." : ""}`;
        }catch(e){
            console.error(e);
        }
        return Globals.hyphen;
    }
    protected renderMenuContent(): any {
        const values = this.state.key_words;
        const oper = this.state.key_words_operation;
        const clearButton = (
            <Button
                disabled={!values.length}
                icon={IconNames.CROSS}
                minimal={true}
                onClick={() => this.setState({key_words: []})}
            />
        );
        const tagProps: TagProps = {
            minimal: true,
            intent: Intent.PRIMARY
        };
        const hndOnChange = (data)=>{
            // console.error(data);
            this.setState({key_words: data ? data : []});
        };
        return (
            <div>
                <div className={"sz-row"} style={{padding: 8}}>
                    <TagInput values={values} fill={true} tagProps={tagProps} rightElement={clearButton} onChange={(data)=> hndOnChange(data)} />
                    <div className={"sz-row"} style={{marginTop: 8}}>
                        <Switch label={"Verknüpft"} innerLabel={"ODER"} innerLabelChecked={"UND"} checked={this.state.key_words_operation} onClick={()=>this.setState({key_words_operation: !this.state.key_words_operation})}/>
                    </div>
                </div>

                <div className={"sz-row"}>
                    {this.renderHelpText(PARAMETER_HELP(EParameters.TpKeyWordsParameter), "fit-content")}
                </div>
            </div>
        );
    }
}
