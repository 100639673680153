import {ParameterConfigs} from "../../../const/ParameterConfigs";
import {SessionStore} from "../../../const/SessionStore";
import {EParameters} from "../../../models/EParameters";
import {IParameterConfig} from "../../../models/IParameterConfig";
import {IToolBarParameter} from "../../../models/IToolBarParameter";
import {SingleSelectParameter} from "../SingleSelectParameter";

export class PledgeStatusParameter extends SingleSelectParameter {

    constructor(props: IToolBarParameter, context: any) {
        super(props, context);
    }
    protected getConfig(): IParameterConfig {
        return ParameterConfigs.configs[EParameters.PledgeStatusParameter];
    }
    protected async getSelectedValue() {
        return SessionStore.get(EParameters.PledgeStatusParameter);
    }
    protected async getOptions() {
        return [
            {label: "Alle", value: null},
            {label: "unbesichert", value: "Unsecured"},
            {label: "besichert", value: "Secured"},
        ];
    }
}
