import * as React from "react";
import {useState} from "react";
import {Button, Icon} from "@blueprintjs/core";
import {Link} from "react-router-dom";

import {FrontendCalls} from "../../services/FrontendCalls";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";
import {newPasswordRequestError} from "./AppToaster";


export function RequestNewPassword(){
    const [uuid, setUuid] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const send = (e)=> {
        e.preventDefault();
        e.stopPropagation();
        if(isLoading){
            return;
        }
        setIsLoading(true);
        (async ()=>{
            try{
                const fc = new FrontendCalls();
                const res = await fc.new_password_request(uuid);
                if(res.error){
                    newPasswordRequestError();
                }
            }catch (e) {
                console.error(e);
            }
            setIsLoading(false);
        })();
    };

    return (
        <div className="login-container">
            <form className="form" id={"loginform"} method={"post"}  onSubmit={(e)=> {send(e);}}>
                <div className="form-content-wrapper">
                    <div className="title-container"><h1 className="title">{_t(ETranslation.get_new_pass)}</h1></div>
                    <br />
                    <div className="email-container password-email-container">
                        <label className="email-label" htmlFor="username" >E-Mail</label>
                        <div className="email-input-wrapper">
                            <input
                                className="email-input"
                                autoComplete="username"
                                id="username"
                                name={"username"}
                                value={uuid}
                                onChange={(e)=> setUuid(e.target.value)}
                                required={true}
                            />
                            <div className="email-icon">
                                <Icon  icon="user" size={20}  />
                            </div>
                        </div>
                    </div>
                    <div className="login-btn-container">
                        <Button loading={isLoading} intent={"primary"} className="login-btn send-btn" type={"submit"} disabled={!uuid}>{_t(ETranslation.send)}</Button>
                    </div>
                    <div className="divider"></div>
                    <div className="register-link-container-container">
                        <Link className="link" to="/login">{_t(ETranslation.login)}</Link>&nbsp;|&nbsp;<a href={"mailto:service@smart-zebra.de?subject=New Password"} target={"_blank"}>Kontakt Support</a>
                    </div>
                </div>
            </form>
        </div>
    );
}
