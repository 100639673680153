import {Icon, Menu, MenuItem, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {CSSProperties} from "react";
import {ErrorTypes, IErrorType} from "../../const/ErrorTypes";
import {Globals} from "../../const/Globals";
import {SessionStore} from "../../const/SessionStore";
import {find_object} from "../../helpers/Helpers";
import {TableStatistics} from "../../helpers/table/TableStatistics";
import {AvgMarketDataResponse, IAvgMarketDataResponse} from "../../models/chef/AvgMarketData";
import {FinancialReportMap} from "../../models/chef/FinancialReported";
import {IRawBetaResponse, IRegression, RawBetaResponse} from "../../models/chef/RawBeta";
import {EParameters} from "../../models/EParameters";
import {ICountry} from "../../models/ICountry";
import {IFinancialAnnualReported} from "../../models/tables/FinancialAnnualReported";
import {TasksCalls} from "../../services/TasksCalls";
import {ICompanyBase} from "../../tables/CompanyBase";
import {EIndexFunction, SzTableHelper} from "../widgets/helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties, SzTable} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {BetaFilterState} from "../../helpers/BetaFilterState";
import {fromDateDecimal, fromDateParameter, fromStr} from "../../tools/DateTools";
import {renderLongListAction} from "./company_info/TableHelper";
import {renderDebtDlg, selectRow} from "./company_info/CompanyInfoDlg";
import {_t, _ta, _tf} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {EBorder, PdfTable} from "../../pdf-tools/PdfTable";
import {EPdfColumnDataType} from "../../pdf-tools/PdfTableColumn";
import {EStatisticsValueType} from "../../const/EStatisticsValueType";


const _ff = (v) => {
    const ff = parseFloat(v);
    if (isNaN(ff) || !v) {
        return undefined;
    }
    return ff;
};

export class BetasStatistics2 extends BaseModule<any> {

    private tableProps: ISzTableProperties<any[]>;

    private data_debt_beta: ISzTableProperties<any[]>;
    private data_tax_shield: ISzTableProperties<any[]>;
    private data_debt: ISzTableProperties<any[]>;
    constructor(props: any, context: any) {
        super(props, context);
        const s = {
            loading: true,
        };
        this.state = BetaFilterState.merge(s);
        BetaFilterState.listen(this);
    }
    public async getDocumentationData() {
        return this.tableProps;
    }
    public getExcelData(target) {
        target.data_stats = this.tableProps.data;
    }
    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }
    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.tableProps);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    public exportAsExcelMappe() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelMapTask([
                    this.data_debt_beta,
                    this.data_tax_shield,
                    this.data_debt,
                ]);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
                Globals.trackInfo(EStatisticsValueType.excel_export, "unlevered_beta_details");
            } catch (ex) {
                console.error(ex);
            }
        })();
    }
    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected renderContent() {
        // console.error("parameters", this.parameters);
        return (<div>{this.data ? this.renderData() : this.noData()}</div>);
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    private renderData() {
        const selected_period = SessionStore.get(EParameters.PeriodsParameter);
        const market_data_avg: AvgMarketDataResponse = this.data.market_data_avg;
        const raw_betas: RawBetaResponse = this.data.raw_betas;

        const table = [];
        const _regression = (company_id): IRegression =>{
            const raw_beta: IRawBetaResponse = raw_betas[company_id];
            if(!raw_beta){
                return {} as IRegression;
            }
            const r = raw_beta.periods[selected_period];
            if(!r){
                return {} as IRegression;
            }

            return r;
        };
        this.data.company_ids.forEach((company_id, index) => {
            if(this.state?.show_company && !this.state?.show_company[company_id]){
                return;
            }
            const is_canceled = BetaFilterState.isCanceled(company_id, this.data);
            if(is_canceled){
                return;
            }
            const company: ICompanyBase = this.data.companies[company_id];
            const country: ICountry = Globals.country_map[company.ex_country_id];
            if(!country){
                return;
            }
            const regression: IRegression = _regression(company_id);
            const market_data: IAvgMarketDataResponse = market_data_avg[company_id];
            const data_row = [
                index + 1,
                company.co_name || company.name,
                country.currency_iso_3,
                "n.a.",
                _ff(regression.DataPoints),
                _ff(regression.R2),
                _ff(regression.TTestSlope),
                _ff(regression.FTest),
                (100 - _ff(market_data.avg_ff_shs_closely_held_pct)) / 100,
                _ff(market_data.p_volume_avg),
                _ff(market_data.p_volume_avg) * _ff(market_data.p_price_avg),
                _ff(market_data.xp_daily_avg_spread),
                _ff(market_data.cc_price),
                _ff(market_data.p_volume_avg) / _ff(market_data.avg_fa_shs_float), // 13
                _ff((market_data.p_volume_avg) * _ff(market_data.p_price_avg)) / _ff(market_data.avg_ff_mkt_val), // 14
                company_id, // 15
                _ff(regression.DurbinWatsonTest),
                _ff(regression.BreuschPaganTest),
            ];
            table.push(data_row);
        });

        // const header = ["No.", "Name", "Free float", "Handelsvolumen", "Anzahl Datenpunkte", "Bestimmtheitsmaß", "T-Test", "F-Test"];
        const header_2: ISzTableColumn[] = [];
        const header_1: ISzTableColumn[] = [];
        header_1.push({ text: "" });
        header_1.push({ text: _t(ETranslation.company), options: {colSpan: 2} });
        header_1.push({ text: "FX¹" });
        header_1.push({ text: "Free Float²", options: {className: "sz-right"} });
        header_1.push({ text: ""});
        header_1.push({ text: _t(ETranslation.trading_volumes_liquidity), options: {colSpan: 6, className: "sz-center"} });
        header_1.push({ text: ""});
        header_1.push({ text: _t(ETranslation.regression_statistics), options: {colSpan: 5, className: "sz-center"} });

        header_2.push({ text: ""});
        header_2.push({ text: "#"});
        header_2.push({ text: "Name"});
        header_2.push({ text: ""});
        header_2.push({ text: ""});
        header_2.push({ text: ""});
        header_2.push({ text: _ta(ETranslation["mio_#"], "³"), options: {className: "sz-right"} });
        header_2.push({ text: _ta(ETranslation["mio_#_relative"], "⁴"), options: {className: "sz-right"} });
        header_2.push({ text: _ta(ETranslation.mio_fx, "⁵"), options: {className: "sz-right"} });
        header_2.push({ text: _ta(ETranslation.mio_fx_relative, "⁶"), options: {className: "sz-right"} });
        header_2.push({ text: _ta(ETranslation.bid_ask_spread, "⁷"), options: {className: "sz-right"} });
        header_2.push({ text: `# ${_t(ETranslation.trading_days)}⁸`, options: {className: "sz-right"} });
        header_2.push({ text: ""});
        // header_2.push({ text: "Datenpunkte⁹", options: {className: "sz-right"} });
        header_2.push({ text: _ta(ETranslation.r_square, "⁹"), options: {className: "sz-right"} });
        header_2.push({ text: "T-Test¹⁰", options: {className: "sz-right"} });
        header_2.push({ text: "F-Test¹¹", options: {className: "sz-right"} });

        header_2.push({ text: "DW-Test¹²", options: {className: "sz-right"} });
        header_2.push({ text: "BP-Test¹³", options: {className: "sz-right"} });

        const apply_style: CSSProperties = {
            whiteSpace: "nowrap",
        };
        header_2.forEach((h)=>{
            if(!h.options){
                return;
            }
            if(h.options.style){
                h.options.style = {...apply_style, ...h.options.style};
            }else{
                h.options.style = {...apply_style};
            }
        });
        const columns: ISzTableColumn[] = [];

        const period = parseInt(this.parameters.PeriodsParameter[0], 10);
        const date_decimal_end = fromDateParameter().asDateDecimal(); // parseInt(moment(this.parameters.DateParameter[0], "DD.MM.YYYY").format("YYYYMMDD"), 10);
        const date_decimal_start = fromDateParameter().minus({year: period}).asDateDecimal();

        const chk_fin_ann_reported = (companyId) => {
            const ann_reported: FinancialReportMap = this.data.financial_reports[companyId];
            const last: IFinancialAnnualReported = find_object(ann_reported, (key, o: IFinancialAnnualReported)=>{
                const dd = fromDateDecimal(date_decimal_end).diff(fromDateDecimal(key), "months").months;
                return dd < 18;
            });
            return last;
        };

        const renderWarning = (company_name, title, type: IErrorType = ErrorTypes.warning) => {
            return (
                <div title={title} style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <span style={{color: type.color}}>{company_name}</span>
                    <Icon icon={IconNames.WARNING_SIGN} size={16} intent={type.intent}/>
                </div>
            );
        };
        const company_details = this.data.companies;
        const aktion_col: ISzTableColumn = {
            index: "",
            options: {
                style: {borderRight: "4px solid #ffffff", width: 30, verticalAlign: "middle"},
                cellRenderer: (cellValue: any, data?: any) => {
                    return renderLongListAction(this, company_details[data[15]], true);
                },
            },
        };
        const name_col: ISzTableColumn = {
            index: "1",
            options: {
                style: {borderRight: "4px solid #ffffff"},
                cellRenderer: (cellValue: any, data?: any) => {
                    const details = company_details[data[15]];
                    if (!details) {
                        return (<span>{cellValue}</span>);
                    }
                    const n_p_first_date = fromStr(details.p_first_date, "LL/dd/yyyy").asDeDate();
                    const n_p_last_date = fromDateDecimal(details.p_last_date).asDeDate();
                    const p_first_date = fromStr(details.p_first_date, "LL/dd/yyyy").asDateDecimal();
                    const p_last_date = parseInt(details.p_last_date, 10);
                    const warn = date_decimal_start < p_first_date || p_last_date < date_decimal_end;
                    const warn_2 = false;

                    let title = p_last_date < date_decimal_end ? `Delisted seit ${n_p_last_date}` : date_decimal_start < p_first_date ? `IPO(${n_p_first_date}) liegt im Betrachtungszeitraum` : "";
                    if (warn_2) {
                        title += `Das "Unlevered Beta" ist größer als das "Levered Beta".`;
                    }

                    let name_cell = (<span>{cellValue}</span>);

                    if (warn || warn_2) {
                        name_cell = renderWarning(cellValue, title);
                    }

                    if (!chk_fin_ann_reported(data[15])) {
                        name_cell = renderWarning(cellValue, "Der letzte vorliegende Finanzbericht zu diesem Unternehmen ist älter als 18 Monate.", ErrorTypes.error);
                    }

                    return name_cell;
                },
            },
        };
        columns.push(aktion_col);
        columns.push({ ...SzTableHelper.columnFunction(EIndexFunction.$row_num), options: {style: {width: 10} }}); // index
        columns.push(name_col); // unternehmen
        columns.push({ ...SzTableHelper.columnIndex("2", 50)} ); //
        columns.push({ ...SzTableHelper.columnPercent("8", 1, {className: "sz-number", style: {width: 90}})}); //
        columns.push({ ...SzTableHelper.columnIndex(""), options: {style: {width: 10, borderLeft: "4px solid #ffffff"} }});
        const w = 100;
        const w2 = 120;
        columns.push({ ...SzTableHelper.columnMoney("9", 2, {className: "sz-number", style: {width: w}}) }); // Vol. Mio. #³
        columns.push({ ...SzTableHelper.columnPercent("13", 2, {className: "sz-number", style: {width: w}}) }); // # in % Free Float
        columns.push({ ...SzTableHelper.columnMoney("10", 2, {className: "sz-number", style: {width: w}}) }); // Vol. Mio. FX⁴
        columns.push({ ...SzTableHelper.columnPercent("14", 2, {className: "sz-number", style: {width: w2}}) }); // FX in % Market Cap.
        columns.push({ ...SzTableHelper.columnPercent("11", 2, {className: "sz-number", style: {width: w2}}) }); // Rel. Bid-Ask Spread
        columns.push({ ...SzTableHelper.columnMoney("12", 0, {className: "sz-number", style: {width: w}}) }); // # Handelstage

        columns.push({ ...SzTableHelper.columnIndex(""), options: {style: {width: 10, borderLeft: "4px solid #ffffff"} }});

        // columns.push({ ...SzTableHelper.columnMoney("4", 0, {className: "sz-number", style: {width: 95}}) }); // Anzahl Datenpunkte
        columns.push({ ...SzTableHelper.columnPercent("5", 1, {className: "sz-number", style: {width: 70}}) }); // Bestimmtheitsmaß
        columns.push({ ...SzTableHelper.columnMoney("6", 2, {className: "sz-number", style: {width: 70}}) }); // t-test
        columns.push({ ...SzTableHelper.columnMoney("7", 2, {className: "sz-number", style: {width: 70}}) }); // f-test
        columns.push({ ...SzTableHelper.columnMoney("16", 2, {className: "sz-number", style: {width: 70}}) }); // DurbinWatsonTest
        columns.push({ ...SzTableHelper.columnMoney("17", 2, {className: "sz-number", style: {width: 70}}) }); // BreuschPaganTest

        const footerData =  TableStatistics.getTableStatistics(table, {
            pack: true,
            headColumn: 0,
            columns: [5, 6, 7, 16, 17],
        });
        const footer: ISzTableColumn[] = [];
        footer.push({index: ""});
        footer.push({index: "0", options: { className: "strong", colSpan: 12 }});
        footer.push({ ...SzTableHelper.columnPercent("1", 1, {className: "sz-number", style: {width: 70}}) });
        footer.push({ ...SzTableHelper.columnMoney("2", 2, {className: "sz-number", style: {width: 70}}) });
        footer.push({ ...SzTableHelper.columnMoney("3", 2, {className: "sz-number", style: {width: 70}}) });
        footer.push({ ...SzTableHelper.columnMoney("4", 1, {className: "sz-number", style: {width: 70}}) });
        footer.push({ ...SzTableHelper.columnMoney("5", 1, {className: "sz-number", style: {width: 70}}) });
        const tableProps: ISzTableProperties<any[]> = {
            colCount: columns.length,
            data: table,
            rowHover: true,
            onSelectRow: (row: number, dataRow: any)=> this.onSelectRow(row, dataRow),
            header: [ header_1, header_2 ],
            bar_code: this.getBarCode(),
            footer,
            footerData,
            columns,
        };
        tableProps.notes = [];
        tableProps.notes.push(_tf(1, ETranslation.primary_listing_currency));
        tableProps.notes.push(_tf(2, ETranslation.at_valuation_date));
        tableProps.notes.push(_tf(3, ETranslation.trading_volume_description));
        tableProps.notes.push(_tf(4, ETranslation.trading_volume_ratio_free_float_description));
        tableProps.notes.push(_tf(5, ETranslation.trading_volume_amount_description));
        tableProps.notes.push(_tf(6, ETranslation.trading_volume_amount_ratio_market_cap_description));
        tableProps.notes.push(_tf(7, ETranslation.average_observation_period_beta));
        tableProps.notes.push(_tf(8, ETranslation.trading_days_description));

        tableProps.notes.push(_tf(9, ETranslation.r_square_description));
        tableProps.notes.push(_tf(10, ETranslation.t_test_beta_description));
        tableProps.notes.push(_tf(11, ETranslation.f_test_description));

        tableProps.notes.push(_tf(12, ETranslation.durbin_watson_test_short));
        tableProps.notes.push(_tf(13, ETranslation.breusch_pagan_test_short));

        this.tableProps = tableProps;
        return (
            <div style={{overflow: "hidden", position: "relative", paddingTop: 16}}>
                <SzTable {...tableProps} />
                {renderDebtDlg(this)}
            </div>
        );
    }

    private noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }
    private onSelectRow(row: number, dataRow: any) {
        const cid = dataRow[15];
        selectRow(this, cid);
    }
    public getPdfPage(): any[] {
        const content = [];
        PdfInsertElement.page_header(content, _t(ETranslation.trading_statistics));
        const widths= (new Array(15)).fill("auto").map((a,i)=>i === 1 ? "*" : "auto");
        const table = new PdfTable(content, widths);
        table.addHeaderRow();
        table.addHeader().text(_t(ETranslation.company)).colSpan(2)
            .style().alignment("left").border([EBorder.right]);
        table.addHeader().text("FX");
        table.addHeader().text(_t(ETranslation.free_float))
            .style().alignment("right").border([EBorder.right]);
        table.addHeader().text(_t(ETranslation.trading_volumes_liquidity)).colSpan(6)
            .style().alignment("center").border([EBorder.right]);
        table.addHeader().text(_t(ETranslation.regression_statistics)).colSpan(5)
            .style().alignment("center");

        // zweite header zeile
        table.addHeaderRow();
        //
        table.addHeader().text("#").style().alignment("right");
        table.addHeader().text("Name").style().border([EBorder.right]);

        table.addHeader().text("");
        table.addHeader().text("").style().alignment("right").border([EBorder.right]);

        table.addHeader().text(_t(ETranslation["mio_#"])).style().alignment("right");
        table.addHeader().text(_t(ETranslation["mio_#_relative"])).style().alignment("right");
        table.addHeader().text(_t(ETranslation.mio_fx)).style().alignment("right");
        table.addHeader().text(_t(ETranslation.mio_fx_relative)).style().alignment("right");
        table.addHeader().text(_t(ETranslation.bid_ask_spread)).style().alignment("right");
        table.addHeader().text(`# ${_t(ETranslation.trading_days)}`).style().alignment("right").border([EBorder.right]);

        table.addHeader().text(_t(ETranslation.r_square)).style().alignment("right");
        table.addHeader().text("T-Test").style().alignment("right");
        table.addHeader().text("F-Test").style().alignment("right");
        table.addHeader().text("DW-Test").style().alignment("right");
        table.addHeader().text("BP-Test").style().alignment("right");

        // header ende
        table.addColumn(EPdfColumnDataType.row_num);
        table.addColumn(EPdfColumnDataType.text).index(1).style().border([EBorder.right]);

        table.addColumn(EPdfColumnDataType.text).index(2); // fx
        table.addColumn(EPdfColumnDataType.percent, 1).index(8).style().border([EBorder.right]);

        table.addColumn(EPdfColumnDataType.number, 2).index(9);
        table.addColumn(EPdfColumnDataType.percent, 2).index(13);
        table.addColumn(EPdfColumnDataType.number, 2).index(10);
        table.addColumn(EPdfColumnDataType.percent, 2).index(14);
        table.addColumn(EPdfColumnDataType.percent, 2).index(11);
        table.addColumn(EPdfColumnDataType.number, 0).index(12).style().border([EBorder.right]);

        table.addColumn(EPdfColumnDataType.percent, 1).index(5);
        table.addColumn(EPdfColumnDataType.number, 2).index(6);
        table.addColumn(EPdfColumnDataType.number, 2).index(7);
        table.addColumn(EPdfColumnDataType.number, 2).index(16);
        table.addColumn(EPdfColumnDataType.number, 2).index(17);

        table.addFooter(EPdfColumnDataType.text).index(0).colSpan(10);

        table.addFooter(EPdfColumnDataType.percent, 1).index(1);
        table.addFooter(EPdfColumnDataType.number, 2).index(2);
        table.addFooter(EPdfColumnDataType.number, 2).index(3);
        table.addFooter(EPdfColumnDataType.number, 2).index(4);
        table.addFooter(EPdfColumnDataType.number, 2).index(5);

        table.addRowStyle(1).border([EBorder.bottom]);
        table.addRowStyle(1 + this.tableProps.data.length).border([EBorder.bottom]);

        table.addRowStyle(2 + this.tableProps.data.length).bold(true);
        table.addRowStyle(2 + this.tableProps.data.length + 3).bold(true);

        table.populateTable(this.tableProps.data, this.tableProps.footerData);
        return content;
    }
}
