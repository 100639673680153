import {Icon, Menu, MenuItem, NonIdealState,} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import * as React from "react";
import {ErrorTypes, IErrorType} from "../../const/ErrorTypes";
import {EStatisticsValueType} from "../../const/EStatisticsValueType";
import {Globals} from "../../const/Globals";
import {Sectors} from "../../const/Sectors";
import {SessionStore} from "../../const/SessionStore";
import {mean, median} from "../../helpers/Statistics";
import {TableStatistics} from "../../helpers/table/TableStatistics";
import {IRawBetaResponse} from "../../models/chef/RawBeta";
import {IUnleveredBeta, IUnleveredBetaResponse} from "../../models/chef/UnleveredBeta";
import {EParameters} from "../../models/EParameters";
import {EValueName} from "../../models/EValueName";
import {TasksCalls} from "../../services/TasksCalls";
import {ICompanyBase} from "../../tables/CompanyBase";
import {EIndexFunction, SzTableHelper} from "../widgets/helper/SzTableHelper";
import {ISzTableColumn, ISzTableProperties, SzTable} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {FinancialReportMap} from "../../models/chef/FinancialReported";

import {BetaFilterState} from "../../helpers/BetaFilterState";
import {IFinancialAnnualReported} from "../../models/tables/FinancialAnnualReported";
import {_fmt, find_object} from "../../helpers/Helpers";
import {fromDateDecimal, fromDateParameter, fromStr} from "../../tools/DateTools";
import {renderLongListAction} from "./company_info/TableHelper";
import {renderDebtDlg, selectRow} from "./company_info/CompanyInfoDlg";
import {_t, _t_super, _ta, _tn} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";
import {PdfInsertElement} from "../../pdf-tools/PdfInsertElement";
import {EBorder, PdfTable} from "../../pdf-tools/PdfTable";

export class BetasUnleveredBeta extends BaseModule<any> {
    private tableProps: ISzTableProperties<any[]>;

    private data_debt_beta: ISzTableProperties<any[]>;
    private data_tax_shield: ISzTableProperties<any[]>;
    private data_debt: ISzTableProperties<any[]>;

    constructor(props: any, context: any) {
        super(props, context);
        const s = {
            loading: true,
        };
        this.state = BetaFilterState.merge(s);
        BetaFilterState.listen(this);
    }
    public async getDocumentationData() {
        return this.tableProps;
    }
    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }
    public getExcelData(target) {
        target.data_beta_factor = this.tableProps.data;
    }
    public exportAsExcelMappe() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelMapTask([
                    this.data_debt_beta,
                    this.data_tax_shield,
                    this.data_debt,
                ]);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
                Globals.trackInfo(EStatisticsValueType.excel_export, "unlevered_beta_details");
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    public exportAsExcel(props?: ISzTableProperties<any>) {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(props ? props : this.tableProps);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    protected getClassNames(): string {
        return "sz-module-table";
    }

    protected getModuleOverride(): string {
        return "sz-module-full-row";
    }

    protected renderContent() {
        return (
            <div>
                {this.data ? this.renderData() : this.noData()}
                {renderDebtDlg(this)}
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }
    private renderData() {
        const selected_period = SessionStore.get(EParameters.PeriodsParameter);
        const selectedBetUnlevered = Number(SessionStore.getItem(EParameters.BetaUnleverdOptions,3));
        const years = parseInt(this.parameters.PeriodsParameter[0], 10);
        const date_decimal_end = fromDateParameter().asDateDecimal();
        const date_decimal_start = fromDateParameter().startOf("month").minus({year: years}).endOf("month").asDateDecimal();
        const hideColumns = this.props?.options?.hideColumns;

        const adjusmentBetaParameter = this.parameters.AdjusmentBetaParameter[0];
        const debtbetaParameter = this.parameters.DebtbetaParameter[0];
        const taxshieldParameter: string = this.parameters.TaxshieldParameter[0];

        const useAdjBeta: boolean = adjusmentBetaParameter === "blumeadjusment";
        const hideDebtBeta: boolean = debtbetaParameter === "nicht_anwenden";
        const showTaxShield: boolean = taxshieldParameter.startsWith("sicher");

        const table = [];

        const tax_shield_safe = [];
        const chk_fin_ann_reported = (companyId): IFinancialAnnualReported => {
            const ann_reported: FinancialReportMap = this.data.financial_reports[companyId];
            const last: IFinancialAnnualReported = find_object(ann_reported, (key, o: IFinancialAnnualReported)=>{
                const dd = fromDateDecimal(date_decimal_end).diff(fromDateDecimal(key), "months").months;
                return dd < 18;
            }, true);
            return last;
        };

        this.data.company_ids.forEach((company_id) => {

            if(this.state?.show_company && !this.state?.show_company[company_id]){
                return;
            }
            const is_canceled = BetaFilterState.isCanceled(company_id, this.data);
            if(is_canceled){
                return;
            }
            const company: ICompanyBase = this.data.companies[company_id];
            const country = Globals.country_map[company.ex_country_id];
            const unlevered_beta_response: IUnleveredBetaResponse = this.data.unlevered_betas[company_id];
            const unlevered_beta: IUnleveredBeta = unlevered_beta_response
                ? unlevered_beta_response[selected_period]
                : {};

            const fin_report:IFinancialAnnualReported = chk_fin_ann_reported(company_id);
            const raw_beta: IRawBetaResponse = this.data.raw_betas[company_id];
            table.push([
                company.co_name || company.name,
                country?.iso_3,
                country?.region_de,
                raw_beta?.stock_name,
                raw_beta?.stock_number_idx,
                unlevered_beta?.result?.levered_beta,
                unlevered_beta?.result?.debt_beta,
                unlevered_beta?.result?.tax_shield,
                unlevered_beta?.result?.gearing,
                unlevered_beta?.result?.unlevered_beta,
                unlevered_beta_response?.raw_beta,
                unlevered_beta?.result?.levered_beta,
                company.id,
                Sectors.sectors_map[company.sector_id],
                company.sector_id,
                company.p_first_date,
                company.p_last_date,
                fin_report?false:true,
                fin_report?fromDateDecimal(fin_report.date_decimal).toFormat("LL|yy") : Globals.hyphen,
            ]);
        });

        const header_2: ISzTableColumn[] = [];
        if (!hideColumns) {
            header_2.push({ text: "" });
        }
        header_2.push({ text: "#" });
        header_2.push({ text: _t(ETranslation.company) });
        header_2.push({ text: "" });

        if (!hideColumns) {
            header_2.push({ text: _t(ETranslation.sector) });
        }

        header_2.push({ text: _t(ETranslation.country) });
        if (!hideColumns) {
            header_2.push({text: "Region"});
            header_2.push({ text: _t(ETranslation.stock_index) });
            header_2.push({ text: _tn(ETranslation.constituents), options: {className: "sz-right"} });
        }
        // header_2.push({ text: "Land" });
        header_2.push({ text: _t_super(1,ETranslation.beta_levered), options: {className: "sz-right"} });

        if (!hideDebtBeta) {
            header_2.push({ text: _t(ETranslation.debt_beta), options: {className: "sz-right"} });
        }

        if (showTaxShield) {
            header_2.push({ text: _t(ETranslation.tax_shield), options: {className: "sz-right"} });
        }

        header_2.push({ text: _t(ETranslation.gearing), options: {className: "sz-right"} });
        header_2.push({ text: _ta(ETranslation.beta_unlevered, "²"), options: {className: "sz-right"} });

        const columns: ISzTableColumn[] = [];
        const renderCompanyName = (company_name)=> {
            return (
                <span>{company_name}</span>
            );
        };
        const renderWarning = (company_name,title, type: IErrorType = ErrorTypes.warning) => {
            return (
                <div title={title} style={{display: "flex", justifyContent: "space-between"}}>
                    <span style={{color: type.color}}>{company_name}</span>
                    <Icon icon={IconNames.WARNING_SIGN} size={16} intent={type.intent}/>
                </div>
            );
        };
        const company_details = this.data.companies;
        const aktion_col: ISzTableColumn = {
            index: "",
            options: {
                style: {borderRight: "4px solid #ffffff", width: 30, verticalAlign: "middle"},
                cellRenderer: (cellValue: any, data?: any) => {
                    return renderLongListAction(this, company_details[data[12]], true);
                },
            },
        };
        const companyColumn: ISzTableColumn = {
            index: "0",
            options: {
                cellRenderer: (cellValue: any, data?: any) => {
                    const cid = data[12];
                    const details: ICompanyBase = company_details[cid];

                    if (!details) {
                        return (<span>{cellValue}</span>);
                    }

                    // console.error(details);
                    const n_p_first_date = fromStr(details.p_first_date, "LL/dd/yyyy").asDeDate();
                    const n_p_last_date = fromDateDecimal(details.p_last_date).asDeDate();
                    const p_first_date = fromStr(details.p_first_date, "LL/dd/yyyy").asDateDecimal();
                    const p_last_date = details.p_last_date;
                    const warn = date_decimal_start < p_first_date || p_last_date < date_decimal_end;
                    const warn_2 = parseFloat(data[9]) > parseFloat(data[5]) && useAdjBeta;
                    // const last_fin_data = data[18] ? moment(data[18], "YYYYMMDD").format("MM|YY") : "";
                    if (data[17]) {
                        return renderWarning(cellValue, "Der letzte vorliegende Finanzbericht zu diesem Unternehmen ist älter als 18 Monate.", ErrorTypes.error);
                    }

                    let title = p_last_date < date_decimal_end ? `Delisted seit ${n_p_last_date}` : date_decimal_start < p_first_date ? `IPO(${n_p_first_date}) liegt im Betrachtungszeitraum` : "";
                    if (warn_2) {
                        title += `Das "Unlevered Beta" ist größer als das "Levered Beta".`;
                    }
                    if (warn || warn_2) {
                        return renderWarning(cellValue, title);
                    }
                    return renderCompanyName(cellValue);
                },
            },
        };
        const lastFinDateColumn: ISzTableColumn = {
            index: "18",
            options: {
                style: {width: 50, paddingLeft: 0, paddingRight: 0, fontSize: "60%"},
            },
        };
        const sectorColumn: ISzTableColumn = {
            index: "13",
            options: {
                style: {width: 250},
            },
        };
        if (!hideColumns) {
            columns.push(aktion_col);
        }
        columns.push({ ...SzTableHelper.columnFunction(EIndexFunction.$row_num), options: {style: {width: 10} }}); // index
        columns.push(companyColumn); // unternehmen
        columns.push(lastFinDateColumn);
        if (!hideColumns) {
            columns.push(sectorColumn); // subsector
        }
        columns.push({ ...SzTableHelper.columnIndex("1", 50)}); // land

        if (!hideColumns) {
            columns.push({ ...SzTableHelper.columnIndex("2"), options: {style: {width: 120} }}); // region
            columns.push({ ...SzTableHelper.columnIndex("3"), options: {style: {width: 180, borderLeft: "4px solid #ffffff"} }});
            columns.push({...SzTableHelper.columnMoney("4", 0, {style: {width: 60}})}); // num aktien
        }

        columns.push({ ...SzTableHelper.columnMoney("5", 2, {style: {width: 110, borderLeft: "4px solid #ffffff"}})}); // raw beta

        if (!hideDebtBeta) {
            columns.push({ ...SzTableHelper.columnMoney("6", 2, {style: {width: 80}})}); // debt beta onDebtBetaClick
        }

        if (showTaxShield) {
            columns.push({ ...SzTableHelper.columnPercent("7", 1, {style: {width: 80}})}); // tax-shield
        }

        columns.push({ ...SzTableHelper.columnPercent("8", 0, {style: {width: 110}})}); // verschuldungsgrad
        columns.push({ ...SzTableHelper.columnMoney("9", 2, {style: {width: 120}, className: "hover-col"})}); // beta unlevered

        const footerData =  TableStatistics.getTableStatistics(table, {
            pack: true,
            headColumn: 0,
            columns: [5, 6, 7, 8, 9, 10],
        });
        if (Array.isArray(footerData) && footerData.length === 6) {
            SessionStore.setGlobalVar(EValueName.raw_beta, footerData[3][1]);
            SessionStore.setGlobalVar(EValueName.tax_shield, footerData[3][3]);
            SessionStore.setGlobalVar(EValueName.unlevered_beta, footerData[selectedBetUnlevered][5]);// depends on szselect
        }
        try {
            SessionStore.setGlobalVar(EValueName.tax_shield_safe, median(tax_shield_safe));
        } catch (e) {
            console.error(e);
        }
        // console.error(footerData);

        const footer: ISzTableColumn[] = [];
        const colSpan = !hideColumns ? 8 : 4;
        if (!hideColumns) {
            footer.push({index: ""});
        }
        footer.push({index: "0", options: { className: "strong", colSpan}});
        footer.push({ ...SzTableHelper.columnMoney("1", 2, {style: {width: 110}})});

        if (!hideDebtBeta) {
            footer.push({ ...SzTableHelper.columnMoney("2", 2, {style: {width: 110}})});
        }

        if (showTaxShield) {
            footer.push({ ...SzTableHelper.columnPercent("3", 1, {style: {width: 110}})});
        }
        footer.push({ ...SzTableHelper.columnPercent("4", 0, {style: {width: 110}})});
        footer.push({ ...SzTableHelper.columnMoney("5", 2, {style: {width: 110}, className: "hover-col"})});

        const tableProps: ISzTableProperties<any[]> = {
            colCount: columns.length,
            data: table,
            header: [ header_2 ],
            rowHover: true,
            bar_code: this.getBarCode(),
            onSelectRow: (a, b) => {this.onSelectRow(a, b); },
            footer,
            footerData,
            columns,
            notes: [
                `¹) ${BetaFilterState.getUnleveredBetaBase()}`,
                `²) ${_t(ETranslation.beta_unlevered_based_on, BetaFilterState.getUnleveredBetaBase())}`
            ],
        };

        this.tableProps = tableProps;

        // console.error(this.props.isHidden);
        if (this.props.isHidden) {
            return null;
        }
        // window.console.log("tableProps:", tableProps)
        window.console.log("table:",table);
        return (
            <div style={{paddingTop: 16}}>
                <SzTable {...tableProps} />
            </div>
        );
    }

    private noData() {
        return (<NonIdealState
            icon={"database"}
            title="Keine Daten"
            description="Die Datenanfrage muss ein Datum und Firmen enthalten."
        />);
    }

    private onSelectRow(row: number, dataRow: any) {
        const cid = dataRow[12];
        selectRow(this, cid);
    }

    public getPdfPage(): any[] {
        const content = [];
        PdfInsertElement.page_header(content, "Shortlist Unlevered Beta");
        const showDebtBeta: boolean = SessionStore.get(EParameters.DebtbetaParameter) !== "nicht_anwenden";
        const showTaxShield: boolean = SessionStore.get(EParameters.TaxshieldParameter).startsWith("sicher");

        const le = 11 + (showTaxShield ? 1 : 0) + (showDebtBeta ? 1 : 0);

        const widths= (new Array(le)).fill("auto").map((a,i)=>i === 1 ? "*" : "auto");
        const table = new PdfTable(content, widths);

        const header_row = [
            table.header("#", false, undefined, "right"),
            table.header(_t(ETranslation.company), false, undefined, "left"),
            table.header("", false, undefined, "left"),
            table.header(_t(ETranslation.sector), false, undefined, "left"),
            table.header(_t(ETranslation.country), false, undefined, "left"),
            table.header("Region", false, undefined, "left", true),

            table.header(_t(ETranslation.stock_index), false, undefined, "left"),
            table.header("#Aktien", false, undefined, "right", true),

            table.header(_t(ETranslation.beta_levered), false, undefined, "right"),
        ];
        table.addRow(header_row);
        if(showDebtBeta){
            header_row.push(
                table.header(_t(ETranslation.debt_beta), false, undefined, "right")
            );
        }
        if(showTaxShield){
            header_row.push(
                table.header(_t(ETranslation.tax_shield), false, undefined, "right")
            );
        }
        header_row.push(
            table.header(_t(ETranslation.gearing), false, undefined, "right")
        );
        header_row.push(
            table.header(_t(ETranslation.beta_unlevered), false, undefined, "right")
        );
        const fmt_digits =  [0,undefined,undefined,undefined,undefined,undefined,undefined,0,2,2,1,0,2];
        const fmt = [_fmt.m,undefined,undefined,undefined,undefined,undefined,undefined, _fmt.m, _fmt.m, _fmt.m, _fmt.p, _fmt.p, _fmt.m];
        const col_idx = [0,0,18,13,1,2,3,4,5,6,7,8,9];
        this.tableProps.data.forEach((r, row_num)=>{
            const row = [];
            table.addRow(row);
            col_idx.forEach((i, idx)=>{
                if(0 === idx){
                    return row.push(table.cell(_fmt.m(row_num + 1, 0), "right"));
                }

                if(!fmt[idx]){
                    const txt_cell: any = table.cell(r[i], "left");
                    // geschäftsjahr
                    if(18 === i){
                        // txt_cell.fontSize = 6;
                        txt_cell.color = "#9b9b9b";
                    }

                    return row.push(txt_cell);
                }

                const cell = table.cell(fmt[idx](r[i], fmt_digits[idx]), "right");
                if(6 === i || 7 === i){
                    if(6 === i && showDebtBeta){
                        return row.push(cell);
                    }
                    // add tax shield
                    if(7 === i && showTaxShield){
                        return row.push(cell);
                    }
                }else{
                    row.push(cell);
                }
            });
        });
        const footer_cols = [0,1,2,3,4,5];
        const footer_digits = [undefined,2,2,1,0,2];
        const footer_fmt = [undefined, _fmt.m, _fmt.m, _fmt.p, _fmt.p, _fmt.m];
        const empty_columns = 7;
        this.tableProps.footerData.forEach((r, row_num)=>{
            const row = [];
            table.addRow(row);
            footer_cols.forEach((i, idx)=>{
                const is_bold = row_num === 0 || row_num === 3;
                if(0 === i){
                    row.push(table.cell(r[i], "left", is_bold, empty_columns + 1, true));
                    for(let c = 0; c < empty_columns; c++){
                        row.push(table.cell(""));
                    }
                    return;
                }
                const cell = table.cell(footer_fmt[idx](r[i], footer_digits[idx]), "right", is_bold);
                if(2 === i || 3 === i){
                    if(2 === i && showDebtBeta){
                        return row.push(cell);
                    }
                    // add tax shield
                    if(3 === i && showTaxShield){
                        return row.push(cell);
                    }
                }else{
                    return row.push(cell);
                }
            });
        });


        const tableBody = table.getBody();
        table.setBorders({
            columns: {
                idx: [5, 7],
                border: [EBorder.right],
            },
            rows: {
                idx: [0, tableBody.length - 7],
                border: [EBorder.bottom],
            },
        });
        // console.error(table);
        // console.error(this.tableProps);
        return content;
    }
}
