import {Icon, IToastProps, Popover, Spinner} from "@blueprintjs/core";
import {CSSProperties} from "react";
import * as React from "react";
import {v4} from "uuid";
import {EContentType} from "../../const/EContentType";
import {EPackageTypes} from "../../const/EPackageTypes";
import {Globals} from "../../const/Globals";
import {EParameters} from "../../models/EParameters";
import {IGlobalValue} from "../../models/IGlobalValue";
import {INopeAction} from "../../models/INopeAction";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {EDataProviderEvents} from "../data/BaseDataProvider";
import {SzDemo} from "../widgets/SzDemo";
import {ParameterConfigs} from "../../const/ParameterConfigs";
import {IModuleAction} from "../../models/IModuleAction";
import {_t} from "../../tools/Translator";
import {ETranslation} from "../../const/ETranslation";

export interface IBaseModule {
    component: string;
    name: string;
    dataSource?: string;
    dataDefaults?: any;
    options?: any;
    dataSourceId: string;
    contentType: EContentType;
    minPackage: EPackageTypes;
    tabbed?: boolean;
    isHidden?: boolean;
    nopeActions?: INopeAction[];
    parameters?: EParameters[];
    values: IGlobalValue[];
    module_actions?: IModuleAction[];
}

export abstract class BaseModule<P extends IBaseModule> extends React.Component<P, any> {
    protected demo_image = null;
    protected data: any;
    protected parameters: any = {};
    protected prepare: boolean = false;

    public eventHandlers: IEventHandler[] = [];
    private parametersValueStr = "";
    protected constructor(props: P, context: any) {
        super(props, context);
        this.demo_image = `${Globals.BASE_DIR}images/demo/demo_beta_2.png`;
        this.state = {
            loading: props.dataSource ? true : false,
        };

        if (props.dataSource) {
            this.subscribeOnBeforeUpdate(props.dataSource, this.onBeforeUpdate.bind(this));
            this.subscribeOnAfterUpdate(props.dataSource, (data)=> this.onAfterUpdate(data));
        }
    }
    public getPdfPage(): any[]{
        return null;
    }
    public getKey(preamble: string) {
        return `${preamble}_${v4()}`;
    }
    public async getDocumentationData() {
        return null;
    }
    public componentWillUnmount(): void {
        for (const eventHandler of this.eventHandlers) {
            EventBus.unsubscribe(eventHandler);
        }
    }
    public getExcelData(target) {
        // target
    }
    public render() {
        const addClassNames = this.getClassNames() ? " " + this.getClassNames() : "";
        const moduleOverride = this.getModuleOverride() ? " " + this.getModuleOverride() : "";
        const moduleName = this.getModuleName();
        const menu = this.extendMenu();
        const renderMenu = () => {
            if (menu) {
                return (
                    <div className={"tools"}>
                        <Popover minimal={true} position={"bottom-right"}>
                            <Icon icon="menu" />
                            {menu}
                        </Popover>
                    </div>
                );
            }
            return null;
        };
        if (this.props.tabbed) {
            return (
                <div className={"sz-base-module-content-div"} key={this.getKey("BaseModule")}>
                    { this.state.loading ? this.renderLoading() : this.renderWrapper() }
                </div>
            );
        }
        return (
            <div className={"sz-base-module" + moduleOverride} key={this.getKey("BaseModule")}>
                <div className={"sz-base-module-content" + addClassNames}>
                    <h4>{moduleName}</h4>
                    {renderMenu()}
                    <div className={"sz-base-module-content-div"}>{ this.state.loading ? this.renderLoading() : this.renderWrapper() }</div>
                </div>
            </div>
        );
    }
    public exportAsExcel() {
        return null;
    }
    public renderLoadingDefault() {
        const div_style: CSSProperties = {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: 200,
        };
        return (
            <div style={div_style}>
                <Spinner size={75} intent={"primary"} />
                <p className={"bp3-text-muted"} style={{fontSize: "80%"}}>{_t(ETranslation.spinner_standard)}</p>
            </div>
        );
    }

    public extendMenu() {
        return null;
    }

    public getName() {
        return this.props.name;
    }

    protected showMessage(data: IToastProps) {
        EventBus.emit<IToastProps>("sz-show-message", data);
    }

    protected getModuleName() {
        return this.props.name;
    }
    protected getParameterValue(parameter: EParameters, defaultValue?: any) {
        let returnValue = defaultValue;
        if (this.parameters[parameter]
            && Array.isArray(this.parameters[parameter])
            && this.parameters[parameter].length) {
            returnValue = this.parameters[parameter][0];
        }
        return returnValue;
    }
    protected getClassNames(): string {
        return null;
    }

    protected getModuleOverride(): string {
        return null;
    }

    protected subscribeOnBeforeUpdate(providerClassName: string, callback: (data: any) => void): IEventHandler {
        return this.subscribeEvent(EDataProviderEvents.OnBeforeUpdate, providerClassName, callback);
    }

    protected subscribeOnAfterUpdate(providerClassName: string, callback: (data: any) => void): IEventHandler {
        return this.subscribeEvent(EDataProviderEvents.OnAfterUpdate, providerClassName, callback);
    }

    protected abstract renderContent();
    protected abstract renderLoading();

    protected onAfterUpdate(data: any): void {
        this.data = data;
        this.setState({
            loading: false,
        });
    }
    protected onBeforeUpdate(data: any): void {
        // console.error(this.props.component);
        this.parameters = data;
        this.setState({
            loading: true,
        });
    }

    private renderWrapper() {
        try{
            if (this.props.contentType === EContentType.Free) {
                return this.renderContent();
            }
            if (this.props.contentType === EContentType.Freemium && Globals.isRegistred) {
                return this.renderContent();
            }

            if ((this.props.contentType === EContentType.Premium || this.props.contentType === EContentType.Elite) && Globals.isRegistred && Globals.ownsPackage >= this.props.minPackage) {
                return this.renderContent();
            }

            if (this.props.contentType === EContentType.Admin && Globals.isAdmin) {
                return this.renderContent();
            }
            return <SzDemo img={this.demo_image} height={310} contentType={this.props.contentType} nopeActions={this.props.nopeActions} />;
        }
        catch(exi){
            console.error(exi);
        }
        finally {
            //
        }
    }

    private subscribeEvent(ev: EDataProviderEvents, providerClassName: string, callback: (data: any) => void): IEventHandler {
        const i = this.props.dataSourceId ? "_" + this.props.dataSourceId : "";
        const e_name = `${providerClassName.toLocaleLowerCase()}_${ev}${i}`;
        const e = EventBus.subscribe(e_name, callback);
        this.eventHandlers.push(e);
        return e;
    }

    protected getBarCode() {
        let parametersValueStr="";
        if(Array.isArray(this.props.parameters)){
            let nope = false;
            this.props.parameters.forEach((p) =>{
                nope = EParameters.NopeParameter === p;
                if(nope){
                    return;
                }
                let pp = [p];
                if(ParameterConfigs.configs[p] && Array.isArray(ParameterConfigs.configs[p].parameterComposition)){
                    pp = pp.concat(ParameterConfigs.configs[p].parameterComposition);
                }
                pp.forEach( (i)=>{
                    const v = Globals.getPackedParameter(i);
                    if(v){
                        parametersValueStr += v;
                    }
                });
            });
        }
        return parametersValueStr;
    }
}
