import { ICompanySearchResult } from "../models/ICompanySearchResult";
import { ICountry } from "../models/ICountry";
import { INearByDetails } from "../models/INearByDetails";
import { EProjectUserRole } from "../models/IProject";
import { IProjectGroupMember } from "../models/IProjectGroupMember";
import { ELangCodes } from "./ELangCodes";
import { EStatisticsValueType } from "./EStatisticsValueType";
import { EModuleNames } from "../models/EModuleNames";
import { EParameters } from "../models/EParameters";
import { EventBus } from "../services/EventBus";
import { IProject } from "../models/IProject";
import {DateTime} from "luxon";
import {ServiceCalls} from "../services/ServiceCalls";

const super_set = "⁰,¹,²,³,⁴,⁵,⁶,⁷,⁸,⁹".split(",");
const code_map =
    "0123456789_$abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const wHostName: string = window.location.hostname;
export class Globals {
    public static user;
    public static askKayBlocked = false;
    public static shortlist_green = "rgba(15, 153, 96, 0.25)";

    static get countries(): ICountry[] {
        if (!Array.isArray(this._countries)) {
            return [];
        }
        return this._countries;
    }

    static set countries(value: ICountry[]) {
        this._countries = value;
    }

    public static country_map: { [country_id: string]: ICountry } = {};
    public static country_map_iso_3: { [index: string]: ICountry } = {};
    public static current_language: ELangCodes = ELangCodes.de;
    public static currentModule: EModuleNames = null;
    public static projects: IProject[] = [];
    public static isAdmin: boolean = false;
    public static isChargeBee: boolean = false;
    public static subscriptions: {end: number, item: string}[] = [];
    public static isRegistred: boolean = false;
    public static user_id: string;
    public static ownsPackage: number = -1;
    public static changed_project;
    public static projectMemberShip: IProjectGroupMember;
    public static projectAccess: EProjectUserRole;
    public static isLocal: boolean =
        wHostName.indexOf("localhost") >= 0 || wHostName.indexOf("127.0.0.1") >= 0;
    public static BASE_DIR: string = "";
    public static _ref = (s) =>
        `${window.location.origin}/${Globals.BASE_DIR}${s}`;

    public static nearByDetails: Map<string, INearByDetails> = new Map<
        string,
        INearByDetails
    >();
    public static longList: ICompanySearchResult[] = [];
    public static primeSectorOptions: any[] = [];

    public static em_space = " ";
    public static hyphen = "—";
    public static average = "Ø";
    public static infinity = "∞";
    public static delta = "Δ";
    public static last_db = parseInt(DateTime.now().minus({day: 1}).toFormat("yyyyLLdd"), 10);

    public static superscript(n: number) {
        const a = [...(n + "")].map((i) => super_set[parseInt(i, 10)]);
        return a;
    }
    public static formatter = (
        v: any,
        fractionDigits: number = 2,
        fractionDigitsMin?: number,
        nan_is_hypen?
    ) => {
        const fv = parseFloat(v);

        if (nan_is_hypen && isNaN(fv)) {
            return Globals.hyphen;
        }

        if (isNaN(fv) || !isFinite(fv)) {
            return v;
        }
        const md =
            fractionDigitsMin !== undefined ? fractionDigitsMin : fractionDigits;
        return isNaN(parseFloat(v))
            ? v
            : new Intl.NumberFormat(navigator.language, {
                maximumFractionDigits: fractionDigits,
                minimumFractionDigits: md,
            }).format(fv);
    };
    public static formatter_delta = (
        v: any,
        fractionDigits: number = 2,
        fractionDigitsMin?: number,
        nan_is_hypen?
    ) => {
        const fv = parseFloat(v);

        if (nan_is_hypen && isNaN(fv)) {
            return Globals.hyphen;
        }

        if (isNaN(fv) || !isFinite(fv)) {
            return v;
        }
        const md =
            fractionDigitsMin !== undefined ? fractionDigitsMin : fractionDigits;
        return isNaN(parseFloat(v))
            ? v
            : new Intl.NumberFormat(navigator.language, {
                maximumFractionDigits: fractionDigits,
                minimumFractionDigits: md,
                signDisplay: "always",
            }).format(fv);
    };
    public static formatter_percent = (
        v: any,
        fractionDigits: number = 2,
        fractionDigitsMin?: number,
        nan_is_hypen?
    ) => {
        const fv = parseFloat(v);

        if (nan_is_hypen && isNaN(fv)) {
            return Globals.hyphen;
        }

        if (isNaN(fv) || !isFinite(fv)) {
            return v;
        }
        const md =
            fractionDigitsMin !== undefined ? fractionDigitsMin : fractionDigits;
        return isNaN(parseFloat(v))
            ? v
            : new Intl.NumberFormat(navigator.language, {
                style: "percent",
                maximumFractionDigits: fractionDigits,
                minimumFractionDigits: md,
            }).format(fv);
    };
    public static save_result = (a: any) => {
        if (a && a.result && Array.isArray(a.result)) {
            return a.result;
        }
        return [];
    };

    public static getWindowKey(windowKey: string) {
        if (window.hasOwnProperty(windowKey)) {
            return window[windowKey];
        }
        return null;
    }
    public static setWindowKey(windowKey: string, value: any) {
        window[windowKey] = value;
    }

    public static setUpdateLock(dsName: string) {
        if (dsName) {
            this.updateLock.set(dsName, true);
        }
    }
    public static unsetUpdateLock(dsName: string): boolean {
        this.updateLock.set(dsName, false);
        let r: boolean = true;
        this.updateLock.forEach((i) => (r = r && !i));
        return r;
    }
    public static isUpdateLock(): boolean {
        let r: boolean = true;
        this.updateLock.forEach((i) => (r = r && !i));
        return r;
    }
    public static trackInfo(type: EStatisticsValueType, values: any) {
        (async () => {
            try {
                // const tc: ServiceCalls = new ServiceCalls();
                // await tc.pushStat(Globals.getWindowKey("wp_show_module"), type, values);
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    public static validateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    }
    public static getPackedParameter(parameter: EParameters) {
        return this.parameter_packed[parameter];
    }
    public static addPackedParameter(parameter: EParameters, values: number[]) {
        let str = "";
        values.forEach((v) => {
            const char = code_map.charAt(v);
            if (!char) {
                str += "-";
            } else {
                str += char;
            }
        });
        this.parameter_packed[parameter] = str;
        EventBus.emit("Globals::addPackedParameter", undefined);
    }
    public static parameter_packed = {};
    private static _countries: ICountry[] = [];
    private static updateLock: Map<string, boolean> = new Map<string, boolean>();
}
