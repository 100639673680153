import {Button, Classes, Icon, NonIdealState, Popover, Spinner} from "@blueprintjs/core";
import * as React from "react";
import {Globals} from "../../const/Globals";
import {ECompanyRelation} from "../../models/ECompanyRelation";
import {IRelatedCompany} from "../../models/ICompanyDetails";
import {ICompanySearchResult} from "../../models/ICompanySearchResult";
import {INearByDetails} from "../../models/INearByDetails";
import {ISearchFilter} from "../../models/ISearchFilter";
import {EventBus} from "../../services/EventBus";
import {PublicDbCalls} from "../../services/PublicDbCalls";

export interface ICompanyDetailPopupProperties {
    company: any;
    flag: string;
    relation: ECompanyRelation;
}
export interface ICompanyDetailPopupState {
    isLoading: boolean;
    isOpen: boolean;
}
export class CompanyDetailPopup extends React.Component<ICompanyDetailPopupProperties, ICompanyDetailPopupState> {

    constructor(props: ICompanyDetailPopupProperties, context: any) {
        super(props, context);
        this.state = {
            isLoading: false,
            isOpen: false,
        };
        EventBus.subscribe("CompanyDetailPopup::open", (data: {c: IRelatedCompany, relation: ECompanyRelation}) => this.openPopup(data));
    }
    public render() {
        const e = this.props.company;
        return (
            <Popover
                className={"tag-wrapper"}
                minimal={true}
                usePortal={true}
                isOpen={this.state.isOpen}
                onClose={()=>this.setState({isOpen: false})}
                onOpening={() => {this.loadDetails(); }}
                content={this.renderNearByDetails()}
            >
                <span style={{display: "flex", alignItems: "center"}}>
                    <img src={this.props.flag} style={{height: 12}}/>
                    <span style={{paddingLeft: 5}}>{e.co_name}</span>
                </span>
            </Popover>
        );
    }
    private renderNearByDetails() {
        // Globals.longList = longList;
        const use: INearByDetails = Globals.nearByDetails["" + this.props.company.id];

        const notLongListed = (
            <Button
                title={"add to long list"}
                onClick={() => { this.updateFilter("AddLongList", use); }}
                intent={"primary"}
                icon={"star-empty"}
                className={Classes.POPOVER_DISMISS}>
                long list
            </Button>
        );
        const longListed = (
            <Button
                title={"remove from long list"}
                onClick={() => { this.updateFilter("DelLongList", use); }}
                intent={"danger"}
                icon={"star"}
                className={Classes.POPOVER_DISMISS}>
                long list
            </Button>
        );
        const open = (
            <Button
                title={"Details Anzeigen"}
                onClick={() => { EventBus.emit<ICompanySearchResult>("onCompanySearchResult.selected", use); }}
                intent={"none"}
                className={Classes.POPOVER_DISMISS}>
                Details
            </Button>
        );
        if (this.state.isLoading) {
            return (<Spinner size={100} />);
        }
        if (!use) {
            return (<NonIdealState
                icon={"database"}
                title="Keine Daten"
                description="Details zu diesem Datensatz sind momentan nicht verfügbar."
                className={"sz-popover"}
            />);
        } else {
            return (
                <div style={{padding: 8, maxWidth: 640}}>
                    <table style={{width: "100%"}} className={"header-table"}>
                        <tr>
                            <td style={{fontWeight: "bold"}}>
                                {use.company_name}
                            </td>
                            <td style={{textAlign: "right"}}>
                                {open}&nbsp;
                                {use.isLongList ? longListed : notLongListed}
                            </td>
                        </tr>
                    </table>
                    <div>
                        <Icon icon={"office"} color={"#5c7080"}/>&nbsp;
                        <a
                           onClick={() => { this.updateFilter("SectorFilter", {sector: use.sector_code, subSector: undefined}); }}
                           className={Classes.POPOVER_DISMISS}
                        >{use.sector_name_de}</a>
                        &nbsp;|&nbsp;
                        <a
                           onClick={() => { this.updateFilter("SectorFilter", {sector: use.sector_code, subSector: use.industry_code}); }}
                           className={Classes.POPOVER_DISMISS}
                        >{use.industry_name_de}</a>
                    </div>

                    <div>
                        <Icon icon={"globe"} color={"#5c7080"}/>&nbsp;
                        <a
                           className={Classes.POPOVER_DISMISS}
                           onClick={() => { this.updateFilter("CountryFilter", {region: use.country_region_de}); }}
                        >{use.country_region_de}</a>
                        &nbsp;|&nbsp;
                        <a
                           className={Classes.POPOVER_DISMISS}
                           onClick={() => { this.updateFilter("CountryFilter", {country: use.country_iso_2}); }}
                        >{use.country_name_de}</a>
                    </div>

                    <p style={{fontSize: "90%", marginTop: 12}}>{use.description}</p>
                </div>
            );
        }
    }
    private updateFilter(filterName: string, data: any) {
        const evt: ISearchFilter = {
            filterName,
            data,
        };
        EventBus.emit<ISearchFilter>("onNearByDetails.updateFilter", evt);
    }
    private loadDetails() {
        const use = Globals.nearByDetails["" + this.props.company.id];
        if (use) {
            return;
        }
        this.setState({
            isLoading: true,
        });
        const db = new PublicDbCalls();

        (async () => {
            const result = await db.getCompanyDetails2(parseInt(this.props.company.id, 10));
            const d: any[] = result.result ? result.result : [];
            if (d && d.length > 0) {
                const item = d[0];

                const isLongList = Globals.longList.find( (c) => c.company_id === "" + this.props.company.id ) ? 1 : 0;

                const details: INearByDetails = {
                    isLongList,
                    company_id: "" + item[0],
                    company_name: item[1],
                    description: item[2],
                    country_iso_2: item[4],
                    sector_name_de: item[5],
                    sector_code: "" + item[6],
                    industry_name_de: item[7],
                    industry_code: "" + item[8],
                    company_home_page: item[9],
                    country_region_de: item[10],
                    country_name_de: item[11],
                    country_iso_3: "",
                    country_ticker: "",
                    full_count: 0,
                    ff_mkt_val: 0,
                };

                Globals.nearByDetails["" + this.props.company.id] = details;
            }
            this.setState({
                isLoading: false,
            });
        })();
        return true;
    }

    private openPopup(data: {c: IRelatedCompany, relation: ECompanyRelation}) {
        if(this.props.company.id !== data.c.id){
            this.setState({isOpen: false});
        }else{
            if(this.props.relation!==data.relation){
                return;
            }
            this.setState({isOpen: true});
        }
    }
}
