const _map = (a: string[])=>{
    const r = {};
    if(Array.isArray(a)){
        a.forEach((s,i)=> r[s]=i);
    }
    return r;
};
// "AA", "AA-", "A+", "A", "A-", "BBB+", "BBB", "BBB-", "BB+", "BB", "BB-", "B+", "B"
// Aa2,Aa3,A1,A2,A3,Baa1,Baa2,Baa3,Ba1,Ba2,Ba3,B1,B2
const _moodys: string[] = "Aaa,Aa1,Aa2,Aa3,A1,A2,A3,Baa1,Baa2,Baa3,Ba1,Ba2,Ba3,B1,B2,B3,Caa1,Caa2,Caa3,Ca,C".split(",");
const _s_and_p: string[] ="AAA,AA+,AA,AA-,A+,A,A-,BBB+,BBB,BBB-,BB+,BB,BB-,B+,B,B-,CCC+,CCC,CCC-,CC,C".split(",");

const _index_s_and_p: number[] =[
    0, undefined, undefined, undefined,
    1, undefined, undefined,
    2, undefined, undefined,
    3, undefined, undefined,
    4, undefined, undefined,
    5, undefined, undefined,
    6,
    7,
]; // "AAA,AA+,AA,AA-,A+,A,A-,BBB+,BBB,BBB-,BB+,BB,BB-,B+,B,B-,CCC+,CCC,CCC-,CC,C".split(",");
const _s_and_packed: string[] ="AAA/AA,A,BBB,BB,B,CCC,CC,C".split(",");
const moodys_to_s_and_p = (r: string): number => {
    switch(r){
        case "Aaa":
        case "Aa1":
        case "Aa2":
        case "Aa3": return 0;
        case "A1":
        case "A2":
        case "A3": return 1;
        case "Baa1":
        case "Baa2":
        case "Baa3": return 2;
        case "Ba1":
        case "Ba2":
        case "Ba3": return 3;
        case "B1":
        case "B2":
        case "B3": return 4;
        case "Caa1":
        case "Caa2":
        case "Caa3": return 5;
        case "Ca1":
        case "Ca2":
        case "Ca3": return 6;
        case "C1":
        case "C2":
        case "C3": return 7;
        default: return 7;
    }
}
const _map3 = ()=>{
    const r = {};
    if(Array.isArray(_moodys)){
        // _moodys.forEach((s)=> r[s]=_s_and_packed[moodys_to_s_and_p(s)]);
        _moodys.forEach((s, i)=> r[s]=_s_and_p[i]);
    }
    return r;
};

export class Ratings {
    public static moodys: string[] = _moodys;
    // public static moodys_mapped_to_num: { [index:string] : number} = _map(_moodys);
    // public static s_and_p_packed: string[] = _s_and_packed;
    public static moodys_to_sp: { [index:string] : string} = _map3();
    // public static moodys_sp_mapped_to_num: { [index:string] : number} = _map2();
    public static s_and_p: string[] = _s_and_p;
    public static s_and_p_mapped_to_num: { [index:string] : number} = _map(_s_and_p);
    public static s_and_p_all_mapped_to_num: { [index:string] : number} = _map(_s_and_p);


    public static packet_s_and_p: string[] = _s_and_packed;
    public static index_s_and_p: number[] = _index_s_and_p;
    public static packet_s_and_p_mapped_to_num: { [index:string] : number} = _map(_s_and_packed);
    public static as_packet_s_and_p(r: string){
        /*
const _s_and_p: string[] ="AAA,AA+,AA,AA-,A+,A,A-,BBB+,BBB,BBB-,BB+,BB,BB-,B+,B,B-,CCC+,CCC,CCC-,CC,C".split(",");
const _s_and_packed: string[] ="AAA/AA,A,BBB,BB,B,CCC,CC,C".split(",");*/
        switch(r){
            case "AAA":
            case "AA+":
            case "AA":
            case "AA-" : return 0;
            case "A+":
            case "A":
            case "A-": return 1;
            case "BBB+":
            case "BBB":
            case "BBB-": return 2;
            case "BB+":
            case "BB":
            case "BB-": return 3;
            case "B+":
            case "B":
            case "B-": return 4;
            case "CCC+":
            case "CCC":
            case "CCC-": return 5;
            case "CC+":
            case "CC":
            case "CC-": return 6;
            case "C+":
            case "C":
            case "C-": return 7;
            default: return _s_and_packed.length - 1;
        }
    }
}
