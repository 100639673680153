import {
    Button,
    Classes,
    ControlGroup,
    HTMLTable,
    Icon,
    InputGroup,
    Intent,
    Menu,
    MenuItem,
    Tooltip,
} from "@blueprintjs/core";
import * as React from "react";
import {CSSProperties} from "react";
import {BetaGlobals} from "../../const/BetaGlobals";
import {EStatisticsValueType} from "../../const/EStatisticsValueType";
import {Globals} from "../../const/Globals";
import {ParameterOptions} from "../../const/ParameterOptions";
import {RatingDebtBetaSystematicRisk} from "../../const/RatingDebtBetaSystematicRisk";
import {SessionStore} from "../../const/SessionStore";
import {EDataType} from "../../models/EDataType";
import {EParameters} from "../../models/EParameters";
import {EValueName} from "../../models/EValueName";
import {ICostOfCapital} from "../../models/ICostOfCapital";
import {EventBus, IEventHandler} from "../../services/EventBus";
import {TasksCalls} from "../../services/TasksCalls";
import {SzTableHelper} from "../widgets/helper/SzTableHelper";
import {SzDocReference} from "../widgets/SzDocReference";
import {ISzTableColumn, ISzTableProperties} from "../widgets/SzTable";
import {BaseModule} from "./BaseModule";
import {ETranslation} from "../../const/ETranslation";
import {_t} from "../../tools/Translator";
import {_k} from "../../helpers/Helpers";

export enum ECostOfCapitalFields {
    DateParameter="DateParameter",
    showShieldRiskEffect= "showShieldRiskEffect",
    showCountryRiskPremium= "showCountryRiskPremium",
    showSizePremium="sohwSizePremium",
    baseInterest= "baseInterest",
    leverage= "leverage",
    countryRiskUsage= "countryRiskUsage",
    betaUnlevered= "betaUnlevered",
    betaUnleveredDefault= "betaUnleveredDefault",
    taxShieldCashEffect= "taxShieldCashEffect",
    taxShieldRiskEffect= "taxShieldRiskEffect",
    debtBeta= "debtBeta",
    marketRiskPremium= "marketRiskPremium",
    countryRiskPremium= "countryRiskPremium",
    sizePremium="sizePremium",
    creditSpread= "creditSpread",
    creditSpreadDefault= "creditSpreadDefault",
    betaLevered= "betaLevered",
    equityRiskPremium= "equityRiskPremium",
    equityRiskCountry= "equityRiskCountry",
    costOfEquity= "costOfEquity",
    weightingCostOfEquity= "weightingCostOfEquity",
    costOfDebt= "costOfDebt",
    costOfDebtAfterTaxes= "costOfDebtAfterTaxes",
    weightingCostOfDebt= "weightingCostOfDebt",
    weightedAvgCostOfCapital= "weightedAvgCostOfCapital",
    unleveredCostOfEquity= "unleveredCostOfEquity",
}
enum EDesign {
    bold_label,
    bold_row,
    bold_highlighted_row,
    intermediate_result,
    final_result,
    extra_padding,
    under_line,
}

interface INamedValue {
    value: string;
    defaultValue?: string;
    label: any;
    dataType: EDataType;
    hideIf0?: boolean;
    design?: EDesign;
    digits?: number;
    editable?: string;
    verticalAlign?: string;
}

export class CostOfCapitalModule extends BaseModule<any> {

    private evtGlobalVarChanged: IEventHandler;
    private tableProps: ISzTableProperties<any[]>;
    private coc_2 = {};

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            editColumn: false,
        };

        this.evtGlobalVarChanged = EventBus.subscribe("SessionStore::SetGlobalVar", () => this.onGlobalVarChanged());
    }
    public async getDocumentationData() {
        return this.tableProps;
    }
    public componentWillUnmount(): void {
        EventBus.unsubscribe(this.evtGlobalVarChanged);
    }

    public extendMenu() {
        if (this.props.tabbed) {
            return this.exportAsExcel;
        }
        return (
            <Menu>
                <MenuItem text={_t(ETranslation.excel_icon_hoover)} icon={"export"} onClick={() => {  this.exportAsExcel(); }}/>
            </Menu>
        );
    }
    public exportAsExcel() {
        (async () => {
            try {
                const tc: TasksCalls = new TasksCalls();
                const result = await tc.createExcelTask(this.tableProps);
                console.error(result);
                if (result.result) {
                    tc.execTask(result.result);
                }
            } catch (ex) {
                console.error(ex);
            }
        })();
    }

    protected renderContent() {
        if (!this.state.coc) {
            return this.renderLoading();
        }
        const table: any[] = [];
        const coc: ICostOfCapital = this.state.coc;
        const coc_2: ICostOfCapital = this.state.coc_2;
        const showEditor: boolean = this.state.coc_2 ? true : false;
        const colSpan = showEditor ? 5 : 3;

        const header: ISzTableColumn[] = [
            { text: "" },
            { text: "", options: {className: "sz-right"} },
        ];
        const columns: ISzTableColumn[] = [
            SzTableHelper.columnIndex("0"),
            { ...SzTableHelper.columnMoney("1", 2)}, // aktien
        ];
        if (coc_2) {
            header.push({ text: "", options: {className: "sz-right", style: {width: 100}} });
            header.push({ text: "", options: {className: "sz-right"} });
            columns.push({ ...SzTableHelper.columnIndex(""), options: {style: {width: 10, borderLeft: "4px solid #ffffff"} }});
            columns.push({ ...SzTableHelper.columnMoney("2", 2)});
        }
        const roundingRuleParameterV = {};
        roundingRuleParameterV[EParameters.RoundingRuleParameter] = SessionStore.get(EParameters.RoundingRuleParameter);
        const roundingRuleParameter = ParameterOptions.getOptionLabel(EParameters.RoundingRuleParameter, roundingRuleParameterV, false);
        const exlLabels: string[] = [
            "Parameter",
            `${_t(ETranslation.base_rate)}¹`,
            `  ${_t(ETranslation.beta_unlevered)}²`,
            `  ${_t(ETranslation.gearing)}`,
            `  ${_t(ETranslation.tax_shield)}`,
            `  ${_t(ETranslation.debt_beta)}`,
            ` Beta levered`,
            ` ${_t(ETranslation.market_risk_premium)}`,
            _t(ETranslation.equity_risk_premium),
            _t(ETranslation.country_risk_premium),
            _t(ETranslation.company_specific_risk_premium),
            _t(ETranslation.cost_of_equity),
            _t(ETranslation.cost_of_equity_weight),
            "",
            ` ${_t(ETranslation.base_rate)}¹`,
            ` ${_t(ETranslation.credit_spread)}`,
            ` ${_t(ETranslation.country_risk_premium)}`,
            _t(ETranslation.cost_of_debt),
            _t(ETranslation.tax_shield),
            _t(ETranslation.cost_of_debt_after_tax),
            _t(ETranslation.cost_of_debt_weight),
            "",
            _t(ETranslation.wacc),
            _t(ETranslation.cost_of_equity_unlevered),
            "",
        ];

        const fieldMap: INamedValue[] = [
            {label: <> {`Parameter`} </>, value: ECostOfCapitalFields.DateParameter, dataType: EDataType.string, design: EDesign.under_line, verticalAlign: "middle"},
            {label: <SzDocReference text={`${_t(ETranslation.base_rate)}¹`} ref_id={"50"}/>, value: ECostOfCapitalFields.baseInterest, dataType: EDataType.percent, design: EDesign.extra_padding, editable: "baseInterest", verticalAlign: "middle"},
            {label: <><SzDocReference text={`  Beta unlevered²`} ref_id={"66"}/></>, value: ECostOfCapitalFields.betaUnlevered, defaultValue: ECostOfCapitalFields.betaUnleveredDefault, dataType: EDataType.number, editable: "betaUnlevered"},
            {label: <><SzDocReference text={`  ${_t(ETranslation.gearing)}`} ref_id={"37"}/></>, value: ECostOfCapitalFields.leverage, dataType: EDataType.percent, digits: 1, editable: "leverage"},
            {label: <>&emsp;&emsp;{_t(ETranslation.tax_shield)}</>, value: ECostOfCapitalFields.taxShieldRiskEffect, dataType: EDataType.percent, hideIf0: coc.showShieldRiskEffect},
            {label: <>&emsp;&emsp;{_t(ETranslation.debt_beta)}</>, value: ECostOfCapitalFields.debtBeta, dataType: EDataType.number, hideIf0: true},
            {label: <><SzDocReference text={` ${_t(ETranslation.beta_levered)}`} ref_id={"62"}/></>, value: ECostOfCapitalFields.betaLevered, dataType: EDataType.number},
            {label: <><SzDocReference text={` ${_t(ETranslation.market_risk_premium)}`} ref_id={"40"}/></>, value: ECostOfCapitalFields.marketRiskPremium, dataType: EDataType.percent},
            {label: <>{_t(ETranslation.equity_risk_premium)}</>, value: ECostOfCapitalFields.equityRiskPremium, dataType: EDataType.percent},
            {label: <>{_t(ETranslation.country_risk_premium)}</>, value: ECostOfCapitalFields.countryRiskPremium, dataType: EDataType.percent, hideIf0:  coc.showCountryRiskPremium},
            {label: <>{_t(ETranslation.company_specific_risk_premium)}</>, value: ECostOfCapitalFields.sizePremium, dataType: EDataType.percent, hideIf0:  coc.showSizePremium},

            {label: <>{_t(ETranslation.cost_of_equity)}</>, value: ECostOfCapitalFields.costOfEquity, dataType: EDataType.percent, design: EDesign.bold_highlighted_row},
            {label: <>{_t(ETranslation.cost_of_equity_weight)}</>, value: ECostOfCapitalFields.weightingCostOfEquity, dataType: EDataType.percent, design: EDesign.intermediate_result, digits: 1},
            {label: "", value: undefined, dataType: EDataType.empty},
            {label: <><SzDocReference text={` ${_t(ETranslation.base_rate)}¹`} ref_id={"50"}/></>, value: ECostOfCapitalFields.baseInterest, dataType: EDataType.percent, design: EDesign.extra_padding},
            {label: <>&emsp;{_t(ETranslation.credit_spread)}</>, value: ECostOfCapitalFields.creditSpread, defaultValue: ECostOfCapitalFields.creditSpreadDefault, dataType: EDataType.percent, editable: "creditSpread"},
            {label: <><SzDocReference text={` ${_t(ETranslation.country_risk_premium)}`} ref_id={"24"}/></>, value: ECostOfCapitalFields.countryRiskPremium, dataType: EDataType.percent, hideIf0: coc.showCountryRiskPremium},
            {label: `${_t(ETranslation.cost_of_debt)}`, value: ECostOfCapitalFields.costOfDebt, dataType: EDataType.percent},
            {label: <SzDocReference text={_t(ETranslation.tax_shield)} ref_id={"41"}/>, value: ECostOfCapitalFields.taxShieldCashEffect, dataType: EDataType.percent},
            {label: _t(ETranslation.cost_of_debt_after_tax), value: ECostOfCapitalFields.costOfDebtAfterTaxes, dataType: EDataType.percent, design: EDesign.bold_highlighted_row},
            {label: _t(ETranslation.cost_of_debt_weight), value: ECostOfCapitalFields.weightingCostOfDebt, dataType: EDataType.percent, design: EDesign.intermediate_result, digits: 1},
            {label: "", value: undefined, dataType: EDataType.empty},
            {label: _t(ETranslation.wacc), value: ECostOfCapitalFields.weightedAvgCostOfCapital, dataType: EDataType.percent, design: EDesign.bold_highlighted_row},
            {label: _t(ETranslation.cost_of_equity_unlevered), value: ECostOfCapitalFields.unleveredCostOfEquity, dataType: EDataType.percent, design: EDesign.intermediate_result},
            {label: "", value: undefined, dataType: EDataType.empty},
        ];
        const getValue = (source: ICostOfCapital, a: INamedValue, isEdit?: boolean) => {
            const v = source[a.value];
            const dataType = a.dataType;
            const digits = a.digits;
            if (dataType === EDataType.h1) {
                return null;
            }
            if (v === undefined) {
                return "—";
            }
            if (dataType === EDataType.string) {
                return v;
            }

            if (isEdit && a.editable && (dataType === EDataType.number || dataType === EDataType.percent || dataType === EDataType.number_1)) {
                const f = parseFloat("" + v) * (a.dataType === EDataType.percent ? 100 : 1);
                return isNaN(f) ? Globals.hyphen : Globals.formatter(f, digits, 0);
            }

            if (dataType === EDataType.number) {
                const f = parseFloat("" + v);
                return isNaN(f) ? Globals.hyphen : Globals.formatter(f, digits);
            }
            if (dataType === EDataType.number_1) {
                const f = parseFloat("" + v);
                return isNaN(f) ? Globals.hyphen : Globals.formatter(f, 1);
            }
            if (dataType === EDataType.percent) {
                const f = parseFloat("" + v);
                return isNaN(f) ? Globals.hyphen : `${Globals.formatter(f * 100, digits)} %`;
            }
            return v;
        };
        const getValue2 = (source: ICostOfCapital, a: INamedValue, isEdit?: boolean) => {
            if(this.coc_2[a.value]){
                return this.coc_2[a.value];
            }
            const dataType = a.dataType;
            const v = source[a.value];
            if (dataType === EDataType.h1) {
                return null;
            }
            if (v === undefined) {
                return "—";
            }
            if (dataType === EDataType.string) {
                return v;
            }
            if (dataType === EDataType.number) {
                const f = parseFloat("" + v);
                return isNaN(f) ? Globals.hyphen : Globals.formatter(f, 2);
            }
            if (dataType === EDataType.number_1) {
                const f = parseFloat("" + v);
                return isNaN(f) ? Globals.hyphen : Globals.formatter(f, 1);
            }
            if (dataType === EDataType.percent) {
                const f = parseFloat("" + v);
                return isNaN(f) ? Globals.hyphen : `${Globals.formatter(f * 100, 2)}`;
            }
            return v;
        };
        const renderValue = (a: INamedValue) => {
            const str_value = getValue(coc, a);
            if (!a.editable) {
                return str_value;
            }
            return (
                <Tooltip content={"Sensitivität"} usePortal={true} wrapperTagName={"div"} targetTagName={"div"}>
                    <div style={{cursor: "pointer", display: "flex", justifyContent: "space-between"}} onClick={ () => {
                        this.setState({coc_2: Object.assign({}, coc)});
                        Globals.trackInfo(EStatisticsValueType.button_click, "edit_mode");
                    } }>
                        <Icon iconSize={16} icon={"edit"} intent={"primary"}/>
                        <span>{str_value}</span>
                    </div>
                </Tooltip>
            );
        };
        const renderInput = (a: INamedValue) => {
            let str_value = getValue2(coc_2, a, true);
            if (!a.editable) {
                if(a.dataType === EDataType.percent && !str_value.endsWith("%")){
                    str_value = `${str_value} %`;
                }
                return (
                    <span style={{display: "inline-block", width: "100%"}}>
                        {str_value}
                    </span>
                    );
            }

            return (
                <ControlGroup fill={true} vertical={false}>
                    <InputGroup leftIcon={a.dataType === EDataType.percent ? "percentage" : "numerical"} value={str_value} onChange={ (e) => this.changeValue(a, e.target.value)}/>
                    <Tooltip content={"Reset"} usePortal={true}><Button icon="reset" intent={Intent.NONE} onClick={ () => this.resetValue(a) } /></Tooltip>
                </ControlGroup>
            );
        };

        const renderField = (i: INamedValue, idx) => {
            if (i.dataType === EDataType.empty) {
                return (
                    <tr key={_k("RenderField", idx)}>
                        <td colSpan={colSpan} style={{padding: 0}}>&nbsp;</td>
                    </tr>
                );
            }
            if (i.dataType === EDataType.h1) {
                return (
                    <tr key={_k("RenderField", idx)}>
                        <td colSpan={colSpan} style={{borderBottom: "1px solid rgba(16, 22, 26, 0.15)", paddingTop: 24}}><strong>{"i.label"}</strong></td>
                    </tr>
                );
            }

            const value = coc[i.value];

            if (i.hideIf0 && value === 0) {
                return null;
            }

            const baseStyle: CSSProperties = Object.assign({}, (idx === 0 ? {boxShadow: "none"} : {}));
            const labelStyle: CSSProperties = Object.assign({}, baseStyle, {});
            const dataStyle: CSSProperties = Object.assign({}, baseStyle, {textAlign: "right"});
            const editorStyle: CSSProperties = Object.assign({}, dataStyle, {padding: 0, width: 135, textAlign: "left"});
            const valueStyle: CSSProperties = Object.assign({}, dataStyle, {width: 100});
            const emptyColumnStyle: CSSProperties = Object.assign({}, baseStyle, {padding: 0});
            if (i.design === EDesign.bold_label) {
                labelStyle.fontWeight = "bold";
            }
            if(i.design === EDesign.under_line){
                const extStyle: CSSProperties = {
                    fontWeight: "600",
                    borderBottom:"1.5px solid rgba(16, 22, 26, 0.15)",
                    paddingTop: 12,
                    paddingBottom: 6,
                };
                Object.assign(labelStyle, extStyle);
                Object.assign(dataStyle, extStyle);
                Object.assign(editorStyle, extStyle);
                Object.assign(valueStyle, extStyle);
                Object.assign(emptyColumnStyle, extStyle);
            }
            if (i.design === EDesign.extra_padding) {
                const extStyle: CSSProperties = {
                    paddingTop: 12,
                    paddingBottom: 12,
                };
                Object.assign(labelStyle, extStyle);
                Object.assign(dataStyle, extStyle);
                Object.assign(editorStyle, extStyle);
                Object.assign(valueStyle, extStyle);
                Object.assign(emptyColumnStyle, extStyle);
            }
            if (i.design === EDesign.bold_highlighted_row) {
                const extStyle: CSSProperties = {
                    fontWeight: "bold",
                    backgroundColor: "rgba(191, 204, 214, 0.15)",
                    borderTop: "1px solid #b5b5b5",
                };
                Object.assign(labelStyle, extStyle);
                Object.assign(dataStyle, extStyle);
                Object.assign(editorStyle, extStyle);
                Object.assign(valueStyle, extStyle);
            }
            if (i.design === EDesign.intermediate_result) {
                const extStyle: CSSProperties = {
                    backgroundColor: "rgba(191, 204, 214, 0.15)",
                };
                Object.assign(labelStyle, extStyle);
                Object.assign(dataStyle, extStyle);
                Object.assign(editorStyle, extStyle);
                Object.assign(valueStyle, extStyle);
            }
            if (i.design === EDesign.final_result) {
                const extStyle: CSSProperties = {
                    fontWeight: "bold",
                    marginTop: 12,
                    backgroundColor: "rgba(191, 204, 214, 0.15)",
                    borderBottom: "3px double #b5b5b5",
                };
                Object.assign(labelStyle, extStyle);
                Object.assign(dataStyle, extStyle);
                Object.assign(editorStyle, extStyle);
                Object.assign(valueStyle, extStyle);
            }
            if(i.verticalAlign){
                const extStyle: CSSProperties = {
                    verticalAlign: i.verticalAlign,
                };
                Object.assign(labelStyle, extStyle);
                Object.assign(dataStyle, extStyle);
                Object.assign(editorStyle, extStyle);
                Object.assign(valueStyle, extStyle);
            }
            if (i.editable) {
                const extStyle: CSSProperties = {
                    paddingLeft: 0,
                    width: 135,
                };
                Object.assign(dataStyle, extStyle);
            }
            const coc_value = getValue(coc, i);

            const label = exlLabels[idx];
            const row_data = [ label, coc_value ];
            if (showEditor) {
                const str_value = getValue(coc_2, i);
                row_data.push(str_value);
            }
            table.push(row_data);
            if(coc_2){
                // console.error(row_data);
                // console.error(coc[i.value], coc_2[i.value]);
            }
            return (
                <tr key={_k("RenderField", idx)}>
                    <td style={labelStyle}>{i.label}</td>
                    <td style={emptyColumnStyle}>&nbsp;</td>
                    <td style={dataStyle}><span className={`${Classes.FOCUS_DISABLED}`} style={{display: "inline-block", width: "100%"}}>{renderValue(i)}</span></td>
                    {showEditor ? <td style={emptyColumnStyle}>&nbsp;</td> : null }
                    {showEditor ? <td style={i.editable ? editorStyle : valueStyle}>{renderInput(i)}</td> : null }
                </tr>
            );
        };
        const getBetaUnleveredLabel = (betaUnlevered: number): string => {
            if (betaUnlevered === 3) {
                return `${_t(ETranslation.median)} ${_t(ETranslation.peer_group_2)}`
            }
            if(betaUnlevered === 0){
                return`${_t(ETranslation.average)} ${_t(ETranslation.peer_group_2)}`
            }
            if(betaUnlevered === 2){
                return`${_t(ETranslation.lower_quartile)} ${_t(ETranslation.peer_group_2)}`
            }
            if(betaUnlevered === 4){
                return`${_t(ETranslation.upper_quartile)} ${_t(ETranslation.peer_group_2)}`
            }
        };
        const tableProps: ISzTableProperties<any[]> = {
            colCount: showEditor ? 4 : 2,
            no_format: true,
            data: table,
            columns,
            header: [],
            notes: [
                `¹) ${_t(ETranslation.rounding, roundingRuleParameter)}`,
                `²) ${_t(ETranslation.median_peer_group)}`
            ],
        };
        this.tableProps = tableProps;

        const resetAll = ()=> {
            this.setState({coc_2: Object.assign({}, coc)});
            this.coc_2 = {};
        };
        return (
            <div style={{marginTop: -20}}>
                <HTMLTable className={`sz-table`} condensed={true} style={{width: "100%", minWidth: 380, borderCollapse: "separate"}}>
                    <tbody>
                    {showEditor ? <tr><td style={{boxShadow: "none", padding: 0}} colSpan={4}>&nbsp;</td><td style={{boxShadow: "none", padding: 0, textAlign: "right"}} >
                        <Tooltip content={"Reset"} usePortal={true}><Button onClick={() => resetAll()} icon={"reset"} intent={"primary"} minimal={true} fill={false} /></Tooltip>
                        <Tooltip content={"Schließen"} usePortal={true}><Button onClick={() => this.setState({coc_2: null})} icon={"cross"} intent={"danger"} minimal={true} fill={false} /></Tooltip>
                    </td></tr> : <tr><td style={{boxShadow: "none", padding: 0, height: 30}} colSpan={5}>&nbsp;</td></tr> }
                    {fieldMap.map( (i, idx) => renderField(i, idx) )}
                    </tbody>
                </HTMLTable>
                <Menu style={{marginTop: 12}}>
                    <li>
                        <p className={"bp3-text-small bp3-text-muted"}>¹) {_t(ETranslation.rounding_footnote, roundingRuleParameter)}</p>
                        <p className={"bp3-text-small bp3-text-muted"}>²) {getBetaUnleveredLabel(Number(SessionStore.getItem(EParameters.BetaUnleverdOptions,3)))} </p>
                    </li>
                </Menu>
            </div>
        );
    }

    protected renderLoading() {
        return this.renderLoadingDefault();
    }

    protected onAfterUpdate(data: any) {
        this.coc_2 = {};
        super.onAfterUpdate(data);
    }

    private changeValue(a: INamedValue, value: string) {
        let str_value = value.replace(new RegExp("[^0-9\\.,]", "g"), "");
        const v = parseFloat((str_value).replace(new RegExp("\\.", "g"), "").replace(",", "."));
        const vnum = isNaN(v) ? 0 : v;

        if(str_value.endsWith(",")){
            str_value = Globals.formatter(vnum, a.digits ? a.digits : 0, 0) + ",";
        }else{
            const idx_k = str_value.indexOf(",");
            let fr_digit = idx_k>=0 ? str_value.length - 1 - idx_k : 0;
            if(fr_digit>2){
                fr_digit = 2;
            }
            str_value = Globals.formatter(vnum, fr_digit);
        }
        const coc_2 = this.state.coc_2;
        this.coc_2[a.value] = str_value;
        coc_2[a.value] = a.dataType === EDataType.percent ? vnum/100 : vnum;
        this.recalCoC(coc_2);
    }
    private resetValue(a: INamedValue) {
        const coc = this.state.coc;
        const coc_2 = this.state.coc_2;
        coc_2[a.value] = coc[a.value];
        this.coc_2[a.value] = a.dataType === EDataType.percent ? Globals.formatter(coc[a.value] *100, a.digits, 0) : a.dataType === EDataType.number || a.dataType === EDataType.number_1 ? Globals.formatter(coc[a.value], a.digits, 0) : coc[a.value];
        this.recalCoC(coc_2);
    }
    private recalCoC(coc_2: ICostOfCapital) {
        const DateParameter=coc_2.DateParameter;
        const baseInterest = coc_2.baseInterest;
        const leverage = coc_2.leverage;
        const countryRiskUsage = coc_2.countryRiskUsage;
        const betaUnlevered = coc_2.betaUnlevered;
        const betaUnleveredDefault = coc_2.betaUnleveredDefault;

        const taxShieldRiskEffect  = coc_2.taxShieldRiskEffect;
        const taxShieldCashEffect  = coc_2.taxShieldCashEffect;

        // const debtBeta = coc_2.debtBeta;

        const marketRiskPremium = coc_2.marketRiskPremium;
        const countryRiskPremium = coc_2.countryRiskPremium;
        const sizePremium = coc_2.sizePremium;
        const creditSpread = coc_2.creditSpread;
        const creditSpreadDefault = coc_2.creditSpreadDefault;

        const showShieldRiskEffect = coc_2.showShieldRiskEffect;
        const showCountryRiskPremium = coc_2.showCountryRiskPremium;
        const showSizePremium = coc_2.showSizePremium;

        const usage_debtBeta = this.getParameterValue(EParameters.DebtBetaCalcParameter, "0");
        let debtBeta = 0;
        if(usage_debtBeta === "2"){
            debtBeta = parseFloat(this.getParameterValue(EParameters.DebtBetaValueParameter, "0"));
        }
        // brechnen
        if(usage_debtBeta === "1"){
            const rating = this.getParameterValue(EParameters.RatingParameter, "BBB");
            const rating_idx = BetaGlobals.ratingScaleSP.indexOf(rating);
            const field = BetaGlobals.ratingScaleMoodys[rating_idx] ? BetaGlobals.ratingScaleMoodys[rating_idx] : "";
            const sys_risk = RatingDebtBetaSystematicRisk.data[field.toLowerCase()];
            debtBeta = creditSpread * sys_risk / marketRiskPremium;
            console.error(debtBeta, creditSpread, marketRiskPremium, sys_risk, rating, rating_idx);
        }


        const betaLevered = betaUnlevered + (betaUnlevered - debtBeta) * (1 - taxShieldRiskEffect) * leverage;
        // eigenkapital risikoprämie
        const equityRiskPremium = marketRiskPremium * betaLevered;
        // länder risikoprämie ... ?? -> countryRiskPremium * betaLevered
        const equityRiskCountry = !countryRiskUsage ? 0 : countryRiskPremium;
        // Eigenkapitalkosten
        const costOfEquity = baseInterest + equityRiskPremium + equityRiskCountry + sizePremium;
        const weightingCostOfEquity = 1 / (1 + leverage);

        const costOfDebt = baseInterest + creditSpread + countryRiskPremium;
        const costOfDebtAfterTaxes = costOfDebt * (1 - taxShieldCashEffect);

        const weightingCostOfDebt = 1 - weightingCostOfEquity;

        const weightedAvgCostOfCapital = costOfEquity * weightingCostOfEquity + costOfDebtAfterTaxes * weightingCostOfDebt;
        const unleveredCostOfEquity = baseInterest + (betaUnlevered * marketRiskPremium) + countryRiskPremium + sizePremium;
        const coc: ICostOfCapital = {
            DateParameter,
            showShieldRiskEffect,
            showCountryRiskPremium,
            showSizePremium,

            baseInterest,
            leverage,
            countryRiskUsage,
            betaUnlevered,
            betaUnleveredDefault,
            taxShieldCashEffect,
            taxShieldRiskEffect,
            debtBeta,
            marketRiskPremium,
            countryRiskPremium,
            sizePremium,
            creditSpread,
            creditSpreadDefault,

            betaLevered,
            equityRiskPremium,
            equityRiskCountry,
            costOfEquity,
            weightingCostOfEquity,
            costOfDebt,
            costOfDebtAfterTaxes,
            weightingCostOfDebt,
            weightedAvgCostOfCapital,
            unleveredCostOfEquity,
        };
        this.setState({coc_2: coc});
    }
    private onGlobalVarChanged() {
        const baseInterest = parseFloat(SessionStore.getGlobalVar(EValueName.base_rate_rounded, "0"));
        const leverage = parseFloat(this.getParameterValue(EParameters.LeverageParameter, "0"));
        const countryRiskUsage = parseInt(this.getParameterValue(EParameters.CountryRiskUsageParameter, "0"), 10);
        const betaUnlevered = parseFloat(SessionStore.getGlobalVar(EValueName.unlevered_beta, 0));
        const betaUnleveredDefault = betaUnlevered;

        const useTSRE = this.getParameterValue(EParameters.TaxShieldTargetCompanyRiskEffect, "unsicher") === "unsicher" ? false : true;

        const taxShieldRiskEffect  = useTSRE ? parseFloat(this.getParameterValue(EParameters.TaxRateParameter, "0")) : 0;
        const taxShieldCashEffect  = parseFloat(this.getParameterValue(EParameters.TaxRateParameter, "0"));

        const marketRiskPremium = parseFloat(SessionStore.getGlobalVar(EValueName.market_risk_premium, 0));

        const showShieldRiskEffect = !useTSRE;
        const showCountryRiskPremium = !countryRiskUsage;


        const countryRiskPremium = !countryRiskUsage ? 0 : parseFloat(SessionStore.getGlobalVar(EValueName.country_risk_premium, 0));
        const creditSpread = parseFloat(SessionStore.getGlobalVar(EValueName.credit_spreads, "0"));
        const creditSpreadDefault = creditSpread;

        const sizePremium = SessionStore.get(EParameters.SizePremium);
        const showSizePremium = sizePremium === 0;


        const usage_debtBeta = this.getParameterValue(EParameters.DebtBetaCalcParameter, "0");
        let debtBeta = 0;
        if(usage_debtBeta === "2"){
            debtBeta = parseFloat(this.getParameterValue(EParameters.DebtBetaValueParameter, "0"));
        }
        // brechnen
        if(usage_debtBeta === "1"){
            const rating = this.getParameterValue(EParameters.RatingParameter, "BBB");
            const rating_idx = BetaGlobals.ratingScaleSP.indexOf(rating);
            const field = BetaGlobals.ratingScaleMoodys[rating_idx] ? BetaGlobals.ratingScaleMoodys[rating_idx] : "";
            const sys_risk = RatingDebtBetaSystematicRisk.data[field.toLowerCase()];
            debtBeta = creditSpread * sys_risk / marketRiskPremium;
            // console.error(debtBeta, creditSpread, marketRiskPremium, sys_risk, rating, rating_idx);
        }


        const betaLevered = betaUnlevered + (betaUnlevered - debtBeta) * (1 - taxShieldRiskEffect) * leverage;

        // eigenkapital risikoprämie
        const equityRiskPremium = marketRiskPremium * betaLevered;

        // länder risikoprämie ... ?? -> countryRiskPremium * betaLevered
        const equityRiskCountry = !countryRiskUsage ? 0 : countryRiskPremium;

        // Eigenkapitalkosten
        const costOfEquity = baseInterest + equityRiskPremium + equityRiskCountry + sizePremium;
        const weightingCostOfEquity = 1 / (1 + leverage);

        const costOfDebt = baseInterest + creditSpread + countryRiskPremium;
        const costOfDebtAfterTaxes = costOfDebt * (1 - taxShieldCashEffect);

        const weightingCostOfDebt = 1 - weightingCostOfEquity;

        const weightedAvgCostOfCapital = costOfEquity * weightingCostOfEquity + costOfDebtAfterTaxes * weightingCostOfDebt;
        const unleveredCostOfEquity = baseInterest + (betaUnlevered * marketRiskPremium) + countryRiskPremium + sizePremium;

        SessionStore.setItem(EParameters.Wacc, weightedAvgCostOfCapital);
        const DateParameter = SessionStore.get(EParameters.DateParameter)
        const coc: ICostOfCapital = {
            DateParameter,
            showShieldRiskEffect,
            showCountryRiskPremium,
            showSizePremium,
            baseInterest,
            leverage,
            countryRiskUsage,
            betaUnlevered,
            betaUnleveredDefault,
            taxShieldCashEffect,
            taxShieldRiskEffect,
            debtBeta,
            marketRiskPremium,
            countryRiskPremium,
            sizePremium,
            creditSpread,
            creditSpreadDefault,

            betaLevered,
            equityRiskPremium,
            equityRiskCountry,
            costOfEquity,
            weightingCostOfEquity,
            costOfDebt,
            costOfDebtAfterTaxes,
            weightingCostOfDebt,
            weightedAvgCostOfCapital,
            unleveredCostOfEquity,
        };
        this.setState({
            coc,
        });
        SessionStore.setItem(EParameters.CostOfCapital, this.state.coc);
        EventBus.emit("CostOfCapital::changed", this.state.coc);
    }
}
