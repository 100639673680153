export function asFinAnnReportedV2(data): IFinAnnReportedV2 {
    if(!Array.isArray(data)){
        return {} as IFinAnnReportedV2;
    }
    const [
        company_id,
        date_decimal,
        currency_iso,
        ff_debt,
        ff_cash_generic,
        ff_pens_liabs_unfunded,
        ff_funds_oper_gross,
        ff_eq_tot,
        ff_shldrs_eq,
        ff_assets,
        ff_ppe_net,
        ff_intang,
        ff_capex,
        ff_wkcap,
        ff_sales,
        ff_ebitda,
        ff_ebit,
        ff_int_exp_debt,
        ff_inc_tax,
        ff_net_inc,
        ff_gross_inc,
        ff_invest_aff,
        ff_invest_lt_oth,
        ff_notes_receiv_lt,
        ff_invest_st_tot,
        ff_cash_restr,
        ff_cash_only,
        ff_tax_cf,
        ff_ptx_inc,
        ff_oper_lease_commit_tot,
        ff_oper_lease_commit_yr1,
        ff_oper_lease_commit_yr2,
        ff_oper_lease_commit_yr3,
        ff_oper_lease_commit_yr4,
        ff_oper_lease_commit_yr5,
        ff_oper_lease_commit_5yr,
        ff_cogs,
        ff_cogs_xdep,
        ff_dep_amort_exp,
        ff_dep_exp,
        ff_amort_intang,
        ff_sga,
        ff_sga_oth,
        ff_rd_exp,
        ff_oper_exp_oth,
        ff_ebit_oper,
        ff_non_oper_inc,
        ff_int_inc_non_oper,
        ff_eq_aff_inc_ptx,
        ff_rsrv_chg,
        ff_misc_exp_non_oper,
        ff_int_exp_net,
        ff_int_cap,
        ff_unusual_exp,
        ff_impair,
        ff_restruct_exp,
        ff_legal_claim_exp,
        ff_unreal_gl_tot,
        ff_oth_xcept_chrg,
        ff_eq_aff_inc,
        ff_adj_net_oth,
        ff_consol_net_inc,
        ff_min_int_exp,
        ff_emp_num,
        ff_ebitda_oper,
    ] = data;
    return {
        company_id,
        date_decimal,
        currency_iso,
        ff_debt,
        ff_cash_generic,
        ff_pens_liabs_unfunded,
        ff_funds_oper_gross,
        ff_eq_tot,
        ff_shldrs_eq,
        ff_assets,
        ff_ppe_net,
        ff_intang,
        ff_capex,
        ff_wkcap,
        ff_sales,
        ff_ebitda,
        ff_ebit,
        ff_int_exp_debt,
        ff_inc_tax,
        ff_net_inc,
        ff_gross_inc,
        ff_invest_aff,
        ff_invest_lt_oth,
        ff_notes_receiv_lt,
        ff_invest_st_tot,
        ff_cash_restr,
        ff_cash_only,
        ff_tax_cf,
        ff_ptx_inc,
        ff_oper_lease_commit_tot,
        ff_oper_lease_commit_yr1,
        ff_oper_lease_commit_yr2,
        ff_oper_lease_commit_yr3,
        ff_oper_lease_commit_yr4,
        ff_oper_lease_commit_yr5,
        ff_oper_lease_commit_5yr,
        ff_cogs,
        ff_cogs_xdep,
        ff_dep_amort_exp,
        ff_dep_exp,
        ff_amort_intang,
        ff_sga,
        ff_sga_oth,
        ff_rd_exp,
        ff_oper_exp_oth,
        ff_ebit_oper,
        ff_non_oper_inc,
        ff_int_inc_non_oper,
        ff_eq_aff_inc_ptx,
        ff_rsrv_chg,
        ff_misc_exp_non_oper,
        ff_int_exp_net,
        ff_int_cap,
        ff_unusual_exp,
        ff_impair,
        ff_restruct_exp,
        ff_legal_claim_exp,
        ff_unreal_gl_tot,
        ff_oth_xcept_chrg,
        ff_eq_aff_inc,
        ff_adj_net_oth,
        ff_consol_net_inc,
        ff_min_int_exp,
        ff_emp_num,
        ff_ebitda_oper,
    }
};
export interface IFinAnnReportedV2 {
    company_id: string;
    date_decimal: string;
    currency_iso: string;
    ff_debt: number;
    ff_cash_generic: number;
    ff_pens_liabs_unfunded: number;
    ff_funds_oper_gross: number;
    ff_eq_tot: number;
    ff_shldrs_eq: number;
    ff_assets: number;
    ff_ppe_net: number;
    ff_intang: number;
    ff_capex: number;
    ff_wkcap: number;
    ff_sales: number;
    ff_ebitda: number;
    ff_ebit: number;
    ff_int_exp_debt: number;
    ff_inc_tax: number;
    ff_net_inc: number;
    ff_gross_inc: number;
    ff_invest_aff: number;
    ff_invest_lt_oth: number;
    ff_notes_receiv_lt: number;
    ff_invest_st_tot: number;
    ff_cash_restr: number;
    ff_cash_only: number;
    ff_tax_cf: number;
    ff_ptx_inc: number;
    ff_oper_lease_commit_tot: number;
    ff_oper_lease_commit_yr1: number;
    ff_oper_lease_commit_yr2: number;
    ff_oper_lease_commit_yr3: number;
    ff_oper_lease_commit_yr4: number;
    ff_oper_lease_commit_yr5: number;
    ff_oper_lease_commit_5yr: number;
    ff_cogs: number;
    ff_cogs_xdep: number;
    ff_dep_amort_exp: number;
    ff_dep_exp: number;
    ff_amort_intang: number;
    ff_sga: number;
    ff_sga_oth: number;
    ff_rd_exp: number;
    ff_oper_exp_oth: number;
    ff_ebit_oper: number;
    ff_non_oper_inc: number;
    ff_int_inc_non_oper: number;
    ff_eq_aff_inc_ptx: number;
    ff_rsrv_chg: number;
    ff_misc_exp_non_oper: number;
    ff_int_exp_net: number;
    ff_int_cap: number;
    ff_unusual_exp: number;
    ff_impair: number;
    ff_restruct_exp: number;
    ff_legal_claim_exp: number;
    ff_unreal_gl_tot: number;
    ff_oth_xcept_chrg: number;
    ff_eq_aff_inc: number;
    ff_adj_net_oth: number;
    ff_consol_net_inc: number;
    ff_min_int_exp: number;
    ff_emp_num: number;
    ff_ebitda_oper: number;
}
